import { useRef, useEffect, useState, useContext, FC } from 'react'
import './App.css'
import { gql, useApolloClient } from '@apollo/client'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
// @ts-ignore
import { keysToCamel } from 'tools'
// @ts-ignore
import { useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import {
  Box,
  Drawer,
  Toolbar,
  CssBaseline,
  List,
  Divider,
  IconButton,
  AppBar as MuiAppBar,
  ListItemButton,
  Checkbox,
  TextField,
  FormControlLabel,
  ListItemText,
  Button,
  ClickAwayListener,
  Stack,
  InputAdornment,
  Dialog,
  Theme,
  Popover,
  ListItem,
} from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CancelIcon from '@mui/icons-material/Cancel'
import MenuIcon from '@mui/icons-material/Menu'
import Home from './Home'
import CallbackRequestsRouter from './CallbackRequests/Router'
import LeadsRouter from './Leads/Router'
import QuotesRouter from './Quotes/Router'
import JobsRouter from './Jobs/Router'
import TimeCardsRouter from './TimeCards/Router'
import TechniciansRouter from './Technicians/Router'
import Schedule from './Schedule/Schedule'
import PartsRouter from './Parts/Router'
import PartVendorsRouter from './PartVendors/Router'
import Users from './Users'
import Reports from './Reports'
import ChartsRouter from './Charts/Router'
import Sales from './Sales'
import Logout from './Logout'
import Payroll from './Admin/Payroll'
import BToBRouter from './BToB/Router'
import BToBInvoicesRouter from './BToB/Invoices/Router'
import BToBDealersRouter from './BToB/Dealers/Router'
import BToBWorkOrdersRouter from './BToB/WorkOrders/Router'
import CustomersRouter from './Customers/Router'
import SuppliesOrdersRouter from './SuppliesOrders/Router'
import Jitsi from './Jitsi'
import Tasks from './Tasks'
import RightDrawer from './RightDrawer'
import LeftDrawer from './LeftDrawer'
import { UserContext } from './UserStore'
import { ReadCacheChannelContext } from './ReadCacheChannelStore'
import GlobalSearchResults from './GlobalSearchResults'
import { useDebounce } from 'use-debounce'
// @ts-ignore
import { CustomerContext } from 'CustomerStore'
// @ts-ignore
import AppointmentsRouter from 'Appointments/Router'
// @ts-ignore
import SmartFollowupsRouter from 'SmartFollowups'
// @ts-ignore
import ConfigurationsRouter from './Configurations/Router'
// @ts-ignore
import PreLeadsRouter from './Pre-Leads/Router'
import { EmployeeContractorSwapper } from 'EmployeeContractorSwapper/EmployeeContactorSwapper'
import RecruitingHome from 'Recruiting'
import { ApplicantContext } from 'RecruitingApplicantStore'
import JobApplicationsRouter from 'JobApplications/Router'
import { Applicant } from 'Applicants/Applicant'
import TechnicianIssueDialog from 'components/TechnicianIssue'
import JobListingView from 'Recruiting/JobListings/JobListingView'

const fullRightDrawerWidth = 400

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  leftMargin: 0,
  marginRight: 0,
}))

const AppBar = styled(MuiAppBar)(({ rightDrawerWidth }: { rightDrawerWidth: number }) => ({
  backgroundColor: (process.env.REACT_APP_COMMAND_ROOT || 'https://crm.carbodylab.local').includes('.com')
    ? ''
    : '#ff8686',
  paddingRight: rightDrawerWidth,
}))

const RightDrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}))

const LeftDrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const App: FC = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const location = useLocation()
  const history = useHistory()
  const apolloClient = useApolloClient()
  const [user] = useContext(UserContext)
  const readCacheChannel = useContext(ReadCacheChannelContext)
  const [globalSearchTerm, setGlobalSearchTerm] = useState('')
  const [globalSearchResults, setGlobalSearchResults] = useState([])
  const [debouncedGlobalSearchTerm] = useDebounce(globalSearchTerm, 500, { leading: false })
  const theme = useTheme()
  const { currentCustomer } = useContext(CustomerContext)
  const { currentApplicant } = useContext(ApplicantContext)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [adminMenuIsOpen, setAdminMenuIsOpen] = useState(false)
  const [reportMenuIsOpen, setReportMenuIsOpen] = useState(false)
  const [techMenuIsOpen, setTechMenuIsOpen] = useState(false)
  const [salesMenuIsOpen, setSalesMenuIsOpen] = useState(false)
  const [b2bMenuIsOpen, setB2bMenuIsOpen] = useState(false)
  const menuAnchorRef = useRef<any>()
  const adminMenuAnchorRef = useRef<any>()
  const reportMenuAnchorRef = useRef<any>()
  const techMenuAnchorRef = useRef<any>()
  const salesMenuAnchorRef = useRef<any>()
  const b2bMenuAnchorRef = useRef<any>()
  const [rightDrawerWidth, setRightDrawerWidth] = useState(
    window.location.pathname.includes('payroll') ? 0 : fullRightDrawerWidth
  )
  const [leftDrawerWidth, setLeftDrawerWidth] = useState(0)

  const [featureRequestDialogOpen, setFeatureRequestDialogOpen] = useState(false)
  const [reportBugDialogOpen, setReportBugDialogOpen] = useState(false)
  const [technicianIssueDialogOpen, setTechnicianIssueDialogOpen] = useState(false)

  const [autoOpen, setAutoOpen] = useState<boolean>(JSON.parse(localStorage.getItem('rightDrawerAutoOpen') || 'true'))
  useEffect(() => localStorage.setItem('rightDrawerAutoOpen', autoOpen.toString()), [autoOpen])

  const [allowQuickCollapse, setAllowQuickCollapse] = useState<boolean>(
    JSON.parse(localStorage.getItem('rightDrawerAllowQuickCollapse') || 'false')
  )
  useEffect(
    () => localStorage.setItem('rightDrawerAllowQuickCollapse', allowQuickCollapse.toString()),
    [allowQuickCollapse]
  )

  useEffect(() => {
    if (!autoOpen) return

    setRightDrawerWidth(currentCustomer ? fullRightDrawerWidth : 0)
  }, [currentCustomer])

  useEffect(() => {
    if (!autoOpen) return

    setRightDrawerWidth(currentApplicant ? fullRightDrawerWidth : 0)
  }, [currentApplicant])

  useEffect(() => {
    const trimmed = debouncedGlobalSearchTerm.trim()
    if (trimmed.length < 3) {
      setGlobalSearchResults([])
    } else {
      fetch(`https://${process.env.REACT_APP_NEO_HOST}/search`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          term: trimmed,
        }),
      })
        .then(res => {
          if (res.ok) {
            return res.json()
          }
        })
        .then(data => setGlobalSearchResults(data.results))
        .catch(err => alert(err))
    }
  }, [debouncedGlobalSearchTerm])

  const updateCache = ({ type, id, data }: { type: any; id: any; data: any }) => {
    const fragment = gql`
      fragment My${type} on ${type} {
        ${Object.keys(data).join(' ')}
      }
    `
    apolloClient.writeFragment({
      id: `${type}:${id}`,
      fragment,
      data,
    })
  }

  useEventHandler(readCacheChannel, 'object_replaced', ({ type, id, data }: { type: any; id: any; data: any }) => {
    // @ts-ignore
    if (apolloClient.cache.data.data[`${type}:${id}`]) {
      updateCache({ type, id, data: keysToCamel(data) })
    }
  })

  const handleRightDrawerOpen = () => {
    setRightDrawerWidth(fullRightDrawerWidth)
  }

  const handleDrawerClose = () => {
    setRightDrawerWidth(0)
  }

  const RoleRoute = ({ roles, children, path, ...rest }: { roles: any; children: any; path: string }) => {
    return (
      <Route
        {...rest}
        render={({ location }: { location: any }) =>
          user.roles.some((role: string) => roles.includes(role)) ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
        }
      />
    )
  }

  const handleMenuItemClick = (pushTo: string) => {
    history.push(pushTo)
    setMenuIsOpen(false)
    setAdminMenuIsOpen(false)
    setReportMenuIsOpen(false)
    setTechMenuIsOpen(false)
    setSalesMenuIsOpen(false)
    setB2bMenuIsOpen(false)
  }

  const handleClickAwayFromRightDrawer = () => {}
  const handleClickAwayFromLeftDrawer = () => {}
  const handleClickIntoRightDrawer = () => {}

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <AppBar position='fixed' rightDrawerWidth={rightDrawerWidth}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Stack direction='row' spacing={1}>
              <IconButton color='inherit' edge='end' style={{ ...(leftDrawerWidth > 0 && { display: 'none' }) }}>
                <MenuIcon />
              </IconButton>
              &nbsp; &nbsp;
              <Button color='secondary' variant='contained' onClick={() => history.push('/')}>
                Home
              </Button>
              <Button color='secondary' variant='contained' onClick={() => history.push('/schedule')}>
                Schedule
              </Button>
              <Button variant='contained' ref={menuAnchorRef} onClick={() => setMenuIsOpen(prevOpen => !prevOpen)}>
                Menu
              </Button>
              <Popover
                elevation={1}
                open={menuIsOpen}
                onClose={() => setMenuIsOpen(false)}
                anchorEl={menuAnchorRef.current}
                disablePortal
                PaperProps={{
                  sx: {
                    zIndex: '1201 !important',
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <List>
                  {user.roles.includes('admin') && (
                    <ListItem disablePadding ref={adminMenuAnchorRef}>
                      <ListItemButton key='admin' onClick={() => setAdminMenuIsOpen(prevOpen => !prevOpen)}>
                        <ListItemText primary='Admin' />
                        <ChevronRightIcon />
                      </ListItemButton>
                    </ListItem>
                  )}

                  {user.roles.includes('reports') && (
                    <ListItem disablePadding ref={reportMenuAnchorRef}>
                      <ListItemButton onClick={() => setReportMenuIsOpen(prevOpen => !prevOpen)} key='reports'>
                        <ListItemText primary='Report' />
                        <ChevronRightIcon />
                      </ListItemButton>
                    </ListItem>
                  )}

                  <ListItem disablePadding ref={techMenuAnchorRef}>
                    <ListItemButton key='technicians' onClick={() => setTechMenuIsOpen(prevOpen => !prevOpen)}>
                      <ListItemText primary='Technician' />
                      <ChevronRightIcon />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding ref={salesMenuAnchorRef}>
                    <ListItemButton key='sales' onClick={() => setSalesMenuIsOpen(prevOpen => !prevOpen)}>
                      <ListItemText primary='Sales' />
                      <ChevronRightIcon />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding ref={b2bMenuAnchorRef}>
                    <ListItemButton onClick={() => setB2bMenuIsOpen(prevOpen => !prevOpen)} key='sales'>
                      <ListItemText primary='B2B' />
                      <ChevronRightIcon />
                    </ListItemButton>
                  </ListItem>

                  <ListItemButton
                    selected={location.pathname === '/recruiting'}
                    onClick={() => handleMenuItemClick('/recruiting')}
                    key='recruiting'
                  >
                    <ListItemText primary='Recruiting' />
                  </ListItemButton>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/leads'}
                      onClick={() => handleMenuItemClick('/leads')}
                      key='Leads'
                    >
                      <ListItemText primary='Leads' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/pre-leads'}
                      onClick={() => handleMenuItemClick('/pre-leads')}
                      key='Pre-Leads'
                    >
                      <ListItemText sx={{ width: '100%' }} primary='Pre-Leads' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/appointments'}
                      onClick={() => handleMenuItemClick('/appointments')}
                      key='appointments'
                    >
                      <ListItemText primary='Appointments' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/parts'}
                      onClick={() => handleMenuItemClick('/parts')}
                      key='parts'
                    >
                      <ListItemText primary='Parts' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/jobs'}
                      onClick={() => handleMenuItemClick('/jobs')}
                      key='jobs'
                    >
                      <ListItemText primary='Jobs' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/part-vendors'}
                      onClick={() => handleMenuItemClick('/part-vendors')}
                      key='merchants'
                    >
                      <ListItemText primary='Merchants' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/jitsi'}
                      onClick={() => handleMenuItemClick('/jitsi')}
                      key='jitsi'
                    >
                      <ListItemText primary='Jitsi' />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
              <Popover
                elevation={1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={reportMenuIsOpen}
                anchorEl={reportMenuAnchorRef.current}
                onClose={() => setReportMenuIsOpen(false)}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/reports'}
                      onClick={() => handleMenuItemClick('/reports')}
                      key='Reports'
                    >
                      <ListItemText primary='Reports' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/charts'}
                      onClick={() => handleMenuItemClick('/charts')}
                      key='charts'
                    >
                      <ListItemText primary='Charts' />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
              <Popover
                elevation={1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => setAdminMenuIsOpen(false)}
                open={adminMenuIsOpen}
                anchorEl={adminMenuAnchorRef.current}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/payroll'}
                      onClick={() => handleMenuItemClick('/payroll')}
                      key='Payroll'
                    >
                      <ListItemText primary='Payroll' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/users'}
                      onClick={() => handleMenuItemClick('/users')}
                      key='users'
                    >
                      <ListItemText primary='Users' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/configurations'}
                      onClick={() => handleMenuItemClick('/configurations')}
                      key='configurations'
                    >
                      <ListItemText primary='Configurations' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/smart-followups'}
                      onClick={() => handleMenuItemClick('/smart-followups')}
                      key='smartfollowups'
                    >
                      <ListItemText primary='Smart Followups' />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
              <Popover
                elevation={1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => setTechMenuIsOpen(false)}
                open={techMenuIsOpen}
                anchorEl={techMenuAnchorRef.current}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/schedule'}
                      onClick={() => handleMenuItemClick('/schedule')}
                      key='schedule'
                    >
                      <ListItemText primary='Schedule' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/technicians'}
                      onClick={() => handleMenuItemClick('/technicians')}
                      key='technicians'
                    >
                      <ListItemText primary='Technicians' />
                    </ListItemButton>
                  </ListItem>

                  {(user.roles.includes('ops') || user.roles.includes('admin')) && (
                    <>
                      <ListItem disablePadding>
                        <ListItemButton
                          selected={location.pathname === '/supplies-orders'}
                          onClick={() => handleMenuItemClick('/supplies-orders')}
                          key='SuppliesOrders'
                        >
                          <ListItemText primary='Supplies Orders' />
                        </ListItemButton>
                      </ListItem>
                    </>
                  )}

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/timecards'}
                      onClick={() => handleMenuItemClick('/timecards')}
                      key='timecards'
                    >
                      <ListItemText primary='Time Cards' />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
              <Popover
                elevation={1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => setSalesMenuIsOpen(false)}
                open={salesMenuIsOpen}
                anchorEl={salesMenuAnchorRef.current}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/sales'}
                      onClick={() => handleMenuItemClick('/sales')}
                      key='sales'
                    >
                      <ListItemText primary='Daily Sales' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/employee-contractor-swapper'}
                      onClick={() => handleMenuItemClick('/employee-contractor-swapper')}
                      key='employee-contractor-swapper'
                    >
                      <ListItemText primary='Job Swapper' />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
              <Popover
                elevation={1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => setB2bMenuIsOpen(false)}
                open={b2bMenuIsOpen}
                anchorEl={b2bMenuAnchorRef.current}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/b-to-b/chat'}
                      onClick={() => handleMenuItemClick('/b-to-b/chat')}
                      key='BToBChat'
                    >
                      <ListItemText primary='Chat' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/b-to-b/invoices'}
                      onClick={() => handleMenuItemClick('/b-to-b/invoices')}
                      key='BToBInvoices'
                    >
                      <ListItemText primary='Invoices' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/b-to-b/statements'}
                      onClick={() => handleMenuItemClick('/b-to-b/statements')}
                      key='BToBIStatements'
                    >
                      <ListItemText primary='Statements' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/b-to-b/dealers'}
                      onClick={() => handleMenuItemClick('/b-to-b/dealers')}
                      key='BToBDealers'
                    >
                      <ListItemText primary='Dealers' />
                    </ListItemButton>
                  </ListItem>

                  {/* <ListItemButton
                            selected={location.pathname === '/b-to-b/work-orders'}
                            onClick={() => handleMenuItemClick('/b-to-b/work-orders')}
                            key='BToBWorkOrders'
                          >
                            <ListItemText primary='Part Orders' />
                          </ListItemButton> */}

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/b-to-b/payments'}
                      onClick={() => handleMenuItemClick('/b-to-b/payments')}
                      key='BToBWorkOrders'
                    >
                      <ListItemText primary='Payments' />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      selected={location.pathname === '/b-to-b/settings'}
                      onClick={() => handleMenuItemClick('/b-to-b/settings')}
                      key='BToBWorkOrders'
                    >
                      <ListItemText primary='Settings' />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
              <Box style={{ borderRadius: '4px', backgroundColor: 'white' }}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          // onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          onClick={() => setGlobalSearchTerm('')}
                          edge='end'
                        >
                          <CancelIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  size='small'
                  placeholder='(☞ ͡° ͜ʖ ͡°)☞ (lead search)'
                  value={globalSearchTerm}
                  onChange={e => setGlobalSearchTerm(e.target.value)}
                />
              </Box>
              <Button
                onClick={() => setFeatureRequestDialogOpen(true)}
                variant='contained'
                sx={{ color: '#fff', pl: 'auto' }}
              >
                Feature Request
              </Button>
              <Button
                onClick={() => setReportBugDialogOpen(true)}
                variant='contained'
                sx={{ background: 'darkred', color: '#fff', '&:hover': { background: 'darkred' } }}
              >
                Report Bug
              </Button>
              <Button
                variant='contained'
                onClick={() => setTechnicianIssueDialogOpen(true)}
                sx={{ background: '#fff', color: '#000', '&:hover': { background: '#fff' } }}
              >
                New Technician Issue
              </Button>
            </Stack>
          </Box>

          <IconButton
            color='inherit'
            edge='end'
            onClick={handleRightDrawerOpen}
            sx={{ ...(rightDrawerWidth > 0 && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Dialog
        PaperProps={{ sx: { minWidth: '850px', minHeight: '560px' } }}
        onClose={() => setFeatureRequestDialogOpen(false)}
        open={featureRequestDialogOpen}
      >
        <iframe
          src='https://forms.raleyapps.com/form/render/1fe87eab-413f-42e0-8b7d-3a0e8d63b687/e23lgc86t4'
          style={{ width: '850px', height: '560px' }}
          scrolling='auto'
        />
      </Dialog>

      <Dialog
        PaperProps={{ sx: { minWidth: '850px', minHeight: '580px' } }}
        onClose={() => setReportBugDialogOpen(false)}
        open={reportBugDialogOpen}
      >
        <iframe
          src='https://forms.raleyapps.com/form/render/8504016c-d023-472a-b233-2ba783ada890/cj25eyh3jn'
          style={{ width: '850px', height: '560px' }}
          scrolling='auto'
        />
      </Dialog>

      <TechnicianIssueDialog showDialog={technicianIssueDialogOpen} setShowDialog={setTechnicianIssueDialogOpen} />

      <Main>
        <LeftDrawerHeader />
        <Tasks />
        {globalSearchResults.length > 0 && <GlobalSearchResults results={globalSearchResults} />}

        {globalSearchResults.length === 0 && (
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route path='/callback-requests'>
              <CallbackRequestsRouter />
            </Route>
            <Route path='/customers'>
              <CustomersRouter />
            </Route>
            <Route path='/leads'>
              <LeadsRouter />
            </Route>
            <Route path='/pre-leads'>
              <PreLeadsRouter />
            </Route>
            <Route path='/quotes'>
              <QuotesRouter />
            </Route>
            <Route path='/technicians'>
              <TechniciansRouter />
            </Route>
            <Route path='/parts'>
              <PartsRouter />
            </Route>
            <Route path='/part-vendors'>
              <PartVendorsRouter />
            </Route>
            <Route path='/jobs'>
              <JobsRouter />
            </Route>
            <Route path='/timecards'>
              <TimeCardsRouter />
            </Route>
            <RoleRoute roles={['admin']} path='/smart-followups'>
              <SmartFollowupsRouter />
            </RoleRoute>
            <Route path='/b-to-b-invoices'>
              <BToBInvoicesRouter />
            </Route>
            <Route path='/b-to-b-dealers'>
              <BToBDealersRouter />
            </Route>
            <Route path='/b-to-b-work-orders'>
              <BToBWorkOrdersRouter />
            </Route>
            <Route path='/schedule'>
              <Schedule />
            </Route>
            <Route path='/appointments'>
              <AppointmentsRouter />
            </Route>
            <Route path='/sales'>
              <Sales />
            </Route>
            <RoleRoute roles={['admin']} path='/users'>
              <Users />
            </RoleRoute>
            <RoleRoute roles={['admin', 'reports']} path='/reports'>
              <Reports />
            </RoleRoute>
            <Route path='/supplies-orders'>
              <SuppliesOrdersRouter />
            </Route>
            <Route path='/configurations'>
              <ConfigurationsRouter />
            </Route>
            <RoleRoute roles={['admin', 'reports']} path='/charts'>
              <ChartsRouter />
            </RoleRoute>
            <RoleRoute roles={['admin']} path='/payroll'>
              <Payroll />
            </RoleRoute>
            <Route path='/b-to-b'>
              <BToBRouter />
            </Route>
            <Route exact path='/logout'>
              <Logout />
            </Route>
            <Route exact path='/jitsi'>
              <Jitsi />
            </Route>
            <Route exact path='/employee-contractor-swapper'>
              <EmployeeContractorSwapper />
            </Route>

            <Route path={['/job-listings/:jobListingId']}>
              <JobListingView />
            </Route>

            <Route path={['/recruiting/:recruitingPage', '/recruiting']}>
              <RecruitingHome />
            </Route>

            <Route path='/applications'>
              <JobApplicationsRouter />
            </Route>
            <Route path='/applicants/:applicantId'>
              <Applicant />
            </Route>
          </Switch>
        )}
      </Main>

      <ClickAwayListener onClickAway={handleClickAwayFromRightDrawer}>
        <Drawer
          onClick={handleClickIntoRightDrawer}
          sx={{
            width: rightDrawerWidth,
            // flexShrink: 0,
            // '& .MuiDrawer-paper': {
            //   width: rightDrawerWidth
            // }
          }}
          variant='persistent'
          anchor='right'
          open={rightDrawerWidth > 0}
        >
          <Box sx={{ width: fullRightDrawerWidth }}>
            <RightDrawerHeader>
              <Box sx={{ flex: 1 }}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </Box>

              <Box sx={{ textAlign: 'right' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size='small'
                      checked={autoOpen}
                      onChange={e => setAutoOpen(e.target.checked)}
                      color='secondary'
                      title='Auto open/close'
                    />
                  }
                  label='Auto open/close'
                />
              </Box>
            </RightDrawerHeader>

            <Divider />

            <RightDrawer />
          </Box>
        </Drawer>
      </ClickAwayListener>
    </Box>
  )
}

export default App
