import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { useQuery, gql } from '@apollo/client'
import { MentionsInput, Mention } from 'react-mentions'
import mentionsClassNames from './mentions.module.css'

const MARKETS_QUERY = gql`
  query markets {
    markets {
      state
      stateCode
      market
    }
  }
`

const STATUSES_QUERY = gql`
  query interactionStatuses {
    interactionStatuses
  }
`

const Edit = ({ smartFollowup, refetch }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [editableSmartFollowup, setEditableSmartFollowup] = useState({
    ...smartFollowup,
    editableCriteria: smartFollowup.otherCriteria,
    editableAction: smartFollowup.smartAction,
    markets: smartFollowup.marketsCriterion?.data?.markets || [],
  })

  useEffect(() => {
    setEditableSmartFollowup({
      ...smartFollowup,
      editableCriteria: smartFollowup.otherCriteria,
      editableAction: smartFollowup.smartAction,
      markets: smartFollowup.marketsCriterion?.data?.markets || [],
    })
  }, [smartFollowup])

  const {
    error: marketsError,
    loading: marketsLoading,
    data: marketsData,
  } = useQuery(MARKETS_QUERY, {
    fetchPolicy: 'network-only',
  })

  const {
    error: statusesError,
    loading: statusesLoading,
    data: statusesData,
  } = useQuery(STATUSES_QUERY, {
    fetchPolicy: 'network-only',
  })

  if (marketsLoading || statusesLoading) return 'Loading...'
  if (marketsError) return JSON.stringify(marketsError)
  if (statusesError) return JSON.stringify(statusesError)

  const markets = marketsData?.markets || []
  const interactionStatuses = statusesData?.interactionStatuses || []

  const handleSaveSmartFollowup = () => {
    const checkCriteria = () => {
      const original = editableSmartFollowup.otherCriteria
      const newCriteria = editableSmartFollowup.editableCriteria

      const originalMarkets = smartFollowup.markets?.data?.marketsCriterion
      const newMarkets = editableSmartFollowup.markets

      const notEqualCriteria = JSON.stringify(original) !== JSON.stringify(newCriteria)
      const notEqualMarkets = JSON.stringify(originalMarkets) !== JSON.stringify(newMarkets)

      if (notEqualMarkets) {
        const foundCriterion = smartFollowup.marketsCriterion
        if (foundCriterion) {
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_smart_criterion_attributes`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              smart_criterion_id: foundCriterion.id,
              updated_attributes: {
                data: {
                  markets: newMarkets,
                },
              },
            }),
          })
        } else {
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_smart_criterion`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              smart_followup_id: smartFollowup.id,
              type: 'SmartCriterion::Types::Markets',
              data: {
                markets: newMarkets,
              },
            }),
          })
        }
      }

      if (notEqualCriteria) {
        const unequalIds = []
        const newIds = []
        const idsToDelete = []

        original.forEach(criterion => {
          const updatedCriterion = newCriteria.find(c => c.id === criterion.id)
          if (updatedCriterion) {
            if (JSON.stringify(updatedCriterion.data) !== JSON.stringify(criterion.data)) {
              unequalIds.push(criterion.id)
            }
          } else {
            idsToDelete.push(criterion.id)
          }
        })

        newCriteria.forEach(criterion => {
          const originalCriterion = original.find(c => c.id === criterion.id)
          if (!originalCriterion) {
            newIds.push(criterion.id)
          }
        })

        unequalIds.map(id => {
          const foundCriterion = newCriteria.find(c => c.id === id)
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_smart_criterion_attributes`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              smart_criterion_id: id,
              updated_attributes: {
                data: foundCriterion.data,
              },
            }),
          })
        })

        newIds.map(id => {
          const foundCriterion = newCriteria.find(a => a.id === id)
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_smart_criterion`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              smart_followup_id: smartFollowup.id,
              data: foundCriterion.data,
              type: foundCriterion.type,
            }),
          })
        })

        idsToDelete.map(id => {
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_smart_criterion`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              smart_criterion_id: id,
            }),
          })
        })
      }
    }

    const checkAction = () => {
      const original = editableSmartFollowup.smartAction
      const newAction = editableSmartFollowup.editableAction
      const notEqual = JSON.stringify(original) !== JSON.stringify(newAction)

      if (notEqual) {
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_smart_action`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            smart_followup_id: smartFollowup.id,
            data: newAction.data,
            type: newAction.type,
          }),
        })
        original &&
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_smart_action`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              smart_action_id: original.id,
            }),
          })
      }
    }

    checkAction()
    checkCriteria()

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_smart_followup_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        smart_followup_id: smartFollowup.id,
        updated_attributes: {
          name: editableSmartFollowup.name,
          active: editableSmartFollowup.active,
          last_message_delay_hours: editableSmartFollowup.lastMessageDelayHours,
          interaction_status: editableSmartFollowup.interactionStatus,
        },
      }),
    }).then(res => refetch())
  }

  return (
    <Box sx={{ padding: '.5rem' }}>
      <Grid container>
        <Grid item xs={6} sx={{ padding: '.5rem' }}>
          <TextField
            label='name'
            onChange={e => setEditableSmartFollowup(prev => ({ ...prev, name: e.target.value }))}
            value={editableSmartFollowup.name}
            fullWidth
          />
        </Grid>

        <Grid item xs={4} sx={{ padding: '.5rem' }}>
          <FormControl fullWidth>
            <InputLabel>Lead status</InputLabel>
            <Select
              onChange={e => setEditableSmartFollowup(prev => ({ ...prev, interactionStatus: e.target.value }))}
              label='Lead status'
              value={editableSmartFollowup.interactionStatus || ''}
            >
              <MenuItem value=''>None</MenuItem>
              {interactionStatuses.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2} sx={{ padding: '.5rem' }}>
          <TextField
            label='last text delay (hours)'
            type='number'
            onChange={e => setEditableSmartFollowup(prev => ({ ...prev, lastMessageDelayHours: +e.target.value }))}
            value={editableSmartFollowup.lastMessageDelayHours}
          />
        </Grid>

        <Grid item xs={12} sx={{ padding: '.5rem' }}>
          <Autocomplete
            multiple
            options={markets.map(m => m.market)}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} size='small' />
                {option}
              </li>
            )}
            renderInput={params => <TextField size='small' {...params} label='Markets' />}
            value={editableSmartFollowup.markets || []}
            selectOnFocus
            clearOnBlur
            onChange={(e, v) => setEditableSmartFollowup(prev => ({ ...prev, markets: v }))}
          />
        </Grid>

        <Grid item xs={6} sx={{ padding: '.5rem' }}>
          <Autocomplete
            options={[
              { type: 'SmartAction::Types::SendText', id: '123', data: { message_body: 'enter some text here!' } },
              {
                type: 'SmartAction::Types::DiscountQuote',
                id: '1234',
                data: { discount_amount: 10, message_body: 'enter some text here!' },
              },
              {
                type: 'SmartAction::Types::Lost',
                id: '142',
                data: { interaction_status: editableSmartFollowup.leadStatus },
              },
            ]}
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option.type === value.type}
            getOptionLabel={option => `${option.type}` || ''}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} size='small' />
                {option.type}
              </li>
            )}
            renderInput={params => <TextField size='small' {...params} label='Actions' />}
            value={editableSmartFollowup.editableAction}
            selectOnFocus
            clearOnBlur
            onChange={(e, v) => setEditableSmartFollowup(prev => ({ ...prev, editableAction: v }))}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: '.5rem' }}>
          <Autocomplete
            multiple
            options={[
              { type: 'SmartCriterion::Types::QuotePartsPresence', id: '12311', data: { has_parts: false } },
              { type: 'SmartCriterion::Types::QuoteGrandTotalRange', id: '2314', data: { max: 1000, min: 500 } },
            ]}
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option.type === value.type}
            getOptionLabel={option => `${option.type}` || ''}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} size='small' />
                {option.type}
              </li>
            )}
            renderInput={params => <TextField size='small' {...params} label='Additional Criteria' />}
            value={editableSmartFollowup.editableCriteria || []}
            selectOnFocus
            clearOnBlur
            onChange={(e, v) => setEditableSmartFollowup(prev => ({ ...prev, editableCriteria: v }))}
          />
        </Grid>

        {editableSmartFollowup.editableCriteria?.find(c => c.type === 'SmartCriterion::Types::QuotePartsPresence') && (
          <Grid item xs={4} sx={{ padding: '.5rem' }}>
            <FormControlLabel
              fullWidth
              label='Quote has parts?'
              onChange={e =>
                setEditableSmartFollowup(prev => ({
                  ...prev,
                  editableCriteria: [
                    ...prev.editableCriteria.filter(c => c.type !== 'SmartCriterion::Types::QuotePartsPresence'),
                    {
                      ...prev.editableCriteria.find(c => c.type === 'SmartCriterion::Types::QuotePartsPresence'),
                      data: {
                        has_parts: e.target.checked,
                      },
                    },
                  ],
                }))
              }
              control={
                <Switch
                  checked={
                    editableSmartFollowup.editableCriteria.find(
                      c => c.type === 'SmartCriterion::Types::QuotePartsPresence'
                    )?.data.has_parts || false
                  }
                />
              }
            />
          </Grid>
        )}

        {editableSmartFollowup.editableCriteria?.find(
          c => c.type === 'SmartCriterion::Types::QuoteGrandTotalRange'
        ) && (
          <Grid item xs={6} sx={{ padding: '.5rem', display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              fullWidth
              label='Quote grand total MIN'
              type='number'
              onChange={e =>
                setEditableSmartFollowup(prev => ({
                  ...prev,
                  editableCriteria: [
                    ...prev.editableCriteria.filter(c => c.type !== 'SmartCriterion::Types::QuoteGrandTotalRange'),
                    {
                      ...prev.editableCriteria.find(c => c.type === 'SmartCriterion::Types::QuoteGrandTotalRange'),
                      data: {
                        ...prev.editableCriteria.find(c => c.type === 'SmartCriterion::Types::QuoteGrandTotalRange')
                          .data,
                        min: +e.target.value,
                      },
                    },
                  ],
                }))
              }
              value={
                editableSmartFollowup.editableCriteria.find(
                  c => c.type === 'SmartCriterion::Types::QuoteGrandTotalRange'
                )?.data?.min || 0
              }
            />
            <TextField
              fullWidth
              label='Quote grand total MAX'
              type='number'
              onChange={e =>
                setEditableSmartFollowup(prev => ({
                  ...prev,
                  editableCriteria: [
                    ...prev.editableCriteria.filter(c => c.type !== 'SmartCriterion::Types::QuoteGrandTotalRange'),
                    {
                      ...prev.editableCriteria.find(c => c.type === 'SmartCriterion::Types::QuoteGrandTotalRange'),
                      data: {
                        ...prev.editableCriteria.find(c => c.type === 'SmartCriterion::Types::QuoteGrandTotalRange')
                          .data,
                        max: +e.target.value,
                      },
                    },
                  ],
                }))
              }
              value={
                editableSmartFollowup.editableCriteria.find(
                  c => c.type === 'SmartCriterion::Types::QuoteGrandTotalRange'
                )?.data?.max || 0
              }
            />
          </Grid>
        )}

        {editableSmartFollowup.editableAction?.type === 'SmartAction::Types::DiscountQuote' && (
          <Grid item xs={4} sx={{ padding: '.5rem' }}>
            <TextField
              fullWidth
              type='number'
              label='discount amount (percent)'
              onChange={e =>
                setEditableSmartFollowup(prev => ({
                  ...prev,
                  editableAction: {
                    ...prev.editableAction,
                    data: {
                      ...prev.editableAction.data,
                      discount_amount: +e.target.value,
                    },
                  },
                }))
              }
              value={editableSmartFollowup.editableAction?.data?.discount_amount || ''}
            />
          </Grid>
        )}

        {(editableSmartFollowup.editableAction?.type === 'SmartAction::Types::SendText' ||
          editableSmartFollowup.editableAction?.type === 'SmartAction::Types::DiscountQuote') && (
          <Grid item xs={12} sx={{ padding: '.5rem' }}>
            <MentionsInput
              className='mentions-new'
              classNames={mentionsClassNames}
              value={editableSmartFollowup.editableAction?.data?.message_body || ''}
              onChange={e =>
                setEditableSmartFollowup(prev => ({
                  ...prev,
                  editableAction: {
                    ...prev.editableAction,
                    data: {
                      ...prev.editableAction.data,
                      message_body: e.target.value,
                    },
                  },
                }))
              }
              placeholder='Add message body'
            >
              <Mention
                displayTransform={(id, display) => `${display}`}
                data={[
                  { id: '_', display: 'customer name' },
                  { id: '_', display: 'csr name' },
                  { id: '_', display: 'quote price' },
                  { id: '_', display: 'discount amount' },
                ]}
                style={{ backgroundColor: 'rgb(215 255 170)' }}
              />
            </MentionsInput>
          </Grid>
        )}

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            sx={{
              width: '100px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Switch
              checked={editableSmartFollowup.active}
              onChange={e => setEditableSmartFollowup(prev => ({ ...prev, active: e.target.checked }))}
            />
            {editableSmartFollowup.active ? 'Active' : 'Inactive'}
          </Box>
          <Button sx={{ display: 'block', maxHeight: '40px' }} onClick={handleSaveSmartFollowup} variant='contained'>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Edit
