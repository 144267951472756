import {
  Container,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Modal,
  Box,
  Dialog,
  Snackbar,
  Chip,
  Typography,
  Paper,
  useMediaQuery,
  Popover,
  List,
  ListItem,
  ListItemButton,
  Divider,
} from '@mui/material'
import { KeyboardArrowDown, Cancel, DownloadRounded, MoreVertRounded } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useQuery, gql } from '@apollo/client'
import { useEffect, useState, useContext } from 'react'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { Dollars, keysToCamel } from 'tools'
import { DateTime } from 'luxon'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import { Link } from 'react-router-dom'
import { UserContext } from 'UserStore'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { CreateATask } from 'Tasks'
import JSONPretty from 'react-json-pretty'
import { labelsList } from '../tools'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { InvoicePDF } from './InvoicePDF'
import InvoiceSelector from './Components/InvoiceSelector'

const styles = {
  photoExpandStyle: {
    border: '1px solid #ddd',
    fontSize: '14px',
    borderRadius: '0px 0px 6px 6px',
    display: 'flex',
    padding: '0.25rem 0.5rem',
    background: '#f1f1f1',
    justifyContent: 'center',
    alignItems: 'center',
  },
  photoCancelButtonStyle: {
    zIndex: 999,
    position: 'absolute',
    top: '5px',
    right: '5px',
    fontSize: '30px',
    color: '#fff',
    border: '2px solid #fff',
    borderRadius: '20px',
  },
  sectionHeaderStyle: {
    borderRadius: '6px 6px 0px 0px',
    fontWeight: 700,
    fontSize: '14px',
    padding: '0.5rem 1rem',
    background: '#bbc7d3',
    display: 'flex',
    alignItems: 'center',
  },
  sectionContainerStyle: {
    mb: '0.5rem',
    mt: '0.5rem',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '6px',
    maxWidth: '100%',
  },
  photoContainerStyle: {
    background: '#fff',
    padding: '1rem 0.5rem',
    border: '1px solid #ddd',
    borderBottom: '0',
    borderTop: '0',
    textAlign: 'center',
  },
  uploadButtonContainerStyle: {
    border: '1px solid #ddd',
    fontSize: '14px',
    borderRadius: '0px 0px 6px 6px',
    display: 'flex',
    padding: '0.25rem 0.5rem',
    background: '#f1f1f1',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '700px',
  maxHeight: '80%',
  width: '1100px',
  maxWidth: '100%',
  boxShadow: 24,
  border: '1px solid black',
}

const DEALERS_QUERY = gql`
  query BToBDealers {
    bToBDealers {
      id
      businessName
    }
  }
`

const ShowEdit = ({ statement, refetch }) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)
  const [payoutDataOpen, setPayoutDataOpen] = useState(false)

  const [actionsPopoverOpen, setActionsPopoverOpen] = useState(false)
  const [actionsPopoverAnchorEl, setActionsPopoverAnchorEl] = useState(null)

  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const [invoiceNumbers, setInvoiceNumbers] = useState('')

  const isMobile = useMediaQuery('(max-width: 600px)')

  useEffect(() => {
    const key = registerTaskTarget({
      targetType: 'BToBInvoice',
      targetId: statement.id,
    })
    return () => unregisterTaskTarget(key)
  }, [])

  const updateAttributes = attr =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_statement_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceStatementId: statement.id,
        updatedAttributes: attr,
        actorType: 'csr',
        actorId: user.id,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleClickLinkToStatement = invoiceId =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/link_b_to_b_invoice_to_statement`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: invoiceId,
        bToBInvoiceStatementId: statement.id,
        userId: user.id,
      }),
    })
      .then(res => {
        refetch()
        setInvoiceNumbers('')
      })
      .catch(() => window.alert('Error'))

  const handleClickLinkInvoiceNumbers = invoiceNumbers =>
    new Promise((resolve, reject) => {
      invoiceNumbers.split(' ').map(invoiceNumber =>
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/link_b_to_b_invoice_to_statement`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: 'invoiceId',
            bToBInvoiceNumber: invoiceNumber,
            bToBInvoiceStatementId: statement.id,
            userId: user.id,
          }),
        })
      )

      resolve(null)
    })
      .then(res => {
        refetch()
        setInvoiceNumbers('')
      })
      .catch(() => window.alert('Error'))

  const handleClickUnlinkFromStatement = invoiceId =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_b_to_b_invoice_from_statement`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: invoiceId,
        userId: user.id,
      }),
    }).then(res => refetch() || window.alert('Error'))

  const handleClickDeleteInvoice = () =>
    window.confirm('Please confirm') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_invoice_statement`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceStatementId: statement.id,
        userId: user.id,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const Photos = () => {
    const [openPhotosDrawer, setOpenPhotosDrawer] = useState(false)
    const [expandPhotoSection, setExpandPhotoSection] = useState(false)
    const [currentFullScreenPhoto, setCurrentFullScreenPhoto] = useState(null)

    const photos = statement.photos || []

    return (
      <>
        <Grid container sx={styles.sectionContainerStyle}>
          <Grid item xs={12} sx={styles.sectionHeaderStyle}>
            Photos
            {photos.length > 0 && <> &nbsp;({photos.length})</>}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              borderRadius: photos.length > 0 && photos.length < 8 ? '0px 0px 6px 6px' : '0px',
              ...styles.photoContainerStyle,
            }}
          >
            {(photos.length > 0 && (
              <Grid container spacing={2}>
                {photos.map(
                  (photo, index) =>
                    (expandPhotoSection || index < 8) && (
                      <Grid item xs={3} sm={3} md={2} key={photo.originalUrl}>
                        <Grid
                          onClick={() => setCurrentFullScreenPhoto(photo)}
                          sx={{
                            borderRadius: '3px',
                            minHeight: '75px',
                            background: `url(${photo.originalUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                          }}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            )) ||
              'No photos uploaded yet'}
          </Grid>

          {photos?.length > 8 && (
            <Grid
              item
              xs={12}
              onClick={() => setExpandPhotoSection(!expandPhotoSection)}
              sx={{
                ...styles.photoExpandStyle,
                '&:active': {
                  background: '#eee',
                },
              }}
            >
              See
              {expandPhotoSection ? ' less' : ' all'}
              <KeyboardArrowDown
                sx={{
                  transform: expandPhotoSection ? 'rotate(180deg)' : '',
                  ml: '1rem',
                  transition: 'all 0.25s ease-in-out',
                }}
              />
            </Grid>
          )}

          {photos?.length < 1 && <Grid item xs={12} sx={styles.uploadButtonContainerStyle}></Grid>}
        </Grid>

        <Modal open={!!currentFullScreenPhoto} onClose={() => setCurrentFullScreenPhoto(null)}>
          <Box sx={modalStyle}>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <Cancel sx={styles.photoCancelButtonStyle} onClick={() => setCurrentFullScreenPhoto(null)} />
              <PinchZoomPan position='center' maxScale={2}>
                <img src={currentFullScreenPhoto && currentFullScreenPhoto.originalUrl} />
              </PinchZoomPan>
            </div>
          </Box>
        </Modal>
      </>
    )
  }

  const DealerSelect = () => {
    const [selectedDealerId, setSelectedDealerId] = useState(statement.dealer.id || '')

    const { data, loading, error } = useQuery(DEALERS_QUERY)

    const handleClickSaveDealer = () => {
      const selectedDealer = data?.bToBDealers?.find(dealer => dealer.id === selectedDealerId)
      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_statement_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceStatementId: statement.id,
            updatedAttributes: {
              dealer_id: selectedDealerId,
              business_name: selectedDealer.businessName,
            },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => refetch() || window.alert('Error'))
    }

    const possibleOptions = data?.bToBDealers || []

    return (
      <Grid item xs={12} sx={{ padding: '1rem 0rem 0rem 0rem', display: 'flex' }}>
        <FormControl size='small' fullWidth>
          <InputLabel>Change Dealer</InputLabel>
          <Select value={selectedDealerId} onChange={e => setSelectedDealerId(e.target.value)} label='Change dealer'>
            {possibleOptions.map(item => (
              <MenuItem value={item.id} key={item.id}>
                {item.businessName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={handleClickSaveDealer}>save</Button>
      </Grid>
    )
  }

  const LabelSelect = () => {
    const [label, setLabel] = useState(statement?.label)

    const handleClickSaveLabel = () => {
      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_statement_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceStatementId: statement.id,
            updatedAttributes: { label: label },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
    }

    const possibleLabels = labelsList

    return (
      <Box sx={{ display: 'flex', ml: { xs: '0rem', sm: 'auto' } }}>
        <FormControl size='small' sx={{ minWidth: '200px' }}>
          <InputLabel>Select a label</InputLabel>
          <Select value={label || ''} onChange={e => setLabel(e.target.value)} label='Select a label'>
            <MenuItem value=''>None</MenuItem>
            {possibleLabels.map(item => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={handleClickSaveLabel}>save</Button>
      </Box>
    )
  }

  const StartTimeTextField = () => {
    const [date, setDate] = useState(
      statement?.periodStart ? DateTime.fromISO(statement?.periodStart).toFormat('yyyy-MM-dd') : ''
    )

    const handleClickSaveLabel = () => {
      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_statement_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceStatementId: statement.id,
            updatedAttributes: { period_start: DateTime.fromFormat(date, 'yyyy-MM-dd').toISO() },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
    }

    return (
      <Box sx={{ display: 'flex', ml: { xs: '0rem', sm: 'auto' } }}>
        <TextField type='date' value={date || ''} onChange={e => setDate(e.target.value)} label='Period start' />
        <Button onClick={handleClickSaveLabel}>save</Button>
      </Box>
    )
  }

  const EndTimeTextField = () => {
    const [date, setDate] = useState(
      statement?.periodEnd ? DateTime.fromISO(statement?.periodEnd).toFormat('yyyy-MM-dd') : ''
    )
    const handleClickSaveLabel = () => {
      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_statement_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceStatementId: statement.id,
            updatedAttributes: { period_end: DateTime.fromFormat(date, 'yyyy-MM-dd').toISO() },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
    }

    return (
      <Box sx={{ display: 'flex', ml: { xs: '0rem', sm: 'auto' } }}>
        <TextField type='date' value={date || ''} onChange={e => setDate(e.target.value)} label='Period start' />
        <Button onClick={handleClickSaveLabel}>save</Button>
      </Box>
    )
  }

  return (
    <Container
      maxWidth='xl'
      disableGutters
      sx={{
        maxWidth: '95vw',
        background: '#F4F6F9',
        padding: {
          xs: '.5rem',
          sm: '1rem',
        },
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <CreateATask targetType='BToBInvoiceStatement' targetId={statement.id} />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
          <Typography variant='h4'>{statement?.businessName}</Typography>
          <LabelSelect />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ opacity: 0.75, fontSize: '14px' }}>
            Created:{' '}
            <Link to={`/b-to-b-invoices/${statement.id}`}>
              {DateTime.fromISO(statement.createdAt).toLocaleString()}
            </Link>
          </span>
        </Grid>

        <Grid item xs={12} sx={{ pt: '.5rem' }}>
          {statement.payoutId && (
            <Chip
              size='small'
              label={'Paid to Technician'}
              sx={{
                color: '#111',
                backgroundColor: 'orange',
                fontWeight: 700,
              }}
            />
          )}

          {statement.fullyPaidAt && (
            <Chip
              size='small'
              label={`PAID AT ${DateTime.fromISO(statement.fullyPaidAt).toFormat('D hh:mm')}`}
              sx={{
                color: '#111',
                backgroundColor: 'palegreen',
                fontWeight: 700,
                ml: '.5rem',
              }}
            />
          )}
          {statement.completedAt && (
            <Chip
              size='small'
              sx={{
                color: '#111',
                backgroundColor: 'palegreen',
                fontWeight: 700,
                ml: '.5rem',
              }}
              label={`COMPLETED ${DateTime.fromISO(statement.completedAt).toFormat('D hh:mm')}`}
            />
          )}

          {statement.acceptedAt && (
            <Chip
              size='small'
              sx={{
                color: '#111',
                backgroundColor: 'palegreen',
                fontWeight: 700,
                ml: '.5rem',
              }}
              label={`ACCEPTED ${DateTime.fromISO(statement.acceptedAt).toFormat('D hh:mm')}`}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={[
              {
                mt: '.5rem',
                display: 'flex',
                background: '#fff',
                alignItems: 'center',
                padding: '.5rem',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                mb: '.5rem',
                border: '2px solid #a1cff7',
                borderRadius: '12px',
              },
            ]}
          >
            <Box
              sx={[
                {
                  display: 'flex',
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                  alignItems: {
                    xs: '',
                    sm: 'center',
                  },
                  width: '100%',
                },
                isMobile && {
                  button: {
                    mb: '.5rem',
                  },
                },
              ]}
            >
              {user.roles.includes('developer') && (
                <Button
                  fullWidth={isMobile}
                  size={isMobile ? 'large' : 'small'}
                  variant='outlined'
                  onClick={() => setPayoutDataOpen(!payoutDataOpen)}
                >
                  {payoutDataOpen ? 'hide' : 'view'} full calculations
                </Button>
              )}

              {statement && (
                <Button
                  sx={{ ml: 'auto', mr: '.5rem' }}
                  fullWidth={isMobile}
                  size={isMobile ? 'large' : 'small'}
                  disableElevation
                  variant='contained'
                >
                  <DownloadRounded sx={{ mr: '.25rem', fontSize: '1rem' }} />
                  <PDFDownloadLink
                    style={{ color: 'unset', textDecoration: 'none' }}
                    document={<InvoicePDF statement={keysToCamel(statement)} />}
                    fileName={`${statement.businessName} - ${DateTime.fromISO(statement.completedAt).toFormat(
                      'MM-dd-yyyy'
                    )} -  ${statement.statementNumber}`}
                  >
                    {({ blob, url, loading, error }) => {
                      if (error) return JSON.stringify(error)
                      return loading ? 'Generating PDF...' : 'Download as PDF'
                    }}
                  </PDFDownloadLink>
                </Button>
              )}

              <Button
                onClick={e => {
                  setActionsPopoverAnchorEl(e.target)
                  setActionsPopoverOpen(true)
                }}
                sx={{ mr: '0rem' }}
              >
                {isMobile && 'Actions'} <MoreVertRounded />
              </Button>

              <Snackbar
                severity='success'
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={e => setSnackbarOpen(false)}
                message='Link Copied!'
              />

              <Popover
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                anchorEl={actionsPopoverAnchorEl}
                open={actionsPopoverOpen}
                onClose={() => setActionsPopoverOpen(false)}
              >
                <List>
                  {statement.completedAt && (
                    <ListItemButton
                      fullWidth={isMobile}
                      onClick={() =>
                        window.confirm('are you sure you want to mark this as incomplete?') &&
                        updateAttributes({ completed_at: null })
                      }
                    >
                      Unmark complete
                    </ListItemButton>
                  )}

                  {!statement.completedAt && (
                    <ListItemButton
                      fullWidth={isMobile}
                      onClick={() =>
                        window.confirm('are you sure you want to mark this as complete?') &&
                        updateAttributes({ completed_at: DateTime.now().toISO() })
                      }
                    >
                      Mark complete
                    </ListItemButton>
                  )}

                  <Divider />

                  {statement.completedAt && !statement.fullyPaidAt && (
                    <ListItemButton
                      fullWidth={isMobile}
                      onClick={() =>
                        window.confirm('are you sure you want to mark this as paid?') &&
                        updateAttributes({ fully_paid_at: DateTime.now().toISO() })
                      }
                    >
                      Mark as paid
                    </ListItemButton>
                  )}

                  <ListItemButton
                    fullWidth={isMobile}
                    size={isMobile ? 'large' : 'small'}
                    disabled={statement.payoutId || statement.deletedAt}
                    variant='outlined'
                    color='error'
                    onClick={handleClickDeleteInvoice}
                    startIcon={<DeleteIcon />}
                  >
                    Delete{statement.deletedAt && 'd'}
                  </ListItemButton>
                </List>
              </Popover>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {payoutDataOpen && (
            <Dialog onClose={() => setPayoutDataOpen(false)} open={payoutDataOpen}>
              <JSONPretty style={{ fontSize: '12px' }} data={statement} />
            </Dialog>
          )}
        </Grid>

        <Grid item xs={12} sm={4} sx={{ display: 'flex' }}>
          <TextField
            label='add invoices by number'
            placeholder='invoice number'
            value={invoiceNumbers}
            onChange={e => setInvoiceNumbers(e.target.value)}
          />
          <Button disabled={invoiceNumbers.length < 5} onClick={() => handleClickLinkInvoiceNumbers(invoiceNumbers)}>
            Add
          </Button>
          <InvoiceSelector
            handleClickLinkInvoiceNumbers={handleClickLinkInvoiceNumbers}
            handleClickLinkToStatement={handleClickLinkToStatement}
          />
        </Grid>

        <Grid item sx={12} sm={4}>
          <StartTimeTextField />
        </Grid>

        <Grid item sx={12} sm={4}>
          <EndTimeTextField />
        </Grid>

        <Grid item sm={5} xs={12} sx={{ padding: '.5rem', border: '1px solid #f1f1f1' }}>
          <Grid item xs={12} sx={styles.sectionHeaderStyle}>
            Details
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ padding: '1rem' }}>
              <span>Grand Total</span>
              <Typography variant='h2'>
                <Dollars value={statement.grandTotal} />
              </Typography>
            </Paper>
          </Grid>
          <Paper sx={{ padding: '.5rem' }}>
            <Grid
              container
              sx={{
                background: '#fff',
                border: '2px solid lightsteelblue',
                borderRadius: '12px',
                padding: '0rem .5rem',
                mb: '1rem',
              }}
            >
              <DealerSelect />
            </Grid>
            <Photos />
          </Paper>
        </Grid>

        <Grid item sm={5} xs={12} sx={{ padding: '.5rem', border: '1px solid #f1f1f1' }}>
          <Grid item xs={12} sx={styles.sectionHeaderStyle}>
            Invoices
          </Grid>
          <Paper sx={{ padding: '.5rem' }}>
            <ul>
              {statement.invoices.map(invoice => (
                <li key={invoice.id}>
                  <Dollars value={invoice.grandTotal} /> -- {invoice.year} {invoice.make} {invoice.model}
                  <Chip
                    sx={{ ml: '.5rem' }}
                    size='small'
                    label={DateTime.fromISO(invoice.createdAt).toFormat('yyyy-MM-dd t')}
                  />
                  <button onClick={() => handleClickUnlinkFromStatement(invoice.id)}>unlink</button>
                </li>
              ))}
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ShowEdit
