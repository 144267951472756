import {
  Box,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,
  Paper,
} from '@mui/material'
import { useJobApplications } from 'hooks/recruiting/jobApplications/useJobApplications'
import { useHistory } from 'react-router-dom'
import { DateRangeHeadCell } from 'BToB/Invoices/BToBInvoice/Components/DateRangeHeadCell'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { SearchTextField } from 'Recruiting/components/SearchTextField'

const JobApplicationTable = ({ jobListingId }: any) => {
  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const {
    jobApplications,
    loading,
    filter,
    setFilter,
    setCursorCeiling,
    limit,
    setLimit,
    order,
    setOrder,
    search,
    setSearch,
    totalCount,
    endCursor,
    fetchMore,
  } = useJobApplications(jobListingId ? { job_listing_id: { eq: jobListingId } } : null)
  const history = useHistory()

  const handleChangePage = (event: any, wantedPage: any) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: limit,
          after: endCursor,
        },
      })
    }
  }

  const handleChangeRowsPerPage = (event: any) => {
    setLimit(event.target.value)
    setPage(0)
    setCursorCeiling('')
  }

  useEffect(() => {
    jobListingId && setFilter({ job_listing_id: { eq: jobListingId } })
  }, [jobListingId])

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <DateRangeHeadCell
                name='created_at'
                filter={filter}
                setFilter={setFilter}
                order={order}
                setOrder={setOrder}
              />
              <TableCell>Job Title</TableCell>
              <TableCell>Applicant Name</TableCell>
              <TableCell>
                <SearchTextField model='JobApplication::Cache' search={search} field='status' setSearch={setSearch} />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {jobApplications
              ?.slice(page * limit, page * limit + limit)
              .map(({ id, createdAt, jobListing, applicant, status }: any) => {
                return (
                  <TableRow
                    sx={[
                      status === 'Marked for Interview' && { background: 'aliceblue' },
                      status === 'Rejected' && { background: '#fff9f9', '&:hover': { background: '#fff9f9' } },
                      { cursor: 'pointer', p: 1, '&:hover': { background: '#f9f9f9' } },
                    ]}
                    key={id}
                    onClick={() => history.push(`/applications/${id}`)}
                  >
                    <TableCell>{DateTime.fromISO(createdAt).toFormat('DD t')}</TableCell>
                    <TableCell>{jobListing?.jobTitle}</TableCell>
                    <TableCell>{applicant?.fullName}</TableCell>
                    <TableCell>{status?.charAt(0)?.toUpperCase() + status?.slice(1)}</TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {loading && (
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

export default JobApplicationTable
