import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { FormControlLabel, Grid, Checkbox, FormControl, Select, MenuItem, TextField, Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import ReplyIcon from '@mui/icons-material/Reply'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { UserContext } from 'UserStore'
import ConfirmDialog from 'components/ConfirmDialog'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { PriceInput, diff } from 'tools'

const useStyles = makeStyles(theme => ({
  editableGrid: {
    '& .MuiInput-root': {
      paddingBottom: '0',
    },
    '& .MuiInput-root::before': {
      borderWidth: '0',
    },
    '& .MuiInput-root input': {
      borderWidth: '0',
      paddingBottom: '0',
    },
    '& .MuiInput-root textarea': {
      borderWidth: '0',
      paddingBottom: '0',
      marginTop: '5px',
    },
    '& .MuiSelect-select': {
      paddingBottom: 0,
    },
    '& .MuiSelect-icon': {
      visibility: 'hidden',
    },
    '& .MuiInput-root:hover .MuiSelect-icon': {
      visibility: 'visible',
    },
    '& input[name="cost"], & input[name="price"]': {
      textAlign: 'right',
    },
    '& .MuiInput-root::before:hover': {
      borderWidth: '1px',
      borderColor: '#eaeae8',
    },
  },
  fontRed: {
    color: 'red',
  },
}))

const CenterAlignedGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // fontSize: '15px'
}))

const InlinePartEditorGrid = ({
  quote,
  part,
  currentEditingPart,
  setCurrentEditingPart,
  editingDisabled,
  refetchQuote,
}) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [confirmRemovePartDialogOpen, setConfirmRemovePartDialogOpen] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const [changed, setChanged] = useState(false)
  const classes = useStyles()

  const unsetEditingPart = () => {
    if (currentEditingPart === formik.values.id) {
      setCurrentEditingPart('')
    }
  }

  const openPartDetails = id => window.open(`/parts/${id}`)

  const handleRemovePart = partId => {
    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/remove_part_from_quote`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        quoteId: quote.id,
        partId: partId,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .then(res => setTimeout(() => refetchQuote(), 250))
      .finally(() => unsetEditingPart())
  }

  const handleClickSave = part => {
    const payload = {
      ...diff(formik.initialValues, part),
      ...diff(part, formik.initialValues),
    }

    if (Object.keys(payload).length === 0) {
      return
    }

    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_part_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        partId: part.id,
        updatedAttributes: payload,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .then(res => refetchQuote())
      .finally(() => {
        setWaiting(false)
        formik.resetForm({ values: part })
      })
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('required'),
    number: Yup.string().required('required'),
    cost: Yup.number().nullable(),
    price: Yup.number(),
    isPaid: Yup.boolean().nullable(),
    prePaymentRequired: Yup.boolean(),
  })

  const formik = useFormik({
    initialValues: part,
    validationSchema: validationSchema,
    onSubmit: handleClickSave,
  })

  useEffect(() => {
    const payload = {
      ...diff(formik.values, formik.initialValues),
    }

    if (Object.keys(payload).length === 0) {
      setChanged(false)

      // This part was manually changed back to it's original value and is no longer
      // in an editing state so we need to notify the parent component
      unsetEditingPart()
      return
    }
    setChanged(true)
    setCurrentEditingPart(part.id)
  }, [formik.values])

  useEffect(() => {
    formik.resetForm({ values: part })
    setWaiting(false)
    setChanged(false)
  }, [part])

  useEffect(() => {
    if (changed && currentEditingPart !== part.id) {
      formik.resetForm({ values: part })
    }
  }, [currentEditingPart])

  useEffect(() => {
    if (editingDisabled) {
      formik.resetForm({ values: part })
    }
  }, [editingDisabled])

  const disabled = waiting || editingDisabled

  return (
    <>
      <Grid container columnSpacing={1} sx={{ px: 1 }} className={classes.editableGrid}>
        <Grid item xs={3}>
          <TextField
            fullWidth
            multiline
            size='small'
            name='name'
            onChange={formik.handleChange}
            type='text'
            value={formik.values.name}
            variant='standard'
            disabled={disabled}
          />
        </Grid>
        <CenterAlignedGridItem item xs={4}>
          <TextField
            required
            fullWidth
            variant='standard'
            size='small'
            name='number'
            value={formik.values.number}
            error={formik.touched.number && Boolean(formik.errors.number)}
            onChange={formik.handleChange}
            disabled={disabled}
          />
        </CenterAlignedGridItem>
        <CenterAlignedGridItem item xs={2} align='right'>
          <TextField
            required
            fullWidth
            variant='standard'
            size='small'
            name='cost'
            value={formik.values.cost}
            error={formik.touched.cost && Boolean(formik.errors.cost)}
            onChange={event => {
              const numberValue = Number(event.target.value)
              formik.setFieldValue('cost', numberValue)
            }}
            InputProps={{
              inputComponent: PriceInput,
            }}
            disabled={disabled}
          />
        </CenterAlignedGridItem>
        <CenterAlignedGridItem item xs={2} align='right'>
          <TextField
            required
            fullWidth
            variant='standard'
            size='small'
            name='price'
            value={formik.values.price}
            error={formik.touched.price && Boolean(formik.errors.price)}
            onChange={event => {
              const numberValue = Number(event.target.value)
              formik.setFieldValue('price]', numberValue)
            }}
            InputProps={{
              inputComponent: PriceInput,
            }}
            disabled={disabled}
          />
        </CenterAlignedGridItem>
        <CenterAlignedGridItem item xs={1} sx={{ justifyContent: 'flex-end' }}>
          <IconButton
            onClick={() => setConfirmRemovePartDialogOpen(part.id)}
            sx={{ p: 0.25 }}
            disabled={disabled}
            id='delete-part-button'
          >
            <DeleteIcon fontSize='small' />
          </IconButton>
        </CenterAlignedGridItem>
      </Grid>
      <Grid container columnSpacing={1} sx={{ px: 1 }} className={classes.editableGrid}>
        <CenterAlignedGridItem item xs={3} sx={{ borderBottom: '1px dotted lightgray' }}>
          <FormControl required fullWidth>
            <Select
              fullWidth
              variant='standard'
              size='small'
              name='type'
              value={formik.values.type}
              error={formik.touched.type && Boolean(formik.errors.type)}
              onChange={event => {
                formik.handleChange(event)
                if (event.target.value === 'oem') {
                  formik.setFieldValue('prePaymentRequired', true)
                }
              }}
              disabled={disabled}
            >
              <MenuItem value='oem'>OEM</MenuItem>
              <MenuItem value='aftermarket'>Aftermarket</MenuItem>
            </Select>
          </FormControl>
        </CenterAlignedGridItem>
        <CenterAlignedGridItem item xs={4} sx={{ borderBottom: '1px dotted lightgray' }}>
          <FormControlLabel
            sx={{ m: 0 }}
            className={formik.values.prePaymentRequired && classes.fontRed}
            control={
              <Checkbox
                name='prePaymentRequired'
                onChange={formik.handleChange}
                disabled={disabled}
                checked={formik.values.prePaymentRequired}
              />
            }
            label='PRE-PAY'
          />
        </CenterAlignedGridItem>

        <CenterAlignedGridItem
          item
          direction='row'
          justifyContent='flex-end'
          alignItems='flex-end'
          sx={{ display: 'flex', borderBottom: '1px dotted lightgray' }}
          xs={5}
        >
          {changed && (
            <>
              <Box sx={{ m: 0 }}>
                <button
                  onClick={() => {
                    if (waiting) {
                      return
                    }
                    formik.resetForm({ values: part })
                    setCurrentEditingPart('')
                  }}
                  disabled={disabled}
                >
                  reset
                </button>
              </Box>
              <Box sx={{ ml: 2, mr: 1 }}>
                {'> '}
                <button onClick={formik.handleSubmit} disabled={disabled}>
                  save
                </button>
                {' <'}
              </Box>
            </>
          )}
          {!changed && (
            <IconButton onClick={() => openPartDetails(part.id)} sx={{ p: 0.25 }} disabled={disabled}>
              <ReplyIcon title='open part details' style={{ transform: 'scaleX(-1)' }} />
            </IconButton>
          )}
        </CenterAlignedGridItem>
      </Grid>

      <ConfirmDialog
        open={confirmRemovePartDialogOpen}
        title='Confirm Action'
        heading='Are you sure you want to remove this part from the quote?'
        text='This action cannot be undone.'
        okText='Yes'
        cancelText='No'
        onClose={res => {
          setConfirmRemovePartDialogOpen(false)
          if (res) {
            handleRemovePart(confirmRemovePartDialogOpen)
          }
        }}
      />
    </>
  )
}

export default InlinePartEditorGrid
