import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  Chip,
  FormControlLabel,
} from '@mui/material'
import { GlobalContext } from '../GlobalStore'

const UsersIndex = () => {
  const [global] = useContext(GlobalContext)
  const [showInactive, setShowInactive] = useState(false)
  const history = useHistory()

  const users = global?.users?.slice().sort((a, b) => (a.firstName > b.firstName ? 1 : -1)) || []

  const usersToShow = showInactive ? users : users.filter(user => user.status !== 'inactive')

  return (
    <Box>
      <FormControlLabel
        control={<Switch checked={showInactive} onChange={e => setShowInactive(e.target.checked)} />}
        label='show inactive'
      />
      <Box sx={{ display: 'flex' }}>
        <Button onClick={() => history.push('/users/new')} variant='contained' color='primary'>
          Create New User
        </Button>
      </Box>
      <Table size='small' sx={{ mt: '1rem' }}>
        <TableHead>
          <TableRow>
            <TableCell> first name </TableCell>
            <TableCell> last name </TableCell>
            <TableCell align='right'> Roles </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersToShow.map(user => {
            const userIsActive = user.status !== 'inactive'

            return (
              <TableRow
                key={user.id}
                sx={{
                  '&:hover': { background: '#eae6e6' },
                  background: userIsActive ? '#fff' : '#dfdfdf',
                  cursor: 'pointer',
                }}
                onClick={() => window.open(`/users/${user.id}/profile`)}
              >
                <TableCell>
                  {user.firstName} <br />
                  {showInactive && (
                    <>
                      {userIsActive ? (
                        <b style={{ color: '#00AA71' }}>active</b>
                      ) : (
                        <b style={{ color: '#FF515B' }}>inactive</b>
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell align='right'>
                  {user.roles.map(role => (
                    <Chip
                      sx={{ mr: '.5rem', mb: '.25rem', mt: '.25rem' }}
                      size='small'
                      key={user.id + role}
                      label={role}
                    />
                  ))}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}

export default UsersIndex
