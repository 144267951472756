import { createContext, useContext, useState } from 'react'
import { useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import { ReadCacheChannelContext } from './ReadCacheChannelStore'
import { v4 as uuid } from 'uuid'

export const RefetchRegistry = createContext()

const RefetchRegistryProvider = ({ children }) => {
  const readCacheChannel = useContext(ReadCacheChannelContext)
  const [refetches, setRefetches] = useState([])

  useEventHandler(readCacheChannel, 'object_type_inserted', ({ type }) => {
    refetches
      .forEach(([key, types, refetch]) =>
        types.includes(type) && setTimeout(refetch, 250)
      )
  })

  const registerRefetch = ({ types, refetch }) => {
    const key = uuid()
    setRefetches(prev => [[key, types, refetch], ...prev.filter(([_key]) => _key !== key)])
    return key
  }

  const unregisterRefetch = key =>
    setRefetches(prev =>
      prev.filter(([_key]) => _key !== key)
    )

  return (
    <RefetchRegistry.Provider
      value={[registerRefetch, unregisterRefetch]}
    >
      {children}
    </RefetchRegistry.Provider>
  )
}

export default RefetchRegistryProvider
