import { Box, Tabs, Tab } from '@mui/material'
import { useState, useEffect } from 'react'
import CSRActivity from './CSRActivity'
import CallBackRequestData from './CallbackRequests'

const PhoneActivity = () => {
  const [tab, setTab] = useState(
    localStorage.getItem('phoneActivityTab') || 'phone'
  )
  useEffect(() => localStorage.setItem('phoneActivityTab', tab), [tab])

  const handleChangeTab = (_, newValue) => {
    setTab(newValue)
  }

  return (
    <Box sx={{ padding: '1rem 0rem' }}>
      <Tabs color='secondary' value={tab} onChange={handleChangeTab}>
        <Tab value='callbacks' label='Callback Requests' />
        <Tab value='phone' label='CSR Activity' />
      </Tabs>
      {tab === 'callbacks' && <CallBackRequestData />}
      {tab === 'phone' && <CSRActivity />}
    </Box>
  )
}

export default PhoneActivity
