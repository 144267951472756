import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import axios from 'axios'
import { UserContext } from 'UserStore'
import { GlobalContext } from 'GlobalStore'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { MentionsInput, Mention } from 'react-mentions'
import mentionsClassNames from './mentions.module.css'
import Linkify from 'linkifyjs/react'
import { DateTime } from 'luxon'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const TECH_NOTES_QUERY = gql`
  query techNotes($leadId: ID, $quoteId: ID, $jobId: ID, $partId: ID, $partIds: [ID!]) {
    techNotes(leadId: $leadId, quoteId: $quoteId, jobId: $jobId, partId: $partId, partIds: $partIds) {
      id
      userType
      userId
      parentType
      parentId
      createdAt
      body
      replyToId
      resourcePath
    }
  }
`

const NotesStyles = makeStyles(theme => ({
  notes: {
    margin: '0.5em',
  },
  note_user: {
    marginRight: '1em',
  },
  new_note_input: {
    padding: '0.75em',
  },
  scrollContainer: {
    maxHeight: '300px',
    overflowY: 'scroll',
    margin: '0',
    padding: '12px 0',
  },
}))

const mentionableUsers = users =>
  users.map(user => ({ id: user.id, display: `${user.firstName} ${user.lastName.charAt(0)}.` }))

const TechNotes = ({
  thisObjectOnly,
  headerText,
  forceLinkTheParent,
  noInput,
  parentType,
  parentId,
  leadId,
  quoteId,
  jobId,
  partId,
  partIds,
  quote,
}) => {
  const classes = NotesStyles()
  const [user] = useContext(UserContext)
  const [newNote, setNewNote] = useState('')
  const [global] = useContext(GlobalContext)

  const onlyForParentObject = (typeof thisObjectOnly === 'undefined' && false) || !!thisObjectOnly

  const { loading, error, data, refetch } = useQuery(TECH_NOTES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      leadId,
      quoteId,
      jobId,
      partId,
      partIds,
    },
  })

  if (loading) return <div>Loading</div>
  if (error) return <div>Error</div>

  const notesForThisParent = data.techNotes
    .filter(note => note.parentId === parentId)
    .sort((a, b) => (Date.parse(a.createdAt) > Date.parse(b.createdAt) ? -1 : 1))
  const otherNotes = onlyForParentObject
    ? []
    : data.techNotes
        .filter(note => note.parentId !== parentId)
        .sort((a, b) => (Date.parse(a.createdAt) > Date.parse(b.createdAt) ? -1 : 1))

  const sortedTechNotes = notesForThisParent
    .concat(otherNotes)
    .sort((a, b) => (DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt) ? 1 : -1))

  const handleClickSubmitNote = () => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_COMMAND_ROOT + '/api/create_tech_note',
      data: {
        user_id: user.id,
        user_type: 'csr',
        parent_type: parentType,
        parent_id: parentId,
        body: newNote,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
      .then(res => {
        setNewNote('')
        refetch()
      })
      .catch(error => {
        window.alert('error')
      })
  }

  const handleNewNoteChanged = evt => {
    setNewNote(evt.target.value)
  }

  return (
    <>
      <Typography color='primary' variant='h6' style={{ fontSize: '1.2em' }}>
        {headerText || 'Technician Notes'}
      </Typography>

      <div className={classes.notes + ' ' + classes.scrollContainer}>
        {sortedTechNotes.map(note => (
          <CurrentNote
            forceLinkTheParent={forceLinkTheParent}
            key={note.id}
            note={note}
            parentType={parentType}
            parentId={parentId}
            refetch={refetch}
          />
        ))}
      </div>

      {!noInput && (
        <>
          <MentionsInput
            className='mentions-new'
            cy-data='tech-note-input'
            style={{ marginBottom: '10px' }}
            classNames={mentionsClassNames}
            value={newNote}
            onChange={handleNewNoteChanged}
            placeholder='Add new note'
          >
            <Mention
              displayTransform={(id, display) => `@${display}`}
              style={{ backgroundColor: 'rgb(215 255 170)' }}
            />
          </MentionsInput>

          <Button cy-data='tech-note-input-submit' onClick={handleClickSubmitNote} variant='contained' color='primary'>
            Submit
          </Button>
        </>
      )}
    </>
  )
}

const CurrentNoteStyles = makeStyles(theme => ({
  user_and_note: {
    flexGrow: 1,
    width: '100%',
    marginBottom: '1.5em',
  },
  note_user: {
    marginRight: '1em',
  },
  reply: {
    paddingLeft: '3em',
  },
  current_note: {
    width: '100%',
    height: 'auto',
  },
}))
const CurrentNote = ({ forceLinkTheParent, note, parentType, parentId, refetch }) => {
  const classes = CurrentNoteStyles()
  const [clickedReply, setClickedReply] = useState(false)
  const [clickedReplyAll, setClickedReplyAll] = useState(false)
  const [global] = useContext(GlobalContext)
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const handleClickCancel = () => {
    setClickedReply(false)
    setClickedReplyAll(false)
  }

  const handleClickDelete = () => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_COMMAND_ROOT + '/api/delete_tech_note',
      data: {
        note_id: note.id,
        parent_id: parentId,
        parent_type: parentType,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
      .then(response => {
        refetch()
      })
      .catch(err => {
        throw Error('bad range?')
      })
  }

  const fromUser =
    (note.userType === 'csr' && user.id === note.userId && { name: 'me' }) ||
    (note.userType === 'csr' && global.users.find(user => user.id === note.userId)) ||
    (note.userType === 'tech' && global.technicians.find(tech => tech.id === note.userId))

  return (
    <>
      <div className={classes.user_and_note}>
        <div className={classes.note_user}>
          <b>{`${fromUser.name || fromUser.firstName} ${fromUser.lastName || ''}`}</b>{' '}
          <small style={{ color: 'gray' }}>{DateTime.fromISO(note.createdAt).toFormat('yyyy-LL-dd h:mm a ZZZZ')}</small>
          &nbsp;
          <small>
            {((forceLinkTheParent || note.parentId !== parentId) && (
              <>
                on the <Link to={note.resourcePath}>{note.parentType}</Link>
              </>
            )) ||
              `on this ${parentType}`}
          </small>
          {(note.userId === user.id || user.roles.includes('admin')) && (
            <Button
              size='small'
              onClick={() =>
                window.confirm('are you sure you want to delete this? this cannot be undone') && handleClickDelete()
              }
              color='error'
            >
              delete
            </Button>
          )}
        </div>

        <div className={classes.note_and_reply_button}>
          <Linkify>
            <div style={{ padding: '5px 0 0 25px', whiteSpace: 'pre-wrap' }}>{note.body}</div>
          </Linkify>
        </div>
      </div>
    </>
  )
}

export default TechNotes
