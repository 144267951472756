import { Link } from 'react-router-dom'
import { keysToCamel } from 'tools'

const GlobalSearchResults = ({ results }) => {
  const sorted = keysToCamel([...results]).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)

  return (
    <>
      (☞ ͡° ͜ʖ ͡°)☞  (☞ ͡° ͜ʖ ͡°)☞
      (☞ ͡° ͜ʖ ͡°)☞  (☞ ͡° ͜ʖ ͡°)☞
      (☞ ͡° ͜ʖ ͡°)☞
      <br />
      <div>
        {sorted.map(result =>
          <div key={result.id}>
            <Link target='_blank' key={result.id} to={`/leads/${result.id}`}>
              {result.id}
            </Link>
            ,&nbsp; {result.name}
            ,&nbsp; {result.email}
            ,&nbsp; {result.phone}
            ,&nbsp; {result.createdAt}
          </div>
        )}
      </div>
    </>
  )
}

export default GlobalSearchResults
