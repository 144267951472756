import { useContext, useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { UserContext } from 'UserStore'
import { Button, Collapse, TextField } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

export const CreateNewCatalogItemRow = ({ refetch }: { refetch: any }) => {
  const [user] = useContext(UserContext)
  const [name, setName] = useState('')

  const bearerTokenHeaders = useBearerTokenHeaders()

  const showCreateButton = name !== ''

  const handleCreateNewCatalogItem = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_part_catalog_item`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        created_by_user_id: user.id,
        name: name,
      }),
    }).then(res => {
      if (res) {
        refetch()
        setName('')
      } else {
      }
    })
  }

  const handleSaveAttribute = (name: string, attribute: string) => {
    window.confirm(`are you sure you want to update the ${name} to ${attribute}?`) &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_category_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          updated_attributes: {
            [name]: attribute,
          },
        }),
      }).then(res => {
        if (res) {
          refetch()
        } else {
          console.log('ERROR!')
        }
      })
  }

  return (
    <TableRow sx={{ background: '#f0fff1', position: 'sticky', top: '2.25rem', zIndex: 1 }}>
      <TableCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <TextField
          // @ts-ignore
          onKeyDown={e => e.code === 'Enter' && handleSaveAttribute('name', e.target.value)}
          placeholder='enter a new line item type (ex. Extra Large Damage + Paint)'
          fullWidth
          size='small'
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Collapse sx={{ height: '32px ' }} in={showCreateButton} orientation='horizontal'>
          <Button onClick={handleCreateNewCatalogItem} sx={{ margin: '0rem .5rem' }} size='small' variant='contained'>
            Create
          </Button>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
