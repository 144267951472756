import { useQuery, gql } from '@apollo/client'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Skeleton } from '@mui/material'
import { CreateNewLineItemPanelRow } from './NewPanelRow'
import { PanelRow } from './PanelRow'

const DEALERS_QUERY = gql`
  query BToBPanels {
    bToBPanels {
      id
      name
      side
      panel
      type
      panelGroupId
      inheritPrice
      isCustom
      panelGroup {
        id
        name
      }
    }
  }
`

const LINE_ITEM_CATEGORIES = gql`
  query bToBPanelGroups {
    bToBPanelGroups {
      id
      name
    }
  }
`

export const LineItemPanelsTable = () => {
  const { loading, error, data, refetch } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: categoriesData,
  } = useQuery(LINE_ITEM_CATEGORIES, {
    notifyOnNetworkStatusChange: true,
  })

  if (error || categoriesError) return <div>Error!</div>

  const panels = data?.bToBPanels || []
  const panelGroups = categoriesData?.bToBPanelGroups

  return (
    <TableContainer component={Paper}>
      <Table size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>Panel</TableCell>
            <TableCell>Side</TableCell>
            <TableCell>Category</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <CreateNewLineItemPanelRow refetch={refetch} />
          {/* @ts-ignore */}
          {(loading || categoriesLoading) && <TableLoadingSkeleton />}
          {!loading &&
            !categoriesLoading &&
            panels.map((panel: any) => <PanelRow refetch={refetch} panelGroups={panelGroups} panel={panel} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TableLoadingSkeleton = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map(item => (
    <TableRow key={item}>
      {[1, 2, 3].map(inner => (
        <TableCell sx={{ padding: '.25rem' }} key={inner}>
          <Skeleton
            sx={{
              minWidth: '100%',
              minHeight: '28px',
              transform: 'scale(1)',
              background: item % 2 !== 0 ? '#0089CD1a' : '#f1f1f1',
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  ))
}
