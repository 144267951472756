import { createContext, useEffect, useState, useContext } from 'react'
import { sendMessage, useEventHandler, useChannel } from '@ericlathrop/phoenix-js-react-hooks'
import { UserContext } from 'UserStore'
import { v4 as uuid } from 'uuid'
import { useLocation } from 'react-router-dom'

export const TaskTargetRegistry = createContext()

const TaskTargetRegistryProvider = ({ children }) => {
  const [user] = useContext(UserContext)
  const location = useLocation()

  const allChannel = useChannel('tasks:registry', undefined, () => {})
  const userChannel = useChannel(`tasks:registry:${user.id}`, undefined, () => {})
  const [tasks, setTasks] = useState([])
  const [registry, setRegistry] = useState([])
  // [
  //   { key, targetType, targetId }
  // ]

  const taskRoles = (localStorage.getItem('tasksRoles') && JSON.parse(localStorage.getItem('tasksRoles'))) || []

  const registryFilter = task =>
    registry.find(entry => entry.targetType === task.targetType && entry.targetId === task.targetId)

  const { pathname } = location

  useEventHandler(
    allChannel,
    'task_updated',
    ({ task }) =>
      tasks.find(_task => _task.id === task.id) &&
      pathname !== '/payroll' &&
      setTasks(_tasks => _tasks.map(_task => (_task.id === task.id ? task : _task)))
  )

  useEventHandler(
    allChannel,
    'task_created',
    ({ task }) =>
      [task].filter(registryFilter).length > 0 && pathname !== '/payroll' && setTasks(_tasks => [task, ..._tasks])
  )

  const registerTaskTarget = ({ targetType, targetId }) => {
    const key = uuid()
    setTimeout(() => sendMessage(userChannel, 'fetchForTarget', { userId: user.id, targetType, targetId }), 200)
    pathname !== '/payroll' && setRegistry(prev => [{ key, targetType, targetId }, ...prev])
    return key
  }

  const unregisterCustomer = () =>
    pathname !== '/payroll' && setRegistry(prev => prev.filter(({ targetType }) => targetType !== 'Customer'))

  const registerCustomer = customerId => {
    setTimeout(
      () =>
        sendMessage(userChannel, 'fetchForTarget', { userId: user.id, targetType: 'Customer', targetId: customerId }),
      200
    )
    pathname !== '/payroll' &&
      setRegistry(prev => [
        { targetType: 'Customer', targetId: customerId },
        ...prev.filter(({ targetType }) => targetType !== 'Customer'),
      ])
  }

  const unregisterTaskTarget = key =>
    pathname !== '/payroll' && setRegistry(prev => prev.filter(({ key: _key }) => key !== _key))

  useEventHandler(userChannel, 'fetchedForTarget', ({ tasks }) => {
    pathname !== '/payroll' &&
      setTasks(_tasks => {
        const registeredTasks = [..._tasks, ...tasks].filter(registryFilter)
        const tasksThatUserHasRoleTurnedOnFor = registeredTasks.filter(item =>
          item.roles.length === 0 ? item : taskRoles.some(tr => item?.roles?.includes(tr))
        )

        // distinct by task id
        return [...new Map(tasksThatUserHasRoleTurnedOnFor.map(task => [task.id, task])).values()]
      })
  })

  useEffect(() => {
    pathname !== '/payroll' && setTasks(_tasks => _tasks.filter(registryFilter))
  }, [registry])

  if (!(userChannel && allChannel)) return null

  return (
    <TaskTargetRegistry.Provider
      value={{ tasks, registerTaskTarget, unregisterTaskTarget, registerCustomer, unregisterCustomer }}
    >
      {children}
    </TaskTargetRegistry.Provider>
  )
}

export default TaskTargetRegistryProvider
