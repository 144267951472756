import { Link } from 'react-router-dom'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Box } from '@mui/material'
// import { CsvConfigurableListOptions } from 'components/CsvConfigurableListOptions'

const Index = ({ markets }) => {
  return (
    <>
      {/* <CsvConfigurableListOptions parentType='Market' parentTitle='Market' /> */}
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>name</TableCell>
              <TableCell>state</TableCell>
              <TableCell align='right'>hourly prep</TableCell>
              <TableCell align='right'>hourly paint</TableCell>
              <TableCell align='right'>hourly body work</TableCell>
              <TableCell align='right'>hourly major part</TableCell>
              <TableCell align='right'>hourly minor part</TableCell>
              <TableCell align='right'>Quote Approval</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {markets.map((market, index) => (
              <TableRow key={market.id} sx={{ background: index % 2 === 0 ? '#fff' : '#f9f9f9' }}>
                <TableCell>
                  <Link to={'/configurations/markets/' + market.id}>{market.market}</Link>
                </TableCell>
                <TableCell>{market.state}</TableCell>
                <TableCell align='right'>${market.hourlyRatePrep}/hr</TableCell>
                <TableCell align='right'>${market.hourlyRatePaint}/hr</TableCell>
                <TableCell align='right'>${market.hourlyRateBodyWork}/hr</TableCell>
                <TableCell align='right'>${market.hourlyRateMajorPart}/hr</TableCell>
                <TableCell align='right'>${market.hourlyRateMinorPart}/hr</TableCell>
                <TableCell align='right'>
                  {market.quoteApprovalRequired ? (
                    <b style={{ color: 'red' }}>Required</b>
                  ) : (
                    <span style={{ color: '#777' }}>N/A</span>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Index
