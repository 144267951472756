import dayjs from 'dayjs'
import { useQuery } from '@apollo/client'
import { getAllJobListingsRes, GET_JOB_LISTINGS } from './query'

export const useJobListings = () => {
  const { data, loading, refetch } = useQuery<getAllJobListingsRes>(GET_JOB_LISTINGS)

  const jobListingsRaw = data?.jobListings || []

  const jobListings = jobListingsRaw.map(jobListing => {
    const isDisabled = !!jobListing?.disabledAt
    const isExpired = dayjs(jobListing?.expiresAt).isAfter(dayjs())

    let status = 'active'
    if (isDisabled) status = 'disabled'
    if (isExpired) status = 'expired'

    return {
      ...jobListing,
      status,
    }
  })

  return { jobListingsRaw, jobListings, refetch, loading }
}
