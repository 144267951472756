import React, { useState } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import UsersNew from './Users/New'
import UsersIndex from './Users/Index'
import UsersShow from './Users/Router'

const Users = () => {
  const { path } = useRouteMatch()

  return (
    <React.Fragment>
      <Switch>
        <Route path={`${path}/new`}>
          <UsersNew />
        </Route>
        <Route path={`${path}/:userId`}>
          <UsersShow />
        </Route>
        <Route path={`${path}`}>
          <UsersIndex />
        </Route>
      </Switch>
    </React.Fragment>
  )
}

export default Users
