import { Link, useHistory } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { BToBDealerType } from 'types/types'

const DEALERS_QUERY = gql`
  query BToBDealers {
    bToBDealers {
      id
      businessName
      createdAt
      invoiceCount
      technicianCount
      isActive
    }
  }
`

export const DealersTable = ({ showInactive }: { showInactive: boolean }) => {
  const { loading, error, data, fetchMore } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const dealers = data?.bToBDealers || []
  const filteredDealers = showInactive ? dealers : dealers.filter((dealer: any) => Boolean(dealer.isActive))

  return (
    <TableContainer component={Paper}>
      <Table size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>Business Name</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Technicians</TableCell>
            <TableCell>Invoices</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredDealers.map((dealer: BToBDealerType) => (
            <TableRow key={dealer.id}>
              <TableCell>
                <Link to={`/b-to-b/dealers/${dealer.id}`}> {dealer.businessName} </Link>
              </TableCell>
              <TableCell>{DateTime.fromISO(dealer.createdAt).toFormat('ff ZZZZ')} </TableCell>
              <TableCell>{dealer.technicianCount}</TableCell>
              <TableCell>{dealer.invoiceCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
