import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Popover } from '@mui/material'
import { useContext } from 'react'
import { DateTime } from 'luxon'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { CloseRounded, DeleteRounded, EditRounded, EventBusyRounded, EventRounded } from '@mui/icons-material'
import { Link } from 'react-router-dom'

export const JobPopover = ({
  selectedJob,
  setJobInfoPopoverOpen,
  jobInfoPopoverOpen,
  jobInfoPopoverAnchorEl,
  setEditJobDrawerOpen,
  dealer,
  refetchAll,
}: any) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const handleClickDeleteJob = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_job`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        b_to_b_job_id: selectedJob.id,
      }),
    })
      .then(res => {
        setJobInfoPopoverOpen(false)
        refetchAll()
      })
      .catch(() => window.alert('let eric know about this'))
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          borderRadius: '6px',
          maxWidth: '400px',
        },
      }}
      anchorEl={jobInfoPopoverAnchorEl}
      open={jobInfoPopoverOpen}
      onClose={() => setJobInfoPopoverOpen(false)}
    >
      {selectedJob && (
        <Box
          sx={{
            padding: '.5rem 1rem',
            userSelect: 'none',
            mb: '.5rem',
            '& b': {
              fontSize: '22px',
              fontWeight: 500,
              '& svg': {
                marginRight: '.5rem',
              },
            },
            '& span': {
              fontSize: '13px',
              color: '#333',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              '& svg': { color: '#777' },
              pb: '.25rem',
              pt: '.25rem',
            }}
          >
            <Button
              onClick={() => {
                setJobInfoPopoverOpen(false)
                setEditJobDrawerOpen(true)
              }}
              sx={{ minWidth: '30px' }}
              size='small'
            >
              <EditRounded fontSize='small' />
            </Button>
            <Button onClick={handleClickDeleteJob} sx={{ minWidth: '30px', ml: '.5rem' }} size='small'>
              <DeleteRounded fontSize='small' />
            </Button>
            <Button onClick={() => setJobInfoPopoverOpen(false)} sx={{ minWidth: '30px', ml: '1rem' }} size='small'>
              <CloseRounded fontSize='small' />
            </Button>
          </Box>
          <List dense disablePadding>
            <ListItem alignItems='flex-start' disableGutters>
              <ListItemIcon sx={{ minWidth: '30px', '& svg': { mt: '.125rem', fontSize: '18px' } }}>
                <EventRounded />
              </ListItemIcon>
              <ListItemText
                primary={
                  <b>
                    <Link to={`/technicians/${selectedJob.technician.id}`}>
                      {selectedJob.technician.firstName} {selectedJob.technician.lastName}
                    </Link>{' '}
                    @ {dealer.businessName}
                  </b>
                }
                secondary={
                  <>
                    <span style={{ pointerEvents: 'none' }}>
                      {DateTime.fromISO(selectedJob.startDatetime, { zone: 'utc' }).toFormat('cccc, LLLL dd')}
                      {'  '}
                      <span style={{ marginLeft: '.25rem', marginRight: '.25rem' }}>&#8226;</span>
                      {'  '}
                    </span>
                    <span style={{ pointerEvents: 'none' }}>
                      {DateTime.fromISO(selectedJob.startDatetime, { zone: 'utc' }).toFormat('t')} &#8212;{' '}
                    </span>
                    <span style={{ pointerEvents: 'none' }}>
                      {DateTime.fromISO(selectedJob.endDatetime, { zone: 'utc' }).toFormat('t')}
                    </span>
                  </>
                }
              />
            </ListItem>
          </List>

          {selectedJob.createdBy && (
            <span style={{ color: '#777' }}>
              <br />
              Created by {selectedJob.createdBy.firstName} {selectedJob.createdBy.lastName} on{' '}
              {DateTime.fromISO(selectedJob.createdAt).toFormat('DD t')}
            </span>
          )}

          {selectedJob.updatedBy && (
            <span style={{ color: '#777' }}>
              <br />
              Updated by {selectedJob.updatedBy.firstName} {selectedJob.updatedBy.lastName} on{' '}
              {DateTime.fromISO(selectedJob.updatedAt).toFormat('DD t')}
            </span>
          )}
        </Box>
      )}
    </Popover>
  )
}
