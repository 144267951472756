import React, { useState, useContext, useEffect } from 'react'
import {
  Dialog,
  Autocomplete,
  Box,
  Button,
  TextField,
  Checkbox,
  Typography,
  Chip,
  ListItem,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
} from '@mui/material'
import { CheckBox, ExpandMore } from '@mui/icons-material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { CloseRounded } from '@mui/icons-material'
import { gql, useLazyQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

const GET_QUALIFIED_TECHNICIANS = gql`
  query QualifiedTechniciansForQuote($quoteId: ID!, $skillsets: [String!]!, $ignoreDrivingDistance: Boolean) {
    qualifiedTechniciansForQuote(
      quoteId: $quoteId
      skillsets: $skillsets
      ignoreDrivingDistance: $ignoreDrivingDistance
    ) {
      id
      firstName
      lastName
      note
    }
  }
`
const AddApprovedTechniciansDialog = ({ quote, refetchQuote }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const { approvedTechnicians, requiredSkillsets, difficultyLevel } = quote
  const [user] = useContext(UserContext)
  const [open, setOpen] = useState(false)
  const [updatedApprovedTechnicians, setUpdatedApprovedTechnicians] = useState(approvedTechnicians)
  const [ignoreDrivingDistance, setIgnoreDrivingDistance] = useState(false)
  const saveButtonIsDisabled = JSON.stringify(updatedApprovedTechnicians) === JSON.stringify(approvedTechnicians)
  useEffect(() => setUpdatedApprovedTechnicians(approvedTechnicians), [approvedTechnicians])

  const [getTechs, { loading, error, data, refetch }] = useLazyQuery(GET_QUALIFIED_TECHNICIANS, {
    fetchPolicy: 'network-only',
    variables: {
      quoteId: quote.id,
      skillsets: requiredSkillsets.concat([difficultyLevel]),
      ignoreDrivingDistance: ignoreDrivingDistance,
    },
  })

  const qualifiedTechnicians =
    data?.qualifiedTechniciansForQuote.slice().sort((a, b) => (a.firstName > b.firstName ? 1 : -1)) ||
    approvedTechnicians

  const handleUpdateApprovedTechnicians = e =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_quote_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        quote_id: quote.id,
        updated_attributes: {
          approved_technician_ids: e.map(tech => tech.id),
        },
      }),
    })
      .then(() => setOpen(false))
      .then(() => refetchQuote())
      .catch(() => alert('An error occurred.'))

  return (
    <>
      <Dialog open={open} PaperProps={{ sx: { minWidth: '750px' } }}>
        <Box sx={{ minWidth: '750px' }}>
          <Box
            sx={{
              padding: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#3f51b5',
              color: '#fff',
            }}
          >
            <Typography variant='h6'>Approve Technicians with Skillsets</Typography>
            <Button onClick={() => setOpen(false)} disableRipple sx={{ color: '#fff', minWidth: '30px' }}>
              <CloseRounded />
            </Button>
          </Box>
          <Box
            sx={{
              background: '#f1f1f1',
              display: 'flex',
              alignItems: 'center',
              padding: '.5rem 1rem',
              borderBottom: '1px solid #ddd',
            }}
          >
            {qualifiedTechnicians.length} Qualified Technicians |
            <span style={{ fontSize: '14px', color: '#777' }}> Deselect any that don't apply </span>
            <FormControlLabel
              sx={{ ml: 'auto' }}
              onChange={e => setIgnoreDrivingDistance(e.target.checked)}
              control={<Checkbox checked={ignoreDrivingDistance} />}
              label='ignore distance'
            />
            <Button onClick={() => getTechs()}>{loading ? <CircularProgress size='24px' /> : <> Refresh </>}</Button>
          </Box>
          <Box sx={{ padding: '1rem', display: 'flex', justifyContent: 'center' }}>
            <Autocomplete
              sx={{
                display: 'inline-block',
                minWidth: '225px',
              }}
              multiple
              size='small'
              options={qualifiedTechnicians}
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => `${option.firstName} ${option.lastName}` || ''}
              value={updatedApprovedTechnicians}
              onChange={(e, v) => setUpdatedApprovedTechnicians(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} size='small' />
                  {option.firstName} {option.lastName}
                </li>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant='outlined'
                    label={`${option.firstName} ${option.lastName}`}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={params => (
                <TextField
                  size='small'
                  {...params}
                  label='Approved Technicians'
                  placeholder='Add an approved technician'
                />
              )}
            />
          </Box>
          <Box sx={{ maxHeight: '400px', overflowY: 'auto', borderTop: '1px solid #ddd' }}>
            <Accordion>
              <AccordionSummary sx={{ background: '#f1f1f1' }} expandIcon={<ExpandMore />}>
                Technician Notes
              </AccordionSummary>
              <AccordionDetails>
                {qualifiedTechnicians.map(tech => (
                  <ListItem sx={{ display: 'block', borderBottom: '1px solid #ddd' }}>
                    <Link to={`/technicians/${tech.id}`}>
                      {tech.firstName} {tech.lastName}
                    </Link>
                    <br /> {tech.note ? tech.note : 'no notes'}
                  </ListItem>
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              background: '#f1f1f1',
              borderTop: '1px solid #ddd',
              padding: '.5rem 1rem',
            }}
          >
            <Button
              disabled={saveButtonIsDisabled}
              onClick={() => handleUpdateApprovedTechnicians(updatedApprovedTechnicians)}
              variant='contained'
            >
              Save
            </Button>
          </Box>
        </Box>
      </Dialog>
      <span style={{ marginLeft: 'auto', marginRight: '.5rem', fontSize: '14px' }}>
        {quote?.approvedTechnicianIds?.length} Approved Techs{' '}
      </span>
      <Button size='small' variant='outlined' onClick={() => setOpen(true)}>
        Change
      </Button>
    </>
  )
}

export default AddApprovedTechniciansDialog
