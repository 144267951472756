export const paymentStatusLabels = {
  '& .fully_paid': {
    color: 'green',
  },
  '& .pre_paid': {
    color: 'orange',
  },
  '& .waiting_pre_payment': {
    color: 'red',
  },
  '& .over_paid': {
    color: 'red',
  },
  '& .none': {
    color: '#444',
  },
}
