import React from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Grid, Chip, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'

const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      phone
      addressLineOne
      addressLineTwo
      city
      state
      zip
      roles
      status
    }
  }
`

const useStyles = makeStyles(theme => ({
  dateTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  attributes_form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
    '& .MuiFormControl-root': {
      margin: theme.spacing(1),
    },
  },
  button_row: {
    '& > *': {
      margin: theme.spacing(0.8),
    },
  },
  userInfo: {
    padding: 0,
    margin: 0,
    '& > li': {
      borderBottom: '1px solid #eee',
      display: 'flex',
      alignItems: 'center',

      '& > p': {
        background: '#eee',
        padding: '5px',
        margin: '0 5px 0 0',
        display: 'inline-block',
        fontSize: '13px',
        fontWeight: '500',
        textAlign: 'right',
        width: '25%',
      },
      '& > span': {
        maxWidth: '70%',
      },
    },
  },
}))

const UsersShow = () => {
  const { userId } = useParams()
  const classes = useStyles()
  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: { id: userId },
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error! {error}</div>

  const user = data.user

  return (
    <Grid container>
      <Box sx={{ minWidth: '600px', mt: '1rem' }}>
        <ul className={classes.userInfo}>
          <li>
            <p> first name: </p> {user.firstName}
          </li>
          <li>
            <p> last name: </p> {user.lastName}
          </li>
          <li>
            <p> active: </p>
            {user.status === 'active' ? (
              <b style={{ color: 'green' }}>YES</b>
            ) : (
              <b style={{ color: 'red' }}>NO</b>
            )}
          </li>

          <li>
            <p> email: </p> {user.email}
          </li>
          <li>
            <p> phone: </p> {user.phone}
          </li>
          <li>
            <p> address: </p> {user.addressLineOne}
          </li>
          {user.addressLineTwo && (
            <li>
              <p> address: </p> {user.addressLineTwo}
            </li>
          )}
          <li>
            <p> city, state zip: </p>
            <>
              {user.city}, {user.state} {user.zip}
            </>
          </li>

          <li>
            <p> roles: </p>
            <span>
              {user.roles?.map(item => (
                <Chip
                  key={item}
                  label={item}
                  sx={{
                    height: 'inherit',
                    margin: 1,
                    '& span': {
                      maxWidth: '100%',
                      whiteSpace: 'pre-line',
                    },
                  }}
                />
              ))}
            </span>
          </li>
        </ul>
      </Box>
    </Grid>
  )
}

export default UsersShow
