import { Typography, Grid, Skeleton, TableRow, TableCell } from '@mui/material'

export const LoadingAveragesSkeleton = ({ label }) => {
  return (
    <Grid
      container
      columnSpacing={2}
      alignItems='center'
      sx={{ borderTop: '2px solid #ddd', p: '1rem 0rem .5rem 0rem' }}
    >
      <Grid item xs={12}>
        <Typography
          variant='h1'
          sx={{ fontSize: '20px', fontWeight: 600, mb: '1rem' }}
        >
          {label} Averages
        </Typography>
      </Grid>
      {[1, 2, 3, 4, 5, 6].map(item => (
        <Grid key={item} item xs={2}>
          <Skeleton
            sx={{
              minHeight: '85px',
              marginBottom: '1rem',
              borderRadius: '12px',
              transform: 'scale(1)',
              background: '#0089CD1a',
              borderColor: '#0089CDbf !important'
            }}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export const UserRecordsSkeleton = () => {
  return [1, 2, 3, 4, 5, 6, 7].map(item => (
    <TableRow key={item}>
      {[11, 12, 13, 14, 15, 16, 17].map(inner => (
        <TableCell sx={{ padding: '.5rem' }} key={inner}>
          <Skeleton
            sx={{
              minWidth: '100%',
              minHeight: '40px',
              transform: 'scale(1)',
              background: item % 2 !== 0 ? '#0089CD1a' : '#f1f1f1'
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  ))
}

export const CallRecordsSkeleton = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map(
    item => (
      <TableRow key={item}>
        {[11, 12, 13, 14, 15, 16, 17].map(inner => (
          <TableCell sx={{ padding: '.25rem' }} key={inner}>
            <Skeleton
              sx={{
                minWidth: '100%',
                minHeight: '28px',
                transform: 'scale(1)',
                background: item % 2 !== 0 ? '#0089CD1a' : '#f1f1f1'
              }}
            />
          </TableCell>
        ))}
      </TableRow>
    )
  )
}

export const ActivityTimelineSkeleton = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map(
    item => (
      <Typography
        key={item}
        sx={{
          width: '100%',
          borderRadius: '12px',
          padding: '.5rem',
          margin: '.25rem 0rem .25rem 0rem',
          display: 'flex',
          alignItems: 'center',
          '& svg': {
            mr: '.5rem',
            height: '16px',
            width: '16px'
          }
        }}
      >
        <Skeleton
          sx={{
            minWidth: '100%',
            minHeight: '28px',
            transform: 'scale(1)',
            background: item % 2 !== 0 ? '#0089CD1a' : '#f1f1f1'
          }}
        />
      </Typography>
    )
  )
}

export const SalesComparisonsSkeleton = () => {
  return ['LEADS', 'SALES', 'CLOSE RATE'].map(section => (
    <Grid
      key={section}
      container
      columnSpacing={2}
      alignItems='center'
      sx={{ borderTop: '2px solid #ddd', p: '.5rem 0rem' }}
    >
      <Grid item xs={0.5}>
        <Typography sx={{ color: '#777' }}>
          <span
            style={{ display: 'inline-block', transform: 'rotate(-90deg)' }}
          >
            {section}
          </span>
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Skeleton
          sx={{
            minHeight: '85px',
            borderRadius: '12px',
            transform: 'scale(1)',
            background: '#0089CD1a',
            borderColor: '#0089CDbf !important'
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <Skeleton
          sx={{
            minHeight: '85px',
            borderRadius: '12px',
            transform: 'scale(1)',
            background: '#0089CD1a',
            borderColor: '#0089CDbf !important'
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <Skeleton
          sx={{
            minHeight: '85px',
            borderRadius: '12px',
            transform: 'scale(1)',
            background: '#0089CD1a',
            borderColor: '#0089CDbf !important'
          }}
        />
      </Grid>
    </Grid>
  ))
}
