import React, { useState } from 'react'
import axios from 'axios'
import { DateTime } from 'luxon'
import { Button, Chip, Grid, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

import CancelIcon from '@mui/icons-material/Cancel'

const useStyles = makeStyles(theme => ({
  dateTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  attributes_form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
    '& .MuiFormControl-root': {
      margin: theme.spacing(1),
    },
  },
  button_row: {
    '& > *': {
      margin: theme.spacing(0.8),
    },
  },
  techInfo: {
    padding: 0,
    margin: 0,
    '& > li': {
      borderBottom: '1px solid #eee',
      display: 'flex',
      alignItems: 'center',

      '& > p': {
        background: '#eee',
        padding: '5px',
        margin: '0 5px 0 0',
        display: 'inline-block',
        fontSize: '13px',
        fontWeight: '500',
        textAlign: 'right',
        width: '25%',
      },
      '& > span': {
        maxWidth: '70%',
      },
    },
  },
  offDayItem: {
    borderBottom: '1px solid #ccc',
    padding: '10px 10px',
    width: '280px',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      background: '#fff',
    },
    '& svg': {
      cursor: 'pointer',
      color: '#DA291C',
      margin: '0px 0px -2px 5px',
      fontSize: '20px',
    },
  },
}))

const newOffDayRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/

const Profile = ({ technician }) => {
  const classes = useStyles()
  const [newOffDay, setNewOffDay] = useState('')

  const handleNewOffDayChange = event => {
    setNewOffDay(event.target.value)
  }

  // offday = Date string "2020-01-01"
  const handleRemoveOffday = offday => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_COMMAND_ROOT + '/api/remove_technician_offday',
      data: {
        technician_id: technician.id,
        date: offday,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
  }

  const handleAddNewOffday = () => {
    if (!newOffDay.match(newOffDayRegex)) {
      return
    }

    axios({
      method: 'post',
      url: process.env.REACT_APP_COMMAND_ROOT + '/api/add_technician_offday',
      data: {
        technician_id: technician.id,
        date: newOffDay,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <ul className={classes.techInfo}>
          <li>
            <p> full name: </p> {technician.name}
          </li>
          <li>
            <p> first name: </p> {technician.firstName}
          </li>
          <li>
            <p> last name: </p> {technician.lastName}
          </li>
          <li>
            <p> active: </p>
            {technician.active ? <b style={{ color: 'green' }}>YES</b> : <b style={{ color: 'red' }}>NO</b>}
          </li>
          <li>
            <p> trusted: </p>
            {technician.isTrusted ? <b style={{ color: 'green' }}>YES</b> : <b style={{ color: 'red' }}>NO</b>}
          </li>
          <li>
            <p> email: </p> {technician.email}
          </li>
          <li>
            <p> phone: </p> {technician.phone}
          </li>
          <li>
            <p> address: </p> {technician.addressLineOne}
          </li>
          {technician.addressLineTwo && (
            <li>
              <p> address: </p> {technician.addressLineTwo}
            </li>
          )}
          <li>
            <p> city, state zip: </p>
            <>
              {technician.city}, {technician.state} {technician.zip}
            </>
          </li>
          <li>
            <p> market: </p> {technician.market}
          </li>
          <li>
            <p> display market: </p> {technician.displayMarket}
          </li>
          {technician.vehicleYear && (
            <li>
              <p> vehicle: </p> {technician.vehicleColor} {technician.vehicleYear} {technician.vehicleMakeShortName}{' '}
              {technician.vehicleModelShortName}{' '}
            </li>
          )}
          <li>
            <p> employee: </p>
            {technician.isEmployee ? <b style={{ color: 'green' }}>YES</b> : <b style={{ color: 'red' }}>NO</b>}
          </li>
          {technician.isEmployee && (
            <>
              <li>
                <p> hourly rate: </p> ${technician.hourlyRate} / hour
              </li>
              <li>
                <p> commission amount: </p> ${technician.commissionAmount} / half-day job
              </li>
            </>
          )}
          {!technician.isEmployee && (
            <li>
              <p> comission rate: </p> {technician.commissionPercent}%
            </li>
          )}
          <li>
            <p> b-to-b: </p>
            {technician.isBToB ? <b style={{ color: 'green' }}>YES</b> : <b style={{ color: 'red' }}>NO</b>}
          </li>
          {technician.isBToB && (
            <li>
              <p> b-to-b business name: </p> {technician.bToBBusinessName}
            </li>
          )}
          {technician.isBToB &&  <li>
            <p> b-to-b dealers: </p>
            <span>
              {technician.bToBDealers?.map(item => (
                <Chip
                  key={item}
                  label={item.businessName}
                  sx={{
                    height: 'inherit',
                    margin: 1,
                    '& span': {
                      maxWidth: '100%',
                      whiteSpace: 'pre-line',
                    },
                  }}
                />
              ))}
            </span>
          </li>}
          <li>
            <p> is business: </p>
            {technician.contractorIsBusiness ? (
              <b style={{ color: 'green' }}>YES</b>
            ) : (
              <b style={{ color: 'red' }}>NO</b>
            )}
          </li>
          {technician.contractorIsBusiness && (
            <>
              <li>
                <p> business name: </p> {technician.businessName}
              </li>
              <li>
                <p> ein: </p> {technician.ein}
              </li>
            </>
          )}
          {!technician.contractorIsBusiness && (
            <li>
              <p> ssn: </p> {technician.ssn}
            </li>
          )}
          <li>
            <p> availability: </p>
            <span style={{ display: 'inline-block' }}>
              {Object.entries(technician.availability)
                .filter(avail => avail[1] !== true && avail[1] !== false)
                .map(e => {
                  return e[0] + ':' + (e[1].length > 0 ? e[1] : ' none') + ', '
                })}
            </span>
          </li>
          <li>
            <p> skillsets: </p>
            <span>
              {technician.skillsets?.map(item => (
                <Chip
                  key={item}
                  label={item}
                  sx={{
                    height: 'inherit',
                    '& span': {
                      maxWidth: '100%',
                      whiteSpace: 'pre-line',
                    },
                  }}
                />
              ))}
            </span>
          </li>
          {technician.maxDrivingDistance && (
            <li>
              <p> max driving distance: </p>
              {technician.maxDrivingDistance}
            </li>
          )}
          <li>
            <p> Notes: </p>
            <TextField sx={{ width: '70%' }} multiline value={technician.note || ''} disabled />
          </li>
          <li>
            <p> emergency contact info: </p> {technician.emergencyContact}
          </li>
          <li>
            <p> notifications: </p>
            <span>
              {technician.notifications?.map(item => (
                <Chip
                  key={item}
                  label={item}
                  sx={{
                    height: 'inherit',
                    margin: 1,
                    '& span': {
                      maxWidth: '100%',
                      whiteSpace: 'pre-line',
                    },
                  }}
                />
              ))}
            </span>
          </li>
        </ul>
      </Grid>

      <Grid item xs={6} sx={{ paddingLeft: 2 }}>
        <div style={{ display: 'flex' }}>
          <TextField
            label='Add Offday'
            type='date'
            className={classes.dateTextField}
            onChange={handleNewOffDayChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              name:"technician-off-day-input"
            }}
          />
          <Button cy-data="technician-off-day-add-button" disabled={newOffDay.length !== 10} variant='contained' onClick={handleAddNewOffday}>
            Add
          </Button>
        </div>

        <br />

        {technician.offdays.length > 0 ? 'Current off days' : 'No offdays to show'}

        <br />

        {(technician.offdays &&
          technician.offdays
            .sort((a, b) => (a > b ? -1 : 1))
            .map(offday => (
              <div key={offday} className={classes.offDayItem}>
                {DateTime.fromISO(offday).toFormat('cccc, yyyy-LL-dd')}
                <CancelIcon cy-data="remove-off-day-icon" onClick={() => handleRemoveOffday(offday)} />
              </div>
            ))) || <div>none</div>}
      </Grid>
    </Grid>
  )
}

export default Profile
