import { useContext, useEffect, useState, createContext } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { keysToCamel } from './tools'
import localForage from 'localforage'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { useLocation, useRouteMatch } from 'react-router-dom'

export const CustomerContext = createContext()

const CUSTOMER_BY_ID = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      id
      firstName
      lastName
      phone
      noClose
      noCloseRecentConvert
      outboundNumber
    }
  }
`

const CUSTOMER_BY_LEAD_ID = gql`
  query customer($leadId: ID!) {
    customer(leadId: $leadId) {
      id
      firstName
      lastName
      phone
      noClose
      noCloseRecentConvert
      outboundNumber
    }
  }
`

const CustomerContextProvider = ({ children }) => {
  const location = useLocation()
  const appointmentMatch = useRouteMatch('/leads/:leadId/quotes/:quoteId/appointments/:appointmentId')
  const jobMatch = useRouteMatch('/leads/:leadId/quotes/:quoteId/jobs/:jobId')
  const partMatch = useRouteMatch('/leads/:leadId/quotes/:quoteId/parts/:partId')
  const leadMatch = useRouteMatch('/leads/:leadId')
  const quoteMatch = useRouteMatch('/leads/:leadId/quotes/:quoteId')
  const customerMatch = useRouteMatch('/customers/:customerId')

  const { registerCustomer, unregisterCustomer } = useContext(TaskTargetRegistry)
  const [currentCustomer, setCurrentCustomer] = useState(null)
  const [currentCustomerContext, setCurrentCustomerContext] = useState(null)
  // this is an obvious place to store "customer history"
  // every time setCurrentCustomerContext is set
  // push the previous value into a list
  // const [history, setHistory] = useLocalForage('customerContextHistory', [])
  const [history, setHistory] = useState([])
  const [pinnedCustomers, setPinnedCustomers] = useState(null)

  const [fetchCustomerByCustomerId] = useLazyQuery(CUSTOMER_BY_ID, {
    fetchPolicy: 'network-only', // needed as a cache bust
    variables: { id: currentCustomerContext?.customerId },
    onCompleted: data => setCurrentCustomer(keysToCamel(data.customer)),
  })

  const [fetchCustomerByLeadId] = useLazyQuery(CUSTOMER_BY_LEAD_ID, {
    fetchPolicy: 'network-only', // needed as a cache bust
    variables: { leadId: currentCustomerContext?.leadId },
    onCompleted: data => setCurrentCustomer(keysToCamel(data.customer)),
  })

  const key = 'currentCustomerHistoryV2'
  // const pinnedCustomersKey = 'pinnedCustomers'

  useEffect(() => {
    if (jobMatch?.isExact) {
      setCurrentCustomerContext({ type: 'Job', id: jobMatch.params.jobId, path: location.pathname, ...jobMatch.params })
    } else if (partMatch?.isExact) {
      setCurrentCustomerContext({
        type: 'Part',
        id: partMatch.params.partId,
        path: location.pathname,
        ...partMatch.params,
      })
    } else if (quoteMatch?.isExact) {
      setCurrentCustomerContext({
        type: 'Quote',
        id: quoteMatch.params.quoteId,
        path: location.pathname,
        ...quoteMatch.params,
      })
    } else if (leadMatch?.isExact) {
      setCurrentCustomerContext({
        type: 'Lead',
        id: leadMatch.params.leadId,
        path: location.pathname,
        ...leadMatch.params,
      })
    } else if (customerMatch?.isExact) {
      setCurrentCustomerContext({
        type: 'Customer',
        id: customerMatch.params.customerId,
        path: location.pathname,
        ...customerMatch.params,
      })
    } else if (appointmentMatch?.isExact) {
      setCurrentCustomerContext({
        type: 'Appointment',
        id: appointmentMatch.params.appointmentId,
        path: location.pathname,
        ...appointmentMatch.params,
      })
    } else {
      setCurrentCustomerContext(null)
    }
  }, [location])

  useEffect(() => {
    localForage.getItem(key).then(data => setHistory(data || []))
  }, [])

  // useEffect(() => {
  //   localForage.getItem(pinnedCustomersKey)
  //     .then(data => setPinnedCustomers(data || []))
  // }, [])

  useEffect(() => {
    localForage.setItem(key, history)
  }, [history])

  // useEffect(() => {
  //   localForage.setItem(pinnedCustomersKey, pinnedCustomers)
  // }, [pinnedCustomers])

  useEffect(() => {
    currentCustomer?.id ? registerCustomer(currentCustomer.id) : unregisterCustomer()
  }, [currentCustomer])

  useEffect(() => {
    if (currentCustomerContext === null) {
      setCurrentCustomer(null)
      return null
    }

    currentCustomerContext?.leadId && fetchCustomerByLeadId()
    currentCustomerContext?.customerId && fetchCustomerByCustomerId()
  }, [currentCustomerContext])

  useEffect(() => {
    if (!currentCustomer) return
    if (
      history.length > 0 &&
      history[0].customer.id === currentCustomer.id &&
      history[0].context.Id === currentCustomerContext.Id
    )
      return

    // else push it onto the history list
    setHistory(_history => [
      {
        customer: currentCustomer,
        context: currentCustomerContext,
        timestamp: Date.now(),
      },
      ..._history.slice(0, 200),
    ])
  }, [currentCustomer])

  const pinCustomer = customer => setPinnedCustomers(_customers => [pinnedCustomers, ..._customers])
  const unpinCustomer = customerId =>
    setPinnedCustomers(_customers => _customers.filter(_customer => _customer.id !== customerId))

  return (
    <CustomerContext.Provider
      value={{
        currentCustomer,
        currentCustomerContext,
        history,
        pinCustomer,
        unpinCustomer,
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

export default CustomerContextProvider
