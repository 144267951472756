import { createContext, useContext, useState } from 'react'
import { useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import { ReadCacheChannelContext } from './ReadCacheChannelStore'
import { v4 as uuid } from 'uuid'

export const UpdateRefetchRegistry = createContext()

const UpdateRefetchRegistryProvider = ({ children }) => {
  const readCacheChannel = useContext(ReadCacheChannelContext)
  const [refetches, setRefetches] = useState([])
  // [
  //   [key, types, refetchFunc],
  //   ...
  // ]

  useEventHandler(readCacheChannel, 'object_replaced', ({ type, key, data }) => {
    refetches
      .forEach(([_key, _types, _refetch]) =>
        _types.includes(type) && setTimeout(_refetch, 250)
      )
  })

  const registerUpdateRefetch = ({ types, refetch }) => {
    const key = uuid()
    setRefetches(prev => [[key, types, refetch], ...prev.filter(([_key]) => _key !== key)])
    return key
  }

  const unregisterUpdateRefetch = key =>
    setRefetches(prev =>
      prev.filter(([_key]) => _key !== key)
    )

  return (
    <UpdateRefetchRegistry.Provider
      value={[registerUpdateRefetch, unregisterUpdateRefetch]}
    >
      {children}
    </UpdateRefetchRegistry.Provider>
  )
}

export default UpdateRefetchRegistryProvider
