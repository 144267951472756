import { gql, useLazyQuery } from '@apollo/client'
import { Box, Button, Checkbox, CircularProgress, Dialog, FormControlLabel, Switch, TextField } from '@mui/material'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { useContext, useEffect, useState } from 'react'
import { keysToCamel } from 'tools'

export const GLASS_LOCATIONS = gql`
  query OmegaGlassLocations($vehicleId: String!, $locations: [String!]) {
    omegaGlassLocations(vehicleId: $vehicleId, locations: $locations) {
      locations
      graphic
      allGlass
    }
  }
`

export const OmegaGlassPopover = ({ open, setOpen, data }) => {
  const [glassIds, setGlassIds] = useState([])
  const [chipCount, setChipCount] = useState(1)
  const [useInsurance, setUseInsurance] = useState(false)
  const [loading, setLoading] = useState(false)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const [getLocations, { loading: loadingOmegaData, error, data: omegaData }] = useLazyQuery(GLASS_LOCATIONS, {
    variables: {
      vehicleId: data?.lead?.vin ? data?.lead?.vin : data?.lead?.omegaVehicleId,
      locations: ['windshield', 'side', 'rear'],
    },
  })

  useEffect(() => {
    data?.lead && open && !omegaData && getLocations()
  }, [data, open])

  const options = keysToCamel(omegaData?.omegaGlassLocations?.locations) || []

  const handleCreateQuote = () => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_autoglass_replace_quote`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        leadId: data?.leadId,
        nagsGlassIds: glassIds,
        useInsurance,
      }),
    })
      .then(data => {
        window.location.reload()
      })
      .catch(e => window.alert(e.message))
  }

  const handleCreateRepairQuote = () => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_autoglass_repair_quote`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        leadId: data?.leadId,
        chipCount: chipCount,
        useInsurance,
      }),
    })
      .then(data => {
        window.location.reload()
      })
      .catch(e => window.alert(e.message))
  }

  return (
    <Dialog
      PaperProps={{
        sx: { minWidth: '700px' },
      }}
      onClose={() => setOpen(false)}
      open={open}
    >
      <Box sx={{ background: 'aliceblue', padding: '1rem', borderBottom: '2px solid #a1cff7', fontWeight: 600 }}>
        Vehicle Breakdown
      </Box>
      <Box sx={{ padding: '.5rem', background: '#f1f1f1' }}>
        <Box sx={{ padding: '1rem', border: '1px solid #ddd', background: "#fff'" }}>
          <TextField
            label='chip count'
            size='small'
            value={chipCount}
            onChange={e => {
              // @ts-ignore
              setChipCount(e.target.value)
            }}
          />
          <Button
            sx={{ mb: '.5rem', '& span': { display: 'flex', alignItems: 'center' } }}
            variant='outlined'
            size='small'
            disabled={loading || chipCount < 1}
            onClick={() => handleCreateRepairQuote()}
          >
            Create repair quote
          </Button>
        </Box>
        <Box sx={{ padding: '1rem', border: '1px solid #ddd', borderRadius: '12px', background: '#fff' }}>
          <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => handleCreateQuote()}
              sx={{ mb: '.5rem', '& span': { display: 'flex', alignItems: 'center' } }}
              variant='outlined'
              size='small'
              disabled={glassIds?.length === 0 || loading}
            >
              <span>
                {loading ? (
                  <>
                    <CircularProgress sx={{ mr: '.5rem' }} size='.9rem' /> creating...{' '}
                  </>
                ) : (
                  <>create new quote</>
                )}
              </span>
            </Button>{' '}
            <FormControlLabel
              onClick={() => setUseInsurance(prev => !prev)}
              control={<Switch size='small' checked={useInsurance} />}
              label='apply insurance discount'
            />
          </Box>
          {/* <JSONPretty style={{ fontSize: '12px' }} data={data} /> */}
          {loadingOmegaData && <CircularProgress />}
          {options?.map(glassLocation => (
            <Box
              sx={{
                '& b': { fontSize: '14px', background: '#f1f1f1', display: 'block', tetxcAlign: 'center' },
                width: '100%',
                fontSize: '12px',
                borderBottom: '1px solid #ddd',
                padding: '.5rem',
              }}
              key={glassLocation?.englishName}
            >
              <b>
                {glassLocation?.englishName} ({glassLocation?.glass?.length})
              </b>
              {glassLocation?.glass?.map(g => (
                <Box
                  key={g?.nagsPartNumber}
                  onClick={() =>
                    glassIds?.includes(g?.nagsPartNumber)
                      ? setGlassIds(prev => prev.filter(i => i !== g?.nagsPartNumber))
                      : setGlassIds(prev => [...prev, g?.nagsPartNumber])
                  }
                >
                  <Checkbox checked={glassIds?.includes(g?.nagsPartNumber)} />
                  <span>{g?.description}</span>- <span>{g?.nagsPartNumber}</span>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Dialog>
  )
}
