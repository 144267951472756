import { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { keysToCamel } from 'tools'
import {
  Typography,
  TextField,
  Box,
  Stack,
  Chip,
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  Paper,
  CircularProgress,
} from '@mui/material'
import { Launch, CloseRounded } from '@mui/icons-material'
import SquarePostJobPaymentForm from './SquarePostJobPaymentForm'
import { DateTime } from 'luxon'

const QUERY = gql`
  query bToBInvoices($listOfIds: [String!]) {
    bToBInvoices(listOfIds: $listOfIds) {
      id
      createdAt
      businessName
      lineItems
      lineItemsSum
      fullyPaidAt
      grandTotal
      preJobPhotos
      postJobPhotos
      year
      make
      model
      payoutData
      vin
      vinPhoto
      completedAt
      repairOrder
      stock
      squareInvoiceId
      projectedPayout
      paidSquareInvoiceId
      invoiceNumber
      dealer {
        id
        state
      }
      technician {
        id
        firstName
        lastName
      }
    }
  }
`

const Payable = () => {
  const [idsInput, setIdsInput] = useState('')
  const [idsToSearch, setIdsToSearch] = useState('')

  const parsedIds = idsToSearch
    .trim()
    .split(/(\s)+/)
    .filter(id => id !== ' ' && id !== '')

  const [getData, { loading, error, data, refetch }] = useLazyQuery(QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      listOfIds: [],
    },
  })

  useEffect(() => {
    !data &&
      idsToSearch.length > 0 &&
      getData({
        variables: {
          listOfIds: idsToSearch
            .trim()
            .split(/(\s)+/)
            .filter(id => id !== ' ' && id !== ''),
        },
      })
    data &&
      idsToSearch.length > 0 &&
      getData({
        variables: {
          listOfIds: idsToSearch
            .trim()
            .split(/(\s)+/)
            .filter(id => id !== ' ' && id !== ''),
        },
      })
  }, [idsToSearch])

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const invoices = keysToCamel(data?.bToBInvoices || [])

  const invoicesMissingPaidSquareInvoiceId = invoices

  const invoicesToMarkPaid = invoicesMissingPaidSquareInvoiceId.filter(
    invoice => parsedIds.includes(invoice.squareInvoiceId) || parsedIds.includes(invoice.invoiceNumber)
  )
  const invoicesToMarkPaidTotal = invoicesMissingPaidSquareInvoiceId.reduce(
    (acc, item) => (item.grandTotal ? acc + item.grandTotal : acc + item.lineItemsSum),
    0
  )

  const invoiceSelectedStates = invoicesToMarkPaid.map(invoice => invoice?.dealer?.state || null)
  const atLeastOneState = invoiceSelectedStates.length > 0
  const moreThanOneSelectedState = atLeastOneState && [...new Set(invoiceSelectedStates)].length > 1
  const invoiceSelectedState = atLeastOneState && invoiceSelectedStates.filter(i => i !== null)[0]

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ padding: '0rem 1rem' }}>
          <Typography variant='h4' sx={{ mt: '1rem', mb: '1rem' }}>
            B2B Payments
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={6} sx={{ mt: '1rem', marginLeft: 'auto', marginRight: 'auto' }}>
        <Box
          sx={{
            transition: 'all .25s ease-in-out',
            marginTop: invoicesToMarkPaid.length == 0 ? '150px' : '0px',
          }}
        >
          <Typography sx={{ mb: '.5rem', textAlign: 'center' }}>
            Paste in individual square invoice ids seprated by spaces and press enter
          </Typography>

          <TextField
            error={moreThanOneSelectedState}
            sx={{ maxHeight: '200px' }}
            fullWidth
            label='individual square invoice ids'
            multiline
            maxRows={6}
            value={idsInput}
            onKeyDown={e => {
              if (e.code === 'Enter') {
                e.preventDefault()
                setIdsToSearch(e.target.value)
              }
              if (e.target.value === '') {
                setIdsToSearch('')
              }
            }}
            onChange={e => setIdsInput(e.target.value)}
            helperText={
              moreThanOneSelectedState && (
                <>You can only make a group payment for invoices that are for the same dealer.</>
              )
            }
          />
        </Box>

        <Stack direction='row' spacing={1} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {invoicesToMarkPaid.map(invoice => (
            <Chip
              key={invoice.id}
              label={invoice.squareInvoiceId || invoice.invoiceNumber}
              variant='outlined'
              sx={{ marginTop: '.5rem !important' }}
            />
          ))}
        </Stack>

        {loading && (
          <Box
            sx={{
              position: 'fixed',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#11111140',
              top: '0px',
              bottom: '0px',
              left: '0px',
              right: '0px',
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </Box>
        )}

        <Collapse in={invoicesToMarkPaid.length > 0}>
          <Paper sx={{ margin: '.5rem 0rem', border: '1px solid #ddd' }}>
            <Box
              sx={{
                padding: '1rem',
                background: '#f1f1f1',
                borderBottom: '1px solid #ddd',
              }}
            >
              {invoicesToMarkPaid.length} Selected Invoices
            </Box>
            <Box sx={{ maxHeight: '350px', overflowY: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>total amount</TableCell>
                    <TableCell>fully paid at</TableCell>
                    <TableCell>Square ID</TableCell>
                    <TableCell>technician</TableCell>
                    <TableCell>link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoicesToMarkPaid.map(invoice => (
                    <TableRow key={invoice.id} variant='outlined'>
                      <TableCell>
                        <Button
                          onClick={() => setIdsToSearch(prev => prev.replace(invoice.invoiceNumber, ''))}
                          color='secondary'
                          disableElevation
                          disableRipple
                          sx={{
                            minWidth: '20px',
                            padding: '.5rem',
                            '& svg': { fontSize: '16px' },
                          }}
                        >
                          <CloseRounded />
                        </Button>
                      </TableCell>
                      <TableCell>${(invoice.grandTotal || invoice.lineItemsSum).toFixed(2)}</TableCell>
                      <TableCell>
                        {(invoice.fullyPaidAt && DateTime.fromISO(invoice.fullyPaidAt).toFormat('DD t')) || 'none'}
                      </TableCell>
                      <TableCell>{invoice.paidSquareInvoiceId}</TableCell>
                      <TableCell>
                        {invoice?.technician?.firstName} {invoice?.technician?.lastName}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => window.open(`/b-to-b-invoices/${invoice.id}`)}
                          sx={{ '& svg': { fontSize: '18px' } }}
                        >
                          <Launch />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <SquarePostJobPaymentForm
              totalAmount={invoicesToMarkPaidTotal.toFixed(2)}
              lead={null}
              quote={null}
              invoiceIds={invoicesToMarkPaid.map(i => i.id)}
              state={invoiceSelectedState}
              refetch={refetch}
              disabled={false}
            />
          </Paper>
        </Collapse>
      </Grid>
    </Grid>
  )
}

export default Payable
