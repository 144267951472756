import { Box, Dialog } from '@mui/material'
import Notes from 'components/Notes'

export const NotesDialog = ({ quote, timeCard, noteDialogOpen, setNoteDialogOpen }) => {
  const objectType = quote ? 'QuoteForPayroll' : 'TimeCardForPayroll'
  const objectId = quote ? quote.id : timeCard.id

  return (
    <Dialog
      PaperProps={{ sx: { minWidth: '600px', maxHeight: '500px', overflowY: 'auto' } }}
      open={noteDialogOpen}
      onClose={() => setNoteDialogOpen(false)}
    >
      <Box sx={{ padding: '1rem' }}>
        <Notes headerText='PAYROLL NOTES' objectType={objectType} objectId={objectId} />
      </Box>
    </Dialog>
  )
}
