import { useParams, Redirect } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'

const QUOTE_QUERY = gql`
  query quote($id: ID!) {
    quote(id: $id) {
      leadId
    }
  }
`

const Quote = () => {
  const { quoteId } = useParams()

  const { loading, error, data } = useQuery(QUOTE_QUERY, { variables: { id: quoteId } })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return <Redirect to={`/leads/${data.quote.leadId}/quotes/${quoteId}`} />
}

export default Quote
