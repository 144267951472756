// commonly used value formatting

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
export const formatCount = (value) => value ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'

export const formatPercent = (value) => formatCount((value * 100.0).toFixed(0)) + '%'

export const formatDollars = (value) => '$' + formatCount(value.toFixed(0))

export const formatCents = (value) => '$' + value.toFixed(2)

export default {
  count: (val) => formatCount('' + val),
  percent: formatPercent,
  dollars: formatDollars,
  cents: formatCents
}
