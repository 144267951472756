import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { makeStyles } from '@mui/styles'
import {
  Box, Paper, Button, SvgIcon, InputLabel, MenuItem, FormControl,
  Select, IconButton, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination, TextField
} from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import CloseIcon from '@mui/icons-material/Close'
import { prettyPhoneNumber } from 'tools'

import STATES from 'statesWeOperateIn.json'
const stateMenuItems = [<MenuItem key='all' id='state' value='all'>all</MenuItem>]
  .concat(Object.entries(STATES).map(state =>
    <MenuItem key={state} id='state' value={state[0]}> {state[1]} </MenuItem>))

const FROM_NUMBER = '+17372528778'

const PART_VENDORS_CONNECTION = gql`
  query partVendorsConnection($search: JSON, $order: JSON, $filter: JSON, $first: Int, $after: String) {
    partVendorsConnection(search: $search, order: $order, filter: $filter, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          name
          googleMapUrl
          addressLineOne
          addressLineTwo
          city
          state
          zip
          phone
          email
          website
          paymentNotes
          createdAt
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  headerFormControl: {
    minWidth: '5em'
  },
  dateComponent: {
    fontSize: '12px',
    margin: '0px -100px -2px 0px',
    float: 'left',
    maxHeight: '27px',
    padding: '3px 7px'
  },
  dateDialog: {
    display: 'flex',
    '& div': {
      padding: '5px',
      '& span': {
        background: '#eee',
        display: 'block',
        padding: '3px',
        borderRadius: '3px 3px 0px 0px',
        border: '1px solid #ccc',
        borderBottom: '1px dashed #eee'
      },
      '& input': {
        padding: '3px',
        borderRadius: '0px 0px 3px 3px',
        border: '1px solid #ccc',
        borderTop: '0px'
      }
    }
  }
}))

const StateSelect = ({ value, onChange }) => {
  return (
    <FormControl
      variant='standard'
      id='state'
    >
      <InputLabel shrink>State</InputLabel>
      <Select
        label='State'
        value={value}
        onChange={onChange}
      >
        {stateMenuItems}
      </Select>
    </FormControl>
  )
}

const PartVendorsIndexWithSearch = () => {
  const [query, setQuery] = useState('')

  const handleChange = event => {
    setQuery(event.target.value.trim())
  }

  return (
    <>
      <br />
      <Box>
        <TextField
          size='small'
          variant='outlined'
          label='Search by name'
          value={query}
          onChange={handleChange}
        />
        {query && query.length > 0 &&
          <IconButton
            aria-label='clear state filter'
            component='span'
            onClick={() => { setQuery('') }}
          >
            <CloseIcon />
          </IconButton>}
      </Box>
      <br />
      <PartVemdorsIndex query={query} />
    </>
  )
}

const PartVemdorsIndex = ({ query }) => {
  const classes = useStyles()
  const [search, setSearch] = useState(JSON.parse(localStorage.getItem('partVendorsSearch')) || {})
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem('partVendorsOrder')) || { name: 'asc' })
  const [filter, setFilter] = useState(JSON.parse(localStorage.getItem('partVendorsFilter')) || {})
  const [limit, setLimit] = useState(JSON.parse(localStorage.getItem('partVendorsLimit')) || 50)
  useEffect(() => localStorage.setItem('partVendorsSearch', JSON.stringify(search)), [search])
  useEffect(() => localStorage.setItem('partVendorsOrder', JSON.stringify(order)), [order])
  useEffect(() => localStorage.setItem('partVendorsFilter', JSON.stringify(filter)), [filter])
  useEffect(() => localStorage.setItem('partVendorsLimit', JSON.stringify(limit)), [limit])

  const [debouncedSearch] = useDebounce(search, 500, { leading: false })

  const [page, setPage] = useState(0)
  const [firstRender, setFirstRender] = useState(true)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  const removeFilter = filterField => {
    const { [filterField]: removed, ...remainingFilters } = filter
    setFilter(remainingFilters)
  }

  const removeSearch = searchField => {
    const { [searchField]: removed, ...remainingSearches } = search
    setSearch(remainingSearches)
  }

  const setOrToggleOrder = name => {
    setOrder({
      [name]: (order[name] && order[name] === 'desc' ? 'asc' : 'desc') || 'desc'
    })
  }

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: limit,
        after: ''
      }
    })
  }, [debouncedSearch, order, filter, limit])

  useEffect(() => {
    if (query === '') {
      removeSearch('name')
    } else {
      setSearch({ ...search, name: query })
    }
  }, [query])

  const { loading, error, data, fetchMore } = useQuery(PART_VENDORS_CONNECTION, {
    variables: {
      search,
      order,
      filter,
      first: limit,
      after: cursorCeiling
    }
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const list = data.partVendorsConnection.edges.map(edge => edge.node)
  const totalCount = data.partVendorsConnection.totalCount
  const endCursor = data.partVendorsConnection.pageInfo.endCursor

  const OrderableHeadCell = ({ attr }) => {
    return (
      <TableCell style={{ cursor: 'pointer' }} onClick={() => setOrToggleOrder(attr)}>
        {attr}

        <Button style={{ minWidth: 0 }} color='primary' disableElevation>
          <SvgIcon
            fontSize='small'
            sx={{ color: '#000000' }}
            component={(order[attr] === 'desc' ? ArrowDownwardIcon : ArrowUpwardIcon) || ArrowDownwardIcon}
          />
        </Button>

      </TableCell>
    )
  }

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: limit,
          after: endCursor
        }
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <OrderableHeadCell attr='name' />
            <TableCell align='left'>address</TableCell>
            <TableCell align='left'>city</TableCell>
            <TableCell align='left' style={{ maxWidth: '180px' }}>
              <StateSelect
                style={{ margin: '8px', width: '90%' }}
                value={filter.state ? filter.state.eq : 'all'}
                onChange={evt => {
                  setPage(0)
                  setPageCeiling(0)
                  if (evt.target.value === 'all') {
                    removeFilter('state')
                  } else {
                    setFilter({ ...filter, state: { eq: evt.target.value } })
                  }
                }}
              />
              {filter.state && filter.state.eq &&
                <IconButton
                  aria-label='clear state filter'
                  component='span'
                  style={{ marginTop: '8px' }}
                  onClick={() => {
                    setPage(0)
                    setPageCeiling(0)
                    removeFilter('state')
                  }}
                >
                  <CloseIcon />
                </IconButton>}
            </TableCell>
            <TableCell align='right'>zip</TableCell>
            <TableCell align='right'>phone</TableCell>
            <TableCell align='right'>actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.slice(page * limit, (page * limit) + limit).map(item =>
            <TableRow key={item.id}>
              <TableCell component='th'>
                <Link to={`/part-vendors/${item.id}`}>
                  {item.name}
                </Link>
              </TableCell>
              <TableCell align='left'>{item.addressLineOne}</TableCell>
              <TableCell align='left'>{item.city}</TableCell>
              <TableCell align='left'>{item.state}</TableCell>
              <TableCell align='right'>{item.zip}</TableCell>
              <TableCell align='right'>
                <a href={`carbodylab-phone://dial?toNumber=${item.phone}&fromNumber=${FROM_NUMBER}`}>
                  {prettyPhoneNumber(item.phone)}
                </a>
              </TableCell>
              <TableCell align='right'>action..</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={totalCount}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default PartVendorsIndexWithSearch
