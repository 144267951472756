import { Box } from '@mui/material'

export const AutoglassSupplierItem = ({ glass }) => {
  return (
    <Box sx={[{ padding: '.25rem', borderBottom: '1px solid #f1f1f1' }]}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{}}>
          {glass?.warehouse} - {glass?.price && '$'}
          {glass?.price || glass?.error}
        </Box>
      </Box>
    </Box>
  )
}
