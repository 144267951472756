import { JobBlockProps } from './types'
import { Box, Checkbox } from '@mui/material'
import { FC, useContext, useState } from 'react'
import { DateTime } from 'luxon'
import { UserContext } from 'UserStore'
import {
  getVisitCount,
  JobValue,
  PartStatus,
  TravelDistanceFromLeadIdToCoordinates,
  VisitCount,
} from 'Leads/AvailabilityMap'
import { Link } from 'react-router-dom'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { keysToCamel } from 'tools'
import { DayLaborValue } from 'Schedule/components/DayLaborValue'

export const JobBlock: FC<JobBlockProps> = ({
  refetch,
  job,
  setSelectedJob,
  selectedJob,
  techCanSwapWithSelectedJob,
  leadCoordinates,
  showDrivingDistance,
}) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [showLoading, setShowLoading] = useState(false)
  const quoteIsFullyPaid = job?.quote?.paymentStatus === 'fully_paid' || false
  const visitCount = getVisitCount({ job })

  const handleSwapJob = () => {
    if (window.confirm('Are you sure you want to swap these jobs?')) {
      setShowLoading(true)
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/swap_job`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          swap_job_id: selectedJob.id,
          job_id: job.id,
        }),
      }).then(response => {
        if (response.ok) {
          response.json().then(res => {
            console.log(res)
            if (Object.keys(res).includes('error')) {
              alert(Object.values(res)[0])
            } else {
              setSelectedJob(null)
              return refetch()
            }
            setShowLoading(false)
          })
        } else {
          alert('Error. The selected technician may not be have availability that fits this job.')
        }
      })
    }
  }

  const checkSubset = (parentArray: Array<string>, subsetArray: Array<string>) => {
    return subsetArray.every((el: string) => {
      return parentArray.includes(el)
    })
  }
  const selectedJobSkillsets = selectedJob && job.quote.requiredSkillsets.concat([job.quote.difficultyLevel])

  const selectedJobTechCanSwapWithThisJob =
    techCanSwapWithSelectedJob &&
    selectedJobSkillsets &&
    checkSubset(selectedJob.technician.skillsets, selectedJobSkillsets)

  const marketMismatch = selectedJob && selectedJob.lead.market !== job.lead.market

  const displayWarningMessage =
    selectedJob && (!selectedJobTechCanSwapWithThisJob || marketMismatch) && selectedJob !== job

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={[
          {
            position: 'relative',
            padding: '0px 4px 0px 4px',
            borderRadius: '3px',
            minHeight: '40px',
            border: '1px solid #ddd',
            justifyContent: 'space-between',
            mb: '.5rem',
            width: '100%',
            background: quoteIsFullyPaid ? '#70FFB9' : '#EEF8FE',
          },
          displayWarningMessage && { opacity: 0.25, border: '2px dotted red' },
        ]}
      >
        <Box
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            right: 0,
            borderRadius: '5px',
            '&:hover': {
              boxShadow: '0px 0px 6px -1px red',
            },
            '& span': {
              padding: '0px',
            },
          }}
        >
          <Checkbox
            checked={selectedJob === job}
            onChange={() => (selectedJob === job ? setSelectedJob(null) : setSelectedJob(job))}
            size='small'
          />
        </Box>
        {visitCount && quoteIsFullyPaid && <b style={{ fontSize: '11px', display: 'block' }}>FULLY PAID</b>}
        <span style={{ fontSize: '11px' }}>
          <VisitCount job={job} />
        </span>
        <b style={{ fontSize: '11px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {' '}
          {job.lead.name}{' '}
        </b>
        <Link style={{ fontSize: '11px' }} to={`/leads/${job.leadId}/quotes/${job.quoteId}/jobs/${job.id}`}>
          {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)} -{' '}
          {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)}
        </Link>
        <br />
        <PartStatus quote={job.quote} />
        <span style={{ fontSize: '11px' }}>
          <DayLaborValue dateIsCurrentDay={false} jobsForDay={[keysToCamel(job)]} />
        </span>
        {selectedJobTechCanSwapWithThisJob && <button onClick={handleSwapJob}>swap</button>}
      </Box>
      {displayWarningMessage && (
        <b
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '0px',
            width: '100%',
            height: '100%',
            background: '#ffffff40',
            backdropFilter: 'blur(3px)',
            fontSize: '11px',
            color: 'red',
          }}
        >
          {marketMismatch ? 'MARKET MISMATCH' : 'SKILLSET MISMATCH'}
        </b>
      )}
      <Box>
        {!displayWarningMessage && showDrivingDistance && leadCoordinates?.lat && leadCoordinates?.lng && (
          <TravelDistanceFromLeadIdToCoordinates text='this job to selected job' fromLeadId={job.lead.id} toCoordinates={leadCoordinates} />
        )}
      </Box>
    </Box>
  )
}
