import {
  Typography,
  Container,
  Grid,
  Grow,
  Collapse,
  Select,
  MenuItem,
  FormControl,
  Drawer,
  InputLabel,
  Button,
  TextField,
  Modal,
  Box,
} from '@mui/material'
import { KeyboardArrowDown, Cancel, DownloadRounded, InfoRounded, LaunchRounded } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useQuery, gql } from '@apollo/client'
import { useEffect, useState, useContext } from 'react'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { PriceInput, Dollars, keysToCamel } from 'tools'
import { DateTime } from 'luxon'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import Chat from 'Chat'
import { Link } from 'react-router-dom'
import { UserContext } from 'UserStore'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { CreateATask } from 'Tasks'
import { PDFDownloadLink } from '@react-pdf/renderer'
import InvoicePDF from './InvoicePDF'
import PartsTable from 'BToB/WorkOrders/WorkOrder/Components/PartsTable'
import { PDFEmailer } from './Components/PDFEmailer'
import { LineItemsSection } from './Components/LineItems/LineItems'

const styles = {
  photoExpandStyle: {
    border: '1px solid #ddd',
    fontSize: '14px',
    borderRadius: '0px 0px 6px 6px',
    display: 'flex',
    padding: '0.25rem 0.5rem',
    background: '#f1f1f1',
    justifyContent: 'center',
    alignItems: 'center',
  },
  photoCancelButtonStyle: {
    zIndex: 999,
    position: 'absolute',
    top: '5px',
    right: '5px',
    fontSize: '30px',
    color: '#fff',
    border: '2px solid #fff',
    borderRadius: '20px',
  },
  sectionHeaderStyle: {
    borderRadius: '6px 6px 0px 0px',
    fontWeight: 700,
    fontSize: '14px',
    padding: '0.5rem 1rem',
    background: '#bbc7d3',
    display: 'flex',
    alignItems: 'center',
  },
  sectionContainerStyle: {
    mb: '0.5rem',
    mt: '0.5rem',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '6px',
    maxWidth: '100%',
  },
  photoContainerStyle: {
    background: '#fff',
    padding: '1rem 0.5rem',
    border: '1px solid #ddd',
    borderBottom: '0',
    borderTop: '0',
    textAlign: 'center',
  },
  uploadButtonContainerStyle: {
    border: '1px solid #ddd',
    fontSize: '14px',
    borderRadius: '0px 0px 6px 6px',
    display: 'flex',
    padding: '0.25rem 0.5rem',
    background: '#f1f1f1',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '700px',
  maxHeight: '80%',
  width: '1100px',
  maxWidth: '100%',
  boxShadow: 24,
  border: '1px solid black',
}

const TECHNICIANS_QUERY = gql`
  query getTechnicians($filter: JSON) {
    technicians(filter: $filter) {
      id
      name
    }
  }
`

const VEHICLE_YEARS_QUERY = gql`
  query fgVehicleYears {
    fgVehicleYears
  }
`

const VEHICLE_MAKE_QUERY = gql`
  query fgVehicleMakes($year: String) {
    fgVehicleMakes(year: $year) {
      makeId
      makeName
      makeShortName
    }
  }
`

const VEHICLE_MODEL_QUERY = gql`
  query fgVehicleModels($year: String, $makeShortName: String) {
    fgVehicleModels(year: $year, makeShortName: $makeShortName) {
      modelId
      modelName
      modelShortName
    }
  }
`

const PayrollPopup = ({ invoice, refetch }) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)
  const [startEmailer, setStartEmailer] = useState(false)

  const updateAttributes = attr =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        updatedAttributes: attr,
        actorType: 'csr',
        actorId: user.id,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const lockedToCsr = false

  const VehiclePicker = () => {
    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4} sx={{ pr: '.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Year</InputLabel>
              <Select disabled value={invoice.year} label='year'></Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} sx={{ pl: '.5rem', pr: '.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Make</InputLabel>
              <Select value={invoice.make} label='make' disabled>
                {invoice.make && (
                  <MenuItem disabled value={invoice.make}>
                    {invoice.make}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} sx={{ pl: '.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Model</InputLabel>
              <Select value={invoice.model} label='model' disabled>
                {invoice.model && (
                  <MenuItem disabled value={invoice.model}>
                    {invoice.model}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const Stock = () => {
    const [editableStock, setEditableStock] = useState(invoice.stock)
    const [waiting, setWaiting] = useState(false)

    const handleChangeStock = e => setEditableStock(e.target.value)

    const handleClickSaveStock = () => {
      setWaiting(true)

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBInvoiceId: invoice.id,
          updatedAttributes: { stock: editableStock.trim() },
          actorType: 'csr',
          actorId: user.id,
        }),
      })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl size='small'>
          <TextField
            value={editableStock}
            label='Stock'
            disabled={!!lockedToCsr}
            onChange={handleChangeStock}
            size='small'
          />
        </FormControl>
        <Button
          disabled={waiting || editableStock === null || editableStock.trim() === invoice.stock}
          onClick={handleClickSaveStock}
        >
          Save
        </Button>
      </Grid>
    )
  }

  const RepairOrder = () => {
    const [editableRepairOrder, setEditableRepairOrder] = useState(invoice.repairOrder)
    const [waiting, setWaiting] = useState(false)

    const handleChangeRepairOrder = e => setEditableRepairOrder(e.target.value)

    const handleClickSaveRepairOrder = () => {
      setWaiting(true)

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBInvoiceId: invoice.id,
          updatedAttributes: { repairOrder: editableRepairOrder.trim() },
          actorType: 'csr',
          actorId: user.id,
        }),
      })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl size='small'>
          <TextField
            value={editableRepairOrder}
            label='RO'
            disabled={!!lockedToCsr}
            onChange={handleChangeRepairOrder}
            size='small'
          />
        </FormControl>
        <Button
          disabled={waiting || editableRepairOrder === null || editableRepairOrder.trim() === invoice.repairOrder}
          onClick={handleClickSaveRepairOrder}
        >
          Save
        </Button>
      </Grid>
    )
  }

  const Vin = () => {
    const [editableVin, setEditableVin] = useState(invoice.vin)
    const [waiting, setWaiting] = useState(false)

    const handleChangeVin = e => setEditableVin(e.target.value)

    const handleClickSaveVin = () => {
      setWaiting(true)

      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            updatedAttributes: { vin: editableVin.trim() },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl size='small'>
          <TextField value={editableVin} label='VIN' disabled={!!lockedToCsr} onChange={handleChangeVin} size='small' />
        </FormControl>
        <Button
          disabled={waiting || editableVin === null || editableVin.trim() === invoice.vin}
          onClick={handleClickSaveVin}
        >
          Save
        </Button>
      </Grid>
    )
  }

  const PayoutOverrideTotal = () => {
    const [payoutOverrideTotal, setPayoutOverrideTotal] = useState(invoice.payoutOverrideTotal)
    const [waiting, setWaiting] = useState(false)

    const handleChangePayoutOverride = e => setPayoutOverrideTotal(e.target.value)

    const handleClickSavePayoutOverride = () => {
      setWaiting(true)

      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            updatedAttributes: { payout_override_total: Number(payoutOverrideTotal) },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl size='small'>
          <TextField
            value={payoutOverrideTotal}
            label='Payout override'
            disabled={!!lockedToCsr}
            onChange={handleChangePayoutOverride}
            size='small'
          />
        </FormControl>
        <Button
          disabled={
            waiting ||
            payoutOverrideTotal === null ||
            Number(payoutOverrideTotal) === Number(invoice.payoutOverrideTotal)
          }
          onClick={handleClickSavePayoutOverride}
        >
          Save
        </Button>
      </Grid>
    )
  }

  const VinPhoto = () => {
    const [modalPhotoUrl, setModalPhotoUrl] = useState(null)

    return (
      <>
        <Grid container sx={styles.sectionContainerStyle}>
          <Grid item xs={12} sx={styles.sectionHeaderStyle}>
            VIN Photo {invoice.vinPhoto ? '' : '(NONE)'}
          </Grid>

          <Grid item xs={12}>
            {!!invoice.vinPhoto && (
              <div style={{ height: '200px' }}>
                <PinchZoomPan position='center' maxScale={3}>
                  <img
                    onClick={() => setModalPhotoUrl(invoice.vinPhoto.originalUrl)}
                    src={invoice.vinPhoto?.originalUrl}
                  />
                </PinchZoomPan>
              </div>
            )}

            <Modal open={!!modalPhotoUrl} onClose={() => setModalPhotoUrl(null)}>
              <Box sx={modalStyle}>
                <div
                  style={{
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Cancel sx={styles.photoCancelButtonStyle} onClick={() => setModalPhotoUrl(null)} />
                  <PinchZoomPan position='center' maxScale={2}>
                    <img src={modalPhotoUrl} />
                  </PinchZoomPan>
                </div>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </>
    )
  }

  const PreJobPhotos = () => {
    const [openPreJobPhotosDrawer, setOpenPreJobPhotosDrawer] = useState(false)
    const [expandPhotoSection, setExpandPhotoSection] = useState(false)
    const [currentFullScreenPhoto, setCurrentFullScreenPhoto] = useState(null)

    const preJobPhotos = invoice.preJobPhotos || []

    return (
      <>
        <Grid container sx={styles.sectionContainerStyle}>
          <Grid item xs={12} sx={styles.sectionHeaderStyle}>
            PRE-Job Photos
            {preJobPhotos.length > 0 && <> &nbsp;({preJobPhotos.length})</>}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              borderRadius: preJobPhotos.length > 0 && preJobPhotos.length < 8 ? '0px 0px 6px 6px' : '0px',
              ...styles.photoContainerStyle,
            }}
          >
            {(preJobPhotos.length > 0 && (
              <Grid container spacing={2}>
                {preJobPhotos.map(
                  (photo, index) =>
                    (expandPhotoSection || index < 8) && (
                      <Grid item xs={3} sm={3} md={2} key={photo.originalUrl}>
                        <Grid
                          onClick={() => setCurrentFullScreenPhoto(photo)}
                          sx={{
                            borderRadius: '3px',
                            minHeight: '75px',
                            background: `url(${photo.originalUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                          }}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            )) ||
              'No photos uploaded yet'}
          </Grid>

          {preJobPhotos?.length > 8 && (
            <Grid
              item
              xs={12}
              onClick={() => setExpandPhotoSection(!expandPhotoSection)}
              sx={{
                ...styles.photoExpandStyle,
                '&:active': {
                  background: '#eee',
                },
              }}
            >
              See
              {expandPhotoSection ? ' less' : ' all'}
              <KeyboardArrowDown
                sx={{
                  transform: expandPhotoSection ? 'rotate(180deg)' : '',
                  ml: '1rem',
                  transition: 'all 0.25s ease-in-out',
                }}
              />
            </Grid>
          )}

          {preJobPhotos?.length < 1 && <Grid item xs={12} sx={styles.uploadButtonContainerStyle}></Grid>}
        </Grid>

        <Modal open={!!currentFullScreenPhoto} onClose={() => setCurrentFullScreenPhoto(null)}>
          <Box sx={modalStyle}>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <Cancel sx={styles.photoCancelButtonStyle} onClick={() => setCurrentFullScreenPhoto(null)} />
              <PinchZoomPan position='center' maxScale={2}>
                <img src={currentFullScreenPhoto && currentFullScreenPhoto.originalUrl} />
              </PinchZoomPan>
            </div>
          </Box>
        </Modal>
      </>
    )
  }

  const PostJobPhotos = () => {
    const [showPostJobPhotosDrawer, setShowPostJobPhotosDrawer] = useState(false)
    const { postJobPhotos } = invoice
    const [expandPhotoSection, setExpandPhotoSection] = useState(false)
    const [currentFullScreenPhoto, setCurrentFullScreenPhoto] = useState(null)

    return (
      <>
        <Grid container sx={styles.sectionContainerStyle}>
          <Grid item xs={12} sx={styles.sectionHeaderStyle}>
            POST-Job Photos
            {postJobPhotos?.length > 0 && <>&nbsp;({postJobPhotos.length})</>}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              borderRadius: postJobPhotos.length > 0 && postJobPhotos.length < 8 ? '0px 0px 6px 6px' : '0px',
              ...styles.photoContainerStyle,
            }}
          >
            {(postJobPhotos?.length > 0 && (
              <Grid container spacing={2}>
                {postJobPhotos.map(
                  (photo, index) =>
                    (expandPhotoSection || index < 8) && (
                      <Grid item xs={3} sm={3} md={2} key={photo.originalUrl}>
                        <Grid
                          onClick={() => setCurrentFullScreenPhoto(photo)}
                          sx={{
                            borderRadius: '3px',
                            minHeight: '75px',
                            background: `url(${photo.originalUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                          }}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            )) ||
              'No photos uploaded yet'}
          </Grid>

          {postJobPhotos?.length > 0 ? (
            postJobPhotos.length > 8 && (
              <Grid
                item
                xs={12}
                onClick={() => setExpandPhotoSection(!expandPhotoSection)}
                sx={{
                  ...styles.photoExpandStyle,
                  '&:active': { background: '#eee' },
                }}
              >
                See
                {expandPhotoSection ? ' less' : ' all'}
                <KeyboardArrowDown
                  sx={{
                    transform: expandPhotoSection ? 'rotate(180deg)' : '',
                    ml: '1rem',
                    transition: 'all 0.25s ease-in-out',
                  }}
                />
              </Grid>
            )
          ) : (
            <Grid item xs={12} sx={styles.uploadButtonContainerStyle}></Grid>
          )}
        </Grid>

        <Modal open={!!currentFullScreenPhoto} onClose={() => setCurrentFullScreenPhoto(null)}>
          <Box sx={modalStyle}>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <Cancel sx={styles.photoCancelButtonStyle} onClick={() => setCurrentFullScreenPhoto(null)} />
              <PinchZoomPan position='center' maxScale={2}>
                <img src={currentFullScreenPhoto && currentFullScreenPhoto.originalUrl} />
              </PinchZoomPan>
            </div>
          </Box>
        </Modal>
      </>
    )
  }

  return (
    <Container maxWidth='sm'>
      <Grid container>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '.5rem' }}>
            <Typography variant='h6' sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
              {invoice.businessName} Invoice
            </Typography>
            <Button size='small' disableElevation variant='contained'>
              <DownloadRounded sx={{ mr: '.25rem', fontSize: '1rem' }} />
              <PDFDownloadLink
                style={{ color: 'unset', textDecoration: 'none' }}
                document={<InvoicePDF invoice={keysToCamel(invoice)} />}
                fileName={`${invoice.businessName} - ${DateTime.fromISO(invoice.completedAt).toFormat(
                  'MM-dd-yyyy'
                )} -  ${invoice.invoiceNumber}`}
              >
                {({ blob, url, loading, error }) => {
                  if (error) return JSON.stringify(error)
                  return loading ? 'Generating PDF...' : 'Download as PDF'
                }}
              </PDFDownloadLink>
            </Button>
          </Box>
          <Link target='_blank' to={`/technicians/${invoice.technician.id}`}>
            {invoice.technician.firstName} {invoice.technician.lastName}
          </Link>
          &nbsp;&nbsp;
          <span style={{ opacity: 0.75, fontSize: '14px' }}>
            Created:{' '}
            <Link to={`/b-to-b-invoices/${invoice.id}`}>{DateTime.fromISO(invoice.createdAt).toLocaleString()}</Link>
          </span>
        </Grid>
      </Grid>

      <VehiclePicker />
      <VinPhoto />
      <Vin />
      <RepairOrder />
      {invoice.businessName === 'carmax' && <Stock />}
      <PreJobPhotos />
      <LineItemsSection invoice={invoice} />
      <PostJobPhotos />

      {invoice.workOrder && (
        <Grid item xs={12}>
          <Grid container sx={{ border: '1px solid #ddd', borderRadius: '6px', background: '#fff', mt: '1rem' }}>
            <Grid
              item
              xs={12}
              sx={{
                borderRadius: '6px 6px 0px 0px',
                fontWeight: 700,
                fontSize: '14px',
                padding: '.5rem 1rem',
                display: 'flex',
                alignItems: 'center',
                background: '#b5d9ff',
                justifyContent: 'space-between',
              }}
            >
              <span>Add Parts</span>
              <Link
                to={`/b-to-b/work-orders/${invoice.workOrder.id}`}
                style={{
                  textAlign: 'center',
                  color: '#111',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Open Part Order <LaunchRounded />
              </Link>
            </Grid>

            <Grid item xs={12}>
              <PartsTable
                refetch={refetch}
                workOrder={invoice.workOrder}
                parts={invoice.workOrder.parts}
                editingDisabled
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid container sx={{ ...styles.sectionContainerStyle, border: '1px solid #ddd' }}>
        <Grid item xs={12} sx={styles.sectionHeaderStyle}>
          Chat
        </Grid>
        <Grid item xs={12} sx={{ padding: '0.5rem' }}>
          <Chat contextId={invoice.id} contextType='BToBInvoice' />
        </Grid>
      </Grid>

      <CreateATask targetType='BToBInvoice' targetId={invoice.id} />
    </Container>
  )
}

export default PayrollPopup
