import { Box, Tabs, Tab } from '@mui/material'
import MarketsRouter from './Markets/Router'
import YearMakePremiumsRouter from './YearMakePremiums/Router'
import TireConnectPricingRouter from './TireConnectPricing/Router'
import OmegaEdiPricingRouter from './OmegaEdiPricing/Router'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { OtherLaborCatalogItemsTable } from './OtherLaborCatalogItems/OtherLaborCatalogItemsTable'
import { TechChargeCatalogItemsTable } from './TechChargesCatalogItems/TechChargesCatalogItemsTable'
import { CarbodylabChargeCatalogItemsTable } from './CarbodylabChargesCatalogItems/CarbodylabChargesCatalogItemsTable'
import { LaborItemsTable } from './LaborItems/LaborItemsTable'
import { PartCatalogItemsTable } from './PartCatalogItems/PartCatalogItemsTable'
import DamageItemsTable from './DamageItems/Router'

const Index = () => {
  const [mainTab, setMainTab] = useLocalStorage('mainConfigTab', 'quoting')
  const [tab, setTab] = useLocalStorage('quotePricingTab', 'labor-items')

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant='scrollable' scrollButtons='auto' value={mainTab} onChange={(e, newTab) => setMainTab(newTab)}>
          <Tab value='quoting' label='quoting' />
          <Tab value='rates' label='rates & pricing' />
        </Tabs>

        {(mainTab === 'quoting' || mainTab === 'rates') && (
          <Tabs variant='scrollable' scrollButtons='auto' value={tab} onChange={(e, newTab) => setTab(newTab)}>
            {mainTab === 'quoting' && <Tab value='damage-items' label='damages' />}
            {mainTab === 'quoting' && <Tab value='panels-list' label='Primary Labor Items' />}
            {mainTab === 'quoting' && <Tab value='parts-list' label='Parts list' />}{' '}
            {mainTab === 'quoting' && <Tab value='other-labor-items' label='Other Labor items' />}
            {mainTab === 'quoting' && <Tab value='tech-charges-items' label='Tech Charges items' />}
            {mainTab === 'quoting' && <Tab value='cbl-charges-items' label='Carbodylab Charges items' />}
            {mainTab === 'rates' && <Tab value='markets' label='market rates' />}
            {mainTab === 'rates' && <Tab value='ym-premiums' label='year/make premium rates' />}
            {mainTab === 'rates' && <Tab value='tire-connect-pricing' label='tire connect pricing' />}
            {mainTab === 'rates' && <Tab value='omega-edi-pricing' label='omega edi pricing' />}
          </Tabs>
        )}
      </Box>

      {tab === 'damage-items' && mainTab === 'quoting' && <DamageItemsTable />}
      {tab === 'panels-list' && mainTab === 'quoting' && <LaborItemsTable />}
      {tab === 'parts-list' && mainTab === 'quoting' && <PartCatalogItemsTable />}
      {tab === 'other-labor-items' && mainTab === 'quoting' && <OtherLaborCatalogItemsTable />}
      {tab === 'tech-charges-items' && mainTab === 'quoting' && <TechChargeCatalogItemsTable />}
      {tab === 'cbl-charges-items' && mainTab === 'quoting' && <CarbodylabChargeCatalogItemsTable />}
      {tab === 'markets' && mainTab === 'rates' && <MarketsRouter />}
      {tab === 'ym-premiums' && mainTab === 'rates' && <YearMakePremiumsRouter />}
      {tab === 'tire-connect-pricing' && mainTab === 'rates' && <TireConnectPricingRouter />}
      {tab === 'omega-edi-pricing' && mainTab === 'rates' && <OmegaEdiPricingRouter />}
    </Box>
  )
}

export default Index
