import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Appointment from './Appointment/Router'
import Index from './Index'

const Router = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:appointmentId`}>
        <Appointment />
      </Route>
      <Route path={`${path}/`}>
        <Index />
      </Route>
    </Switch>
  )
}

export default Router
