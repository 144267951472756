import { useQuery } from '@apollo/client'
import { JOB_APPLICATIONS_CONNECTION } from './query'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useLocation } from 'react-router-dom'

export const useJobApplications = (initialFilter: any) => {
  const [order, setOrder] = useState({})

  const location = useLocation()
  const { pathname } = location
  const [search, setSearch] = useLocalStorage(`${pathname}-search`, {}, true)
  const [filter, setFilter] = useState(initialFilter ? initialFilter : {})

  const [limit, setLimit] = useState(25)
  const [cursorCeiling, setCursorCeiling] = useState('')

  const { data, loading, fetchMore } = useQuery<any>(JOB_APPLICATIONS_CONNECTION, {
    variables: {
      search,
      order,
      filter,
      first: limit,
      after: cursorCeiling,
    },
  })
  const jobApplicationsRaw = data?.jobApplicationsConnection?.edges?.map((edge: any) => edge.node) ?? []
  const totalCount = data?.jobApplicationsConnection?.totalCount || 0
  const endCursor = data?.jobApplicationsConnection?.pageInfo?.endCursor || 0

  const jobApplications = jobApplicationsRaw

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: limit,
        after: '',
        cursorCeiling,
      },
    })
  }, [search, order, filter, limit])

  return {
    jobApplications,
    loading,
    filter,
    setFilter,
    order,
    setOrder,
    limit,
    setLimit,
    setCursorCeiling,
    totalCount,
    fetchMore,
    endCursor,
    search,
    setSearch,
  }
}
