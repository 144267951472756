import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { makeStyles } from '@mui/styles'
import {
  Autocomplete, Dialog, DialogContent, DialogActions, DialogTitle, Box, Chip,
  FormControlLabel, Checkbox, CircularProgress, Switch, Collapse, Paper, Button,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
  TableSortLabel, TextField
} from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { markets } from 'constants.js'
import FilterListIcon from '@mui/icons-material/FilterList'
import SvgIcon from '@mui/material/SvgIcon'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import leadLabels from 'data/leadLabels'
import { GlobalContext } from 'GlobalStore'

const LEADS = gql`
  query LeadsConnection($search: JSON, $order: JSON, $filter: JSON, $first: Int, $after: String) {
    leadsConnection(search: $search, order: $order, filter: $filter, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          name
          email
          phone
          zip
          city
          state
          county
          market
          source
          createdAt
          noWay
          label
          interactionStatuses
          utmTerm
          utmMedium
          utmSource
          utmContent
          utmCampaign
          gclidField
          serviceType
          claimedByUserId
          quotes {
            fullyPaidAt
            quoteGrandTotal
            technician {
              name
            }
            jobs {
              id
            }
            parts {
              price
              cost
              name
            }
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const STATUSES_QUERY = gql`
  query interactionStatuses {
    interactionStatuses
  }
`
const useStyles = makeStyles(theme => ({
  headerFormControl: {
    minWidth: '5em'
  },
  dateComponent: {
    fontSize: '12px',
    margin: '0px -100px -2px 0px',
    float: 'left',
    maxHeight: '27px',
    padding: '3px 7px'
  },
  dateDialog: {
    display: 'flex',
    '& div': {
      padding: '5px',
      '& span': {
        background: '#eee',
        display: 'block',
        padding: '3px',
        borderRadius: '3px 3px 0px 0px',
        border: '1px solid #ccc',
        borderBottom: '1px dashed #eee'
      },
      '& input': {
        padding: '3px',
        borderRadius: '0px 0px 3px 3px',
        border: '1px solid #ccc',
        borderTop: '0px'
      }
    }
  }
}))

const LeadsIndex = () => {
  const [global] = useContext(GlobalContext)
  const classes = useStyles()
  const [ballerMode, setBallerMode] = useState(
    (localStorage.getItem('ballerMode') && localStorage.getItem('ballerMode') === 'true') || false
  )
  const [search, setSearch] = useState(
    JSON.parse(localStorage.getItem('extendedLeadsSearch')) || {
      exclude_no_way: false,
      exclude_leads_with_jobs: false,
      exclude_leads_without_quotes: true,
      exclude_all_except_leads_without_quotes: false
    }
  )
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem('leadsOrder')) || {})
  const [filter, setFilter] = useState(
    JSON.parse(localStorage.getItem('extendedLeadsFilter')) || {
      created_at: { ge: DateTime.now().minus({ weeks: 1 }).toISO() }
    }
  )
  const [limit, setLimit] = useState(JSON.parse(localStorage.getItem('leadsLimit')) || 50)
  useEffect(() => localStorage.setItem('extendedLeadsSearch', JSON.stringify(search)), [search])
  useEffect(() => {
    localStorage.setItem('leadsOrder', JSON.stringify(order))
  }, [order])
  useEffect(() => {
    localStorage.setItem('extendedLeadsFilter', JSON.stringify(filter))
  }, [filter])
  useEffect(() => {
    localStorage.setItem('leadsLimit', JSON.stringify(limit))
  }, [limit])
  useEffect(() => localStorage.setItem('ballerMode', ballerMode), [ballerMode])

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  const handleChangeInteractionStatuses = v => setFilter({ ...filter, interaction_statuses: { all: v } })

  const removeFilter = filterName => {
    const { [filterName]: removed, ...remainingFilters } = filter
    setFilter(remainingFilters)
  }

  const setOrToggleOrder = name => {
    setOrder({
      ...order,
      [name]: (order[name] && order[name] === 'desc' ? 'asc' : 'desc') || 'desc'
    })
  }

  const {
    error: statusesError,
    loading: loadingStatuses,
    data: statusesData
  } = useQuery(STATUSES_QUERY, {
    fetchPolicy: 'network-only'
  })

  const interactionStatuses = statusesData?.interactionStatuses || []

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: limit,
        after: ''
      }
    })
  }, [search, order, filter, limit])

  useEffect(() => {
    !ballerMode &&
      setSearch(prev => ({
        exclude_no_way: prev.exclude_no_way,
        exclude_leads_with_jobs: false,
        exclude_leads_without_quotes: false,
        exclude_all_except_leads_without_quotes: false
      }))
  }, [ballerMode])

  const { loading, error, data, fetchMore } = useQuery(LEADS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      search,
      order,
      filter,
      first: limit,
      after: cursorCeiling
    }
  })

  // if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const leads = data?.leadsConnection?.edges?.map(edge => edge.node) || []
  const totalCount = data?.leadsConnection?.totalCount || 0
  const endCursor = data?.leadsConnection?.pageInfo?.endCursor || 0

  const LabelSelect = () => {
    const [value, setValue] = useState(filter.label ? filter.label.eq : 'all')

    const handleChange = evt => {
      setPage(0)
      setPageCeiling(0)
      if (evt.target.value === 'all') {
        removeFilter('label')
      } else {
        setFilter({ ...filter, label: { eq: evt.target.value } })
      }
    }

    return (
      <FormControl className={classes.headerFormControl}>
        <InputLabel shrink>Label</InputLabel>
        <Select variant='standard' value={value} onChange={handleChange}>
          <MenuItem value='all'>
            <i>all</i>
          </MenuItem>
          {leadLabels.map(label => (
            <MenuItem key={label} value={label}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const ServiceTypeSelect = () => {
    const [value, setValue] = useState(filter.service_type ? filter.service_type.eq : 'all')

    const handleChange = evt => {
      setPage(0)
      setPageCeiling(0)
      if (evt.target.value === 'all') {
        removeFilter('service_type')
      } else {
        setFilter({ ...filter, service_type: { eq: evt.target.value } })
      }
    }

    return (
      <FormControl sx={{ minWidth: '100px' }} size='small' className={classes.headerFormControl}>
        <InputLabel shrink>Service type</InputLabel>
        <Select label='service type' size='small' variant='outlined' value={value} onChange={handleChange}>
          <MenuItem value='all'>
            <i>all</i>
          </MenuItem>
          <MenuItem value='windshields'>windshields</MenuItem>
          <MenuItem value='autobody'>autobody</MenuItem>
          <MenuItem value='tires'>tires</MenuItem>
        </Select>
      </FormControl>
    )
  }

  const MarketSelect = () => {
    const [value, setValue] = useState((filter.market && filter.market.eq) || 'all')

    const handleChange = evt => {
      setPage(0)
      setPageCeiling(0)
      if (evt.target.value === 'all') {
        removeFilter('market')
      } else {
        setFilter({ ...filter, market: { eq: evt.target.value } })
      }
    }

    return (
      <FormControl className={classes.headerFormControl}>
        <InputLabel shrink>Market</InputLabel>

        <Select variant='standard' value={value} onChange={handleChange}>
          <MenuItem value='all'>all</MenuItem>

          {markets.map(market => (
            <MenuItem key={market} value={market}>
              {market}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const OwnerSelect = () => {
    const [value, setValue] = useState(filter?.claimed_by_user_id?.eq || 'all')

    const handleChange = evt => {
      setPage(0)
      setPageCeiling(0)
      if (evt.target.value === 'all') {
        removeFilter('claimed_by_user_id')
      } else {
        setFilter({ ...filter, claimed_by_user_id: { eq: evt.target.value } })
      }
    }

    return (
      <FormControl className={classes.headerFormControl}>
        <InputLabel shrink>Owner</InputLabel>

        <Select variant='standard' value={value} onChange={handleChange}>
          <MenuItem value='all'>all</MenuItem>

          {global.users.filter(u => u.roles.includes('can_claim_customer')).map(user => (
            <MenuItem key={user.id} value={user.id}>
              {user.firstName} {user.lastName[0]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const DateRangeHeadCell = ({ name }) => {
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [dateRange, setDateRange] = useState(filter[name] || {})
    // ONE of BOTH key of: { ge: iso8601, le: iso8601 } OR null
    // ge -> greater than or equal
    // le -> less than or equal

    const handleDateTimeChanged = evt => {
      setDateRange({ ...dateRange, [evt.target.name]: evt.target.value })
    }

    const handleClickApply = () => {
      setFilter({ ...filter, [name]: dateRange })
    }

    const handleClickClose = () => {
      setDialogIsOpen(false)
    }

    const handleDeleteRangeKey = key => {
      const { [key]: removed, ...rest } = dateRange
      setDateRange(rest)
      setFilter({ ...filter, [name]: rest })
    }

    return (
      <TableCell>
        {name}

        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={() => setOrToggleOrder(name)}>
          <SvgIcon
            fontSize='small'
            component={(order[name] === 'desc' ? ArrowDownwardIcon : ArrowUpwardIcon) || ArrowDownwardIcon}
          />
        </Button>

        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={() => setDialogIsOpen(true)}>
          <SvgIcon fontSize='small' component={FilterListIcon} />
        </Button>

        {filter[name] && Object.entries(filter[name]).length > 0 && (
          <>
            <br />
            {Object.entries(filter[name])
              .sort((a, b) => (a[0] > b[0] ? 1 : -1))
              .map(filterTuple => (
                <Chip
                  key={filterTuple[0]}
                  variant='outlined'
                  size='small'
                  onDelete={() => handleDeleteRangeKey(filterTuple[0])}
                  label={`
                    ${filterTuple[0] === 'le' ? 'before' : 'after'}
                    ${DateTime.fromISO(filterTuple[1]).toFormat('yy-MM-dd')}
                  `}
                />
              ))}
          </>
        )}

        <Dialog open={dialogIsOpen} onClose={setDialogIsOpen}>
          <DialogTitle>Select Date Range (One Or Both)</DialogTitle>

          <DialogContent className={classes.dateDialog}>
            <Box>
              <span>
                Start Date<small> (optional)</small>
              </span>
              <input type='datetime-local' name='ge' value={dateRange.ge || ''} onChange={handleDateTimeChanged} />
            </Box>
            <Box>
              <span>
                End Date<small> (optional)</small>
              </span>
              <input type='datetime-local' name='le' value={dateRange.le || ''} onChange={handleDateTimeChanged} />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClickClose} color='primary'>
              Close
            </Button>
            <Button onClick={handleClickApply} color='primary' autoFocus>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    )
  }

  const OrderableTableCell = ({ name, children }) => {
    return (
      <TableCell style={{ background: order[name] ? '#eee' : '#fff' }}>
        <TableSortLabel active={order[name]} direction={order[name]} onClick={() => setOrToggleOrder(name)}>
          {name}
          {children}
        </TableSortLabel>
      </TableCell>
    )
  }

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: limit,
          after: endCursor
        }
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <TableContainer component={Paper}>
      <Collapse in={ballerMode}>
        <Box sx={{ padding: '1rem', background: '#f1f1f1' }}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() =>
                  search.exclude_leads_with_jobs
                    ? setSearch(prev => ({
                      ...prev,
                      exclude_leads_with_jobs: false
                    }))
                    : setSearch(prev => ({
                      ...prev,
                      exclude_leads_with_jobs: true
                    }))}
                checked={!!search.exclude_leads_with_jobs}
                defaultChecked
              />
            }
            label='Hide leads with jobs'
          />
          <FormControlLabel
            control={
              <Checkbox
                onClick={() =>
                  search.exclude_leads_without_quotes
                    ? setSearch(prev => ({
                      ...prev,
                      exclude_leads_without_quotes: false,
                      exclude_all_except_leads_without_quotes: false
                    }))
                    : setSearch(prev => ({
                      ...prev,
                      exclude_leads_without_quotes: true,
                      exclude_all_except_leads_without_quotes: false
                    }))}
                checked={!!search.exclude_leads_without_quotes}
                defaultChecked
              />
            }
            label='Hide leads without quotes'
          />
          <FormControlLabel
            control={
              <Checkbox
                onClick={() =>
                  search.exclude_all_except_leads_without_quotes
                    ? setSearch(prev => ({
                      ...prev,
                      exclude_all_except_leads_without_quotes: false,
                      exclude_leads_without_quotes: false
                    }))
                    : setSearch(prev => ({
                      ...prev,
                      exclude_all_except_leads_without_quotes: true,
                      exclude_leads_without_quotes: false
                    }))}
                checked={!!search.exclude_all_except_leads_without_quotes}
                defaultChecked
              />
            }
            label='Only show leads without quotes'
          />
          <Button
            onClick={() => {
              setSearch({})
            }}
          >
            <CloseRounded sx={{ marginRight: '1rem' }} /> Clear all
          </Button>
        </Box>
      </Collapse>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              onClick={() =>
                search.exclude_no_way
                  ? setSearch(prev => ({
                    ...prev,
                    exclude_no_way: false
                  }))
                  : setSearch(prev => ({ ...prev, exclude_no_way: true }))}
              checked={search.exclude_no_way}
            />
          }
          label='Hide no way'
        />
        <FormControlLabel
          control={<Switch onClick={() => setBallerMode(!ballerMode)} checked={ballerMode} />}
          label='Enable baller mode'
        />
      </Box>
      <Table className={classes.table} size='small' aria-label='a dense table'>
        {loading && (
          <Box
            sx={{
              position: 'fixed',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#11111140',
              top: '0px',
              bottom: '0px',
              left: '0px',
              right: '0px'
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </Box>
        )}
        <TableHead>
          <TableRow>
            {ballerMode && <TableCell align='right'>quoted amounts</TableCell>}
            {ballerMode && <TableCell align='right'># of parts</TableCell>}
            <TableCell align='right'>name</TableCell>
            <TableCell align='right'>email</TableCell>
            <TableCell align='right'>phone</TableCell>
            <TableCell align='right'>zip</TableCell>
            <TableCell align='right'>county</TableCell>
            <TableCell align='right'>
              <ServiceTypeSelect />
            </TableCell>
            <TableCell align='right'>
              <Autocomplete
                sx={{ display: 'inline-block', minWidth: '150px' }}
                multiple
                size='small'
                options={interactionStatuses}
                disableCloseOnSelect
                value={filter.interactionStatuses?.all}
                onChange={(e, v) => handleChangeInteractionStatuses(v)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                      checkedIcon={<CheckBoxIcon fontSize='small' />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={params => <TextField size='small' {...params} label='Status' placeholder='Add a status' />}
              />
            </TableCell>

            <TableCell align='right'>
              <LabelSelect />
            </TableCell>

            <TableCell align='right'>
              <MarketSelect />
            </TableCell>

            <TableCell align='right'>
              <OwnerSelect />
            </TableCell>

            <DateRangeHeadCell name='created_at' />
          </TableRow>
        </TableHead>
        <TableBody>
          {leads.slice(page * limit, page * limit + limit).map(lead => (
            <TableRow key={lead.id}>
              {ballerMode && (
                <TableCell align='right'>
                  {lead?.quotes?.map(q => (
                    <Box>${q.quoteGrandTotal.toFixed(2)}</Box>
                  ))}
                </TableCell>
              )}
              {ballerMode && (
                <TableCell align='right'>
                  {lead?.quotes?.map(q => (
                    <Box>{q.parts.length}</Box>
                  ))}
                </TableCell>
              )}
              <TableCell component='th'>
                <Link to={`/leads/${lead.id}`}>{lead.name}</Link>
              </TableCell>
              <TableCell align='right'>{lead.email}</TableCell>
              <TableCell align='right'>{lead.phone}</TableCell>
              <TableCell align='right'>{lead.zip}</TableCell>
              <TableCell align='right'>{lead.county}</TableCell>
              <TableCell align='right'>{lead.serviceType}</TableCell>
              <TableCell align='right'>
                {lead.interactionStatuses?.map(status => (
                  <Chip key={status} label={status} variant='outlined' size='small' />
                ))}
              </TableCell>
              <TableCell align='right'>{lead.label || ''}</TableCell>
              <TableCell align='right'>{lead.market}</TableCell>
              <TableCell align='right'>{lead.claimedByUserId && global.users && global.users.find(u => u.id === lead.claimedByUserId).firstName}</TableCell>
              <TableCell align='right'>{DateTime.fromISO(lead.createdAt).toFormat('ff')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={totalCount}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default LeadsIndex
