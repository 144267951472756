import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import CallbackRequestRouter from './CallbackRequest/Router'

const CallbackRequestsRouter = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:callbackRequestId`}>
        <CallbackRequestRouter />
      </Route>
    </Switch>
  )
}

export default CallbackRequestsRouter
