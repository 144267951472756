import {
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
  CircularProgress,
  MenuItem,
  Drawer,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { CheckCircle, CloseRounded, DeleteRounded, Error } from '@mui/icons-material'
import { useDebounce } from 'use-debounce/lib'

export const EditJobDrawer = ({ editJobDrawerOpen, setEditJobDrawerOpen, selectedJob, dealer }: any) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const handleClickDeleteJob = () => {
    window.confirm('are you sure you want to delete this?') &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_job`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          b_to_b_job_id: selectedJob.id,
        }),
      })
        .then(res => {
          setEditJobDrawerOpen(false)

          if (res.ok) {
            console.log('ok')
          } else {
            window.alert('Error')
          }
        })
        .catch(() => window.alert('let eric know about this'))
  }

  return (
    <Drawer
      anchor='right'
      PaperProps={{
        sx: {
          maxWidth: '500px',
        },
      }}
      open={editJobDrawerOpen}
      onClose={() => setEditJobDrawerOpen(false)}
    >
      {selectedJob && (
        <Box
          sx={{
            padding: '.5rem 1rem',
            userSelect: 'none',
            mb: '.5rem',
            '& b': {
              fontSize: '22px',
              fontWeight: 500,
            },
            '& span': {
              fontSize: '13px',
              color: '#333',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              '& svg': { color: '#777' },
              pb: '.75rem',
              pt: '.25rem',
            }}
          >
            <Button onClick={handleClickDeleteJob} sx={{ minWidth: '30px', ml: '.5rem' }} size='small'>
              <DeleteRounded fontSize='small' />
            </Button>
            <Button onClick={() => setEditJobDrawerOpen(false)} sx={{ minWidth: '30px', ml: '1rem' }} size='small'>
              <CloseRounded fontSize='small' />
            </Button>
          </Box>

          <CreateJob setEditJobDrawerOpen={setEditJobDrawerOpen} selectedJob={selectedJob} dealer={dealer} />
        </Box>
      )}
    </Drawer>
  )
}

export const CreateJob = ({ dealer, selectedJob, setEditJobDrawerOpen, refetchAll }: any) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [ignoreAvailability, setIgnoreAvailability] = useState(false)
  const [date, setDate] = useState(DateTime.fromISO(selectedJob.startDatetime, { zone: 'utc' }))
  const [startDatetime, setStartDatetime] = useState(DateTime.fromISO(selectedJob.startDatetime, { zone: 'utc' }))
  const [endDatetime, setEndDatetime] = useState(DateTime.fromISO(selectedJob.endDatetime, { zone: 'utc' }))
  const [jobDuration, setJobDuration] = useState({ hours: 0, minutes: 0 })
  const [scheduleCheck, setScheduleCheck] = useState({ startDatetime, endDatetime })
  const [debouncedScheduleCheck] = useDebounce(scheduleCheck, 500, { leading: false })
  const [fitsOnSchedule, setFitsOnSchedule] = useState(true)
  const [selectedTechnicianId, setSelectedTechnicianId] = useState(selectedJob.technician.id)

  const [loading, setLoading] = useState(false)

  const handleClickSave = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_job_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        updated_attributes: {
          start_datetime: startDatetime,
          end_datetime: endDatetime,
          technician_id: selectedTechnicianId,
        },
        b_to_b_job_id: selectedJob.id,
      }),
    })
      .then(res => {
        setTimeout(() => setEditJobDrawerOpen(false), 250)
        refetchAll()
      })
      .catch(() => window.alert('let eric know about this'))
  }

  const handleChangedDate = (event: any) => {
    setFitsOnSchedule(false)
    setDate(DateTime.fromISO(event.target.value))
  }

  const handleChangedStartTime = (event: any) => {
    setFitsOnSchedule(false)
    setStartDatetime(DateTime.fromISO(startDatetime.toFormat('kkkk-LL-dd') + 'T' + event.target.value, { zone: 'utc' }))
  }
  const handleChangedEndTime = (event: any) => {
    setFitsOnSchedule(false)
    setEndDatetime(DateTime.fromISO(endDatetime.toFormat('kkkk-LL-dd') + 'T' + event.target.value, { zone: 'utc' }))
  }

  const jobDurationIsValid = jobDuration && (jobDuration.hours > 0 || jobDuration.minutes > 0)

  useEffect(() => {
    const newStartDatetime = DateTime.fromISO(date.toFormat('kkkk-LL-dd') + 'T' + startDatetime.toFormat('HH:mm'), {
      zone: 'utc',
    })

    setStartDatetime(newStartDatetime)
    setEndDatetime(DateTime.fromISO(date.toFormat('kkkk-LL-dd') + 'T' + endDatetime.toFormat('HH:mm'), { zone: 'utc' }))
  }, [date])

  const checkScheduleFit = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/check_technician_schedule_fit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...debouncedScheduleCheck,
        user_id: user.id,
        technician_id: selectedTechnicianId,
        ignore_availability: ignoreAvailability,
      }),
    })
      .then(res => {
        if (res.ok) {
          return true
        } else {
          return false
        }
      })
      .then(res => {
        setFitsOnSchedule(res)
        setLoading(false)
      })
  }

  useEffect(() => {
    // @ts-ignore
    setJobDuration(endDatetime.diff(startDatetime, ['hours', 'minutes']).toObject())
    setScheduleCheck({ ...scheduleCheck, ...{ startDatetime, endDatetime } })
  }, [startDatetime, endDatetime])

  const jobDurationStringOrNull = (jobDurationIsValid && `${jobDuration.hours}h, ${jobDuration.minutes}m`) || null
  const allowedToSave = jobDuration && (jobDuration.hours > 0 || jobDuration.minutes > 0) && fitsOnSchedule
  const approvedTechsForQuote = dealer?.technicians || []

  return (
    <Box>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#11111140',
            pointerEvents: 'none',
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box sx={{ padding: '1rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex' }}>
              <FormControl fullWidth size='small'>
                <InputLabel>Select Technician</InputLabel>
                <Select
                  value={selectedTechnicianId}
                  onChange={e => setSelectedTechnicianId(e.target.value)}
                  label='Select Technician'
                  size='small'
                  sx={{ minWidth: '200px' }}
                  placeholder='select technician'
                >
                  {approvedTechsForQuote.map((tech: any) => (
                    <MenuItem value={tech.id}>
                      {tech.firstName} {tech.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label='date'
              type='date'
              fullWidth
              size='small'
              value={date.toFormat('kkkk-LL-dd')}
              onChange={handleChangedDate}
              style={{ maxWidth: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size='small'
              label='start time'
              type='time'
              id='new-job-start-time'
              value={startDatetime.toFormat('HH:mm')}
              onChange={handleChangedStartTime}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size='small'
              label='end time'
              type='time'
              id='new-job-end-time'
              onChange={handleChangedEndTime}
              value={endDatetime.toFormat('HH:mm')}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: '0rem 1rem 1rem 1rem', textAlign: 'center', '& button': { mt: '.5rem' } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          conflicts? &nbsp;
          {fitsOnSchedule ? <CheckCircle style={{ color: 'lightgreen' }} /> : <Error style={{ color: 'red' }} />}
        </Box>
        <Button disableElevation size='small' variant='contained' onClick={checkScheduleFit}>
          Check schedule conflicts
        </Button>
      </Box>
      <Box sx={{ display: 'flex', padding: '1rem', borderTop: '2px solid #a1cff7' }}>
        <Button disabled={!allowedToSave} onClick={handleClickSave}>
          Save
        </Button>
      </Box>
    </Box>
  )
}
