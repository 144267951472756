import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { Chip } from '@mui/material'
import EmojiString from './EmojiString'
import taskTypesToTitles from 'data/taskTypesToTitles'

const taskAge = task => {
  const diff = DateTime.local().diff(DateTime.fromISO(task.createdAt), ['hours', 'minutes']).toObject()

  return `${diff.hours}h:${diff.minutes.toFixed()}m`
}

const TaskLink = ({ ownedName, task, showAge, showMarket, children }) => {
  return (
    <Link to={task.urlPath} target='_blank'>
      <div style={{ paddingLeft: '1em' }}>
        {ownedName && (
          <Chip size='small' color='primary' label={ownedName} sx={{ marginRight: '1rem' }} />
        )}
        {showAge && (
          <span style={{ display: 'inline-block', whiteSpace: 'nowrap', width: '60px' }}>{taskAge(task)}</span>
        )}
        {showMarket && (
          <Chip size='small' color='primary' label={task.market || 'No Market'} sx={{ marginRight: '1rem' }} />
        )}
        <EmojiString inputString={task.title || taskTypesToTitles[task.type]} />
        {children}
      </div>
    </Link>
  )
}

export default TaskLink
