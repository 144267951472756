import React, { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  Chip,
  CircularProgress,
} from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { markets } from 'constants.js'
import FilterListIcon from '@mui/icons-material/FilterList'
import SvgIcon from '@mui/material/SvgIcon'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { GlobalContext } from 'GlobalStore'
import { paymentStatusLabels } from '../data/styles'

const JOBS = gql`
  query JobsConnection(
    $search: JSON
    $order: JSON
    $filter: JSON
    $first: Int
    $after: String
  ) {
    jobsConnection(
      search: $search
      order: $order
      filter: $filter
      first: $first
      after: $after
    ) {
      edges {
        cursor
        node {
          startDatetime
          endDatetime
          quotedAmount
          market
          technician {
            name
            id
          }
          lead {
            id
            name
            zip
            state
          }
          quote {
            id
            fullyPaidAt
            quoteGrandTotal
            paymentStatus
            parts {
              price
              cost
              name
            }
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  headerFormControl: {
    minWidth: '5em',
  },
  dateComponent: {
    fontSize: '12px',
    margin: '0px -100px -2px 0px',
    float: 'left',
    maxHeight: '27px',
    padding: '3px 7px',
  },
  dateDialog: {
    display: 'flex',
    '& div': {
      padding: '5px',
      '& span': {
        background: '#eee',
        display: 'block',
        padding: '3px',
        borderRadius: '3px 3px 0px 0px',
        border: '1px solid #ccc',
        borderBottom: '1px dashed #eee',
      },
      '& input': {
        padding: '3px',
        borderRadius: '0px 0px 3px 3px',
        border: '1px solid #ccc',
        borderTop: '0px',
      },
    },
  },
}))

const LeadsIndex = () => {
  const classes = useStyles()
  const [global] = useContext(GlobalContext)
  const [search, setSearch] = useState(
    JSON.parse(localStorage.getItem('jobsSearch')) || {}
  )
  const [order, setOrder] = useState(
    JSON.parse(localStorage.getItem('jobsOrder')) || {}
  )
  const [filter, setFilter] = useState(
    JSON.parse(localStorage.getItem('jobsFilter')) || {
      start_datetime: { ge: DateTime.now().minus({ weeks: 1 }).toISO() },
    }
  )
  const [limit, setLimit] = useState(
    JSON.parse(localStorage.getItem('jobsLimit')) || 50
  )
  useEffect(
    () => localStorage.setItem('jobsSearch', JSON.stringify(search)),
    [search]
  )
  useEffect(
    () => localStorage.setItem('jobsOrder', JSON.stringify(order)),
    [order]
  )
  useEffect(
    () => localStorage.setItem('jobsFilter', JSON.stringify(filter)),
    [filter]
  )
  useEffect(
    () => localStorage.setItem('jobsLimit', JSON.stringify(limit)),
    [limit]
  )

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  const removeFilter = filterName => {
    const { [filterName]: removed, ...remainingFilters } = filter
    setFilter(remainingFilters)
  }

  const removeSearch = filterName => {
    const { [filterName]: removed, ...remainingFilters } = search
    setSearch(remainingFilters)
  }

  const setOrToggleOrder = name => {
    setOrder({
      [name]:
        (order[name] && order[name] === 'desc' ? 'asc' : 'desc') || 'desc',
    })
  }

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: limit,
        after: '',
      },
    })
  }, [search, order, filter, limit])

  const { loading, error, data, fetchMore } = useQuery(JOBS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      search,
      order,
      filter,
      first: limit,
      after: cursorCeiling,
    },
  })

  // if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const jobs = data?.jobsConnection?.edges?.map(edge => edge.node) || []
  const totalCount = data?.jobsConnection?.totalCount || 0
  const endCursor = data?.jobsConnection?.pageInfo?.endCursor || 0

  const MarketSelect = () => {
    const [value, setValue] = useState(
      (filter.market && filter.market.eq) || 'all'
    )

    const handleChange = evt => {
      setPage(0)
      setPageCeiling(0)
      if (evt.target.value === 'all') {
        removeFilter('market')
      } else {
        setFilter({ ...filter, market: { eq: evt.target.value } })
      }
    }

    return (
      <FormControl className={classes.headerFormControl}>
        <InputLabel shrink>Market</InputLabel>
        <Select variant='standard' value={value} onChange={handleChange}>
          <MenuItem value='all'>all</MenuItem>
          {markets.map(market => (
            <MenuItem key={market} value={market}>
              {market}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const PaymentStatusSelect = () => {
    const [value, setValue] = useState(
      (search.payment_status && search.payment_status.eq) || 'all'
    )

    const handleChange = evt => {
      setPage(0)
      setPageCeiling(0)
      if (evt.target.value === 'all') {
        removeSearch('payment_status')
      } else {
        setSearch({ ...search, payment_status: { eq: evt.target.value } })
      }
    }

    return (
      <FormControl className={classes.headerFormControl}>
        <InputLabel shrink>Payment status</InputLabel>
        <Select variant='standard' value={value} onChange={handleChange}>
          <MenuItem value='all'>all</MenuItem>
          {['none', 'pre_paid', 'partially_paid', 'fully_paid'].map(status => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const DateRangeHeadCell = ({ name, align }) => {
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [dateRange, setDateRange] = useState(filter[name] || {})
    // ONE of BOTH key of: { ge: iso8601, le: iso8601 } OR null
    // ge -> greater than or equal
    // le -> less than or equal

    const handleDateTimeChanged = evt => {
      setDateRange({ ...dateRange, [evt.target.name]: evt.target.value })
    }

    const handleClickApply = () => {
      setFilter({ ...filter, [name]: dateRange })
    }

    const handleClickClose = () => {
      setDialogIsOpen(false)
    }

    const handleDeleteRangeKey = key => {
      const { [key]: removed, ...rest } = dateRange
      setDateRange(rest)
      setFilter({ ...filter, [name]: rest })
    }

    return (
      <TableCell align={align}>
        {name}

        <Button
          style={{ minWidth: 0 }}
          color='primary'
          disableElevation
          onClick={() => setOrToggleOrder(name)}
        >
          <SvgIcon
            fontSize='small'
            component={
              (order[name] === 'desc' ? ArrowDownwardIcon : ArrowUpwardIcon) ||
              ArrowDownwardIcon
            }
          />
        </Button>

        <Button
          style={{ minWidth: 0 }}
          color='primary'
          disableElevation
          onClick={() => setDialogIsOpen(true)}
        >
          <SvgIcon fontSize='small' component={FilterListIcon} />
        </Button>

        {filter[name] && Object.entries(filter[name]).length > 0 && (
          <>
            <br />
            {Object.entries(filter[name])
              .sort((a, b) => (a[0] > b[0] ? 1 : -1))
              .map(filterTuple => (
                <Chip
                  key={filterTuple[0]}
                  variant='outlined'
                  size='small'
                  onDelete={() => handleDeleteRangeKey(filterTuple[0])}
                  label={`
                    ${filterTuple[0] === 'le' ? 'before' : 'after'}
                    ${DateTime.fromISO(filterTuple[1]).toFormat('yy-MM-dd')}
                  `}
                />
              ))}
          </>
        )}

        <Dialog open={dialogIsOpen} onClose={setDialogIsOpen}>
          <DialogTitle>Select Date Range (One Or Both)</DialogTitle>

          <DialogContent className={classes.dateDialog}>
            <Box>
              <span>
                Start Date<small> (optional)</small>
              </span>
              <input
                type='datetime-local'
                name='ge'
                value={dateRange.ge || ''}
                onChange={handleDateTimeChanged}
              />
            </Box>
            <Box>
              <span>
                End Date<small> (optional)</small>
              </span>
              <input
                type='datetime-local'
                name='le'
                value={dateRange.le || ''}
                onChange={handleDateTimeChanged}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClickClose} color='primary'>
              Close
            </Button>
            <Button onClick={handleClickApply} color='primary' autoFocus>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    )
  }

  const OrderableHeadCell = ({ name, align }) => {
    return (
      <TableCell align={align}>
        {name}

        <Button
          style={{ minWidth: 0 }}
          color='primary'
          disableElevation
          onClick={() => setOrToggleOrder(name)}
        >
          <SvgIcon
            fontSize='small'
            component={
              (order[name] === 'desc' ? ArrowDownwardIcon : ArrowUpwardIcon) ||
              ArrowDownwardIcon
            }
          />
        </Button>
      </TableCell>
    )
  }

  const SelectTechnicianHeadCell = props => {
    const [technicianId, setTechnicianId] = useState(
      (filter.technician_id && filter.technician_id.eq) || ''
    )

    const handleChange = evt => {
      if (evt.target.value === 'clear') {
        removeFilter('technician_id')
      } else {
        setFilter({ ...filter, technician_id: { eq: evt.target.value } })
      }
    }

    const technicians = global.technicians.filter(
      technician => technician.active
    )

    return (
      <TableCell {...props}>
        {!technicianId && <>Technician</>}
        <Select
          color='primary'
          size='small'
          label='Technician'
          value={technicianId}
          onChange={handleChange}
        >
          <MenuItem value='clear'>
            <i>clear</i>
          </MenuItem>
          {technicians.map(technician => (
            <MenuItem key={technician.id} value={technician.id}>
              <>
                {technician.firstName} {technician.lastName}
              </>
            </MenuItem>
          ))}
        </Select>
      </TableCell>
    )
  }

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: limit,
          after: endCursor,
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size='small' aria-label='a dense table'>
        {loading && (
          <Box
            sx={{
              position: 'fixed',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#11111140',
              top: '0px',
              bottom: '0px',
              left: '0px',
              right: '0px',
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </Box>
        )}
        <TableHead>
          <TableRow>
            <DateRangeHeadCell align='right' name='start_datetime' />
            <SelectTechnicianHeadCell align='right' />
            <TableCell align='right'>Quote</TableCell>
            <OrderableHeadCell align='right' name='quoted_amount' />
            {/* <TableCell align='right'>Fully paid</TableCell> */}
            <TableCell align='right'>State</TableCell>
            <TableCell align='right'>
              <MarketSelect />
            </TableCell>
            <TableCell align='right'>
              <PaymentStatusSelect />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.slice(page * limit, page * limit + limit).map(job => (
            <TableRow key={job.id} sx={paymentStatusLabels}>
              <TableCell align='right'>
                {DateTime.fromISO(job.startDatetime).toFormat('ff ZZZZ')}
              </TableCell>
              <TableCell component='th' align='right'>
                <Link to={`/technicians/${job.lead.id}`}>
                  {job.technician.name}
                </Link>
              </TableCell>
              <TableCell component='th' align='right'>
                <Link to={`/quotes/${job.quote.id}`}>{job.lead.name}</Link>
              </TableCell>
              <TableCell align='right'>${job.quotedAmount.toFixed(2)}</TableCell>
              {/* <TableCell align='right'>
                {job.quote.fullyPaidAt ? 'true' : ''}
              </TableCell> */}
              <TableCell align='right'>{job.lead.state}</TableCell>
              <TableCell align='right'>{job.market}</TableCell>

              <TableCell className={job.quote.paymentStatus} align='right'>
                {job.quote.paymentStatus}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={totalCount}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default LeadsIndex
