import { gql, useQuery } from '@apollo/client'
import { Box, Button } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const QUERY = gql`
  query GetEstimate($estimateId: String!) {
    getEstimate(estimateId: $estimateId) {
      acceptedAt
      rejectedAt
    }
  }
`

export const EstimateApprovalButtons = ({ estimateId }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const { data, error, loading, refetch } = useQuery(QUERY, {
    variables: {
      estimateId,
    },
  })

  const estimate = data?.getEstimate || {}

  const handleAccept = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/quotes/accept_estimation`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        automated_estimate_id: estimateId,
      }),
    }).then(() => setTimeout(() => refetch(), 250))

  const handleReject = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/quotes/reject_estimation`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        automated_estimate_id: estimateId,
      }),
    }).then(() => setTimeout(() => refetch(), 250))

  return (
    <Box sx={{ display: 'flex' }}>
      <Button size='small' variant='contained' onClick={handleAccept} disabled={estimate?.acceptedAt}>
        accept
      </Button>
      <Button size='small' variant='outlined' onClick={handleReject} disabled={estimate?.rejectedAt}>
        reject
      </Button>
    </Box>
  )
}
