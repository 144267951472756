import React, { useContext } from 'react'
import { UserContext } from 'UserStore'
import Today from './Today'
import MyUpcomingJobs from './MyUpcomingJobs'

const Dashboard = () => {
  const [user] = useContext(UserContext)

  return (
    <>
      {user.roles.includes('reports') && <Today />}
      {user.roles.includes('sales') && <MyUpcomingJobs />}
    </>
  )
}

export default Dashboard
