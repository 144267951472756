export const mergeRanges = (ranges: string[]) => {
  // Ex: [9-18, 18-20]
  let dupRanges = [...ranges]

  ranges?.forEach(range => {
    const [start, end] = range?.split('-')
    if (!start || !end) return

    const matchingRange = dupRanges?.find(range => range?.includes(`${end}-`))
    if (!matchingRange) return

    const [matchStart, matchEnd] = matchingRange?.split('-')
    if (!matchStart || !matchEnd) return

    dupRanges = dupRanges?.filter(r => r !== range && r !== matchingRange)
    dupRanges?.push(`${start}-${matchEnd}`)
  })

  return dupRanges
}
