import * as Yup from 'yup'

import { isEmail, ZipcodeRegex, UrlRegex } from 'tools'
const phoneRegExp = /^\d{10}$/
const googleMapUrlRegex = /^https:\/\/www\.google\.com\/maps\/place\/.+/

const stringFieldIsEmpty = value =>
  typeof value === 'undefined' ||
  (typeof value === 'string' && value.trim() === '')

const validationSchema = Yup.object().shape({
  userId: Yup.string().required('This field is required.'),
  name: Yup.string().required('This field is required.'),
  googleMapUrl: Yup.string()
    .required('This field is required.')
    .matches(
      googleMapUrlRegex,
      'Value must be like https://google.com/maps/place/...'
    ),
  addressLineOne: Yup.string().required('This field is required.'),
  addressLineTwo: Yup.string(),
  city: Yup.string().required('This field is required.'),
  state: Yup.string().required('This field is required.'),
  zip: Yup.string()
    .required('This field is required')
    .matches(ZipcodeRegex, 'Zipcode must be 5 numbers only.'),
  phone: Yup.string()
    .required('This field is required.')
    .matches(phoneRegExp, 'Invalid phone, requires 10 digits'),
  email: Yup.string().test({
    name: 'email',
    test: value => (stringFieldIsEmpty(value) ? true : isEmail(value)),
    message: 'Please enter a valid email address.'
  }),
  website: Yup.string().test({
    name: 'url',
    test: value => (stringFieldIsEmpty(value) ? true : UrlRegex.test(value)),
    message: "Please enter a valid url like 'https://example.com'."
  }),
  paymentNotes: Yup.string(),
  hasTires: Yup.boolean()
})

export default validationSchema
