import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { prettyPhoneNumber } from 'tools'

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { useDebounce } from 'use-debounce'

const filter = createFilterOptions({ limit: 100 })

const LeadTypeaheadSelect = ({ inputProps, disabled, value, onChange }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)

  const [debouncedQuery] = useDebounce(query, 500, { leading: false })

  useEffect(() => {
    const trimmed = query.trim()
    if (!trimmed.length) {
      return
    }

    setLoading(true)

    fetch(`https://${process.env.REACT_APP_NEO_HOST}/search`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ term: trimmed }),
    })
      .then(res => res.json())
      .then(data => setOptions(data.results))
      .catch(err => alert(err))
      .finally(() => setLoading(false))
  }, [debouncedQuery])

  return (
    <Autocomplete
      id='part-vendor-select'
      open={open}
      size={inputProps.size}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => (option ? option.id === value.id : false)}
      getOptionLabel={option => (option ? option.name : '')}
      options={options}
      value={value && Object.keys(value).length > 0 ? value : ''}
      loading={loading}
      disabled={disabled}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newInputValue) => setQuery(newInputValue)}
      filterOptions={(options, params) => filter(options, params)}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.name, inputValue)
        const parts = parse(option.name, matches)

        return (
          <li key={option.id} {...props}>
            <div>
              <b>
                {parts.map((part, index) => (
                  <span key={index} style={{ textDecoration: part.highlight ? 'underline' : 'none' }}>
                    {part.text}
                  </span>
                ))}
              </b>
              <br />
              {option.city} ({option.market})
              <br />
              {prettyPhoneNumber(option.phone)}
            </div>
          </li>
        )
      }}
      renderInput={params => <TextField label='Customer' variant='outlined' {...params} />}
    />
  )
}

export default LeadTypeaheadSelect
