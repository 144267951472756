import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { useContext, useEffect } from 'react'
import { Grid, Paper } from '@mui/material'
import Notes from 'components/Notes'

export const PayrollNotes = ({ invoice }) => {
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)

  useEffect(() => {
    const key = registerTaskTarget({
      targetType: 'BToBInvoiceForPayroll',
      targetId: invoice.id,
    })
    return () => unregisterTaskTarget(key)
  }, [])

  return (
    <Grid item sm={3.5} xs={12}>
      <Paper sx={{ padding: '.5rem .5rem 0rem .5rem' }}>
        <Notes headerText='PAYROLL NOTES' objectType='BToBInvoiceForPayroll' objectId={invoice.id} />
      </Paper>
    </Grid>
  )
}
