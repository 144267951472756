import React, { useEffect, useRef, useContext, useState, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from 'UserStore'
import { useQuery, gql} from '@apollo/client'
import { DateTime } from 'luxon'
import { GlobalContext } from 'GlobalStore'
import { Dollars } from 'tools'
import { makeStyles } from '@mui/styles'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

const JOBS_QUERY = gql`
  query getJobs( $filter: JSON!, ) {
    jobs( filter: $filter ) {
      id
      leadId
      quoteId
      startDatetime
      endDatetime
      canceledAt
      rescheduledAt
      technicianId
      rescheduleHistory
      canceledAt
      rescheduleHistory
      rescheduledAt
      isActive
      createdAt
      lead {
        id
        name
        market
        state
        soldByUserId
      }
      quote {
        id
        leadId
        assignedTechnicianId
        payments
        balanceAmountDue
        postTaxGrandTotal
        paymentStatus
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: '1em',
    marginRight: '1em',
    width: 200,
  }
}))

const paymentsFromToday = (quote, utcDay) =>
  quote.payments.filter( payment =>
    DateTime.fromISO(payment.timestamp, {zone: 'America/Los_Angeles'}) >= utcDay.setZone('America/Los_Angeles').startOf('day') )

const paymentsBeforeToday = (quote, utcDay) =>
  quote.payments.filter( payment =>
    DateTime.fromISO(payment.timestamp, {zone: 'America/Los_Angeles'}) < utcDay.setZone('America/Los_Angeles').startOf('day') )

const paymentsSumFromToday = (quote, utcDay) =>
  paymentsFromToday(quote, utcDay).reduce((acc,payment) => acc + Number(payment.amount_before_tip), 0)

const paymentsSumBeforeToday = (quote, utcDay) =>
  paymentsBeforeToday(quote, utcDay).reduce((acc,payment) => acc + Number(payment.amount_before_tip), 0)

const dueAtBeginningOfDay = (quote, utcDay) =>
  Number(quote.postTaxGrandTotal) - paymentsSumBeforeToday(quote, utcDay)

const MyUpcomingJobs = () => {
  const [user] = useContext(UserContext)
  const classes = useStyles()
  const [pacificDay, setPacificDay] = useState(DateTime.fromISO(DateTime.local().toISODate(), {zone: 'America/Los_Angeles'}))

  const [utcDay, setUtcDay] = useState(
    pacificDay.setZone('utc')
  )

  const nowInLa = DateTime.local().setZone('America/Los_Angeles')

  const [filter, setFilter] = useState({
    start_datetime: {
      gte: utcDay.startOf('day').toISO(),
      lt: utcDay.plus({days: 14}).endOf('day').toISO()
    }
  })

  const { loading, error, data } = useQuery(JOBS_QUERY, {
    variables: { filter, withCalendarSnapshotDate: utcDay.toISODate() }
  })
  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const jobs =
    data.jobs.slice()
    .filter( job => {
      if (!job.canceledAt)
        return true

      let canceledAtDateTime = DateTime.fromISO(job.canceledAt)
      let localDateTime = DateTime.local()

      return job.canceledAt &&
      canceledAtDateTime > localDateTime.startOf('day') &&
      canceledAtDateTime <= localDateTime.endOf('day')
    })
    .sort( (a,b) =>
      DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 0 : -1
    )

  const jobsForTodayForLeadsSoldByMe = data.jobs.filter( job => {
    let startDatetime = DateTime.fromISO(job.startDatetime)

    return job.lead.soldByUserId === user.id &&
      startDatetime > utcDay.startOf('day') &&
      startDatetime <= utcDay.endOf('day')
  })

  const jobsForTomorrowPlusForLeadsSoldByMe = data.jobs.filter( job => {
    let startDatetime = DateTime.fromISO(job.startDatetime)

    return job.lead.soldByUserId === user.id &&
      job.leadstartDatetime > utcDay.endOf('day')
  })

  // const quoteSumData = Object.keys(jobsGroupedByQuoteId).map( quoteId => {
  //   let jobs = jobsGroupedByQuoteId[quoteId]
  //   let quote = jobs[0].quote

  //   return {
  //     quote,
  //     jobs,
  //     dueAtBeginningOfDay: dueAtBeginningOfDay(quote, utcDay),
  //     dueCurrently: Number(quote.balanceAmountDue, utcDay),
  //     collectedToday: paymentsSumFromToday(quote, utcDay)
  //   }
  // })

  // const handleChangedDay = e => setUtcDay(DateTime.fromISO(e.target.value, {zone: 'utc'}))

  // const handleCheckShowJobs = e => setShowJobs(e.target.checked)

  return <>
    <Typography variant="h6">
      Jobs today for leads that you sold
    </Typography>

    <TableContainer component={Paper}>
      <Table size="small">

        <TableHead>
          <TableRow>
            <TableCell align="right" style={{position: 'sticky', top: 0}}>Customer</TableCell>
            <TableCell align="right">Technician</TableCell>
            <TableCell align="right">State</TableCell>
            <TableCell align="right">Market</TableCell>
            <TableCell align="right">Quote Amount</TableCell>
            <TableCell align="right">Date/Time</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Amount due currently</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          { jobsForTodayForLeadsSoldByMe.map( job =>
          <JobRow job={job} />)}
        </TableBody>

      </Table>
    </TableContainer>
  </>
}

const quoteRowStyles = makeStyles(theme => ({
  row: {
    '& td': {
      fontWeight: 'bold',
      backgroundColor: '#e1e1e1'
    },
  },
  payment_status: {
    '&.fully_paid': {
      color: 'green'
    },
    '&.pre_paid': {
      color: 'orange'
    },
    '&.waiting_pre_payment': {
      color: 'red'
    }
  }
}))

const QuoteRow = ({data}) => {
  const classes = quoteRowStyles()
  let jobs = data.jobs
  let lead = jobs[0].lead
  let quote = data.quote

  return <TableRow>
    <TableCell align="right">
      <Link target="_blank" to={`/leads/${lead.id}/quotes/${quote.id}`}>
        {lead.name}
      </Link>
    </TableCell>
    <TableCell align="right">
      <Dollars value={quote.postTaxGrandTotal} />
    </TableCell>
    <TableCell align="right">{lead.state}</TableCell>
    <TableCell align="right">{lead.market}</TableCell>
    <TableCell align="right"><Dollars value={data.dueAtBeginningOfDay} /></TableCell>
    <TableCell align="right"><Dollars value={data.dueCurrently} /></TableCell>
    <TableCell align="right"><Dollars value={data.collectedToday} /></TableCell>
    <TableCell align="right">
      <span className={classes.payment_status + ` ${data.quote.paymentStatus}`} >
        {data.quote.paymentStatus}
      </span>
    </TableCell>
  </TableRow>
}

const sumRowStyles = makeStyles(theme => ({
  sumRow: {
    '& td': {
      fontWeight: 'bold'
    },
  }
}))
const SumRow = ({quoteSumData}) => {
  const classes = sumRowStyles()

  const dueAtBeginningOfDay = quoteSumData.reduce( (acc,q) => acc + Number(q.dueAtBeginningOfDay), Number(0) )
  const dueCurrently = quoteSumData.reduce( (acc,q) => acc + Number(q.dueCurrently), Number(0) )
  const collectedToday = quoteSumData.reduce( (acc,q) => acc + Number(q.collectedToday), Number(0) )

  return <TableRow className={classes.sumRow}>
    <TableCell colSpan={4}></TableCell>
    <TableCell align="right"> <Dollars value={dueAtBeginningOfDay} /> </TableCell>
    <TableCell align="right"> <Dollars value={dueCurrently} /> </TableCell>
    <TableCell align="right"> <Dollars value={collectedToday} /> </TableCell>
    <TableCell colSpan={1}></TableCell>
  </TableRow>
}

const jobRowStyles = makeStyles(theme => ({
  jobRow: {
    '& td': {
      padding: '3px'
    },
  }
}))
const JobRow = ({job}) => {
  const [global] = useContext(GlobalContext)
  const classes = jobRowStyles()
  const quote = job.quote
  const lead = job.lead
  const technician = global.technicians.find(tech => tech.id === job.technicianId)

  const csrNameById = userId => {
    let user = global.users.find(user => user.id === userId)
    return user && `${user.firstName} ${user.lastName}` || ''
  }

  const prettyRescheduleHistory = rescheduleHistory =>
    rescheduleHistory
    .map( history => (
      {
        csr: csrNameById(history.user_id),
        timestamp: DateTime.fromISO(history.timestamp).toLocaleString(DateTime.TIME_SIMPLE),
        moved_from: DateTime.fromISO(history.from.start).toLocaleString(DateTime.DATE_SIMPLE),
        moved_to: DateTime.fromISO(history.to.start).toLocaleString(DateTime.DATE_SIMPLE),
      }
    ))

  return (
    <TableRow className={classes.jobRow}>
      <TableCell align="right">
        <Link target="_blank" to={`/leads/${job.leadId}`}>
          {job.lead.name}
        </Link>
      </TableCell>
      <TableCell align="right">
        { technician &&
          <Link target="_blank" to={`/technicians/${technician.id}`}>
            {`${technician.firstName} ${technician.lastName}`}
          </Link> }
      </TableCell>
      <TableCell align="right">{job.lead.state}</TableCell>
      <TableCell align="right">{job.lead.market}</TableCell>
      <TableCell align="right"><Dollars value={job.quote.postTaxGrandTotal} /></TableCell>
      <TableCell align="right">
        <Link style={{fontSize: '12px'}} target="_blank" to={`/leads/${job.leadId}/quotes/${job.quoteId}/jobs/${job.id}`}>
          {DateTime.fromISO(job.startDatetime, {zone: 'utc'}).toLocaleString(DateTime.TIME_SIMPLE)}
          &nbsp;
          to:
          &nbsp;
          {DateTime.fromISO(job.endDatetime, {zone: 'utc'}).toLocaleString(DateTime.TIME_SIMPLE)}
        </Link>
      </TableCell>
      <TableCell align="right">
        { job.canceledAt && <>
          Canceled
        </> }

        { job.rescheduledAt &&
          DateTime.fromISO(job.rescheduledAt) > DateTime.local().startOf('day') &&
          <Link title={JSON.stringify(prettyRescheduleHistory(job.rescheduleHistory), null, 2)} target="_blank" to={`/jobs/${job.id}`}>
            {job.rescheduledAt && <>
              moved to<br />
              {DateTime.fromISO(job.startDatetime, {zone: 'utc'}).toFormat('ccc d, ')}
            </>}
            {DateTime.fromISO(job.startDatetime, {zone: 'utc'}).toLocaleString(DateTime.TIME_SIMPLE)}
            &nbsp; - &nbsp;
            {DateTime.fromISO(job.endDatetime, {zone: 'utc'}).toLocaleString(DateTime.TIME_SIMPLE)}
          </Link> }

        { job.isActive && 'active' }

      </TableCell>
      <TableCell align="right"><Dollars value={job.quote.balanceAmountDue} /></TableCell>
    </TableRow>
  )
}

const jobsTableStyles = makeStyles(theme => ({
  jobsTableContainer: {
    marginBottom: '1em',
    backgroundColor: 'aliceblue',
    '& td': {
      width: '33%'
    },
  }
}))
const JobsTable = ({jobs}) => {
  const [global] = useContext(GlobalContext)
  const classes = jobsTableStyles()

  return <TableContainer
    style={{ maxWidth: '700px', border: '1px solid #8ed7e7', borderStyle: 'double' }}
    className={classes.jobsTableContainer}
  >
    <Table size="small">
      <TableBody>
        { jobs.map( job => <JobRow job={job} /> ) }
      </TableBody>

    </Table>
  </TableContainer>
}

export default MyUpcomingJobs
