import { Modal, Typography, Grid, Grow, Drawer, Button, Box } from '@mui/material'
import { KeyboardArrowDown, Cancel } from '@mui/icons-material'
import { useEffect, useState, useContext } from 'react'
import { bearerTokenHeaders, thumbnailUrlForPhoto } from 'tools'
// @ts-ignore
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import { UserContext } from 'UserStore'
import { Uploader } from './Uploader'

export const PreJobPhotos = ({ workOrder }: { workOrder: any }) => {
  const [user] = useContext(UserContext)
  const [openPreJobPhotosDrawer, setOpenPreJobPhotosDrawer] = useState(false)
  const [expandPhotoSection, setExpandPhotoSection] = useState(false)
  const [showDeleteIconsOnPhotos, setShowDeleteIconsOnPhotos] = useState(false)
  const [currentFullScreenPhoto, setCurrentFullScreenPhoto] = useState<any>(null)

  const preJobPhotos = workOrder.preJobPhotos || []

  const deletePhoto = (e: any, photo: any) => {
    e.stopPropagation()

    window.confirm('Are you sure you want to delete this photo?') &&
      fetch(`${process.env.REACT_APP_API_HOST}/remove_pre_job_photo_url_from_b_to_b_work_order`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          actor_id: user.id,
          actor_type: 'csr',
          photo_id: photo.id,
          b_to_b_work_order_id: workOrder.id,
        }),
      })
        .then(res => {
          if (res.ok) {
            return true
          } else {
            throw Error('error')
          }
        })
        .catch(e => window.alert(e.message))
  }

  useEffect(() => {
    showDeleteIconsOnPhotos && setExpandPhotoSection(showDeleteIconsOnPhotos)
  }, [showDeleteIconsOnPhotos])

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            borderRadius: '6px 6px 0px 0px',
            fontWeight: 700,
            fontSize: '14px',
            padding: '.5rem 1rem',
            background: '#bbc7d3',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          PRE-Job Photos
          {preJobPhotos.length > 0 && (
            <>
              &nbsp;({preJobPhotos.length})
              {!workOrder.payoutData && !workOrder.completedAt && (
                <>
                  <Button
                    size='small'
                    color='secondary'
                    disabled={showDeleteIconsOnPhotos}
                    onClick={() => setOpenPreJobPhotosDrawer(!openPreJobPhotosDrawer)}
                    sx={{ ml: 'auto', padding: '0rem 1rem' }}
                  >
                    Add More
                  </Button>
                  <Button
                    onClick={() => setShowDeleteIconsOnPhotos(!showDeleteIconsOnPhotos)}
                    disableElevation
                    size='small'
                    sx={{
                      ml: '.25rem',
                      color: showDeleteIconsOnPhotos ? '#4765A4' : '#222',
                      padding: '0rem .5rem',
                    }}
                  >
                    [ EDIT{showDeleteIconsOnPhotos && 'ING'} ]
                  </Button>
                </>
              )}
            </>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            borderRadius: preJobPhotos.length > 0 && preJobPhotos.length < 8 ? '0px 0px 6px 6px' : '0px',
            background: '#fff',
            padding: '1rem .5rem',
            border: '1px solid #ddd',
            borderBottom: '0px',
            borderTop: '0px',
            textAlign: 'center',
          }}
        >
          {preJobPhotos.length > 0 ? (
            <Grid container spacing={2}>
              {preJobPhotos.map(
                (photo: any, index: Number) =>
                  (expandPhotoSection || index < 8) && (
                    <Grid item xs={3} sm={3} md={2} key={photo.originalUrl}>
                      <Grid
                        onClick={() => setCurrentFullScreenPhoto(photo)}
                        sx={{
                          borderRadius: '3px',
                          minHeight: '75px',
                          background: `url(${thumbnailUrlForPhoto(photo)})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                        }}
                      >
                        <Grow in={showDeleteIconsOnPhotos}>
                          <Grid container sx={{ display: 'flex' }}>
                            <Button
                              onClick={e => deletePhoto(e, photo)}
                              color='error'
                              sx={{ ml: 'auto', mt: '-.5rem', mr: '-.5rem' }}
                            >
                              <Cancel
                                color='error'
                                sx={{
                                  background: '#fff',
                                  ml: 'auto',
                                  mt: '-.5rem',
                                  mr: '-.5rem',
                                  borderRadius: '50px',
                                  border: '2px solid #E62937',
                                }}
                              />
                            </Button>
                          </Grid>
                        </Grow>
                      </Grid>
                    </Grid>
                  )
              )}
            </Grid>
          ) : (
            <> No photos uploaded yet </>
          )}
        </Grid>

        {preJobPhotos?.length > 8 && (
          <Grid
            item
            xs={12}
            onClick={() => setExpandPhotoSection(!expandPhotoSection)}
            sx={{
              border: '1px solid #ddd',
              fontSize: '14px',
              borderRadius: '0px 0px 6px 6px',
              display: 'flex',
              padding: '.25rem .5rem',
              background: '#f1f1f1',
              justifyContent: 'center',
              alignItems: 'center',
              '&:active': {
                background: '#eee',
              },
            }}
          >
            See
            {expandPhotoSection ? ' less' : ' all'}
            <KeyboardArrowDown
              sx={{
                transform: expandPhotoSection ? 'rotate(180deg)' : '',
                ml: '1rem',
                transition: 'all .25s ease-in-out',
              }}
            />
          </Grid>
        )}

        {preJobPhotos?.length < 1 && (
          <Grid
            item
            xs={12}
            sx={{
              border: '1px solid #ddd',
              fontSize: '14px',
              borderRadius: '0px 0px 6px 6px',
              display: 'flex',
              padding: '.25rem .5rem',
              background: '#f1f1f1',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></Grid>
        )}
      </Grid>

      <Modal open={!!currentFullScreenPhoto} onClose={() => setCurrentFullScreenPhoto(null)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '700px',
            maxHeight: '80%',
            width: '1100px',
            maxWidth: '100%',
            boxShadow: 24,
            border: '1px solid black',
          }}
        >
          <div style={{ position: 'relative', height: '100%', width: '100%' }}>
            <Cancel
              sx={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                fontSize: '30px',
                color: '#fff',
                border: '2px solid #fff',
                borderRadius: '20px',
              }}
              onClick={() => setCurrentFullScreenPhoto(null)}
            />
            <PinchZoomPan position='center' maxScale={2}>
              <img src={(currentFullScreenPhoto && currentFullScreenPhoto.originalUrl) || ''} />
            </PinchZoomPan>
          </div>
        </Box>
      </Modal>
    </Grid>
  )
}
