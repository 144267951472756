import React, { useRef, useContext, useState } from 'react'
import { UserContext } from 'UserStore'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { DateTime } from 'luxon'
import { makeStyles } from '@mui/styles'
import  Grid from '@mui/material/Grid'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const QuotesDump = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [isWorking, setIsWorking] = useState(false)

  const handleClickSubmit = () => {
    setIsWorking(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/quotes_dump`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id
      }),
    })
    .then(response => {
      if (response.ok) {
        return response.text()
      } else {
        throw Error('bad range?')
      }
    })
    .then(responseText => {
      window.alert('OK, check your email soon; this process takes a few seconds per day in range')
      setIsWorking(false)
    })
    .catch(err => {
      window.alert(err)
      setIsWorking(false)
    })
  }

  return (
    <Grid container >
      <Grid item xs={12}>
        <Typography>
          Quotes <small>all with payments_sum &gt; 0</small>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleClickSubmit}>
          {isWorking ? 'working' : 'send email'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default QuotesDump
