import { useQuery } from '@apollo/client'
import { useContext } from 'react'

import { UserContext } from 'UserStore'
import { GET_USER, GetUserProps, GetUserRes } from './query'

export const useUser = (id?: string) => {
  const { data } = useQuery<GetUserRes, GetUserProps>(GET_USER, {
    variables: { id },
    skip: !id,
  })

  return { user: data?.user }
}

export const useCurrentUser = () => {
  const [user] = useContext(UserContext)
  return useUser(user?.id)
}
