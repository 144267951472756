import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, Tab, Tabs, Typography } from '@mui/material'
import { useHistory } from 'react-router'
import { useJobListing } from 'hooks/recruiting/jobListings/useJobListing'
import CreateUpdateJobListingPopup from '../CreateUpdateJobListingPopup'
import { KeyboardArrowLeftRounded } from '@mui/icons-material'
import { FormCreator } from './components/FormCreator'
import JobApplicationTable from '../../JobApplications/JobApplicationTable'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { useLocalStorage } from 'hooks/useLocalStorage'

const JobListingView = () => {
  const [openUpdate, setOpenUpdate] = useState(false)
  const toggle = () => setOpenUpdate(!openUpdate)
  const history = useHistory()
  const [tab, setTab] = useLocalStorage(`jobListingPageTab`, 'info')

  const bearerTokenHeaders = useBearerTokenHeaders()

  const params: any = useParams()
  const jobListingId = params?.jobListingId

  const { jobListing, enable_job_listing, disable_job_listing, refetch } = useJobListing(jobListingId)
  if (!jobListingId || !jobListing) return null

  const jobApplications = jobListing?.jobApplications

  const handleClickDelete = () =>
    window.confirm('Are you sure? This cannot be undone') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_job_listing`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        jobListingId: jobListing?.id,
      }),
    })
      .then(response => history.push('/recruiting/job-listings'))
      .catch(e => {
        alert(e.message)
      })

  return (
    <Box sx={{ padding: '1rem' }}>
      <Box
        onClick={() => history.push('/recruiting/job-listings')}
        sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', '&:hover': { color: '#777' } }}
      >
        <KeyboardArrowLeftRounded />
        <Typography variant='h6'>{jobListing?.jobTitle}</Typography>
      </Box>

      <Box>
        <Box sx={{ display: 'flex', gap: 1, padding: 2 }}>
          <Button onClick={enable_job_listing} disabled={!jobListing?.disabledAt} variant='contained'>
            Enable
          </Button>
          <Button onClick={disable_job_listing} disabled={!!jobListing?.disabledAt} variant='contained'>
            Disable
          </Button>
          <Button sx={{ ml: 'auto' }} onClick={handleClickDelete} variant='outlined' color='error'>
            Delete
          </Button>
        </Box>
      </Box>

      <Tabs value={tab} onChange={(e, v) => setTab(v)}>
        <Tab value='info' label='Listing Info' />
        <Tab value='questions' label='Application Questions' />
        <Tab value='applications' label={`Applications (${jobApplications?.length})`} />
      </Tabs>

      {tab === 'info' && (
        <Box>
          <Box sx={{ p: 2 }}>
            <Typography>Job Title: {jobListing?.jobTitle}</Typography>
            <Typography>City: {jobListing?.city}</Typography>
            <Typography>State: {jobListing?.state}</Typography>
            <Typography>Description: {jobListing?.description}</Typography>
            <Typography>Salary Start: {jobListing?.salaryStart}</Typography>
            <Typography>Salary End: {jobListing?.salaryEnd}</Typography>
            <Typography>Salary Period: {jobListing?.salaryPeriod}</Typography>
            <Typography>Education: {jobListing?.education}</Typography>
            <Typography>Job Type: {jobListing?.jobType}</Typography>
            <Typography>Experience: {jobListing?.experience}</Typography>
            <Typography>Company: {jobListing?.company}</Typography>
            <Typography>Country: {jobListing?.country}</Typography>
            <Typography>ZIP Code: {jobListing?.zipCode}</Typography>
            <Typography>Street Address: {jobListing?.streetAddress}</Typography>
            <Typography>Email: {jobListing?.email}</Typography>
            <Typography>Created At: {jobListing?.createdAt}</Typography>
            <Typography>Expires At: {jobListing?.expiresAt}</Typography>
            <Typography>Disabled At: {jobListing?.disabledAt}</Typography>

            <Button onClick={toggle} variant='contained'>
              Edit
            </Button>
          </Box>
        </Box>
      )}

      {tab === 'questions' && <FormCreator refetch={refetch} contextType='JobListing' contextId={jobListingId} />}

      {tab === 'applications' && (
        <Box>
          <JobApplicationTable jobListingId={jobListingId} />
        </Box>
      )}

      <CreateUpdateJobListingPopup jobListingId={jobListingId} open={openUpdate} onClose={toggle} refresh={refetch} />
    </Box>
  )
}

export default JobListingView
