import { useEffect, useContext, useState } from 'react'
import { useHistory, Link, useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import NumberFormat from 'react-number-format'
import { DateTime } from 'luxon'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { Typography, Button } from '@mui/material'
import { OmegaGlassPopover } from './OmegaGlassPopover'

const GET_QUOTES = gql`
  query getQuotes($leadId: ID!) {
    quotes(leadId: $leadId) {
      id
      leadId
      postTaxGrandTotal
      createdAt
      updatedAt
      deletedAt
      source
      inspektlabsEstimate {
        id
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`

const Quotes = ({ lead }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)
  const history = useHistory()

  const [open, setOpen] = useState(false)

  const handleCreateQuote = () => {
    if (waiting) {
      return
    }

    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_empty_quote`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        leadId: lead.id,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (data.quote) {
          history.push(`/leads/${lead.id}/quotes/${data.quote.id}`)
        } else {
          throw new Error(data.error)
        }
      })
      .catch(e => window.alert(e.message))
      .finally(() => setWaiting(false))
  }

  const { loading, error, data, refetch } = useQuery(GET_QUOTES, {
    variables: { leadId: lead.id },
  })

  useEffect(() => {
    data && refetch && refetch()
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return (
    <div>
      <Typography color='primary' variant='h6'>
        Quotes:
      </Typography>

      {lead.market ? (
        <Button
          cy-data='create-quote-button'
          variant='outlined'
          size='small'
          color='primary'
          disabled={!!lead.deletedAt}
          onClick={() => handleCreateQuote()}
        >
          create quote
        </Button>
      ) : (
        <b style={{ color: 'red' }}>Missing market. Select market to create a quote.</b>
      )}

      {(lead?.serviceType === 'windshields' || lead?.serviceType === 'autoglass') && (
        <Button
          cy-data='create-quote-button'
          variant='outlined'
          size='small'
          color='primary'
          disabled={!!lead.deletedAt}
          onClick={() => setOpen(true)}
          sx={{ ml: '.5rem' }}
        >
          create auto glass quote
        </Button>
      )}

      <OmegaGlassPopover
        open={open}
        setOpen={setOpen}
        data={{
          glassLocationData: lead?.glassLocationData,
          leadId: lead.id,
          lead,
        }}
      />

      <ul>
        {data.quotes.map(quote => (
          <li key={quote.id}>
            QUOTE: &nbsp;
            <Link to={`/leads/${lead.id}/quotes/${quote.id}`}>
              Total:{' '}
              <NumberFormat
                value={Number(quote.postTaxGrandTotal).toFixed(2)}
                displayType='text'
                thousandSeparator
                prefix='$'
              />
              &nbsp;
              <small>
                {quote.createdBy && quote.createdBy.firstName + ' ' + quote.createdBy.lastName.charAt(0) + '. '}
                (created: {DateTime.fromISO(quote.createdAt).toFormat('f')}
                {quote.updatedAt && <>&nbsp;, updated: {DateTime.fromISO(quote.updatedAt).toFormat('f')}</>}
                {quote.deletedAt && <>&nbsp;, deleted: {DateTime.fromISO(quote.deletedAt).toFormat('f')}</>}
                {quote.source === 'inspekt_labs' && <>&nbsp;-&nbsp;Inspekt Labs Quote</>}
                {quote.source === 'crm' && <>&nbsp;-&nbsp;CRM Quote</>}

              </small>
              )
            </Link>
            <QuoteJobs quote={quote} />
            <QuoteAppointments quote={quote} />
          </li>
        ))}
      </ul>
    </div>
  )
}

const GET_APPOINTMENTS = gql`
  query getAppointments($quoteId: ID!, $includePending: Boolean, $includeCanceled: Boolean) {
    appointments(quoteId: $quoteId, includePending: $includePending, includeCanceled: $includeCanceled) {
      id
      quoteId
      technicianId
      startDatetime
      endDatetime
    }
  }
`

const GET_JOBS = gql`
  query getJobs($quoteId: ID!) {
    jobs(quoteId: $quoteId) {
      id
      quoteId
      technicianId
      startDatetime
      endDatetime
      heldForReschedulingAt
    }
  }
`
const QuoteJobs = ({ quote }) => {
  const { leadId } = useParams()
  const [global] = useContext(GlobalContext)

  const { loading, error, data } = useQuery(GET_JOBS, {
    variables: { quoteId: quote.id },
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return (
    <ul>
      {data.jobs.map(job => {
        const technician = global.technicians.find(tech => tech.id === job.technicianId) || {}
        return (
          <li key={job.id}>
            JOB: &nbsp;
            <Link to={`/leads/${leadId}/quotes/${job.quoteId}/jobs/${job.id}`}>
              {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat('cccc LLLL dd, h:mm a')} -&nbsp;
              {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toFormat('h:mm a')}
              {job.heldForReschedulingAt && (
                <b style={{ color: 'crimson', marginLeft: '1em' }}>HELD FOR RESCHEDULING</b>
              )}
            </Link>
            &nbsp;-&nbsp; (<Link to={`/technicians/${job.technicianId}`}>{technician.name || ''}</Link>)
          </li>
        )
      })}
    </ul>
  )
}

const QuoteAppointments = ({ quote }) => {
  const { leadId } = useParams()
  const [global] = useContext(GlobalContext)

  const { loading, error, data } = useQuery(GET_APPOINTMENTS, {
    variables: { quoteId: quote.id, includePending: true, includeCanceled: true },
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>{JSON.stringify(error)}</div>

  return (
    <ul>
      {data.appointments.map(appt => {
        const technician = global.technicians.find(tech => tech.id === appt.technicianId) || {}
        return (
          <li key={appt.id}>
            APPOINTMENT: &nbsp;
            <Link to={`/leads/${leadId}/quotes/${appt.quoteId}/appointments/${appt.id}`}>
              {DateTime.fromISO(appt.startDatetime, { zone: 'utc' }).toFormat('cccc LLLL dd, h:mm a')} -&nbsp;
              {DateTime.fromISO(appt.endDatetime, { zone: 'utc' }).toFormat('h:mm a')}
              {appt.canceledAt && <b style={{ color: 'crimson', marginLeft: '1em' }}>CANCELLED</b>}
            </Link>
            &nbsp;-&nbsp; (<Link to={`/technicians/${appt.technicianId}`}>{technician.name || ''}</Link>)
          </li>
        )
      })}
    </ul>
  )
}

export default Quotes
