import React, { useState, useContext } from 'react'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'

import PartStatusSquare from 'components/PartStatusSquare'

const useStyles = makeStyles(theme => ({
  techBlock: {
    display: 'inline-block',
    width: '100px',
    backgroundColor: 'white',
    fontWeight: 'bold',
    border: '1px solid #434343'
  },
  gridBlock: {
    display: 'block',
    height: '60px',
    width: '100px',
    backgroundColor: 'white',
    border: '1px solid #434343',
    padding: '2px'
  }
}))

const PARTS_QUERY = gql`
  query partsForSchedule($startDatetime: ISO8601DateTime!, $endDatetime: ISO8601DateTime!) {
    partsForSchedule(startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      status
      price
      cost
      name
      number
      prePaymentRequired
      referenceNumber
      etaBusinessDays
      etaLastUpdated
      quote {
        id
        leadId
        technician {
          id
          firstName
          lastName
        }
        jobs {
          id
          isActive
          startDatetime
        }
      }
    }
  }
`

const Schedule = () => {
  const classes = useStyles()
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const [dayStart, setDayStart] = useState(DateTime.local().startOf('day'))

  const { error, loading, data } = useQuery(PARTS_QUERY, {
    variables: {
      startDatetime: dayStart.toISO(),
      endDatetime: dayStart.plus({ days: 14 }).toISO()
    }
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const techById = id => global.technicians.find(tech => tech.id === id)

  const partsGroupedByTechnician =
    data.partsForSchedule.reduce((acc, part) => ({
      ...acc,
      [part.quote.technician.id]: [...acc[part.quote.technician.id] || [], part]
    }), {})

  const groupPartsByQuote = parts =>
    parts.reduce((acc, part) => ({
      ...acc,
      [part.quote.id]: [...acc[part.quote.id] || [], part]
    }), {})

  return (
    <>
      <div className={classes.techBlock}>tech</div>

      {[...Array(14).keys()].map(dayIndex =>
        <div key={dayIndex} className={classes.techBlock}>
          {dayStart.plus({ days: dayIndex }).toISODate()}:
        </div>)}

      {Object.entries(partsGroupedByTechnician).map(([technicianId, parts]) =>
        <Grid container key={technicianId}>
          <div className={classes.gridBlock}>
            {techById(technicianId).firstName} {techById(technicianId).lastName}
          </div>

          {[...Array(14).keys()].map(dayIndex =>
            <div
              key={dayIndex}
              className={classes.gridBlock}
            >
              {parts.filter(part =>
                part.quote.jobs.filter(job => {
                  if (!job.isActive) { return false }

                  const thisJobStart = DateTime.fromISO(job.startDatetime)
                  const thisDay = dayStart.plus({ days: dayIndex })

                  return thisJobStart > thisDay.startOf('day') &&
                    thisJobStart < thisDay.endOf('day')
                }).length > 0
              ).map(part => (
                <Link key={part.id} to={`/leads/${part.quote.leadId}/quotes/${part.quote.id}/parts/${part.id}`}>
                  <PartStatusSquare part={part} />
                </Link>)
              )}
            </div>)}
        </Grid>
      )}
    </>
  )
}

export default Schedule
