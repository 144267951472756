import { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { FormControlLabel, Grid, Checkbox, FormControl, TextField, Box, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import ReplyIcon from '@mui/icons-material/Reply'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { UserContext } from 'UserStore'
import ConfirmDialog from 'components/ConfirmDialog'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { PriceInput, diff } from 'tools'

const useStyles = makeStyles(theme => ({
  editableGrid: {
    '& .MuiInput-root': {
      paddingBottom: '0',
    },
    '& .MuiInput-root::before': {
      borderWidth: '0',
    },
    '& .MuiInput-root input': {
      borderWidth: '0',
      paddingBottom: '0',
    },
    '& .MuiInput-root textarea': {
      borderWidth: '0',
      paddingBottom: '0',
      marginTop: '5px',
    },
    '& .MuiSelect-select': {
      paddingBottom: 0,
    },
    '& .MuiSelect-icon': {
      visibility: 'hidden',
    },
    '& .MuiInput-root:hover .MuiSelect-icon': {
      visibility: 'visible',
    },
    '& input[name="cost"], & input[name="price"]': {
      textAlign: 'right',
    },
    '& .MuiInput-root::before:hover': {
      borderWidth: '1px',
      borderColor: '#eaeae8',
    },
  },
  fontRed: {
    color: 'red',
  },
}))

const CenterAlignedGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const InlineTireconnectPartEditorGrid = ({
  quote,
  part,
  currentEditingPart,
  setCurrentEditingPart,
  editingDisabled,
  refetchQuote,
}) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [confirmRemovePartDialogOpen, setConfirmRemovePartDialogOpen] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const [changed, setChanged] = useState(false)
  const classes = useStyles()

  const unsetEditingPart = () => {
    if (currentEditingPart === formik.values.id) {
      setCurrentEditingPart('')
    }
  }

  const openPartDetails = id => window.open(`/parts/${id}`)

  const handleRemovePart = partId => {
    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/remove_part_from_quote`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        quoteId: quote.id,
        partId: partId,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .finally(() => unsetEditingPart())
  }

  const handleClickSave = part => {
    const payload = {
      ...diff(formik.initialValues, part),
      ...diff(part, formik.initialValues),
    }

    if (Object.keys(payload).length === 0) {
      return
    }

    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_part_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        partId: part.id,
        updatedAttributes: payload,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .then(res => refetchQuote())
      .finally(() => {
        setWaiting(false)
        formik.resetForm({ values: part })
      })
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('required'),
    number: Yup.string().required('required'),
    price: Yup.number(),
    quantity: Yup.number(),
    total: Yup.number(),
    isPaid: Yup.boolean().nullable(),
    prePaymentRequired: Yup.boolean(),
  })

  const formik = useFormik({
    initialValues: part,
    validationSchema: validationSchema,
    onSubmit: handleClickSave,
  })

  useEffect(() => {
    const payload = {
      ...diff(formik.values, formik.initialValues),
    }

    if (Object.keys(payload).length === 0) {
      setChanged(false)

      unsetEditingPart()
      return
    }
    setChanged(true)
    setCurrentEditingPart(part.id)
  }, [formik.values])

  useEffect(() => {
    formik.resetForm({ values: part })
    setWaiting(false)
    setChanged(false)
  }, [part])

  useEffect(() => {
    if (changed && currentEditingPart !== part.id) {
      formik.resetForm({ values: part })
    }
  }, [currentEditingPart])

  useEffect(() => {
    if (editingDisabled) {
      formik.resetForm({ values: part })
    }
  }, [editingDisabled])

  const disabled = waiting || editingDisabled
  const disablePunchout = (disabled || !quote?.activeJobIds?.length) && formik.values.number

  const locationId =
    process.env.NODE_ENV === 'production'
      ? part?.tireConnectData?.locationId || part?.tireConnectData?.location_id
      : 12674

  const pinCode = process.env.NODE_ENV === 'production' ? 213616 : 416905

  const apiKey =
    process.env.NODE_ENV === 'production' ? '0fb1de2c96ac2f8667c3c53241270d64' : '05198c64d142efd851ef15e54fdd917d'

  const tireId =
    process.env.NODE_ENV === 'production' ? part?.tireConnectData?.id : 'S2VsbHl8fDM1Njk5MDA3Nnx8a218fDEyNjc0fHxC'

  const handlePurchase = () => {
    if (window.confirm('Are you sure you want to order this tire?')) {
      setWaiting(true)
      fetch(`https://${process.env.NODE_ENV === 'production' ? 'wl' : 'devdemo'}.tireconnect.ca/api/location/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dealer_location_id: locationId,
          pin_code: pinCode, // 416905, 213616
          key: apiKey,
        }),
      }).then(res => {
        res.json().then(res => {
          const { token } = res

          fetch(
            `https://${
              process.env.NODE_ENV === 'production' ? 'wl' : 'devdemo'
            }.tireconnect.ca/api/supplier/order/create?token=${token}`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                tires: [
                  {
                    quantity: part?.quantity,
                    tire_id: tireId,
                  },
                ],
                supplier_fields: {
                  po_number: '1234',
                  DeliveryMethod: 'Pickup',
                  fillkill: 'fak',
                  shipping_method_local: 'pickup',
                },
              }),
            }
          ).then(res =>
            res.json().then(() =>
              part?.allPartIds?.map(partId => {
                const order = {
                  userId: user.id,
                  partId: partId,
                  quoteId: quote.id,
                  orderData: {
                    invoice: res?.invoice,
                    po_number: part?.tireConnectData?.po_number,
                    // business_name: part?.tireConnectData,
                    ...part?.tireConnectData,
                    branch_id: part?.tireConnectData?.branchId,
                    branch_name: part?.tireConnectData?.branchName,
                    supplier: part?.tireConnectData?.supplier,
                    order_info: part?.tireConnectData,
                  },
                }

                fetch(`${process.env.REACT_APP_COMMAND_ROOT}/place_order_tire_connect`, {
                  method: 'POST',
                  headers: bearerTokenHeaders,
                  body: JSON.stringify(order),
                })
              })
            )
          )
        })
      })
    }
  }

  return (
    <>
      <Grid container columnSpacing={1} sx={{ px: 1 }} className={classes.editableGrid}>
        <CenterAlignedGridItem item xs={3}>
          <TextField
            fullWidth
            multiline
            size='small'
            name='name'
            type='text'
            variant='standard'
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            disabled={disabled}
          />
        </CenterAlignedGridItem>
        <CenterAlignedGridItem item xs={3}>
          <TextField
            fullWidth
            size='small'
            name='number'
            variant='standard'
            value={formik.values.number}
            error={formik.touched.number && Boolean(formik.errors.number)}
            disabled={disabled}
            sx={{ '& .MuiInputBase-root input': { textAlign: 'right' } }}
          />
        </CenterAlignedGridItem>
        <CenterAlignedGridItem item xs={2}>
          <TextField
            disabled
            required
            fullWidth
            variant='standard'
            size='small'
            name='cost'
            value={formik.values.cost}
            error={formik.touched.cost && Boolean(formik.errors.cost)}
            onChange={event => {
              const numberValue = Number(event.target.value)
              formik.setFieldValue('cost', numberValue)
            }}
            InputProps={{
              inputComponent: PriceInput,
            }}
          />
        </CenterAlignedGridItem>
        <CenterAlignedGridItem item xs={1}>
          <TextField
            required
            disabled
            fullWidth
            variant='standard'
            size='small'
            name='quantity'
            value={formik.values.quantity}
            error={formik.touched.quantity && Boolean(formik.errors.quantity)}
            onChange={event => {
              const numberValue = Number(event.target.value)
              formik.setFieldValue('quantity', numberValue)
            }}
            // disabled={disabled}
            sx={{ '& .MuiInputBase-root input': { textAlign: 'right' } }}
          />
        </CenterAlignedGridItem>
        <CenterAlignedGridItem item xs={2}>
          <TextField
            required
            fullWidth
            variant='standard'
            size='small'
            name='price'
            value={formik.values.price}
            error={formik.touched.price && Boolean(formik.errors.price)}
            onChange={event => {
              const numberValue = Number(event.target.value)
              formik.setFieldValue('price', numberValue)
            }}
            InputProps={{
              inputComponent: PriceInput,
            }}
            disabled
          />
        </CenterAlignedGridItem>
        <CenterAlignedGridItem item xs={1} sx={{ justifyContent: 'flex-end' }}>
          <IconButton
            onClick={() => setConfirmRemovePartDialogOpen(part.id)}
            sx={{ p: 0.25 }}
            disabled={disabled}
            id='delete-part-button'
          >
            <DeleteIcon fontSize='small' />
          </IconButton>
        </CenterAlignedGridItem>
      </Grid>
      <Grid container columnSpacing={1} sx={{ px: 1 }} className={classes.editableGrid}>
        <CenterAlignedGridItem item xs={4} sx={{ borderBottom: '1px dotted lightgray' }}>
          <FormControlLabel
            sx={{ m: 0 }}
            className={formik.values.prePaymentRequired && classes.fontRed}
            control={
              <Checkbox
                name='prePaymentRequired'
                onChange={formik.handleChange}
                checked={formik.values.prePaymentRequired}
              />
            }
            label='PRE-PAY'
          />
        </CenterAlignedGridItem>
        <CenterAlignedGridItem item xs={5}>
          <FormControl fullWidth>
            <Button
              size='small'
              onClick={() => {
                handlePurchase()
                // setWidgetOpen(true)
              }}
              disableElevation
              variant='contained'
              disabled={disablePunchout || part?.tireConnectOrderData || waiting}
              name='add-new-part-button'
            >
              {waiting ? <>Making purchase...</> : <>{!part?.tireConnectOrderData ? 'Order' : 'Ordered'}</>}
            </Button>
          </FormControl>
        </CenterAlignedGridItem>
        <CenterAlignedGridItem
          item
          direction='row'
          justifyContent='flex-end'
          alignItems='flex-end'
          sx={{ display: 'flex', borderBottom: '1px dotted lightgray' }}
          xs={3}
        >
          {changed && (
            <>
              <Box sx={{ m: 0 }}>
                <button
                  onClick={() => {
                    if (waiting) {
                      return
                    }
                    formik.resetForm({ values: part })
                    setCurrentEditingPart('')
                  }}
                  disabled={disabled}
                >
                  reset
                </button>
              </Box>
              <Box sx={{ ml: 2, mr: 1 }}>
                {'> '}
                <button onClick={formik.handleSubmit} disabled={disabled}>
                  save
                </button>
                {' <'}
              </Box>
            </>
          )}
          {!changed && (
            <IconButton onClick={() => openPartDetails(part.id)} sx={{ p: 0.25 }} disabled={disabled}>
              <ReplyIcon title='open part details' style={{ transform: 'scaleX(-1)' }} />
            </IconButton>
          )}
        </CenterAlignedGridItem>
      </Grid>

      <ConfirmDialog
        open={confirmRemovePartDialogOpen}
        title='Confirm Action'
        heading='Are you sure you want to remove this part from the quote?'
        text='This action cannot be undone.'
        okText='Yes'
        cancelText='No'
        onClose={res => {
          setConfirmRemovePartDialogOpen(false)
          if (res) {
            handleRemovePart(confirmRemovePartDialogOpen)
          }
        }}
      />
    </>
  )
}

export default InlineTireconnectPartEditorGrid
