import React from 'react'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { Dollars } from 'tools'

const useStyles = makeStyles(theme => ({
  summaryList: {
    width: '100%',
    backgroundColor: '#f2f2f2',
  },
}))

const ListItemValue = ({ sx, style, title, value }) => (
  <Grid container spacing={1} style={style}>
    <Grid item xs={9} style={{ textAlign: 'right' }}>
      {title}
    </Grid>

    <Grid
      item
      xs={3}
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
      }}
    >
      <strong>
        <Dollars value={value} />
      </strong>
    </Grid>
  </Grid>
)

const QuoteTotalsV2 = ({ quoteTotals }) => {
  return (
    <Grid container sx={{ backgroundColor: '#f2f2f2', pr: 2 }}>
      <ListItemValue title='Labor:' value={quoteTotals.laborSumAfterAllDiscounts} />

      <ListItemValue title='Parts:' value={quoteTotals.partsSum} />

      <ListItemValue title='Technician charges:' value={quoteTotals.technicianChargesSum} />

      <ListItemValue title='Carbodylab charges:' value={quoteTotals.carbodylabChargesSum} />

      <ListItemValue title='Grand total before tax' value={quoteTotals.preTaxGrandTotal} />

      <ListItemValue
        title={
          <Box>
            Sales tax on <Dollars value={quoteTotals.taxableAmount} /> @{' '}
            {(Number(quoteTotals.taxRate) * 100).toFixed(2)}%
          </Box>
        }
        value={quoteTotals.taxTotal}
      />
      <Divider />

      <ListItemValue
        style={{
          fontSize: '1.05em',
          fontWeight: 'bold',
        }}
        title='Grand total after tax'
        value={quoteTotals.postTaxGrandTotal}
      />

      {quoteTotals.preJobGrandTotal > 0 && (
        <ListItemValue
          style={{ color: 'red', fontWeight: 'bold' }}
          title='PRE-PAY AMOUNT'
          value={quoteTotals.preJobGrandTotal}
        />
      )}
    </Grid>
  )
}

export default QuoteTotalsV2
