import React from 'react'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <span variant='h6'>{children}</span>
      {onClose &&
        <>
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </>}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50ch'
    }
  }
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogActions)

export default function ConfirmDialog (props) {
  const { onClose, onOK, title, heading, text, okText, cancelText, value: valueProp, open, ...other } = props

  const handleClose = (e, reason) => {
    e.stopPropagation()
    if (reason === 'backdropClick') return

    onClose()
  }

  const handleCancel = () => onClose()

  const handleOk = () => onClose(1)

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth='xs'
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {heading && <Box fontSize='h6.fontSize'>{heading}</Box>}
        {text && <p>{text}</p>}
      </DialogContent>
      <DialogActions>
        <Button autoFocus color='cancel' onClick={handleCancel}>
          {cancelText || 'Cancel'}
        </Button>
        <Button onClick={handleOk}>{okText || 'Ok'}</Button>
      </DialogActions>
    </Dialog>
  )
}
