import {
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
  CircularProgress,
  MenuItem,
  Dialog,
  Drawer,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { CheckCircle, CloseRounded, DeleteRounded, Error } from '@mui/icons-material'
import { useDebounce } from 'use-debounce/lib'

export const EditSchedulerDrawer = ({
  editJobDrawerOpen,
  setEditJobDrawerOpen,
  selectedScheduler,
  dealer,
  refetchAll,
}: any) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const handleClickDeleteJob = () => {
    window.confirm('are you sure you want to delete this?') &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_job`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          b_to_b_job_id: selectedScheduler.id,
        }),
      })
        .then(res => {
          setEditJobDrawerOpen(false)

          if (res.ok) {
            console.log('ok')
          } else {
            window.alert('Error')
          }
        })
        .catch(() => window.alert('let eric know about this'))
  }

  return (
    <Drawer
      anchor='right'
      PaperProps={{
        sx: {
          maxWidth: '500px',
        },
      }}
      open={editJobDrawerOpen}
      onClose={() => setEditJobDrawerOpen(false)}
    >
      {selectedScheduler && (
        <Box
          sx={{
            padding: '.5rem 1rem',
            userSelect: 'none',
            mb: '.5rem',
            '& b': {
              fontSize: '22px',
              fontWeight: 500,
            },
            '& span': {
              fontSize: '13px',
              color: '#333',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              '& svg': { color: '#777' },
              pb: '.75rem',
              pt: '.25rem',
            }}
          >
            <Button onClick={handleClickDeleteJob} sx={{ minWidth: '30px', ml: '.5rem' }} size='small'>
              <DeleteRounded fontSize='small' />
            </Button>
            <Button onClick={() => setEditJobDrawerOpen(false)} sx={{ minWidth: '30px', ml: '1rem' }} size='small'>
              <CloseRounded fontSize='small' />
            </Button>
          </Box>

          <CreateJob
            setEditJobDrawerOpen={setEditJobDrawerOpen}
            selectedScheduler={selectedScheduler}
            dealer={dealer}
            refetchAll={refetchAll}
          />
        </Box>
      )}
    </Drawer>
  )
}

export const CreateJob = ({ dealer, selectedScheduler, setEditJobDrawerOpen, refetchAll }: any) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [ignoreAvailability, setIgnoreAvailability] = useState(false)
  const [date, setDate] = useState(DateTime.fromISO(selectedScheduler.dateTime, { zone: 'utc' }))
  const [startDatetime, setStartDatetime] = useState(
    DateTime.fromISO(selectedScheduler.startDatetime, { zone: 'utc' })
  )
  const [endDatetime, setEndDatetime] = useState(DateTime.fromISO(selectedScheduler.endDatetime, { zone: 'utc' }))
  const [jobDuration, setJobDuration] = useState({ hours: 0, minutes: 0 })
  const [scheduleCheck, setScheduleCheck] = useState({ startDatetime, endDatetime })
  const [debouncedScheduleCheck] = useDebounce(scheduleCheck, 500, { leading: false })
  const [fitsOnSchedule, setFitsOnSchedule] = useState(true)
  const [selectedTechnicianId, setSelectedTechnicianId] = useState(selectedScheduler.technician.id)

  const [confirmRecurrenceOptionPopoverOpen, setConfirmRecurrenceOptionPopoverOpen] = useState(false)
  const [selectedRecurrenceChange, setSelectedRecurrenceChange] = useState<null | string>(null)

  const [loading, setLoading] = useState(false)

  const handleClickSave = () => {
    return new Promise((resolve, reject) => {
      if (selectedRecurrenceChange === 'This event') {
        const getNewStartTime = () => {}

        const newStartTime =
          // @ts-ignore
          (selectedScheduler?.dateTime).toISO().substring(0, 10) + startDatetime.toISO().substring(10)
        // @ts-ignore
        const newEndTime = selectedScheduler.dateTime.toISO().substring(0, 10) + endDatetime.toISO().substring(10)

        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_excluded_day_to_b_to_b_job_scheduler`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            user_id: user.id,
            b_to_b_job_scheduler_id: selectedScheduler.id,
            excluded_day: selectedScheduler.dateTime.toFormat('MM-dd-yy'),
          }),
        }).then(res =>
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_job`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              user_id: user.id,
              start_datetime: newStartTime,
              end_datetime: newEndTime,
              ignore_availability: ignoreAvailability,
              technician_id: selectedScheduler.technician.id,
              b_to_b_dealer_id: dealer.id,
              scheduler_id: selectedScheduler.id,
            }),
          })
            .then(res => resolve(res))
            .catch(err => reject(err))
        )
      }

      if (selectedRecurrenceChange === 'This and all following events') {
          // @ts-ignore
        const newStartTime = selectedScheduler.dateTime.toISO().substring(0, 10) + startDatetime.toISO().substring(10)
          // @ts-ignore
        const newEndTime = selectedScheduler.dateTime.toISO().substring(0, 10) + endDatetime.toISO().substring(10)

        let recurrencePattern: string

        if (selectedScheduler.recurrencePeriod === 'week') {
          recurrencePattern = selectedScheduler.recurrencePattern
        }

        if (selectedScheduler.recurrencePeriod === 'month') {
          const dateToChange = selectedScheduler.dateTime
          const schedulerWithoutThisDate = selectedScheduler.recurrencePattern.filter(
            (date: string) => date !== dateToChange.toFormat('dd')
          )
          recurrencePattern = schedulerWithoutThisDate.concat([date.toFormat('dd')])
        }

        if (selectedScheduler.recurrencePeriod === 'year') {
          const dateToChange = selectedScheduler.dateTime
          const schedulerWithoutThisDate = selectedScheduler.recurrencePattern.filter(
            (date: string) => date !== dateToChange.toFormat('MM-dd')
          )
          recurrencePattern = schedulerWithoutThisDate.concat([date.toFormat('MM-dd')])
        }

        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_job_scheduler_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            user_id: user.id,
            b_to_b_job_scheduler_id: selectedScheduler.id,
            updated_attributes: {
              recurrence_ends_at: selectedScheduler.dateTime.toISO(),
            },
          }),
        }).then(res =>
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_job_scheduler`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              user_id: user.id,
              start_datetime: newStartTime,
              end_datetime: newEndTime,
              ignore_availability: ignoreAvailability,
              technician_id: selectedTechnicianId,
              b_to_b_dealer_id: dealer.id,
              recurrence_pattern: recurrencePattern,
              recurrence_period: selectedScheduler.recurrencePeriod,
              recurrence_ends_at: selectedScheduler.recurrenceEndsAt,
              recurrence_interval: selectedScheduler.recurrenceInterval,
            }),
          })
            .then(res => resolve(res))
            .catch(err => reject(err))
        )
      }

      if (selectedRecurrenceChange === 'All events') {
        let recurrencePattern: string

        if (selectedScheduler.recurrencePeriod === 'week') {
          recurrencePattern = selectedScheduler.recurrencePattern
        }

        if (selectedScheduler.recurrencePeriod === 'month') {
          const dateToChange = selectedScheduler.dateTime
          const schedulerWithoutThisDate = selectedScheduler.recurrencePattern.filter(
            (date: string) => date !== dateToChange.toFormat('dd')
          )
          recurrencePattern = schedulerWithoutThisDate.concat([date.toFormat('dd')])
        }

        if (selectedScheduler.recurrencePeriod === 'year') {
          const dateToChange = selectedScheduler.dateTime
          const schedulerWithoutThisDate = selectedScheduler.recurrencePattern.filter(
            (date: string) => date !== dateToChange.toFormat('MM-dd')
          )
          recurrencePattern = schedulerWithoutThisDate.concat([date.toFormat('MM-dd')])
        }

        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_job_scheduler_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            user_id: user.id,
            b_to_b_job_scheduler_id: selectedScheduler.id,
            updated_attributes: {
              start_datetime: startDatetime.toISO(),
              end_datetime: endDatetime.toISO(),
              technician_id: selectedTechnicianId,
              // @ts-ignore
              recurrence_pattern: recurrencePattern,
            },
          }),
        })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }
    }).then(() => {
      setTimeout(() => setEditJobDrawerOpen(), 250)
      setTimeout(() => refetchAll(), 250)
    })
  }

  const handleChangedDate = (event: any) => {
    setFitsOnSchedule(false)
    setDate(DateTime.fromISO(event.target.value))
  }

  const handleChangedStartTime = (event: any) => {
    setFitsOnSchedule(false)
    setStartDatetime(DateTime.fromISO(startDatetime.toFormat('kkkk-LL-dd') + 'T' + event.target.value, { zone: 'utc' }))
  }
  const handleChangedEndTime = (event: any) => {
    setFitsOnSchedule(false)
    setEndDatetime(DateTime.fromISO(endDatetime.toFormat('kkkk-LL-dd') + 'T' + event.target.value, { zone: 'utc' }))
  }

  const jobDurationIsValid = jobDuration && (jobDuration.hours > 0 || jobDuration.minutes > 0)

  useEffect(() => {
    const newStartDatetime = DateTime.fromISO(date.toFormat('kkkk-LL-dd') + 'T' + startDatetime.toFormat('HH:mm'), {
      zone: 'utc',
    })

    setStartDatetime(newStartDatetime)
    setEndDatetime(DateTime.fromISO(date.toFormat('kkkk-LL-dd') + 'T' + endDatetime.toFormat('HH:mm'), { zone: 'utc' }))
  }, [date])

  const checkScheduleFit = () => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/check_technician_schedule_fit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...debouncedScheduleCheck,
        user_id: user.id,
        technician_id: selectedTechnicianId,
        ignore_availability: ignoreAvailability,
      }),
    })
      .then(res => {
        if (res.ok) {
          return true
        } else {
          return false
        }
      })
      .then(res => {
        setFitsOnSchedule(res)
        setLoading(false)
      })
  }

  useEffect(() => {
    // @ts-ignore
    setJobDuration(endDatetime.diff(startDatetime, ['hours', 'minutes']).toObject())
    setScheduleCheck({ ...scheduleCheck, ...{ startDatetime, endDatetime } })
  }, [startDatetime, endDatetime])

  const jobDurationStringOrNull = (jobDurationIsValid && `${jobDuration.hours}h, ${jobDuration.minutes}m`) || null
  const allowedToSave = jobDuration && (jobDuration.hours > 0 || jobDuration.minutes > 0) && fitsOnSchedule
  const approvedTechsForQuote = dealer?.technicians || []

  return (
    <Box>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#11111140',
            pointerEvents: 'none',
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box sx={{ padding: '1rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex' }}>
              <FormControl fullWidth size='small'>
                <InputLabel>Select Technician</InputLabel>
                <Select
                  value={selectedTechnicianId}
                  onChange={e => setSelectedTechnicianId(e.target.value)}
                  label='Select Technician'
                  size='small'
                  sx={{ minWidth: '200px' }}
                  placeholder='select technician'
                >
                  {approvedTechsForQuote.map((tech: any) => (
                    <MenuItem value={tech.id}>
                      {tech.firstName} {tech.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label='date'
              type='date'
              fullWidth
              size='small'
              value={date.toFormat('kkkk-LL-dd')}
              onChange={handleChangedDate}
              style={{ maxWidth: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size='small'
              label='start time'
              type='time'
              id='new-job-start-time'
              value={startDatetime.toFormat('HH:mm')}
              onChange={handleChangedStartTime}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size='small'
              label='end time'
              type='time'
              id='new-job-end-time'
              onChange={handleChangedEndTime}
              value={endDatetime.toFormat('HH:mm')}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: '0rem 1rem 1rem 1rem', textAlign: 'center', '& button': { mt: '.5rem' } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          conflicts? &nbsp;
          {fitsOnSchedule ? <CheckCircle style={{ color: 'lightgreen' }} /> : <Error style={{ color: 'red' }} />}
        </Box>
        <Button disableElevation size='small' variant='contained' onClick={checkScheduleFit}>
          Check schedule conflicts
        </Button>
      </Box>
      <Box sx={{ display: 'flex', padding: '1rem', borderTop: '2px solid #a1cff7' }}>
        <Button disabled={!allowedToSave} onClick={() => setConfirmRecurrenceOptionPopoverOpen(true)}>
          Save
        </Button>
      </Box>

      <Dialog
        PaperProps={{ sx: { maxWidth: '400px' } }}
        open={confirmRecurrenceOptionPopoverOpen}
        onClose={() => setConfirmRecurrenceOptionPopoverOpen(false)}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{ padding: '1rem', background: 'aliceblue', borderBottom: '2px solid #a1cff7', fontWeight: 600 }}
          >
            Edit recurring event
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', padding: '.5rem 1rem' }}>
            <FormControlLabel
              control={
                <Radio
                  onChange={() => setSelectedRecurrenceChange('This event')}
                  checked={selectedRecurrenceChange === 'This event'}
                />
              }
              label='This event'
            />
            <FormControlLabel
              control={
                <Radio
                  onChange={() => setSelectedRecurrenceChange('This and all following events')}
                  checked={selectedRecurrenceChange === 'This and all following events'}
                />
              }
              label='This and all following events'
            />
            <FormControlLabel
              control={
                <Radio
                  onChange={() => setSelectedRecurrenceChange('All events')}
                  checked={selectedRecurrenceChange === 'All events'}
                />
              }
              label='All events'
            />
          </Grid>
          <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex', padding: '.5rem' }}>
            <Button onClick={() => setConfirmRecurrenceOptionPopoverOpen(false)}>Cancel</Button>
            <Button disabled={!selectedRecurrenceChange} onClick={handleClickSave}>
              OK
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  )
}
