import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import MarketRouter from './Market/Router'
import Index from './Index'

const QUERY = gql`
  query markets {
    markets {
      id
      market
      state
      stateCode
      hourlyRatePrep
      hourlyRatePaint
      hourlyRateBodyWork
      hourlyRateMajorPart
      hourlyRateMinorPart
      quoteApprovalRequired
    }
  }
`

const Router = () => {
  const { path } = useRouteMatch()
  const { loading, error, data, refetch } = useQuery(QUERY)

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)
  const markets = data.markets

  return (
    <Switch>
      <Route path={`${path}`}>
        <Index markets={markets} />
      </Route>
    </Switch>
  )
}

export default Router
