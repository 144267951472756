import { useContext, useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { UserContext } from 'UserStore'
import { Button, MenuItem, Select, TextField } from '@mui/material'
import { DeleteRounded } from '@mui/icons-material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

export const CatalogItemRow = ({ laborItem, refetch }: { laborItem: any; refetch: any }) => {
  const [user] = useContext(UserContext)
  const [name, setName] = useState(laborItem.name)

  const bearerTokenHeaders = useBearerTokenHeaders()
  const nameNeedsSaving = name !== laborItem.name

  const handleSaveAttribute = (name: string, attribute: string) => {
    window.confirm(`are you sure you want to update the ${name} to ${attribute}?`) &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_part_catalog_item_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          part_catalog_item_id: laborItem.id,
          updated_attributes: {
            [name]: attribute,
            updated_by_user_id: user.id,
          },
        }),
      }).then(res => {
        if (res) {
          refetch()
        } else {
          console.log('ERROR!')
        }
      })
  }

  const deleteLaborItem = () => {
    window.confirm(`are you sure you want to update delete this labor item?`) &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_part_catalog_item`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          part_catalog_item_id: laborItem.id,
        }),
      }).then(res => {
        if (res) {
          refetch()
        } else {
          console.log('ERROR!')
        }
      })
  }

  return (
    <TableRow>
      <TableCell
        sx={{
          display: 'flex',
          alignitems: 'center',
          '& .MuiSelect-select': {
            padding: '.25rem .25rem .25rem .5rem',
          },
          '& fieldset': {
            borderColor: nameNeedsSaving ? 'orange !important' : '',
            borderWidth: nameNeedsSaving ? '2px !important' : '',
          },
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <TextField
          // @ts-ignore
          onKeyDown={e => e.code === 'Enter' && handleSaveAttribute('name', e.target.value)}
          fullWidth
          size='small'
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Button onClick={deleteLaborItem} color='error' sx={{ minWidth: '40px' }}>
          <DeleteRounded fontSize='small' />
        </Button>
      </TableCell>
    </TableRow>
  )
}
