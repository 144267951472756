import { useQuery, gql } from '@apollo/client'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Chip, Skeleton } from '@mui/material'
import {
  CompareArrowsRounded,
  GroupWorkRounded,
  MonetizationOnRounded,
  SellRounded,
  TimeToLeaveRounded,
} from '@mui/icons-material'
import { useState } from 'react'

const PRICE_CATEGORIES = gql`
  query BToBPriceCategories($dealerId: String) {
    bToBPriceCategories(dealerId: $dealerId) {
      id
      createdAt
      name
      isCustom
      pricePoints {
        id
        price
        pointAmount
        commissionAmount
        customPricePoint {
          id
          price
          commissionAmount
        }
        panelGroup {
          id
          name
        }
      }
      categoriesWithoutPricePoints {
        id
        name
      }
    }
  }
`

const PANELS_QUERY = gql`
  query BToBPanels {
    bToBPanels {
      id
      name
      side
      panel
      type
      panelGroupId
      inheritPrice
      isCustom
      panelGroup {
        name
      }
    }
  }
`

const PANEL_GROUPS = gql`
  query bToBPanelGroups {
    bToBPanelGroups {
      id
      name
      isCustom
    }
  }
`

export const LineItemsTable = ({ dealer }: any) => {
  const [selectedPanel, setSelectedPanel] = useState(null)
  const [selectedPricingCategory, setSelectedPricingCategory] = useState(null)
  const [selectedPanelGroup, setSelectedPanelGroup] = useState(null)

  const {
    loading: loadingPanels,
    error: errorPanels,
    data: panelsData,
    refetch: refetchPanels,
  } = useQuery(PANELS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const {
    loading: loadingPanelGroups,
    error: errorPanelGroups,
    data: panelGroupsData,
    refetch: refetchPanelGroups,
  } = useQuery(PANEL_GROUPS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: categoriesdata,
    refetch: refetchCategories,
  } = useQuery(PRICE_CATEGORIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      dealerId: dealer.id,
    },
  })

  if (errorCategories || errorPanels || errorPanelGroups) return <div>Error!</div>

  const priceCategories = categoriesdata?.bToBPriceCategories || []
  const panelsList = panelsData?.bToBPanels || []
  const panelGroups = panelGroupsData?.bToBPanelGroups || []

  const lineItems: any = []

  !loadingCategories &&
    !loadingPanels &&
    !loadingPanelGroups &&
    priceCategories.forEach((pricingCategory: any) => {
      pricingCategory.pricePoints.forEach((pricePoint: any) => {
        const panelGroupForPricePoint = panelGroups.find(
          (panelGroup: any) => panelGroup.id === pricePoint.panelGroup.id
        )
        const panelsInCategory = panelsList.filter((panel: any) => panel.panelGroupId === panelGroupForPricePoint.id)

        panelsInCategory.forEach((panel: any) => {
          let name = `${panel.panel} - ${pricingCategory.name}`

          if (panel.side) {
            if (panel.side === 'front' || panel.side === 'rear') {
              name = `${panel.panel} - ${panel.side} Side - ${pricingCategory.name}`
            } else if (panel.side !== '') {
              name = `${panel.panel} - ${panel.side} - ${pricingCategory.name}`
            } else {
              name = `${panel.panel} - ${pricingCategory.name}`
            }
          }

          if (pricePoint.customPricePoint) {
            if (pricePoint.customPricePoint.price !== 0) {
              lineItems.push({
                name: name,
                panelType: panel.panelType,
                panel: panel.panel,
                side: panel.side,
                type: pricingCategory.name,
                panelGroupId: panelGroupForPricePoint.id,
                price: pricePoint.price,
                panelGroup: {
                  name: panelGroupForPricePoint.name,
                },
                pricePoint: pricePoint,
                pricingCategory: pricingCategory,
                pointAmount: pricePoint.pointAmount,
              })
            }
          } else {
            lineItems.push({
              name: name,
              panelType: panel.panelType,
              panel: panel.panel,
              side: panel.side,
              type: pricingCategory.name,
              panelGroupId: panelGroupForPricePoint.id,
              price: pricePoint.price,
              panelGroup: {
                name: panelGroupForPricePoint.name,
              },
              pricePoint: pricePoint,
              pricingCategory: pricingCategory,
              pointAmount: pricePoint.pointAmount,
            })
          }
        })
      })
    })

  let filteredLineItems = lineItems

  if (selectedPanel) {
    filteredLineItems = filteredLineItems.filter((item: any) => item.panel === selectedPanel)
  }

  if (selectedPricingCategory) {
    filteredLineItems = filteredLineItems.filter((item: any) => item.pricingCategory.name === selectedPricingCategory)
  }

  if (selectedPanelGroup) {
    filteredLineItems = filteredLineItems.filter((item: any) => item.panelGroup.name === selectedPanelGroup)
  }

  return (
    <TableContainer component={Paper}>
      <Table size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>Price</TableCell>
            <TableCell>Points</TableCell>
            <TableCell>Line Item Name</TableCell>
            <TableCell>
              Panel{' '}
              {selectedPanel && (
                <Chip
                  variant='outlined'
                  onDelete={() => setSelectedPanel(null)}
                  icon={<TimeToLeaveRounded />}
                  size='small'
                  label={selectedPanel}
                />
              )}
            </TableCell>
            <TableCell>Side</TableCell>
            <TableCell>
              Panel Group{' '}
              {selectedPanelGroup && (
                <Chip
                  variant='outlined'
                  onDelete={() => setSelectedPanelGroup(null)}
                  icon={<GroupWorkRounded />}
                  size='small'
                  label={selectedPanelGroup}
                />
              )}
            </TableCell>
            <TableCell>
              Pricing Category{' '}
              {selectedPricingCategory && (
                <Chip
                  variant='outlined'
                  onDelete={() => setSelectedPricingCategory(null)}
                  icon={<SellRounded />}
                  size='small'
                  label={selectedPricingCategory}
                />
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* @ts-ignore */}
          {(loadingCategories || loadingPanels || loadingPanelGroups) && <TableLoadingSkeleton />}
          {!loadingCategories &&
            !loadingPanels &&
            !loadingPanelGroups &&
            filteredLineItems
              ?.sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
              .map((lineItem: any) => (
                <TableRow key={lineItem.id}>
                  <TableCell sx={{ minWidth: '80px', maxWidth: '80px' }}>
                    {lineItem.pricePoint.customPricePoint ? (
                      <Chip
                        color='success'
                        icon={<MonetizationOnRounded />}
                        size='small'
                        // variant='outlined'
                        label={lineItem.pricePoint.customPricePoint.price}
                        sx={{ marginRight: '.5rem', borderWidth: 2, fontWeight: 600 }}
                      />
                    ) : (
                      <Chip
                        icon={<MonetizationOnRounded />}
                        size='small'
                        label={lineItem.price}
                        sx={{ marginRight: '.5rem' }}
                      />
                    )}
                  </TableCell>
                  <TableCell sx={{ textTransform: 'capitalize', maxWidth: '50px !important', minWidth: '50px' }}>
                    {lineItem.pointAmount}
                  </TableCell>
                  <TableCell>{lineItem.name}</TableCell>
                  <TableCell>
                    <Chip
                      onClick={() => setSelectedPanel(lineItem.panel)}
                      icon={<TimeToLeaveRounded />}
                      size='small'
                      label={lineItem.panel}
                      sx={{
                        marginRight: '.5rem',
                        border: '1px solid transparent',
                        '&:hover': { border: '1px solid #aaa' },
                        cursor: 'pointer',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {lineItem.side && (
                      <Chip
                        icon={<CompareArrowsRounded />}
                        size='small'
                        label={lineItem.side}
                        sx={{ marginRight: '.5rem' }}
                      />
                    )}
                  </TableCell>
                  <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <Chip
                      icon={<GroupWorkRounded />}
                      size='small'
                      label={lineItem.panelGroup.name}
                      sx={{
                        marginRight: '.5rem',
                        border: '1px solid transparent',
                        '&:hover': { border: '1px solid #aaa' },
                        cursor: 'pointer',
                      }}
                      onClick={() => setSelectedPanelGroup(lineItem.panelGroup.name)}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      icon={<SellRounded />}
                      size='small'
                      label={lineItem.pricingCategory.name}
                      sx={{
                        marginRight: '.5rem',
                        border: '1px solid transparent',
                        '&:hover': { border: '1px solid #aaa' },
                        cursor: 'pointer',
                      }}
                      onClick={() => setSelectedPricingCategory(lineItem.pricingCategory.name)}
                    />
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TableLoadingSkeleton = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map(item => (
    <TableRow key={item}>
      {[1, 2, 3, 4, 5, 6, 7].map(inner => (
        <TableCell sx={{ padding: '.25rem' }} key={inner}>
          <Skeleton
            sx={{
              minWidth: '100%',
              minHeight: '28px',
              transform: 'scale(1)',
              background: item % 2 !== 0 ? '#0089CD1a' : '#f1f1f1',
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  ))
}
