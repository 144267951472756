import { forwardRef, useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { DateTime, Duration } from 'luxon'

export const capitalizeWords = inputString =>
  inputString
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize () {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

export const checkSubsetArray = (parentArray, subsetArray) => {
  return subsetArray.every((el) => {
    return parentArray.includes(el)
  })
}

export const basicDateTimeFormatter = (v) => {
  const datetime = DateTime.fromISO(v)
  return datetime.isValid ? datetime.toFormat(basicDateTimeFormat) : ''
}

export const basicDateTimeFormat = 'yyyy-LL-dd, hh:mm a'
export const thumbnailDateTimeFormat = 'L-d-yy h:mm a'
export const ZipcodeRegex = /[0-9]{5}/
export const PhoneRegex = /\+1\d{10}/
export const UrlRegex = /[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/

export const TLD = process.env.NODE_ENV === 'production' ? 'com' : 'local'

export const bearerTokenHeaders = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`
}

export const secondsToHoursView = sec => Duration.fromMillis((sec || 0) * 1000).shiftTo('hours', 'minutes', 'seconds').toFormat('hh:mm:ss')

export const cdnUrl = url => url.replace(/\.us-east-2/, '-accelerate')

export const UUIDv4Regex = /[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}/i

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

// no longer in use in any view
export const thumbnailForPhotoUrl = ({ thumbnailUrls, photoUrl }) => {
  const thisUUIDMatch = photoUrl.match(UUIDv4Regex)
  const thisUUID = thisUUIDMatch && thisUUIDMatch[0]
  const thumbnailUrl = thisUUID && thumbnailUrls && thumbnailUrls.find(url => url.match(thisUUID))

  return thumbnailUrl || photoUrl
}

export const thumbnailUrlForPhoto = (photo) => {
  return cdnUrl(photo.thumbnailUrl || photo.largeUrl || photo.originalUrl)
}

export const fullSizeUrlForPhoto = photo => {
  if (!photo) return null
  return cdnUrl(photo.largeUrl || photo.originalUrl)
}

export const formatPhotoTimestamp = (ts) => {
  return DateTime.fromISO(ts).toFormat(thumbnailDateTimeFormat)
}

export const Dollars = ({ value }) =>
  <NumberFormat
    value={value}
    decimalScale={2}
    fixedDecimalScale
    displayType='text'
    thousandSeparator
    prefix='$'
  />

export const PriceInput = forwardRef((props, ref) => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      prefix='$'
    />
  )
})

export const PhoneInput = props => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      format={val => {
        const cleanedValue = (val || '').replace(/\D/g, '').replace(/^1/, '').substr(0, 10)
        const arr = ['('].concat(cleanedValue.split(''))
        arr.length > 4 && arr.splice(4, 0, ') ')
        arr.length > 8 && arr.splice(8, 0, '-')

        return arr.join('')
      }}
      mask='_'
    />
  )
}

export const ZipInput = props => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      format='#####'
      mask='_'
    />
  )
}

const API_AUTH_STRING = 'Basic ' + btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`)

export const API_COMMAND_HEADERS = {
  Authorization: API_AUTH_STRING,
  'Content-Type': 'application/json'
}

export const downloadStringAsFile = ({ string, fileName }) => {
  const element = document.createElement('a')
  const file = new Blob([string], { type: 'text/plain;charset=utf-8' })
  element.href = URL.createObjectURL(file)
  element.download = fileName
  document.body.appendChild(element)
  element.click()
}

export const getPresignedUploadParams = async filename =>
  fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_presigned_s3_url`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({ filename })
  })
    .then(res => res.ok && res.json())
    .then(data => data)

export const getUploadParamsForDropzone = async ({ file, meta: { name } }) => {
  const { uploadUrl, fileUrl } = await getPresignedUploadParams(name)
  return { body: file, meta: { fileUrl }, url: uploadUrl, method: 'put' }
}

export const fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim

export const isDefined = (o) => !(o === undefined || o === null)

export const toCamel = s =>
  s.replace(/([-_][a-z])/ig, ($1) =>
    $1.toUpperCase()
      .replace('-', '')
      .replace('_', '')
  )

const isObject = o => {
  return o === Object(o) && !isArray(o) && typeof o !== 'function'
}

const isArray = function (a) {
  return Array.isArray(a)
}

export const keysToCamel = o => {
  if (isObject(o)) {
    const n = {}

    Object.keys(o)
      .forEach(k => {
        n[toCamel(k)] = keysToCamel(o[k])
      })

    return n
  } else if (isArray(o)) {
    return o.map(i => {
      return keysToCamel(i)
    })
  }

  return o
}

export const toProperCase = o => o && o.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())

export const underscoreToTitleCase = o => toProperCase(o && o.replace(/_/g, ' '))

export const isEmail = (email) => {
  const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regexp.test(String(email).toLowerCase())
}

export const fieldHasError = (formik, fieldName) => formik.touched[fieldName] && Boolean(formik.errors[fieldName])
export const fieldHelperText = (formik, fieldName) => formik.touched[fieldName] && formik.errors[fieldName]

export const pluralizeText = (str, num) => {
  if (!str) {
    return ''
  }

  if (str.slice(-1) === 's') {
    return str
  }

  return (num > 1 || num < 1) ? str + 's' : str
}

export const prettyPhoneNumber = input => {
  const cleaned = ('' + input).replace(/\D/g, '').replace(/^1*/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
  return null
}

// exclude: array of properties to ignore
export const diff = (obj1, obj2, exclude) => {
  obj1 = obj1 || {}
  obj2 = obj2 || {}
  exclude = exclude || []
  const r = {}

  for (const prop in obj1) {
    if (Object.hasOwnProperty.call(obj1, prop) && prop !== '__proto__' && exclude.indexOf(prop) === -1) {
      // check if obj2 has prop
      if (!Object.hasOwnProperty.call(obj2, prop)) r[prop] = obj1[prop]

      // check if prop is object and
      // NOT a JavaScript engine object (i.e. __proto__), if so, recursive diff
      else if (obj1[prop] === Object(obj1[prop])) {
        const difference = diff(obj1[prop], obj2[prop])
        if (Object.keys(difference).length > 0) r[prop] = difference
      }

      // check if obj1 and obj2 are equal
      else if (obj1[prop] !== obj2[prop]) {
        if (obj1[prop] === undefined) { r[prop] = 'undefined' }
        if (obj1[prop] === null) {
          r[prop] = null
        } else if (typeof obj1[prop] === 'function') {
          r[prop] = 'function'
        } else if (typeof obj1[prop] === 'object') {
          r[prop] = 'object'
        } else {
          r[prop] = obj1[prop]
        }
      }
    }
  }

  return r
}
