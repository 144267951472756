import React, { useState } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import { withStyles } from '@mui/styles'
import MuiInputLabel from '@mui/material/InputLabel'

import InputAdornment from '@mui/material/InputAdornment'
import LocationCity from '@mui/icons-material/LocationCity'

import STATES from 'states.json'

const stateMenuItems = Object.entries(STATES).map(state => <MenuItem key={state} id='state' value={state[0]}> {state[1]} </MenuItem>)

const styles = (theme) => ({
  root: {
    background: 'white',
    marginTop: '-2px',
    padding: '0 6px',
    marginLeft: '-4px'
  }
})

const CustomInputLabel = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiInputLabel className={classes.root} {...other}>
      {children}
    </MuiInputLabel>
  )
})

const StateSelect = ({ name, value, style, showInputAdornment, onChange, required, helperText, variant, error }) => {
  const [hasFocus, setFocus] = useState()

  return (
    <FormControl
      error={error}
      focused={hasFocus}
      variant={variant}
      id='state'
      style={style}
    >
      {showInputAdornment &&
        <InputAdornment>
          <LocationCity
            style={{ marginTop: '55px', marginLeft: '15px', marginRight: '-15px' }}
            color='action'
          />
        </InputAdornment>}
      <CustomInputLabel focused={hasFocus} shrink>State{required ? '*' : ''}</CustomInputLabel>
      <Select
        name={name}
        required={required}
        style={{ paddingLeft: showInputAdornment ? '32px' : '' }}
        label='State'
        onOpen={() => { setFocus(true) }}
        value={value}
        onChange={(e) => {
          onChange(e)
          setFocus()
        }}
        onClose={() => setFocus}
      >
        {stateMenuItems}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default StateSelect
