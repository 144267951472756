import React, { useState, useContext } from 'react'
import Typography from '@mui/material/Typography'
import { useQuery, gql } from '@apollo/client'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { basicDateTimeFormat, PriceInput, Dollars, getUploadParamsForDropzone } from 'tools'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Dropzone from 'react-dropzone-uploader'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { DateTime } from 'luxon'

const QUERY = gql`
  query bToBPayouts($technicianId: ID) {
    bToBPayouts(technicianId: $technicianId) {
      id
      createdAt
      amount
      photoUrls
      payoutId
      note
      deletedAt
      payout {
        id
        createdAt
      }
    }
  }
`

const modalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  backgroundColor: 'white',
  padding: '1em',
  boxShadow: 24,
  maxHeight: '500px',
  overflow: 'scroll',
  p: 4,
}

const BToBPayouts = ({ technician }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const [newAmount, setNewAmount] = useState(0)
  const [newNote, setNewNote] = useState('')
  const [bToBPayoutForPhotoUploadModal, setBToBPayoutForPhotoUploadModal] = useState(null)

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { technicianId: technician.id },
  })
  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const allowCreate = newAmount > 0 && newNote.length > 2

  const handleClickCreate = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_payout`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        technicianId: technician.id,
        amount: newAmount,
        note: newNote.trim(),
      }),
    })
      .then(response => {
        if (response.ok) {
          setNewAmount(0)
          setNewNote('')
          setTimeout(refetch, 200)
          return true
        } else {
          throw Error('error')
        }
      })
      .catch(err => {
        window.alert(err)
      })

  const handleClickDelete = id =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_payout`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        bToBPayoutId: id,
      }),
    }).then(res => res.ok || window.alert('error'))

  const PhotoUploader = ({ bToBPayout }) => {
    const postPhoto = photo =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_photo_url_to_b_to_b_payout`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          bToBPayoutId: bToBPayout.id,
          photoUrl: photo.meta.fileUrl,
        }),
      })
        .then(response => {
          if (response.ok) {
            photo.remove()
            return true
          } else {
            throw Error('error')
          }
        })
        .catch(err => {
          window.alert(err)
        })

    const handleSubmit = photos => {
      photos.forEach(photo => postPhoto(photo))
      setBToBPayoutForPhotoUploadModal(null)
    }

    return (
      <Dropzone
        addClassNames={{ dropzone: 'dzu-dropzone-custom' }}
        getUploadParams={getUploadParamsForDropzone}
        accept='image/*'
        onSubmit={handleSubmit}
        inputContent='Attach photos: '
      />
    )
  }

  return (
    <>
      <Typography variant='h6'>B To B Payouts</Typography>

      <TextField
        size='small'
        value={newAmount}
        onChange={event => setNewAmount(Number(event.target.value))}
        InputProps={{
          inputComponent: PriceInput,
          name: 'technician-b2b-payouts-amount',
        }}
      />
      <TextField
        style={{ minWidth: '250px' }}
        size='small'
        value={newNote}
        label='Note'
        variant='outlined'
        multiline
        onChange={e => setNewNote(e.target.value)}
        InputProps={{
          name: 'technician-b2b-payouts-note',
        }}
      />

      <Button
        cy-data='technician-b2b-payouts-create-button'
        size='small'
        disabled={!allowCreate}
        variant='outlined'
        onClick={handleClickCreate}
      >
        Create
      </Button>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='right'>amount</TableCell>
              <TableCell align='right'>note</TableCell>
              <TableCell align='right'>photos</TableCell>
              <TableCell align='right'>payout?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.bToBPayouts
              .filter(re => !re.deletedAt)
              .map(bToBPayout => (
                <TableRow key={bToBPayout.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align='right'>
                    <Dollars value={bToBPayout.amount} />
                  </TableCell>
                  <TableCell align='right'>{bToBPayout.note}</TableCell>
                  <TableCell align='right'>
                    {(bToBPayout.photoUrls || []).map(photoUrl => (
                      <div
                        key={photoUrl}
                        onClick={() => window.open(photoUrl)}
                        style={{
                          border: '2px solid rgb(239, 239, 239)',
                          display: 'inline-block',
                          cursor: 'pointer',
                          width: '80px',
                          height: '80x',
                        }}
                      >
                        <img
                          style={{
                            objectFit: 'cover',
                            height: '100%',
                            width: '100%',
                          }}
                          src={photoUrl}
                        />
                      </div>
                    ))}
                    <button
                      style={{ display: 'inline-block' }}
                      onClick={() => setBToBPayoutForPhotoUploadModal(bToBPayout)}
                    >
                      {' '}
                      +
                    </button>
                  </TableCell>
                  <TableCell align='right'>
                    {bToBPayout.payout ? (
                      <>{DateTime.fromISO(bToBPayout.payout.createdAt).toFormat(basicDateTimeFormat)}</>
                    ) : (
                      <>
                        not linked&nbsp;
                        <Button
                          cy-data='technician-b2b-payouts-delete-button'
                          size='small'
                          onClick={() => handleClickDelete(bToBPayout.id)}
                        >
                          delete
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {bToBPayoutForPhotoUploadModal && (
        <Modal open={bToBPayoutForPhotoUploadModal} onClose={() => setBToBPayoutForPhotoUploadModal(null)}>
          <Box style={modalBoxStyle}>
            <Typography sx={{ mt: 2 }}>
              <Dollars value={bToBPayoutForPhotoUploadModal.amount} />
              <pre>{bToBPayoutForPhotoUploadModal.note}</pre>
            </Typography>
            <PhotoUploader bToBPayout={bToBPayoutForPhotoUploadModal} />
          </Box>
        </Modal>
      )}
    </>
  )
}

export default BToBPayouts
