import { useState, useEffect, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material'
import { CsvConfigurableListOptions } from 'components/CsvConfigurableListOptions'
import { UserContext } from 'UserStore'

const QUERY = gql`
  query primaryLaborDamagesConnection($order: JSON, $filter: JSON, $first: Int, $after: String) {
    primaryLaborDamagesConnection(order: $order, filter: $filter, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          panel
          type
          sides
          locations
          totalHours
          prepHours
          paintHours
          bodyWorkHours
          minorPartHours
          majorPartHours
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const Index = () => {
  const [search, setSearch] = useState(JSON.parse(localStorage.getItem('primaryLaborDamagesSearch')) || {})
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem('primaryLaborDamagesOrder')) || {})
  const [filter, setFilter] = useState(JSON.parse(localStorage.getItem('primaryLaborDamagesFilter')) || {})
  const [limit, setLimit] = useState(JSON.parse(localStorage.getItem('primaryLaborDamagesLimit')) || 50)

  useEffect(() => localStorage.setItem('primaryLaborDamagesSearch', JSON.stringify(search)), [search])
  useEffect(() => localStorage.setItem('primaryLaborDamagesOrder', JSON.stringify(order)), [order])
  useEffect(() => localStorage.setItem('primaryLaborDamagesFilter', JSON.stringify(filter)), [filter])
  useEffect(() => localStorage.setItem('primaryLaborDamagesLimit', JSON.stringify(limit)), [limit])

  const [user] = useContext(UserContext)

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  const addFilter = newFilter => {
    setFilter({ ...filter, ...newFilter })
  }

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: limit,
        after: '',
      },
    })
  }, [search, order, filter, limit])

  const { loading, error, data, fetchMore } = useQuery(QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      search,
      order,
      filter,
      first: Number(limit),
      after: cursorCeiling,
    },
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const primaryLaborDamages = data.primaryLaborDamagesConnection.edges.map(edge => edge.node)
  const totalCount = data.primaryLaborDamagesConnection.totalCount
  const endCursor = data.primaryLaborDamagesConnection.pageInfo.endCursor

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: Number(limit),
          after: endCursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          fetchMoreResult.primaryLaborDamagesConnection.edges = [
            ...prevResult.primaryLaborDamagesConnection.edges,
            ...fetchMoreResult.primaryLaborDamagesConnection.edges,
          ]

          return fetchMoreResult
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <TableContainer>
      {user?.roles?.includes('admin') && (
        <CsvConfigurableListOptions parentType='PrimaryLaborDamage' parentTitle='Primary Labor Damages' />
      )}
      <TablePagination
        // rowsPerPageOptions={[10, 25, 50, 100]}
        rowsPerPageOptions={[50]}
        component='div'
        count={totalCount}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>panel</TableCell>
            <TableCell>sides</TableCell>
            <TableCell>locations</TableCell>
            <TableCell>type</TableCell>
            <TableCell>prep_hours</TableCell>
            <TableCell>paint_hours</TableCell>
            <TableCell>body_work_hours</TableCell>
            <TableCell>minor_part_hours</TableCell>
            <TableCell>major_part_hours</TableCell>
            <TableCell>total_hours</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {primaryLaborDamages.slice(page * limit, page * limit + limit).map((damageItem, index) => (
            <TableRow sx={{ background: index % 2 === 0 ? '#fff' : '#f9f9f9' }} key={damageItem.id}>
              <TableCell>{damageItem.panel}</TableCell>
              <TableCell>{damageItem.sides}</TableCell>
              <TableCell>{damageItem.locations}</TableCell>
              <TableCell>{damageItem.type}</TableCell>
              <TableCell>{damageItem.prepHours}</TableCell>
              <TableCell>{damageItem.paintHours}</TableCell>
              <TableCell>{damageItem.bodyWorkHours}</TableCell>
              <TableCell>{damageItem.minorPartHours}</TableCell>
              <TableCell>{damageItem.majorPartHours}</TableCell>
              <TableCell>{damageItem.totalHours}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Index
