import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { Dollars } from 'tools'

export const TimeCards = ({ timeCards }: any) => {
  const totalTipAmount = timeCards.reduce((acc: any, timeCard: any) => acc + timeCard.payoutData.totalTipsForDay, 0)
  const totalHourlyPay = timeCards.reduce((acc: any, timeCard: any) => acc + timeCard.payoutData.totalHourlyPay, 0)
  const totalJobCommission = timeCards.reduce(
    (acc: any, timeCard: any) => acc + timeCard.payoutData.totalCommissionAmount,
    0
  )
  const totalPaidHours = timeCards.reduce((acc: any, timeCard: any) => acc + timeCard.payoutData.totalPaidHours, 0)
  const totalHours = timeCards.reduce((acc: any, timeCard: any) => acc + timeCard.payoutData.totalHours, 0)
  const totalworkHours = timeCards.reduce((acc: any, timeCard: any) => acc + timeCard.payoutData.totalWorkHours, 0)
  const totalPaidTravelHours = timeCards.reduce(
    (acc: any, timeCard: any) => acc + timeCard.payoutData.paidTravelTotalHours,
    0
  )
  const totalUnpaidTravelHours = timeCards.reduce(
    (acc: any, timeCard: any) => acc + timeCard.payoutData.unpaidTravelTotalHours,
    0
  )
  const jobsTotal = timeCards.reduce((acc: any, timeCard: any) => acc + timeCard.payoutData.numJobs, 0)
  const totalPayoutAmount = timeCards.reduce((acc: any, timeCard: any) => acc + timeCard.payoutData.totalPayout, 0)
  const totalMiles = timeCards.reduce((acc: any, timeCard: any) => acc + timeCard.payoutData.travelTotalDistance, 0)

  return (
    <Table size='small' component={Paper}>
      <TableHead sx={{ fontSize: '12px !important' }}>
        <TableRow>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Date
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            # Jobs
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Distance
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Unpaid Travel Hours
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Paid Travel Hours
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Work Hours
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Total Hours
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Total paid hours
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Tips total
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Hourly pay
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Job Commission
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Total payout
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {timeCards.map((timeCard: any, index: number) => (
          <TableRow sx={{ background: index % 2 === 0 ? '#fff' : '#f9f9f9' }}>
            <TableCell
              align='right'
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <Link
                key={timeCard.id}
                onClick={e => e.preventDefault()}
                target='_blank'
                to={`/timecards/${timeCard.id}`}
              >
                {DateTime.fromISO(timeCard.timeCardDate).toISODate()}
              </Link>
            </TableCell>

            <TableCell align='right'>{timeCard.payoutData.numJobs} </TableCell>
            <TableCell align='right'>{timeCard.payoutData?.travelTotalDistance} mi</TableCell>
            <TableCell align='right'>{timeCard.payoutData?.unpaidTravelTotalHours.toFixed(2)}</TableCell>
            <TableCell align='right'>{timeCard.payoutData?.paidTravelTotalHours.toFixed(2)}</TableCell>
            <TableCell align='right'>{timeCard.payoutData?.totalWorkHours?.toFixed(2)}</TableCell>
            <TableCell align='right'>{timeCard.payoutData?.totalHours.toFixed(2)}</TableCell>

            <TableCell align='right'>{timeCard.payoutData?.totalPaidHours.toFixed(2)}</TableCell>

            <TableCell align='right' sx={{ fontWeight: 'bold' }}>
              <Dollars value={timeCard.payoutData?.totalTipsForDay || 0} />
            </TableCell>

            <TableCell align='right' sx={{ fontWeight: 'bold' }}>
              <Dollars value={timeCard.payoutData?.totalHourlyPay || 0} />
            </TableCell>

            <TableCell
              align='right'
              sx={{
                fontWeight: 'bold',
              }}
            >
              <Dollars value={timeCard.payoutData?.totalCommissionAmount || 0} />
            </TableCell>

            <TableCell
              align='right'
              sx={{
                fontWeight: 'bold',
              }}
            >
              <Dollars value={timeCard.payoutData?.totalPayout || 0} />
            </TableCell>
          </TableRow>
        ))}
        <TableRow sx={{ background: 'aliceblue', borderTop: '2px solid #a1cff7' }}>
          <TableCell />
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            {jobsTotal}
          </TableCell>
          <TableCell align='right'>{totalMiles.toFixed(2)} mi</TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            {totalUnpaidTravelHours.toFixed(2)}
          </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            {totalPaidTravelHours.toFixed(2)}
          </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            {totalworkHours.toFixed(2)}
          </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            {totalHours.toFixed(2)}
          </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            {totalPaidHours.toFixed(2)}
          </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalTipAmount} />
          </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalHourlyPay} />
          </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalJobCommission} />
          </TableCell>

          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalPayoutAmount} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
