import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { ExpandMoreOutlined } from '@mui/icons-material'
import { useFormik } from 'formik'
import { api } from 'utils/axios'
import { useMemo } from 'react'
import * as yup from 'yup'

import {
  Box,
  Button,
  Dialog,
  Select,
  MenuItem,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { useJobListing } from 'hooks/recruiting/jobListings/useJobListing'
import { DateTime } from 'luxon'

const validationSchema = yup.object({
  job_title: yup.string().required('Job title is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  description: yup.string().required('Description is required'),
  salary_start: yup.number().required('Salary start is required'),
  salary_end: yup.number().required('Salary end is required'),
  salary_period: yup.string().required('Salary period is required'),
  education: yup.string().required('Education is required'),
  job_type: yup.string().required('Job type is required'),
  experience: yup.string().required('Experience is required'),
  company: yup.string().required('Company is required'),
  country: yup.string().required('Country is required'),
  zip_code: yup.string().required('Zip code is required'),
  street_address: yup.string().required('Street address is required'),
  email: yup.string().email('Enter a valid recruiting email').required('Recruiting email is required'),
  open_positions: yup.number().required('Open positions number is require'),
})

const defaultInitialFormData = {
  job_title: '',
  company: 'Car Body Lab',
  city: '',
  state: '',
  country: 'US',
  zip_code: '1234',
  street_address: 'Fake Street',
  email: 'recruiting@carbodylab.com',
  description: '',
  salary_start: undefined,
  salary_end: undefined,
  salary_period: 'year',
  education: '',
  job_type: 'fulltime',
  experience: '',
  open_positions: 1,
}

interface CreateUpdateJobListingPopupProps {
  jobListingId?: string
  onClose: () => void
  refresh: () => void
  open: boolean
}

const CreateUpdateJobListingPopup = ({ jobListingId, open, refresh, onClose }: CreateUpdateJobListingPopupProps) => {
  const { jobListing } = useJobListing(jobListingId)

  const initialFormData = useMemo(() => {
    if (!jobListingId || !jobListing) return defaultInitialFormData

    return {
      job_title: jobListing?.jobTitle,
      company: jobListing?.company,
      city: jobListing?.city,
      state: jobListing?.state,
      country: jobListing?.country,
      zip_code: jobListing?.zipCode,
      street_address: jobListing?.streetAddress,
      email: jobListing?.email,
      description: jobListing?.description,
      salary_start: jobListing?.salaryStart,
      salary_end: jobListing?.salaryEnd,
      salary_period: jobListing?.salaryPeriod,
      education: jobListing?.education,
      job_type: jobListing?.jobType,
      experience: jobListing?.experience,
      open_positions: jobListing?.openPositions,
    }
  }, [jobListing, jobListingId])

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema,
    onSubmit: data => {
      if (jobListing) {
        return api
          .post('/update_job_listing', {
            job_listing_id: jobListingId,
            ...data,
          })
          .then(res => {
            if (res.status === 200) alert('Successfully updated job listing!')
            else alert('Something went wrong')

            refresh && refresh()
            onClose && onClose()
          })
      }

      api.post('/create_job_listing', data).then(res => {
        if (res.status === 200) alert('Successfully created job listing!')
        else alert('Something went wrong')

        refresh && refresh()
        onClose && onClose()
      })
    },
  })

  return (
    <Dialog PaperProps={{ sx: { minWidth: '850px', minHeight: '560px' } }} onClose={onClose} open={open}>
      <Box sx={{ padding: '1em' }}>
        <form onSubmit={formik.handleSubmit}>
          {/* Human Filled Fields */}
          <Grid sx={{ paddingBottom: '1em' }} container spacing={2}>
            <Grid xs={3}>
              <Typography gutterBottom>Job Title</Typography>
            </Grid>

            <Grid xs={9}>
              <TextField
                name='job_title'
                value={formik.values.job_title}
                onChange={formik.handleChange}
                error={formik.touched.job_title && Boolean(formik.errors.job_title)}
                helperText={formik.touched.job_title && formik.errors.job_title}
                label='Job Title'
                fullWidth
              />
            </Grid>

            <Grid xs={3}>
              <Typography gutterBottom>City</Typography>
            </Grid>

            <Grid xs={9}>
              <TextField
                name='city'
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                label='City'
                fullWidth
              />
            </Grid>

            <Grid xs={3}>
              <Typography gutterBottom>State</Typography>
            </Grid>

            <Grid xs={9}>
              <TextField
                name='state'
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
                label='State'
                fullWidth
              />
            </Grid>

            <Grid xs={3}>
              <Typography gutterBottom>Description</Typography>
            </Grid>

            <Grid xs={9}>
              <TextField
                name='description'
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                label='Description'
                multiline
                fullWidth
              />
            </Grid>

            <Grid xs={3}>
              <Typography gutterBottom>Salary</Typography>
            </Grid>

            <Grid xs={3}>
              <TextField
                name='salary_start'
                value={formik.values.salary_start}
                onChange={formik.handleChange}
                error={formik.touched.salary_start && Boolean(formik.errors.salary_start)}
                helperText={formik.touched.salary_start && formik.errors.salary_start}
                label='Salary Start'
                type='number'
                fullWidth
              />
            </Grid>

            <Grid xs={3}>
              <TextField
                name='salary_end'
                value={formik.values.salary_end}
                onChange={formik.handleChange}
                error={formik.touched.salary_end && Boolean(formik.errors.salary_end)}
                helperText={formik.touched.salary_end && formik.errors.salary_end}
                label='Salary End'
                type='number'
                fullWidth
              />
            </Grid>

            <Grid xs={3}>
              <Select
                sx={{ width: '100%' }}
                name='salary_period'
                value={formik.values.salary_period}
                onChange={formik.handleChange}
                error={formik.touched.salary_period && Boolean(formik.errors.salary_period)}
              >
                <MenuItem value='year'>Year</MenuItem>
                <MenuItem value='month'>Month</MenuItem>
                <MenuItem value='week'>Week</MenuItem>
                <MenuItem value='hour'>Hour</MenuItem>
              </Select>
            </Grid>

            <Grid xs={3}>
              <Typography gutterBottom>Education</Typography>
            </Grid>

            <Grid xs={9}>
              <TextField
                name='education'
                value={formik.values.education}
                onChange={formik.handleChange}
                error={formik.touched.education && Boolean(formik.errors.education)}
                helperText={formik.touched.education && formik.errors.education}
                label='Education'
                fullWidth
              />
            </Grid>

            <Grid xs={3}>
              <Typography gutterBottom>Job Type</Typography>
            </Grid>

            <Grid xs={9}>
              <Select
                sx={{ width: '100%' }}
                name='job_type'
                value={formik.values.job_type}
                onChange={formik.handleChange}
                error={formik.touched.job_type && Boolean(formik.errors.job_type)}
              >
                <MenuItem value='fulltime'>Full-time</MenuItem>
                <MenuItem value='parttime'>Part-time</MenuItem>
              </Select>
            </Grid>

            <Grid xs={3}>
              <Typography gutterBottom>Experience</Typography>
            </Grid>

            <Grid xs={9}>
              <TextField
                name='experience'
                value={formik.values.experience}
                onChange={formik.handleChange}
                error={formik.touched.experience && Boolean(formik.errors.experience)}
                helperText={formik.touched.experience && formik.errors.experience}
                label='Experience'
                fullWidth
              />
            </Grid>

            <Grid xs={3}>
              <Typography gutterBottom>Open Positions</Typography>
            </Grid>

            <Grid xs={9}>
              <TextField
                name='open_positions'
                value={formik.values.open_positions}
                onChange={formik.handleChange}
                error={formik.touched.open_positions && Boolean(formik.errors.open_positions)}
                helperText={formik.touched.open_positions && formik.errors.open_positions}
                label='Open Positions'
                type='number'
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Automatically Filled Fields */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls='panel1a-content' id='panel1a-header'>
              Advanced Fields
            </AccordionSummary>

            <AccordionDetails>
              <Grid sx={{ paddingBottom: '1em' }} container spacing={2}>
                <Grid xs={3}>
                  <Typography gutterBottom>Company</Typography>
                </Grid>

                <Grid xs={9}>
                  <TextField
                    name='company'
                    value={formik.values.company}
                    onChange={formik.handleChange}
                    error={formik.touched.company && Boolean(formik.errors.company)}
                    helperText={formik.touched.company && formik.errors.company}
                    label='Company'
                    fullWidth
                  />
                </Grid>

                <Grid xs={3}>
                  <Typography gutterBottom>Country</Typography>
                </Grid>

                <Grid xs={9}>
                  <TextField
                    name='country'
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.touched.country && formik.errors.country}
                    label='Country'
                    fullWidth
                  />
                </Grid>

                <Grid xs={3}>
                  <Typography gutterBottom>Postal Code</Typography>
                </Grid>

                <Grid xs={9}>
                  <TextField
                    name='zip_code'
                    value={formik.values.zip_code}
                    onChange={formik.handleChange}
                    error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
                    helperText={formik.touched.zip_code && formik.errors.zip_code}
                    label='Postal Code'
                    fullWidth
                  />
                </Grid>

                <Grid xs={3}>
                  <Typography gutterBottom>Street Address</Typography>
                </Grid>

                <Grid xs={9}>
                  <TextField
                    name='street_address'
                    value={formik.values.street_address}
                    onChange={formik.handleChange}
                    error={formik.touched.street_address && Boolean(formik.errors.street_address)}
                    helperText={formik.touched.street_address && formik.errors.street_address}
                    label='Street Address'
                    fullWidth
                  />
                </Grid>

                <Grid xs={3}>
                  <Typography gutterBottom>Email</Typography>
                </Grid>

                <Grid xs={9}>
                  <TextField
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    label='Email'
                    fullWidth
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Button sx={{ marginTop: '1em' }} variant='contained' type='submit'>
            Submit Job Listing
          </Button>
        </form>
      </Box>
    </Dialog>
  )
}

export default CreateUpdateJobListingPopup
