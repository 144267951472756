export default [
  'collected',
  'cancelled',
  'rescheduled',
  'pending',
  'unfinished',
  'half_collected',
  'paid_in_cash',
  'follow_up_completed',
  'complaint',
  'tech_cancelled',
  'compensate',
]
