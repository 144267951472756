import { api } from 'utils/axios'
import { AvailabilityHoursType } from './types'

export const localToUTCHours = (hours: number) => {
  const localDate = new Date()
  localDate.setHours(hours)
  return localDate.getUTCHours()
}

export const UTCtoLocalHours = (hours: number) => {
  const localDate = new Date()
  localDate.setUTCHours(hours)
  return localDate.getHours()
}

export const submitCallback = () => {
  alert('Successfully Updated')
}

export const updateAvailability = (
  availability: AvailabilityHoursType,
  toId: string,
  fromId: string,
  callback: () => void
) => {
  api
    .post('/api/update_user_attributes', {
      user_id: toId,
      updated_attributes: {
        availability_hours: availability,
      },
      performed_by_id: fromId,
    })
    .then(callback)
}

export const updateDaysOff = (daysOff: string[], toId: string, fromId: string, callback: () => void) => {
  api
    .post('/api/update_user_attributes', {
      user_id: toId,
      updated_attributes: {
        days_off: daysOff,
      },
      performed_by_id: fromId,
    })
    .then(callback)
}
