import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import Show from './Show'
import { useQuery, gql } from '@apollo/client'

const QUERY = gql`
  query botSession($id: ID!) {
    botSession(id: $id) {
      id
      name
      serviceType
      email
      phone
      followedUpAt
      startedAt
      completedAt
      zip
      followedUpBy {
        id
        firstName
        lastName
      }
      customer {
        id
        firstName
        lastName
        lastMessageSentAt
        lastMessageReceivedAt
        leads {
          id
          createdAt
        }
        callbackRequests {
          id
          createdAt
        }
      }
    }
  }
`

const Router = () => {
  const { path } = useRouteMatch()
  const { preLeadId } = useParams()
  const { loading, error, data, refetch } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: preLeadId,
    },
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const preLead = data?.botSession
  console.log(preLead)

  return (
    <Switch>
      <Route path={path}>
        <Show preLead={preLead} refetch={refetch} />
      </Route>
    </Switch>
  )
}

export default Router
