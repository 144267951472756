import { useState } from 'react'
import { Box, Button } from '@mui/material'

import CreateUpdateJobListingPopup from '../CreateUpdateJobListingPopup'
import { useJobListings } from 'hooks/recruiting/jobListings/useJobListings'

const CreateJobListing = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const { refetch } = useJobListings()

  const toggleDialog = () => setOpenDialog(!openDialog)

  return (
    <Box>
      <Button variant='contained' onClick={toggleDialog}>
        Create Job Listing
      </Button>

      <CreateUpdateJobListingPopup open={openDialog} onClose={toggleDialog} refresh={refetch} />
    </Box>
  )
}

export default CreateJobListing
