import { useEffect, useMemo, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import {
  Accordion, AccordionSummary, AccordionDetails, Autocomplete, Box, Button,
  Chip, Grid, TableContainer, Table, TableBody, TableHead, TableRow, TableCell,
  TextField, Paper, Snackbar, CircularProgress, Modal, SvgIcon
} from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { API_COMMAND_HEADERS, Dollars, downloadStringAsFile, keysToCamel } from 'tools'
import { ArrowDropDownRounded, ArrowRightRounded, CheckCircleOutlineRounded, ExpandMore } from '@mui/icons-material'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useToggle } from 'hooks/useToggle'
import { PayoutDataPopover } from './components/PayoutDataPopover'
import { PanelBasedInvoices, RevenueBasedInvoices } from './components/ReadOnlyTables/BToBInvoices'
import { HourlyQuotes, RevenueQuotes } from './components/ReadOnlyTables/Quotes'
import { TimeCards } from './components/ReadOnlyTables/TimeCards'

const PAYROLLS_QUERY = gql`
  query payrolls {
    payrolls {
      id
      createdAt
      gustoCsv
      payouts {
        id
        firstName
        lastName
      }
    }
  }
`

const PAYROLL_QUERY = gql`
  query getPayroll($id: ID!) {
    payroll(id: $id) {
      payouts {
        id
        firstName
        lastName
        businessName
        contractorIsBusiness
        technicianId
        quotePayouts
        quotePayoutsSum
        loanDeductionAmount
        otherLoanDeductionAmount
        createdAt
        einLastFour
        ssnLastFour
        gustoWageAmount
        finalCheckAmount
        note
        payrollId
        deleted
        totalPaidTimeOffHours
        totalSickTimeOffHours
        totalUnpaidTimeOffHours
        loanBalanceBeforeThisPayout
        loanBalanceAfterThisPayout
        otherLoanBalanceBeforeThisPayout
        otherLoanBalanceAfterThisPayout
        timeCardsSum
        totalPaidTimeOffHours
        minimumHourlyPayAdjustment
        overtimePayAdjustment
        overtimePay
        commissionChargeBacksSum
        bToBInvoicePayouts
        timeCardPayouts
        sickTimeOff {
          id
          deletedAt
          totalHours
          note
          userId
          timeOffDate
        }
        commissionChargeBacks {
          id
          createdAt
          quoteId
          amount
          note
          worklog {
            id
            startedAt
            completedAt
            job {
              id
              startDatetime
              endDatetime
            }
          }
        }
        timeCards {
          id
          timeCardDate
          createdAt
          workHours
          paidTravelHours
          unpaidTravelHours
          jobCount
          payoutData
          travelLogs {
            id
            createdAt
            startedAt
            completedAt
            type
            isPaid
            jobId
            bToBJobId
            travelDistance
            totalHours
            technician {
              hourlyRate
              commissionAmount
            }
          }
          bToBJobsLogs {
            id
            createdAt
            startedAt
            completedAt
            type
            isPaid
            jobId
            bToBJobId
            travelDistance
            totalHours
            commissionType
            selectedForCommissionPayment
            hourlyType
            bToBJob {
              id
              scheduledLength
            }
          }
          workLogs {
            id
            createdAt
            startedAt
            commissionType
            amount
            completedAt
            type
            isPaid
            jobId
            travelDistance
            totalHours
            payoutData
            selectedForCommissionPayment
            hourlyType
            quoteId
            technician {
              hourlyRate
              state
              commissionAmount
              isEmployee
            }
            job {
              id
              scheduledLength
            }
            quote {
              id
              quoteGrandTotal
              tipSum
              ccFeeSum
              totalTransactionAmount
              laborSumAfterAllDiscounts
              technicianChargesSum
              paymentStatus
              fullyPaidAt
              payoutIds
              payrollStatuses
              payrollNotesCount
              payoutData
              technician {
                id
                firstName
                lastName
                hourlyRate
                commissionAmount
                isEmployee
              }
              workLogs {
                id
                hourlyType
                createdAt
                startedAt
                amount
                completedAt
                type
                isPaid
                jobId
                travelDistance
                totalHours
                quoteId
                payoutData
                commissionType
                selectedForCommissionPayment
                technician {
                  hourlyRate
                  commissionAmount
                  state
                  isEmployee
                }
                job {
                  id
                  scheduledLength
                }
              }
            }
          }
        }
        technician {
          contractorIsBusiness
          ssn
          ein
          businessName
          firstName
          lastName
          loanBalance
          otherLoanBalance
          hourlyRate
          isEmployee
          state
          commissionAmount
          bToBInvoiceCommissionStructure
        }
        reimbursements {
          id
          createdByType
          createdById
          createdAt
          amount
          photoUrls
          payoutId
          note
        }
        reimbursementsSum
        manualCompensations {
          id
          deletedAt
          amount
          note
          userId
        }
        quotes {
          id
          markedInsuranceAt
          payoutData
        }
        manualCompensationsSum
        bToBPayouts {
          id
          deletedAt
          amount
          note
          userId
          photoUrls
        }
        paidTimeOff {
          id
          deletedAt
          totalHours
          note
          userId
          timeOffDate
        }
        unpaidTimeOff {
          id
          deletedAt
          totalHours
          note
          userId
          timeOffDate
        }
        bToBPayoutsSum
        bToBInvoices {
          id
          createdAt
          completedAt
          make
          model
          year
          lineItemsCommissionSum
          lineItemsPointsSum
          grandTotal
          payoutData
          lineItems
          commissionPercent
          invoiceNumber
          dealer {
            id
            businessName
          }
        }
        bToBInvoicesSum
      }
    }
  }
`

const QUOTE_QUERY = gql`
  query quote($id: ID!, $cached: Boolean!) {
    quote(id: $id, cached: $cached) {
      id
      postJobGrandTotal
      leadId
      balanceAmountDue
      preJobAmountDue
      payments
      draftedInitialCloseReplies
      paymentStatus
      csrRequestedDraftInitialCloseReplies
      updatedAt
      commissionPercentOverride
      payoutBonus
      payoutData
      assignedTechnicianId
      payoutId
    }
  }
`

function indexFunc (arr, testFunc) {
  for (let i = 0; i < arr.length; i++) {
    if (testFunc(arr[i])) return i
  }
  return -1
}

function downloadResponse (data) {
  downloadStringAsFile({
    string: data.csvString,
    fileName: data.csvFilename
  })
}

const Payrolls = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const { loading, error, data, refetch } = useQuery(PAYROLLS_QUERY)
  const [payrollOpen, setPayrollOpen] = useLocalStorage('payrollSessionOpen', 'false', false)
  const [modalIsOpen, toggleModal, closeModal, openModal] = useToggle()
  const [exportRange, setExportRange] = useState(['', ''])

  const { payrolls, payrollsLabeled } = useMemo(() => {
    const payrolls = (data?.payrolls.slice() ?? []).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    const payrollsLabeled = payrolls.map(payroll => ({
      ...payroll,
      label: `${DateTime.fromISO(payroll.createdAt).toFormat('y-LL-dd')} (${payroll.id.substring(30)})`
    }))

    return {
      payrolls,
      payrollsLabeled
    }
  }, [data?.payrolls])

  useEffect(() => {
    data && refetch && refetch()
    setPayrollOpen(false)
  }, [])

  const exportingCombinedPayrollIds = useMemo(() => {
    const iStart = indexFunc(payrolls, payroll => payroll.id == exportRange[0])
    const iEnd = indexFunc(payrolls, payroll => payroll.id == exportRange[1])
    return payrolls.slice(iEnd, iStart + 1).map(payroll => payroll.id)
  }, [exportRange])

  function handleDownloadCombinedGustoCSV () {
    let fileData = ''

    payrolls
      .filter(payroll => exportingCombinedPayrollIds.includes(payroll.id))
      .forEach(payroll => {
        if (fileData == '') {
          fileData = payroll.gustoCsv
        } else {
          const withoutHeader = payroll.gustoCsv.substring(payroll.gustoCsv.indexOf('\n') + 1)
          fileData = fileData + withoutHeader
        }
      })

    downloadStringAsFile({
      string: fileData,
      fileName: `payroll_gusto_combined_${DateTime.now().toFormat('y-LL-dd')}.csv`
    })
  }
  function handleDownloadCombinedCAContractors () {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_csv_of_ca_contractors_for_payroll`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ payroll_ids: exportingCombinedPayrollIds })
    })
      .then(res => res.json())
      .then(downloadResponse)
      .catch(err => window.alert(err))
  }
  function handleDownloadCombinedNonCAContractors () {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_csv_of_non_ca_contractors_for_payroll`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ payroll_ids: exportingCombinedPayrollIds })
    })
      .then(res => res.json())
      .then(downloadResponse)
      .catch(err => window.alert(err))
  }
  function handleDownloadCombinedCAEmployees () {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_csv_of_ca_employees_for_payroll`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ payroll_ids: exportingCombinedPayrollIds })
    })
      .then(res => res.json())
      .then(downloadResponse)
      .catch(err => window.alert(err))
  }
  function handleDownloadCombinedNonCAEmployees () {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_csv_of_non_ca_employees_for_payroll`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ payroll_ids: exportingCombinedPayrollIds })
    })
      .then(res => res.json())
      .then(downloadResponse)
      .catch(err => window.alert(err))
  }
  function handleDownloadCombinedQuotesOnPayroll () {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_csv_of_quotes_on_payroll`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ payroll_ids: exportingCombinedPayrollIds })
    })
      .then(res => res.json())
      .then(downloadResponse)
      .catch(err => window.alert(err))
  }
  function handleDownloadCombinedBToBInvoicesOnPayroll () {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_csv_of_b_to_b_invoices_on_payroll`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ payroll_ids: exportingCombinedPayrollIds })
    })
      .then(res => res.json())
      .then(downloadResponse)
      .catch(err => window.alert(err))
  }
  function handleDownloadCombinedEverything () {
    handleDownloadCombinedGustoCSV()
    handleDownloadCombinedCAContractors()
    handleDownloadCombinedNonCAContractors()
    handleDownloadCombinedCAEmployees()
    handleDownloadCombinedNonCAEmployees()
    handleDownloadCombinedQuotesOnPayroll()
    handleDownloadCombinedBToBInvoicesOnPayroll()
  }

  if (loading) return 'loading'
  if (error) return 'error'

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <div style={{ display: 'flex' }}>
                  <span>Created At</span>
                  <span style={{ flex: 1 }} />
                  <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => openModal()}>
                    Export combiner
                  </a>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payrolls.map(payroll => (
              <PayrollRows
                refetch={refetch}
                key={payroll.id}
                payroll={payroll}
                isCombinedExport={exportingCombinedPayrollIds.includes(payroll.id)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={!!modalIsOpen} onClose={() => closeModal()}>
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 24,
            padding: 2,
            maxWidth: '640px'
          }}
        >
          <div style={{ textAlign: 'center' }}>Export Combiner</div>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                clearOnBlur
                options={payrollsLabeled}
                getOptionLabel={option => option.label}
                renderInput={params => <TextField {...params} label='Starting Payroll' />}
                onChange={(e, v) => setExportRange([v?.id, exportRange[1]])}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                clearOnBlur
                options={payrollsLabeled}
                getOptionLabel={option => option.label}
                renderInput={params => <TextField {...params} label='Ending Payroll' />}
                onChange={(e, v) => setExportRange([exportRange[0], v?.id])}
              />
            </Grid>
            <Grid item xs={12} sx={{ button: { m: 1 }, display: 'flex', flexWrap: 'wrap' }}>
              <Button
                variant='outlined'
                size='small'
                disabled={exportingCombinedPayrollIds.length < 1}
                onClick={handleDownloadCombinedGustoCSV}
              >
                Gusto
              </Button>

              <Button
                variant='outlined'
                size='small'
                disabled={exportingCombinedPayrollIds.length < 1}
                onClick={handleDownloadCombinedQuotesOnPayroll}
              >
                Quotes
              </Button>

              <Button
                variant='outlined'
                size='small'
                disabled={exportingCombinedPayrollIds.length < 1}
                onClick={handleDownloadCombinedBToBInvoicesOnPayroll}
              >
                B2B Invoices
              </Button>
              <Button
                variant='outlined'
                size='small'
                disabled={exportingCombinedPayrollIds.length < 1}
                onClick={handleDownloadCombinedCAEmployees}
              >
                CA Employees
              </Button>
              <Button
                variant='outlined'
                size='small'
                disabled={exportingCombinedPayrollIds.length < 1}
                onClick={handleDownloadCombinedNonCAEmployees}
              >
                NON-CA Employees
              </Button>
              <Button
                variant='outlined'
                size='small'
                disabled={exportingCombinedPayrollIds.length < 1}
                onClick={handleDownloadCombinedCAContractors}
              >
                CA Contractors
              </Button>
              <Button
                variant='outlined'
                size='small'
                disabled={exportingCombinedPayrollIds.length < 1}
                onClick={handleDownloadCombinedNonCAContractors}
              >
                NON-CA Contractors
              </Button>
              <span style={{ flex: 1 }} />
              <Button
                variant='outlined'
                size='small'
                color='secondary'
                disabled={exportingCombinedPayrollIds.length < 1}
                onClick={handleDownloadCombinedEverything}
              >
                Everything
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  )
}

const PayrollRows = ({ payroll, refetch, isCombinedExport }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [expanded, setExpanded] = useState(false)
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [requestLoading, setRequestLoading] = useState(false)

  const handleClickDownloadCAContractors = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_csv_of_ca_contractors_for_payroll`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ payroll_id: payroll.id })
    })
      .then(res => res.json())
      .then(data => {
        downloadStringAsFile({ string: data.csvString, fileName: data.csvFilename })
      })
      .catch(err => window.alert(err))

  const handleClickDownloadNonCAContractors = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_csv_of_non_ca_contractors_for_payroll`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ payroll_id: payroll.id })
    })
      .then(res => res.json())
      .then(data => {
        downloadStringAsFile({ string: data.csvString, fileName: data.csvFilename })
      })
      .catch(err => window.alert(err))

  const handleClickDownloadCAEmployees = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_csv_of_ca_employees_for_payroll`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ payroll_id: payroll.id })
    })
      .then(res => res.json())
      .then(data => {
        downloadStringAsFile({ string: data.csvString, fileName: data.csvFilename })
      })
      .catch(err => window.alert(err))

  const handleClickDownloadNonCAEmployees = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_csv_of_non_ca_employees_for_payroll`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ payroll_id: payroll.id })
    })
      .then(res => res.json())
      .then(data => {
        downloadStringAsFile({ string: data.csvString, fileName: data.csvFilename })
      })
      .catch(err => window.alert(err))

  const handleClickDownloadQuotesOnPayroll = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_csv_of_quotes_on_payroll`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ payroll_id: payroll.id })
    })
      .then(res => res.json())
      .then(data => {
        downloadStringAsFile({ string: data.csvString, fileName: data.csvFilename })
      })
      .catch(err => window.alert(err))

  const handleClickDownloadBToBInvoicesOnPayroll = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_csv_of_b_to_b_invoices_on_payroll`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ payroll_id: payroll.id })
    })
      .then(res => res.json())
      .then(data => {
        downloadStringAsFile({ string: data.csvString, fileName: data.csvFilename })
      })
      .catch(err => window.alert(err))

  const handleClickDeletePayroll = () => {
    setRequestLoading(true)

    window.confirm('are you sure?') &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/delete_payroll`, {
        method: 'POST',
        headers: API_COMMAND_HEADERS,
        body: JSON.stringify({ payroll_id: payroll.id })
      })
        .then(res => {
          setRequestLoading(false)
          setRequestSuccess(true)
          setExpanded(false)
          refetch()
        })
        .then(() => setRequestSuccess(true))
        .catch(err => window.alert(err))
  }

  return (
    <>
      <TableRow
        key={payroll.id}
        style={{ position: 'relative', cursor: 'pointer' }}
        sx={[
          { '&:last-child td, &:last-child th': { border: 0 } },
          expanded && {
            background: '#f2f6fa',
            borderTop: '2px solid #3f51b5 !important',
            borderLeft: '2px solid #3f51b5',
            borderRight: '2px solid #3f51b5'
          }
        ]}
        onClick={() => setExpanded(!expanded)}
      >
        <TableCell>
          <Box sx={{ display: 'flex' }}>
            <SvgIcon component={expanded ? ArrowDropDownRounded : ArrowRightRounded} />
            <>{DateTime.fromISO(payroll.createdAt).toFormat('y-LL-dd')}</>
            {isCombinedExport && <i>&nbsp;&nbsp;&nbsp;combined export</i>}
          </Box>
        </TableCell>
      </TableRow>

      {expanded && (
        <TableRow
          sx={[
            { display: 'block', backgroundColor: '#f0f8ff', borderTop: 0 },
            expanded && {
              background: '#fff',
              borderLeft: '2px solid #3f51b5',
              borderRight: '2px solid #3f51b5',
              borderBottom: '2px solid #3f51b5'
            }
          ]}
        >
          <TableCell
            sx={{
              display: 'flex',
              '& .MuiButton-root ': {
                p: '.5rem',
                background: 'transparent !important',
                padding: '.125rem .5rem',
                fontSize: '11px !important',
                minWidth: '30px',
                minHeight: '15px !important',
                mr: '.5rem'
              },
              '&  .MuiButtonBase-root': {
                padding: '.125rem .5rem',
                minHeight: '15px !important'
              }
            }}
          >
            <Button
              variant='outlined'
              size='small'
              onClick={() =>
                downloadStringAsFile({
                  string: payroll.gustoCsv,
                  fileName: `payroll_gusto_${DateTime.fromISO(payroll.createdAt).toFormat('y-LL-dd')}.csv`
                })}
            >
              Gusto csv
            </Button>

            <Button variant='outlined' size='small' onClick={handleClickDownloadQuotesOnPayroll}>
              csv of Quotes
            </Button>

            <Button variant='outlined' size='small' onClick={handleClickDownloadBToBInvoicesOnPayroll}>
              csv of B2B Invoices{' '}
            </Button>
            <Button variant='outlined' size='small' onClick={handleClickDownloadCAEmployees}>
              csv of CA Employees{' '}
            </Button>
            <Button variant='outlined' size='small' onClick={handleClickDownloadNonCAEmployees}>
              csv of NON-CA Employees{' '}
            </Button>

            <Button variant='outlined' size='small' onClick={handleClickDownloadCAContractors}>
              csv of CA Contractors{' '}
            </Button>
            <Button variant='outlined' size='small' onClick={handleClickDownloadNonCAContractors}>
              csv of NON-CA Contractors{' '}
            </Button>

            <Button
              sx={{ ml: 'auto' }}
              color='error'
              variant='outlined'
              size='small'
              onClick={handleClickDeletePayroll}
              disabled={payroll.payouts.length !== 0}
            >
              Delete payroll
            </Button>
          </TableCell>

          <PayoutsTable payrollId={payroll.id} />
        </TableRow>
      )}

      <Snackbar
        onClose={() => setRequestSuccess(false)}
        open={requestSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ '& svg': { color: '#fff', mr: '1rem', height: '20px', width: '20px' } }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleOutlineRounded /> Payroll Deleted
          </Box>
        }
      />
      <Snackbar
        onClose={() => setRequestLoading(false)}
        open={requestLoading}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ '& svg': { color: '#fff' } }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress size='1rem' sx={{ mr: '1rem' }} /> Loading...{' '}
          </Box>
        }
      />
    </>
  )
}

const PayoutsTable = ({ payrollId, isEmployee }) => {
  const { loading, error, data, refetch } = useQuery(PAYROLL_QUERY, {
    variables: { id: payrollId }
  })

  useEffect(() => {
    data && refetch && refetch()
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const payroll = keysToCamel(data.payroll)
  const payouts = payroll.payouts

  const employeePayouts =
    payroll?.payouts
      .slice()
      .filter(payout => payout.technician.isEmployee)
      .sort((a, b) => (a.firstName > b.firstName ? 1 : -1)) || []

  const contractorPayouts =
    payroll?.payouts
      .slice()
      .filter(payout => !payout.technician.isEmployee)
      .sort((a, b) => (a.firstName > b.firstName ? 1 : -1)) || []

  return (
    <>
      {[
        { label: 'Employees', isEmployee: true, payouts: employeePayouts },
        { label: 'Contractors', isEmployee: false, payouts: contractorPayouts }
      ].map(e => (
        <Box
          sx={{
            '& .MuiButtonBase-root': {
              minHeight: '30px',
              padding: '0rem .75rem'
            },
            '& .MuiAccordionSummary-content': {
              margin: '0px'
            },
            '& .MuiAccordionDetails-root': {
              padding: '.5rem',
              background: '#ddd'
            }
          }}
        >
          <Accordion elevation={0} disablePadding disableGutters defaultExpanded sx={{ border: '1px solid #ccc' }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <b>{e.label}</b>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Tech
                      </TableCell>
                      {e.isEmployee && (
                        <TableCell sx={{ fontSize: '12px' }} align='right'>
                          Hourly
                        </TableCell>
                      )}
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Quotes
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Reimbursements
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Manual compensations
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        B2B Payouts
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        B2B Invoices
                      </TableCell>
                      {!e.isEmployee && (
                        <TableCell sx={{ fontSize: '12px' }} align='right'>
                          Equip. loan deduction
                        </TableCell>
                      )}
                      {!e.isEmployee && (
                        <TableCell sx={{ fontSize: '12px' }} align='right'>
                          Equip. loan balance
                        </TableCell>
                      )}
                      {!e.isEmployee && (
                        <TableCell sx={{ fontSize: '12px' }} align='right'>
                          Other loan deduction
                        </TableCell>
                      )}
                      {!e.isEmployee && (
                        <TableCell sx={{ fontSize: '12px' }} align='right'>
                          Other loan balance
                        </TableCell>
                      )}
                      {e.isEmployee && (
                        <TableCell sx={{ fontSize: '12px' }} align='right'>
                          PTO (hours)
                        </TableCell>
                      )}
                      {e.isEmployee && (
                        <TableCell sx={{ fontSize: '12px' }} align='right'>
                          STO (hours)
                        </TableCell>
                      )}
                      {e.isEmployee && (
                        <TableCell sx={{ fontSize: '12px' }} align='right'>
                          UTO (hours)
                        </TableCell>
                      )}
                      {e.isEmployee && (
                        <TableCell sx={{ fontSize: '12px' }} align='right'>
                          Hourly pay adj.
                        </TableCell>
                      )}
                      {e.isEmployee && (
                        <TableCell sx={{ fontSize: '12px' }} align='right'>
                          OT pay
                        </TableCell>
                      )}
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Charge backs
                      </TableCell>

                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Final check amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {e.payouts.map((payout, index) => (
                      <PayoutRows
                        index={index}
                        isEmployee={e.isEmployee}
                        key={payout.id}
                        payout={payout}
                        refetch={refetch}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </>
  )
}

const PayoutRows = ({ index, payout, isEmployee, refetch }) => {
  const [expanded, setExpanded] = useState(false)
  const [payoutDataOpen, setPayoutDataOpen] = useState(false)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [requestLoading, setRequestLoading] = useState(false)

  const invoices = keysToCamel(payout.bToBInvoices)
  const quotes = keysToCamel(payout.quotes)
  const contractorInvoices = invoices.filter(
    i => i.payoutData.payoutStructure === 'labor cost' || i.payoutData.payoutStructure === 'contractor'
  )
  const employeeInvoices = invoices.filter(
    i => i.payoutData.payoutStructure === 'panel' || i.payoutData.payoutStructure === 'employee'
  )

  const contractorQuotes = quotes.filter(
    i => i.payoutData.payoutType === 'labor cost' || i.payoutData.payoutType === 'contractor'
  )
  const employeeQuotes = quotes
    .filter(i => i.payoutData.payoutType === 'hourly' || i.payoutData.payoutType === 'employee')
    .map(q => ({ ...q, technician: payout.technician }))

  const handleUnlinkPayout = () => {
    setRequestLoading(true)
    if (window.confirm('are you sure?')) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/unclaim_payout_from_payroll`, {
        method: 'POST',
        headers: API_COMMAND_HEADERS,
        body: JSON.stringify({ payroll_id: payout.payrollId, payoutId: payout.id })
      })
        .then(res => {
          setRequestLoading(false)
          setRequestSuccess(true)
          setExpanded(false)
          refetch()
        })
        .catch(err => window.alert(err))
    }
  }

  return (
    <>
      <Snackbar
        onClose={() => setRequestSuccess(false)}
        open={!!requestSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiSnackbarContent-root': { color: '#3f51b5', background: 'aliceblue', border: '1px solid #a1cff7' },
          '& svg': { color: '#3f51b5', mr: '1rem', height: '20px', width: '20px' },
          '& .MuiSnackbarContent-message': { color: '#3f51b5' }
        }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleOutlineRounded /> {requestSuccess}
          </Box>
        }
      />
      <Snackbar
        onClose={() => setRequestLoading(false)}
        open={requestLoading}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiSnackbarContent-root': { color: '#3f51b5', background: 'aliceblue', border: '1px solid #a1cff7' },
          '& svg': { color: '#3f51b5' },
          '& .MuiSnackbarContent-message': { color: '#3f51b5' }
        }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress size='1rem' sx={{ mr: '1rem' }} /> Loading...{' '}
          </Box>
        }
      />

      <TableRow
        sx={[
          {
            '&:last-child td, &:last-child th': { border: 0 },
            background: index % 2 === 0 ? '#fff' : '#f9f9f9',
            cursor: 'pointer'
          },

          expanded && {
            background: '#f2f6fa',
            borderTop: '2px solid #3f51b5 !important',
            borderLeft: '2px solid #3f51b5',
            borderRight: '2px solid #3f51b5'
          }
        ]}
        onClick={() => setExpanded(!expanded)}
      >
        <TableCell align='right'>
          <span
            style={{ position: 'absolute', left: '15px', cursor: 'pointer' }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? <ArrowDropDownRounded /> : <ArrowRightRounded />}
          </span>
          &nbsp;
          <Link target='_blank' to={`/technicians/${payout.technicianId}`}>
            {payout.firstName} {payout.lastName}
          </Link>
        </TableCell>
        {isEmployee && (
          <TableCell align='right'>
            <Dollars value={payout.timeCardsSum + payout.overtimePayAdjustment} />
          </TableCell>
        )}
        <TableCell align='right'>
          <Dollars value={payout.quotePayoutsSum} />
        </TableCell>

        <TableCell align='right'>
          <Dollars value={payout.reimbursementsSum} />
        </TableCell>
        <TableCell align='right'>
          <Dollars value={payout.manualCompensationsSum} />
        </TableCell>
        <TableCell align='right'>
          <Dollars value={payout.bToBPayoutsSum} />
        </TableCell>
        <TableCell align='right'>
          <Dollars value={payout.bToBInvoicesSum} />
        </TableCell>

        {!isEmployee && (
          <TableCell align='right'>
            <b>
              <Dollars value={payout.loanDeductionAmount} />
            </b>
          </TableCell>
        )}
        {!isEmployee && (
          <TableCell align='right'>
            <Dollars value={payout.technician.loanBalance} />
          </TableCell>
        )}

        {!isEmployee && (
          <TableCell align='right'>
            <Dollars value={payout.otherLoanDeductionAmount} />
          </TableCell>
        )}

        {!isEmployee && (
          <TableCell align='right'>
            <Dollars value={payout.technician.otherLoanBalance} />
          </TableCell>
        )}

        {isEmployee && (
          <TableCell align='right'>
            <b>{payout.totalPaidTimeOffHours}</b>
          </TableCell>
        )}
        {isEmployee && (
          <TableCell align='right'>
            <b>{payout.totalSickTimeOffHours}</b>
          </TableCell>
        )}

        {isEmployee && (
          <TableCell align='right'>
            <b>{payout.totalUnpaidTimeOffHours}</b>
          </TableCell>
        )}
        {isEmployee && (
          <TableCell align='right'>
            <b>
              <Dollars value={payout.minimumHourlyPayAdjustment || 0} />
              <b style={{ fontSize: '11px' }}>
                {' '}
                ~{(payout.minimumHourlyPayAdjustment / payout.technician.hourlyRate || 0).toFixed(2) || 0} hour(s)
              </b>
            </b>
          </TableCell>
        )}

        {isEmployee && (
          <TableCell align='right'>
            <b>
              <Dollars value={payout.overtimePay || 0} />
            </b>
          </TableCell>
        )}

        <TableCell align='right'>
          <b>
            <Dollars value={-payout.commissionChargeBacksSum} />
          </b>
        </TableCell>

        <TableCell align='right'>
          <b>
            <Dollars value={payout.finalCheckAmount} />
          </b>
        </TableCell>
      </TableRow>

      {expanded && (
        <TableRow
          sx={{
            backgroundColor: '#fff',
            borderLeft: '2px solid #3f51b5',
            borderRight: '2px solid #3f51b5',
            borderBottom: '2px solid #3f51b5',
            borderTop: 0,
            width: '100%',
            '& .MuiButtonBase-root': {
              minHeight: '30px',
              padding: '0rem .75rem',
              background: '#f2f6fa'
            },
            '& .MuiAccordionSummary-content': {
              margin: '0px'
            },
            '& .MuiAccordionDetails-root': {
              padding: '.5rem',
              background: '#ddd'
            },
            '& .MuiTable-root': {
              background: '#fff',
              borderRadius: '6px'
            },
            '& .totalNumber': {
              color: '#fff',
              borderRadius: '100px',
              fontSize: '10px',
              fontWeight: 800,
              height: '17px',
              width: '17px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#3f51b5',
              border: '1px solid #3f51b5',
              fontFamily: 'Arial',
              cursor: 'default',
              mr: '.5rem',
              lineHeight: 4
            },
            '& .numItems-0': {
              opacity: '.20 !important',
              fontWeight: '400 !important',
              pointerEvents: 'none',
              background: '#aaa',
              alignItems: 'center',
              '& .totalNumber': {
                display: 'none'
              }
            }
          }}
        >
          <TableCell colSpan={16} sx={{ padding: '0rem' }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                padding: '.5rem',
                background: '#fff'
              }}
            >
              <Box>
                note:&nbsp;
                {payout.note && (
                  <span title={payout.note}>
                    {payout.note.substring(0, 15) + (payout.note.length > 15 ? '...' : '')}
                  </span>
                )}
              </Box>

              <Button
                variant='outlined'
                size='small'
                onClick={() => setPayoutDataOpen(!payoutDataOpen)}
                sx={{
                  marginLeft: 'auto',
                  background: 'transparent !important',
                  padding: '.125rem',
                  fontSize: '12px !important',
                  minWidth: '30px',
                  minHeight: '15px !important',
                  '&  .MuiButtonBase-root': {
                    padding: '.125rem',
                    minHeight: '15px !important'
                  }
                }}
              >
                view full calculations
              </Button>

              <Button
                variant='outlined'
                size='small'
                color='error'
                onClick={handleUnlinkPayout}
                sx={{
                  marginLeft: '1rem',
                  background: 'transparent !important',
                  padding: '.125rem',
                  fontSize: '12px !important',
                  minWidth: '30px',
                  minHeight: '15px !important',
                  '&  .MuiButtonBase-root': {
                    padding: '.125rem',
                    minHeight: '15px !important'
                  }
                }}
              >
                unlink payout
              </Button>
            </Box>
            {payoutDataOpen && (
              <PayoutDataPopover
                payoutDataOpen={payoutDataOpen}
                setPayoutDataOpen={setPayoutDataOpen}
                data={{
                  payout: {
                    id: payout.id,
                    firstName: payout.firstName,
                    lastName: payout.lastName,
                    businessName: payout.businessName,
                    contractorIsBusiness: payout.contractorIsBusiness,
                    technicianId: payout.technicianId,
                    quotePayouts: payout.quotePayouts,
                    quotePayoutsSum: payout.quotePayoutsSum,
                    loanDeductionAmount: payout.loanDeductionAmount,
                    otherLoanDeductionAmount: payout.otherLoanDeductionAmount,
                    createdAt: payout.createdAt,
                    einLastFour: payout.einLastFour,
                    ssnLastFour: payout.ssnLastFour,
                    gustoWageAmount: payout.gustoWageAmount,
                    finalCheckAmount: payout.finalCheckAmount,
                    note: payout.note,
                    totalPaidTimeOffHours: payout.totalPaidTimeOffHours,
                    totalSickTimeOffHours: payout.totalSickTimeOffHours,
                    totalUnpaidTimeOffHours: payout.totalUnpaidTimeOffHours,
                    loanBalanceBeforeThisPayout: payout.loanBalanceBeforeThisPayout,
                    loanBalanceAfterThisPayout: payout.loanBalanceAfterThisPayout,
                    otherLoanBalanceBeforeThisPayout: payout.otherLoanBalanceBeforeThisPayout,
                    otherLoanBalanceAfterThisPayout: payout.otherLoanBalanceAfterThisPayout,
                    timeCardsSum: payout.timeCardsSum,
                    minimumHourlyPayAdjustment: payout.minimumHourlyPayAdjustment,
                    overtimePayAdjustment: payout.overtimePayAdjustment,
                    overtimePay: payout.overtimePay,
                    commissionChargeBacksSum: payout.commissionChargeBacksSum,
                    commissionChargeBacks: payout.commissionChargeBacks,
                    reimbursementsSum: payout.reimbursementsSum,
                    manualCompensations: payout.manualCompensations,
                    manualCompensationsSum: payout.manualCompensationsSum,
                    bToBPayoutsSum: payout.bToBPayoutsSum,
                    bToBInvoicesSum: payout.bToBInvoicesSum,
                    bToBInvoicePayouts: payout.bToBInvoicePayouts,
                    timeCardPayouts: payout.timeCardPayouts
                  }
                }}
              />
            )}

            <Accordion disablePadding disableGutters>
              <AccordionSummary className={`numItems-${payout?.quotes?.length}`} expandIcon={<ExpandMore />}>
                <span className='totalNumber'>{payout?.quotes?.length}</span>
                <b>Quote payouts - ${payout.quotePayoutsSum.toFixed(2)}</b>
              </AccordionSummary>
              {payout.quotes.length > 0 && (
                <AccordionDetails>
                  {contractorQuotes.length > 0 && <RevenueQuotes quotes={contractorQuotes} />}
                  {employeeQuotes.length > 0 && <HourlyQuotes quotes={employeeQuotes} />}
                </AccordionDetails>
              )}
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.timeCards?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.timeCards?.length}</span>

                <b>Time Card Payouts - ${payout.timeCardsSum.toFixed(2)}</b>
              </AccordionSummary>
              {payout.timeCards.length > 0 && (
                <AccordionDetails>
                  <Table size='small' component={Paper}>
                    <TimeCards timeCards={keysToCamel(payout.timeCardPayouts)} />
                  </Table>
                </AccordionDetails>
              )}
            </Accordion>

            {payout.bToBInvoices.length > 0 && (
              <Accordion disablePadding disableGutters>
                <AccordionSummary className={`numItems-${payout?.bToBInvoices?.length}`} expandIcon={<ExpandMore />}>
                  <span className='totalNumber'>{payout?.bToBInvoices?.length}</span>
                  <b>B2B Invoices - ${payout.bToBInvoicesSum.toFixed(2)}</b>
                </AccordionSummary>
                <AccordionDetails>
                  {contractorInvoices.length > 0 && <RevenueBasedInvoices invoices={contractorInvoices} />}
                  {employeeInvoices.length > 0 && <PanelBasedInvoices invoices={employeeInvoices} />}
                </AccordionDetails>
              </Accordion>
            )}

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.reimbursements?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.reimbursements?.length}</span>
                <b>
                  Reimbursements - <Dollars value={payout.reimbursementsSum} />
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.reimbursements.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.reimbursements.map(reimbursement => (
                          <li key={reimbursement.id} style={{ padding: '0.5em' }}>
                            <span title={reimbursement.note}>
                              {reimbursement.note.substring(0, 15) + (reimbursement.note.length > 15 ? '...' : '')}
                            </span>
                            :&nbsp;
                            <Dollars value={reimbursement.amount} />
                            {reimbursement.photoUrls && reimbursement.photoUrls.length > 0 && (
                              <>
                                <br />
                                photos <br />
                              </>
                            )}
                            {reimbursement.photoUrls &&
                              reimbursement.photoUrls.map(photoUrl => (
                                <img
                                  key={photoUrl}
                                  title='open in new window'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => window.open(photoUrl)}
                                  width={50}
                                  src={photoUrl}
                                />
                              ))}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.manualCompensations?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.manualCompensations?.length}</span>

                <b>
                  Manual Compensations - <Dollars value={payout.manualCompensationsSum} />
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.manualCompensations.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.manualCompensations.map(comp => (
                          <li key={comp.id} style={{ padding: '0.5em' }}>
                            <Link target='_blank' to={`/leads/${comp.quote?.lead?.id}/quotes/${comp?.quote?.id}`}>
                              quote
                            </Link>
                            &nbsp;
                            <span title={comp.note}>{comp.note}</span> :&nbsp;
                            <Dollars value={comp.amount} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.paidTimeOff?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.paidTimeOff?.length}</span>

                <b>
                  PTO - <b>{payout.totalPaidTimeOffHours} hour(s)</b>
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.paidTimeOff.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.paidTimeOff.map(comp => (
                          <li key={comp.id} style={{ padding: '0.5em' }}>
                            <Chip
                              sx={{ mr: '.25rem' }}
                              size='small'
                              label={DateTime.fromISO(comp.timeOffDate).toFormat('kkkk-LL-dd')}
                            />
                            <span title={comp.note}>{comp.note}</span> :&nbsp;
                            {comp.totalHours} hour(s) &nbsp;
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.sickTimeOff?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.sickTimeOff?.length}</span>

                <b>
                  Sick Time - <b>{payout.totalSickTimeOffHours} hour(s)</b>
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.sickTimeOff.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.sickTimeOff.map(comp => (
                          <li key={comp.id} style={{ padding: '0.5em' }}>
                            <Chip
                              sx={{ mr: '.25rem' }}
                              size='small'
                              label={DateTime.fromISO(comp.timeOffDate).toFormat('kkkk-LL-dd')}
                            />
                            <span title={comp.note}>{comp.note}</span> :&nbsp;
                            {comp.totalHours} hour(s) &nbsp;
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.unpaidTimeOff?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.unpaidTimeOff?.length}</span>

                <b>
                  Unpaid Time Off - <b>{payout.totalUnpaidTimeOffHours || 0} hour(s)</b>
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.unpaidTimeOff.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.unpaidTimeOff.map(comp => (
                          <li key={comp.id} style={{ padding: '0.5em' }}>
                            <Chip
                              sx={{ mr: '.25rem' }}
                              size='small'
                              label={DateTime.fromISO(comp.timeOffDate).toFormat('kkkk-LL-dd')}
                            />
                            <span title={comp.note}>{comp.note}</span> :&nbsp;
                            {comp.totalHours} hour(s) &nbsp;
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.bToBPayouts?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.bToBPayouts?.length}</span>

                <b>
                  B2B Payouts -
                  <b>
                    <Dollars value={payout.bToBPayoutsSum} />
                  </b>
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.bToBPayouts.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.bToBPayouts.map(bToBPayout => (
                          <li key={bToBPayout.id} style={{ padding: '0.5em' }}>
                            <span title={bToBPayout.note}>
                              {bToBPayout.note.substring(0, 15) + (bToBPayout.note.length > 15 ? '...' : '')}
                            </span>
                            :&nbsp;
                            <Dollars value={bToBPayout.amount} />
                            {bToBPayout.photoUrls && bToBPayout.photoUrls.length > 0 && (
                              <>
                                <br />
                                photos <br />
                              </>
                            )}
                            {bToBPayout.photoUrls &&
                              bToBPayout.photoUrls.map(photoUrl => (
                                <img
                                  key={photoUrl}
                                  title='open in new window'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => window.open(photoUrl)}
                                  width={50}
                                  src={photoUrl}
                                />
                              ))}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.commissionChargeBacks?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <b>
                  Charge backs <Dollars value={-payout.commissionChargeBacksSum} />
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.commissionChargeBacks.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.commissionChargeBacks.map(comp => (
                          <li key={comp.id} style={{ padding: '0.5em' }}>
                            <Chip
                              sx={{ mr: '.25rem' }}
                              size='small'
                              label={DateTime.fromISO(comp.createdAt).toFormat('kkkk-LL-dd')}
                            />{' '}
                            &nbsp;<Link to={`/quotes/${comp.quoteId}`}>see quote</Link>:&nbsp;
                            <span title={comp.note}>{comp.note}</span>&nbsp;
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default Payrolls
