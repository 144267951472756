import { useState, useEffect, createContext, useContext } from 'react'
import { Button, Typography, Grid, Dialog, useMediaQuery, Tooltip, Box, Skeleton, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useSquareSDK } from './useSquareSDK'
import { Dollars } from 'tools'
import {
  VerifyingOverlay,
  SuccessfullyVerifiedOverlay,
  ProcessingOverlay,
  SuccessfullyProcessedOverlay,
  ErrorOverlay,
} from '../Invoices/Overlays'
import { styles } from '../Invoices/style'
import { v4 as uuid } from 'uuid'
import { DateTime } from 'luxon'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'

const SquarePostJobPaymentContext = createContext()

const CustomerInfoScreen = ({ squareErrorMessage, attachSquareToDom, handleCardInfoSubmitted, totalAmount }) => {
  const { paymentContext, updatePaymentContext } = useContext(SquarePostJobPaymentContext)
  const {
    quote,
    cardIsReady,
    paymentInfo: { firstName, lastName },
  } = paymentContext

  const [errorMessages, setErrorMessages] = useState({
    firstName: '',
    lastName: '',
  })
  const [toolTip, setToolTip] = useState(false)

  const isMobile = useMediaQuery('(max-width:770px)')

  const handleChangeFirstName = event => {
    updatePaymentContext({
      paymentInfo: {
        ...paymentContext.paymentInfo,
        firstName: event.target.value,
      },
    })
  }
  const handleChangeLastName = event => {
    updatePaymentContext({
      paymentInfo: {
        ...paymentContext.paymentInfo,
        lastName: event.target.value,
      },
    })
  }

  useEffect(() => {
    if (cardIsReady) {
      firstName.length < 1 &&
        setErrorMessages(prev => ({
          ...prev,
          firstName: 'First name is required.',
        }))
      lastName.length < 1 &&
        setErrorMessages(prev => ({
          ...prev,
          lastName: 'Last name is required.',
        }))
    }
  }, [cardIsReady])

  useEffect(() => {
    firstName.length >= 1 && setErrorMessages(prev => ({ ...prev, firstName: '' }))
    lastName.length >= 1 && setErrorMessages(prev => ({ ...prev, lastName: '' }))
  }, [lastName, firstName])

  useEffect(() => {
    if (squareErrorMessage.length > 0) {
      setToolTip(true)
    } else {
      setToolTip(false)
    }
  }, [squareErrorMessage])

  useEffect(() => {
    setTimeout(() => {
      attachSquareToDom()
    }, 2000)
  }, [])

  return (
    <Box sx={styles.popupContainer}>
      <Box sx={{ height: '100%', width: '100%', pb: '1rem' }} id='scroll-container'>
        <Box>
          <Typography sx={styles.title} variant='h1'>
            B2B Payments
          </Typography>
          <Typography sx={styles.subtitle} variant='h2'>
            Secure checkout powered by Square.
          </Typography>
        </Box>
        <Grid item xs={12}>
          <Grid container sx={{ marginBottom: '.25rem' }}>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={styles.label} variant='h2'>
                    Contact Info
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{
                      width: isMobile ? '100%' : '98%',
                      marginBottom: '.25rem',
                      padding: '0px',
                    }}
                    value={paymentContext.paymentInfo.firstName}
                    onChange={handleChangeFirstName}
                    type='text'
                    size='small'
                    placeholder='First name'
                    error={(errorMessages && errorMessages.firstName && errorMessages.firstName.length > 0) || false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ width: '100%' }}
                    onChange={handleChangeLastName}
                    value={paymentContext.paymentInfo.lastName}
                    type='text'
                    size='small'
                    placeholder='Last name'
                    error={(errorMessages && errorMessages.lastName && errorMessages.lastName.length > 0) || false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '-1rem' }}>
            <Grid item xs={12}>
              <Typography sx={styles.label} variant='h2'>
                Payment Info
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div id='square'>
                <Skeleton
                  sx={{
                    transform: 'scale(1)',
                    color: '#666',
                    alignItems: 'center',
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: '100%',
                    padding: '.5rem',
                    minHeight: '100px',
                    margin: '0 0 6rem 0',
                    userSelect: 'none',
                  }}
                >
                  <img
                    style={{
                      width: '100px',
                      visibility: 'visible',
                      marginBottom: '.75rem',
                    }}
                    src='https://driveway-development.s3.us-east-2.amazonaws.com/getdriveway-CRA/square.svg'
                  />
                  Loading Secure Payment Module
                </Skeleton>
              </div>
              <Tooltip
                open={toolTip}
                disableFocusListener
                disableTouchListener
                title={<span style={{ lineHeight: '1.25rem' }}>{squareErrorMessage}</span>}
              >
                <span
                  style={{
                    fontSize: '0px',
                    width: '100%',
                    display: 'block',
                    marginTop: '-3.15rem',
                    marginBottom: '3.15rem',
                  }}
                >
                  {' '}
                </span>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => handleCardInfoSubmitted()}
              disableRipple
              disableElevation
              fullWidth
              variant='contained'
              sx={styles.filledButton}
            >
              Pay&nbsp;
              <Dollars value={totalAmount} />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const SquarePaymentDialogContent = ({ setShowIt, showIt, totalAmount, invoiceIds, state }) => {
  const {
    verifyDetailsAndMakePayment,
    cardIsReady,
    status: cardStatus,
    attachSquareToDom,
    squareErrorMessage,
    cleanUpSquareScripts,
  } = useSquareSDK('/take_square_group_payment_for_b2b_invoices', state)
  const { paymentContext, updatePaymentContext } = useContext(SquarePostJobPaymentContext)
  const idempotencyKey = uuid()
  const handleClose = () => {
    cleanUpSquareScripts().then(() => {
      setShowIt(false)
    })
  }

  useEffect(() => {
    updatePaymentContext({ cardIsReady })
  }, [cardIsReady])

  useEffect(() => {
    if (!showIt) {
      handleClose()
    }
  }, [showIt])

  useEffect(() => {
    updatePaymentContext({ cardStatus: cardStatus })
  }, [cardStatus])

  const handleCardInfoSubmitted = () => {
    const customerPaymentData = {
      amount: totalAmount, // for verifying with square
      idempotency_key: idempotencyKey,
      billingContact: {
        familyName: paymentContext.paymentInfo.lastName,
        givenName: paymentContext.paymentInfo.firstName,
        country: 'US',
        phone: paymentContext.lead.phone,
        region: paymentContext.lead.city,
        city: paymentContext.lead.city,
      },
      currencyCode: 'USD',
      intent: 'CHARGE',
      invoice_ids: invoiceIds,
      total_amount: Number(Number(totalAmount).toFixed(2)),
    }

    updatePaymentContext({
      customerPaymentData: customerPaymentData,
    })
    verifyDetailsAndMakePayment(customerPaymentData)
  }

  return (
    <>
      <Button onClick={handleClose} disableRipple sx={styles.closeButton}>
        <Close />
      </Button>
      <CustomerInfoScreen
        attachSquareToDom={attachSquareToDom}
        squareErrorMessage={squareErrorMessage}
        handleCardInfoSubmitted={handleCardInfoSubmitted}
        totalAmount={totalAmount}
      />
    </>
  )
}

const SquarePostJobPaymentForm = ({ quote, lead, refetch, totalAmount, invoiceIds, state, disabled }) => {
  const [paymentContext, setPaymentContext] = useState({
    cardIsReady: false,
    cardStatus: 'info',
    buttonClickedForSelectedTip: '',
    paymentInfo: {
      firstName: '',
      lastName: '',
      amount: 0,
      splitPaymentAmount: 0,
      tipAmount: 0,
    },
  })
  const [showModal, setShowModal] = useState(false)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const updatePaymentContext = update => {
    setPaymentContext(prev => ({ ...prev, ...update }))
  }

  useEffect(() => {
    paymentContext.cardStatus === 'success' && refetch()
    paymentContext.cardStatus === 'success' && setShowModal(false)
  }, [paymentContext])

  useEffect(() => {
    updatePaymentContext({ quote: { ...quote }, lead: { ...lead } })
  }, [quote])

  const handleMarkMultipleAsPaid = () => {
    const amount = window.prompt('What is the total check amount?')
    if (amount) {
      return new Promise((resolve, reject) => {
        invoiceIds.map(id =>
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/take_check_group_payment_for_b2b_invoices`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              b_to_b_invoice_ids: invoiceIds,
              user_id: user.id,
              amount: amount,
            }),
          }).then(res => res.ok || window.alert('Error'))
        )
        resolve(null)
      }).then(() => refetch())
    }
  }

  return (
    <SquarePostJobPaymentContext.Provider value={{ paymentContext, updatePaymentContext }}>
      <Dialog PaperProps={{ sx: { borderRadius: '20px ' } }} open={showModal} onClose={() => setShowModal(false)}>
        {paymentContext.cardStatus === 'verifying' && <VerifyingOverlay />}
        {paymentContext.cardStatus === 'verified' && <SuccessfullyVerifiedOverlay />}
        {paymentContext.cardStatus === 'processing' && <ProcessingOverlay />}
        {paymentContext.cardStatus === 'processed' && <SuccessfullyProcessedOverlay />}
        {paymentContext.cardStatus === 'error' && <ErrorOverlay />}

        <SquarePaymentDialogContent
          showIt={showModal}
          setShowIt={setShowModal}
          totalAmount={totalAmount}
          invoiceIds={invoiceIds}
          state={state}
        />
      </Dialog>
      {!(Number(quote?.preJobAmountDue) > Number(0)) && (
        <Box
          sx={{
            display: 'flex',
            padding: '.5rem',
            background: '#f1f1f1',
            borderTop: '1px solid',
          }}
        >
          <Button
            disabled={disabled}
            disableRipple
            disableElevation
            fullWidth
            onClick={handleMarkMultipleAsPaid}
            variant='outlined'
            sx={{ ...styles.filledButton, mr: 'auto !important' }}
          >
            Mark as paid by check
          </Button>
          <Button
            disabled={disabled}
            onClick={() => setShowModal(true)}
            disableRipple
            disableElevation
            fullWidth
            variant='contained'
            sx={{ ...styles.filledButton, ml: 'auto !important' }}
          >
            Pay&nbsp;
            <Dollars value={totalAmount} />
          </Button>
        </Box>
      )}
    </SquarePostJobPaymentContext.Provider>
  )
}

export default SquarePostJobPaymentForm
