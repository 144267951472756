import React, { useEffect, useState, useContext, Fragment } from 'react'
import { useQuery, gql } from '@apollo/client'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { RefetchRegistry } from 'RefetchRegistry'

import WarningIcon from '@mui/icons-material/Warning'

import NewPartVendorDialog from './NewPartVendorDialog'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { useDebounce } from 'use-debounce'

import FormControl from '@mui/material/FormControl'

const filter = createFilterOptions({ limit: 100 })

const PART_VENDOR_QUERY = gql`
  query partVendors($filter: JSON, $hasTires: Boolean) {
    partVendors(filter: $filter, hasTires: $hasTires) {
      id
      name
      addressLineOne
      city
      state
      zip
      hasTires
    }
  }
`

const PartVendorSelect = ({
  inputProps,
  helperText,
  disabled,
  value,
  vendorState,
  hideLabel,
  hideAddVendor,
  onClick,
  onChange,
  hasOneOrMoreTires
}) => {
  const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [query, setQuery] = useState('')
  const [lastQuery, setLastQuery] = useState('')
  const [newVendor, setNewVendor] = useState({})

  const oneOrMoreTires = hasOneOrMoreTires

  const { loading, fetchError, data, refetch } = useQuery(PART_VENDOR_QUERY, {
    variables: oneOrMoreTires
      ? { hasTires: oneOrMoreTires }
      : {
          filter:
            vendorState && vendorState !== ''
              ? {
                  state: { eq: vendorState }
                }
              : {}
        }
  })

  const [debouncedData] = useDebounce(data, 500, { leading: true })

  // useEffect(() => {
  //   const key = registerRefetch({ types: ['PartVendor'], refetch: refetch })
  //   return () => unregisterRefetch(key)
  // }, [])

  useEffect(() => {
    if (debouncedData && debouncedData.partVendors) {
      setOptions(
        [...debouncedData.partVendors].sort((a, b) =>
          a.name < b.name ? -1 : 1
        )
      )
    }
  }, [debouncedData])

  return (
    <div style={{ position: 'relative' }}>
      <FormControl error style={{ width: '100%' }}>
        {!hideAddVendor && (
          <Box style={{ position: 'absolute', right: '8px', top: '-20px' }}>
            <a
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                setNewVendor(null)
              }}
            >
              Add Merchant
            </a>
          </Box>
        )}
        <Autocomplete
          id='part-vendor-select'
          open={open}
          size={inputProps.size}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            // save the last query so we can pass it to the add merchant modal
            // because it is cleared when the focus is lost
            setLastQuery(query)
            setOpen(false)
          }}
          isOptionEqualToValue={(option, value) =>
            option ? option.id === value.id : false
          }
          getOptionLabel={option => (option ? option.name : '')}
          options={options}
          value={value && Object.keys(value).length > 0 ? value : ''}
          loading={loading}
          disabled={disabled}
          onChange={(event, newValue) => onChange(newValue)}
          onInputChange={(event, newInputValue) => setQuery(newInputValue)}
          filterOptions={(options, params) => filter(options, params)}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.name, inputValue)
            const parts = parse(option.name, matches)

            return (
              <li key={option.id} {...props}>
                <div>
                  <b>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          textDecoration: part.highlight ? 'underline' : 'none'
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </b>
                  <br />
                  {option.addressLineOne}, {option.city} {option.state}
                </div>
              </li>
            )
          }}
          renderInput={params => (
            <TextField
              required={inputProps.required}
              label={hideLabel ? '' : 'Merchant Name'}
              variant={inputProps.variant || 'outlined'}
              onClick={onClick}
              error={inputProps.error}
              helperText={helperText}
              InputProps={{
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : fetchError ? (
                      <WarningIcon color='error' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
              {...params}
            />
          )}
        />
      </FormControl>
      <NewPartVendorDialog
        newVendorName={lastQuery}
        onVendorCreated={data => {
          setNewVendor(data)
          onChange(data)
        }}
        isOpen={!newVendor}
        onClose={() => {
          setNewVendor({})
        }}
      />
    </div>
  )
}

export default PartVendorSelect
