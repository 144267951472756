import { useState } from 'react'
import {
  Box, Tabs, Tab
} from '@mui/material'
import All from './All'
import Unplaced from './Unplaced'
import Items from './Items'

const Index = ({ items, refetch }) => {
  const [tab, setTab] = useState('all')

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)}>
          <Tab value='all' label='all' />
          <Tab value='unplaced' label='unplaced orders' />
          <Tab value='items' label='manage items' />
        </Tabs>
      </Box>
      {tab === 'all' && <All items={items} />}
      {tab === 'unplaced' && <Unplaced items={items} />}
      {tab === 'items' && <Items items={items} refetch={refetch} />}
    </Box>
  )
}

export default Index
