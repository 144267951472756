import {
  Typography,
  Container,
  Grid,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
  Popover,
} from '@mui/material'
import { WorkRounded, MovingRounded } from '@mui/icons-material'
import { useState, useContext } from 'react'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { UserContext } from 'UserStore'

const PayrollPopup = ({ prefetched, refetch, close }) => {
  const [newLogOpen, setNewLogOpen] = useState(false)
  const [newLogItem, setNewLogItem] = useState({
    type: '',
    startedAt: '',
    completedAt: '',
  })

  const timeCard = prefetched

  const workLogs = timeCard?.workLogs || []
  const travelLogs = timeCard?.travelLogs || []

  const sortedTimeLogs =
    workLogs
      .concat(travelLogs)
      ?.slice()
      .sort((a, b) => (a.startedAt > b.startedAt ? 1 : -1)) || []

  return (
    <Container disableGutters maxWidth='sm' sx={{}}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '2px solid #ddd',
              padding: '1rem',
              minWidth: '600px',
            }}
          >
            <Typography sx={{ fontSize: '18px', fontWeight: 600, '& span': { fontSize: '13px', fontWeight: 400 } }}>
              {timeCard.technician.firstName} {timeCard.technician.lastName}
            </Typography>

            <Link to={`/timecards/${timeCard.id}`}>{DateTime.fromISO(timeCard.timeCardDate).toFormat('DDDD')}</Link>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>item</TableCell>
                  <TableCell align='center'>paid?</TableCell>
                  <TableCell align='center'>mileage</TableCell>
                  <TableCell align='center'>started at</TableCell>
                  <TableCell align='center'>completed at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedTimeLogs?.map(timeLog => (
                  <TimeCardLineItem key={timeLog.id} timeLog={timeLog} refetch={refetch} timeCard={timeCard} />
                ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>
      </Grid>

      <Popover
        PaperProps={{ sx: { padding: '1rem' } }}
        anchorEl={newLogOpen}
        onClose={() => setNewLogOpen(null)}
        open={!!newLogOpen}
      >
        Started at <br />
        <input
          value={newLogItem.startedAt}
          onChange={e =>
            setNewLogItem(prev => ({
              ...prev,
              startedAt: e.target.value,
            }))
          }
          type='time'
        />
        <br />
        Completed at <br />
        <input
          value={newLogItem.completedAt}
          onChange={e =>
            setNewLogItem(prev => ({
              ...prev,
              completedAt: e.target.value,
            }))
          }
          type='time'
        />
        <br />
        <FormControlLabel
          label='is paid?'
          size='small'
          control={
            <Checkbox
              checked={newLogItem.isPaid}
              onChange={e =>
                setNewLogItem(prev => ({
                  ...prev,
                  isPaid: newLogItem.isPaid ? false : true,
                }))
              }
            />
          }
        />
        <br />
      </Popover>
    </Container>
  )
}

const TimeCardLineItem = ({ timeLog, refetch, timeCard }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const disableEverything = !!timeCard.acceptedAt

  return (
    <TableRow key={timeLog.id}>
      <TableCell align='center'>
        <Box
          sx={{
            color: '#666',
            '& svg': {
              fontSize: '24px',
            },
            display: 'flex',
            alignItems: 'center',
            '& span': {
              margin: '0px 3px',
            },
          }}
        >
          {timeLog.type === 'travel_log' && <MovingRounded />}
          {timeLog.type === 'work_log' && <WorkRounded />}
        </Box>
      </TableCell>
      <TableCell align='center'>
        <Checkbox
          disabled={timeLog.type === 'work_log' || disableEverything}
          checked={timeLog.isPaid}
          onChange={e => {
            fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_time_or_work_log_attributes`, {
              method: 'POST',
              headers: bearerTokenHeaders,
              body: JSON.stringify({
                user_id: user.id,
                updated_attributes: {
                  is_paid: timeLog.isPaid ? false : true,
                },
                type: timeLog.type,
                log_id: timeLog.id,
              }),
            }).then(res => {
              if (res.ok) {
                refetch()
              } else {
                window.alert('Error')
              }
            })
          }}
        />
      </TableCell>
      <TableCell align='center'>
        {timeLog.type !== 'work_log' && (
          <input
            disabled={disableEverything}
            type='number'
            style={{ maxWidth: '50px' }}
            defaultValue={timeLog.travelDistance}
            onChange={e => {
              fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_time_or_work_log_attributes`, {
                method: 'POST',
                headers: bearerTokenHeaders,
                body: JSON.stringify({
                  user_id: user.id,
                  updated_attributes: {
                    travel_distance: e.target.value,
                  },
                  type: timeLog.type,
                  log_id: timeLog.id,
                }),
              }).then(res => {
                if (res.ok) {
                  refetch()
                } else {
                  window.alert('Error')
                }
              })
            }}
          />
        )}
      </TableCell>
      <TableCell align='center'>
        <input
          disabled={disableEverything}
          defaultValue={timeLog?.startedAt?.slice(11, -6)}
          type='time'
          onChange={e =>
            fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_time_or_work_log_attributes`, {
              method: 'POST',
              headers: bearerTokenHeaders,
              body: JSON.stringify({
                user_id: user.id,
                updated_attributes: {
                  started_at: e.target.value,
                },
                type: timeLog.type,
                log_id: timeLog.id,
              }),
            }).then(res => {
              if (res.ok) {
              } else {
                window.alert('Error')
              }
            })
          }
        />
      </TableCell>
      <TableCell align='center'>
        <input
          disabled={disableEverything}
          defaultValue={timeLog?.completedAt?.slice(11, -6)}
          type='time'
          onChange={e =>
            fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_time_or_work_log_attributes`, {
              method: 'POST',
              headers: bearerTokenHeaders,
              body: JSON.stringify({
                user_id: user.id,
                updated_attributes: {
                  completed_at: e.target.value,
                },
                type: timeLog.type,
                log_id: timeLog.id,
              }),
            }).then(res => {
              if (res.ok) {
              } else {
                window.alert('Error')
              }
            })
          }
        />
      </TableCell>
    </TableRow>
  )
}

export default PayrollPopup
