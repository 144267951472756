import { useContext, useState } from 'react'
import { UserContext } from 'UserStore'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { DateTime } from 'luxon'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}))

const CallbackRequestsDump = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [isWorking, setIsWorking] = useState(false)
  const classes = useStyles()

  const handleClickToday = () => {
    const now = DateTime.local().setZone('America/Los_Angeles')
    setFromDate(now.startOf('day'))
    setToDate(now.endOf('day'))
  }

  const handleClickSubmit = () => {
    setIsWorking(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/callback_requests_dump`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        from_la_datetime: fromDate.toISO(),
        to_la_datetime: toDate.toISO()
      })
    })
      .then(response => {
        if (response.ok) {
          return response.text()
        } else {
          throw Error('bad range?')
        }
      })
      .then(responseText => {
        window.alert('OK, check your email soon; this process takes a few seconds per day in range')
        setIsWorking(false)
      })
      .catch(err => {
        window.alert(err)
        setIsWorking(false)
      })
  }

  const handleChangedFromDate = event =>
    setFromDate(DateTime.fromISO(event.target.value, { zone: 'America/Los_Angeles' }).startOf('day'))

  const handleChangedToDate = event =>
    setToDate(DateTime.fromISO(event.target.value, { zone: 'America/Los_Angeles' }).endOf('day'))

  const disabled = !(fromDate && toDate) || isWorking

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            Callback requests
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label='From'
            type='date'
            value={(fromDate && fromDate.toISODate()) || ''}
            className={classes.textField}
            onChange={handleChangedFromDate}
            InputLabelProps={{
              shrink: true
            }}
          />

          <TextField
            label='To'
            type='date'
            value={(toDate && toDate.toISODate()) || ''}
            className={classes.textField}
            onChange={handleChangedToDate}
            InputLabelProps={{
              shrink: true
            }}
          />

          <Button variant='outlined' color='primary' onClick={handleClickToday}>
            today
          </Button>
          &nbsp;
          <Button disabled={disabled} variant='contained' color='primary' onClick={handleClickSubmit}>
            {isWorking ? 'working' : 'send email'}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default CallbackRequestsDump
