import { useHistory, Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { Tabs, Tab, Box, Button, Typography} from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import Overview from './Overview'
import Edit from './Edit'

const SMART_FOLLOWUP_QUERY = gql`
  query smartFollowup($id: ID!) {
    smartFollowup(id: $id) {
      id
      name
      active
      lastMessageDelayHours
      interactionStatus
      smartAction {
        id
        data
        type
      }
      otherCriteria {
        id
        data
        type
      }
      marketsCriterion {
        id
        data
        type
      }
    }
  }
`

const SmartFollowup = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const { path } = useRouteMatch()
  const { smartFollowupId } = useParams()
  const history = useHistory()
  // useEffect(() => {
  //   const key = registerTaskTarget({ targetType: 'SmartFollowup', targetId: smartFollowupId })
  //   return () => unregisterTaskTarget(key)
  // }, [])

  const { loading, error, data, refetch } = useQuery(SMART_FOLLOWUP_QUERY, { variables: { id: smartFollowupId } })
  const handleChangeTab = (_, newValue) => {
    history.push(newValue)
  }

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const handleClickDelete = () =>
    window.confirm('are you sure you want to delete this? this cant be undone.') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_smart_followup`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        smart_followup_id: data?.smartFollowup.id,
      }),
    }).then(_ => history.push('/smart-followups'))

  const smartFollowup = data.smartFollowup

  return (
    <Box sx={{ padding: '1rem', maxWidth: '1050px' }}>
      <Button sx={{ mb: '1rem' }} variant='outlined' onClick={() => history.push('/smart-followups')}>
        &lt; Back
      </Button>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='h4'>{smartFollowup.name}</Typography>
        <Button onClick={handleClickDelete} variant='contained' color='secondary'>
          delete
        </Button>
      </Box>
      <Tabs value={window.location.pathname} onChange={handleChangeTab}>
        <Tab disabled value={`/smart-followups/${smartFollowupId}/overview`} label='Overview' />
        <Tab value={`/smart-followups/${smartFollowupId}/edit`} label='Edit' />
      </Tabs>

      <Switch>
        <Route path={`${path}/overview`}>
          <Overview smartFollowup={smartFollowup} />
        </Route>
        <Route path={`${path}/edit`}>
          <Edit refetch={refetch} smartFollowup={smartFollowup} />
        </Route>
      </Switch>
    </Box>
  )
}

export default SmartFollowup
