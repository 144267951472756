import { Grid, Typography } from '@mui/material'
import { gql, useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import { CreateATask } from 'Tasks'
import { prettyPhoneNumber } from 'tools'
import { JobApplication } from 'hooks/recruiting/jobApplications/useJobApplications/query'
import StreamDump from 'components/StreamDump'
import { Interview } from 'hooks/recruiting/interviews/useInterviews/query'
import { DateTime } from 'luxon'

const APPLICANT_BY_ID = gql`
  query applicant($id: ID!) {
    applicant(id: $id) {
      id
      fullName
      phone
      jobApplications {
        id
        createdAt
      }
      interviews {
        id
        createdAt
        startDatetime
        jobApplication {
          id
        }
      }
    }
  }
`

export const Applicant = () => {
  // @ts-ignore
  const { applicantId } = useParams()

  const { loading, data } = useQuery(APPLICANT_BY_ID, {
    fetchPolicy: 'network-only', // needed as a cache bust
    variables: { id: applicantId },
  })

  const applicant = data?.applicant || { jobApplications: [], interviews: [] }

  if (loading) return <>loading...</>

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={6}>
        <Typography variant='h6'>
          {applicant.fullName}
          &nbsp;
          <CreateATask targetType='Applicant' targetId={applicant.id} />
        </Typography>

        <Typography variant='subtitle1'>
          <a href={`carbodylab-phone://dial?toNumber=${applicant.phone}&fromNumber=+12132053671`}>
            {prettyPhoneNumber(applicant.phone)}
          </a>
        </Typography>

        <hr />
        <Typography variant='h6'>Job Applications</Typography>
        {applicant?.jobApplications.map((application: JobApplication) => (
          <li key={application.id}>
            <Link to={`/applications/${application.id}`}>
              {application.createdAt && DateTime.fromISO(application.createdAt).toFormat('DD t')}
            </Link>
          </li>
        ))}
        <hr />
        <Typography variant='h6'>Job Interviews</Typography>
        {applicant?.interviews.map((interview: Interview) => (
          <li key={interview.id}>
            <Link to={`/applications/${interview?.jobApplication?.id}/interviews/${interview.id}`}>
              {interview?.startDatetime && DateTime.fromISO(interview.startDatetime).toFormat('DD t')}
            </Link>
          </li>
        ))}
      </Grid>

      <Grid item xs={12} xl={6} />

      <Grid item xs={12}>
        <StreamDump stream={`Applicant$${applicant.id}`} />
      </Grid>
    </Grid>
  )
}
