import { useEffect, useState } from 'react'
import { Dollars, basicDateTimeFormatter, keysToCamel } from 'tools'
import { Popover, TextField, Box, DialogContent, DialogActions, Chip, Dialog } from '@mui/material'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import FilterListIcon from '@mui/icons-material/FilterList'
import SvgIcon from '@mui/material/SvgIcon'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { bToBInvoiceStatementsConnection, DEALERS_QUERY, removeSearch, useStyles } from './tools'
import { SelectBusinessHeadCell } from './BToBInvoiceStatement/Components/SelectBusinessHeadCell'
import { DateRangeHeadCell } from './BToBInvoiceStatement/Components/DateRangeHeadCell'
import { SelectLabelHeadCell } from './BToBInvoiceStatement/Components/SelectLabelHeadCell'

export const AllInvoices = () => {
  const classes = useStyles()
  const parseState = true
  const [search, setSearch] = useLocalStorage('bToBInvoiceStatementsSearchFromDealerPage', {}, parseState)
  const [order, setOrder] = useLocalStorage('bToBInvoiceStatementsOrderFromDealerPage', {}, parseState)
  const [filter, setFilter] = useLocalStorage('bToBInvoiceStatementsFilterFromDealerPage', {}, parseState)
  const [limit, setLimit] = useLocalStorage('bToBInvoiceStatementsLimitFromDealerPage', 50)

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: Number(limit),
        after: '',
      },
    })
  }, [search, order, filter, limit])

  const { loading, error, data, refetch, fetchMore } = useQuery(bToBInvoiceStatementsConnection, {
    variables: {
      search,
      order,
      filter,
      first: Number(limit),
      after: cursorCeiling,
    },
  })

  const { data: dealersData } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const invoices = data.bToBInvoiceStatementsConnection.edges.map(edge => edge.node)
  const totalCount = data.bToBInvoiceStatementsConnection.totalCount
  const endCursor = data.bToBInvoiceStatementsConnection.pageInfo.endCursor

  const dealers = dealersData?.bToBDealers || []

  const SortSearchHeadCell = ({ label, field, searchOnType }) => {
    const [value, setValue] = useState(search[field] ? search[field] : '')
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClickApply = () => {
      if (value === '') {
        removeSearch({ field, setSearch, search })
      } else {
        setSearch({ ...search, [field]: value })
      }
      setAnchorEl(null)
    }
    const handleClearFilter = () => {
      removeSearch({ field, setSearch, search })
      setValue('')
      setAnchorEl(null)
    }
    const handleClickClose = () => setAnchorEl(null)
    const handleChange = e => {
      setValue(e.target.value)
      if (searchOnType) {
        setSearch({ ...search, [field]: value })
      }
    }

    return (
      <TableCell>
        <Box>
          {label}
          <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={e => setAnchorEl(e.currentTarget)}>
            <SvgIcon fontSize='small' component={FilterListIcon} />
          </Button>
        </Box>

        {search[field] && search[field] !== '' && (
          <Chip variant='outlined' size='small' onDelete={handleClearFilter} label={search[field]} />
        )}

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <DialogContent>
            <TextField fullWidth autoFocus size='small' variant='outlined' value={value} onChange={handleChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickClose} color='cancel'>
              Close
            </Button>
            <Button onClick={handleClickApply} color='primary' autoFocus>
              Apply
            </Button>
          </DialogActions>
        </Popover>
      </TableCell>
    )
  }

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: Number(limit),
          after: endCursor,
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='right'>Statement #</TableCell>
              <DateRangeHeadCell
                name='created_at'
                filter={filter}
                setFilter={setFilter}
                order={order}
                setOrder={setOrder}
              />
              <DateRangeHeadCell
                setOrder={setOrder}
                name='completed_at'
                filter={filter}
                setFilter={setFilter}
                order={order}
              />
              <DateRangeHeadCell
                setOrder={setOrder}
                name='fully_paid_at'
                filter={filter}
                setFilter={setFilter}
                order={order}
              />
              <SelectBusinessHeadCell align='right' filter={filter} setFilter={setFilter} dealers={dealers} />
              <TableCell align='right'>Grand Total</TableCell>
              <SelectLabelHeadCell filter={filter} setFilter={setFilter} />
              <TableCell align='right'>Paid Sq. Invoice #</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.slice(page * limit, page * limit + limit).map(invoice => (
              <TableRow key={invoice.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align='right'>{invoice.statementNumber}</TableCell>
                <TableCell>
                  <Link target='_blank' to={`/b-to-b/statements/${invoice.id}`}>
                    {basicDateTimeFormatter(invoice.createdAt)}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link target='_blank' to={`/b-to-b/statements/${invoice.id}`}>
                    {basicDateTimeFormatter(invoice.completedAt)}
                  </Link>
                </TableCell>
                <TableCell>{basicDateTimeFormatter(invoice.fullyPaidAt)}</TableCell>
                <TableCell align='right'>{invoice.businessName}</TableCell>
                <TableCell align='right'>
                  <Dollars value={invoice.grandTotal} />
                </TableCell>
                <TableCell align='right'>{invoice.label && <Chip size='small' label={invoice.label} />}</TableCell>
                <TableCell align='right'>{invoice.paidSquareInvoiceId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}
