import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import {
  Paper,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'

import { SuppliesOrderSummary } from './SuppliesOrderSummary'

const QUERY = gql`
  query suppliesOrdersUnplaced($needsPlaced: Boolean) {
    suppliesOrders(needsPlaced: $needsPlaced) {
      id
      itemIds
      createdAt
      updatedAt

      technician {
        id
        firstName
        lastName
      }
    }
  }
`

const Unplaced = ({ items }) => {
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { needsPlaced: true },
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const handleClickUpdate = () => refetch()

  const formatDateTime = dtString => {
    const dt = DateTime.fromISO(dtString)
    return dt.isValid && dt.toLocaleString(DateTime.DATETIME_FULL)
  }
  const formatFullName = user => user && user.firstName + ' ' + user.lastName

  return (
    <>
      <Button sx={{ float: 'left' }} onClick={handleClickUpdate}>
        update
      </Button>
      <Typography>
        <b>WARNING: </b>
        <>this list does not real time update, please see the tasks page</>
      </Typography>
      <TableContainer component={Paper}>
        <Table size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell align='right'>technician</TableCell>
              <TableCell align='right'>items</TableCell>
              <TableCell align='right'>created_at</TableCell>
              <TableCell align='right'>updated_at</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.suppliesOrders.map(order => (
              <TableRow key={order.id}>
                <TableCell component='th'>
                  <Link to={'/supplies-orders/' + order.id}>{order.id}</Link>
                </TableCell>
                <TableCell align='right'>
                  <Link to={'/technicians/' + order.technician.id}>
                    {formatFullName(order.technician)}
                  </Link>
                </TableCell>
                <TableCell align='right'>
                  <SuppliesOrderSummary items={items} order={order} />
                </TableCell>
                <TableCell align='right'>
                  {formatDateTime(order.createdAt)}
                </TableCell>
                <TableCell align='right'>
                  {formatDateTime(order.updatedAt)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Unplaced
