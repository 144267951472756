export const lookupPrice = ({ lineItems, lineItem, hasAtLeastOneSide = false }) => {
  const foundItem = lineItems.find(
    item =>
      item.panel === lineItem.panel &&
      (hasAtLeastOneSide ? item.side === lineItem.side : true) &&
      item.type === lineItem.type
  )

  const price = foundItem?.price || null
  return price
}

export const availableSideOptionsForLineItem = (lineItem, lineItems) =>
  (lineItem.type && lineItems.find(item => item.type === lineItem.type)?.sides) || []

export const requiresSideSelection = (lineItem, lineItems) => availableSideOptionsForLineItem(lineItem, lineItems).length > 0

export const lineItemIsValid = (lineItem, lineItems) =>
  (requiresSideSelection(lineItem, lineItems) ? lineItem.side : true) && lineItem.panel && lineItem.type && lineItem.price
