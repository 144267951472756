import { Box, Tabs, Tab, Typography } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'

import JobListings from './JobListings'
import JobApplications from './JobApplications'
import Interviews from './Interviews'
import Recruiter from './Recruiter'
import { useLocalStorage } from 'hooks/useLocalStorage'

const recruitingTabs = [
  { key: 'job-listings', label: 'Job Listings' },
  { key: 'applications', label: 'Applications' },
  { key: 'interviews', label: 'Interviews' },
]

const RecruitingHome = () => {
  const params: any = useParams()
  const history = useHistory()

  // @ts-ignore
  const { jobListingId, applicationId, interviewId } = useParams()
  const [page, setPage] = useLocalStorage('job-listings-page', params?.recruitingPage || 'job-listings', false)

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='h4' sx={{ mt: '1rem', mb: '1rem' }}>
        Recruiting
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {!jobListingId && !applicationId && !interviewId && (
          <Tabs
            value={page}
            onChange={(e, newTab) => {
              history?.push(`/recruiting/${newTab}`)
              setPage(newTab)
            }}
          >
            <Tab value={'schedule'} label={'schedule'} />
            {recruitingTabs.map(({ key, label }) => (
              <Tab key={key} value={key} label={label} />
            ))}
          </Tabs>
        )}

        <Box sx={{ padding: '1em 0' }}>
          {page === 'schedule' && <Recruiter />}
          {page === 'job-listings' && <JobListings />}
          {page === 'applications' && <JobApplications />}
          {page === 'interviews' && <Interviews />}
        </Box>
      </Box>
    </Box>
  )
}

export default RecruitingHome
