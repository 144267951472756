import React, { useState, useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Snackbar, Container, Grid, Button, Stack, Typography, TextField } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { basicDateTimeFormat, prettyPhoneNumber } from 'tools'
import { DateTime } from 'luxon'

const optParseUTC = { zone: 'utc' }

const Edit = ({ preLead, refetch }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [user] = useContext(UserContext)
  const history = useHistory()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false)
  }

  const makeNewCustomer = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_customer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...bearerTokenHeaders,
      },
      body: JSON.stringify({
        firstName: preLead.name?.split(' ')[0],
        lastName: preLead.name?.split(' ')[1],
        email: preLead.email,
        phone: preLead.phone.replace('+1', ''),
        zip: preLead.zip,
      }),
    })
      .then(res =>
        res
          .json()
          .then(newRes => newRes.customer && history.push(`/customers/${newRes.customer.id}`))
          .catch(e => console.log(e))
      )
      .catch(e => console.log(e))
  }

  const markFollowupCompleted = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/mark_pre_lead_followup_complete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: user.id,
        session_id: preLead.id,
      }),
    }).then(() => {
      setSnackbarOpen(true)
      refetch()
    })
  }

  if (!preLead) return 'Error: invalid preLead id'

  console.log(preLead)

  return (
    <Container sx={{ mt: '1rem' }}>
      {!preLead.customer && <> NO CUSTOMER FOUND </>}
      <ul style={{ marginBottom: '1rem' }}>
        <li>
          <span> name: </span>
          {preLead.name || 'Not found'}
        </li>
        {preLead.email && (
          <li>
            <span> email: </span>
            {preLead.email || 'Not found'}
          </li>
        )}
        <li>
          <span> phone: </span>
          <a href={`carbodylab-phone://dial?toNumber=${preLead.phone}&fromNumber=+17372527400`}>
            {prettyPhoneNumber(preLead.phone)}
          </a>
        </li>
        <li>
          <span> entered at: </span>
          {DateTime.fromISO(preLead.completedAt).toFormat(basicDateTimeFormat)}
        </li>
        {preLead.followedUpAt && (
          <li>
            <span>followed up at: </span>
            {DateTime.fromISO(preLead.followedUpAt).toFormat(basicDateTimeFormat)}
            <span>
              by: {preLead.followedUpBy?.firstName} {preLead.followedUpBy?.lastName}
            </span>
          </li>
        )}
        {preLead.customer && (
          <li>
            <span>customer: </span>
            <Link to={`/customers/${preLead.customer.id}`}>
              {preLead.customer.firstName} {preLead.customer.lastName}
            </Link>{' '}
          </li>
        )}
        {preLead.customer && (
          <ul>
            <li>
              <span>last message sent at: </span>
              {preLead.customer.lastMessageSentAt
                ? DateTime.fromISO(preLead.customer.lastMessageSentAt).toFormat(basicDateTimeFormat)
                : 'Never'}
            </li>
            <li>
              <span>last message receive at: </span>
              {preLead.customer?.lastMessageReceivedAt
                ? DateTime.fromISO(preLead.customer?.lastMessageReceivedAt).toFormat(basicDateTimeFormat)
                : 'Never'}
            </li>
          </ul>
        )}
        {preLead.customer && preLead.customer.leads?.length > 0 && (
          <>
            <ul>
              <span> leads: </span>
              {preLead.customer.leads.map(lead => (
                <li key={lead.id}>
                  <Link to={`/leads/${lead.id}`}>{DateTime.fromISO(lead.createdAt).toFormat(basicDateTimeFormat)}</Link>
                </li>
              ))}
            </ul>
          </>
        )}
        {preLead.customer && preLead.customer.callbackRequests?.length > 0 && (
          <>
            <span>callback requests: </span>
            <ul>
              {preLead.customer.callbackRequests.map(request => (
                <li key={request.id}>
                  <Link to={`/callback-requests/${request.id}`}>
                    {DateTime.fromISO(request.createdAt).toFormat(basicDateTimeFormat)}
                  </Link>
                </li>
              ))}
            </ul>
          </>
        )}
      </ul>

      {preLead.zip && preLead.name && !preLead.customer && (
        <Button onClick={() => makeNewCustomer()} variant='contained' sx={{ mr: '1rem' }}>
          Create new customer record
        </Button>
      )}

      <Button onClick={() => markFollowupCompleted()} variant='contained'>
        Followup completed
      </Button>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose} message='Changes saved.' />
    </Container>
  )
}

export default Edit
