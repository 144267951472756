import { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import { MultipleChoiceCreator } from './MultipleChoiceCreator'
import { KeyboardArrowUpRounded } from '@mui/icons-material'

const LaborItemRow = ({ data, index, refetch, moveOrderingOfQuestion }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [title, setTitle] = useState(data.title)
  const [description, setDescription] = useState(data.description)
  const [type, setType] = useState(data.type)
  const [options, setOptions] = useState(data.options)
  const [editing, setEditing] = useState(false)
  const [isOptional, setIsOptional] = useState(data.isOptional)

  function onClickEdit() {
    setEditing(data.id)
  }
  function onClickCancel() {
    setEditing('')
  }
  function onClickSave() {
    setEditing('')
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_form_question`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        questionId: data.id,
        updatedAttributes: {
          title,
          description,
          is_optional: isOptional,
          options,
          type: type,
        },
      }),
    })
      .then(res => (res.ok ? refetch() : alert('error')))
      .catch(err => alert(err))
  }
  function onClickDelete() {
    if (window.confirm(`Are you sure you want to delete this Tire Price Markup Bracket ($${data.title})?`))
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_form_question`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          questionId: data.id,
        }),
      })
        .then(res => (res.ok ? refetch() : alert('error')))
        .catch(err => alert(err))
  }

  const isEditing = data.id == editing

  return (
    <Box sx={{ padding: '.5rem' }}>
      <Box sx={{ display: 'flex' }}>
        {index !== 0 && (
          <Button
            onClick={() => moveOrderingOfQuestion({ question: data, action: 'up' })}
            title='move up'
            variant='contained'
            disableElevation
            sx={{
              minHeight: '40px',
              minWidth: '30px',
              mr: '.5rem',
              '& svg': {
                transform: 'scale(2)',
                margin: '0px !important',
                padding: '0rem !important',
                height: '16px',
                width: '16px',
              },
            }}
            size='small'
          >
            <KeyboardArrowUpRounded />
          </Button>
        )}
        <TextField
          disabled={!editing}
          label='TItle'
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder='question title...'
          size='small'
        />
        <TextField
          disabled={!editing}
          label='Description'
          sx={{ margin: '0rem .5rem' }}
          value={description}
          onChange={e => setDescription(e.target.value)}
          placeholder='description'
          size='small'
        />
        <FormControl disabled={!editing} size='small'>
          <InputLabel>Question Type</InputLabel>
          <Select
            disabled={!editing}
            label='question type'
            size='small'
            value={type}
            onChange={e => setType(e.target.value)}
            sx={{ minWidth: '150px' }}
          >
            {[
              'date',
              'number',
              'yes/no',
              'rating',
              'short-answer',
              'long-answer',
              'multiple-choice',
              'multiple-select',
            ].map(question => (
              <MenuItem key={question} value={question}>
                {question?.replace('-', ' ')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControlLabel
          disabled={!editing}
          sx={{ ml: '.5rem' }}
          control={<Switch checked={isOptional} onClick={() => setIsOptional(!isOptional)} />}
          label='Optional'
        />

        {!isEditing && (
          <Button onClick={onClickEdit}>
            <EditIcon />
          </Button>
        )}
        {isEditing && (
          <>
            <Button onClick={onClickSave} sx={{ color: 'green' }}>
              <SaveIcon />
            </Button>
            <Button onClick={onClickCancel} sx={{ color: 'orange' }}>
              <CancelIcon />
            </Button>
            <Button onClick={onClickDelete} sx={{ color: 'red' }}>
              <DeleteIcon />
            </Button>
          </>
        )}
      </Box>

      {isEditing && (type === 'multiple-choice' || type === 'multiple-select') && (
        <Box>
          <MultipleChoiceCreator options={options} setOptions={setOptions} />
        </Box>
      )}
    </Box>
  )
}

export default LaborItemRow
