import React, { useContext, useState } from 'react'
import { Box, Chip, Dialog } from '@mui/material'
import { UserContext } from 'UserStore'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { getUploadParamsForDropzone } from 'tools'

const InsurancePaymentPhotoUploader = ({ payment, refetchQuote }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [open, setOpen] = useState(false)
  const [user] = useContext(UserContext)
  const [uploads, setUploads] = useState([])

  const handleSubmit = files => {
    files.map(item =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_insurance_photo_to_payment`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          paymentId: payment.id,
          photoUrl: item.meta.fileUrl,
        }),
      })
        .catch(e => {
          alert(e.message)
        })
        .then(() => refetchQuote())
    )
    files.forEach(f => {
      setUploads(prev => [...prev, f.meta.fileUrl])
      f.remove()
    })
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ sx: { minWidth: '500px' } }}>
        <Box
          sx={{
            padding: '.5rem 1rem',
            background: 'aliceblue',
            borderBottom: '2px solid #a1cff7',
            fontWeight: 500,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& span': {
              fontSize: '11px',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontSize: '18px' }}>Photos</span>{' '}
          </Box>
          <span style={{ color: '#777' }}>CHECK PAYMENT</span>
        </Box>
        {payment?.photoUrls.length > 0 && (
          <Box
            sx={{
              padding: '1rem',
              display: 'flex',
              justifyContent: 'space-evenly',
              '& img': { cursor: 'pointer', maxHeight: '75px', '&:hover': { border: '1px solid blue' } },
            }}
          >
            {payment?.photoUrls?.map(photoUrl => (
              <img onClick={() => window.open(photoUrl)} src={photoUrl} />
            ))}
          </Box>
        )}
        <Box sx={{ padding: '1rem' }}>
          <Dropzone
            getUploadParams={getUploadParamsForDropzone}
            multiple
            accept='image/jpg,image/jpeg,image/png'
            onSubmit={handleSubmit}
            submitButton
            inputContent='Drop Photos or click to Browse for insurance photos'
          />
          {uploads.length > 0 && (
            <Box>
              <p>successful uploads: {uploads.length}</p>
              <>
                {uploads.map(photoUrl => (
                  <img
                    onClick={() => window.open(photoUrl)}
                    key={photoUrl}
                    style={{
                      maxWidth: '100px',
                      maxHeight: '100px',
                      cursor: 'pointer',
                    }}
                    src={photoUrl}
                  />
                ))}
              </>
            </Box>
          )}
        </Box>
      </Dialog>
      {payment?.photoUrls.length > 0 && (
        <Chip size='small' label={`${payment.photoUrls.length} photo${payment.photoUrls.length !== 1 ? 's' : ''}`} />
      )}

      <button onClick={() => setOpen(true)}>Add photo</button>
    </>
  )
}

export default InsurancePaymentPhotoUploader
