import { gql } from '@apollo/client'

export const FROM_NUMBERS = [
  {
    number: '+13236162688',
    pretty_number: '(323) 616-2688',
    name: 'Technician Dispatch'
  },
  {
    number: '+13214999270',
    name: 'TEST'
  },
  {
    number: '+17372521188',
    pretty_number: '(737) 252-1188',
    name: 'B-to-B'
  },
  {
    number: '+17372528778',
    pretty_number: '(737) 252-8778',
    name: 'Parts Department'
  },
  {
    number: '+17372527400',
    pretty_number: '(737) 252-7400',
    name: 'Driveway Customer Service'
  },
  {
    number: '+12136167900',
    pretty_number: '(213) 616-7900',
    name: 'Carbodylab Customer Service'
  },
  {
    number: '+17372521922',
    pretty_number: '(737) 252-1922',
    name: 'Carparts Support'
  },
  {
    number: '+17372521999',
    pretty_number: '(737) 252-1999',
    name: "Eric's party like it's 1999"
  },
  {
    number: '+14158516620',
    pretty_number: '(415) 851-6620',
    name: 'Customer Sales'
  },
  {
    number: '+14158059800',
    pretty_number: '(415) 805-9800',
    name: 'West Coast Customer Service'
  }
]

export const filterBarButtonPrettyNames = [
  'today',
  'yesterday',
  'this week',
  'last week',
  'two weeks ago',
  'this month',
  'last month',
  'all time'
]

export const globalAveragesTableHeaders = [
  'CSR', 'Total number of calls', 'Average call duration',
  'Average time between calls', 'Average calls per day',
  'Average response time', 'total time on calls']

export const callbackRequestsTableHeaders = [
  'Lead',
  'requested_at',
  'TFR',
  'List of Attempts',
  '# of Attempts',
  'completed_at'
]

export const callRecordsTableHeaders = [
  'Direction',
  'From',
  'To',
  'Start',
  'End',
  'Duration',
  'Gap'
]

export const CALLBACK_REQUESTS = gql`
  query CallbackRequestsConnection(
    $search: JSON
    $order: JSON
    $filter: JSON
    $first: Int
    $after: String
  ) {
    callbackRequestsConnection(
      search: $search
      order: $order
      filter: $filter
      first: $first
      after: $after
    ) {
      edges {
        cursor
        node {
          id
          toNumber
          attempts
          customerReasonOther
          csrReasonOther
          completedAt
          createdAt
          customerName
          source
          zip
          customer {
            id
            firstName
            lastName
            phone
            outboundNumber
          }
          lead {
            id
            name
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const CALL_RECORDS = gql`
  query CallRecordsConnection(
    $search: JSON
    $order: JSON
    $filter: JSON
    $first: Int
    $after: String
  ) {
    callRecordsConnection(
      search: $search
      order: $order
      filter: $filter
      first: $first
      after: $after
    ) {
      edges {
        cursor
        node {
          clientId
          fromNumber
          toNumber
          direction
          startDatetime
          endDatetime
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
export const LEADS = gql`
  query LeadsConnection(
    $search: JSON
    $order: JSON
    $filter: JSON
    $first: Int
    $after: String
  ) {
    leadsConnection(
      search: $search
      order: $order
      filter: $filter
      first: $first
      after: $after
    ) {
      edges {
        cursor
        node {
          source
          createdAt
          browserData
          jobs {
            id
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
