import React from 'react'
import { useQuery, gql } from '@apollo/client'
import {
  useHistory,
  Switch,
  Route,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import { Typography, Tabs, Tab,  Box } from '@mui/material'
import Profile from './Profile'
import Edit from './Edit'
import { keysToCamel } from 'tools'

const QUERY = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      phone
      addressLineOne
      addressLineTwo
      city
      state
      zip
      roles
      status
    }
  }
`

const Router = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { userId } = useParams()

  const handleChangeTab = (_, newValue) => {
    history.push(newValue)
  }

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { id: userId },
  })

  if (loading || !data) return <div>Loading...</div>
  if (error) return <div>Error!</div>

  const user = keysToCamel(data.user)

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='h5'>
          {user.firstName} {user.lastName}
          &nbsp;&nbsp;
        </Typography>
      </Box>

      <Tabs value={window.location.pathname} onChange={handleChangeTab}>
        <Tab value={`/users/${userId}/profile`} label='Profile' />
        <Tab value={`/users/${userId}/edit`} label='Edit' />
      </Tabs>

      <Switch>
        <Route path={`${path}/profile`}>
          <Profile user={user} />
        </Route>
        <Route path={`${path}/edit`}>
          <Edit refetch={refetch} editableUser={user} />
        </Route>
      </Switch>
    </>
  )
}

export default Router
