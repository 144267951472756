import { useEffect, useContext, useState } from 'react'
import { Dollars, basicDateTimeFormatter, keysToCamel } from 'tools'
import { Box, Dialog, Chip, Checkbox, Collapse, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import ChatMessageCount from 'components/ChatMessageCount'
import ShowEditV1 from 'BToB/Invoices/BToBInvoice/ShowEditV1'
import ShowEdit from 'BToB/Invoices/BToBInvoice/ShowEdit'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { UserContext } from 'UserStore'
import { CheckCircleOutlineRounded, DeleteRounded, DownloadRounded } from '@mui/icons-material'
import InvoicePDF from './BToBInvoice/InvoicePDF'
import { PDFDownloadLink } from '@react-pdf/renderer'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { BToBInvoicesConnection, DEALERS_QUERY, useStyles } from './tools'
import { SelectBusinessHeadCell } from './BToBInvoice/Components/SelectBusinessHeadCell'
import { SelectTechnicianHeadCell } from './BToBInvoice/Components/SelectTechnicianHeadCell'
import { DateRangeHeadCell } from './BToBInvoice/Components/DateRangeHeadCell'
import { SelectLabelHeadCell } from './BToBInvoice/Components/SelectLabelHeadCell'
import { VinSearchTextField } from './BToBInvoice/Components/VinSearchTextField'

export const UnAccepted = () => {
  const classes = useStyles()
  const [user] = useContext(UserContext)
  const [modalItemId, setModalItemId] = useState(null)
  const parseState = true
  const [isLoading, setIsLoading] = useState(false)

  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([])
  const [search, setSearch] = useLocalStorage('bToBInvoicesSearchFromUnacceptedPage', {}, parseState)
  const [order, setOrder] = useLocalStorage('bToBInvoicesOrderFromUnacceptedPage', {}, parseState)
  const [filter, setFilter] = useLocalStorage('bToBInvoicesFilterFromUnacceptedPage', {}, parseState)
  const [limit, setLimit] = useLocalStorage('bToBInvoicesLimitFromUnacceptedPage', 50)

  const [startDownloadMultiple, setStartDownloadMultiple] = useState(false)
  const [downloadInvoiceIds, setDownloadInvoiceIds] = useState([])

  const [fontsLoaded, setFontsLoaded] = useState(false)

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  const bearerTokenHeaders = useBearerTokenHeaders()

  useEffect(() => {
    downloadInvoiceIds && downloadInvoiceIds.length === 0 && setStartDownloadMultiple(false)
    downloadInvoiceIds && downloadInvoiceIds.length === 0 && setIsLoading(false)
    downloadInvoiceIds && downloadInvoiceIds.length === 0 && refetch()
  }, [downloadInvoiceIds])

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: Number(limit),
        after: '',
        isCompleted: true,
        isAccepted: false,
        hasPayoutData: false,
      },
    })
  }, [search, order, filter, limit])

  const { loading, error, data, fetchMore, refetch } = useQuery(BToBInvoicesConnection, {
    variables: {
      search,
      order,
      filter,
      first: Number(limit),
      after: cursorCeiling,
      isCompleted: true,
      isAccepted: false,
      hasPayoutData: false,
    },
  })

  const { data: dealersData } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const invoices = data.bToBInvoicesConnection.edges.map(edge => edge.node)
  const totalCount = data.bToBInvoicesConnection.totalCount
  const endCursor = data.bToBInvoicesConnection.pageInfo.endCursor
  const selectedInvoices = invoices.filter(invoice => selectedInvoiceIds.includes(invoice.id))
  const downloadingInvoices = invoices.filter(invoice => downloadInvoiceIds.includes(invoice.id))

  const dealers = dealersData?.bToBDealers || []

  const handleClickAccept = () => {
    setIsLoading(true)
    selectedInvoiceIds.map(id =>
      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/accept_b_to_b_invoice`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: id,
            userId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
        .then(res => setIsLoading(false))
        .then(res => setSelectedInvoiceIds([]))
        .then(res => setTimeout(() => refetch(), 250))
    )
  }

  const acceptWithoutRefetch = () => {
    setIsLoading(true)
    selectedInvoiceIds.map(id =>
      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/accept_b_to_b_invoice`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: id,
            userId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
        .then(res => setIsLoading(false))
        .then(res => setSelectedInvoiceIds([]))
    )
  }

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: limit,
          after: endCursor,
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  const foundInvoiceForModal = modalItemId && invoices.find(invoice => invoice.id === modalItemId)

  return (
    <>
      <Box sx={{ padding: '1rem' }}>
        <VinSearchTextField setSearch={setSearch} search={search} field='vin' />
      </Box>
      <Collapse in={selectedInvoiceIds.length > 0}>
        <Box sx={{ display: 'flex', padding: '1rem', justifyContent: 'flex-end' }}>
          <Box sx={{ mr: 'auto' }}>
            <Button
              onClick={() => {
                setStartDownloadMultiple(true)
                setDownloadInvoiceIds(selectedInvoiceIds)
                setIsLoading(true)
              }}
              size='small'
              variant='outlined'
              sx={{ mr: '1rem', '& svg': { fontSize: '.9rem', mr: '.5rem' } }}
            >
              <DownloadRounded /> Download Selected ({selectedInvoiceIds.length})
            </Button>

            <Button
              onClick={() => {
                setStartDownloadMultiple(true)
                setDownloadInvoiceIds(selectedInvoiceIds)
                setIsLoading(true)
                acceptWithoutRefetch()
              }}
              disableElevation
              size='small'
              variant='contained'
              sx={{ mr: '1rem', '& svg': { fontSize: '.9rem', mr: '.5rem' } }}
            >
              <DownloadRounded /> Download And Accept Selected ({selectedInvoiceIds.length})
            </Button>
          </Box>
          <Button
            disableElevation
            onClick={handleClickAccept}
            size='small'
            variant='contained'
            sx={{ mr: '1rem', '& svg': { fontSize: '.9rem', mr: '.5rem' } }}
          >
            <CheckCircleOutlineRounded /> Accept Selected ({selectedInvoiceIds.length})
          </Button>
          <Button size='small' variant='outlined' sx={{ '& svg': { fontSize: '.9rem', mr: '.5rem' } }}>
            <DeleteRounded /> Delete Selected
          </Button>
        </Box>
      </Collapse>

      {startDownloadMultiple &&
        fontsLoaded &&
        downloadingInvoices.map(invoice => (
          <PDFDownloader setSelectedInvoiceIds={setDownloadInvoiceIds} invoice={invoice} />
        ))}

      {startDownloadMultiple &&
        !fontsLoaded &&
        downloadingInvoices.map(invoice => <PDFDepsDownloader setFontsLoaded={setFontsLoaded} invoice={invoice} />)}

      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            background: '#11111140',
            bottom: '0px',
            width: '100%',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color='warning' />
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='right'></TableCell>
              <DateRangeHeadCell
                name='completed_at'
                filter={filter}
                setFilter={setFilter}
                order={order}
                setOrder={setOrder}
              />

              <SelectTechnicianHeadCell filter={filter} setFilter={setFilter} />
              <SelectBusinessHeadCell filter={filter} setFilter={setFilter} dealers={dealers} />
              <TableCell align='right'>VIN #</TableCell>
              <TableCell align='right'>RO #</TableCell>
              <TableCell align='right'>Invoice Total</TableCell>
              <TableCell align='right'>Projected Payout</TableCell>
              <TableCell align='right'>Invoice #</TableCell>
              <TableCell align='right'># Items </TableCell>
              <TableCell align='right'>Info</TableCell>
              <TableCell align='right'>Notes</TableCell>
              <SelectLabelHeadCell filter={filter} setFilter={setFilter} />
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.slice(page * limit, page * limit + limit).map(invoice => (
              <Row
                refetch={refetch}
                setModalItemId={setModalItemId}
                invoice={invoice}
                setSelectedInvoiceIds={setSelectedInvoiceIds}
                selectedInvoiceIds={selectedInvoiceIds}
              />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog PaperProps={{ sx: { maxWidth: '100%' } }} open={modalItemId} onClose={() => setModalItemId(null)}>
        <Box>
          {foundInvoiceForModal &&
            (foundInvoiceForModal.invoiceVersion > 1 ? (
              <ShowEdit refetch={refetch} invoice={keysToCamel(invoices.find(invoice => invoice.id === modalItemId))} />
            ) : (
              <ShowEditV1
                refetch={refetch}
                invoice={keysToCamel(invoices.find(invoice => invoice.id === modalItemId))}
              />
            ))}
        </Box>
      </Dialog>
    </>
  )
}

const PDFDepsDownloader = ({ setFontsLoaded, invoice }) => {
  const [completedLoading, setCompletedLoading] = useState(false)

  useEffect(() => {
    completedLoading && setTimeout(() => setFontsLoaded(true), 1000)
  }, [completedLoading])

  return (
    <PDFDownloadLink
      style={{ color: 'unset', textDecoration: 'none' }}
      document={<InvoicePDF invoice={invoice} />}
      fileName={`${invoice.id}.pdf`}
    >
      {({ blob, url, loading, error }) => {
        if (error) return JSON.stringify(error)
        blob && setCompletedLoading(true)
      }}
    </PDFDownloadLink>
  )
}

const PDFDownloader = ({ invoice, setSelectedInvoiceIds }) => {
  const [completedFirstDownload, setCompletedFirstDownload] = useState(false)
  const [fileBlob, setFileBlob] = useState(null)

  const handleDownload = blob => {
    if ((!completedFirstDownload && blob) || (!blob && fileBlob && completedFirstDownload)) {
      const data = window.URL.createObjectURL(blob || fileBlob)
      const link = document.createElement('a')
      link.href = data
      link.download = `${DateTime.fromISO(invoice.createdAt).toFormat('MM-dd-yyyy')}-${invoice.make || ''}-${
        invoice.model || ''
      }-${invoice.year || ''}`

      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: false,
          cancelable: true,
          view: window,
        })
      )
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
        link.remove()
        setSelectedInvoiceIds(prev => prev.filter(i => i !== invoice.id))
      }, 100)
    }
  }

  useEffect(() => {
    completedFirstDownload && handleDownload()
  }, [completedFirstDownload])

  return (
    <PDFDownloadLink
      style={{ color: 'unset', textDecoration: 'none' }}
      document={<InvoicePDF invoice={invoice} />}
      fileName={`${invoice.id}.pdf`}
    >
      {({ blob, url, loading, error }) => {
        if (error) return JSON.stringify(error)
        blob && setFileBlob(blob)
        blob && setCompletedFirstDownload(true)
      }}
    </PDFDownloadLink>
  )
}

const Row = ({ invoice, setSelectedInvoiceIds, selectedInvoiceIds, setModalItemId, refetch }) => {
  const [user] = useContext(UserContext)
  const [showDownloadLink, setShowDownloadLink] = useState(false)
  const [completedFirstDownload, setCompletedFirstDownload] = useState(false)
  const [fileBlob, setFileBlob] = useState(null)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const handleClickAccept = () => {
    window
      .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/accept_b_to_b_invoice`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBInvoiceId: invoice.id,
          userId: user.id,
        }),
      })
      .then(res => res.ok || window.alert('Error'))
      .then(res => refetch())
  }

  const handleDownload = blob => {
    if ((!completedFirstDownload && blob) || (!blob && fileBlob && completedFirstDownload)) {
      const data = window.URL.createObjectURL(blob || fileBlob)
      const link = document.createElement('a')
      link.href = data
      link.download = `${DateTime.fromISO(invoice.createdAt).toFormat('MM-dd-yyyy')}-${invoice.make || ''}-${
        invoice.model || ''
      }-${invoice.year || ''}`

      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: false,
          cancelable: true,
          view: window,
        })
      )

      setTimeout(() => {
        window.URL.revokeObjectURL(data)
        link.remove()
      }, 100)
    }
  }

  useEffect(() => {
    completedFirstDownload && handleDownload()
  }, [completedFirstDownload])

  return (
    <TableRow key={invoice.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell>
        <Checkbox
          onChange={() =>
            selectedInvoiceIds.includes(invoice.id)
              ? setSelectedInvoiceIds(prev => prev.filter(id => id !== invoice.id))
              : setSelectedInvoiceIds(prev => [...prev, invoice.id])
          }
          checked={selectedInvoiceIds.includes(invoice.id)}
        />
      </TableCell>
      <TableCell>
        <Link target='_blank' to={`/b-to-b-invoices/${invoice.id}`}>
          {basicDateTimeFormatter(invoice.completedAt)}
        </Link>
      </TableCell>
      <TableCell align='right'>
        <Link target='_blank' to={`/technicians/${invoice.technician.id}`}>
          {invoice.technician.firstName}
          &nbsp;
          {invoice.technician.lastName}
        </Link>
      </TableCell>
      <TableCell align='right'>{invoice.businessName}</TableCell>
      <TableCell align='right'>{invoice.vin}</TableCell>
      <TableCell align='right'>{invoice.repairOrder}</TableCell>
      <TableCell align='right'>
        <b>
          <Dollars value={invoice.grandTotal || invoice.lineItemsSum} />
        </b>
        &nbsp;
        <button onClick={() => setModalItemId(invoice.id)}>show</button>
      </TableCell>
      <TableCell align='right'>
        <b>
          <Dollars value={invoice.projectedPayout} />
        </b>
      </TableCell>
      <TableCell align='right'>{invoice.invoiceNumber}</TableCell>
      <TableCell align='right'>{invoice.lineItems.length}</TableCell>
      <TableCell align='right'>
        {invoice.year}, {invoice.make} {invoice.model}
      </TableCell>
      <TableCell align='right'>
        <ChatMessageCount contextType='BToBInvoice' contextId={invoice.id} />
      </TableCell>
      <TableCell align='right'>{invoice.label && <Chip size='small' label={invoice.label} />}</TableCell>
      <TableCell>
        <button onClick={handleClickAccept}>accept</button>
      </TableCell>
      <TableCell align='right'>
        <Button
          onClick={() => (completedFirstDownload ? handleDownload() : setShowDownloadLink(true))}
          size='small'
          title='download PDF'
          sx={{ minWidth: '30px', '& svg': { fontSize: '1rem' } }}
        >
          {!showDownloadLink && <DownloadRounded />}
          {showDownloadLink && (
            <PDFDownloadLink
              style={{ color: 'unset', textDecoration: 'none' }}
              document={<InvoicePDF invoice={invoice} />}
              fileName={`${invoice.id}.pdf`}
              id={invoice.id}
            >
              {({ blob, url, loading, error }) => {
                if (error) return JSON.stringify(error)
                blob && setFileBlob(blob)
                blob && setCompletedFirstDownload(true)
                blob && setShowDownloadLink(false)
                return loading ? '...' : <DownloadRounded />
              }}
            </PDFDownloadLink>
          )}
        </Button>
      </TableCell>
    </TableRow>
  )
}
