import {
  Typography,
  Container,
  Grid,
  FormControl,
  Button,
  TextField,
  Box,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material'
import { DownloadRounded, LaunchRounded } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useQuery, gql } from '@apollo/client'
import { useEffect, useState, useContext } from 'react'
import { DateTime } from 'luxon'
import Chat from 'Chat'
import { Link } from 'react-router-dom'
import { UserContext } from 'UserStore'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { CreateATask } from 'Tasks'
import { PDFDownloadLink } from '@react-pdf/renderer'
import InvoicePDF from './InvoicePDF'
import { VehiclePicker } from './Components/VehiclePicker'
import { PreJobPhotos } from './Components/PreJobPhotos'
import AddNewPart from './Components/AddNewPart'
import PartsTable from './Components/PartsTable'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const styles = {
  photoExpandStyle: {
    border: '1px solid #ddd',
    fontSize: '14px',
    borderRadius: '0px 0px 6px 6px',
    display: 'flex',
    padding: '0.25rem 0.5rem',
    background: '#f1f1f1',
    justifyContent: 'center',
    alignItems: 'center',
  },
  photoCancelButtonStyle: {
    zIndex: 999,
    position: 'absolute',
    top: '5px',
    right: '5px',
    fontSize: '30px',
    color: '#fff',
    border: '2px solid #fff',
    borderRadius: '20px',
  },
  sectionHeaderStyle: {
    borderRadius: '6px 6px 0px 0px',
    fontWeight: 700,
    fontSize: '14px',
    padding: '0.5rem 1rem',
    background: '#bbc7d3',
    display: 'flex',
    alignItems: 'center',
  },
  sectionContainerStyle: {
    mb: '0.5rem',
    mt: '0.5rem',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '6px',
    maxWidth: '100%',
  },
  photoContainerStyle: {
    background: '#fff',
    padding: '1rem 0.5rem',
    border: '1px solid #ddd',
    borderBottom: '0',
    borderTop: '0',
    textAlign: 'center',
  },
  uploadButtonContainerStyle: {
    border: '1px solid #ddd',
    fontSize: '14px',
    borderRadius: '0px 0px 6px 6px',
    display: 'flex',
    padding: '0.25rem 0.5rem',
    background: '#f1f1f1',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const ShowEdit = ({ workOrder, refetch }) => {
  const [user] = useContext(UserContext)
  const [updateQuoteClickGate, setUpdateQuoteClickGate] = useState(true)
  const [currentPhotoId, setCurrentPhotoId] = useState('')
  const [editingMutex, setEditingMutex] = useState('')
  const workOrderFilename = DateTime.fromJSDate(new Date(workOrder.createdAt)).toFormat('yyyy-LL-dd') + '-payout.pdf'
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)
  const bearerTokenHeaders = useBearerTokenHeaders()

  useEffect(() => {
    const key = registerTaskTarget({
      targetType: 'BToBWorkOrder',
      targetId: workOrder.id,
    })
    return () => unregisterTaskTarget(key)
  }, [])

  const lockedToCsr = workOrder.payoutData

  const handleClickDeleteInvoice = () =>
    window.confirm('Please confirm') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_work_order`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBWorkOrderId: workOrder.id,
        userId: user.id,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const Stock = () => {
    const [editableStock, setEditableStock] = useState(workOrder.stock)
    const [waiting, setWaiting] = useState(false)

    const handleChangeStock = e => setEditableStock(e.target.value)

    const handleClickSaveStock = () => {
      setWaiting(true)

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_work_order_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBWorkOrderId: workOrder.id,
          updatedAttributes: { stock: editableStock.trim() },
          actorType: 'csr',
          actorId: user.id,
        }),
      })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl size='small'>
          <TextField
            value={editableStock}
            label='Stock'
            disabled={!!lockedToCsr}
            onChange={handleChangeStock}
            size='small'
          />
        </FormControl>
        <Button
          disabled={waiting || editableStock === null || editableStock.trim() === workOrder.stock}
          onClick={handleClickSaveStock}
        >
          Save
        </Button>
      </Grid>
    )
  }

  const RepairOrder = () => {
    const [editableRepairOrder, setEditableRepairOrder] = useState(workOrder.repairOrder)
    const [waiting, setWaiting] = useState(false)

    const handleChangeRepairOrder = e => setEditableRepairOrder(e.target.value)

    const handleClickSaveRepairOrder = () => {
      setWaiting(true)

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_work_order_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBWorkOrderId: workOrder.id,
          updatedAttributes: { repairOrder: editableRepairOrder.trim() },
          actorType: 'csr',
          actorId: user.id,
        }),
      })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl size='small'>
          <TextField
            value={editableRepairOrder}
            label='RO'
            disabled={!!lockedToCsr}
            onChange={handleChangeRepairOrder}
            size='small'
          />
        </FormControl>
        <Button
          disabled={waiting || editableRepairOrder === null || editableRepairOrder.trim() === workOrder.repairOrder}
          onClick={handleClickSaveRepairOrder}
        >
          Save
        </Button>
      </Grid>
    )
  }

  const Vin = () => {
    const [editableVin, setEditableVin] = useState(workOrder.vin)
    const [waiting, setWaiting] = useState(false)

    const handleChangeVin = e => setEditableVin(e.target.value)

    const handleClickSaveVin = () => {
      setWaiting(true)

      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_work_order_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBWorkOrderId: workOrder.id,
            updatedAttributes: { vin: editableVin.trim() },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl size='small'>
          <TextField value={editableVin} label='VIN' disabled={!!lockedToCsr} onChange={handleChangeVin} size='small' />
        </FormControl>
        <Button
          disabled={waiting || editableVin === null || editableVin.trim() === workOrder.vin}
          onClick={handleClickSaveVin}
        >
          Save
        </Button>
      </Grid>
    )
  }

  const PartProcurement = () => {
    const [editablePurchasingCompany, setEditablePurchasingCompany] = useState(workOrder.purchasingCompany || '')
    const [waiting, setWaiting] = useState(false)

    const handleClickSaveVin = () => {
      setWaiting(true)

      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_work_order_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBWorkOrderId: workOrder.id,
            updatedAttributes: { purchasingCompany: editablePurchasingCompany.trim() },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl fullWidth size='small'>
          <InputLabel>Who will purchase the parts?</InputLabel>
          <Select
            value={editablePurchasingCompany}
            onChange={e => setEditablePurchasingCompany(e.target.value)}
            size='small'
            label='Who will purchase the parts?'
          >
            <MenuItem value='carbodylab'>Us</MenuItem>
            <MenuItem value={workOrder.businessName}>{workOrder.businessName}</MenuItem>
          </Select>
        </FormControl>
        <Button
          disabled={
            waiting ||
            editablePurchasingCompany === null ||
            editablePurchasingCompany.trim() === workOrder.purchasingCompany
          }
          onClick={handleClickSaveVin}
        >
          Save
        </Button>
      </Grid>
    )
  }

  return (
    <Container maxWidth='sm'>
      <Grid container>
        {workOrder.bToBInvoiceId && (
          <Grid item xs={12}>
            <Link
              to={`/b-to-b-invoices/${workOrder.bToBInvoiceId}`}
              style={{
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#111',
                backgroundColor: 'palegreen',
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Open Invoice <LaunchRounded />
            </Link>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '.5rem' }}>
            <Typography variant='h6' sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
              {workOrder.businessName} Work Order
            </Typography>
            <Button
              disableElevation
              variant='contained'
              // sx={{ padding: '.25rem .5rem' }}
            >
              <DownloadRounded sx={{ mr: '.25rem' }} />
              <PDFDownloadLink
                style={{ color: 'unset', textDecoration: 'none' }}
                document={<InvoicePDF workOrder={workOrder} />}
                fileName={workOrderFilename}
              >
                {({ blob, url, loading, error }) => {
                  if (error) return JSON.stringify(error)
                  return loading ? 'Generating PDF...' : 'Download as PDF'
                }}
              </PDFDownloadLink>
            </Button>
          </Box>
          <Link target='_blank' to={`/technicians/${workOrder.technician.id}`}>
            {workOrder.technician.firstName} {workOrder.technician.lastName}
          </Link>
          &nbsp;&nbsp;
          <span style={{ opacity: 0.75, fontSize: '14px' }}>
            Created:{' '}
            <Link to={`/b-to-b/work-orders/${workOrder.id}`}>
              {DateTime.fromISO(workOrder.createdAt).toLocaleString()}
            </Link>
          </span>
        </Grid>

        {workOrder.completedAt && (
          <Grid item xs={12}>
            <b
              style={{
                display: 'block',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#111',
                backgroundColor: 'palegreen',
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
              }}
            >
              SUBMITTED {DateTime.fromISO(workOrder.completedAt).toFormat('D hh:mm')}
            </b>
          </Grid>
        )}
      </Grid>

      <VehiclePicker workOrder={workOrder} refetch={refetch} />
      <Vin />
      <RepairOrder />
      {workOrder.businessName === 'carmax' && <Stock />}
      <PreJobPhotos workOrder={workOrder} />

      <Grid item xs={12}>
        <Grid container sx={{ border: '1px solid #ddd', borderRadius: '6px', background: '#fff', mt: '1rem' }}>
          <Grid
            item
            xs={12}
            sx={{
              borderRadius: '6px 6px 0px 0px',
              fontWeight: 700,
              fontSize: '14px',
              padding: '.5rem 1rem',
              display: 'flex',
              alignItems: 'center',
              background: '#b5d9ff',
            }}
          >
            Add Parts
          </Grid>
          <Grid item xs={12}>
            <AddNewPart
              workOrder={workOrder}
              editingMutex={editingMutex}
              setEditingMutex={setEditingMutex}
              editingDisabled={!updateQuoteClickGate}
              currentPhotoId={currentPhotoId}
              refetch={refetch}
            />
          </Grid>
          <Grid item xs={12}>
            <PartsTable
              refetch={refetch}
              workOrder={workOrder}
              parts={workOrder.parts}
              editingDisabled={!updateQuoteClickGate || editingMutex === 'add-part'}
              setEditingMutex={setEditingMutex}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container sx={{ border: '1px solid #ddd', borderRadius: '6px', background: '#fff', mt: '1rem' }}>
          <Grid
            item
            xs={12}
            sx={{
              borderRadius: '6px 6px 0px 0px',
              fontWeight: 700,
              fontSize: '14px',
              padding: '.5rem 1rem',
              display: 'flex',
              alignItems: 'center',
              background: '#b5d9ff',
            }}
          >
            Part Procurement
          </Grid>

          <Grid item xs={12} sx={{ padding: '1rem' }}>
            <PartProcurement />
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={{ ...styles.sectionContainerStyle, border: '1px solid #ddd' }}>
        <Grid item xs={12} sx={styles.sectionHeaderStyle}>
          Chat
        </Grid>
        <Grid item xs={12} sx={{ padding: '0.5rem' }}>
          <Chat contextId={workOrder.id} contextType='BToBWorkOrder' />
        </Grid>
      </Grid>

      <Button
        disabled={workOrder.payoutId || workOrder.deletedAt}
        variant='outlined'
        color='error'
        onClick={handleClickDeleteInvoice}
        startIcon={<DeleteIcon />}
      >
        Delete{workOrder.deletedAt && 'd'}
      </Button>

      <CreateATask targetType='BToBInvoice' targetId={workOrder.id} />
    </Container>
  )
}

export default ShowEdit
