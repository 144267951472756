export const techSkillsets = [
  'level 1',
  'level 2',
  'level 3',
  'level 4',
  '5-star technician',
  '3-stage color',
  'aluminum',
  'white paint',
  'red candy paint',
  'part replacements',
  'tire replacements',
  'autoglass work',
  'tesla parts',
  'PDR'
]
