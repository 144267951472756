import { Box, Typography, Button, Breadcrumbs, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'

import { useJobApplication } from 'hooks/recruiting/jobApplications/useJobApplication'
import StreamDump from 'components/StreamDump'
import Notes from 'components/Notes'
import { TLD } from 'tools'
import { FormAnswers } from './components/FormAnswers'

const Show = ({ jobApplicationId }: { jobApplicationId: string }) => {
  const { jobApplication, reject, markForInterview, markAsPending } = useJobApplication(jobApplicationId)

  if (!jobApplication) return null

  const breadCrumbs = [
    <Link key='listing' to={`/job-listings/${jobApplication?.jobListing?.id || ''}`}>
      Job listing
    </Link>,

    <Typography key='application'> Application </Typography>,
  ]

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '.5rem 0rem' }}>
        <Breadcrumbs sx={{ display: 'block', padding: '.5rem' }} separator='›'>
          {breadCrumbs}
        </Breadcrumbs>
        <Button
          onClick={() => window.open(`https://www.carbodylab.${TLD}/applications/${jobApplication?.id}`)}
          variant='outlined'
          size='small'
          sx={{ maxHeight: '30px' }}
        >
          Open Applicant Submission Page
        </Button>
      </Box>

      <Box>
        <FormAnswers
          creatorId={jobApplication?.applicant?.id}
          contextId={jobApplication?.id}
          contextType='JobApplication'
          formContextType='JobListing'
          formContextId={jobApplication?.jobListingId}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 1, padding: 1 }}>
        <Button
          onClick={reject}
          disabled={!!jobApplication?.rejectedAt || !!jobApplication?.markedForInterviewAt}
          variant='contained'
        >
          Reject
        </Button>
        <Button
          onClick={markForInterview}
          disabled={!!jobApplication?.rejectedAt || !!jobApplication?.markedForInterviewAt}
          variant='contained'
        >
          Mark for Interview
        </Button>
        <Button
          onClick={markAsPending}
          disabled={!jobApplication?.rejectedAt && !jobApplication?.markedForInterviewAt}
          variant='contained'
        >
          Mark as Pending
        </Button>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant='h6'>Interviews</Typography>

        <Box sx={{ mt: 2 }}>
          <ul>
            {jobApplication?.interviews?.map(({ id, recruiter, startDatetime }) => (
              <li key={id}>
                <Link to={`/applications/${jobApplication?.id}/interviews/${id}`}>
                  {recruiter?.firstName} {recruiter?.lastName} -{' '}
                  {startDatetime !== null &&
                    // @ts-ignore
                    DateTime.fromISO(startDatetime || DateTime.now().toISO()).toFormat('DD t')}
                </Link>
              </li>
            ))}
          </ul>
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <Notes
            noteContainerStyle={{
              background: '#fff',
            }}
            descendentsOnly={false}
            headerText='Notes for this Application'
            objectType='application'
            objectId={jobApplication?.id}
          />
        </Grid>
      </Grid>

      <StreamDump stream={`JobApplication$${jobApplication?.id}`} />
    </Box>
  )
}

export default Show
