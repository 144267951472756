import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import { UserContext } from 'UserStore'
import { GlobalContext } from 'GlobalStore'
import Typography from '@mui/material/Typography'
import { MentionsInput, Mention } from 'react-mentions'
import mentionsClassNames from './mentions.module.css'
import { DateTime } from 'luxon'

const NOTES_QUERY = gql`
  query notes($parentId: ID!, $parentType: String!, $descendentsOnly: Boolean) {
    notes(parentId: $parentId, parentType: $parentType, descendentsOnly: $descendentsOnly) {
      id
      userType
      userId
      parentType
      parentId
      createdAt
      body
      replyToId
      mentionedUserIds
      resourcePath
    }
  }
`

const NotesStyles = makeStyles((theme) => ({
  notes: {
    margin: '1em'
  },
  note_user: {
    marginRight: '1em'
  },
  new_note_input: {
    padding: '0.75em'
  }
}))

const mentionsRegex = /@\[[a-z,A-Z,\s\.\@]+\]\([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\)/mg
const mentionIdRegex = /[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/

const mentionedIdsInString = string => {
  const matches = string.match(mentionsRegex)
  if (matches) {
    return matches.map(matchedString => {
      const match = matchedString.match(mentionIdRegex)
      if (match && match.length > 0) {
        return match[0]
      } else {
        return null
      }
    })
  } else {
    return []
  }
}

const mentionableUsers = users => users.map(user => ({ id: user.id, display: `${user.firstName} ${user.lastName.charAt(0)}.` }))

const DescendentNotes = ({ objectType, objectId }) => {
  const classes = NotesStyles()
  const [user] = useContext(UserContext)
  const [newNote, setNewNote] = useState('')
  const [mentionedUserIds, setMentionedUserIds] = useState([])

  useEffect(() => {
    setMentionedUserIds(mentionedIdsInString(newNote))
  }, [newNote])

  const { loading, error, data, refetch } = useQuery(NOTES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      parentId: objectId,
      parentType: objectType,
      descendentsOnly: true
    }
  })

  if (loading) return <div>Loading</div>
  if (error) return <div>Error</div>

  if (data.notes.length == 0) { return null }

  const handleClickSubmitNote = () => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_COMMAND_ROOT + '/api/create_note',
      data: {
        user_id: user.id,
        user_type: 'csr',
        parent_type: objectType,
        parent_id: objectId,
        body: newNote,
        mentioned_user_ids: mentionedUserIds
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD
      }
    })
      .then(res => {
        setNewNote('')
        refetch()
      })
      .catch(error => {
        window.alert('error')
      })
  }

  const handleNewNoteChanged = evt => {
    setNewNote(evt.target.value)
  }

  return (
    <>
      <Typography color='primary' variant='h6'>
        Descendent Notes
      </Typography>
      <div className={classes.notes}>
        {data.notes.map(note =>
          <CurrentNote key={note.id} note={note} objectType={objectType} objectId={objectId} />
        )}
      </div>

    </>
  )
}

const CurrentNoteStyles = makeStyles((theme) => ({
  user_and_note: {
    flexGrow: 1,
    width: '100%',
    marginBottom: '1.5em'
  },
  note_user: {
    marginRight: '1em'
  },
  reply: {
    paddingLeft: '3em'
  },
  current_note: {
    width: '100%',
    height: 'auto'
  }
}))
const CurrentNote = ({ note, objectType, objectId, refetch, descendentsOnly }) => {
  const classes = CurrentNoteStyles()
  const [clickedReply, setClickedReply] = useState(false)
  const [clickedReplyAll, setClickedReplyAll] = useState(false)
  const [global] = useContext(GlobalContext)
  const [user] = useContext(UserContext)

  const handleClickCancel = () => {
    setClickedReply(false)
    setClickedReplyAll(false)
  }

  const fromUser =
    user.id === note.userId && { firstName: 'me', lastName: '' } ||
    global.users.find(user => user.id === note.userId) || { firstName: 'unknown', lastName: '' }

  return (
    <>
      <div className={classes.user_and_note}>
        <div className={classes.note_user}>
          <b>{`${fromUser.firstName} ${fromUser.lastName}`}</b>
          <small>
            {DateTime.fromISO(note.createdAt).toFormat('yyyy-LL-dd h:mm a ZZZZ')}
          </small>
        &nbsp;
          <small>
            <Link to={note.resourcePath}>on the {note.parentType}</Link>
          </small>
        </div>

        <div className={classes.note_and_reply_button}>
          <MentionsInput
            className='mentions'
            classNames={mentionsClassNames}
            value={note.body}
          >
            <Mention
              displayTransform={(id, display) => `@${display}`}
              style={{ backgroundColor: 'rgb(215 255 170)' }}
              trigger='@' data={mentionableUsers(global.users)}
            />
          </MentionsInput>
        </div>
      </div>
    </>
  )
}

export default DescendentNotes
