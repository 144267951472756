import { Box, CircularProgress, Dialog, FormControlLabel, InputAdornment, Radio } from '@mui/material'
import { useEffect, useState } from 'react'
import { Grid, Button, TextField } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

export const ChangeCommissionDialog = ({
  commissionMenuOpen,
  setCommissionMenuOpen,
  workLog,
  job,
  refetch,
  worklogPayoutData,
}) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [hourlyRateSetting, setHourlyRateSetting] = useState((worklogPayoutData || workLog.payoutData).hourlyType)
  const [commissionSetting, setCommissionSetting] = useState((worklogPayoutData || workLog.payoutData).commissionType)
  const [customCommissionAmount, setCustomCommissionAmount] = useState(
    (worklogPayoutData || workLog.payoutData).commissionAmount
  )
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    workLog && setHourlyRateSetting((worklogPayoutData || workLog.payoutData).hourlyType)
    workLog && setCommissionSetting((worklogPayoutData || workLog.payoutData).commissionType)
    workLog && setCustomCommissionAmount((worklogPayoutData || workLog.payoutData).commissionAmount)
  }, [workLog])

  const hourlyRateNeedsSaving = hourlyRateSetting !== (worklogPayoutData || workLog.payoutData).hourlyType
  const commissionNeedsSaving =
    commissionSetting !== (worklogPayoutData || workLog.payoutData).commissionType ||
    customCommissionAmount !== workLog?.payoutData?.commissionAmount

  const handleSave = () => {
    return new Promise((resolve, reject) => {
      setWaiting(true)
      if (commissionNeedsSaving) {
        window
          .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/apply_new_commission_rate`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              worklog_id: workLog.id,
              type: commissionSetting,
              amount: customCommissionAmount,
            }),
          })
          .then(response => {
            if (response.ok) {
              setTimeout(() => refetch())
              resolve(null)
            } else {
              resolve(null)
              throw Error('error')
            }
          })
          .catch(err => {
            window.alert(err)
          })
      } else {
        resolve(null)
      }
    }).then(res => {
      if (hourlyRateNeedsSaving) {
        window
          .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/apply_new_hourly_rate`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              worklog_id: workLog.id,
              type: hourlyRateSetting,
            }),
          })
          .then(response => {
            if (response.ok) {
              setTimeout(() => refetch())
              setWaiting(false)
            } else {
              throw Error('error')
            }
          })
          .catch(err => {
            window.alert(err)
          })
      } else {
        setTimeout(() => refetch())
        setWaiting(false)
      }
    })
  }

  const disableCommissionSettings = (worklogPayoutData || workLog.payoutData).commissionType === 'None'

  return (
    <Dialog
      PaperProps={{ sx: { minWidth: '600px' } }}
      open={commissionMenuOpen}
      onClose={() => setCommissionMenuOpen(!commissionMenuOpen)}
    >
      {waiting && (
        <Box
          sx={{
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#11111140',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
          }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      )}

      <Box
        sx={{
          padding: '.5rem 1rem',
          background: 'aliceblue',
          borderBottom: '2px solid #a1cff7',
          fontWeight: 500,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& span': {
            fontSize: '11px',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontSize: '18px' }}>Payment settings</span>{' '}
        </Box>
        <span style={{ color: '#777' }}>UPDATE PAYMENT SETTINGS</span>
      </Box>
      <Grid item xs={12} sx={{ padding: '1rem' }}>
        {!job?.quote?.fullyPaidAt && <b>Quote not fully paid yet* </b>}
        <Grid container sx={{ mt: job?.quote?.fullyPaidAt ? '0rem' : '1rem', '& b': { fontSize: '13px' } }}>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', '& b': { minWidth: '120px' } }}>
                  <b>Hourly rate based on</b>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel
                    control={
                      <Radio
                        onChange={_ => setHourlyRateSetting('Worklog')}
                        checked={hourlyRateSetting === 'Worklog'}
                      />
                    }
                    label={`Worked time (${workLog.totalHours} hr)`}
                  />
                  <FormControlLabel
                    control={
                      <Radio onChange={_ => setHourlyRateSetting('Job')} checked={hourlyRateSetting === 'Job'} />
                    }
                    label={`Scheduled time (${job.scheduledLength} hr)`}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        onChange={_ => setHourlyRateSetting('Withheld')}
                        checked={hourlyRateSetting === 'Withheld'}
                      />
                    }
                    label='Withold to $0'
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <b>Commission amount based on </b>{' '}
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={disableCommissionSettings}
                        onChange={() => setCommissionSetting('Worklog')}
                        checked={commissionSetting === 'Worklog'}
                      />
                    }
                    label={`Worked time (${workLog.totalHours} hr)`}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={disableCommissionSettings}
                        onChange={() => setCommissionSetting('Job')}
                        checked={commissionSetting === 'Job'}
                      />
                    }
                    z
                    label={`Scheduled time (${job.scheduledLength} hr)`}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={disableCommissionSettings}
                        onChange={() => setCommissionSetting('Withheld')}
                        checked={commissionSetting === 'Withheld'}
                      />
                    }
                    label={`Withhold to $0`}
                  />
                  <Box>
                    <FormControlLabel
                      control={
                        <Radio
                          disabled={disableCommissionSettings}
                          onChange={() => setCommissionSetting('Custom')}
                          checked={commissionSetting === 'Custom'}
                        />
                      }
                      label={`Custom`}
                    />
                    <TextField
                      hiddenLabel
                      variant='filled'
                      disabled={commissionSetting !== 'Custom' || disableCommissionSettings}
                      size='small'
                      type='number'
                      sx={{ maxWidth: '160px' }}
                      value={customCommissionAmount}
                      onChange={e => setCustomCommissionAmount(e.target.value)}
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      }}
                    />
                  </Box>
                </Box>
                {job?.quote?.payoutId && (
                  <b style={{ marginTop: '1rem', display: 'inline-block', color: 'red' }}>
                    This quote's commission has already been paid to the technician
                  </b>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex', padding: '1rem 0rem 0rem 0rem' }}>
          <Button onClick={() => setCommissionMenuOpen(!commissionMenuOpen)}>Cancel</Button>
          <Button onClick={handleSave} disabled={!commissionNeedsSaving && !hourlyRateNeedsSaving}>
            OK
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}
