import { gql } from '@apollo/client'
import { JobApplication } from '../useJobApplications/query'

export interface GetJobApplicationReq {
  id?: string
}

export interface GetJobApplicationRes {
  jobApplication?: JobApplication
}

export const GET_JOB_APPLICATION = gql`
  query getJobApplication($id: ID!) {
    jobApplication(id: $id) {
      id
      jobListingId
      applicantId
      coverLetter
      resumeUrl
      createdAt
      rejectedAt
      markedForInterviewAt
      rejectionReason
      withdrewAt
      jobListing {
        id
        jobTitle
      }
      applicant {
        id
        fullName
        phone
        email
      }
      interviews {
        id
        startDatetime
        recruiter {
          id
          firstName
          lastName
        }
      }
    }
  }
`
