import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'

import { Grid } from '@mui/material'
import InlinePartEditorGrid from './InlinePartEditorGrid'

const useStyles = makeStyles(theme => ({
  partGridHeader: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderWidth: '1px 0',
  },
}))

const PartsTable = ({ parts, quote, lead, setEditingMutex, editingDisabled, refetchQuote }) => {
  const classes = useStyles()
  const [currentEditingPart, setCurrentEditingPart] = useState('')

  const sortedParts = quote.parts
    .reduce((acc, item) => {
      const found = parts.find(part => part.id === item.id)
      if (found) {
        acc.push(found)
      }
      return acc
    }, [])
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    .map(o => parts.find(part => part.id === o.id))

  useEffect(() => {
    setEditingMutex(currentEditingPart !== '' ? 'parts' : '')
  }, [currentEditingPart])

  return (
    <>
      {sortedParts.length > 0 && (
        <Grid container sx={{ px: 1 }} className={classes.partGridHeader}>
          <Grid item sm={3} xs={3}>
            <b>Name</b>
          </Grid>
          <Grid item sm={4} xs={2}>
            <b>Number</b>
          </Grid>
          <Grid item sm={2} xs={2} align='right'>
            <b>Cost</b>
          </Grid>
          <Grid item sm={2} xs={2} align='right'>
            <b>Price</b>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      )}
      {sortedParts.map(part => (
        <InlinePartEditorGrid
          refetchQuote={refetchQuote}
          key={part.id}
          quote={quote}
          part={part}
          currentEditingPart={currentEditingPart}
          setCurrentEditingPart={setCurrentEditingPart}
          editingDisabled={editingDisabled}
        />
      ))}
    </>
  )
}

export default PartsTable
