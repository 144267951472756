import { useState, useContext, useEffect } from 'react'
import { UserContext } from 'UserStore'
import { useQuery, gql } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Collapse, Button, Box, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { logout } from 'auth'
import { API_COMMAND_HEADERS } from 'tools'
import SaveIcon from '@mui/icons-material/Save'

const GET_USER_DATA = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      phone
      loginKeys
    }
  }
`

const useStyles = makeStyles(theme => ({
  userInfo: {
    padding: 0,
    margin: 0,
    '& > li': {
      borderBottom: '1px solid #eee',
      display: 'flex',
      alignItems: 'center',

      '& > p': {
        background: '#eee',
        padding: '5px',
        margin: '0 5px 0 0',
        display: 'inline-block',
        fontSize: '13px',
        fontWeight: '500',
        textAlign: 'right',
        width: '25%',
      },
      '& > span': {
        maxWidth: '70%',
      },
    },
  },
}))

const phoneNumberRegex = /^(\+503\d{8}|\+54\d{10}|\+8869\d{8}|\+1\d{10})$/

const profileSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneNumberRegex, 'Phone number must match 1 of: +503(8#), +54(10#), +8869(8#), +1(10#)')
    .required(),
})

const Profile = () => {
  const classes = useStyles()
  const [user] = useContext(UserContext)
  const [editing, setEditing] = useState(false)
  const [initialValues, setInitialValues] = useState({ phone: '' })

  const { loading, error, data } = useQuery(GET_USER_DATA, {
    variables: { id: user.id },
  })

  useEffect(() => {
    if (!data) return
    const next = { phone: data.user.phone }
    setInitialValues(next)
    formik.initialValues = next
    formik.setValues(next)
  }, [data])

  const handleToggleEdit = () => setEditing(v => !v)
  const handleClickCancel = () => {
    setEditing(false)
    formik.setValues(initialValues)
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: profileSchema,
    enableReinitialize: true,
    onSubmit: values => {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/api/update_user_attributes`, {
        method: 'POST',
        headers: API_COMMAND_HEADERS,
        body: JSON.stringify({
          user_id: user.id,
          updated_attributes: values,
        }),
      }).then(res => (res.ok ? window.location.reload() : alert('error')))
    },
  })

  const validationErrors = Object.values(
    Object.keys(formik.errors).reduce((acc, k) => {
      if (k === 'availability') {
        // flatten availability sub object errors
        Object.keys(formik.errors.availability).forEach(k => {
          acc['availability.' + k] = k + ': ' + formik.errors.availability[k]
        })
      } else {
        acc[k] = formik.errors[k]
      }
      return acc
    }, {})
  )

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  return (
    <>
      <h3>
        Logged in as {data.user.firstName} {data.user.lastName}
      </h3>
      <Button variant='contained' color='secondary' onClick={logout}>
        logout
      </Button>
      <Button
        variant='contained'
        color='primary'
        style={{ marginLeft: '1rem' }}
        onClick={() => window.open(`carbodylab-phone://login?userId=${user.id}`)}
      >
        login to phone
      </Button>
      {!editing && (
        <Button variant='outlined' style={{ marginLeft: '1rem' }} onClick={handleToggleEdit}>
          edit
        </Button>
      )}
      <br />
      <br />
      <hr />
      <Collapse in={editing}>
        <h3>My profile</h3>

        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            '& .MuiFormControl-root': { m: 1 },
          }}
          noValidate
          autoComplete='off'
          onSubmit={formik.handleSubmit}
        >
          <ul className={classes.userInfo}>
            <li>
              <p>phone:</p>
              <TextField
                variant='outlined'
                size='small'
                label='phone'
                name='phone'
                value={formik.values.phone || ''}
                onChange={formik.handleChange}
              />
            </li>
          </ul>
          <Collapse in={validationErrors.length > 0}>
            {validationErrors.length > 0 && (
              <>
                <div style={{ color: 'red' }}>
                  {validationErrors.map(error => (
                    <div key={error}>{error}</div>
                  ))}
                </div>
              </>
            )}
          </Collapse>
          <Button variant='outlined' color='primary' onClick={handleClickCancel}>
            cancel
          </Button>
          &nbsp;
          <Button
            variant='contained'
            color='primary'
            disabled={!formik.dirty || !formik.isValid}
            type='submit'
            onClick={formik.handleSubmit}
          >
            <SaveIcon />
            save
          </Button>
        </Box>
      </Collapse>

    </>
  )
}

export default Profile
