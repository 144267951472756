import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { useQuery, gql } from '@apollo/client'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import { basicDateTimeFormat, PriceInput, Dollars, getUploadParamsForDropzone } from 'tools'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { DateTime } from 'luxon'

const QUERY = gql`
  query manualCompensations($technicianId: ID) {
    manualCompensations(technicianId: $technicianId) {
      id
      createdAt
      amount
      payoutId
      note
      deletedAt
      quote {
        id
        lead {
          id
          name
        }
      }
      payout {
        id
        createdAt
      }
    }
  }
`

const ManualCompensations = ({ technician }) => {
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { technicianId: technician.id }
  })
  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return (
    <>
      <Typography variant='h6'>
        Manual Compensations
      </Typography>

      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='right'>amount</TableCell>
              <TableCell align='right'>note</TableCell>
              <TableCell align='right'>quote</TableCell>
              <TableCell align='right'>payout?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.manualCompensations
              .filter(re => !re.deletedAt)
              .map(comp => (
                <TableRow
                  key={comp.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align='right'><Dollars value={comp.amount} /></TableCell>
                  <TableCell align='right'>{comp.note}</TableCell>
                  <TableCell align='right'>
                    <Link to={`/leads/${comp.quote.lead.id}/quotes/${comp.quote.id}#manual-compensations`}>{comp.quote.lead.name}</Link>
                  </TableCell>
                  <TableCell align='right'>
                    {comp.payout
                      ? <>{DateTime.fromISO(comp.payout.createdAt).toFormat(basicDateTimeFormat)}</>
                      : <> not linked </>}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ManualCompensations
