import { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { Checkbox, Box, CircularProgress, FormControlLabel } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { UserContext } from 'UserStore'
import { paymentStatusLabels } from '../data/styles'
import { prettyPhoneNumber } from 'tools'
import { ChatFlowProvider } from './Providers/ChatFlowContext'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { SearchTextField } from 'Recruiting/components/SearchTextField'

const BOT_SESSIONS = gql`
  query PreLeadsConnection(
    $first: Int
    $after: String
    $order: JSON
    $search: JSON
    $excludeFollowups: Boolean
    $excludeCustomers: Boolean
  ) {
    botSessionsConnection(
      first: $first
      after: $after
      order: $order
      search: $search
      excludeFollowups: $excludeFollowups
      excludeCustomers: $excludeCustomers
    ) {
      edges {
        cursor
        node {
          id
          name
          completedAt
          startedAt
          followedUpAt
          email
          phone
          serviceType
          customer {
            id
            firstName
            lastName
            outboundNumber
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  headerFormControl: {
    minWidth: '5em',
  },
  dateComponent: {
    fontSize: '12px',
    margin: '0px -100px -2px 0px',
    float: 'left',
    maxHeight: '27px',
    padding: '3px 7px',
  },
  dateDialog: {
    display: 'flex',
    '& div': {
      padding: '5px',
      '& span': {
        background: '#eee',
        display: 'block',
        padding: '3px',
        borderRadius: '3px 3px 0px 0px',
        border: '1px solid #ccc',
        borderBottom: '1px dashed #eee',
      },
      '& input': {
        padding: '3px',
        borderRadius: '0px 0px 3px 3px',
        border: '1px solid #ccc',
        borderTop: '0px',
      },
    },
  },
}))

const PreLeadsIndex = () => {
  const [value, setValue] = useLocalStorage('preLeadTab', 'preleads')
  const classes = useStyles()
  const [user] = useContext(UserContext)
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem('preLeadsOrder')) || {})
  const [search, setSearch] = useState(JSON.parse(localStorage.getItem('preLeadsSearch')) || {})
  const [limit, setLimit] = useState(JSON.parse(localStorage.getItem('preLeadsLimit')) || 50)
  const [excludeFollowups, setExcludeFollowups] = useState(
    JSON.parse(localStorage.getItem('preLeadsExcludeFollowups')) || false
  )

  const [excludeCustomers, setExcludeCustomers] = useState(
    JSON.parse(localStorage.getItem('preLeadsExcludeCustomers')) || false
  )
  useEffect(() => localStorage.setItem('preLeadsOrder', JSON.stringify(order)), [order])
  useEffect(() => localStorage.setItem('preLeadsLimit', JSON.stringify(limit)), [limit])
  useEffect(
    () => localStorage.setItem('preLeadsExcludeFollowups', JSON.stringify(excludeFollowups)),
    [excludeFollowups]
  )

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  const markFollowupCompleted = ({ preLeadId }) => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/mark_pre_lead_followup_complete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: user.id,
        session_id: preLeadId,
      }),
    }).then(() => {
      fetchMore({
        variables: {
          order,
          first: limit,
          after: '',
          excludeFollowups,
          excludeCustomers,
          search,
        },
      })
    })
  }

  const setOrToggleOrder = name => {
    setOrder({
      [name]: (order[name] && order[name] === 'desc' ? 'asc' : 'desc') || 'desc',
    })
  }

  useEffect(() => {
    fetchMore({
      variables: {
        order,
        first: limit,
        after: '',
        excludeFollowups,
        excludeCustomers,
        search,
      },
    })
  }, [order, limit, excludeFollowups, excludeCustomers, search])

  const { loading, error, data, fetchMore } = useQuery(BOT_SESSIONS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: limit,
      after: '',
      order: order,
      search,
      excludeFollowups,
      excludeCustomers,
    },
  })

  // if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const preLeads = data?.botSessionsConnection?.edges?.map(edge => edge.node) || []
  const totalCount = data?.botSessionsConnection?.totalCount || 0
  const endCursor = data?.botSessionsConnection?.pageInfo?.endCursor || 0

  const OrderableHeadCell = ({ name, align }) => {
    return (
      <TableCell align={align}>
        {name}

        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={() => setOrToggleOrder(name)}>
          <SvgIcon
            fontSize='small'
            component={(order[name] === 'desc' ? ArrowDownwardIcon : ArrowUpwardIcon) || ArrowDownwardIcon}
          />
        </Button>
      </TableCell>
    )
  }

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          order,
          first: limit,
          after: endCursor,
          excludeFollowups,
          excludeCustomers,
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <ChatFlowProvider>
      <Box>
        {value === 'preleads' && (
          <>
            <FormControlLabel
              onChange={e => setExcludeFollowups(e.target.checked)}
              label='only show those without a followup'
              control={<Checkbox checked={!!excludeFollowups} />}
            />
            <FormControlLabel
              onChange={e => setExcludeCustomers(e.target.checked)}
              label='only show those without a customer'
              control={<Checkbox checked={!!excludeCustomers} />}
            />
          </>
        )}

        <TableContainer component={Paper}>
          <Table className={classes.table} size='small' aria-label='a dense table'>
            {loading && (
              <Box
                sx={{
                  position: 'fixed',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#11111140',
                  top: '0px',
                  bottom: '0px',
                  left: '0px',
                  right: '0px',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <CircularProgress />
                  {excludeCustomers && (
                    <span> WARNING: excluding customers is a FAT query, it may take some time </span>
                  )}
                </Box>
              </Box>
            )}
            <TableHead>
              <TableRow>
                <TableCell align='right'>Followedup?</TableCell>
                <OrderableHeadCell align='right' name='started_at' />
                <OrderableHeadCell align='right' name='completed_at' />
                <TableCell align='right'>Name</TableCell>
                <TableCell align='right'>Phone</TableCell>
                <TableCell align='right'>Email</TableCell>
                <TableCell align='right'>
                  <SearchTextField
                    model='BotSession::Cache'
                    search={search}
                    field='service_type'
                    setSearch={setSearch}
                  />
                </TableCell>
                <TableCell align='right'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {preLeads.slice(page * limit, page * limit + limit).map(preLead => (
                <TableRow key={preLead.id} sx={paymentStatusLabels}>
                  <TableCell align='right'>
                    {preLead.followedUpAt ? DateTime.fromISO(preLead.followedUpAt).toFormat('ff ZZZZ') : 'nope'}
                  </TableCell>
                  <TableCell align='right'>
                    <Link to={`/pre-leads/${preLead.id}`}>
                      {' '}
                      {DateTime.fromISO(preLead.startedAt).toFormat('ff ZZZZ')}{' '}
                    </Link>
                  </TableCell>
                  <TableCell align='right'>{DateTime.fromISO(preLead.completedAt).toFormat('ff ZZZZ')}</TableCell>
                  <TableCell align='right'>
                    {preLead.customer ? (
                      <Link to={`/customers/${preLead.customer.id}`}>
                        {preLead.customer.firstName} {preLead.customer.lastName}
                      </Link>
                    ) : (
                      <>{preLead.name || <span style={{ color: '#777' }}> Not found </span>} </>
                    )}
                  </TableCell>
                  <TableCell align='right'>
                    {preLead.customer ? (
                      <a
                        href={`carbodylab-phone://dial?toNumber=${preLead.phone}&fromNumber=${preLead.customer.outboundNumber}`}
                      >
                        {prettyPhoneNumber(preLead.phone)}
                      </a>
                    ) : (
                      <a href={`carbodylab-phone://dial?toNumber=${preLead.phone}&fromNumber=+17372527400`}>
                        {prettyPhoneNumber(preLead.phone)}
                      </a>
                    )}
                  </TableCell>
                  <TableCell align='right'>
                    {preLead.email || <span style={{ color: '#777' }}> Not found </span>}
                  </TableCell>
                  <TableCell align='right'>
                    {preLead.serviceType || <span style={{ color: '#777' }}> Not found </span>}
                  </TableCell>
                  <TableCell align='right'>
                    <button onClick={() => markFollowupCompleted({ preLeadId: preLead.id })}>
                      Mark as followed up
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component='div'
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </ChatFlowProvider>
  )
}

export default PreLeadsIndex
