import React, { useEffect, useContext, useState } from 'react'
import { Dollars } from 'tools'
import { useDebounce } from 'use-debounce'
import { useHistory } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const TECHNICIANS_QUERY = gql`
  query getTechnicians {
    technicians {
      id
      name
      email
      firstName
      lastName
      businessName
      contractorIsBusiness
      ssn
      ein
      phone
      addressLineOne
      addressLineTwo
      city
      state
      zip
      lat
      lng
      drivewayJobs
      active
      commissionPercent
      market
      availability
      offdays
      loanBalance
      loanWeeklyDeduction
      loanActivity
      otherLoanBalance
      otherLoanWeeklyDeduction
      otherLoanActivity
      payrollIds
      isTrusted
      note
    }
  }
`

const useStyles = makeStyles((theme) => ({
  headerFormControl: {
    minWidth: '5em'
  },
  dateComponent: {
    fontSize: '12px',
    margin: '0px -100px -2px 0px',
    float: 'left',
    maxHeight: '27px',
    padding: '3px 7px'
  },
  dateDialog: {
    display: 'flex',
    '& div': {
      padding: '5px',
      '& span': {
        background: '#eee',
        display: 'block',
        padding: '3px',
        borderRadius: '3px 3px 0px 0px',
        border: '1px solid #ccc',
        borderBottom: '1px dashed #eee'
      },
      '& input': {
        padding: '3px',
        borderRadius: '0px 0px 3px 3px',
        border: '1px solid #ccc',
        borderTop: '0px'
      }
    }
  }
}))

const Index = () => {
  const history = useHistory()
  const classes = useStyles()
  const [showInactive, setShowInactive] = useState(false)

  const { loading, error, data } = useQuery(TECHNICIANS_QUERY)

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const sortedTechnicians =
    [...data.technicians]
      .sort((a, b) => a.firstName > b.firstName ? 1 : -1)

  const technicians =
    showInactive
      ? sortedTechnicians
      : sortedTechnicians.filter(tech => tech.active)

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={showInactive} onChange={(e) => setShowInactive(e.target.checked)} />}
          label='Show Inactive'
        />
      </FormGroup>

      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='right'>first name</TableCell>
              <TableCell align='right'>last name</TableCell>
              <TableCell align='right'>equipment loan</TableCell>
              <TableCell align='right'>equipment loan deduction</TableCell>
              <TableCell align='right'>other loan</TableCell>
              <TableCell align='right'>other loan deduction</TableCell>
              <TableCell align='right'>business name</TableCell>
              <TableCell align='right'>commission percent</TableCell>
              {showInactive &&
                <TableCell align='right'>active?</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {technicians.map(tech =>
              <React.Fragment key={tech.id}>
                <TableRow
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(`/technicians/${tech.id}`)}
                  key={tech.id}
                >
                  <TableCell align='right'>{tech.firstName}</TableCell>
                  <TableCell align='right'>{tech.lastName}</TableCell>
                  <TableCell align='right'><Dollars value={tech.loanBalance} /></TableCell>
                  <TableCell align='right'><Dollars value={tech.loanWeeklyDeduction} /></TableCell>
                  <TableCell align='right'><Dollars value={tech.otherLoanBalance} /></TableCell>
                  <TableCell align='right'><Dollars value={tech.otherLoanWeeklyDeduction} /></TableCell>
                  <TableCell align='right'>{tech.businessName}</TableCell>
                  <TableCell align='right'>{tech.commissionPercent}</TableCell>
                  {showInactive &&
                    <TableCell align='right'>
                      {!tech.active && <span style={{ color: 'red' }}>inactive</span>}
                    </TableCell>}
                </TableRow>
                {/* <TableRow> */}
                {/*   <Show technician={tech} /> */}
                {/* </TableRow> */}
              </React.Fragment>)}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const IndexTabs = () => {
  const [tab, setTab] = useState(localStorage.getItem('techniciansTab') || 'index')
  useEffect(() => localStorage.setItem('techniciansTab', tab), [tab])

  const handleChangeTab = (_, newValue) => {
    setTab(newValue)
  }

  return (
    <>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab value='index' label='Index' />
        {/* <Tab value="loans" label="Loans"></Tab> */}
      </Tabs>

      {tab === 'index' && <Index />}
    </>
  )
}

export default IndexTabs
