import { Tabs, Tab, AppBar, Box } from '@mui/material'
import Problems from './Payroll/Problems'
import Payrolls from './Payroll/Payrolls'
import { Configure } from './Payroll/Configure/Configure'
import { useLocalStorage } from 'hooks/useLocalStorage'

const PayrollTabs = () => {
  const [tab, setTab] = useLocalStorage('payrollTab', 'configure', false)

  const handleChange = (_, newValue) => {
    setTab(newValue)
  }

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: '61px',
          left: '0px',
          zIndex: 5,
          width: '100%',
          background: '#f0f8ffbf',
          backdropFilter: 'blur(12px)',
          borderBottom: '2px solid #a1cff7',
        }}
      >
        <Tabs value={tab} onChange={handleChange}>
          <Tab value='problems' label='problems' />
          <Tab value='configure' label='New Payroll' />
          <Tab sx={{ ml: 'auto', textDecoration: 'underline' }} value='payrolls' label='history' />
        </Tabs>
      </Box>

      <Box sx={{ mt: '41px' }}>
        {tab === 'problems' && <Problems />}
        {tab === 'configure' && <Configure />}
        {tab === 'payrolls' && <Payrolls />}
      </Box>
    </>
  )
}

export default PayrollTabs
