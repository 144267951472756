import { Box, Tabs, Tab, Typography } from '@mui/material'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { InProgress } from './InProgress'
import { WorkOrders } from './WorkOrders'
import { UnAccepted } from './Unaccepted'

const Index = () => {
  const [tab, setTab] = useLocalStorage('b2bWorkOrdersTab', 'unaccepted')

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ padding: '0rem 1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='h4' sx={{ mt: '1rem', mb: '1rem' }}>
          B2B Part Orders
        </Typography>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)}>
          <Tab value='unaccepted' label='Pending Review' />
          <Tab value='all' label='all' />
          <Tab value='in-progress' label='in-progress' />
        </Tabs>
      </Box>
      {tab === 'all' && <WorkOrders />}
      {tab === 'unaccepted' && <UnAccepted />}
      {tab === 'in-progress' && <InProgress />}
    </Box>
  )
}

export default Index
