import { useContext, useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
  TextField,
} from '@mui/material'
import { useToggle } from 'hooks/useToggle'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

import EditIcon from '@mui/icons-material/Edit'

export default function LabelReadWriter({
  obj,
  idName,
  propName = 'label',
  freeSolo = true,
  allowNull = true,
  options,
  endpoint,
  refetch,
}) {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [dialogOpen, toggleDialog, closeDialog, openDialog] = useToggle()
  const [pending, setPending] = useState(obj[propName] ?? '')

  const handleApply = () => {
    closeDialog()
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/${endpoint}`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        [idName]: obj.id,
        updatedAttributes: { [propName]: pending },
      }),
    }).then(res => (res.ok ? refetch() : alert('Error!')))
  }

  const handleChange = (event, value) => {
    if (value) setPending(value.trim())
    else setPending(null)
  }

  useEffect(() => {
    if (!dialogOpen) setPending(obj[propName])
  }, [dialogOpen])

  return (
    <>
      <b>{propName}: </b>
      {obj[propName]}
      <Button name='editLabel' style={{ minWidth: 0 }} color='primary' disableElevation onClick={openDialog}>
        <SvgIcon fontSize='small' component={EditIcon} />
      </Button>

      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Select {propName}</DialogTitle>

        <DialogContent>
          <Box sx={{ p: 4 }}>
            <Autocomplete
              fullWidth
              freeSolo={freeSolo}
              clearOnBlur
              options={options}
              value={pending}
              onChange={handleChange}
              renderInput={props => <TextField {...props} label={propName} />}
              sx={{ minWidth: '250px' }}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialog} color='primary'>
            close
          </Button>
          <Button
            onClick={handleApply}
            disabled={pending === '' || pending === obj[propName] || (!pending && !allowNull)}
            color='primary'
          >
            apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
