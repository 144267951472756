import { useEffect, useContext, useState } from 'react'
import { Dollars, basicDateTimeFormatter, keysToCamel } from 'tools'
import { Box, Dialog, Chip, Checkbox, Collapse, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { UserContext } from 'UserStore'
import { CheckCircleOutlineRounded, DeleteRounded, DownloadRounded } from '@mui/icons-material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { bToBInvoiceStatementsConnection, DEALERS_QUERY, useStyles } from './tools'
import { SelectBusinessHeadCell } from './BToBInvoiceStatement/Components/SelectBusinessHeadCell'
import { DateRangeHeadCell } from './BToBInvoiceStatement/Components/DateRangeHeadCell'
import { SelectLabelHeadCell } from './BToBInvoiceStatement/Components/SelectLabelHeadCell'

export const UnAccepted = () => {
  const classes = useStyles()
  const [user] = useContext(UserContext)
  const parseState = true
  const [isLoading, setIsLoading] = useState(false)

  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([])
  const [search, setSearch] = useLocalStorage('bToBInvoiceStatementsSearchFromUnacceptedPage', {}, parseState)
  const [order, setOrder] = useLocalStorage('bToBInvoiceStatementsOrderFromUnacceptedPage', {}, parseState)
  const [filter, setFilter] = useLocalStorage('bToBInvoiceStatementsFilterFromUnacceptedPage', {}, parseState)
  const [limit, setLimit] = useLocalStorage('bToBInvoiceStatementsLimitFromUnacceptedPage', 50)

  const [startDownloadMultiple, setStartDownloadMultiple] = useState(false)
  const [downloadInvoiceIds, setDownloadInvoiceIds] = useState([])

  const [fontsLoaded, setFontsLoaded] = useState(false)

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  const bearerTokenHeaders = useBearerTokenHeaders()

  useEffect(() => {
    downloadInvoiceIds && downloadInvoiceIds.length === 0 && setStartDownloadMultiple(false)
    downloadInvoiceIds && downloadInvoiceIds.length === 0 && setIsLoading(false)
    downloadInvoiceIds && downloadInvoiceIds.length === 0 && refetch()
  }, [downloadInvoiceIds])

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: Number(limit),
        after: '',
        isCompleted: true,
        isAccepted: false,
        hasPayoutData: false,
      },
    })
  }, [search, order, filter, limit])

  const { loading, error, data, fetchMore, refetch } = useQuery(bToBInvoiceStatementsConnection, {
    variables: {
      search,
      order,
      filter,
      first: Number(limit),
      after: cursorCeiling,
      isCompleted: true,
      isAccepted: false,
      hasPayoutData: false,
    },
  })

  const { data: dealersData } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const statements = data.bToBInvoiceStatementsConnection.edges.map(edge => edge.node)
  const totalCount = data.bToBInvoiceStatementsConnection.totalCount
  const endCursor = data.bToBInvoiceStatementsConnection.pageInfo.endCursor
  const selectedInvoices = statements.filter(statement => selectedInvoiceIds.includes(statement.id))
  const downloadingInvoices = statements.filter(statement => downloadInvoiceIds.includes(statement.id))

  const dealers = dealersData?.bToBDealers || []

  const handleClickAccept = () => {
    setIsLoading(true)
    selectedInvoiceIds.map(id =>
      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/accept_b_to_b_invoice_statement`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: id,
            userId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
        .then(res => setIsLoading(false))
        .then(res => setSelectedInvoiceIds([]))
        .then(res => setTimeout(() => refetch(), 250))
    )
  }

  const acceptWithoutRefetch = () => {
    setIsLoading(true)
    selectedInvoiceIds.map(id =>
      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/accept_b_to_b_invoice_statement`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: id,
            userId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
        .then(res => setIsLoading(false))
        .then(res => setSelectedInvoiceIds([]))
    )
  }

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: limit,
          after: endCursor,
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <>
      <Collapse in={selectedInvoiceIds.length > 0}>
        <Box sx={{ display: 'flex', padding: '1rem', justifyContent: 'flex-end' }}>
          <Box sx={{ mr: 'auto' }}>
            <Button
              onClick={() => {
                setStartDownloadMultiple(true)
                setDownloadInvoiceIds(selectedInvoiceIds)
                setIsLoading(true)
              }}
              size='small'
              variant='outlined'
              sx={{ mr: '1rem', '& svg': { fontSize: '.9rem', mr: '.5rem' } }}
            >
              <DownloadRounded /> Download Selected ({selectedInvoiceIds.length})
            </Button>

            <Button
              onClick={() => {
                setStartDownloadMultiple(true)
                setDownloadInvoiceIds(selectedInvoiceIds)
                setIsLoading(true)
                acceptWithoutRefetch()
              }}
              disableElevation
              size='small'
              variant='contained'
              sx={{ mr: '1rem', '& svg': { fontSize: '.9rem', mr: '.5rem' } }}
            >
              <DownloadRounded /> Download And Accept Selected ({selectedInvoiceIds.length})
            </Button>
          </Box>
          <Button
            disableElevation
            onClick={handleClickAccept}
            size='small'
            variant='contained'
            sx={{ mr: '1rem', '& svg': { fontSize: '.9rem', mr: '.5rem' } }}
          >
            <CheckCircleOutlineRounded /> Accept Selected ({selectedInvoiceIds.length})
          </Button>
          <Button size='small' variant='outlined' sx={{ '& svg': { fontSize: '.9rem', mr: '.5rem' } }}>
            <DeleteRounded /> Delete Selected
          </Button>
        </Box>
      </Collapse>

      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            background: '#11111140',
            bottom: '0px',
            width: '100%',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color='warning' />
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='right'></TableCell>
              <TableCell align='right'>Statement #</TableCell>
              <DateRangeHeadCell
                name='created_at'
                filter={filter}
                setFilter={setFilter}
                order={order}
                setOrder={setOrder}
              />

              <DateRangeHeadCell
                name='completed_at'
                filter={filter}
                setFilter={setFilter}
                order={order}
                setOrder={setOrder}
              />

              <SelectBusinessHeadCell filter={filter} setFilter={setFilter} dealers={dealers} />
              <TableCell align='right'>Grand Total</TableCell>
              <SelectLabelHeadCell filter={filter} setFilter={setFilter} />
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statements.slice(page * limit, page * limit + limit).map(statement => (
              <Row
                refetch={refetch}
                statement={statement}
                setSelectedInvoiceIds={setSelectedInvoiceIds}
                selectedInvoiceIds={selectedInvoiceIds}
              />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}

const Row = ({ statement, setSelectedInvoiceIds, selectedInvoiceIds, refetch }) => {
  const [user] = useContext(UserContext)
  const [showDownloadLink, setShowDownloadLink] = useState(false)
  const [completedFirstDownload, setCompletedFirstDownload] = useState(false)
  const [fileBlob, setFileBlob] = useState(null)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const handleClickAccept = () => {
    window
      .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/accept_b_to_b_statement`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBInvoiceId: statement.id,
          userId: user.id,
        }),
      })
      .then(res => res.ok || window.alert('Error'))
      .then(res => refetch())
  }

  const handleDownload = blob => {}

  return (
    <TableRow key={statement.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell>
        <Checkbox
          onChange={() =>
            selectedInvoiceIds.includes(statement.id)
              ? setSelectedInvoiceIds(prev => prev.filter(id => id !== statement.id))
              : setSelectedInvoiceIds(prev => [...prev, statement.id])
          }
          checked={selectedInvoiceIds.includes(statement.id)}
        />
      </TableCell>
      <TableCell align='right'>{statement.statementNumber}</TableCell>
      <TableCell>
        <Link target='_blank' to={`/b-to-b/statements/${statement.id}`}>
          {basicDateTimeFormatter(statement.createdAt)}
        </Link>
      </TableCell>
      <TableCell>
        <Link target='_blank' to={`/b-to-b/statements/${statement.id}`}>
          {basicDateTimeFormatter(statement.completedAt)}
        </Link>
      </TableCell>
      <TableCell align='right'>{statement.businessName}</TableCell>
      <TableCell align='right'>
        <Dollars value={statement.grandTotal} />
      </TableCell>

      <TableCell align='right'>{statement.label && <Chip size='small' label={statement.label} />}</TableCell>
      <TableCell>
        <button onClick={handleClickAccept}>accept</button>
      </TableCell>
      <TableCell align='right'>
        <Button
          onClick={() => (completedFirstDownload ? handleDownload() : setShowDownloadLink(true))}
          size='small'
          title='download PDF'
          sx={{ minWidth: '30px', '& svg': { fontSize: '1rem' } }}
        >
          {!showDownloadLink && <DownloadRounded />}
        </Button>
      </TableCell>
    </TableRow>
  )
}
