import { useContext, useState, useEffect } from 'react'
import { UserContext } from './UserStore'
import { useHistory } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Profile from './Home/Profile'
import Dashboard from './Home/Dashboard'
import Tasks from './Home/Tasks'

const Home = () => {
  let history = useHistory()
  const [user] = useContext(UserContext)
  const [tab, setTab] = useState(localStorage.getItem('homeTab') || 'dashboard')
  useEffect(() => localStorage.setItem('homeTab', tab), [tab])

  const handleChangeTab = (_, newValue) => {
    setTab(newValue)
  }

  return (
    <>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab cy-data='tasks-button' value='tasks' label='Tasks' />
        <Tab cy-data='dashboard-button' value='dashboard' label='Dashboard' />
        <Tab cy-data='profile-button' value='profile' label='Profile'></Tab>
      </Tabs>

      {tab === 'tasks' && <Tasks />}
      {tab === 'dashboard' && <Dashboard />}
      {tab === 'profile' && <Profile />}
    </>
  )
}

export default Home
