import { GlobalContext } from 'GlobalStore'
import { useContext, useState } from 'react'
import { basicDateTimeFormatter, bearerTokenHeaders, keysToCamel } from 'tools'
import { CircularProgress, Collapse, Modal, Box, Dialog, DialogContent, Checkbox, DialogActions, DialogTitle, Chip } from '@mui/material'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FilterListIcon from '@mui/icons-material/FilterList'
import SvgIcon from '@mui/material/SvgIcon'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ChatMessageCount from 'components/ChatMessageCount'
import ShowEdit from 'BToB/WorkOrders/WorkOrder/ShowEdit'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { UserContext } from 'UserStore'

const modalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90%',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '2px',
  p: 2,
}

const useStyles = makeStyles(theme => ({
  headerFormControl: {
    minWidth: '5em',
  },
  dateComponent: {
    fontSize: '12px',
    margin: '0px -100px -2px 0px',
    float: 'left',
    maxHeight: '27px',
    padding: '3px 7px',
  },
  dateDialog: {
    display: 'flex',
    '& div': {
      padding: '5px',
      '& span': {
        background: '#eee',
        display: 'block',
        padding: '3px',
        borderRadius: '3px 3px 0px 0px',
        border: '1px solid #ccc',
        borderBottom: '1px dashed #eee',
      },
      '& input': {
        padding: '3px',
        borderRadius: '0px 0px 3px 3px',
        border: '1px solid #ccc',
        borderTop: '0px',
      },
    },
  },
}))

const QUERY = gql`
  query BToBWorkOrdersConnection(
    $search: JSON
    $order: JSON
    $filter: JSON
    $first: Int
    $after: String
    $isCompleted: Boolean
  ) {
    bToBWorkOrdersConnection(
      search: $search
      order: $order
      filter: $filter
      first: $first
      after: $after
      isCompleted: $isCompleted
    ) {
      edges {
        cursor
        node {
          id
          createdAt
          acceptedAt
          businessName
          preJobPhotos
          year
          make
          makeShortName
          model
          modelShortName
          vin
          vinPhoto
          completedAt
          repairOrder
          stock
          deletedAt
          workOrderNumber
          dealerId
          dealer {
            businessName
            primaryContact {
              firstName
              lastName
              email
            }
          }
          technician {
            id
            firstName
            lastName
          }
          parts {
            id
            name
            number
            price
            cost
            type
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const DEALERS_QUERY = gql`
  query BToBDealers {
    bToBDealers {
      id
      businessName
    }
  }
`

export const WorkOrders = () => {
  const classes = useStyles()
  const [global] = useContext(GlobalContext)
  const [modalItemId, setModalItemId] = useState(null)
  const parseState = true
  const [search, setSearch] = useLocalStorage('bToBWorkOrdersSearchFromUnacceptedPage', {}, parseState)
  const [order, setOrder] = useLocalStorage('bToBWorkOrdersOrderFromUnacceptedPage', {}, parseState)
  const [filter, setFilter] = useLocalStorage('bToBWorkOrdersFilterFromUnacceptedPage', {}, parseState)
  const [limit, setLimit] = useLocalStorage('bToBWorkOrdersLimitFromUnacceptedPage', 50)

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  const removeFilter = filterName => {
    const { [filterName]: removed, ...remainingFilters } = filter
    setFilter(remainingFilters)
  }

  const setOrToggleOrder = name => {
    const { [name]: removed, ...remainingOrder } = order
    if (order[name] === 'asc') setOrder(remainingOrder)
    else setOrder({ [name]: order[name] ? 'asc' : 'desc', ...remainingOrder })
  }

  // useEffect(() => {
  //   fetchMore({
  //     variables: {
  //       search,
  //       order,
  //       filter,
  //       first: Number(limit),
  //       after: '',
  //       isCompleted: true,
  //     },
  //   })
  // }, [search, order, filter, limit])

  const { loading, error, data, fetchMore, refetch } = useQuery(QUERY, {
    variables: {
      search,
      order,
      filter,
      first: Number(limit),
      after: cursorCeiling,
      isCompleted: true,
    },
  })

  const { data: dealersData } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const workOrders = data.bToBWorkOrdersConnection.edges.map(edge => edge.node)
  const totalCount = data.bToBWorkOrdersConnection.totalCount
  const endCursor = data.bToBWorkOrdersConnection.pageInfo.endCursor

  const dealers = dealersData?.bToBDealers

  const DateRangeHeadCell = ({ name }) => {
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [dateRange, setDateRange] = useState(filter[name] || {})
    // ONE of BOTH key of: { ge: iso8601, le: iso8601 } OR null
    // ge -> greater than or equal
    // le -> less than or equal

    const handleDateTimeChanged = evt => {
      setDateRange({ ...dateRange, [evt.target.name]: evt.target.value })
    }

    const handleClickApply = () => {
      setFilter({ ...filter, [name]: dateRange })
    }

    const handleClickClose = () => {
      setDialogIsOpen(false)
    }

    const handleDeleteRangeKey = key => {
      const { [key]: removed, ...rest } = dateRange
      setDateRange(rest)
      setFilter({ ...filter, [name]: rest })
    }

    return (
      <TableCell>
        {name}

        <Button
          sx={{
            minWidth: '30px',
            padding: '5px 0px',
            margin: '0px 5px',
          }}
          variant={order[name] ? 'contained' : 'text'}
          color='primary'
          disableElevation
          onClick={() => setOrToggleOrder(name)}
        >
          <SvgIcon
            fontSize='small'
            component={(order[name] === 'desc' ? ArrowDownwardIcon : ArrowUpwardIcon) || ArrowDownwardIcon}
          />
        </Button>

        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={() => setDialogIsOpen(true)}>
          <SvgIcon fontSize='small' component={FilterListIcon} />
        </Button>

        {filter[name] && Object.entries(filter[name]).length > 0 && (
          <>
            <br />
            {Object.entries(filter[name])
              .sort((a, b) => (a[0] > b[0] ? 1 : -1))
              .map(filterTuple => (
                <Chip
                  key={filterTuple[0]}
                  variant='outlined'
                  size='small'
                  onDelete={() => handleDeleteRangeKey(filterTuple[0])}
                  label={`
                    ${filterTuple[0] === 'le' ? 'before' : 'after'}
                    ${DateTime.fromISO(filterTuple[1]).toFormat('yy-MM-dd')}
                  `}
                />
              ))}
          </>
        )}

        <Dialog open={dialogIsOpen} onClose={setDialogIsOpen}>
          <DialogTitle>Select Date Range (One Or Both)</DialogTitle>
          <DialogContent className={classes.dateDialog}>
            <Box>
              <span>
                Start Date<small> (optional)</small>
              </span>
              <input type='datetime-local' name='ge' value={dateRange.ge || ''} onChange={handleDateTimeChanged} />
            </Box>
            <Box>
              <span>
                End Date<small> (optional)</small>
              </span>
              <input type='datetime-local' name='le' value={dateRange.le || ''} onChange={handleDateTimeChanged} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickClose} color='primary'>
              Close
            </Button>
            <Button onClick={handleClickApply} color='primary' autoFocus>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    )
  }

  const SelectTechnicianHeadCell = props => {
    const [technicianId, setTechnicianId] = useState((filter.technician_id && filter.technician_id.eq) || '')

    const handleChange = evt => {
      if (evt.target.value === 'clear') {
        removeFilter('technician_id')
      } else {
        setFilter({ ...filter, technician_id: { eq: evt.target.value } })
      }
    }

    const technicians = global.technicians.filter(technician => technician.active && technician.isBToB)

    return (
      <TableCell {...props}>
        {!technicianId && <>Technician</>}
        <Select color='primary' size='small' label='Technician' value={technicianId} onChange={handleChange}>
          <MenuItem value='clear'>
            <i>clear</i>
          </MenuItem>
          {technicians.map(technician => (
            <MenuItem key={technician.id} value={technician.id}>
              <>
                {technician.firstName} {technician.lastName}
              </>
            </MenuItem>
          ))}
        </Select>
      </TableCell>
    )
  }

  const SelectBusinessHeadCell = props => {
    const [businessName, setBusinessName] = useState((filter.dealer_id && filter.dealer_id.eq) || '')

    const handleChange = evt => {
      if (evt.target.value === 'clear') {
        removeFilter('dealer_id')
      } else {
        setFilter({ ...filter, dealer_id: { eq: evt.target.value } })
      }
    }

    const businesses = dealers

    return (
      <TableCell {...props}>
        <Select color='primary' size='small' label='Business' value={businessName} onChange={handleChange}>
          <MenuItem value='clear'>
            <i>clear</i>
          </MenuItem>
          {businesses.map(business => (
            <MenuItem key={business.id} value={business.id}>
              <>{business.businessName}</>
            </MenuItem>
          ))}
        </Select>
      </TableCell>
    )
  }

  const OrderableTableCell = ({ name, children }) => {
    return (
      <TableCell style={{ background: order[name] ? '#eee' : '#fff' }}>
        <TableSortLabel active={order[name]} direction={order[name]} onClick={() => setOrToggleOrder(name)}>
          {name}
          {children}
        </TableSortLabel>
      </TableCell>
    )
  }

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: limit,
          after: endCursor,
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow>
              <DateRangeHeadCell name='completed_at' />
              <SelectTechnicianHeadCell align='right' />
              <SelectBusinessHeadCell align='right' />
              <TableCell align='right'>RO #</TableCell>
              <TableCell align='right'># of Parts</TableCell>
              <TableCell align='right'>Invoice #</TableCell>
              <TableCell align='right'>Info</TableCell>
              <TableCell align='right'>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workOrders.slice(page * limit, page * limit + limit).map(workOrder => (
              <TableRow key={workOrder.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  <Link target='_blank' to={`/b-to-b/work-orders/${workOrder.id}`}>
                    {basicDateTimeFormatter(workOrder.completedAt)}
                  </Link>
                </TableCell>
                <TableCell align='right'>
                  <Link target='_blank' to={`/technicians/${workOrder.technician.id}`}>
                    {workOrder.technician.firstName}
                    &nbsp;
                    {workOrder.technician.lastName}
                  </Link>
                </TableCell>
                <TableCell align='right'>{workOrder.businessName}</TableCell>
                <TableCell align='right'>{workOrder.repairOrder}</TableCell>
                <TableCell align='right'>
                  <b>
                    {workOrder.parts.length}
                  </b>
                </TableCell>
                <TableCell align='right'>{workOrder.workOrderNumber}</TableCell>
                <TableCell align='right'>{workOrder.paidSquareInvoiceId}</TableCell>
                <TableCell align='right'>
                  {workOrder.year}, {workOrder.make} {workOrder.model}
                </TableCell>
                <TableCell align='right'>
                  <ChatMessageCount contextType='BToBInvoice' contextId={workOrder.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Modal open={modalItemId} onClose={() => setModalItemId(null)}>
        <Box sx={modalBoxStyle}>
          {modalItemId && <ShowEdit workOrder={keysToCamel(workOrders.find(workOrder => workOrder.id === modalItemId))} />}
        </Box>
      </Modal>
    </>
  )
}
