import {
  Container,
  Button,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material'
import { useState, useContext } from 'react'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { DateTime } from 'luxon'
import { UserContext } from 'UserStore'
import { GlobalContext } from '../GlobalStore'

const New = ({ setCurrentTab }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [global] = useContext(GlobalContext)
  const [user] = useContext(UserContext)
  const [technicianId, setTechnicianId] = useState('')
  const [dateToSelect, setDateToSelect] = useState(
    DateTime.now().toISO().slice(0, -19)
  )
  const technicians = global.technicians.filter(
    technician => technician.active && technician.isEmployee
  )

  const handleCreateTimeCard = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_time_card`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        technician_id: technicianId,
        date: dateToSelect
      }),
    }).then(res => {
      if (res.ok) {
        setCurrentTab('unaccepted')
      } else {
        window.alert('Error')
      }
    })
  }
  const handleChange = evt => {
    if (evt.target.value === 'clear') {
      setTechnicianId('')
    } else {
      setTechnicianId(evt.target.value)
    }
  }

  return (
    <Container disableGutters maxWidth='sm'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '2px solid #ddd',
          padding: '1rem',
        }}
      >
        <FormControl fullWidth size='small'>
          <InputLabel>Technician</InputLabel>
          <Select
            fullWidth
            color='primary'
            size='small'
            label='Technician'
            value={technicianId}
            onChange={handleChange}
          >
            <MenuItem value='clear'>
              <i>clear</i>
            </MenuItem>
            {technicians.map(technician => (
              <MenuItem key={technician.id} value={technician.id}>
                <>
                  {technician.firstName} {technician.lastName}
                </>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <input
          type='date'
          value={dateToSelect}
          defaultValue={DateTime.now().toISO().slice(0, -19)}
          onChange={e => setDateToSelect(e.target.value)}
        />
      </Box>
      <Box
        sx={{
          borderRadius: '0px 0px 20px 20px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem',
          background: '#f1f1f1',
        }}
      >
        <Button
          onClick={handleCreateTimeCard}
          variant='contained'
          disableElevation
        >
          Create
        </Button>
      </Box>
    </Container>
  )
}

export default New
