import { useContext } from 'react'
import { DateTime } from 'luxon'
import { UserContext } from 'UserStore'
import { GlobalContext } from 'GlobalStore'
import { Grid, TextField, Box, Typography, Button } from '@mui/material'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { TodayRounded } from '@mui/icons-material'
import TaskLink from 'components/TaskLink'

const ManualTasks = ({ tasks }) => {
  const [global] = useContext(GlobalContext)
  const [user] = useContext(UserContext)
  const [startDatetime, setStartDatetime] = useLocalStorage('myTasksStartDateRaimge', null, false)
  const [endDatetime, setEndDatetime] = useLocalStorage('myTasksEndDateRaimge', null, false)

  const userById = id => global.users.find(user => user.id === id)
  const userName = id => {
    const user = userById(id)

    return (user && `${user.firstName} ${user.lastName[0]}`) || 'UNKNOWN'
  }

  const allManualTasks = tasks.filter(task => !task.completedAt).filter(task => task.type === 'Task::Types::Manual')

  const manualTasksToShow = allManualTasks
    .filter(task => {
      if (startDatetime && endDatetime) {
        return (
          DateTime.fromISO(task.greenAt) >
            DateTime.fromFormat(startDatetime.replace('T', ' '), 'yyyy-MM-dd').startOf('day') &&
          DateTime.fromISO(task.greenAt) < DateTime.fromFormat(endDatetime.replace('T', ' '), 'yyyy-MM-dd').endOf('day')
        )
      }

      if (endDatetime) {
        return (
          DateTime.fromISO(task.greenAt) < DateTime.fromFormat(endDatetime.replace('T', ' '), 'yyyy-MM-dd').endOf('day')
        )
      }

      if (startDatetime) {
        return (
          DateTime.fromISO(task.greenAt) >
          DateTime.fromFormat(startDatetime.replace('T', ' '), 'yyyy-MM-dd').startOf('day')
        )
      }
      return task
    })
    .sort((a, b) => (a.greenAt > b.greenAt ? 1 : -1))

  const myTasks = manualTasksToShow.filter(task => task.assignedToUserId === user.id)
  const notMyTasks = manualTasksToShow
    .filter(task => task.assignedToUserId !== user.id)
    .filter(task => !task.exclusiveUserId)

  const handleClickTodayButton = () => {
    setStartDatetime(DateTime.now().startOf('day').toFormat('yyyy-MM-dd'))
    setEndDatetime(DateTime.now().endOf('day').toFormat('yyyy-MM-dd'))
  }

  const handleClickThisWeekButton = () => {
    setStartDatetime(DateTime.now().startOf('day').startOf('week').toFormat('yyyy-MM-dd'))
    setEndDatetime(DateTime.now().endOf('day').endOf('week').toFormat('yyyy-MM-dd'))
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div style={{ padding: '0.25em 0.5em' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
            <Button
              sx={{ minWidth: '100px', mr: '.5rem' }}
              onClick={handleClickTodayButton}
              variant='contained'
              disableElevation
              startIcon={<TodayRounded />}
            >
              Today
            </Button>

            <Button
              sx={{ minWidth: '100px', mr: '.5rem', whiteSpace: 'nowrap' }}
              onClick={handleClickThisWeekButton}
              variant='outlined'
              startIcon={<TodayRounded />}
            >
              This Week
            </Button>
            <TextField
              size='small'
              label='start date'
              type='date'
              id='new-job-start-time'
              value={startDatetime}
              onChange={e => setStartDatetime(e.target.value)}
              sx={{ minWidth: '250px' }}
            />

            <TextField
              size='small'
              label='end date'
              type='date'
              id='new-job-start-time'
              value={endDatetime}
              onChange={e => setEndDatetime(e.target.value)}
              sx={{ minWidth: '250px' }}
            />
          </Box>
        </div>
      </Grid>

      <Grid item xs={5.5}>
        <div style={{ backgroundColor: 'white' }}>
          <Typography variant='h6' sx={{ fontSize: '12px', mb: '.5rem' }}>
            Assigned to me
          </Typography>

          {myTasks.map((task, index) => (
            <div style={{ padding: '3px 1em' }} key={task.id}>
              <div>
                {userName(task.assignedToUserId)}
                &nbsp;-&nbsp;
                {DateTime.fromISO(task.greenAt).toFormat('DD t')}
              </div>
              <TaskLink task={task} />
              {task.claimedByUserId && <b> &nbsp;&#8212;Claimed: {userName(task.assignedToUserId)} </b>}
            </div>
          ))}
        </div>
      </Grid>

      <Grid item xs={0.25}>
        <Box sx={{ height: '100%', background: '#dcefff', width: '2px' }} />
      </Grid>

      <Grid item xs={5.5}>
        <div style={{ backgroundColor: 'white' }}>
          <Typography variant='h6' sx={{ fontSize: '12px', mb: '.5rem' }}>
            Assigned to others
          </Typography>

          {notMyTasks.map((task, index) => (
            <div style={{ padding: '3px 1em' }} key={task.id}>
              <div>
                {userName(task.assignedToUserId)}
                &nbsp;-&nbsp;
                {DateTime.fromISO(task.greenAt).toFormat('DD t')}
              </div>
              <TaskLink task={task} target='_blank' />
              {task.claimedByUserId && <b> &nbsp;&#8212;Claimed: {userName(task.assignedToUserId)} </b>}
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  )
}

export default ManualTasks
