import { DateTime } from 'luxon'

export const groupByDayCompleted = timeCards =>
timeCards.reduce((acc, timeCard) => {
  const key = DateTime.fromISO(timeCard.timeCardDate).startOf('day').toISODate()
  return {
    ...acc,
    [key]: [...(acc[key] || []), timeCard],
  }
}, {})

export const groupByTechnician = timeCards =>
timeCards.reduce((acc, timeCard) => {
  const key = timeCard.technician.id
  return {
    ...acc,
    [key]: [timeCard, ...(acc[key] || [])],
  }
}, {})

export const groupByDayCompletedThenTechnician = timeCards =>
Object.entries(groupByDayCompleted(timeCards)).reduce(
  (acc, [isoDay, timeCards]) => ({
    ...acc,
    [isoDay]: groupByTechnician(timeCards),
  }),
  {}
)

export const modalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '2px',
  minWidth: '500px',
  borderRadius: '20px',
}
