import { Box, Chip, Paper, Typography } from '@mui/material'
import { Interview } from 'hooks/recruiting/interviews/useInterviews/query'
import { DateTime } from 'luxon'

interface CalendarInterviewsProps {
  interviews?: Interview[]
}

const CalendarInterviews = ({ interviews }: CalendarInterviewsProps) => {
  return (
    <Box>
      {interviews?.map(interview => {
        return (
          <Paper key={interview.startDatetime} sx={{ py: 0.5, px: 0.5, my: 2 }}>
            <Box sx={{ py: 0.5 }}>
              <b style={{ fontSize: '10px', display: 'block' }}>
                {interview?.endDatetime && DateTime.fromISO(interview?.endDatetime).toFormat('MM-dd-yyyy')}
              </b>
              <b>{interview?.applicant?.fullName} </b>
            </Box>
            <a
              key={interview?.id}
              href={`/applications/${interview?.jobApplication?.id}/interviews/${interview?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <Typography sx={{ fontSize: '.9rem' }}>
                from: {interview?.startDatetime && DateTime.fromISO(interview?.startDatetime).toFormat('t')}
              </Typography>
              <Typography sx={{ fontSize: '.9rem' }}>
                to: {interview?.endDatetime && DateTime.fromISO(interview?.endDatetime).toFormat('t')}
              </Typography>
            </a>
            {interview?.status && <Chip size='small' label={interview.status} />}
          </Paper>
        )
      })}
    </Box>
  )
}

export default CalendarInterviews
