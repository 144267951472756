import { useEffect, useState } from 'react'
import { Dollars, basicDateTimeFormatter, keysToCamel } from 'tools'
import { Popover, TextField, Box, DialogContent, DialogActions, Chip, Dialog } from '@mui/material'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import FilterListIcon from '@mui/icons-material/FilterList'
import SvgIcon from '@mui/material/SvgIcon'
import ChatMessageCount from 'components/ChatMessageCount'
import ShowEditV1 from 'BToB/Invoices/BToBInvoice/ShowEditV1'
import ShowEdit from 'BToB/Invoices/BToBInvoice/ShowEdit'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { BToBInvoicesConnection, DEALERS_QUERY, removeSearch, useStyles } from './tools'
import { SelectBusinessHeadCell } from './BToBInvoice/Components/SelectBusinessHeadCell'
import { SelectTechnicianHeadCell } from './BToBInvoice/Components/SelectTechnicianHeadCell'
import { DateRangeHeadCell } from './BToBInvoice/Components/DateRangeHeadCell'
import { SelectLabelHeadCell } from './BToBInvoice/Components/SelectLabelHeadCell'
import { VinSearchTextField } from './BToBInvoice/Components/VinSearchTextField'

export const AllInvoices = () => {
  const classes = useStyles()
  const [modalItemId, setModalItemId] = useState(null)
  const parseState = true

  const [search, setSearch] = useLocalStorage('bToBInvoicesSearchFromDealerPage', {}, parseState)
  const [order, setOrder] = useLocalStorage('bToBInvoicesOrderFromDealerPage', {}, parseState)
  const [filter, setFilter] = useLocalStorage('bToBInvoicesFilterFromDealerPage', {}, parseState)
  const [limit, setLimit] = useLocalStorage('bToBInvoicesLimitFromDealerPage', 50)

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: Number(limit),
        after: '',
      },
    })
  }, [search, order, filter, limit])

  const { loading, error, data, refetch, fetchMore } = useQuery(BToBInvoicesConnection, {
    variables: {
      search,
      order,
      filter,
      first: Number(limit),
      after: cursorCeiling,
    },
  })

  const { data: dealersData } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const invoices = data.bToBInvoicesConnection.edges.map(edge => edge.node)
  const totalCount = data.bToBInvoicesConnection.totalCount
  const endCursor = data.bToBInvoicesConnection.pageInfo.endCursor

  const dealers = dealersData?.bToBDealers || []

  const SortSearchHeadCell = ({ label, field, searchOnType }) => {
    const [value, setValue] = useState(search[field] ? search[field] : '')
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClickApply = () => {
      if (value === '') {
        removeSearch({ field, setSearch, search })
      } else {
        setSearch({ ...search, [field]: value })
      }
      setAnchorEl(null)
    }
    const handleClearFilter = () => {
      removeSearch({ field, setSearch, search })
      setValue('')
      setAnchorEl(null)
    }
    const handleClickClose = () => setAnchorEl(null)
    const handleChange = e => {
      setValue(e.target.value)
      if (searchOnType) {
        setSearch({ ...search, [field]: value })
      }
    }

    return (
      <TableCell>
        <Box>
          {label}
          <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={e => setAnchorEl(e.currentTarget)}>
            <SvgIcon fontSize='small' component={FilterListIcon} />
          </Button>
        </Box>

        {search[field] && search[field] !== '' && (
          <Chip variant='outlined' size='small' onDelete={handleClearFilter} label={search[field]} />
        )}

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <DialogContent>
            <TextField fullWidth autoFocus size='small' variant='outlined' value={value} onChange={handleChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickClose} color='cancel'>
              Close
            </Button>
            <Button onClick={handleClickApply} color='primary' autoFocus>
              Apply
            </Button>
          </DialogActions>
        </Popover>
      </TableCell>
    )
  }

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: Number(limit),
          after: endCursor,
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }
  const foundInvoiceForModal = modalItemId && invoices.find(invoice => invoice.id === modalItemId)

  return (
    <>
      <Box sx={{ padding: '1rem' }}>
        <VinSearchTextField setSearch={setSearch} search={search} field='vin' />
      </Box>

      <TableContainer component={Paper}>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow>
              <DateRangeHeadCell
                setOrder={setOrder}
                name='completed_at'
                filter={filter}
                setFilter={setFilter}
                order={order}
              />
              <DateRangeHeadCell
                setOrder={setOrder}
                name='fully_paid_at'
                filter={filter}
                setFilter={setFilter}
                order={order}
              />
              <SelectTechnicianHeadCell align='right' filter={filter} setFilter={setFilter} />
              <SelectBusinessHeadCell align='right' filter={filter} setFilter={setFilter} dealers={dealers} />
              <TableCell align='right'>VIN</TableCell>
              <TableCell align='right'>RO #</TableCell>
              <TableCell align='right'>Invoice Total</TableCell>
              <SelectLabelHeadCell filter={filter} setFilter={setFilter} />
              <SortSearchHeadCell order={order} label='invoice #.' field='invoice_number' />
              <TableCell align='right'>Paid Sq. Invoice #</TableCell>
              <TableCell align='right'># Items</TableCell>
              <TableCell align='right'>Info</TableCell>
              <TableCell align='right'>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.slice(page * limit, page * limit + limit).map(invoice => (
              <TableRow key={invoice.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  <Link target='_blank' to={`/b-to-b-invoices/${invoice.id}`}>
                    {basicDateTimeFormatter(invoice.completedAt)}
                  </Link>
                </TableCell>
                <TableCell>{basicDateTimeFormatter(invoice.fullyPaidAt)}</TableCell>
                <TableCell align='right'>
                  <Link target='_blank' to={`/technicians/${invoice.technician.id}`}>
                    {invoice.technician.firstName}
                    &nbsp;
                    {invoice.technician.lastName}
                  </Link>
                </TableCell>
                <TableCell align='right'>{invoice.businessName}</TableCell>
                <TableCell align='right'>{invoice.vin}</TableCell>
                <TableCell align='right'>{invoice.repairOrder}</TableCell>
                <TableCell align='right'>
                  <b>
                    <Dollars value={invoice.grandTotal || invoice.lineItemsSum} />
                  </b>
                  &nbsp;
                  <button onClick={() => setModalItemId(invoice.id)}>show</button>
                </TableCell>
                <TableCell align='right'>{invoice.label && <Chip size='small' label={invoice.label} />}</TableCell>

                <TableCell align='right'>{invoice.invoiceNumber}</TableCell>
                <TableCell align='right'>{invoice.paidSquareInvoiceId}</TableCell>
                <TableCell align='right'>{invoice.lineItems.length}</TableCell>
                <TableCell align='right'>
                  {invoice.year}, {invoice.make} {invoice.model}
                </TableCell>
                <TableCell align='right'>
                  <ChatMessageCount contextType='BToBInvoice' contextId={invoice.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog PaperProps={{ sx: { maxWidth: '100%' } }} open={modalItemId} onClose={() => setModalItemId(null)}>
        <Box>
          {foundInvoiceForModal &&
            (foundInvoiceForModal.invoiceVersion > 1 ? (
              <ShowEdit refetch={refetch} invoice={keysToCamel(invoices.find(invoice => invoice.id === modalItemId))} />
            ) : (
              <ShowEditV1
                refetch={refetch}
                invoice={keysToCamel(invoices.find(invoice => invoice.id === modalItemId))}
              />
            ))}
        </Box>
      </Dialog>
    </>
  )
}
