import { useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Link, useParams, useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { DateTime } from 'luxon'

const GET_APPOINTMENTS = gql`
  query getAppointments($quoteId: ID!, $includePending: Boolean, $includeCanceled: Boolean) {
    appointments(quoteId: $quoteId, includePending: $includePending, includeCanceled: $includeCanceled) {
      id
      quoteId
      leadId
      technicianId
      startDatetime
      endDatetime
    }
  }
`

const GET_TECHNICIAN = gql`
  query getTechnician($id: ID!) {
    technician(id: $id) {
      id
      name
    }
  }
`

const useStyles = makeStyles({
  appointmentCard: {
    padding: '0.7em',
    border: '1px solid #83cfff',
    borderRadius: '3px',
    margin: '0.5em',
    backgroundColor: 'ghostwhite',
  },
})

const Appointments = ({ quote, hideCreateJob }) => {
  let history = useHistory()

  const { loading, error, data, refetch } = useQuery(GET_APPOINTMENTS, {
    variables: {
      quoteId: quote.id,
      includeCanceled: true,
      includePending: true,
    },
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return (
    <ul>
      {data.appointments.map(appointment => (
        <AppointmentLi key={appointment.id} appointment={appointment} />
      ))}
    </ul>
  )
}

const AppointmentLi = ({ appointment }) => {
  const {  quoteId } = useParams()

  const { loading, error, data } = useQuery(GET_TECHNICIAN, {
    variables: { id: appointment.technicianId },
  })
  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  let startDatetime = DateTime.fromISO(appointment.startDatetime, { zone: 'utc' })
  let endDatetime = DateTime.fromISO(appointment.endDatetime, { zone: 'utc' })

  return (
    <li>
      <Link to={`/technicians/${data.technician.id}`}>{data.technician.name}</Link>
      <span style={{ padding: '0 5px' }}>-&gt;</span>
      <Link to={`/leads/${appointment.leadId}/quotes/${quoteId}/appointments/${appointment.id}`}>
        {startDatetime.toFormat('cccc, yyyy LLL dd')}, {startDatetime.toFormat('h:mm a')} -{' '}
        {endDatetime.toFormat('h:mm a')}
      </Link>
      {appointment.canceledAt && <b style={{ color: 'crimson', marginLeft: '1em' }}>CANCELED</b>}
    </li>
  )
}

export default Appointments
