import { useQuery } from '@apollo/client'
import { GET_INTERVIEW, GetInterviewReq, GetInterviewRes } from './query'
import { api } from 'utils/axios'

export const useInterview = (id?: string) => {
  const { data, refetch } = useQuery<GetInterviewRes, GetInterviewReq>(GET_INTERVIEW, {
    variables: { id },
    skip: !id,
  })

  const cancel = () => {
    api
      .post('/cancel_interview', {
        interview_id: id,
      })
      .then(res => {
        if (res.status === 200) alert('Canceled interview successfully')
        else alert('Failed to cancel interview')
        refetch()
      })
  }

  return { interview: data?.interview, cancel }
}
