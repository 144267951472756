import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Part from './Part'

const PartRouter = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:partId`}>
        <Part />
      </Route>
    </Switch>
  )
}

export default PartRouter
