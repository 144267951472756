import { Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { useQuery, gql } from '@apollo/client'
import { useEffect, useState } from 'react'
import { markets } from 'constants.js'
import { DateTime } from 'luxon'
import { keysToCamel } from 'tools'
import { CalendarTodayRounded, KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material'
import { JobType } from 'types/types'
import { DayLaborValue } from 'Schedule/components/DayLaborValue'
import { JobBlock } from './JobBlock'
import { Link } from 'react-router-dom'
// @ts-ignore
import Geocode from 'react-geocode'
// @ts-ignore
import { useJsApiLoader } from '@react-google-maps/api'
import { TravelDistanceFromLeadIdToCoordinates } from 'Leads/AvailabilityMap'

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
Geocode.setApiKey(GOOGLE_MAPS_API_KEY)

const JOBS = gql`
  query JobsConnection($search: JSON, $order: JSON, $filter: JSON, $first: Int, $after: String) {
    jobsConnection(search: $search, order: $order, filter: $filter, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          leadId
          quoteId
          startDatetime
          endDatetime
          canceledAt
          technicianId
          rescheduleHistory
          heldForReschedulingAt
          isActive
          technician {
            id
            name
            availability
            isEmployee
            skillsets
            lat
            lng
          }
          lead {
            id
            name
            market
            addressLineOne
            city
            state
            zip
            jobs {
              id
              startDatetime
              endDatetime
            }
          }
          quote {
            id
            balanceAmountDue
            preJobAmountDue
            payments
            paymentStatus
            difficultyLevel
            requiredSkillsets
            parts {
              id
              status
              name
              number
              price
              cost
              prePaymentRequired
              etaBusinessDays
              etaLastUpdated
            }
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const EmployeeContractorSwapper = () => {
  const [jobDateReference, setJobDateReference] = useState(DateTime.now())
  const [market, setMarket] = useState('all')
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem('jobsOrder') || '{}') || {})
  const [search, setSearch] = useState(JSON.parse(localStorage.getItem('jobsSearch') || '{}') || {})
  const [limit, setLimit] = useState(50)
  const [filter, setFilter] = useState<any>({
    start_datetime: { ge: jobDateReference.startOf('day').toISO(), le: jobDateReference.endOf('day').toISO() },
  })
  const [page, setPage] = useState(0)
  const [cursorCeiling, setCurrentCeiling] = useState('')
  const [selectedJob, setSelectedJob] = useState<any>(null)
  const [showDrivingDistance, setShowDrivingDistance] = useState(false)

  const removeFilter = (filterName: string) => {
    const { [filterName]: removed, ...remainingFilters } = filter
    setFilter(remainingFilters)
  }

  const { isLoaded } = useJsApiLoader({
    // @ts-ignore
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  })

  const [leadCoordinates, setLeadCoordinates] = useState({ lat: null, lng: null })

  useEffect(() => {
    if (selectedJob) {
      if (selectedJob.lead.addressLineOne) {
        Geocode.fromAddress(
          `${selectedJob.lead.addressLineOne}, ${selectedJob.lead.city}, ${selectedJob.lead.state}`
        ).then(
          (response: any) => {
            const { lat, lng } = response.results[0].geometry.location
            setLeadCoordinates({ lat, lng })
          },
          (error: any) => {
            console.error(error)
          }
        )
      } else {
        Geocode.fromAddress(`${selectedJob.lead.zip}`).then(
          (response: any) => {
            const { lat, lng } = response.results[0].geometry.location
            setLeadCoordinates({ lat, lng })
          },
          (error: any) => {
            console.error(error)
          }
        )
      }
    }
  }, [selectedJob])

  const { loading, error, data, fetchMore, refetch } = useQuery(JOBS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      search,
      order,
      filter,
      first: limit,
      after: cursorCeiling,
    },
  })

  useEffect(() => {
    fetchMore({ variables: { filter } })
    setSelectedJob(null)
    setShowDrivingDistance(false)
  }, [filter])

  useEffect(() => {
    setShowDrivingDistance(false)
  }, [selectedJob])

  useEffect(() => {
    setFilter({
      ...filter,
      start_datetime: { ge: jobDateReference.startOf('day').toISO(), le: jobDateReference.endOf('day').toISO() },
    })
  }, [jobDateReference])

  useEffect(() => {
    market !== 'all' &&
      setFilter({
        ...filter,
        market: { eq: market },
      })
  }, [market])

  const jobs = data?.jobsConnection.edges.map((edge: any) => edge.node) || []

  console.log(jobs)

  const MarketSelect = () => {
    const [value, setValue] = useState(market || 'all')

    const handleChange = (evt: any) => {
      if (evt.target.value === 'all') {
        removeFilter('market')
        setMarket('all')
      } else {
        setMarket(evt.target.value)
      }
    }

    return (
      <FormControl fullWidth>
        <InputLabel shrink>Market</InputLabel>
        <Select variant='standard' value={value} onChange={handleChange}>
          <MenuItem value='all'>all</MenuItem>
          {markets.map((market: string) => (
            <MenuItem key={market} value={market}>
              {market}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const groupedEmployees = Object.values(
    jobs
      .filter((job: JobType) => job.technician.isEmployee)
      .slice()
      .reduce(
        (acc: any, job: JobType) =>
          acc[job.technician.id]
            ? {
                ...acc,
                [job.technician.id]: {
                  jobs: [...acc[job.technician.id].jobs, job],
                  technician: job.technician,
                },
              }
            : { ...acc, [job.technician.id]: { jobs: [job], technician: job.technician } },
        {}
      )
  )

  const groupedContractors = Object.values(
    jobs
      .filter((job: JobType) => !job.technician.isEmployee)
      .slice()
      .reduce(
        (acc: any, job: JobType) =>
          acc[job.technician.id]
            ? {
                ...acc,
                [job.technician.id]: {
                  jobs: [...acc[job.technician.id].jobs, job],
                  technician: job.technician,
                },
              }
            : { ...acc, [job.technician.id]: { jobs: [job], technician: job.technician } },
        {}
      )
  )

  const selectedJobSkillsets =
    selectedJob && selectedJob.quote.requiredSkillsets.concat([selectedJob.quote.difficultyLevel])

  const checkSubset = (parentArray: Array<string>, subsetArray: Array<string>) => {
    return subsetArray.every((el: string) => {
      return parentArray.includes(el)
    })
  }

  return (
    <Container maxWidth='xl' sx={{ pt: '1rem' }}>
      <Box
        sx={{
          textAlign: 'center',
          background: '#3852B0',
          fontSize: '11px',
          fontWeight: 600,
          color: '#FFF',
        }}
      >
        JOB SWAPPER (BETA)
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          background: '#f1f1f1',
          justifyContent: 'space-between',
          padding: '.5rem 0rem',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={() => setJobDateReference(DateTime.now())}>
            <CalendarTodayRounded />{' '}
          </Button>
          {jobDateReference.toFormat('ccc LLL d, yyyy')}

          {/* <input value={jobDateReference.toISO()} type='date' /> */}
          <Button sx={{ ml: 'auto' }} onClick={() => setJobDateReference(jobDateReference.minus({ days: 1 }))}>
            <KeyboardArrowLeftRounded />{' '}
          </Button>
          <Button onClick={() => setJobDateReference(jobDateReference.plus({ days: 1 }))}>
            <KeyboardArrowRightRounded />{' '}
          </Button>
        </Box>
        {selectedJob && (
          <Box sx={{ ml: 'auto', mr: '1rem' }}>
            <Button variant='contained' size='small' onClick={() => setShowDrivingDistance(true)}>
              click to show driving distance
            </Button>
          </Box>
        )}
        <Box sx={{ width: '150px' }}>
          <MarketSelect />
        </Box>
      </Box>

      <Grid container>
        <Grid item xs={6} sx={{ borderRight: '1px solid #ddd' }}>
          <Box
            sx={{
              background: '#f1f1f1',
              textAlign: 'center',
              borderTop: '2px solid #ddd',
              borderBottom: '2px solid #ddd',
            }}
          >
            {groupedEmployees.length} employee{groupedEmployees.length !== 1 && 's'}
            <DayLaborValue
              jobsForDay={jobs
                .filter((job: JobType) => job.technician.isEmployee)
                .map((job: JobType) => keysToCamel(job))}
              dateIsCurrentDay={false}
            />
          </Box>
          <Box sx={{ padding: '1rem', display: 'flex', flexWrap: 'wrap' }}>
            {groupedEmployees.map((jobTechBlock: any) => {
              const techCanSwapWithSelectedJob =
                selectedJobSkillsets && checkSubset(jobTechBlock.technician.skillsets, selectedJobSkillsets)
              return (
                <Box sx={{ width: '160px', padding: '1rem' }}>
                  <Link to={`/technicians/${jobTechBlock.technician.id}`} style={{ fontSize: '11px' }}>
                    {jobTechBlock.technician.name}
                  </Link>
                  {techCanSwapWithSelectedJob &&
                    showDrivingDistance &&
                    leadCoordinates?.lat &&
                    leadCoordinates?.lng && (
                      <TravelDistanceFromLeadIdToCoordinates
                        text='tech distance from selected job'
                        fromLeadId={selectedJob.lead.id}
                        toCoordinates={{
                          lat: Number(jobTechBlock.technician.lat),
                          lng: Number(jobTechBlock.technician.lng),
                        }}
                      />
                    )}

                  <DayLaborValue
                    dateIsCurrentDay={false}
                    jobsForDay={jobTechBlock.jobs.map((job: JobType) => keysToCamel(job))}
                  />
                  {jobTechBlock.jobs
                    .sort((a: JobType, b: JobType) => (a.startDatetime > b.startDatetime ? 1 : -1))
                    .map((job: any) => (
                      <JobBlock
                        leadCoordinates={leadCoordinates}
                        refetch={refetch}
                        showDrivingDistance={showDrivingDistance}
                        techCanSwapWithSelectedJob={techCanSwapWithSelectedJob}
                        selectedJob={selectedJob}
                        setSelectedJob={setSelectedJob}
                        job={job}
                        key={job.id}
                      />
                    ))}
                </Box>
              )
            })}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              background: '#f1f1f1',
              textAlign: 'center',
              borderTop: '2px solid #ddd',
              borderBottom: '2px solid #ddd',
            }}
          >
            {groupedContractors.length} contractor{groupedContractors.length !== 1 && 's'}
            <DayLaborValue
              jobsForDay={jobs
                .filter((job: JobType) => !job.technician.isEmployee)
                .map((job: JobType) => keysToCamel(job))}
              dateIsCurrentDay={false}
            />
          </Box>
          <Box sx={{ padding: '1rem', display: 'flex', flexWrap: 'wrap' }}>
            {groupedContractors.map((jobTechBlock: any) => {
              const techCanSwapWithSelectedJob =
                selectedJobSkillsets && checkSubset(jobTechBlock.technician.skillsets, selectedJobSkillsets)

              return (
                <Box sx={{ width: '160px', padding: '1rem' }}>
                  <Link to={`/technicians/${jobTechBlock.technician.id}`} style={{ fontSize: '11px' }}>
                    {jobTechBlock.technician.name}
                  </Link>
                  {techCanSwapWithSelectedJob &&
                    showDrivingDistance &&
                    leadCoordinates?.lat &&
                    leadCoordinates?.lng && (
                      <TravelDistanceFromLeadIdToCoordinates
                        text='tech distance from selected job'
                        fromLeadId={selectedJob.lead.id}
                        toCoordinates={{
                          lat: Number(jobTechBlock.technician.lat),
                          lng: Number(jobTechBlock.technician.lng),
                        }}
                      />
                    )}
                  <DayLaborValue
                    dateIsCurrentDay={false}
                    jobsForDay={jobTechBlock.jobs.map((job: JobType) => keysToCamel(job))}
                  />
                  {jobTechBlock.jobs
                    .sort((a: JobType, b: JobType) => (a.startDatetime > b.startDatetime ? 1 : -1))
                    .map((job: any) => (
                      <JobBlock
                        leadCoordinates={leadCoordinates}
                        showDrivingDistance={showDrivingDistance}
                        refetch={refetch}
                        techCanSwapWithSelectedJob={techCanSwapWithSelectedJob}
                        selectedJob={selectedJob}
                        setSelectedJob={setSelectedJob}
                        job={job}
                        key={job.id}
                      />
                    ))}
                </Box>
              )
            })}
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
