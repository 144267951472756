import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import { CustomerContext } from './CustomerStore'
import { Grid } from '@mui/material'

const CustomerHistory = () => {
  const { history } = useContext(CustomerContext)

  return (
    <Grid container spacing={0.5}>
      {history.map(item => (
        <Grid item xs={12} key={item.timestamp}>
          &nbsp;&nbsp;
          <Link to={item.context.path}>
            {item.customer.firstName} {item.customer.lastName}
          </Link>
          &nbsp;
          <small>
            <b>{item.context.type}</b>
          </small>
          &nbsp;
          <small>{DateTime.fromMillis(item.timestamp).toFormat('ccc MM/d, hh:mm a')}</small>
        </Grid>
      ))}
    </Grid>
  )
}

export default CustomerHistory
