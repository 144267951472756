import { gql } from '@apollo/client'

export interface JobApplication {
  id?: string
  jobListingId?: string
  applicantId?: string
  coverLetter?: string
  resumeUrl?: string
  createdAt?: string
  rejectedAt?: string
  markedForInterviewAt?: string
  rejectionReason?: string
  withdrewAt?: string

  jobListing?: {
    id?: string
    jobTitle?: string
  }
  applicant?: {
    id?: string
    fullName?: string
    phone?: string
    email?: string
  }
  interviews?: {
    id?: string
    startDatetime?: string
    recruiter?: {
      id?: string
      firstName?: string
      lastName?: string
    }
  }[]
}

export interface GetJobApplicationsRes {
  jobApplications?: JobApplication[]
}

export const JOB_APPLICATIONS_CONNECTION = gql`
  query JobApplicationsConnection($order: JSON, $filter: JSON, $first: Int, $after: String, $search: JSON) {
    jobApplicationsConnection(order: $order, filter: $filter, first: $first, after: $after, search: $search) {
      edges {
        cursor
        node {
          id
          jobListingId
          applicantId
          coverLetter
          resumeUrl
          createdAt
          rejectedAt
          markedForInterviewAt
          rejectionReason
          withdrewAt
          status
          jobListing {
            id
            jobTitle
          }
          applicant {
            id
            fullName
          }
          interviews {
            id
            createdAt
            startDatetime
            recruiter {
              id
              firstName
              lastName
            }
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
