import { createContext } from 'react'
import { useChannel } from '@ericlathrop/phoenix-js-react-hooks'

export const ReadCacheChannelContext = createContext()

const ReadCacheChannelProvider = ({ children }) => {
  const readCacheChannel = useChannel('mongo:activity', undefined, () => {})

  return <ReadCacheChannelContext.Provider value={readCacheChannel}>{children}</ReadCacheChannelContext.Provider>
}

export default ReadCacheChannelProvider
