import {
  Paper, Link, Table, TableHead, TableCell, TableBody,
  TableRow, Typography, Button, SvgIcon, TablePagination
} from '@mui/material'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { useQuery } from '@apollo/client'
import { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../GlobalStore'
import { CALLBACK_REQUESTS, callbackRequestsTableHeaders } from './constants'
import { style } from './style'
import { callbackRequestsForTable, FilterBar } from './tools.js'

const CallBackRequestData = () => {
  const [global] = useContext(GlobalContext)
  const [order, setOrder] = useState(
    JSON.parse(localStorage.getItem('callbackRequestsOrder')) || {}
  )
  const [filter, setFilter] = useState({})
  const [limit, setLimit] = useState(
    JSON.parse(localStorage.getItem('callbackRequestsLimit')) || 50
  )
  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const users = global.users

  const setOrToggleOrder = name => {
    setOrder({
      [name]: (order[name] && order[name] === 'desc' ? 'asc' : 'desc') || 'desc'
    })
  }

  useEffect(() => {
    if (fetchMore) {
      fetchMore({
        variables: {
          order: order,
          filter: filter,
          first: limit
        }
      })
    }
  }, [filter, order, limit])

  const { loading, error, data, fetchMore } = useQuery(CALLBACK_REQUESTS, {
    variables: {
      search: {},
      order,
      filter: filter,
      first: limit,
      after: ''
    }
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const callbackRequests = data.callbackRequestsConnection.edges.map(
    edge => edge.node
  )
  const totalCount = data.callbackRequestsConnection.totalCount
  const endCursor = data.callbackRequestsConnection.pageInfo.endCursor

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          order,
          filter,
          first: limit,
          after: endCursor
        }
      })
    }
  }

  const handleChangeRowsPerPage = event => setLimit(event.target.value)

  const ToggleDirectionButton = ({ item }) => {
    return (
      <Button
        style={{ minWidth: 0 }}
        color='primary'
        disableElevation
        onClick={() => setOrToggleOrder(item === 'requested_at' ? 'created_at' : item)}
      >
        <SvgIcon
          fontSize='small'
          component={
            (order[item === 'requested_at' ? 'created_at' : item] === 'desc' ? ArrowDownward : ArrowUpward) ||
            ArrowDownward
          }
        />
      </Button>
    )
  }

  return (
    <Paper sx={{ ...style.mainPaper, padding: '0rem', borderRadius: '12px' }}>
      <FilterBar
        setLimit={setLimit}
        limit={limit}
        totalCount={totalCount}
        setFilter={setFilter}
        filter={filter}
        setOrder={setOrder}
        setPage={setPage}
        name='Callback requests data'
      />
      <Table>
        <TableHead>
          <TableRow>
            {[
              ...callbackRequestsTableHeaders,
              'Reason (customer)',
              'Reason (CSR)'
            ].map(item => (
              <TableCell key={item} size='small'>
                {(item === 'completed_at' || item === 'requested_at') && (
                  <ToggleDirectionButton item={item} key={item} />
                )}
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {callbackRequestsForTable(callbackRequests, page, limit, users).map(
            req => (
              <TableRow
                key={req.createdAt}
                sx={{
                  background: req.isSunday ? '#cd8c001a' : '#fff'
                }}
              >
                <TableCell size='small'>
                  {(req.lead && (
                    <Link href={`/leads/${req.lead?.id}`}>
                      {req.lead?.name}
                    </Link>
                  )) ||
                    'No lead'}
                </TableCell>

                <TableCell size='small'>
                  <Link href={`/callback-requests/${req.id}`}>
                    {req.requestedAt}
                  </Link>
                </TableCell>

                <TableCell>{req.timeToFirstResponse}</TableCell>

                <TableCell size='small'>{req.attempts}</TableCell>

                <TableCell size='small'>{req.attempts.length}</TableCell>

                <TableCell size='small'>{req.completedAt}</TableCell>

                <TableCell size='small'>
                  <Typography title={req.customerReasonOther} sx={style.reason}>
                    {req.customerReasonOther}
                  </Typography>
                </TableCell>

                <TableCell size='small'>
                  <Typography title={req.csrReasonOther} sx={style.reason}>
                    {req.csrReasonOther}
                  </Typography>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={totalCount}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default CallBackRequestData
