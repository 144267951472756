import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from 'UserStore'
import {
  Grid,
  Collapse,
  Button,
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Box,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { v4 as uuid } from 'uuid'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import PriceInput from 'components/PriceInput'
import SearchSelector from './SearchSelector'
import { useQuery, gql } from '@apollo/client'

const PARTS_CATALOG = gql`
  query PartCatalogItems {
    partCatalogItems {
      id
      name
    }
  }
`

const AddNewPart = ({ quote, editingMutex, setEditingMutex, editingDisabled, currentPhotoId, refetchQuote }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)

  const handleSavePart = part => {
    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_part_for_quote`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        quoteId: quote.id,
        part: part,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .finally(() => {
        setWaiting(false)
        resetForm()
        refetchQuote()
      })
  }

  const validationSchema = Yup.object().shape({
    id: Yup.string().required('This field is required.'),
    // quotePartId will be non-empty if the part exists in the database
    quotePartId: Yup.string().nullable(),
    // partId will be non-empty if the part does NOT exist in the database
    partId: Yup.string().nullable(),
    photoId: Yup.string(),
    name: Yup.string()
      .required('This field is required.')
      .test({
        test: value => value && value.trim().length > 1,
        message: 'Name must be at least 1 character in length.',
      }),
    type: Yup.string()
      .required('This field is required.')
      .test({
        test: value => value === 'aftermarket' || value === 'oem',
        message: 'Part type is required.',
      }),
    number: Yup.string().required('This field is required.'),
    cost: Yup.number()
      .nullable()
      .test({
        test: (value, context) => (context.parent.type === 'aftermarket' ? value > 0 : true),
        message: 'Cost is required.',
      }),
    price: Yup.number().required('This field is required for aftermarket parts.'),
    prePaymentRequired: Yup.boolean(),
  })

  const getInitialValues = partObject => ({
    id: uuid(),
    partId: partObject.partId || '',
    photoId: currentPhotoId,
    name: partObject.name || '',
    type: partObject.type || '',
    number: partObject.number || '',
    cost: partObject.cost || 0,
    price: partObject.price || 0,
    prePaymentRequired: partObject.prePaymentRequired || false,
  })

  const formik = useFormik({
    initialValues: getInitialValues({}),
    validationSchema: validationSchema,
    onSubmit: handleSavePart,
  })

  const resetForm = () => {
    formik.resetForm({
      values: getInitialValues({}),
    })

    setEditingMutex('')
  }

  useEffect(() => {
    if (editingDisabled) {
      resetForm()
    }
  }, [editingDisabled])

  useEffect(() => {
    if (editingMutex === '') {
      resetForm()
    } else if (editingMutex === 'parts') {
      formik.resetForm({
        values: getInitialValues({}),
      })
    }
  }, [editingMutex])

  useEffect(() => {
    formik.setFieldValue('photoId', currentPhotoId)
  }, [currentPhotoId])

  const disabled = editingDisabled

  const { loading, error, data, refetch } = useQuery(PARTS_CATALOG, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  if (error) return <div>Error!</div>

  const catalogItems = data?.partCatalogItems.slice().sort((a, b) => (a.name > b.name ? 1 : -1)) || []

  return (
    <>
      <Grid container sx={{ px: 1, pt: 0.3 }}>
        <Grid item xs={10}>
          {formik.values.partId !== '' && (
            <TextField
              fullWidth
              size='small'
              style={{ minWidth: '360px' }}
              name='name'
              onChange={formik.handleChange}
              type='search'
              value={formik.values.name}
              variant='standard'
              disabled={disabled}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => resetForm()}>
                      <CancelIcon fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          {formik.values.partId === '' && (
            <SearchSelector
              value={formik.values.name}
              placeholder='...'
              listOfObjects={catalogItems}
              attributeToFilterOn='name'
              renderOption={obj => obj.name}
              onSelectionMade={newValue => {
                if (!newValue) {
                  return resetForm()
                }
                formik.setValues({
                  ...formik.values,
                  partId: newValue.id,
                  name: newValue.name,
                })

                setEditingMutex('add-part')
              }}
              disabled={editingDisabled || editingMutex === 'parts'}
              name='parts-search-selector'
              listName='part-options-for-quote'
            />
          )}
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {waiting ? (
            <Box>
              <CircularProgress size={20} />
            </Box>
          ) : (
            <Button
              sx={{ ml: 3 }}
              size='small'
              onClick={formik.handleSubmit}
              disableElevation
              variant='contained'
              disabled={disabled}
              name='add-new-part-button'
            >
              Add
            </Button>
          )}
        </Grid>
      </Grid>
      <Collapse in={formik.values.partId !== ''} sx={{ width: '100%' }}>
        <Grid container columnSpacing={1} sx={{ px: 1, mt: 0.5 }}>
          <Grid item xs={3}>
            <FormControl required fullWidth>
              <InputLabel
                shrink
                style={{
                  marginTop: '8px',
                  color: formik.touched.type && Boolean(formik.errors.type) ? 'red' : '',
                }}
              >
                Type
              </InputLabel>
              <Select
                fullWidth
                variant='standard'
                size='small'
                name='type'
                cy-data='new-part-type'
                value={formik.values.type}
                error={formik.touched.type && Boolean(formik.errors.type)}
                onChange={event => {
                  formik.handleChange(event)
                  if (event.target.value === 'oem') {
                    formik.setFieldValue('prePaymentRequired', true)
                  }
                }}
                disabled={waiting}
              >
                <MenuItem value='oem'>OEM</MenuItem>
                <MenuItem value='aftermarket'>Aftermarket</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <TextField
              required
              fullWidth
              variant='standard'
              size='small'
              name='number'
              label='Part Number'
              InputProps={{ id: 'new-part-number' }}
              InputLabelProps={{ shrink: true }}
              value={formik.values.number}
              error={formik.touched.number && Boolean(formik.errors.number)}
              onChange={formik.handleChange}
              disabled={waiting}
            />
          </Grid>
          {formik.values.type === 'aftermarket' && (
            <Grid item xs={2}>
              <TextField
                required
                fullWidth
                variant='standard'
                size='small'
                name='cost'
                label='Our Cost'
                value={formik.values.cost}
                error={formik.touched.cost && Boolean(formik.errors.cost)}
                onChange={event => {
                  const numberValue = Number(event.target.value)

                  // update the price for aftermarket parts
                  if (formik.values.partId && numberValue > 0 && formik.values.type === 'aftermarket') {
                    formik.setFieldValue('price', (numberValue * 1.4).toFixed(2))
                  }

                  formik.handleChange(event)
                }}
                InputProps={{
                  inputComponent: PriceInput,
                  id: 'new-part-our-cost',
                }}
                InputLabelProps={{ shrink: true }}
                disabled={waiting}
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <TextField
              required
              fullWidth
              variant='standard'
              size='small'
              label='Quoted Price'
              name='price'
              value={formik.values.price}
              error={formik.touched.price && Boolean(formik.errors.price)}
              onChange={formik.handleChange}
              InputProps={{
                inputComponent: PriceInput,
                id: 'new-part-quoted-price',
              }}
              InputLabelProps={{ shrink: true }}
              disabled={waiting}
            />
          </Grid>
          <Grid item xs={3} sx={{ mx: 3 }}>
            <FormControl>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='prePaymentRequired'
                      onChange={formik.handleChange}
                      disabled={formik.values.type === 'oem' || waiting}
                      checked={formik.values.prePaymentRequired}
                    />
                  }
                  label='pre-pay'
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Collapse>
    </>
  )
}

export default AddNewPart
