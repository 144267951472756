import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'UserStore'
import { Grid, Button, InputLabel, Select, FormControl, MenuItem, CircularProgress } from '@mui/material'
import { v4 as uuid } from 'uuid'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import partsList from 'data/parts'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const AddNewPart = ({ workOrder, editingMutex, setEditingMutex, editingDisabled, currentPhotoId, refetch }) => {
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const handleSavePart = () => {
    const part = partsList.find(p => p.id === formik.values.partId)
    setWaiting(true)

    fetch(`${process.env.REACT_APP_API_HOST}/create_part_for_b_to_b_work_order`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        bToBWorkOrderId: workOrder.id,
        part: {
          id: part.id,
          name: part.name,
        },
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .finally(() => {
        setWaiting(false)
        resetForm()
        refetch()
      })
  }

  const validationSchema = Yup.object().shape({
    id: Yup.string().required('This field is required.'),
    // quotePartId will be non-empty if the part exists in the database
    quotePartId: Yup.string().nullable(),
    // partId will be non-empty if the part does NOT exist in the database
    partId: Yup.string().nullable(),
    photoId: Yup.string(),
    name: Yup.string()
      .required('This field is required.')
      .test({
        test: value => value && value.trim().length > 1,
        message: 'Name must be at least 1 character in length.',
      }),
    type: Yup.string()
      .required('This field is required.')
      .test({
        test: value => value === 'aftermarket' || value === 'oem',
        message: 'Part type is required.',
      }),
    number: Yup.string().required('This field is required.'),
    cost: Yup.number()
      .nullable()
      .test({
        test: (value, context) => (context.parent.type === 'aftermarket' ? value > 0 : true),
        message: 'Cost is required.',
      }),
    price: Yup.number().required('This field is required for aftermarket parts.'),
    prePaymentRequired: Yup.boolean(),
  })

  const getInitialValues = partObject => ({
    id: uuid(),
    partId: partObject.partId || '',
    photoId: currentPhotoId,
    name: partObject.name || '',
    type: partObject.type || '',
    number: partObject.number || '',
    cost: partObject.cost || 0,
    price: partObject.price || 0,
    prePaymentRequired: partObject.prePaymentRequired || false,
  })

  const formik = useFormik({
    initialValues: getInitialValues({}),
    validationSchema: validationSchema,
    onSubmit: handleSavePart,
  })

  const resetForm = () => {
    formik.resetForm({
      values: getInitialValues({}),
    })

    setEditingMutex('')
  }

  useEffect(() => {
    if (editingDisabled) {
      resetForm()
    }
  }, [editingDisabled])

  useEffect(() => {
    if (editingMutex === '') {
      resetForm()
    } else if (editingMutex === 'parts') {
      formik.resetForm({
        values: getInitialValues({}),
      })
    }
  }, [editingMutex])

  useEffect(() => {
    formik.setFieldValue('photoId', currentPhotoId)
  }, [currentPhotoId])

  const disabled = formik.values.partId === ''

  return (
    <>
      <Grid container sx={{ pt: '.5rem', pb: '.5rem' }}>
        <Grid item xs={10} sx={{ pr: '.5rem', pl: '.5rem' }}>
          <FormControl size='small' fullWidth>
            <InputLabel>Select a part</InputLabel>
            <Select
              name='partId'
              onChange={formik.handleChange}
              value={formik.values.partId}
              label='select a part'
              size='small'
              fullWidth
            >
              {partsList
                .slice()
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(item => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', pl: '.5rem', pr: '.5rem' }}>
          <Button
            size='small'
            fullWidth
            onClick={handleSavePart}
            disableElevation
            variant='contained'
            disabled={disabled || waiting}
            name='add-new-part-button'
            sx={{ minWidth: '30px' }}
          >
            {waiting ? <CircularProgress size={20} /> : <>Add</>}
          </Button>
        </Grid>
      </Grid>
      {/* <Collapse in={formik.values.partId !== ''} sx={{ width: '100%' }}>
        <Grid container columnSpacing={1} sx={{ px: 1, mt: 0.5 }}>
          <Grid item xs={3}>
            <FormControl required fullWidth>
              <InputLabel
                shrink
                style={{
                  marginTop: '8px',
                  color: formik.touched.type && Boolean(formik.errors.type) ? 'red' : '',
                }}
              >
                Type
              </InputLabel>
              <Select
                fullWidth
                variant='standard'
                size='small'
                name='type'
                cy-data='new-part-type'
                value={formik.values.type}
                error={formik.touched.type && Boolean(formik.errors.type)}
                onChange={event => {
                  formik.handleChange(event)
                  if (event.target.value === 'oem') {
                    formik.setFieldValue('prePaymentRequired', true)
                  }
                }}
                disabled={waiting}
              >
                <MenuItem value='oem'>OEM</MenuItem>
                <MenuItem value='aftermarket'>Aftermarket</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <TextField
              required
              fullWidth
              variant='standard'
              size='small'
              name='number'
              label='Part Number'
              InputProps={{ id: 'new-part-number' }}
              InputLabelProps={{ shrink: true }}
              value={formik.values.number}
              error={formik.touched.number && Boolean(formik.errors.number)}
              onChange={formik.handleChange}
              disabled={waiting}
            />
          </Grid>
          {formik.values.type === 'aftermarket' && (
            <Grid item xs={2}>
              <TextField
                required
                fullWidth
                variant='standard'
                size='small'
                name='cost'
                label='Our Cost'
                value={formik.values.cost}
                error={formik.touched.cost && Boolean(formik.errors.cost)}
                onChange={event => {
                  const numberValue = Number(event.target.value)

                  // update the price for aftermarket parts
                  if (formik.values.partId && numberValue > 0 && formik.values.type === 'aftermarket') {
                    formik.setFieldValue('price', (numberValue * 1.4).toFixed(2))
                  }

                  formik.handleChange(event)
                }}
                InputProps={{
                  inputComponent: PriceInput,
                  id: 'new-part-our-cost',
                }}
                InputLabelProps={{ shrink: true }}
                disabled={waiting}
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <TextField
              required
              fullWidth
              variant='standard'
              size='small'
              label='Quoted Price'
              name='price'
              value={formik.values.price}
              error={formik.touched.price && Boolean(formik.errors.price)}
              onChange={formik.handleChange}
              InputProps={{
                inputComponent: PriceInput,
                id: 'new-part-quoted-price',
              }}
              InputLabelProps={{ shrink: true }}
              disabled={waiting}
            />
          </Grid>
          <Grid item xs={3} sx={{ mx: 3 }}>
            <FormControl>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='prePaymentRequired'
                      onChange={formik.handleChange}
                      disabled={formik.values.type === 'oem' || waiting}
                      checked={formik.values.prePaymentRequired}
                    />
                  }
                  label='pre-pay'
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Collapse> */}
    </>
  )
}

export default AddNewPart
