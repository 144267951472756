import { useState, useContext } from 'react'
import { CreateATask } from 'Tasks'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { Typography, Grid, Button } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { keysToCamel, prettyPhoneNumber, basicDateTimeFormat } from 'tools'
import StreamDump from 'components/StreamDump'
import { UserContext } from 'UserStore'

const Show = ({ customer }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} xl={6}>
      <Typography variant='h6'>
        {customer.firstName} {customer.lastName}
        &nbsp;
        <CreateATask targetType='Customer' targetId={customer.id} />
      </Typography>

      <Typography variant='subtitle1'>
        <a href={`carbodylab-phone://dial?toNumber=${customer.phone}&fromNumber=${customer.outboundNumber}`}>
          {prettyPhoneNumber(customer.phone)}
        </a>
      </Typography>

      {customer.noClose || (
        <a target='_blank' href={`https://app.close.com/search/${customer.phone}/`} rel='noreferrer'>
          find in close by phone
        </a>
      )}

      <hr />
      <LeadsForCustomer customer={customer} />
      <hr />

      {customer.botSessions && (
        <>
          <Typography variant='h6'>Pre Leads</Typography>
          <ul>
            {customer.botSessions.map(preLead => (
              <li key={preLead.id}>
                <Link to={`/pre-leads/${preLead.id}`}>
                  {DateTime.fromISO(preLead.completedAt).toFormat(basicDateTimeFormat)}
                </Link>
              </li>
            ))}
          </ul>

          <hr />
        </>
      )}
      <CallbackRequestsForCustomer customer={customer} />
    </Grid>

    <Grid item xs={12} xl={6} />

    <Grid item xs={12}>
      <StreamDump stream={`Customer$${customer.id}`} />
    </Grid>
  </Grid>
)

const CALLBACK_REQUESTS_FOR_CUSTOMER = gql`
  query callbackRequests($customerId: ID!) {
    callbackRequests(customerId: $customerId) {
      id
      createdAt
    }
  }
`

const CallbackRequestsForCustomer = ({ customer }) => {
  const { loading, error, data } = useQuery(CALLBACK_REQUESTS_FOR_CUSTOMER, {
    variables: { customerId: customer.id },
  })

  if (loading || error) return null

  const callbackRequests = keysToCamel(data.callbackRequests)

  const sorted = [...callbackRequests].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))

  return (
    <>
      <Typography variant='h6'>CallbackRequests</Typography>

      <ul>
        {sorted.map(callbackRequest => (
          <li key={callbackRequest.id}>
            <Link to={`/callback-requests/${callbackRequest.id}`}>
              {DateTime.fromISO(callbackRequest.createdAt).toFormat(basicDateTimeFormat)}
            </Link>
          </li>
        ))}
      </ul>
    </>
  )
}

const LEADS_FOR_CUSTOMER = gql`
  query leads($customerId: ID!) {
    leads(customerId: $customerId) {
      id
      createdAt
      deletedAt
      year
      makeShortName
      modelShortName
      quotes {
        id
        createdAt
        deletedAt
        quoteGrandTotal
        jobs {
          id
          startDatetime
          endDatetime
        }
      }
    }
  }
`

const LeadsForCustomer = ({ customer }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)

  const { loading, error, data } = useQuery(LEADS_FOR_CUSTOMER, {
    variables: { customerId: customer.id },
  })

  const handleClickCreateNewLead = () => {
    setWaiting(true) &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_empty_lead_for_customer`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          customerId: customer.id,
        }),
      }).then(res => res.ok || window.alert('Error'))
  }

  if (loading || error) return null

  const leads = keysToCamel(data.leads)

  if (leads.length === 0) return 'No Leads'

  const sorted = leads.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))

  return (
    <ul>
      {sorted.map(lead => (
        <li key={lead.id}>
          <>Lead </>
          <b>
            {lead.year} - {lead.makeShortName} - {lead.modelShortName}
          </b>
          <>:&nbsp;</>

          <Link to={`/leads/${lead.id}`}>{DateTime.fromISO(lead.createdAt).toFormat(basicDateTimeFormat)}</Link>

          {lead.deletedAt && <> (deleted) </>}

          {lead.quotes.length > 0 && <QuotesForLead lead={lead} />}
        </li>
      ))}
    </ul>
  )
}

const QuotesForLead = ({ lead }) => {
  const sorted = lead.quotes.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))

  return (
    <ul>
      {sorted.map(quote => (
        <li key={quote.id}>
          Quote:&nbsp;<b>${quote.quoteGrandTotal.toFixed(2)}</b>&nbsp;
          <Link to={`/leads/${lead.id}/quotes/${quote.id}`}>
            {DateTime.fromISO(quote.createdAt).toFormat(basicDateTimeFormat)}
          </Link>
          {quote.deletedAt && <> (deleted) </>}
          {lead.quotes.length > 0 && <JobsForQuote lead={lead} quote={quote} />}
        </li>
      ))}
    </ul>
  )
}

const JobsForQuote = ({ lead, quote }) => {
  const sorted = quote.jobs.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))

  return (
    <ul>
      {sorted.map(job => (
        <li key={job.id}>
          Job:&nbsp;
          <Link to={`/leads/${lead.id}/quotes/${quote.id}/jobs/${job.id}`}>
            from: {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat('ff')}
            &nbsp; to: {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default Show
