import { Box, Grid, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material'
import { HourlyLaborPopver } from './HourlyLaborPopover'
import { PreFilledHourlyLaborPopover } from './PreFilledHourlyLaborPopover'
import SectionHeaderGrid from './SectionHeaderGrid'
import { DateTime } from 'luxon'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { ListItemValue, SectionFooterGrid, inputStyles } from '../QuoteCalculatorV2'
import { DeleteRounded, EditRounded, StrikethroughS } from '@mui/icons-material'
import { Dollars, PriceInput } from 'tools'
import { UserContext } from 'UserStore'

export const LaborDamagesContext = createContext({
  laborDamageItems: [],
  setLaborDamageItems: c => c,
})

export const hourlyRateFields = hourlyRatesForThisMarket => [
  {
    key: 'totalPrepHours',
    hourlyRate: hourlyRatesForThisMarket.prep,
    label: 'Prep',
  },
  {
    key: 'totalPaintHours',
    hourlyRate: hourlyRatesForThisMarket.paint,
    label: 'Paint',
  },
  {
    key: 'totalBodyWorkHours',
    hourlyRate: hourlyRatesForThisMarket.bodyWork,
    label: 'Body work',
  },
  {
    key: 'totalMajorPartHours',
    hourlyRate: hourlyRatesForThisMarket.majorPart,
    label: 'Major part',
  },
  {
    key: 'totalMinorPartHours',
    hourlyRate: hourlyRatesForThisMarket.minorPart,
    label: 'Minor part',
  },
]

const hourlyRatesFallback = {
  paint: 0,
  bodyWork: 0,
  majorPart: 0,
  minorPart: 0,
  prep: 0,
}

const PrimaryLaborVolumeDiscount = ({ primaryLaborVolumeDiscountPercent }) => {
  if (primaryLaborVolumeDiscountPercent === 0 || !primaryLaborVolumeDiscountPercent) {
    return null
  }
  return <ListItemValue title='volume discount' value={`( - %${primaryLaborVolumeDiscountPercent} )`} />
}

export const PrimaryLaborItems = ({
  primaryLaborItems,
  quote,
  handleLaborSelected,
  handlePrimaryLaborChanged,
  handleDeletedPrimaryLaborId,
  quoteNeedsSaving,
  temporaryQuote,
  isEditingParts,
}) => {
  const [user] = useContext(UserContext)
  const [laborDamageItems, setLaborDamageItems] = useState([])
  const numPrimaryItems = useRef(null)
  const hourlyRates = quote.hourlyRates ? quote.hourlyRates : hourlyRatesFallback

  const maybeResetHoursForLaborItem = laborItem => {
    const foundDamage = laborDamageItems?.find(li => laborItem.id === li.id || laborItem.damageId === li.id)

    if (foundDamage) {
      const prepCost = foundDamage.prepHours * hourlyRates.prep
      const paintCost = foundDamage.paintHours * hourlyRates.paint
      const bodyWorkCost = foundDamage.bodyWorkHours * hourlyRates.bodyWork
      const majorPartCost = foundDamage.majorPartHours * hourlyRates.majorPart
      const minorPartCost = foundDamage.minorPartHours * hourlyRates.minorPart

      const totalLaborCost = prepCost + paintCost + bodyWorkCost + majorPartCost + minorPartCost

      if (foundDamage?.totalHours !== laborItem.totalHours) {
        handlePrimaryLaborChanged(null, {
          ...laborItem,
          price: totalLaborCost,
          totalHours: foundDamage.totalHours,
          totalPrepHours: foundDamage.prepHours,
          totalPaintHours: foundDamage.paintHours,
          totalBodyWorkHours: foundDamage.bodyWorkHours,
          totalMinorPartHours: foundDamage.minorPartHours,
          totalMajorPartHours: foundDamage.majorPartHours,
        })
      }
    }
  }

  useEffect(() => {
    numPrimaryItems.current !== primaryLaborItems.length &&
      primaryLaborItems.forEach((laborItem, index) => index === 0 && maybeResetHoursForLaborItem(laborItem))

    numPrimaryItems.current = primaryLaborItems.length
  }, [primaryLaborItems])

  return (
    <Box>
      <SectionHeaderGrid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        Primary Labor
        <LaborDamagesContext.Provider value={{ laborDamageItems, setLaborDamageItems }}>
          <PreFilledHourlyLaborPopover
            primaryLaborItems={primaryLaborItems}
            hourlyRates={hourlyRates}
            handleLaborSelected={handleLaborSelected}
          />
        </LaborDamagesContext.Provider>
      </SectionHeaderGrid>

      {primaryLaborItems.length > 0 && (
        <Grid item xs={12}>
          {primaryLaborItems
            .sort((a, b) => (DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt) ? 1 : -1))
            .map(item => (
              <PrimaryLaborCatalogItem
                key={item.id}
                item={item}
                disabled={isEditingParts}
                hourlyRates={hourlyRates}
                handleItemChanged={handlePrimaryLaborChanged}
                handleItemDeleted={labor => handleDeletedPrimaryLaborId(labor.id)}
                quoteNeedsSaving={quoteNeedsSaving}
              />
            ))}
        </Grid>
      )}

      {primaryLaborItems.length > 0 && (
        <SectionFooterGrid item xs={12} sx={{ px: 2 }}>
          {primaryLaborItems.length > 0 && (
            <ListItemValue
              title='subtotal'
              value={
                <Dollars
                  value={
                    temporaryQuote && temporaryQuote.primaryLaborSum ? temporaryQuote.primaryLaborSum.toFixed(2) : 0
                  }
                />
              }
            />
          )}

          <ListItemValue
            title='hours total'
            value={temporaryQuote && temporaryQuote.totalLaborHours ? temporaryQuote.totalLaborHours.toFixed(2) : 0}
          />

          <PrimaryLaborVolumeDiscount
            primaryLaborVolumeDiscountPercent={temporaryQuote.primaryLaborVolumeDiscountPercent}
          />

          {temporaryQuote.primaryLaborSumAfterVolumeDiscount !== 0 && (
            <b>
              <ListItemValue
                title='final subtotal'
                value={
                  <Dollars
                    value={
                      temporaryQuote && temporaryQuote.primaryLaborSumAfterVolumeDiscount
                        ? temporaryQuote.primaryLaborSumAfterVolumeDiscount.toFixed(2)
                        : 0
                    }
                  />
                }
              />
            </b>
          )}
        </SectionFooterGrid>
      )}
    </Box>
  )
}

const PrimaryLaborCatalogItem = ({
  item,
  disabled,
  handleItemChanged,
  handleItemDeleted,
  hourlyRates,
  quoteNeedsSaving,
}) => {
  const [user] = useContext(UserContext)
  const [isEditingPrice, setIsEditingPrice] = useState(false)
  const classes = inputStyles()

  const { isHourly } = item

  const prepCost = item.totalPrepHours * hourlyRates.prep
  const paintCost = item.totalPaintHours * hourlyRates.paint
  const bodyWorkCost = item.totalBodyWorkHours * hourlyRates.bodyWork
  const majorPartCost = item.totalMajorPartHours * hourlyRates.majorPart
  const minorPartCost = item.totalMinorPartHours * hourlyRates.minorPart

  const totalLaborCost = prepCost + paintCost + bodyWorkCost + majorPartCost + minorPartCost
  const [customPrice, setCustomPrice] = useState(totalLaborCost)

  useEffect(() => {
    setCustomPrice(totalLaborCost)
  }, [totalLaborCost])

  const totalHours =
    item.totalPrepHours +
    item.totalPaintHours +
    item.totalBodyWorkHours +
    item.totalMajorPartHours +
    item.totalMinorPartHours

  const costDoesntMatchWithNewHourlyRate = Number(totalLaborCost).toFixed(1) !== Number(item.price).toFixed(1)

  useEffect(() => {
    !quoteNeedsSaving && setIsEditingPrice(false)
    !quoteNeedsSaving && setCustomPrice(totalLaborCost)
  }, [quoteNeedsSaving])

  const calculateNewPrice = ({ newHours, oldPrice, attribute }) => {
    const getCostOfAttributeBeforeHourChange = () => {
      switch (attribute) {
        case 'totalBodyWorkHours':
          return bodyWorkCost
        case 'totalMajorPartHours':
          return majorPartCost
        case 'totalMinorPartHours':
          return minorPartCost
        case 'totalPaintHours':
          return paintCost
        case 'totalPrepHours':
          return prepCost
        default:
          return 0
      }
    }

    const getNewCostForAttribute = () => {
      switch (attribute) {
        case 'totalBodyWorkHours':
          return hourlyRates.bodyWork * newHours
        case 'totalMajorPartHours':
          return hourlyRates.majorPart * newHours
        case 'totalMinorPartHours':
          return hourlyRates.minorPart * newHours
        case 'totalPaintHours':
          return hourlyRates.paint * newHours
        case 'totalPrepHours':
          return hourlyRates.prep * newHours
        default:
          return 0
      }
    }

    const newPrice = Number((oldPrice - getCostOfAttributeBeforeHourChange() + getNewCostForAttribute()).toFixed(2))

    return newPrice
  }

  return (
    <Grid container sx={{ px: 1, pt: 0.5 }}>
      <Grid item xs={8} sx={{ borderBottom: isHourly ? '' : '1px dotted lightgray' }}>
        {costDoesntMatchWithNewHourlyRate && (
          <b
            style={{
              border: '1px dashed orange',
              background: '#ffa50040',
              display: 'block',
              fontSize: '11px',
              padding: '0rem .25rem',
            }}
          >
            ITEM PRICED WITH A CUSTOM LABOR RATE
          </b>
        )}
        <TextField
          className={classes.inputField}
          fullWidth
          size='small'
          name='name'
          multiline
          inputProps={{ sx: { fontSize: '15px' } }}
          variant='standard'
          value={item.name || ''}
          onClick={e => e.stopPropagation()}
          error={(item.name || '').trim() === ''}
          disabled={disabled}
          onChange={e => {
            handleItemChanged(e, {
              name: e.target.value,
              id: item.id,
              panel: item.panel,
              side: item.side,
              price: item.price,
              totalHours: item.totalHours,
              totalPrepHours: item.totalPrepHours,
              totalPaintHours: item.totalPaintHours,
              totalBodyWorkHours: item.totalBodyWorkHours,
              totalMinorPartHours: item.totalMinorPartHours,
              totalMajorPartHours: item.totalMajorPartHours,
              createdAt: item.createdAt || DateTime.now().toISO(),
              void: item.void,
            })
          }}
        />
      </Grid>

      <Grid item xs={2} style={{ paddingLeft: 0 }} sx={{ borderBottom: isHourly ? '' : '1px dotted lightgray' }}>
        {costDoesntMatchWithNewHourlyRate && (
          <b
            style={{
              border: '1px dashed orange',
              background: '#ffa50040',
              textAlign: 'right',
              display: 'block',
              fontSize: '11px',
            }}
          >
            ${item.price.toFixed(2)}
          </b>
        )}

        {!isEditingPrice && (
          <Tooltip title='click to edit' arrow placement='right'>
            <TextField
              disabled
              // label='Click to edit'
              variant='standard'
              className={classes.inputField}
              size='small'
              name='price'
              value={totalLaborCost}
              onClick={() => setIsEditingPrice(true)}
              sx={{
                cursor: 'pointer !important',
                input: {
                  padding: '0px',
                },
                '& svg': {
                  ml: '.25rem',
                },
              }}
              InputProps={{
                inputComponent: PriceInput,
                endAdornment: (
                  <InputAdornment>
                    <EditRounded fontSize='.5rem' />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Tooltip>
        )}

        {isEditingPrice && (
          <TextField
            required
            variant='standard'
            className={classes.inputField}
            value={customPrice}
            size='small'
            name='price'
            onChange={e => {
              setCustomPrice(e.target.value)

              handleItemChanged(e, {
                ...item,
                price: Number(e.target.value),
              })
            }}
            InputProps={{
              inputComponent: PriceInput,
            }}
            InputLabelProps={{ shrink: true }}
          />
        )}
      </Grid>

      <Grid
        item
        xs={2}
        style={{
          borderBottom: isHourly ? '' : '1px dotted lightgray',
          textAlign: 'right',
          padding: 0,
        }}
      >
        <IconButton
          cy-data='void-labor-item-button'
          disabled={disabled || !user.roles.includes('admin')}
          onClick={e => handleItemChanged(e, { ...item, void: !item.void })}
          sx={{ p: 0.25 }}
        >
          <StrikethroughS fontSize='small' color={item.void ? 'warning' : ''} />
        </IconButton>
        <IconButton
          cy-data='delete-labor-item-button'
          disabled={disabled}
          onClick={() => handleItemDeleted(item)}
          sx={{ p: 0.25 }}
        >
          <DeleteRounded fontSize='small' />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.inputField}
          fullWidth
          size='small'
          name='description'
          placeholder='description (internal only)'
          multiline
          inputProps={{ sx: { fontSize: '15px', color: '#999' } }}
          variant='standard'
          value={item.description || ''}
          onClick={e => e.stopPropagation()}
          disabled={disabled}
          onChange={e => {
            handleItemChanged(e, {
              ...item,
              createdAt: item.createdAt || DateTime.now().toISO(),
              description: e.target.value,
            })
          }}
        />
      </Grid>
      <Grid
        item
        xs={8}
        sx={{
          borderBottom: '1px dotted lightgray',
          padding: '.5rem 0rem',
          '& svg': { fontSize: '16px' },
        }}
      >
        <Box sx={{ display: 'flex' }}>
          {hourlyRateFields(hourlyRates).map(textfield => (
            <TextField
              key={textfield.key}
              value={item[textfield.key]}
              onChange={e => {
                setIsEditingPrice(false)
                handleItemChanged(e, {
                  ...item,
                  [textfield.key]: Number(e.target.value),
                  totalHours: Number(e.target.value) + (totalHours - item[textfield.key]),
                  price: calculateNewPrice({
                    hoursWithoutThisAmount: Number(e.target.value) + (totalHours - item[textfield.key]),
                    newHours: Number(e.target.value),
                    oldHours: item[textfield.key],
                    oldPrice: totalLaborCost,
                    attribute: textfield.key,
                  }),
                })
              }}
              inputProps={{
                min: 0,
                name: `${textfield.label} hours`,
                sx: {
                  padding: '.125rem 0rem .125rem .5rem',
                },
              }}
              type='number'
              disabled={disabled}
              size='small'
              sx={{ maxWidth: '70px', marginRight: '.25rem' }}
              label={textfield.label}
            />
          ))}
        </Box>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          textAlign: 'right',
          borderBottom: '1px dotted lightgray',
          '& svg': { fontSize: '16px' },
        }}
      >
        {totalHours ? totalHours.toFixed(2) : 0} hours
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          textAlign: 'right',
          borderBottom: '1px dotted lightgray',
          '& svg': { fontSize: '16px' },
        }}
      ></Grid>
    </Grid>
  )
}
