import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import AccountCircle from '@mui/icons-material/AccountCircleRounded'
import ContactsRounded from '@mui/icons-material/ContactsRounded'
import ChatRounded from '@mui/icons-material/ChatBubbleOutlineRounded'
import EmailRounded from '@mui/icons-material/EmailRounded'
import PhoneAndroid from '@mui/icons-material/PhoneAndroid'
import HomeRounded from '@mui/icons-material/HomeRounded'
import Business from '@mui/icons-material/BusinessRounded'
import LocationCity from '@mui/icons-material/LocationCityRounded'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import LocationSearch from '@mui/icons-material/GpsFixedRounded'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import PinDropRounded from '@mui/icons-material/PinDropRounded'
import HomeWork from '@mui/icons-material/HomeWorkRounded'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

import axios from 'axios'
import STATES from 'states.json'
import { UserContext } from 'UserStore.js'
import { isEmail } from 'tools'

const useStyles = makeStyles((theme) => ({
  card: {
    width: 602,
    marginTop: '50px',
    margin: '0px auto'
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  absurd: {
    width: 82,
    margin: '12px 10px 12px 10px',
  },
  tiny: {
    width: 122,
    margin: '12px 10px 12px 10px',
  },
  small: {
    width: 264,
    margin: '12px 10px 12px 10px',
  },
  large: {
    width: 549,
    margin: '12px 10px 12px 10px',
  },
  cardHeading: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 25px 15px 25px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    fontWeight: '500',
    fontSize: '18px',
    borderRadius: '4px 4px 0px 0px',
    background: "#6573c3",
    margin: '-16px -16px 20px -16px',
  },
  buttonContainer: {
    display: 'flex',
    paddingBottom: '20px',
    justifyContent: 'center'
  }
}))

const NewTechnician = () => {
  let history = useHistory()
  const classes = useStyles()
  const [user] = useContext(UserContext)
  const [technician, setTechnician] = useState({first_name: '', last_name: '', email: '', business_name: '', contractor_is_business: false, ein: '', ssn: '', phone: '', address_line_one: '', address_line_two: '', city: '', zip: '', state: '', lat: '', lng: '', commission_percent: '60'})
  const [hasBeenFocused, setHasBeenFocused] = useState({})
  const [errors, setErrors] = useState({email: '', phone: '', lng: '', lat: '', ein: '', ssn: '', commission_percent: ''})
  const [focused, setFocused] = useState()
  const stateMenuItems = Object.entries(STATES).map(state => <MenuItem key={state} id="state" value={state[0]}> {state[0]} </MenuItem>)

  const setElementFocus = (evt) => {
    setFocused(evt.target.name)
    setHasBeenFocused({...hasBeenFocused, [evt.target.name]: true})
  }

  const Validators = {
    email: {check: (e) => isEmail(e), error: 'Must match form: tech@example.com'},
    phone: {check: (e) => e.includes('+1') && e.length == 12, error: ' Must match form: +1xxxXXXxxxx'},
    lat: {check: (e) => e.includes('.') && e.split('.')[1].length == 6, error: 'Must have 6 digits after decimal.'},
    lng: {check: (e) => e.includes('.') && e.split('.')[1].length == 6, error: 'Must have 6 digits after decimal.'},
    ein: {check: (e) => e && e.length == 4 && e.match(/^[0-9]+$/), error: 'Must have 4 numbers only.'},
    ssn: {check: (e) => e && e.length == 4 && e.match(/^[0-9]+$/), error: 'Must have 4 numbers only.'},
    zip: {check: (e) => e && e.length == 5 && e.match(/^[0-9]+$/), error: 'Must have 5 numbers only.'},
    businessName: {check: (e) => e && e.length > 0, error: 'Must have a business name.'},
    commission_percent: {check: (e) => e.length > 0, error: 'Must be more than 0.'},
  }

  useEffect(() => {
    const newErrors = {}
      // Check validators when editable fields change
      Object.entries(Validators).map(each_entry => {
        const entry_name = each_entry[0]
        const entry_check = each_entry[1].check
        const entry_error = each_entry[1].error
        // find the entry in Validators object that matches the name of focused input
        // if the entry check has failed, add the error
        if(entry_check(technician[entry_name]) == null || !entry_check(technician[entry_name]) ) {
          if (entry_name === 'businessName') {
            technician.contractor_is_business && (newErrors['businessName'] = entry_error)
          }
          else if (entry_name === 'ein') {
            technician.contractor_is_business && (newErrors['ein'] = entry_error)
          }
          else if (entry_name === 'ssn') {
            if(hasBeenFocused['ssn']) {
              !technician.contractor_is_business && (newErrors['ssn'] = entry_error)
            }
          }
          else {
            if(hasBeenFocused[entry_name]) {
              newErrors[entry_name] = entry_error
            }
          }
        }
      })

    setErrors(newErrors)
  }, [technician])

  const isEnabled = technician.first_name.length > 0 &&
    technician.last_name.length > 0 &&
    technician.email.length > 0 &&
    technician.phone.length > 0 &&
    technician.address_line_one.length > 0 &&
    technician.city.length > 0 &&
    technician.zip.length > 4 &&
    technician.state.length > 0 &&
    technician.lat.includes('.') &&
    technician.lat.split('.')[1].length == 6 &&
    technician.lng.includes('.') > 0 &&
    technician.lng.split('.')[1].length == 6 &&
    technician.commission_percent.length > 0

  const handleAttributeChange = (e) => {
    let { name, value }  = e.target
    name === 'contractor_is_business' && setTechnician(technician => ({ ...technician, ['contractor_is_business']: !technician.contractor_is_business }))
    name !== 'contractor_is_business' && setTechnician(technician => ({ ...technician, [name]: value}))
    setFocused(name)
  }

  const handlesubmit = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: process.env.REACT_APP_COMMAND_ROOT + '/api/create_technician',
      data: {
        first_name: technician.first_name,
        last_name: technician.last_name,
        email: technician.email,
        phone: technician.phone,
        address_line_one: technician.address_line_one,
        address_line_two: technician.address_line_two,
        city: technician.city,
        zip: technician.zip,
        state: technician.state,
        lat: technician.lat,
        lng: technician.lng,
        business_name: technician.business_name,
        contractor_is_business: technician.contractor_is_business,
        ein: technician.ein,
        ssn: technician.ssn,
        commission_percent: technician.commission_percent,
        user_id: user.id
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD
      }
    }).then(res => {
      history.push('/technicians/' + res.data.technician.id)
    })
  }

  return (
    <Paper className={classes.card} elevation={2} >
      <form onSubmit={handlesubmit} noValidate autoComplete="off">
        <CardContent>
          <Typography gutterBottom className={classes.cardHeading}>
            New Technician <ContactsRounded style={{color: "#fff"}} />
          </Typography>

          <TextField color="primary"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <AccountCircle color="action" /></InputAdornment>
              ),
            }}
            id="first_name"
            name="first_name"
            onChange={handleAttributeChange}
            value={technician.first_name}
            className={classes.small}
            variant='outlined'
            size="small"
            label="First Name" />

          <TextField color="primary"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <AccountCircle color="action" /></InputAdornment>
              ),
            }}
            id="last_name"
            name="last_name"
            onChange={handleAttributeChange}
            value={technician.last_name}
            className={classes.small}
            variant='outlined'
            size="small"
            label="Last Name" />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <PhoneAndroid color="action" /></InputAdornment>
              ),
            }}
            label="Phone, +1xxxXXXxxxx"
            name="phone"
            value={technician.phone}
            className={classes.small}
            onFocus={setElementFocus}
            onBlur={setFocused}
            onChange={handleAttributeChange}
            variant="outlined"
            size="small"
            helperText={focused ==='phone'? false : (errors && errors.phone) }
            error={focused === 'phone'? false : (errors && errors.phone)}
            />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <EmailRounded color="action" /></InputAdornment>
              ),
            }}
            label="Email"
            name="email"
            onFocus={setElementFocus}
            className={classes.small}
            value={technician.email}
            onBlur={setFocused}
            onChange={handleAttributeChange}
            variant="outlined"
            size="small"
            helperText={focused ==='email'? false : (errors && errors.email) }
            error={focused === 'email'? false : (errors && errors.email)}

          />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <HomeRounded color="action" /></InputAdornment>
              ),
            }}
            label="Address Line 1"
            value={technician.address_line_one || ''}
            className={classes.large}
            name="address_line_one"
            fullWidth
            onFocus={setElementFocus}
            onChange={handleAttributeChange}
            variant="outlined"
            size="small"
            />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <HomeWork color="action" /></InputAdornment>
              ),
            }}
            label="Address Line 2"
            value={technician.address_line_two || ''}
            name="address_line_two"
            className={classes.large}
            onFocus={setElementFocus}
            fullWidth
            onChange={handleAttributeChange}
            variant="outlined"
            size="small"
            />

           <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <LocationCity color="action" /></InputAdornment>
              ),
            }}
            label="City"
            value={technician.city || ''}
            className={classes.small}
            name="city"
            onFocus={setElementFocus}
            onChange={handleAttributeChange}
            variant="outlined"
            size="small"
            />

          <FormControl variant="outlined" id="state" size="small" margin="none" className={classes.tiny}>
            <InputAdornment>
              <LocationCity style={{marginBottom: "-40px", marginLeft: '15px', marginRight: '-15px'}} color="action" />
            </InputAdornment>
            <InputLabel shrink>State</InputLabel>
            <Select style={{paddingLeft: '40px'}} onFocus={setElementFocus} label="state" value={technician.state} onChange={handleAttributeChange} name="state">
              {stateMenuItems}
            </Select>
          </FormControl>

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <PinDropRounded color="action" /></InputAdornment>
              ),
            }}
            label="Zip"
            name="zip"
            onFocus={setElementFocus}
            value={technician.zip || ''}
            onBlur={setFocused}
            className={classes.tiny}
            onChange={handleAttributeChange}
            variant="outlined"
            size="small"
            helperText={focused ==='zip'? false : (errors && errors.zip) }
            error={focused === 'zip'? false : (errors && errors.zip)}
          />

          <br />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <LocationSearch color="action" /></InputAdornment>
              ),
            }}
            label="Latitde"
            value={technician.lat || ''}
            name="lat"
            className={classes.small}
            onBlur={setFocused}
            onChange={handleAttributeChange}
            onFocus={setElementFocus}
            variant="outlined"
            size="small"
            helperText={focused === 'lat'? false : (errors && errors.lat) }
            error={focused === 'lat'? false : (errors && errors.lat)} />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <LocationSearch color="action" /></InputAdornment>
              ),
            }}
            label="Longitude"
            value={technician.lng || ''}
            className={classes.small}
            name="lng"
            onBlur={setFocused}
            onChange={handleAttributeChange}
            onFocus={setElementFocus}
            variant="outlined"
            size="small"
            helperText={focused ==='lng'? false : (errors && errors.lng) }
            error={focused === 'lng'? false : (errors && errors.lng)}
            />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <img src="https://driveway-development.s3.us-east-2.amazonaws.com/ssn.svg" style={{height: '20px'}} /> </InputAdornment>
              ),
            }}
            label="ssn last 4"
            name="ssn"
            value={technician.ssn || ''}
            onChange={handleAttributeChange}
            onBlur={setFocused}
            className={classes.tiny}
            onFocus={setElementFocus}
            required={!technician.contractor_is_business}
            variant="outlined"
            size="small"
            disabled={technician.contractor_is_business}
            helperText={focused ==='ssn'? false : (errors && errors.ssn) }
            error={focused === 'ssn'? false : (errors && errors.ssn)}
          />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <img src="https://driveway-development.s3.us-east-2.amazonaws.com/percent.svg" style={{height: '15px'}} /> </InputAdornment>
              ),
            }}
            label="Commission"
            type="number"
            value={technician.commission_percent || ''}
            className={classes.tiny}
            name="commission_percent"
            onFocus={setElementFocus}
            onChange={handleAttributeChange}
            variant="outlined"
            size="small"
          />

          <FormControlLabel
            labelPlacement="start"
            style={{height: '40px', margin: '12px 4px 0px 15px'}}
            control={<Switch size="small" checked={technician.contractor_is_business} color="primary" id="contractor_is_business" name="contractor_is_business" onChange={handleAttributeChange} />}
            label={<span style={{fontSize: '14px'}}>Is a Business</span>}
            />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <AssignmentIndIcon color="action" /></InputAdornment>
              ),
            }}
            label="ein"
            name="ein"
            className={classes.tiny}
            value={technician.ein || ''}
            onFocus={setElementFocus}
            onChange={handleAttributeChange}
            onBlur={setFocused}
            required={technician.contractor_is_business}
            variant="outlined"
            size="small"
            helperText={focused ==='ein'? false : (errors && errors.ein) }
            error={focused === 'ein'? false : (errors && errors.ein)}
            disabled={!technician.contractor_is_business}
          />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> <BusinessCenterIcon color="action" /> </InputAdornment>
              ),
            }}
            label="Business Name"
            name="businessName"
            value={technician.businessName || ''}
            className={classes.small}
            onFocus={setElementFocus}
            onBlur={setFocused}
            onChange={handleAttributeChange}
            variant="outlined"
            required={technician.contractor_is_business}
            size="small"
            helperText={focused ==='businessName'? false : (errors && errors.businessName) }
            error={focused === 'businessName'? false : (errors && errors.businessName)}
            disabled={!technician.contractor_is_business}
          />

        </CardContent>
        <CardActions className={classes.buttonContainer}>
          <Button className={classes.addButton} type="submit" disabled={!isEnabled} variant="contained" size="normal" color="primary"> Create </Button>
        </CardActions>
      </form>
    </Paper>
  )
}

export default NewTechnician
