import {
  Box,
  Button,
  Typography,
  FormControl,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Divider,
} from '@mui/material'
import { Save } from '@mui/icons-material'
import { useContext } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { UserContext } from 'UserStore'
import { Link, useHistory } from 'react-router-dom'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { useQuery, gql } from '@apollo/client'
import { ContactAdder } from './Components/ContactAdder'
import { diff } from 'tools'

const MARKETS_QUERY = gql`
  query markets {
    markets {
      state
      stateCode
      market
    }
  }
`

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
const phoneNumberRegex = /^\+1\d{10}$/
const zipRegex = /^\d{5}$/
const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

const dealerSchema = Yup.object().shape({
  business_name: Yup.string().required(),
  contact_name: Yup.string().nullable(),
  address_line_one: Yup.string().nullable(),
  address_line_two: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable(),
  market: Yup.string().nullable(),
  zip: Yup.string().matches(zipRegex, 'Must match zip code form').nullable(),
  contact_email: Yup.string().matches(emailRegex, 'Must match this form: tech@example.com').nullable(),
  contact_phone: Yup.string()
    .matches(phoneNumberRegex, 'Invalid phone number. Must match this form: +1XXXxxxXXXX')
    .nullable(),
})

export const Profile = ({ refetch, dealer }) => {
  const [user] = useContext(UserContext)
  const history = useHistory()
  const bearerTokenHeaders = useBearerTokenHeaders()

  const { data: marketsData, loading: marketsLoading, error: marketsError } = useQuery(MARKETS_QUERY)

  const states = {}
  const marketMenuItemsByState = {}

  marketsData?.markets.forEach(market => {
    states[market.stateCode] = market.state
    marketMenuItemsByState[market.stateCode] = marketMenuItemsByState[market.stateCode] || []
    marketMenuItemsByState[market.stateCode].push(
      <MenuItem key={market.market} value={market.market}>
        {market.market}
      </MenuItem>
    )
  })

  const stateMenuItems = Object.entries(states).map(([k, v]) => (
    <MenuItem key={k} value={k}>
      {v}
    </MenuItem>
  ))

  const dealerStatus = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' },
  ]

  const dealerStatusItems = dealerStatus.map(dealStatus => (
    <MenuItem key={dealStatus.value} value={dealStatus.value}>
      {dealStatus.label}
    </MenuItem>
  ))

  const initialValues = {
    business_name: dealer.businessName,
    address_line_one: dealer.addressLineOne,
    address_line_two: dealer.addressLineTwo,
    city: dealer.city,
    zip: dealer.zip,
    state: dealer.state,
    market: dealer.market,
    is_active: dealer.isActive,
    store_hours: {
      sunday: dealer?.storeHours?.sunday.join(),
      monday: dealer?.storeHours?.monday.join(),
      tuesday: dealer?.storeHours?.tuesday.join(),
      wednesday: dealer?.storeHours?.wednesday.join(),
      thursday: dealer?.storeHours?.thursday.join(),
      friday: dealer?.storeHours?.friday.join(),
      saturday: dealer?.storeHours?.saturday.join(),
    },
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: dealerSchema,
    onSubmit: values => {
      const filteredValues = Object.fromEntries(Object.entries(values).filter(([k, v]) => v !== initialValues[k]))

      const storeHoursDiff = diff(values.store_hours, initialValues.store_hours)

      if (Object.keys(storeHoursDiff).length === 0) {
        delete filteredValues.store_hours
      } else {
        filteredValues.store_hours = {}
        daysOfWeek.forEach(day => {
          let v = values.store_hours[day].trim()
          filteredValues.store_hours[day] = v.length > 0 ? v.split(',') : []
        })
      }

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_dealer_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          b_to_b_dealer_id: dealer.id,
          user_id: user.id,
          updated_attributes: filteredValues,
        }),
      }).then(() => refetch())
    },
  })

  const handleReset = () => {
    formik.setValues(initialValues)
  }

  const validationErrors = Object.values(
    Object.keys(formik.errors).reduce((acc, k) => {
      if (k === 'availability') {
        // flatten availability sub object errors
        Object.keys(formik.errors.availability).forEach(k => {
          acc['availability.' + k] = k + ': ' + formik.errors.availability[k]
        })
      } else {
        acc[k] = formik.errors[k]
      }
      return acc
    }, {})
  )

  const handleClickDeleteBToBDealer = () => {
    window.confirm('are you sure you want to delete this?') &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_dealer`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          b_to_b_dealer_id: dealer.id,
        }),
      }).then(res => {
        if (res) {
          history.push('/b-to-b/dealers')
        } else {
        }
      })
  }

  const allTechnicians = dealer.technicians.slice().sort((a, b) => (a.firstName > b.firstName ? 1 : -1))

  const activeTechnicians = allTechnicians.filter(tech => tech.active)
  const deactivatedTechnicians = allTechnicians.filter(tech => !tech.active)

  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <Typography sx={{ background: 'aliceblue', padding: '.5rem', borderBottom: '2px solid #a1cff7' }}>
            Dealer Info
          </Typography>

          <Grid container spacing={2} padding={2}>
            {['business_name', 'address_line_one', 'address_line_two', 'city', 'zip'].map(val => (
              <Grid item xs={6}>
                <FormControl fullWidth size='small'>
                  <TextField
                    size='small'
                    label={val.replaceAll('_', ' ')}
                    value={formik.values[val]}
                    name={val}
                    onChange={formik.handleChange}
                    placeholder={val}
                    error={formik.touched[val] && Boolean(formik.errors[val])}
                    helperText={formik.touched[val] && formik.errors[val]}
                  />
                </FormControl>
              </Grid>
            ))}

            <Grid item xs={6}>
              <FormControl fullWidth size='small' variant='outlined'>
                <InputLabel>State</InputLabel>
                <Select label='State' name='state' value={formik.values.state} onChange={formik.handleChange}>
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {stateMenuItems}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl size='small' variant='outlined' fullWidth>
                <InputLabel>Market</InputLabel>
                <Select label='Market' name='market' value={formik.values.market} onChange={formik.handleChange}>
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {marketMenuItemsByState[formik.values.state]}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth size='small' variant='outlined'>
                <InputLabel>State</InputLabel>
                <Select label='Status' name='is_active' value={formik.values.is_active} onChange={formik.handleChange}>
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {dealerStatusItems}
                </Select>
              </FormControl>
            </Grid>

            {validationErrors.length > 0 && (
              <>
                <br />
                <div style={{ color: 'red' }}>
                  {validationErrors.map(error => (
                    <div key={error}>{error}</div>
                  ))}
                </div>
                <br />
              </>
            )}
          </Grid>
          <Grid container spacing={2} padding={2} paddingTop={0}>
            <Grid item xs={12}>
              <b>Store hours: military time, ex. 8am to 5pm is "8-17"</b>
              <br />
              <span>
                also, if daily store hours are segmented for example (7am-noon, 2pm-7pm), you can enter "7-12,14-19"
              </span>
              <br />
            </Grid>
            {daysOfWeek.map((day, index) => (
              <Grid key={index} item xs={4} sx={{ maxWidth: '48%', display: 'flex' }}>
                <TextField
                  fullWidth
                  key={day.toUpperCase()}
                  variant='outlined'
                  size='small'
                  label={day.toUpperCase()}
                  name={`store_hours.${day}`}
                  value={formik.values.store_hours[day]}
                  onChange={formik.handleChange}
                  disabled={!user.roles.includes('admin')}
                />
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', padding: '0rem .5rem' }}>
            <Button
              variant='contained'
              color='primary'
              disabled={!formik.dirty || !formik.isValid || !user.roles.includes('admin')}
              type='submit'
              onClick={formik.handleSubmit}
            >
              <Save />
              Save
            </Button>
            <Button onClick={() => history.goBack()}>Cancel</Button>
            <Button disabled={!formik.dirty} onClick={handleReset}>
              Reset
            </Button>
            <Button
              onClick={handleClickDeleteBToBDealer}
              variant='outlined'
              sx={{ marginLeft: 'auto' }}
              color='warning'
              disabled={!user.roles.includes('admin')}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={6} sx={{ borderLeft: '1px solid #ddd' }}>
          <Typography sx={{ background: 'aliceblue', padding: '.5rem', borderBottom: '2px solid #a1cff7' }}>
            Technicians
          </Typography>

          {deactivatedTechnicians.length > 0 && (
            <Box sx={{ padding: '1rem 1rem 0rem 1rem' }}>
              <b>Active Technicians</b>
              <Divider />
            </Box>
          )}
          <ul>
            {activeTechnicians.map(tech => (
              <li key={tech.id} sx={{ padding: '.5rem 0rem' }}>
                <Link to={`/technicians/${tech.id}`}>
                  {tech.firstName} {tech.lastName}
                </Link>
              </li>
            ))}
          </ul>

          {deactivatedTechnicians.length > 0 && (
            <Box sx={{ padding: '0rem 1rem' }}>
              <b>Deactivated Technicians</b>
              <Divider />
            </Box>
          )}
          <ul>
            {deactivatedTechnicians.map(tech => (
              <li key={tech.id} sx={{ padding: '.5rem 0rem' }}>
                <Link to={`/technicians/${tech.id}`}>
                  {tech.firstName} {tech.lastName} - (deactivated)
                </Link>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid item xs={12}>
          <ContactAdder refetch={refetch} dealer={dealer} />
        </Grid>
      </Grid>
    </Box>
  )
}
