import { Box } from '@mui/material'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import JobApplicationView from './JobApplicationView'
import JobApplicationTable from './JobApplicationTable'

const JobApplications = () => {
  const { url } = useRouteMatch()

  return (
    <Box>
      <Switch>
        <Route path={`${url}/:jobApplicationId`}>
          <JobApplicationView />
        </Route>

        <Route path={url}>
          <JobApplicationTable />
        </Route>
      </Switch>
    </Box>
  )
}

export default JobApplications
