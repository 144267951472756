import React, { useContext } from 'react'
import { UserContext } from './UserStore'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { getUploadParamsForDropzone } from 'tools'

const ChatPhotoUploader = ({ contextType, contextId }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const handleSubmit = files =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_chat_message`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        authorId: user.id,
        authorType: 'csr',
        mediaUrls: files.map(file => file.meta.fileUrl),
        contextType: contextType,
        contextId: contextId,
      }),
    })
      .then(response => {
        if (response.ok) {
          files.forEach(file => file.remove())
          return true
        } else {
          throw Error('error')
        }
      })
      .catch(e => {
        alert(e.message)
      })

  const handleChangeStatus = () => {
    window.scrollTo(0, 100000)
    return true
  }

  return (
    <div id='chat-photo-uploader'>
      <Dropzone
        getUploadParams={getUploadParamsForDropzone}
        onChangeStatus={handleChangeStatus}
        multiple
        accept='image/jpg,image/jpeg,image/png'
        onSubmit={handleSubmit}
        submitButton
        inputContent='Drop Photos or click to Browse'
      />
    </div>
  )
}

export default ChatPhotoUploader
