import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from 'UserStore'
import { PriceInput } from 'tools'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const Admin = ({ quote }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [postJobRefundAmount, setPostJobRefundAmount] = useState(quote.postJobRefundAmount || 0)
  const [tipRefundAmount, setTipRefundAmount] = useState(quote.tipRefundAmount || 0)

  const handleClickSavePostJobRefundAmount = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/set_post_job_refund_amount`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        quote_id: quote.id,
        amount: Number(postJobRefundAmount),
      }),
    })
      .then(response => {
        if (response.ok) {
          return true
        } else {
          throw Error('Error')
        }
      })
      .catch(err => {
        window.alert(err)
      })
  }

  const handleClickSaveTipRefundAmount = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/set_tip_refund_amount`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        quote_id: quote.id,
        amount: Number(tipRefundAmount),
      }),
    })
      .then(response => {
        if (response.ok) {
          return true
        } else {
          throw Error('Error')
        }
      })
      .catch(err => {
        window.alert(err)
      })
  }

  return (
    <>
      <TextField
        InputLabelProps={{ shrink: true }}
        label='Post-job refund amount'
        value={postJobRefundAmount}
        onChange={event => setPostJobRefundAmount(event.target.value)}
        InputProps={{
          inputComponent: PriceInput,
        }}
      />
      &nbsp;
      <Button
        variant='outlined'
        disabled={Number(postJobRefundAmount) === Number(quote.postJobRefundAmount)}
        onClick={handleClickSavePostJobRefundAmount}
      >
        save
      </Button>
      &nbsp;
      <TextField
        InputLabelProps={{ shrink: true }}
        label='Tip refund amount'
        value={tipRefundAmount}
        onChange={event => setTipRefundAmount(event.target.value)}
        InputProps={{
          inputComponent: PriceInput,
        }}
      />
      &nbsp;
      <Button
        variant='outlined'
        disabled={Number(tipRefundAmount) === Number(quote.tipRefundAmount)}
        onClick={handleClickSaveTipRefundAmount}
      >
        save
      </Button>
    </>
  )
}

export default Admin
