import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { makeStyles } from '@mui/styles'
import { Dollars, keysToCamel } from 'tools'
import Button from '@mui/material/Button'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import { RefreshRounded, SouthRounded } from '@mui/icons-material'
import { DayLaborValue } from './DayLaborValue'
import { getWeekLaborValue, WeekLaborValue } from './WeekLaborValue'
import { RescheduleJob } from './RescheduleJob'
import { JobCell } from './JobCell'
import { BToBJobCell } from './BToBJobCell'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const DAYS_PER_ROW = 14

const TECH_JOBS_QUERY = gql`
  query jobs($technicianId: ID!, $startDatetime: String!, $endDatetime: String!) {
    jobs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      leadId
      quoteId
      startDatetime
      endDatetime
      canceledAt
      technicianId
      rescheduleHistory
      rescheduledReason
      heldForReschedulingAt
      isActive
      quote {
        id
        balanceAmountDue
        preJobAmountDue
        postTaxGrandTotal
        payments
        difficultyLevel
        requiredSkillsets
        paymentStatus
        technicianChargesSum
        laborSumAfterAllDiscounts
        approvedTechnicianIds
        approvedTechnicians {
          id
          firstName
          lastName
        }
        parts {
          id
          status
          name
          number
          price
          cost
          prePaymentRequired
          etaBusinessDays
          etaLastUpdated
        }
      }
      lead {
        id
        name
        emojis
        contextNotes
        jobs(onlyActive: true) {
          id
          leadId
          startDatetime
        }
      }
    }
  }
`

const BTOB_JOBS_QUERY = gql`
  query BToBJobs($technicianId: String, $startDatetime: String, $endDatetime: String) {
    bToBJobs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      createdAt
      startDatetime
      endDatetime
      updatedAt
      schedulerId
      dealer {
        id
        businessName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        primaryContact {
          firstName
          lastName
          email
        }
      }
      technician {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
`

const PAID_TIME_OFF = gql`
  query PaidTimeOffs($technicianId: ID, $startDatetime: String, $endDatetime: String) {
    paidTimeOffs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      createdAt
      timeOffDate
      totalHours
    }
  }
`

const UNPAID_TIME_OFF = gql`
  query UnpaidTimeOffs($technicianId: ID, $startDatetime: String, $endDatetime: String) {
    unpaidTimeOffs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      createdAt
      timeOffDate
      totalHours
    }
  }
`

const SICK_TIME_OFF = gql`
  query SickTimeOffs($technicianId: ID, $startDatetime: String, $endDatetime: String) {
    sickTimeOffs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      createdAt
      timeOffDate
      totalHours
    }
  }
`

const JOBS_SCHEDULERS_QUERY = gql`
  query BToBJobSchedulers($technicianId: String, $recurrenceStartsAt: String!, $recurrenceEndsAt: String!) {
    bToBJobSchedulers(
      technicianId: $technicianId
      recurrenceStartsAt: $recurrenceStartsAt
      recurrenceEndsAt: $recurrenceEndsAt
    ) {
      id
      createdAt
      startDatetime
      endDatetime
      recurrencePeriod
      recurrencePattern
      recurrenceStartsAt
      recurrenceEndsAt
      excludedDays
      dealer {
        id
        businessName
        addressLineOne
        primaryContact {
          firstName
          lastName
          email
        }
      }
      technician {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`

const techRowStyles = makeStyles(theme => ({
  job_card: {
    minWidth: 150,
  },
  title: {
    fontSize: 14,
  },
  job_to_highlight: {
    border: '4px solid orange',
  },
  schedule_container: {
    marginBottom: '1em',
    backgroundColor: 'white',
  },
  schedule_th_cell: {
    padding: '0 2px',
    width: '150px',
    lineHeight: '1rem',
    borderRight: '1px dotted lavender',
    textAlign: 'center',
    '&:last-child': {
      borderRight: '0',
    },
  },
  week_start_cell: {
    borderLeft: '1px solid darkgray',
  },
  schedule_row_cell: {
    boxSizing: 'border-box',
    padding: '15px 5px',
    width: '150px',
    borderBottom: '1px',
    borderRight: '1px dotted lavender',
    '&:last-child': {
      borderRight: '0',
    },
  },
  week_end_cell: {
    borderRight: '1px solid darkgray',
  },
  rescheduling_to_here: {
    border: '5px solid green',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

export const TechnicianRows = ({ technician, startDatetime, focusJob, jobFilter = { b2c: true, b2b: true } }) => {
  const classes = techRowStyles()
  const [reschedulingJob, setReschedulingJob] = useState(null)
  const [aboutToRescheduleTo, setAboutToRescheduleTo] = useState(null)
  const [dropIndicator, setDropIndicator] = useState('')

  startDatetime =
    (reschedulingJob && DateTime.local().setZone('utc').startOf('day').startOf('week')) || startDatetime.startOf('week')
  const endDatetime = startDatetime.plus({ days: 50 })

  const { error, loading, data, refetch } = useQuery(TECH_JOBS_QUERY, {
    variables: {
      technicianId: technician.id,
      startDatetime: startDatetime,
      endDatetime: endDatetime,
    },
  })

  const {
    error: b2bJobsError,
    loading: loadingB2BJobs,
    data: b2bJobsData,
  } = useQuery(BTOB_JOBS_QUERY, {
    variables: {
      technicianId: technician.id,
      startDatetime: startDatetime,
      endDatetime: endDatetime,
    },
  })

  const {
    error: b2bJobSchedulersError,
    loading: loadingB2BJobSchedulers,
    data: b2bJobSchedulersData,
  } = useQuery(JOBS_SCHEDULERS_QUERY, {
    variables: {
      technicianId: technician.id,
      recurrenceEndsAt: DateTime.now().endOf('day'),
      recurrenceStartsAt: DateTime.now().endOf('month').endOf('week').endOf('day'),
    },
  })

  const {
    error: ptoError,
    loading: ptoLoading,
    data: ptoData,
  } = useQuery(PAID_TIME_OFF, {
    variables: {
      technicianId: technician.id,
      startDatetime: startDatetime.minus({ minutes: 1 }),
      endDatetime: endDatetime.plus({ minutes: 1 }),
    },
  })

  const {
    error: utoError,
    loading: utoLoading,
    data: utoData,
  } = useQuery(UNPAID_TIME_OFF, {
    variables: {
      technicianId: technician.id,
      startDatetime: startDatetime.minus({ minutes: 1 }),
      endDatetime: endDatetime.plus({ minutes: 1 }),
    },
  })

  const {
    error: stoError,
    loading: stoLoading,
    data: stoData,
  } = useQuery(SICK_TIME_OFF, {
    variables: {
      technicianId: technician.id,
      startDatetime: startDatetime.minus({ minutes: 1 }),
      endDatetime: endDatetime.plus({ minutes: 1 }),
    },
  })

  const refetchJob = refetch

  if (loading || loadingB2BJobs || stoLoading || utoLoading || ptoLoading) return <div>LOADING...</div>
  if (error || b2bJobsError || stoError || ptoError || utoError) return <div>Error!</div>

  const camelizedJobs = data.jobs.map(job => keysToCamel(job))

  const jobs = camelizedJobs.filter(job => job.isActive)
  const bToBJobs = b2bJobsData?.bToBJobs || []
  const bToBJobSchedulers = b2bJobSchedulersData?.bToBJobSchedulers || []

  const pto = ptoData?.paidTimeOffs
  const uto = utoData?.unpaidTimeOffs
  const sto = stoData?.sickTimeOffs

  const heldJobs = camelizedJobs.filter(job => job.heldForReschedulingAt)

  const futureMostJob =
    [...jobs].sort((a, b) => (DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? -1 : 1))[0] ||
    null

  const rowCount =
    (reschedulingJob && 4) ||
    (!futureMostJob && 1) ||
    Math.max(
      Math.ceil(
        DateTime.fromISO(futureMostJob.startDatetime, { setZone: true }).diff(startDatetime, 'days').toObject().days /
          DAYS_PER_ROW
      ),
      1
    )

  const getSchedulersForDay = date => {
    const weeklySchedulersForDay = bToBJobSchedulers.filter(
      scheduler =>
        scheduler.recurrencePeriod === 'week' &&
        scheduler.recurrencePattern.includes(date.toFormat('cccc').toLowerCase()) &&
        DateTime.fromISO(scheduler.recurrenceStartsAt) <= date.endOf('day') &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !bToBJobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(job.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        )
    )

    const monthlySchedulersForDay = bToBJobSchedulers.filter(
      scheduler =>
        scheduler.recurrencePeriod === 'month' &&
        scheduler.recurrencePattern.includes(date.toFormat('dd')) &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceStartsAt && DateTime.fromISO(scheduler.recurrenceStartsAt) >= date.endOf('month')
          ? false
          : true) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !bToBJobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(job.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        )
    )

    const yearlySchedulersForDay = bToBJobSchedulers.filter(
      scheduler =>
        scheduler.recurrencePeriod === 'year' &&
        scheduler.recurrencePattern.includes(date.toFormat('MM-dd')) &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceStartsAt && DateTime.fromISO(scheduler.recurrenceStartsAt) >= date.endOf('month')
          ? false
          : true) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !bToBJobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(job.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        )
    )

    return weeklySchedulersForDay.concat(monthlySchedulersForDay).concat(yearlySchedulersForDay)
  }

  const populatedScheduleRows = [...Array(rowCount).keys()].map(rowIndex =>
    [...Array(DAYS_PER_ROW).keys()].map(dayIndex => {
      const thisDateTime = startDatetime.plus({ days: rowIndex * DAYS_PER_ROW }).plus({ days: dayIndex })

      const jobsOnThisDay = jobs.filter(job =>
        DateTime.fromISO(job.startDatetime, { setZone: true }).hasSame(thisDateTime, 'day')
      )

      const bToBJobsOnThisDay =
        bToBJobs
          .filter(job => DateTime.fromISO(job.startDatetime, { zone: 'utc' }).hasSame(thisDateTime, 'day'))
          .map(appt => ({ ...appt, type: 'bToBJob' })) || []

      const bToBJobSchedulersOnThisDay = getSchedulersForDay(thisDateTime)

      const heldJobsFromOnThisDay = heldJobs.filter(job =>
        DateTime.fromISO(job.startDatetime, { setZone: true }).hasSame(thisDateTime, 'day')
      )

      const ptoOnThisDate = pto.filter(job =>
        DateTime.fromISO(job.timeOffDate, { setZone: true }).hasSame(thisDateTime, 'day')
      )
      const utoOnThisDate = uto.filter(job =>
        DateTime.fromISO(job.timeOffDate, { setZone: true }).hasSame(thisDateTime, 'day')
      )
      const stoOnThisDate = sto.filter(job =>
        DateTime.fromISO(job.timeOffDate, { setZone: true }).hasSame(thisDateTime, 'day')
      )

      return {
        dateTime: thisDateTime,
        jobs: jobFilter?.b2c ? [...heldJobsFromOnThisDay, ...jobsOnThisDay] : [],
        bToBJobs: jobFilter?.b2b ? bToBJobsOnThisDay : [],
        bToBJobSchedulers: jobFilter?.b2b ? bToBJobSchedulersOnThisDay : [],
        pto: ptoOnThisDate,
        uto: utoOnThisDate,
        sto: stoOnThisDate,
      }
    })
  )

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          '&.Mui-expanded .MuiAccordionSummary-content': {
            marginBottom: 0,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            sx={{ minHeight: '20px', minWidth: '20px', padding: '.25rem', '& svg': { pointerEvents: 'none' } }}
            onClick={e => {
              e.stopPropagation()
              refetch()
            }}
          >
            <RefreshRounded />
          </Button>{' '}
          <Link style={{ fontSize: '1.1em', fontWeight: 'bold' }} to={`/technicians/${technician.id}`}>
            {technician.name}
          </Link>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Grid item xs={12}>
          <div style={{ padding: '0.2em 1em 1em' }}>
            <Typography variant='body1' sx={{ fontSize: '12px', mt: '.5rem', fontWeight: 600 }}>
              {technician.note}
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '12px', mt: '.5rem', fontWeight: 600 }}>
              <span style={{ display: 'inline-block', color: '#777' }}>
                {Object.entries(technician.availability)
                  .filter(avail => avail[1] !== true && avail[1] !== false)
                  .map(e => {
                    return e[0] + ':' + (e[1].length > 0 ? e[1] : ' none') + ', '
                  })}
              </span>
            </Typography>
          </div>

          {populatedScheduleRows.map((populatedSchedule, index) => (
            <Paper key={index} style={{ padding: 0 }} className={classes.paper}>
              <TableContainer>
                <Table aria-label='simple table'>
                  <TableHead sx={{ padding: '0px' }}>
                    {populatedSchedule.map(dateAndJobs => {
                      const totalForWeek = getWeekLaborValue({ populatedSchedule, date: dateAndJobs.dateTime }).toFixed(
                        2
                      )

                      const totalIsGreen = totalForWeek > 3999.99
                      const totalIsYellow = totalForWeek > 2999.99 && totalForWeek < 3999.99
                      const totalIsRed = totalForWeek < 2999.99
                      const totalIsGray = Number(totalForWeek) === 0

                      return (
                        <>
                          <TableCell
                            id={dateAndJobs.dateTime.toISO()}
                            sx={[
                              totalIsGreen && { background: '#cbf7cf' },
                              totalIsYellow && { background: '#f7f1cb' },
                              totalIsRed && { background: '#f7cecb' },
                              totalIsGray && { background: '#f1f1f1', opacity: 0.5 },
                              {
                                padding: '0px .5rem',
                                textAlign: dateAndJobs.dateTime.weekday === 7 ? 'right' : 'left',
                                fontSize: '12px',
                                fontWeight: 600,
                              },
                              dateAndJobs.dateTime.weekday === 7 && { borderRight: '1px solid #aaa' },
                            ]}
                            key={dateAndJobs.dateTime}
                          >
                            {dateAndJobs.dateTime.weekday === 1 &&
                              (totalIsGreen ? (
                                <Box sx={{ '& svg': { fontSize: '12px' } }}>
                                  + <Dollars value={totalForWeek - 4000} />
                                </Box>
                              ) : (
                                <Box sx={{ '& svg': { fontSize: '12px' } }}>
                                  <SouthRounded /> <Dollars value={4000 - totalForWeek} />
                                </Box>
                              ))}
                            {dateAndJobs.dateTime.weekday === 7 && (
                              <WeekLaborValue date={dateAndJobs.dateTime} populatedSchedule={populatedSchedule} />
                            )}
                          </TableCell>
                        </>
                      )
                    })}
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      {populatedSchedule.map(dateAndJobs => (
                        <>
                          <TableCell
                            onDragEnter={e => setDropIndicator(dateAndJobs.dateTime.toISO())}
                            sx={[
                              {
                                padding: '4px',
                                borderBottom:
                                  dropIndicator === dateAndJobs.dateTime.toISO()
                                    ? '1px solid #2486E9'
                                    : '1px solid #ddd',
                              },
                              dateAndJobs.dateTime.toFormat('MM/dd') === DateTime.now().toFormat('MM/dd') && {
                                color: '#3852B0 ',
                                fontWeight: 600,
                              },
                              dateAndJobs.dateTime < DateTime.now().startOf('day').minus({ days: 1 }) && {
                                opacity: '.5 !important',
                              },
                            ]}
                            className={[
                              classes.schedule_th_cell,
                              dateAndJobs.dateTime.weekday === 7 && classes.week_end_cell,
                            ].join(' ')}
                            key={dateAndJobs.dateTime}
                          >
                            {dateAndJobs.dateTime.toFormat('ccc, L/d')} <br />
                            <DayLaborValue
                              dateIsCurrentDay={
                                dateAndJobs.dateTime.startOf('day').toFormat('MM/dd') ===
                                DateTime.now().toFormat('MM/dd')
                              }
                              jobsForDay={dateAndJobs.jobs}
                            />
                          </TableCell>
                        </>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      {populatedSchedule.map(dateAndJobs => (
                        <TableCell
                          // id={dateAndJobs.dateTime.toISO()}
                          id='job-cell-parent'
                          date={dateAndJobs.dateTime.toISO()}
                          tech-id={technician.id}
                          onDragEnter={e => setDropIndicator(dateAndJobs.dateTime.toISO())}
                          className={[
                            classes.schedule_row_cell,
                            dateAndJobs.dateTime.weekday === 7 && classes.week_end_cell,
                            aboutToRescheduleTo &&
                              aboutToRescheduleTo.startOf('day').toISO() ===
                                dateAndJobs.dateTime.startOf('day').toISO() &&
                              classes.rescheduling_to_here,
                          ].join(' ')}
                          key={dateAndJobs.dateTime}
                          sx={[
                            dateAndJobs.dateTime.toFormat('MM/dd') === DateTime.now().toFormat('MM/dd') && {
                              borderColor: '#3852B0 !important',
                              borderRightWidth: '2px !important',
                            },
                            {
                              background: dropIndicator === dateAndJobs.dateTime.toISO() ? '#2486E980' : '#fff',
                              borderBottom:
                                dropIndicator === dateAndJobs.dateTime.toISO() ? '1px solid #2486E9' : '1px solid #ddd',
                            },
                          ]}
                        >
                          {dateAndJobs.jobs.length > 0 &&
                            dateAndJobs.jobs
                              .slice()
                              .sort((a, b) =>
                                DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1
                              )
                              .map(job => (
                                <React.Fragment key={job.id}>
                                  {focusJob && focusJob.id === job.id && (
                                    <div style={{ color: 'red' }}>
                                      <small>THIS JOB</small>
                                    </div>
                                  )}

                                  {focusJob && job.heldForReschedulingAt && (
                                    <button onClick={() => setReschedulingJob(job)}>click to reschedule</button>
                                  )}

                                  {!focusJob && job.heldForReschedulingAt && (
                                    <>
                                      <Link
                                        style={{
                                          fontSize: '12px',
                                          color: 'red',
                                          border: '2px solid red',
                                          display: 'inline-block',
                                          padding: '3px',
                                        }}
                                        to={`/leads/${job.leadId}/quotes/${job.quoteId}/jobs/${job.id}`}
                                      >
                                        held job, needs rescheduling
                                        <br />
                                        {job.lead.name}
                                      </Link>
                                      <br />
                                    </>
                                  )}

                                  {!!job.heldForReschedulingAt || (
                                    <JobCell
                                      jobIsInPast={
                                        dateAndJobs.dateTime < DateTime.now().startOf('day').minus({ days: 1 })
                                      }
                                      reschedulingJob={reschedulingJob}
                                      setReschedulingJob={setReschedulingJob}
                                      focusJob={focusJob}
                                      key={job.id}
                                      job={job}
                                      onDragEnd={() => setDropIndicator('')}
                                      refetch={refetch}
                                    />
                                  )}
                                </React.Fragment>
                              ))}

                          {dateAndJobs.bToBJobs.length > 0 &&
                            dateAndJobs.bToBJobs
                              .slice()
                              .sort((a, b) =>
                                DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1
                              )
                              .map(job => (
                                <React.Fragment key={job.id}>
                                  <BToBJobCell
                                    jobIsInPast={
                                      dateAndJobs.dateTime < DateTime.now().startOf('day').minus({ days: 1 })
                                    }
                                    key={job.id}
                                    job={job}
                                  />
                                </React.Fragment>
                              ))}

                          {dateAndJobs.bToBJobSchedulers.length > 0 &&
                            dateAndJobs.bToBJobSchedulers
                              .slice()
                              .sort((a, b) =>
                                DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1
                              )
                              .map(job => (
                                <React.Fragment key={job.id}>
                                  <BToBJobCell
                                    jobIsInPast={
                                      dateAndJobs.dateTime < DateTime.now().startOf('day').minus({ days: 1 })
                                    }
                                    key={job.id}
                                    job={job}
                                  />
                                </React.Fragment>
                              ))}

                          {dateAndJobs?.pto?.map(pto => (
                            <span
                              style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                display: 'block',
                                textAlign: 'center',
                                border: '2px solid gray',
                                color: 'gray',
                                fontWeight: 'bold',
                              }}
                            >
                              PAID TIME OFF - {pto.totalHours} hour(s)
                            </span>
                          ))}

                          {dateAndJobs?.uto?.map(pto => (
                            <span
                              style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                display: 'block',
                                textAlign: 'center',
                                border: '2px solid gray',
                                color: 'gray',
                                fontWeight: 'bold',
                              }}
                            >
                              UNPAID TIME OFF - {pto.totalHours} hour(s)
                            </span>
                          ))}

                          {dateAndJobs?.sto?.map(pto => (
                            <span
                              style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                display: 'block',
                                textAlign: 'center',
                                border: '2px solid gray',
                                color: 'gray',
                                fontWeight: 'bold',
                              }}
                            >
                              SICK TIME OFF - {pto.totalHours} hour(s)
                            </span>
                          ))}

                          {technician.offdays.includes(dateAndJobs.dateTime.toISODate()) && (
                            <span
                              style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                display: 'block',
                                textAlign: 'center',
                                border: '2px solid gray',
                                color: 'gray',
                                fontWeight: 'bold',
                              }}
                            >
                              REQUEST OFF
                            </span>
                          )}

                          {technician.availability[dateAndJobs.dateTime.toFormat('EEEE').toLowerCase()].length < 1 &&
                            !technician.offdays.includes(dateAndJobs.dateTime.toISODate()) &&
                            dateAndJobs.jobs.length === 0 && (
                              <span
                                style={{
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  display: 'block',
                                  textAlign: 'center',
                                  border: '2px solid gray',
                                  color: 'gray',
                                  fontWeight: 'bold',
                                }}
                              >
                                OFF DAY
                              </span>
                            )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ))}

          {reschedulingJob && (
            <RescheduleJob
              job={reschedulingJob}
              onChangePendingDate={setAboutToRescheduleTo}
              onClose={() => setReschedulingJob(null)}
            />
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
