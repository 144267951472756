import { Box } from '@mui/material'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import JobApplicationView from './InterviewView'
import InterviewTable from './InterviewTable'

const Interviews = () => {
  const { url } = useRouteMatch()

  return (
    <Box>
      <Switch>
        <Route path={`${url}/:interviewId`}>
          <JobApplicationView />
        </Route>

        <Route path={url}>
          <InterviewTable />
        </Route>
      </Switch>
    </Box>
  )
}

export default Interviews
