import { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import {
  Paper,
  Box,
  Button,
  SvgIcon,
  Chip,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import FilterListIcon from '@mui/icons-material/FilterList'
import ReorderIcon from '@mui/icons-material/Reorder'

import { GlobalContext } from 'GlobalStore'

import { SuppliesOrderSummary } from './SuppliesOrderSummary'
import { Dollars } from 'tools'

const SUPPLIES_ORDERS_CONNECTION = gql`
  query suppliesOrdersConnection($search: JSON, $order: JSON, $filter: JSON, $first: Int, $after: String) {
    suppliesOrdersConnection(search: $search, order: $order, filter: $filter, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          itemIds
          createdAt
          placedAt
          rejectedAt
          purchasePrice
          technician {
            id
            firstName
            lastName
          }
          updatedBy {
            firstName
            lastName
          }
          placedBy {
            firstName
            lastName
          }
          rejectedBy {
            firstName
            lastName
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  employeeField: {
    display: 'inline-flex',
    width: 250,
  },
  dateField: {
    display: 'inline-flex',
    width: 150,
  },
  nameLabel: {
    minWidth: 120,
  },
  dateDialog: {
    display: 'flex',
    '& div': {
      padding: '5px',
      '& span': {
        background: '#eee',
        display: 'block',
        padding: '3px',
        borderRadius: '3px 3px 0px 0px',
        border: '1px solid #ccc',
        borderBottom: '1px dashed #eee',
      },
      '& input': {
        padding: '3px',
        borderRadius: '0px 0px 3px 3px',
        border: '1px solid #ccc',
        borderTop: '0px',
      },
    },
  },
}))

const All = ({ items }) => {
  const classes = useStyles()
  const [global] = useContext(GlobalContext)
  const [search, setSearch] = useState(JSON.parse(localStorage.getItem('suppliesOrdersSearch')) || {})
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem('suppliesOrdersOrder')) || {})
  const [filter, setFilter] = useState(JSON.parse(localStorage.getItem('suppliesOrdersFilter')) || {})
  const [limit, setLimit] = useState(JSON.parse(localStorage.getItem('suppliesOrdersLimit')) || 50)
  useEffect(() => localStorage.setItem('suppliesOrdersSearch', JSON.stringify(search)), [search])
  useEffect(() => localStorage.setItem('suppliesOrdersOrder', JSON.stringify(order)), [order])
  useEffect(() => localStorage.setItem('suppliesOrdersFilter', JSON.stringify(filter)), [filter])
  useEffect(() => localStorage.setItem('suppliesOrdersLimit', JSON.stringify(limit)), [limit])

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  const removeFilter = filterName => {
    const { [filterName]: removed, ...remainingFilters } = filter
    setFilter(remainingFilters)
  }

  const setOrToggleOrder = name => {
    const { [name]: removed, ...remainingOrder } = order
    if (order[name] === 'asc') setOrder(remainingOrder)
    else setOrder({ [name]: order[name] ? 'asc' : 'desc', ...remainingOrder })
  }

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: limit,
        after: '',
      },
    })
  }, [search, order, filter, limit])

  const { loading, error, data, fetchMore } = useQuery(SUPPLIES_ORDERS_CONNECTION, {
    variables: {
      search,
      order,
      filter,
      first: limit,
      after: cursorCeiling,
    },
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const suppliesOrders = data.suppliesOrdersConnection.edges.map(edge => edge.node)
  const totalCount = data.suppliesOrdersConnection.totalCount
  const endCursor = data.suppliesOrdersConnection.pageInfo.endCursor

  const DateRangeHeadCell = ({ name }) => {
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [dateRange, setDateRange] = useState(filter[name] || {})
    // ONE of BOTH key of: { ge: iso8601, le: iso8601 } OR null
    // ge -> greater than or equal
    // le -> less than or equal

    const handleDateTimeChanged = evt => {
      setDateRange({ ...dateRange, [evt.target.name]: evt.target.value })
    }

    const handleClickApply = () => {
      setFilter({ ...filter, [name]: dateRange })
    }

    const handleClickClose = () => {
      setDialogIsOpen(false)
    }

    const handleDeleteRangeKey = key => {
      const { [key]: removed, ...rest } = dateRange
      setDateRange(rest)
      setFilter({ ...filter, [name]: rest })
    }

    return (
      <TableCell align='right'>
        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={() => setOrToggleOrder(name)}>
          {!order[name] && <SvgIcon fontSize='small' component={ReorderIcon} />}
          {order[name] === 'asc' && <SvgIcon fontSize='small' component={ArrowUpwardIcon} />}
          {order[name] === 'desc' && <SvgIcon fontSize='small' component={ArrowDownwardIcon} />}
        </Button>

        <>{name}</>

        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={() => setDialogIsOpen(true)}>
          <SvgIcon fontSize='small' component={FilterListIcon} />
        </Button>

        {filter[name] && Object.entries(filter[name]).length > 0 && (
          <>
            <br />
            {Object.entries(filter[name])
              .sort((a, b) => (a[0] > b[0] ? 1 : -1))
              .map(filterTuple => (
                <Chip
                  key={filterTuple[0]}
                  variant='outlined'
                  size='small'
                  onDelete={() => handleDeleteRangeKey(filterTuple[0])}
                  label={`
                    ${filterTuple[0] === 'le' ? 'before' : 'after'}
                    ${DateTime.fromISO(filterTuple[1]).toFormat('yy-MM-dd')}
                  `}
                />
              ))}
          </>
        )}

        <Dialog open={dialogIsOpen} onClose={setDialogIsOpen}>
          <DialogTitle>Select Date Range (One Or Both)</DialogTitle>

          <DialogContent className={classes.dateDialog}>
            <Box>
              <span>
                Start Date<small> (optional)</small>
              </span>
              <input type='datetime-local' name='ge' value={dateRange.ge || ''} onChange={handleDateTimeChanged} />
            </Box>
            <Box>
              <span>
                End Date<small> (optional)</small>
              </span>
              <input type='datetime-local' name='le' value={dateRange.le || ''} onChange={handleDateTimeChanged} />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClickClose} color='primary'>
              Close
            </Button>
            <Button onClick={handleClickApply} color='primary' autoFocus>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    )
  }

  const SelectTechnicianHeadCell = props => {
    const [technicianId, setTechnicianId] = useState((filter.technician_id && filter.technician_id.eq) || '')

    const handleChange = evt => {
      if (evt.target.value === 'clear') {
        removeFilter('technician_id')
      } else {
        setFilter({ ...filter, technician_id: { eq: evt.target.value } })
      }
    }

    const technicians = global.technicians.filter(technician => technician.active && technician.isEmployee)

    return (
      <TableCell {...props}>
        {!technicianId && <>Technician</>}
        <Select color='primary' size='small' label='Technician' value={technicianId} onChange={handleChange}>
          <MenuItem value='clear'>
            <i>clear</i>
          </MenuItem>
          {technicians.map(technician => (
            <MenuItem key={technician.id} value={technician.id}>
              <>
                {technician.firstName} {technician.lastName}
              </>
            </MenuItem>
          ))}
        </Select>
      </TableCell>
    )
  }

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: limit,
          after: endCursor,
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  const formatDateTime = dtString => {
    const dt = DateTime.fromISO(dtString)
    return dt.isValid && dt.toLocaleString()
  }
  const formatFullName = user => user && user.firstName + ' ' + user.lastName

  return (
    <>
      <TableContainer component={Paper}>
        <Table size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: '300px' }}>id</TableCell>
              <SelectTechnicianHeadCell align='right' />
              <TableCell align='right'>items</TableCell>
              <DateRangeHeadCell name='created_at' />
              <DateRangeHeadCell name='placed_at' />
              <TableCell align='right' className={classes.nameLabel}>
                placed_by
              </TableCell>
              <TableCell align='right' className={classes.nameLabel}>
                purchase price
              </TableCell>

              <DateRangeHeadCell name='rejected_at' />
              <TableCell align='right' className={classes.nameLabel}>
                rejected_by
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliesOrders.map(suppliesOrder => (
              <TableRow key={suppliesOrder.id}>
                <TableCell component='th'>
                  <Link to={'/supplies-orders/' + suppliesOrder.id}>{suppliesOrder.id}</Link>
                </TableCell>
                <TableCell align='right'>
                  <Link to={'/technicians/' + suppliesOrder.technician.id}>
                    {formatFullName(suppliesOrder.technician)}
                  </Link>
                </TableCell>
                <TableCell align='right' sx={{ maxWidth: 400 }}>
                  <SuppliesOrderSummary items={items} order={suppliesOrder} />
                </TableCell>
                <TableCell align='right'>{formatDateTime(suppliesOrder.createdAt)}</TableCell>
                <TableCell align='right'>{formatDateTime(suppliesOrder.placedAt)}</TableCell>
                <TableCell align='right'>{formatFullName(suppliesOrder.placedBy)}</TableCell>
                <TableCell align='right'>
                  {suppliesOrder.purchasePrice ? (
                    <Dollars value={suppliesOrder.purchasePrice} />
                  ) : (
                    <span style={{ color: '#aaa' }}>Not available</span>
                  )}
                </TableCell>
                <TableCell align='right'>{formatDateTime(suppliesOrder.rejectedAt)}</TableCell>
                <TableCell align='right'>{formatFullName(suppliesOrder.rejectedBy)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}

export default All
