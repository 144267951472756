import { useEffect, useState, useContext } from 'react'
import { DateTime } from 'luxon'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { TextField } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { prettyPhoneNumber, basicDateTimeFormat } from 'tools'
import { UserContext } from 'UserStore'
import { GlobalContext } from 'GlobalStore'
import Button from '@mui/material/Button'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import phoneBook from 'data/phoneBook'
import { keysToCamel } from 'tools'
import { useQuery, gql } from '@apollo/client'
import CustomerCard from './components/CustomerCard'

const QUERY = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      id
      firstName
      lastName
      phone
      noClose
      noCloseRecentConvert
      outboundNumber
      botSessions {
        id
       completedAt
      }
    }
  }
`

const Show = ({ callback, isPopover }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const [attemptDisabled, setAttemptDisabled] = useState(false)
  const [csrReason, setCsrReason] = useState(callback.csrReasonOther || '')
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)

  const userById = (userId) => global.users.find((user) => user.id === userId)

  const { loading, error, data } = useQuery(QUERY, {
    variables: { id: callback?.customer?.id },
  })


  useEffect(() => {
    const key1 = !isPopover && registerTaskTarget({ targetType: 'Customer', targetId: callback?.customer?.id })
    const key2 = !isPopover && registerTaskTarget({ targetType: 'CallbackRequest', targetId: callback?.id })
    return () => {
      !isPopover && unregisterTaskTarget(key1)
      !isPopover && unregisterTaskTarget(key2)
    }
  }, [])

  if (loading || error) return <div>Loading...</div>

  const customer = keysToCamel(data.customer)

  const handleClickAttemptMade = () => {
    setAttemptDisabled(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_callback_request_attempt`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        callback_request_id: callback.id
      })
    })
      .then((response) => {
        if (response.ok) {
          return true
        } else {
          throw Error('error')
        }
      })
      .catch((e) => {
        alert(e.message)
      })
  }

  const handleClickSaveReason = () => {
    setAttemptDisabled(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_callback_request_reason`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        callback_request_id: callback.id,
        reason: csrReason
      })
    })
      .then((response) => {
        if (response.ok) {
          return true
        } else {
          throw Error('error')
        }
      })
      .catch((e) => {
        alert(e.message)
      })
  }

  const handleToggleCompleted = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_callback_request_completed`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        callback_request_id: callback.id,
        completed: !callback.completedAt
      })
    })
      .then((response) => {
        if (response.ok) {
          return true
        } else {
          throw Error('error')
        }
      })
      .catch((e) => {
        alert(e.message)
      })

  return (
    <>
      <Typography variant='h5'>Callback Request</Typography>

      <div>
        <ul>
          <li style={{ color: 'red', fontWeight: 700 }}>
            reason: {callback.customerReason}
          </li>
          {callback.customerReasonOther && (
            <li style={{ color: 'red', fontWeight: 700 }}>
              additional reason info: {callback.customerReasonOther}
            </li>
          )}
          <li>
            customer: <Link to={`/customers/${callback.customer.id}`}>{callback.customer.firstName} {callback.customer.lastName}</Link>
          </li>

          <li>
            customers number:&nbsp;
            <CopyToClipboard style={{ cursor: 'pointer' }} text={callback.toNumber}>
              <a href={`carbodylab-phone://dial?toNumber=${callback.toNumber}&fromNumber=${callback.customer.outboundNumber}`}>
                {prettyPhoneNumber(callback.toNumber)}
              </a>
            </CopyToClipboard>
          </li>

          <li>
            call from: {phoneBook[callback.customer.outboundNumber]} {prettyPhoneNumber(callback.customer.outboundNumber)}
          </li>

          <li>created at: {DateTime.fromISO(callback.createdAt).toFormat(basicDateTimeFormat)}</li>
          <li>source: {callback.source}</li>
          <li>zip code: {callback.zip}</li>
        </ul>

        <div>
          <Button sx={{ mb: '.5rem' }} disabled={attemptDisabled} onClick={handleClickAttemptMade} variant='contained'>
            Callback attempt made
          </Button>
        </div>

        {(callback.attempts.length > 0 &&
          <>
            <div>Callback attempts:</div>
            <ul>
              {callback.attempts.map((attempt) => {
                const user = userById(attempt.userId)
                return (
                  <li key={attempt.id}>
                    {user.firstName} {user.lastName} &nbsp;
                    {DateTime.fromISO(attempt.timestamp).toFormat(basicDateTimeFormat)}
                  </li>
                )
              })}
            </ul>
          </>
        ) || (
          <>
            <br />
            NO ATTEMPTS
          </>
        )}

        <div>
          <b style={{ display: 'block' }}>Enter a reason for the call:</b>
          <i style={{ display: 'block', marginTop: '.5rem', marginBottom: '.5rem' }}>
            (only type here after you have spoken with the customer, if they did not answer then only click "callback
            attempt made")
          </i>
          <TextField
            multiline
            InputProps={{ style: { minWidth: '500px' } }}
            style={{ display: 'block' }}
            rows={5}
            disabled={callback.completedAt || callback.attempts.length < 1}
            value={csrReason}
            onChange={(e) => setCsrReason(e.target.value)}
          />
          <Button
            onClick={handleClickSaveReason}
            disabled={
              (csrReason.length < 5) ||
              callback.completedAt ||
              callback.csrReasonOther === csrReason.trim()
            }
            variant='contained'
            style={{ marginTop: '.5rem', marginLeft: '400px', minWidth: '100px' }}
          >
            save
          </Button>
        </div>

        <FormGroup>
          <FormControlLabel
            disabled={!callback.csrReasonOther}
            control={<Checkbox checked={callback.completedAt} onChange={handleToggleCompleted} />}
            label='Completed (no more callbacks required)'
          />
        </FormGroup>

        <CustomerCard customer={customer} />
      </div>
    </>
  )
}

export default Show
