import { Fragment, useEffect, useContext, useState, useMemo } from 'react'
import { UserContext } from 'UserStore'
import { Box, CircularProgress } from '@mui/material'
import { gql, useQuery } from '@apollo/client'

const QUOTE_QUERY = gql`
  query quote($id: ID!) {
    quote(id: $id) {
      lead {
        id
        name
        make
        model
        year
      }
      id
      quoteGrandTotal
      ccFeeSum
      totalTransactionAmount
      tipSum
      laborSumAfterAllDiscounts
      technicianChargesSum
      paymentStatus
      fullyPaidAt
      payoutIds
      payrollStatuses
      payrollNotesCount
      technician {
        id
        commissionAmount
        isEmployee
        state
      }
    }
  }
`

const Problems = () => {
  const [user] = useContext(UserContext)
  const [problems, setProblems] = useState({ WAIT: 'this query takes a long time' })
  const [firstRendered, setFirstRendered] = useState(false)

  const problemKeys = useMemo(() => Object.keys(problems), [problems])

  useEffect(() => {
    if (!firstRendered) setFirstRendered(true)
    else return

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/payroll_problems`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ user_id: user.id }),
    })
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw 'Error'
        }
      })
      .then(data => setProblems(data))
      .catch(error => alert(error))
  }, [])

  if (problemKeys[0] === 'WAIT')
    return (
      <Box
        sx={{
          display: 'flex',
          position: 'fixed',
          top: '0px',
          bottom: '0px',
          left: '0px',
          right: '0px',
          background: '#44444480',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <pre style={{ fontSize: '1em' }}>
      {problemKeys.map(problemKey => (
        <Fragment key={problemKey}>
          <b>{problemKey}: [</b>
          {problems[problemKey]?.slice()?.map(item => (
            <QuoteRow key={item} quoteId={item} />
          ))}
          <b>]</b>
          <br />
        </Fragment>
      ))}
    </pre>
  )
}

const QuoteRow = ({ quoteId }) => {
  const { data, loading, error } = useQuery(QUOTE_QUERY, { variables: { id: quoteId } })

  const quote = data?.quote

  // console.log({ quote })

  return (
    <Box>
      <li>
        <a href={`https://crm.carbodylab.com/quotes/${quoteId}`}>
          {quoteId} - {quote?.lead?.name} - {quote?.lead?.year}- {quote?.lead?.make}- {quote?.lead?.model}
        </a>
      </li>
    </Box>
  )
}

export default Problems
