import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from 'GlobalStore'
import { useQuery, gql, useLazyQuery } from '@apollo/client'
import { makeStyles } from '@mui/styles'
import { DateTime } from 'luxon'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { DistanceMatrixService, Marker, GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import Button from '@mui/material/Button'
import Geocode from 'react-geocode'
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Chip,
  Popover,
  Stack,
} from '@mui/material'
import { checkSubsetArray, Dollars, keysToCamel } from 'tools'
import PartStatusSquare from 'components/PartStatusSquare'
import { ChatRounded, CheckCircleRounded, ErrorRounded, VerifiedRounded } from '@mui/icons-material'
import CustomerSmsChat from 'CustomerSmsChat'
import { BToBJobCellWithDrivingDistance } from 'Schedule/components/BToBJobCell'
import WeatherForecast from './WeatherForecast'

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
Geocode.setApiKey(GOOGLE_MAPS_API_KEY)

const MIN_ZOOM_LEVEL = 6

const TECHNICIANS = gql`
  query ($filter: JSON) {
    technicians(filter: $filter) {
      id
      name
      email
      phone
      addressLineOne
      addressLineTwo
      city
      state
      zip
      lat
      lng
      note
      replacements
      availability
      offdays
      active
      note
      skillsets
    }
  }
`

const QUOTE_FOR_JOB_VALUE = gql`
  query getQuote($id: ID!) {
    quote(id: $id) {
      id
      balanceAmountDue
      preJobAmountDue
      payments
      requiredSkillsets
      difficultyLevel
      approvedTechnicianIds
      postTaxGrandTotal
      parts {
        id
      }
    }
  }
`

const GET_SINGLE_JOB = gql`
  query getJob($id: ID!) {
    job(id: $id) {
      technicianId
      quote {
        requiredSkillsets
        difficultyLevel
        approvedTechnicianIds
        parts {
          id
        }
      }
      technician {
        skillsets
        name
      }
    }
  }
`

export const JobValue = ({ quoteId }) => {
  const { loading, error, data } = useQuery(QUOTE_FOR_JOB_VALUE, {
    variables: { id: quoteId },
  })
  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return (
    <div style={{ cursor: 'pointer' }} onClick={() => window.open(`/quotes/${quoteId}`)}>
      quoted: <Dollars value={data.quote.postTaxGrandTotal} />
    </div>
  )
}

export const jobSummaryText = (dateString, job) => (
  <>
    {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)} -
    <br />
    {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)}
  </>
)

export const visitCountStyles = makeStyles(theme => ({
  visitCount: {
    fontWeight: 'bold',
    '&.visit2': {
      color: 'orange',
    },
    '&.visit3': {
      color: 'red',
    },
  },
}))

export const getVisitCount = ({ job }) => {
  const visitNumber =
    job.lead.jobs
      .slice()
      .sort((a, b) => (DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1))
      .findIndex(thisJob => thisJob.id === job.id) + 1

  if (visitNumber === 1) {
    return null
  }
  return visitNumber
}

export const VisitCount = ({ job }) => {
  const classes = visitCountStyles()
  const visitNumber = getVisitCount({ job })

  if (visitNumber) {
    return <span className={classes.visitCount + ` visit${Math.min(visitNumber, 3)}`}>visit #{visitNumber}</span>
  } else {
    return null
  }
}

export const getCoordinates = (addressLineOne, city, state, zip) => {
  return new Promise((resolve, reject) => {
    if (!zip) {
      reject('Missing zip code')
    }

    if (addressLineOne) {
      Geocode.fromAddress(`${addressLineOne}, ${city}, ${state}`).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          resolve({ lat, lng })
        },
        error => {
          console.error(error)
          reject(error)
        }
      )
    } else {
      Geocode.fromAddress(`${zip}`).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          resolve({ lat, lng })
        },
        error => {
          console.error(error)
          reject(error)
        }
      )
    }
  })
}

export const PartStyles = makeStyles(theme => ({
  parts: {
    backgroundColor: 'white',
    borderRadius: '2px',
    lineHeight: 0,
    border: '1px solid #e6e6e6',
    padding: '2px 2px 0px 2px',
  },
  part: {
    cursor: 'pointer',
    display: 'inline-block',
    margin: '0 0 2px 0',
  },
}))

export const PartStatus = ({ quote }) => {
  const history = useHistory()
  const classes = PartStyles()
  const parts = quote.parts

  const handleClickPart = id => history.push(`/parts/${id}`)

  return (
    <div className={classes.parts}>
      {parts.map(part => {
        return (
          <div onClick={() => handleClickPart(part.id)} key={part.id} className={classes.part}>
            <PartStatusSquare part={part} />
          </div>
        )
      })}
    </div>
  )
}

export const JobCell = ({
  job,
  scheduleDay,
  showDrivingDistanceDays,
  leadCoordinates,
  chunkIndex,
  setJobAnchorEl,
  setJobChatCustomer,
  currentJob,
}) => {
  const classes = techScheduleStyles()
  const quoteIsFullyPaid = job?.quote?.paymentStatus === 'fully_paid' || false
  const visitCount = job.type === 'job' ? getVisitCount({ job }) : null
  const thisJobQuote = job.quote
  const currentJobTech = currentJob?.technician
  const currentTechCanDoThisJob =
    currentJobTech &&
    thisJobQuote &&
    checkSubsetArray(
      currentJobTech.skillsets,
      thisJobQuote.requiredSkillsets
        .concat(thisJobQuote.difficultyLevel)
        .concat((thisJobQuote.parts.length > 0 && ['part replacements']) || [])
    )

  return job.type === 'job' ? (
    <Box key={job.id} sx={[visitCount && quoteIsFullyPaid && { border: '2px solid #e6ba6a' }]}>
      <Box className={classes.job} key={job.leadId}>
        <Grid item xs={12} sx={{ textAlign: 'center', background: '#e6ba6a', fontSize: '12px', fontWeight: 600 }}>
          {visitCount && quoteIsFullyPaid && <>FULLY PAID</>}
        </Grid>
        <Grid item xs={12}>
          <VisitCount job={job} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            '& svg': { fontSize: '.75rem', cursor: 'pointer', '&:hover': { boxShadow: '0px 0px 4px red' } },
          }}
        >
          <div>
            <b>
              {job?.lead?.emojis?.map((emoji, index) => (
                <em-emoji set='apple' key={index} shortcodes={emoji}></em-emoji>
              ))}&nbsp;
              {job.lead.name}
            </b>
            {setJobAnchorEl && setJobChatCustomer && (
              <ChatRounded
                title='quick view sms'
                onClick={e => {
                  setJobAnchorEl(e.target)
                  setJobChatCustomer(job.lead.customer)
                }}
              />
            )}
          </div>
        </Grid>
        <Link target='_blank' to={`/leads/${job.leadId}/quotes/${job.quoteId}/${job.type}s/${job.id}`}>
          {job.type === 'appointment' && <b> PENDING APPOINTMENT </b>}

          {jobSummaryText(scheduleDay.dateString, job)}
        </Link>
        <Grid item xs={12}>
          <PartStatus quote={job.quote} />
        </Grid>
        <JobValue quoteId={job.quoteId} />
        {currentTechCanDoThisJob && (
          <Box
            title={`${currentJobTech.name} is qualified to perform this repair`}
            sx={{ '& svg': { fontSize: '14px' } }}
          >
            <VerifiedRounded color='success' />
          </Box>
        )}
      </Box>
      <Box>
        {showDrivingDistanceDays > 0 &&
          leadCoordinates?.lat &&
          leadCoordinates?.lng &&
          showDrivingDistanceDays / 14 >= chunkIndex + 1 && (
            <TravelDistanceFromLeadIdToCoordinates fromLeadId={job.leadId} toCoordinates={leadCoordinates} />
          )}
      </Box>
    </Box>
  ) : (
    <Box key={job.id}>
      <Box className={classes.job} key={job.leadId} sx={{ border: '2px dashed #e6ba6a !important' }}>
        <Grid item xs={12}>
          <div>
            <b>
              {job?.lead?.emojis?.map((emoji, index) => (
                <em-emoji set='apple' key={index} shortcodes={emoji}></em-emoji>
              ))}
              {job.lead.name}
            </b>
          </div>
        </Grid>
        <Link target='_blank' to={`/leads/${job.leadId}/quotes/${job.quoteId}/${job.type}s/${job.id}`}>
          {job.type === 'appointment' && <b> PENDING APPOINTMENT </b>}
          {jobSummaryText(scheduleDay.dateString, job)}
        </Link>
        <JobValue quoteId={job.quoteId} />
        {currentTechCanDoThisJob && (
          <Box
            title={`${currentJobTech.name} is qualified to perform this repair`}
            sx={{ '& svg': { fontSize: '14px' } }}
          >
            <VerifiedRounded color='success' />
          </Box>
        )}
      </Box>
      <Box>
        <Box>
          {showDrivingDistanceDays > 0 &&
            leadCoordinates?.lat &&
            leadCoordinates?.lng &&
            showDrivingDistanceDays / 14 >= chunkIndex + 1 && (
              <TravelDistanceFromLeadIdToCoordinates fromLeadId={job.leadId} toCoordinates={leadCoordinates} />
            )}
        </Box>
      </Box>
    </Box>
  )
}

const TheMap = ({ technicians, lead, quote, leadCoordinates }) => {
  const [techsInBounds, setTechsInbounds] = useState([])
  const [map, setMap] = useState(null)

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  })

  const handleOnIdle = () => {
    if (map.zoom > MIN_ZOOM_LEVEL) {
      const bounds = map?.getBounds()

      const newInBoundTechs = technicians.filter(
        technician =>
          Number(technician.lat) > bounds.getSouthWest().lat() &&
          Number(technician.lng) > bounds.getSouthWest().lng() &&
          Number(technician.lat) < bounds.getNorthEast().lat() &&
          Number(technician.lng) < bounds.getNorthEast().lng()
      )
      setTechsInbounds(newInBoundTechs)
    } else {
      setTechsInbounds([])
    }
  }

  const onLoad = map => setMap(map)
  const onUnmount = map => setMap(null)

  if (!isLoaded) return <div>Loading google map</div>

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <GoogleMap
            zoom={10}
            mapContainerStyle={{
              height: window.innerHeight / 2.2,
              width: '100%',
            }}
            onIdle={handleOnIdle}
            center={leadCoordinates}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <Marker
              position={leadCoordinates}
              label={`Customer:   ${lead?.emojis?.map((emoji, index) => (
                <em-emoji set='apple' key={index} shortcodes={emoji}></em-emoji>
              ))} ${lead.name} (${lead.repairLocation})`}
              icon='https://driveway-production.s3.us-east-2.amazonaws.com/img/customer_map_marker.png'
            />

            {techsInBounds.map(technician => (
              <Marker
                key={`${technician.id}`}
                label={technician.name}
                position={{ lat: Number(technician.lat), lng: Number(technician.lng) }}
              />
            ))}
          </GoogleMap>
        </Grid>

        <Grid item xs={4}>
          {lead.addressLineOne?.length > 2 || 'Missing Lead address, using center of zip code'}
          {lead.addressLineOne?.length > 2 && (
            <>
              Have customer address, the map marker is accurate
              <br />
              {lead.addressLineOne}
              <br />
              {lead.city}, {lead.state} {lead.zip}
            </>
          )}
        </Grid>
      </Grid>

      <TechniciansInBounds technicians={techsInBounds} leadCoordinates={leadCoordinates} quote={quote} lead={lead} />

      {techsInBounds.length === 0 && <div>zoom/pan the map to show technicians</div>}
      {map?.zoom <= MIN_ZOOM_LEVEL && <b style={{ color: 'red' }}>Too far, zoom in to see technicians</b>}
    </Box>
  )
}

const TechniciansInBoundsStyles = makeStyles(theme => ({
  techSection: {
    border: '1px solid #c9c9c9',
    marginTop: '1em',
    backgroundColor: '#f9f9f9ba',
  },
}))
const TechniciansInBounds = ({ technicians, leadCoordinates, lead }) => {
  const classes = TechniciansInBoundsStyles()
  const [travelDistances, setTravelDistances] = useState([])
  const { pathname } = useLocation()

  const quoteIdIsInUrl = pathname?.includes('quotes')
  const quoteId = quoteIdIsInUrl && pathname.split('/')[4]
  const jobIdIsInUrl = pathname?.includes('jobs')
  const jobId = jobIdIsInUrl && pathname.split('/')[6]

  const [getQuote, { loading, error, data }] = useLazyQuery(QUOTE_FOR_JOB_VALUE)
  const [getJob, { loading: loadingJob, error: errorJob, data: dataJob }] = useLazyQuery(GET_SINGLE_JOB, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    quoteId &&
      getQuote({
        variables: {
          id: quoteId,
        },
      })
  }, [quoteId])

  useEffect(() => {
    jobId &&
      getJob({
        variables: {
          id: jobId,
        },
      })
  }, [jobId])

  const quote = data?.quote
  const job = dataJob?.job

  const receiveTravelDistance = travelDistance => {
    if (travelDistances.find(distance => distance.technicianId === travelDistance.technicianId)) return

    setTravelDistances(prev => [travelDistance, ...prev])
  }

  const prefetchedForTech = technician => travelDistances.find(result => result.technicianId === technician.id)

  return (
    <>
      {technicians
        .sort(
          (a, b) =>
            ((travelDistances.find(e => e.technicianId === a.id) &&
              travelDistances.find(e => e.technicianId === a.id).distanceValue) ||
              0) -
            ((travelDistances.find(e => e.technicianId === b.id) &&
              travelDistances.find(e => e.technicianId === b.id).distanceValue) ||
              0)
        )
        .map(technician => (
          <div className={classes.techSection} key={technician.id}>
            <TechSchedule
              prefetchedTravelDistance={prefetchedForTech(technician)}
              leadCoordinates={leadCoordinates}
              sendMyTravelDistanceUp={receiveTravelDistance}
              key={technician.id}
              lead={lead}
              technician={technician}
              quote={quote}
              currentJob={job}
            />
          </div>
        ))}
    </>
  )
}

const AvailabilityMap = ({ forTechnicianIdOnly, lead }) => {
  const [global] = useContext(GlobalContext)
  const [showModal, setShowModal] = useState(false)
  const [showWeather, setShowWeather] = useState(false)

  const { loading, error, data } = useQuery(TECHNICIANS, {
    variables: {
      filter: { active: true },
    },
  })
  const [leadCoordinates, setLeadCoordinates] = useState({ lat: null, lng: null })

  useEffect(() => {
    const { addressLineOne, city, state, zip } = lead
    showModal && getCoordinates(addressLineOne, city, state, zip)
      .then(coordinates => {
        setLeadCoordinates(coordinates)
      })
      .catch(err => {
        console.error(err)
      })
  }, [showModal])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const maybeFilteredTechnicians =
    (forTechnicianIdOnly && data.technicians.filter(tech => tech.id === forTechnicianIdOnly)) || data.technicians

  const onlyForTech = global.technicians.find(tech => tech.id === forTechnicianIdOnly)

  return (
    <>
      <Stack direction='row' spacing={2}>
        <Button size='small' color='secondary' variant='outlined' onClick={() => setShowModal(!showModal)}>
          map/availability
        </Button>
        <Button size='small' variant='outlined' onClick={() => setShowWeather(!showWeather)}>
          show weather
        </Button>
      </Stack>

      {showModal && (
        <Box>
          {forTechnicianIdOnly && (
            <b style={{ color: 'red', margin: '1em' }}>
              Only one technician per Quote, showing ONLY: {onlyForTech && onlyForTech.name}
            </b>
          )}

          {(!leadCoordinates.lat || !leadCoordinates.lng) && <div>no lead coordinates</div>}

          {leadCoordinates.lat && leadCoordinates.lng && (
            <TheMap technicians={maybeFilteredTechnicians} lead={lead} leadCoordinates={leadCoordinates} />
          )}
        </Box>
      )}

      <WeatherForecast showWeather={showWeather} zip={lead.zip} />
    </>
  )
}

const techScheduleStyles = makeStyles(theme => ({
  modalContent: {
    position: 'absolute',
    width: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  scheduleCell: {
    padding: '5px',
    verticalAlign: 'top',
    width: '120px',
    minWidth: '120px',
    borderLeft: '1px solid lightgray',
    borderRight: '1px solid lightgray',
    '&.sunday': {
      border: '2px solid gray',
      borderTop: 0,
      borderRight: 0,
    },
    '&.saturday': {
      border: '2px solid gray',
      borderTop: 0,
      borderLeft: 0,
    },
  },
  job: {
    marginTop: '3px',
    fontSize: 13,
    backgroundColor: 'aliceblue',
    padding: '0px 3px',
    borderRadius: '2px',
    border: '1px solid lavender',
  },
  tech_header_cell: {
    padding: '8px',
    lineHeight: '1em',
  },
}))
const availabilityRegex = /(\d+)-(\d+)/

export const GET_JOBS = gql`
  query getJobs($technicianId: ID!, $startDatetime: String!, $endDatetime: String!) {
    jobs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      startDatetime
      endDatetime
      leadId
      quoteId
      heldForReschedulingAt
      lead {
        name
        emojis
        customer {
          id
          firstName
          lastName
          noClose
        }
        jobs {
          id
          startDatetime
          endDatetime
          leadId
          quoteId
        }
      }
      quote {
        id
        balanceAmountDue
        preJobAmountDue
        payments
        paymentStatus
        difficultyLevel
        requiredSkillsets
        parts {
          id
          status
          name
          number
          price
          cost
          prePaymentRequired
          etaBusinessDays
          etaLastUpdated
        }
      }
    }
  }
`

export const GET_APPOINTMENTS = gql`
  query getJobs($technicianId: ID!, $startDatetime: String!, $endDatetime: String!) {
    appointments(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      startDatetime
      endDatetime
      leadId
      quoteId
      lead {
        name
      }
    }
  }
`

const BTOB_JOBS_QUERY = gql`
  query BToBJobs($technicianId: String, $startDatetime: String, $endDatetime: String) {
    bToBJobs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      createdAt
      startDatetime
      endDatetime
      updatedAt
      dealer {
        id
        businessName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        primaryContact {
          firstName
          lastName
          email
        }
      }
      technician {
        id
        firstName
        lastName
        addressLineOne
        city
        state
        zip
        lat
        lng
      }
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
`
const PAID_TIME_OFF = gql`
  query PaidTimeOffs($technicianId: ID, $startDatetime: String, $endDatetime: String) {
    paidTimeOffs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      createdAt
      timeOffDate
      totalHours
    }
  }
`

const UNPAID_TIME_OFF = gql`
  query UnpaidTimeOffs($technicianId: ID, $startDatetime: String, $endDatetime: String) {
    unpaidTimeOffs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      createdAt
      timeOffDate
      totalHours
    }
  }
`

const SICK_TIME_OFF = gql`
  query SickTimeOffs($technicianId: ID, $startDatetime: String, $endDatetime: String) {
    sickTimeOffs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      createdAt
      timeOffDate
      totalHours
    }
  }
`

const JOBS_SCHEDULERS_QUERY = gql`
  query BToBJobSchedulers($technicianId: String, $recurrenceStartsAt: String!, $recurrenceEndsAt: String!) {
    bToBJobSchedulers(
      technicianId: $technicianId
      recurrenceStartsAt: $recurrenceStartsAt
      recurrenceEndsAt: $recurrenceEndsAt
    ) {
      id
      createdAt
      startDatetime
      endDatetime
      recurrencePeriod
      recurrencePattern
      recurrenceStartsAt
      recurrenceEndsAt
      excludedDays
      dealer {
        id
        businessName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        primaryContact {
          firstName
          lastName
          email
        }
      }
      technician {
        id
        firstName
        lastName
        addressLineOne
        city
        state
        zip
        lat
        lng
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`

const TechSchedule = ({
  lead,
  quote,
  technician,
  prefetchedTravelDistance,
  sendMyTravelDistanceUp,
  leadCoordinates,
  currentJob,
}) => {
  const classes = techScheduleStyles()
  const [daysToShow, setDaysToShow] = useState(14)
  const [myTravelDistance, setMyTravelDistance] = useState(prefetchedTravelDistance)
  const [showDrivingDistanceDays, setShowDrivingDistanceDays] = useState(0)
  const [jobAnchorEl, setJobAnchorEl] = useState(null)
  const [jobChatCustomer, setJobChatCustomer] = useState(null)

  const quoteHasParts = quote?.parts?.length > 0

  const requiredSkillsets =
    quote?.requiredSkillsets.concat([quote.difficultyLevel]).concat(quoteHasParts ? ['part replacements'] : []) || []

  const thisTechMatchesSkillsets =
    quote && technician.skillsets && checkSubsetArray(technician.skillsets, requiredSkillsets)
  const thisTechIsApproved = quote?.approvedTechnicianIds.includes(technician.id)

  const handleTravelDistanceResult = result => {
    const deepResult = result.rows[0].elements[0]
    setMyTravelDistance({
      technicianId: technician.id,
      distanceValue: deepResult.distance.value,
      distanceString: deepResult.distance.text,
      durationString: deepResult.duration.text,
    })
  }

  useEffect(() => {
    if (prefetchedTravelDistance) return
    if (!myTravelDistance) return
    sendMyTravelDistanceUp(myTravelDistance)
  }, [myTravelDistance])

  const beginningOfUtcDay = DateTime.local().setZone('utc').startOf('day')
  const { loading, error, data, refetch } = useQuery(GET_JOBS, {
    variables: {
      technicianId: technician.id,
      startDatetime: beginningOfUtcDay.toISO(),
      endDatetime: beginningOfUtcDay.plus({ days: daysToShow }).endOf('day').toISO(),
    },
  })

  const {
    loading: loadingAppts,
    error: errorAppts,
    data: apptsData,
    refetch: refetchAppts,
  } = useQuery(GET_APPOINTMENTS, {
    variables: {
      technicianId: technician.id,
      startDatetime: beginningOfUtcDay.toISO(),
      endDatetime: beginningOfUtcDay.plus({ days: daysToShow }).endOf('day').toISO(),
    },
  })

  const {
    error: b2bJobsError,
    loading: loadingB2BJobs,
    data: b2bJobsData,
  } = useQuery(BTOB_JOBS_QUERY, {
    variables: {
      technicianId: technician.id,
      startDatetime: beginningOfUtcDay.toISO(),
      endDatetime: beginningOfUtcDay.plus({ days: daysToShow }).endOf('day').toISO(),
    },
  })

  const {
    error: b2bJobSchedulersError,
    loading: loadingB2BJobSchedulers,
    data: b2bJobSchedulersData,
  } = useQuery(JOBS_SCHEDULERS_QUERY, {
    variables: {
      technicianId: technician.id,
      recurrenceEndsAt: DateTime.now().endOf('day'),
      recurrenceStartsAt: DateTime.now().endOf('month').endOf('week').endOf('day'),
    },
  })

  const {
    error: ptoError,
    loading: ptoLoading,
    data: ptoData,
  } = useQuery(PAID_TIME_OFF, {
    variables: {
      technicianId: technician.id,
      startDatetime: beginningOfUtcDay.minus({ minutes: 1 }),
      endDatetime: beginningOfUtcDay.plus({ days: daysToShow }).endOf('day').toISO(),
    },
  })

  const {
    error: utoError,
    loading: utoLoading,
    data: utoData,
  } = useQuery(UNPAID_TIME_OFF, {
    variables: {
      technicianId: technician.id,
      startDatetime: beginningOfUtcDay.minus({ minutes: 1 }),
      endDatetime: beginningOfUtcDay.plus({ days: daysToShow }).endOf('day').toISO(),
    },
  })

  const {
    error: stoError,
    loading: stoLoading,
    data: stoData,
  } = useQuery(SICK_TIME_OFF, {
    variables: {
      technicianId: technician.id,
      startDatetime: beginningOfUtcDay.minus({ minutes: 1 }),
      endDatetime: beginningOfUtcDay.plus({ days: daysToShow }).endOf('day').toISO(),
    },
  })

  useEffect(() => {
    data && refetch && refetch()
  }, [])

  if (loading || loadingAppts || loadingB2BJobs || loadingB2BJobSchedulers || stoLoading || utoLoading || ptoLoading)
    return <div>LOADING...</div>
  if (error || errorAppts || b2bJobSchedulersError || b2bJobsError || stoError || ptoError || utoError)
    return <div>Error!</div>

  const jobs = data?.jobs || []
  const bToBJobs = b2bJobsData?.bToBJobs || []
  const bToBJobSchedulers = b2bJobSchedulersData?.bToBJobSchedulers || []

  const pto = ptoData?.paidTimeOffs
  const uto = utoData?.unpaidTimeOffs
  const sto = stoData?.sickTimeOffs

  const getSchedulersForDay = date => {
    const weeklySchedulersForDay = bToBJobSchedulers.filter(
      scheduler =>
        scheduler.recurrencePeriod === 'week' &&
        scheduler.recurrencePattern.includes(date.toFormat('cccc').toLowerCase()) &&
        DateTime.fromISO(scheduler.recurrenceStartsAt) <= date.endOf('day') &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !jobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(scheduler.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        )
    )

    const monthlySchedulersForDay = bToBJobSchedulers.filter(
      scheduler =>
        scheduler.recurrencePeriod === 'month' &&
        scheduler.recurrencePattern.includes(date.toFormat('dd')) &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceStartsAt && DateTime.fromISO(scheduler.recurrenceStartsAt) >= date.endOf('month')
          ? false
          : true) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !jobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(scheduler.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        )
    )

    const yearlySchedulersForDay = bToBJobSchedulers.filter(
      scheduler =>
        scheduler.recurrencePeriod === 'year' &&
        scheduler.recurrencePattern.includes(date.toFormat('MM-dd')) &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceStartsAt && DateTime.fromISO(scheduler.recurrenceStartsAt) >= date.endOf('month')
          ? false
          : true) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !jobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(scheduler.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        )
    )

    return weeklySchedulersForDay.concat(monthlySchedulersForDay).concat(yearlySchedulersForDay)
  }

  const populatedSchedule = [...Array(daysToShow).keys()].map(i => {
    const thisDay = DateTime.local()
      .setZone('utc')
      .startOf('day')
      .plus({ days: i - 1 })
    const dateString = thisDay.toISODate()

    const jobsOnThisDay = data.jobs
      .filter(job => DateTime.fromISO(job.startDatetime, { zone: 'utc' }).hasSame(thisDay, 'day'))
      .map(job => ({ ...job, type: 'job' }))

    const apptsOnThisDay =
      apptsData?.appointments
        .filter(job => DateTime.fromISO(job.startDatetime, { zone: 'utc' }).hasSame(thisDay, 'day'))
        .map(appt => ({ ...appt, type: 'appointment' })) || []

    const bToBJobsOnThisDay =
      bToBJobs
        .filter(job => DateTime.fromISO(job.startDatetime, { zone: 'utc' }).hasSame(thisDay, 'day'))
        .map(appt => ({ ...appt, type: 'bToBJob' })) || []

    const bToBJobSchedulerssOnThisDay = getSchedulersForDay(thisDay)

    const ptoOnThisDate = pto.filter(job =>
      DateTime.fromISO(job.timeOffDate, { setZone: true }).hasSame(thisDay, 'day')
    )
    const utoOnThisDate = uto.filter(job =>
      DateTime.fromISO(job.timeOffDate, { setZone: true }).hasSame(thisDay, 'day')
    )
    const stoOnThisDate = sto.filter(job =>
      DateTime.fromISO(job.timeOffDate, { setZone: true }).hasSame(thisDay, 'day')
    )

    return {
      dateString: dateString,
      jobs: jobsOnThisDay.concat(apptsOnThisDay).sort((a, b) => (a.startDatetime > b.startDatetime ? 1 : -1)),
      bToBJobs: bToBJobsOnThisDay,
      bToBJobSchedulers: bToBJobSchedulerssOnThisDay,
      pto: ptoOnThisDate,
      uto: utoOnThisDate,
      sto: stoOnThisDate,
    }
  })

  const formattedStringForAvailRange = string => {
    const match = string.match(/(\d+)-(\d+)/)
    const startHour = DateTime.fromObject({ hour: match[1] }).toFormat('ha')
    const endHour = DateTime.fromObject({ hour: match[2] }).toFormat('ha')
    return `${startHour}- ${endHour}`
  }

  const handleClickShowTwoMoreWeeks = () => {
    setDaysToShow(daysToShow + 14)
  }

  const chunk = (arr, size) =>
    arr.reduce((acc, _, i) => {
      if (i % size === 0) acc.push(arr.slice(i, i + size))
      return acc
    }, [])

  const chunkedPopulatedSchedule = chunk(populatedSchedule, 14)

  const requestedOff = dateString => technician.offdays.includes(dateString)

  const offDay = dateString =>
    technician.availability[DateTime.fromISO(dateString).toFormat('EEEE').toLowerCase()].length === 0

  return (
    <>
      {!myTravelDistance && (
        <DistanceMatrixService
          options={{
            unitSystem: window.google.maps.UnitSystem.IMPERIAL,
            travelMode: 'DRIVING',
            destinations: [leadCoordinates],
            origins: [{ lat: Number(technician.lat), lng: Number(technician.lng) }],
          }}
          callback={handleTravelDistanceResult}
        />
      )}

      <Popover
        disablePortal={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ sx: { width: '400px', height: '700px' } }}
        onClose={() => setJobAnchorEl(null)}
        anchorEl={jobAnchorEl}
        open={!!jobAnchorEl}
      >
        <CustomerSmsChat customer={jobChatCustomer} contextType='Customer' contextId={jobChatCustomer?.id} />
      </Popover>

      <Box sx={{ pl: 1 }}>
        <Grid container sx={{ p: 1 }}>
          <Grid item xs={7}>
            <Link to={`/technicians/${technician.id}`}>
              <b>{technician.name}</b>
            </Link>
            &nbsp; (Travel {`Distance: ${myTravelDistance?.distanceString}, Time: ${myTravelDistance?.durationString}`})
            &nbsp;
            <Button size='small' variant='outlined' onClick={handleClickShowTwoMoreWeeks}>
              + 2 weeks
            </Button>
            &nbsp;
            <Button
              size='small'
              variant='outlined'
              onClick={() => setShowDrivingDistanceDays(showDrivingDistanceDays + 14)}
            >
              driving distances ({showDrivingDistanceDays + 14} days)
            </Button>
          </Grid>

          <Grid item xs={5} sx={{ pt: 1, fontSize: '13px' }}>
            {Object.entries(technician.availability).map(
              (el, i) =>
                el[1].length > 0 &&
                el[1][0] !== '' && (
                  <>
                    <b>{el[0]}:</b>{' '}
                    {el[1].map((range, i) => (
                      <>{formattedStringForAvailRange(range)},&nbsp;</>
                    ))}
                  </>
                )
            )}
          </Grid>
        </Grid>
      </Box>

      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell colSpan={14}>
                {technician.note && technician.note.length > 0 && (
                  <>
                    Notes: <span style={{ color: '#3f51b5', fontWeight: 'bold' }}>{technician.note}</span>
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={14}>
                {thisTechIsApproved && (
                  <Chip
                    color='success'
                    variant='outlined'
                    size='small'
                    icon={<CheckCircleRounded />}
                    label={<>Approved for this quote </>}
                  />
                )}
                {thisTechMatchesSkillsets && (
                  <Chip
                    sx={{ ml: '.5rem' }}
                    color='success'
                    variant='outlined'
                    size='small'
                    icon={<CheckCircleRounded />}
                    label={<>Qualified for this quote </>}
                  />
                )}
                {!thisTechMatchesSkillsets && quote && (
                  <Chip
                    sx={{ ml: '.5rem' }}
                    color='error'
                    variant='outlined'
                    size='small'
                    icon={<ErrorRounded />}
                    label={<>Not qualified for this quote </>}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {chunkedPopulatedSchedule.map((chunk, chunkIndex) => (
              <TableRow key={chunkIndex}>
                {chunk.map((scheduleDay, scheduleDayIndex) => (
                  <TableCell
                    key={scheduleDay.dateString}
                    className={
                      classes.scheduleCell +
                      ' ' +
                      DateTime.fromISO(scheduleDay.dateString, { zone: 'utc' }).toFormat('cccc').toLowerCase()
                    }
                  >
                    <Box style={{ borderBottom: '1px solid rgb(203 203 203)' }}>
                      {DateTime.fromISO(scheduleDay.dateString, { zone: 'utc' }).toFormat('ccc, LLL d')}
                    </Box>

                    {scheduleDay.jobs.length > 0 &&
                      scheduleDay.jobs
                        .filter(job => !job.heldForReschedulingAt)
                        .map(job => (
                          <JobCell
                            chunkIndex={chunkIndex}
                            leadCoordinates={leadCoordinates}
                            showDrivingDistanceDays={showDrivingDistanceDays}
                            scheduleDay={scheduleDay}
                            key={job.id}
                            job={job}
                            setJobAnchorEl={setJobAnchorEl}
                            setJobChatCustomer={setJobChatCustomer}
                            currentJob={currentJob}
                          />
                        ))}

                    {scheduleDay.bToBJobs.length > 0 &&
                      scheduleDay.bToBJobs
                        .slice()
                        .sort((a, b) =>
                          DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1
                        )
                        .map(job => (
                          <React.Fragment key={job.id}>
                            <BToBJobCellWithDrivingDistance
                              jobIsInPast={scheduleDay.dateTime < DateTime.now().startOf('day').minus({ days: 1 })}
                              key={job.id}
                              job={job}
                              chunkIndex={chunkIndex}
                              showDrivingDistanceDays={showDrivingDistanceDays}
                              leadCoordinates={leadCoordinates}
                            />
                          </React.Fragment>
                        ))}

                    {scheduleDay.bToBJobSchedulers.length > 0 &&
                      scheduleDay.bToBJobSchedulers
                        .slice()
                        .sort((a, b) =>
                          DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1
                        )
                        .map(job => (
                          <React.Fragment key={job.id}>
                            <BToBJobCellWithDrivingDistance
                              jobIsInPast={scheduleDay.dateTime < DateTime.now().startOf('day').minus({ days: 1 })}
                              key={job.id}
                              job={job}
                              chunkIndex={chunkIndex}
                              showDrivingDistanceDays={showDrivingDistanceDays}
                              leadCoordinates={leadCoordinates}
                            />
                          </React.Fragment>
                        ))}

                    <br />

                    {scheduleDay?.pto?.map(pto => (
                      <span
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          border: '2px solid gray',
                          color: 'gray',
                          fontWeight: 'bold',
                        }}
                      >
                        PAID TIME OFF - {pto.totalHours} hour(s)
                      </span>
                    ))}

                    {scheduleDay?.uto?.map(pto => (
                      <span
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          border: '2px solid gray',
                          color: 'gray',
                          fontWeight: 'bold',
                        }}
                      >
                        UNPAID TIME OFF - {pto.totalHours} hour(s)
                      </span>
                    ))}

                    {scheduleDay?.sto?.map(pto => (
                      <span
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          border: '2px solid gray',
                          color: 'gray',
                          fontWeight: 'bold',
                        }}
                      >
                        SICK TIME OFF - {pto.totalHours} hour(s)
                      </span>
                    ))}

                    {requestedOff(scheduleDay.dateString) && (
                      <span
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          border: '2px solid gray',
                          color: 'gray',
                          fontWeight: 'bold',
                        }}
                      >
                        REQUESTED OFF
                      </span>
                    )}

                    {offDay(scheduleDay.dateString) && (
                      <span
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          border: '2px solid gray',
                          color: 'gray',
                          fontWeight: 'bold',
                        }}
                      >
                        OFF DAY
                      </span>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const LEAD_FOR_TRAVEL_DISTANCE_FROM = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      addressLineOne
      city
      state
      zip
    }
  }
`
export const TravelDistanceFromLeadIdToCoordinates = ({ fromLeadId, toCoordinates, text = 'From above job' }) => {
  const [distanceResult, setDistanceResult] = useState(null)
  const [fromLeadCoordinates, setFromLeadCoordinates] = useState(null)

  const handleTravelDistanceResult = result => {
    const deepResult = result.rows[0].elements[0]
    setDistanceResult({
      distanceValue: deepResult.distance.value,
      distanceString: deepResult.distance.text,
      durationString: deepResult.duration.text,
    })
  }

  const { loading, error, data } = useQuery(LEAD_FOR_TRAVEL_DISTANCE_FROM, {
    variables: { id: fromLeadId },
  })

  useEffect(() => {
    if (!data?.lead) return

    const lead = keysToCamel(data.lead)
    const { addressLineOne, city, state, zip } = lead
    getCoordinates(addressLineOne, city, state, zip).then(coordinates => {
      setFromLeadCoordinates(coordinates)
    })
  }, [data])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return (
    <>
      {fromLeadCoordinates && (
        <>
          {!distanceResult && (
            <DistanceMatrixService
              options={{
                unitSystem: window.google.maps.UnitSystem.IMPERIAL,
                travelMode: 'DRIVING',
                destinations: [toCoordinates],
                origins: [{ lat: Number(fromLeadCoordinates.lat), lng: Number(fromLeadCoordinates.lng) }],
              }}
              callback={handleTravelDistanceResult}
            />
          )}

          {distanceResult && (
            <Box
              style={{
                border: '2px solid #aeaeae',
                backgroundColor: '#e4e4e4',
                borderRadius: '3px',
                padding: '3px',
                fontSize: '12.5px',
              }}
            >
              {text}
              <br />
              Distance: <b>{distanceResult.distanceString}</b>
              <br />
              Time: <b>{distanceResult.durationString}</b>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export const TravelDistanceFromLeadToDealer = ({ leadCoordinates, dealerCoordinates, text = 'From above job' }) => {
  const [distanceResult, setDistanceResult] = useState(null)

  const handleTravelDistanceResult = result => {
    const deepResult = result.rows[0].elements[0]
    setDistanceResult({
      distanceValue: deepResult.distance.value,
      distanceString: deepResult.distance.text,
      durationString: deepResult.duration.text,
    })
  }

  return (
    <>
      {leadCoordinates && (
        <>
          {!distanceResult && (
            <DistanceMatrixService
              options={{
                unitSystem: window.google.maps.UnitSystem.IMPERIAL,
                travelMode: 'DRIVING',
                destinations: [dealerCoordinates],
                origins: [{ lat: Number(leadCoordinates.lat), lng: Number(leadCoordinates.lng) }],
              }}
              callback={handleTravelDistanceResult}
            />
          )}

          {distanceResult && (
            <Box
              style={{
                border: '2px solid #aeaeae',
                backgroundColor: '#e4e4e4',
                borderRadius: '3px',
                padding: '3px',
                fontSize: '12.5px',
              }}
            >
              {text}
              <br />
              Distance: <b>{distanceResult.distanceString}</b>
              <br />
              Time: <b>{distanceResult.durationString}</b>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default AvailabilityMap
