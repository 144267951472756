import { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { UserContext } from 'UserStore'
import { Button, Skeleton } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { PriceCategoryDialog } from './PriceCategoryDialog'
import { LaunchRounded } from '@mui/icons-material'
import { PanelGroupDialog } from './PanelGroupDialog'

const DEALERS_QUERY = gql`
  query BToBPriceCategories {
    bToBPriceCategories {
      id
      createdAt
      name
      isCustom
      pricePoints {
        id
        price
        commissionAmount
        pointAmount
        panelGroup {
          id
          name
        }
      }
      categoriesWithoutPricePoints {
        id
        name
      }
    }
  }
`

const LINE_ITEM_CATEGORIES = gql`
  query bToBPanelGroups {
    bToBPanelGroups {
      id
      name
      isCustom
    }
  }
`

export const PriceCategoriesTable = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [open, setOpen] = useState(false)
  const [selectedPriceCategory, setSelectedPriceCategory] = useState<any>({})
  const [panelGroupDialogOpen, setPanelGroupDialogOpen] = useState(false)
  const [selectedPanelGroup, setSeletedPanelGroup] = useState(false)

  const { loading, error, data, refetch } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: dataCategories,
    refetch: refetchCategories,
  } = useQuery(LINE_ITEM_CATEGORIES, {
    notifyOnNetworkStatusChange: true,
  })

  const pricingCategories = data?.bToBPriceCategories || []
  const rows = dataCategories?.bToBPanelGroups || []

  useEffect(() => {
    JSON.stringify(selectedPriceCategory) !== JSON.stringify({}) &&
      setSelectedPriceCategory(pricingCategories.find((item: any) => item.id === selectedPriceCategory.id))
  }, [pricingCategories])

  if (error) return <div>Error!</div>

  const handleCreateNewCategory = () => {
    let categoryName = window.prompt('Enter a name for the pricing category')

    if (categoryName) {
      categoryName = categoryName.substring(0, 25)

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_price_category`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          created_by_user_id: user.id,
          name: categoryName,
        }),
      }).then(() => {
        refetch()
        refetchCategories()
      })
    }
  }

  const handleCreateNewItemCategory = () => {
    let categoryName = window.prompt('Enter a name for the new panel group')

    if (categoryName) {
      categoryName = categoryName.substring(0, 25)
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_panel_group`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          created_by_user_id: user.id,
          name: categoryName,
        }),
      }).then(() => {
        refetch()
        refetchCategories()
      })
    }
  }

  const handleRefetch = () => {
    refetch()
    refetchCategories()
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow sx={{ background: 'aliceblue' }}>
              <TableCell>Panel Group</TableCell>
              {/* <TableCell>Category Name</TableCell> */}
              {/* <TableCell>Type</TableCell> */}
              {pricingCategories
                .slice()
                .sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1))
                .map((lineItem: any) => (
                  <TableCell>
                    {lineItem.name}{' '}
                    {user.roles.includes('admin') && (
                      <button
                        onClick={() => {
                          setOpen(true)
                          setSelectedPriceCategory(lineItem)
                        }}
                      >
                        edit
                      </button>
                    )}
                  </TableCell>
                ))}
              <TableCell sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  disableElevation
                  disableRipple
                  sx={{
                    minHeight: '24px',
                    padding: '.125rem',
                    minWidth: '24px',
                    borderRadius: '100px',
                    lineHeight: 1.5,
                  }}
                  variant='contained'
                  onClick={handleCreateNewCategory}
                  size='small'
                  disabled={!user.roles.includes('admin')}
                >
                  +
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* @ts-ignore */}
            {loading && <TableLoadingSkeleton />}
            {rows
              .slice()
              .sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1))
              .map((panelGroup: any) => {
                return (
                  <TableRow>
                    <TableCell
                      onClick={() => {
                        setPanelGroupDialogOpen(true)
                        setSeletedPanelGroup(panelGroup)
                      }}
                      sx={{
                        userSelect: 'none',
                        cursor: 'pointer',
                        borderRight: '1px solid #ddd',
                        '& svg': { fontSize: '.9rem', ml: '.25rem' },
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {panelGroup.name}

                      <LaunchRounded />
                    </TableCell>
                    {pricingCategories
                      .slice()
                      .sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1))
                      .map((thing: any) =>
                        thing.pricePoints.find((pricePoint: any) => pricePoint.panelGroup.id === panelGroup.id)
                      )
                      .map((pricePoint: any) => (
                        <TableCell
                          key={pricePoint?.id}
                          sx={{ background: !pricePoint ? '#eee' : '#fff', borderRight: '1px solid #ddd' }}
                        >
                          {(pricePoint?.price && <span>${pricePoint?.price}</span>) || ''}{' '}
                          {(pricePoint?.commissionAmount && (
                            <span style={{ color: '#777' }}>(${pricePoint?.commissionAmount})</span>
                          )) ||
                            ''}
                        </TableCell>
                      ))}
                    <TableCell />
                  </TableRow>
                )
              })}
            <TableRow>
              <TableCell sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  disableElevation
                  disableRipple
                  sx={{
                    minHeight: '24px',
                    padding: '.125rem',
                    minWidth: '24px',
                    borderRadius: '100px',
                    lineHeight: 1.5,
                  }}
                  variant='contained'
                  onClick={handleCreateNewItemCategory}
                  size='small'
                  disabled={!user.roles.includes('admin')}
                >
                  +
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {panelGroupDialogOpen && (
        <PanelGroupDialog
          panelGroup={selectedPanelGroup}
          open={panelGroupDialogOpen}
          setOpen={setPanelGroupDialogOpen}
          refetch={() => handleRefetch()}
        />
      )}

      {open && (
        <PriceCategoryDialog
          open={open}
          setOpen={setOpen}
          refetch={() => handleRefetch()}
          lineItem={selectedPriceCategory}
        />
      )}
    </>
  )
}

const TableLoadingSkeleton = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => (
    <TableRow key={item}>
      {[1, 2, 3].map(inner => (
        <TableCell sx={{ padding: '.25rem' }} key={inner}>
          <Skeleton
            sx={{
              minWidth: '100%',
              minHeight: '28px',
              transform: 'scale(1)',
              background: item % 2 !== 0 ? '#0089CD1a' : '#f1f1f1',
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  ))
}
