import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { keysToCamel } from 'tools'
import Show from './Show'
// import QuotesRouter from './Quotes/Router'

const QUERY = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      id name email phone addressLineOne addressLineTwo city state zip
      make model year description photos createdAt interactionStatuses
      repairLocation zip source market makeShortName modelShortName
      noWay carparts soldAt soldByUserId isATest garageConfirmed
      customerDeclinedAt customerDeclinedReason
    }
  }
`

const Router = ({ customer }) => {
  const { path } = useRouteMatch()
  const { leadId } = useParams()

  const { loading, error, data } = useQuery(QUERY, {
    variables: { id: leadId }
  })

  if (loading || error) return <div>Loading...</div>

  const lead = keysToCamel(data.lead)

  return (
    <Switch>
      {/* <Route path={`${path}/quotes`}> */}
      {/*   <QuotesRouter customer={customer} /> */}
      {/* </Route> */}
      <Route path={path}>
        <Show customer={customer} lead={lead} />
      </Route>
    </Switch>
  )
}

export default Router
