import { useState } from 'react'
import { TechnicianRows } from 'Schedule/components/TechnicianRows'
import { DateTime } from 'luxon'

const Schedule = ({ technician }) => {
  const [startDatetime] = useState(DateTime.local().setZone('utc').startOf('day'))

  return (
    <>
      <TechnicianRows technician={technician} startDatetime={startDatetime} />
    </>
  )
}

export default Schedule
