import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import PartVendorRouter from './PartVendor/Router'
import Index from './Index'

const Router = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:partVendorId`}>
        <PartVendorRouter />
      </Route>
      <Route path={`${path}/`}>
        <Index />
      </Route>
    </Switch>
  )
}

export default Router
