import { useContext, useState } from 'react'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const initialValues = {
  technician: '',
  issueName: '',
  issueType: '',
  jobLink: '',
  priority: '',
  description: '',
  helpNeeded: false,
}

const validationSchema = Yup.object().shape({
  technician: Yup.string().required('This field is required.'),
  issueName: Yup.string().required('This field is required.'),
  issueType: Yup.string().required('This field is required.'),
  jobLink: Yup.string().nullable(),
  priority: Yup.string().required('This field is required.'),
  description: Yup.string().required('This field is required.'),
})

const issueTypes = [
  'Attendance',
  'Behavior',
  'Vehicle',
  'Tools & Supplies',
  'Job & Customer',
  'Parts',
  'Weather',
  'Other',
  'Quote Complaint'
].map((type, index) => ({
  id: index,
  name: type,
  value: type,
}))

const issuePriorities = ['Low', 'Medium', 'High'].map((priority, index) => ({
  id: index,
  name: priority,
  value: priority,
}))

const TechnicianIssueDialog = ({ showDialog, setShowDialog }) => {
  const [global] = useContext(GlobalContext)
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const technicians = global.technicians.filter(technician => technician.active)
  const currentUser = global.users.find(u => u.id === user.id)

  const [disabled, setDisabled] = useState(false)

  const handleReset = () => {
    formik.resetForm({
      values: initialValues,
    })

    setShowDialog(false)
  }

  const handleSubmit = ({ technician, issueName, issueType, jobLink, priority, description, helpNeeded }) => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/submit_issue`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        submitter: currentUser?.firstName ? `${currentUser.firstName} ${currentUser.lastName}` : '',
        technicianId: technician,
        issueName,
        issueType,
        description,
        jobLink,
        priority,
        helpNeeded,
      }),
    })
      .then(res => {
        if (!res.ok) throw new Error('Error creating Notion Page')
      })
      .catch(err => {
        console.error(err)
        window.alert('Error')
      })
      .finally(() => {
        handleReset()
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })

  if (!technicians.length) return <div>LOADING...</div>

  return (
    <Dialog open={showDialog} onClose={() => setShowDialog(false)} PaperProps={{ sx: { minWidth: '600px' } }}>
      <Box>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              padding: '1rem',
              mb: '.5rem',
              background: 'aliceblue',
              borderBottom: '2px solid #a1cff7',
              fontWeight: 600,
            }}
          >
            Create new technician issue
          </Grid>

          <Grid item xs={12} sx={{ paddingY: '.5rem', paddingX: '1rem' }}>
            <FormControl fullWidth>
              <TextField
                size='small'
                name='issueName'
                label='Enter an Issue Name'
                value={formik.values.issueName}
                onChange={formik.handleChange}
                helperText='*Required'
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} sx={{ padding: '.5rem', paddingLeft: '1rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Select Technician</InputLabel>
              <Select
                name='technician'
                label='Select Technician'
                value={formik.values.technician}
                onChange={formik.handleChange}
              >
                {technicians.map(technician => (
                  <MenuItem key={technician.id} value={technician.id}>
                    {technician.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>*Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6} sx={{ padding: '.5rem', paddingRight: '1rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Select Issue Type</InputLabel>
              <Select
                name='issueType'
                label='Select Issue Type'
                value={formik.values.issueType}
                onChange={formik.handleChange}
                helperText='required'
              >
                {issueTypes.map(type => (
                  <MenuItem key={type.id} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>*Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6} sx={{ padding: '.5rem', paddingLeft: '1rem' }}>
            <TextField
              fullWidth
              name='jobLink'
              label='Paste link to job here'
              size='small'
              type='text'
              value={formik.values.jobLink}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={6} sx={{ padding: '.5rem', paddingRight: '1rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Select Priority</InputLabel>
              <Select
                name='priority'
                label='Select Priority'
                value={formik.values.priority}
                onChange={formik.handleChange}
              >
                {issuePriorities.map(type => (
                  <MenuItem key={type.id} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>*Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ paddingTop: '.5rem', paddingX: '1rem' }}>
            <FormControl fullWidth size='small'>
              <TextField
                multiline
                name='description'
                label='Enter a description'
                minRows={5}
                value={formik.values.description}
                onChange={formik.handleChange}
              />
              <FormHelperText>*Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ paddingBottom: '.5rem', paddingX: '1rem' }}>
            <FormControlLabel
              label='I need help to solve this'
              control={<Checkbox name='helpNeeded' value={formik.values.helpNeeded} onChange={formik.handleChange} />}
            />
          </Grid>
        </Grid>

        <Stack direction='row' justifyContent='space-between' sx={{ padding: '1rem', pt: '0.5rem' }}>
          <Button variant='outlined' size='small' onClick={handleReset} sx={{ minWidth: '30%' }}>
            Cancel
          </Button>
          <Button
            disabled={disabled}
            variant='contained'
            size='small'
            onClick={() => {
              setDisabled(true)
              formik.handleSubmit()
            }}
            sx={{ minWidth: '30%' }}
          >
            Submit
          </Button>
        </Stack>
      </Box>
    </Dialog>
  )
}

export default TechnicianIssueDialog
