import { useContext, useEffect, useState, createContext } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { keysToCamel } from './tools'
import localForage from 'localforage'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { useLocation, useRouteMatch } from 'react-router-dom'

export const ApplicantContext = createContext()

const APPLICANT_BY_ID = gql`
  query applicant($id: ID!) {
    applicant(id: $id) {
      id
      fullName
      phone
    }
  }
`

const APPLICANT_BY_APPLICATION_ID = gql`
  query applicant($jobApplicationId: ID!) {
    applicant(jobApplicationId: $jobApplicationId) {
      id
      fullName
      phone
    }
  }
`

const APPLICANT_BY_INTERVIEW_ID = gql`
  query applicant($interviewId: ID!) {
    applicant(interviewId: $interviewId) {
      id
      fullName
      phone
    }
  }
`

const RecruiterApplicantContextProvider = ({ children }) => {
  const location = useLocation()
  const inteviewMatch = useRouteMatch('/applications/interviews/:interviewId')
  const jobListingMatch = useRouteMatch('/recruiting/job-listings/:jobListingId/')
  const jobApplicationMatch = useRouteMatch('/applications/:applicationId/')
  const applicantMatch = useRouteMatch('/applicants/:applicantId')
  const [currentApplicant, setCurrentApplicant] = useState(null)
  const [currentApplicantContext, setCurrentApplicantContext] = useState(null)
  // this is an obvious place to store "customer history"
  // every time setCurrentApplicantContext is set
  // push the previous value into a list
  // const [history, setHistory] = useLocalForage('customerContextHistory', [])
  const [history, setHistory] = useState([])
  const [pinnedApplicants, setPinnedApplicants] = useState(null)

  const [fetchApplicantByApplicantId] = useLazyQuery(APPLICANT_BY_ID, {
    fetchPolicy: 'network-only', // needed as a cache bust
    variables: { id: currentApplicantContext?.applicantId },
    onCompleted: data => setCurrentApplicant(keysToCamel(data.applicant)),
  })

  const [fetchApplicantByApplicationId] = useLazyQuery(APPLICANT_BY_APPLICATION_ID, {
    fetchPolicy: 'network-only', // needed as a cache bust
    variables: { jobApplicationId: currentApplicantContext?.applicationId },
    onCompleted: data => setCurrentApplicant(keysToCamel(data.applicant)),
  })

  const [fetchApplicantByInterviewId] = useLazyQuery(APPLICANT_BY_INTERVIEW_ID, {
    fetchPolicy: 'network-only', // needed as a cache bust
    variables: { interviewId: currentApplicantContext?.id },
    onCompleted: data => setCurrentApplicant(keysToCamel(data.applicant)),
  })

  useEffect(() => {
    if (inteviewMatch) {
      setCurrentApplicantContext({
        type: 'Interview',
        id: inteviewMatch.params.interviewId,
        path: location.pathname,
        ...inteviewMatch.params,
      })
    } else if (jobListingMatch) {
      setCurrentApplicantContext({
        type: 'JobListing',
        id: jobListingMatch.params.quoteId,
        path: location.pathname,
        ...jobListingMatch.params,
      })
    } else if (jobApplicationMatch) {
      setCurrentApplicantContext({
        type: 'JobApplication',
        id: jobApplicationMatch.params.applicationId,
        path: location.pathname,
        ...jobApplicationMatch.params,
      })
    } else if (applicantMatch) {
      setCurrentApplicantContext({
        type: 'Applicant',
        id: applicantMatch.params.customerId,
        path: location.pathname,
        ...applicantMatch.params,
      })
    } else {
      setCurrentApplicantContext(null)
    }
  }, [location])

  useEffect(() => {
    if (currentApplicantContext === null) {
      setCurrentApplicant(null)
      return null
    }

    currentApplicantContext?.interviewId && fetchApplicantByInterviewId()
    currentApplicantContext?.applicationId && fetchApplicantByApplicationId()
    currentApplicantContext?.customerId && fetchApplicantByApplicantId()
  }, [currentApplicantContext])

  useEffect(() => {
    if (!currentApplicant) return
    if (
      history.length > 0 &&
      history[0].customer.id === currentApplicant.id &&
      history[0].context.Id === currentApplicantContext.Id
    )
      return

    // else push it onto the history list
    setHistory(_history => [
      {
        customer: currentApplicant,
        context: currentApplicantContext,
        timestamp: Date.now(),
      },
      ..._history.slice(0, 200),
    ])
  }, [currentApplicant])

  const pinApplicant = customer => setPinnedApplicants(_customers => [pinnedApplicants, ..._customers])
  const unpinApplicant = customerId =>
    setPinnedApplicants(_customers => _customers.filter(_customer => _customer.id !== customerId))

  return (
    <ApplicantContext.Provider
      value={{
        currentApplicant,
        currentApplicantContext,
        history,
        pinApplicant,
        unpinApplicant,
      }}
    >
      {children}
    </ApplicantContext.Provider>
  )
}

export default RecruiterApplicantContextProvider
