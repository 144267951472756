import { Box, Tabs, Tab, Typography, Button, Dialog, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { AllInvoices } from './AllInvoices'
import { UnAccepted } from './PendingReview'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { InProgress } from './InProgress'
import { WorkOrders } from '../WorkOrders/WorkOrders'
import { useHistory } from 'react-router-dom'
import { DEALERS_QUERY } from 'BToB/Invoices/tools'
import { useContext, useState } from 'react'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { useQuery } from '@apollo/client'

const Index = () => {
  const [user] = useContext(UserContext)
  const [tab, setTab] = useLocalStorage('b2bPageTab', 'home')
  const [showCreatingSpinner, setShowCreatingSpinner] = useState(false)
  const [selectDealerOpen, setSelectDealerOpen] = useState(false)
  const [selectedDealerId, setSelectedDealerId] = useState('')

  const bearerTokenHeaders = useBearerTokenHeaders()

  const history = useHistory()

  const createStatement = () => {
    setShowCreatingSpinner(true)
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_invoice_statement`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        b_to_b_dealer_id: selectedDealerId,
      }),
    }).then(res => res.json().then(res => history.push(`/b-to-b/statements/${res.bToBInvoiceStatement.id}`)))
  }

  const { data } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  const bToBDealers = data?.bToBDealers || []

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ padding: '0rem 1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='h4' sx={{ mt: '1rem', mb: '1rem' }}>
          B2B Invoice Statements
        </Typography>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)}>
          <Tab value='unaccepted' label='Pending Review' />
          <Tab value='all' label='all' />
          <Tab value='in-progress' label='in-progress' />
          <Button
            onClick={() => setSelectDealerOpen(true)}
            sx={{ ml: 'auto', mr: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            Create New
          </Button>
          <Button
            onClick={() => history.push('/b-to-b/payments')}
            sx={{ ml: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            Payments
          </Button>
        </Tabs>
      </Box>
      {tab === 'in-progress' && <InProgress />}
      {tab === 'unaccepted' && <UnAccepted />}
      {tab === 'workOrders' && <WorkOrders />}
      {tab === 'all' && <AllInvoices />}

      <Dialog
        onClose={() => setSelectDealerOpen(false)}
        open={selectDealerOpen}
        PaperProps={{ sx: { minWidth: '200px', padding: '1rem', textAlign: 'center' } }}
      >
        <b>Select a Dealer</b>
        <FormControl size='small' fullWidth sx={{ margin: '1rem auto' }}>
          <InputLabel>Selected Dealer</InputLabel>
          <Select
            label='Selected Dealer'
            fullWidth
            size='small'
            value={selectedDealerId}
            onChange={e => setSelectedDealerId(e.target.value)}
          >
            {bToBDealers
              .slice()
              .sort((a, b) => (a.businessName > b.businessName ? 1 : -1))
              .filter(dealer => dealer.isActive)
              .map(item => (
                <MenuItem value={item.id}>{item.businessName}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
          disabled={showCreatingSpinner || selectedDealerId === ''}
          variant='contained'
          size='small'
          onClick={createStatement}
        >
          Create Statement
        </Button>
      </Dialog>
    </Box>
  )
}

export default Index
