import { useState } from 'react'
import useBearerTokenHeaders from './useBearerTokenHeaders'

export const sms_gpt_prompt = `Rewrite the following text message so that it is professional, courteous and friendly; suitable for the workplace:\n\n`
export const leading_res = `Rewritten text message:\n\n`

export const useCustomerSMSHelpRespond = (customerId: string) => {
  const [loading, setLoading] = useState(false)

  const bearerTokenHeaders = useBearerTokenHeaders()

  const response = (text: string, callback: (newText: string) => void) => {
    setLoading(true)
    const context = window.prompt('Provide any additional context please:')

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_help_responding`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        customer_id: customerId,
        custom_prompt: context,
      }),
    }).then(res => {
      setLoading(false)
      res.json().then(newRes => callback(newRes?.response))
    })
  }

  return { response, responseLoading: loading }
}
