import { Switch, Route, useRouteMatch } from 'react-router-dom'

import Quote from './Quote'

const QuoteRouter = ({ lead }) => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:quoteId`}>
        <Quote lead={lead} />
      </Route>
    </Switch>
  )
}

export default QuoteRouter
