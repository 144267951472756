import React, { useContext, useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import {
  Paper, Box, Grid, Typography, Button, TextField, Autocomplete, FormControlLabel, Checkbox
} from '@mui/material'
import {
  ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis, Legend, Bar, Line, Label, LabelList, Tooltip, ReferenceLine
} from 'recharts'
import { Link } from 'react-router-dom'

import { GlobalContext } from 'GlobalStore'
import Formatters from 'Charts/Formatters'
import LabelContent from 'Charts/LabelContent'
import TooltipContent from 'Charts/TooltipContent'

const QUOTES = gql`
  query quoteCharts($source: String, $group: String, $market: String, $fromDate: String, $toDate: String, $technicianId: String) {
    quoteCharts(source: $source, group: $group, market: $market, fromDate: $fromDate, toDate: $toDate, technicianId: $technicianId) {
      x
      technicianIds
      quotes
      quoteIds
      total
      paymentGross
      paymentNet
      taxableAmount
    }
  }
`

const sources = ['All', 'Car Body Lab', 'Driveway']
const groups = ['Month', 'Week', 'Day']
const colors = ['black', 'red', 'blue', 'cyan', 'green', 'orange', 'silver', 'gray']

const metricLabels = {
  quotes: 'Quotes',
  total: 'Grand Total',
  paymentGross: 'Payments sum without tip',
  paymentNet: 'Payments sum with tip',
  taxableAmount: 'Taxable Amount'
}
const metrics = Object.keys(metricLabels)
const metricFormatters = {
  quotes: Formatters.count,
  total: Formatters.dollars,
  paymentGross: Formatters.dollars,
  paymentNet: Formatters.dollars,
  taxableAmount: Formatters.dollars
}

const defaultFilter = {
  source: 'All',
  market: 'All',
  group: 'Day',
  fromDate: '',
  toDate: '',
  technicianId: 'All'
}
const defaultView = {
  bar: 'quotes',
  line: 'total',
  filter: true,
  labels: true
}
const defaultMetricColors = {
  quotes: 'purple',
  total: 'black',
  paymentGross: 'green',
  paymentNet: 'teal',
  taxableAmount: 'orange'
}

const styles = {
  sourceField: {
    display: 'inline-flex',
    width: 200
  },
  marketField: {
    display: 'inline-flex',
    width: 240
  },
  groupField: {
    display: 'inline-flex',
    width: 140
  },
  dateField: {
    display: 'inline-flex',
    width: 150
  },
  metricField: {
    display: 'inline-flex',
    width: 225
  },
  colorField: {
    display: 'inline-flex',
    width: 120
  }
}

const Quotes = ({ markets }) => {
  const [global] = useContext(GlobalContext)

  const [filter, setFilter] = useState(JSON.parse(localStorage.getItem('chartsQuotesFilter')) || defaultFilter)
  useEffect(() => localStorage.setItem('chartsQuotesFilter', JSON.stringify(filter)), [filter])

  const [nextFilter, setNextFilter] = useState({})
  const handleChangeSource = (event, newval) => setNextFilter({ ...nextFilter, source: newval || defaultFilter.source })
  const handleChangeMarket = (event, newval) => {
    if (!newval) {
      const next = { ...nextFilter }
      delete next.market
      setNextFilter(next)
    } else setNextFilter({ ...nextFilter, market: newval.value })
  }
  const handleChangeGroup = (event, newval) => setNextFilter({ ...nextFilter, group: newval || defaultFilter.group })
  const handleChangeFromDate = event => setNextFilter({ ...nextFilter, fromDate: event.target.value })
  const handleChangeToDate = event => setNextFilter({ ...nextFilter, toDate: event.target.value })
  const handleClickClear = () => setNextFilter({})
  const handleClickUpdate = () => {
    const next = nextFilter
    setNextFilter({})
    setFilter({
      source: next.source || filter.source,
      market: next.market || filter.market,
      group: next.group || filter.group,
      fromDate: dirtyFromDate ? next.fromDate : filter.fromDate, // allow ''
      toDate: dirtyToDate ? next.toDate : filter.toDate // allow ''
    })
  }

  const [view, setView] = useState(JSON.parse(localStorage.getItem('chartsQuotesView')) || defaultView)
  useEffect(() => localStorage.setItem('chartsQuotesView', JSON.stringify(view)), [view])
  const setViewProp = (key, val) => {
    const next = { ...view }
    next[key] = val
    setView(next)
  }
  const handleChangeViewFilters = () => setViewProp('filter', !view.filter)
  const handleChangeViewView = () => setViewProp('view', !view.view)
  const handleChangeViewBar = (event, newval) => setViewProp('bar', newval || defaultView.bar)
  const handleChangeViewLine = (event, newval) => setViewProp('line', newval || defaultView.line)
  const handleChangeViewLegend = () => setViewProp('legend', !view.legend)
  const handleChangeViewLabels = () => setViewProp('labels', !view.labels)
  const handleChangeViewTooltip = () => setViewProp('tooltip', !view.tooltip)

  const [metricColors, setMetricColors] = useState(JSON.parse(localStorage.getItem('chartsQuotesColors')) || defaultMetricColors)
  useEffect(() => localStorage.setItem('chartsQuotesColors', JSON.stringify(metricColors)), [metricColors])
  const setColor = (key, val) => {
    if (!val && (key !== 'fromDate' && key !== 'toDate')) return
    const next = { ...metricColors }
    next[key] = val
    setMetricColors(next)
  }
  const handleChangeBarColor = (event, newval) => setColor(view.bar, newval || defaultMetricColors[view.bar])
  const handleChangeLineColor = (event, newval) => setColor(view.line, newval || defaultMetricColors[view.line])

  const CustomTooltip = TooltipContent(metricFormatters, metricLabels, metricColors)
  const CustomBarLabel = LabelContent.bar(metricFormatters[view.bar], metricColors[view.bar])
  const CustomLineLabel = LabelContent.line(metricFormatters[view.line], metricColors[view.line])

  // GraphQL
  const { loading, error, data } = useQuery(QUOTES, {
    variables: {
      technicianId: 'All',
      source: filter.source,
      group: filter.group,
      market: filter.market,
      fromDate: filter.fromDate || null,
      toDate: filter.toDate || null
    }
  })

  const techniciansNames = {}
  global.technicians.forEach(technician => {
    techniciansNames[technician.id] = technician.firstName + ' ' + technician.lastName
  })

  const handleClickBar = (event) => setSelectedDate(event)
  const handleClickCopyQuotes = () => navigator.clipboard.writeText(selectedDate.payload.quoteIds.join('\n'))

  const dirtyFromDate = Object.hasOwnProperty.call(nextFilter, 'fromDate')
  const dirtyToDate = Object.hasOwnProperty.call(nextFilter, 'toDate')
  const dirty = !!(nextFilter.source || nextFilter.market || nextFilter.group || dirtyFromDate || dirtyToDate)
  const displayMarket = markets.find(market => market.value === (nextFilter.market || filter.market))
  const displayFromDate = dirtyFromDate ? nextFilter.fromDate : filter.fromDate
  const displayToDate = dirtyToDate ? nextFilter.toDate : filter.toDate

  const [selectedDate, setSelectedDate] = useState({})
  useEffect(() => setSelectedDate({}), [filter])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ float: 'right' }}>
        <FormControlLabel label='Filter' control={<Checkbox checked={view.filter} onChange={handleChangeViewFilters} name='Filter' />} />
        <FormControlLabel label='View' control={<Checkbox checked={view.view} onChange={handleChangeViewView} name='View' />} />
      </Box>
      <Typography variant='h5'>
        <>Quotes for Any Technician in {filter.market} </>
        <> from {filter.fromDate ? filter.fromDate : '(all time)'}</>
        <> to {filter.toDate ? filter.toDate : '(current)'}</>
        <> by {filter.group}</>
      </Typography>

      <Grid container spacing={2}>
        {(view.filter || view.view) &&
          <Grid item xs={12}>
            <Paper>
              <Grid container>

                {view.filter &&
                  <Grid item xs={12}>
                    <Autocomplete
                      sx={styles.sourceField}
                      options={sources}
                      value={nextFilter.source ? nextFilter.source : filter.source}
                      onChange={handleChangeSource}
                      renderInput={(markets) => <TextField {...markets} label='Source' />}
                    />
                    <Autocomplete
                      sx={styles.marketField}
                      options={markets}
                      value={displayMarket}
                      getOptionLabel={(market) => market.label}
                      getOptionSelected={(market) => market.value === filter.market}
                      onChange={handleChangeMarket}
                      renderInput={(markets) => <TextField {...markets} label='Market' />}
                    />
                    <Autocomplete
                      sx={styles.groupField}
                      value={nextFilter.group ? nextFilter.group : filter.group}
                      onChange={handleChangeGroup}
                      options={groups}
                      style={{ width: 110 }}
                      renderInput={(groups) => <TextField {...groups} label='Group' />}
                    />
                    <TextField
                      sx={styles.dateField}
                      label='From'
                      type='date'
                      value={displayFromDate}
                      onChange={handleChangeFromDate}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={styles.dateField}
                      label='To'
                      type='date'
                      value={displayToDate}
                      onChange={handleChangeToDate}
                      InputLabelProps={{ shrink: true }}
                    />
                    <Button variant='outlined' color='primary' disabled={!dirty} onClick={handleClickClear}>
                      clear
                    </Button>
                    <Button variant='contained' color='primary' disabled={!dirty} onClick={handleClickUpdate}>
                      update
                    </Button>
                  </Grid>}

                {view.view &&
                  <Grid item xs={12}>
                    <Autocomplete
                      sx={styles.metricField}
                      value={view.bar}
                      options={metrics}
                      onChange={handleChangeViewBar}
                      getOptionSelected={(metric) => metric === view.bar}
                      getOptionLabel={(metric) => metricLabels[metric]}
                      renderInput={(params) => <TextField {...params} label='Bar' />}
                    />
                    <Autocomplete
                      sx={styles.colorField}
                      value={metricColors[view.bar]}
                      options={colors}
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      onChange={handleChangeBarColor}
                      getOptionSelected={(color) => color === metricColors[view.bar]}
                      renderInput={(params) => <TextField {...params} label='Bar Color' />}
                    />
                    {/* <TextField
                      className={`${classes.textField} ${classes.heightField}`}
                      label='Bar Y'
                      value={scale[view.bar]}
                      size='small'
                      onChange={handleChangeScaleBar}
                      inputProps={{
                        step: metricScale[view.bar],
                        type: 'number',
                        'aria-labelledby': 'input-slider'
                      }}
                    /> */}
                    <Autocomplete
                      sx={styles.metricField}
                      value={view.line}
                      onChange={handleChangeViewLine}
                      options={metrics}
                      getOptionSelected={(metric) => metric === view.line}
                      getOptionLabel={(metric) => metricLabels[metric]}
                      renderInput={(params) => <TextField {...params} label='Line' />}
                    />
                    <Autocomplete
                      sx={styles.colorField}
                      value={metricColors[view.line]}
                      onChange={handleChangeLineColor}
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      options={colors}
                      getOptionSelected={(color) => color === metricColors[view.line]}
                      renderInput={(params) => <TextField {...params} label='Line Color' />}
                    />
                    {/* <TextField
                      className={`${classes.textField} ${classes.heightField}`}
                      label='Line Y'
                      value={scale[view.line]}
                      size='small'
                      onChange={handleChangeScaleLine}
                      inputProps={{
                        step: metricScale[view.line],
                        type: 'number',
                        'aria-labelledby': 'input-slider'
                      }}
                    /> */}
                    <FormControlLabel
                      control={<Checkbox checked={view.legend} onChange={handleChangeViewLegend} name='Legend' />}
                      label='Legend'
                    />
                    <FormControlLabel
                      control={<Checkbox checked={view.labels} onChange={handleChangeViewLabels} name='Labels' />}
                      label='Labels'
                    />
                    <FormControlLabel
                      control={<Checkbox checked={view.tooltip} onChange={handleChangeViewTooltip} name='Tooltip' />}
                      label='Tooltip'
                    />
                  </Grid>}

              </Grid>
            </Paper>
          </Grid>}

        {loading &&
          <Grid item xs={12}>
            <Paper>Loading...</Paper>
          </Grid>}

        {error &&
          <Grid item xs={12}>
            <Paper>
              Error!
              <br />
              {error.graphQLErrors.message}
              <br />
              {error.graphQLErrors.locations}
            </Paper>
          </Grid>}

        {(!loading) && (!error) &&
          <Grid item xs={12} style={{ position: 'relative' }}>
            <Paper>
              <ResponsiveContainer height={360} width='98%'>
                <ComposedChart data={data.quoteCharts} margin={{ top: 20, left: 20, right: 20 }}>
                  <CartesianGrid stroke='#ccc' />

                  {view.legend && <Legend width={160} />}

                  {view.bar &&
                    <Bar type='monotone' yAxisId='left' barSize={32} dataKey={view.bar} fill={metricColors[view.bar]} onClick={handleClickBar}>
                      {view.labels && <LabelList dataKey={view.bar} position='top' content={CustomBarLabel} />}
                    </Bar>}

                  {view.line &&
                    <Line type='monotone' yAxisId='right' strokeWidth={7} dataKey={view.line} stroke={metricColors[view.line]}>
                      {view.labels && <LabelList dataKey={view.line} position='center' content={CustomLineLabel} />}
                    </Line>}

                  <XAxis dataKey='x' />
                  <YAxis yAxisId='left' type='number'>
                    <Label position='left' angle={-90} offset={0} value={metricLabels[view.bar]} />
                  </YAxis>
                  <YAxis yAxisId='right' orientation='right' type='number'>
                    <Label position='right' angle={90} offset={0} value={metricLabels[view.line]} />
                  </YAxis>

                  {view.tooltip && <Tooltip content={CustomTooltip} />}

                  {selectedDate && selectedDate.payload &&
                    <ReferenceLine
                      x={selectedDate.payload.x}
                      stroke='black'
                      yAxisId='left'
                    />}

                </ComposedChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>}

        {selectedDate.payload &&
          <Grid item xs={4}>
            <Paper>
              <ul>{filter.group} of {selectedDate.payload.x} in {filter.market}
                {metrics.map(metric =>
                  <li key={metric}>{metricLabels[metric]}: {selectedDate.payload[metric]}</li>
                )}
              </ul>
            </Paper>
          </Grid>}

        {selectedDate.payload && selectedDate.payload.technicianIds &&
          <Grid item xs={4}>
            <Paper>
              <Button variant='contained' color='primary' style={{ float: 'right' }} onClick={handleClickCopyQuotes}>
                copy
              </Button>
              <ul>Technicians: {selectedDate.payload.technicianIds.length}
                {selectedDate.payload.technicianIds.map(technicianID => {
                  return (
                    <li key={technicianID}>
                      <Link to={'/technicians/' + technicianID}>{techniciansNames && techniciansNames[technicianID]}</Link>
                      <> : </>
                      <Link to={'/charts/technicians/' + technicianID}>Chart</Link>
                    </li>
                  )
                })}
              </ul>
            </Paper>
          </Grid>}

        {selectedDate.payload &&
          <Grid item xs={4}>
            <Paper>
              <Button variant='contained' color='primary' style={{ float: 'right' }} onClick={handleClickCopyQuotes}>
                copy
              </Button>
              <ul>Quotes: {selectedDate.payload.quotes}
                {selectedDate.payload.quoteIds.map(quoteID => {
                  return (
                    <li key={quoteID}>
                      <Link to={'/quotes/' + quoteID}>{quoteID}</Link>
                    </li>
                  )
                })}
              </ul>
            </Paper>
          </Grid>}
      </Grid>
    </Box>
  )
}

export default Quotes
