import { useQuery, gql } from '@apollo/client'

const QUERY = gql`
  query chatMessageCount($contextType: String!, $contextId: String!) {
    chatMessageCount(contextType: $contextType, contextId: $contextId)
  }
`

const ChatMessageCount = ({ contextType, contextId }) => {
  const { data } = useQuery(QUERY, {
    variables: { contextType, contextId }
  })

  return data ? data.chatMessageCount : '-'
}

export default ChatMessageCount
