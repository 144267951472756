import { Box, Dialog } from '@mui/material'
import JSONPretty from 'react-json-pretty'

export const PayoutDataPopover = ({ payoutDataOpen, setPayoutDataOpen, data }: any) => {
  return (
    <Dialog
      PaperProps={{
        sx: { minWidth: '700px' },
      }}
      onClose={() => setPayoutDataOpen(false)}
      open={payoutDataOpen}
    >
      <Box sx={{ background: 'aliceblue', padding: '1rem', borderBottom: '2px solid #a1cff7', fontWeight: 600 }}>
        Payout Breakdown
      </Box>
      <Box sx={{ padding: '.5rem', background: '#f1f1f1' }}>
        <Box sx={{ padding: '0rem 1rem', border: '1px solid #ddd', borderRadius: '12px', background: '#fff' }}>
          <JSONPretty style={{ fontSize: '12px' }} data={data} />
        </Box>
      </Box>
    </Dialog>
  )
}
