import React, { useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Box,
  CircularProgress,
  Switch,
  Typography,
  Button,
  Chip,
} from '@mui/material'
import { paymentStatusLabels } from '../data/styles'
import { Link, useHistory } from 'react-router-dom'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const SMART_FOLLOWUPS_QUERY = gql`
  query SmartFollowups {
    smartFollowups {
      id
      name
      active
      lastMessageDelayHours
      interactionStatus
      marketsCriterion {
        id
        data
        type
      }
    }
  }
`

const SmartFollowupsIndex = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const { loading, error, refetch, data } = useQuery(SMART_FOLLOWUPS_QUERY, { fetchPolicy: 'network-only' })
  const history = useHistory()

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const createNewSmartFollowup = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_smart_followup`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
      }),
    }).then(res => res.json().then(res => history.push(`/smart-followups/${res.id}/edit`)))
  }

  const handleToggleStatus = (e, smart_followup_id) => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_smart_followup_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        smart_followup_id: smart_followup_id,
        updated_attributes: {
          active: e.target.checked,
        },
      }),
    }).then(res => refetch())
  }

  const smartFollowups = data?.smartFollowups || []

  return (
    <Box sx={{ maxWidth: '75%', margin: '0px auto' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='h4' sx={{ mt: '1rem', mb: '1rem' }}>
          Smart Followups
        </Typography>
        <Button variant='contained' onClick={createNewSmartFollowup}>
          NEW
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table size='small'>
          {loading && (
            <Box
              sx={{
                position: 'fixed',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#11111140',
                top: '0px',
                bottom: '0px',
                left: '0px',
                right: '0px',
              }}
            >
              <Box>
                <CircularProgress />
              </Box>
            </Box>
          )}
          <TableHead>
            <TableRow>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='left'>Markets</TableCell>
              <TableCell align='right'>Delay</TableCell>
              <TableCell align='right'>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {smartFollowups.map(sf => (
              <TableRow key={sf.id} sx={paymentStatusLabels}>
                <TableCell align='left'>
                  <Link to={`/smart-followups/${sf.id}/edit`}>{sf.name}</Link>
                </TableCell>
                <TableCell>
                  {sf.marketsCriterion?.data.markets.map(m => (
                    <Chip size='small' label={m} />
                  ))}
                </TableCell>
                <TableCell align='right'>{sf.lastMessageDelayHours}</TableCell>
                <TableCell align='right'>
                  <Switch checked={sf.active} onChange={e => handleToggleStatus(e, sf.id)} />
                  {sf.active ? (
                    <Chip size='small' style={{ background: 'lightgreen', fontWeight: 800 }} label='ACTIVE' />
                  ) : (
                    <Chip size='small' style={{ background: 'orange', fontWeight: 800 }} label='INACTIVE' />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default SmartFollowupsIndex
