import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import Show from './Show'
import { useQuery, gql } from '@apollo/client'

const QUERY = gql`
  query bToBDealer($id: ID!) {
    bToBDealer(id: $id) {
      id
      businessName
      createdAt
      addressLineOne
      addressLineTwo
      city
      state
      zip
      market
      storeHours
      isActive
      technicians {
        id
        firstName
        lastName
        active
      }
      contacts {
        id
        firstName
        lastName
        description
        phone
        email
        isPrimary
      }
    }
  }
`

const Router = () => {
  const { path } = useRouteMatch()
  const { dealerId } = useParams()
  const { loading, error, data, refetch } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: dealerId,
    },
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const dealer = data?.bToBDealer

  return (
    <Switch>
      <Route path={path}>
        <Show dealer={dealer} refetch={refetch} />
      </Route>
    </Switch>
  )
}

export default Router
