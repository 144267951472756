import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { RefetchRegistry } from 'RefetchRegistry'
import { UpdateRefetchRegistry } from 'UpdateRefetchRegistry'

import ShowEdit from './ShowEdit'

const PART_VENDOR_QUERY = gql`
  query partVendor($id: ID!) {
    partVendor(id: $id) {
      id
      name
      googleMapUrl
      addressLineOne
      addressLineTwo
      city
      state
      zip
      phone
      email
      website
      paymentNotes
    }
  }
`

const Router = () => {
  const { partVendorId } = useParams()
  const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  const [registerUpdateRefetch, unregisterUpdateRefetch] = useContext(UpdateRefetchRegistry)

  const { loading, error, data, refetch } = useQuery(PART_VENDOR_QUERY, { variables: { id: partVendorId } })

  useEffect(() => {
    const key = registerRefetch({ types: ['PartVendor'], refetch })
    return () => unregisterRefetch(key)
  }, [])

  useEffect(() => {
    const key = registerUpdateRefetch({ types: ['PartVendor'], refetch })
    return () => unregisterUpdateRefetch(key)
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return <ShowEdit partVendor={data.partVendor} />
}

export default Router
