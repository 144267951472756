import { gql } from '@apollo/client'
import { makeStyles } from '@mui/styles'

export const removeFilter = ({ name, filter, setFilter }) => {
  const { [name]: removed, ...remainingFilters } = filter
  setFilter(remainingFilters)
}

export const setOrToggleOrder = ({ name, order, setOrder }) => {
  const { [name]: removed, ...remainingOrder } = order
  if (order[name] === 'asc') setOrder(remainingOrder)
  else setOrder({ [name]: order[name] ? 'asc' : 'desc', ...remainingOrder })
}

export const removeSearch = ({ field, search, setSearch }) => {
  const { [field]: removed, ...remainingSearches } = search
  setSearch(remainingSearches)
}

export const labelsList = ['Received', 'Pending approval', 'Approved', 'Delivered'].sort()

export const useStyles = makeStyles(theme => ({
  headerFormControl: {
    minWidth: '5em',
  },
  dateComponent: {
    fontSize: '12px',
    margin: '0px -100px -2px 0px',
    float: 'left',
    maxHeight: '27px',
    padding: '3px 7px',
  },
  dateDialog: {
    display: 'flex',
    '& div': {
      padding: '5px',
      '& span': {
        background: '#eee',
        display: 'block',
        padding: '3px',
        borderRadius: '3px 3px 0px 0px',
        border: '1px solid #ccc',
        borderBottom: '1px dashed #eee',
      },
      '& input': {
        padding: '3px',
        borderRadius: '0px 0px 3px 3px',
        border: '1px solid #ccc',
        borderTop: '0px',
      },
    },
  },
}))

export const B2BTechs = gql`
  query bToBTechnicians {
    bToBTechnicians {
      id
      name
      firstName
      lastName
      market
      displayMarket
      active
      state
      offdays
      isBToB
      isEmployee
      availability
      note
    }
  }
`
export const DEALERS_QUERY = gql`
  query BToBDealers {
    bToBDealers {
      id
      businessName
      isActive
    }
  }
`

export const bToBInvoiceStatementsConnection = gql`
  query BToBInvoiceStatementsConnection(
    $search: JSON
    $order: JSON
    $filter: JSON
    $first: Int
    $after: String
    $isCompleted: Boolean
    $isAccepted: Boolean
    $hasPayoutData: Boolean
    $excludeFullyPaid: Boolean
  ) {
    bToBInvoiceStatementsConnection(
      search: $search
      order: $order
      filter: $filter
      first: $first
      after: $after
      isCompleted: $isCompleted
      isAccepted: $isAccepted
      hasPayoutData: $hasPayoutData
      excludeFullyPaid: $excludeFullyPaid
    ) {
      edges {
        cursor
        node {
          id
          createdAt
          acceptedAt
          businessName
          grandTotal
          discountPercent
          discountPercentAmount
          photos
          completedAt
          deletedAt
          paidSquareInvoiceId
          fullyPaidAt
          ccFee
          dealerId
          label
          periodStart
          periodEnd
          statementNumber
          dealer {
            id
            state
            businessName
            primaryContact {
              firstName
              lastName
              email
            }
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
