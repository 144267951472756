import { useContext, useEffect } from 'react'
import { useParams, Switch, Route, useRouteMatch } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import JobsRouter from './Jobs/Router'
import PartsRouter from './Parts/Router'
import AppointmentsRouter from './Appointments/Router'
import { keysToCamel } from 'tools'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import ShowEditV2 from './Quote/V2/ShowEditV2'
import { UpdateRefetchRegistry } from 'UpdateRefetchRegistry'
import { RefetchRegistry } from 'RefetchRegistry'

const GET_QUOTE = gql`
  query quote($id: ID!) {
    quote(id: $id) {
      id
      leadId
      balanceAmountDue
      preJobAmountDue
      payments
      offeredDiscount
      postTaxGrandTotal
      nonPaymentReason
      laborIsTaxable
      payrollStatuses
      customerApprovalRequired
      customerMarkedApprovedAt
      payrollHistory

      inspektlabsEstimate {
        id
      }
      lead {
        serviceType
      }
      market {
        quoteApprovalRequired
      }
      payouts {
        quotePayouts
        technician {
          id
          firstName
          lastName
        }
      }
      workLogs {
        id
        payoutData
        job {
          id
          startDatetime
          endDatetime
          technician {
            id
            firstName
            lastName
          }
        }
      }
      groupedPayments {
        id
        createdAt
        totalAmount
        tipAmount
        paymentType
        refundedAmount
        receiptUrl
        amountBeforeTip
        processingFeeAmount
        updatedAt
        origin
        paymentType
        sourceType
        squarePaymentId
        photoUrls
      }
      primaryLaborItems {
        id
        damageId
        description
        name
        price
        void
        panel
        side
        totalHours
        totalPrepHours
        totalPaintHours
        totalBodyWorkHours
        totalMinorPartHours
        totalMajorPartHours
        createdAt
      }
      otherLaborItems {
        id
        name
        price
        void
        createdAt
      }
      carbodylabCharges {
        id
        name
        price
        void
        createdAt
      }
      technicianCharges {
        id
        name
        price
        void
        createdAt
      }
      lead {
        addressLineOne
        addressLineTwo
        state
        city
        zip
      }
      draftedInitialCloseReplies
      paymentStatus
      csrRequestedDraftInitialCloseReplies
      partsPo
      createdAt
      updatedAt
      deletedAt
      commissionPercentOverride
      payoutIds
      payoutData
      payoutBonus
      toggledAudatexOnAt
      assignedTechnicianId
      isAllowedPayoutBeforeFollowups
      postJobRefundAmount
      tipRefundAmount
      markedInsuranceAt
      insurancePhotos
      difficultyLevel
      requiredSkillsets
      sentToCustomerAt
      appointmentOfferedAt
      taxRate
      quoteVersion
      approvedTechnicianIds
      approvedTechnicians {
        id
        firstName
        lastName
        note
        availability
        offdays
        isEmployee
      }
      activeJobIds
      jobs {
        id
        isActive
        canceledAt
      }
      parts {
        id
        quoteId
        quotePart
        quotePartId
        quoteNumber
        photoId
        status
        createdAt
        canceledAt
        removedFromQuoteAt
        price
        cost
        type
        name
        number
        prePaymentRequired
        referenceNumber
        pickupStatus
        pickupHistory
        etaRequestedAt
        etaBusinessDays
        orderRequestedAt
        orderPlacedAt
        readyForPickupAt
        ccLastFour
        isPaid
        coreRefundExpectedAmount
        partVendorId
        tireConnectData
        tireConnectOrderData
        nagsGlassId
        omegaOrderId
        vendor {
          id
          name
        }
      }
      hourlyRates {
        prep
        paint
        bodyWork
        majorPart
        minorPart
      }
      technician {
        id
        name
        firstName
        lastName
        state
        phone
        market
        offdays
        active
        lat
        lng
      }
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
`

const Quote = ({ lead }) => {
  const { path } = useRouteMatch()
  const { quoteId } = useParams()
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)

  // const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  // const [registerUpdateRefetch, unregisterUpdateRefetch] = useContext(UpdateRefetchRegistry)

  const { loading, error, data, refetch } = useQuery(GET_QUOTE, {
    variables: { id: quoteId },
  })

  useEffect(() => {
    data && refetch && refetch()
  }, [])

  useEffect(() => {
    const key = registerTaskTarget({ targetType: 'Quote', targetId: quoteId })
    return () => unregisterTaskTarget(key)
  }, [])

  // useEffect(() => {
  //   const key = registerRefetch({ types: ['Quote'], refetch })
  //   return () => unregisterRefetch(key)
  // }, [])

  // useEffect(() => {
  //   const key = registerUpdateRefetch({ types: ['Quote'], refetch })
  //   return () => unregisterUpdateRefetch(key)
  // }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return (
    <Switch>
      <Route path={`${path}/jobs`}>
        <JobsRouter refetchQuote={refetch} lead={lead} quote={data.quote} />
      </Route>
      <Route path={`${path}/parts`}>
        <PartsRouter lead={lead} quote={data.quote} />
      </Route>
      <Route path={`${path}/appointments`}>
        <AppointmentsRouter lead={lead} quote={data.quote} />
      </Route>

      <Route path={path}>
        <ShowEditV2 lead={lead} quote={keysToCamel(data.quote)} refetchQuote={refetch} />
      </Route>
    </Switch>
  )
}

export default Quote
