import { useState, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { keysToCamel } from 'tools'
import { Link } from 'react-router-dom'
import { Collapse, Button } from '@mui/material'

import { UserContext } from 'UserStore'
import { ArrowDownward } from '@mui/icons-material'

const GET_LEAD_DATA = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      id
      customer {
        id
        leads {
          id
          createdAt
          deletedAt
        }
      }
    }
  }
`

const LeadSiblingWarning = ({ lead, refetch }) => {
  const [user] = useContext(UserContext)
  const [open, setOpen] = useState(false)
  const { loading, error, data } = useQuery(GET_LEAD_DATA, {
    fetchPolicy: 'network-only',
    variables: { id: lead.id },
  })

  if (loading || error) return null

  const now = DateTime.now()

  const recentSiblings = keysToCamel(
    data.lead.customer.leads
      .filter(_lead => _lead.id !== lead.id)
      .filter(_lead => now.diff(DateTime.fromISO(_lead.createdAt), ['days']).toObject().days < 31)
  )

  if (recentSiblings.length === 0) return null

  const sorted = recentSiblings.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))

  return (
    <div style={{ border: '2px dashed orange', padding: 4, margin: 2, fontSize: 16 }}>
      <Button
        color='error'
        sx={{ color: 'red', fontSize: '12px', '& svg': { fontSize: '14px', transform: open ? 'rotate(180deg)' : '' } }}
        onClick={() => setOpen(!open)}
      >
        <ArrowDownward sx={{ mr: '.5rem' }} />
        This customer has {recentSiblings.length} additional lead{recentSiblings.length > 1 ? 's' : ''} within 30 days
        <ArrowDownward sx={{ ml: '.5rem' }} />
      </Button>
      &nbsp;&nbsp;
      <Collapse in={open}>
        <ul style={{ margin: 0 }}>
          {sorted.map(lead => (
            <li key={lead.id}>
              <Link to={`/leads/${lead.id}`}>{DateTime.fromISO(lead.createdAt).toFormat('f')}</Link>
              {lead.deletedAt && <> (deleted) </>}
            </li>
          ))}
        </ul>
      </Collapse>
    </div>
  )
}

export default LeadSiblingWarning
