import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import Edit from './Edit'
import { useQuery, gql } from '@apollo/client'

const QUERY = gql`
  query vehicleYearMakePremium($id: String!) {
    vehicleYearMakePremium(id: $id) {
      id
      year
      makeShortName
      premiumPercent
    }
  }
`

const Router = () => {
  const { path } = useRouteMatch()
  const { yearMakePremiumID } = useParams()
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: {
      id: yearMakePremiumID
    }})

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const yearMakePremium = data?.vehicleYearMakePremium

  return (
    <Switch>
      <Route path={path}>
        <Edit yearMakePremium={yearMakePremium} refetch={refetch} />
      </Route>
    </Switch>
  )
}

export default Router
