import {
  Box, Paper, Link, Table, TableHead, TableCell, TableBody, TableRow,
  Typography, Grid, Button, Select, MenuItem, FormControl, InputLabel,
  TablePagination, Popover, TextField
} from '@mui/material'
import { PhoneRounded, PostAdd, CheckCircle, Timeline, CircleOutlined, Circle, Launch } from '@mui/icons-material'
import { useLazyQuery } from '@apollo/client'
import { useState, useEffect, useContext, createContext } from 'react'
import { GlobalContext } from '../../GlobalStore'
import { DateTime } from 'luxon'
import DateTimePicker from '@mui/lab/DateTimePicker'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import {
  CALL_RECORDS, CALLBACK_REQUESTS, filterBarButtonPrettyNames, callRecordsTableHeaders,
  globalAveragesTableHeaders, callbackRequestsTableHeaders
} from './constants'
import { LoadingAveragesSkeleton, UserRecordsSkeleton, CallRecordsSkeleton, ActivityTimelineSkeleton } from './Skeletons'
import Show from '../../CallbackRequests/CallbackRequest/Show'
import { keysToCamel } from '../../tools'
import {
  avgTimeToStartCall, totalTimeOnCallsForUserInList, totalTimeOnCalls, avgNumberOfDailyCalls,
  numberOfCsrs, avgCallDurationForUserInList, avgCallDurationForList, avgTimeToStartCallForUserInList,
  callRecordTotalsGroupedByCsr, averageDurationBetweenCallsForUserInList, averageDurationBetweenCalls,
  handleClickDateRangeButton, callLogsForTable, callbackRequestsForTable, userByTwilioId, userName,
  userId, avgNumberOfDailyCallsForUserInList, groupedActivityForUser
} from './tools'
import { style } from './style'

const QueryContext = createContext()

const CSRActivity = () => {
  const {
    callRecordsStateData,
    callbackRequestsStateData,
    queryVariableStateData
  } = useContext(QueryContext)
  const [global] = useContext(GlobalContext)
  const [callbackRequestInPopup, setCallbackRequestInPopup] = useState(null)

  const {
    search, setSearch, limit, filter, page, setPage,
    callbackRequestsPage, callLogsPageCeiling,
    setCallLogsPageCeiling, setCallbackRequestsPage,
    setCallbackRequestsPageCeiling, getData
  } = queryVariableStateData

  const {
    loading: loadingRecords,
    error: errorRecords,
    data: callRecordsData,
    fetchMore: fetchMoreCallLogs
  } = callRecordsStateData

  const {
    error,
    loading,
    data: callbackRequestsData,
    fetchMore: fetchMoreCallbacks
  } = callbackRequestsStateData

  useEffect(() => (search || filter) && getData(), [])

  if (error) return <div>Error!</div>
  if (errorRecords) return <div>Error!</div>
  if (!callRecordsData) {
    return (
      <Button sx={{ mt: '1rem' }} onClick={getData} variant='contained'>
        generate new report
      </Button>
    )
  }

  const users = global.users
  const callRecords = callRecordsData.callRecordsConnection.edges.map(
    edge => edge.node
  )
  const totalCallRecords =
    (callRecordsData && callRecordsData.callRecordsConnection.totalCount) || 0

  const callbackRequests =
    (callbackRequestsData &&
      callbackRequestsData.callbackRequestsConnection.edges.map(
        edge => edge.node
      )) ||
    []
  const callRecordsEndCursor =
    (callRecordsData &&
      callRecordsData.callRecordsConnection.pageInfo.endCursor) ||
    null

  const showGlobalMetrics = Object.keys(search).length < 1
  const showUserSpecificMetrics = Object.keys(search).length > 0

  const averagesMetricsMap = [
    { label: 'TOTAL NUMBER OF CALLS', metric: totalCallRecords },
    {
      label: 'AVERAGE CALLS PER DAY',
      metric: avgNumberOfDailyCalls(callRecords).toFixed(2)
    },
    {
      label: 'AVERAGE RESPONSE TIME',
      metric: showGlobalMetrics
        ? avgTimeToStartCall(callbackRequests)
        : avgTimeToStartCallForUserInList(
          userId(search.clientId, users),
          callbackRequests
        )
    },
    {
      label: 'AVERAGE CALL DURATION',
      metric: avgCallDurationForList(callRecords)
    },
    {
      label: 'AVERAGE TIME BETWEEN CALLS',
      metric: averageDurationBetweenCalls(callRecords)
    },
    {
      label: 'TOTAL TIME SPENT ON CALLS',
      metric: showGlobalMetrics
        ? totalTimeOnCalls(callRecords)
        : totalTimeOnCallsForUserInList(search.clientId, callRecords)
    }
  ]

  const UserView = ({ clientId }) => {
    const [highlightedCall, setHighlightedCall] = useState()
    const [callRecordsRowsPerPage, setCallRecordsRowsPerPage] = useState(
      JSON.parse(localStorage.getItem('callbackLogsLimit')) || 25
    )
    const [
      callbacksRequestsRowsPerPage,
      setCallbacksRequestsRowsPerPage
    ] = useState(25)
    const [activityLimit, setActivityLimit] = useState(50)
    const [order] = useState(
      JSON.parse(localStorage.getItem('callLogsOrder')) || {}
    )

    const handleChangePageForCallLogs = (event, wantedPage) => {
      setPage(wantedPage)

      if (wantedPage > callLogsPageCeiling) {
        setCallLogsPageCeiling(wantedPage)

        fetchMoreCallLogs({
          variables: {
            search,
            order,
            filter,
            first: limit,
            after: callRecordsEndCursor
          }
        })
      }
    }

    const handleChangePageForCallbackRequests = (event, wantedPage) => {
      setCallbackRequestsPage(wantedPage)

      if (wantedPage > callLogsPageCeiling) {
        setCallbackRequestsPageCeiling(wantedPage)

        fetchMoreCallbacks({
          variables: {
            search,
            order,
            filter,
            first: limit,
            after: callRecordsEndCursor
          }
        })
      }
    }

    const handleChangeRowsPerPageForCallLogs = event =>
      setCallRecordsRowsPerPage(event.target.value)
    const handleChangeRowsPerPageForCallbackRequests = event =>
      setCallbacksRequestsRowsPerPage(event.target.value)

    const callbackRequestsForUser = callbackRequests.filter(req =>
      req.attempts.find(attempt => attempt.user_id === userId(clientId, users))
    )

    const ActivityTimeline = () => {
      const handleClickItemInActivityList = (rec) => {
        if (rec.type === 'call') {
          setHighlightedCall(rec.timestamp)
          window.scrollTo({
            top: document.getElementById(rec.timestamp).offsetTop,
            behavior: 'smooth'
          })
        } else {
          setCallbackRequestInPopup(rec.id)
        }
      }
      return (
        <Box>
          {(loading || loadingRecords) && <ActivityTimelineSkeleton />}
          {!loading &&
            !loadingRecords &&
            groupedActivityForUser(
              clientId,
              users,
              filter,
              callbackRequests,
              callRecords,
              callbackRequestsData,
              activityLimit
            ).map(rec => (
              <Typography
                key={rec.timestamp}
                sx={{
                  ...style.activityItem,
                  background:
                    rec.timestamp === highlightedCall ? '#0089CD1a' : '#fff',
                  '& svg': {
                    color:
                      rec.timestamp === highlightedCall ? '#1565c0' : '#777'
                  }
                }}
              >
                {rec.timestamp === highlightedCall && <Circle className='svg' />}
                {rec.timestamp !== highlightedCall && <CircleOutlined className='svg' sx={{ cursor: 'pointer' }} />}

                <Box sx={style.activityItemDivider} />
                {rec.hoursTimestamp}
                &nbsp;
                <span style={{ fontSize: '12px' }}>({rec.prettyDate})</span>
                <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>

                  {rec.type === 'call' && <PhoneRounded className='svg' />}

                  {rec.type === 'attempt' && <CheckCircle className='svg' />}

                  {rec.type === 'request' && <PostAdd className='svg' />}

                  <Link sx={{ cursor: 'pointer' }} onClick={() => handleClickItemInActivityList(rec)}>
                    {rec.type}&nbsp;
                    {rec.duration && <span style={{ fontSize: '12px' }}>({rec.duration})</span>}
                  </Link>
                  &nbsp;
                </Box>
              </Typography>
            ))}
          <Button
            onClick={() => setActivityLimit(prev => prev + 50)}
            sx={{ mt: '1rem' }}
          >
            Show more
          </Button>
        </Box>
      )
    }

    return (
      <Grid container>
        <Grid item xs={9}>
          <Box>
            <Typography
              variant='h1'
              sx={{ fontSize: '20px', fontWeight: 600, mb: '1rem' }}
            >
              Call logs for {userByTwilioId(clientId, users)?.firstName}{' '}
              {userByTwilioId(clientId, users)?.lastName}
            </Typography>
            <Paper sx={style.callbackRequestsPaper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {callRecordsTableHeaders.map((item, index) => (
                      <TableCell
                        key={item}
                        sx={{
                          borderTopLeftRadius: index === 0 ? '12px' : '0px',
                          borderTopRightRadius: index === 6 ? '12px' : '0px'
                        }}
                        size='small'
                      >
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(loading || loadingRecords) && <CallRecordsSkeleton />}
                  {!loading &&
                    !loadingRecords &&
                    callLogsForTable(
                      callRecords,
                      page,
                      callRecordsRowsPerPage
                    ).map(req => (
                      <TableRow
                        key={req.startDatetime}
                        id={req.startDatetime}
                        sx={{
                          background:
                            req.startDatetime === highlightedCall
                              ? '#0089CD1a'
                              : '#fff'
                        }}
                      >
                        <TableCell size='small'>{req.direction}</TableCell>
                        <TableCell size='small'>
                          {req.prettyFromNumber}
                        </TableCell>
                        <TableCell size='small'>{req.prettyToNumber}</TableCell>
                        <TableCell size='small'>
                          {req.prettyStartTime}
                        </TableCell>
                        <TableCell size='small'>{req.prettyEndTime}</TableCell>
                        <TableCell size='small'>{req.duration}</TableCell>
                        <TableCell size='small'>{req.timeGap}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component='div'
                count={totalCallRecords}
                rowsPerPage={callRecordsRowsPerPage}
                page={page}
                onPageChange={handleChangePageForCallLogs}
                onRowsPerPageChange={handleChangeRowsPerPageForCallLogs}
              />
            </Paper>
            <Typography
              variant='h1'
              sx={{ fontSize: '20px', fontWeight: 600, mb: '1rem' }}
            >
              Callback request logs for{' '}
              {userByTwilioId(clientId, users)?.firstName}{' '}
              {userByTwilioId(clientId, users)?.lastName}
            </Typography>
            <Paper sx={style.callbackRequestsPaper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {callbackRequestsTableHeaders.map((item, index) => (
                      <TableCell
                        key={item}
                        sx={{
                          borderTopLeftRadius: index === 0 ? '12px' : '0px',
                          borderTopRightRadius: index === 5 ? '12px' : '0px'
                        }}
                        size='small'
                      >
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {callbackRequestsForTable(
                    callbackRequestsForUser,
                    callbackRequestsPage,
                    callbacksRequestsRowsPerPage,
                    users
                  ).map(req => (
                    <TableRow
                      key={req.createdAt}
                      sx={{
                        background: req.isSunday ? '#cd8c001a' : '#fff'
                      }}
                    >
                      <TableCell size='small'>
                        {(req.lead && (
                          <Link href={`/leads/${req.lead?.id}`}>
                            {req.lead?.name}
                          </Link>
                        )) ||
                          'No lead'}
                      </TableCell>
                      <TableCell size='small'>
                        <Link
                          sx={{ cursor: 'pointer' }}
                          onClick={() => setCallbackRequestInPopup(req.id)}
                        >
                          {req.requestedAt}
                        </Link>
                      </TableCell>
                      <TableCell>{req.timeToFirstResponse}</TableCell>
                      <TableCell size='small'>{req.attempts}</TableCell>
                      <TableCell size='small'>{req.attempts.length}</TableCell>
                      <TableCell size='small'>{req.completedAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component='div'
                count={callbackRequestsForUser.length}
                rowsPerPage={25}
                page={callbackRequestsPage}
                onPageChange={handleChangePageForCallbackRequests}
                onRowsPerPageChange={handleChangeRowsPerPageForCallbackRequests}
              />
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={3} sx={{ pl: '.5rem' }}>
          <Box>
            <Typography variant='h1' sx={style.activityLogTitle}>
              <Timeline sx={{ mr: '1rem' }} /> Activity Log
            </Typography>
            <Paper sx={{ background: '#fff !important' }}>
              <ActivityTimeline />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    )
  }

  const GroupView = () => {
    return (
      <Grid item xs={10.5}>
        <Typography sx={style.bigAverageNumber}>
          CSR Breakdown &#8212; {numberOfCsrs(callRecords)}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              {globalAveragesTableHeaders.map(item => (
                <TableCell key={item} size='small'>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(loading || loadingRecords) && <UserRecordsSkeleton />}
            {!loading &&
              !loadingRecords &&
              callRecordTotalsGroupedByCsr(callRecords)
                .slice()
                .sort((a, b) => (a[1] < b[1] ? 1 : -1))
                .map(csr => (
                  // csr looks like this ['twilio_identity', 0]
                  // csr[0] is the CSR twilioIdentity
                  // csr[1] is number of records
                  <TableRow key={csr[0]}>
                    <TableCell size='small'>
                      <Typography
                        onClick={() => setSearch({ clientId: csr[0] })}
                        color='primary'
                        sx={style.csrNameInGlobalList}
                      >
                        {csr[0]}
                      </Typography>
                    </TableCell>

                    <TableCell size='small'>
                      <span
                        style={{
                          fontWeight: 600,
                          opacity: 0.5,
                          fontSize: '16px'
                        }}
                      >
                        {csr[1]}
                      </span>
                    </TableCell>

                    <TableCell size='small'>
                      {avgCallDurationForUserInList(csr[0], callRecords)}
                    </TableCell>

                    <TableCell size='small'>
                      {averageDurationBetweenCallsForUserInList(
                        csr[0],
                        callRecords
                      )}
                    </TableCell>

                    <TableCell size='small'>
                      {avgNumberOfDailyCallsForUserInList(csr[0], callRecords)}
                    </TableCell>

                    <TableCell size='small'>
                      {callbackRequests &&
                        avgTimeToStartCallForUserInList(
                          userId(csr[0], users),
                          callbackRequests
                        )}
                    </TableCell>

                    <TableCell size='small'>
                      {totalTimeOnCallsForUserInList(csr[0], callRecords)}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </Grid>
    )
  }

  return (
    <Paper sx={style.mainPaper}>
      <FilterBar totalCallRecords={totalCallRecords} users={users} />

      {(loading || loadingRecords) && (
        <LoadingAveragesSkeleton
          label={
            showUserSpecificMetrics
              ? `${userName(search.clientId, users)}'s`
              : 'Global'
          }
        />
      )}

      {!loading && !loadingRecords && (
        <Grid
          container
          columnSpacing={2}
          alignItems='center'
          sx={{
            borderTop: '2px solid #ddd',
            p: '1rem 0rem .5rem 0rem'
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant='h1'
              sx={{ fontSize: '20px', fontWeight: 600, mb: '1rem' }}
            >
              {showUserSpecificMetrics
                ? `${userName(search.clientId, users)}'s`
                : 'Global'}
              &nbsp; Averages
            </Typography>
          </Grid>
          {averagesMetricsMap.map(avgItem => (
            <Grid key={avgItem.label} item xs={2}>
              <Paper>
                <Typography sx={style.bigAverageNumber}>
                  {avgItem.label}
                </Typography>
                <Typography color='primary' sx={{ fontSize: '32px' }}>
                  {avgItem.metric}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}

      {showGlobalMetrics && <GroupView />}

      {showUserSpecificMetrics && <UserView clientId={search.clientId} />}

      <Popover
        disableScrollLock
        container={document.body}
        open={!!callbackRequestInPopup}
        onClose={() => setCallbackRequestInPopup(null)}
        PaperProps={{ sx: { maxWidth: '50%' } }}
      >
        <Box sx={{ padding: '1rem', maxHeight: '75vh', overflowY: 'auto' }}>
          {callbackRequestInPopup && (
            <Show
              isPopover
              callback={keysToCamel(
                callbackRequests.find(
                  request => request.id === callbackRequestInPopup
                )
              )}
            />
          )}
        </Box>

        <Box sx={style.callbackPopoverFooter}>
          <Link
            rel='noopener noreferrer'
            target='_blank'
            href={`/callback-requests/${callbackRequestInPopup}`}
            sx={{ '& svg': { height: '16px' } }}
          >
            <Button
              disableElevation
              sx={{ minWidth: '30px' }}
              variant='contained'
            >
              Open in new tab <Launch />
            </Button>
          </Link>
          <Button
            onClick={() => setCallbackRequestInPopup(null)}
            disableElevation
            sx={{ minWidth: '30px' }}
            variant='outlined'
          >
            Close
          </Button>
        </Box>
      </Popover>
    </Paper>
  )
}

const FilterBar = ({ totalCallRecords, users }) => {
  const { queryVariableStateData } = useContext(QueryContext)
  const {
    search,
    setSearch,
    limit,
    setLimit,
    filter,
    setFilter,
    setOrder
  } = queryVariableStateData
  const [anchorEl, setAnchorEl] = useState(null)
  const [editableFilter, setEditableFilter] = useState(filter)

  const handleClickUpdate = () => {
    setFilter(editableFilter)
  }

  return (
    <Grid container alignItems='center' sx={{ padding: '.5rem' }}>
      <Grid item xs={7} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ color: '#777' }}>
          Call Record Data &#8212;&nbsp;
        </Typography>
        <span style={{ fontSize: '16px' }}>
          Results based on {limit > totalCallRecords ? totalCallRecords : limit}{' '}
          records
          {filter.end_datetime && (
            <span style={style.filterChip}>
              {filter.end_datetime.ge &&
                DateTime.fromISO(filter.end_datetime.ge).toFormat('ccc DD tt')}
              &#8212;
              {filter.end_datetime.le &&
                DateTime.fromISO(filter.end_datetime.le).toFormat('ccc DD tt')}
            </span>
          )}
        </span>
      </Grid>
      <Grid
        item
        xs={5}
        sx={{ ml: 'auto', display: 'flex', justifyContent: 'flex-end' }}
      >
        <FormControl
          size='small'
          fullWidth
          sx={{ maxWidth: '140px', mr: '.5rem' }}
        >
          <InputLabel>Max results</InputLabel>
          <Select
            size='small'
            value={limit}
            onChange={e => e.target.value && setLimit(e.target.value)}
            label='max results'
          >
            <MenuItem
              onClick={e => {
                e.stopPropagation()
                setLimit(totalCallRecords)
              }}
            >
              See all
            </MenuItem>
            {[100, 200, 500, 750, 1000, 2500, 3000, 5000].map(item => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          size='small'
          fullWidth
          sx={{ maxWidth: '140px', mr: '.5rem' }}
        >
          <InputLabel>Select CSR</InputLabel>
          <Select
            size='small'
            value={search.clientId || ''}
            onChange={e =>
              e.target.value && setSearch({ clientId: e.target.value })}
            label='Select CSR'
          >
            <MenuItem
              onClick={e => {
                e.stopPropagation()
                setSearch({})
              }}
            >
              See all
            </MenuItem>
            {users
              .slice()
              .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
              .map(item => (
                <MenuItem value={item.twilioIdentity} key={item.id}>
                  {item.firstName} {item.lastName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <Button
          sx={{ maxWidth: '140px' }}
          onClick={e => setAnchorEl(e.target)}
          disableElevation
          variant='contained'
          placeholder='from'
        >
          Filter Dates
        </Button>

        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Popover
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            open={!!anchorEl}
          >
            <Grid container sx={{ padding: '1rem', minWidth: '450px' }}>
              <Grid item xs={6} sx={style.filterButtonsGroup}>
                {filterBarButtonPrettyNames.map(item => (
                  <Button
                    key={item}
                    disableElevation
                    sx={{ mb: '.5rem' }}
                    variant='outlined'
                    onClick={() =>
                      handleClickDateRangeButton(item, setEditableFilter)}
                  >
                    {item}
                  </Button>
                ))}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pl: '.5rem'
                }}
              >
                <DateTimePicker
                  inputFormat='MMM dd, yyyy hh:mm'
                  label='starting from'
                  maxDate={DateTime.fromJSDate(new Date())}
                  value={
                    (editableFilter.end_datetime &&
                      editableFilter.end_datetime.ge) ||
                    null
                  }
                  onChange={newValue =>
                    setEditableFilter({
                      end_datetime: {
                        ...editableFilter.end_datetime,
                        ge: newValue ? newValue.toISO() : null
                      }
                    })}
                  renderInput={startProps => <TextField {...startProps} />}
                />

                <DateTimePicker
                  inputFormat='MMM dd, yyyy hh:mm'
                  label='ending on'
                  maxDate={DateTime.fromJSDate(new Date())}
                  value={
                    (editableFilter.end_datetime &&
                      editableFilter.end_datetime.le) ||
                    null
                  }
                  onChange={newValue =>
                    setEditableFilter({
                      end_datetime: {
                        ...editableFilter.end_datetime,
                        le: newValue ? newValue.toISO() : null
                      }
                    })}
                  renderInput={startProps => (
                    <TextField sx={{ mt: '1rem' }} {...startProps} />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: '1rem'
                }}
              >
                <Button
                  disableElevation
                  sx={{ minWidth: '200px' }}
                  variant='contained'
                  onClick={handleClickUpdate}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Popover>
        </LocalizationProvider>
        <Button
          onClick={() => {
            setSearch({})
            setFilter({})
            setOrder({ end_datetime: 'desc' })
          }}
          fullWidth
          sx={{ maxWidth: '100px' }}
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  )
}

const DataFetcher = () => {
  const [limit, setLimit] = useState(2500)
  const [search, setSearch] = useState(
    JSON.parse(localStorage.getItem('csrPhoneActivitySearch')) || {}
  )
  const [filter, setFilter] = useState({})
  const [order, setOrder] = useState({})
  const [page, setPage] = useState(0)
  const [callLogsPageCeiling, setCallLogsPageCeiling] = useState(page)
  const [callbackRequestsPage, setCallbackRequestsPage] = useState(0)
  const [
    callbackRequestsPageCeiling,
    setCallbackRequestsPageCeiling
  ] = useState(callbackRequestsPage)

  const [cursorCeiling, setCurrentCeiling] = useState('')

  useEffect(
    () =>
      localStorage.setItem('csrPhoneActivitySearch', JSON.stringify(search)),
    [search]
  )

  useEffect(() => {
    if (fetchMoreRecords && fetchMore) {
      fetchMoreRecords({
        variables: {
          search: search,
          order: { end_datetime: 'desc' },
          filter: filter,
          first: limit,
          after: ''
        }
      })
      fetchMore({
        variables: {
          filter: {
            created_at: {
              ge: (filter.end_datetime && filter.end_datetime.ge) || null,
              le: (filter.end_datetime && filter.end_datetime.le) || null
            }
          }
        }
      })
    }
  }, [filter, search, order, limit])

  const [getCallbacks, { loading, error, data, fetchMore }] = useLazyQuery(
    CALLBACK_REQUESTS,
    {
      notifyOnNetworkStatusChange: true,
      networkPolicy: 'network-only',
      variables: {
        search: {},
        order: {},
        filter: {},
        first: limit,
        after: ''
      }
    }
  )

  const [
    getRecords,
    {
      loading: loadingRecords,
      error: errorRecords,
      data: recordData,
      fetchMore: fetchMoreRecords
    }
  ] = useLazyQuery(CALL_RECORDS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      search: search,
      order: { end_datetime: 'desc' },
      filter: {},
      first: limit,
      after: ''
    }
  })

  const callbackRequestsStateData = {
    loading: loading,
    data: data,
    fetchMore: fetchMore,
    error: error
  }

  const callRecordsStateData = {
    loading: loadingRecords,
    data: recordData,
    fetchMore: fetchMoreRecords,
    error: errorRecords
  }

  const queryVariableStateData = {
    limit,
    setLimit,
    search,
    setSearch,
    order,
    setOrder,
    filter,
    setFilter,
    page,
    setPage,
    callLogsPageCeiling,
    setCallLogsPageCeiling,
    callbackRequestsPage,
    setCallbackRequestsPage,
    callbackRequestsPageCeiling,
    setCallbackRequestsPageCeiling,
    cursorCeiling,
    setCurrentCeiling,
    getData: () => {
      getCallbacks()
      getRecords()
    }
  }

  return (
    <QueryContext.Provider
      value={{
        callbackRequestsStateData: callbackRequestsStateData,
        callRecordsStateData: callRecordsStateData,
        queryVariableStateData: queryVariableStateData
      }}
    >
      <CSRActivity />
    </QueryContext.Provider>
  )
}

export default DataFetcher
