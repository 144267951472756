import { useContext } from 'react'
import { UserContext } from 'UserStore'
import { DateTime } from 'luxon'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import { Dollars } from 'tools'
import InsurancePaymentPhotoUploader from './InsurancePaymentPhotoUploader'

const PaymentsTableV2 = ({
  quote,
  handleClickUnlinkSquarePayment,
  handleClickDeleteCashPayment,
  handleClickDeleteInsurancePayment,
  handleClickDeleteWriteoffAmount,
  refetchQuote,
  handleClickProcessRefund,
}) => {
  const [user] = useContext(UserContext)
  const payments = quote.groupedPayments
  // const payments = quote.payments

  return (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell align='right'>type</TableCell>
            <TableCell align='right'>source</TableCell>
            <TableCell align='right'>amount before tip</TableCell>
            <TableCell align='right'>tip amount</TableCell>
            <TableCell align='right'>total amount</TableCell>
            <TableCell align='right'>processing fee</TableCell>
            <TableCell align='right'>refunded amount</TableCell>
            <TableCell align='right'>timestamps</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments
            .slice()
            .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
            .map(payment => (
              <TableRow key={payment.id}>
                <TableCell align='right'>
                  {payment.sourceType}
                  {user.roles.includes('admin') && payment.sourceType === 'cash' && (
                    <>
                      <br />
                      <button onClick={() => handleClickDeleteCashPayment(payment.id)}>delete</button>
                    </>
                  )}
                  {user.roles.includes('admin') && payment.sourceType === 'writeoff' && (
                    <>
                      <br />
                      <button onClick={() => handleClickDeleteWriteoffAmount(payment.id)}>delete</button>
                    </>
                  )}
                  {user.roles.includes('admin') && payment.sourceType === 'check' && (
                    <>
                      <br />
                      <button onClick={() => handleClickDeleteInsurancePayment(payment.id)}>delete</button>
                    </>
                  )}
                </TableCell>
                <TableCell align='right'>
                  {payment.origin}
                  {payment.sourceType === 'card' && (
                    <>
                      <br />
                      <a
                        target='_blank'
                        href={`https://squareup.com/dashboard/sales/transactions/${payment.squarePaymentId}`}
                        rel='noreferrer'
                      >
                        {payment.squarePaymentId}
                      </a>
                      {user.roles.includes('admin') && (
                        <>
                          <button onClick={() => handleClickUnlinkSquarePayment(payment.id)}>unlink</button>
                        </>
                      )}
                    </>
                  )}

                  {payment.sourceType === 'check' && (
                    <>
                      <br />
                      <a
                        target='_blank'
                        href={`https://squareup.com/dashboard/sales/transactions/${payment.squarePaymentId}`}
                        rel='noreferrer'
                      >
                        {payment.squarePaymentId}
                      </a>
                      {user.roles.includes('admin') && (
                        <>
                          <InsurancePaymentPhotoUploader payment={payment} refetchQuote={refetchQuote} />
                        </>
                      )}
                    </>
                  )}

                  {payment.sourceType === 'wallet' && (
                    <>
                      <br />
                      <a
                        target='_blank'
                        href={`https://squareup.com/dashboard/sales/transactions/${payment.squarePaymentId}`}
                        rel='noreferrer'
                      >
                        {payment.squarePaymentId}
                      </a>
                      {user.roles.includes('admin') && (
                        <>
                          <button onClick={() => handleClickUnlinkSquarePayment(payment.id)}>unlink</button>
                        </>
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell align='right'>
                  <Dollars value={payment.amountBeforeTip} />
                </TableCell>
                <TableCell align='right'>
                  <Dollars value={payment.tipAmount} />
                </TableCell>
                <TableCell align='right'>
                  <Dollars value={payment.totalAmount} />
                </TableCell>
                <TableCell align='right'>
                  <Dollars value={payment.processingFeeAmount} />
                </TableCell>
                <TableCell align='right'>
                  <Dollars value={payment.refundedAmount} />
                  {/* {user.roles.includes('admin') && (
                    <button
                      disabled={payment.refundedAmount !== 0}
                      onClick={() => handleClickProcessRefund(payment.id)}
                    >
                      {payment.refundedAmount === 0 ? 'process refund' : 'refunded'}
                    </button>
                  )} */}
                </TableCell>
                <TableCell align='right'>
                  <div>created: {DateTime.fromISO(payment.createdAt).toLocaleString(DateTime.DATETIME_MED)}</div>
                  {payment.createdAt !== payment.updatedAt && (
                    <div>updated: {DateTime.fromISO(payment.updatedAt).toLocaleString(DateTime.DATETIME_MED)}</div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          <TableRow style={{ borderTop: '2px solid gray' }}>
            <TableCell colSpan={2} align='center'>
              <b>totals:</b>
            </TableCell>
            <TableCell align='right'>
              <b>
                <Dollars
                  value={payments.reduce((acc, payment) => acc + Number(payment.amountBeforeTip), 0).toFixed(2)}
                />
              </b>
            </TableCell>
            <TableCell align='right'>
              <b>
                <Dollars value={payments.reduce((acc, payment) => acc + Number(payment.tipAmount), 0).toFixed(2)} />
              </b>
            </TableCell>
            <TableCell align='right'>
              <b>
                <Dollars value={payments.reduce((acc, payment) => acc + Number(payment.totalAmount), 0).toFixed(2)} />
              </b>
            </TableCell>
            <TableCell align='right'>
              <b>
                <Dollars
                  value={payments.reduce((acc, payment) => acc + Number(payment.processingFeeAmount), 0).toFixed(2)}
                />
              </b>
            </TableCell>
            <TableCell align='right'>
              <b>
                <Dollars
                  value={payments.reduce((acc, payment) => acc + Number(payment.refundedAmount), 0).toFixed(2)}
                />
              </b>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PaymentsTableV2
