import { Box, Tabs, Tab, Typography, Button } from '@mui/material'
import { AllInvoices } from './AllInvoices'
import { UnAccepted } from './PendingReview'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { InProgress } from './InProgress'
import { WorkOrders } from '../WorkOrders/WorkOrders'
import { AcceptedNotPaid } from './ReadyForPayroll'
import { useHistory } from 'react-router-dom'

const Index = () => {
  const [tab, setTab] = useLocalStorage('b2bPageTab', 'home')

  const history = useHistory()

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ padding: '0rem 1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='h4' sx={{ mt: '1rem', mb: '1rem' }}>
          B2B Invoices
        </Typography>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)}>
          <Tab value='unaccepted' label='Pending Review' />
          <Tab value='accepted-not-paid' label='Ready For Payroll' />
          <Tab value='all' label='all' />
          <Tab value='in-progress' label='in-progress' />
          <Button onClick={() => history.push('/b-to-b/payments')} sx={{ ml: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Payments</Button>
        </Tabs>
      </Box>
      {tab === 'accepted-not-paid' && <AcceptedNotPaid />}
      {tab === 'in-progress' && <InProgress />}
      {tab === 'unaccepted' && <UnAccepted />}
      {tab === 'workOrders' && <WorkOrders />}
      {tab === 'all' && <AllInvoices />}
    </Box>
  )
}

export default Index
