import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from 'UserStore'
import { withStyles } from '@mui/styles'
import { Switch, InputAdornment, TextField, Button, Dialog, Box, FormControlLabel } from '@mui/material'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import HomeRounded from '@mui/icons-material/HomeRounded'
import HomeWork from '@mui/icons-material/HomeWork'
import PhoneAndroid from '@mui/icons-material/PhoneAndroid'
import LocationCity from '@mui/icons-material/LocationCity'
import PinDropRounded from '@mui/icons-material/PinDropRounded'
import EmailRounded from '@mui/icons-material/EmailRounded'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import HttpIcon from '@mui/icons-material/Http'
import PaymentsIcon from '@mui/icons-material/Payments'

import { useFormik } from 'formik'
import ValidationSchema from '../PartVendors/ValidationScheme'

import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { fieldHasError, PhoneInput, fieldHelperText } from 'tools'

import StateSelect from 'components/StateSelect'
import DialogTitle from 'components/DialogTitle'

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflowX: 'hidden',
    '& form': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
    },
    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
    },
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '90%',
    },
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions)

const NewPartVendorDialog = ({ isOpen, onClose, newVendorName, onVendorCreated }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)

  const onSubmit = data => {
    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_part_vendor`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data => {
        onVendorCreated(data.partVendor)
      })
      .catch(e => window.alert(e.message))
      .finally(() => setWaiting(false))
  }

  const formik = useFormik({
    initialValues: {
      userId: user.id,
      name: '',
      googleMapUrl: '',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      email: '',
      website: '',
      paymentNotes: '',
      hasTires: false,
      hasWindshields: false,
    },
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  })

  useEffect(() => {
    formik.setFieldValue('name', newVendorName)
  }, [newVendorName])

  const handleClose = (e, reason) => {
    e && e.stopPropagation()
    if (reason === 'backdropClick') return

    onClose()
  }

  const handleAddressPasted = e => {
    const str = e.clipboardData.getData('Text')
    if (str) {
      const split = str.split(', ')
      if (split.length === 3) {
        const stateZip = split[2].split(' ')

        formik.setValues({
          ...formik.values,
          addressLineOne: split[0],
          city: split[1],
          state: stateZip[0],
          zip: stateZip[1],
        })
      }
    }
  }

  const removeQueryString = e => {
    if (e.target.value) {
      formik.setFieldValue('website', e.target.value.split('?')[0], {
        shouldValidate: true,
      })
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setWaiting(false)
      formik.resetForm()
    }
  }, [isOpen])

  return (
    <div>
      <Dialog fullWidth maxWidth='md' onClose={handleClose} aria-labelledby='order-details' open={isOpen}>
        <DialogTitle id='order-details' onClose={handleClose}>
          Enter Merchant Details
        </DialogTitle>
        <DialogContent sx={{ padding: '24px' }} dividers>
          <Box mr={1}>
            <TextField
              required
              InputLabelProps={{
                shrink: true,
              }}
              name='name'
              label='Merchant Name'
              autoFocus
              value={formik.values.name}
              onChange={formik.handleChange}
              error={fieldHasError(formik, 'name')}
              helperText={fieldHelperText(formik, 'name')}
              disabled={waiting}
            />
            <TextField
              required
              InputProps={{
                type: 'url',
                startAdornment: (
                  <InputAdornment position='start'>
                    <HttpIcon color='action' />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                placeholder: 'https://google.com/maps/place/...',
              }}
              name='googleMapUrl'
              label='Google Maps URL'
              value={formik.values.googleMapUrl}
              onChange={formik.handleChange}
              error={fieldHasError(formik, 'googleMapUrl')}
              helperText={fieldHelperText(formik, 'googleMapUrl')}
              disabled={waiting}
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <ContentPasteIcon color='action' />
                  </InputAdornment>
                ),
              }}
              label='Paste Address From Google Maps Here To Autofill Address'
              name='paste-address'
              value=''
              onPaste={handleAddressPasted}
              disabled={waiting}
            />
            <TextField
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <HomeRounded color='action' />
                  </InputAdornment>
                ),
              }}
              name='addressLineOne'
              label='Address Line 1'
              variant='outlined'
              value={formik.values.addressLineOne}
              onChange={formik.handleChange}
              error={fieldHasError(formik, 'addressLineOne')}
              helperText={fieldHelperText(formik, 'addressLineOne')}
              disabled={waiting}
            />
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <HomeWork color='action' />
                  </InputAdornment>
                ),
              }}
              label='Address Line 2'
              name='addressLineTwo'
              variant='outlined'
              value={formik.values.addressLineTwo}
              onChange={formik.handleChange}
              disabled={waiting}
            />
            <TextField
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LocationCity color='action' />
                  </InputAdornment>
                ),
              }}
              name='city'
              label='City'
              variant='outlined'
              value={formik.values.city}
              onChange={formik.handleChange}
              error={fieldHasError(formik, 'city')}
              helperText={fieldHelperText(formik, 'city')}
              disabled={waiting}
            />
            <StateSelect
              required
              variant='outlined'
              name='state'
              showInputAdornment
              style={{ margin: '8px', width: '90%' }}
              value={formik.values.state}
              onChange={formik.handleChange}
              error={fieldHasError(formik, 'state')}
              helperText={fieldHelperText(formik, 'state')}
              disabled={waiting}
            />
            <TextField
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PinDropRounded color='action' />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: '5',
              }}
              name='zip'
              label='Zip'
              variant='outlined'
              value={formik.values.zip}
              onChange={formik.handleChange}
              error={fieldHasError(formik, 'zip')}
              helperText={fieldHelperText(formik, 'zip')}
              disabled={waiting}
            />
          </Box>
          <Box sx={{ alignItems: 'flex-start' }}>
            <TextField
              required
              InputProps={{
                inputComponent: PhoneInput,
                startAdornment: (
                  <InputAdornment position='start'>
                    <PhoneAndroid color='action' />
                  </InputAdornment>
                ),
              }}
              name='phone'
              label='Phone'
              variant='outlined'
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={fieldHasError(formik, 'phone')}
              helperText={fieldHelperText(formik, 'phone')}
              disabled={waiting}
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <EmailRounded color='action' />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                type: 'email',
              }}
              name='email'
              label='Email'
              variant='outlined'
              value={formik.values.email}
              onChange={formik.handleChange}
              error={fieldHasError(formik, 'email')}
              helperText={fieldHelperText(formik, 'email')}
              disabled={waiting}
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <HttpIcon color='action' />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                type: 'url',
                placeholder: 'http://www.example.com',
              }}
              name='website'
              label='Merchant Website'
              onPaste={removeQueryString}
              variant='outlined'
              value={formik.values.website}
              onChange={removeQueryString}
              error={fieldHasError(formik, 'website')}
              helperText={fieldHelperText(formik, 'website')}
              disabled={waiting}
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PaymentsIcon color='action' />
                  </InputAdornment>
                ),
              }}
              name='paymentNotes'
              label='Payment Notes'
              variant='outlined'
              value={formik.values.paymentNotes}
              onChange={formik.handleChange}
              disabled={waiting}
            />
            <FormControlLabel
              labelPlacement='bottom'
              label={
                formik.values.hasTires ? (
                  'This merchant sells tires'
                ) : (
                  <>
                    This merchant <b>DOES NOT</b> sell tires
                  </>
                )
              }
              control={
                <Switch
                  name='hasTires'
                  value={formik.values.hasTires}
                  onChange={formik.handleChange}
                  disabled={waiting}
                />
              }
            />
            <FormControlLabel
              labelPlacement='bottom'
              label={
                formik.values.hasWindshields ? (
                  'This merchant sells windshields'
                ) : (
                  <>
                    This merchant <b>DOES NOT</b> sell windshields
                  </>
                )
              }
              control={
                <Switch
                  name='hasWindshields'
                  value={formik.values.hasWindshields}
                  onChange={formik.handleChange}
                  disabled={waiting}
                />
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' disabled={waiting} onClick={formik.handleSubmit}>
            {!waiting ? 'Submit' : 'Saving'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default NewPartVendorDialog
