import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { Dollars } from 'tools'

export const PanelBasedInvoices = ({ invoices }: any) => {
  const totalPayoutAmount = invoices.reduce((acc: any, invoice: any) => acc + invoice.payoutData.totalPayout, 0)
  const totalGrandTotal = invoices.reduce((acc: any, invoice: any) => acc + invoice.grandTotal || 0, 0)
  const totalPoints = invoices.reduce((acc: any, invoice: any) => acc + invoice?.payoutData?.totalPoints || 0, 0)

  return (
    <Table size='small' component={Paper}>
      <TableHead sx={{ fontSize: '12px !important' }}>
        <TableRow>
          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Completed At
          </TableCell>
          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Created At
          </TableCell>
          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Business
          </TableCell>
          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Vehicle
          </TableCell>
          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            CC fee
          </TableCell>

          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Total Points
          </TableCell>

          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Grand total
          </TableCell>

          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Total payout
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {invoices.map((invoice: any, index: number) => (
          <TableRow sx={{ background: index % 2 === 0 ? '#fff' : '#f9f9f9' }}>
            <TableCell align='right'>
              <Link key={invoice.id} target='_blank' to={`/b-to-b/invoices/${invoice.id}`}>
                {DateTime.fromISO(invoice.completedAt).toISODate()}
              </Link>
            </TableCell>
            <TableCell align='right'>
              <Link key={invoice.id} target='_blank' to={`/b-to-b/invoices/${invoice.id}`}>
                {DateTime.fromISO(invoice.createdAt).toISODate()}
              </Link>
            </TableCell>
            <TableCell align='right'>
              <Link key={invoice.dealer.id} target='_blank' to={`/b-to-b/dealers/${invoice.dealer.id}`}>
                {invoice.dealer.businessName}
              </Link>
            </TableCell>
            <TableCell align='right'>
              {invoice.year} {invoice.make} {invoice.model}
            </TableCell>
            <TableCell align='right'>
              <Dollars value={invoice.payoutData?.ccFee} />
            </TableCell>

            <TableCell align='right'>{invoice.payoutData?.totalPoints}</TableCell>

            <TableCell align='right'>
              <Dollars value={invoice.grandTotal} />
            </TableCell>


            <TableCell align='right'>
              <Dollars value={invoice.payoutData?.totalPayout} />
            </TableCell>
          </TableRow>
        ))}
        <TableRow sx={{ background: 'aliceblue', borderTop: '2px solid #a1cff7' }}>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell align='right'>{totalPoints} </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalGrandTotal} />
          </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalPayoutAmount} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export const RevenueBasedInvoices = ({ invoices }: any) => {
  const totalPayoutAmount = invoices.reduce((acc: any, invoice: any) => acc + invoice.payoutData.totalPayout, 0)
  const totalGrandTotal = invoices.reduce((acc: any, invoice: any) => acc + invoice.grandTotal || 0, 0)

  return (
    <Table size='small' component={Paper}>
      <TableHead sx={{ fontSize: '12px !important' }}>
        <TableRow>
          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Completed At
          </TableCell>
          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Created At
          </TableCell>
          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Business
          </TableCell>
          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Vehicle
          </TableCell>
          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            CC fee
          </TableCell>
          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Commission percent
          </TableCell>
          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Grand total
          </TableCell>
          <TableCell sx={{ fontSize: '12px !important' }} align='right'>
            Total payout
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {invoices.map((invoice: any, index: number) => (
          <TableRow sx={{ background: index % 2 === 0 ? '#fff' : '#f9f9f9' }}>
            <TableCell align='right'>
              <Link key={invoice.id} target='_blank' to={`/b-to-b/invoices/${invoice.id}`}>
                {DateTime.fromISO(invoice.completedAt).toISODate()}
              </Link>
            </TableCell>
            <TableCell align='right'>
              <Link key={invoice.id} target='_blank' to={`/b-to-b/invoices/${invoice.id}`}>
                {DateTime.fromISO(invoice.createdAt).toISODate()}
              </Link>
            </TableCell>
            <TableCell align='right'>
              <Link key={invoice.dealer.id} target='_blank' to={`/b-to-b/dealers/${invoice.dealer.id}`}>
                {invoice.dealer.businessName}
              </Link>
            </TableCell>
            <TableCell align='right'>
              {invoice.year} {invoice.make} {invoice.model}
            </TableCell>
            <TableCell align='right'>
              <Dollars value={invoice.payoutData?.ccFee} />
            </TableCell>

            <TableCell align='right'>{invoice.payoutData?.commissionPercent}</TableCell>
            <TableCell align='right'>
              <Dollars value={invoice.grandTotal} />
            </TableCell>
            <TableCell align='right'>
              <Dollars value={invoice.payoutData?.totalPayout} />
            </TableCell>
          </TableRow>
        ))}
        <TableRow sx={{ background: 'aliceblue', borderTop: '2px solid #a1cff7' }}>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />

          <TableCell />
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalGrandTotal} />
          </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalPayoutAmount} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
