import React from 'react'
import { DateTime } from 'luxon'
import { useQuery, gql} from '@apollo/client'
import TechNotes from 'components/TechNotes'
import { basicDateTimeFormat } from 'tools'

const JOBS_QUERY = gql`
  query jobs($quoteId: ID!) {
    jobs(quoteId: $quoteId) {
      id
      startDatetime
    }
  }
`

const TechNotesForJobsUnderQuote = ({ quote }) => {
  const { loading, error, data } = useQuery(JOBS_QUERY, {
    variables: { quoteId: quote.id }
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  if (!data.jobs) return null

  return data.jobs.map(job =>
    <TechNotes
      key={job.id}
      headerText={`job notes: ${DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat(basicDateTimeFormat)}`}
      noInput thisObjectOnly
      forceLinkTheParent
      jobId={job.id}
      parentType='job'
      parentId={job.id}
      quote={quote}
    />
  )
}

export default TechNotesForJobsUnderQuote
