import { Switch, Route, useRouteMatch } from 'react-router-dom'
import SmartFollowup from './SmartFollowups/Router'
import Index from './SmartFollowups/Index'

const SmartFollowupsRouter = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:smartFollowupId`}>
        <SmartFollowup />
      </Route>
      <Route path={`${path}/`}>
        <Index />
      </Route>
    </Switch>
  )
}

export default SmartFollowupsRouter
