import { useQuery, gql } from '@apollo/client'

const QUERY = gql`
  query UnpaidTimeOff($technicianId: ID, $unclaimed: Boolean) {
    unpaidTimeOffs(technicianId: $technicianId, unclaimed: $unclaimed) {
      id
      createdAt
      totalHours
      payoutId
      note
      deletedAt
      timeOffDate
      payout {
        id
        createdAt
      }
    }
  }
`

export const useTechnicianUnpaidTimeOff = ({ technicianId }) => {
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { technicianId: technicianId, unclaimed: true },
  })

  return { loading, error, data, unpaidTimeOff: data?.unpaidTimeOffs || [], refetch }
}
