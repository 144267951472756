import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

const SectionHeaderGrid = styled(Grid)(() => ({
  padding: '0.33em 1em',
  width: '100%',
  backgroundColor: 'aliceblue',
  color: '#3f51b5',
  fontWeight: 500,
}))

export default SectionHeaderGrid
