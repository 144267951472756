import { ChevronLeftRounded } from '@mui/icons-material'
import { AppBar, Box, Button, Dialog, Paper, Step, StepLabel, Stepper, Tab, Tabs, TextField } from '@mui/material'
import { createContext, useEffect, useState } from 'react'
import { BToCContractors } from '../ResidentialContractor/ResidentialContractor'
import { ResidentialEmployees } from '../ResidentialEmployees/ResidentialEmployees'
import BToBContractors from '../B2BContractors/BToBContractorsV2'
import PendingPayouts from '../Review/PendingPayouts'
import BToBEmployees from '../B2BEmployees/BToBEmployees'
import TimeCards from '../TimeCards/TimeCards'
import { DateTime } from 'luxon'
import { useLocalStorage } from 'hooks/useLocalStorage'

export const ConfigurationContext = createContext({
  // @ts-ignore
  startDatetime: DateTime.now().toISO().slice(0, 10),
  // @ts-ignore
  endDatetime: DateTime.now().toISO().slice(0, 10),
})

export const Configure = () => {
  const [startDatetime, setStartDatetime] = useLocalStorage(
    'payrollStart',
    // @ts-ignore
    DateTime.now().minus({ days: 10 }).startOf('day').toISO().slice(0, 10)
  )
  const [endDatetime, setEndDatetime] = useLocalStorage(
    'payrollEnd',
    DateTime.now().endOf('week').endOf('day').toISO()?.slice(0, 10)
  )

  const [tab, setTab] = useLocalStorage('newPayrollTab', 'configure', false)
  const [payrollOpen, setPayrollOpen] = useLocalStorage('payrollSessionOpen', 'true', false)

  const handleChange = (_: any, newValue: any) => {
    newValue && setTab(newValue)
  }

  useEffect(() => {
    setPayrollOpen(true)
    return () => setPayrollOpen(false)
  }, [])

  return (
    <Box sx={{ padding: '1rem 0rem' }}>
      <Box
        sx={{
          top: '111px',
          position: 'fixed',
          width: '100%',
          left: '0px',
          zIndex: 5,
          background: '#f0f8ffbf',
          backdropFilter: 'blur(12px)',
          borderBottom: '2px solid #a1cff7',
        }}
      >
        <Tabs value={tab} onChange={handleChange}>
          {/* <Tab value='configure' label='Configure' /> */}
          <Tab value='b2c-contractors' label='Contractor Quotes' />
          <Tab value='b2c-employees' label='Employee Quotes' />
          <Tab value='timecards' label='Time Cards' />
          <Tab value='b_to_b_invoices' label='B2B Contractors' />
          <Tab value='b2b-employees' label='B2B Employees' />
          <Tab value='pending_payouts' label='review' />
          {tab === 'timecards' && (
            <Box sx={{ display: 'flex', maxWidth: '500px', ml: 'auto', mt: '.25rem', mr: '.5rem' }}>
              <TextField
                hiddenLabel
                type='date'
                variant='filled'
                fullWidth
                size='small'
                value={startDatetime}
                onChange={(e: any) => setStartDatetime(e.target.value)}
                style={{ maxWidth: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />{' '}
              -{' '}
              <TextField
                hiddenLabel
                type='date'
                variant='filled'
                fullWidth
                size='small'
                value={endDatetime}
                onChange={(e: any) => setEndDatetime(e.target.value)}
                style={{ maxWidth: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          )}
        </Tabs>
      </Box>
      <Box sx={{ minHeight: '40px' }} />

      {/* <Button variant='contained' disableElevation onClick={() => setSessionOpen(true)}>
        Start payroll
      </Button> */}
      <ConfigurationContext.Provider value={{ startDatetime, endDatetime }}>
        {tab === 'b2c-contractors' && <BToCContractors />}
        {tab === 'b2c-employees' && <ResidentialEmployees />}
        {tab === 'timecards' && <TimeCards />}
        {tab === 'b_to_b_invoices' && <BToBContractors />}
        {tab === 'b2b-employees' && <BToBEmployees />}
        {tab === 'pending_payouts' && (
          <>
            <PendingPayouts />
          </>
        )}
      </ConfigurationContext.Provider>
    </Box>
  )
}
