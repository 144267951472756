import axios from 'axios'
import { DateTime } from 'luxon'
import { Collapse, Divider, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

const DatePicker = ({value, min, max, onChange}) => {
  return (
    <input
      type="date"
      value={value}
      min={min}
      max={max}
      onChange={onChange}
    />
  )
}

const WeatherForecast = ({showWeather, zip}) => {
  const BASE_URL = "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/"
  // const VISUALCROSSING_API_KEY = process.env.REACT_APP_VISUALCROSSING_API_KEY || '' // Hardcoded for testing purpose only
  const [ startDate, setStartDate ] = useState(DateTime.now().toISODate())
  const [ endDate, setEndDate ] = useState(DateTime.now().plus({days: 4}).toISODate())
  const [ forecast, setForecast ] = useState([])

  useEffect( () => {
    if(!showWeather || !zip) return

    axios({
      url: `${BASE_URL}${zip}/${startDate}/${endDate}?unitGroup=us&key=XBHXQLG2J6SEN2EPW3FSQZNSZ&contentType=json`,
    })
    .then((res) => {
      const forecastDays = res?.data?.days || []
      setForecast(forecastDays)
    })
    .catch((error) => {
      setForecast([])
      console.error(error)
    })
  }, [showWeather, zip, startDate, endDate])

  return (
    <Collapse in={showWeather} >
      <Stack direction="row" spacing={2} mt="1rem">
        <DatePicker
          value={startDate}
          min={DateTime.now().toISODate()}
          max={DateTime.now().plus({days: 14}).toISODate()}
          onChange={(event) => { setStartDate(event.target.value) }}
        />
        <DatePicker
          value={endDate}
          min={startDate}
          max={DateTime.now().plus({days: 14}).toISODate()}
          onChange={(event) => { setEndDate(event.target.value) }}
        />
      </Stack>

      <Stack direction="row" spacing={2} mt="1rem" sx={{ overflowX: 'scroll', width: 'fitContent', maxWidth: ' calc(100vw - 450px)' }} >
        {forecast.map((forecastDay, index) => (
          <>
            {(!!index) && <Divider orientation="vertical" flexItem  />}
            <Stack sx={{ whiteSpace: 'nowrap' }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {DateTime.fromISO(forecastDay?.datetime).toLocaleString(DateTime.DATE_HUGE)}
              </Typography>
              <Typography variant="body2">Condition: {forecastDay?.conditions}</Typography>
              <Typography variant="body2">Temperature F: {forecastDay?.tempmin} - {forecastDay?.tempmax}</Typography>
              <Typography variant="body2">Wind mph: {forecastDay?.windspeed}</Typography>
            </Stack>
          </>
          ))}
      </Stack>
    </Collapse>
  )
}

export default WeatherForecast