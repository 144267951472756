import { Button, TextField } from '@mui/material'
import { removeSearch } from 'BToB/Invoices/tools'
import { useState } from 'react'

export const VinSearchTextField = ({ search, field, setSearch }) => {
  const [value, setValue] = useState(search[field] ? search[field] : '')

  const handleClickApply = () => {
    if (value === '') {
      removeSearch({ field, setSearch, search })
    } else {
      setSearch({ ...search, [field]: value })
    }
  }
  const handleClearFilter = () => {
    removeSearch({ field, setSearch, search })
    setValue('')
  }

  const handleChange = e => {
    setValue(e.target.value)
  }

  return (
    <>
      <TextField value={value} onChange={handleChange} label={`${field} search`} size='small' />{' '}
      <Button onClick={handleClickApply}>Apply</Button>
      <Button onClick={handleClearFilter}>Cancel</Button>
    </>
  )
}
