import { Switch, Route, useRouteMatch } from 'react-router-dom'

import Index from './Index'
import TechnicianRouter from './Technician/Router'

const Router = ({ markets }) => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:technicianId`}>
        <TechnicianRouter />
      </Route>
      <Route path={`${path}`}>
        <Index markets={markets} />
      </Route>
    </Switch>
  )
}

export default Router
