import { useState, ChangeEvent } from 'react'
import { Box, Button, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { RangeSelectorProps, RangesSelectorProps } from './types'
import { localToUTCHours, UTCtoLocalHours } from '../../utils'

const RangeSelector = ({ range, onChange, onRemove }: RangeSelectorProps) => {
  const rangeArr = range.split('-')
  const start = isNaN(parseInt(rangeArr[0])) ? 9 : parseInt(rangeArr[0])
  const end = parseInt(rangeArr[1] ?? 16)

  if (isNaN(start) || isNaN(end)) return null

  const valueChange = (start: number, end: number) => {
    if (start !== 0 && !start) return
    if (end !== 0 && !end) return

    onChange(`${start}-${end}`)
  }

  const handleStartChange = (e: SelectChangeEvent<number>) => {
    const valueStr = e.target.value as string
    const value = parseInt(valueStr)
    if (value >= end) return alert('Start is greater than end hour')
    valueChange(value, end)
  }

  const handleEndChange = (e: SelectChangeEvent<number>) => {
    const valueStr = e.target.value as string
    const value = parseInt(valueStr)
    if (value <= start) return alert('End is less than start hour')
    valueChange(start, value)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        pb: 2,
      }}
    >
      <Select value={start} onChange={handleStartChange}>
        {Array(end ?? 24)
          .fill(0)
          .map((_, index) => (
            <MenuItem value={index}>{index}</MenuItem>
          ))}
      </Select>

      <Box sx={{ px: 2 }}>-</Box>

      <Select value={end} onChange={handleEndChange}>
        {Array(24 - start)
          .fill(0)
          .map((_, index) => (
            <MenuItem value={index + start + 1}>{index + start + 1}</MenuItem>
          ))}
      </Select>

      <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
        <Button onClick={onRemove} variant='contained' color='warning'>
          X
        </Button>
      </Box>
    </Box>
  )
}

const RangesSelector = ({ day, initialRanges, onChange }: RangesSelectorProps) => {
  const updateRanges = (newRanges: string[]) => {
    onChange(day, newRanges)
  }

  const addNewRange = () => {
    const newRanges = [...initialRanges]
    newRanges.push('9-16')
    updateRanges(newRanges)
  }

  return (
    <Box>
      {initialRanges.map((range, index) => {
        const onChange = (value: string) => {
          const newRanges = [...initialRanges]
          newRanges[index] = value
          updateRanges(newRanges)
        }

        const onRemove = () => {
          const newRanges = [...initialRanges]
          newRanges.splice(index, 1)
          updateRanges(newRanges)
        }

        return <RangeSelector range={range} onChange={onChange} onRemove={onRemove} />
      })}

      <Button onClick={addNewRange} variant='contained'>
        Add new range
      </Button>
    </Box>
  )
}

export default RangesSelector
