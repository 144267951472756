import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from 'GlobalStore'
import { useQuery, gql, useLazyQuery } from '@apollo/client'
import { makeStyles } from '@mui/styles'
import { DateTime } from 'luxon'
import { Link, useParams } from 'react-router-dom'
import { DistanceMatrixService, Marker, GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import Button from '@mui/material/Button'
import Geocode from 'react-geocode'
import { Box, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Grid,  } from '@mui/material'
import { Dollars, keysToCamel } from 'tools'
import { BToBJobCellWithDrivingDistance } from 'Schedule/components/BToBJobCell'
import { TravelDistanceFromLeadIdToCoordinates } from 'Leads/AvailabilityMap'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { AutoglassProcurementSections } from './components/AutoglassProcurementSection'
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
Geocode.setApiKey(GOOGLE_MAPS_API_KEY)

const MIN_ZOOM_LEVEL = 6

const TIRECONNECT_SUPPLIERS_AVAILABILITY = gql`
  query TireConnectSuppliersAvailability($partNumber: String!) {
    tireConnectSuppliersAvailability(partNumber: $partNumber) {
      zip
      state
      city
      phone
      addressLineOne
      businessName
      supplierName
      totalQuantity
      lat
      lng
      tireConnectSupplierId
    }
  }
`

const TECHNICIANS = gql`
  query ($filter: JSON) {
    technicians(filter: $filter) {
      id
      name
      email
      phone
      addressLineOne
      addressLineTwo
      city
      state
      zip
      lat
      lng
      note
      replacements
      availability
      offdays
      active
      note
    }
  }
`

const QUOTE_FOR_JOB_VALUE = gql`
  query getQuote($id: ID!) {
    quote(id: $id) {
      id
      postTaxGrandTotal
      balanceAmountDue
      preJobAmountDue
      payments
    }
  }
`
const JobValue = ({ quoteId }) => {
  const { loading, error, data } = useQuery(QUOTE_FOR_JOB_VALUE, {
    variables: { id: quoteId },
  })
  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return (
    <div style={{ cursor: 'pointer' }} onClick={() => window.open(`/quotes/${quoteId}`)}>
      labor: <Dollars value={data.quote.postTaxGrandTotal} />
    </div>
  )
}

const jobSummaryText = (dateString, job) => (
  <>
    {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)} -
    <br />
    {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)}
  </>
)

const TheMap = ({ assignedTechnician, technicians, lead, quote, leadCoordinates }) => {
  const [techsInBounds, setTechsInbounds] = useState([])
  const [map, setMap] = useState(null)
  const [suppliers, setSuppliers] = useState([])

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  })

  const handleOnIdle = () => {
    if (map.zoom > MIN_ZOOM_LEVEL) {
      const bounds = map?.getBounds()

      const newInBoundTechs = technicians.filter(
        technician =>
          Number(technician.lat) > bounds.getSouthWest().lat() &&
          Number(technician.lng) > bounds.getSouthWest().lng() &&
          Number(technician.lat) < bounds.getNorthEast().lat() &&
          Number(technician.lng) < bounds.getNorthEast().lng()
      )
      setTechsInbounds(newInBoundTechs)
    } else {
      setTechsInbounds([])
    }
  }

  const onLoad = map => setMap(map)
  const onUnmount = map => setMap(null)

  if (!isLoaded) return <div>Loading google map</div>

  const partsReadyForPunchout =
    quote?.parts?.reduce(
      (acc, item) =>
        acc.find(i => i?.tireConnectData?.id === item?.tireConnectData?.id)
          ? [
              ...acc.filter(i => i?.tireConnectData?.id !== item?.tireConnectData?.id),
              {
                ...item,
                quantity: quote.parts.filter(i => i?.tireConnectData?.id === item?.tireConnectData?.id).length,
                allPartIds: quote.parts
                  .filter(i => i?.tireConnectData?.id === item?.tireConnectData?.id)
                  .map(i => i?.id),
              },
            ]
          : [...acc, item],
      []
    ) || []

  const omegaParts =
    quote?.parts?.reduce(
      (acc, item) =>
        acc.find(i => i?.nagsGlassId === item?.nagsGlassId)
          ? [
              ...acc.filter(i => i?.nagsGlassId !== item?.nagsGlassId),
              {
                ...item,
                quantity: quote.parts.filter(i => i?.nagsGlassId === item?.nagsGlassId).length,
                allPartIds: quote.parts.filter(i => i?.nagsGlassId === item?.nagsGlassId).map(i => i?.id),
              },
            ]
          : [...acc, item],
      []
    ) || []

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={8}
          sx={{
            '& .technicianLabel': {
              background: '#650606f7',
              color: '#fff !important',
              padding: '.25rem .5rem',
              borderRadius: '20px',
            },
            '& .supplierLabel': {
              background: '#226e26f7',
              color: '#fff !important',
              padding: '.25rem .5rem',
              borderRadius: '20px',
            },
            '& .customerLabel': {
              background: '#222222f7',
              color: '#fff !important',
              padding: '.25rem',
              borderRadius: '20px',
            },
          }}
        >
          <GoogleMap
            zoom={10}
            mapContainerStyle={{
              height: window.innerHeight / 2.2,
              width: '100%',
            }}
            onIdle={handleOnIdle}
            center={leadCoordinates}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <Marker
              position={leadCoordinates}
              label={{
                text: `Customer: ${lead.name} (${lead.repairLocation})`,
                className: 'customerLabel',
              }}
              icon='https://driveway-production.s3.us-east-2.amazonaws.com/img/customer_map_marker.png'
            />

            {suppliers.map(supplier => {
              return (
                <Marker
                  key={`${supplier.supplierName}`}
                  label={{
                    text: `${supplier.totalQuantity} - ${supplier.supplierName} ${supplier.businessName} - ${supplier.addressLineOne}`,
                    className: 'supplierLabel',
                  }}
                  position={{ lat: Number(supplier.lat), lng: Number(supplier.lng) }}
                />
              )
            })}

            {techsInBounds.map(technician => (
              <Marker
                key={`${technician.id}`}
                label={{ text: technician.name, className: 'technicianLabel' }}
                position={{ lat: Number(technician.lat), lng: Number(technician.lng) }}
              />
            ))}
          </GoogleMap>
        </Grid>

        <Grid item xs={4}>
          {lead.addressLineOne?.length > 2 || 'Missing Lead address, using center of zip code'}
          {lead.addressLineOne?.length > 2 && (
            <>
              Have customer address, the map marker is accurate
              <br />
              {lead.addressLineOne}
              <br />
              {lead.city}, {lead.state} {lead.zip}
            </>
          )}

          {partsReadyForPunchout.map(part => (
            <TireProcurementSections
              key={part.id}
              assignedTechnician={assignedTechnician}
              leadCoordinates={leadCoordinates}
              quote={quote}
              suppliers={suppliers}
              part={part}
              setSuppliers={setSuppliers}
            />
          ))}

          {omegaParts.map(part => (
            <AutoglassProcurementSections
              key={part.id}
              quote={quote}
              part={part}
              zip={lead.zip}
            />
          ))}
        </Grid>
      </Grid>

      <TechniciansInBounds technicians={techsInBounds} leadCoordinates={leadCoordinates} quote={quote} lead={lead} />

      {techsInBounds.length === 0 && <div>zoom/pan the map to show technicians</div>}
      {map?.zoom <= MIN_ZOOM_LEVEL && <b style={{ color: 'red' }}>Too far, zoom in to see technicians</b>}
    </Box>
  )
}

export const TireProcurementSections = ({
  part,
  quote,
  suppliers,
  setSuppliers,
  leadCoordinates,
  assignedTechnician,
}) => {
  const [
    getTireConnectSuppliersAvailability,
    { loading: loadingSuppliers, error: suppliersError, data: suppliersData, refetch: refetchSuppliers },
  ] = useLazyQuery(TIRECONNECT_SUPPLIERS_AVAILABILITY, { variables: { partNumber: '' } })

  const [suppliersForPart, setSupplierForPart] = useState([])

  useEffect(() => {
    part?.tireConnectData &&
      getTireConnectSuppliersAvailability({
        variables: {
          partNumber: keysToCamel(part.tireConnectData)?.partNumber,
        },
      })
  }, [quote])

  useEffect(() => {
    suppliersData && setSuppliers(prev => [...prev, ...suppliersData?.tireConnectSuppliersAvailability])
    suppliersData && setSupplierForPart(suppliersData?.tireConnectSuppliersAvailability)
  }, [suppliersData])

  return (
    <>
      {loadingSuppliers && (
        <>
          {' '}
          <br />
          <br />
          loading supplier stock information...{' '}
        </>
      )}
      {suppliersForPart.length > 0 && <hr />}
      {suppliersForPart.length > 0 && (
        <b>
          Tire Availability: (
          {`${part.tireConnectData?.brand} - ${part.tireConnectData?.model}  - ${part.tireConnectData?.size}`})
        </b>
      )}

      {suppliersForPart.map(supplier => {
        const selected = supplier.tireConnectSupplierId === keysToCamel(part.tireConnectData)?.tireConnectSupplierId

        return (
          <TireSupplierItem
            key={supplier.addressLineOne}
            leadCoordinates={leadCoordinates}
            selected={selected}
            supplier={supplier}
            technician={assignedTechnician}
            quote={quote}
            part={part}
          />
        )
      })}
    </>
  )
}

export const TireSupplierItem = ({ quote, technician, leadCoordinates, supplier, selected, part }) => {
  const [myTravelDistance, setMyTravelDistance] = useState()
  const [techTravelDistance, setTechTravelDistance] = useState()
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const handleSwapSupplier = () =>
    window.confirm('are you sure you want to swap this supplier?') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/change_tire_supplier_for_tire`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        tireConnectSupplierId: supplier.tireConnectSupplierId,
        partIds: quote?.parts.filter(p => p?.tireConnectData?.id === part?.tireConnectData?.id).map(i => i.id),
        userId: user.id,
      }),
    })

  const handleTravelDistanceResult = result => {
    const deepResult = result.rows[0].elements[0]
    setMyTravelDistance({
      distanceValue: deepResult.distance?.value,
      distanceString: deepResult.distance?.text,
      durationString: deepResult.duration?.text,
    })
  }

  const handleTechnicianTravelDistanceResult = result => {
    const deepResult = result.rows[0].elements[0]
    setTechTravelDistance({
      distanceValue: deepResult.distance?.value,
      distanceString: deepResult.distance?.text,
      durationString: deepResult.duration?.text,
    })
  }

  return (
    <Box sx={[{ padding: '.25rem', borderBottom: '1px solid #f1f1f1' }, selected && { background: 'palegreen' }]}>
      {!myTravelDistance && (
        <DistanceMatrixService
          options={{
            unitSystem: window.google.maps.UnitSystem.IMPERIAL,
            travelMode: 'DRIVING',
            destinations: [{ lat: Number(supplier.lat), lng: Number(supplier.lng) }],
            origins: [leadCoordinates],
          }}
          callback={handleTravelDistanceResult}
        />
      )}

      {!techTravelDistance && technician && (
        <DistanceMatrixService
          options={{
            unitSystem: window.google.maps.UnitSystem.IMPERIAL,
            travelMode: 'DRIVING',
            destinations: [{ lat: Number(supplier.lat), lng: Number(supplier.lng) }],
            origins: [{ lat: Number(technician.lat), lng: Number(technician.lng) }],
          }}
          callback={handleTechnicianTravelDistanceResult}
        />
      )}

      <Box sx={{ display: 'flex' }}>
        <Box
          sx={[
            {
              color: '#fff',
              borderRadius: '20px',
              fontSize: '14px',
              fontWeight: 500,
              background: '#226e26',
              mr: '.5rem',
              padding: '.25rem',
              width: '28px',
              height: '28px',
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          ]}
        >
          {supplier.totalQuantity}
        </Box>{' '}
        <Box>
          {supplier.supplierName} {supplier.businessName} {supplier.addressLineOne}
          <br />
          <b>{`Distance to customer: ${myTravelDistance?.distanceString}, Time: ${myTravelDistance?.durationString}`}</b>
          <br />
          <b>{`Distance to technician: ${techTravelDistance?.distanceString}, Time: ${techTravelDistance?.durationString}`}</b>
          &nbsp;
          <br />
          {!selected && (
            <button disabled={part.orderPlacedAt} onClick={handleSwapSupplier}>
              {' '}
              use this supplier instead{' '}
            </button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const TechniciansInBoundsStyles = makeStyles(theme => ({
  techSection: {
    border: '1px solid #c9c9c9',
    marginTop: '1em',
    backgroundColor: '#f9f9f9ba',
  },
}))
const TechniciansInBounds = ({ technicians, leadCoordinates, quote, lead }) => {
  const classes = TechniciansInBoundsStyles()
  const [travelDistances, setTravelDistances] = useState([])

  const receiveTravelDistance = travelDistance => {
    if (travelDistances.find(distance => distance.technicianId === travelDistance.technicianId)) return

    setTravelDistances(prev => [travelDistance, ...prev])
  }

  const prefetchedForTech = technician => travelDistances.find(result => result.technicianId === technician.id)

  return (
    <>
      {technicians
        .sort(
          (a, b) =>
            ((travelDistances.find(e => e.technicianId === a.id) &&
              travelDistances.find(e => e.technicianId === a.id).distanceValue) ||
              0) -
            ((travelDistances.find(e => e.technicianId === b.id) &&
              travelDistances.find(e => e.technicianId === b.id).distanceValue) ||
              0)
        )
        .map(technician => (
          <div className={classes.techSection} key={technician.id}>
            <TechSchedule
              prefetchedTravelDistance={prefetchedForTech(technician)}
              leadCoordinates={leadCoordinates}
              sendMyTravelDistanceUp={receiveTravelDistance}
              key={technician.id}
              lead={lead}
              technician={technician}
            />
          </div>
        ))}
    </>
  )
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '95%',
  width: '95%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
}

const AvailabilityMap = ({ assignedTechnician, forTechnicianIdOnly, lead, technicians, quote }) => {
  const [global] = useContext(GlobalContext)
  const [showModal, setShowModal] = useState(false)

  const { loading, error, data } = useQuery(TECHNICIANS, {
    variables: {
      filter: { active: true },
    },
  })
  const [leadCoordinates, setLeadCoordinates] = useState({ lat: null, lng: null })

  useEffect(() => {
    if (lead.addressLineOne) {
      Geocode.fromAddress(`${lead.addressLineOne}, ${lead.city}, ${lead.state}`).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          setLeadCoordinates({ lat, lng })
        },
        error => {
          console.error(error)
        }
      )
    } else {
      Geocode.fromAddress(`${lead.zip}`).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          setLeadCoordinates({ lat, lng })
        },
        error => {
          console.error(error)
        }
      )
    }
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const maybeFilteredTechnicians =
    (forTechnicianIdOnly && technicians.filter(tech => tech.id === forTechnicianIdOnly)) || technicians

  const onlyForTech = technicians.find(tech => tech.id === forTechnicianIdOnly)

  return (
    <>
      <Button size='small' color='secondary' variant='outlined' onClick={() => setShowModal(!showModal)}>
        qualified technicians map/availability
      </Button>

      {showModal && (
        <Box>
          {forTechnicianIdOnly && (
            <b style={{ color: 'red', margin: '1em' }}>
              Only one technician per Quote, showing ONLY: {onlyForTech && onlyForTech.name}
            </b>
          )}

          {(!leadCoordinates.lat || !leadCoordinates.lng) && <div>no lead coordinates</div>}

          {leadCoordinates.lat && leadCoordinates.lng && (
            <TheMap
              quote={quote}
              technicians={maybeFilteredTechnicians}
              lead={lead}
              leadCoordinates={leadCoordinates}
              assignedTechnician={assignedTechnician}
            />
          )}
        </Box>
      )}
    </>
  )
}

const techScheduleStyles = makeStyles(theme => ({
  modalContent: {
    position: 'absolute',
    width: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  scheduleCell: {
    padding: '5px',
    verticalAlign: 'top',
    width: '120px',
    minWidth: '120px',
    borderLeft: '1px solid lightgray',
    borderRight: '1px solid lightgray',
    '&.sunday': {
      border: '2px solid gray',
      borderTop: 0,
      borderRight: 0,
    },
    '&.saturday': {
      border: '2px solid gray',
      borderTop: 0,
      borderLeft: 0,
    },
  },
  job: {
    marginTop: '3px',
    fontSize: 13,
    backgroundColor: 'aliceblue',
    padding: '0px 3px',
    borderRadius: '2px',
    border: '1px solid lavender',
  },
  tech_header_cell: {
    padding: '8px',
    lineHeight: '1em',
  },
}))
const availabilityRegex = /(\d+)-(\d+)/
const formattedAvailability = arr =>
  arr
    .map(rangeString => {
      const match = rangeString.match(availabilityRegex)
      const startDT = DateTime.fromObject({ zone: 'utc', hour: Number(match[1]) })
      const endDT = DateTime.fromObject({ zone: 'utc', hour: Number(match[2]) })
      return `${startDT.toFormat('ha')} - ${endDT.toFormat('ha')}`
    })
    .join(', ')

const GET_JOBS = gql`
  query getJobs($technicianId: ID!, $startDatetime: String!, $endDatetime: String!) {
    jobs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      startDatetime
      endDatetime
      leadId
      quoteId
      heldForReschedulingAt
    }
  }
`
const GET_APPOINTMENTS = gql`
  query getJobs($technicianId: ID!, $startDatetime: String!, $endDatetime: String!) {
    appointments(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      startDatetime
      endDatetime
      leadId
      quoteId
    }
  }
`

const BTOB_JOBS_QUERY = gql`
  query BToBJobs($technicianId: String, $startDatetime: String, $endDatetime: String) {
    bToBJobs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      createdAt
      startDatetime
      endDatetime
      updatedAt
      dealer {
        id
        businessName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        primaryContact {
          firstName
          lastName
          email
        }
      }
      technician {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
`

const JOBS_SCHEDULERS_QUERY = gql`
  query BToBJobSchedulers($technicianId: String, $recurrenceStartsAt: String!, $recurrenceEndsAt: String!) {
    bToBJobSchedulers(
      technicianId: $technicianId
      recurrenceStartsAt: $recurrenceStartsAt
      recurrenceEndsAt: $recurrenceEndsAt
    ) {
      id
      createdAt
      startDatetime
      endDatetime
      recurrencePeriod
      recurrencePattern
      recurrenceStartsAt
      recurrenceEndsAt
      excludedDays
      dealer {
        id
        businessName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        primaryContact {
          firstName
          lastName
          email
        }
      }
      technician {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`

const TechSchedule = ({ lead, technician, prefetchedTravelDistance, sendMyTravelDistanceUp, leadCoordinates }) => {
  const classes = techScheduleStyles()
  const [daysToShow, setDaysToShow] = useState(14)
  const { appointmentId } = useParams()
  const [ignoreTechnicianAvailability, setIgnoreTechnicianAvailability] = useState(false)

  const [myTravelDistance, setMyTravelDistance] = useState(prefetchedTravelDistance)

  const [showDrivingDistanceDays, setShowDrivingDistanceDays] = useState(0)

  const handleTravelDistanceResult = result => {
    const deepResult = result.rows[0].elements[0]
    setMyTravelDistance({
      technicianId: technician.id,
      distanceValue: deepResult.distance.value,
      distanceString: deepResult.distance.text,
      durationString: deepResult.duration.text,
    })
  }

  useEffect(() => {
    if (prefetchedTravelDistance) return
    if (!myTravelDistance) return
    sendMyTravelDistanceUp(myTravelDistance)
  }, [myTravelDistance])

  const beginningOfUtcDay = DateTime.local().setZone('utc').startOf('day')
  const { loading, error, data, refetch } = useQuery(GET_JOBS, {
    variables: {
      technicianId: technician.id,
      startDatetime: beginningOfUtcDay.toISO(),
      endDatetime: beginningOfUtcDay.plus({ days: daysToShow }).endOf('day').toISO(),
    },
  })

  const {
    loading: loadingAppts,
    error: errorAppts,
    data: apptsData,
    refetch: refetchAppts,
  } = useQuery(GET_APPOINTMENTS, {
    variables: {
      technicianId: technician.id,
      startDatetime: beginningOfUtcDay.toISO(),
      endDatetime: beginningOfUtcDay.plus({ days: daysToShow }).endOf('day').toISO(),
    },
  })

  const {
    error: b2bJobsError,
    loading: loadingB2BJobs,
    data: b2bJobsData,
  } = useQuery(BTOB_JOBS_QUERY, {
    variables: {
      technicianId: technician.id,
      startDatetime: beginningOfUtcDay.toISO(),
      endDatetime: beginningOfUtcDay.plus({ days: daysToShow }).endOf('day').toISO(),
    },
  })

  const {
    error: b2bJobSchedulersError,
    loading: loadingB2BJobSchedulers,
    data: b2bJobSchedulersData,
  } = useQuery(JOBS_SCHEDULERS_QUERY, {
    variables: {
      technicianId: technician.id,
      recurrenceEndsAt: DateTime.now().endOf('day'),
      recurrenceStartsAt: DateTime.now().endOf('month').endOf('week').endOf('day'),
    },
  })

  useEffect(() => {
    data && refetch && refetch()
  }, [])

  if (loading || loadingAppts || loadingB2BJobs || loadingB2BJobSchedulers) return <div>LOADING...</div>
  if (error || errorAppts || b2bJobSchedulersError || b2bJobsError) return <div>Error!</div>

  const jobs = data?.jobs || []
  const bToBJobs = b2bJobsData?.bToBJobs || []
  const bToBJobSchedulers = b2bJobSchedulersData?.bToBJobSchedulers || []

  const getSchedulersForDay = date => {
    const weeklySchedulersForDay = bToBJobSchedulers.filter(
      scheduler =>
        scheduler.recurrencePeriod === 'week' &&
        scheduler.recurrencePattern.includes(date.toFormat('cccc').toLowerCase()) &&
        DateTime.fromISO(scheduler.recurrenceStartsAt) <= date.endOf('day') &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !jobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(scheduler.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        )
    )

    const monthlySchedulersForDay = bToBJobSchedulers.filter(
      scheduler =>
        scheduler.recurrencePeriod === 'month' &&
        scheduler.recurrencePattern.includes(date.toFormat('dd')) &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceStartsAt && DateTime.fromISO(scheduler.recurrenceStartsAt) >= date.endOf('month')
          ? false
          : true) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !jobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(scheduler.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        )
    )

    const yearlySchedulersForDay = bToBJobSchedulers.filter(
      scheduler =>
        scheduler.recurrencePeriod === 'year' &&
        scheduler.recurrencePattern.includes(date.toFormat('MM-dd')) &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceStartsAt && DateTime.fromISO(scheduler.recurrenceStartsAt) >= date.endOf('month')
          ? false
          : true) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !jobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(scheduler.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        )
    )

    return weeklySchedulersForDay.concat(monthlySchedulersForDay).concat(yearlySchedulersForDay)
  }

  const populatedSchedule = [...Array(daysToShow).keys()].map(i => {
    const thisDay = DateTime.local()
      .setZone('utc')
      .startOf('day')
      .plus({ days: i - 1 })
    const dateString = thisDay.toISODate()

    const jobsOnThisDay = data.jobs
      .filter(job => DateTime.fromISO(job.startDatetime, { zone: 'utc' }).hasSame(thisDay, 'day'))
      .map(job => ({ ...job, type: 'job' }))

    const apptsOnThisDay = apptsData.appointments
      .filter(job => DateTime.fromISO(job.startDatetime, { zone: 'utc' }).hasSame(thisDay, 'day'))
      .map(appt => ({ ...appt, type: 'appointment' }))

    const bToBJobsOnThisDay =
      bToBJobs
        .filter(job => DateTime.fromISO(job.startDatetime, { zone: 'utc' }).hasSame(thisDay, 'day'))
        .map(appt => ({ ...appt, type: 'bToBJob' })) || []

    const bToBJobSchedulerssOnThisDay = getSchedulersForDay(thisDay)

    return {
      dateString: dateString,
      jobs: jobsOnThisDay.concat(apptsOnThisDay).sort((a, b) => (a.startDatetime > b.startDatetime ? 1 : -1)),
      bToBJobs: bToBJobsOnThisDay,
      bToBJobSchedulers: bToBJobSchedulerssOnThisDay,
    }
  })

  const formattedStringForAvailRange = string => {
    const match = string.match(/(\d+)-(\d+)/)
    const startHour = DateTime.fromObject({ hour: match[1] }).toFormat('ha')
    const endHour = DateTime.fromObject({ hour: match[2] }).toFormat('ha')
    return `${startHour}- ${endHour}`
  }

  const handleClickShowTwoMoreWeeks = () => {
    setDaysToShow(daysToShow + 14)
  }

  const chunk = (arr, size) =>
    arr.reduce((acc, _, i) => {
      if (i % size === 0) acc.push(arr.slice(i, i + size))
      return acc
    }, [])

  const chunkedPopulatedSchedule = chunk(populatedSchedule, 14)

  const requestedOff = dateString => technician.offdays.includes(dateString)

  const offDay = dateString =>
    technician.availability[DateTime.fromISO(dateString).toFormat('EEEE').toLowerCase()].length === 0

  return (
    <>
      {!myTravelDistance && (
        <DistanceMatrixService
          options={{
            unitSystem: window.google.maps.UnitSystem.IMPERIAL,
            travelMode: 'DRIVING',
            destinations: [leadCoordinates],
            origins: [{ lat: Number(technician.lat), lng: Number(technician.lng) }],
          }}
          callback={handleTravelDistanceResult}
        />
      )}

      <Box sx={{ pl: 1 }}>
        <Grid container sx={{ p: 1 }}>
          <Grid item xs={7}>
            <Link to={`/technicians/${technician.id}`}>
              <b>{technician.name}</b>
            </Link>
            &nbsp; (Travel {`Distance: ${myTravelDistance?.distanceString}, Time: ${myTravelDistance?.durationString}`})
            &nbsp;
            <Button size='small' variant='outlined' onClick={handleClickShowTwoMoreWeeks}>
              + 2 weeks
            </Button>
            &nbsp;
            <Button
              size='small'
              variant='outlined'
              onClick={() => setShowDrivingDistanceDays(showDrivingDistanceDays + 14)}
            >
              driving distances ({showDrivingDistanceDays + 14} days)
            </Button>
          </Grid>

          <Grid item xs={5} sx={{ pt: 1, fontSize: '13px' }}>
            {Object.entries(technician.availability).map(
              (el, i) =>
                el[1].length > 0 &&
                el[1][0] !== '' && (
                  <>
                    <b>{el[0]}:</b>{' '}
                    {el[1].map((range, i) => (
                      <>{formattedStringForAvailRange(range)},&nbsp;</>
                    ))}
                  </>
                )
            )}
          </Grid>
        </Grid>
      </Box>

      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell colSpan={14}>
                {technician.note && technician.note.length > 0 && (
                  <>
                    Notes: <span style={{ color: '#3f51b5', fontWeight: 'bold' }}>{technician.note}</span>
                  </>
                )}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {chunkedPopulatedSchedule.map((chunk, chunkIndex) => (
              <TableRow key={chunkIndex}>
                {chunk.map((scheduleDay, scheduleDayIndex) => (
                  <TableCell
                    key={scheduleDay.dateString}
                    className={
                      classes.scheduleCell +
                      ' ' +
                      DateTime.fromISO(scheduleDay.dateString, { zone: 'utc' }).toFormat('cccc').toLowerCase()
                    }
                  >
                    <Box style={{ borderBottom: '1px solid rgb(203 203 203)' }}>
                      {DateTime.fromISO(scheduleDay.dateString, { zone: 'utc' }).toFormat('ccc, LLL d')}
                    </Box>

                    {scheduleDay.jobs.length > 0 &&
                      scheduleDay.jobs
                        .filter(job => !job.heldForReschedulingAt)
                        .map(job => (
                          <Box key={job.id}>
                            <Box
                              className={classes.job}
                              key={job.leadId}
                              sx={{ background: job.type === 'job' ? '' : '#e6ba6a !important' }}
                            >
                              {job.type === 'job' && <b>job</b>}
                              <b>
                                {job.type === 'appointment' &&
                                  (job.id === appointmentId ? 'THIS APPOINTMENT' : 'PENDING APPOINTMENT')}
                              </b>
                              <br />
                              <Link
                                target='_blank'
                                to={`/leads/${job.leadId}/quotes/${job.quoteId}/${job.type}s/${job.id}`}
                              >
                                {/* <VisitCount job={job} /> */}
                                {jobSummaryText(scheduleDay.dateString, job)}
                              </Link>

                              <JobValue quoteId={job.quoteId} />
                            </Box>
                            <Box>
                              {showDrivingDistanceDays > 0 &&
                                leadCoordinates?.lat &&
                                leadCoordinates?.lng &&
                                showDrivingDistanceDays / 14 >= chunkIndex + 1 && (
                                  <TravelDistanceFromLeadIdToCoordinates
                                    fromLeadId={job.leadId}
                                    toCoordinates={leadCoordinates}
                                  />
                                )}
                            </Box>
                          </Box>
                        ))}

                    {scheduleDay.bToBJobs.length > 0 &&
                      scheduleDay.bToBJobs
                        .slice()
                        .sort((a, b) =>
                          DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1
                        )
                        .map(job => (
                          <React.Fragment key={job.id}>
                            <BToBJobCellWithDrivingDistance
                              jobIsInPast={scheduleDay.dateTime < DateTime.now().startOf('day').minus({ days: 1 })}
                              key={job.id}
                              job={job}
                              chunkIndex={chunkIndex}
                              showDrivingDistanceDays={showDrivingDistanceDays}
                              leadCoordinates={leadCoordinates}
                            />
                          </React.Fragment>
                        ))}

                    {scheduleDay.bToBJobSchedulers.length > 0 &&
                      scheduleDay.bToBJobSchedulers
                        .slice()
                        .sort((a, b) =>
                          DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1
                        )
                        .map(job => (
                          <React.Fragment key={job.id}>
                            <BToBJobCellWithDrivingDistance
                              jobIsInPast={scheduleDay.dateTime < DateTime.now().startOf('day').minus({ days: 1 })}
                              key={job.id}
                              job={job}
                              chunkIndex={chunkIndex}
                              showDrivingDistanceDays={showDrivingDistanceDays}
                              leadCoordinates={leadCoordinates}
                            />
                          </React.Fragment>
                        ))}

                    <br />

                    {requestedOff(scheduleDay.dateString) && (
                      <span
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          border: '2px solid gray',
                          color: 'gray',
                          fontWeight: 'bold',
                        }}
                      >
                        REQUESTED OFF
                      </span>
                    )}

                    {offDay(scheduleDay.dateString) && (
                      <span
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          border: '2px solid gray',
                          color: 'gray',
                          fontWeight: 'bold',
                        }}
                      >
                        OFF DAY
                      </span>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default AvailabilityMap
