import { useParams, Redirect } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'

const JOB_QUERY = gql`
  query job($id: ID!) {
    job(id: $id) {
      quote { id }
      lead { id }
    }
  }
`

const Job = () => {
  const { jobId } = useParams()

  const { loading, error, data } = useQuery(JOB_QUERY, { variables: { id: jobId } })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return <Redirect to={`/leads/${data.job.lead.id}/quotes/${data.job.quote.id}/jobs/${jobId}`} />
}

export default Job
