import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { keysToCamel } from '../../../tools'
import ShowEdit from './ShowEdit'

const QUERY = gql`
  query bToBWorkOrder($id: ID!) {
    bToBWorkOrder(id: $id) {
      id
      createdAt
      businessName
      preJobPhotos
      year
      make
      makeShortName
      model
      modelShortName
      vin
      vinPhoto
      completedAt
      repairOrder
      stock
      deletedAt
      workOrderNumber
      purchasingCompany
      bToBInvoiceId
      technician {
        id
        firstName
        lastName
      }
      parts {
        id
        name
        number
        price
        cost
        type
      }
    }
  }
`

const Router = () => {
  const { path } = useRouteMatch()
  const { workOrderId } = useParams()

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { id: workOrderId },
  })

  if (loading) return 'Loading'
  if (error) return JSON.stringify(error)

  const workOrder = data?.bToBWorkOrder

  return (
    <Switch>
      <Route path={path}>
        <ShowEdit refetch={refetch} workOrder={keysToCamel(workOrder)} />
      </Route>
    </Switch>
  )
}

export default Router
