import { useContext, useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { UserContext } from 'UserStore'
import { Button, Collapse, MenuItem, Select, TextField } from '@mui/material'
import { gql, useQuery } from '@apollo/client'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const PRICING_CATEGORIES = gql`
  query bToBPanelGroups {
    bToBPanelGroups {
      id
      name
    }
  }
`

export const CreateNewLineItemPanelRow = ({ refetch }: { refetch: any }) => {
  const [user] = useContext(UserContext)
  const [panelName, setPanelName] = useState('')
  const [side, setSide] = useState('')
  const [panelGroup, setpanelGroup] = useState('')

  const bearerTokenHeaders = useBearerTokenHeaders()
  const showCreateButton = panelName !== '' && panelGroup !== ''

  const { loading, error, data } = useQuery(PRICING_CATEGORIES, {
    notifyOnNetworkStatusChange: true,
  })

  const handleCreateNewLineItem = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_panel`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        created_by_user_id: user.id,
        panel: panelName,
        side: side,
        panel_group_id: panelGroup,
      }),
    }).then(res => {
      if (res) {
        refetch()
        setSide('')
        setPanelName('')
        setpanelGroup('')
      } else {
      }
    })
  }

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const lineItems = data?.bToBPanelGroups || []

  return (
    <TableRow sx={{ background: '#f0fff1', position: 'sticky', top: '2.25rem', zIndex: 1 }}>
      <TableCell
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <TextField
          // @ts-ignore
          placeholder='Enter a new panel name ex. Bumper (Front)'
          fullWidth
          inputProps={{
            maxLength: 25,
          }}
          size='small'
          value={panelName}
          onChange={e => setPanelName(e.target.value)}
          disabled={!user.roles.includes('admin')}
        />
      </TableCell>
      <TableCell
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
          '& .MuiSelect-select': {
            padding: '.25rem .25rem .25rem .5rem',
          },
        }}
      >
        <Select
          fullWidth
          placeholder='select a category'
          size='small'
          value={side}
          onChange={e => setSide(e.target.value)}
          disabled={!user.roles.includes('admin')}
        >
          <MenuItem value=''>
            <i>None</i>
          </MenuItem>
          <MenuItem value='passenger'>Passenger</MenuItem>
          <MenuItem value='driver'>Driver</MenuItem>
        </Select>
      </TableCell>

      <TableCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
          '& .MuiSelect-select': {
            padding: '.25rem .25rem .25rem .5rem',
          },
        }}
      >
        <Select
          fullWidth
          placeholder='select a category'
          size='small'
          value={panelGroup}
          onChange={e => setpanelGroup(e.target.value)}
          disabled={!user.roles.includes('admin')}
        >
          <MenuItem value=''>
            <i>None</i>
          </MenuItem>
          {lineItems.map((item: any) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </Select>
        <Collapse sx={{ height: '32px ' }} in={showCreateButton} orientation='horizontal'>
          <Button onClick={handleCreateNewLineItem} sx={{ margin: '0rem .5rem' }} size='small' variant='contained'>
            Create
          </Button>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
