import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import PartRouter from './Part/Router'
import Index from './Index'

const Router = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:partId`}>
        <PartRouter />
      </Route>
      <Route path={`${path}/`}>
        <Index />
      </Route>
    </Switch>
  )
}

export default Router
