import { gql } from '@apollo/client'

export interface JobListing {
  id?: string
  jobTitle?: string
  city?: string
  state?: string
  description?: string
  salaryStart?: number
  salaryEnd?: number
  salaryPeriod?: string
  education?: string
  jobType?: string
  experience?: string
  company?: string
  country?: string
  zipCode?: string
  streetAddress?: string
  email?: string
  openPositions?: number
  createdAt?: string
  expiresAt?: string
  disabledAt?: string

  jobApplications?: {
    id?: string
    applicant?: {
      fullName?: string
    }
  }[]
}

export interface getAllJobListingsRes {
  jobListings?: JobListing[]
}

export const GET_JOB_LISTINGS = gql`
  query getAllJobListings {
    jobListings {
      id
      jobTitle
      city
      state
      description
      salaryStart
      salaryEnd
      salaryPeriod
      education
      jobType
      experience
      company
      country
      zipCode
      streetAddress
      email
      openPositions
      createdAt
      expiresAt
      disabledAt

      jobApplications {
        id
        applicant {
          fullName
        }
      }
    }
  }
`
