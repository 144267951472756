import { gql, useQuery } from '@apollo/client'
import { Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { removeSearch } from 'BToB/Invoices/tools'

const QUERY = gql`
  query AttributeValues($value: String!, $model: String!) {
    attributeValues(value: $value, model: $model)
  }
`

export const SearchTextField = ({ search, field, setSearch, model }) => {
  const { loading, error, data, fetchMore } = useQuery(QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      value: field,
      model: model,
    },
  })

  const values = data?.attributeValues || []
  const foundValue = Object.keys(search)?.find(v => v === field)
  const foundValueLabel = search[foundValue]

  const handleChange = e =>
    e.target.value === 'None'
      ? removeSearch({ field, setSearch, search })
      : setSearch({ ...search, [field]: e.target.value })

  return (
    <FormControl size='small' fullWidth>
      <InputLabel>{field}</InputLabel>
      <Select value={foundValueLabel || ''} fullWidth onChange={handleChange} size='small' label={field}>
        <MenuItem value='None'>
          <i>None</i>
        </MenuItem>
        <Divider />
        {values?.map(v => (
          <MenuItem key={v} value={v}>
            {v}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
