import React, { useState, useContext } from 'react'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import { DateTime } from 'luxon'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { sendMessage, useChannel, useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import Dropzone from 'react-dropzone-uploader'
import { MentionsInput, Mention } from 'react-mentions'
import mentionsClassNames from 'components/mentions.module.css'
import 'react-dropzone-uploader/dist/styles.css'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import Modal from '@mui/material/Modal'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Skeleton from '@mui/material/Skeleton'
import VisibilitySensor from 'react-visibility-sensor-v2'
import ChatPhotoUploader from './ChatPhotoUploader'

const groupsForMention = [
  // {id: 'parts', display: 'group:Parts'},
  // {id: 'sales', display: 'group:Sales'},
]

const Chat = ({ contextType, contextId, enableReadToggle }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [messages, setMessages] = useState([])
  const [global] = useContext(GlobalContext)
  const [user] = useContext(UserContext)
  const [body, setBody] = useState('')
  const [mentionedUserIds, setMentionedUserIds] = useState([])
  const [mentionedGroups, setMentionedGroups] = useState([])
  const [photoModalUrl, setPhotoModalUrl] = useState(null)

  const scrollToBottom = () => {
    const element = document.getElementById('chatMessageList')
    if (element) {
      element.scrollTop = element.scrollHeight - element.clientHeight
    }
  }

  const csrById = csrId => global.users.find(csr => csr.id === csrId)
  const csrName = csrId => {
    const csr = csrById(csrId)
    return `${csr.firstName} ${csr.lastName[0]}.`
  }

  const techById = techId => global.technicians.find(tech => tech.id === techId)
  const techName = techId => {
    const tech = techById(techId)
    return `${tech.firstName} ${tech.lastName[0]}.`
  }

  const authorName = message =>
    (message.authorType === 'csr' && csrName(message.authorId)) ||
    (message.authorType === 'tech' && techName(message.authorId))

  const mentionableUsers = [
    ...global.users
      .filter(user => Boolean(user.status === 'active'))
      .map(user => ({ id: user.id, display: `${user.firstName} ${user.lastName.charAt(0)}.` })),
    ...groupsForMention,
  ].sort((a, b) => (a.display > b.display ? 1 : -1))

  const originPath = (() => {
    switch (contextType) {
      case 'Technician':
        return `/technicians/${contextId}/chat`
      default:
        return null
    }
  })()

  const channel = useChannel(`chat:${contextType}$${contextId}`, undefined, (channel, { chatMessages }) => {
    setMessages(chatMessages.map((message, index) => ({ ...message, renderPhotos: index < 5 })).reverse())
    setTimeout(scrollToBottom, 300)
  })

  useEventHandler(channel, 'new_chat_message', ({ chatMessage }) => {
    setMessages(messages => [...messages, { ...chatMessage, renderPhotos: true }])
    setTimeout(scrollToBottom, 250)
  })
  useEventHandler(channel, 'chat_message_updated', ({ chatMessage }) => {
    setMessages(prev =>
      prev.map(thisMsg => (chatMessage.id === thisMsg.id ? { ...chatMessage, renderPhotos: true } : thisMsg))
    )
  })

  const userById = userId => global.users.find(user => user.id === userId)
  const userName = userId => {
    const user = userById(userId)
    return `${user.firstName} ${user.lastName[0]}.`
  }

  const createChatMessage = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_chat_message`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        authorType: 'csr',
        authorId: user.id,
        body: body.trim(),
        originPath: originPath,
        contextType: contextType,
        contextId: contextId,
        mentionedUserIds: mentionedUserIds,
        mentionedGroups: mentionedGroups,
      }),
    })
      .then(response => {
        if (response.ok) {
          setBody('')
          return true
        } else {
          throw Error('error')
        }
      })
      .catch(e => {
        alert(e.message)
      })

  const handleToggleRead = (read, id) =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_read_chat_message`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        chatMessageId: id,
        userType: 'csr',
        userId: user.id,
        read,
      }),
    }).then(res => res.ok || alert('error'))

  const userIdsFromMentionsList = mentions =>
    mentions.filter(mention => !mention.display.match(/@group/)).map(mention => mention.id)

  const groupsFromMentionsList = mentions =>
    mentions.filter(mention => mention.display.match(/@group/)).map(mention => mention.id)

  const handleVisibilityOfMessagePhotos = (messageId, visibility) => {
    if (!visibility) {
      return null
    }

    setMessages(messages =>
      messages.map(message => (message.id === messageId ? { ...message, renderPhotos: true } : message))
    )
  }

  return (
    <>
      <Modal open={!!photoModalUrl} onClose={() => setPhotoModalUrl(null)}>
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            paddingTop: '64px',
          }}
          onClick={() => setPhotoModalUrl(null)}
        >
          <PinchZoomPan position='center' maxScale={2}>
            <img src={photoModalUrl} />
          </PinchZoomPan>
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              marginBottom: '1em',
              padding: '0.5em 0',
              backgroundColor: 'white',
              textAlign: 'center',
            }}
          >
            <CopyToClipboard style={{ cursor: 'pointer' }} text={photoModalUrl}>
              <span>
                Photo url: (click to copy)
                <br />
                {photoModalUrl}
              </span>
            </CopyToClipboard>
            <div style={{ cursor: 'pointer', position: 'absolute', top: '8px', right: '8px' }}>
              [click anywhere to close]
            </div>
          </div>
        </div>
      </Modal>

      <div id='chatMessageList' style={{ fontSize: '1.2em', maxHeight: '500px', overflow: 'auto' }}>
        {messages.map(message => (
          <div key={message.id}>
            <div style={{ margin: '0.2em 0.5em 0.4em' }}>
              <Typography>
                <b>{authorName(message)}</b>&nbsp;
                <small style={{ color: '#747474' }}>
                  {DateTime.fromISO(message.createdAt).toLocaleString(DateTime.DATETIME_FULL)}
                </small>
                {enableReadToggle && (
                  <>
                    &nbsp;&nbsp;
                    <div style={{ fontSize: '12px', display: 'inline-block' }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
                              checked={!!message.readBy.find(({ userId }) => user.id === userId)}
                              onClick={e => handleToggleRead(e.target.checked, message.id)}
                            />
                          }
                          label='Read'
                        />
                      </FormGroup>
                    </div>
                  </>
                )}
              </Typography>

              {message.body && (
                <div style={{ marginLeft: '0.3em' }}>
                  <MentionsInput className='mentions' classNames={mentionsClassNames} value={message.body}>
                    <Mention
                      displayTransform={(id, display) => `@${display}`}
                      style={{ backgroundColor: 'rgb(215 255 170)' }}
                      trigger='@'
                    />
                  </MentionsInput>
                </div>
              )}
              {(message.mediaUrls || []).map(mediaUrl => {
                const photoSideNumber = Math.floor(Math.max(120 / message.mediaUrls.length, 80))
                const photoSidePx = `${photoSideNumber}px`

                return (
                  <div
                    key={mediaUrl}
                    style={{
                      border: '2px solid #efefef',
                      display: 'inline-block',
                      width: photoSidePx,
                      height: photoSidePx,
                    }}
                  >
                    <VisibilitySensor
                      onChange={isVisible => handleVisibilityOfMessagePhotos(message.id, isVisible)}
                      intervalDelay={250}
                    >
                      {message.renderPhotos ? (
                        <img
                          src={mediaUrl}
                          style={{
                            objectFit: 'cover',
                            height: photoSidePx,
                            width: photoSidePx,
                            cursor: 'pointer',
                          }}
                          onClick={() => setPhotoModalUrl(mediaUrl)}
                        />
                      ) : (
                        <Skeleton variant='rectangular' width={photoSideNumber} height={photoSideNumber} />
                      )}
                    </VisibilitySensor>
                  </div>
                )
              })}
            </div>
          </div>
        ))}
      </div>

      <div style={{ border: '2px solid #c8c8c8', padding: '0.5em', fontSize: '1.2em' }}>
        <MentionsInput
          className='mentions'
          cy-data='mentions-input'
          classNames={mentionsClassNames}
          value={body}
          onChange={(e, newVal, newPlainVal, mentions) => {
            setBody(newVal)
            setMentionedUserIds(userIdsFromMentionsList(mentions))
            setMentionedGroups(groupsFromMentionsList(mentions))
          }}
        >
          <Mention
            displayTransform={(id, display) => `@${display}`}
            appendSpaceOnAdd
            trigger='@'
            onAdd={(id, display, start, end) => console.log(id, display, start, end)}
            data={mentionableUsers}
            style={{ backgroundColor: 'rgb(215 255 170)' }}
          />
        </MentionsInput>
      </div>

      <Button
        style={{ marginTop: '5px' }}
        cy-data='mentions-input-submit'
        variant='outlined'
        color='primary'
        disabled={body.length === 0}
        onClick={createChatMessage}
      >
        Send
      </Button>

      <br />
      <br />
      <div style={{ zIndex: 100, backgroundColor: 'white' }}>
        <ChatPhotoUploader contextType={contextType} contextId={contextId} />
      </div>
    </>
  )
}

export default Chat
