// commonly used chart labels

const guessSVGTextWidth = (text) => {
  if (text.length > 8) return text.length * 8
  if (text.length > 4) return text.length * 9 + 4
  return text.length * 10 + 8
}

export const labelBar = (formatter, color) => (props) => {
  const { x, y, width, value } = props
  const text = formatter(value)
  const labelW = guessSVGTextWidth(text)
  const rectX = x + ((width - labelW) / 2)
  const labelY = y - 28
  return (
    <g>
      <rect x={rectX} y={labelY} width={labelW} height={24} style={{ strokeWidth: 2, stroke: color, fill: '#fff' }} />
      <text x={rectX + 4} y={labelY + 18}>{text}</text>
    </g>
  )
}

export const labelLine = (formatter, color) => (props) => {
  const { x, y, value } = props
  const text = formatter(value)
  const labelW = guessSVGTextWidth(text)
  const rectX = x - (labelW / 2)
  const labelY = y - 14
  return (
    <g>
      <rect x={rectX} y={labelY} width={labelW} height={24} style={{ strokeWidth: 2, stroke: color, fill: '#fff' }} />
      <text x={rectX + 4} y={labelY + 18}>{text}</text>
    </g>
  )
}

export const rotateTick = () => (props) => {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={4} dy={4} textAnchor='end' fill='#777' transform='rotate(-35)'>
        {payload.value}
      </text>
    </g>
  )
}

export default {
  bar: labelBar,
  line: labelLine,
  tick: rotateTick
}
