import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import ShowEdit from './Job/ShowEdit'

const GET_JOB = gql`
  query job($id: ID!) {
    job(id: $id) {
      id
      technicianId
      quoteId
      leadId
      startDatetime
      endDatetime
      status
      canceledAt
      isActive
      rescheduledAt
      rescheduledBy {
        id
        firstName
        lastName
      }
      rescheduledReason
      heldForReschedulingAt
      didntGo
      postJobPhotos
      preJobPhotos
      markedCompleteAt
      cancellationReason
      followUpReason
      label

      technician {
        id
        name
        firstName
        lastName
        offdays
        availability
      }
      quote {
        id
        requiredSkillsets
        difficultyLevel
        approvedTechnicians {
          id
          firstName
          lastName
        }
        parts {
          id
        }
      }
    }
  }
`

const Job = ({ lead, quote, refetchQuote }) => {
  const { jobId } = useParams()

  const { loading, error, data, refetch } = useQuery(GET_JOB, {
    variables: { id: jobId },
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return <ShowEdit refetchQuote={refetchQuote} refetchJob={refetch} lead={lead} quote={quote} job={data.job} />
}

export default Job
