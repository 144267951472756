import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Grid, TextField, Box, Typography, Button } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { PhoneInput, ZipInput } from 'tools'

const phoneRegExp = /^\d{10}$/
const zipRegExp = /^\d{5}$/

const newCustomerSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short!').required('Required'),
  lastName: Yup.string().min(2, 'Too Short!').required('Required'),
  zip: Yup.string().required('required').matches(zipRegExp, 'Invalid zip, requires 5 digits'),
  email: Yup.string().email('Invalid email'),
  phone: Yup.string().required('required').matches(phoneRegExp, 'Invalid phone, requires 10 digits'),
})

const New = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      zip: '',
      phone: '',
      email: '',
    },
    validationSchema: newCustomerSchema,
    onSubmit: values => {
      const filteredValues = Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== ''))

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_customer`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify(filteredValues),
      })
        .then(res => {
          if (res.ok) {
            return res.json()
          } else {
            throw Error('error')
          }
        })
        .then(data => history.push(`/customers/${data.customer.id}`))
        .catch(e => alert(e.message))
    },
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete='off'
          onSubmit={formik.handleSubmit}
        >
          <Typography variant='h6'>Create a Customer</Typography>

          <Typography variant='subtitle1'>phone and email must not already exist</Typography>

          <div>
            <TextField
              required
              size='small'
              label='First name'
              name='firstName'
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />

            <TextField
              required
              size='small'
              label='Last name'
              name='lastName'
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </div>

          <div>
            <TextField
              required
              size='small'
              label='Zip'
              name='zip'
              value={formik.values.zip}
              onChange={formik.handleChange}
              error={formik.touched.zip && Boolean(formik.errors.zip)}
              helperText={formik.touched.zip && formik.errors.zip}
              InputProps={{ inputComponent: ZipInput }}
            />

            <TextField
              required
              size='small'
              label='Phone'
              name='phone'
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              InputProps={{ inputComponent: PhoneInput }}
            />
          </div>

          <div>
            <TextField
              size='small'
              label='Email'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>

          <div>
            <Button color='primary' variant='contained' type='submit'>
              Submit
            </Button>
          </div>
        </Box>
      </Grid>

      <Grid item xs={12} lg={6} />
    </Grid>
  )
}

export default New
