import React from 'react'
import { DateTime } from 'luxon'
import { makeStyles } from '@mui/styles'
import { pluralizeText, underscoreToTitleCase } from 'tools'

import partStatusClasses from 'Parts/part_status.module.css'

const useStyles = makeStyles(theme => ({
  partSquareOuterBorder: {
    margin: '1px',
    display: 'inline-block',
    paddingBottom: '2px',
    borderBottom: '4px solid',
  },
  partStatusSquare: {
    height: '15px',
    width: '15px',
  },
}))

const PartStatusSquare = ({ part }) => {
  const classes = useStyles()

  const etaStatus = () => {
    if (part.etaBusinessDays === null) {
      return 'eta_missing'
    }
    if (DateTime.fromISO(part.etaLastUpdated) < DateTime.now().plus({ days: -5 })) {
      return 'eta_stale'
    }
    return 'eta_fresh'
  }

  const statusTitle = () => {
    const str = 'part status: ' + underscoreToTitleCase(part.status).toLowerCase()

    if (part.etaBusinessDays === null) {
      return str + '--'
    }

    const diffDays = Math.ceil(DateTime.now().toUTC().diff(DateTime.fromISO(part.etaLastUpdated), 'days').as('days'))

    return (
      str +
      '\npart eta (days): ' +
      part.etaBusinessDays +
      '  \neta updated: ' +
      `${diffDays} ${pluralizeText('day', diffDays)} ago`
    )
  }

  return (
    <div
      className={[classes.partSquareOuterBorder, partStatusClasses['border_' + etaStatus()]].join(' ')}
      title={statusTitle()}
    >
      <div className={[partStatusClasses['bg_' + part.status], classes.partStatusSquare].join(' ')} />
    </div>
  )
}

export default PartStatusSquare
