import { Button, Grid } from '@mui/material'
import SectionHeaderGrid from './SectionHeaderGrid'
import AddNewPart from './AddNewPart'
import PartsTable from './PartsTable'
import PartsTechPartsTable from './PartsTechPartsTable'
import PartsTireconnectTable from './tireConnectParts/PartsTireconnectTable'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { useContext } from 'react'
import { UserContext } from 'UserStore'
import OmegaEdiPartsTable from './OmegaEdiPartsTable'

const Parts = ({
  quote,
  refetchQuote,
  editingDisabled,
  parts,
  currentPhotoId,
  editingMutex,
  setEditingMutex,
  updateQuoteClickGate,
}) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const sortedParts = parts
    .filter(item => !item.partsTechKeyword && !item.tireConnectData  && !item.nagsGlassId)
    .reduce((acc, item) => {
      const found = parts.find(part => part.id === item.id)
      if (found) {
        acc.push(found)
      }
      return acc
    }, [])
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    .map(o => parts.find(part => part.id === o.id))

  const partsTechParts = parts
    .filter(item => item.partsTechKeyword)
    .reduce((acc, item) => {
      const found = parts.find(part => part.id === item.id)
      if (found) {
        acc.push(found)
      }
      return acc
    }, [])
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    .map(o => parts.find(part => part.id === o.id))

    const omegaEdiParts = parts
    .filter(item => item.nagsGlassId)
    .reduce((acc, item) => {
      const found = parts.find(part => part.id === item.id)
      if (found) {
        acc.push(found)
      }
      return acc
    }, [])
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    .map(o => parts.find(part => part.id === o.id))

  const partsTireconnect = parts
    .filter(item => item.tireConnectData)
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))

  const handleAddTireToQuote = tireId =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_tire_to_quote`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        tireId: tireId,
        quoteId: quote.id,
        userId: user.id,
      }),
    })

  return (
    <>
      <SectionHeaderGrid item xs={12}>
        Parts
      </SectionHeaderGrid>

      <AddNewPart
        quote={quote}
        refetchQuote={refetchQuote}
        editingMutex={editingMutex}
        setEditingMutex={setEditingMutex}
        editingDisabled={editingDisabled}
        currentPhotoId={currentPhotoId}
      />

      <Grid item>
        <PartsTable
          quote={quote}
          parts={sortedParts}
          editingDisabled={editingDisabled || editingMutex === 'add-part'}
          setEditingMutex={setEditingMutex}
        />
      </Grid>

      {partsTechParts.length > 0 && (
        <>
          <SectionHeaderGrid item xs={12}>
            PartsTech Parts
          </SectionHeaderGrid>

          <Grid item>
            <PartsTechPartsTable
              quote={quote}
              parts={partsTechParts}
              editingDisabled={!updateQuoteClickGate || editingMutex === 'add-part'}
              setEditingMutex={setEditingMutex}
            />
          </Grid>
        </>
      )}

      {omegaEdiParts.length > 0 && (
        <>
          <SectionHeaderGrid item xs={12}>
            OmegaEDI Parts
          </SectionHeaderGrid>
          <Grid item>
            <OmegaEdiPartsTable
              quote={quote}
              parts={omegaEdiParts}
              editingDisabled={!updateQuoteClickGate || editingMutex === 'add-part'}
              setEditingMutex={setEditingMutex}
            />
          </Grid>
        </>
      )}

      {quote?.lead?.serviceType === 'tires' && (
        <>
          <SectionHeaderGrid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            Tireconnect Parts{' '}
            <Button
              onClick={() => {
                const tireId = window.prompt('Paste the tire ID here from the tire search page')
                handleAddTireToQuote(tireId)
              }}
              sx={{ ml: 'auto' }}
              size='small'
              variant='outlined'
            >
              Add tire to this quote
            </Button>
          </SectionHeaderGrid>

          <Grid item>
            <PartsTireconnectTable
              quote={quote}
              refetchQuote={refetchQuote}
              parts={partsTireconnect}
              editingDisabled={!updateQuoteClickGate || editingMutex === 'add-part'}
              setEditingMutex={setEditingMutex}
            />
          </Grid>
        </>
      )}
    </>
  )
}

export default Parts
