import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { keysToCamel } from 'tools'
import Show from './Show'

const QUERY = gql`
  query callbackRequest($id: ID!) {
    callbackRequest(id: $id) {
      id
      toNumber
      attempts
      customerReasonOther
      customerReason
      csrReasonOther
      completedAt
      createdAt
      customerName
      source
      zip
      customer {
        id
        firstName
        lastName
        phone
        outboundNumber
      }
      lead {
        id
        name
      }
    }
  }
`

const CallbackRequest = () => {
  const { path } = useRouteMatch()
  const { callbackRequestId } = useParams()

  const { loading, error, data } = useQuery(QUERY, {
    variables: { id: callbackRequestId }
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const callbackRequest = keysToCamel(data.callbackRequest)

  return (
    <>
      <Switch>
        <Route path={path}>
          <Show callback={callbackRequest} />
        </Route>
      </Switch>
    </>
  )
}

export default CallbackRequest
