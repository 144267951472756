import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'UserStore'
import { Box, Button, Dialog, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useQuery, gql } from '@apollo/client'
import { DeleteRounded, EditRounded } from '@mui/icons-material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const DEALERS_QUERY = gql`
  query BToBPanels($panelGroupId: String) {
    bToBPanels(panelGroupId: $panelGroupId) {
      id
      name
      panel
      side
    }
  }
`

export const PanelGroupDialog = ({ open, setOpen, panelGroup, refetch }: any) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const { loading, error, data } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      panelGroupId: panelGroup.id,
    },
  })

  const handleClickDeletePanelGroup = () => {
    window.confirm(`are you sure you want to update delete this panel group?`) &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_panel_group`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          panel_group_id: panelGroup.id,
        }),
      }).then(res => {
        if (res) {
          setTimeout(() => refetch(), 250)
          setOpen(false)
        } else {
          console.log('ERROR!')
        }
      })
  }

  const handleClickEditPanelGroup = () => {
    const newName = prompt("Enter a new name for the panel group", panelGroup.name)
    if (!!newName?.toString().trim()) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_panel_group_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          panel_group_id: panelGroup.id,
          updated_attributes: {
            name: newName.trim(),
            updated_by_user_id: user.id,
          },
        }),
      }).then(res => {
        if (res) {
          refetch()
          setOpen(false)
        } else {
          console.log('ERROR!')
        }
      })
    }
  }

  if (error) return <div>Error!</div>

  const lineItems = data?.bToBPanels || []

  return (
    <Dialog
      PaperProps={{
        sx: { minWidth: '750px ' },
      }}
      onClose={() => setOpen(false)}
      open={open}
    >
      <Box
        sx={{
          padding: '.5rem 1rem',
          background: 'aliceblue',
          borderBottom: '2px solid #a1cff7',
          fontWeight: 500,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& span': {
            fontSize: '11px',
          },
          position: 'sticky',
          top: '0px',
          zIndex: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontSize: '18px' }}>{panelGroup.name}</span>{' '}
        </Box>
        <Button
          onClick={ handleClickEditPanelGroup }
          color='warning'
          size='small'
          sx={{ minWidth: '30px', minHeight: '30px', marginLeft: '.5rem' }}
        >
          <EditRounded fontSize='small' />
        </Button>
        <Button
          onClick={handleClickDeletePanelGroup}
          color='warning'
          size='small'
          sx={{ minWidth: '30px', minHeight: '30px', marginLeft: '.5rem', marginRight: 'auto' }}
        >
          <DeleteRounded fontSize='small' />
        </Button>
        <span style={{ color: '#777' }}>PANEL GROUP</span>
      </Box>

      {lineItems.length > 0 && (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Panel Name</TableCell>
              <TableCell>Side</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems.map((lineItem: any) => (
              <TableRow key={lineItem.id}>
                <TableCell>{lineItem.panel}</TableCell>
                <TableCell>
                  {lineItem.side || <span style={{ fontSize: '12px', color: '#777', opacity: 0.5 }}>None</span>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {lineItems.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#777',
            justifyContent: 'center',
            padding: '2rem 1rem',
            textAlign: 'center',
          }}
        >
          There are no panels for this panel group yet. You can create a panel for this panel group on the "panels list"
          tab.
        </Box>
      )}
    </Dialog>
  )
}
