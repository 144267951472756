import { Grid, Collapse, Select, MenuItem, FormControl, InputLabel, Button, TextField } from '@mui/material'
import { useContext, useState } from 'react'
import { PriceInput } from 'tools'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { lineItemIsValid, lookupPrice } from '../tools'

export const EditLineItem = ({ lineItem, lineItems, invoice }) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const [editableLineItem, setEditableLineItem] = useState({ ...lineItem })
  const handlePriceChanged = e =>
    setEditableLineItem(prev => ({
      ...prev,
      price: Number(e.target.value),
    }))

  const resetEditableLineItem = () => setEditableLineItem({ ...lineItem })

  const handleSelectPanel = panel =>
    setEditableLineItem(prev => ({
      ...prev,
      ...{ panel, side: null, type: null, price: null },
    }))

  const handleSelectSide = side =>
    setEditableLineItem(prev => ({
      ...prev,
      ...{ side },
    }))

  const handleSelectType = type =>
    setEditableLineItem(prev => ({
      ...prev,
      ...{
        type,
        price: lookupPrice({ lineItems, lineItem: { ...lineItem, type } }),
      },
    }))

  const handleClickSave = () => {
    const foundItem = lineItems.find(
      item =>
        editableLineItem.panel === item.panel &&
        editableLineItem.type === item.type &&
        (editableLineItem.side ? item.side === editableLineItem.side : true)
    )
    
    const { pricePoint, customPricePoint } = foundItem

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_line_item`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        lineItem: { ...editableLineItem, pricePoint, customPricePoint },
        actorType: 'csr',
        actorId: user.id,
      }),
    }).then(res => res.ok || window.alert('Error'))
  }

  const handleClickDelete = () =>
    window.confirm('Are you sure you want to delete this line item?') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_invoice_line_item`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        lineItemId: lineItem.id,
        actorType: 'csr',
        actorId: user.id,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const editableHasDiff =
    editableLineItem.panel !== lineItem.panel ||
    editableLineItem.side !== lineItem.side ||
    editableLineItem.type !== lineItem.type ||
    editableLineItem.price !== lineItem.price

  const allowSave = editableLineItem && lineItemIsValid(editableLineItem, lineItems)

  const lineItemHasSide = !!editableLineItem.side

  const possiblePanels = [...new Set(lineItems.slice().map(item => item.panel))].sort()
  const possibleSides = [
    ...new Set(lineItems.filter(item => item.panel === editableLineItem.panel).map(item => item.side)),
  ].sort()

  const possibleTypes = [
    ...new Set(
      lineItems
        .filter(
          item =>
            item.panel === editableLineItem.panel && (lineItemHasSide ? item.side === editableLineItem.side : true)
        )
        .map(item => item.type)
    ),
  ].sort()

  return (
    <Grid
      container
      sx={{
        borderRadius: '6px',
        border: '1px solid #e8ddd9',
        background: '#fffdf8',
        mb: '0.5em',
      }}
    >
      <Grid container xs={12} sx={{ margin: '4px 0' }}>
        <Grid item xs={6}>
          <FormControl fullWidth size='small' sx={{ background: '#fff' }}>
            <InputLabel>Panel</InputLabel>
            <Select label='Panel' value={editableLineItem.panel} onChange={e => handleSelectPanel(e.target.value)}>
              {possiblePanels.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {lineItemHasSide && (
          <Grid item xs={6}>
            <FormControl fullWidth size='small' sx={{ background: '#fff' }}>
              <InputLabel>Side</InputLabel>
              <Select label='side' value={editableLineItem.side} onChange={e => handleSelectSide(e.target.value)}>
                {possibleSides.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>

      <Grid container xs={12} sx={{ mb: '4px' }}>
        <Grid item xs={6}>
          <FormControl fullWidth size='small' sx={{ background: '#fff' }}>
            <InputLabel>Type</InputLabel>
            <Select label='Type' value={editableLineItem.type} onChange={e => handleSelectType(e.target.value)}>
              {possibleTypes.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FormControl fullWidth size='small'>
            <TextField
              size='small'
              value={editableLineItem.price}
              onChange={handlePriceChanged}
              InputProps={{
                inputComponent: PriceInput,
              }}
            />
          </FormControl>
          <Button disabled={editableHasDiff} color='error' onClick={handleClickDelete}>
            [delete]
          </Button>
        </Grid>
      </Grid>

      <Collapse in={editableHasDiff} sx={{ width: '100%' }}>
        <Grid container>
          <Grid item xs={12} sx={{ p: '5px', display: 'flex' }}>
            <Button onClick={resetEditableLineItem} color='warning' size='small'>
              cancel/reset
            </Button>
            <Button
              disabled={!allowSave}
              onClick={handleClickSave}
              disableElevation
              variant='contained'
              size='small'
              sx={{ ml: 'auto' }}
            >
              Save changes
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  )
}
