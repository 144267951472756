import { useEffect, useState } from 'react'

export const OmegaVehicleInfo = ({ omegaVehicleId }) => {
  const [vehicle, setVehicle] = useState({})

  useEffect(() => {
    JSON.stringify(vehicle) === '{}' &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_omega_vehicle`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          vehicle_id: omegaVehicleId,
        }),
      }).then(res => res.json().then(newRes => setVehicle(newRes.vehicle)))
  }, [omegaVehicleId])

  return <strong> - {vehicle?.body_style_dsc}</strong>
}
