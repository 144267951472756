import { useEffect, useState } from 'react'
import { AutoglassSupplierItem } from './AutoglassSupplierItem'
import { gql, useLazyQuery } from '@apollo/client'

const Availability = gql`
  query OmegaGlassAvailability($zip: String!, $nagsGlassId: String!) {
    omegaGlassAvailability(nagsGlassId: $nagsGlassId, zip: $zip)
  }
`

export const AutoglassProcurementSections = ({ part, quote, zip }) => {
  const [
    getGlass,
    { loading: loadingSuppliers, error: suppliersError, data: suppliersData, refetch: refetchSuppliers },
  ] = useLazyQuery(Availability, { variables: { nagsGlassId: part?.nagsGlassId, zip: zip } })

  const [suppliersForPart, setSupplierForPart] = useState([])

  useEffect(() => {
    part?.nagsGlassId &&
      getGlass({
        variables: {
          nagsGlassId: part?.nagsGlassId,
          zip: zip,
        },
      })
  }, [quote])

  useEffect(() => {
    suppliersData && setSupplierForPart(suppliersData?.omegaGlassAvailability)
  }, [suppliersData])

  const availableGlass =
    suppliersData?.omegaGlassAvailability?.flatMap(i => i.availability).flatMap(v => Object.values(v)[0]) || []

  return (
    <>
      {loadingSuppliers && (
        <>
          <br />
          <br />
          loading supplier stock information...
        </>
      )}
      {suppliersForPart.length > 0 && <hr />}
      {suppliersForPart.length > 0 && (
        <b>
          {part.nagsGlassId}: {part.name}
        </b>
      )}

      {availableGlass.map((supplier, index) => (
        <AutoglassSupplierItem key={index} glass={supplier} />
      ))}
    </>
  )
}
