import { useContext, useEffect } from 'react'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import {
  Autocomplete,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { downloadStringAsFile } from 'tools'
import { UserContext } from 'UserStore'
import { TaskTargetRegistry } from 'TaskTargetRegistry'

const styles = {
  displayInlineFlex: { display: 'inline-flex' },
  textAlignCenter: { textAlign: 'center' },
  textAlignRight: { textAlign: 'right' },
}

const confirmMarkPlaced = `Mark this order as placed?\nThis will complete the task, and you should then arrange delivery.\nPlease enter the total amount that the order cost (up to 2 decimal places, like this: 19.99)`
const urlMarkPlaced = `${process.env.REACT_APP_COMMAND_ROOT}/mark_supplies_order_as_placed`
const confirmMarkRejected = `Mark this order as rejected?\nThis will complete the task, and you should then notify the technician`
const urlMarkRejected = `${process.env.REACT_APP_COMMAND_ROOT}/mark_supplies_order_as_rejected`
const confirmAddItem = 'Edit this order?\nAdding item: '
const urlAddItem = `${process.env.REACT_APP_COMMAND_ROOT}/add_item_to_supplies_order`
const confirmRemoveItem = 'Edit this order?\nRemoving item: '
const urlRemoveItem = `${process.env.REACT_APP_COMMAND_ROOT}/remove_item_from_supplies_order`

const Show = ({ order, refetch, items }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)

  useEffect(() => {
    const key = registerTaskTarget({
      targetType: 'SuppliesOrder',
      targetId: order.id,
    })
    return () => unregisterTaskTarget(key)
  }, [order])

  const itemsAddable = items
    .filter(
      i =>
        !order.itemIds.includes(i.id) &&
        !i.deletedAt &&
        ((order.technician.state === 'CA' && i.legality !== 'National Rule') ||
          (order.technician.state !== 'CA' && i.legality !== 'California'))
    )
    .map(i => {
      return i.id + ' ' + i.description + ' ' + i.size + ' ' + i.type
    })

  const itemsRemovable = items.filter(i => order.itemIds.includes(i.id))
  const itemsMap = items.reduce((acc, i) => ({ ...acc, [i.id]: i }), {})
  const orderHistory = order.technician.suppliesOrders.filter(o => o.placedAt)
  const itemsInHistory = orderHistory.reduce(
    (acc, o) => [...acc, ...o.itemIds.reduce((_acc, i) => (acc.indexOf(i) < 0 ? [..._acc, i] : _acc), [])],
    []
  )
  const orderContainsItemId = orderHistory.reduce(
    (acc, o) => ({
      ...acc,
      [o.id]: o.itemIds.reduce((_acc, i) => ({ ..._acc, [i]: true }), {}),
    }),
    {}
  )

  const handlePlaced = () => {
    const suppliesOrderCost = window.prompt(confirmMarkPlaced)
    // if (!window.prompt(confirmMarkPlaced)) return false

    !!suppliesOrderCost &&
      fetch(urlMarkPlaced, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          suppliesOrderId: order.id,
          purchasePrice: Number(suppliesOrderCost),
        }),
      }).then(res => (res.ok ? refetch() : window.alert('Error')))
  }

  const handleRejected = () => {
    if (!window.confirm(confirmMarkRejected)) return false

    fetch(urlMarkRejected, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        suppliesOrderId: order.id,
      }),
    }).then(res => (res.ok ? refetch() : window.alert('Error')))
  }

  const handleAddItem = (e, value) => {
    if (!value) return undefined

    const item_id = value.substring(0, value.indexOf(' '))
    if (!window.confirm(confirmAddItem + item_id)) return false

    fetch(urlAddItem, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        suppliesOrderId: order.id,
        suppliesOrderItemId: item_id,
      }),
    }).then(res => (res.ok ? refetch() : window.alert('Error')))
  }

  const handleRemoveItem = item_id => {
    if (!window.confirm(confirmRemoveItem + item_id)) return false

    fetch(urlRemoveItem, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        suppliesOrderId: order.id,
        suppliesOrderItemId: item_id,
      }),
    }).then(res => (res.ok ? refetch() : window.alert('Error')))
  }

  const handleDownloadOrder = () => {
    let csv = 'product,type,description,size,quantity\n'
    order.itemIds.map(i => {
      csv += `${i},${itemsMap[i].type},${itemsMap[i].description},${itemsMap[i].size},${itemsMap[i].quantity}\n`
    })
    downloadStringAsFile({
      string: csv,
      fileName: `SuppliesOrder-${order.id}.csv`,
    })
  }

  const handleDownloadHistory = () => {
    let csv = itemsInHistory.reduce((acc, i) => acc + ',' + i, 'order') + '\n'
    orderHistory.map(o => {
      csv += itemsInHistory.reduce(
        (acc, i) => acc + ',' + (orderContainsItemId[o.id][i] ? 'x' : ''),
        DateTime.fromISO(o.placedAt).toLocaleString()
      )
      csv += '\n'
    })
    const now = DateTime.now()
    const dt = `${now.year}-${now.month}-${now.day}T${now.hour}_${now.minute}`
    downloadStringAsFile({
      string: csv,
      fileName: `SuppliesOrderHistory-${order.technician.firstName}_${order.technician.lastName}-${dt}.csv`,
    })
  }

  const formatDateTime = dtString => {
    const dt = DateTime.fromISO(dtString)
    return dt.isValid && dt.toLocaleString(DateTime.DATETIME_FULL)
  }
  const formatFullName = user => user && user.firstName + ' ' + user.lastName

  return (
    <Container maxWidth='xl' sx={{ backgroundColor: '#eee' }}>
      <Stack>
        <>
          <Typography variant='h6'>Requested</Typography>
          <Paper sx={{ m: 1, p: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Link to={'/technicians/' + order.technician.id}>{formatFullName(order.technician)}</Link>
              </Grid>
              <Grid item xs={6}>
                <Typography align='right'>{formatDateTime(order.createdAt)}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </>

        {order.updatedAt && (
          <>
            <Typography variant='h6'>Updated</Typography>
            <Paper sx={{ m: 1, p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {formatFullName(order.updatedBy)}
                </Grid>
                <Grid item xs={6}>
                  <Typography align='right'>{formatDateTime(order.updatedAt)}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </>
        )}

        {order.placedAt && (
          <>
            <Typography variant='h6'>Placed</Typography>
            <Paper sx={{ m: 1, p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {formatFullName(order.placedBy)}
                </Grid>
                <Grid item xs={6}>
                  <Typography align='right'>{formatDateTime(order.placedAt)}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </>
        )}

        {order.rejectedAt && (
          <>
            <Typography variant='h6'>Rejected</Typography>
            <Paper sx={{ m: 1, p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {formatFullName(order.rejectedBy)}
                </Grid>
                <Grid item xs={6}>
                  <Typography align='right'>{formatDateTime(order.rejectedAt)}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </>
        )}

        {!order.placedAt && !order.rejectedAt && (
          <>
            <Typography variant='h6'>Actions</Typography>
            <Paper sx={{ m: 1, p: 1 }}>
              <Grid container>
                <Grid item xs={8}>
                  &nbsp;&nbsp;
                  <Autocomplete
                    sx={{ minWidth: 400, display: 'inline-block' }}
                    options={itemsAddable}
                    onChange={handleAddItem}
                    renderInput={props => <TextField {...props} label='Add Item' />}
                  />
                  &nbsp;&nbsp;
                  <FormControl variant='filled' sx={{ minWidth: 400 }}>
                    <InputLabel id='remove-item-select-label'>Remove Item</InputLabel>
                    <Select
                      labelId='remove-item-select-label'
                      label='Remove Item'
                      value=''
                      onChange={e => handleRemoveItem(e.target.value)}
                    >
                      {itemsRemovable.map(i => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.id} - {i.type} - {i.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={styles.textAlignRight}>
                  <Button onClick={handleRejected}>Mark as rejected</Button>
                  &nbsp;&nbsp;
                  <Button onClick={handlePlaced}>Mark as placed</Button>
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        <br />
        <Divider />
        <br />
        <>
          <div>
            <Typography variant='h6' sx={styles.displayInlineFlex}>
              Items
            </Typography>
            <Button variant='contained' color='primary' style={{ float: 'right' }} onClick={handleDownloadOrder}>
              download csv
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table size='small' aria-label='a dense table'>
              <TableHead>
                <TableRow>
                  <TableCell>product</TableCell>
                  <TableCell align='right'>type</TableCell>
                  <TableCell align='right'>description</TableCell>
                  <TableCell align='right'>size</TableCell>
                  <TableCell align='right'>quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.itemIds.map(itemId => (
                  <TableRow key={itemId}>
                    <TableCell component='th'>{itemId}</TableCell>
                    <TableCell align='right'>{itemsMap[itemId].type}</TableCell>
                    <TableCell align='right'>{itemsMap[itemId].description}</TableCell>
                    <TableCell align='right'>{itemsMap[itemId].size}</TableCell>
                    <TableCell align='right'>{itemsMap[itemId].quantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        <br />
        <Divider />
        <br />
        <>
          <div>
            <Typography variant='h6' sx={styles.displayInlineFlex}>
              Item Order History
            </Typography>
            <Button variant='contained' color='primary' style={{ float: 'right' }} onClick={handleDownloadHistory}>
              download csv
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>order</TableCell>
                  {order.itemIds.map(itemId => (
                    <TableCell key={itemId}>{itemId}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {orderHistory.map(histOrder => (
                  <TableRow key={histOrder.id}>
                    <TableCell>
                      <Link to={`/supplies-orders/${histOrder.id}`}>
                        {DateTime.fromISO(histOrder.placedAt).toLocaleString()}
                      </Link>
                    </TableCell>
                    {order.itemIds.map(itemId => (
                      <TableCell key={itemId}>{orderContainsItemId[histOrder.id][itemId] && <>X</>}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
        </>
      </Stack>
    </Container>
  )
}

export default Show
