import React, { useRef, useState, useContext } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { Dollars, downloadStringAsFile, PriceInput, basicDateTimeFormat } from 'tools'
import { UserContext } from 'UserStore'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { DateTime } from 'luxon'
import { GlobalContext } from 'GlobalStore'

const Loans = ({ technician }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const [newLoanDeduction, setNewLoanDeduction] = useState(technician.loanWeeklyDeduction)
  const [newOtherLoanDeduction, setNewOtherLoanDeduction] = useState(technician.otherLoanWeeklyDeduction)

  const userById = id => global.users.find(user => user.id === id)
  const userName = id => {
    const user = userById(id)

    return (user && `${user.firstName} ${user.lastName[0]}`) || 'UNKNOWN'
  }

  const handleSetLoanWeeklyDeduction = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/set_loan_weekly_deduction`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        technicianId: technician.id,
        amount: newLoanDeduction,
      }),
    }).catch(() => alert('error'))
  }

  const handleSetOtherLoanWeeklyDeduction = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/set_other_loan_weekly_deduction`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        technicianId: technician.id,
        amount: newOtherLoanDeduction,
      }),
    }).catch(() => alert('error'))
  }

  const handleDownloadLoanActivity = () => {
    let csv = 'date,event,note,debit,credit,balance\n'
    technician.loanActivity.map(a => {
      csv += `${DateTime.fromISO(a.timestamp).toLocaleString()},${a.type},"${a.note}",`
      if (a.type === 'increment') csv += `,${Number(a.amount).toFixed(2)},`
      else if (a.type === 'payroll_deduction') csv += `-${Number(a.amount).toFixed(2)},,`
      csv += `${a.newBalance < 0 ? '-' : ''}${Number(a.newBalance).toFixed(2)}\n`
    })
    const now = DateTime.now()
    const dt = `${now.year}-${now.month}-${now.day}T${now.hour}_${now.minute}`
    downloadStringAsFile({
      string: csv,
      fileName: `TechnicianLoanActivity-${technician.firstName}_${technician.lastName}-${dt}.csv`,
    })
  }

  const ModifyLoanBalanceDialog = ({ direction, loan }) => {
    const [open, setOpen] = useState(false)
    const [amount, setAmount] = useState(0)
    const [note, setNote] = useState('')

    const handleClickOpen = () => setOpen(true)

    const handleClose = () => setOpen(false)

    const handleSubmit = () => {
      fetch(
        `${process.env.REACT_APP_COMMAND_ROOT}/${direction}_technician${loan === 'other' ? '_other' : ''}_loan_balance`,
        {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            user_id: user.id,
            technician_id: technician.id,
            amount: amount,
            note: note,
          }),
        }
      )
        .then(res => {
          if (res.ok) {
            setOpen(false)
          } else {
            alert('error!')
          }
        })
        .catch(() => alert('error'))
    }

    const allowSubmit = (note.length >= 5 && Number(amount) > 0) || false

    return (
      <>
        <Button variant='outlined' size='small' color='primary' onClick={handleClickOpen}>
          {direction}
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
          <DialogTitle>
            {`${direction.toUpperCase()} ${technician.firstName}'s ${
              loan === 'other' ? 'Other' : 'Equipment'
            } Loan balance`}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin='dense'
              label='Note'
              multiline
              onChange={event => setNote(event.target.value)}
              fullWidth
            />
            <br />
            <br />
            <TextField
              label='amount'
              value={amount}
              name='amount'
              onChange={event => setAmount(Math.abs(Number(event.target.value)))}
              variant='outlined'
              size='small'
              InputProps={{ inputComponent: PriceInput }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary'>
              Cancel
            </Button>
            <Button disabled={!allowSubmit} onClick={handleSubmit} color='primary'>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return (
    <>
      <Typography variant='h6'>Loans</Typography>
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Equipment Loan</b>, Balance:&nbsp;
            <b>
              <Dollars style={{ color: technician.loanBalance > 0 ? 'red' : 'black' }} value={technician.loanBalance} />
            </b>
            , weekly deduction <Dollars value={technician.loanWeeklyDeduction} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                helperText='Weekly EQUIPMENT loan deduction'
                size='small'
                value={newLoanDeduction}
                onChange={event => setNewLoanDeduction(Math.abs(Number(event.target.value)))}
                InputProps={{ inputComponent: PriceInput }}
              />
              <Button
                onClick={handleSetLoanWeeklyDeduction}
                disabled={newLoanDeduction === technician.loanWeeklyDeduction}
              >
                save
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ float: 'right' }}
                variant='outlined'
                size='small'
                color='primary'
                onClick={handleDownloadLoanActivity}
              >
                download csv
              </Button>
              <ModifyLoanBalanceDialog direction='increment' /> <ModifyLoanBalanceDialog direction='decrement' />
            </Grid>
            <Grid item xs={12}>
              {technician.loanActivity.map(activity => (
                <Grid key={activity.timestamp} container>
                  <Grid item xs={12}>
                    {(activity.userId && userName(activity.userId)) || 'Payroll'}&nbsp;
                    <small>{DateTime.fromISO(activity.timestamp).toFormat(basicDateTimeFormat)}</small>&nbsp;
                    <Dollars value={activity.previousBalance} />
                    <b>
                      &nbsp;
                      {activity.type === 'increment' ? '+' : '-'}
                      &nbsp;
                      <Dollars value={activity.amount} />
                      &nbsp;
                    </b>
                    = <Dollars value={activity.newBalance} />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ marginLeft: '1em', marginBottom: '0.5em' }}>note: {activity.note}</div>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ width: '100%' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Other Loan</b>, Balance:&nbsp;
            <b>
              <Dollars
                style={{ color: technician.otherLoanBalance > 0 ? 'red' : 'black' }}
                value={technician.otherLoanBalance}
              />
            </b>
            , weekly deduction <Dollars value={technician.otherLoanWeeklyDeduction} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                helperText='Weekly EQUIPMENT loan deduction'
                size='small'
                value={newOtherLoanDeduction}
                onChange={event => setNewOtherLoanDeduction(Math.abs(Number(event.target.value)))}
                InputProps={{ inputComponent: PriceInput }}
              />
              <Button
                onClick={handleSetOtherLoanWeeklyDeduction}
                disabled={newOtherLoanDeduction === technician.otherLoanWeeklyDeduction}
              >
                save
              </Button>
            </Grid>
            <Grid item xs={12}>
              <ModifyLoanBalanceDialog direction='increment' loan='other' />
              <ModifyLoanBalanceDialog direction='decrement' loan='other' />
            </Grid>
            <Grid item xs={12}>
              {technician.otherLoanActivity.map(activity => (
                <Grid key={activity.timestamp} container>
                  <Grid item xs={12}>
                    {(activity.userId && userName(activity.userId)) || 'Payroll'}&nbsp;
                    <small>{DateTime.fromISO(activity.timestamp).toFormat(basicDateTimeFormat)}</small>&nbsp;
                    <Dollars value={activity.previousBalance} />
                    <b>
                      &nbsp;
                      {activity.type === 'increment' ? '+' : '-'}
                      &nbsp;
                      <Dollars value={activity.amount} />
                      &nbsp;
                    </b>
                    = <Dollars value={activity.newBalance} />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ marginLeft: '1em', marginBottom: '0.5em' }}>note: {activity.note}</div>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default Loans
