export const style = {
  mainPaper: {
    padding: '0rem 1rem',
    '& .MuiPaper-root': {
      padding: '.5rem .75rem',
      border: '2px solid #ddd',
      borderRadius: '12px',
      boxShadow: 'none',
      minHeight: '85px',
      mb: '1rem',
      background: '#0089CD1a',
      borderColor: '#0089CDbf'
    },
    '& .MuiTableHead-root': {
      background: '#f1f1f1'
    },
    '& .MuiTablePagination-root': {
      background: '#f1f1f1',
      borderRadius: '0px 0px 12px 12px'
    }
  },
  filterChip: {
    border: '2px solid #0089CDbf',
    background: '#0089CD1a',
    padding: '.25rem .5rem',
    display: 'inline-block',
    fontSize: '12px',
    borderRadius: '8px',
    marginLeft: '.5rem'
  },
  filterButtonsGroup: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #ddd',
    paddingRight: '.25rem'
  },
  callbackPopoverFooter: {
    background: '#f1f1f1',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  csrNameInGlobalList: {
    border: '1px solid #ddd',
    margin: '.5rem .5rem 0rem 0rem',
    borderRadius: '12px',
    fontSize: '18px',
    display: 'inline-block',
    padding: '0rem .5rem',
    '&:hover': {
      cursor: 'pointer',
      background: '#0089CD1a'
    }
  },
  activityItem: {
    width: '100%',
    borderRadius: '12px',
    padding: '.25rem',
    margin: '.25rem 0rem .25rem 0rem',
    display: 'flex',
    alignItems: 'center',
    '& .svg': {
      height: '16px !important',
      width: '16px !important',
      mr: '.5rem !important'
    }
  },
  activityItemDivider: {
    width: '2px',
    height: '22px',
    background: '#ddd',
    mt: '2.35rem',
    ml: '.45rem',
    position: 'absolute'
  },
  activityLogTitle: {
    fontSize: '20px',
    fontWeight: 600,
    mb: '1rem',
    display: 'flex',
    alignItems: 'center'
  },
  callbackRequestsPaper: {
    background: '#fff !important',
    padding: '0rem !important',
    border: '2px solid #ddd',
    borderRadius: '12px'
  },
  bigAverageNumber: {
    color: '#444', fontWeight: 600, fontSize: '12px'
  },
  reason: {
    maxWidth: '200px',
    overflow: 'hidden',
    fontSize: '14px',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    margin: '0px'
  }
}
