import React, { useState, useEffect, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { Dollars } from 'tools'
import { Link } from 'react-router-dom'
import { RefetchRegistry } from 'RefetchRegistry'
import { UpdateRefetchRegistry } from 'UpdateRefetchRegistry'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Button, Typography } from '@mui/material'

const QUERY = gql`
  query reimbursements($unclaimed: Boolean) {
    reimbursements(unclaimed: $unclaimed) {
      id
      technicianId
      createdByType
      createdById
      photoUrls
      createdAt
      amount
      payoutId
      note
    }
  }
`

const Reimbursements = ({ refetchAll }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const [waiting, setWaiting] = useState(false)
  const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  const [registerUpdateRefetch, unregisterUpdateRefetch] = useContext(UpdateRefetchRegistry)

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { unclaimed: true },
  })

  useEffect(() => {
    data && refetch && refetch()
  }, [])

  useEffect(() => {
    const key = registerRefetch({ types: ['Reimbursement'], refetch })
    return () => unregisterRefetch(key)
  }, [])

  useEffect(() => {
    const key = registerUpdateRefetch({ types: ['Reimbursement'], refetch })
    return () => unregisterUpdateRefetch(key)
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const technicianById = id => global.technicians.find(tech => tech.id === id)

  const linkToAPayout = id =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/link_reimbursement_to_a_payout`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        reimbursement_id: id,
        user_id: user.id,
      }),
    })
      .then(response => {
        if (response.ok) {
          refetch()
          refetchAll()
        } else {
          throw Error('error')
        }
      })
      .catch(err => {
        window.alert(err)
      })

  const moveAll = () =>
    data.reimbursements.forEach(comp =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/link_reimbursement_to_a_payout`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          reimbursement_id: comp.id,
        }),
      })
        .then(response => {
          if (response.ok) {
            console.log('ok')
          } else {
            throw Error('error')
          }
        })
        .catch(err => {
          window.alert(err)
        })
    )

  return (
    <>
      <Typography
        variant='body1'
        sx={{
          padding: '.5rem',
          background: 'aliceblue',
          borderBottom: '2px solid #a1cff7',
          borderTop: '2px solid #a1cff7',
          opacity: data.reimbursements.length === 0 ? 0.5 : 1,
        }}
      >
        Reimbursements:
        <Button
          variant='outlined'
          sx={{
            fontSize: '12px !important',
            minWidth: '30px',
            padding: '.125rem .5rem',
            minHeight: '15px !important',
            ml: '.5rem',
            '&  .MuiButtonBase-root': {
              padding: '.125rem .5rem',
              minHeight: '15px !important',
            },
          }}
          onClick={e => {
            e.stopPropagation()
            moveAll()
          }}
        >
          Move all
        </Button>
      </Typography>

      <TableContainer
        component={Paper}
        sx={{
          opacity: data.reimbursements.length === 0 ? 0.35 : 1,
          overflowX: 'visible !important',
        }}
      >
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='right'>Tech</TableCell>
              <TableCell align='right'>Amount</TableCell>
              <TableCell align='right'>Note</TableCell>
              <TableCell align='right'>Photos</TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.reimbursements.map(reimbursement => (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align='right'>
                  <Link target='_blank' to={`/technicians/${reimbursement.technicianId}`}>
                    {technicianById(reimbursement.technicianId).firstName}{' '}
                    {technicianById(reimbursement.technicianId).lastName}
                  </Link>
                </TableCell>
                <TableCell align='right'>
                  <b>
                    <Dollars value={reimbursement.amount} />
                  </b>
                </TableCell>
                <TableCell align='right'>{reimbursement.note}</TableCell>
                <TableCell align='right'>
                  {reimbursement.photoUrls.length > 0 && (
                    <div>
                      {reimbursement.photoUrls.map(photoUrl => (
                        <div
                          onClick={() => window.open(photoUrl)}
                          style={{ display: 'inline-block', cursor: 'pointer' }}
                        >
                          <img src={photoUrl} width={50} height={50} />
                        </div>
                      ))}
                    </div>
                  )}
                </TableCell>
                <TableCell align='right'>
                  <button onClick={() => linkToAPayout(reimbursement.id)} disabled={waiting}>
                    link to payout
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Reimbursements
