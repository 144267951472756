import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { Dialog, Drawer } from '@mui/material'
import { InvoicesTable } from './InvoicesTable'

export default function InvoiceSelector({ handleClickLinkToStatement, handleClickLinkInvoiceNumbers }) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [insertedInvoiceNumbers, setInputValue] = React.useState('')
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleButtonClick = () => {
    if (!insertedInvoiceNumbers.length) return
    const generatedInvoiceIds = insertedInvoiceNumbers.split(' ')
    selectInvoices(generatedInvoiceIds)
  }

  const selectInvoices = async invoiceIds => {
    setLoading(true)
    try {
      const promises = invoiceIds.map(async invoiceId => {
        await handleClickLinkToStatement(invoiceId)
      })
      await Promise.all(promises)
      setLoading(false)
      handleClose()
    } catch (error) {
      alert('Error: ', error)
      setLoading(false)
      return
    }
  }

  return (
    <>
      <Button disabled={loading} onClick={handleOpen}>
        Search Invoices
      </Button>
      <Drawer
        anchor='bottom'
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { maxHeight: '80%' } }}
      >
        <Box sx={{ padding: '1rem' }}>
          <Box>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Add Invoices to Statement
            </Typography>
          </Box>
          <Box>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {loading && <CircularProgress />}
              <InvoicesTable selectInvoices={selectInvoices} isAddingToStatement={loading} statement={true} />
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}
