import { gql } from '@apollo/client'
import { makeStyles } from '@mui/styles'

export const removeFilter = ({ name, filter, setFilter }) => {
  const { [name]: removed, ...remainingFilters } = filter
  setFilter(remainingFilters)
}

export const setOrToggleOrder = ({ name, order, setOrder }) => {
  const { [name]: removed, ...remainingOrder } = order
  if (order[name] === 'asc') setOrder(remainingOrder)
  else setOrder({ [name]: order[name] ? 'asc' : 'desc', ...remainingOrder })
}

export const removeSearch = ({ field, search, setSearch }) => {
  const { [field]: removed, ...remainingSearches } = search
  setSearch(remainingSearches)
}

export const labelsList = [
  'Received',
  'Pending approval',
  'Approved',
  'Pending parts',
  'Ready for repair',
  'Repair completed',
  'Pending inspection',
  'Sand/Buff',
  'Redo (color match)',
  'Redo (other)',
  'Delivered',
].sort()

export const useStyles = makeStyles(theme => ({
  headerFormControl: {
    minWidth: '5em',
  },
  dateComponent: {
    fontSize: '12px',
    margin: '0px -100px -2px 0px',
    float: 'left',
    maxHeight: '27px',
    padding: '3px 7px',
  },
  dateDialog: {
    display: 'flex',
    '& div': {
      padding: '5px',
      '& span': {
        background: '#eee',
        display: 'block',
        padding: '3px',
        borderRadius: '3px 3px 0px 0px',
        border: '1px solid #ccc',
        borderBottom: '1px dashed #eee',
      },
      '& input': {
        padding: '3px',
        borderRadius: '0px 0px 3px 3px',
        border: '1px solid #ccc',
        borderTop: '0px',
      },
    },
  },
}))

export const B2BTechs = gql`
  query bToBTechnicians {
    bToBTechnicians {
      id
      name
      firstName
      lastName
      market
      displayMarket
      active
      state
      offdays
      isBToB
      isEmployee
      availability
      note
    }
  }
`
export const DEALERS_QUERY = gql`
  query BToBDealers {
    bToBDealers {
      id
      businessName
      isActive
    }
  }
`

export const BToBInvoicesConnection = gql`
  query BToBInvoicesConnection(
    $search: JSON
    $order: JSON
    $filter: JSON
    $first: Int
    $after: String
    $isCompleted: Boolean
    $isAccepted: Boolean
    $hasPayoutData: Boolean
    $excludeFullyPaid: Boolean
    $hasPayoutId: Boolean
  ) {
    bToBInvoicesConnection(
      search: $search
      order: $order
      filter: $filter
      first: $first
      after: $after
      isCompleted: $isCompleted
      isAccepted: $isAccepted
      hasPayoutData: $hasPayoutData
      excludeFullyPaid: $excludeFullyPaid
      hasPayoutId: $hasPayoutId
    ) {
      edges {
        cursor
        node {
          id
          createdAt
          acceptedAt
          businessName
          lineItems
          lineItemsSum
          grandTotal
          discountPercent
          discountPercentTotal
          preJobPhotos
          postJobPhotos
          year
          make
          makeShortName
          model
          modelShortName
          payoutData
          vin
          vinPhoto
          completedAt
          repairOrder
          stock
          deletedAt
          payoutId
          squareInvoiceId
          paidSquareInvoiceId
          projectedPayout
          fullyPaidAt
          invoiceNumber
          ccFee
          invoiceVersion
          dealerId
          emailSentAt
          commissionPercent
          payoutOverrideTotal
          label
          markedAsPromotionalRepairAt
          statementId
          dealer {
            id
            businessName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            primaryContact {
              firstName
              lastName
              email
            }
          }
          technician {
            id
            firstName
            lastName
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
