import { useContext, useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Switch,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { basicDateTimeFormat } from 'tools'
import { DateTime } from 'luxon'
import TireMarkupBracketRow from './components/TireMarkupBracketRow'
import LaborItemRow from './components/LaborItemRow'

const PRICING_QUERY = gql`
  query tireConnectPricing {
    tireConnectPricing {
      updatedAt
      updatedBy {
        firstName
        lastName
      }
      tireMarkupBrackets {
        id
        limit
        cashRate
      }
      laborItems {
        id
        name
        description
        price
        useMarketUpcharges
        quantitySource
      }
    }
  }
`

const pricingSchema = Yup.object().shape({
  calibrationDynamic: Yup.number().required(),
  calibrationStatic: Yup.number().required(),
})

export const Index = () => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const { loading, error, data, refetch } = useQuery(PRICING_QUERY)
  const [editingTireMarkupBracket, setEditingTireMarkupBracket] = useState('')

  const [newItemName, setNewItemName] = useState('')
  const [newItemDescription, setNewItemDescription] = useState('')
  const [newItemPrice, setNewItemPrice] = useState('')
  const [newItemCashRate, setNewItemCashRate] = useState('')
  const [newItemLimit, setNewItemLimit] = useState('')
  const [useMarketUpcharges, setUseMarketUpcharges] = useState(false)
  const [quantitySource, setQuantitySource] = useState('1 per quote')

  const formik = useFormik({
    validationSchema: pricingSchema,
    enableReinitialize: true,
    onSubmit: values => {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/tire_connect_update_pricing`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          ...values,
        }),
      })
        .then(res => (res.ok ? refetch() : alert('error')))
        .catch(err => alert(err))
    },
  })

  if (loading) return 'loading...'
  if (error) return JSON.stringify(error)

  function onClickNewBracket() {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/tire_connect_create_pricing_markup_bracket`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ userId: user.id, limit: newItemLimit, cashRate: newItemCashRate }),
    })
      .then(res => (res.ok ? refetch() : alert('error')))
      .then(() => {
        setNewItemCashRate('')
        setNewItemLimit('')
      })
      .catch(err => alert(err))
  }

  function onClickNewLaborItem() {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/tire_connect_create_labor_item`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        name: newItemName,
        price: newItemPrice,
        useMarketUpcharges,
        quantitySource,
      }),
    })
      .then(res => (res.ok ? refetch() : alert('error')))
      .then(() => {
        setNewItemName('')
        setNewItemPrice('')
        setUseMarketUpcharges(false)
      })
      .catch(err => alert(err))
  }

  const validationErrors = Object.values(
    Object.keys(formik.errors).reduce((acc, k) => {
      acc[k] = formik.errors[k]
      return acc
    }, {})
  )

  return (
    <>
      <Container>
        <Box
          component='form'
          sx={{
            '& .MuiFormControl-root': { m: 1 },
          }}
          noValidate
          autoComplete='off'
          onSubmit={formik.handleSubmit}
        >
          <Typography variant='h6'>Tire Connect Pricing</Typography>

          <div>
            <>Last updated by </>
            <>
              {data.tireConnectPricing.updatedBy.firstName} {data.tireConnectPricing.updatedBy.lastName[0]}.
            </>
            <> at </>
            <>{DateTime.fromISO(data.tireConnectPricing.updatedAt).toFormat(basicDateTimeFormat)}</>
          </div>
          <hr />
          <div style={{ color: 'red' }}>
            {validationErrors.map(error => (
              <div key={error}>{error}</div>
            ))}
          </div>
        </Box>

        <Box>
          <div>
            <b>Tire Labor Items</b>
            <Box sx={{ display: 'flex' }}>
              <TextField
                value={newItemName}
                onChange={e => setNewItemName(e.target.value)}
                placeholder='item name...'
                size='small'
              />
              <TextField
                value={newItemDescription}
                onChange={e => setNewItemDescription(e.target.value)}
                placeholder='description'
                size='small'
              />
              <TextField
                type='number'
                value={newItemPrice}
                onChange={e => setNewItemPrice(e.target.value)}
                placeholder='price'
                size='small'
                sx={{ ml: '.5rem', mr: '.5rem' }}
              />
              <FormControlLabel
                label='Use Market Upcharges'
                control={
                  <Switch
                    size='small'
                    checked={useMarketUpcharges}
                    onClick={() => {
                      setUseMarketUpcharges(!useMarketUpcharges)
                    }}
                  />
                }
              />

              <FormControl size='small'>
                <Select size='small' value={quantitySource} onChange={e => setQuantitySource(e.target.value)}>
                  <MenuItem value='1 per quote'>1 per quote</MenuItem>
                  <MenuItem value='1 per tire'>1 per tire</MenuItem>
                </Select>
              </FormControl>

              <Button disabled={newItemName === ''} onClick={onClickNewLaborItem} sx={{ float: 'right' }}>
                new item
              </Button>
            </Box>
            <hr />

            <Grid container xs={12} sx={{ m: 4 }}>
              <Grid item xs={2}>
                Name
              </Grid>
              <Grid item xs={2}>
                Description
              </Grid>
              <Grid item xs={2}>
                Price
              </Grid>
              <Grid item xs={2}>
                Quantity / Use market upcharges
              </Grid>
            </Grid>
            {data.tireConnectPricing.laborItems.map(item => (
              <LaborItemRow
                key={item.id}
                data={item}
                editing={editingTireMarkupBracket}
                setEditing={setEditingTireMarkupBracket}
                refetch={refetch}
              />
            ))}
          </div>
        </Box>
        <hr />
        <Box>
          <div>
            <b>Tire Price Markup Brackets</b>

            <Box sx={{ display: 'flex' }}>
              <TextField
                value={newItemLimit}
                onChange={e => setNewItemLimit(e.target.value)}
                placeholder='limit'
                size='small'
              />
              <TextField
                type='number'
                value={newItemCashRate}
                onChange={e => setNewItemCashRate(e.target.value)}
                placeholder='cash rate'
                size='small'
                sx={{ ml: '.5rem', mr: '.5rem' }}
              />
              <Button onClick={onClickNewBracket} sx={{ float: 'right' }}>
                new bracket
              </Button>
            </Box>
            <hr />

            <Grid container xs={12} sx={{ m: 4 }}>
              <Grid item xs={3}>
                Limit
              </Grid>
              <Grid item xs={3}>
                Cash Rate
              </Grid>
              <Grid item xs={3} />
            </Grid>
            {data.tireConnectPricing.tireMarkupBrackets.map(bracket => (
              <TireMarkupBracketRow
                key={bracket.id}
                data={bracket}
                editing={editingTireMarkupBracket}
                setEditing={setEditingTireMarkupBracket}
                refetch={refetch}
              />
            ))}
          </div>
        </Box>
      </Container>
    </>
  )
}

export default Index
