import { useState, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { keysToCamel } from 'tools'
import { Link } from 'react-router-dom'
import { Collapse, Button } from '@mui/material'

import { UserContext } from 'UserStore'
import { ArrowDownward } from '@mui/icons-material'

const LeadDuplicatesWarning = ({ lead, refetch }) => {
  const [user] = useContext(UserContext)
  const [open, setOpen] = useState(false)
  const { duplicatedLeads } = lead

  if (duplicatedLeads.length === 0) return null

  const sorted = duplicatedLeads.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))

  return (
    <div style={{ border: '2px dashed orange', padding: 4, margin: 2, fontSize: 16 }}>
      <Button
        color='error'
        sx={{ color: 'red', fontSize: '12px', '& svg': { fontSize: '14px', transform: open ? 'rotate(180deg)' : '' } }}
        onClick={() => setOpen(!open)}
      >
        <ArrowDownward sx={{ mr: '.5rem' }} />
        This lead has {duplicatedLeads.length} duplicate{duplicatedLeads.length > 1 ? 's' : ''}
        <ArrowDownward sx={{ ml: '.5rem' }} />
      </Button>
      &nbsp;&nbsp;
      <Collapse in={open}>
        <ul style={{ margin: 0 }}>
          {sorted.map(lead => (
            <li key={lead.id}>
              <Link to={`/leads/${lead.id}`}>{lead.name} {DateTime.fromISO(lead.createdAt).toFormat('f')}</Link>
              {lead.deletedAt && <> (deleted) </>}
            </li>
          ))}
        </ul>
      </Collapse>
    </div>
  )
}

export default LeadDuplicatesWarning
