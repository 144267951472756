import { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import LeadsReport from './Reports/LeadsReport'
import QuotesDump from './Reports/QuotesDump'
import QuotesDumpByFullyPaidAt from './Reports/QuotesDumpByFullyPaidAt'
import QuotesByCreatedAt from './Reports/QuotesByCreatedAt'
import TimeOff from './Reports/TimeOff'
import TimeTracking from './Reports/TimeTracking'
import LeadsReportBySoldAt from './Reports/LeadsReportBySoldAt'
import Sales from './Reports/Sales'
import { Tabs, Tab, Paper, Grid, Typography } from '@mui/material'
import PhoneActivity from './Reports/Phone/PhoneActivity'
import CallbackRequestsDump from './Reports/CallbackRequestsDump'
import TasksByCreatedAt from './Reports/TasksByCreatedAt'
import BToBInvoicesByCreatedAt from './Reports/BToBInvoicesByCreatedAt'
import InsuranceDashboard from './Reports/InsuranceDashboard'
import TimeCardsByDate from './Reports/TimeCardsByDate'
import EmployeePayoutsByCreatedAt from 'Reports/EmployeePayoutsByCreatedAt'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}))

const Reports = () => {
  const classes = useStyles()
  const [tab, setTab] = useState(localStorage.getItem('reportsTab') || 'exports')
  useEffect(() => localStorage.setItem('reportsTab', tab), [tab])

  const handleChangeTab = (_, newValue) => {
    setTab(newValue)
  }

  return (
    <div className={classes.root}>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab value='insurance' label='Insurance Dashboard' />
        <Tab value='phone' label='Phone Activity' />
        <Tab value='exports' label='Exports' />
      </Tabs>

      {tab === 'phone' && <PhoneActivity />}

      {tab === 'insurance' && <InsuranceDashboard />}

      {tab === 'exports' && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <TasksByCreatedAt />
              <br />
              <LeadsReport />
              <br />
              <LeadsReportBySoldAt />
              <br />
              <QuotesDump />
              <br />
              <QuotesDumpByFullyPaidAt />
              <br />
              <QuotesByCreatedAt />
              <br />
              <TimeOff />
              <br />
              <TimeTracking />
              <br />
              <Sales />
              <br />
              <CallbackRequestsDump />
              <br />
              <BToBInvoicesByCreatedAt />
              <br />
              <TimeCardsByDate />
              <br />
              <EmployeePayoutsByCreatedAt />
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default Reports
