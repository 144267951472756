import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { keysToCamel } from 'tools'
import Show from './Show'
import LeadsRouter from './Leads/Router'

const QUERY = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      id
      firstName
      lastName
      phone
      noClose
      noCloseRecentConvert
      outboundNumber
      botSessions {
        id
       completedAt
      }
    }
  }
`

const Router = () => {
  const { path } = useRouteMatch()
  const { customerId } = useParams()

  const { loading, error, data } = useQuery(QUERY, {
    variables: { id: customerId },
  })

  if (loading || error) return <div>Loading...</div>

  const customer = keysToCamel(data.customer)

  return (
    <Switch>
      <Route path={`${path}/leads`}>
        <LeadsRouter customer={customer} />
      </Route>
      <Route path={path}>
        <Show customer={customer} />
      </Route>
    </Switch>
  )
}

export default Router
