import { useContext, useState } from 'react'
import { Button, FormControl, Grid, MenuItem, Select, Switch, TextField } from '@mui/material'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'

const LaborItemRow = ({ data, editing, setEditing, refetch }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [name, setName] = useState(data.name)
  const [description, setDescription] = useState(data.description)
  const [useMarketUpcharges, setUseMarketUpcharges] = useState(data.useMarketUpcharges ? true : false)
  const [price, setPrice] = useState(data.price)
  const [quantitySource, setQuantitySource] = useState(data.quantitySource)

  function onClickEdit() {
    setEditing(data.id)
  }
  function onClickCancel() {
    setEditing('')
  }
  function onClickSave() {
    setEditing('')
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/omega_update_labor_item`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        laborItemId: data.id,
        name,
        useMarketUpcharges,
        price,
        description,
        quantitySource,
      }),
    })
      .then(res => (res.ok ? refetch() : alert('error')))
      .catch(err => alert(err))
  }
  function onClickDelete() {
    if (window.confirm(`Are you sure you want to delete this Tire Price Markup Bracket ($${data.name})?`))
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/omega_delete_labor_item`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          laborItemId: data.id,
        }),
      })
        .then(res => (res.ok ? refetch() : alert('error')))
        .catch(err => alert(err))
  }
  function onChangeName(e) {
    setName(e.target.value)
  }
  function onChangeDescription(e) {
    setDescription(e.target.value)
  }

  function onChangePrice(e) {
    setPrice(e.target.value)
  }

  const isEditing = data.id == editing

  return (
    <>
      <Grid container xs={12} sx={{ m: 4 }}>
        <Grid item xs={2}>
          {!isEditing && <b>{data.name}</b>}
          {isEditing && (
            <TextField variant='outlined' size='small' label='Name' name='name' value={name} onChange={onChangeName} />
          )}
        </Grid>
        <Grid item xs={2}>
          {!isEditing && <b>{data.description}</b>}
          {isEditing && (
            <TextField
              variant='outlined'
              size='small'
              label='Description'
              name='description'
              value={description}
              onChange={onChangeDescription}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          {!isEditing && data.price}
          {isEditing && (
            <TextField
              variant='outlined'
              size='small'
              label='Cash Rate'
              name='price'
              type='number'
              value={price}
              onChange={onChangePrice}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          {isEditing && (
            <FormControl size='small' sx={{ mr: '1rem' }}>
              <Select size='small' value={quantitySource} onChange={e => setQuantitySource(e.target.value)}>
                <MenuItem value='1 per quote'>1 per quote</MenuItem>
                <MenuItem value='1 per chip'>1 per chip</MenuItem>
                <MenuItem value='1 per replacement'>1 per replacement</MenuItem>
              </Select>
            </FormControl>
          )}

          {!isEditing && (
            <FormControl size='small' sx={{ mr: '1rem' }}>
              <Select disabled size='small' value={quantitySource} onChange={e => setQuantitySource(e.target.value)}>
                <MenuItem value='1 per quote'>1 per quote</MenuItem>
                <MenuItem value='1 per chip'>1 per chip</MenuItem>
                <MenuItem value='1 per replacement'>1 per replacement</MenuItem>
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={2}>
          {!isEditing && (
            <Button onClick={onClickEdit}>
              <EditIcon />
            </Button>
          )}
          {isEditing && (
            <>
              <Button onClick={onClickSave} sx={{ color: 'green' }}>
                <SaveIcon />
              </Button>
              <Button onClick={onClickCancel} sx={{ color: 'orange' }}>
                <CancelIcon />
              </Button>
              <Button onClick={onClickDelete} sx={{ color: 'red' }}>
                <DeleteIcon />
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default LaborItemRow
