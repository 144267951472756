import { useState, useContext } from 'react'
import Typography from '@mui/material/Typography'
import { useQuery, gql } from '@apollo/client'
import { UserContext } from 'UserStore'
import { basicDateTimeFormat } from 'tools'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, TextField } from '@mui/material'
import { DateTime } from 'luxon'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { ExpandMoreRounded } from '@mui/icons-material'

const QUERY = gql`
  query UnpaidTimeOff($technicianId: ID) {
    unpaidTimeOffs(technicianId: $technicianId) {
      id
      createdAt
      totalHours
      payoutId
      note
      deletedAt
      timeOffDate
      payout {
        id
        createdAt
      }
    }
  }
`

const UnpaidTimeOff = ({ technician }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [totalHoursAmount, setTotalHoursAmount] = useState(0)
  const [manualCompNote, setManualCompNote] = useState('')
  const [ptoDate, setPtoDate] = useState(DateTime.now())
  //
  const handleChangeNote = e => setManualCompNote(e.target.value)
  const handleChangeDate = e => setPtoDate(DateTime.fromISO(e.target.value))

  const handleClickCreatePaidTimeOff = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_unpaid_time_off`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        technician_id: technician.id,
        total_hours: Number(totalHoursAmount),
        note: manualCompNote,
        date: ptoDate,
      }),
    })
      .then(response => {
        if (response.ok) {
          refetch()
        } else {
          throw Error('error')
        }
      })
      .then(data => {
        setTotalHoursAmount(0)
        setManualCompNote('')
      })
      .catch(err => {
        window.alert(err)
      })

  const deletePaidTimeOff = id =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_unpaid_time_off`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        unpaid_time_off_id: id,
      }),
    }).then(res => refetch() || alert('Error'))

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { technicianId: technician.id },
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
          <Box>
            <Typography variant='h6' sx={{ color: 'red' }}>
              🚫 UN-Paid time off 🚫
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <b style={{ fontSize: '12px', display: 'block' }}>Any notes will be visible by the technician</b>

          <TextField
            type='date'
            style={{ maxWidth: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
            value={ptoDate.toFormat('kkkk-LL-dd')}
            label='date'
            variant='outlined'
            onChange={handleChangeDate}
          />

          <TextField
            label='hours'
            type='number'
            style={{ width: 120 }}
            value={totalHoursAmount}
            onChange={event => setTotalHoursAmount(Number(event.target.value))}
          />

          <TextField value={manualCompNote} label='Note' variant='outlined' multiline onChange={handleChangeNote} />

          <Button size='small' variant='outlined' onClick={handleClickCreatePaidTimeOff}>
            Create
          </Button>

          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align='right'>date</TableCell>
                  <TableCell align='right'>total hours</TableCell>
                  <TableCell align='right'>note</TableCell>
                  <TableCell align='right'>payout?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.unpaidTimeOffs
                  .filter(re => !re.deletedAt)
                  .map(pto => (
                    <TableRow key={pto.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align='right'>
                        {DateTime.fromISO(pto.timeOffDate, { zone: 'utc' }).toFormat('kkkk-LL-dd')}
                      </TableCell>
                      <TableCell align='right'>{pto.totalHours}</TableCell>
                      <TableCell align='right'>{pto.note}</TableCell>
                      <TableCell align='right'>
                        {pto.payout ? (
                          <>{DateTime.fromISO(pto.payout.createdAt).toFormat(basicDateTimeFormat)}</>
                        ) : (
                          <>
                            {' '}
                            not linked <button onClick={() => deletePaidTimeOff(pto.id)}>Delete</button>{' '}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default UnpaidTimeOff
