import { gql, useQuery } from '@apollo/client'
import { AddOutlined, CheckCircle, ChevronRight, PublicOutlined } from '@mui/icons-material'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  tooltipClasses,
} from '@mui/material'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useContext, useState } from 'react'
import { CreatePrefabMessage } from './components/CreatePrefabMessage'
import { PrefabMessage } from './components/PrefabMessage'
import { styled } from '@mui/styles'

export const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 420,
      padding: '0rem',
      borderRadius: '6px',
      boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)',
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #ddd',
    },
  })
)

export const PREFAB_MESSAGES = gql`
  query PrefabMessages($targetType: String, $targetId: String) {
    prefabMessages(targetType: $targetType, targetId: $targetId) {
      id
      label
      systemAction
      messageContent
      expandedMessageContent
      targetType
      createdByUserId
    }
  }
`

export const PrefabMessages = ({
  inputRef,
  setBody,
  body,
  selectedPrefab,
  setSelectedPrefab,
  contextType,
  contextId,
  children,
}) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [currentTab, setCurrentTab] = useLocalStorage('prefabMessagingVersion', 'v1')
  const [prefabsOpen, setPrefabsOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [editablePrefab, setEditablePrefab] = useState({})

  const { data, loading, error, refetch } = useQuery(PREFAB_MESSAGES, {
    variables: { targetType: contextType, targetId: contextId },
    skip: !contextId || !contextType,
  })

  const handleGetPrefab = e => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_prefab_text`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        contextType,
        contextId,
        type: e,
      }),
    })
      .then(res => res.json())
      .then(data => {
        setBody(data.text.trim())
      })
      .catch(() => alert('Error'))
  }

  const prefabTooltipOptionsByContextType = () => {
    switch (contextType) {
      case 'Quote':
        return [
          ['initialQuote', 'Initial quote'],
          ['oemPrePayment', 'OEM Prepayment'],
          ['offerAppointment', 'Offer appointment time'],
        ].map(([value, title]) => (
          <ListItem
            onClick={() => {
              setSelectedPrefab(title)
              handleGetPrefab(value)
              setTimeout(() => inputRef.current?.focus(), 0)
              setPrefabsOpen(false)
            }}
            key={value}
            disablePadding
            sx={{
              '& .MuiListItemButton-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
            }}
          >
            <ListItemButton>
              {title} <ChevronRight />
            </ListItemButton>
          </ListItem>
        ))
      case 'Lead':
        return [
          ['morePhotos', 'Request more photos'],
          ['requestAddress', 'Request address'],
          ['requestPanelSpecify', 'Request panel specification'],
          ['requestVin', 'Request VIN'],
          ['acknowledgeComplaint', 'Acknowledge complaint'],
        ].map(([value, title, timestamp]) => (
          <ListItem
            key={value}
            disablePadding
            sx={{
              '& .MuiListItemButton-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
            }}
          >
            <ListItemButton
              onClick={() => {
                setSelectedPrefab(title)
                handleGetPrefab(value)
                setTimeout(() => inputRef.current?.focus(), 0)
                setPrefabsOpen(false)
              }}
            >
              {title} {timestamp ? <CheckCircle /> : <ChevronRight />}
            </ListItemButton>
          </ListItem>
        ))
      case 'Job':
        return [
          ['appointmentConfirmation', 'Appointment Confirmation'],
          ['oemPrePayment', 'OEM Prepayment'],
        ].map(([value, title]) => (
          <ListItem
            key={value}
            disablePadding
            sx={{
              '& .MuiListItemButton-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
            }}
          >
            <ListItemButton
              onClick={() => {
                setSelectedPrefab(title)
                handleGetPrefab(value)
                setTimeout(() => inputRef.current?.focus(), 0)
                setPrefabsOpen(false)
              }}
            >
              {title} <ChevronRight />
            </ListItemButton>
          </ListItem>
        ))
      default:
        return ''
    }
  }

  const savedPrefabMessages = data?.prefabMessages || []

  const contents = (
    <Box
      sx={{
        minWidth: '350px',
        maxWidth: '350px',
        maxHeight: '700px',
        overflowY: 'auto',
        display: !!selectedPrefab ? 'none' : '',
      }}
    >
      {currentTab === 'v1' && (
        <Typography
          sx={{
            borderBottom: '1px solid #ddd',
            color: '#777',
            fontSize: '16px',
            fontWeight: 500,
            padding: '.5rem',
          }}
          variant='h1'
        >
          About to do any of the following?
        </Typography>
      )}
      <List
        sx={{
          '& .MuiListItem-root': {
            background: '#fff',
            '&:hover': { cursor: 'pointer', background: '#f1f1f1' },
          },
        }}
      >
        {currentTab === 'v1' && prefabTooltipOptionsByContextType()}

        {currentTab === 'v2' && user?.roles?.includes('admin') && (
          <>
            <ListItem
              disablePadding
              sx={{
                '& .MuiListItemButton-root': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                },
              }}
              onClick={() => {
                setPrefabsOpen(true)
              }}
            >
              <ListItemButton>
                Add <AddOutlined />
              </ListItemButton>
            </ListItem>

            <Divider />
          </>
        )}

        {currentTab === 'v2' && (
          <Box>
            <Box
              sx={{
                display: 'flex',
                padding: '.25rem .5rem',
                alignItems: 'center',
                background: '#f1f1f1',
                position: 'sticky',
                top: '0rem',
              }}
            >
              <PublicOutlined sx={{ fontSize: '12px', mr: '.25rem' }} /> Global Prefabs
            </Box>
            {savedPrefabMessages
              .filter(i => i.targetType === 'all')
              .map(msg => (
                <PrefabMessage
                  onClick={() => {
                    setSelectedPrefab('other')
                    setBody(msg.expandedMessageContent.trim())
                    setPrefabsOpen(false)
                    msg.systemAction !== '' && setSelectedPrefab(msg.systemAction)
                  }}
                  onEditClick={(e, prefab) => {
                    setEditablePrefab(prefab)
                    setEditOpen(true)
                  }}
                  key={msg.label}
                  title={msg.label}
                  tooltip={msg.expandedMessageContent}
                  refetch={refetch}
                  msg={msg}
                />
              ))}

            <Divider />
            <Box
              sx={{
                display: 'flex',
                padding: '.25rem .5rem',
                background: '#f1f1f1',
                position: 'sticky',
                top: '0rem',
              }}
            >
              {contextType} Prefabs
            </Box>

            {savedPrefabMessages
              .filter(i => i.targetType !== 'all')
              .map(msg => (
                <PrefabMessage
                  onClick={(e, prefab) => {
                    setSelectedPrefab('other')
                    setBody(msg.expandedMessageContent.trim())
                    setPrefabsOpen(false)

                    msg.systemAction !== '' && setSelectedPrefab(msg.systemAction)
                  }}
                  onEditClick={(e, prefab) => {
                    setEditablePrefab(prefab)
                    setEditOpen(true)
                  }}
                  key={msg.label}
                  title={msg.label}
                  tooltip={msg.expandedMessageContent}
                  refetch={refetch}
                  msg={msg}
                />
              ))}
          </Box>
        )}

        <Divider />
        <ListItem
          disablePadding
          sx={{
            '& .MuiListItemButton-root': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
          }}
          onClick={() => {
            setTimeout(() => inputRef.current?.focus(), 0)
            setSelectedPrefab('other')
            setPrefabsOpen(false)
          }}
        >
          <ListItemButton>
            Other <ChevronRight />
          </ListItemButton>
        </ListItem>
      </List>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pb: '.5rem' }}>
        <Switch
          size='small'
          checked={currentTab === 'v2'}
          onClick={() => setCurrentTab(prev => (prev === 'v2' ? 'v1' : 'v2'))}
        />
        <Box> Use v2 </Box>{' '}
      </Box>
    </Box>
  )

  return (
    <>
      <CreatePrefabMessage
        id='new-prefab'
        titleText='New Prefab'
        submitText='Create'
        onSubmit={editablePrefab =>
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_prefab_message`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              user_id: user.id,
              ...editablePrefab,
            }),
          })
            .then(res => (res.ok ? refetch() : alert('Error!')))
            .then(() => setPrefabsOpen(false))
        }
        contextType={contextType}
        open={prefabsOpen}
        handleClose={e => {
          setPrefabsOpen(false)
          setEditablePrefab({})
        }}
        refetch={refetch}
      />

      {editablePrefab?.id && (
        <CreatePrefabMessage
          id='edit-prefab'
          titleText='Edit Prefab'
          onSubmit={e =>
            fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_prefab_message_attributes`, {
              method: 'POST',
              headers: bearerTokenHeaders,
              body: JSON.stringify({
                message_id: editablePrefab.id,
                updated_attributes: {
                  ...e,
                },
              }),
            })
              .then(res => (res.ok ? refetch() : alert('Error!')))
              .then(() => {
                setEditOpen(false)
                setEditablePrefab({})
              })
          }
          submitText='Save'
          contextType={editablePrefab?.targetType}
          open={editOpen}
          handleClose={e => {
            setEditOpen(false)
            setEditablePrefab({})
          }}
          cached={{
            label: editablePrefab.label,
            system_action: editablePrefab.systemAction,
            message_content: editablePrefab.messageContent,
            target_type: editablePrefab.targetType,
          }}
        />
      )}

      <HtmlTooltip
        arrow={!!selectedPrefab ? false : true}
        open={body === '' ? undefined : false}
        placement='top'
        title={contents}
      >
        {children}
      </HtmlTooltip>
    </>
  )
}
