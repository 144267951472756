import { useState, useContext, useEffect } from 'react'
import { UserContext } from 'UserStore'
import { Button, Dialog, Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PartVendorSelect from 'components/PartVendorSelect'
import DialogTitle from 'components/DialogTitle'
import { classes as DialogSubheaderClasses, DialogSubheader } from 'components/DialogSubheader'
import TextField from '@mui/material/TextField'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { fieldHasError, fieldHelperText } from 'tools'

const DialogContent = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
    },
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions)

const AddVendorDialog = ({ lead, parts, isOpen, onClose }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)

  useEffect( () => {
    const vendor = parts[0]?.vendor
    formik.setFieldValue('partVendorId', vendor ? vendor.id : '')
    formik.setFieldValue('partVendorName', vendor ? vendor.name : '')
    formik.setFieldValue('partVendorContact', vendor ? parts[0]?.partVendorContact : '')
  }, [isOpen])

  const validationSchema = Yup.object().shape({
    userId: Yup.string().required('This field is required.'),
    partIds: Yup.array().of(Yup.string()).min(1, 'At least one part is required.'),
    partVendorId: Yup.string().required('This field is required.'),
    partVendorName: Yup.string(),
    partVendorContact: Yup.string(),
  })

  const onSubmit = data => {
    setWaiting(true)

    const promises = data.partIds.map(partId => {
      const payload = {
        partId: partId,
        userId: data.userId,
        updatedAttributes: {
          partVendorId: data.partVendorId,
          partVendorName: data.partVendorName,
          partVendorContact: data.partVendorContact,
        }
      }

      return window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_part_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify(payload),
        })
        .then(res => (res.ok ? onClose() : window.alert('Error')))
    })

    Promise.all(promises).finally(() => setWaiting(false))
  }

  const formik = useFormik({
    initialValues: {
      userId: user.id,
      partIds: [parts.map(o => o.id)],
      partVendorId: '',
      partVendorName: '',
      partVendorContact: '',
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })

  const handleClose = (e, reason) => {
    e.stopPropagation()
    if (reason === 'backdropClick') return

    onClose()
  }

  useEffect(() => {
    if (!isOpen) {
      setWaiting(false)
      formik.resetForm()
    } else {
      formik.setFieldValue(
        'partIds',
        parts.map(o => o.id)
      )
    }
  }, [isOpen])

  const hasOneOrMoreTires = (parts?.find(part => part.type === 'tire') && true) || false

  return (
    <>
      <Dialog fullWidth maxWidth='sm' onClose={handleClose} aria-labelledby='order-details' open={isOpen}>
        <DialogTitle id='order-details' onClose={handleClose}>
          Vendor Details
        </DialogTitle>
        <DialogSubheader className={DialogSubheaderClasses.root}>
          {parts ? parts.map(o => o.name).join(', ') : ''}
        </DialogSubheader>
        <DialogContent sx={{ padding: '32px 24px' }} dividers>
          <Grid container spacing={3} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
            <Grid item xs={12}>
              <h3 style={{ margin: '0 8px' }}>Merchant Info</h3>
              <PartVendorSelect
                name='partVendorId'
                vendorState={lead.state}
                value={
                  formik.values.partVendorId
                    ? {
                        id: formik.values.partVendorId,
                        name: formik.values.partVendorName,
                      }
                    : undefined
                }
                onChange={vendor => {
                  formik.setFieldValue('partVendorId', vendor ? vendor.id : '')
                  formik.setFieldValue('partVendorName', vendor ? vendor.name : '')
                }}
                inputProps={{
                  name: 'partVendorId',
                  error: fieldHasError(formik, 'partVendorId'),
                  required: true,
                }}
                disabled={waiting}
                helperText={fieldHelperText(formik, 'partVendorId')}
                hasOneOrMoreTires={hasOneOrMoreTires}
              />
              <TextField
                fullWidth
                name='partVendorContact'
                variant='outlined'
                label='Merchant Contact'
                disabled={waiting}
                value={formik.values.partVendorContact}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' disabled={waiting} onClick={formik.handleSubmit}>
            {!waiting ? 'Submit' : 'Saving'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddVendorDialog
