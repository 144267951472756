import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Card, CardMedia, CardContent, Chip } from '@mui/material'

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import useKeypress from 'react-use-keypress'
import { thumbnailUrlForPhoto, fullSizeUrlForPhoto, formatPhotoTimestamp } from 'tools'

const useStyles = makeStyles(theme => ({
  fullSizeImageContainer: {
    textAlign: 'right',
    height: '700px',
    position: 'relative',
    '& .react-transform-wrapper': {
      width: '100%',
    },
  },
  fullSizeImage: {
    maxHeight: '700px',
  },
  zoomTools: {
    position: 'absolute',
    top: '5px',
    left: '5px',
    textAlign: 'left',
    zIndex: 1,

    '& button': {
      padding: '8px 12px',
      marginRight: '8px',
      borderRadius: '4px',
      fontWeight: 600,
    },
  },
  buttonPrev: {
    position: 'absolute',
    left: 0,
    padding: '8px',
    border: '1px solid',
    width: '30px',
  },
  photoCard: {
    cursor: 'pointer',
    minHeight: '140px',
    width: '160px',
    margin: '0 5px',
    fontSize: '13px',
    display: 'inline-block',
  },
  thumbnailContainer: {
    position: 'relative',
    marginTop: theme.spacing(1),

    '& .alice-carousel__stage-item': {
      maxWidth: '20%!important',
    },
    '& .photoCard': {
      maxWidth: '100%',
    },
  },
  arrowContainer: {
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.3)',
    zIndex: 100,
    position: 'absolute',
    top: '25%',
    padding: '0.5em',
    fontSize: '1.5em',
    cursor: 'pointer',
    height: '50%',
    display: 'flex',
    alignItems: 'center',
  },
}))

const LeadPhotoCarousel = ({ lead, quote, setCurrentPhotoId }) => {
  const classes = useStyles()
  const [photoIndex, setPhotoIndex] = useState(0)
  const [showFull, setShowFull] = useState(false)

  useKeypress('Escape', () => {
    setShowFull(false)
    setCurrentPhotoId('')
  })

  return (
    <Box>
      <Box style={{ display: showFull ? 'flex' : 'none' }} className={classes.fullSizeImageContainer}>
        <TransformWrapper centerOnInit key={photoIndex}>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
            return (
              <>
                <div className={classes.zoomTools}>
                  <b style={{ color: 'red' }}>(esc to close)</b>
                  <br />
                  <button onClick={() => zoomOut()}>-</button>
                  <button onClick={() => zoomIn()}>+</button>
                  <br />
                  <button style={{ marginTop: '8px' }} onClick={() => resetTransform()}>
                    reset
                  </button>
                </div>

                <TransformComponent className={classes.fullSizeImage}>
                  <img className={classes.fullSizeImage} src={fullSizeUrlForPhoto(lead.photos[photoIndex])} alt='' />
                </TransformComponent>
              </>
            )
          }}
        </TransformWrapper>
      </Box>

      <div className={classes.thumbnailContainer}>
        {lead.photos.map((photo, i) => (
          <Card
            key={photo.id}
            className={classes.photoCard}
            onClick={() => {
              setPhotoIndex(i)
              setShowFull(true)
              setCurrentPhotoId(photo.id)
            }}
          >
            {formatPhotoTimestamp(photo.timestamp)}

            <b style={{ fontSize: '12px', float: 'right', marginTop: '.125rem' }}>
              {photo.hiddenAt ? 'HIDDEN' : 'VISIBLE'}
            </b>
            <CardMedia image={thumbnailUrlForPhoto(photo)} style={{ height: 0, paddingTop: '100%', margin: '3px' }} />
            <CardContent style={{ margin: 0, padding: '8px' }}>
              {/* labor chips */}
              {quote.primaryLaborItems
                .filter(laborObject => laborObject.photoId === photo.id)
                .map(labor => (
                  <Chip
                    key={labor.id}
                    style={{
                      fontSize: '11px',
                      backgroundColor: 'lightcyan',
                      border: '1px solid darkgray',
                    }}
                    label={labor.name}
                    size='small'
                  />
                ))}

              {quote.otherLaborItems
                .filter(laborObject => laborObject.photoId === photo.id)
                .map(labor => (
                  <Chip
                    key={labor.id}
                    style={{
                      fontSize: '11px',
                      backgroundColor: 'lightcyan',
                      border: '1px solid darkgray',
                    }}
                    label={labor.name}
                    size='small'
                  />
                ))}

              {/* part chips */}
              {quote.parts
                .filter(part => part.photoId === photo.id)
                .map(part => (
                  <Chip
                    key={part.id}
                    style={{
                      fontSize: '11px',
                      backgroundColor: 'red',
                      border: '1px solid darkgray',
                    }}
                    label={part.name}
                    size='small'
                  />
                ))}
            </CardContent>
          </Card>
        ))}
      </div>
    </Box>
  )
}

export default LeadPhotoCarousel
