import React, { useState, useEffect, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'

import { makeStyles } from '@mui/styles'
import partStatusClasses from 'Parts/part_status.module.css'

import { UserContext } from 'UserStore'
import { DateTime } from 'luxon-business-days'

import {
  Paper,
  Box,
  Grid,
  Checkbox,
  TextField,
  Typography,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
} from '@mui/material'

import Notes from 'components/Notes'
import TechNotes from 'components/TechNotes'
import Breadcrumbs from 'components/Breadcrumbs'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { PriceInput, claimTaskId, Dollars, basicDateTimeFormat, underscoreToTitleCase, pluralizeText } from 'tools'
import { GlobalContext } from 'GlobalStore'
import TechNotesForJobsUnderQuote from 'components/TechNotesForJobsUnderQuote'
import ConfirmDialog from 'components/ConfirmDialog'
import Tasks, { CreateATask } from 'Tasks'
import Jobs from '../../Quote/components/Jobs'
import OrderPartDialog from './OrderPartDialog'
import PartAndSiblings from './PartAndSiblings'
import NumberFormat from 'react-number-format'
import { RefetchRegistry } from 'RefetchRegistry'
import { UpdateRefetchRegistry } from 'UpdateRefetchRegistry'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { CustomerContext } from 'CustomerStore'
import StreamDump from 'components/StreamDump'
import AddVendorDialog from './AddVendorDialog'
import { TireConnectSupplier } from './TireConnectSupplier'

const PARTS_BY_QUOTE_ID = gql`
  query parts($quoteId: ID!) {
    parts(quoteId: $quoteId) {
      id
      quotePart
      quotePartId
      quoteId
      status
      createdAt
      removedFromQuoteAt
      type
      status
      price
      cost
      name
      number
      prePaymentRequired
      referenceNumber
      quotePartsPo
      pickupStatus
      pickupHistory
      etaRequestedAt
      etaReceivedAt
      readyForPickupAt
      pickedUpAt
      notPickedUpAt
      disputeInitiatedAt
      disputeResolvedAt
      orderRequestedAt
      orderPlacedAt
      canceledAt
      etaBusinessDays
      lostAt
      specialOrder
      ccLastFour
      isPaid
      partVendorId
      invoiceNumber
      refundInitiatedAt
      refundCompletedAt
      refundedAt
      returnInitiatedAt
      returnedToVendorAt
      returnCompletedAt
      returnAmount
      hasCoreRefund
      coreRefundExpectedAmount
      coreRefundReceivedAmount
      coreRefundInitiatedAt
      coreReturnedToVendorAt
      coreRefundCompletedAt
      coreRefundAmount
      specialOrder
      tireConnectData
      tireConnectOrderData
      vendor {
        id
        name
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  pickup_status: {
    fontWeight: 'bold',
  },
  part_card: {
    position: 'relative',
    padding: '1em',
  },
  purchaseOrder: {
    marginBottom: '0.5em',
    textDecoration: 'underline',
    color: '#444',
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  returnStatusContainer: {
    borderRadius: '4px',
    border: '2px solid red',
  },
  borderBlack: {
    borderColor: 'black',
  },
}))

const isEmpty = val => !val || val === ''

const PartEta = ({ part }) => {
  const orderDate = part.orderPlacedAt
  const businessDays = part.etaBusinessDays || 0
  const etaDate = DateTime.fromISO(orderDate || DateTime.now()).plusBusiness({ days: businessDays })
  const diffDays = etaDate && Math.ceil(etaDate.diff(DateTime.now().toUTC(), 'days').as('days'))

  const etaString = () => {
    if (part.readyForPickupAt) {
      return <b className={partStatusClasses.color_ready_for_pickup}>ready for pickup</b>
    }
    const str = etaDate.toFormat('L/d/yy')
    if (diffDays === 0) {
      return str + ' (today)'
    }
    if (diffDays < 0) {
      return str + ' (' + Math.abs(diffDays) + ' days ago)'
    }
    return str + ' (' + diffDays + ' days)'
  }

  return (
    <>
      {orderDate && businessDays ? (
        etaString()
      ) : businessDays ? (
        <span style={{ color: 'red' }}>
          {businessDays + ' business days from purchase (' + etaDate.toFormat('L/d/yy') + ' if purchased today)'}
        </span>
      ) : (
        'unknown'
      )}
    </>
  )
}

const Address = ({ addr }) => {
  return (
    <>
      <div>{addr.addressLineOne}</div>
      {addr.addressLineTwo ? <div>{addr.addressLineTwo}</div> : null}
      <div>
        {addr.city}, {addr.state} {addr.zip}
      </div>
      <div>
        <NumberFormat value={addr.phone} displayType='text' format='(###) ###-####' />
      </div>
    </>
  )
}

const returnStatusText = part => {
  if (!part.returnedToVendorAt) {
    return 'Waiting for Tech to Return Part'
  }
  if (part.returnCompletedAt) {
    return 'Complete'
  }
  return 'Awaiting Refund'
}

const coreReturnStatusText = part => {
  if (!part.coreReturnedToVendorAt) {
    return 'Waiting for Tech to Return Core'
  }
  if (part.coreRefundCompletedAt) {
    return 'Complete'
  }
  return 'Awaiting Core Refund'
}

const refundStatusText = part => (!part.refundCompletedAt ? 'Awaiting Refund' : 'Complete')

const DaysAgo = ({ date }) => {
  if (!date) {
    return ''
  }

  const diffDays = Math.ceil(DateTime.fromISO(date).diff(DateTime.now().toUTC(), 'days').as('days'))
  return `(${diffDays} ${pluralizeText('day', diffDays)} ago)`
}

const formatDate = (dt, emptyStr) => (dt ? DateTime.fromISO(dt).toFormat(basicDateTimeFormat) : emptyStr)

const ShowEdit = ({ lead, quote, part }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const classes = useStyles()
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)
  const [editablePart, setEditablePart] = useState(part)
  const [showEditCost, setShowEditCost] = useState(false)
  const [addVendorDialogOpen, setAddVendorDialogOpen] = useState(false)
  const [showEditQuoteN, setShowEditQuoteN] = useState(false)
  const [orderPartDialogOpen, setOrderPartDialogOpen] = useState(false)
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false)
  const [processingCancel, setProcessingCancel] = useState(false)
  const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  const [registerUpdateRefetch, unregisterUpdateRefetch] = useContext(UpdateRefetchRegistry)
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)

  useEffect(() => {
    const key = registerTaskTarget({ targetType: 'Part', targetId: part.id })
    return () => unregisterTaskTarget(key)
  }, [])

  const { loading, error, data, refetch } = useQuery(PARTS_BY_QUOTE_ID, {
    variables: {
      quoteId: part.quoteId,
    },
  })

  useEffect(() => {
    const key = registerRefetch({ types: ['Part'], refetch })
    return () => unregisterRefetch(key)
  }, [])

  useEffect(() => {
    const key = registerUpdateRefetch({ types: ['Part'], refetch })
    return () => unregisterUpdateRefetch(key)
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error! {error}</div>

  const siblings = data.parts.filter(thisPart => part.id !== thisPart.id)
  const externalHandlingBy =
    (part.tireConnectData && 'TireConnect') ||
    (part.nagsGlassId && 'OmegaEDI') ||
    (part.partsTechKeyword && 'PartsTech') ||
    ''
  const handlingDisabled = !!externalHandlingBy

  const handleToggleOrderRequested = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_order_requested`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        order_requested: !part.orderRequestedAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleToggleReadyForPickup = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_ready_for_pickup`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        ready_for_pickup: !part.readyForPickupAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleToggleOrderPlaced = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_order_placed`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        order_placed: !part.orderPlacedAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleToggleCanceled = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_canceled`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        canceled: !part.canceledAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleToggleReturnInitiated = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_return_initiated`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        return_initiated: !part.returnInitiatedAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleToggleReturnedToVendor = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_returned_to_vendor`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        returned: !part.returnedToVendorAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleToggleReturnCompleted = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_return_completed`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        return_completed: !part.returnCompletedAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleToggleCoreRefundInitiated = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_core_refund_initiated`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        core_refund_initiated: !part.coreRefundInitiatedAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleToggleCoreReturnedToVendor = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_core_returned_to_vendor`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        returned: !part.coreReturnedToVendorAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleToggleCoreRefundCompleted = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_core_refund_completed`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        core_refund_completed: !part.coreRefundCompletedAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleToggleInitiateRefund = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_refund_initiated`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        refund_initiated: !part.refundInitiatedAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleToggleRefunded = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_refunded`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        refunded: !part.refundedAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleToggleDisputeInitiated = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_dispute_initiated`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        dispute_initiated: !part.disputeInitiatedAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleToggleDisputeResolved = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_dispute_resolved`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        dispute_resolved: !part.disputeResolvedAt,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const updateAttributes = ({ payload, successCallback }) => {
    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_part_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.id,
        updated_attributes: payload,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .finally(() => {
        setWaiting(false)
        successCallback()
      })
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs lead={lead} quote={quote} part={part} activeObject={part} />
        </Grid>

        <Grid item xs={12} style={{ padding: '0 12px' }}>
          <Typography color='primary' variant='h6'>
            Jobs:
          </Typography>
          <Jobs hideCreateJob lead={lead} quote={quote} />
        </Grid>

        <Grid container spacing={3} style={{ padding: '0 12px' }}>
          <Grid item xs={6}>
            <div className={classes.purchaseOrder}>Purchase Order # {part.quotePartsPo}</div>
            <Paper
              className={[classes.part_card, partStatusClasses.border, partStatusClasses['border_' + part.status]].join(
                ' '
              )}
            >
              <div style={{ position: 'absolute', right: '10px' }}>
                <CreateATask targetType='Part' targetId={part.id} />
              </div>
              <div>
                <b>Part name:</b> {part.name}
              </div>
              <div>
                <b>Part number:</b> {part.number}
              </div>
              <div>
                <b>Part type:</b> {part.type}
              </div>
              <div>
                <b>Special order:</b> {part.specialOrder ? 'YES' : 'NO'}
              </div>
              <div>
                <b>Core refund:</b> {part.hasCoreRefund ? 'YES' : 'NO'} <br />
                <b>Core refund amount:</b>{' '}
                {part.coreRefundExpectedAmount ? <Dollars value={part.coreRefundExpectedAmount} /> : 'N/A'}
              </div>
              <div>
                <b>Part cost:</b> <Dollars value={part.cost} />{' '}
                <span onClick={() => setShowEditCost(true)} style={{ color: 'gray', cursor: 'pointer' }}>
                  [edit]
                </span>{' '}
                {!part.cost && <b style={{ color: 'red' }}> 🡰 Set the cost</b>}
                {showEditCost && (
                  <>
                    <br />
                    <TextField
                      size='small'
                      value={editablePart.cost}
                      onChange={event => setEditablePart({ ...part, cost: Number(event.target.value) })}
                      InputProps={{
                        inputComponent: PriceInput,
                      }}
                    />
                    <Button
                      onClick={() => {
                        updateAttributes({
                          payload: {
                            cost: editablePart.cost,
                          },
                          successCallback: () => setShowEditCost(false),
                        })
                      }}
                      disabled={waiting || Number(editablePart.cost) === Number(part.cost)}
                    >
                      save
                    </Button>
                    <Button onClick={() => setShowEditCost(false)}>cancel</Button>
                  </>
                )}
              </div>
              <div>
                <b>Quote number:</b> {part.quoteNumber ? part.quoteNumber : 'None'}{' '}
                <span onClick={() => setShowEditQuoteN(true)} style={{ color: 'gray', cursor: 'pointer' }}>
                  [edit]
                </span>{' '}
                {!part.quoteNumber && <b style={{ color: 'red' }}> 🡰 Set quote number</b>}
                {showEditQuoteN && (
                  <>
                    <br />
                    <TextField
                      size='small'
                      value={editablePart.quoteNumber}
                      onChange={event => setEditablePart({ ...part, quoteNumber: event.target.value })}
                    />
                    <Button
                      onClick={() => {
                        updateAttributes({
                          payload: { quote_number: editablePart.quoteNumber },
                          successCallback: () => setShowEditQuoteN(false),
                        })
                      }}
                      disabled={waiting || editablePart.quoteNumber === part.quoteNumber}
                    >
                      save
                    </Button>
                    <Button onClick={() => setShowEditQuoteN(false)}>cancel</Button>
                  </>
                )}
              </div>
              <div>
                <b>Quoted customer price:</b> <Dollars value={part.price} />
              </div>
              <div>
                <b>Prepay required:</b>{' '}
                {part.prePaymentRequired ? (
                  <>
                    <b style={{ color: 'red' }}>YES</b> (click into
                    <Link to={`/leads/${lead.id}/quotes/${quote.id}`}> the quote </Link> to check for pre-payments)
                  </>
                ) : (
                  'no'
                )}
              </div>

              <div>
                <b>Projected ETA:</b> <PartEta part={part} />
              </div>

              <div title={JSON.stringify(part.pickupHistory, null, 2)}>
                <b>Pickup status (hover):</b>&nbsp;
                {part.pickupStatus ? (
                  <span className={[classes.pickup_status, partStatusClasses['color_' + part.pickupStatus]].join(' ')}>
                    {underscoreToTitleCase(part.pickupStatus)}
                  </span>
                ) : (
                  'none'
                )}
              </div>

              {(part.status === 'quoted' || part.status === 'eta_received') && !part.vendor && (
                <Button
                  variant='contained'
                  size='small'
                  sx={{ marginBottom: '1rem' }}
                  disabled={handlingDisabled}
                  onClick={() => {
                    setAddVendorDialogOpen(true)
                  }}
                >
                  Add Vendor
                </Button>
              )}

              <AddVendorDialog
                lead={lead}
                parts={[part]}
                isOpen={addVendorDialogOpen}
                onClose={() => {
                  setAddVendorDialogOpen(false)
                }}
              />

              {part.orderPlacedAt && part.returnInitiatedAt && (
                <Box
                  sx={{ mt: 2, p: 1 }}
                  className={[classes.returnStatusContainer, part.returnCompletedAt && classes.borderBlack].join(' ')}
                >
                  <b>Return Status: {returnStatusText(part)}</b>
                  <div>
                    <b>Return Initiated:</b>&nbsp;
                    {formatDate(part.returnInitiatedAt, '--')} <DaysAgo date={part.returnInitiatedAt} />
                  </div>
                  <div>
                    <b>Returned to Merchant:</b>&nbsp;
                    {formatDate(part.returnedToVendorAt, '--')} <DaysAgo date={part.returnedToVendorAt} />
                  </div>
                  <div>
                    <b>Refund Amount:</b> {part.returnAmount !== null ? <Dollars value={part.returnAmount} /> : '--'}
                  </div>
                </Box>
              )}

              {part.orderPlacedAt && part.coreRefundInitiatedAt && (
                <Box
                  sx={{ mt: 2, p: 1 }}
                  className={[classes.returnStatusContainer, part.coreRefundCompletedAt && classes.borderBlack].join(
                    ' '
                  )}
                >
                  <b>Refund Status: {coreReturnStatusText(part)}</b>
                  <div>
                    <b>Core Refund Initiated:</b>&nbsp;
                    {formatDate(part.coreRefundInitiatedAt, '--')} <DaysAgo date={part.coreRefundInitiatedAt} />
                  </div>
                  <div>
                    <b>Core Returned to Merchant:</b>&nbsp;
                    {formatDate(part.coreReturnedToVendorAt, '--')} <DaysAgo date={part.coreReturnedToVendorAt} />
                  </div>
                  <div>
                    <b>Refund Amount:</b>{' '}
                    {part.coreRefundReceivedAmount !== null ? <Dollars value={part.coreRefundReceivedAmount} /> : '--'}
                  </div>
                </Box>
              )}

              {part.orderPlacedAt && part.refundInitiatedAt && (
                <Box
                  sx={{ mt: 2, p: 1 }}
                  className={[classes.returnStatusContainer, part.refundCompletedAt && classes.borderBlack].join(' ')}
                >
                  <b>Refund Status: {refundStatusText(part)}</b>
                  <div>
                    <b>Refund Initiated:</b>&nbsp;
                    {formatDate(part.refundInitiatedAt, '--')} <DaysAgo date={part.refundInitiatedAt} />
                  </div>
                  <div>
                    <b>Refund Amount:</b> {part.refundAmount !== null ? <Dollars value={part.refundAmount} /> : '--'}
                  </div>
                </Box>
              )}

              <br />
              {quote.technician && (
                <div>
                  <b>Technician:</b>&nbsp;
                  <Link target='_blank' to={`/technicians/${quote.technician.id}`}>
                    {quote.technician.name}
                  </Link>
                </div>
              )}
              <div>
                <b>Customer:</b> {lead.name}
              </div>
              <div>
                <b>Market:</b> {lead.market}
              </div>
              <div>
                <b>State:</b> {lead.state}
              </div>

              {part.vendor && (
                <>
                  <h3 style={{ marginBottom: '0' }}>Merchant</h3>
                  <b>{part.vendor.name}</b>
                  <Address addr={part.vendor} />
                  {!isEmpty(part.partVendorContact) && <div>Contact: {part.partVendorContact}</div>}
                  {!isEmpty(part.vendor.paymentNotes) && <div>Payment notes: {part.vendor.paymentNotes}</div>}
                  {!isEmpty(part.vendor.website) && (
                    <div>
                      <a target='_blank' rel='noreferrer' href={part.vendor.website}>
                        {part.vendor.website}
                      </a>
                    </div>
                  )}
                  {part.orderPlacedAt && <div>Invoice #: {part.invoiceNumber}</div>}
                </>
              )}

              {part.vendor && !part.orderPlacedAt && (
                <Button
                  variant='contained'
                  size='small'
                  sx={{ marginBottom: '1rem' }}
                  disabled={handlingDisabled}
                  onClick={() => {
                    setAddVendorDialogOpen(true)
                  }}
                >
                  Edit Vendor
                </Button>
              )}

              <br />

              {handlingDisabled && (
                <>
                  <b>Handled Externally by: {externalHandlingBy}</b>
                  <br />
                </>
              )}

              {part?.tireConnectData?.tireConnectSupplierId && !part.orderPlacedAt &&  (
                <TireConnectSupplier supplierId={part?.tireConnectData?.tireConnectSupplierId} />
              )}

              <FormControl>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!part.orderRequestedAt}
                        disabled={handlingDisabled}
                        onChange={handleToggleOrderRequested}
                      />
                    }
                    label='Order Requested'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!part.orderPlacedAt}
                        disabled={handlingDisabled}
                        onChange={() => {
                          if (!part.orderPlacedAt) {
                            return setOrderPartDialogOpen(true)
                          }
                          handleToggleOrderPlaced()
                        }}
                      />
                    }
                    label={`Order Placed${part.orderPlacedAt ? ' at: ' + formatDate(part.orderPlacedAt, '') : ''}`}
                  />
                  <OrderPartDialog
                    lead={lead}
                    parts={[part]}
                    isOpen={orderPartDialogOpen}
                    onClose={() => {
                      setOrderPartDialogOpen(false)
                    }}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!part.readyForPickupAt}
                        disabled={handlingDisabled}
                        onChange={handleToggleReadyForPickup}
                      />
                    }
                    label={`Ready For Pickup${
                      part.readyForPickupAt ? ' at: ' + formatDate(part.readyForPickupAt, '') : ''
                    }`}
                  />
                </FormGroup>

                {/* <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!part.canceledAt}
                        disabled={processingCancel}
                        onChange={() => {
                          if (!part.canceledAt) {
                            setConfirmCancelDialogOpen(true)
                          } else {
                            handleToggleCanceled()
                          }
                        }}
                      />
                    }
                    label='Cancel (never ordered or paid for)'
                  />
                </FormGroup> */}

                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!part.returnInitiatedAt}
                        disabled={handlingDisabled}
                        onChange={handleToggleReturnInitiated}
                      />
                    }
                    label='Return Initiated'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!part.returnedToVendorAt}
                        disabled={handlingDisabled}
                        onChange={handleToggleReturnedToVendor}
                      />
                    }
                    label={`Returned to Merchant${
                      part.returnedToVendorAt ? ' at: ' + formatDate(part.returnedToVendorAt, '') : ''
                    }`}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!part.returnCompletedAt}
                        disabled={handlingDisabled}
                        onChange={handleToggleReturnCompleted}
                      />
                    }
                    label={`Return Completed${part.returnAmount ? ' ($' + part.returnAmount + ')' : ''}`}
                  />
                </FormGroup>

                {(part.hasCoreRefund || part.coreRefundExpectedAmount > 0) && (
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!part.coreRefundInitiatedAt}
                          disabled={handlingDisabled}
                          onChange={handleToggleCoreRefundInitiated}
                        />
                      }
                      label='Core Refund Initiated'
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!part.coreReturnedToVendorAt}
                          disabled={handlingDisabled}
                          onChange={handleToggleCoreReturnedToVendor}
                        />
                      }
                      label={`Core Returned to Vendor${
                        part.coreReturnedToVendorAt ? ' at: ' + formatDate(part.coreReturnedToVendorAt, '') : ''
                      }`}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!part.coreRefundCompletedAt}
                          disabled={handlingDisabled}
                          onChange={handleToggleCoreRefundCompleted}
                        />
                      }
                      label={`Core Refunded${part.coreRefundAmount ? ' ($' + part.coreRefundAmount + ')' : ''}`}
                    />
                  </FormGroup>
                )}

                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={part.refundInitiatedAt}
                        disabled={handlingDisabled}
                        onChange={handleToggleInitiateRefund}
                      />
                    }
                    label='Refund Initiated'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox checked={part.refundedAt} disabled={handlingDisabled} onChange={handleToggleRefunded} />
                    }
                    label={`Refunded${part.refundAmount ? ' ($' + part.refundAmount + ')' : ''}`}
                  />
                </FormGroup>

                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!part.disputeInitiatedAt}
                        disabled={handlingDisabled}
                        onChange={handleToggleDisputeInitiated}
                      />
                    }
                    label={`Dispute Initiated${
                      part.disputeInitiatedAt ? ' at: ' + formatDate(part.disputeInitiatedAt, '') : ''
                    }`}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!part.disputeResolvedAt}
                        disabled={handlingDisabled}
                        onChange={handleToggleDisputeResolved}
                      />
                    }
                    label={`Dispute Resolved${
                      part.disputeResolvedAt ? ' at: ' + formatDate(part.disputeResolvedAt, '') : ''
                    }`}
                  />
                </FormGroup>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Notes headerText='Notes from the Quote' objectType='quote' objectId={quote.id} />

            <hr />

            <Grid item xs={12}>
              <TechNotes
                headerText='Technician Notes (Quote-wide)'
                parentType='quote'
                parentId={quote.id}
                leadId={lead.id}
                quoteId={quote.id}
              />

              <hr />
              <TechNotesForJobsUnderQuote
                quote={quote}
                noteContainerStyle={{
                  maxHeight: '300px',
                  overflowY: 'scroll',
                  margin: '0',
                  padding: '12px 0',
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={{ p: 3 }} style={{ padding: '24px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PartAndSiblings lead={lead} part={part} parts={siblings} quote={quote} />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h3>{/* :: reimplement map here :: */}</h3>
              {/* <Map lead={data.partAcquisitionRequest.lead} partAcquisitionRequest={data.partAcquisitionRequest} /> */}
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StreamDump stream={`Part$${part.id}`} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        open={confirmCancelDialogOpen}
        title='Confirm Action'
        heading='Are you sure you want to cancel this part?'
        text='This action cannot be undone.'
        onClose={res => {
          setConfirmCancelDialogOpen(false)
          if (res) {
            setProcessingCancel(true)
            handleToggleCanceled(user, part)
          }
        }}
      />
    </div>
  )
}

export default ShowEdit
