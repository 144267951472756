import { gql, useQuery } from '@apollo/client'
import { Box, Button, Collapse, Dialog, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { useState } from 'react'

const SUBMISSION = gql`
  query GetFormSubmission($parentId: String!, $parentType: String!, $creatorId: String!) {
    formSubmission(parentId: $parentId, parentType: $parentType, creatorId: $creatorId) {
      id
      createdAt
      answers
    }
  }
`

export const FormAnswers = ({ contextType, contextId, creatorId }: any) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const { data, loading, error, refetch } = useQuery(SUBMISSION, {
    fetchPolicy: 'network-only',
    variables: {
      parentType: contextType,
      parentId: contextId,
      creatorId,
    },
  })

  const submission = data?.formSubmission

  return (
    <Box>
      <Box>
        <Typography variant='body1'>Created: {DateTime.fromISO(submission?.createdAt).toFormat('DD t')}</Typography>
      </Box>

      <Box>
        <Button onClick={() => setDialogOpen(!dialogOpen)}>{!dialogOpen ? 'See' : 'Hide'} application answers</Button>
        <Collapse in={dialogOpen}>
          <Box sx={{ padding: '1rem' }}>
            {submission?.answers && Object.entries(submission?.answers)?.map(([title, answer]) => (
              <Box key={title} sx={{ padding: '.5rem' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: 500 }} variant='h1'>
                  {title}
                </Typography>
                <Box sx={{ width: '100%', height: '1px', background: '#f1f1f1' }} />
                {answer}
              </Box>
            ))}
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}
