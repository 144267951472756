import {
  Container,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Modal,
  Box,
  Dialog,
  Snackbar,
  Chip,
  Typography,
  Paper,
  useMediaQuery,
  Popover,
  List,
  ListItem,
  ListItemButton,
  Divider,
} from '@mui/material'
import {
  KeyboardArrowDown,
  Cancel,
  DownloadRounded,
  CopyAllRounded,
  MoreVertRounded,
  DoDisturb,
  InfoOutlined,
} from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useQuery, gql } from '@apollo/client'
import { useEffect, useState, useContext } from 'react'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { keysToCamel } from 'tools'
import { DateTime } from 'luxon'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import Chat from 'Chat'
import { Link, useHistory } from 'react-router-dom'
import { UserContext } from 'UserStore'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { CreateATask } from 'Tasks'
import { PDFDownloadLink } from '@react-pdf/renderer'
import InvoicePDF from './InvoicePDF'
import { LineItemsSection } from './Components/LineItems/LineItems'
import JSONPretty from 'react-json-pretty'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { PayrollNotes } from './Components/PayrollNotes'
import { labelsList } from '../tools'

const styles = {
  photoExpandStyle: {
    border: '1px solid #ddd',
    fontSize: '14px',
    borderRadius: '0px 0px 6px 6px',
    display: 'flex',
    padding: '0.25rem 0.5rem',
    background: '#f1f1f1',
    justifyContent: 'center',
    alignItems: 'center',
  },
  photoCancelButtonStyle: {
    zIndex: 999,
    position: 'absolute',
    top: '5px',
    right: '5px',
    fontSize: '30px',
    color: '#fff',
    border: '2px solid #fff',
    borderRadius: '20px',
  },
  sectionHeaderStyle: {
    borderRadius: '6px 6px 0px 0px',
    fontWeight: 700,
    fontSize: '14px',
    padding: '0.5rem 1rem',
    background: '#bbc7d3',
    display: 'flex',
    alignItems: 'center',
  },
  sectionContainerStyle: {
    mb: '0.5rem',
    mt: '0.5rem',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '6px',
    maxWidth: '100%',
  },
  photoContainerStyle: {
    background: '#fff',
    padding: '1rem 0.5rem',
    border: '1px solid #ddd',
    borderBottom: '0',
    borderTop: '0',
    textAlign: 'center',
  },
  uploadButtonContainerStyle: {
    border: '1px solid #ddd',
    fontSize: '14px',
    borderRadius: '0px 0px 6px 6px',
    display: 'flex',
    padding: '0.25rem 0.5rem',
    background: '#f1f1f1',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '700px',
  maxHeight: '80%',
  width: '1100px',
  maxWidth: '100%',
  boxShadow: 24,
  border: '1px solid black',
}

const TECHNICIANS_QUERY = gql`
  query getTechnicians($filter: JSON) {
    technicians(filter: $filter) {
      id
      name
    }
  }
`

const DEALERS_QUERY = gql`
  query BToBDealers {
    bToBDealers {
      id
      businessName
    }
  }
`

const VEHICLE_YEARS_QUERY = gql`
  query fgVehicleYears {
    fgVehicleYears
  }
`

const VEHICLE_MAKE_QUERY = gql`
  query fgVehicleMakes($year: String) {
    fgVehicleMakes(year: $year) {
      makeId
      makeName
      makeShortName
    }
  }
`

const VEHICLE_MODEL_QUERY = gql`
  query fgVehicleModels($year: String, $makeShortName: String) {
    fgVehicleModels(year: $year, makeShortName: $makeShortName) {
      modelId
      modelName
      modelShortName
    }
  }
`

const isValidToDownloadPdf = dealer => {
  const { businessName, addressLineOne, addressLineTwo, city, state, zip, primaryContact = {} } = dealer ?? {}
  const { firstName, lastName, email } = primaryContact ?? {}

  return Boolean(
    businessName && addressLineOne && primaryContact && firstName && lastName && email && city && state && zip
  )
}

const ShowEdit = ({ invoice, refetch }) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)
  const [payoutDataOpen, setPayoutDataOpen] = useState(false)

  const [actionsPopoverOpen, setActionsPopoverOpen] = useState(false)
  const [actionsPopoverAnchorEl, setActionsPopoverAnchorEl] = useState(null)

  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const history = useHistory()

  const isMobile = useMediaQuery('(max-width: 600px)')

  useEffect(() => {
    const key = registerTaskTarget({
      targetType: 'BToBInvoice',
      targetId: invoice.id,
    })
    return () => unregisterTaskTarget(key)
  }, [])

  const updateAttributes = attr =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        updatedAttributes: attr,
        actorType: 'csr',
        actorId: user.id,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const lockedToCsr = false
  // const lockedToCsr = invoice.payoutData

  const handleClickLinkToStatement = statementId =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/link_b_to_b_invoice_to_statement`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        bToBInvoiceStatementId: statementId,
        userId: user.id,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleClickUnlinkFromStatement = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_b_to_b_invoice_from_statement`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        userId: user.id,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleClickDeleteInvoice = () =>
    window.confirm('Please confirm') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_invoice`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        userId: user.id,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const VehiclePicker = () => {
    const [selectsDisabled, setSelectsDisabled] = useState(!!invoice.payoutData)

    const { data: yearsData, loading: yearsLoading, error: yearsError } = useQuery(VEHICLE_YEARS_QUERY)

    const {
      data: makesData,
      loading: makesLoading,
      error: makesError,
    } = useQuery(VEHICLE_MAKE_QUERY, {
      variables: {
        year: invoice?.year?.toString(),
      },
    })

    const {
      data: modelsData,
      loading: modelsLoading,
      error: modelsError,
    } = useQuery(VEHICLE_MODEL_QUERY, {
      variables: {
        year: invoice?.year?.toString(),
        makeShortName: invoice?.makeShortName,
      },
    })

    const updateAttribute = attr => {
      setSelectsDisabled(true)

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBInvoiceId: invoice.id,
          updatedAttributes: attr,
          actorType: 'csr',
          actorId: user.id,
        }),
      })
        .catch(() => window.alert('Error'))
        .finally(() => setSelectsDisabled(false))
    }

    if (makesLoading || modelsLoading) return <div>loading</div>

    const handleSelectedYear = year => updateAttribute({ year })
    const handleSelectedMake = make =>
      updateAttribute({
        make: make,
        makeShortName: make
          .replace(/[^a-zA-Z0-9]/, ' ')
          .replace(/\s+/, '_')
          .toLowerCase()
          .trim(),
      })
    const handleSelectedModel = model =>
      updateAttribute({
        model: model,
        modelShortName: model
          .replace(/[^a-zA-Z0-9]/, ' ')
          .replace(/\s+/, '_')
          .toLowerCase()
          .trim(),
      })

    const yearsForSelect = (yearsData && yearsData.fgVehicleYears.slice().sort((a, b) => (a < b ? 1 : -1))) || []

    const makesForSelect =
      (makesData && makesData.fgVehicleMakes.slice().sort((a, b) => (a.makeName > b.makeName ? 1 : -1))) || []

    const modelsForSelect =
      (modelsData && modelsData.fgVehicleModels.slice().sort((a, b) => (a.modelName > b.modelName ? 1 : -1))) || []

    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4} sx={{ pr: '.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Year</InputLabel>
              <Select
                disabled={selectsDisabled}
                value={invoice.year}
                label='year'
                onChange={e => handleSelectedYear(e.target.value)}
              >
                {yearsForSelect.map(year => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} sx={{ pl: '.5rem', pr: '.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Make</InputLabel>
              <Select
                value={invoice.make}
                label='make'
                disabled={makesLoading || selectsDisabled}
                onChange={e => handleSelectedMake(e.target.value)}
              >
                {invoice.make && (
                  <MenuItem disabled value={invoice.make}>
                    {invoice.make}
                  </MenuItem>
                )}
                {makesForSelect.map(({ makeShortName, makeName }) => (
                  <MenuItem key={makeShortName} value={makeName}>
                    {makeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} sx={{ pl: '.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Model</InputLabel>
              <Select
                value={invoice.model}
                label='model'
                disabled={modelsLoading || selectsDisabled || !invoice.make}
                onChange={e => handleSelectedModel(e.target.value)}
              >
                {invoice.model && modelsForSelect.length === 0 && (
                  <MenuItem disabled value={invoice.model}>
                    {invoice.model}
                  </MenuItem>
                )}
                {modelsForSelect.map(({ modelName, modelShortName }) => (
                  <MenuItem key={modelShortName} value={modelName}>
                    {modelName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const Stock = () => {
    const [editableStock, setEditableStock] = useState(invoice.stock)
    const [waiting, setWaiting] = useState(false)

    const handleChangeStock = e => setEditableStock(e.target.value)

    const handleClickSaveStock = () => {
      setWaiting(true)

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBInvoiceId: invoice.id,
          updatedAttributes: { stock: editableStock.trim() },
          actorType: 'csr',
          actorId: user.id,
        }),
      })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl size='small'>
          <TextField
            value={editableStock}
            label='Stock'
            disabled={!!lockedToCsr}
            onChange={handleChangeStock}
            size='small'
          />
        </FormControl>
        <Button
          disabled={waiting || editableStock === null || editableStock.trim() === invoice.stock}
          onClick={handleClickSaveStock}
        >
          Save
        </Button>
      </Grid>
    )
  }

  const RepairOrder = () => {
    const [editableRepairOrder, setEditableRepairOrder] = useState(invoice.repairOrder)
    const [waiting, setWaiting] = useState(false)

    const handleChangeRepairOrder = e => setEditableRepairOrder(e.target.value)

    const handleClickSaveRepairOrder = () => {
      setWaiting(true)

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBInvoiceId: invoice.id,
          updatedAttributes: { repairOrder: editableRepairOrder.trim() },
          actorType: 'csr',
          actorId: user.id,
        }),
      })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl size='small'>
          <TextField
            value={editableRepairOrder}
            label='RO'
            disabled={!!lockedToCsr}
            onChange={handleChangeRepairOrder}
            size='small'
          />
        </FormControl>
        <Button
          disabled={waiting || editableRepairOrder === null || editableRepairOrder.trim() === invoice.repairOrder}
          onClick={handleClickSaveRepairOrder}
        >
          Save
        </Button>
      </Grid>
    )
  }

  const Vin = () => {
    const [editableVin, setEditableVin] = useState(invoice.vin)
    const [waiting, setWaiting] = useState(false)

    const handleChangeVin = e => setEditableVin(e.target.value)

    const handleClickSaveVin = () => {
      setWaiting(true)

      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            updatedAttributes: { vin: editableVin.trim() },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl size='small'>
          <TextField value={editableVin} label='VIN' disabled={!!lockedToCsr} onChange={handleChangeVin} size='small' />
        </FormControl>
        <Button
          disabled={waiting || editableVin === null || editableVin.trim() === invoice.vin}
          onClick={handleClickSaveVin}
        >
          Save
        </Button>
      </Grid>
    )
  }

  const VinPhoto = () => {
    const [modalPhotoUrl, setModalPhotoUrl] = useState(null)

    return (
      <>
        <Grid container sx={styles.sectionContainerStyle}>
          <Grid item xs={12} sx={styles.sectionHeaderStyle}>
            VIN Photo {invoice.vinPhoto ? '' : '(NONE)'}
          </Grid>

          <Grid item xs={12}>
            {!!invoice.vinPhoto && (
              <div style={{ height: '200px' }}>
                <PinchZoomPan position='center' maxScale={3}>
                  <img
                    onClick={() => setModalPhotoUrl(invoice.vinPhoto.originalUrl)}
                    src={invoice.vinPhoto?.originalUrl}
                  />
                </PinchZoomPan>
              </div>
            )}

            <Modal open={!!modalPhotoUrl} onClose={() => setModalPhotoUrl(null)}>
              <Box sx={modalStyle}>
                <div
                  style={{
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Cancel sx={styles.photoCancelButtonStyle} onClick={() => setModalPhotoUrl(null)} />
                  <PinchZoomPan position='center' maxScale={2}>
                    <img src={modalPhotoUrl} />
                  </PinchZoomPan>
                </div>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </>
    )
  }

  const PreJobPhotos = () => {
    const [openPreJobPhotosDrawer, setOpenPreJobPhotosDrawer] = useState(false)
    const [expandPhotoSection, setExpandPhotoSection] = useState(false)
    const [currentFullScreenPhoto, setCurrentFullScreenPhoto] = useState(null)

    const preJobPhotos = invoice.preJobPhotos || []

    return (
      <>
        <Grid container sx={styles.sectionContainerStyle}>
          <Grid item xs={12} sx={styles.sectionHeaderStyle}>
            PRE-Job Photos
            {preJobPhotos.length > 0 && <> &nbsp;({preJobPhotos.length})</>}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              borderRadius: preJobPhotos.length > 0 && preJobPhotos.length < 8 ? '0px 0px 6px 6px' : '0px',
              ...styles.photoContainerStyle,
            }}
          >
            {(preJobPhotos.length > 0 && (
              <Grid container spacing={2}>
                {preJobPhotos.map(
                  (photo, index) =>
                    (expandPhotoSection || index < 8) && (
                      <Grid item xs={3} sm={3} md={2} key={photo.originalUrl}>
                        <Grid
                          onClick={() => setCurrentFullScreenPhoto(photo)}
                          sx={{
                            borderRadius: '3px',
                            minHeight: '75px',
                            background: `url(${photo.originalUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                          }}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            )) ||
              'No photos uploaded yet'}
          </Grid>

          {preJobPhotos?.length > 8 && (
            <Grid
              item
              xs={12}
              onClick={() => setExpandPhotoSection(!expandPhotoSection)}
              sx={{
                ...styles.photoExpandStyle,
                '&:active': {
                  background: '#eee',
                },
              }}
            >
              See
              {expandPhotoSection ? ' less' : ' all'}
              <KeyboardArrowDown
                sx={{
                  transform: expandPhotoSection ? 'rotate(180deg)' : '',
                  ml: '1rem',
                  transition: 'all 0.25s ease-in-out',
                }}
              />
            </Grid>
          )}

          {preJobPhotos?.length < 1 && <Grid item xs={12} sx={styles.uploadButtonContainerStyle}></Grid>}
        </Grid>

        <Modal open={!!currentFullScreenPhoto} onClose={() => setCurrentFullScreenPhoto(null)}>
          <Box sx={modalStyle}>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <Cancel sx={styles.photoCancelButtonStyle} onClick={() => setCurrentFullScreenPhoto(null)} />
              <PinchZoomPan position='center' maxScale={2}>
                <img src={currentFullScreenPhoto && currentFullScreenPhoto.originalUrl} />
              </PinchZoomPan>
            </div>
          </Box>
        </Modal>
      </>
    )
  }

  const PostJobPhotos = () => {
    const [showPostJobPhotosDrawer, setShowPostJobPhotosDrawer] = useState(false)
    const { postJobPhotos } = invoice
    const [expandPhotoSection, setExpandPhotoSection] = useState(false)
    const [currentFullScreenPhoto, setCurrentFullScreenPhoto] = useState(null)

    return (
      <>
        <Grid container sx={styles.sectionContainerStyle}>
          <Grid item xs={12} sx={styles.sectionHeaderStyle}>
            POST-Job Photos
            {postJobPhotos?.length > 0 && <>&nbsp;({postJobPhotos.length})</>}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              borderRadius: postJobPhotos.length > 0 && postJobPhotos.length < 8 ? '0px 0px 6px 6px' : '0px',
              ...styles.photoContainerStyle,
            }}
          >
            {(postJobPhotos?.length > 0 && (
              <Grid container spacing={2}>
                {postJobPhotos.map(
                  (photo, index) =>
                    (expandPhotoSection || index < 8) && (
                      <Grid item xs={3} sm={3} md={2} key={photo.originalUrl}>
                        <Grid
                          onClick={() => setCurrentFullScreenPhoto(photo)}
                          sx={{
                            borderRadius: '3px',
                            minHeight: '75px',
                            background: `url(${photo.originalUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                          }}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            )) ||
              'No photos uploaded yet'}
          </Grid>

          {postJobPhotos?.length > 0 ? (
            postJobPhotos.length > 8 && (
              <Grid
                item
                xs={12}
                onClick={() => setExpandPhotoSection(!expandPhotoSection)}
                sx={{
                  ...styles.photoExpandStyle,
                  '&:active': { background: '#eee' },
                }}
              >
                See
                {expandPhotoSection ? ' less' : ' all'}
                <KeyboardArrowDown
                  sx={{
                    transform: expandPhotoSection ? 'rotate(180deg)' : '',
                    ml: '1rem',
                    transition: 'all 0.25s ease-in-out',
                  }}
                />
              </Grid>
            )
          ) : (
            <Grid item xs={12} sx={styles.uploadButtonContainerStyle}></Grid>
          )}
        </Grid>

        <Modal open={!!currentFullScreenPhoto} onClose={() => setCurrentFullScreenPhoto(null)}>
          <Box sx={modalStyle}>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <Cancel sx={styles.photoCancelButtonStyle} onClick={() => setCurrentFullScreenPhoto(null)} />
              <PinchZoomPan position='center' maxScale={2}>
                <img src={currentFullScreenPhoto && currentFullScreenPhoto.originalUrl} />
              </PinchZoomPan>
            </div>
          </Box>
        </Modal>
      </>
    )
  }

  const DealerSelect = () => {
    const [selectedDealerId, setSelectedDealerId] = useState(invoice.dealer.id || '')

    const { data, loading, error } = useQuery(DEALERS_QUERY)

    const handleClickSaveDealer = () => {
      const selectedDealer = data?.bToBDealers?.find(dealer => dealer.id === selectedDealerId)
      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            updatedAttributes: {
              dealer_id: selectedDealerId,
              business_name: selectedDealer.businessName,
            },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => refetch() || window.alert('Error'))
    }

    const possibleOptions = data?.bToBDealers || []

    return (
      <Grid item xs={12} sx={{ padding: '1rem 0rem 0rem 0rem', display: 'flex' }}>
        <FormControl size='small' fullWidth>
          <InputLabel>Change Dealer</InputLabel>
          <Select value={selectedDealerId} onChange={e => setSelectedDealerId(e.target.value)} label='Change dealer'>
            {possibleOptions.map(item => (
              <MenuItem value={item.id} key={item.id}>
                {item.businessName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={handleClickSaveDealer}>save</Button>
      </Grid>
    )
  }

  const PrimaryTechnicianSelect = () => {
    const [selectedPrimaryTechnicianId, setSelectedTechnicianId] = useState(invoice.technician.id || '')

    const { data, loading, error } = useQuery(TECHNICIANS_QUERY, {
      variables: {
        filter: {
          b_to_b_dealer_ids: invoice.dealerId,
        },
      },
    })

    const handleClickSaveTech = () => {
      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            updatedAttributes: { technician_id: selectedPrimaryTechnicianId },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => refetch() || window.alert('Error'))
    }

    const possiblePrimaryTechs = data?.technicians || []

    return (
      <Grid item xs={12} sx={{ padding: '1rem 0rem 0rem 0rem', display: 'flex' }}>
        <FormControl size='small' fullWidth>
          <InputLabel>Change primary technician</InputLabel>
          <Select
            value={selectedPrimaryTechnicianId}
            onChange={e => setSelectedTechnicianId(e.target.value)}
            label='Change primary technician'
          >
            {possiblePrimaryTechs.map(item => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={handleClickSaveTech}>save</Button>
      </Grid>
    )
  }

  const SecondaryTechnicianSelect = () => {
    const [selectedSecondaryTechnicianId, setSelectedTechnicianId] = useState(invoice.secondaryTechnicianId || '')

    const { data, loading, error } = useQuery(TECHNICIANS_QUERY, {
      variables: {
        filter: {
          b_to_b_dealer_ids: invoice.dealerId,
        },
      },
    })

    const handleClickSaveTech = () => {
      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            updatedAttributes: { secondary_technician_id: selectedSecondaryTechnicianId },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
    }

    const possibleSecondaryTechs = data?.technicians?.filter(tech => tech.id !== invoice.technician.id) || []

    return (
      <Grid item xs={12} sx={{ padding: '1rem 0rem 1rem 0rem', display: 'flex' }}>
        <FormControl size='small' fullWidth>
          <InputLabel>Select a secondary technician</InputLabel>
          <Select
            value={selectedSecondaryTechnicianId}
            onChange={e => setSelectedTechnicianId(e.target.value)}
            label='Select a secondary technician'
          >
            <MenuItem value=''>None</MenuItem>
            {possibleSecondaryTechs.map(item => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={handleClickSaveTech}>save</Button>
      </Grid>
    )
  }

  const LabelSelect = () => {
    const [label, setLabel] = useState(invoice?.label)

    const handleClickSaveLabel = () => {
      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            updatedAttributes: { label: label },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
    }

    const possibleLabels = labelsList

    return (
      <Box sx={{ display: 'flex', ml: { xs: '0rem', sm: 'auto' } }}>
        <FormControl size='small' sx={{ minWidth: '200px' }}>
          <InputLabel>Select a label</InputLabel>
          <Select value={label || ''} onChange={e => setLabel(e.target.value)} label='Select a label'>
            <MenuItem value=''>None</MenuItem>
            {possibleLabels.map(item => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={handleClickSaveLabel}>save</Button>
      </Box>
    )
  }

  const isValid = isValidToDownloadPdf(invoice?.dealer)
  const customStyles = isValid ? { ml: 'auto', mr: '.5rem' } : { ml: '1rem', mr: '.5rem', textTransform: 'capitalize' }

  return (
    <Container
      maxWidth='xl'
      disableGutters
      sx={{
        maxWidth: '95vw',
        background: '#F4F6F9',
        padding: {
          xs: '.5rem',
          sm: '1rem',
        },
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <CreateATask targetType='BToBInvoice' targetId={invoice.id} />
          <CopyToClipboard style={{ cursor: 'pointer' }} text={invoice.id}>
            <Button size='small' variant='outlined' onClick={() => setSnackbarOpen(true)}>
              Copy ID
            </Button>
          </CopyToClipboard>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
          <Typography variant='h4'>
            {invoice?.dealer?.businessName} &#8212; #{invoice.invoiceNumber}
          </Typography>
          <LabelSelect />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Link target='_blank' to={`/technicians/${invoice.technician.id}`}>
            {invoice.technician.firstName} {invoice.technician.lastName}
          </Link>
          &nbsp;&nbsp;
          <span style={{ opacity: 0.75, fontSize: '14px' }}>
            Created:{' '}
            <Link to={`/b-to-b-invoices/${invoice.id}`}>{DateTime.fromISO(invoice.createdAt).toLocaleString()}</Link>
          </span>
        </Grid>

        <Grid item xs={12} sx={{ pt: '.5rem' }}>
          {invoice.statementId && (
            <Chip
              size='small'
              label={'Linked To Statement'}
              sx={{
                color: '#111',
                backgroundColor: 'orange',
                fontWeight: 700,
              }}
            />
          )}

          {invoice.payoutId && (
            <Chip
              size='small'
              label={'Paid to Technician'}
              sx={{
                color: '#111',
                backgroundColor: 'orange',
                fontWeight: 700,
              }}
            />
          )}

          {invoice.fullyPaidAt && (
            <Chip
              size='small'
              label={`PAID AT ${DateTime.fromISO(invoice.fullyPaidAt).toFormat('D hh:mm')}`}
              sx={{
                color: '#111',
                backgroundColor: 'palegreen',
                fontWeight: 700,
                ml: '.5rem',
              }}
            />
          )}

          {invoice.deletedAt && (
            <Chip
              size='small'
              label={`DELETED AT ${DateTime.fromISO(invoice.deletedAt).toFormat('D hh:mm')}`}
              sx={{
                color: '#fff',
                backgroundColor: '#ff5757',
                fontWeight: 700,
                ml: '.5rem',
              }}
            />
          )}

          {invoice.completedAt && (
            <Chip
              size='small'
              sx={{
                color: '#111',
                backgroundColor: 'palegreen',
                fontWeight: 700,
                ml: '.5rem',
              }}
              label={`COMPLETED ${DateTime.fromISO(invoice.completedAt).toFormat('D hh:mm')}`}
            />
          )}

          {invoice.acceptedAt && (
            <Chip
              size='small'
              sx={{
                color: '#111',
                backgroundColor: 'palegreen',
                fontWeight: 700,
                ml: '.5rem',
              }}
              label={`ACCEPTED ${DateTime.fromISO(invoice.acceptedAt).toFormat('D hh:mm')}`}
            />
          )}

          {invoice.markedAsPromotionalRepairAt && (
            <Chip
              size='small'
              sx={{
                color: '#111',
                backgroundColor: 'palegreen',
                fontWeight: 700,
                ml: '.5rem',
              }}
              label={`MARKED AS PROMOTIONAL REPAIR ${DateTime.fromISO(invoice.markedAsPromotionalRepairAt).toFormat(
                'D hh:mm'
              )}`}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={[
              {
                mt: '.5rem',
                display: 'flex',
                background: '#fff',
                alignItems: 'center',
                padding: '.5rem',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                mb: '.5rem',
                border: '2px solid #a1cff7',
                borderRadius: '12px',
              },
            ]}
          >
            <Box
              sx={[
                {
                  display: 'flex',
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                  alignItems: {
                    xs: '',
                    sm: 'center',
                  },
                  width: '100%',
                },
                isMobile && {
                  button: {
                    mb: '.5rem',
                  },
                },
              ]}
            >
              {user.roles.includes('developer') && (
                <Button
                  fullWidth={isMobile}
                  size={isMobile ? 'large' : 'small'}
                  variant='outlined'
                  onClick={() => setPayoutDataOpen(!payoutDataOpen)}
                >
                  {payoutDataOpen ? 'hide' : 'view'} full calculations
                </Button>
              )}

              {!isValid && (
                <Box sx={{ marginLeft: 'auto', marginRight: '1rem' }}>
                  <Box display='flex' alignItems='center' sx={{ whiteSpace: 'nowrap', fontSize: '1rem', color: 'red' }}>
                    <InfoOutlined sx={{ mr: '.25rem', fontSize: '1rem', color: 'red', mt: '10px', mb: '10px' }} />
                    Dealer must have a valid address and primary contact to download
                  </Box>
                </Box>
              )}

              <Button
                sx={customStyles}
                fullWidth={isMobile}
                size={isMobile ? 'large' : 'small'}
                disableElevation
                variant={isValid ? 'contained' : 'outlined'}
                color={!isValid ? 'error' : 'primary'}
              >
                {isValid ? (
                  <DownloadRounded sx={{ mr: '.25rem', fontSize: '1rem' }} />
                ) : (
                  <DoDisturb sx={{ mr: '.25rem', fontSize: '1rem' }} />
                )}
                {invoice && isValid ? (
                  <PDFDownloadLink
                    style={{ color: 'unset', textDecoration: 'none' }}
                    document={<InvoicePDF invoice={keysToCamel(invoice)} />}
                    fileName={`${invoice.businessName} - ${DateTime.fromISO(invoice.completedAt).toFormat(
                      'MM-dd-yyyy'
                    )} -  ${invoice.invoiceNumber}`}
                  >
                    {({ blob, url, loading, error }) => {
                      if (error) return JSON.stringify(error)
                      return loading ? 'Generating PDF...' : 'Download as PDF'
                    }}
                  </PDFDownloadLink>
                ) : (
                  'Unable to Download'
                )}
              </Button>

              <Button
                onClick={e => {
                  setActionsPopoverAnchorEl(e.target)
                  setActionsPopoverOpen(true)
                }}
                sx={{ mr: '0rem' }}
              >
                {isMobile && 'Actions'} <MoreVertRounded />
              </Button>

              <Snackbar
                severity='success'
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={e => setSnackbarOpen(false)}
                message='Link Copied!'
              />

              <Popover
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                anchorEl={actionsPopoverAnchorEl}
                open={actionsPopoverOpen}
                onClose={() => setActionsPopoverOpen(false)}
              >
                <List>
                  <CopyToClipboard
                    style={{ cursor: 'pointer' }}
                    text={`https://www.carbodylab.com/invoices/${invoice.id}`}
                  >
                    <ListItemButton
                      fullWidth={isMobile}
                      startIcon={<CopyAllRounded />}
                      onClick={() => setSnackbarOpen(true)}
                      size={isMobile ? 'large' : 'small'}
                      variant='outlined'
                    >
                      Copy Public Link
                    </ListItemButton>
                  </CopyToClipboard>

                  {invoice.statementId && (
                    <ListItemButton
                      fullWidth={isMobile}
                      onClick={() => history.push(`/b-to-b/statements/${invoice.statementId}`)}
                    >
                      Open statement
                    </ListItemButton>
                  )}

                  {!invoice.statementId && (
                    <ListItemButton
                      fullWidth={isMobile}
                      onClick={() => {
                        const statementId = window.prompt('what is the ID of the statement to link to?')
                        handleClickLinkToStatement(statementId)
                      }}
                    >
                      Link to statement
                    </ListItemButton>
                  )}

                  {invoice.statementId && (
                    <ListItemButton fullWidth={isMobile} onClick={handleClickUnlinkFromStatement}>
                      Unlink from statement
                    </ListItemButton>
                  )}

                  {!invoice.acceptedAt && (
                    <ListItemButton
                      fullWidth={isMobile}
                      onClick={() =>
                        window.confirm('are you sure you want to mark this as accepted?') &&
                        updateAttributes({ accepted_at: DateTime.now().toISO() })
                      }
                    >
                      Mark accepted
                    </ListItemButton>
                  )}

                  {invoice.acceptedAt && (
                    <ListItemButton
                      fullWidth={isMobile}
                      onClick={() =>
                        window.confirm('are you sure you want to mark this as accepted?') &&
                        updateAttributes({ accepted_at: null })
                      }
                    >
                      Unmark accepted
                    </ListItemButton>
                  )}

                  {invoice.completedAt && (
                    <ListItemButton
                      fullWidth={isMobile}
                      onClick={() =>
                        window.confirm('are you sure you want to mark this as incomplete?') &&
                        updateAttributes({ completed_at: null })
                      }
                    >
                      Unmark complete
                    </ListItemButton>
                  )}

                  {!invoice.completedAt && (
                    <ListItemButton
                      fullWidth={isMobile}
                      onClick={() =>
                        window.confirm('are you sure you want to mark this as complete?') &&
                        updateAttributes({ completed_at: DateTime.now().toISO() })
                      }
                    >
                      Mark complete
                    </ListItemButton>
                  )}

                  {invoice.markedAsPromotionalRepairAt && (
                    <ListItemButton
                      fullWidth={isMobile}
                      onClick={() =>
                        window.confirm('are you sure you want to unmark this as a promotional repair?') &&
                        updateAttributes({ marked_as_promotional_repair_at: null })
                      }
                    >
                      Unmark as promotional repair
                    </ListItemButton>
                  )}

                  {!invoice.markedAsPromotionalRepairAt && (
                    <ListItemButton
                      fullWidth={isMobile}
                      onClick={() =>
                        window.confirm('are you sure you want to mark this as a promotional repair?') &&
                        updateAttributes({ marked_as_promotional_repair_at: DateTime.now().toISO() })
                      }
                    >
                      Mark as promotional repair
                    </ListItemButton>
                  )}

                  <Divider />

                  {invoice.completedAt && !invoice.fullyPaidAt && (
                    <ListItemButton
                      fullWidth={isMobile}
                      disabled={!user.roles.includes('admin')}
                      onClick={() =>
                        window.confirm('are you sure you want to mark this as paid?') &&
                        updateAttributes({ fully_paid_at: DateTime.now().toISO() })
                      }
                    >
                      Mark as paid by dealer
                    </ListItemButton>
                  )}

                  <ListItemButton
                    fullWidth={isMobile}
                    size={isMobile ? 'large' : 'small'}
                    disabled={invoice.payoutId || invoice.deletedAt}
                    variant='outlined'
                    color='error'
                    onClick={handleClickDeleteInvoice}
                    startIcon={<DeleteIcon />}
                  >
                    Delete{invoice.deletedAt && 'd'}
                  </ListItemButton>
                </List>
              </Popover>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {payoutDataOpen && (
            <Dialog onClose={() => setPayoutDataOpen(false)} open={payoutDataOpen}>
              <JSONPretty style={{ fontSize: '12px' }} data={invoice} />
            </Dialog>
          )}
        </Grid>
        <Grid item sm={5} xs={12} sx={{ padding: '.5rem', border: '1px solid #f1f1f1' }}>
          <Grid item xs={12} sx={styles.sectionHeaderStyle}>
            Details
          </Grid>
          <Paper sx={{ padding: '.5rem' }}>
            <Grid
              container
              sx={{
                background: '#fff',
                border: '2px solid lightsteelblue',
                borderRadius: '12px',
                padding: '0rem .5rem',
                mb: '1rem',
              }}
            >
              <DealerSelect />
              <PrimaryTechnicianSelect />
              <SecondaryTechnicianSelect />
            </Grid>

            <VehiclePicker />
            <VinPhoto />
            <Vin />
            <RepairOrder />
            {invoice.businessName === 'carmax' && <Stock />}
            <PreJobPhotos />
            <LineItemsSection invoice={invoice} />
            <PostJobPhotos />
          </Paper>
        </Grid>
        <Grid item sm={3.5} xs={12}>
          <Grid container sx={{ ...styles.sectionContainerStyle, border: '1px solid #ddd', background: '#fff' }}>
            <Grid item xs={12} sx={styles.sectionHeaderStyle}>
              Chat
            </Grid>
            <Grid item xs={12} sx={{ padding: '0.5rem' }}>
              <Chat contextId={invoice.id} contextType='BToBInvoice' />
            </Grid>
          </Grid>
        </Grid>
        <PayrollNotes invoice={invoice} />
      </Grid>
    </Container>
  )
}

export default ShowEdit
