import { useEffect, useState, useContext, useMemo } from 'react'
import { DateTime } from 'luxon'
import { useDebounce } from 'use-debounce'
import { gql, useQuery } from '@apollo/client'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import ReplayIcon from '@mui/icons-material/Replay'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { GlobalContext } from 'GlobalStore'
import { diff } from 'tools'
import { jobRescheduleReasonOptions } from 'data/reasons'

const GET_QUALIFIED_TECHNICIANS = gql`
  query QualifiedTechniciansForQuote($quoteId: ID!, $skillsets: [String!]!, $ignoreDrivingDistance: Boolean) {
    qualifiedTechniciansForQuote(
      quoteId: $quoteId
      skillsets: $skillsets
      ignoreDrivingDistance: $ignoreDrivingDistance
    ) {
      id
      firstName
      lastName
      note
    }
  }
`

function makeDateTime(date, time) {
  return makeDateTimeTimeString(date, time.toFormat('HH:mm'))
}

function makeDateTimeTimeString(date, timeString) {
  return makeDateTimeStrings(date.toFormat('kkkk-LL-dd'), timeString)
}

function makeDateTimeStrings(dateString, timeString) {
  return DateTime.fromISO(dateString + 'T' + timeString, { zone: 'utc' })
}

function makeSearch(job) {
  const startDatetime = DateTime.fromISO(job.startDatetime, { zone: 'utc' })

  return {
    technicianId: job.technicianId,
    date: startDatetime,
    startTime: startDatetime,
    endTime: DateTime.fromISO(job.endDatetime, { zone: 'utc' }),
  }
}

export const RescheduleJob = ({
  job,
  refetch,
  onClose,
  onChangePendingDate = null,
  technicianId = null,
  prefilledDate = null,
}) => {
  const quote = job.quote

  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)

  const _search = useMemo(() => makeSearch(job), [job])
  const prefilledDateDT = useMemo(() => {
    return prefilledDate ? DateTime.fromISO(prefilledDate) : _search.startTime
  }, [prefilledDate, _search.startTime])

  const [search, setSearch] = useState(_search)
  const [pending, setPending] = useState({
    technicianId: technicianId ?? _search.technicianId,
    date: prefilledDateDT,
    startTime: makeDateTime(prefilledDateDT, _search.startTime),
    endTime: makeDateTime(prefilledDateDT, _search.endTime),
  })
  const [debouncedPending] = useDebounce(pending, 500, { leading: false })

  const jobDuration = useMemo(() => {
    return pending.endTime.diff(pending.startTime, ['hours', 'minutes']).toObject()
  }, [pending])

  const [reason, setReason] = useState('')
  const [ignoreAvailability, setIgnoreAvailability] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fitsOnSchedule, setFitsOnSchedule] = useState(false)

  // Requesting qualified technicians for this quote
  const { loading: loadingTechs, data: qualifiedTechnicians } = useQuery(GET_QUALIFIED_TECHNICIANS, {
    variables: {
      quoteId: quote.id,
      skillsets: quote.requiredSkillsets.concat([quote.difficultyLevel]),
      ignoreDrivingDistance: false,
    },
  })

  const skillsets = useMemo(() => {
    return quote?.requiredSkillsets
      .concat([quote.difficultyLevel])
      .concat((quote.parts.length > 0 && ['part replacements']) || [])
  }, [quote])

  const technicianOptions = useMemo(() => {
    return (qualifiedTechnicians?.qualifiedTechniciansForQuote ?? []).reduce((acc, val) => {
      if (!acc.find(tech => tech.id == val.id)) acc.push(val)
      return acc
    }, quote.approvedTechnicians ?? [])
  }, [quote.approvedTechnicians, qualifiedTechnicians])

  const techName = useMemo(() => {
    return global?.technicians?.find(tech => tech.id === job.technicianId)?.name
  }, [global?.technicians, job?.technicianId])

  function handleClose() {
    if (onChangePendingDate) onChangePendingDate(null)
    if (onClose) onClose()
  }

  function handleSave() {
    setLoading(true)
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/reschedule_job`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        job_id: job.id,
        reason: reason.trim(),
        ignore_availability: ignoreAvailability,
        start_datetime: debouncedPending.startTime,
        end_datetime: debouncedPending.endTime,
        technician_id: debouncedPending.technicianId,
      }),
    })
      .then(res => {
        setLoading(false)
        if (res.ok) {
          setTimeout(refetch, 0)
          handleClose()
        } else {
          alert('Error')
        }
      })
      .catch(() => alert('let henry know about this'))
  }

  function handleChangedDate(event) {
    const date = DateTime.fromISO(event.target.value)
    if (onChangePendingDate) onChangePendingDate(date)
    setPending({
      ...pending,
      date,
      startTime: makeDateTime(date, pending.startTime),
      endTime: makeDateTime(date, pending.endTime),
    })
  }
  function handleChangedStartTime(event) {
    setPending({
      ...pending,
      startTime: makeDateTimeTimeString(pending.date, event.target.value),
    })
  }
  function handleChangedEndTime(event) {
    setPending({
      ...pending,
      endTime: makeDateTimeTimeString(pending.date, event.target.value),
    })
  }
  function handleChangeIgnoreAvailability(event) {
    setIgnoreAvailability(event.target.checked)
  }
  function handleChangeSelectedTechnician(event) {
    setPending({ ...pending, technicianId: event.target.value })
  }

  function handleResetApprovedTechs(e) {
    setLoading(true)
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/reset_approved_techs_on_quote`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        quote_id: quote.id,
      }),
    })
      .then(res => res.status !== 200 && alert('An error occured'))
      .catch(() => alert('An error occured'))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (ignoreAvailability) {
      // console.debug('RescheduleJob.useEffect: skip=ignoreAvailability')
      return
    } else if (!jobDurationIsValid) {
      // console.debug('RescheduleJob.useEffect: skip=jobDurationInvalid')
      return
    }
    const pendingDiff = diff(debouncedPending, search)
    const initialDiff = diff(search, _search)
    if (Object.keys(pendingDiff).length < 1 && Object.keys(initialDiff).length > 0) {
      // console.debug('RescheduleJob.useEffect: skip=noChanges')
      return
    }

    // console.debug('RescheduleJob.useEffect', { pendingDiff, initialDiff })
    setSearch(debouncedPending)
    setFitsOnSchedule(false)
    setLoading(true)
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/check_technician_schedule_fit`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        jobId: job.id,
        startDatetime: debouncedPending.startTime,
        endDatetime: debouncedPending.endTime,
        technician_id: debouncedPending.technicianId,
      }),
    })
      .then(res => {
        setLoading(false)
        if (res.ok) {
          setFitsOnSchedule(true)
          setIgnoreAvailability(false)
        }
      })
      .catch(() => alert('let henry know about this'))
  }, [debouncedPending])

  const jobDurationIsValid = jobDuration.hours > 0 || jobDuration.minutes > 0
  const jobDurationStringOrNull = jobDurationIsValid ? `${jobDuration.hours}h, ${jobDuration.minutes}m` : null
  const reasonIsValid = reason?.trim()
  const saveDisabled = !jobDurationIsValid || (!fitsOnSchedule && !ignoreAvailability) || !reasonIsValid
  const selectTechnicianLabel = `Select Technician (${technicianOptions.length})`

  return (
    <Container maxWidth='sm'>
      <Paper style={{ margin: '1em', padding: '1em', position: 'relative' }}>
        {(loading || loadingTechs) && (
          <Box
            sx={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#11111140',
              pointerEvents: 'none',
              zIndex: 9999,
            }}
          >
            <CircularProgress />
          </Box>
        )}

        <Typography align='center'>Rescheduling {techName}</Typography>
        <Box sx={{ width: '100%', display: 'flex' }}></Box>

        <Grid container sx={{ '> *': { px: 1, pt: 1 } }}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              clearOnBlur
              size='small'
              options={jobRescheduleReasonOptions}
              renderInput={params => <TextField {...params} label='Reason' error={!reasonIsValid} />}
              onChange={(event, value) => setReason(value?.value ?? value)}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Box sx={{ flex: 1 }}>
              <FormControl size='small' fullWidth>
                <InputLabel>{selectTechnicianLabel}</InputLabel>
                <Select
                  value={pending.technicianId}
                  onChange={handleChangeSelectedTechnician}
                  label={selectTechnicianLabel}
                  size='small'
                  sx={{ minWidth: '200px' }}
                >
                  {technicianOptions.map(tech => (
                    <MenuItem key={tech.id} value={tech.id}>
                      {tech.firstName} {tech.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Button onClick={handleResetApprovedTechs} sx={{ minWidth: 'unset' }}>
              <ReplayIcon />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <>Skillsets: &nbsp;</>
            {skillsets.map(skillset => (
              <Chip key={skillset} label={skillset} />
            ))}
          </Grid>
          <Grid item xs={4}>
            <TextField
              size='small'
              fullWidth
              type='date'
              label='date'
              value={pending.date.toFormat('kkkk-LL-dd')}
              onChange={handleChangedDate}
              style={{ maxWidth: '100%' }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size='small'
              fullWidth
              label='start time'
              type='time'
              value={pending.startTime.toFormat('HH:mm')}
              onChange={handleChangedStartTime}
              InputLabelProps={{ shrink: true }}
              inputProps={{ step: 300 }} // 5 min
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size='small'
              fullWidth
              label='end time'
              type='time'
              onChange={handleChangedEndTime}
              value={pending.endTime.toFormat('HH:mm')}
              InputLabelProps={{ shrink: true }}
              inputProps={{ step: 300 }} // 5 min
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            conflicts? &nbsp;
            <SvgIcon
              // fontSize='small'
              component={fitsOnSchedule ? CheckCircleIcon : ErrorIcon}
              style={{ color: fitsOnSchedule ? 'lightgreen' : 'red' }}
            />
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            Job duration:
            {jobDurationStringOrNull || (
              <>
                <ErrorIcon style={{ color: 'red' }} /> Start time must before end time
              </>
            )}
          </Grid>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ flex: 1 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fitsOnSchedule}
                      checked={ignoreAvailability}
                      onChange={handleChangeIgnoreAvailability}
                    />
                  }
                  label='Ignore availability'
                />
              </FormGroup>
            </Box>
            <Box>
              <Button onClick={handleClose}>cancel</Button>
              <Button disabled={saveDisabled} onClick={handleSave}>
                Save
              </Button>
            </Box>
          </Box>
        </Grid>
      </Paper>
    </Container>
  )
}
