import { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { UserContext } from 'UserStore'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import Notes from 'components/Notes'
import TechNotes from 'components/TechNotes'
import Grid from '@mui/material/Grid'
import Breadcrumbs from 'components/Breadcrumbs'
import { TechnicianRows } from 'Schedule/components/TechnicianRows'
import { RescheduleJob } from 'Schedule/components/RescheduleJob'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import TextField from '@mui/material/TextField'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { CreateATask } from 'Tasks'
import StreamDump from 'components/StreamDump'
import {
  cdnUrl,
  thumbnailUrlForPhoto,
  fullSizeUrlForPhoto,
  formatPhotoTimestamp,
  keysToCamel,
  basicDateTimeFormatter,
} from 'tools'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import { Autocomplete, Chip, ListSubheader, Paper, useTheme } from '@mui/material'
import {
  cblCancellationReasons,
  customerCancellationReasons,
  otherCancellationReasons,
  techCancellationReasons,
  didntGoToJobReasons,
  jobCancellationReasonOptions,
} from 'data/reasons'
import { GlobalContext } from 'GlobalStore'
import LabelReadWriter from 'components/LabelReadWriter'
import jobLabels from 'data/jobLabels'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  thumbnail_paper: {
    color: theme.palette.text.secondary,
    padding: '8px 8px 4px 8px',
    display: 'inline-flex',
    marginRight: '.75rem',
    marginBottom: '.75rem',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0,
    },
  },
  thumbnail_paper_container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
  },
  thumbnail_image: {
    width: '100%',
    minWidth: '120px',
    maxHeight: '120px',
    minHeight: '120px',
    objectFit: 'cover',
  },
  grid_section: {
    marginTop: '1em',
  },
  modal_close: {
    top: '0.5em',
    right: '0.5em',
    position: 'absolute',
    fontSize: '1.75em',
    color: 'red',
    border: '1px solid red',
    padding: '0 0.3em',
    borderRadius: '7px',
    zIndex: '10',
    textShadow: '1px 1px 1px #00000045',
    boxShadow: '1px 1px 1px #00000045',
    cursor: 'pointer',
  },
  modal_paper: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 0,
  },
  chipLabel: {
    padding: '0 .25rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chip: {
    position: 'absolute',
    padding: '0rem',
    height: '1.25rem',
    paddingTop: '.15rem',
    borderRadius: '4px',
    background: 'rgba(0, 0, 0, 0.5)',
    marginLeft: '.25rem',
    marginTop: '.25rem',
    backdropFilter: 'blur(6px)',
    '&:hover': {
      cursor: 'pointer',
      background: 'rgba(0, 0, 0, 0.75)',
      '& p': {
        color: '#fff',
        display: 'block',
      },
    },
    '& svg': {
      maxWidth: '1rem',
      display: 'block',
    },
    '& p': {
      display: 'none',
      fontWeight: 700,
      marginLeft: '.25rem',
    },
  },
}))

const ShowEdit = ({ lead, quote, job, refetchJob, refetchQuote }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const classes = useStyles()
  const [user] = useContext(UserContext)
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)
  const [global] = useContext(GlobalContext)

  const theme = useTheme()
  const [photoModalIsOpen, setPhotoModalIsOpen] = useState(false)
  const [modalPhoto, setModalPhoto] = useState('')

  const [showDidntGoModal, setShowDidntGoModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showHoldForReschedulingModal, setShowHoldForReschedulingModal] = useState(false)

  const [reasonForCanceling, setReasonForCanceling] = useState(null)
  const [reasonForHolding, setReasonForHolding] = useState(null)
  const [reasonForTechNotGoing, setReasonForTechNotGoing] = useState(null)

  const [otherReasonForHolding, setOtherReasonForHolding] = useState(false)
  const [otherReasonForTechNotGoing, setOtherReasonForTechNotGoing] = useState(false)

  const [selectedTechnicianId, setSelectedTechnicianId] = useState(job.technicianId)
  const [loadingTechs, setLoadingTechs] = useState(false)
  const [showReschedulePanel, setShowHoldForReschedulePanel] = useState(false)

  const [jobStartDatetime, setJobStartDatetime] = useState(
    DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat("yyyy-MM-dd'T'HH:mm")
  )
  const [jobEndDatetime, setJobEndDatetime] = useState(
    DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toFormat("yyyy-MM-dd'T'HH:mm")
  )

  useEffect(() => {
    setJobStartDatetime(DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat("yyyy-MM-dd'T'HH:mm"))
    setJobEndDatetime(DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toFormat("yyyy-MM-dd'T'HH:mm"))
  }, [job])

  const [photosArray, setPhotosArray] = useState([])
  const partIds = quote.parts.map(part => part.id)

  const handleClickPhotoThumbnail = photo => {
    setModalPhoto(photo)
    setPhotoModalIsOpen(true)
  }

  const handleClosePhotoModal = () => {
    setPhotoModalIsOpen(false)
  }

  const modalPhotoIndex = currentPhoto => photosArray.findIndex(photo => photo.id === currentPhoto.id)

  useEffect(() => {
    const key = registerTaskTarget({ targetType: 'Job', targetId: job.id })
    return () => unregisterTaskTarget(key)
  }, [])

  useEffect(() => {
    if (reasonForTechNotGoing === 'other') {
      setOtherReasonForTechNotGoing(null)
    }
  }, [reasonForTechNotGoing])

  useEffect(() => {
    if (reasonForHolding === 'other') {
      setOtherReasonForHolding(null)
    }
  }, [reasonForHolding])

  const handleChangedReasonForHolding = e => setReasonForHolding(e.target.value)
  const handleChangedOtherReasonForHolding = e => setOtherReasonForHolding(e.target.value)

  function handleChangedReasonForCanceling(e, value) {
    setReasonForCanceling(value)
  }

  const handleChangedReasonForTechNotGoing = e => setReasonForTechNotGoing(e.target.value)
  const handleChangedOtherReasonForTechNotGoing = e => setOtherReasonForTechNotGoing(e.target.value)

  const finalReasonForTechNotGoing =
    (reasonForTechNotGoing && reasonForTechNotGoing !== 'other' && reasonForTechNotGoing) || otherReasonForTechNotGoing
  const finalReasonForHolding =
    (reasonForHolding && reasonForHolding !== 'other' && reasonForHolding) || otherReasonForHolding

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  const stepPhotoBack = () => setModalPhoto(prev => photosArray[Math.max(modalPhotoIndex(prev) - 1, 0)])

  const stepPhotoForward = () =>
    setModalPhoto(prev => photosArray[Math.min(modalPhotoIndex(prev) + 1, photosArray.length - 1)])

  const handleClickHold = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/hold_job_for_rescheduling`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        job_id: job.id,
        reason: finalReasonForHolding,
      }),
    })
      .then(response => {
        if (response.ok) {
          return true
        } else {
          throw Error('error')
        }
      })
      .then(data => {
        setShowHoldForReschedulingModal(false)
      })
      .catch(err => {
        window.alert(err)
      })
  }

  const handleClickSubmitTechDidntGo = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/claim_tech_didnt_go_to_job`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        job_id: job.id,
        reason: finalReasonForTechNotGoing,
      }),
    })
      .then(response => {
        if (response.ok) {
          return true
        } else {
          throw Error('error')
        }
      })
      .then(data => {
        setShowDidntGoModal(false)
      })
      .catch(err => {
        window.alert(err)
      })
  }

  const handleClickSubmitCancel = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/cancel_job`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        job_id: job.id,
        reason: reasonForCanceling,
      }),
    })
      .then(response => {
        if (response.ok) {
          return true
        } else {
          throw Error('error')
        }
      })
      .then(data => {
        setShowCancelModal(false)
      })
      .catch(err => {
        window.alert(err)
      })
  }

  const handleClickSubmitEditCancel = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_job_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        job_id: job.id,
        updated_attributes: { cancellation_reason: reasonForCanceling },
      }),
    })
      .then(response => {
        if (response.ok) {
          return true
        } else {
          throw Error('error')
        }
      })
      .then(data => {
        setShowCancelModal(false)
      })
      .catch(err => {
        window.alert(err)
      })
  }

  const handleAddPhotoToLead = photoUrl => {
    if (window.confirm('Are you sure you want to add this to the lead?')) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/api/add_photo_url_to_lead`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(process.env.REACT_APP_API_USERNAME + ':' + process.env.REACT_APP_API_PASSWORD)}`,
        },
        body: JSON.stringify({
          photo_url: photoUrl,
          lead_id: lead.id,
        }),
      })
        .then(response => {
          if (response.ok) {
            return true
          } else {
            alert('Error')
          }
        })
        .finally(() => setTimeout(() => alert('photo added to lead!'), 500))
    }
  }

  const handleClickMarkComplete = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/mark_job_complete`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        actor_id: user.id,
        actor_type: 'csr',
        job_id: job.id,
      }),
    }).then(response => {
      if (response.ok) {
        refetchJob()
      } else {
        alert('Error. The selected technician may not be have availability that fits this job.')
      }
    })
  }

  const handleClickUnmarkComplete = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unmark_job_complete`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        actor_id: user.id,
        actor_type: 'csr',
        job_id: job.id,
      }),
    }).then(response => {
      if (response.ok) {
        refetchJob()
      } else {
        alert('Error. The selected technician may not be have availability that fits this job.')
      }
    })
  }

  return (
    <>
      <Breadcrumbs lead={lead} quote={quote} job={job} activeObject={job} />

      <Grid container>
        <Grid item xs={6}>
          <ul>
            <li>
              <Link to={`/technicians/${job.technicianId}`}>{job.technician.name}</Link>{' '}
              <span style={{ fontSize: '12px' }}>
                ({DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toFormat('ccc LLL dd, yyyy')})
              </span>
              {!job.canceledAt && <button onClick={() => setShowHoldForReschedulePanel(true)}>reschedule</button>}
            </li>
            <li>from: {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat('ff')}</li>
            <li>to: {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toFormat('ff')}</li>
            <li>
              Status: <Chip label={job.status} />
            </li>
            {job.rescheduledAt && <li>rescheduled at: {basicDateTimeFormatter(job.rescheduledAt)}</li>}
            {job.rescheduledBy && (
              <li>
                rescheduled by: {job.rescheduledBy.firstName} {job.rescheduledBy.lastName.charAt(0)}
              </li>
            )}
            {job.rescheduledReason && <li>rescheduled reason: {job.rescheduledReason}</li>}
            {job.followUpReason && <li>follow up reason: {job.followUpReason}</li>}
            {!job.isActive && job.heldForReschedulingAt && (
              <>
                <li>
                  <button onClick={() => setShowDidntGoModal(true)}>Tech Didn't Go (remove from tech schedule)</button>
                </li>
                <li>
                  <button onClick={() => setShowCancelModal(true)}>Cancel Job (remove from tech schedule)</button>
                </li>
              </>
            )}

            {(job.isActive && (
              <>
                <li>
                  <button onClick={() => setShowDidntGoModal(true)}>Tech Didn't Go (remove from tech schedule)</button>
                </li>
                <li>
                  <button onClick={() => setShowCancelModal(true)}>Cancel Job (remove from tech schedule)</button>
                </li>
                <li>
                  <button onClick={() => setShowHoldForReschedulingModal(true)}>
                    Hold for Rescheduling (temporarily remove from tech schedule)
                  </button>
                </li>
              </>
            )) || (
              <li style={{ color: 'red' }}>
                {job.canceledAt && 'Canceled'}
                {job.heldForReschedulingAt && 'Held for rescheduling'}
                {job.didntGo && "Didn't go"}
              </li>
            )}
            {job.cancellationReason && (
              <li>
                {`cancellation reason: "${job.cancellationReason}"`}
                <Button onClick={() => setShowCancelModal(true)}> Edit </Button>
              </li>
            )}

            <li>
              <CreateATask targetType='Job' targetId={job.id} />
            </li>
            {job.markedCompleteAt && (
              <li>marked complete at: {DateTime.fromISO(job.markedCompleteAt).toFormat('DD t')}</li>
            )}
            {!job.markedCompleteAt && (
              <li>
                <Button variant='outlined' size='small' onClick={handleClickMarkComplete}>
                  Mark Complete{' '}
                </Button>
              </li>
            )}
            {job.markedCompleteAt && (
              <Button variant='outlined' size='small' onClick={handleClickUnmarkComplete}>
                Unmark Complete{' '}
              </Button>
            )}
            <li>
              <LabelReadWriter
                obj={job}
                idName='jobId'
                options={jobLabels}
                endpoint='update_job_attributes'
                refetch={refetchJob}
              />
            </li>
          </ul>
        </Grid>
        {showReschedulePanel && <RescheduleJob job={job} refetch={refetchJob} />}
      </Grid>

      <Modal open={showDidntGoModal} onClose={() => setShowDidntGoModal(false)}>
        <Box sx={modalStyle}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            The tech didn't go
          </Typography>

          <br />

          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel>Reason</InputLabel>
            <Select
              value={reasonForTechNotGoing || 'none'}
              onChange={handleChangedReasonForTechNotGoing}
              autoWidth
              label='Reason'
              name='didnt-go-reason'
            >
              <MenuItem value='none'>Must select a reason</MenuItem>
              {didntGoToJobReasons.map((reason, index) => (
                <MenuItem key={index} value={reason}>
                  {reason}
                </MenuItem>
              ))}
              <MenuItem value='other'>Other</MenuItem>
            </Select>

            {reasonForTechNotGoing === 'other' && (
              <TextField
                multiline
                onChange={handleChangedOtherReasonForTechNotGoing}
                label='Reason'
                variant='outlined'
              />
            )}
          </FormControl>

          <br />

          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <Button
              name='confirm-didnt-go-button'
              disabled={!finalReasonForTechNotGoing}
              variant='outlined'
              onClick={handleClickSubmitTechDidntGo}
            >
              Submit
            </Button>
          </FormControl>
        </Box>
      </Modal>

      <Modal open={showHoldForReschedulingModal} onClose={() => setShowHoldForReschedulingModal(false)}>
        <Box sx={modalStyle}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Hold this job for rescheduling
            <br />
            The technician will remain assigned to the Quote
          </Typography>

          <br />

          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel>Reason</InputLabel>
            <Select
              value={reasonForHolding || 'none'}
              onChange={handleChangedReasonForHolding}
              autoWidth
              label='Reason'
              name='hold-job-reason'
            >
              <MenuItem value='none'>Must select a reason</MenuItem>
              <ListSubheader>Carbodylab</ListSubheader>
              {cblCancellationReasons.map(r => (
                <MenuItem key={r} value={r}>
                  {r}
                </MenuItem>
              ))}
              <ListSubheader>Technician</ListSubheader>
              {techCancellationReasons.map(r => (
                <MenuItem key={r} value={r}>
                  {r}
                </MenuItem>
              ))}
              <ListSubheader>Customer</ListSubheader>
              {customerCancellationReasons.map(r => (
                <MenuItem key={r} value={r}>
                  {r}
                </MenuItem>
              ))}
              <ListSubheader>Other</ListSubheader>
              {otherCancellationReasons.map(r => (
                <MenuItem key={r} value={r}>
                  {r}
                </MenuItem>
              ))}
              <MenuItem value='other'>other</MenuItem> <MenuItem value='other'>Other</MenuItem>
            </Select>

            {reasonForHolding === 'other' && (
              <TextField multiline onChange={handleChangedOtherReasonForHolding} label='Reason' variant='outlined' />
            )}
          </FormControl>

          <br />

          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <Button
              name='confirm-hold-job-button'
              disabled={!finalReasonForHolding}
              variant='outlined'
              onClick={handleClickHold}
            >
              Hold
            </Button>
          </FormControl>
        </Box>
      </Modal>

      <Modal open={showCancelModal} onClose={() => setShowCancelModal(false)}>
        <Box sx={modalStyle}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {job?.cancellationReason ? 'Update cancellation reason' : 'Cancel this job'}
          </Typography>

          <br />
          <Autocomplete
            fullWidth
            clearOnBlur
            options={jobCancellationReasonOptions}
            renderInput={params => <TextField {...params} label='Cancellation Reason' />}
            onChange={handleChangedReasonForCanceling}
          />

          <br />

          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <Button
              name='confirm-cancel-job-button'
              disabled={!reasonForCanceling}
              variant='outlined'
              onClick={e => {
                if (job?.cancellationReason) {
                  handleClickSubmitEditCancel(e)
                } else {
                  handleClickSubmitCancel(e)
                }
              }}
            >
              {job?.cancellationReason ? 'Save' : 'Cancel this job'}
            </Button>
          </FormControl>
        </Box>
      </Modal>

      <div className={classes.root}>
        {(job.isActive || job.heldForReschedulingAt) && (
          <Grid container>
            <Grid item xs={12}>
              <TechnicianRows
                focusJob={job}
                technician={job.technician}
                startDatetime={DateTime.fromISO(job.startDatetime).setZone('utc').startOf('day')}
              />
            </Grid>
          </Grid>
        )}

        <Box sx={{ minHeight: '1rem ' }} />

        <Grid container>
          <Grid item xs={6}>
            <Typography variant='h6'> Pre job photos </Typography>
            {job?.preJobPhotos?.map(photo => (
              <Paper
                key={photo.id}
                sx={{
                  color: theme.palette.text.secondary,
                  padding: '8px 8px 4px 8px',
                  display: 'inline-flex',
                  marginRight: '.75rem',
                  marginBottom: '.75rem',
                  cursor: 'pointer',
                  '&:last-child': {
                    marginRight: 0,
                  },
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 auto',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      minWidth: '120px',
                      maxHeight: '120px',
                      minHeight: '120px',
                      objectFit: 'cover',
                    }}
                    onClick={() => {
                      handleClickPhotoThumbnail(photo)
                      setPhotosArray(job.preJobPhotos)
                    }}
                    src={thumbnailUrlForPhoto(keysToCamel(photo))}
                  />

                  <div style={{ marginTop: '4px' }}>{formatPhotoTimestamp(photo.timestamp)}</div>
                  <Button
                    onClick={() => handleAddPhotoToLead(fullSizeUrlForPhoto(keysToCamel(photo)))}
                    disableElevation
                    size='small'
                    variant='contained'
                  >
                    Add to Lead
                  </Button>
                </div>
              </Paper>
            ))}
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'> Post job photos </Typography>
            {job?.postJobPhotos?.map(photo => (
              <Paper
                key={photo.id}
                sx={{
                  color: theme.palette.text.secondary,
                  padding: '8px 8px 4px 8px',
                  display: 'inline-flex',
                  marginRight: '.75rem',
                  marginBottom: '.75rem',
                  cursor: 'pointer',
                  '&:last-child': {
                    marginRight: 0,
                  },
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 auto',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      minWidth: '120px',
                      maxHeight: '120px',
                      minHeight: '120px',
                      objectFit: 'cover',
                    }}
                    onClick={() => {
                      handleClickPhotoThumbnail(photo)
                      setPhotosArray(job.postJobPhotos)
                    }}
                    src={thumbnailUrlForPhoto(keysToCamel(photo))}
                  />

                  <div style={{ marginTop: '4px' }}>{formatPhotoTimestamp(photo.timestamp)}</div>
                  <Button
                    onClick={() => handleAddPhotoToLead(fullSizeUrlForPhoto(keysToCamel(photo)))}
                    disableElevation
                    size='small'
                    variant='contained'
                  >
                    Add to Lead
                  </Button>
                </div>
              </Paper>
            ))}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Notes objectType='job' objectId={job.id} />
          </Grid>
          <Grid item xs={6}>
            <TechNotes
              parentType='job'
              parentId={job.id}
              leadId={lead.id}
              quoteId={quote.id}
              jobId={job.id}
              partIds={partIds}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StreamDump stream={`Job$${job.id}`} />
          </Grid>
        </Grid>
      </div>

      {job.preJobPhotos?.length > 0 && (
        <Modal open={photoModalIsOpen} onClose={handleClosePhotoModal}>
          <div className={classes.modal_paper}>
            <div onClick={() => setPhotoModalIsOpen(false)} className={classes.modal_close}>
              X
            </div>

            <PinchZoomPan position='center' maxScale={2}>
              <img src={cdnUrl(modalPhoto ? fullSizeUrlForPhoto(keysToCamel(modalPhoto)) : '')} />
            </PinchZoomPan>

            {modalPhoto.id !== job.preJobPhotos[0].id && (
              <div
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  zIndex: 100,
                  position: 'absolute',
                  left: 0,
                  top: '50%',
                  padding: '0.5em',
                  fontSize: '1.5em',
                  cursor: 'pointer',
                }}
                onClick={stepPhotoBack}
              >
                ◄
              </div>
            )}
            {modalPhoto.id !== job.preJobPhotos[job.preJobPhotos.length - 1].id && (
              <div
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  zIndex: 100,
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  padding: '0.5em',
                  fontSize: '1.5em',
                  cursor: 'pointer',
                }}
                onClick={stepPhotoForward}
              >
                ►
              </div>
            )}
          </div>
        </Modal>
      )}

      {job.postJobPhotos?.length > 0 && (
        <Modal open={photoModalIsOpen} onClose={handleClosePhotoModal}>
          <div className={classes.modal_paper}>
            <div onClick={() => setPhotoModalIsOpen(false)} className={classes.modal_close}>
              X
            </div>

            <PinchZoomPan position='center' maxScale={2}>
              <img src={cdnUrl(modalPhoto ? fullSizeUrlForPhoto(keysToCamel(modalPhoto)) : '')} />
            </PinchZoomPan>

            {modalPhoto.id !== job.postJobPhotos[0].id && (
              <div
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  zIndex: 100,
                  position: 'absolute',
                  left: 0,
                  top: '50%',
                  padding: '0.5em',
                  fontSize: '1.5em',
                  cursor: 'pointer',
                }}
                onClick={stepPhotoBack}
              >
                ◄
              </div>
            )}
            {modalPhoto.id !== job.postJobPhotos[job.postJobPhotos.length - 1].id && (
              <div
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  zIndex: 100,
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  padding: '0.5em',
                  fontSize: '1.5em',
                  cursor: 'pointer',
                }}
                onClick={stepPhotoForward}
              >
                ►
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  )
}

export default ShowEdit
