import { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalStore'
import Container from '@mui/material/Container'
import { useQuery, useLazyQuery, gql } from '@apollo/client'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import DatePicker from 'react-date-picker'
import VisibilitySensor from 'react-visibility-sensor'
import Skeleton from '@mui/material/Skeleton'
import { DateTime } from 'luxon'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { TechnicianRows } from './components/TechnicianRows'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const techIndexStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  filterFormControl: {
    marginRight: 0,
  },
  stickyHeader: {
    backgroundColor: 'white',
    zIndex: 1,
    position: 'sticky',
    top: '64px',
    margin: '0',
    boxShadow: '0px 1px 2px slategrey',
    borderRadius: '3px',
  },
}))

const MARKETS_QUERY = gql`
  query markets {
    markets {
      state
      stateCode
      market
    }
  }
`

const HELD_JOBS = gql`
  query heldJobs {
    heldJobs {
      id
      startDatetime
      technicianId
      lead {
        id
        name
      }
    }
  }
`

const heldJobsModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const Schedule = () => {
  const history = useHistory()
  const [global] = useContext(GlobalContext)
  const classes = techIndexStyles()
  const [startDatetime, setStartDatetime] = useState(
    DateTime.fromISO(DateTime.local().toFormat('yyyy-LL-dd'), { zone: 'utc' })
  )
  const [dateTimeForPicker, setDatetimeForPicker] = useState(DateTime.local())
  const [showHeldJobsModal, setShowHeldJobsModal] = useState(false)
  const [jobFilter, setJobFilter] = useState({ b2c: true, b2b: true })

  const [filter, setFilter] = useState(JSON.parse(localStorage.getItem('scheduleFilter')) || { active: true })
  useEffect(() => window.localStorage.setItem('scheduleFilter', JSON.stringify(filter)), [filter])

  useEffect(() => {
    setStartDatetime(DateTime.fromISO(dateTimeForPicker.toFormat('yyyy-LL-dd'), { zone: 'utc' }))
  }, [dateTimeForPicker])

  const {
    error,
    loading,
    data: heldJobsData,
    refetch,
  } = useQuery(HELD_JOBS, {
    fetchPolicy: 'network-only',
  })

  const [getMarkets, { error: marketsError, loading: marketsLoading, data: marketsData }] = useLazyQuery(
    MARKETS_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    getMarkets()
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const heldJobs = heldJobsData.heldJobs

  const removeFilter = filterName => {
    const { [filterName]: removed, ...remainingFilter } = filter
    setFilter(remainingFilter)
  }

  const handleStateFilterChanged = event => setFilter(prev => ({ ...filter, ...{ state: event.target.value } }))

  const handleActiveStatusFilterChanged = event =>
    setFilter(prev => ({ ...prev, active: event.target.value === 'active' }))

  const techniciansByMarket = global.technicians
    .filter(tech => (filter.hasOwnProperty('active') ? tech.active === filter.active : true))
    .filter(tech => (filter.hasOwnProperty('state') ? tech.state === filter.state : true))
    .reduce((acc, tech) => {
      return {
        ...acc,
        ...{
          [tech.displayMarket]: Array.isArray(acc[tech.displayMarket]) ? acc[tech.displayMarket].concat(tech) : [tech],
        },
      }
    }, {})

  if (marketsLoading) return <>Loading...</>
  if (marketsError) return <>Error!</>

  const states = marketsData.markets.reduce((acc, item) => ({ ...acc, [item.stateCode]: item.state }), {})
  const stateMenuItems = Object.keys(states).map(k => (
    <MenuItem key={k} value={k}>
      <em>{states[k]}</em>
    </MenuItem>
  ))

  return (
    <Container disableGutters maxWidth={false}>
      <Grid container className={classes.stickyHeader}>
        <Grid item xs={9}>
          <Stack direction='row' spacing={1}>
            <Item>
              14 days from:&nbsp;
              <DatePicker
                value={dateTimeForPicker.toJSDate()}
                clearIcon={null}
                format='yy/MM/dd'
                onChange={jsDate => setDatetimeForPicker(DateTime.fromJSDate(jsDate))}
              />
            </Item>
            <Item>
              <FormControl size='small' variant='outlined' className={classes.filterFormControl}>
                <InputLabel>State</InputLabel>
                <Select value={filter.state || ''} onChange={handleStateFilterChanged} label='State'>
                  {stateMenuItems}
                </Select>
              </FormControl>
              <button onClick={() => removeFilter('state')}>X</button>
            </Item>

            <Item>
              <div>
                <FormControl size='small' variant='outlined' className={classes.filterFormControl}>
                  <InputLabel>Active Status</InputLabel>
                  <Select
                    value={filter.active ? 'active' : 'inactive'}
                    onChange={handleActiveStatusFilterChanged}
                    label='Active Status'
                  >
                    <MenuItem value='active'>
                      <em>Active</em>
                    </MenuItem>

                    <MenuItem value='inactive'>
                      <em>Inactive</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Item>

            {heldJobs.length > 0 && (
              <Item>
                <Button variant='outlined' color='secondary' onClick={() => setShowHeldJobsModal(true)}>
                  held jobs ({`${heldJobs.length}`})
                </Button>

                <Modal open={showHeldJobsModal} onClose={() => setShowHeldJobsModal(false)}>
                  <Box sx={heldJobsModalStyle}>
                    <Typography variant='h6' component='h5'>
                      Jobs held for rescheduling
                    </Typography>
                    <ul>
                      {heldJobs.map(job => (
                        <li key={job.id}>
                          <Link to={`/leads/${job.lead.id}`}>
                            {job.lead.name},&nbsp; previously for{' '}
                            {DateTime.fromISO(job.startDatetime, { setZone: true }).toFormat('kkkk-LL-dd')}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Modal>
              </Item>
            )}

            <Item>
              <FormControl component='fieldset' variant='standard'>
                <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={jobFilter.b2c}
                        onClick={() => {
                          setJobFilter(prev => ({
                            ...prev,
                            b2c: !prev.b2c,
                          }))
                        }}
                        name='B2C'
                      />
                    }
                    label='B2C Jobs'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={jobFilter.b2b}
                        onClick={() => {
                          setJobFilter(prev => ({
                            ...prev,
                            b2b: !prev.b2b,
                          }))
                        }}
                        name='B2B'
                      />
                    }
                    label='B2B Jobs'
                  />
                </FormGroup>
              </FormControl>
            </Item>
          </Stack>
        </Grid>

        <Grid item xs={3}>
          <Stack direction='row' justifyContent='flex-end' alignItems='flex-start' spacing={1}>
            <Item>
              <Button color='primary' variant='contained' onClick={() => history.push('/technicians/new')}>
                {' '}
                Add Tech{' '}
              </Button>
            </Item>
          </Stack>
        </Grid>
      </Grid>

      <div className={classes.root}>
        {Object.entries(techniciansByMarket)
          .sort((a, b) => (a[0].toUpperCase() < b[0].toUpperCase() ? -1 : 1))
          .map(([market, technicians]) => (
            <Grid container key={market} style={{ backgroundColor: 'rgb(213 216 229)', marginTop: '1em' }} spacing={1}>
              <TechniciansByMarket
                market={market}
                technicians={technicians}
                startDatetime={startDatetime}
                jobFilter={jobFilter}
              />
            </Grid>
          ))}
      </div>
    </Container>
  )
}

const TechniciansByMarket = ({ market, technicians, startDatetime, jobFilter }) => {
  return (
    <>
      <Typography style={{ margin: '0.5em 1em', fontSize: '1.2em' }} variant='h6'>
        {market}
      </Typography>
      {technicians.map(technician => (
        <TechnicianRowVisibilityWrapper key={technician.id}>
          <TechnicianRows startDatetime={startDatetime} technician={technician} jobFilter={jobFilter} />
        </TechnicianRowVisibilityWrapper>
      ))}
    </>
  )
}

const TechnicianRowVisibilityWrapper = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleVisibilityChanged = currentVisibility => {
    setTimeout(() => setIsVisible(isVisible || currentVisibility), 100)
  }

  return (
    <VisibilitySensor onChange={handleVisibilityChanged}>
      {(isVisible && <>{children}</>) || <Skeleton variant='rect' width={21} height={70} />}
    </VisibilitySensor>
  )
}

export default Schedule
