export const techCancellationReasons = [
  'tech cancelled',
  'tech late',
  'tech needs more time',
  'tech no show / no call',
  'tech sick',
  "tech's truck broke down",
]
export const cblCancellationReasons = [
  'follow up',
  'follow up / paid half',
  'incomplete',
  'parts not ordered',
  'pending pick up',
  'too big for mobile',
  'unrepairable',
  'pending to paint',
  'redo',
]
export const customerCancellationReasons = [
  'location not good',
  'client canceled',
  'client rescheduled',
  'client no show / no call',
  'client late',
]
export const otherCancellationReasons = [
  'am job took longer',
  'color not matching',
  'parts delayed',
  'revised quote',
  'more damage',
  'incorrect parts',
  'weather',
]

export const didntGoToJobReasons = ['vehicle broke down', 'family emergency', 'sick', 'morning job taking longer']

function makeOption(value, group) {
  return { value, group }
}

export const jobFollowupReasonOptions = ['redo', 'follow_up', 'follow_up_for_another_tech', 'redo_for_another_tech']

const jobMovedReasonOptions = [
  "tech didn't go",
  'client cancelled',
  'tech cancelled',
  'weather',
  'location',
  'parts issues',
  'too big for mobile',
  'office',
  'calendar_optimization',
  'held_for_rescheduling',
  'am_took_longer',
]

export const jobRescheduleReasonOptions = jobMovedReasonOptions

export const jobCancellationReasonOptions = jobMovedReasonOptions
