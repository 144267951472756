import { gql } from "@apollo/client"

export interface GetUserProps {
  id?: string;
}

export interface User {
  id?: string,
  roles?: string[],
  daysOff?: string[],
  availabilityHours?: {
    monday?: string[],
    tuesday?: string[],
    wednesday?: string[],
    thursday?: string[],
    friday?: string[],
    saturday?: string[],
    sunday?: string[]
  }
}

export interface GetUserRes {
  user?: User
}

export const GET_USER = gql`
  query getUser ($id: ID!) {
    user(id: $id) {
        id
        roles
        daysOff
        availabilityHours
    }
  }
`