import dayjs from 'dayjs'
import dayjsUtc from 'dayjs/plugin/utc'
import { useQuery } from '@apollo/client'
import { Interview } from '../useInterviews/query'
import { daysConst } from 'Recruiting/Recruiter/RecruiterAvailability/AvailabilityHours/AvailabilityHours'
import { GetInterviewsRes, GetInterviewsReq, GET_ALL_RECRUITER_INTERVIEWS } from './query'
import { DateTime } from 'luxon'

dayjs.extend(dayjsUtc)

interface Week {
  monday?: Interview[]
  tuesday?: Interview[]
  wednesday?: Interview[]
  thursday?: Interview[]
  friday?: Interview[]
  saturday?: Interview[]
  sunday?: Interview[]
}

const defaultWeek: Week = {}
daysConst?.forEach(day => (defaultWeek[day] = []))

export const useRecruiterInterviews = (recruiter_id?: string) => {
  const { data, loading } = useQuery<GetInterviewsRes, GetInterviewsReq>(GET_ALL_RECRUITER_INTERVIEWS, {
    variables: { recruiter_id },
    skip: !recruiter_id,
  })

  const weeks: { [week: string]: Week } = {}

  const interviewsRaw = data?.recruiterInterviews ?? []
  const interviews = interviewsRaw?.map(interview => {
    let status = 'pending'
    if (interview?.canceledAt) status = 'canceled'
    if (interview?.startDatetime && DateTime.fromISO(interview?.startDatetime) < DateTime.now().endOf('day'))
      status = 'completed'

    return {
      ...interview,
      status,
    }
  })

  interviews?.forEach(interview => {
    const startDate = dayjs.utc(interview?.startDatetime).local()
    const startDateDay = daysConst[startDate.day()]

    const startWeek = startDate.startOf('week')?.format('ll')
    const endWeek = startDate.endOf('week')?.format('ll')

    const weekKey = `${startWeek} - ${endWeek}`
    if (!weeks[weekKey]) weeks[weekKey] = defaultWeek

    const week = { ...weeks[weekKey] }
    const day = [...(week[startDateDay] ?? [])]
    day.push(interview)

    day.sort((a, b) => dayjs(a?.startDatetime)?.valueOf() - dayjs(b?.startDatetime)?.valueOf())
    week[startDateDay] = day
    weeks[weekKey] = week
  })

  return {
    interviews,
    loading,
    weeks,
  }
}
