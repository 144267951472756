import { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { useQuery, gql, useLazyQuery } from '@apollo/client'
import { UserContext } from 'UserStore'
import { basicDateTimeFormat, PriceInput, Dollars } from 'tools'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Button, Chip, TextField } from '@mui/material'
import { DateTime } from 'luxon'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const QUERY = gql`
  query SickTimeOff($technicianId: ID, $unclaimed: Boolean) {
    sickTimeOffs(technicianId: $technicianId, unclaimed: $unclaimed) {
      id
      createdAt
      totalHours
      payoutId
      note
      deletedAt
      timeOffDate
      payout {
        id
        createdAt
      }
    }
  }
`

const SickTimeOff = ({ technician, prefetched }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [totalHoursAmount, setTotalHoursAmount] = useState(0)
  const [manualCompNote, setManualCompNote] = useState('')
  const [ptoDate, setPtoDate] = useState(DateTime.now())
  //
  const handleChangeNote = e => setManualCompNote(e.target.value)
  const handleChangeDate = e => setPtoDate(DateTime.fromISO(e.target.value))

  const handleClickCreateSickTimeOff = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_sick_time_off`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        technician_id: technician.id,
        total_hours: Number(totalHoursAmount),
        note: manualCompNote,
        date: ptoDate,
      }),
    })
      .then(response => {
        if (response.ok) {
          refetch()
        } else {
          throw Error('error')
        }
      })
      .then(data => {
        setTotalHoursAmount(0)
        setManualCompNote('')
      })
      .catch(err => {
        window.alert(err)
      })

  const deleteSickTimeOff = id =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_sick_time_off`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        sick_time_off_id: id,
      }),
    }).then(res => console.log('ok') || alert('Error'))

  const [fetchData, { loading, error, data, refetch }] = useLazyQuery(QUERY, {
    variables: { technicianId: technician.id, unclaimed: true },
  })

  useEffect(() => {
    !prefetched && fetchData()
  }, [prefetched])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const sickTimeOff = prefetched || data?.sickTimeOffs || []

  return (
    <>
      <span style={{ fontSize: '12px', color: '#111', fontWeight: 700 }}>Sick time off::&nbsp;</span>

      {sickTimeOff
        .filter(re => !re.deletedAt)
        .map(pto => (
          <Chip
            size='small'
            key={pto.id}
            label={
              <>
                {DateTime.fromISO(pto.timeOffDate, { zone: 'utc' }).toFormat('kkkk-LL-dd')}- {pto.totalHours} hour(s) -{' '}
                {pto.note}- <button onClick={() => deleteSickTimeOff(pto.id)}>Delete</button>
              </>
            }
          />
        ))}
      {sickTimeOff.length === 0 && <span style={{ fontSize: '12px', color: '#777 ' }}>None</span>}
    </>
  )
}

export default SickTimeOff
