import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import { DateTime } from 'luxon'
import { useChannel, useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { fileExtensionPattern, getUploadParamsForDropzone, uuid } from 'tools'
import Dropzone from 'react-dropzone-uploader'
import TechChat from 'Chat'
import 'react-dropzone-uploader/dist/styles.css'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import VisibilitySensor from 'react-visibility-sensor-v2'
import { Box, Button, Grid, Modal, Stack, Skeleton, TextField, Typography, CircularProgress } from '@mui/material'
import axios from 'axios'

const Chat = ({ technician }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [messages, setMessages] = useState([])
  const [global] = useContext(GlobalContext)
  const [user] = useContext(UserContext)
  const [body, setBody] = useState('')
  const [photoModalUrl, setPhotoModalUrl] = useState(null)
  const [photoClipboard, setPhotoClipboard] = useState(null)
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    (photoClipboard) && scrollToBottom()
  },[photoClipboard])

  const scrollToBottom = () => {
    const element = document.getElementById('techChatList')
    if (element) {
      element.scrollTop = element.scrollHeight - element.clientHeight
    }
  }

  const channel = useChannel(`tech_smss:${technician.id}`, undefined, (channel, { messages: techChatMessages }) => {
    setMessages(techChatMessages.map((message, index) => ({ ...message, renderPhotos: index < 5 })).reverse())
    setTimeout(scrollToBottom, 300)
  })

  useEventHandler(channel, 'tech_sms_created', ({ message }) => {
    setMessages(messages => [...messages, { ...message, renderPhotos: true }])
    setTimeout(scrollToBottom, 250)
  })

  useEventHandler(channel, 'tech_sms_updated', ({ message }) => {
    setMessages(prev => prev.map(thisMsg => (message.id === thisMsg.id ? { ...message, renderPhotos: true } : thisMsg)))
  })

  const handleVisibilityOfMessagePhotos = (messageId, visibility) => {
    if (!visibility) {
      return null
    }

    setMessages(messages =>
      messages.map(message => (message.id === messageId ? { ...message, renderPhotos: true } : message))
    )
  }

  const createOutgoingTechSms = () => {
    setWaiting(true)
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_outgoing_tech_sms`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        technician_id: technician.id,
        body: body.trim(),
      }),
    })
      .then(response => {
        if (response.ok) {
          setBody('')
          scrollToBottom()
          return true
        } else {
          throw Error('error')
        }
      })
      .catch(e => {
        alert(e.message)
      })
      .finally(() => setTimeout(() => setWaiting(false), 1000))
  }

  const userById = userId => global.users.find(user => user.id === userId)
  const userName = userId => {
    const user = userById(userId)
    return `${user.firstName} ${user.lastName[0]}`
  }

  const photos = message => {
    if (!message.mediaUrls) {
      return null
    }

    const photoWidthNumber = Math.floor(Math.max(300 / message.mediaUrls.length, 120))
    const photoWidthPx = `${photoWidthNumber}px`
    const photoHeightNumber = Math.floor(Math.max(120 / message.mediaUrls.length, 45))
    const photoHeightPx = `${photoHeightNumber}px`

    return (
      <>
        {message.mediaUrls.map(mediaUrl => (
          <div
            key={mediaUrl}
            style={{
              border: '2px solid #efefef',
              display: 'inline-block',
              width: photoWidthPx,
              height: photoHeightPx,
            }}
          >
            <VisibilitySensor
              onChange={isVisible => handleVisibilityOfMessagePhotos(message.id, isVisible)}
              intervalDelay={250}
            >
              {(message.renderPhotos && (
                <img
                  alt="chat-img"
                  src={mediaUrl}
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                    cursor: 'pointer',
                  }}
                  onClick={() => setPhotoModalUrl(mediaUrl)}
                />
              )) || <Skeleton variant='rectangular' width={photoWidthNumber} height={photoHeightNumber} />}
            </VisibilitySensor>
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <Modal open={!!photoModalUrl} onClose={() => setPhotoModalUrl(null)}>
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            paddingTop: '64px',
          }}
          onClick={() => setPhotoModalUrl(null)}
        >
          <PinchZoomPan position='center' maxScale={2}>
            <img src={photoModalUrl} />
          </PinchZoomPan>
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              marginBottom: '1em',
              padding: '0.5em 0',
              backgroundColor: 'white',
              textAlign: 'center',
            }}
          >
            <CopyToClipboard style={{ cursor: 'pointer' }} text={photoModalUrl}>
              <span>
                Photo url: (click to copy)
                <br />
                {photoModalUrl}
              </span>
            </CopyToClipboard>
            <div style={{ cursor: 'pointer', position: 'absolute', top: '8px', right: '8px' }}>
              [click anywhere to close]
            </div>
          </div>
        </div>
      </Modal>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant='h6'>
            SMS with {technician.firstName} {technician.lastName}
          </Typography>
          <div
            id='techChatList'
            style={{
              maxHeight: '500px',
              overflow: 'auto',
            }}
          >
            {messages.map(message => (
              <div key={message.id}>
                {(message.userId && (
                  <div style={{ padding: '0.5em', margin: '0.2em' }}>
                    <Typography>
                      <b>{userName(message.userId)}</b>&nbsp;
                      <small style={{ color: '#747474' }}>
                        {DateTime.fromISO(message.timestamp).toLocaleString(DateTime.DATETIME_FULL)}
                        {message.deliveredAt && ' (delivered)'}
                      </small>
                    </Typography>

                    <div style={{ whiteSpace: 'pre-wrap', padding: '0.2em 0.8em' }}>{message.body}</div>

                    {photos(message)}
                  </div>
                )) || (
                  <div style={{ padding: '0.5em', margin: '0.2em', backgroundColor: 'rgb(165 255 242 / 32%)' }}>
                    <Typography>
                      <b>{technician.firstName}</b>&nbsp;
                      <small style={{ color: 'gray' }}>
                        {DateTime.fromISO(message.timestamp).toLocaleString(DateTime.DATETIME_FULL)}
                      </small>
                    </Typography>

                    <div style={{ padding: '0.5em' }}>{message.body}</div>

                    {photos(message)}
                  </div>
                )}
              </div>
            ))}
            <Grid style={{ padding: '0.5em', margin: '0.2em' }}>
              { photoClipboard && (
                <Preview
                  technicianId={technician.id}
                  photoClipboard={photoClipboard}
                  setPhotoClipboard={setPhotoClipboard}
                  setMessages={setMessages}
                />
              )}
            </Grid>
          </div>

          <TextField
            cy-data='technician-profile-sms-input'
            style={{ width: '100%' }}
            label='...'
            multiline
            value={body}
            onChange={event => setBody(event.target.value)}
            onPaste={ (event) => {
              const clipboardItems = event.clipboardData.items;
              const items = [].slice.call(clipboardItems).filter((item) => {
                return item.type.indexOf('image') !== -1; // Filter the image items only
              });

              if (items.length > 0) {
                const blob = items[0]?.getAsFile();
                setPhotoClipboard(blob)
              }
            }}
          />

          <Button
            cy-data='technician-profile-sms-submit'
            style={{ marginTop: '5px', minWidth: '100px' }}
            variant='outlined'
            color='primary'
            disabled={waiting || body.length === 0}
            onClick={createOutgoingTechSms}
          >
            Send
          </Button>

          <br />
          <br />
          <FileUploader technician={technician} />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5}>
          <TechChat contextType='Technician' contextId={technician.id} />
        </Grid>
      </Grid>
    </>
  )
}

const Preview = ({ technicianId, photoClipboard, setPhotoClipboard, setMessages }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  if (!photoClipboard) {
    return
  }

  const handleUploadImage = () => {
    setIsUploading(true)
    getUploadParamsForDropzone({
      file: photoClipboard,
      meta: {
        name: `${uuid()}-${Date.now()}${photoClipboard.name.match(fileExtensionPattern)[0]}`
      }
    })
    .then(async (res) => {
      await axios({
        method: 'put',
        url: res.url,
        headers: { 'Content-Type': photoClipboard.type },
        data: photoClipboard,
        onUploadProgress: data => {
          setUploadPercentage(Math.round((100 * data.loaded) / data.total))
        }
      })
      return res
    })
    .then((res) => {
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_COMMAND_ROOT}/create_outgoing_tech_sms`,
        headers: bearerTokenHeaders,
        data: {
          userId: user.id,
          technicianId: technicianId,
          body: '',
          mediaUrls: [res.meta.fileUrl],
        },
      })
    })
    .catch(err => { console.error(err.message) })
    .finally(() => {
      setPhotoClipboard(null)
      setIsUploading(false)
    })
  }

  return (
    <Grid container>
      <Grid item xs={8}>
        <Typography sx={{ color: 'grey'}}>
          {(isUploading) ? 'File uploading...' : 'Press SEND to send as text'}
        </Typography>
        <Box sx={{ height:"120px", width:"300px", border: '2px solid #efefef' }}>
          {(isUploading) ? (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              background: 'rgba(0, 0, 0, 0.7)',
              borderRadius: '8px',
              backdropFilter: 'blur(6px)',
              color: '#fff',
              fontWeight: 600,
              fontFamily: 'Lato'
            }}>
              <CircularProgress sx={{ color: '#fff' }} />
              <p> {`${uploadPercentage}%`} </p>
            </Box>
          ) : (
            <img
              src={URL.createObjectURL(photoClipboard)}
              alt="preview"
              style={{
                objectFit: 'cover',
                height: '100%',
                width: '100%',
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Stack direction="column" justifyContent="space-evenly" alignItems="flex-start" sx={{ height: ' 100%'}}>
          <Button
            disabled={isUploading}
            variant="outlined"
            color="error"
            sx={{ minWidth: "100px" }}
            onClick={() => setPhotoClipboard(null)}
          >
            Cancel
          </Button>
          <Button
            disabled={isUploading}
            variant="outlined"
            sx={{ minWidth: "100px" }}
            onClick={ handleUploadImage }
          >
            Send
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}

const FileUploader = ({ technician }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const handleSubmit = files => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_outgoing_tech_sms`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        technicianId: technician.id,
        body: '',
        mediaUrls: files.map(file => file.meta.fileUrl),
      }),
    })
      .then(response => {
        if (response.ok) {
          files.forEach(file => file.remove())
          return true
        } else {
          throw Error('error')
        }
      })
      .catch(e => {
        alert(e.message)
      })
  }

  return (
    <div id='technician-sms-uploader'>
      <Dropzone
        addClassNames={{ dropzone: 'dzu-dropzone-custom' }}
        getUploadParams={getUploadParamsForDropzone}
        multiple
        accept='image/*'
        onSubmit={handleSubmit}
        submitButton
        inputContent='Drop Photos or click to Browse'
      />
    </div>
  )
}

export default Chat
