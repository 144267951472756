import { useEffect, useState } from 'react'
import axios from 'axios'

export const useSquareSDK = (endPoint, state) => {
  const [squareCard, setSquareCard] = useState()
  const [squarePaymentModule, setSquarePaymentModule] = useState()
  const [cardIsReady, setCardIsReady] = useState(false)
  const [status, setStatus] = useState('info')
  const [tokens, setTokens] = useState()
  const [squareErrorMessage, setSquareErrorMessage] = useState('')

  const squareStyles = {
    '.input-container': {
      borderColor: '#ced4da',
      borderRadius: '8px',
    },
    '.input-container.is-focus': {
      borderColor: '#0089CD',
      borderWidth: '1px',
    },
    '.input-container.is-error': {
      borderColor: '#ff1600',
    },
    '.message-icon': {
      color: 'rgba(0, 0, 0, 0.0)',
    },
    '.message-text': {
      color: 'rgba(0, 0, 0, 0.0)',
    },
    '.message-icon.is-error': {
      color: 'rgba(0, 0, 0, 0.0)',
    },
    '.message-text.is-error': {
      color: 'rgba(0, 0, 0, 0.0)',
    },
    input: {
      backgroundColor: '#fcfcfb',
    },
    '@media screen and (max-width: 600px)': {
      input: {
        fontSize: '16px',
      },
    },
  }

  const verifyDetailsAndMakePayment = async customerPaymentData => {
    setTimeout(() => {
      setStatus('processing')
    }, 0)

    setTimeout(() => {
      const verifyBuyer = async (customerPaymentData, token) => {
        const sendToBackend = (tokens, customerPaymentData) => {
          setTimeout(() => {
            const { amount: squareVerificationAmount, ...customerPaymentDataWithoutAmount } = customerPaymentData
            axios({
              method: 'post',
              url:
                process.env.REACT_APP_ENV === 'production'
                  ? 'https://' + process.env.REACT_APP_API_HOST + endPoint
                  : process.env.REACT_APP_API_HOST + endPoint,
              data: {
                ...customerPaymentDataWithoutAmount,
                ...tokens,
              },
            })
              .then(res => {
                setStatus('processed')
                setTimeout(() => {
                  setStatus('success')
                }, 1000)
              })
              .catch(res => {
                setStatus('error')
              })
          }, 500)
        }

        squarePaymentModule
          .verifyBuyer(token, customerPaymentData)
          .then(res => {
            sendToBackend({ token: res.token, nonce: token }, { ...customerPaymentData })
          })
          .catch(() => {
            setSquareErrorMessage('Failed to verify card. Try entering the card number again, or try a different card.')
          })
      }
      squareCard.tokenize().then(res => verifyBuyer({ ...customerPaymentData }, res.token))
    }, 1000)
  }

  const verifyPaymentDetails = async verificationDetails => {
    setTimeout(() => {
      setStatus('verifying')
    }, 0)

    return new Promise((resolve, reject) => {
      const verifyBuyer = async (verificationDetails, token) => {
        squarePaymentModule.verifyBuyer(token, verificationDetails).then(res => {
          if (!res.token) {
            setStatus('error')
            reject('error')
          } else {
            // console.log('got the tokens! here they are', { token: res.token, nonce: token })
            setTokens({ token: res.token, nonce: token })
            setTimeout(() => {
              resolve({ token: res.token, nonce: token })
            }, 1500)
          }
        })
      }
      squareCard.tokenize().then(res => verifyBuyer(verificationDetails, res.token))
    })
  }

  const makePaymentWithDetails = customerPaymentData => {
    setStatus('processing')
    setTimeout(() => {
      axios({
        method: 'post',
        url:
          process.env.REACT_APP_ENV === 'production'
            ? 'https://' + process.env.REACT_APP_API_HOST + endPoint
            : process.env.REACT_APP_API_HOST + endPoint,
        data: {
          ...customerPaymentData,
          ...tokens,
        },
      })
        .then(res => {
          setStatus('processed')
          setTimeout(() => {
            setStatus('success')
          }, 1000)
        })
        .catch(res => {
          setStatus('error')
        })
    }, 1000)
  }

  useEffect(() => {
    if (status === 'info') {
      setTokens('')
    }
    if (status === 'verified') {
      setTimeout(() => {
        setStatus('ready')
      }, 500)
    }
  }, [status])

  useEffect(() => {
    if (!tokens) {
      setSquareErrorMessage('')
    }
    if (tokens) {
      setStatus('verified')
    }
  }, [tokens])

  useEffect(() => {
    if (squareErrorMessage.length > 0) {
      setStatus('square-error')
    } else {
      setStatus('info')
    }
  }, [squareErrorMessage])

  useEffect(() => {
    if (!cardIsReady) {
      setTokens('')
    }
  }, [cardIsReady])

  const attachCard = async () => {
    const payments = window.Square.payments(
      state === 'CA' ? process.env.REACT_APP_SQUARE_APP_ID_CA_B2B : process.env.REACT_APP_SQUARE_APP_ID,
      state === 'CA' ? process.env.REACT_APP_SQUARE_LOCATION_ID_CA_B2B : process.env.REACT_APP_SQUARE_LOCATION_ID
    )
    setSquarePaymentModule(payments)
    const squareCard = await payments.card({ style: squareStyles })
    if (document.getElementById('square')) {
      document.getElementById('square').innerHTML = ''
    }
    await squareCard.attach('#square')
    squareCard.addEventListener('errorClassAdded', e => {
      const brand =
        e.detail.cardBrand === 'unknown'
          ? 'Card'
          : e.detail.cardBrand.substring(0, 1).toUpperCase() + e.detail.cardBrand.substring(1)
      const errorField = e.detail.field
        .split(/(?=[A-Z])/)
        .map(s => s.toLowerCase())
        .join(' ')
      setSquareErrorMessage(`${brand} must have a valid ${errorField}`)
    })
    squareCard.addEventListener('errorClassRemoved', () => {
      setSquareErrorMessage('')
    })
    squareCard.addEventListener('focusClassRemoved', event => {
      setStatus('info')
      if (event && event.detail && event.detail.postalCodeValue && event.detail.postalCodeValue.length > 4) {
        setCardIsReady(true)
      } else {
        setCardIsReady(false)
      }
    })
    squareCard.addEventListener('postalCodeChanged', event => {
      if (event && event.detail && event.detail.postalCodeValue && event.detail.postalCodeValue.length > 4) {
        setCardIsReady(true)
      } else {
        setCardIsReady(false)
      }
    })
    setSquareCard(squareCard)
  }

  const cleanUpSquareScripts = async () => {
    setCardIsReady(false)

    return new Promise((resolve, reject) => {
      if (squareCard) {
        setTimeout(async () => {
          await squareCard
            .destroy()
            .then(() => {
              window.Square = null
              document.querySelectorAll('iframe') && document.querySelectorAll('iframe').forEach(e => e.remove())
              document.getElementById('square-payments-analytics-link-script') &&
                document.getElementById('square-payments-analytics-link-script').remove()
            })
            .then(() => {
              setStatus('info')
              resolve()
            })
            .catch(() => {
              reject('error')
            })
        }, 0)
      }
    })
  }

  const attachSquareToDom = async () => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = process.env.REACT_APP_SQUARE_CDN_URL
    document.getElementsByTagName('head')[0].appendChild(script)
    script.addEventListener('load', async () => {
      attachCard()
    })
  }

  return {
    verifyPaymentDetails,
    makePaymentWithDetails,
    verifyDetailsAndMakePayment,
    status,
    cardIsReady,
    attachSquareToDom,
    cleanUpSquareScripts,
    squareErrorMessage,
  }
}
