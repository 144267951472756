import { ChevronRight, DeleteOutline, EditOutlined } from '@mui/icons-material'
import { Box, ListItem, ListItemButton } from '@mui/material'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { useContext } from 'react'

export const PrefabMessage = ({ title, onClick, onEditClick, tooltip, refetch, msg }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const handleClickDelete = () =>
    window.confirm('are you sure') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_prefab_message`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        message_id: msg.id,
      }),
    }).then(res => (res.ok ? refetch() : alert('Error!')))

  return (
    <>
      <ListItem
        title={msg.systemAction === '' ? tooltip : `${msg.systemAction}: ${tooltip}`}
        onClick={e => onClick(e, msg)}
        disablePadding
        sx={{
          '& .MuiListItemButton-root': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          '& .delete': {
            transition: 'all .25s ease-in-out',
            maxWidth: '0px',
            overflow: 'hidden',
            fontSize: '16px',
          },
          '&:hover': {
            '& .delete': {
              maxWidth: '20px',
            },
            '& .edit': {
              mr: '.5rem',
              ml: '.5rem',
            },
          },
        }}
      >
        <ListItemButton
          sx={{
            '& span': {
              maxWidth: '100%',
              overflow: 'hidden',
              fontSize: '14px',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            },
          }}
        >
          {msg?.systemAction !== '' && (
            <Box sx={{ width: '5px', mr: '.5rem', height: '5px', borderRadius: '15px', background: '#909991' }} />
          )}
          <span>{title}</span>
          <ChevronRight sx={{ ml: 'auto' }} />{' '}
          {user?.roles?.includes('admin') && (
            <EditOutlined
              onClick={e => {
                e.stopPropagation()
                onEditClick && onEditClick(e, msg)
              }}
              className='delete edit'
            />
          )}
          {user?.roles?.includes('admin') && (
            <DeleteOutline
              onClick={e => {
                e.stopPropagation()
                handleClickDelete()
              }}
              className='delete'
            />
          )}
        </ListItemButton>
      </ListItem>
    </>
  )
}
