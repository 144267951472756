import {  Tabs, Tab, Box, Button, Typography, Grid } from '@mui/material'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { ChevronLeftRounded } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { Invoices } from './Components/Invoices'
import { Profile } from './Profile'
import { LineItemsTable } from './Components/LineItemsTable'
import { WorkOrders } from './Components/WorkOrders'
import { PriceCategoriesTable } from './Components/PriceCategoriesTable'
import { Schedule } from './Schedule'

const Edit = ({ dealer, refetch }) => {
  const history = useHistory()
  const [value, setValue] = useLocalStorage('dealerProfileTab', 'profile')
  const handleChangeTab = (_, newValue) => {
    setValue(newValue)
  }

  if (!dealer) return 'Error: invalid dealer id'

  const primaryContact = dealer.contacts.find(contact => contact.isPrimary)

  return (
    <Box sx={{ mt: '0rem' }}>
      <Box sx={{ background: 'aliceblue', padding: '1rem 0rem', borderBottom: '2px solid #a1cff7',  }}>
        <Box>
          <Button onClick={() => history.push('/b-to-b/dealers')} sx={{ mb: '1rem' }}>
            <ChevronLeftRounded /> All Dealers
          </Button>
        </Box>
        <Grid container sx={{ padding: '1rem' }}>
          <Grid item xs={6}>
            <Typography variant='h1' sx={{ fontSize: '24px', fontWeight: '500' }}>
              {dealer.businessName}
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '14px' }}>
              {dealer.addressLineOne} {dealer.city} {dealer.state} {dealer.zip}
            </Typography>
          </Grid>
          <Grid item xs={6} align='right'>
            <Typography variant='body1' sx={{ fontSize: '14px' }}>
              {primaryContact?.firstName} {primaryContact?.lastName}
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '14px' }}>
              {primaryContact?.phone}
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '14px' }}>
              {primaryContact?.email}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs fullWidth value={value} onChange={handleChangeTab}>
          <Tab value='profile' label='Profile' />
          <Tab value='scheduling' label='scheduling' />
          <Tab value='invoices' label='Invoices' />
          <Tab value='pricing-categories' label='Price Settings' />
          <Tab value='line-items' label='Line Items' />
          {/* <Tab value='workOrders' label='Part-Orders' /> */}
        </Tabs>
      </Box>

      {value === 'invoices' && <Invoices dealerId={dealer.id} />}
      {value === 'scheduling' && <Schedule refetch={refetch} dealer={dealer} />}
      {value === 'profile' && <Profile refetch={refetch} dealer={dealer} />}
      {value === 'line-items' && <LineItemsTable dealer={dealer} />}
      {value === 'pricing-categories' && <PriceCategoriesTable dealer={dealer} />}
      {value === 'workOrders' && <WorkOrders dealerId={dealer.id} />}
    </Box>
  )
}

export default Edit
