import { useContext, useState } from 'react'
import {
  MenuItem,
  Select,
  FormControl,
  Box,
  TextField,
  Button,
  InputLabel,
  Collapse,
  FormControlLabel,
  Switch,
} from '@mui/material'

import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { MultipleChoiceCreator } from './MultipleChoiceCreator'

export const NewQuestionRow = ({ refetch, formId }) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [isOptional, setIsOptional] = useState(false)
  const [type, setType] = useState('short-answer')
  const [options, setOptions] = useState([])

  const handleCreateQuestion = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_form_question`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        formId: formId,
        userId: user.id,
        title,
        description,
        questionType: type,
        options,
        isOptional,
      }),
    })
      .then(res => (res.ok ? refetch() : alert('error')))
      .then(() => {
        setTitle('')
        setDescription('')
        setOptions([])
      })
      .catch(err => alert(err))
  }

  return (
    <>
      <Box sx={{ display: 'flex', padding: '.5rem', background: 'aliceblue', border: '1px solid lightblue' }}>
        <Box>
          <Box sx={{ display: 'flex' }}>
            <TextField
              label='Title'
              value={title}
              onChange={e => setTitle(e.target.value)}
              placeholder='question title...'
              size='small'
            />
            <TextField
              label='Description'
              sx={{ margin: '0rem .5rem' }}
              value={description}
              onChange={e => setDescription(e.target.value)}
              placeholder='description'
              size='small'
            />
            <FormControl size='small'>
              <InputLabel>Question Type</InputLabel>
              <Select
                sx={{ minWidth: '150px' }}
                label='question type'
                size='small'
                value={type}
                onChange={e => setType(e.target.value)}
              >
                {[
                  'date',
                  'number',
                  'yes/no',
                  'rating',
                  'short-answer',
                  'long-answer',
                  'multiple-choice',
                  'multiple-select',
                ].map(question => (
                  <MenuItem key={question} value={question}>
                    {question?.replace('-', ' ')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              sx={{ ml: '.5rem' }}
              control={<Switch onClick={e => setIsOptional(!isOptional)} checked={isOptional} />}
              label='Optional'
            />

            <Button disabled={title === ''} onClick={handleCreateQuestion} sx={{ float: 'right' }}>
              new question
            </Button>
          </Box>
          <Collapse in={type === 'multiple-choice' || type === 'multiple-select'}>
            <Box sx={{ padding: '1rem .5rem' }}>
              <MultipleChoiceCreator options={options} setOptions={setOptions} />
            </Box>
          </Collapse>
        </Box>
      </Box>
    </>
  )
}
