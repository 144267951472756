import {
  Box,
  Typography,
  Button,
  Skeleton,
  Tabs,
  Tab,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogActions,
  DialogContent,
  Checkbox,
  CircularProgress,
} from '@mui/material'
import { useContext } from 'react'
import { Launch } from '@mui/icons-material'
import { useLazyQuery, useQuery, gql } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import DialogTitle from 'components/DialogTitle'
import {
  classes as DialogSubheaderClasses,
  DialogSubheader,
} from 'components/DialogSubheader'
import { UserContext } from '../../../../../UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const TIRE_LOOKUP_QUERY = gql`
  query tireLookupByPartNumber($partNumber: String) {
    tireLookupByPartNumber(partNumber: $partNumber)
  }
`

const PART_VENDORS_QUERY = gql`
  query partVendorsWithTire($partNumber: String) {
    partVendorsWithTire(partNumber: $partNumber)
  }
`

const PART_VENDOR_QUERY_FOR_SIZE = gql`
  query tiresThatMatchSize($partNumber: String) {
    tiresThatMatchSize(partNumber: $partNumber)
  }
`

export const TireLookupDialog = ({ part, setPartVendorId }) => {
  const [currentTab, setCurrentTab] = useState('locations')
  const [open, setOpen] = useState(false)
  const [selectedVendor, setSelectedVendor] = useState(null)
  const [selectedTireId, setSelectedTireId] = useState(null)
  const history = useHistory()
  const [getVendors, { loading, error, data }] =
    useLazyQuery(PART_VENDORS_QUERY)

  const { quoteId, leadId, partId } = useParams()
  const [user] = useContext(UserContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const [
    getVendorsForSize,
    { loading: loadingTires, error: errorTires, data: tiresData },
  ] = useLazyQuery(PART_VENDOR_QUERY_FOR_SIZE)

  const handleVendorCheckboxClick = (vendorId) =>
    selectedVendor === vendorId
      ? setSelectedVendor(null)
      : setSelectedVendor(vendorId)

  const handleTireCheckboxClick = (partNumber) =>
    selectedTireId === partNumber
      ? setSelectedTireId(null)
      : setSelectedTireId(partNumber)

  useEffect(() => {
    currentTab === 'locations' &&
      getVendors({
        variables: {
          partNumber: part.number,
        },
      })
    currentTab === 'size' &&
      getVendorsForSize({
        variables: {
          partNumber: part.number,
        },
      })
  }, [currentTab])

  useEffect(() => {
    data &&
      setSelectedVendor(
        data?.partVendorsWithTire.find(
          (vendor) => vendor.vendor.id === part.partVendorId
        )
      )
  }, [data, tiresData])

  if (loading) return <Skeleton />
  if (error) return 'error '

  const makeRowDark = (vendor) =>
    vendor === selectedVendor || vendor.id === part.partVendorId

  const handleDuplicateQuote = () => {
    if (
      window.confirm(
        `Are you sure you want to duplicate this? If there is more than one size of tire on the quote, doing this will only swap out the ${
          part.name
        } for the ${`${
          tiresData?.tiresThatMatchSize.find(
            (tire) => tire.tire.id === selectedTireId
          )?.tire?.brand
        } ${
          tiresData?.tiresThatMatchSize.find(
            (tire) => tire.tire.id === selectedTireId
          )?.tire?.model
        }`}. Any additional tires will be copied over.`
      )
    ) {
      setIsSubmitting(true)

      fetch(
        `${process.env.REACT_APP_COMMAND_ROOT}/commands/duplicate_quote_with_new_tires`,
        {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            user_id: user.id,
            quote_id: quoteId,
            replacement_tire_id: selectedTireId,
            replaced_tire_id: tiresData?.tiresThatMatchSize.find(
              (tire) => tire.tire.part_number === part.number
            )?.tire?.id,
          }),
        }
      )
        .then((res) => {
          if (res.status === 200) {
            res.json().then((res) => {
              setIsSubmitting(false)
              history.push(`/leads/${leadId}/quotes/${res.quote_id}`)
              setTimeout(() =>
                alert('You have arrived at the duplicate quote.')
              )
            })
          } else {
            setIsSubmitting(false)
            alert('An error occured')
          }
        })
        .catch(() => alert('An error occured'))
    }
  }

  return (
    <>
      <Dialog maxWidth='lg' onClose={() => setOpen(false)} open={open}>
        <DialogTitle onClose={() => setOpen(false)}>
          Tire Lookup &#8212; {part.name}
        </DialogTitle>
        <DialogSubheader>
          <Typography
            variant='body1'
            sx={{
              background: '#F5F5F5',
              borderBottom: '1px solid #e0e0e0',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Tabs value={currentTab} onChange={(_, e) => setCurrentTab(e)}>
              <Tab label='Locations' value='locations' />
              <Tab label='All tires by this size' value='size' />
            </Tabs>
          </Typography>
        </DialogSubheader>
        <DialogContent sx={{ padding: '0px' }}>
          <SelectedTire part={part} />

          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>TIRE</TableCell>
                <TableCell>VENDOR</TableCell>
                <TableCell>SEASON</TableCell>
                <TableCell>SIZE</TableCell>
                <TableCell>COST</TableCell>
                <TableCell>LINK</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentTab === 'locations' &&
                data?.partVendorsWithTire &&
                data?.partVendorsWithTire.length > 0 &&
                data?.partVendorsWithTire.map((pair, index) => (
                  <TableRow
                    id={pair.tire.id}
                    key={index}
                    onClick={() => handleVendorCheckboxClick(pair.vendor)}
                    sx={{
                      background: makeRowDark(pair.vendor)
                        ? '#E2F3F9 !important'
                        : index % 2 === 0
                        ? '#fff'
                        : '#f6f6f6',
                      cursor: 'pointer',
                      '&:hover': { background: 'aliceblue' },
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        onChange={() => handleVendorCheckboxClick(pair.vendor)}
                        checked={makeRowDark(pair.vendor)}
                      />
                    </TableCell>
                    <TableCell>
                      {pair?.tire?.brand} {pair?.tire?.model}
                    </TableCell>
                    <TableCell>{pair?.vendor?.name}</TableCell>
                    <TableCell>{pair?.tire?.season}</TableCell>
                    <TableCell>{pair?.tire?.size}</TableCell>

                    <TableCell sx={{ fontWeight: 600 }}>
                      ${pair?.tire?.single_price}
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => window.open(pair?.tire?.url)}>
                        <Launch />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              {loadingTires &&
                [12, 3, 4, 5, 6, 7, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3].map(
                  (thing, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        minHeight: '69px !important',
                        zIndex: 1,
                        background: index % 2 === 0 ? '#fff' : '#f6f6f6',
                        cursor: 'pointer',
                        '&:hover': { background: 'aliceblue' },
                      }}
                    >
                      <TableCell>
                        {' '}
                        <Box sx={{ minHeight: '39px' }} />{' '}
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  )
                )}
              {currentTab === 'size' &&
                tiresData?.tiresThatMatchSize?.map((pair, index) => (
                  <TableRow
                    id={pair.tire.id}
                    key={index}
                    onClick={() => handleTireCheckboxClick(pair.tire.id)}
                    sx={{
                      bottom: '-1px',
                      zIndex: 1,
                      background: index % 2 === 0 ? '#fff' : '#f6f6f6',
                      cursor: 'pointer',
                      '&:hover': { background: 'aliceblue' },
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        onChange={() => handleTireCheckboxClick(pair.tire.id)}
                        checked={selectedTireId === pair.tire.id}
                      />
                    </TableCell>
                    <TableCell>
                      {pair?.tire?.brand} {pair?.tire?.model}
                    </TableCell>
                    <TableCell>{pair?.vendor?.name}</TableCell>
                    <TableCell>{pair?.tire?.season}</TableCell>
                    <TableCell>{pair?.tire?.size}</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>
                      ${pair?.tire?.single_price}
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => window.open(pair?.tire?.url)}>
                        <Launch />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions
          sx={{
            padding: '1rem',
            background: '#F5F5F5',
            borderTop: '1px solid #e0e0e0',
          }}
        >
          {currentTab === 'locations' && (
            <Button
              disabled={selectedVendor ? false : true}
              onClick={() => {
                setPartVendorId(selectedVendor)
                setOpen(false)
              }}
              disableElevation
              variant='contained'
            >
              Select this vendor
            </Button>
          )}
          {currentTab === 'size' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <em>
                If the customer's chosen tire is not in stock, you <b>must</b>{' '}
                ask them if the tire you selected is ok.
              </em>
              <Button
                disabled={selectedTireId ? false : true}
                onClick={handleDuplicateQuote}
                disableElevation
                variant='contained'
              >
                Make new quote with this tire
              </Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>
      <Box
        onClick={() => setOpen(true)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Button variant='contained'>See vendors for {part.name}</Button>
      </Box>
      {isSubmitting && (
        <Box
          sx={{
            zIndex: 1908,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#11111180',
            backdropFilter: 'blur(2px)',
            position: 'fixed',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            color: '#fff',
          }}
        >
          <CircularProgress sx={{ mb: '1rem', color: '#fff' }} />
          Duplicating quote...
        </Box>
      )}
    </>
  )
}

const SelectedTire = ({ part }) => {
  const { loading, error, data } = useQuery(TIRE_LOOKUP_QUERY, {
    variables: { partNumber: part.number },
  })

  if (loading) return <Skeleton />
  if (error) return 'error'

  const tire = data?.tireLookupByPartNumber?.tire
  const vendor = data?.tireLookupByPartNumber?.vendor

  return (
    <Box
      sx={{
        background: '#75F9FD',
        borderRadius: '12px',
        padding: '.5rem 1rem',
        position: 'sticky',
        zIndex: 1,
        top: '1rem',
        border: '1px solid #efefef',
        boxShadow: '0px 0px 3px #EEF8FE',
        width: 'calc(100% - 2rem)',
        margin: '1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 600,
          fontSize: '12px',
        }}
      >
        CUSTOMER CHOICE
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          '& span': {
            fontWeight: 600,
            fontSize: '12px',
          },
        }}
      >
        <Box>
          <span>
            TIRE <br />
          </span>
          {tire.brand} | {tire.model}
        </Box>
        <Box>
          <span>
            VENDOR <br />
          </span>
          {vendor.name}
        </Box>
        <Box>
          <span>
            SEASON <br />
          </span>
          {tire.season}
        </Box>
        <Box>
          <span>
            SIZE <br />
          </span>
          {tire.size}
        </Box>
        <Box>
          <span>
            PRICE <br />
          </span>
          ${tire?.single_price}
        </Box>
        <Box>
          <Button onClick={() => window.open(tire?.url)}>
            <Launch />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
