import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Radio,
} from '@mui/material'
import { useContext, useState } from 'react'
import { DateTime } from 'luxon'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { CloseRounded, DeleteRounded, EditRounded, EventBusyRounded, EventRounded } from '@mui/icons-material'
import { Link } from 'react-router-dom'

export const SchedulerPopover = ({
  selectedScheduler,
  setJobSchedulerPopoverOpen,
  jobSchedulerPopoverOpen,
  jobInfoPopoverAnchorEl,
  setEditJobDrawerOpen,
  dealer,
  refetchAll,
}: any) => {
  const [confirmRecurrenceOptionPopoverOpen, setConfirmRecurrenceOptionPopoverOpen] = useState(false)
  const [selectedRecurrenceChange, setSelectedRecurrenceChange] = useState<null | string>(null)

  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const handleClickDeleteJob = () => {
    return new Promise((resolve, reject) => {
      if (selectedRecurrenceChange === 'This event') {
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_excluded_day_to_b_to_b_job_scheduler`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            user_id: user.id,
            b_to_b_job_scheduler_id: selectedScheduler.id,
            excluded_day: selectedScheduler.dateTime.toFormat('MM-dd-yy'),
          }),
        })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }
      if (selectedRecurrenceChange === 'This and all following events') {
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_job_scheduler_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            user_id: user.id,
            b_to_b_job_scheduler_id: selectedScheduler.id,
            updated_attributes: {
              recurrence_ends_at: selectedScheduler.dateTime.toISO(),
            },
          }),
        })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }
      if (selectedRecurrenceChange === 'All events') {
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_job_scheduler`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            b_to_b_job_scheduler_id: selectedScheduler.id,
          }),
        })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }
    })
      .then((res: any) => {
        if (res.ok) {
          refetchAll()
          setSelectedRecurrenceChange(null)
          setConfirmRecurrenceOptionPopoverOpen(false)
          setJobSchedulerPopoverOpen(false)
        } else {
          window.alert('Error')
        }
      })
      .catch(() => window.alert('let eric know about this'))
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          borderRadius: '6px',
          maxWidth: '400px',
        },
      }}
      anchorEl={jobInfoPopoverAnchorEl}
      open={jobSchedulerPopoverOpen}
      onClose={() => setJobSchedulerPopoverOpen(false)}
    >
      {selectedScheduler && (
        <Box
          sx={{
            padding: '.5rem 1rem',
            userSelect: 'none',
            mb: '.5rem',
            '& b': {
              fontSize: '22px',
              fontWeight: 500,
              '& svg': {
                marginRight: '.5rem',
              },
            },
            '& span': {
              fontSize: '13px',
              color: '#333',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              '& svg': { color: '#777' },
              pb: '.25rem',
              pt: '.25rem',
            }}
          >
            <Button
              onClick={() => {
                setJobSchedulerPopoverOpen(false)
                setEditJobDrawerOpen(true)
              }}
              sx={{ minWidth: '30px' }}
              size='small'
            >
              <EditRounded fontSize='small' />
            </Button>
            <Button
              onClick={() => setConfirmRecurrenceOptionPopoverOpen(true)}
              sx={{ minWidth: '30px', ml: '.5rem' }}
              size='small'
            >
              <DeleteRounded fontSize='small' />
            </Button>
            <Button
              onClick={() => setJobSchedulerPopoverOpen(false)}
              sx={{ minWidth: '30px', ml: '1rem' }}
              size='small'
            >
              <CloseRounded fontSize='small' />
            </Button>
          </Box>
          <List dense disablePadding>
            <ListItem alignItems='flex-start' disableGutters>
              <ListItemIcon sx={{ minWidth: '30px', '& svg': { mt: '.125rem', fontSize: '18px' } }}>
                <EventRounded />
              </ListItemIcon>
              <ListItemText
                primary={
                  <b>
                    <Link to={`/technicians/${selectedScheduler.technician.id}`}>
                      {selectedScheduler.technician.firstName} {selectedScheduler.technician.lastName}
                    </Link>{' '}
                    @ {dealer.businessName}
                  </b>
                }
                secondary={
                  <>
                    <span style={{ pointerEvents: 'none' }}>
                      {DateTime.fromISO(selectedScheduler.dateTime, { zone: 'utc' }).toFormat('cccc, LLLL dd')}
                      {'  '}
                      <span style={{ marginLeft: '.25rem', marginRight: '.25rem' }}>&#8226;</span>
                      {'  '}
                    </span>
                    <span style={{ pointerEvents: 'none' }}>
                      {DateTime.fromISO(selectedScheduler.startDatetime, { zone: 'utc' }).toFormat('t')} &#8212;{' '}
                    </span>
                    <span style={{ pointerEvents: 'none' }}>
                      {DateTime.fromISO(selectedScheduler.endDatetime, { zone: 'utc' }).toFormat('t')}
                    </span>
                  </>
                }
              />
            </ListItem>
          </List>

          {selectedScheduler.createdBy && (
            <span style={{ color: '#777' }}>
              <br />
              Created by {selectedScheduler.createdBy.firstName} {selectedScheduler.createdBy.lastName} on{' '}
              {DateTime.fromISO(selectedScheduler.createdAt).toFormat('DD t')}
            </span>
          )}

          {selectedScheduler.updatedBy && (
            <span style={{ color: '#777' }}>
              <br />
              Updated by {selectedScheduler.updatedBy.firstName} {selectedScheduler.updatedBy.lastName} on{' '}
              {DateTime.fromISO(selectedScheduler.updatedAt).toFormat('DD t')}
            </span>
          )}
        </Box>
      )}

      <Dialog
        PaperProps={{ sx: { maxWidth: '400px' } }}
        open={confirmRecurrenceOptionPopoverOpen}
        onClose={() => setConfirmRecurrenceOptionPopoverOpen(false)}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{ padding: '1rem', background: 'aliceblue', borderBottom: '2px solid #a1cff7', fontWeight: 600 }}
          >
            Delete recurring event
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', padding: '.5rem 1rem' }}>
            <FormControlLabel
              control={
                <Radio
                  onChange={() => setSelectedRecurrenceChange('This event')}
                  checked={selectedRecurrenceChange === 'This event'}
                />
              }
              label='This event'
            />
            <FormControlLabel
              control={
                <Radio
                  onChange={() => setSelectedRecurrenceChange('This and all following events')}
                  checked={selectedRecurrenceChange === 'This and all following events'}
                />
              }
              label='This and all following events'
            />
            <FormControlLabel
              control={
                <Radio
                  onChange={() => setSelectedRecurrenceChange('All events')}
                  checked={selectedRecurrenceChange === 'All events'}
                />
              }
              label='All events'
            />
          </Grid>
          <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex', padding: '.5rem' }}>
            <Button onClick={() => setConfirmRecurrenceOptionPopoverOpen(false)}>Cancel</Button>
            <Button disabled={!selectedRecurrenceChange} onClick={handleClickDeleteJob}>
              OK
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </Popover>
  )
}
