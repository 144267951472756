import { useEffect, useState } from 'react'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { Container } from '@mui/material'
import VerticalTabs from './VerticalTabs'
import LoadingButton from '@mui/lab/LoadingButton'

const StreamDump = ({ stream, open }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [dump, setDump] = useState(null)

  const fetchStream = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/events_dump`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ stream })
    })
      .then(res => res.json())
      .then(data => setDump(data))
  }

  useEffect(() => {
    open && fetchStream()
  }, [open])

  if (!dump) return <LoadingButton loading variant="outlined" sx={{ py: '3rem' }}>Loading</LoadingButton>

  return (
    <Container id="streamDumpContainer" sx={{ px: '3rem', py: '1rem' }}>
      <VerticalTabs data={ dump } />
    </Container>
  )
}

export default StreamDump
