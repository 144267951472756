import { createContext, useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const initialState = {
  steps: [],
}

const STEPS = gql`
  query StepItems {
    chatFlow {
      id
      stepNumber
      stepName
      reponseSuccess
      responseError
    }
  }
`

export const ChatFlowContext = createContext(initialState)

export const ChatFlowProvider = ({ children }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const { loading, error, data, refetch } = useQuery(STEPS)
  const [stepsData, setSteps] = useState(initialState)
  const [changeTracked, setChangeTracked] = useState(false)

  useEffect(() => {
    if (data && data.chatFlow) {
      setSteps({ steps: data.chatFlow })
    }
  }, [loading])

  const openItem = id => {
    let steps = stepsData.steps
    setSteps({ steps: updatedStatus(steps, id) })
  }

  const updatedStatus = (steps, id) => {
    return steps.map(step => {
      if (step.id === id) {
        return {
          ...step,
          open: true,
        }
      } else {
        return {
          ...step,
          open: false,
        }
      }
    })
  }

  const closeItem = id => {
    let steps = stepsData.steps
    const item = steps.find(step => step.id === id)
    if (!item) return
    const editedItem = { ...item, open: false }
    steps[steps.findIndex(el => el.id === id)] = editedItem
    setSteps({ steps })
  }

  const updateStepInfo = async ({ reponse_success, response_error, id }) => {
    let steps = stepsData.steps
    const item = steps.find(step => step.id === id)
    if (!item) return
    const editedItem = {
      ...item,
      reponseSuccess: reponse_success,
      responseError: response_error,
    }

    const response = await fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_chat_flow_step`, {
      method: 'PUT',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ id, reponse_success, response_error }),
    }).then(res => res.ok || window.alert('Error'))

    if (response) {
      steps[steps.findIndex(el => el.id === id)] = editedItem
      setSteps({ steps })
      await refetch()
    }
  }

  const addNewStep = async step => {
    const response = await fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_chat_flow_step`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify(step),
    }).then(res => res.ok || window.alert('Error'))

    if (response) {
      await refetch()
    }
  }

  const sortSteps = async steps => {
    setSteps({ steps })
    setChangeTracked(true)
  }

  const sendSortStep = async () => {
    const steps = stepsData.steps
    const sortedSteps = steps.map((step, index) => {
      return {
        ...step,
        stepNumber: index + 1,
      }
    })

    const result = await fetch(`${process.env.REACT_APP_COMMAND_ROOT}/sort_chat_flow_steps`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ steps: sortedSteps }),
    })
      .then(res => res.ok || window.alert('Error'))
      .catch(err => false)

    if (result) {
      setChangeTracked(false)
    }
  }

  return (
    <ChatFlowContext.Provider
      value={{
        stepsData,
        loading,
        error,
        updateStepInfo,
        addNewStep,
        sortSteps,
        openItem,
        closeItem,
        sendSortStep,
        changeTracked,
      }}
    >
      {children}
    </ChatFlowContext.Provider>
  )
}
