export const SuppliesOrderSummary = ({ items, order }) => {
  const summary = order.itemIds.reduce((acc, id) => {
    const type = items.find((item) => item.id === id).type
    return { ...acc, [type]: (acc[type] || 0) + 1 }
  }, {})

  return (
    <>
      {Object.keys(summary).map((type, i) => (
        <span key={type}>
          {i > 0 && <>, </>}
          <>
            {summary[type]} {type}
          </>
        </span>
      ))}
    </>
  )
}
