import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  TextField,
  CircularProgress,
  MenuItem,
  Dialog,
  FormControlLabel,
  Radio,
  Collapse,
  InputAdornment,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { DateTime, Interval } from 'luxon'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { useQuery, gql } from '@apollo/client'
import {
  ArrowLeftRounded,
  ArrowRightRounded,
  CheckCircle,
  Error,
  EventRepeatRounded,
  RefreshRounded,
  TodayRounded,
} from '@mui/icons-material'
import { useDebounce } from 'use-debounce/lib'
import { EditJobDrawer } from './Components/EditJobDrawer'
import { JobPopover } from './Components/JobPopover'
import { SchedulerPopover } from './Components/SchedulerPopover'
import { EditSchedulerDrawer } from './Components/EditSchedulerDrawer'

const JOBS_QUERY = gql`
  query BToBJobs($bToBDealerId: String, $startDatetime: String!, $endDatetime: String!) {
    bToBJobs(bToBDealerId: $bToBDealerId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      createdAt
      startDatetime
      endDatetime
      updatedAt
      schedulerId
      technician {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
`

const JOBS_SCHEDULERS_QUERY = gql`
  query BToBJobSchedulers($bToBDealerId: String, $recurrenceStartsAt: String!, $recurrenceEndsAt: String!) {
    bToBJobSchedulers(
      bToBDealerId: $bToBDealerId
      recurrenceStartsAt: $recurrenceStartsAt
      recurrenceEndsAt: $recurrenceEndsAt
    ) {
      id
      createdAt
      startDatetime
      endDatetime
      recurrencePeriod
      recurrencePattern
      recurrenceStartsAt
      recurrenceEndsAt
      recurrenceInterval
      excludedDays
      technician {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`

export const Schedule = ({ dealer }) => {
  const [newJobDialogOpen, setNewJobDialogOpen] = useState(false)
  const [jobInfoPopoverOpen, setJobInfoPopoverOpen] = useState(false)
  const [jobInfoPopoverAnchorEl, setJobInfoPopoverAnchorEl] = useState(null)
  const [selectedJob, setSelectedJob] = useState(null)
  const [selectedScheduler, setSelectedScheduler] = useState(null)
  const [jobSchedulerPopoverOpen, setJobSchedulerPopoverOpen] = useState(false)
  const [startOfWeek, setStartOfWeek] = useState(DateTime.fromJSDate(new Date(new Date())).startOf('week'))

  const [editJobDrawerOpen, setEditJobDrawerOpen] = useState(false)

  const [editSchedulerDrawerOpen, setEditSchedulerDrawerOpen] = useState(false)

  const { loading, error, data, refetch } = useQuery(JOBS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      bToBDealerId: dealer.id,
      startDatetime: startOfWeek.startOf('month').startOf('week').startOf('day'),
      endDatetime: startOfWeek.endOf('month').endOf('week').endOf('day'),
    },
  })

  const {
    loading: loadingSchedulers,
    error: errorSchedulers,
    data: schedulersData,
    refetch: refetchSchedulers,
  } = useQuery(JOBS_SCHEDULERS_QUERY, {
    variables: {
      bToBDealerId: dealer.id,
      recurrenceEndsAt: DateTime.now().endOf('day'),
      recurrenceStartsAt: startOfWeek.endOf('month').endOf('week').endOf('day'),
    },
  })

  useEffect(() => {
    refetch({
      variables: {
        bToBDealerId: dealer.id,
        startDatetime: startOfWeek.startOf('month').startOf('week').startOf('day'),
        endDatetime: startOfWeek.endOf('month').endOf('week').endOf('day'),
      },
    })

    refetchSchedulers({
      variables: {
        bToBDealerId: dealer.id,
        recurrenceEndsAt: DateTime.now().endOf('day'),
        recurrenceStartsAt: startOfWeek.endOf('month').endOf('week').endOf('day'),
      },
    })
  }, [startOfWeek])

  if (error || errorSchedulers) return <div>Error!</div>

  const jobs = data?.bToBJobs || []
  const schedulers = schedulersData?.bToBJobSchedulers || []

  const getSchedulersForDay = date => {
    const weeklySchedulersForDay = schedulers.filter(scheduler => {
      const diff = date.diff(DateTime.fromISO(scheduler.recurrenceStartsAt), ['weeks'])
      const { weeks } = diff.toObject()

      return (
        scheduler.recurrencePeriod === 'week' &&
        scheduler.recurrencePattern.includes(date.toFormat('cccc').toLowerCase()) &&
        DateTime.fromISO(scheduler.recurrenceStartsAt) <= date.endOf('day') &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !jobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(job.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        ) &&
        (scheduler.recurrenceInterval && Math.round(weeks) % scheduler.recurrenceInterval == 0 ? true : false)
      )
    })

    const monthlySchedulersForDay = schedulers.filter(scheduler => {
      const diff = date.diff(DateTime.fromISO(scheduler.recurrenceStartsAt), ['months'])
      const { months } = diff.toObject()

      return (
        scheduler.recurrencePeriod === 'month' &&
        scheduler.recurrencePattern.includes(date.toFormat('dd')) &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceStartsAt && DateTime.fromISO(scheduler.recurrenceStartsAt) >= date.endOf('month')
          ? false
          : true) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !jobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(job.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        ) &&
        (scheduler.recurrenceInterval && Math.round(months) % scheduler.recurrenceInterval == 0 ? true : false)
      )
    })

    const yearlySchedulersForDay = schedulers.filter(scheduler => {
      const diff = date.diff(DateTime.fromISO(scheduler.recurrenceStartsAt), ['years'])
      const { years } = diff.toObject()

      return (
        scheduler.recurrencePeriod === 'year' &&
        scheduler.recurrencePattern.includes(date.toFormat('MM-dd')) &&
        !scheduler.excludedDays.includes(date.toFormat('MM-dd-yy')) &&
        (scheduler.recurrenceStartsAt && DateTime.fromISO(scheduler.recurrenceStartsAt) >= date.endOf('month')
          ? false
          : true) &&
        (scheduler.recurrenceEndsAt && DateTime.fromISO(scheduler.recurrenceEndsAt) <= date ? false : true) &&
        !jobs.find(
          job =>
            job.schedulerId === scheduler.id &&
            DateTime.fromISO(job.startDatetime).toFormat('MM-dd-yy') === date.toFormat('MM-dd-yy')
        ) &&
        (scheduler.recurrenceInterval && Math.round(years) % scheduler.recurrenceInterval == 0 ? true : false)
      )
    })

    return weeklySchedulersForDay.concat(monthlySchedulersForDay).concat(yearlySchedulersForDay)
  }

  const wholeMonth = Interval.fromDateTimes(
    startOfWeek.startOf('month').startOf('week').minus({ days: 1 }),
    startOfWeek.endOf('month').endOf('week')
  )
    .splitBy({ day: 1 })
    .map(d => d.start)

  const populatedScheduleRows = [
    wholeMonth.slice(0, 7).map((num, index) => ({
      dateTime: num,
      jobs: jobs.filter(job => DateTime.fromISO(job.startDatetime).toFormat('DD') == num.toFormat('DD')),
      schedulers: getSchedulersForDay(num),
    })),
    wholeMonth.slice(7, 14).map((num, index) => ({
      dateTime: num,
      jobs: jobs.filter(job => DateTime.fromISO(job.startDatetime).toFormat('DD') == num.toFormat('DD')),
      schedulers: getSchedulersForDay(num),
    })),
    wholeMonth.slice(14, 21).map((num, index) => ({
      dateTime: num,
      jobs: jobs.filter(job => DateTime.fromISO(job.startDatetime).toFormat('DD') == num.toFormat('DD')),
      schedulers: getSchedulersForDay(num),
    })),
    wholeMonth.slice(21, 28).map((num, index) => ({
      dateTime: num,
      jobs: jobs.filter(job => DateTime.fromISO(job.startDatetime).toFormat('DD') == num.toFormat('DD')),
      schedulers: getSchedulersForDay(num),
    })),
    wholeMonth.slice(28, 35).map((num, index) => ({
      dateTime: num,
      jobs: jobs.filter(job => DateTime.fromISO(job.startDatetime).toFormat('DD') == num.toFormat('DD')),
      schedulers: getSchedulersForDay(num),
    })),
  ]

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '.5rem',
              background: 'aliceblue',
              borderBottom: '2px solid #a1cff7',
            }}
          >
            <Button
              size='small'
              onClick={() => {
                setStartOfWeek(DateTime.fromJSDate(new Date(new Date())).startOf('week'))
              }}
            >
              <TodayRounded />
            </Button>
            <Typography sx={{ padding: '.5rem', fontWeight: 600 }}>
              Schedule - {startOfWeek.toFormat('MMMM yyyy')}{' '}
            </Typography>
            <Button
              size='small'
              onClick={() => {
                refetch()
                refetchSchedulers()
              }}
            >
              <RefreshRounded />
            </Button>
            <Button
              size='small'
              onClick={() => {
                setStartOfWeek(startOfWeek.minus({ months: 1 }))
              }}
            >
              <ArrowLeftRounded />
            </Button>
            <Button
              sx={{ mr: 'auto' }}
              size='small'
              onClick={() => {
                setStartOfWeek(startOfWeek.plus({ months: 1 }))
              }}
            >
              <ArrowRightRounded />
            </Button>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button onClick={() => setNewJobDialogOpen(true)} disableElevation size='small' variant='contained'>
                Add new job
              </Button>
            </Box>
          </Box>

          <Dialog open={newJobDialogOpen} onClose={() => setNewJobDialogOpen(false)}>
            <CreateJob
              setModalIsOpen={setNewJobDialogOpen}
              dealer={dealer}
              refetchAll={() => {
                refetch()
                refetchSchedulers()
              }}
            />
          </Dialog>

          <EditJobDrawer
            editJobDrawerOpen={editJobDrawerOpen}
            setEditJobDrawerOpen={setEditJobDrawerOpen}
            selectedJob={selectedJob}
            dealer={dealer}
            refetchAll={() => {
              refetch()
              refetchSchedulers()
            }}
          />

          <EditSchedulerDrawer
            editJobDrawerOpen={editSchedulerDrawerOpen}
            setEditJobDrawerOpen={setEditSchedulerDrawerOpen}
            selectedScheduler={selectedScheduler}
            dealer={dealer}
            refetchAll={() => {
              refetch()
              refetchSchedulers()
            }}
          />

          <JobPopover
            selectedJob={selectedJob}
            setJobInfoPopoverOpen={setJobInfoPopoverOpen}
            jobInfoPopoverOpen={jobInfoPopoverOpen}
            jobInfoPopoverAnchorEl={jobInfoPopoverAnchorEl}
            setEditJobDrawerOpen={setEditJobDrawerOpen}
            dealer={dealer}
            refetchAll={() => {
              refetch()
              refetchSchedulers()
            }}
          />

          <SchedulerPopover
            setJobSchedulerPopoverOpen={setJobSchedulerPopoverOpen}
            selectedScheduler={selectedScheduler}
            jobSchedulerPopoverOpen={jobSchedulerPopoverOpen}
            jobInfoPopoverAnchorEl={jobInfoPopoverAnchorEl}
            setEditJobDrawerOpen={setEditSchedulerDrawerOpen}
            dealer={dealer}
            refetchAll={() => {
              refetch()
              refetchSchedulers()
            }}
          />

          <Grid container>
            {populatedScheduleRows.map((populatedSchedule, index) => (
              <Paper key={index} style={{ padding: 0, width: '100%' }}>
                <Grid container>
                  {populatedSchedule.map(dateAndJobs => (
                    <Grid item sx={12} sm={1.71428571429} key={dateAndJobs.dateTime}>
                      <Box
                        sx={[
                          {
                            background: '#fff',
                            width: '100%',
                          },
                          dateAndJobs.dateTime < DateTime.now().startOf('day') && {
                            opacity: 0.125,
                            borderColor: '#aaa !important',
                          },
                          {
                            width: '100%',
                            fontSize: '12px',
                            padding: '.25rem',
                            fontWeight: 600,
                            borderBottom: '1px solid #ddd',
                            color: '#444',
                            borderRight: '1px solid #ddd',
                          },
                          dateAndJobs.dateTime.toFormat('MM/dd') === DateTime.now().toFormat('MM/dd') && {
                            color: '#3852B0 ',
                            fontWeight: 600,
                            background: '#3852b01a',
                          },
                        ]}
                      >
                        {dateAndJobs.dateTime.toFormat('ccc, L/d')}
                      </Box>
                    </Grid>
                  ))}

                  {populatedSchedule.map(dateAndJobs => (
                    <Grid
                      item
                      sm={1.71428571429}
                      key={dateAndJobs.dateTime}
                      sx={[
                        dateAndJobs.dateTime < DateTime.now().startOf('day') && {
                          opacity: 0.45,
                          borderColor: '#ddd !important',
                        },
                        {
                          padding: '.5rem',
                          minHeight: '75px',
                          borderBottom: '1px solid #ddd',
                          borderRight: '1px solid #ddd',
                        },
                      ]}
                    >
                      {dateAndJobs.jobs.map(job => (
                        <Box
                          onClick={e => {
                            setJobInfoPopoverAnchorEl(e.target)
                            setJobInfoPopoverOpen(true)
                            setSelectedJob(job)
                          }}
                          sx={{
                            transition: 'all .125s ease-in-out',
                            boxShadow:
                              selectedJob === job && jobInfoPopoverOpen ? '0px 2px 8px rgba(0, 0, 0, 0.25)' : '',
                            padding: '.125rem .25rem',
                            background: 'aliceblue',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            userSelect: 'none',
                            mb: '.5rem',
                            '& b': {
                              fontSize: '14px',
                            },
                          }}
                        >
                          <b style={{ pointerEvents: 'none' }}>
                            {job.technician.firstName} {job.technician.lastName}
                          </b>
                          <br />
                          <span style={{ fontSize: '11px', pointerEvents: 'none' }}>
                            {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat('t')} &#8212;{' '}
                          </span>
                          <span style={{ fontSize: '11px', pointerEvents: 'none' }}>
                            {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toFormat('t')}
                          </span>
                        </Box>
                      ))}

                      {dateAndJobs.schedulers.map((scheduler, index) => (
                        <Box
                          onClick={e => {
                            setJobInfoPopoverAnchorEl(e.target)
                            setJobSchedulerPopoverOpen(true)
                            setSelectedScheduler({ ...scheduler, dateTime: dateAndJobs.dateTime })
                          }}
                          sx={{
                            transition: 'all .125s ease-in-out',
                            boxShadow:
                              selectedScheduler?.dateTime.toFormat('cccc MM dd') ===
                                dateAndJobs?.dateTime.toFormat('cccc MM dd') && jobSchedulerPopoverOpen
                                ? '0px 2px 8px rgba(0, 0, 0, 0.25)'
                                : '',
                            padding: '.125rem .25rem',
                            background: 'aliceblue',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            userSelect: 'none',
                            mb: '.5rem',
                            '& b': {
                              fontSize: '14px',
                            },
                            '& svg': {
                              fontSize: '14px',
                              mr: '.25rem',
                            },
                          }}
                        >
                          <EventRepeatRounded />
                          <b style={{ pointerEvents: 'none' }}>
                            {scheduler.technician.firstName} {scheduler.technician.lastName}
                          </b>

                          <br />
                          <span style={{ fontSize: '11px', pointerEvents: 'none' }}>
                            {DateTime.fromISO(scheduler.startDatetime, { zone: 'utc' }).toFormat('t')} &#8212;{' '}
                          </span>
                          <span style={{ fontSize: '11px', pointerEvents: 'none' }}>
                            {DateTime.fromISO(scheduler.endDatetime, { zone: 'utc' }).toFormat('t')}
                          </span>
                        </Box>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export const CreateJob = ({ dealer, setModalIsOpen, refetchAll }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [ignoreAvailability, setIgnoreAvailability] = useState(false)
  const [date, setDate] = useState(DateTime.now())

  const [startTime, setStartTime] = useState('08:00')
  const [endTime, setEndTime] = useState('16:00')

  const startDatetime = DateTime.fromFormat(`${date.toFormat('MM/dd/yyyy')}-${startTime}`, 'MM/dd/yyyy-hh:mm', {
    zone: 'utc',
  })
  const endDatetime = DateTime.fromFormat(`${date.toFormat('MM/dd/yyyy')}-${endTime}`, 'MM/dd/yyyy-hh:mm', {
    zone: 'utc',
  })

  const [jobDuration, setJobDuration] = useState(null)
  const [scheduleCheck, setScheduleCheck] = useState({ startDatetime, endDatetime })
  const [debouncedScheduleCheck] = useDebounce(scheduleCheck, 500, { leading: false })
  const [selectedTechnicianId, setSelectedTechnicianId] = useState()

  const [easyRecurrenceConfig, setEasyRecurrenceConfig] = useState('does not repeat')

  const [recurrencePattern, setRecurrencePattern] = useState([])
  const [recurrencePeriod, setRecurrencePeriod] = useState('week')
  const [recurrenceInterval, setRecurrenceInterval] = useState(1)
  const [selectedEndDate, setSelectedEndDate] = useState(DateTime.now().plus({ months: 3 }))
  const [selectedEndOccurenceNumber, setSelectedEndOccurenceNumber] = useState(13)
  const [selectedEndOption, setSelectedEndOption] = useState('never')

  const [customDialogOpen, setCustomDialogOpen] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleCloseModal = () => setModalIsOpen(false)
  const handleClickSave = () => {
    if (easyRecurrenceConfig === 'does not repeat') {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_job`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          start_datetime: startDatetime,
          end_datetime: endDatetime,
          ignore_availability: ignoreAvailability,
          technician_id: selectedTechnicianId,
          b_to_b_dealer_id: dealer.id,
        }),
      })
        .then(res => {
          if (res.ok) {
            setTimeout(() => {
              setModalIsOpen(false)
            }, 250)
            refetchAll()
          } else {
            window.alert('Error')
          }
        })
        .catch(() => window.alert('let eric know about this'))
    } else if (easyRecurrenceConfig === 'custom') {
      const requestAttributes = {
        user_id: user.id,
        start_datetime: startDatetime,
        end_datetime: endDatetime,
        ignore_availability: ignoreAvailability,
        technician_id: selectedTechnicianId,
        b_to_b_dealer_id: dealer.id,
        recurrence_pattern: recurrencePattern,
        recurrence_period: recurrencePeriod,
        recurrence_interval: recurrenceInterval,
      }

      if (selectedEndOption === 'never') {
      }

      if (selectedEndOption === 'date') {
        requestAttributes.recurrence_ends_at = selectedEndDate.toISO()
      }

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_job_scheduler`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify(requestAttributes),
      })
        .then(res => {
          if (res.ok) {
            setTimeout(() => {
              setModalIsOpen(false)
            }, 250)
            refetchAll()
          } else {
            window.alert('Error')
          }
        })
        .catch(() => window.alert('let eric know about this'))
    } else {
      const getValues = () => {
        switch (easyRecurrenceConfig) {
          case 'daily':
            return {
              pattern: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
              period: 'day',
            }
          case 'weekly':
            return {
              pattern: [date.toFormat('cccc').toLowerCase()],
              period: 'week',
            }
          case 'monthly':
            return {
              pattern: [date.toFormat('dd')],
              period: 'month',
            }
          case 'anually':
            return {
              pattern: [date.toFormat('MM-dd')],
              period: 'year',
            }
          case 'weekdays':
            return {
              pattern: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
              period: 'week',
            }
          default:
        }
      }

      const { pattern, period } = getValues()

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_job_scheduler`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          start_datetime: startDatetime,
          end_datetime: endDatetime,
          ignore_availability: ignoreAvailability,
          technician_id: selectedTechnicianId,
          b_to_b_dealer_id: dealer.id,
          recurrence_pattern: pattern,
          recurrence_period: period,
          recurrence_interval: 1,
        }),
      })
        .then(res => {
          if (res.ok) {
            setTimeout(() => {
              setModalIsOpen(false)
            }, 250)
            refetchAll()
          } else {
            window.alert('Error')
          }
        })
        .catch(() => window.alert('let eric know about this'))
    }
  }

  const handleChangedDate = event => {
    setDate(DateTime.fromISO(event.target.value))
  }

  const jobDurationIsValid = jobDuration && (jobDuration.hours > 0 || jobDuration.minutes > 0)

  useEffect(() => {
    setJobDuration(endDatetime.diff(startDatetime, ['hours', 'minutes']).toObject())
  }, [startTime, endTime])

  const allowedToSave = jobDurationIsValid && selectedTechnicianId
  const approvedTechsForQuote = dealer?.technicians || []

  const hoursForDay = dealer?.storeHours[date.toFormat('cccc').toLowerCase()]

  return (
    <Box sx={{ minWidth: '500px' }}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#11111140',
            pointerEvents: 'none',
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ padding: '1rem', background: 'aliceblue', borderBottom: '2px solid #a1cff7', fontWeight: 600 }}
        >
          Create new job
        </Grid>
      </Grid>
      <Box sx={{ padding: '1rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex' }}>
              <FormControl fullWidth size='small'>
                <InputLabel>Select Technician</InputLabel>
                <Select
                  value={selectedTechnicianId}
                  onChange={e => setSelectedTechnicianId(e.target.value)}
                  label='Select Technician'
                  size='small'
                  sx={{ minWidth: '200px' }}
                  placeholder='select technician'
                >
                  {approvedTechsForQuote.map(tech => (
                    <MenuItem value={tech.id}>
                      {tech.firstName} {tech.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label='date'
              type='date'
              fullWidth
              size='small'
              value={date.toFormat('kkkk-LL-dd')}
              onChange={handleChangedDate}
              style={{ maxWidth: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size='small'
              label='start time'
              type='time'
              id='new-job-start-time'
              value={startTime}
              onChange={e => setStartTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size='small'
              label='end time'
              type='time'
              id='new-job-end-time'
              onChange={e => setEndTime(e.target.value)}
              value={endTime}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size='small'>
              <Select
                value={easyRecurrenceConfig}
                onChange={e => setEasyRecurrenceConfig(e.target.value)}
                size='small'
                sx={{ minWidth: '200px' }}
                placeholder='select technician'
                variant='standard'
              >
                <MenuItem value='does not repeat'>Does not repeat</MenuItem>
                <MenuItem value='daily'>Daily</MenuItem>
                <MenuItem value='weekly'>Weekly on {date.toFormat('cccc')}</MenuItem>
                <MenuItem value='monthly'>Monthly on day {date.toFormat('dd')}</MenuItem>
                <MenuItem value='anually'>Annually on {date.toFormat('MMMM dd')}</MenuItem>
                <MenuItem value='weekdays'>Every weekday (Monday to Friday)</MenuItem>
                <MenuItem value='custom' onClick={() => setCustomDialogOpen(true)}>
                  Custom...
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {(hoursForDay?.length > 0 && (
            <Grid item xs={6}>
              Store hours for {date.toFormat('cccc')}: {hoursForDay.join('')}
            </Grid>
          )) || (
            <Grid item xs={6}>
              No store hours set for {date.toFormat('cccc')}
            </Grid>
          )}
        </Grid>
      </Box>
      <Dialog
        PaperProps={{ sx: { maxWidth: '400px' } }}
        open={customDialogOpen}
        onClose={() => setCustomDialogOpen(false)}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{ padding: '1rem', background: 'aliceblue', borderBottom: '2px solid #a1cff7', fontWeight: 600 }}
          >
            Custom recurrence
          </Grid>
          <Grid item xs={12} sx={{ padding: '1rem' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                '& b': { fontSize: '13px', mr: '.5rem', minWidth: '100px' },
              }}
            >
              <b>Repeat every</b>
              <TextField
                hiddenLabel
                variant='filled'
                size='small'
                type='number'
                value={recurrenceInterval}
                onChange={e => setRecurrenceInterval(e.target.value)}
                sx={{ width: '100px' }}
              />{' '}
              <FormControl fullWidth size='small' sx={{ ml: '.5rem' }}>
                <Select
                  hiddenLabel
                  variant='filled'
                  value={recurrencePeriod}
                  onChange={e => {
                    setRecurrencePeriod(e.target.value)
                    e.target.value === 'week' && setRecurrencePattern([])
                    e.target.value === 'month' && setRecurrencePattern([date.toFormat('dd')])
                    e.target.value === 'year' && setRecurrencePattern([date.toFormat('MM-dd')])
                  }}
                  size='small'
                  placeholder='select technician'
                >
                  <MenuItem value='day'>Days</MenuItem>
                  <MenuItem value='week'>Weeks</MenuItem>
                  <MenuItem value='month'>Months</MenuItem>
                  <MenuItem value='year'>Years</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Collapse in={recurrencePeriod === 'week'}>
              <Grid container sx={{ mt: '1rem', '& b': { fontSize: '13px' } }}>
                <Grid item xs={12}>
                  <b>Repeat on</b>{' '}
                </Grid>
                {['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map(i => (
                  <Grid
                    item
                    onClick={() =>
                      recurrencePattern.includes(i)
                        ? setRecurrencePattern(prev => prev.filter(day => day !== i))
                        : setRecurrencePattern(prev => [...prev, i])
                    }
                    sx={[
                      {
                        userSelect: 'none',
                        mt: '.5rem',
                        background: '#f1f1f1',
                        width: '24px',
                        height: '24px',
                        borderRadius: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mr: '.5rem',
                        cursor: 'pointer',
                        fontSize: '12px',
                      },
                      recurrencePattern.includes(i) && {
                        background: '#1a73e8',
                        color: '#fff',
                        fontWeight: 600,
                      },
                    ]}
                  >
                    {i[0]}
                  </Grid>
                ))}
              </Grid>
            </Collapse>

            <Grid container sx={{ mt: '1rem', '& b': { fontSize: '13px' } }}>
              <Grid item xs={12}>
                <b>Ends</b>{' '}
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  control={
                    <Radio checked={selectedEndOption === 'never'} onChange={e => setSelectedEndOption('never')} />
                  }
                  label='Never'
                />
                <Box sx={{ display: 'flex' }}>
                  <FormControlLabel
                    control={
                      <Radio checked={selectedEndOption === 'date'} onChange={e => setSelectedEndOption('date')} />
                    }
                    label='On'
                  />
                  <TextField
                    hiddenLabel
                    variant='filled'
                    disabled={selectedEndOption !== 'date'}
                    type='date'
                    fullWidth
                    size='small'
                    value={selectedEndDate.toFormat('kkkk-LL-dd')}
                    onChange={e => setSelectedEndDate(DateTime.fromISO(e.target.value))}
                    sx={{ maxWidth: '160px', ml: 'auto' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>

                {/* <Box sx={{ display: 'flex' }}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedEndOption === 'occurences'}
                        onChange={e => setSelectedEndOption('occurences')}
                      />
                    }
                    label='After'
                  />
                  <TextField
                    hiddenLabel
                    variant='filled'
                    disabled={selectedEndOption !== 'occurences'}
                    size='small'
                    type='number'
                    sx={{ maxWidth: '160px', ml: 'auto' }}
                    value={selectedEndOccurenceNumber}
                    onChange={e => setSelectedEndOccurenceNumber(e.target.value)}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>occurences</InputAdornment>,
                    }}
                  />
                </Box> */}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex', padding: '.5rem .5rem 0rem .5rem' }}>
              <Button onClick={() => setCustomDialogOpen(false)}>Cancel</Button>
              <Button onClick={() => setCustomDialogOpen(false)}>OK</Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      <Box sx={{ display: 'flex', padding: '1rem', borderTop: '2px solid #a1cff7' }}>
        <Button onClick={handleCloseModal}>cancel</Button>
        <Button disabled={!allowedToSave} onClick={handleClickSave}>
          Save
        </Button>
      </Box>
    </Box>
  )
}
