import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import {
  Button,
  SvgIcon,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ReorderIcon from '@mui/icons-material/Reorder'
import EditIcon from '@mui/icons-material/Edit'
import { CsvConfigurableListOptions } from 'components/CsvConfigurableListOptions'

const QUERY = gql`
  query vehicleYearMakePremiumsConnection($order: JSON, $filter: JSON, $first: Int, $after: String) {
    vehicleYearMakePremiumsConnection(order: $order, filter: $filter, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          year
          makeShortName
          premiumPercent
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const Index = () => {
  const [search, setSearch] = useState(JSON.parse(localStorage.getItem('vehicleYearMakePremiumsSearch')) || {})
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem('vehicleYearMakePremiumsOrder')) || {})
  const [filter, setFilter] = useState(JSON.parse(localStorage.getItem('vehicleYearMakePremiumsFilter')) || {})
  const [limit, setLimit] = useState(JSON.parse(localStorage.getItem('vehicleYearMakePremiumsLimit')) || 50)

  useEffect(() => localStorage.setItem('vehicleYearMakePremiumsSearch', JSON.stringify(search)), [search])
  useEffect(() => localStorage.setItem('vehicleYearMakePremiumsOrder', JSON.stringify(order)), [order])
  useEffect(() => localStorage.setItem('vehicleYearMakePremiumsFilter', JSON.stringify(filter)), [filter])
  useEffect(() => localStorage.setItem('vehicleYearMakePremiumsLimit', JSON.stringify(limit)), [limit])

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  const addFilter = newFilter => {
    setFilter({ ...filter, ...newFilter })
  }

  const removeFilter = filterName => {
    const { [filterName]: removed, ...remainingFilters } = filter
    setFilter(remainingFilters)
  }

  const setOrToggleOrder = name => {
    const { [name]: removed, ...remainingOrder } = order
    if (order[name] === 'asc') setOrder(remainingOrder)
    else setOrder({ [name]: order[name] ? 'asc' : 'desc', ...remainingOrder })
  }

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: limit,
        after: '',
      },
    })
  }, [search, order, filter, limit])

  const { loading, error, data, fetchMore } = useQuery(QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      search,
      order,
      filter,
      first: Number(limit),
      after: cursorCeiling,
    },
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const vehicleYearMakePremiums = data.vehicleYearMakePremiumsConnection.edges.map(edge => edge.node)
  const totalCount = data.vehicleYearMakePremiumsConnection.totalCount
  const endCursor = data.vehicleYearMakePremiumsConnection.pageInfo.endCursor

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: Number(limit),
          after: endCursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          fetchMoreResult.vehicleYearMakePremiumsConnection.edges = [
            ...prevResult.vehicleYearMakePremiumsConnection.edges,
            ...fetchMoreResult.vehicleYearMakePremiumsConnection.edges,
          ]

          return fetchMoreResult
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <TableContainer>
      <CsvConfigurableListOptions parentType='Vehicle' parentTitle='Year/Make Premium' />
      <TablePagination
        // rowsPerPageOptions={[10, 25, 50, 100]}
        rowsPerPageOptions={[50]}
        component='div'
        count={totalCount}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>
              <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={() => setOrToggleOrder('year')}>
                {!order.year && <SvgIcon fontSize='small' component={ReorderIcon} />}
                {order.year === 'asc' && <SvgIcon fontSize='small' component={ArrowUpwardIcon} />}
                {order.year === 'desc' && <SvgIcon fontSize='small' component={ArrowDownwardIcon} />}
              </Button>
              <>year </>
              {!!filter.year && (
                <Chip
                  variant='outlined'
                  onDelete={() => removeFilter('year')}
                  // icon={<TimeToLeaveRounded />}
                  size='small'
                  label={filter.year.eq}
                />
              )}
            </TableCell>
            <TableCell>
              <Button
                style={{ minWidth: 0 }}
                color='primary'
                disableElevation
                onClick={() => setOrToggleOrder('make_short_name')}
              >
                {!order.make_short_name && <SvgIcon fontSize='small' component={ReorderIcon} />}
                {order.make_short_name === 'asc' && <SvgIcon fontSize='small' component={ArrowUpwardIcon} />}
                {order.make_short_name === 'desc' && <SvgIcon fontSize='small' component={ArrowDownwardIcon} />}
              </Button>
              <>make_short </>
              {!!filter.make_short_name && (
                <Chip
                  variant='outlined'
                  onDelete={() => removeFilter('make_short_name')}
                  // icon={<TimeToLeaveRounded />}
                  size='small'
                  label={filter.make_short_name.eq}
                />
              )}
            </TableCell>
            <TableCell align='right'>
              <Button
                style={{ minWidth: 0 }}
                color='primary'
                disableElevation
                onClick={() => setOrToggleOrder('premium_percent')}
              >
                {!order.premium_percent && <SvgIcon fontSize='small' component={ReorderIcon} />}
                {order.premium_percent === 'asc' && <SvgIcon fontSize='small' component={ArrowUpwardIcon} />}
                {order.premium_percent === 'desc' && <SvgIcon fontSize='small' component={ArrowDownwardIcon} />}
              </Button>
              <>premium_percent </>
              {!!filter.premium_percent && (
                <Chip
                  variant='outlined'
                  onDelete={() => removeFilter('premium_percent')}
                  // icon={<TimeToLeaveRounded />}
                  size='small'
                  label={filter.premium_percent.eq}
                />
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vehicleYearMakePremiums.slice(page * limit, page * limit + limit).map((vehicleYearMakePremium, index) => (
            <TableRow sx={{ background: index % 2 === 0 ? '#fff' : '#f9f9f9' }} key={vehicleYearMakePremium.id}>
              <TableCell>
                <Chip
                  // icon={<FilterAltIcon />}
                  size='small'
                  label={vehicleYearMakePremium.year}
                  sx={{
                    marginRight: '.5rem',
                    border: '1px solid transparent',
                    '&:hover': { border: '1px solid #aaa' },
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    addFilter({ year: { eq: vehicleYearMakePremium.year } })
                  }}
                />
              </TableCell>
              <TableCell>
                <Chip
                  // icon={<FilterAltIcon />}
                  size='small'
                  label={vehicleYearMakePremium.makeShortName}
                  sx={{
                    marginRight: '.5rem',
                    border: '1px solid transparent',
                    '&:hover': { border: '1px solid #aaa' },
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    addFilter({ make_short_name: { eq: vehicleYearMakePremium.makeShortName } })
                  }}
                />
              </TableCell>
              <TableCell align='right'>
                <Chip
                  // icon={<FilterAltIcon />}
                  size='small'
                  label={vehicleYearMakePremium.premiumPercent}
                  sx={{
                    marginRight: '.5rem',
                    border: '1px solid transparent',
                    '&:hover': { border: '1px solid #aaa' },
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    addFilter({ premium_percent: { eq: vehicleYearMakePremium.premiumPercent } })
                  }}
                />
                <Link to={'/configurations/year-make-premiums/' + vehicleYearMakePremium.id} title='Edit'>
                  <EditIcon />
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Index
