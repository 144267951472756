import { useContext, useState } from 'react'
import { Grid, FormControl, Button, TextField } from '@mui/material'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

export const PayoutOverrideTotal = ({ invoice }) => {
  const [payoutOverrideTotal, setPayoutOverrideTotal] = useState(invoice.payoutOverrideTotal)
  const [waiting, setWaiting] = useState(false)
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const lockedToCsr = !!invoice.payoutId

  const handleChangePayoutOverride = e => setPayoutOverrideTotal(e.target.value)

  const handleClickSavePayoutOverride = () => {
    setWaiting(true)

    window
      .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBInvoiceId: invoice.id,
          updatedAttributes: { payout_override_total: Number(payoutOverrideTotal) },
          actorType: 'csr',
          actorId: user.id,
        }),
      })
      .then(res => res.ok || window.alert('Error'))
      .finally(() => setWaiting(false))
  }

  return (
    <Grid item xs={12}>
      <FormControl size='small'>
        <TextField
          value={payoutOverrideTotal}
          label='Payout override'
          disabled={!!lockedToCsr}
          onChange={handleChangePayoutOverride}
          size='small'
        />
      </FormControl>
      <Button
        disabled={
          waiting || (Number(payoutOverrideTotal) === Number(invoice.payoutOverrideTotal) && payoutOverrideTotal !== '0')
        }
        onClick={handleClickSavePayoutOverride}
      >
        Save
      </Button>
    </Grid>
  )
}
