import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'

const PART_QUERY = gql`
  query part($id: ID!) {
    part(id: $id) {
      quoteId
      bToBWorkOrderId
      quote {
        id
        leadId
      }
    }
  }
`

const Router = () => {
  const { partId } = useParams()

  const { loading, error, data } = useQuery(PART_QUERY, { variables: { id: partId } })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const part = data?.part
  const hasQuote = part?.quoteId

  console.log({ part })

  return hasQuote ? (
    <Redirect to={`/leads/${data.part.quote.leadId}/quotes/${data.part.quote.id}/parts/${partId}`} />
  ) : (
    <Redirect to={`/b-to-b/work-orders/${part.bToBWorkOrderId}/parts/${partId}`} />
  )
}

export default Router
