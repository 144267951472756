import React, { useState, useContext, useEffect } from 'react'
import { UserContext } from 'UserStore'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import DialogTitle from 'components/DialogTitle'

import TextField from '@mui/material/TextField'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { Dollars, PriceInput } from 'tools'

const DialogContent = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '100%',
      width: '100%'
    },
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  }
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogActions)

const RefundDialog = ({ parts, isOpen, onClose }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)
  const [editableParts, setEditableParts] = useState(parts)

  const validationSchema = Yup.array()
    .of(
      Yup.object().shape({
        partId: Yup.string().required('PartId is required.'),
        amount: Yup.number()
          .required('Amount is required.')
          .test({
            name: 'max',
            message: 'Amount cannot be greater than cost',
            test: (value, context) => context.parent.cost ? value <= context.parent.cost : true
          })
      })
    )

  const onSubmit = data => {
    setWaiting(true)

    const promises = data.map(part => window.fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_refunded`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        part_id: part.partId,
        refunded: true,
        refund_amount: part.amount
      })
    }).then(res => res.ok || window.alert('Error')))

    Promise.all(promises)
      .finally(() => {
        setWaiting(false)
        onClose()
      })
  }

  const initialFormValues = (parts) => parts.reduce((acc, part) => {
    acc.push({
      partId: part.id,
      cost: part.cost,
      amount: undefined
    })
    return acc
  }, [])

  const formik = useFormik({
    initialValues: initialFormValues(editableParts),
    validationSchema: validationSchema,
    onSubmit: onSubmit
  })

  const handleClose = (e, reason) => {
    e.stopPropagation()
    if (reason === 'backdropClick') return

    onClose()
  }

  useEffect(() => {
    setEditableParts(parts)

    formik.setValues(parts.reduce((acc, part) => {
      acc.push({
        partId: part.id,
        cost: part.cost,
        amount: (formik.values.find(o => o.partId === part.id) || {}).amount
      })
      return acc
    }, []))
  }, [parts])

  useEffect(() => {
    if (!isOpen) {
      setWaiting(false)
    } else {
      formik.setValues(initialFormValues(editableParts))
    }
  }, [isOpen])

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='sm'
        onClose={handleClose}
        aria-labelledby='part-refund'
        open={isOpen}
      >
        <DialogTitle id='part-refund' onClose={handleClose}>
          Refund Received
        </DialogTitle>
        <DialogContent sx={{ padding: '24px 8px' }} dividers>
          <Box flexDirection='column'>
            <TableContainer>
              <Table sx={{ maxWidth: 700 }} size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Part</TableCell>
                    <TableCell align='right'>Price</TableCell>
                    <TableCell align='right'>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editableParts.map((part, index) =>
                    <TableRow key={part.id}>
                      <TableCell>{part.name}</TableCell>
                      <TableCell><Dollars value={part.cost} /></TableCell>
                      <TableCell align='right'>
                        <TextField
                          required
                          size='small'
                          style={{ maxWidth: '150px' }}
                          error={!!(formik.dirty && formik.errors[index] && formik.errors[index].amount)}
                          helperText={formik.dirty && formik.errors[index] && formik.errors[index].amount}
                          value={formik.values[index].amount}
                          onChange={(event) => {
                            formik.setFieldValue(`${index}.amount`, Number(event.target.value))
                          }}
                          InputProps={{
                            inputComponent: PriceInput
                          }}
                        />
                      </TableCell>
                    </TableRow>)}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            disabled={waiting || !formik.isValid}
            onClick={formik.handleSubmit}
          >
            {!waiting ? 'Submit' : 'Saving'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RefundDialog
