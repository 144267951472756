import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import Show from './Show'
import { FC } from 'react'
import { SuppliesOrderRouterType } from './types'

const QUERY = gql`
  query suppliesOrder($id: String!) {
    suppliesOrder(id: $id) {
      id
      itemIds
      createdAt
      updatedAt
      placedAt
      rejectedAt

      updatedBy {
        firstName
        lastName
      }
      placedBy {
        firstName
        lastName
      }
      rejectedBy {
        firstName
        lastName
      }
      technician {
        id
        state
        firstName
        lastName

        suppliesOrders {
          id
          createdAt
          itemIds
          placedAt
        }
      }
    }
  }
`

const Router: FC<SuppliesOrderRouterType> = ({ items }) => {
  const { path } = useRouteMatch()
  const { suppliesOrderId } = useParams<{ suppliesOrderId: string }>()
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { id: suppliesOrderId },
  })

  if (loading) return <> Loading... </>
  if (error) return <>{JSON.stringify(error)}</>
  const order = data.suppliesOrder

  return (
    <Switch>
      <Route path={path}>
        <Show order={order} refetch={refetch} items={items} />
      </Route>
    </Switch>
  )
}

export default Router
