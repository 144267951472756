import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Job from './Job'
import Index from './Index'

const Router = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:jobId`}>
        <Job />
      </Route>
      <Route path={`${path}/`}>
        <Index />
      </Route>
    </Switch>
  )
}

export default Router
