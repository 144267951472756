import { useEffect } from 'react'
import { Grid } from '@mui/material'
import InlineOmegaEdiPartEditorGrid from './InlineOmegaEdiPartEditorGrid'

const HeaderGrid = () => {
  return (
    <Grid container sx={{ border: '1px solid rgba(224, 224, 224, 1)', borderWidth: '1px 0', px: 1 }}>
      <Grid item xs={5}>
        <b>NAGS Glass ID</b>
      </Grid>
      <Grid item xs={3} align='right'>
        <b>Cost</b>
      </Grid>
      <Grid item xs={3} align='right'>
        <b>Price</b>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}

const OmegaEdiPartsTable = ({ parts, quote, editingDisabled, setEditingMutex }) => {
  return (
    <>
      {parts.length > 0 && <HeaderGrid />}
      {parts.map(part => (
        <InlineOmegaEdiPartEditorGrid
          key={part.id}
          quote={quote}
          part={part}
          editingDisabled={editingDisabled}
          setEditingMutex={setEditingMutex}
        />
      ))}
    </>
  )
}

export default OmegaEdiPartsTable