import { useState, useEffect, useContext } from 'react'
import TextField from '@mui/material/TextField'
import { useQuery, gql } from '@apollo/client'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { Dollars } from 'tools'
import { UserContext } from 'UserStore'
import { GlobalContext } from 'GlobalStore'
import Button from '@mui/material/Button'
import { RefetchRegistry } from 'RefetchRegistry'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, Dialog, FormControlLabel, Grid, Switch } from '@mui/material'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'

const QUERY = gql`
  query commissionChargeBacks($quoteId: ID!) {
    commissionChargeBacks(quoteId: $quoteId) {
      id
      userId
      createdAt
      amount
      payoutId
      note
      deletedAt
      technicianId
      quote {
        id
        assignedTechnicianId
      }
    }
  }
`

const CommissionChargeBacks = ({ quoteId, quote }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const [commissionChargeBackNote, setCommissionChargeBackNote] = useState('')
  const [commissionChargeBackAmount, setCommissionChargeBackAmount] = useState('')
  const [includeTip, setIncludeTip] = useState(false)
  const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  const allowCreate = commissionChargeBackNote.length > 2

  const { loading, error, data, refetch } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: { quoteId },
  })

  useEffect(() => {
    const key = registerRefetch({ types: ['CommissionChargeBack'], refetch })
    return () => unregisterRefetch(key)
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const technicianById = id => global.technicians.find(tech => tech.id === id)
  const handleChangeNote = e => setCommissionChargeBackNote(e.target.value)

  const handleClickCreateCommissionChargeback = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_commission_charge_back`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        quote_id: quoteId,
        include_tip: includeTip,
        note: commissionChargeBackNote,
        amount: commissionChargeBackAmount,
      }),
    })
      .then(response => {
        if (response.ok) {
          refetch()
          setDialogOpen(false)
        } else {
          throw Error('error')
        }
      })
      .then(data => {
        setCommissionChargeBackNote('')
      })
      .catch(err => {
        window.alert(err)
      })

  const deleteCommissionChargeBack = id =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_commission_charge_back`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        commission_charge_back_id: id,
      }),
    }).then(res => refetch() || alert('Error'))

  const activeCommissionChargeBacks = data.commissionChargeBacks.filter(comp => !comp.deletedAt) || []

  const shouldBeDisabled = activeCommissionChargeBacks.length > 0

  const lastPayout = quote.payouts.length > 0 && quote.payouts[quote.payouts.length - 1]
  const quotePayoutFromLastPayout = lastPayout.quotePayouts.find(quotePayout => quotePayout.quoteId === quote.id)
  const lastPayoutTechnician = lastPayout?.technician
  const payoutAmountForChargeBack = quotePayoutFromLastPayout?.payoutData?.totalPayout || 0
  const tipAmountForChargeBack = quotePayoutFromLastPayout?.payoutData?.tipAfterFee || 0

  return (
    <div style={{ display: 'inline-block', border: '1px solid gray', padding: '1em' }}>
      <b style={{ color: 'red' }}>💀 Charge Back 💀</b> <br />
      <b style={{ fontSize: '12px' }}>
        A total of <Dollars value={payoutAmountForChargeBack} /> will be deducted from {lastPayoutTechnician.firstName}{' '}
        {lastPayoutTechnician.lastName}. The tip amount is <Dollars value={tipAmountForChargeBack} />
      </b>
      <br />
      <FormControlLabel
        disabled={shouldBeDisabled}
        label='Include tip?'
        control={<Switch checked={includeTip} onChange={e => setIncludeTip(!includeTip)} />}
      />
      <TextField
        disabled={shouldBeDisabled}
        value={commissionChargeBackNote}
        label='Note'
        variant='outlined'
        multiline
        onChange={handleChangeNote}
      />
      <TextField
        disabled={shouldBeDisabled}
        value={commissionChargeBackAmount}
        label='Custom amount'
        variant='outlined'
        multiline
        onChange={e => setCommissionChargeBackAmount(e.target.value)}
      />
      <Button
        disabled={shouldBeDisabled || !allowCreate}
        size='small'
        variant='outlined'
        onClick={handleClickCreateCommissionChargeback}
      >
        Create
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead />
          <TableBody>
            {activeCommissionChargeBacks.map(comp => (
              <TableRow key={comp.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align='right'>
                  {technicianById(comp.technicianId).firstName} {technicianById(comp.technicianId).lastName}
                </TableCell>
                <TableCell align='right'>
                  <Dollars value={comp.amount} />
                </TableCell>
                <TableCell align='right'>"{comp.note}"</TableCell>
                <TableCell align='right'>
                  {comp.payoutId ? (
                    'linked to payout'
                  ) : (
                    <>
                      <b style={{ color: 'red' }}>not charged out</b>
                      <Button onClick={() => deleteCommissionChargeBack(comp.id)} size='small'>
                        delete
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default CommissionChargeBacks
