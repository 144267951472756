import { Box } from '@mui/material'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import CreateJobListing from './CreateJobListing'
import JobListingsTable from './JobListingsTable'

import JobListingView from './JobListingView'

const JobListings = () => {
  const { url } = useRouteMatch()

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <CreateJobListing />
      </Box>

      <Switch>
        <Route path={`${url}/:jobListingId`}>
          <JobListingView />
        </Route>

        <Route path={url}>
          <JobListingsTable />
        </Route>
      </Switch>
    </Box>
  )
}

export default JobListings
