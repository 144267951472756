import { useState } from 'react'
import { Grid } from '@mui/material'
import InlineTireconnectPartEditorGrid from './InlineTireconnectPartEditorGrid'
import InlineTireconnectPartSearchGrid from './InlineTireconnectPartSearchGrid'

const HeaderGrid = () => {
  return (
    <Grid container sx={{ border: '1px solid rgba(224, 224, 224, 1)', borderWidth: '1px 0', px: 1 }}>
      <Grid item xs={3} align='center'>
        <b>Keyword</b>
      </Grid>
      <Grid item xs={3} align='center'>
        <b>Number</b>
      </Grid>
      <Grid item xs={2} align='center'>
        <b>Cost</b>
      </Grid>
      <Grid item xs={1} align='center'>
        <b>Qty</b>
      </Grid>
      <Grid item xs={2} align='center'>
        <b>Total</b>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}

const PartsTireconnectTable = ({ parts, quote, editingDisabled, setEditingMutex }) => {
  const [openWidget, setOpenWidget] = useState(false)
  const partsReadyForPunchout = parts.reduce(
    (acc, item) =>
      acc.find(i => i?.tireConnectData?.id === item?.tireConnectData?.id)
        ? [
            ...acc.filter(i => i?.tireConnectData?.id !== item?.tireConnectData?.id),
            {
              ...item,
              quantity: parts.filter(i => i?.tireConnectData?.id === item?.tireConnectData?.id).length,
              allPartIds: parts.filter(i => i?.tireConnectData?.id === item?.tireConnectData?.id).map(i => i?.id),
            },
          ]
        : [
            ...acc,
            {
              ...item,
              quantity: parts.filter(i => i?.tireConnectData?.id === item?.tireConnectData?.id).length,
              allPartIds: parts.filter(i => i?.tireConnectData?.id === item?.tireConnectData?.id).map(i => i?.id),
            },
          ],
    []
  )
  let helpNeededPart = null

  return (
    <>
      {helpNeededPart && <InlineTireconnectPartSearchGrid quote={quote} part={helpNeededPart} />}
      {partsReadyForPunchout.length > 0 && <HeaderGrid />}
      {partsReadyForPunchout.map(part => (
        <InlineTireconnectPartEditorGrid
          key={part.id}
          quote={quote}
          part={part}
          editingDisabled={editingDisabled}
          setEditingMutex={setEditingMutex}
        />
      ))}
    </>
  )
}

export default PartsTireconnectTable
