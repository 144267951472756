import Reimbursements from './Payroll/Reimbursements'
import ManualCompensations from './Payroll/ManualCompensations'
import BToBPayouts from './Payroll/BToBPayouts'
import Loans from './Payroll/Loans'
import Grid from '@mui/material/Grid'
import PaidTimeOff from './Payroll/PaidTimeOff'
import UnpaidTimeOff from './Payroll/UnpaidTimeOff'
import SickTimeOff from './Payroll/SickTimeOff'

const Payroll = ({ technician }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xl={6} md={12}>
        <Reimbursements technician={technician} />
      </Grid>
      <Grid item xl={6} md={12}>
        <ManualCompensations technician={technician} />
        <br /> <br />
        <PaidTimeOff technician={technician} />
        <UnpaidTimeOff technician={technician} />
        <SickTimeOff technician={technician} />
      </Grid>
      <Grid item xl={6} md={12}>
        <BToBPayouts technician={technician} />
      </Grid>
      <Grid item xl={6} md={12}>
        <Loans technician={technician} />
      </Grid>
    </Grid>
  )
}

export default Payroll
