import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import CustomerRouter from './Customer/Router'
import Index from './Index'
import New from './New'

const Router = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <New />
      </Route>
      <Route path={`${path}/:customerId`}>
        <CustomerRouter />
      </Route>
      <Route path={`${path}`}>
        <Index />
      </Route>
    </Switch>
  )
}

export default Router
