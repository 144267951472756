import { useQuery } from '@apollo/client'
import { FormControl, InputLabel, MenuItem, Select, TableCell } from '@mui/material'
import { B2BTechs, removeFilter } from 'BToB/Invoices/tools'

export const SelectTechnicianHeadCell = ({ filter, setFilter }) => {
  const { loading, error, data: b2bTechsData, fetchMore, refetch } = useQuery(B2BTechs)

  const handleChange = evt => {
    if (evt.target.value === 'clear') {
      removeFilter({ name: 'technician_id', filter, setFilter })
    } else {
      setFilter({ ...filter, technician_id: { eq: evt.target.value } })
    }
  }

  const selectedTechnicianId = filter?.technician_id?.eq

  const technicians =
    b2bTechsData?.bToBTechnicians
      ?.slice()
      .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
      .filter(tech => tech.active) || []

  return (
    <TableCell align='right'>
      <FormControl fullWidth size='small'>
        <InputLabel>Technician</InputLabel>
        <Select color='primary' size='small' label='Technician' value={selectedTechnicianId} onChange={handleChange}>
          <MenuItem value='clear'>
            <i>clear</i>
          </MenuItem>
          {technicians.map(technician => (
            <MenuItem key={technician.id} value={technician.id}>
              <>
                {technician.firstName} {technician.lastName}
              </>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TableCell>
  )
}
