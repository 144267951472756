import { useState, useContext, useEffect } from 'react'
import { TextField, TableRow, TableCell, Button, Grid, FormControl, Select, MenuItem, InputLabel } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import ReplyIcon from '@mui/icons-material/Reply'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UserContext } from 'UserStore'
import { diff, PriceInput } from 'tools'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const InlinePartEditorGrid = ({
  refetch,
  workOrder,
  part,
  currentEditingPart,
  setCurrentEditingPart,
  editingDisabled,
}) => {
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)
  const [changed, setChanged] = useState(false)

  const bearerTokenHeaders = useBearerTokenHeaders()

  useEffect(() => {
    formik.resetForm({ values: part })
  }, [part])

  const unsetEditingPart = () => {
    if (currentEditingPart === formik.values.id) {
      setCurrentEditingPart('')
    }
  }
  const openPartDetails = id => window.open(`/b-to-b/work-orders/${workOrder.id}/parts/${id}`)

  const handleRemovePart = partId => {
    setWaiting(true)

    fetch(`${process.env.REACT_APP_API_HOST}/remove_part_from_b_to_b_work_order`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        bToBWorkOrderId: workOrder.id,
        partId: partId,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .then(res => refetch())
      .finally(() => unsetEditingPart())
  }

  const handleClickSave = part => {
    const payload = {
      ...diff(formik.initialValues, part),
      ...diff(part, formik.initialValues),
    }

    if (Object.keys(payload).length === 0) {
      return
    }

    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_attributes_for_b_to_b_part`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        partId: part.id,
        updatedAttributes: payload,
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .finally(() => {
        setWaiting(false)
      })
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('required'),
    number: Yup.string().required('required'),
    cost: Yup.number().nullable(),
    price: Yup.number(),
    isPaid: Yup.boolean().nullable(),
    prePaymentRequired: Yup.boolean(),
  })

  const formik = useFormik({
    initialValues: part,
    validationSchema: validationSchema,
    onSubmit: handleClickSave,
  })

  useEffect(() => {
    const payload = {
      ...diff(formik.values, formik.initialValues),
    }

    if (Object.keys(payload).length === 0) {
      setChanged(false)

      // This part was manually changed back to it's original value and is no longer
      // in an editing state so we need to notify the parent component
      unsetEditingPart()
      return
    }
    setChanged(true)
    setCurrentEditingPart(part.id)
  }, [formik.values])

  useEffect(() => {
    formik.resetForm({ values: part })
    setWaiting(false)
    setChanged(false)
  }, [part])

  useEffect(() => {
    if (changed && currentEditingPart !== part.id) {
      formik.resetForm({ values: part })
    }
  }, [currentEditingPart])

  useEffect(() => {
    if (editingDisabled) {
      formik.resetForm({ values: part })
    }
  }, [editingDisabled])

  const disabled = waiting || editingDisabled

  return (
    <>
      <TableRow sx={{ borderBottom: '2px dashed #444' }}>
        <TableCell>
          <Grid container spacing={2} sx={{ pt: '.5rem', pb: '.5rem' }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='part name'
                multiline
                size='small'
                name='name'
                onChange={formik.handleChange}
                type='text'
                value={formik.values.name}
                variant='standard'
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label='part number'
                fullWidth
                variant='standard'
                type='text'
                name='number'
                value={formik.values.number}
                error={formik.touched.number && Boolean(formik.errors.number)}
                onChange={formik.handleChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl required fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  label='type'
                  fullWidth
                  variant='standard'
                  size='small'
                  name='type'
                  value={formik.values.type}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  onChange={event => {
                    formik.handleChange(event)
                    if (event.target.value === 'oem') {
                      formik.setFieldValue('prePaymentRequired', true)
                    }
                  }}
                  disabled={disabled}
                >
                  <MenuItem value='oem'>OEM</MenuItem>
                  <MenuItem value='aftermarket'>Aftermarket</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                label='cost'
                fullWidth
                variant='standard'
                size='small'
                name='cost'
                value={formik.values.cost}
                error={formik.touched.cost && Boolean(formik.errors.cost)}
                onChange={event => {
                  const numberValue = Number(event.target.value)
                  formik.setFieldValue('cost', numberValue)
                }}
                InputProps={{
                  inputComponent: PriceInput,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                label='price'
                fullWidth
                variant='standard'
                size='small'
                name='price'
                value={formik.values.price}
                error={formik.touched.price && Boolean(formik.errors.price)}
                onChange={event => {
                  const numberValue = Number(event.target.value)
                  formik.setFieldValue('price]', numberValue)
                }}
                InputProps={{
                  inputComponent: PriceInput,
                }}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align='right'>
          <IconButton
            onClick={() => window.confirm('are you sure?') && handleRemovePart(part.id)}
            disabled={disabled}
            id='delete-part-button'
          >
            <DeleteIcon fontSize='small' />
          </IconButton>{' '}
          <br />
          <IconButton onClick={() => openPartDetails(part.id)} sx={{ p: 0.25 }} disabled={disabled}>
            <ReplyIcon title='open part details' style={{ transform: 'scaleX(-1)' }} />
          </IconButton>
        </TableCell>
      </TableRow>
      {changed && (
        <TableRow sx={{ background: '#f1f1f1' }}>
          <TableCell>
            <Button
              size='small'
              variant='outlined'
              onClick={() => {
                if (waiting) {
                  return
                }
                formik.resetForm({ values: part })
                setCurrentEditingPart('')
              }}
              disabled={disabled}
            >
              reset
            </Button>
          </TableCell>
          <TableCell align='right'>
            <Button
              sx={{ minWidth: '20px' }}
              size='small'
              variant='contained'
              onClick={formik.handleSubmit}
              disabled={disabled}
            >
              save
            </Button>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default InlinePartEditorGrid
