import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { Button } from '@mui/material'

export const DuplicateLead = ({ lead, refetch }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const history = useHistory()


  const handleDuplicateLead = () => {
    const number = window.prompt("Customer phone number")
    const validNumberRegex = new RegExp('^[0-9]{10}$')

    if(!validNumberRegex.test(number)) {
      !!number && alert('Please add a valid american phone\n(10 digit / only numbers)')
      return
    }

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/duplicate_lead`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        lead_id: lead.id,
        customer_phone_number: number,
      }),
    })
    .then(res => res.json())
    .then(data => {
      refetch()
      history.push(`/leads/${data?.lead?.id}`)
    })
  }

  return (
    <Button size='small' variant='outlined' onClick={ handleDuplicateLead }>
      {`Duplicate this lead`}
    </Button>
  )
}