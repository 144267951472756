import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { keysToCamel } from '../../../tools'
import ShowEdit from './ShowEdit'

const QUERY = gql`
  query bToBInvoiceStatement($id: ID!) {
    bToBInvoiceStatement(id: $id) {
      id
      createdAt
      statementNumber
      acceptedAt
      businessName
      grandTotal
      discountPercent
      discountPercentAmount
      photos
      completedAt
      deletedAt
      ccFee
      dealerId
      fullyPaidAt
      label
      periodStart
      periodEnd
      photos
      invoices {
        id
        createdAt
        grandTotal
        invoiceNumber
        make
        model
        vin
        year
        repairOrder
        dealer {
          businessName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          primaryContact {
            firstName
            lastName
            email
          }
        }
      }
      dealer {
        id
        businessName
        primaryContact {
          firstName
          lastName
          email
        }
      }
    }
  }
`

const Router = () => {
  const { path } = useRouteMatch()
  const { bToBInvoiceStatementId } = useParams()

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { id: bToBInvoiceStatementId },
  })

  if (loading) return 'Loading'
  if (error) return JSON.stringify(error)

  const statement = data?.bToBInvoiceStatement

  return (
    <Switch>
      <Route path={path}>
        <ShowEdit refetch={refetch} statement={keysToCamel(statement)} />
      </Route>
    </Switch>
  )
}

export default Router
