import { useContext, useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { UserContext } from 'UserStore'
import { Box, Button, Collapse, Dialog, Table, TableBody, TableHead, TextField, Tooltip } from '@mui/material'
import { DeleteRounded } from '@mui/icons-material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

export const PriceCategoryDialog = ({ lineItem, open, setOpen, refetch, dealer }: any) => {
  const bearerTokenHeaders = useBearerTokenHeaders()

  const handleClickDeletePriceCategory = () => {
    window.confirm(`are you sure you want to update delete this line item?`) &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_price_category`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          category_id: lineItem.id,
        }),
      }).then(res => {
        if (res) {
          setTimeout(() => refetch(), 250)
          setOpen(false)
        } else {
          console.log('ERROR!')
        }
      })
  }

  const pricePoints = lineItem?.pricePoints || []
  const customPricePoints = lineItem?.customPricePoints || []
  const customPricePointPossibilities = pricePoints.filter((pricePoint: any) => !pricePoint.customPricePoint)

  return (
    <>
      {open && (
        <Dialog
          onClose={() => setOpen(false)}
          open={open}
          PaperProps={{
            sx: { minWidth: '750px ' },
          }}
        >
          <Box
            sx={{
              padding: '.5rem 1rem',
              background: 'aliceblue',
              borderBottom: '2px solid #a1cff7',
              fontWeight: 500,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '& span': {
                fontSize: '11px',
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '18px' }}>{lineItem.name}</span>{' '}
              {/* <Button
                onClick={handleClickDeletePriceCategory}
                color='warning'
                size='small'
                sx={{ minWidth: '30px', minHeight: '30px', marginLeft: '.5rem' }}
              >
                <DeleteRounded fontSize='small' />
              </Button> */}
            </Box>
            <span style={{ color: '#777' }}>PRICE CATEGORY</span>
          </Box>
          <Box>
            {(customPricePointPossibilities.length > 0 || customPricePoints.length > 0) && (
              <>
                <Box
                  sx={{
                    padding: '.5rem 1rem',
                    borderBottom: '2px solid aliceblue',
                    fontWeight: 500,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '& span': {
                      fontSize: '11px',
                    },
                  }}
                >
                  <span style={{ color: '#777' }}>CUSTOM PRICING</span>
                </Box>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Panel Category</TableCell>
                      <TableCell>Dealer Price (Custom)</TableCell>
                      <TableCell>Comission Price (Custom)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customPricePointPossibilities.map((pricePoint: any) => (
                      <NewCustomPricePointRow
                        dealer={dealer}
                        key={pricePoint.id}
                        pricingCatogory={lineItem}
                        pricePoint={pricePoint}
                        refetch={refetch}
                      />
                    ))}

                    {customPricePoints.map((pricePoint: any) => (
                      <CustomPricePointRow
                        key={pricePoint.id}
                        pricingCatogory={lineItem}
                        pricePoint={pricePoint}
                        refetch={refetch}
                      />
                    ))}
                  </TableBody>
                </Table>
              </>
            )}

            <Box
              sx={{
                padding: '.5rem 1rem',
                borderBottom: '2px solid aliceblue',
                fontWeight: 500,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& span': {
                  fontSize: '11px',
                },
              }}
            >
              <span style={{ color: '#777' }}>DEFAULT PRICING</span>
            </Box>

            {pricePoints.length > 0 && (
              <Table size='small' sx={{ opacity: 0.5, background: 'aliceblue' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Panel Category</TableCell>
                    <TableCell>Dealer Price (Default)</TableCell>
                    <TableCell>Comission Price (Default)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pricePoints.map((pricePoint: any) => (
                    <PricePointRow key={pricePoint.id} pricePoint={pricePoint} refetch={refetch} />
                  ))}
                </TableBody>
              </Table>
            )}
          </Box>
        </Dialog>
      )}
    </>
  )
}

const CustomPricePointRow = ({ pricePoint, category, refetch }: any) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const categoryName = pricePoint.panelGroup.name
  const [price, setPrice] = useState(pricePoint.price)
  const [commissionAmount, setCommissionAmount] = useState(pricePoint.commissionAmount)

  const priceNeedsSaving = price !== pricePoint.price
  const comissionNeedsSaving = commissionAmount !== pricePoint.commissionAmount

  const handleSaveAttribute = (name: string, attribute: string) => {
    window.confirm(`are you sure you want to update the ${name} to ${attribute}?`) &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_custom_price_point_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          price_point_id: pricePoint.id,
          updated_attributes: {
            [name]: attribute,
            updated_by_user_id: user.id,
          },
        }),
      }).then(res => {
        if (res) {
          refetch()
        } else {
          console.log('ERROR!')
        }
      })
  }

  const handleClickDelete = () => {
    window.confirm(`are you sure you want to update delete this line item?`) &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_custom_price_point`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          price_point_id: pricePoint.id,
        }),
      }).then(res => {
        if (res) {
          setTimeout(() => refetch(), 250)
        } else {
          console.log('ERROR!')
        }
      })
  }

  return (
    <TableRow key={pricePoint.id}>
      <TableCell>{categoryName}</TableCell>
      <TableCell
        sx={{
          '& fieldset': {
            borderColor: priceNeedsSaving ? 'orange !important' : '',
            borderWidth: priceNeedsSaving ? '2px !important' : '',
          },
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <Tooltip open={priceNeedsSaving} title='press enter to save changes' arrow>
          <TextField
            fullWidth
            size='small'
            value={price}
            type='number'
            // @ts-ignore
            onKeyDown={e => e.code === 'Enter' && e.target.value !== '' && handleSaveAttribute('price', e.target.value)}
            onChange={e =>
              Number(e.target.value) > 999 || Number(e.target.value) < 0 || e.target.value === ''
                ? setPrice('')
                : setPrice(Number(e.target.value))
            }
          />
        </Tooltip>
      </TableCell>
      <TableCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& fieldset': {
            borderColor: comissionNeedsSaving ? 'orange !important' : '',
            borderWidth: comissionNeedsSaving ? '2px !important' : '',
          },
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <Tooltip open={comissionNeedsSaving} title='press enter to save changes' arrow>
          <TextField
            fullWidth
            type='number'
            size='small'
            value={commissionAmount}
            onKeyDown={e =>
              // @ts-ignore
              e.code === 'Enter' && e.target.value !== '' && handleSaveAttribute('commissionAmount', e.target.value)
            }
            onChange={e =>
              Number(e.target.value) > 999 || Number(e.target.value) < 0 || e.target.value === ''
                ? setCommissionAmount('')
                : setCommissionAmount(Number(e.target.value))
            }
          />
        </Tooltip>

        <Button onClick={() => handleClickDelete()} color='error' size='small' sx={{ ml: 'auto', minWidth: '40px' }}>
          <DeleteRounded fontSize='small' />
        </Button>
      </TableCell>
    </TableRow>
  )
}

const NewCustomPricePointRow = ({ pricePoint, refetch, dealer, pricingCatogory }: any) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const categoryName = pricePoint.panelGroup.name
  const [price, setPrice] = useState('')
  const [commissionAmount, setCommissionAmount] = useState('')

  const handleCreateCustomPricePoint = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_custom_price_point`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        price_point_id: pricePoint.id,
        pricing_category_id: pricingCatogory.id,
        dealer_id: dealer.id,
        price: price,
        commission_amount: commissionAmount || 0,
        user_id: user.id,
      }),
    }).then(res => {
      if (res) {
        refetch()
      } else {
        console.log('ERROR!')
      }
    })
  }

  return (
    <TableRow key={pricePoint.id}>
      <TableCell>{categoryName}</TableCell>
      <TableCell
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <TextField
          fullWidth
          size='small'
          value={price}
          type='number'
          // @ts-ignore
          onChange={e =>
            Number(e.target.value) > 999 || Number(e.target.value) < 0 ? setPrice('') : setPrice(e.target.value)
          }
        />
      </TableCell>
      <TableCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <TextField
          fullWidth
          size='small'
          value={commissionAmount}
          type='number'
          // @ts-ignore
          onChange={e =>
            Number(e.target.value) > 999 || Number(e.target.value) < 0
              ? setCommissionAmount('')
              : setCommissionAmount(e.target.value)
          }
        />
        <Collapse orientation='horizontal' in={price != ''} sx={{ padding: '0rem .25rem' }}>
          {price !== '' && (
            <Button onClick={handleCreateCustomPricePoint} variant='contained' size='small'>
              Create
            </Button>
          )}
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

const PricePointRow = ({ pricePoint, refetch }: any) => {
  const categoryName = pricePoint.panelGroup.name
  const [price, setPrice] = useState(pricePoint.price)
  const [commissionAmount, setCommissionAmount] = useState(pricePoint.commissionAmount)

  return (
    <TableRow key={pricePoint.id}>
      <TableCell>{categoryName}</TableCell>
      <TableCell
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <TextField fullWidth disabled size='small' value={price} type='number' />
      </TableCell>
      <TableCell
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <TextField fullWidth disabled type='number' size='small' value={commissionAmount} />
      </TableCell>
    </TableRow>
  )
}
