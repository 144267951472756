import { useHistory } from 'react-router-dom'
import { Box, Tabs, Tab } from '@mui/material'

import RecruiterAvailability from './RecruiterAvailability'
import RecruitingCalendar from './RecruiterCalendar/RecruiterCalendar'
import { useLocalStorage } from 'hooks/useLocalStorage'

const Recruiter = () => {
  const [currentTab, setCurrentTab] = useLocalStorage('recruitingTab', 'calendar')

  return (
    <Box>
      <Tabs value={currentTab} onChange={(event, value: string) => setCurrentTab(value)}>
        <Tab value='availability' label='Availability' />
        <Tab value='calendar' label='Calendar' />
      </Tabs>

      {currentTab === 'calendar' && <RecruitingCalendar />}
      {currentTab === 'availability' && <RecruiterAvailability />}
    </Box>
  )
}

export default Recruiter
