import { useContext, useState } from 'react';
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { Box, Button, Grid, Tab, Tabs} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ReactJson from 'react-json-view'

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const scrollTop = () => {
  const element = document.getElementById('streamDumpContainer')
  if (element) element.scrollIntoView({ behavior: 'smooth' })
}

const VerticalTabs = ({ data }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [value, setValue] = useState(0)
  const history = []

  // Reverting the array. Do not use reverse method
  data.forEach(element => history.unshift(element))

  const handleChange = (evt, newValue) => {
    setValue(newValue)
  }

  const handleRevert = (file_url) => {
    if(!file_url) return

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/upload_csv_configurable_list`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        migration_type: 'reversion',
        parent_type: data[0].data.parent_type,
        url_string: file_url,
      }),
    })
      .then(response => {
        if (response.ok) {
          alert("Reversion applied successfully")
          window.location.reload(false)
          return true
        } else {
          throw Error('error')
        }
      })
      .catch(e => {
        alert(e.message)
      })
  }

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '80vh' }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider', minWidth: 'fit-content'}}
      >
        {history.map((item, index, items) => {
          return (
            <Tab
              key={ index }
              label={ `${items.length - index} - ${item.timestamp.slice(0, 10)} - ${item.data.migration_type}` }
              sx={{ whiteSpace: 'nowrap', pr: '1rem' }}
              {...a11yProps(index)}
            />
          )
        })}
      </Tabs>
      {history.map((item, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            <Grid container>
              <Grid item xs={6} sx={{ pr: '1rem' }}>
                <Button
                  disabled={ !item.data.file_url }
                  href={ item.data.file_url }
                  variant="outlined"
                  sx={{ width: '100%' }}>
                    Download CSV
                  </Button>
              </Grid>
              <Grid item xs={6} sx={{ pl: '1rem' }}>
                <Button
                  disabled={ !item.data.file_url }
                  variant="outlined"
                  onClick={ () => handleRevert(item.data.file_url) }
                  sx={{ width: '100%' }}
                >
                  Revert
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ my: '1rem' }}>
                <ReactJson
                  src={item.data}
                  theme='bright:inverted'
                  iconStyle='circle'
                  collapseStringsAfterLength='10'
                  displayDataTypes={ false }
                  enableClipboard={ false }
                />
              </Grid>
              <Grid item xs={12}>
                <Button onClick={ scrollTop } endIcon={ <ArrowUpwardIcon /> } sx={{ width: '100%' }}>
                  Back to Top
                </Button>
              </Grid>
            </Grid>
          </TabPanel>
        )
      })}
    </Box>
  );
}

export default VerticalTabs