import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Snackbar, Container, Grid, Button, Stack, Typography, TextField } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { diff } from 'tools'

const yearMakePremiumSchema = Yup.object().shape({
  premium_percent: Yup.number().required().min(0, "Allowed minimum is 0").max(100, "Allowed maximum is 100"),
})

const Edit = ({ yearMakePremium, refetch }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const history = useHistory()

  const initialValues = {
    premium_percent: yearMakePremium?.premiumPercent || 0,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yearMakePremiumSchema,
    onSubmit: values => {
      const updatedAttributes = diff(values, initialValues)

      if (Object.keys(updatedAttributes).length === 0) return alert('No changes!')

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_year_make_premium`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          year_make_premium_id: yearMakePremium.id,
          premium_percent: formik.values.premium_percent,
        }),
      }).then(res => (res.ok ? setSnackbarOpen(true): window.alert('error')))
    },
  })

  const handleReset = () => formik.setValues(initialValues)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false)
  }

  if (!yearMakePremium) return 'Error: invalid yearMakePremium id'

  return (
    <Container sx={{ mt: '1rem' }}>
      <Stack
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
          '& .MuiFormControl-root': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={formik.handleSubmit}
      >
        <Typography variant='h6'>
          Edit Year Make Premium: {yearMakePremium.makeShortName} {yearMakePremium.year}
        </Typography>

        <Grid container>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              size='small'
              label='Percent Premium'
              name='premium_percent'
              type='number'
              value={formik.values.premium_percent}
              onChange={formik.handleChange}
              error={formik.touched.premium_percent && Boolean(formik.errors.premium_percent)}
              helperText={formik.touched.premium_percent && formik.errors.premium_percent}
            />
          </Grid>
        </Grid>
        <div>
          <Button
            variant='contained'
            color='primary'
            disabled={!formik.dirty || !formik.isValid}
            type='submit'
            onClick={formik.handleSubmit}
          >
            <SaveIcon />
            Save
          </Button>
          <Button onClick={() => history.goBack()}>Cancel</Button>
          <Button disabled={!formik.dirty} onClick={handleReset}>
            Reset
          </Button>
        </div>
      </Stack>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose} message='Changes saved.' />
    </Container>
  )
}

export default Edit
