import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import Index from './Index'
import MetricsChart from './Chart/Metrics'
import QuotesChart from './Chart/Quotes'
import TechnicianRouter from './Technicians/Router'

const QUERY = gql`
  query markets {
    markets {
      market
      state
      stateCode
    }
  }
`

// mapMarkets iterates markets, returns new [] with {label:string, value:string}
const mapMarkets = (markets) => {
  const foundStates = {}
  const items = [{ label: 'All', value: 'All' }]

  markets.sort((a, b) =>
    a.stateCode < b.stateCode ? -1 : a.stateCode > b.stateCode
  )

  markets.forEach(market => {
    if (!foundStates[market.state]) {
      foundStates[market.state] = true
      items.push({ label: market.state, value: market.stateCode })
    }
    items.push({ label: market.market, value: market.market })
  })

  return items.sort((a,b) => a.label > b.label ? 1 : -1)
}

const Router = () => {
  const { path } = useRouteMatch()
  const { data } = useQuery(QUERY)

  const markets = data ? mapMarkets([...data.markets]) : []
  if (!markets.length) return <div>Loading...</div>

  return (
    <Switch>
      <Route path={`${path}/metrics`}>
        <MetricsChart markets={markets} />
      </Route>

      <Route path={`${path}/quotes`}>
        <QuotesChart markets={markets} />
      </Route>

      {/* <Route path={`${path}/technicians/:technicianId`}>
        <TechnicianRouter />
      </Route> */}

      <Route path={`${path}/technicians`}>
        <TechnicianRouter markets={markets} />
      </Route>

      <Route path={`${path}`}>
        <Index />
      </Route>
    </Switch>
  )
}

export default Router
