import {
  Box,
  Button,
  Dialog,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { MentionsInput, Mention } from 'react-mentions'
import mentionsClassNames from '../../../SmartFollowups/mentions.module.css'
import { useState } from 'react'
import { capitalizeWords } from 'tools'

export const CreatePrefabMessage = ({
  id,
  contextType,
  submitText,
  titleText,
  open,
  handleClose,
  onSubmit,
  cached,
}) => {
  const smallScreen = useMediaQuery('(max-width: 560px)')
  const [editablePrefab, setEditablePrefab] = useState(
    cached
      ? cached
      : {
          system_action: '',
          message_content: '',
          label: '',
          target_type: contextType && capitalizeWords(contextType),
        }
  )
  const readyToCreate = editablePrefab.message_content !== '' && editablePrefab.label !== ''

  const handleClickCreate = () => {
    readyToCreate && onSubmit(editablePrefab)
  }

  const content = (
    <Box sx={{ padding: '1rem', minWidth: { xs: '100%', sm: '500px' } }}>
      <Typography sx={{ mb: '.5rem' }}>{titleText}</Typography>

      <Divider sx={{ mb: '1rem' }} />

      <TextField
        size='small'
        fullWidth
        placeholder='label'
        value={editablePrefab?.label}
        onChange={e => setEditablePrefab(prev => ({ ...prev, label: e.target.value }))}
        sx={{ mb: '.5rem' }}
      />

      <FormControl size='small' fullWidth>
        <InputLabel>Target Type</InputLabel>
        <Select
          fullWidth
          label='Target Type'
          size='small'
          placeholder='Target Type'
          value={editablePrefab?.target_type}
          onChange={e => setEditablePrefab(prev => ({ ...prev, target_type: e.target.value }))}
          sx={{ mb: '.5rem' }}
        >
          {['all', 'Customer', 'Lead', 'Quote', 'Job', 'Appointment'].map(i => (
            <MenuItem key={i} value={i}>
              {i} {i === 'all' ? '- global' : ''}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl size='small' fullWidth>
        <InputLabel>System Action</InputLabel>
        <Select
          fullWidth
          label='System action'
          size='small'
          placeholder='System action'
          onChange={e => setEditablePrefab(prev => ({ ...prev, system_action: e.target.value }))}
          value={editablePrefab?.system_action}
          sx={{ mb: '.5rem' }}
        >
          <MenuItem value=''>None</MenuItem>
          {[
            ['appointmentConfirmation', 'Appointment Confirmation'],
            ['oemPrePayment', 'OEM Prepayment'],
            ['initialQuote', 'Initial quote'],
            ['oemPrePayment', 'OEM Prepayment'],
            ['offerAppointment', 'Offer appointment time'],
            ['morePhotos', 'Request more photos'],
            ['requestAddress', 'Request address'],
            ['requestPanelSpecify', 'Request panel specification'],
            ['requestVin', 'Request VIN'],
            ['acknowledgeComplaint', 'Acknowledge complaint'],
          ].map(([k, v]) => (
            <MenuItem key={k} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <MentionsInput
        className='mentions-new'
        classNames={mentionsClassNames}
        value={editablePrefab?.message_content || ''}
        onChange={e =>
          setEditablePrefab(prev => ({
            ...prev,
            message_content: e.target.value,
          }))
        }
        placeholder='Add message body'
        style={{
          minHeight: '200px',
          input: {
            minHeight: '200px',
          },
        }}
      >
        <Mention
          displayTransform={(id, display) => `${display}`}
          data={[
            { id: '_', display: 'customer name' },
            { id: '_', display: 'csr name' },
            { id: '_', display: 'quote price' },
            { id: '_', display: 'discount amount' },
          ]}
          style={{ backgroundColor: 'rgb(215 255 170)' }}
        />
      </MentionsInput>

      <Button disabled={!readyToCreate} onClick={() => handleClickCreate()} sx={{ mt: '1rem', ml: 'auto' }}>
        {submitText}
      </Button>
    </Box>
  )

  return (
    <Box>
      {!smallScreen && (
        <Dialog PaperProps={{ id: id }} open={open} onClose={e => handleClose()}>
          {content}
        </Dialog>
      )}

      {smallScreen && (
        <Drawer PaperProps={{ id: id }} anchor='bottom' open={open} onClose={e => handleClose()}>
          {content}
        </Drawer>
      )}
    </Box>
  )
}
