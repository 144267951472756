import {
  Typography,
  Container,
  Grid,
  Collapse,
  Button,
  Chip,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Popover,
} from '@mui/material'
import {
  CloseRounded,
  WorkRounded,
  CheckCircleRounded,
  AddCircleRounded,
  KeyboardArrowDownRounded,
  MovingRounded,
} from '@mui/icons-material'
import { useQuery, gql, useLazyQuery } from '@apollo/client'
import { useEffect, useState, useContext } from 'react'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { DateTime } from 'luxon'
import { Link, useHistory, useParams } from 'react-router-dom'
import { UserContext } from 'UserStore'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import Notes from 'components/Notes'

const GET_LEAD_DATA = gql`
  query timeCard($id: ID!) {
    timeCard(id: $id) {
      id
      timeCardDate
      createdAt
      deletedAt
      acceptedAt
      workHours
      paidTravelHours
      unpaidTravelHours
      payoutData
      payoutId
      technician {
        id
        firstName
        lastName
      }
      timeLogs {
        id
        createdAt
        startedAt
        completedAt
        type
        isPaid
        jobId
        bToBJobId
        travelDistance
      }
    }
  }
`

const ShowEdit = ({ prefetched, refetch, close }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [newLogOpen, setNewLogOpen] = useState(false)
  const [newLogItem, setNewLogItem] = useState({
    type: '',
    startedAt: '',
    completedAt: '',
  })

  const history = useHistory()

  const { timeCardId } = useParams()

  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)

  useEffect(() => {
    const key = registerTaskTarget({
      targetType: 'TimeCard',
      targetId: prefetched?.id,
    })
    return () => unregisterTaskTarget(key)
  }, [])

  const [getData, { loading, error, data, refetch: refetchTimeCard }] = useLazyQuery(GET_LEAD_DATA, {
    variables: { id: timeCardId },
  })

  useEffect(() => {
    !prefetched && getData()
  }, [prefetched])

  if (loading) return <>loading...</>
  if (error) return <> {JSON.stringify(error)} </>

  const timeCard = prefetched ? prefetched : data?.timeCard || { technician: {} }

  const createTravelLogForTimeCard = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        time_card_id: timeCard.id,
        user_id: user.id,
        start_datetime: newLogItem.startedAt,
        end_datetime: newLogItem.completedAt,
        technician_id: timeCard.technician.id,
        is_paid: newLogItem.isPaid,
      }),
    }).then(res => {
      if (res.ok) {
        prefetched && refetch()
        !prefetched && refetchTimeCard()
      } else {
        window.alert('Error')
      }
    })
  }

  const handleToggleTimeCardAccepted = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/${timeCard.acceptedAt ? 'unaccept_time_card' : 'accept_time_card'}`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        time_card_id: timeCard.id,
      }),
    }).then(res => {
      if (res.ok) {
        prefetched && refetch()
        !prefetched && refetchTimeCard()
        prefetched && close()
      } else {
        window.alert('Error')
      }
    })

  const handleClickDeleteTimeCard = () =>
    window.confirm('are you sure you want to delete this time card? This cannot be undone.') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_time_card`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        time_card_id: timeCard.id,
      }),
    }).then(res => {
      if (res.ok) {
        prefetched && close()
        history.push('/timecards')
      } else {
        window.alert('Error')
      }
    })

  const sortedTimeLogs = [
    ...(timeCard?.timeLogs?.slice().filter(log => !log.startedAt) || []),
    ...(timeCard?.timeLogs
      ?.slice()
      .filter(log => log.startedAt)
      .sort((a, b) => (a.startedAt > b.startedAt ? 1 : -1)) || []),
  ]

  const disableEverything = !!timeCard.acceptedAt

  return (
    <Container disableGutters maxWidth='sm' sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '90vh' }}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '2px solid #ddd',
              padding: '1rem',
              minWidth: '600px',
            }}
          >
            <Typography sx={{ fontSize: '18px', fontWeight: 600, '& span': { fontSize: '13px', fontWeight: 400 } }}>
              {timeCard.technician.firstName} {timeCard.technician.lastName}
            </Typography>

            <Link to={`/timecards/${timeCard.id}`}>{DateTime.fromISO(timeCard.timeCardDate).toFormat('DDDD')}</Link>
          </Box>
          {timeCard.acceptedAt && (
            <Typography
              sx={{
                fontSize: '14px',
                padding: '.5rem 0rem',
                fontWeight: 600,
                background: 'lightgreen',
                textAlign: 'center',
              }}
            >
              Accepted at: {DateTime.fromISO(timeCard.acceptedAt).toFormat('DDDD t ZZZZ')}
            </Typography>
          )}
        </Grid>
        {timeCard && (
          <Grid item xs={12}>
            <JobsForDayComponent
              timeCard={timeCard}
              technicianId={timeCard?.technician?.id}
              timeLogs={timeCard?.timeLogs}
              refetch={prefetched ? refetch : refetchTimeCard}
            />
            <BToBJobsForDayComponent
              timeCard={timeCard}
              technicianId={timeCard?.technician?.id}
              timeLogs={timeCard?.timeLogs}
              refetch={prefetched ? refetch : refetchTimeCard}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Box>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>item</TableCell>
                  <TableCell align='center'>paid?</TableCell>
                  <TableCell align='center'>mileage</TableCell>
                  <TableCell align='center'>started at</TableCell>
                  <TableCell align='center'>completed at</TableCell>
                  <TableCell>
                    <Button
                      onClick={e => setNewLogOpen(e.target)}
                      disableElevation
                      disabled={disableEverything}
                      size='small'
                      variant='contained'
                      sx={{
                        minWidth: '30px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '.25rem',
                        '& svg': { fontSize: '20px', margin: '0rem .125rem' },
                      }}
                    >
                      <MovingRounded /> <AddCircleRounded />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedTimeLogs?.map(timeLog => (
                  <TimeCardLineItem
                    key={timeLog.id}
                    timeLog={timeLog}
                    refetch={prefetched ? refetch : refetchTimeCard}
                    timeCard={timeCard}
                  />
                ))}
              </TableBody>
            </Table>
            {timeCard?.timeLogs?.length === 0 && (
              <Box sx={{ width: '100%', padding: '1rem', textAlign: 'center' }}>
                No time logs added yet. Try clicking the "Add all" button above to add all work and travel logs for the
                day.
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          borderRadius: '0px 0px 20px 20px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem',
          background: '#f1f1f1',
        }}
      >
        <Button size='small' onClick={handleClickDeleteTimeCard} disableElevation variant='contained'>
          Delete timecard
        </Button>
        {/* <CreateATask targetType='TimeCard' targetId={timeCard.id} /> */}
        <FormControlLabel
          label={timeCard.acceptedAt ? 'Unaccept' : 'Accept'}
          size='small'
          onClick={handleToggleTimeCardAccepted}
          control={<Checkbox disabled={!!timeCard.payoutId} checked={!!timeCard.acceptedAt} />}
        />
      </Box>

      <Popover
        PaperProps={{ sx: { padding: '1rem' } }}
        anchorEl={newLogOpen}
        onClose={() => setNewLogOpen(null)}
        open={!!newLogOpen}
      >
        Started at <br />
        <input
          value={newLogItem.startedAt}
          onChange={e =>
            setNewLogItem(prev => ({
              ...prev,
              startedAt: e.target.value,
            }))
          }
          type='time'
        />
        <br />
        Completed at <br />
        <input
          value={newLogItem.completedAt}
          onChange={e =>
            setNewLogItem(prev => ({
              ...prev,
              completedAt: e.target.value,
            }))
          }
          type='time'
        />
        <br />
        <FormControlLabel
          label='is paid?'
          size='small'
          control={
            <Checkbox
              checked={newLogItem.isPaid}
              onChange={e =>
                setNewLogItem(prev => ({
                  ...prev,
                  isPaid: newLogItem.isPaid ? false : true,
                }))
              }
            />
          }
        />
        <br />
        <Button onClick={createTravelLogForTimeCard}>Add</Button>
      </Popover>

      <Box sx={{ padding: '1rem' }}>
        {timeCard && <Notes headerText='PAYROLL NOTES' objectType='TimeCardForPayroll' objectId={timeCard.id} />}
      </Box>

      {timeCard.payoutData && (
        <Box sx={{ padding: '1rem', fontSize: '13px' }}>
          <span>
            <b> payoutData:</b> <br />
            {timeCard.payoutData && JSON.stringify(timeCard.payoutData, null, 5)}
          </span>
        </Box>
      )}
    </Container>
  )
}

const TimeCardLineItem = ({ timeLog, refetch, timeCard }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const disableEverything = !!timeCard.acceptedAt

  return (
    <TableRow key={timeLog.id}>
      <TableCell align='center'>
        <Box
          sx={{
            color: '#666',
            '& svg': {
              fontSize: '24px',
            },
            display: 'flex',
            alignItems: 'center',
            '& span': {
              margin: '0px 3px',
            },
          }}
        >
          {timeLog.type === 'travel_log' && <MovingRounded />}
          {timeLog.type === 'work_log' && <WorkRounded />}
        </Box>
      </TableCell>
      <TableCell align='center'>
        <Checkbox
          disabled={timeLog.type === 'work_log' || disableEverything}
          checked={timeLog.isPaid}
          onChange={e => {
            fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_time_or_work_log_attributes`, {
              method: 'POST',
              headers: bearerTokenHeaders,
              body: JSON.stringify({
                user_id: user.id,
                updated_attributes: {
                  is_paid: timeLog.isPaid ? false : true,
                },
                type: timeLog.type,
                log_id: timeLog.id,
              }),
            }).then(res => {
              if (res.ok) {
                refetch()
              } else {
                window.alert('Error')
              }
            })
          }}
        />
      </TableCell>
      <TableCell align='center'>
        {timeLog.type !== 'work_log' && (
          <input
            disabled={disableEverything}
            type='number'
            style={{ maxWidth: '50px' }}
            defaultValue={timeLog.travelDistance}
            onChange={e => {
              fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_time_or_work_log_attributes`, {
                method: 'POST',
                headers: bearerTokenHeaders,
                body: JSON.stringify({
                  user_id: user.id,
                  updated_attributes: {
                    travel_distance: e.target.value,
                  },
                  type: timeLog.type,
                  log_id: timeLog.id,
                }),
              }).then(res => {
                if (res.ok) {
                  refetch()
                } else {
                  window.alert('Error')
                }
              })
            }}
          />
        )}
      </TableCell>
      <TableCell align='center'>
        <input
          disabled={disableEverything}
          defaultValue={timeLog?.startedAt?.slice(11, -6)}
          type='time'
          onChange={e =>
            fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_time_or_work_log_attributes`, {
              method: 'POST',
              headers: bearerTokenHeaders,
              body: JSON.stringify({
                user_id: user.id,
                updated_attributes: {
                  started_at: e.target.value,
                },
                type: timeLog.type,
                log_id: timeLog.id,
              }),
            }).then(res => {
              if (res.ok) {
              } else {
                window.alert('Error')
              }
            })
          }
        />
      </TableCell>
      <TableCell align='center'>
        <input
          disabled={disableEverything}
          defaultValue={timeLog?.completedAt?.slice(11, -6)}
          type='time'
          onChange={e =>
            fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_time_or_work_log_attributes`, {
              method: 'POST',
              headers: bearerTokenHeaders,
              body: JSON.stringify({
                user_id: user.id,
                updated_attributes: {
                  completed_at: e.target.value,
                },
                type: timeLog.type,
                log_id: timeLog.id,
              }),
            }).then(res => {
              if (res.ok) {
              } else {
                window.alert('Error')
              }
            })
          }
        />
      </TableCell>
      <TableCell align='right'>
        <Button
          disabled={disableEverything}
          onClick={() =>
            window.confirm('are you sure you want to delete this log?') &&
            fetch(
              `${process.env.REACT_APP_COMMAND_ROOT}/${
                timeLog.type === 'travel_log' ? 'delete_travel_log' : 'delete_work_log'
              }`,
              {
                method: 'POST',
                headers: bearerTokenHeaders,
                body: JSON.stringify({
                  user_id: user.id,
                  log_id: timeLog.id,
                }),
              }
            ).then(res => {
              if (res.ok) {
                refetch()
              } else {
                window.alert('Error')
              }
            })
          }
          sx={{ minWidth: '30px', color: 'red' }}
        >
          <CloseRounded />
        </Button>
      </TableCell>
    </TableRow>
  )
}

const GET_B2BJOBS = gql`
  query BToBJobs($technicianId: String, $startDatetime: String, $endDatetime: String) {
    bToBJobs(technicianId: $technicianId, startDatetime: $startDatetime, endDatetime: $endDatetime) {
      id
      technicianId
      startDatetime
      endDatetime
      canceledAt
      dealer {
        businessName
      }
      technician {
        id
        name
        offdays
        availability
      }
    }
  }
`

const BToBJobsForDayComponent = ({ technicianId, refetch, timeCard, timeLogs }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [showAllJobs, setShowAllJobs] = useState(false)

  const { loading, error, data } = useQuery(GET_B2BJOBS, {
    variables: {
      technicianId: technicianId,
      startDatetime: DateTime.fromISO(timeCard.timeCardDate).startOf('day').toUTC().toISO(),
      endDatetime: DateTime.fromISO(timeCard.timeCardDate).endOf('day').toUTC().toISO(),
    },
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const addWorkLogFromJob = job => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_work_log_to_time_card`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        b_to_b_job_id: job.id,
        time_card_id: timeCard.id,
        start_datetime: job.startDatetime,
        end_datetime: job.endDatetime,
        technician_id: technicianId,
      }),
    }).then(res => {
      if (res.ok) {
        refetch()
      } else {
        window.alert('Error')
      }
    })
  }
  const sortedJobs = data?.bToBJobs?.slice().sort((a, b) => (a.startDatetime > b.startDatetime ? 1 : -1))

  const addAllJobsFromDay = () => {
    sortedJobs.map(job =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_work_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          b_to_b_job_id: job.id,
          time_card_id: timeCard.id,
          start_datetime: job.startDatetime,
          end_datetime: job.endDatetime,
          technician_id: technicianId,
        }),
      })
    )

    if (sortedJobs.length === 1) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[0].startDatetime,
          start_datetime: sortedJobs[0].startDatetime,
          technician_id: technicianId,
          is_paid: false,
        }),
      }).then(res => {
        if (res.ok) {
          refetch()
        } else {
          window.alert('Error')
        }
      })

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          start_datetime: sortedJobs[0].endDatetime,
          end_datetime: sortedJobs[0].endDatetime,
          technician_id: technicianId,
          is_paid: false,
        }),
      }).then(res => {
        if (res.ok) {
          refetch()
        } else {
          window.alert('Error')
        }
      })
    }

    if (sortedJobs.length == 2) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[0].startDatetime,
          start_datetime: sortedJobs[0].startDatetime,
          technician_id: technicianId,
          is_paid: false,
        }),
      })

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[1].startDatetime,
          start_datetime: sortedJobs[0].endDatetime,
          technician_id: technicianId,
          is_paid: true,
        }),
      })

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[1].endDatetime,
          start_datetime: sortedJobs[1].endDatetime,
          technician_id: technicianId,
          is_paid: false,
        }),
      }).then(res => {
        if (res.ok) {
          refetch()
        } else {
          window.alert('Error')
        }
      })
    }

    if (sortedJobs.length == 3) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[0].startDatetime,
          start_datetime: sortedJobs[0].startDatetime,
          technician_id: technicianId,
          is_paid: false,
        }),
      })

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[1].startDatetime,
          start_datetime: sortedJobs[0].endDatetime,
          technician_id: technicianId,
          is_paid: true,
        }),
      })

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[2].startDatetime,
          start_datetime: sortedJobs[1].endDatetime,
          technician_id: technicianId,
          is_paid: true,
        }),
      })

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[1].endDatetime,
          start_datetime: sortedJobs[1].endDatetime,
          technician_id: technicianId,
          is_paid: false,
        }),
      }).then(res => {
        if (res.ok) {
          refetch()
        } else {
          window.alert('Error')
        }
      })
    }
  }

  const allJobsAdded =
    JSON.stringify(sortedJobs?.map(job => job.id).sort()) ===
    JSON.stringify(
      (timeLogs || [])
        .filter(log => log.bToBJobId)
        .map(log => log.bToBJobId)
        .sort()
    )

  return sortedJobs.length > 0 ? (
    <Box
      sx={{
        justifyContent: 'space-between',
        borderBottom: '2px solid #ddd',
        padding: '.125rem .5rem',
        maxHeight: '200px',
        overflowY: 'auto',
        background: '#f1f1f1',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& svg': {
              transition: 'all .25s ease-in-out',
              transform: showAllJobs ? 'rotate(180deg)' : '',
            },
          }}
        >
          <Button onClick={() => setShowAllJobs(!showAllJobs)}>
            <KeyboardArrowDownRounded />
          </Button>
          <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
            {/* {timeCard.technician.firstName}'s jobs on{' '} */}
            {sortedJobs.length} b2b jobs on {DateTime.fromISO(timeCard.timeCardDate).toFormat('DDDD')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {allJobsAdded && (
            <Typography
              variant='body1'
              sx={{
                marginRight: '.5rem',
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                  fontSize: '22px',
                  marginRight: '.5rem',
                  color: '#777',
                },
              }}
            >
              <CheckCircleRounded /> All jobs added
            </Typography>
          )}

          <Button
            onClick={addAllJobsFromDay}
            size='small'
            variant='contained'
            disableElevation
            disabled={allJobsAdded || timeCard.acceptedAt}
          >
            Add All
          </Button>
        </Box>
      </Box>
      <Collapse in={showAllJobs}>
        <List>
          {data?.bToBJobs.map(job => {
            const matchFound = timeLogs?.find(j => j.bToBJobId === job.id)
            return (
              <ListItem
                sx={{
                  background: matchFound ? '#d1f2d0' : '',
                  '&:hover': { background: '#f9f9f9' },
                  fontSize: '14px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  '& svg': {
                    fontSize: '22px',
                    marginRight: '.5rem',
                    color: matchFound ? 'green' : '',
                  },
                }}
                key={job.id}
              >
                <Typography variant='body1' sx={{ fontSize: '14px' }}>
                  <Link to={`/jobs/${job.id}`}>{job.dealer.businessName}</Link>
                  {' - '}
                  <Chip
                    size='small'
                    label={
                      DateTime.fromISO(job.startDatetime, {
                        zone: 'utc',
                      }).toLocaleString(DateTime.TIME_SIMPLE) +
                      ' - ' +
                      DateTime.fromISO(job.endDatetime, {
                        zone: 'utc',
                      }).toLocaleString(DateTime.TIME_SIMPLE)
                    }
                  />
                </Typography>
                {matchFound && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      '& svg': {
                        fontSize: '18px',
                      },
                    }}
                  >
                    <CheckCircleRounded /> Added
                  </Box>
                )}
                {!matchFound && (
                  <Button
                    onClick={() => addWorkLogFromJob(job)}
                    size='small'
                    variant='contained'
                    disableElevation
                    disabled={!!timeCard.acceptedAt}
                    sx={{
                      display: 'flex',
                      minWidth: '30px',
                      justifyContent: 'space-between',
                      '& svg': {
                        fontSize: '18px',
                        marginRight: '0px',
                        color: matchFound ? 'green' : '',
                      },
                    }}
                  >
                    <AddCircleRounded />
                  </Button>
                )}
              </ListItem>
            )
          })}
        </List>
      </Collapse>
    </Box>
  ) : (
    <Box
      sx={{
        justifyContent: 'space-between',
        borderBottom: '2px solid #ddd',
        padding: '1rem',
        background: '#f1f1f1',
      }}
    >
      No b2b jobs to display for this day...
    </Box>
  )
}

const GET_JOBS = gql`
  query jobs($technicianId: ID, $startDatetime: String, $endDatetime: String, $includeCanceled: Boolean) {
    jobs(
      technicianId: $technicianId
      startDatetime: $startDatetime
      endDatetime: $endDatetime
      includeCanceled: $includeCanceled
    ) {
      id
      technicianId
      quoteId
      leadId
      startDatetime
      endDatetime
      canceledAt
      isActive
      heldForReschedulingAt
      didntGo
      postJobPhotos
      preJobPhotos
      technician {
        id
        name
        offdays
        availability
      }
      lead {
        name
      }
    }
  }
`

const JobsForDayComponent = ({ technicianId, refetch, timeCard, timeLogs }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [showAllJobs, setShowAllJobs] = useState(false)

  const { loading, error, data } = useQuery(GET_JOBS, {
    variables: {
      technicianId: technicianId,
      includeCanceled: true,
      startDatetime: DateTime.fromISO(timeCard.timeCardDate).startOf('day').toUTC().toISO(),
      endDatetime: DateTime.fromISO(timeCard.timeCardDate).endOf('day').toUTC().toISO(),
    },
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const addWorkLogFromJob = job => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_work_log_to_time_card`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        job_id: job.id,
        time_card_id: timeCard.id,
        start_datetime: job.startDatetime,
        end_datetime: job.endDatetime,
        technician_id: technicianId,
      }),
    }).then(res => {
      if (res.ok) {
        refetch()
      } else {
        window.alert('Error')
      }
    })
  }

  const addAllJobsFromDay = () => {
    data?.jobs.map(job =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_work_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          job_id: job.id,
          time_card_id: timeCard.id,
          start_datetime: job.startDatetime,
          end_datetime: job.endDatetime,
          technician_id: technicianId,
        }),
      })
    )

    const sortedJobs = data?.jobs?.slice().sort((a, b) => (a.startDatetime > b.startDatetime ? 1 : -1))

    if (sortedJobs.length === 1) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[0].startDatetime,
          start_datetime: sortedJobs[0].startDatetime,
          technician_id: technicianId,
          is_paid: false,
        }),
      }).then(res => {
        if (res.ok) {
          refetch()
        } else {
          window.alert('Error')
        }
      })

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          start_datetime: sortedJobs[0].endDatetime,
          end_datetime: sortedJobs[0].endDatetime,
          technician_id: technicianId,
          is_paid: false,
        }),
      }).then(res => {
        if (res.ok) {
          refetch()
        } else {
          window.alert('Error')
        }
      })
    }

    if (sortedJobs.length == 2) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[0].startDatetime,
          start_datetime: sortedJobs[0].startDatetime,
          technician_id: technicianId,
          is_paid: false,
        }),
      })

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[1].startDatetime,
          start_datetime: sortedJobs[0].endDatetime,
          technician_id: technicianId,
          is_paid: true,
        }),
      })

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[1].endDatetime,
          start_datetime: sortedJobs[1].endDatetime,
          technician_id: technicianId,
          is_paid: false,
        }),
      }).then(res => {
        if (res.ok) {
          refetch()
        } else {
          window.alert('Error')
        }
      })
    }

    if (sortedJobs.length == 3) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[0].startDatetime,
          start_datetime: sortedJobs[0].startDatetime,
          technician_id: technicianId,
          is_paid: false,
        }),
      })

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[1].startDatetime,
          start_datetime: sortedJobs[0].endDatetime,
          technician_id: technicianId,
          is_paid: true,
        }),
      })

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[2].startDatetime,
          start_datetime: sortedJobs[1].endDatetime,
          technician_id: technicianId,
          is_paid: true,
        }),
      })

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_travel_log_to_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          time_card_id: timeCard.id,
          user_id: user.id,
          end_datetime: sortedJobs[1].endDatetime,
          start_datetime: sortedJobs[1].endDatetime,
          technician_id: technicianId,
          is_paid: false,
        }),
      }).then(res => {
        if (res.ok) {
          refetch()
        } else {
          window.alert('Error')
        }
      })
    }
  }

  return data.jobs.length > 0 ? (
    <Box
      sx={{
        justifyContent: 'space-between',
        borderBottom: '2px solid #ddd',
        padding: '.125rem .5rem',
        maxHeight: '200px',
        overflowY: 'auto',
        background: '#f1f1f1',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& svg': {
              transition: 'all .25s ease-in-out',
              transform: showAllJobs ? 'rotate(180deg)' : '',
            },
          }}
        >
          <Button onClick={() => setShowAllJobs(!showAllJobs)}>
            <KeyboardArrowDownRounded />
          </Button>
          <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
            {/* {timeCard.technician.firstName}'s jobs on{' '} */}
            {data?.jobs.length} jobs on {DateTime.fromISO(timeCard.timeCardDate).toFormat('DDDD')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {JSON.stringify(data?.jobs.map(job => job.id).sort()) ===
            JSON.stringify(
              timeLogs
                .filter(log => log.jobId)
                .map(log => log.jobId)
                .sort()
            ) && (
            <Typography
              variant='body1'
              sx={{
                marginRight: '.5rem',
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                  fontSize: '22px',
                  marginRight: '.5rem',
                  color: '#777',
                },
              }}
            >
              <CheckCircleRounded /> All jobs added
            </Typography>
          )}

          <Button
            onClick={addAllJobsFromDay}
            size='small'
            variant='contained'
            disableElevation
            disabled={
              JSON.stringify(data?.jobs.map(job => job.id).sort()) ===
                JSON.stringify(
                  timeLogs
                    .filter(log => log.jobId)
                    .map(log => log.jobId)
                    .sort()
                ) || timeCard.acceptedAt
            }
          >
            Add All
          </Button>
        </Box>
      </Box>
      <Collapse in={showAllJobs}>
        <List>
          {data.jobs.map(job => {
            const matchFound = timeLogs.find(j => j.jobId === job.id)
            return (
              <ListItem
                sx={[
                  {
                    background: matchFound ? '#d1f2d0' : '',
                    '&:hover': { background: '#f9f9f9' },
                    fontSize: '14px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    '& svg': {
                      fontSize: '22px',
                      marginRight: '.5rem',
                      color: matchFound ? 'green' : '',
                    },
                  },
                  job.canceledAt && {
                    background: '#d32f2f1a',
                  },
                ]}
                key={job.id}
              >
                <Typography variant='body1' sx={{ fontSize: '14px' }}>
                  <Link to={`/jobs/${job.id}`}>{job.lead.name}</Link>
                  {' - '}
                  <Chip
                    size='small'
                    label={
                      DateTime.fromISO(job.startDatetime, {
                        zone: 'utc',
                      }).toLocaleString(DateTime.TIME_SIMPLE) +
                      ' - ' +
                      DateTime.fromISO(job.endDatetime, {
                        zone: 'utc',
                      }).toLocaleString(DateTime.TIME_SIMPLE)
                    }
                  />
                  {job.canceledAt && <Chip sx={{ ml: '.5rem' }} color='error' size='small' label='Canceled' />}
                </Typography>
                {matchFound && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      '& svg': {
                        fontSize: '18px',
                      },
                    }}
                  >
                    <CheckCircleRounded /> Added
                  </Box>
                )}
                {!matchFound && (
                  <Button
                    onClick={() => addWorkLogFromJob(job)}
                    size='small'
                    variant='contained'
                    disableElevation
                    sx={{
                      display: 'flex',
                      minWidth: '30px',
                      justifyContent: 'space-between',
                      '& svg': {
                        fontSize: '18px',
                        marginRight: '0px',
                        color: matchFound ? 'green' : '',
                      },
                    }}
                  >
                    <AddCircleRounded />
                  </Button>
                )}
              </ListItem>
            )
          })}
        </List>
      </Collapse>
    </Box>
  ) : (
    <Box
      sx={{
        justifyContent: 'space-between',
        borderBottom: '2px solid #ddd',
        padding: '1rem',
        background: '#f1f1f1',
      }}
    >
      No jobs to display for this day...
    </Box>
  )
}

export default ShowEdit
