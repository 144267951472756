import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Snackbar, Container, Grid, Button, Stack, Typography, TextField, FormControlLabel, Switch } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'

import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { PriceInput, diff } from 'tools'
import { UserContext } from 'UserStore'

const marketSchema = Yup.object().shape({
  hourly_rate_prep: Yup.number().required(),
  hourly_rate_paint: Yup.number().required(),
  hourly_rate_body_work: Yup.number().required(),
  hourly_rate_major_part: Yup.number().required(),
  hourly_rate_minor_part: Yup.number().required(),
  quote_approval_required: Yup.boolean().required(),
})

const Edit = ({ market }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const history = useHistory()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false)
  }

  const initialValues = {
    hourly_rate_prep: market?.hourlyRatePrep || 0,
    hourly_rate_paint: market?.hourlyRatePaint || 0,
    hourly_rate_body_work: market?.hourlyRateBodyWork || 0,
    hourly_rate_major_part: market?.hourlyRateMajorPart || 0,
    hourly_rate_minor_part: market?.hourlyRateMinorPart || 0,
    quote_approval_required: market?.quoteApprovalRequired || 0,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: marketSchema,
    onSubmit: values => {
      const updatedAttributes = diff(values, initialValues)

      if (Object.keys(updatedAttributes).length === 0) return alert('No changes!')

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_market_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          market_id: market.id,
          user_id: user.id,
          updated_attributes: updatedAttributes,
        }),
      }).then(res => (res.ok ? setSnackbarOpen(true) : window.alert('error')))
    },
  })

  const handleReset = () => formik.setValues(initialValues)

  if (!market) return 'Error: invalid market id'

  return (
    <Container>
      <Stack
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
          '& .MuiFormControl-root': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={formik.handleSubmit}
      >
        <Typography variant='h6'>
          Edit Market hourly rates: {market.market} {market.stateCode}
        </Typography>

        <Grid container>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              size='small'
              label='Prep'
              name='hourly_rate_prep'
              InputProps={{ inputComponent: PriceInput }}
              value={formik.values.hourly_rate_prep}
              onChange={formik.handleChange}
              error={formik.touched.hourly_rate_prep && Boolean(formik.errors.hourly_rate_prep)}
              helperText={formik.touched.hourly_rate_prep && formik.errors.hourly_rate_prep}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              size='small'
              label='Paint'
              name='hourly_rate_paint'
              InputProps={{ inputComponent: PriceInput }}
              value={formik.values.hourly_rate_paint}
              onChange={formik.handleChange}
              error={formik.touched.hourly_rate_paint && Boolean(formik.errors.hourly_rate_paint)}
              helperText={formik.touched.hourly_rate_paint && formik.errors.hourly_rate_paint}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              size='small'
              label='Body Work'
              name='hourly_rate_body_work'
              InputProps={{ inputComponent: PriceInput }}
              value={formik.values.hourly_rate_body_work}
              onChange={formik.handleChange}
              error={formik.touched.hourly_rate_body_work && Boolean(formik.errors.hourly_rate_body_work)}
              helperText={formik.touched.hourly_rate_body_work && formik.errors.hourly_rate_body_work}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              size='small'
              label='Major Part'
              name='hourly_rate_major_part'
              InputProps={{ inputComponent: PriceInput }}
              value={formik.values.hourly_rate_major_part}
              onChange={formik.handleChange}
              error={formik.touched.hourly_rate_major_part && Boolean(formik.errors.hourly_rate_major_part)}
              helperText={formik.touched.hourly_rate_major_part && formik.errors.hourly_rate_major_part}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              size='small'
              label='Minor Part'
              name='hourly_rate_minor_part'
              InputProps={{ inputComponent: PriceInput }}
              value={formik.values.hourly_rate_minor_part}
              onChange={formik.handleChange}
              error={formik.touched.hourly_rate_minor_part && Boolean(formik.errors.hourly_rate_minor_part)}
              helperText={formik.touched.hourly_rate_minor_part && formik.errors.hourly_rate_minor_part}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
            <FormControlLabel
              cy-data='approval-switch'
              control={
                <Switch
                  color='primary'
                  checked={ formik.values.quote_approval_required }
                  onChange={ () => formik.setFieldValue('quote_approval_required', !formik.values.quote_approval_required) }
                />
              }
              label='Approval required'
              labelPlacement='bottom'
            />
          </Grid>
        <div>
          <Button
            variant='contained'
            color='primary'
            disabled={!formik.dirty || !formik.isValid}
            type='submit'
            onClick={formik.handleSubmit}
          >
            <SaveIcon />
            Save
          </Button>
          <Button onClick={() => history.goBack()}>Cancel</Button>
          <Button disabled={!formik.dirty} onClick={handleReset}>
            Reset
          </Button>
        </div>
      </Stack>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose} message='Changes saved.' />
    </Container>
  )
}

export default Edit
