import { useContext } from 'react'
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom'
import { GlobalContext } from 'GlobalStore'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Technician from './Technician'

const Chat = () => {
  const { path } = useRouteMatch()
  const [global] = useContext(GlobalContext)

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ul style={{ fontSize: '1.2em' }}>
            {global.technicians
              .filter(tech => tech.isBToB)
              .map(tech => (
                <li key={tech.id} style={{ padding: '0.3em 0' }}>
                  <Link to={`/b-to-b/chat/${tech.id}`}>{`${tech.firstName} ${tech.lastName}`}</Link>
                </li>
              ))}
          </ul>
        </Grid>
        <Grid item xs={8}>
          <Switch>
            <Route path={`${path}/:technicianId`}>
              <Technician />
            </Route>
            <Route path={path}>
              <p>SELECT A TECHNICIAN</p>
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Chat
