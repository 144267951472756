import React, { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  Chip,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import SvgIcon from '@mui/material/SvgIcon'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { UserContext } from '../UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import InsurancePhotoUploader from '../Leads/Quotes/Quote/V2/components/InsurancePhotoUploader'
import { paymentStatusLabels } from '../data/styles'

const QUOTES = gql`
  query QuotesConnection($order: JSON, $filter: JSON, $first: Int, $after: String, $insuranceCheckReceived: Boolean) {
    quotesConnection(
      order: $order
      filter: $filter
      first: $first
      after: $after
      insuranceCheckReceived: $insuranceCheckReceived
    ) {
      edges {
        cursor
        node {
          id
          quoteGrandTotal
          paymentStatus
          markedInsuranceAt
          insuranceCheckReceivedAt
          balanceAmountDue
          createdAt
          insurancePhotos
          jobs {
            id
            isActive
            startDatetime
          }
          lead {
            id
            name
            state
            market
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const filterButtons = [
  {
    label: 'all',
    filter: {},
    insuranceCheckReceived: null,
  },
  {
    label: 'all unpaid',
    filter: {},
    insuranceCheckReceived: false,
  },
  {
    label: 'All paid',
    filter: {},
    insuranceCheckReceived: true,
  },
  {
    label: 'Unpaid for 2+ weeks',
    filter: {
      marked_insurance_at: {
        le: DateTime.now().minus({ weeks: 2 }).toISO().slice(0, -13),
      },
    },
    insuranceCheckReceived: false,
  },
]

const InsuranceDashboard = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')
  const [photoUploadPopupQuoteId, setPhotoUploadPopupQuoteId] = useState()
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem('quotesOrder')) || {})
  const [filter, setFilter] = useState(
    JSON.parse(localStorage.getItem('quotesFilter')) || {
      marked_insurance_at: {
        le: DateTime.now().minus({ weeks: 2 }).toISO().slice(0, -13),
      },
    }
  )
  const [limit, setLimit] = useState(JSON.parse(localStorage.getItem('quotesLimit')) || 50)
  const [insuranceCheckReceived, setInsuranceCheckReceived] = useState(
    !!localStorage.getItem('insuranceCheckReceivedFlag') || false
  )

  useEffect(() => localStorage.setItem('quotesOrder', JSON.stringify(order)), [order])
  useEffect(() => localStorage.setItem('quotesFilter', JSON.stringify(filter)), [filter])
  useEffect(() => localStorage.setItem('quotesLimit', JSON.stringify(limit)), [limit])

  useEffect(() => localStorage.setItem('insuranceCheckReceivedFlag', insuranceCheckReceived), [insuranceCheckReceived])

  const setOrToggleOrder = name => {
    setOrder({
      ...order,
      [name]: (order[name] && order[name] === 'desc' ? 'asc' : 'desc') || 'desc',
    })
  }

  useEffect(() => {
    fetchMore({
      variables: {
        order,
        filter,
        first: limit,
        after: cursorCeiling,
        insuranceCheckReceived,
      },
    })
  }, [order, filter, limit])

  const { loading, error, data, fetchMore, refetch } = useQuery(QUOTES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      order,
      filter,
      first: limit,
      after: cursorCeiling,
      insuranceCheckReceived,
    },
  })

  if (error) return <div>Error!</div>

  const quotes = data?.quotesConnection?.edges?.map(edge => edge.node) || []
  const totalCount = data?.quotesConnection?.totalCount || 0
  const endCursor = data?.quotesConnection?.pageInfo?.endCursor || 0

  const DateRangeHeadCell = ({ name, align }) => {
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [dateRange, setDateRange] = useState(filter[name] || {})
    // ONE of BOTH key of: { ge: iso8601, le: iso8601 } OR null
    // ge -> greater than or equal
    // le -> less than or equal

    const handleDateTimeChanged = evt => {
      setDateRange({ ...dateRange, [evt.target.name]: evt.target.value })
    }

    const handleClickApply = () => {
      setFilter({ ...filter, [name]: dateRange })
    }

    const handleClickClose = () => {
      setDialogIsOpen(false)
    }

    const handleDeleteRangeKey = key => {
      const { [key]: removed, ...rest } = dateRange
      setDateRange(rest)
      setFilter({ ...filter, [name]: rest })
    }

    return (
      <TableCell align={align}>
        {name}

        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={() => setOrToggleOrder(name)}>
          <SvgIcon
            fontSize='small'
            component={(order[name] === 'desc' ? ArrowDownwardIcon : ArrowUpwardIcon) || ArrowDownwardIcon}
          />
        </Button>

        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={() => setDialogIsOpen(true)}>
          <SvgIcon fontSize='small' component={FilterListIcon} />
        </Button>

        {filter[name] && Object.entries(filter[name]).length > 0 && (
          <>
            <br />
            {Object.entries(filter[name])
              .sort((a, b) => (a[0] > b[0] ? 1 : -1))
              .map(filterTuple => (
                <Chip
                  key={filterTuple[0]}
                  variant='outlined'
                  size='small'
                  onDelete={() => handleDeleteRangeKey(filterTuple[0])}
                  label={`
                    ${filterTuple[0] === 'le' ? 'before' : 'after'}
                    ${DateTime.fromISO(filterTuple[1]).toFormat('yy-MM-dd')}
                  `}
                />
              ))}
          </>
        )}

        <Dialog open={dialogIsOpen} onClose={setDialogIsOpen}>
          <DialogTitle>Select Date Range (One Or Both)</DialogTitle>

          <DialogContent>
            <Box>
              <span>
                Start Date<small> (optional)</small>
              </span>
              <input type='datetime-local' name='ge' value={dateRange.ge || ''} onChange={handleDateTimeChanged} />
            </Box>
            <Box>
              <span>
                End Date<small> (optional)</small>
              </span>
              <input type='datetime-local' name='le' value={dateRange.le || ''} onChange={handleDateTimeChanged} />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClickClose} color='primary'>
              Close
            </Button>
            <Button onClick={handleClickApply} color='primary' autoFocus>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    )
  }

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          order,
          filter,
          first: limit,
          after: endCursor,
          insuranceCheckReceived,
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          mb: '1rem',
          background: '#f1f1f1',
          padding: '.5rem',
          ...paymentStatusLabels,
        }}
      >
        {filterButtons.map(button => (
          <Button
            key={button.label}
            onClick={() => {
              setFilter(button.filter)
              setInsuranceCheckReceived(button.insuranceCheckReceived)
            }}
            disabled={
              !!(JSON.stringify(filter) == JSON.stringify(button.filter)) &&
              insuranceCheckReceived === button.insuranceCheckReceived
            }
            sx={{ ml: '.5rem' }}
            variant='contained'
          >
            {button.label}
          </Button>
        ))}
      </Box>
      <TableContainer component={Paper}>
        <Table size='small' aria-label='a dense table'>
          {loading && (
            <Box
              sx={{
                position: 'fixed',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#11111140',
                top: '0px',
                bottom: '0px',
                left: '0px',
                right: '0px',
              }}
            >
              <Box>
                <CircularProgress />
              </Box>
            </Box>
          )}
          <TableHead>
            <TableRow>
              <DateRangeHeadCell align='right' name='created_at' />
              <DateRangeHeadCell align='right' name='marked_insurance_at' />
              <TableCell align='right'>Quote</TableCell>
              <TableCell align='right'>Total Quoted amount</TableCell>
              <TableCell align='right'>State</TableCell>
              <TableCell align='right'>Market</TableCell>
              <TableCell align='right'>Job statuses</TableCell>
              <TableCell align='right'>Amount due</TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quotes.slice(page * limit, page * limit + limit).map(quote => (
              <TableRow key={quote.id} sx={paymentStatusLabels}>
                <TableCell align='right'>{DateTime.fromISO(quote.createdAt).toFormat('ff ZZZZ')}</TableCell>

                <TableCell align='right'>{DateTime.fromISO(quote.markedInsuranceAt).toFormat('ff ZZZZ')}</TableCell>

                <TableCell component='th' align='right'>
                  <Link to={`/quotes/${quote.id}`}>{quote.lead.name}</Link>
                </TableCell>
                <TableCell align='right'>${quote.quoteGrandTotal}</TableCell>
                <TableCell align='right'>{quote.lead.state}</TableCell>
                <TableCell align='right'>{quote.lead.market}</TableCell>
                <TableCell align='right' className={quote.paymentStatus}>
                  {quote.jobs.length > 0 &&
                    (quote.jobs.find(job => job.isActive) ? (
                      <span style={{ color: 'green' }}> Active </span>
                    ) : (
                      <span style={{ color: 'red' }}> Cancelled </span>
                    ))}
                  {quote.jobs.length === 0 && <> No jobs scheduled </>}
                </TableCell>
                <TableCell align='right' className={quote.paymentStatus}>
                  {quote.balanceAmountDue}
                </TableCell>
                <TableCell align='right'>
                  <button
                    disabled={!!quote.insuranceCheckReceivedAt}
                    onClick={() => setPhotoUploadPopupQuoteId(quote.id)}
                  >
                    [Add payment]
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <PaymentDialog
          setPhotoUploadPopupQuoteId={setPhotoUploadPopupQuoteId}
          photoUploadPopupQuoteId={photoUploadPopupQuoteId}
          refetch={refetch}
        />
      </TableContainer>
    </Box>
  )
}

const PaymentDialog = ({ photoUploadPopupQuoteId, setPhotoUploadPopupQuoteId, refetch }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [paymentAmount, setPaymentAmount] = useState(0.0)

  const handleClickAddInsurancePayment = () => {
    paymentAmount > 0 &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_insurance_payment`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          quote_id: photoUploadPopupQuoteId,
          amount: paymentAmount.toFixed(2),
        }),
      }).then(res => {
        if (res.ok) {
          refetch()
          setPhotoUploadPopupQuoteId(null)
        } else {
          alert('Error')
        }
      })
  }

  return (
    <Dialog
      open={!!photoUploadPopupQuoteId}
      onClose={() => setPhotoUploadPopupQuoteId(null)}
      PaperProps={{
        sx: {
          minWidth: '500px',
        },
      }}
    >
      <Box>
        <Box
          sx={{
            background: '#f1f1f1',
            padding: '1rem',
            borderBottom: '2px solid #ddd',
          }}
        >
          <Typography variant='h6'>Add insurance payment</Typography>
        </Box>
        <Box
          sx={{
            padding: '1rem',
            '& .dzu-dropzone': {
              overflow: 'hidden',
              textAlign: 'center',
              minHeight: '50px',
            },
            '& .dzu-inputLabel': {
              fontSize: '14px',
            },
            '& span': {
              fontSize: '12px',
              fontStyle: 'italic',
            },
            textAlign: 'center',
          }}
        >
          <TextField
            sx={{ margin: '.5rem' }}
            size='small'
            label='payment amount'
            type='number'
            value={paymentAmount}
            onChange={e => setPaymentAmount(Number(e.target.value))}
          />
          <br />
          <span>*Make sure to enter the exact amount, without commas. Like this: 1144.45</span>

        </Box>
        <Box
          sx={{
            background: '#f1f1f1',
            padding: '1rem',
            borderTop: '2px solid #ddd',
          }}
        >
          <Button variant='contained' onClick={handleClickAddInsurancePayment}>
            Add payment
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default InsuranceDashboard
