import { useContext, useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { MenuItem, Select, FormControl, Container, Box, Typography, TextField, Button, Grid } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { basicDateTimeFormat } from 'tools'
import { DateTime } from 'luxon'
import GlassMarkupBracketRow from './components/GlassMarkupBracketRow'
import LaborItemRow from './components/LaborItemRow'

const PRICING_QUERY = gql`
  query omegaEdiPricing {
    omegaEdiPricing {
      updatedAt
      cashDiscountRate
      installationRate
      calibrationDynamic
      calibrationStatic
      repairPriceChips
      updatedBy {
        firstName
        lastName
      }
      glassMarkupBrackets {
        id
        limit
        insuranceRate
        cashRate
      }
      laborItems {
        id
        name
        description
        price
        useMarketUpcharges
        quantitySource
      }
    }
  }
`

const pricingSchema = Yup.object().shape({
  cashDiscountRate: Yup.number().required(),
  installationRate: Yup.number().required(),
  calibrationDynamic: Yup.number().required(),
  calibrationStatic: Yup.number().required(),
})

function stateForData(data) {
  return {
    cashDiscountRate: data.omegaEdiPricing.cashDiscountRate,
    installationRate: data.omegaEdiPricing.installationRate,
    calibrationDynamic: data.omegaEdiPricing.calibrationDynamic,
    calibrationStatic: data.omegaEdiPricing.calibrationStatic,
    repairChip1: data.omegaEdiPricing.repairPriceChips['1'],
    repairChip2: data.omegaEdiPricing.repairPriceChips['2'],
    repairChip3: data.omegaEdiPricing.repairPriceChips['3'],
  }
}

export const Index = () => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const { loading, error, data, refetch } = useQuery(PRICING_QUERY)
  const [initialValues, setInitialValues] = useState({
    cashDiscountRate: 0,
    installationRate: 0,
    calibrationDynamic: 0,
    calibrationStatic: 0,
    repairChip1: 0,
    repairChip2: 0,
    repairChip3: 0,
  })
  const [editingGlassMarkupBracket, setEditingGlassMarkupBracket] = useState('')
  const [newItemName, setNewItemName] = useState('')
  const [newItemDescription, setNewItemDescription] = useState('')
  const [newItemPrice, setNewItemPrice] = useState('')
  const [useMarketUpcharges, setUseMarketUpcharges] = useState(false)
  const [quantitySource, setQuantitySource] = useState('1 per quote')

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: pricingSchema,
    enableReinitialize: true,
    onSubmit: values => {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/omega_edi_update_pricing`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          ...values,
        }),
      })
        .then(res => (res.ok ? window.location.reload() : alert('error')))
        .catch(err => alert(err))
    },
  })

  useEffect(() => {
    if (!data) return
    const next = stateForData(data)
    setInitialValues(next)
    formik.initialValues = next
    formik.setValues(next)
  }, [data])

  if (loading) return 'loading...'
  if (error) return JSON.stringify(error)

  function onClickNewBracket() {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/omega_edi_create_pricing_markup_bracket`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ userId: user.id }),
    })
      .then(res => (res.ok ? window.location.reload() : alert('error')))
      .catch(err => alert(err))
  }

  function onClickNewLaborItem() {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/omega_create_labor_item`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        name: newItemName,
        price: newItemPrice,
        useMarketUpcharges,
        quantitySource,
      }),
    })
      .then(res => (res.ok ? refetch() : alert('error')))
      .then(() => {
        setNewItemName('')
        setNewItemPrice('')
        setUseMarketUpcharges(false)
      })
      .catch(err => alert(err))
  }

  const validationErrors = Object.values(
    Object.keys(formik.errors).reduce((acc, k) => {
      acc[k] = formik.errors[k]
      return acc
    }, {})
  )

  return (
    <>
      <Container>
        <Box
          component='form'
          sx={{
            '& .MuiFormControl-root': { m: 1 },
          }}
          noValidate
          autoComplete='off'
          onSubmit={formik.handleSubmit}
        >
          <Typography variant='h6'>OmegaEDI Pricing</Typography>

          <div>
            <>Last updated by </>
            <>
              {data.omegaEdiPricing.updatedBy.firstName} {data.omegaEdiPricing.updatedBy.lastName[0]}.
            </>
            <> at </>
            <>{DateTime.fromISO(data.omegaEdiPricing.updatedAt).toFormat(basicDateTimeFormat)}</>
          </div>
          <br />

          <div>
            <TextField
              variant='outlined'
              size='small'
              label='Cash Discount Rate'
              name='cashDiscountRate'
              type='number'
              value={formik.values.cashDiscountRate}
              onChange={formik.handleChange}
              error={formik.touched.cashDiscountRate && Boolean(formik.errors.cashDiscountRate)}
              helperText={formik.touched.cashDiscountRate && formik.errors.cashDiscountRate}
            />

            <TextField
              variant='outlined'
              size='small'
              label='Installation Rate (% glass cost)'
              name='installationRate'
              type='number'
              value={formik.values.installationRate}
              onChange={formik.handleChange}
              error={formik.touched.installationRate && Boolean(formik.errors.installationRate)}
              helperText={formik.touched.installationRate && formik.errors.installationRate}
            />

            <TextField
              variant='outlined'
              size='small'
              label='Dynamic Calibration Price'
              name='calibrationDynamic'
              type='number'
              value={formik.values.calibrationDynamic}
              onChange={formik.handleChange}
              error={formik.touched.calibrationDynamic && Boolean(formik.errors.calibrationDynamic)}
              helperText={formik.touched.calibrationDynamic && formik.errors.calibrationDynamic}
            />
            <TextField
              variant='outlined'
              size='small'
              label='Static Calibration Price'
              name='calibrationStatic'
              type='number'
              value={formik.values.calibrationStatic}
              onChange={formik.handleChange}
              error={formik.touched.calibrationStatic && Boolean(formik.errors.calibrationStatic)}
              helperText={formik.touched.calibrationStatic && formik.errors.calibrationStatic}
            />
          </div>

          <div style={{ color: 'red' }}>
            {validationErrors.map(error => (
              <div key={error}>{error}</div>
            ))}
          </div>
          <Button variant='contained' color='primary' type='submit' disabled={!formik.isValid}>
            save
          </Button>
        </Box>

        <hr />
        <Box>
          <div>
            <b>Windshield Labor Items</b>
            <Box sx={{ display: 'flex' }}>
              <TextField
                value={newItemName}
                onChange={e => setNewItemName(e.target.value)}
                placeholder='item name...'
                size='small'
              />
              <TextField
                value={newItemDescription}
                onChange={e => setNewItemDescription(e.target.value)}
                placeholder='description'
                size='small'
              />
              <TextField
                type='number'
                value={newItemPrice}
                onChange={e => setNewItemPrice(e.target.value)}
                placeholder='price'
                size='small'
                sx={{ ml: '.5rem', mr: '.5rem' }}
              />
              <FormControl size='small'>
                <Select size='small' value={quantitySource} onChange={e => setQuantitySource(e.target.value)}>
                  <MenuItem value='1 per quote'>1 per quote</MenuItem>
                  <MenuItem value='1 per chip'>1 per chip</MenuItem>
                  <MenuItem value='1 per replacement'>1 per replacement</MenuItem>
                </Select>
              </FormControl>
              <Button disabled={newItemName === ''} onClick={onClickNewLaborItem} sx={{ float: 'right' }}>
                new item
              </Button>
            </Box>
            <hr />

            <Grid container xs={12} sx={{ m: 4 }}>
              <Grid item xs={3}>
                Name
              </Grid>
              <Grid item xs={3}>
                Description
              </Grid>
              <Grid item xs={3}>
                Price
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            {data.omegaEdiPricing.laborItems.map(item => (
              <LaborItemRow
                key={item.id}
                data={item}
                editing={editingGlassMarkupBracket}
                setEditing={setEditingGlassMarkupBracket}
                refetch={refetch}
              />
            ))}
          </div>
        </Box>

        <hr />
        <Box>
          <div>
            <b>Glass Price Markup Brackets</b>
            <Button onClick={onClickNewBracket} sx={{ float: 'right' }}>
              new bracket
            </Button>
            <Grid container xs={12} sx={{ m: 4 }}>
              <Grid item xs={3}>
                Limit
              </Grid>
              <Grid item xs={3}>
                Insurance Rate
              </Grid>
              <Grid item xs={3}>
                Cash Rate
              </Grid>
              <Grid item xs={3} />
            </Grid>
            {data.omegaEdiPricing.glassMarkupBrackets.map(bracket => (
              <GlassMarkupBracketRow
                key={bracket.id}
                data={bracket}
                editing={editingGlassMarkupBracket}
                setEditing={setEditingGlassMarkupBracket}
              />
            ))}
          </div>
        </Box>
      </Container>
    </>
  )
}

export default Index
