import { useState, useEffect, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { API_COMMAND_HEADERS, Dollars, keysToCamel } from 'tools'
import { Link } from 'react-router-dom'
import { UpdateRefetchRegistry } from 'UpdateRefetchRegistry'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  Skeleton,
  CircularProgress,
  Checkbox,
  ListItem,
  ListItemButton,
  List,
  Popover,
  Divider,
  Dialog,
  Badge,
  Chip,
  Snackbar
} from '@mui/material'
import { DateTime } from 'luxon'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { ConfigurationContext } from '../Configure/Configure'
import {
  CheckCircleOutlineRounded,
  KeyboardArrowRightRounded,
  LaunchRounded,
  MoreVertRounded
} from '@mui/icons-material'
import PayrollPopup from 'BToB/Invoices/BToBInvoice/PayrollPopup'
import { calculateBToBInvoicePayoutData, calculatePayoutData } from '@carbodylab/payroll-calculator'
// import { calculateBToBInvoicePayoutData } from '@carbodylab/payroll-calculator'
import { NotesDialog } from '../B2BEmployees/NotesDialog'
import JSONPretty from 'react-json-pretty'
import { PayoutDataPopover } from '../components/PayoutDataPopover'
import { EmployeeInvoiceHeadRow, EmployeeInvoicePayoutRow, EmployeeInvoicesSumRow } from '../B2BEmployees/BToBEmployees'

const QUERY = gql`
  query bToBInvoices($isAccepted: Boolean, $hasPayoutId: Boolean) {
    bToBInvoices(isAccepted: $isAccepted, hasPayoutId: $hasPayoutId) {
      id
      createdAt
      acceptedAt
      businessName
      lineItems
      lineItemsSum
      grandTotal
      preJobPhotos
      postJobPhotos
      year
      make
      model
      payoutData
      vin
      vinPhoto
      commissionPercent
      completedAt
      repairOrder
      stock
      deletedAt
      payoutId
      squareInvoiceId
      projectedPayout
      technicianId
      invoiceVersion
      payoutOverrideTotal
      payrollNotesCount
      technician {
        id
        isEmployee
        firstName
        lastName
        isEmployee
        enableB2bSquareFee
        bToBInvoiceCommissionStructure
        commissionPercent
      }
      dealer {
        id
        businessName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        primaryContact {
          firstName
          lastName
          email
        }
      }
    }
  }
`

export const ContractorBToBInvoicePayoutRow = ({
  invoice,
  index,
  selectInvoice,
  invoiceIsSelected,
  handleClickDisclude,
  hideCheckbox,
  unacceptButton
}) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [menuOpen, setMenuOpen] = useState(false)
  const [popoverRef, setPopoverRef] = useState(null)
  const [invoiceId, setInvoiceId] = useState(null)
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)

  const handleClickUnaccept = id => {
    window
      .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unaccept_b_to_b_invoice`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBInvoiceId: id,
          userId: user.id
        })
      })
      .then(response => {
        if (response.ok) {
          return true
        } else {
          throw Error('error')
        }
      })
      .catch(err => {
        window.alert(err)
      })
  }

  return (
    <TableRow sx={{ background: index % 2 === 0 ? '#fff' : '#f9f9f9' }}>
      {!hideCheckbox && (
        <TableCell sx={{ maxWidth: '60px', padding: '0rem .5rem' }}>
          <Checkbox
            sx={{
              padding: '0px',
              '& svg': {
                height: '15px',
                width: '15px'
              }
            }}
            onClick={selectInvoice}
            checked={invoiceIsSelected}
            size='small'
          />
        </TableCell>
      )}
      <TableCell align='right'>
        <Link key={invoice.id} target='_blank' to={`/b-to-b/invoices/${invoice.id}`}>
          {DateTime.fromISO(invoice.completedAt).toISODate()}
        </Link>
      </TableCell>
      <TableCell align='right'>
        <Link key={invoice.id} target='_blank' to={`/b-to-b/invoices/${invoice.id}`}>
          {DateTime.fromISO(invoice.createdAt).toISODate()}
        </Link>
      </TableCell>
      <TableCell align='right'>
        <Link key={invoice.dealer.id} target='_blank' to={`/b-to-b/dealers/${invoice.dealer.id}`}>
          {invoice.dealer.businessName}
        </Link>
      </TableCell>
      <TableCell align='right'>
        {invoice.year} {invoice.make} {invoice.model}
      </TableCell>
      <TableCell align='right'>
        <Dollars value={invoice.payoutData?.ccFee} />
      </TableCell>

      <TableCell align='right'>{invoice.payoutData?.commissionPercent || invoice.technician?.commissionPercent}</TableCell>
      <TableCell align='right'>
        <Dollars value={invoice.grandTotal} />
      </TableCell>
      <TableCell align='right'>
        <Dollars value={invoice.payoutData?.totalPayout} />
      </TableCell>
      {unacceptButton && (
        <TableCell align='right'>
          <Badge color='primary' badgeContent={invoice.payrollNotesCount}>
            <Button
              onClick={e => {
                e.stopPropagation()
                setMenuOpen(true)
                setPopoverRef(e.target)
              }}
              sx={{
                minWidth: '20px',
                minHeight: '20px',
                padding: '.125rem',
                '& svg': { width: '20px', height: '20px' }
              }}
            >
              <MoreVertRounded />
            </Button>
          </Badge>

          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            onClose={e => {
              e.stopPropagation()
              setMenuOpen(false)
            }}
            open={menuOpen}
            anchorEl={popoverRef}
          >
            <List>
              <ListItem disablePadding disableGutters>
                <ListItemButton
                  onClick={e => {
                    e.stopPropagation()
                    setInvoiceId(invoice.id)
                  }}
                >
                  View invoice <LaunchRounded sx={{ fontSize: '18px', ml: '.5rem' }} />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding disableGutters>
                <ListItemButton
                  onClick={e => {
                    e.stopPropagation()
                    setNoteDialogOpen(true)
                  }}
                >
                  View notes
                </ListItemButton>
              </ListItem>
              <Divider />

              <ListItem disablePadding disableGutters>
                <ListItemButton
                  onClick={e => {
                    e.stopPropagation()
                    handleClickUnaccept(invoice.id)
                  }}
                >
                  Unaccept
                </ListItemButton>
              </ListItem>
            </List>
          </Popover>
        </TableCell>
      )}

      {handleClickDisclude && (
        <TableCell align='right'>
          <button onClick={handleClickDisclude}>Remove</button>
        </TableCell>
      )}

      <NotesDialog bToBInvoice={invoice} noteDialogOpen={noteDialogOpen} setNoteDialogOpen={setNoteDialogOpen} />

      <Dialog
        PaperProps={{ sx: { borderRadius: '12px', padding: '1rem' } }}
        open={!!invoiceId}
        onClose={() => setInvoiceId(null)}
      >
        <Box>{invoiceId && <PayrollPopup invoice={keysToCamel(invoice)} />}</Box>
      </Dialog>
    </TableRow>
  )
}

const PayoutPreview = ({ invoices, setRequestSuccess, setRequestLoading, refetch }) => {
  const [user] = useContext(UserContext)
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState(invoices.map(tc => tc.id))
  const [waiting, setWaiting] = useState(false)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [payoutDataOpen, setPayoutDataOpen] = useState(false)

  const { technician } = invoices[0]

  const handleLinkChecked = () => {
    setWaiting(true)

    setRequestLoading(true)

    Promise.all(
      selectedInvoiceIds.map((invoiceId, index) =>
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/link_b_to_b_invoice_to_a_payout`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: invoiceId,
            userId: user.id
          })
        })
      )
    ).then(() => {
      setRequestLoading(false)
      setRequestSuccess('Invoices moved to payout')
    })
  }

  const payoutCalculations = calculatePayoutData({
    reimbursements: [],
    pto: [],
    uto: [],
    sto: [],
    manualCompensations: [],
    chargeBacks: [],
    timeCards: [],
    quotes: [],
    bToBInvoices: invoices,
    bToBPayouts: [],
    technician,
    loanDeductionAmount: 0,
    otherLoanDeductionAmount: 0
  })

  const allTimeCardsAreSelected = JSON.stringify(selectedInvoiceIds) === JSON.stringify(invoices.map(tc => tc.id))

  const { gustoWageAmount } = payoutCalculations

  return (
    <Box sx={{ padding: '.5rem', border: '1px solid #ddd', borderRadius: '12px', mb: '1rem' }}>
      <Typography color='textSecondary'>
        <Link target='_blank' to={`/technicians/${technician.id}`}>
          <b>
            {technician.firstName} {technician.lastName}{' '}
            {technician.contractorIsBusiness ? `(${technician.businessName})` : ''}
          </b>
        </Link>
        <small style={{ fontSize: '12px' }}>
          &nbsp;&#8212;&nbsp;<b>COMMISSION TYPE:</b>
          <Chip sx={{ ml: '.5rem' }} size='small' label={technician.bToBInvoiceCommissionStructure} />
        </small>

        <small>
          {technician.loanBalance > 0
            ? (
              <>
                <br />
                Equipment Loan Balance: <Dollars value={technician.loanBalance} />
              </>
              )
            : (
                ''
              )}
        </small>

        <small>
          {technician.otherLoanBalance > 0
            ? (
              <>
                <br />
                Other Loan Balance: <Dollars value={technician.otherLoanBalance} />
              </>
              )
            : (
                ''
              )}
        </small>
      </Typography>
      <Table size='small'>
        {technician.bToBInvoiceCommissionStructure === 'labor cost'
          ? (
            <ContractorBToBInvoiceTableHeadRow
              unacceptButton
              selectAll={() =>
                allTimeCardsAreSelected ? setSelectedInvoiceIds([]) : setSelectedInvoiceIds(invoices.map(i => i.id))}
              allSelected={allTimeCardsAreSelected}
            />
            )
          : (
            <EmployeeInvoiceHeadRow
              unacceptButton
              selectAll={() =>
                allTimeCardsAreSelected ? setSelectedInvoiceIds([]) : setSelectedInvoiceIds(invoices.map(i => i.id))}
              allSelected={allTimeCardsAreSelected}
            />
            )}
        <TableBody>
          {invoices.map((invoice, index) =>
            technician.bToBInvoiceCommissionStructure === 'labor cost'
              ? (
                <ContractorBToBInvoicePayoutRow
                  invoiceIsSelected={selectedInvoiceIds.includes(invoice.id)}
                  selectInvoice={() =>
                    selectedInvoiceIds.includes(invoice.id)
                      ? setSelectedInvoiceIds(prev => prev.filter(id => id !== invoice.id))
                      : setSelectedInvoiceIds(prev => [...prev, invoice.id])}
                  index={index}
                  key={invoice.id}
                  invoice={invoice}
                  unacceptButton
                  refetch={refetch}
                />
                )
              : (
                <EmployeeInvoicePayoutRow
                  invoiceIsSelected={selectedInvoiceIds.includes(invoice.id)}
                  selectInvoice={() =>
                    selectedInvoiceIds.includes(invoice.id)
                      ? setSelectedInvoiceIds(prev => prev.filter(id => id !== invoice.id))
                      : setSelectedInvoiceIds(prev => [...prev, invoice.id])}
                  index={index}
                  key={invoice.id}
                  invoice={invoice}
                  unacceptButton
                  refetch={refetch}
                />
                )
          )}
          {technician.bToBInvoiceCommissionStructure === 'labor cost'
            ? (
              <ContractorBToBInvoiceSumRow unacceptButton invoices={invoices} />
              )
            : (
              <EmployeeInvoicesSumRow unacceptButton invoices={invoices} />
              )}
        </TableBody>
      </Table>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <Button
          size='small'
          onClick={handleLinkChecked}
          disabled={waiting}
          variant='contained'
          color='primary'
          endIcon={<ArrowRightIcon />}
        >
          Move to Payout
        </Button>

        <Button
          variant='outlined'
          size='small'
          onClick={() => setPayoutDataOpen(!payoutDataOpen)}
          sx={{ marginLeft: '1rem', mr: 'auto', background: 'transparent !important' }}
        >
          view full calculations
        </Button>

        <Box sx={{ background: '#f1f1f1', borderRadius: '6px', padding: '.125rem .5rem', marginTop: '.5rem' }}>
          <span style={{ fontSize: '11px' }}>Payout total:</span>
          <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
            <Dollars value={gustoWageAmount} />
          </Box>
        </Box>
      </Box>

      {payoutDataOpen && (
        <PayoutDataPopover
          payoutDataOpen={payoutDataOpen}
          setPayoutDataOpen={setPayoutDataOpen}
          data={payoutCalculations}
        />
      )}
    </Box>
  )
}

const BToBContractors = () => {
  const [waiting, setWaiting] = useState(false)
  const [registerUpdateRefetch, unregisterUpdateRefetch] = useContext(UpdateRefetchRegistry)
  const { startDatetime, endDatetime } = useContext(ConfigurationContext)
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [requestLoading, setRequestLoading] = useState(false)

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: {
      isAccepted: true,
      hasPayoutId: false
    }
  })

  useEffect(() => {
    data && refetch && refetch()
  }, [])

  useEffect(() => {
    const key = registerUpdateRefetch({ types: ['BToBInvoice'], refetch })
    return () => unregisterUpdateRefetch(key)
  }, [])

  if (error) return <div>Error!</div>

  const bToBInvoices = keysToCamel(data?.bToBInvoices || [])?.filter(invoice => !invoice.technician.isEmployee) || []

  const bToBInvoicesWithPayoutData = bToBInvoices.map(invoice => ({
    ...invoice,
    payoutData: calculateBToBInvoicePayoutData({ bToBInvoice: invoice, technician: invoice.technician })
  }))

  const invoicesGroupedByTechnician = bToBInvoicesWithPayoutData.reduce(
    (acc, invoice) =>
      acc[invoice.technicianId]
        ? { ...acc, [invoice.technicianId]: [...acc[invoice.technicianId], invoice] }
        : { ...acc, [invoice.technicianId]: [invoice] },
    {}
  )

  const handleClickGeneratePayouts = () => {
    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/generate_all_b2b_payouts`, {
      method: 'POST',
      headers: API_COMMAND_HEADERS,
      body: JSON.stringify({
        startDatetime,
        endDatetime,
        contractorsOnly: true
      })
    })
      .then(res => {
        res.ok ? refetch() : alert('Error')
      })

      .finally(() => setWaiting(false))
  }

  return (
    <Box sx={{ pb: '2rem' }}>
      <Snackbar
        onClose={() => setRequestSuccess(false)}
        open={!!requestSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiSnackbarContent-root': { color: '#3f51b5', background: 'aliceblue', border: '1px solid #a1cff7' },
          '& svg': { color: '#3f51b5', mr: '1rem', height: '20px', width: '20px' },
          '& .MuiSnackbarContent-message': { color: '#3f51b5' },
          mb: '3rem'
        }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleOutlineRounded /> {requestSuccess}
          </Box>
        }
      />
      <Snackbar
        onClose={() => setRequestLoading(false)}
        open={requestLoading}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiSnackbarContent-root': { color: '#3f51b5', background: 'aliceblue', border: '1px solid #a1cff7' },
          '& svg': { color: '#3f51b5' },
          '& .MuiSnackbarContent-message': { color: '#3f51b5' },
          mb: '3rem'
        }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress size='1rem' sx={{ mr: '1rem' }} /> Loading...{' '}
          </Box>
        }
      />

      {waiting && (
        <Box
          sx={{
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#11111140',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            zIndex: 100
          }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      )}
      {data && Object.entries(invoicesGroupedByTechnician).length === 0 && (
        <Box
          sx={{
            zIndex: 2,
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#11111180',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            color: '#fff',
            alignItems: 'center',
            justifyContent: 'center',
            backdropFilter: 'blur(2px)',
            '& svg': {
              height: '40px',
              width: '40px',
              mb: '.5rem'
            }
          }}
        >
          <CheckCircleOutlineRounded />
          <Typography variant='body1' sx={{ fontSize: '24px' }}>
            All Contractor B2B Invoices Have Been Added
          </Typography>
        </Box>
      )}

      <Button
        sx={{ zIndex: 100, position: 'fixed', bottom: '1rem', right: '1rem', '& svg': { ml: '.5rem' }, zIndex: 1 }}
        disabled={waiting || Object.entries(invoicesGroupedByTechnician).length === 0}
        variant='contained'
        color='primary'
        onClick={handleClickGeneratePayouts}
      >
        move all to payouts <KeyboardArrowRightRounded />
      </Button>

      <Box sx={{ padding: '.25rem' }}>
        {Object.entries(invoicesGroupedByTechnician).map(group => (
          <PayoutPreview
            setRequestLoading={setRequestLoading}
            setRequestSuccess={setRequestSuccess}
            key={group[0]}
            invoices={group[1]}
            refetch={refetch}
          />
        ))}
        {(loading || Object.entries(invoicesGroupedByTechnician).length === 0) && (
          <>
            {[1, 2, 3, 4, 5, 6, 7].map(i => (
              <Card sx={{ mb: '1rem ' }}>
                <Box sx={{ width: '150px', padding: '.5rem 1rem' }}>
                  <Skeleton
                    sx={{
                      transform: 'scale(1)',
                      minHeight: '32px'
                    }}
                  />
                </Box>
                <Table size='small'>
                  <ContractorBToBInvoiceTableHeadRow unacceptButton />
                  <TableBody>
                    {[1, 2].map(row => (
                      <SkeletonRow key={row} />
                    ))}
                  </TableBody>
                </Table>
              </Card>
            ))}
          </>
        )}
      </Box>
    </Box>
  )
}

export const ContractorBToBInvoiceSumRow = ({ invoices, hideCheckbox, showDiscludeButton, unacceptButton }) => {
  const totalPayoutAmount = invoices.reduce((acc, invoice) => acc + invoice.payoutData?.totalPayout, 0)
  const totalGrandTotal = invoices.reduce((acc, invoice) => acc + invoice.grandTotal || 0, 0)

  return (
    <TableRow sx={{ background: 'aliceblue', borderTop: '2px solid #a1cff7' }}>
      {!hideCheckbox && <TableCell />}
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />

      <TableCell />
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        <Dollars value={totalGrandTotal} />
      </TableCell>
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        <Dollars value={totalPayoutAmount} />
      </TableCell>

      {showDiscludeButton && <TableCell align='right' />}
      {unacceptButton && <TableCell align='right' />}
    </TableRow>
  )
}

export const ContractorBToBInvoiceTableHeadRow = ({
  selectAll,
  allSelected,
  showDiscludeButton,
  hideCheckbox,
  unacceptButton
}) => {
  return (
    <TableHead>
      <TableRow>
        {!hideCheckbox && (
          <TableCell sx={{ maxWidth: '60px', padding: '0rem .5rem' }}>
            <Checkbox
              sx={{
                padding: '0px',
                '& svg': {
                  height: '15px',
                  width: '15px'
                }
              }}
              size='small'
              checked={allSelected}
              onClick={() => selectAll()}
            />
          </TableCell>
        )}
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Completed At
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Created At
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Business
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Vehicle
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          CC fee
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Commission percent
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Grand total
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Total payout
        </TableCell>
        {showDiscludeButton && (
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Actions
          </TableCell>
        )}
        {unacceptButton && <TableCell align='right' />}
      </TableRow>
    </TableHead>
  )
}

const SkeletonRow = () => {
  return (
    <TableRow>
      <TableCell sx={{ maxWidth: '60px', padding: '0rem .5rem' }}>
        <Checkbox
          sx={{
            padding: '0px',
            '& svg': {
              height: '15px',
              width: '15px'
            }
          }}
          size='small'
        />
      </TableCell>
      {[1, 2, 3, 4, 5, 6, 7, 8].map((i, index) => (
        <TableCell key={i}>
          <Skeleton
            sx={{
              minWidth: '100%',
              minHeight: '28px',
              transform: 'scale(1)',
              background: index % 2 !== 0 ? '#0089CD1a' : '#f1f1f1'
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  )
}

export default BToBContractors
