import { Typography, Button, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useState, useEffect, useMemo } from 'react'

import dayjs from 'dayjs'
import UTC from 'dayjs/plugin/utc'

import RangesSelector from './RangesSelector'

import { mergeRanges } from './utils'
import { submitCallback, updateAvailability } from '../utils'
import { AvailabilityHoursType } from '../types'
import { AvailabilityHoursProps } from './types'

dayjs.extend(UTC)
export const daysConst = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] as const
const days = daysConst as unknown as string[]

const defaultAvailabilityHours: AvailabilityHoursType = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
}

const getNextDay = (day: string) => {
  const dayIndex = days.indexOf(day)
  if (dayIndex === -1) return days[0]

  if (dayIndex < days.length - 1) return days[dayIndex + 1]
  return days[0]
}

const AvailabilityHours = ({ user }: AvailabilityHoursProps) => {
  const params: any = useParams()
  const [availabilityHours, setAvailabilityHours] = useState<AvailabilityHoursType>()

  useEffect(() => {
    if (!user) return
    if (!user?.availabilityHours) return setAvailabilityHours(defaultAvailabilityHours)

    const rawAvailabilityHours: AvailabilityHoursType = { ...(user.availabilityHours ?? defaultAvailabilityHours) }
    Object.entries(rawAvailabilityHours).forEach(([day, ranges]) => {
      rawAvailabilityHours[day] = Array.from(new Set(ranges)).filter(range => range)
    })

    setAvailabilityHours({ ...defaultAvailabilityHours, ...user?.availabilityHours })
  }, [user])

  const localAvailabilityHours = useMemo(() => {
    const newLocalAvailabilityHours: AvailabilityHoursType = { ...defaultAvailabilityHours }
    if (!availabilityHours) return newLocalAvailabilityHours

    Object.entries(availabilityHours).forEach(([day, ranges]) => {
      ranges.forEach(range => {
        const [start, end] = range.split('-')
        if (!start || !end) return ''

        const [startInt, endInt] = [parseInt(start), parseInt(end)]
        const utcStart = dayjs.utc().day(days.indexOf(day)).hour(startInt).minute(0)
        const utcEnd = dayjs.utc().day(days.indexOf(day)).hour(endInt).minute(0)

        const localStart = utcStart.local()
        const localEnd = utcEnd.local()

        const localEndHour = localEnd.hour() === 0 ? 24 : localEnd.hour()
        const newRange = `${localStart.hour()}-${localEndHour}`
        const newDay = days[localStart.day()]

        const newDayRanges = [...newLocalAvailabilityHours[newDay], newRange]
        newLocalAvailabilityHours[newDay] = mergeRanges(newDayRanges)
      })
    })

    return newLocalAvailabilityHours
  }, [availabilityHours])

  const onAvailabilityHoursChange = (day: string, newRanges: string[]) => {
    const newLocalAvailabilityHours: AvailabilityHoursType = {
      ...localAvailabilityHours,
      [day]: newRanges,
    }

    const newAvailabilityHours: AvailabilityHoursType = { ...defaultAvailabilityHours }

    Object.entries(newLocalAvailabilityHours)?.forEach(([day, ranges]) => {
      ranges.forEach(range => {
        const [start, end] = range.split('-')
        if (!start || !end) return

        const [startInt, endInt] = [parseInt(start), parseInt(end)]
        const localStart = dayjs().day(days.indexOf(day)).hour(startInt).minute(0)
        const localEnd = dayjs().day(days.indexOf(day)).hour(endInt).minute(0)

        const utcStart = localStart.utc()
        const utcEnd = localEnd.utc()

        const utcEndHour = utcEnd.hour() === 0 ? 24 : utcEnd.hour()
        const newRange = `${utcStart.hour()}-${utcEndHour}`
        const newDay = days[utcStart.day()]

        const newRanges = [...newAvailabilityHours[newDay], newRange]
        newAvailabilityHours[newDay] = newRanges
      })
    })

    setAvailabilityHours(newAvailabilityHours)
  }

  const submitAvailability = () => {
    const apiAvailabilityHours = { ...availabilityHours }
    const futureAdjustments: AvailabilityHoursType = {}

    days.forEach(day => {
      const nextDay = getNextDay(day)
      const nextDayRanges: string[] = []

      const dayRanges = apiAvailabilityHours[day] || []
      const newDayRanges = dayRanges?.map(range => {
        const [start, end] = range?.split('-')?.map(value => parseInt(value))
        if (start < end) return range

        if (end > 0) nextDayRanges.push(`0-${end}`)
        return `${start}-24`
      })

      apiAvailabilityHours[day] = newDayRanges
      futureAdjustments[nextDay] = nextDayRanges
    })

    const finalAvailabilityHours: AvailabilityHoursType = {}

    days.forEach(day => {
      finalAvailabilityHours[day] = [...apiAvailabilityHours[day], ...futureAdjustments[day]]
    })

    const id = user?.id
    // const toId = params?.userId

    if (!id) return
    updateAvailability(finalAvailabilityHours, id, id, submitCallback)
  }

  return (
    <Box>
      <Typography variant='h5' sx={{ padding: '1em' }}>
        Availability Hours:
      </Typography>

      <Box sx={{ padding: '1rem' }}>{JSON.stringify(localAvailabilityHours)}</Box>

      {!!Object.keys(localAvailabilityHours).length &&
        days?.map(day => {
          return (
            <Accordion>
              <AccordionSummary>{day.charAt(0).toUpperCase() + day.slice(1)}</AccordionSummary>
              <AccordionDetails>
                {
                  <RangesSelector
                    day={day}
                    onChange={onAvailabilityHoursChange}
                    initialRanges={localAvailabilityHours[day] ?? []}
                  />
                }
              </AccordionDetails>
            </Accordion>
          )
        })}

      <Button sx={{ my: 2 }} onClick={submitAvailability} variant='contained'>
        Update Availability
      </Button>
    </Box>
  )
}

export default AvailabilityHours
