import React, { useEffect, useState, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { Dollars, keysToCamel } from 'tools'
import { Link } from 'react-router-dom'
import { RefetchRegistry } from 'RefetchRegistry'
import {
  Button,
  SvgIcon,
  DialogTitle,
  DialogActions,
  DialogContent,
  Chip,
  Dialog,
  Grid,
  Box,
  Modal,
  Select,
  MenuItem,
  TablePagination,
} from '@mui/material'
import { FilterList, ArrowUpward, ArrowDownward } from '@mui/icons-material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ShowEdit from './ShowEdit'
import { modalBoxStyle, groupByDayCompletedThenTechnician } from './tools'
import GlobalStore, { GlobalContext } from 'GlobalStore'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { DateTime } from 'luxon'

const QUERY = gql`
  query TimeCardsConnection($search: JSON, $order: JSON, $filter: JSON, $first: Int, $after: String) {
    timeCardsConnection(search: $search, order: $order, filter: $filter, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          timeCardDate
          createdAt
          deletedAt
          acceptedAt
          workHours
          paidTravelHours
          unpaidTravelHours
          jobCount
          payoutData
          totalHours
          technician {
            id
            firstName
            lastName
            name
          }
          timeLogs {
            id
            createdAt
            startedAt
            completedAt
            type
            isPaid
            jobId
            travelDistance
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
const Unaccepted = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  const [modalTimeCardId, setModalTimeCardId] = useState(null)

  const parseState = true
  const [search, setSearch] = useLocalStorage('timeCardSearchPendingReview', {}, parseState)
  const [order, setOrder] = useLocalStorage('timeCardOrderPendingReview', {}, parseState)
  const [filter, setFilter] = useLocalStorage('timeCardFilterPendingReview', {}, parseState)
  const [limit, setLimit] = useLocalStorage('timeCardLimitPendingReview', 50)

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  const removeFilter = filterName => {
    const { [filterName]: removed, ...remainingFilters } = filter
    setFilter(remainingFilters)
  }

  const removeSearch = searchField => {
    const { [searchField]: removed, ...remainingSearches } = search
    setSearch(remainingSearches)
  }

  const setOrToggleOrder = name => {
    const { [name]: removed, ...remainingOrder } = order
    if (order[name] === 'asc') setOrder(remainingOrder)
    else setOrder({ [name]: order[name] ? 'asc' : 'desc', ...remainingOrder })
  }

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter: { ...filter, custom: { hide_accepted: true } },
        first: Number(limit),
        after: '',
      },
    })
  }, [search, order, filter, limit])

  const { loading, error, data, fetchMore, refetch } = useQuery(QUERY, {
    variables: {
      search,
      order,
      filter: { ...filter, custom: { hide_accepted: true } },
      first: Number(limit),
      after: cursorCeiling,
    },
  })

  useEffect(() => {
    const key = registerRefetch({ types: ['TimeCard'], refetch })
    return () => unregisterRefetch(key)
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>{JSON.stringify(error)}</div>

  const timeCards = data?.timeCardsConnection?.edges.map(edge => edge.node)
  const totalCount = data.timeCardsConnection.totalCount
  const endCursor = data.timeCardsConnection.pageInfo.endCursor

  const SelectTechnicianHeadCell = props => {
    const [technicianId, setTechnicianId] = useState((filter.technician_id && filter.technician_id.eq) || '')
    const [global] = useContext(GlobalContext)

    const handleChange = evt => {
      if (evt.target.value === 'clear') {
        removeFilter('technician_id')
      } else {
        setFilter({ ...filter, technician_id: { eq: evt.target.value } })
      }
    }

    const technicians =
      global?.technicians
        ?.slice()
        .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
        .filter(tech => tech.isEmployee && tech.active) || []

    return (
      <TableCell {...props}>
        {!technicianId && <>Technician</>}
        <Select color='primary' size='small' label='Technician' value={technicianId} onChange={handleChange}>
          <MenuItem value='clear'>
            <i>clear</i>
          </MenuItem>
          {technicians.map(technician => (
            <MenuItem key={technician.id} value={technician.id}>
              <>
                {technician.firstName} {technician.lastName}
              </>
            </MenuItem>
          ))}
        </Select>
      </TableCell>
    )
  }

  const DateRangeHeadCell = ({ name }) => {
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [dateRange, setDateRange] = useState(filter[name] || {})
    // ONE of BOTH key of: { ge: iso8601, le: iso8601 } OR null
    // ge -> greater than or equal
    // le -> less than or equal

    const handleDateTimeChanged = evt => {
      setDateRange({ ...dateRange, [evt.target.name]: evt.target.value })
    }

    const handleClickApply = () => {
      setFilter({ ...filter, [name]: dateRange })
    }

    const handleClickClose = () => {
      setDialogIsOpen(false)
    }

    const handleDeleteRangeKey = key => {
      const { [key]: removed, ...rest } = dateRange
      setDateRange(rest)
      setFilter({ ...filter, [name]: rest })
    }

    return (
      <TableCell align='right'>
        {name}

        <Button
          sx={{
            minWidth: '30px',
            padding: '5px 0px',
            margin: '0px 5px',
          }}
          variant={order[name] ? 'contained' : 'text'}
          color='primary'
          disableElevation
          onClick={() => setOrToggleOrder(name)}
        >
          <SvgIcon
            fontSize='small'
            component={(order[name] === 'desc' ? ArrowDownward : ArrowUpward) || ArrowDownward}
          />
        </Button>

        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={() => setDialogIsOpen(true)}>
          <SvgIcon fontSize='small' component={FilterList} />
        </Button>

        {filter[name] && Object.entries(filter[name]).length > 0 && (
          <>
            <br />
            {Object.entries(filter[name])
              .sort((a, b) => (a[0] > b[0] ? 1 : -1))
              .map(filterTuple => (
                <Chip
                  key={filterTuple[0]}
                  variant='outlined'
                  size='small'
                  onDelete={() => handleDeleteRangeKey(filterTuple[0])}
                  label={`
                    ${filterTuple[0] === 'le' ? 'before' : 'after'}
                    ${DateTime.fromISO(filterTuple[1]).toFormat('yy-MM-dd')}
                  `}
                />
              ))}
          </>
        )}

        <Dialog open={dialogIsOpen} onClose={setDialogIsOpen}>
          <DialogTitle>Select Date Range (One Or Both)</DialogTitle>
          <DialogContent>
            <Box>
              <span>
                Start Date<small> (optional)</small>
              </span>
              <input type='datetime-local' name='ge' value={dateRange.ge || ''} onChange={handleDateTimeChanged} />
            </Box>
            <Box>
              <span>
                End Date<small> (optional)</small>
              </span>
              <input type='datetime-local' name='le' value={dateRange.le || ''} onChange={handleDateTimeChanged} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickClose} color='primary'>
              Close
            </Button>
            <Button onClick={handleClickApply} color='primary' autoFocus>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    )
  }

  const handleClickAccept = id =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/accept_time_card`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        time_card_id: id,
      }),
    }).then(res => {
      refetch()
    })

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: Number(limit),
          after: endCursor,
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }} size='small'>
              <TableHead>
                <TableRow>
                  <DateRangeHeadCell name='time_card_date' />
                  <SelectTechnicianHeadCell align='right' />
                  <TableCell align='right'># of jobs</TableCell>
                  <TableCell align='right'>Unpaid travel hours</TableCell>
                  <TableCell align='right'>Paid travel hours</TableCell>
                  <TableCell align='right'>Work hours</TableCell>
                  <TableCell align='right'>Total hours</TableCell>
                  <TableCell align='right'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timeCards.slice(page * limit, page * limit + limit).map(timeCard => (
                  <TableRow key={timeCard.id}>
                    <TableCell align='right'>
                      <button style={{ marginRight: '.5rem' }} onClick={() => setModalTimeCardId(timeCard.id)}>
                        Show
                      </button>
                      <Link to={`/timecards/${timeCard.id}`}>
                        {DateTime.fromISO(timeCard.timeCardDate).toFormat('cccc, DD')}
                      </Link>
                    </TableCell>
                    <TableCell align='right'>
                      <Link target='_blank' to={`/technicians/${timeCard.technician.id}`}>
                        {timeCard.technician.firstName}
                        &nbsp;
                        {timeCard.technician.lastName}
                      </Link>
                    </TableCell>
                    <TableCell align='right'>{timeCard.jobCount}</TableCell>
                    <TableCell align='right'>{timeCard.unpaidTravelHours}</TableCell>
                    <TableCell align='right'>{timeCard.paidTravelHours}</TableCell>

                    <TableCell align='right'>{timeCard.workHours}</TableCell>
                    <TableCell align='right'>{timeCard.totalHours.toFixed(2)}</TableCell>

                    <TableCell align='right'>
                      <button onClick={() => handleClickAccept(timeCard.id)}>Accept</button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component='div'
              count={totalCount}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
      <Modal open={!!modalTimeCardId} onClose={() => setModalTimeCardId(null)}>
        <Box sx={modalBoxStyle}>
          {modalTimeCardId && (
            <ShowEdit
              close={() => setModalTimeCardId(null)}
              refetch={refetch}
              prefetched={keysToCamel(timeCards.find(timeCard => timeCard.id === modalTimeCardId))}
            />
          )}
        </Box>
      </Modal>
    </>
  )
}

export default Unaccepted
