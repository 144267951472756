import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Button, TextField, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers'
import { Box } from '@mui/system'

import { DaysOffProps } from './types'
import { submitCallback, updateDaysOff } from '../utils'

const DaysOff = ({ user }: DaysOffProps) => {
  const [daysOff, setDaysOff] = useState<string[]>([])
  const params: any = useParams()

  const addNewDate = () => {
    const date = dayjs().toISOString()
    const newDaysOff = [...daysOff, date]
    setDaysOff(newDaysOff)
  }

  const onDelete = (index: number) => {
    return () => {
      const newDaysOff = [...daysOff]
      newDaysOff.splice(index, 1)

      setDaysOff(newDaysOff)
    }
  }

  const handleChange = (index: number) => {
    return (date?: Dayjs | null) => {
      if (!date) return
      if (!dayjs(date)?.isValid()) return

      const newDaysOff = [...daysOff]
      newDaysOff[index] = date.toISOString()
      setDaysOff(newDaysOff)
    }
  }

  const submitDaysOff = () => {
    const id = user?.id
    const toId = params?.userId

    if (!id || !toId) return
    updateDaysOff(daysOff, toId, id, submitCallback)
  }

  useEffect(() => {
    if (user?.daysOff) setDaysOff(user?.daysOff)
  }, [user])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography variant='h5' sx={{ padding: '1em' }}>
        Days Off:
      </Typography>

      <Box>
        {daysOff.map((day, index) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <DatePicker
                disablePast
                inputFormat='MM/DD/YYYY'
                value={dayjs(day)}
                onChange={handleChange(index)}
                renderInput={params => <TextField {...params} />}
              />

              <Button sx={{ ml: 2 }} variant='contained' color='warning' onClick={onDelete(index)}>
                X
              </Button>
            </Box>
          )
        })}

        <Box sx={{ display: 'flex' }}>
          <Button onClick={addNewDate} variant='contained'>
            Add Day Off
          </Button>

          <Button sx={{ ml: 2 }} onClick={submitDaysOff} variant='contained'>
            Submit
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  )
}

export default DaysOff
