import { Switch, Route, useRouteMatch } from 'react-router-dom'
import InterviewView from './InterviewView'

const InterviewRouter = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:interviewId`}>
        <InterviewView />
      </Route>
    </Switch>
  )
}

export default InterviewRouter
