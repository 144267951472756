import { useQuery, gql } from '@apollo/client'

const QUERY = gql`
  query commissionChargeBacks($technicianId: ID, $unclaimed: Boolean) {
    commissionChargeBacks(technicianId: $technicianId, unclaimed: $unclaimed) {
      id
      userId
      createdAt
      amount
      payoutId
      note
      deletedAt
      technicianId
      quote {
        id
        assignedTechnicianId
        lead {
          name
        }
      }
    }
  }
`

export const useTechnicianChargeBacks = ({ technicianId }) => {
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { technicianId: technicianId, unclaimed: true },
  })

  return { loading, error, data, commissionChargebacks: data?.commissionChargeBacks || [], refetch }
}
