import { useState, useEffect } from 'react'
import {
  Grid, List, ListItem
} from '@mui/material'

const Jitsi = () => {
  const [confNumber, setConfNumber] = useState(null)
  const [frameHeight, setFrameHight] = useState(window.innerHeight - 85)

  const updateFrameHeight = () => {
    setFrameHight(window.innerHeight - 85)
  }

  useEffect(() => {
    window.addEventListener('resize', updateFrameHeight)
    return () => window.removeEventListener('resize', updateFrameHeight)
  }, [])

  return (
    <Grid container>
      <Grid item xs={2}>
        <List>
          <ListItem style={{ cursor: 'pointer' }} onClick={() => setConfNumber(null)}>
            {confNumber === null && '>'} none
          </ListItem>
          <ListItem style={{ cursor: 'pointer' }} onClick={() => setConfNumber('shootTheShit')}>
            {confNumber === 'shootTheShit' && '>'} ShootTheShit
          </ListItem>
          <ListItem style={{ cursor: 'pointer' }} onClick={() => setConfNumber(1)}>
            {confNumber === 1 && '>'} Conference 1
          </ListItem>
          <ListItem style={{ cursor: 'pointer' }} onClick={() => setConfNumber(2)}>
            {confNumber === 2 && '>'} Conference 2
          </ListItem>
          <ListItem style={{ cursor: 'pointer' }} onClick={() => setConfNumber(3)}>
            {confNumber === 3 && '>'} Conference 3
          </ListItem>
                            </List>

        <small>
          JITSI KEYBOARD SHORTCUTS:
          (you have to have the jitsi frame focused ((click onto it)))
          <br />
          <br />

          F - Show or hide video thumbnails
          <br />
          M - Mute or unmute your microphone
          <br />
          V - Start or stop your camera
          <br />
          A - Manage video quality
          <br />
          C - Open or close the chat
          <br />
          D - Switch between camera and screen sharing
          <br />
          P - Show or hide the participants pane
          <br />
          R - Raise or lower your hand
          <br />
          S - View or exit full screen
          <br />
          W - Toggle tile view
          <br />
          ? - Show or hide keyboard shortcuts
          <br />
          SPACE - Push to talk
          <br />
          T - Show speaker stats
          <br />
          0 - Focus on your video
          <br />
          1-9 - Focus on another person's video
        </small>
      </Grid>

      <Grid item xs={10}>
        {confNumber &&
          <iframe
            style={{ width: '100%', height: frameHeight }}
            src={`https://meet.jit.si/carbodylab-conf-${confNumber}`}
            allow='microphone; camera; fullscreen; display-capture; autoplay'
          />}

        {!confNumber &&
          'Select a conference #'}
      </Grid>
    </Grid>
  )
}

export default Jitsi
