import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Quote from './Quote'

const Router = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:quoteId`}>
        <Quote />
      </Route>
      {/* <Route path={`${path}/`}> */}
      {/*   <Index /> */}
      {/* </Route> */}
    </Switch>
  )
}

export default Router
