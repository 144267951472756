import React, { useEffect, useState, useContext } from 'react'
import { UserContext } from 'UserStore'
import { GlobalContext } from 'GlobalStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import taskTypesToTitles from 'data/taskTypesToTitles'
import {
  TextField,
  Button,
  Modal,
  FormControlLabel,
  Grid,
  Dialog,
  Switch,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import manualTaskTypes from 'data/manualTaskTypes'
import { DateTime } from 'luxon'
import { TaskTargetRegistry } from 'TaskTargetRegistry'

const modalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

const claimTaskId = ({ bearerTokenHeaders, userId, taskId }) =>
  fetch(`${process.env.REACT_APP_COMMAND_ROOT}/claim_task`, {
    method: 'POST',
    headers: bearerTokenHeaders,
    body: JSON.stringify({
      userId,
      taskId,
    }),
  }).then(res => res.ok || window.alert('Error'))

const manuallyComplete = ({ bearerTokenHeaders, userId, taskId }) =>
  fetch(`${process.env.REACT_APP_COMMAND_ROOT}/manually_complete_task`, {
    method: 'POST',
    headers: bearerTokenHeaders,
    body: JSON.stringify({
      userId,
      taskId,
    }),
  }).then(res => res.ok || alert('error'))

const Tasks = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const { tasks } = useContext(TaskTargetRegistry)

  const userName = id => {
    const user = global.users.find(user => user.id === id)
    return `${user.firstName} ${user.lastName}`
  }

  const tasksToShow = tasks.filter(
    task => (task.exclusiveUserId && task.exclusiveUserId === user.id) || !task.exclusiveUserId
  )

  return (
    <Box sx={{ backgroundColor: '#a8ffd6', position: 'sticky', top: 80, zIndex: 1 }}>
      {tasksToShow.length > 0 &&
        tasksToShow.map(task => (
          <div key={task.id} style={{ margin: '0.5em' }}>
            <b>{task.title || taskTypesToTitles[task.type]}</b>
            &nbsp;|&nbsp;
            {(task.exclusiveUserId && 'Exclusive Task') ||
              (task.claimedByUserId && (
                <b style={{ color: task.claimedByUserId === user.id ? 'green' : 'red' }}>
                  Claimed by {task.claimedByUserId === user.id ? 'YOU' : userName(task.claimedByUserId)}
                </b>
              )) || <b>UNCLAIMED</b>}
            &nbsp;
            {!task.exclusiveUserId && (
              <button
                disabled={task.claimedByUserId}
                onClick={() => claimTaskId({ bearerTokenHeaders, userId: user.id, taskId: task.id })}
              >
                claim
              </button>
            )}
            &nbsp;|&nbsp;
            {(task.completedAt && <b>COMPLETE</b>) || <b style={{ color: 'red' }}>NOT Complete</b>}
            &nbsp;
            <button
              disabled={task.completedAt || task.activityCompleteOnly}
              onClick={() => manuallyComplete({ bearerTokenHeaders, userId: user.id, taskId: task.id })}
            >
              {task.activityCompleteOnly ? 'automatic complete only' : 'complete'}
            </button>
            &nbsp;
            {task.type === 'Task::Types::Manual' && task.assignedToUserId && (
              <b style={{ fontSize: '12px' }}>(Assigned to: {userName(task.assignedToUserId)})</b>
            )}
          </div>
        ))}
    </Box>
  )
}

export const CreateATask = ({ targetType, targetId }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false)
  const defaultType = 'default'
  const defaultOtherTitle = ''
  const [global] = useContext(GlobalContext)

  const [user] = useContext(UserContext)
  const [type, setType] = useState(defaultType)
  const [otherTitle, setOtherTitle] = useState(defaultOtherTitle)
  const [taskDate, setTaskDate] = useState(DateTime.now().toISO().slice(0, 10))
  const [taskTime, setTaskTime] = useState('00:00')
  const [isExclusiveTask, setIsExclusiveTask] = useState(false)

  const [assignedUserId, setAssignedUserId] = useState(user.id)

  const { users } = global
  const activeUsers = users
    .filter(u => u.status === 'active')
    .slice()
    .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))

  const availableTaskTypes = (() => {
    const match = manualTaskTypes.find(([_type]) => _type === targetType)
    return (
      (match && match[1]) || [
        ['default', 'select'],
        ['Other', 'Other'],
      ]
    )
  })()

  const titleFromSelect = availableTaskTypes.find(([_type, _title]) => type === _type)[1]
  const minimumOtherTitleOrEmpty = (otherTitle.length > 2 && otherTitle) || ' '

  const finalTitle = otherTitle

  const resetInputs = () => {
    setType(defaultType)
    setOtherTitle(defaultOtherTitle)
  }

  const handleCreateManualTask = () => {
    let fetchBody = {
      userId: user.id,
      assignedToUserId: assignedUserId,
      targetType,
      targetId,
      type,
      title: finalTitle,
      greenAtAt: DateTime.fromFormat(taskDate + taskTime, 'yyyy-MM-ddhh:mm', { zone: 'utc' }).toISO(),
    }

    if (isExclusiveTask) {
      fetchBody.exclusiveUserId = user.id
    }

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_manual_task`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify(fetchBody),
    }).then(res => {
      if (res.ok) {
        resetInputs()
        setShowCreateTaskModal(false)
      } else {
        alert('error')
      }
    })
  }

  const allowCreate = finalTitle.length > 0

  return (
    <>
      <Button size='small' variant='outlined' onClick={() => setShowCreateTaskModal(true)}>
        {`Create Task for this ${targetType}`}
      </Button>

      <Dialog
        PaperProps={{ sx: { minWidth: '600px' } }}
        open={showCreateTaskModal}
        onClose={() => setShowCreateTaskModal(false)}
      >
        <Box>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ padding: '1rem', background: 'aliceblue', borderBottom: '2px solid #a1cff7', fontWeight: 600 }}
            >
              Create new task
            </Grid>

            <Grid item xs={12} sx={{ padding: '.5rem' }}>
              <TextField
                fullWidth
                placeholder='Short Title'
                size='small'
                value={otherTitle}
                onChange={e => setOtherTitle(e.target.value)}
              />
            </Grid>

            <Grid item xs={6} sx={{ padding: '.5rem' }}>
              <TextField
                fullWidth
                size='small'
                label='date'
                type='date'
                value={taskDate}
                onChange={e => setTaskDate(e.target.value)}
              />
            </Grid>

            <Grid item xs={6} sx={{ padding: '.5rem' }}>
              <TextField
                fullWidth
                size='small'
                label='time (optional)'
                type='time'
                value={taskTime}
                onChange={e => setTaskTime(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sx={{ padding: '.5rem' }}>
              <FormControl fullWidth size='small'>
                <InputLabel>Task Assignee</InputLabel>
                <Select label='task assignee' value={assignedUserId} onChange={e => setAssignedUserId(e.target.value)}>
                  {activeUsers.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.firstName}&nbsp;{item.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {assignedUserId === user.id && (
              <Grid item xs={12} sx={{ padding: '.5rem' }}>
                <FormControlLabel
                  control={<Switch checked={isExclusiveTask} onChange={e => setIsExclusiveTask(!isExclusiveTask)} />}
                  label='exclusive for me only (this means others wont see this task)'
                />
              </Grid>
            )}
          </Grid>

          <Box sx={{ display: 'flex', padding: '1rem', borderTop: '2px solid #a1cff7' }}>
            <Button onClick={() => setShowCreateTaskModal(true)}>cancel</Button>
            <Button
              sx={{ ml: 'auto' }}
              size='small'
              disabled={!allowCreate}
              variant='outlined'
              onClick={handleCreateManualTask}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default Tasks
