export default
[
  {
    "id":"d106d1a0-8835-434b-a7f1-59d4dadac6ec",
    "name":"Bumper (Front)"
  },
  {
    "id":"18feae7a-2459-4fd8-b3f1-7cd8763f7c6e",
    "name":"Bumper (Rear)"
  },
  {
    "id":"eeb91583-7bd4-4e82-853a-40cdfbf36fd9",
    "name":"Bumper Absorber (Front)"
  },
  {
    "id":"00a5ce18-416d-4426-958e-f4dbdcc701a0",
    "name":"Bumper Absorber (Rear)"
  },
  {
    "id":"856b4769-2454-46a1-a7f6-f157f20033fa",
    "name":"Bumper Bracket (Front - Driver Side)"
  },
  {
    "id":"113cacfd-85b0-465a-b418-6d66a47194bd",
    "name":"Bumper Bracket (Front - Passenger Side)"
  },
  {
    "id":"ebe5e403-1cf8-48ba-8768-8d9c8d02506f",
    "name":"Bumper Bracket (Rear - Driver Side)"
  },
  {
    "id":"c8d89fe6-360d-49f6-96a7-0918ec3df0ea",
    "name":"Bumper Bracket (Rear - Passenger Side)"
  },
  {
    "id":"cf2ef331-1f86-4627-8c53-d778282e55a3",
    "name":"Bumper Clips"
  },
  {
    "id":"5edd0cc3-3517-41e2-b9dd-3e1012d577a0",
    "name":"Bumper Molding (Front)"
  },
  {
    "id":"8d290de8-700a-4f95-add4-f6c0fad8a04e",
    "name":"Bumper Molding (Rear)"
  },
  {
    "id":"59487196-d349-4fc2-b0f2-40f4d758b672",
    "name":"Door Molding ((Front – Passenger Side)"
  },
  {
    "id":"35c9212b-0cd6-4de6-a196-43d9aa5a4155",
    "name":"Door Molding (Front – Driver Side)"
  },
  {
    "id":"e3d4e447-07b0-4581-a1d7-488a43133b21",
    "name":"Door Molding (Rear – Driver Side)"
  },
  {
    "id":"6c3c9368-98fc-49f0-81f1-49fc7797a2a9",
    "name":"Door Molding (Rear – Passenger Side)"
  },
  {
    "id":"acbb713f-6ac8-4165-adc7-64529ad6326a",
    "name":"Fender (Driver Side)"
  },
  {
    "id":"b5245f89-b01a-457a-bace-6673609ea3bd",
    "name":"Fender (Passenger Side)"
  },
  {
    "id":"b31011f7-eb1d-4467-9101-1c7ec32ff750",
    "name":"Fender Flare (Driver Side)"
  },
  {
    "id":"5578cc99-b906-4f72-85dc-e24d210ecf1c",
    "name":"Fender Liner (Passenger Side)"
  },
  {
    "id":"0d786aa3-2efe-45e9-ae5c-b7a402be4079",
    "name":"Fog Light (Driver Side)"
  },
  {
    "id":"17aec7c2-609f-4dbe-b352-79abde740d1e",
    "name":"Fog Light Cover (Driver Side)"
  },
  {
    "id":"7fe82637-83f2-414c-8e52-15cba5e69225",
    "name":"Fog Light Cover (Passenger Side)"
  },
  {
    "id":"a804cb71-ddab-4627-976d-02ce249df6f8",
    "name":"Grille (Front – Lower)"
  },
  {
    "id":"7499b732-615a-45f8-98f7-22d5eb75d064",
    "name":"Grille (Front – Upper)"
  },
  {
    "id":"bff81a07-4ce1-4201-a790-3f545c1d0d53",
    "name":"Headlamp (Driver Side)"
  },
  {
    "id":"764535da-dd5f-4d63-b208-51f2a337f7d0",
    "name":"Headlamp (Passenger Side)"
  },
  {
    "id":"29c7b2eb-ca3f-48b2-9cd2-f88ab2a10b89",
    "name":"Hood"
  },
  {
    "id":"16fb3c4a-085e-4a00-91ce-4931aa8e0c93",
    "name":"Mirror (Driver Side)"
  },
  {
    "id":"c8138560-56f0-4c47-9b66-672e4fadede3",
    "name":"Parking Sensors (Front)"
  },
  {
    "id":"0248eb1a-c21b-4bc0-8c69-beff5aa80438",
    "name":"Parking Sensors (Rear)"
  },
  {
    "id":"5b391281-98bc-4b1b-9da4-ead502ad89b1",
    "name":"Quarter Panel Flare (Driver Side)"
  },
  {
    "id":"34eebf28-d7ed-466b-a44e-6835effe4636",
    "name":"Quarter Panel Flare (Passenger Side)"
  },
  {
    "id":"d3b2b4c6-c3cc-4d88-903c-01c571513cb4",
    "name":"Reflector (Front – Driver Side)"
  },
  {
    "id":"f170af76-4264-4508-b4ed-187cc0364fbf",
    "name":"Reflector (Front – Passenger Side)"
  },
  {
    "id":"52c81157-e6ae-42dd-aaa2-73a36081c166",
    "name":"Rocker Panel (Driver Side)"
  },
  {
    "id":"440fcacf-698c-458b-ae5e-0008cf1afa67",
    "name":"Rocker Panel (Passenger Side)"
  },
  {
    "id":"0f011a1c-23c4-43cd-87cc-1636232b1b56",
    "name":"Splash Shield"
  },
  {
    "id":"d1ffa25b-211f-4236-827e-417cefa124cd",
    "name":"Spoiler (Front - Lower)"
  },
  {
    "id":"9507b83f-4276-49f8-82dd-44c29b847ec5",
    "name":"Spoiler (Rear Lower)"
  },
  {
    "id":"af995776-9124-46cb-bbe3-2ac16e36ce31",
    "name":"Taillamp (Inner - Driver Side)"
  },
  {
    "id":"274df3fa-5082-4f0b-8125-d7051d038603",
    "name":"Taillamp (Outer - Driver Side)"
  },
  {
    "id":"0b350072-af48-4c04-803c-b09009a4c04b",
    "name":"Taillamp (Outer Passenger Side)"
  },
  {
    "id":"6a899bf4-0394-4c20-94d2-b28691a1aa1b",
    "name":"Tow Hook Cover"
  },
  {
    "id":"fd65cb83-e462-4978-b6f4-469ada492537",
    "name":"Marker (Rear - Driver Side)"
  },
  {
    "id":"e6421da0-8b43-4bf0-9db5-05393bb03319",
    "name":"Fender Liner (Clips)"
  },
  {
    "id":"1aa67d5f-966a-4a07-a580-0bec12adbc76",
    "name":"Gas Cap"
  },
  {
    "id":"c2489eb6-0e98-474e-9b3b-d1adc9716504",
    "name":"Bumper Clips v2"
  },
  {
    "id":"019cb6af-95e4-439f-908d-f6cd4e3624fb",
    "name":"Bumper Clips v3"
  },
  {
    "id":"612dfb74-2029-4bde-81d1-e34e849fb1de",
    "name":"Emblem"
  },
  {
    "id":"9932fad7-728a-484d-850c-a29d913b48e3",
    "name":"Cover"
  },
  {
    "id":"dfd641f6-70d4-4452-8127-7b756b0b62df",
    "name":"Clips"
  },
  {
    "id":"11963547-e9c5-45a5-9b7e-5d9545f9d1bd",
    "name":"Clips v2"
  },
  {
    "id":"b386dcab-a604-4112-b4c5-8da9d73ae0c4",
    "name":"Clips v3"
  },
  {
    "id":"a8c5c8e3-27a5-4332-ba61-c16df69b5179",
    "name":"Clips v4"
  },
  {
    "id":"36c6a8b1-bde4-4a19-872a-a0ac1120c828",
    "name":"Rear Bumper Pad"
  },
  {
    "id":"9afefbc7-a99f-4721-a565-1558d6268348",
    "name":"Door Handle Cap"
  },
  {
    "id":"93614785-2872-49e3-8f99-aac3ad449048",
    "name":"Mirror (Passenger Side)"
  },
  {
    "id":"0b1b26f0-aacc-485e-a8de-cd828824d39e",
    "name":"Door Handle Front (Driver Side)"
  },
  {
    "id":"46d702d0-ea4b-4e85-bd01-7da729f2bc8c",
    "name":"Door Handle Front (Passenger Side)"
  },
  {
    "id":"f32cb996-1848-4d4f-872c-024c3e66fa74",
    "name":"Door Handle Rear (Passenger Side)"
  },
  {
    "id":"a62f2bc5-e573-42fe-a449-4203cd2c0429",
    "name":"Door Handle Rear (Driver Side)"
  },
  {
    "id":"a148beaa-8f6b-4e24-a4a5-82035fbf0b33",
    "name":"Fender Liner (Driver Side)"
  },
  {
    "id":"6c2c9ec0-58ae-4672-b4e0-086a2e22346f",
    "name":"Fender Flare (Passenger Side)"
  },
  {
    "id":"55a26a29-003b-49a5-bd01-475f0c9e72f5",
    "name":"Name Plate"
  },
  {
    "id":"1bdfe6bf-21f3-4f3c-8d47-a9276a2dd22b",
    "name":"Reflector Rear (Rear - Passenger Side)"
  },
  {
    "id":"6643e581-2e2a-4a24-b1ea-b8383cebe508",
    "name":"Reflector Rear (Rear - Driver Side)"
  },
  {
    "id":"86370449-5ab3-46d9-a2cc-534951a775a2",
    "name":"Fog Light ( Passenger Side)"
  },
  {
    "id":"9b3b0f58-5488-48ec-a596-7099ff18fec1",
    "name":"Taillamp (Inner - Passenger Side)"
  },
  {
    "id":"ea377434-3d45-4750-8571-74080147236f",
    "name":"Upper Bumper (Front)"
  },
  {
    "id":"771f87ed-7f62-4422-ab4d-4f13d8aebc39",
    "name":"Lower Bumper (Front)"
  },
  {
    "id":"dfac406d-2769-4e36-abe0-fe137e2880d6",
    "name":"Upper Bumper (Rear)"
  },
  {
    "id":"9fc5e008-7760-4f2e-9d18-ed48ca64b012",
    "name":"Lower Bumper (Rear)"
  },
  {
    "id":"e80ce2cd-bbb2-4a68-a6bf-71f3bbf70f68",
    "name":"Marker (Front – Passenger Side)"
  },
  {
    "id":"f11e2251-c775-40ee-be6e-abf415d02401",
    "name":"Marker (Front – Driver Side)"
  },
  {
    "id":"f7b91f93-a584-4923-bc50-b9394f24802e",
    "name":"Marker (Rear - Passenger Side)"
  },
  {
    "id":"f05b31e1-fc94-409b-9a70-158b5e162141",
    "name":"Step Pad (Rear)"
  },
  {
    "id":"7cd9866c-012d-4eef-a2d7-00ff1de18341",
    "name":"Licence Plate Frame (Front)"
  },
  {
    "id":"4cfa7a46-eaa6-4776-8e9d-82a41312b753",
    "name":"Licence Plate Frame (Rear)"
  },
  {
    "id":"d4eaa4b8-6d2e-46e6-9c2f-f02934023d65",
    "name":"Tire(s)"
  },
  {
    "id":"b3e84ce8-def3-4b5e-bab4-cdd747cf19ed",
    "name":"Windshield"
  },
  {
    "id":"e476e60e-47f6-4f61-bec6-b0eb0f2869c5",
    "name":"Battery"
  },
  {
    "id":"a6f2777b-dc2f-46ef-a7c6-cef5ea7c8ee5",
    "name":"Brake Pads"
  }
]
