export default
{
  'Task::Types::InitialQuoteAndReply': 'Initial Quote',
  'Task::Types::SetMissingCostOfOrderedPart': 'Set missing Part cost',
  'Task::Types::SetMissingEtaOfPart': 'Set missing Part ETA',
  'Task::Types::CsrWantsToKnowEtaOfPart': 'CSR requested Part ETA',
  'Task::Types::CsrReceivedEtaOfPart': 'Check Part ETA received',
  'Task::Types::TechnicianCallback': 'Technician Callback',
  'Task::Types::PartsCallback': 'Parts Callback',
  'Task::Types::CarbodylabCustomerCallback': 'Carbodylab Customer Callback',
  'Task::Types::DrivewayCustomerCallback': 'Driveway Customer Callback',
  'Task::Types::BToBCallback': 'B-to-B Callback',
  'Task::Types::CarpartsCallback': 'Carparts Callback',
  'Task::Types::ReadReplyTechSms': 'Read/Reply Technician Text',
  'Task::Types::ReadChatMention': 'Read Mention',
  'Task::Types::UpcomingJobNeedsPartEta': 'Upcoming Job Needs Part ETA',
  'Task::Types::LookIntoReschedulingJobDueToPartsDelay': 'Parts Delay: Look Into Rescheduling Job',
  'Task::Types::OrderPartNowToArriveOnTimeForJob': 'Upcoming Job Needs Part Ordered Now',
  'Task::Types::CheckIfOrderedPartArrived': 'Check If Part Arrived',
  'Task::Types::RemindTechToReturnPartToVendor': 'Remind Tech to Return Part to Vendor',
  'Task::Types::CheckIfRefundWasReceivedOnReturnedPart': 'Check if Refund was Received on Returned Part',
  'Task::Types::InitiatePartCoreRefund': 'Initiate Part Core Refund',
  'Task::Types::RemindTechToReturnPartCoreToVendor': 'Remind Tech to Return Part Core to Vendor',
  'Task::Types::CheckIfRefundWasReceivedOnReturnedCore': 'Check if Part Core Refund was Received',
  'Task::Types::CheckIfRefundWasReceivedOnPart': 'Check if Part Refund was Receieved',
  'Task::Types::InitiateReturnOfPartInPossessionOfTechButRemovedFromQuote': 'Initiate Part Return',
  'Task::Types::InitiateRefundOfPartRemovedFromQuote': 'Initiate Part Refund'
}
