import { useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Link, useParams, useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { DateTime } from 'luxon'
import { Chip } from '@mui/material'
import { ErrorRounded } from '@mui/icons-material'

const GET_JOBS = gql`
  query getJobs($quoteId: ID!, $includeCanceled: Boolean) {
    jobs(quoteId: $quoteId, includeCanceled: $includeCanceled) {
      id
      technicianId
      startDatetime
      endDatetime
      canceledAt
      didntGo
      leadId
      heldForReschedulingAt
      isActive
      rescheduleHistory
    }
  }
`

const GET_TECHNICIAN = gql`
  query getTechnician($id: ID!) {
    technician(id: $id) {
      id
      name
    }
  }
`

const useStyles = makeStyles({
  jobCard: {
    padding: '0.7em',
    border: '1px solid #83cfff',
    borderRadius: '3px',
    margin: '0.5em',
    backgroundColor: 'ghostwhite',
  },
})

const Jobs = ({ quote, hideCreateJob }) => {
  let history = useHistory()

  const handleClickCreateJob = () => {
    history.push(`/leads/${quote.leadId}/quotes/${quote.id}/jobs/new`)
  }

  const { loading, error, data, refetch } = useQuery(GET_JOBS, {
    variables: {
      quoteId: quote.id,
      includeCanceled: true,
    },
  })

  // useEffect(() => {
  //   data && refetch && refetch()
  // }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const hasAnyHeldJobs = data.jobs.find(job => job.heldForReschedulingAt && !job.canceledAt)

  const buttonDisabled =
    (quote.customerApprovalRequired && !quote.customerMarkedApprovedAt) ||
    (quote.customerApprovalRequired === null && quote?.market?.quoteApprovalRequired && !quote.customerMarkedApprovedAt)

  return (
    <>
      {!hideCreateJob && (
        <>
          <Button
            cy-data='create-job-button'
            disabled={hasAnyHeldJobs || !!quote.deletedAt || buttonDisabled}
            variant='outlined'
            size='small'
            color='primary'
            onClick={handleClickCreateJob}
          >
            Create Job{hasAnyHeldJobs && ' (must reschedule existing held job)'}
          </Button>
          <br />

          {buttonDisabled && (
            <Chip
              icon={<ErrorRounded />}
              size='small'
              color='error'
              variant='outlined'
              sx={{
                fontSize: '14px',
                textAlign: 'center',
                mt: '.5rem',
              }}
              label={'Not approved yet by the customer'}
            />
          )}
        </>
      )}
      <ul>
        {data.jobs.map(job => (
          <JobLi key={job.id} job={job} />
        ))}
      </ul>
    </>
  )
}

const JobLi = ({ job }) => {
  const { leadId, quoteId } = useParams()

  const { loading, error, data } = useQuery(GET_TECHNICIAN, {
    variables: { id: job.technicianId },
  })
  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  let startDatetime = DateTime.fromISO(job.startDatetime, { zone: 'utc' })
  let endDatetime = DateTime.fromISO(job.endDatetime, { zone: 'utc' })

  return (
    <li>
      <Link to={`/technicians/${data.technician.id}`}>{data.technician.name}</Link>
      <span style={{ padding: '0 5px' }}>-&gt;</span>
      <Link to={`/leads/${job.leadId}/quotes/${quoteId}/jobs/${job.id}`}>
        {startDatetime.toFormat('cccc, yyyy LLL dd')}, {startDatetime.toFormat('h:mm a')} -{' '}
        {endDatetime.toFormat('h:mm a')}
      </Link>
      {job.canceledAt && <b style={{ color: 'crimson', marginLeft: '1em' }}>CANCELED</b>}
      {job.heldForReschedulingAt && <b style={{ color: 'crimson', marginLeft: '1em' }}>HELD FOR RESCHEDULING</b>}
      {job.rescheduleHistory && job.rescheduleHistory.length > 0 && (
        <b style={{ fontSize: '14px', color: 'crimson', marginLeft: '1em' }}>
          RESCHEDULED ({job.rescheduleHistory.length})
        </b>
      )}
      {job.didntGo && <b style={{ color: 'crimson', marginLeft: '1em' }}>DIDN'T GO</b>}
    </li>
  )
}

export default Jobs
