import { FormControl, InputLabel, MenuItem, Select, TableCell } from '@mui/material'
import { labelsList, removeFilter } from 'BToB/InvoiceStatements/tools'

export const SelectLabelHeadCell = ({ filter, setFilter }) => {
  const handleChange = evt => {
    if (evt.target.value === 'clear') {
      removeFilter({ name: 'label', filter, setFilter })
    } else {
      setFilter({ ...filter, label: { eq: evt.target.value } })
    }
  }

  const label = filter?.label?.eq
  const labels = labelsList

  return (
    <TableCell align='right'>
      <FormControl fullWidth size='small'>
        <InputLabel>Label</InputLabel>
        <Select color='primary' size='small' label='Label' value={label} onChange={handleChange}>
          <MenuItem value='clear'>
            <i>All</i>
          </MenuItem>
          {labels.map(business => (
            <MenuItem key={business} value={business}>
              <>{business}</>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TableCell>
  )
}
