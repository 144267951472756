import { styled } from '@mui/material/styles'

const PREFIX = 'DialogSubheader'
const classes = {
  root: `${PREFIX}-root`
}
const DialogSubheader = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    color: 'black',
    padding: theme.spacing(2),
    fontSize: '1.125rem',
    fontWeight: '600'
  }
}))

export { classes, DialogSubheader }
