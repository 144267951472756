import { DateTime } from 'luxon'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material'
import { Dollars } from 'tools'
import { GlobalContext } from 'GlobalStore'
import { useContext } from 'react'

const PayrollHistoryTable = ({ quote }) => {
  const payrollHistory = quote.payrollHistory
  const [global] = useContext(GlobalContext)

  const techById = techId => global.technicians.find(tech => tech.id === techId)
  const techName = techId => {
    const tech = techById(techId)
    return tech && `${tech.firstName} ${tech.lastName[0]}.`
  }

  const payouts = payrollHistory.filter(item => item.type === 'payout')
  const chargebacks = payrollHistory.filter(item => item.type === 'chargeback')

  return (
    <>
      <Box>
        <b>Payouts</b>
      </Box>
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='right'>timestamp</TableCell>
              <TableCell align='right'>technician</TableCell>
              <TableCell align='right'>commission paid</TableCell>
              <TableCell align='right'>tip paid</TableCell>
              <TableCell align='right'>total paid</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payouts
              .slice()
              .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
              .map(item => (
                <TableRow key={item.id}>
                  <TableCell align='right'>{DateTime.fromISO(item.timestamp).toFormat('DD t')}</TableCell>
                  <TableCell align='right'>{techName(item.technicianId)}</TableCell>
                  <TableCell align='right'>
                    <Dollars value={item.commissionPaid || 0} />
                  </TableCell>
                  <TableCell align='right'>
                    <Dollars value={item.tipPaid || 0} />
                  </TableCell>
                  <TableCell align='right'>
                    <Dollars value={Number(item.tipPaid || 0) + Number(item.commissionPaid || 0) || 0} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box>
        <b>Chargebacks</b>
      </Box>

      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='right'>timestamp</TableCell>
              <TableCell align='right'>technician</TableCell>
              <TableCell align='right'>chargeback amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chargebacks
              .slice()
              .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
              .map(item => (
                <TableRow key={item.id} sx={{ '& .MuiTableCell-root ': { color: 'red' } }}>
                  <TableCell align='right'>{DateTime.fromISO(item.timestamp).toFormat('DD t')}</TableCell>
                  <TableCell align='right'>{techName(item.technicianId)}</TableCell>
                  <TableCell align='right'>
                    <Dollars value={item.chargeBackAmount || 0} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default PayrollHistoryTable
