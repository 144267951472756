import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { keysToCamel } from '../../../tools'
import ShowEdit from './ShowEdit'
import ShowEditV1 from './ShowEditV1'

const QUERY = gql`
  query bToBInvoice($id: ID!) {
    bToBInvoice(id: $id) {
      id
      createdAt
      acceptedAt
      businessName
      lineItems
      lineItemsSum
      grandTotal
      discountPercent
      discountPercentTotal
      preJobPhotos
      postJobPhotos
      year
      make
      makeShortName
      model
      modelShortName
      payoutData
      vin
      vinPhoto
      completedAt
      repairOrder
      stock
      deletedAt
      payoutId
      squareInvoiceId
      projectedPayout
      invoiceNumber
      ccFee
      invoiceVersion
      dealerId
      fullyPaidAt
      emailSentAt
      payoutOverrideTotal
      commissionPercent
      label
      markedAsPromotionalRepairAt
      statementId
      dealer {
        id
        businessName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        primaryContact {
          firstName
          lastName
          email
        }
      }
      secondaryTechnicianId
      workOrder {
        id
        parts {
          id
          name
          price
          cost
        }
      }
      technician {
        id
        firstName
        lastName
      }
    }
  }
`

const Router = () => {
  const { path } = useRouteMatch()
  const { bToBInvoiceId } = useParams()

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { id: bToBInvoiceId },
  })

  if (loading) return 'Loading'
  if (error) return JSON.stringify(error)

  const invoice = data?.bToBInvoice

  return (
    <Switch>
      <Route path={path}>
        {invoice.invoiceVersion && invoice.invoiceVersion > 1 ? (
          <ShowEdit refetch={refetch} invoice={keysToCamel(invoice)} />
        ) : (
          <ShowEditV1 invoice={keysToCamel(invoice)} />
        )}
      </Route>
    </Switch>
  )
}

export default Router
