import { useContext, useState } from 'react'
import { Button, Grid, TextField } from '@mui/material'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { Dollars, PriceInput } from 'tools'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'

const TireMarkupBracketRow = ({ data, editing, setEditing, refetch }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [limit, setLimit] = useState(data.limit)
  const [cashRate, setCashRate] = useState(data.cashRate)

  function onClickEdit() {
    setEditing(data.id)
  }
  function onClickCancel() {
    setEditing('')
  }
  function onClickSave() {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/tire_connect_update_pricing_markup_bracket`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        markupBracketId: data.id,
        limit,
        cashRate,
      }),
    })
      .then(res => (res.ok ? refetch() : alert('error')))
      .catch(err => alert(err))
  }
  function onClickDelete() {
    if (window.confirm(`Are you sure you want to delete this Tire labor item ($${data.limit})?`))
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/tire_connect_delete_pricing_markup_bracket`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          markupBracketId: data.id,
        }),
      })
        .then(res => (res.ok ? refetch() : alert('error')))
        .catch(err => alert(err))
  }
  function onChangeLimit(e) {
    setLimit(e.target.value)
  }

  function onChangeCashRate(e) {
    setCashRate(e.target.value)
  }

  const isEditing = data.id == editing

  return (
    <>
      <Grid container xs={12} sx={{ m: 4 }}>
        <Grid item xs={3}>
          {!isEditing && <Dollars value={data.limit} />}
          {isEditing && (
            <TextField
              variant='outlined'
              size='small'
              label='Limit'
              name='limit'
              InputProps={{ inputComponent: PriceInput }}
              value={limit}
              onChange={onChangeLimit}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          {!isEditing && data.cashRate}
          {isEditing && (
            <TextField
              variant='outlined'
              size='small'
              label='Cash Rate'
              name='cashRate'
              type='number'
              value={cashRate}
              onChange={onChangeCashRate}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          {!isEditing && (
            <Button onClick={onClickEdit}>
              <EditIcon />
            </Button>
          )}
          {isEditing && (
            <>
              <Button onClick={onClickSave} sx={{ color: 'green' }}>
                <SaveIcon />
              </Button>
              <Button onClick={onClickCancel} sx={{ color: 'orange' }}>
                <CancelIcon />
              </Button>
              <Button onClick={onClickDelete} sx={{ color: 'red' }}>
                <DeleteIcon />
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default TireMarkupBracketRow