import {
  Typography,
  Container,
  Grid,
  Grow,
  Collapse,
  Select,
  MenuItem,
  FormControl,
  Drawer,
  InputLabel,
  Button,
  TextField,
  Modal,
  Box,
} from '@mui/material'
import { KeyboardArrowDown, Cancel, DownloadRounded, InfoRounded } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useQuery, gql } from '@apollo/client'
import { useEffect, useState, useContext } from 'react'
import { PriceInput, Dollars, keysToCamel } from 'tools'
import { DateTime } from 'luxon'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import Chat from 'Chat'
import { Link } from 'react-router-dom'
import { UserContext } from 'UserStore'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { CreateATask } from 'Tasks'
import { PDFDownloadLink } from '@react-pdf/renderer'
import InvoicePDF from './InvoicePDF'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const styles = {
  photoExpandStyle: {
    border: '1px solid #ddd',
    fontSize: '14px',
    borderRadius: '0px 0px 6px 6px',
    display: 'flex',
    padding: '0.25rem 0.5rem',
    background: '#f1f1f1',
    justifyContent: 'center',
    alignItems: 'center',
  },
  photoCancelButtonStyle: {
    zIndex: 999,
    position: 'absolute',
    top: '5px',
    right: '5px',
    fontSize: '30px',
    color: '#fff',
    border: '2px solid #fff',
    borderRadius: '20px',
  },
  sectionHeaderStyle: {
    borderRadius: '6px 6px 0px 0px',
    fontWeight: 700,
    fontSize: '14px',
    padding: '0.5rem 1rem',
    background: '#bbc7d3',
    display: 'flex',
    alignItems: 'center',
  },
  sectionContainerStyle: {
    mb: '0.5rem',
    mt: '0.5rem',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '6px',
    maxWidth: '100%',
  },
  photoContainerStyle: {
    background: '#fff',
    padding: '1rem 0.5rem',
    border: '1px solid #ddd',
    borderBottom: '0',
    borderTop: '0',
    textAlign: 'center',
  },
  uploadButtonContainerStyle: {
    border: '1px solid #ddd',
    fontSize: '14px',
    borderRadius: '0px 0px 6px 6px',
    display: 'flex',
    padding: '0.25rem 0.5rem',
    background: '#f1f1f1',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '700px',
  maxHeight: '80%',
  width: '1100px',
  maxWidth: '100%',
  boxShadow: 24,
  border: '1px solid black',
}

const VEHICLE_YEARS = gql`
  query vehicleData {
    allVehicleYears
  }
`

const VEHICLE_MAKES = gql`
  query vehicleData($year: Int, $distinctBy: String) {
    vehicles(year: $year, distinctBy: $distinctBy) {
      makeShortName
      makePrettyName
    }
  }
`

const VEHICLE_MODELS = gql`
  query vehicleData($year: Int, $makeShortName: String, $distinctBy: String) {
    vehicles(year: $year, makeShortName: $makeShortName, distinctBy: $distinctBy) {
      modelShortName
      modelPrettyName
    }
  }
`

const ShowEdit = ({ invoice }) => {
  const [user] = useContext(UserContext)
  const [lineItemOptions, setLineItemOptions] = useState([])
  const invoiceFilename = DateTime.fromJSDate(new Date(invoice.createdAt)).toFormat('yyyy-LL-dd') + '-payout.pdf'
  const [businessName, setBusinessName] = useState(invoice?.businessName)
  const invoiceNameNeedsSaving = invoice.businessName !== businessName
  const bearerTokenHeaders = useBearerTokenHeaders()

  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)

  useEffect(() => {
    const key = registerTaskTarget({
      targetType: 'BToBInvoice',
      targetId: invoice.id,
    })
    return () => unregisterTaskTarget(key)
  }, [])

  useEffect(() => {
    if (invoice.businessName === 'drivetime') {
      fetch(`https://driveway-production.s3-accelerate.amazonaws.com/json/${invoice.businessName}.json`)
        .then(res => res.json())
        .then(data => setLineItemOptions(data.sort((a, b) => (a.type > b.type ? 1 : -1))))
    } else {
      fetch(`https://driveway-production.s3-accelerate.amazonaws.com/json/drivetime.json`)
        .then(res => res.json())
        .then(data => setLineItemOptions(data.sort((a, b) => (a.type > b.type ? 1 : -1))))
    }
  }, [])

  const updateAttributes = attr =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        updatedAttributes: attr,
        actorType: 'csr',
        actorId: user.id,
      }),
    }).then(res => res.ok || window.alert('Error'))

  if (lineItemOptions.length === 0) return 'loading...'

  const filteredOptions = lineItem =>
    ((lineItem.type && lineItemOptions.find(item => item.type === lineItem.type)?.options) || []).sort((a, b) =>
      a.name > b.name ? 1 : -1
    )

  const lookupPrice = ({ type, option }) =>
    (type &&
      option &&
      lineItemOptions.find(item => item.type === type).options.find(item => item.name === option).price) ||
    null

  const availableSideOptionsForLineItem = lineItem =>
    (lineItem.type && lineItemOptions.find(item => item.type === lineItem.type)?.sides) || []

  const requiresSideSelection = lineItem => availableSideOptionsForLineItem(lineItem).length > 0

  const lineItemIsValid = lineItem =>
    (requiresSideSelection(lineItem) ? lineItem.side : true) && lineItem.type && lineItem.option && lineItem.price

  const lockedToCsr = invoice.payoutData

  const handleClickDeleteInvoice = () =>
    window.confirm('Please confirm') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_invoice`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        userId: user.id,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const VehiclePicker = () => {
    const { data: fetchedYears } = useQuery(VEHICLE_YEARS)

    const { loading: makesLoading, data: fetchedMakes } = useQuery(VEHICLE_MAKES, {
      variables: { year: invoice.year, distinctBy: 'makeShortName' },
    })

    const { loading: modelsLoading, data: fetchedModels } = useQuery(VEHICLE_MODELS, {
      variables: {
        year: invoice.year,
        makeShortName: invoice.make,
        distinctBy: 'modelShortName',
      },
    })

    const updateAttribute = attr =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBInvoiceId: invoice.id,
          updatedAttributes: attr,
          actorType: 'csr',
          actorId: user.id,
        }),
      }).then(res => res.ok || window.alert('Error'))

    const handleSelectedYear = year => updateAttribute({ year })
    const handleSelectedMake = make => updateAttribute({ make })
    const handleSelectedModel = model => updateAttribute({ model })

    const yearsForSelect = (fetchedYears && fetchedYears.allVehicleYears.slice().sort((a, b) => (a < b ? 1 : -1))) || []

    const makesForSelect =
      (fetchedMakes &&
        fetchedMakes.vehicles
          .slice()
          .sort((a, b) => (a.makePrettyName > b.makePrettyName ? 1 : -1))
          .map(vehicle => ({
            prettyName: vehicle.makePrettyName,
            shortName: vehicle.makeShortName,
          }))) ||
      []

    const modelsForSelect =
      (fetchedModels &&
        fetchedModels.vehicles
          .slice()
          .sort((a, b) => (a.modelPrettyName > b.modelPrettyName ? 1 : -1))
          .map(vehicle => ({
            prettyName: vehicle.modelPrettyName,
            shortName: vehicle.modelShortName,
          }))) ||
      []

    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4} sx={{ pr: '0.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Year</InputLabel>
              <Select
                disabled={!!lockedToCsr}
                value={invoice.year || ''}
                label='year'
                onChange={e => handleSelectedYear(e.target.value)}
              >
                {yearsForSelect.map(year => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} sx={{ pl: '0.5rem', pr: '0.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Make</InputLabel>
              <Select
                value={invoice.make || ''}
                label='make'
                disabled={!!(lockedToCsr || makesLoading || !invoice.year)}
                onChange={e => handleSelectedMake(e.target.value)}
              >
                {makesForSelect.map(({ shortName, prettyName }) => (
                  <MenuItem key={shortName} value={shortName}>
                    {prettyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} sx={{ pl: '0.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Model</InputLabel>
              <Select
                value={invoice.model || ''}
                label='model'
                disabled={!!(lockedToCsr || modelsLoading || !invoice.make)}
                onChange={e => handleSelectedModel(e.target.value)}
              >
                {modelsForSelect.map(({ shortName, prettyName }) => (
                  <MenuItem key={shortName} value={shortName}>
                    {prettyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const Stock = () => {
    const [editableStock, setEditableStock] = useState(invoice.stock)
    const [waiting, setWaiting] = useState(false)

    const handleChangeStock = e => setEditableStock(e.target.value)

    const handleClickSaveStock = () => {
      setWaiting(true)

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBInvoiceId: invoice.id,
          updatedAttributes: { stock: editableStock.trim() },
          actorType: 'csr',
          actorId: user.id,
        }),
      })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl size='small'>
          <TextField
            value={editableStock}
            label='Stock'
            disabled={!!lockedToCsr}
            onChange={handleChangeStock}
            size='small'
          />
        </FormControl>
        <Button
          disabled={waiting || editableStock === null || editableStock.trim() === invoice.stock}
          onClick={handleClickSaveStock}
        >
          Save
        </Button>
      </Grid>
    )
  }

  const RepairOrder = () => {
    const [editableRepairOrder, setEditableRepairOrder] = useState(invoice.repairOrder)
    const [waiting, setWaiting] = useState(false)

    const handleChangeRepairOrder = e => setEditableRepairOrder(e.target.value)

    const handleClickSaveRepairOrder = () => {
      setWaiting(true)

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBInvoiceId: invoice.id,
          updatedAttributes: { repairOrder: editableRepairOrder.trim() },
          actorType: 'csr',
          actorId: user.id,
        }),
      })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl size='small'>
          <TextField
            value={editableRepairOrder}
            label='RO'
            disabled={!!lockedToCsr}
            onChange={handleChangeRepairOrder}
            size='small'
          />
        </FormControl>
        <Button
          disabled={waiting || editableRepairOrder === null || editableRepairOrder.trim() === invoice.repairOrder}
          onClick={handleClickSaveRepairOrder}
        >
          Save
        </Button>
      </Grid>
    )
  }

  const Vin = () => {
    const [editableVin, setEditableVin] = useState(invoice.vin)
    const [waiting, setWaiting] = useState(false)

    const handleChangeVin = e => setEditableVin(e.target.value)

    const handleClickSaveVin = () => {
      setWaiting(true)

      window
        .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            updatedAttributes: { vin: editableVin.trim() },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
        .then(res => res.ok || window.alert('Error'))
        .finally(() => setWaiting(false))
    }

    return (
      <Grid item xs={12}>
        <FormControl size='small'>
          <TextField value={editableVin} label='VIN' disabled={!!lockedToCsr} onChange={handleChangeVin} size='small' />
        </FormControl>
        <Button
          disabled={waiting || editableVin === null || editableVin.trim() === invoice.vin}
          onClick={handleClickSaveVin}
        >
          Save
        </Button>
      </Grid>
    )
  }

  const VinPhoto = () => {
    const [modalPhotoUrl, setModalPhotoUrl] = useState(null)

    return (
      <>
        <Grid container sx={styles.sectionContainerStyle}>
          <Grid item xs={12} sx={styles.sectionHeaderStyle}>
            VIN Photo {invoice.vinPhoto ? '' : '(NONE)'}
          </Grid>

          <Grid item xs={12}>
            {!!invoice.vinPhoto && (
              <div style={{ height: '200px' }}>
                <PinchZoomPan position='center' maxScale={3}>
                  <img
                    onClick={() => setModalPhotoUrl(invoice.vinPhoto.originalUrl)}
                    src={invoice.vinPhoto?.originalUrl}
                  />
                </PinchZoomPan>
              </div>
            )}

            <Modal open={!!modalPhotoUrl} onClose={() => setModalPhotoUrl(null)}>
              <Box sx={modalStyle}>
                <div
                  style={{
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Cancel sx={styles.photoCancelButtonStyle} onClick={() => setModalPhotoUrl(null)} />
                  <PinchZoomPan position='center' maxScale={2}>
                    <img src={modalPhotoUrl} />
                  </PinchZoomPan>
                </div>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </>
    )
  }

  const PreJobPhotos = () => {
    const [openPreJobPhotosDrawer, setOpenPreJobPhotosDrawer] = useState(false)
    const [expandPhotoSection, setExpandPhotoSection] = useState(false)
    const [currentFullScreenPhoto, setCurrentFullScreenPhoto] = useState(null)

    const preJobPhotos = invoice.preJobPhotos || []

    return (
      <>
        <Grid container sx={styles.sectionContainerStyle}>
          <Grid item xs={12} sx={styles.sectionHeaderStyle}>
            PRE-Job Photos
            {preJobPhotos.length > 0 && <> &nbsp;({preJobPhotos.length})</>}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              borderRadius: preJobPhotos.length > 0 && preJobPhotos.length < 8 ? '0px 0px 6px 6px' : '0px',
              ...styles.photoContainerStyle,
            }}
          >
            {(preJobPhotos.length > 0 && (
              <Grid container spacing={2}>
                {preJobPhotos.map(
                  (photo, index) =>
                    (expandPhotoSection || index < 8) && (
                      <Grid item xs={3} sm={3} md={2} key={photo.originalUrl}>
                        <Grid
                          onClick={() => setCurrentFullScreenPhoto(photo)}
                          sx={{
                            borderRadius: '3px',
                            minHeight: '75px',
                            background: `url(${photo.originalUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                          }}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            )) ||
              'No photos uploaded yet'}
          </Grid>

          {preJobPhotos?.length > 8 && (
            <Grid
              item
              xs={12}
              onClick={() => setExpandPhotoSection(!expandPhotoSection)}
              sx={{
                ...styles.photoExpandStyle,
                '&:active': {
                  background: '#eee',
                },
              }}
            >
              See
              {expandPhotoSection ? ' less' : ' all'}
              <KeyboardArrowDown
                sx={{
                  transform: expandPhotoSection ? 'rotate(180deg)' : '',
                  ml: '1rem',
                  transition: 'all 0.25s ease-in-out',
                }}
              />
            </Grid>
          )}

          {preJobPhotos?.length < 1 && <Grid item xs={12} sx={styles.uploadButtonContainerStyle}></Grid>}
        </Grid>

        <Modal open={!!currentFullScreenPhoto} onClose={() => setCurrentFullScreenPhoto(null)}>
          <Box sx={modalStyle}>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <Cancel sx={styles.photoCancelButtonStyle} onClick={() => setCurrentFullScreenPhoto(null)} />
              <PinchZoomPan position='center' maxScale={2}>
                <img src={currentFullScreenPhoto && currentFullScreenPhoto.originalUrl} />
              </PinchZoomPan>
            </div>
          </Box>
        </Modal>
      </>
    )
  }

  const LineItemsSection = () => {
    const [editingLineItems, setEditingLineItems] = useState(false)

    const initialDiscountPercent = invoice.discountPercent || 0
    const [editingDiscountPercent, setEditingDiscountPercent] = useState(initialDiscountPercent)

    const handleSaveDiscountPercent = () =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          bToBInvoiceId: invoice.id,
          updatedAttributes: {
            discount_percent: editingDiscountPercent,
          },
          actorType: 'csr',
          actorId: user.id,
        }),
      }).then(res => res.ok || window.alert('Error'))

    const NewLineItem = () => {
      const [lineItem, setLineItem] = useState({})

      const resetLineItem = () => setLineItem({})

      const handleSelectedType = type => setLineItem(lineItem => ({ type }))

      const handleSelectedOption = option =>
        setLineItem(prev => ({
          ...prev,
          ...{
            option,
            price: lookupPrice({ type: lineItem.type, option }),
          },
        }))

      useEffect(() => {
        setLineItem(prev => ({
          ...prev,
          price: lookupPrice({ type: lineItem.type, option: lineItem.option }),
        }))
      }, [lineItem.type, lineItem.option])

      const lineItemIsSavable = lineItem.type && lineItem.option && lineItem.price

      const handleClickAdd = () =>
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_invoice_line_item`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            lineItem,
            actorType: 'csr',
            actorId: user.id,
          }),
        }).then(res => res.ok || window.alert('Error'))

      return (
        <Grid container sx={{ mb: '1.25rem' }}>
          <Grid item xs={12} sx={{ mb: '0.25rem' }}>
            Add new line item
          </Grid>

          <Grid item xs={5} sx={{ pr: '0.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Type</InputLabel>
              <Select label='type' value={lineItem.type || ''} onChange={e => handleSelectedType(e.target.value)}>
                {lineItemOptions
                  .slice()
                  .sort((a, b) => (a.type > b.type ? 1 : -1))
                  .map(item => (
                    <MenuItem key={item.type} sx={{ textTransform: 'capitalize' }} value={item.type}>
                      {item.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} sx={{ pr: '0.5rem', pl: '0.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Option</InputLabel>
              <Select label='option' value={lineItem.option || ''} onChange={e => handleSelectedOption(e.target.value)}>
                {filteredOptions(lineItem)
                  .slice()
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map(item => (
                    <MenuItem key={item.name} sx={{ textTransform: 'capitalize' }} value={item.name}>
                      {item.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3} sx={{ pl: '0.5rem' }}>
            <FormControl fullWidth size='small'>
              <TextField value={`$ ${lineItem.price || 0}`} label='price' disabled size='small' />
            </FormControl>
          </Grid>

          <Collapse in={lineItemIsSavable} sx={{ width: '100%' }}>
            <Grid container>
              <Grid item xs={12} sx={{ display: 'flex', mt: '0.5rem' }}>
                <Button onClick={resetLineItem} color='error'>
                  Cancel
                </Button>
                <Button onClick={handleClickAdd} disableElevation variant='contained' sx={{ ml: 'auto' }}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      )
    }

    const EditLineItem = ({ lineItem }) => {
      const [editableLineItem, setEditableLineItem] = useState({ ...lineItem })

      const resetEditableLineItem = () => setEditableLineItem({ ...lineItem })

      const handleSelectType = type =>
        setEditableLineItem(prev => ({
          ...prev,
          ...{ type, side: null, option: null, price: null },
        }))

      const handleSelectSide = side =>
        setEditableLineItem(prev => ({
          ...prev,
          ...{ side },
        }))

      const handleSelectOption = option =>
        setEditableLineItem(prev => ({
          ...prev,
          ...{
            option,
            price: lookupPrice({ type: prev.type, option }),
          },
        }))

      const handlePriceChanged = e =>
        setEditableLineItem(prev => ({
          ...prev,
          price: Number(e.target.value),
        }))

      const handleClickSave = () =>
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_line_item`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            lineItem: editableLineItem,
            actorType: 'csr',
            actorId: user.id,
          }),
        }).then(res => res.ok || window.alert('Error'))

      const handleClickDelete = () =>
        window.confirm('Are you sure you want to delete this line item?') &&
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_invoice_line_item`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: invoice.id,
            lineItemId: lineItem.id,
            actorType: 'csr',
            actorId: user.id,
          }),
        }).then(res => res.ok || window.alert('Error'))

      const editableHasDiff =
        editableLineItem.type !== lineItem.type ||
        editableLineItem.side !== lineItem.side ||
        editableLineItem.option !== lineItem.option ||
        editableLineItem.price !== lineItem.price

      const allowSave = editableLineItem && lineItemIsValid(editableLineItem)

      return (
        <Grid
          container
          sx={{
            borderRadius: '6px',
            border: '1px solid #e8ddd9',
            background: '#fffdf8',
            mb: '0.5em',
          }}
        >
          <Grid container sx={{ margin: '4px 0' }}>
            <Grid item xs={6}>
              <FormControl fullWidth size='small' sx={{ background: '#fff' }}>
                <InputLabel>Type</InputLabel>
                <Select
                  label='type'
                  value={editableLineItem.type || ''}
                  onChange={e => handleSelectType(e.target.value)}
                >
                  {lineItemOptions.map(item => (
                    <MenuItem key={item.type} value={item.type}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {requiresSideSelection(editableLineItem) && (
              <Grid item xs={6}>
                <FormControl fullWidth size='small' sx={{ background: '#fff' }}>
                  <InputLabel>Side</InputLabel>
                  <Select
                    label='side'
                    value={editableLineItem.side || ''}
                    onChange={e => handleSelectSide(e.target.value)}
                  >
                    {availableSideOptionsForLineItem(editableLineItem).map(item => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>

          <Grid container sx={{ mb: '4px' }}>
            <Grid item xs={6}>
              <FormControl fullWidth size='small' sx={{ background: '#fff' }}>
                <InputLabel>Option</InputLabel>
                <Select
                  label='option'
                  value={editableLineItem.option || ''}
                  onChange={e => handleSelectOption(e.target.value)}
                >
                  {filteredOptions(editableLineItem).map(item => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FormControl fullWidth size='small'>
                <TextField
                  size='small'
                  value={editableLineItem.price}
                  onChange={handlePriceChanged}
                  InputProps={{
                    inputComponent: PriceInput,
                  }}
                />
              </FormControl>

              <Button disabled={editableHasDiff} color='error' onClick={handleClickDelete}>
                [delete]
              </Button>
            </Grid>
          </Grid>

          <Collapse in={editableHasDiff} sx={{ width: '100%' }}>
            <Grid container>
              <Grid item xs={12} sx={{ p: '5px', display: 'flex' }}>
                <Button onClick={resetEditableLineItem} color='warning' size='small'>
                  cancel/reset
                </Button>
                <Button
                  disabled={!allowSave}
                  onClick={handleClickSave}
                  disableElevation
                  variant='contained'
                  size='small'
                  sx={{ ml: 'auto' }}
                >
                  Save changes
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      )
    }

    const ShowLineItem = ({ lineItem, index }) => (
      <Grid
        container
        style={{
          padding: '4px',
          borderTop: index > 0 && '1px dashed lightsteelblue',
        }}
      >
        <Grid item xs={5} sx={{ p: '4px' }}>
          {lineItemOptions.find(option => option.type === lineItem.type).title}

          {lineItem.side && (
            <>
              <br />({availableSideOptionsForLineItem(lineItem).find(option => option.name === lineItem.side).title})
            </>
          )}
        </Grid>

        <Grid item xs={5} sx={{ p: '4px' }}>
          {filteredOptions(lineItem).find(option => option.name === lineItem.option).title}
        </Grid>

        <Grid item xs={2} sx={{ textAlign: 'right', p: '4px' }}>
          <Dollars value={lineItem.price} />
        </Grid>
      </Grid>
    )

    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            borderRadius: '6px 6px 0px 0px',
            fontWeight: 700,
            fontSize: '14px',
            padding: '0.5rem 1rem',
            background: '#fff',
            border: '1px solid #ddd',
          }}
        >
          Line Items
          <Grow in={!invoice.payoutData}>
            <Button
              color='primary'
              onClick={() => setEditingLineItems(!editingLineItems)}
              sx={{ ml: 'auto', padding: '0.125rem' }}
            >
              [ Edit{editingLineItems && 'ing'} ]
            </Button>
          </Grow>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            background: '#fff',
            padding: '0.5rem 0.5rem 1rem 0.5rem',
            borderRadius: '0px 0px 6px 6px',
            border: '1px solid #ddd',
            borderTop: '0px',
          }}
        >
          <Collapse in={!invoice.payoutData && editingLineItems}>
            <NewLineItem />
          </Collapse>

          {invoice.lineItems.length > 0 && (
            <Grid
              container
              sx={{
                border: '1px solid #d8dBdd',
                mt: '0.25rem',
                background: '#F8FBFF',
                borderRadius: '8px',
              }}
            >
              <Grid item xs={12} sx={{ padding: '0 0.25rem' }}>
                {invoice.lineItems.map((item, i) =>
                  editingLineItems ? (
                    <EditLineItem key={item.id} lineItem={item} />
                  ) : (
                    <ShowLineItem key={item.id} lineItem={item} index={i} />
                  )
                )}
              </Grid>
            </Grid>
          )}

          <Collapse in={!invoice.payoutData && editingLineItems}>
            <Grid container sx={{ padding: '0.5rem 0rem' }}>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  variant='outlined'
                  size='small'
                  label='Discount Percent'
                  type='number'
                  value={editingDiscountPercent}
                  onChange={e => setEditingDiscountPercent(parseFloat(e.target.value))}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  disabled={editingDiscountPercent === initialDiscountPercent}
                  color='primary'
                  onClick={handleSaveDiscountPercent}
                >
                  save
                </Button>
              </Grid>
            </Grid>
          </Collapse>

          {(invoice.discountPercent || editingLineItems) && (
            <Grid
              item
              xs={12}
              sx={{
                padding: '0.75rem 0.5rem 0rem 0rem',
                textAlign: 'right',
                ml: 'auto',
              }}
            >
              Subtotal: ${invoice.lineItemsSum.toFixed(2)}
            </Grid>
          )}

          <Collapse in={!editingLineItems}>
            {!!invoice.discountPercent && (
              <Grid
                item
                xs={12}
                sx={{
                  padding: '0.25rem 0.5rem 0rem 0rem',
                  textAlign: 'right',
                  ml: 'auto',
                }}
              >
                Discount (-{invoice.discountPercent}%): -${invoice.discountPercentTotal.toFixed(2)}
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sx={{
                fontWeight: 600,
                padding: '0.75rem 0.5rem 0rem 0rem',
                textAlign: 'right',
                ml: 'auto',
              }}
            >
              Grand total: ${(invoice.grandTotal || invoice.lineItemsSum).toFixed(2)}
            </Grid>
          </Collapse>
          {!!invoice.ccFee && (
            <Grid
              item
              xs={12}
              sx={{
                textAlign: 'right',
                justifyContent: 'flex-end',
                display: 'flex',
                fontSize: '12px',
                alignItems: 'center',
                '& span': { marginLeft: '.25rem' },
              }}
            >
              (SQUARE FEE: <Dollars value={invoice.ccFee} />)
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }

  const PostJobPhotos = () => {
    const [showPostJobPhotosDrawer, setShowPostJobPhotosDrawer] = useState(false)
    const { postJobPhotos } = invoice
    const [expandPhotoSection, setExpandPhotoSection] = useState(false)
    const [currentFullScreenPhoto, setCurrentFullScreenPhoto] = useState(null)

    return (
      <>
        <Grid container sx={styles.sectionContainerStyle}>
          <Grid item xs={12} sx={styles.sectionHeaderStyle}>
            POST-Job Photos
            {postJobPhotos?.length > 0 && <>&nbsp;({postJobPhotos.length})</>}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              borderRadius: postJobPhotos.length > 0 && postJobPhotos.length < 8 ? '0px 0px 6px 6px' : '0px',
              ...styles.photoContainerStyle,
            }}
          >
            {(postJobPhotos?.length > 0 && (
              <Grid container spacing={2}>
                {postJobPhotos.map(
                  (photo, index) =>
                    (expandPhotoSection || index < 8) && (
                      <Grid item xs={3} sm={3} md={2} key={photo.originalUrl}>
                        <Grid
                          onClick={() => setCurrentFullScreenPhoto(photo)}
                          sx={{
                            borderRadius: '3px',
                            minHeight: '75px',
                            background: `url(${photo.originalUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                          }}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            )) ||
              'No photos uploaded yet'}
          </Grid>

          {postJobPhotos?.length > 0 ? (
            postJobPhotos.length > 8 && (
              <Grid
                item
                xs={12}
                onClick={() => setExpandPhotoSection(!expandPhotoSection)}
                sx={{
                  ...styles.photoExpandStyle,
                  '&:active': { background: '#eee' },
                }}
              >
                See
                {expandPhotoSection ? ' less' : ' all'}
                <KeyboardArrowDown
                  sx={{
                    transform: expandPhotoSection ? 'rotate(180deg)' : '',
                    ml: '1rem',
                    transition: 'all 0.25s ease-in-out',
                  }}
                />
              </Grid>
            )
          ) : (
            <Grid item xs={12} sx={styles.uploadButtonContainerStyle}></Grid>
          )}
        </Grid>

        <Modal open={!!currentFullScreenPhoto} onClose={() => setCurrentFullScreenPhoto(null)}>
          <Box sx={modalStyle}>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <Cancel sx={styles.photoCancelButtonStyle} onClick={() => setCurrentFullScreenPhoto(null)} />
              <PinchZoomPan position='center' maxScale={2}>
                <img src={currentFullScreenPhoto && currentFullScreenPhoto.originalUrl} />
              </PinchZoomPan>
            </div>
          </Box>
        </Modal>
      </>
    )
  }

  return (
    <Container maxWidth='sm'>
      <Grid container>
        {invoice.completedAt && !invoice.fullyPaidAt && (
          <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex', pb: '.5rem' }}>
            <Button
              onClick={() => updateAttributes({ fully_paid_at: DateTime.now().toISO() })}
              size='small'
              variant='outlined'
            >
              Mark as paid
            </Button>
          </Grid>
        )}
        {invoice.fullyPaidAt && (
          <Grid item xs={12}>
            <b
              style={{
                display: 'block',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#111',
                borderTop: '2px dashed palegreen',
                borderBottom: '2px dashed palegreen',
                background: '#98fb9840',
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
              }}
            >
              PAID AT {DateTime.fromISO(invoice.fullyPaidAt).toFormat('D hh:mm')}
            </b>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '.5rem' }}>
            <Typography variant='h6' sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
              <TextField
                size='small'
                value={businessName}
                onChange={e => setBusinessName(e.target.value)}
                sx={{ mr: '.5rem' }}
              />
              Invoice
            </Typography>
            <Button
              size='small'
              disableElevation
              variant='contained'
              // sx={{ padding: '.25rem .5rem' }}
            >
              <DownloadRounded sx={{ mr: '.25rem', fontSize: '1rem' }} />
              <PDFDownloadLink
                style={{ color: 'unset', textDecoration: 'none' }}
                document={<InvoicePDF invoice={keysToCamel(invoice)} />}
                fileName={invoiceFilename}
              >
                {({ blob, url, loading, error }) => {
                  if (error) return JSON.stringify(error)
                  return loading ? 'Generating PDF...' : 'Download as PDF'
                }}
              </PDFDownloadLink>
            </Button>
          </Box>
          <Collapse in={invoiceNameNeedsSaving}>
            <Button
              onClick={() => updateAttributes({ businessName: businessName })}
              sx={{ mb: '1rem' }}
              variant='contained'
            >
              Save Business Name
            </Button>
          </Collapse>
          <Link target='_blank' to={`/technicians/${invoice.technician.id}`}>
            {invoice.technician.firstName} {invoice.technician.lastName}
          </Link>
          &nbsp;&nbsp;
          <span style={{ opacity: 0.75, fontSize: '14px' }}>
            Created:{' '}
            <Link to={`/b-to-b-invoices/${invoice.id}`}>{DateTime.fromISO(invoice.createdAt).toLocaleString()}</Link>
          </span>
        </Grid>

        {invoice.completedAt && (
          <Grid item xs={12}>
            <b
              style={{
                display: 'block',
                padding: '0.25rem 0.5rem',
                textAlign: 'center',
                color: '#111',
                backgroundColor: 'palegreen',
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
              }}
            >
              COMPLETED {DateTime.fromISO(invoice.completedAt).toFormat('D hh:mm')}
            </b>
          </Grid>
        )}
      </Grid>

      <VehiclePicker />
      <VinPhoto />
      <Vin />
      <RepairOrder />
      {invoice.businessName === 'carmax' && <Stock />}
      <PreJobPhotos />
      <LineItemsSection />
      <PostJobPhotos />
      {/* <SquareInvoiceId /> */}

      <Grid container sx={{ ...styles.sectionContainerStyle, border: '1px solid #ddd' }}>
        <Grid item xs={12} sx={styles.sectionHeaderStyle}>
          Chat
        </Grid>
        <Grid item xs={12} sx={{ padding: '0.5rem' }}>
          <Chat contextId={invoice.id} contextType='BToBInvoice' />
        </Grid>
      </Grid>

      <Button
        disabled={invoice.payoutId || invoice.deletedAt}
        variant='outlined'
        color='error'
        onClick={handleClickDeleteInvoice}
        startIcon={<DeleteIcon />}
      >
        Delete{invoice.deletedAt && 'd'}
      </Button>

      <CreateATask targetType='BToBInvoice' targetId={invoice.id} />
    </Container>
  )
}

export default ShowEdit
