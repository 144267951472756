import React, { useState, useContext } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import axios from 'axios'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Box,
  Grid,
  Button,
  Collapse,
  Chip,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Autocomplete,
  Checkbox,
  FormGroup,
  FormLabel,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'

import { UserContext } from 'UserStore'
import { PriceInput, diff, isEmail, API_COMMAND_HEADERS } from 'tools'
import { techSkillsets } from 'data/techSkillsets.js'

const MARKETS_QUERY = gql`
  query markets {
    markets {
      state
      stateCode
      market
    }
  }
`

const DEALERS_QUERY = gql`
  query BToBDealers {
    bToBDealers {
      id
      businessName
    }
  }
`

const VEHICLE_YEARS_QUERY = gql`
  query fgVehicleYears {
    fgVehicleYears
  }
`

const VEHICLE_MAKE_QUERY = gql`
  query fgVehicleMakes($year: String) {
    fgVehicleMakes(year: $year) {
      makeId
      makeName
      makeShortName
    }
  }
`

const VEHICLE_MODEL_QUERY = gql`
  query fgVehicleModels($year: String, $makeShortName: String) {
    fgVehicleModels(year: $year, makeShortName: $makeShortName) {
      modelId
      modelName
      modelShortName
    }
  }
`

const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
  padding: '6px',
}))

const StyledOffDayItem = styled('div')(({ theme }) => ({
  borderBottom: '1px solid #ccc',
  padding: '10px 10px',
  width: '280px',
  display: 'flex',
  justifyContent: 'space-between',
  '& svg': {
    cursor: 'pointer',
    color: '#DA291C',
    margin: '0px 0px -2px 5px',
    fontSize: '20px',
  },
}))

const newOffDayRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
const phoneNumberRegex = /^\+1\d{10}$/
const coordinateRegex = /^-?\d+\.\d{6}$/
const fourDigitRegex = /^[0-9]{4}$/
const zipRegex = /^\d{5}$/
const availabilityRegex = /^1?\d-1?\d(,1?\d-1?\d)?$/

// businessName: { check: (e) => e && e.length > 0, error: 'Must have a business name.' },
// bToBBusinessName: { check: (e) => !!e, error: 'Must have a b-to-b business name when b-to-b is toggled on.' }

const availabilitySchema = Yup.string().matches(
  availabilityRegex,
  'Please enter a valid hours range using 24-hour time format without spaces [e.g. "9-17"]'
)

const technicianSchema = Yup.object().shape({
  availability: Yup.object().shape({
    sunday: availabilitySchema,
    sundaySingleJobLimit: Yup.boolean().nullable(),
    monday: availabilitySchema,
    mondaySingleJobLimit: Yup.boolean().nullable(),
    tuesday: availabilitySchema,
    tuesdaySingleJobLimit: Yup.boolean().nullable(),
    wednesday: availabilitySchema,
    wednesdaySingleJobLimit: Yup.boolean().nullable(),
    thursday: availabilitySchema,
    thursdaySingleJobLimit: Yup.boolean().nullable(),
    friday: availabilitySchema,
    fridaySingleJobLimit: Yup.boolean().nullable(),
    saturday: availabilitySchema,
    saturdaySingleJobLimit: Yup.boolean().nullable(),
  }),
  notifications: Yup.array().of(Yup.string()),
  name: Yup.string().required(),
  email: Yup.string().test({
    name: 'email',
    test: value => !!value && isEmail(value),
    message: 'Please enter a valid email address.',
  }),
  phone: Yup.string().matches(phoneNumberRegex, 'Invalid phone number. Must match this form: +1XXXxxxXXXX').required(),
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  lat: Yup.string().matches(coordinateRegex, 'Must have 6 digits after decimal.').required(),
  lng: Yup.string().matches(coordinateRegex, 'Must have 6 digits after decimal.').required(),
  zip: Yup.string().matches(zipRegex, 'Must have 5 numbers only.').required(),
  is_trusted: Yup.boolean().nullable(),
  is_b_to_b: Yup.boolean().nullable(),
  contractor_is_business: Yup.boolean().nullable(),
  quote_commission_structure: Yup.string().nullable(),
  b_to_b_invoice_commission_structure: Yup.string().nullable(),
  tech_charges_commission_percent: Yup.string().nullable(),
  ssn: Yup.string()
    .when('contractor_is_business', {
      is: false,
      then: schema => schema.matches(fourDigitRegex, 'Must have 4 numbers only.').required(),
    })
    .nullable(),
  ein: Yup.string()
    .when('contractor_is_business', {
      is: true,
      then: schema => schema.matches(fourDigitRegex, 'Must have 4 numbers only.').required(),
    })
    .nullable(),
  business_name: Yup.string()
    .when('contractor_is_business', {
      is: true,
      then: schema => schema.required(),
    })
    .nullable(),
  hourly_rate: Yup.number()
    .when('is_employee', {
      is: true,
      then: schema => schema.required(),
    })
    .nullable(),
  commission_amount: Yup.number()
    .when('is_employee', {
      is: true,
      then: schema => schema.required(),
    })
    .nullable(),
  supplies_cost_percentage: Yup.number()
    .nullable(),
  net_labor_revenue_percentage: Yup.number()
    .nullable(),
})

const ShowEdit = ({ technician }) => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const [user] = useContext(UserContext)
  const [newOffDay, setNewOffDay] = useState('')
  const [notificationMessage, setNotificationMessage] = useState('')

  const { data: marketsData, loading: marketsLoading, error: marketsError } = useQuery(MARKETS_QUERY)

  const handleNewOffDayChange = event => {
    setNewOffDay(event.target.value)
  }

  // offday = Date string "2020-01-01"
  const handleRemoveOffday = offday => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_COMMAND_ROOT + '/api/remove_technician_offday',
      data: {
        technician_id: technician.id,
        date: offday,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
  }

  const initialValues = {
    availability: {
      sunday: technician.availability.sunday.join(),
      sundaySingleJobLimit: technician.availability.sundaySingleJobLimit || false,
      monday: technician.availability.monday.join(),
      mondaySingleJobLimit: technician.availability.mondaySingleJobLimit || false,
      tuesday: technician.availability.tuesday.join(),
      tuesdaySingleJobLimit: technician.availability.tuesdaySingleJobLimit || false,
      wednesday: technician.availability.wednesday.join(),
      wednesdaySingleJobLimit: technician.availability.wednesdaySingleJobLimit || false,
      thursday: technician.availability.thursday.join(),
      thursdaySingleJobLimit: technician.availability.thursdaySingleJobLimit || false,
      friday: technician.availability.friday.join(),
      fridaySingleJobLimit: technician.availability.fridaySingleJobLimit || false,
      saturday: technician.availability.saturday.join(),
      saturdaySingleJobLimit: technician.availability.saturdaySingleJobLimit || false,
    },
    notifications: technician.notifications || [],
    active: technician.active,
    name: technician.name,
    first_name: technician.firstName,
    last_name: technician.lastName,
    zip: technician.zip,
    phone: technician.phone,
    email: technician.email,
    contractor_is_business: technician.contractorIsBusiness,
    business_name: technician.businessName,
    is_trusted: !!technician.isTrusted,
    is_elite: !!technician.isElite,
    ssn: technician.ssn,
    ein: technician.ein,
    city: technician.city,
    lat: technician.lat,
    lng: technician.lng,
    state: technician.state,
    market: technician.market,
    display_market: technician.displayMarket,
    address_line_one: technician.addressLineOne,
    address_line_two: technician.addressLineTwo,
    is_employee: !!technician.isEmployee,
    hourly_rate: technician.hourlyRate,
    commission_amount: technician.commissionAmount,
    commission_percent: technician.commissionPercent,
    is_b_to_b: !!technician.isBToB,
    b_to_b_business_name: technician.bToBBusinessName,
    enable_b2b_square_fee: technician.enableB2bSquareFee,
    note: technician.note,
    emergency_contact: technician.emergencyContact,
    loan_weekly_deduction: Number(technician.loanWeeklyDeduction),
    other_loan_weekly_deduction: Number(technician.otherLoanWeeklyDeduction),
    vehicle_year: technician.vehicleYear,
    vehicle_make_short_name: technician.vehicleMakeShortName,
    vehicle_model_short_name: technician.vehicleModelShortName,
    vehicle_color: technician.vehicleColor,
    skillsets: technician.skillsets,
    max_driving_distance: technician.maxDrivingDistance,
    b_to_b_dealer_ids: technician.bToBDealerIds,
    quote_commission_structure: technician.quoteCommissionStructure,
    b_to_b_invoice_commission_structure: technician.bToBInvoiceCommissionStructure,
    tech_charges_commission_percent: technician.techChargesCommissionPercent,
    supplies_cost_percentage: technician.suppliesCostPercentage,
    net_labor_revenue_percentage: technician.netLaborRevenuePercentage,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: technicianSchema,
    onSubmit: values => {
      // using diff here breaks sub array
      const filteredValues = Object.fromEntries(Object.entries(values).filter(([k, v]) => v !== initialValues[k]))

      const availabilityDiff = diff(values.availability, initialValues.availability)

      if (Object.keys(availabilityDiff).length === 0) {
        delete filteredValues.availability
      } else {
        filteredValues.availability = {}
        daysOfWeek.forEach(day => {
          let v = values.availability[day].trim()
          let vLimit = values.availability[`${day}SingleJobLimit`]
          filteredValues.availability[day] = v.length > 0 ? v.split(',') : []
          filteredValues.availability[`${day}SingleJobLimit`] = vLimit
        })
      }

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/api/update_technician`, {
        method: 'POST',
        headers: API_COMMAND_HEADERS,
        body: JSON.stringify({
          technician_id: technician.id,
          user_id: user.id,
          updated_attributes: filteredValues,
        }),
      }).then(res =>
        res.ok ? history.push(path.substring(0, path.length - 18) + technician.id + '/profile') : window.alert('error')
      )
    },
  })

  const handleReset = () => {
    formik.setValues(initialValues)
  }
  const handleAddNotification = () => {
    const notifications = [...formik.values.notifications, notificationMessage]
    setNotificationMessage('')
    formik.setFieldValue('notifications', notifications)
  }
  const handleRemoveNotification = message => {
    const filteredValues = formik.values.notifications.filter(noti => noti !== message)
    formik.setFieldValue('notifications', filteredValues)
  }

  const handleAddNewOffday = () => {
    if (!newOffDay.match(newOffDayRegex)) {
      return
    }
    axios({
      method: 'post',
      url: process.env.REACT_APP_COMMAND_ROOT + '/api/add_technician_offday',
      data: {
        technician_id: technician.id,
        date: newOffDay,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
  }

  const { data: yearsData, loading: yearsLoading, error: yearsError } = useQuery(VEHICLE_YEARS_QUERY)

  const {
    data: makesData,
    loading: makesLoading,
    error: makesError,
  } = useQuery(VEHICLE_MAKE_QUERY, {
    variables: {
      year: formik.values.vehicle_year + '',
    },
  })

  const {
    data: modelsData,
    loading: modelsLoading,
    error: modelsError,
  } = useQuery(VEHICLE_MODEL_QUERY, {
    variables: {
      year: formik.values.vehicle_year + '',
      makeShortName: formik.values.vehicle_make_short_name,
    },
  })

  const {
    loading: loadingDealers,
    error: errorDealers,
    data: dealersData,
  } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  const getMakeOptionLabel = make => {
    const dataMake = makesData?.fgVehicleMakes.find(m => m.makeShortName === make)
    return dataMake ? dataMake.makeName : make
  }

  const getModelOptionLabel = model => {
    const dataModel = modelsData?.fgVehicleModels.find(m => m.modelShortName === model)
    return dataModel ? dataModel.modelName : model
  }

  if (marketsLoading || yearsLoading) return <div>Loading...</div>
  if (marketsError) return JSON.stringify(marketsError)
  if (yearsError) return JSON.stringify(yearsError)
  if (makesError) return JSON.stringify(makesError)
  if (modelsError) return JSON.stringify(modelsError)

  const states = {}
  const marketMenuItemsByState = {}
  marketsData.markets.forEach(market => {
    states[market.stateCode] = market.state
    marketMenuItemsByState[market.stateCode] = marketMenuItemsByState[market.stateCode] || []
    marketMenuItemsByState[market.stateCode].push(
      <MenuItem key={market.market} value={market.market}>
        {market.market}
      </MenuItem>
    )
  })
  const stateMenuItems = Object.entries(states).map(([k, v]) => (
    <MenuItem key={k} value={k}>
      {v}
    </MenuItem>
  ))
  const validationErrors = Object.values(
    Object.keys(formik.errors).reduce((acc, k) => {
      if (k === 'availability') {
        // flatten availability sub object errors
        Object.keys(formik.errors.availability).forEach(k => {
          acc['availability.' + k] = k + ': ' + formik.errors.availability[k]
        })
      } else {
        acc[k] = formik.errors[k]
      }
      return acc
    }, {})
  )

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <Box
            component='form'
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
              '& .MuiFormControl-root': { m: 1 },
            }}
            noValidate
            autoComplete='off'
            onSubmit={formik.handleSubmit}
          >
            <FormControl>
              <FormLabel component='legend'>Basic Info</FormLabel>
              <FormGroup>
                <div>
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Name'
                    name='name'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Email'
                    name='email'
                    required
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Phone: +1xxxXXXxxxx'
                    name='phone'
                    required
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </div>

                <div>
                  <TextField
                    variant='outlined'
                    size='small'
                    label='First name'
                    name='first_name'
                    required
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    helperText={formik.touched.first_name && formik.errors.first_name}
                  />
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Last name'
                    name='last_name'
                    required
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.touched.last_name && formik.errors.last_name}
                  />
                  <StyledLabel
                    label='Active'
                    control={
                      <Switch
                        color='primary'
                        name='active'
                        checked={formik.values.active}
                        onChange={formik.handleChange}
                      />
                    }
                  />
                  <StyledLabel
                    label='Trusted'
                    control={
                      <Switch
                        color='primary'
                        name='is_trusted'
                        checked={formik.values.is_trusted}
                        onChange={formik.handleChange}
                      />
                    }
                  />

                  <StyledLabel
                    label='Is Employee'
                    control={
                      <Switch
                        color='primary'
                        name='is_employee'
                        checked={formik.values.is_employee}
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </div>

                <div>
                  <StyledLabel
                    label='Is a Business'
                    control={
                      <Switch
                        color='primary'
                        name='contractor_is_business'
                        checked={formik.values.contractor_is_business}
                        onChange={formik.handleChange}
                      />
                    }
                  />

                  {formik.values.contractor_is_business && (
                    <>
                      <TextField
                        variant='outlined'
                        size='small'
                        label='Business Name'
                        name='business_name'
                        disabled={!formik.values.contractor_is_business}
                        required={formik.values.contractor_is_business}
                        value={formik.values.business_name}
                        onChange={formik.handleChange}
                        error={formik.touched.business_name && Boolean(formik.errors.business_name)}
                        helperText={formik.touched.business_name && formik.errors.business_name}
                      />
                      <TextField
                        variant='outlined'
                        size='small'
                        label='ein'
                        name='ein'
                        disabled={!formik.values.contractor_is_business}
                        required={formik.values.contractor_is_business}
                        value={formik.values.ein}
                        onChange={formik.handleChange}
                        error={formik.touched.ein && Boolean(formik.errors.ein)}
                        helperText={formik.touched.ein && formik.errors.ein}
                      />
                    </>
                  )}

                  {!formik.values.contractor_is_business && (
                    <TextField
                      variant='outlined'
                      size='small'
                      label='ssn'
                      name='ssn'
                      disabled={formik.values.contractor_is_business}
                      required={!formik.values.contractor_is_business}
                      value={formik.values.ssn}
                      onChange={formik.handleChange}
                      error={formik.touched.ssn && Boolean(formik.errors.ssn)}
                      helperText={formik.touched.ssn && formik.errors.ssn}
                    />
                  )}
                </div>

                <div>
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Address Line 1'
                    name='address_line_one'
                    fullWidth
                    style={{ width: '100%' }}
                    value={formik.values.address_line_one}
                    onChange={formik.handleChange}
                  />
                  <br />
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Address Line 2'
                    name='address_line_two'
                    value={formik.values.address_line_two}
                    fullWidth
                    style={{ width: '100%' }}
                    onChange={formik.handleChange}
                  />
                </div>

                <div>
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Zip'
                    name='zip'
                    value={formik.values.zip}
                    onChange={formik.handleChange}
                    error={formik.touched.zip && Boolean(formik.errors.zip)}
                    helperText={formik.touched.zip && formik.errors.zip}
                  />
                  <TextField
                    variant='outlined'
                    size='small'
                    label='City'
                    name='city'
                    value={formik.values.city}
                    onChange={formik.handleChange}
                  />
                </div>

                <div>
                  <FormControl size='small' variant='outlined'>
                    <InputLabel>State</InputLabel>
                    <Select label='State' name='state' value={formik.values.state} onChange={formik.handleChange}>
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {stateMenuItems}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ maxWidth: '100px' }}
                    variant='outlined'
                    size='small'
                    label='State free style'
                    name='state'
                    value={formik.values.state}
                    onChange={formik.handleChange}
                  />
                  <FormControl size='small' variant='outlined'>
                    <InputLabel>Market</InputLabel>
                    <Select label='Market' name='market' value={formik.values.market} onChange={formik.handleChange}>
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {marketMenuItemsByState[formik.values.state]}
                    </Select>
                  </FormControl>
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Market free style'
                    name='display_market'
                    value={formik.values.display_market}
                    onChange={formik.handleChange}
                  />
                </div>

                <div>
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Latitde'
                    name='lat'
                    value={formik.values.lat}
                    onChange={formik.handleChange}
                    error={formik.touched.lat && Boolean(formik.errors.lat)}
                    helperText={formik.touched.lat && formik.errors.lat}
                  />
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Longitude'
                    name='lng'
                    value={formik.values.lng}
                    onChange={formik.handleChange}
                    error={formik.touched.lng && Boolean(formik.errors.lng)}
                    helperText={formik.touched.lng && formik.errors.lng}
                  />
                </div>

                <div>
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Emergency contact info'
                    name='emergency_contact'
                    multiline
                    style={{ width: '90%' }}
                    value={formik.values.emergency_contact}
                    onChange={formik.handleChange}
                    inputProps={{ style: { minHeight: '3em' } }}
                  />
                </div>
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel component='legend'>Pay Related Info</FormLabel>
              <FormGroup>
                <div>
                  <FormControl fullWidth size='small' variant='outlined'>
                    <InputLabel>Quote Commission Structure</InputLabel>
                    <Select
                      label='Quote Commission Structure'
                      name='quote_commission_structure'
                      value={formik.values.quote_commission_structure}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value='labor cost'>Labor cost based (Percentage)</MenuItem>
                      <MenuItem value='hourly'>Hourly based (Static amount)</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth size='small' variant='outlined'>
                    <InputLabel>B2B Invoice Commission Structure</InputLabel>
                    <Select
                      label='B2B Invoice Commission Structure'
                      name='b_to_b_invoice_commission_structure'
                      value={formik.values.b_to_b_invoice_commission_structure}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value='labor cost'>Labor cost based (Percentage)</MenuItem>
                      <MenuItem value='panel'>Panel based (Static amount)</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Hourly Rate'
                    name='hourly_rate'
                    InputProps={{ inputComponent: PriceInput }}
                    required={formik.values.is_employee}
                    value={formik.values.hourly_rate}
                    onChange={formik.handleChange}
                    error={formik.touched.hourly_rate && Boolean(formik.errors.hourly_rate)}
                    helperText={formik.touched.hourly_rate && formik.errors.hourly_rate}
                  />
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Commission Amount (1/2 day of work)'
                    name='commission_amount'
                    InputProps={{ inputComponent: PriceInput }}
                    required={formik.values.is_employee}
                    value={formik.values.commission_amount}
                    onChange={formik.handleChange}
                    error={formik.touched.commission_amount && Boolean(formik.errors.commission_amount)}
                    helperText={formik.touched.commission_amount && formik.errors.commission_amount}
                  />
                  <TextField
                    variant='outlined'
                    size='small'
                    type='number'
                    label='Commission Percent'
                    name='commission_percent'
                    required={!formik.values.is_employee}
                    value={formik.values.commission_percent}
                    onChange={formik.handleChange}
                    error={formik.touched.commission_percent && Boolean(formik.errors.commission_percent)}
                    helperText={formik.touched.commission_percent && formik.errors.commission_percent}
                  />

                  <TextField
                    variant='outlined'
                    size='small'
                    type='number'
                    label='Technician Charges Commission Percent'
                    name='tech_charges_commission_percent'
                    inputProps={{ max: 100 }}
                    required={!formik.values.is_employee}
                    value={formik.values.tech_charges_commission_percent}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.tech_charges_commission_percent &&
                      Boolean(formik.errors.tech_charges_commission_percent)
                    }
                    helperText={
                      formik.touched.tech_charges_commission_percent && formik.errors.tech_charges_commission_percent
                    }
                  />
                </div>

                <FormLabel component='legend'>
                  Display Fields Only (These are not used for calculations, just to display information)
                </FormLabel>

                <div>
                  <TextField
                    variant='outlined'
                    size='small'
                    type='number'
                    label='Net Labor Commission Percent'
                    name='net_labor_revenue_percentage'
                    required={!formik.values.is_employee}
                    value={formik.values.net_labor_revenue_percentage}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.net_labor_revenue_percentage && Boolean(formik.errors.net_labor_revenue_percentage)
                    }
                    helperText={
                      formik.touched.net_labor_revenue_percentage && formik.errors.net_labor_revenue_percentage
                    }
                  />

                  <TextField
                    variant='outlined'
                    size='small'
                    type='number'
                    label='Supplies Cost Percent'
                    name='supplies_cost_percentage'
                    inputProps={{ max: 100 }}
                    required={!formik.values.is_employee}
                    value={formik.values.supplies_cost_percentage}
                    onChange={formik.handleChange}
                    error={formik.touched.supplies_cost_percentage && Boolean(formik.errors.supplies_cost_percentage)}
                    helperText={formik.touched.supplies_cost_percentage && formik.errors.supplies_cost_percentage}
                  />
                </div>
              </FormGroup>
            </FormControl>
            <br />
            <FormControl>
              <FormLabel component='legend'>System Attributes and Skills</FormLabel>
              <FormGroup>
                <div>
                  <Autocomplete
                    size='small'
                    multiple
                    options={techSkillsets}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} size='small' />
                        {option}
                        {option.includes('level') && <> jobs </>}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        sx={{ width: '90% !important' }}
                        size='small'
                        {...params}
                        label='Skillsets'
                        placeholder='skillset'
                      />
                    )}
                    value={formik.values.skillsets}
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    onChange={(e, v) => formik.setFieldValue('skillsets', v ? v : initialValues.skillsets)}
                  />
                  <TextField
                    variant='outlined'
                    size='small'
                    type='number'
                    label='Max driving distance (miles)'
                    name='max_driving_distance'
                    value={formik.values.max_driving_distance}
                    onChange={formik.handleChange}
                  />
                </div>
                <StyledLabel
                  label='Elite Make Jobs'
                  control={
                    <Switch
                      color='primary'
                      name='is_elite'
                      checked={formik.values.is_elite}
                      onChange={formik.handleChange}
                    />
                  }
                />

                <div>
                  <StyledLabel
                    label='B-to-B work'
                    control={
                      <Switch
                        color='primary'
                        name='is_b_to_b'
                        checked={formik.values.is_b_to_b}
                        onChange={formik.handleChange}
                      />
                    }
                  />
                  {formik.values.is_b_to_b && (
                    <TextField
                      variant='outlined'
                      size='small'
                      label='B-to-B Business Name'
                      name='b_to_b_business_name'
                      required={formik.values.is_b_to_b}
                      value={formik.values.b_to_b_business_name}
                      style={{ minWidth: '250px' }}
                      onChange={formik.handleChange}
                      error={formik.touched.b_to_b_business_name && Boolean(formik.errors.b_to_b_business_name)}
                      helperText={formik.touched.b_to_b_business_name && formik.errors.b_to_b_business_name}
                    />
                  )}
                  {formik.values.is_b_to_b && (
                    <StyledLabel
                      label='B-to-B Square Fees'
                      control={
                        <Switch
                          color='primary'
                          name='enable_b2b_square_fee'
                          checked={!!formik.values.enable_b2b_square_fee}
                          onChange={formik.handleChange}
                        />
                      }
                    />
                  )}
                </div>
                {formik.values.is_b_to_b && (
                  <Autocomplete
                    size='small'
                    multiple
                    options={dealersData?.bToBDealers?.map(dealer => ({
                      label: dealer.businessName,
                      value: dealer.id,
                    }))}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} size='small' />
                        {option.label}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        sx={{ width: '90% !important' }}
                        size='small'
                        {...params}
                        label='B2B Dealers'
                        placeholder='select one or more dealers'
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          size='small'
                          label={dealersData?.bToBDealers?.find(dealer => dealer.id === option).businessName}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    value={formik.values.b_to_b_dealer_ids}
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    isOptionEqualToValue={(option, value) => (option ? option.value === value : false)}
                    getOptionSelected={option => formik.values.b_to_b_dealer_ids.includes(option.value)}
                    onChange={(e, v) =>
                      formik.setFieldValue(
                        'b_to_b_dealer_ids',
                        v ? v.map(option => option?.value || option) : initialValues.b_to_b_dealer_ids
                      )
                    }
                  />
                )}
              </FormGroup>
            </FormControl>

            {!formik.values.is_employee && (
              <div>
                <TextField
                  sx={{ maxWidth: '100px' }}
                  variant='outlined'
                  size='small'
                  label='Vehicle color'
                  name='vehicle_color'
                  value={formik.values.vehicle_color}
                  onChange={formik.handleChange}
                />

                <FormControl sx={{ minWidth: '100px' }} size='small' variant='outlined'>
                  <InputLabel>Year</InputLabel>
                  <Select
                    label='Year'
                    name='vehicle_year'
                    value={formik.values.vehicle_year + ''}
                    onChange={(e, v) => formik.handleChange(e, +v)}
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {yearsData.fgVehicleYears
                      .slice()
                      .sort((a, b) => (a < b ? 1 : -1))
                      .map(year => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Autocomplete
                  sx={{ display: 'inline-block' }}
                  size='small'
                  options={
                    makesData?.fgVehicleMakes
                      .slice()
                      .sort((a, b) => (a.makeShortName > b.makeShortName ? 1 : -1))
                      .map(make => make.makeShortName) || []
                  }
                  value={formik.values.vehicle_make_short_name}
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  onChange={(e, v) =>
                    formik.setFieldValue('vehicle_make_short_name', v ? v : initialValues.vehicle_make_short_name)
                  }
                  getOptionLabel={getMakeOptionLabel}
                  getOptionSelected={make => make === formik.values.vehicle_make_short_name}
                  renderInput={params => <TextField {...params} label='Make' />}
                />
                <Autocomplete
                  sx={{ display: 'inline-block' }}
                  size='small'
                  options={
                    modelsData?.fgVehicleModels
                      .slice()
                      .sort((a, b) => (a.modelShortName > b.modelShortName ? 1 : -1))
                      .map(model => model.modelShortName) || []
                  }
                  value={formik.values.vehicle_model_short_name}
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  onChange={(e, v) =>
                    formik.setFieldValue('vehicle_model_short_name', v ? v : initialValues.vehicle_model_short_name)
                  }
                  getOptionLabel={getModelOptionLabel}
                  getOptionSelected={model => model === formik.values.vehicle_model_short_name}
                  renderInput={params => <TextField {...params} label='Model' />}
                />
              </div>
            )}

            <div
              style={{
                background: '#eee',
                padding: '10px 0px',
                maxWidth: '735px',
              }}
            >
              <b style={{ padding: '5px 10px' }}>Availability: military time, ex. 8am to 5pm is "8-17"</b>
              <br />
              <span style={{ padding: '0px 10px', display: 'inline-block' }}>
                also, if daily availability is segmented for example (7am-noon, 2pm-7pm), you can enter "7-12,14-19"
              </span>
              <br />
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {daysOfWeek.map(day => (
                  <Box sx={{ maxWidth: '48%', display: 'flex' }}>
                    <TextField
                      key={day.toUpperCase()}
                      variant='outlined'
                      size='small'
                      label={day.toUpperCase()}
                      name={`availability.${day}`}
                      value={formik.values.availability[day]}
                      onChange={formik.handleChange}
                    />
                    <FormControlLabel
                      size='small'
                      name={`availability.${day}SingleJobLimit`}
                      sx={{ fontSize: '14px !important' }}
                      onChange={formik.handleChange}
                      control={<Checkbox size='small' checked={formik.values.availability[`${day}SingleJobLimit`]} />}
                      label='1 job/day'
                    />
                  </Box>
                ))}
              </Box>
            </div>

            <br />

            <div>
              <TextField
                variant='outlined'
                size='small'
                InputProps={{ inputComponent: PriceInput }}
                label='equipment loan weekly deduction'
                name='loan_weekly_deduction'
                value={formik.values.loan_weekly_deduction}
                onChange={evt => formik.setFieldValue('loan_weekly_deduction', Number(evt.target.value))}
              />
              <TextField
                variant='outlined'
                size='small'
                InputProps={{ inputComponent: PriceInput }}
                label='other loan weekly deduction'
                name='other_loan_weekly_deduction'
                value={formik.values.other_loan_weekly_deduction}
                onChange={evt => formik.setFieldValue('other_loan_weekly_deduction', Number(evt.target.value))}
              />
            </div>

            <div>
              <TextField
                variant='outlined'
                size='small'
                InputProps={{ style: { minHeight: '3em' } }}
                style={{ width: '100%' }}
                multiline
                label='Notes'
                name='note'
                value={formik.values.note}
                onChange={formik.handleChange}
              />
            </div>

            <div>
              <TextField
                variant='outlined'
                size='small'
                style={{ width: '100%' }}
                multiline
                label='Notification Message'
                name='notification'
                value={notificationMessage}
                onChange={e => setNotificationMessage(e.target.value)}
                InputProps={{ style: { minHeight: '1em' } }}
              />
              <Collapse in={!!notificationMessage}>
                <Button disabled={!notificationMessage} onClick={handleAddNotification}>
                  Add
                </Button>
              </Collapse>

              {formik.values.notifications.map(item => (
                <Chip key={item} sx={{ mb: '.125rem' }} label={item} onDelete={() => handleRemoveNotification(item)} />
              ))}
            </div>

            <div>
              {validationErrors.length > 0 && (
                <>
                  <br />
                  <div style={{ color: 'red' }}>
                    {validationErrors.map(error => (
                      <div key={error}>{error}</div>
                    ))}
                  </div>
                  <br />
                </>
              )}

              <Button
                variant='contained'
                color='primary'
                disabled={!formik.dirty || !formik.isValid}
                type='submit'
                onClick={formik.handleSubmit}
              >
                <SaveIcon />
                Save
              </Button>
              <Button onClick={() => history.goBack()}>Cancel</Button>
              <Button disabled={!formik.dirty} onClick={handleReset}>
                Reset
              </Button>
            </div>
          </Box>
        </Grid>

        <Grid item xs={3} sx={{ paddingLeft: 2 }}>
          <div style={{ display: 'flex', marginTop: '50px' }}>
            <TextField
              label='Add Offday'
              type='date'
              style={{ marginLeft: '8px', marginRight: '8px', width: '200px' }}
              onChange={handleNewOffDayChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button disabled={newOffDay.length !== 10} variant='contained' onClick={handleAddNewOffday}>
              Add
            </Button>
          </div>
          <br />
          {technician.offdays.length > 0 ? (
            <span style={{ padding: '10px', color: '#555', fontSize: '13px' }}> Current off days: </span>
          ) : (
            <span style={{ padding: '10px', color: '#555', fontSize: '14px' }}> *No offdays to show </span>
          )}
          <br />
          {(technician.offdays &&
            technician.offdays.map(offday => (
              <StyledOffDayItem key={offday}>
                {DateTime.fromISO(offday).toFormat('cccc, yyyy-LL-dd')}
                <CancelIcon onClick={() => handleRemoveOffday(offday)} />
              </StyledOffDayItem>
            ))) || <div>none</div>}
        </Grid>
      </Grid>
    </>
  )
}

export default ShowEdit
