import { useState, useEffect, useContext, useMemo } from 'react'
import { GlobalContext } from 'GlobalStore'
import { useQuery, gql } from '@apollo/client'
import { makeStyles, useTheme } from '@mui/styles'
import { DateTime } from 'luxon'
import { UserContext } from 'UserStore'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import { DistanceMatrixService, Marker, GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import Geocode from 'react-geocode'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Autocomplete,
  Switch,
  Collapse,
} from '@mui/material'
import { Dollars, keysToCamel } from 'tools'
import { eliteVehicleMakes } from 'constants.js'
import { JobCell, GET_JOBS, GET_APPOINTMENTS } from 'Leads/AvailabilityMap'
import { TireProcurementSections } from '../Appointments/AvailabilityMap'
import { jobFollowupReasonOptions } from 'data/reasons'
import { AutoglassProcurementSections } from '../Appointments/components/AutoglassProcurementSection'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import CommissionChargeBacks from '../Quote/CommissionChargeBacks'

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
Geocode.setApiKey(GOOGLE_MAPS_API_KEY)

const MIN_ZOOM_LEVEL = 6

const TECHNICIANS = gql`
  query ($filter: JSON) {
    technicians(filter: $filter) {
      id
      name
      email
      phone
      addressLineOne
      addressLineTwo
      city
      state
      zip
      lat
      lng
      note
      replacements
      availability
      offdays
      active
      note
      isElite
      skillsets
    }
  }
`

// function technicianRequestedOff(technician, dateString) {
//   return technician.offdays.includes(dateString)
// }

// function technicianNormalDayOff(technician, dateString) {
//   return
// }

// const JOBS_FOR_LEAD = gql`
//   query getJobs($leadId: ID!) {
//     jobs(leadId: $leadId) {
//       id
//       leadId
//       startDatetime
//     }
//   }
// `

const QUOTE_FOR_JOB_VALUE = gql`
  query getQuote($id: ID!) {
    quote(id: $id) {
      id
      postTaxGrandTotal
      balanceAmountDue
      preJobAmountDue
      payments
    }
  }
`
const JobValue = ({ quoteId }) => {
  const { loading, error, data } = useQuery(QUOTE_FOR_JOB_VALUE, {
    variables: { id: quoteId },
  })
  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return (
    <div style={{ cursor: 'pointer' }} onClick={() => window.open(`/quotes/${quoteId}`)}>
      labor: <Dollars value={data.quote.postTaxGrandTotal} />
    </div>
  )
}

const jobSummaryText = (dateString, job) => (
  <>
    {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)} -
    <br />
    {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)}
  </>
)

const RootStyles = makeStyles(theme => ({
  scheduler_warning: {
    color: 'red',
    margin: '1em 0',
    border: '1px solid darkorange',
    padding: '0.2em',
    width: 'fit-content',
  },
}))

const TheMap = ({ technicians, lead, quote, leadCoordinates }) => {
  const [techsInBounds, setTechsInbounds] = useState([])
  const [map, setMap] = useState(null)
  const [suppliers, setSuppliers] = useState([])
  const [glassSuppliers, setGlassSuppliers] = useState([])

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  })

  const handleOnIdle = () => {
    if (map.zoom > MIN_ZOOM_LEVEL) {
      const bounds = map?.getBounds()

      const newInBoundTechs = technicians.filter(
        technician =>
          Number(technician.lat) > bounds.getSouthWest().lat() &&
          Number(technician.lng) > bounds.getSouthWest().lng() &&
          Number(technician.lat) < bounds.getNorthEast().lat() &&
          Number(technician.lng) < bounds.getNorthEast().lng()
      )
      setTechsInbounds(newInBoundTechs)
    } else {
      setTechsInbounds([])
    }
  }

  const onLoad = map => setMap(map)
  const onUnmount = map => setMap(null)

  if (!isLoaded) return <div>Loading google map</div>

  const partsReadyForPunchout =
    quote?.parts?.reduce(
      (acc, item) =>
        acc.find(i => i?.tireConnectData?.id === item?.tireConnectData?.id)
          ? [
              ...acc.filter(i => i?.tireConnectData?.id !== item?.tireConnectData?.id),
              {
                ...item,
                quantity: quote.parts.filter(i => i?.tireConnectData?.id === item?.tireConnectData?.id).length,
                allPartIds: quote.parts
                  .filter(i => i?.tireConnectData?.id === item?.tireConnectData?.id)
                  .map(i => i?.id),
              },
            ]
          : [...acc, item],
      []
    ) || []

  const omegaParts =
    quote?.parts?.reduce(
      (acc, item) =>
        acc.find(i => i?.nagsGlassId === item?.nagsGlassId)
          ? [
              ...acc.filter(i => i?.nagsGlassId !== item?.nagsGlassId),
              {
                ...item,
                quantity: quote.parts.filter(i => i?.nagsGlassId === item?.nagsGlassId).length,
                allPartIds: quote.parts.filter(i => i?.nagsGlassId === item?.nagsGlassId).map(i => i?.id),
              },
            ]
          : [...acc, item],
      []
    ) || []

  return (
    <Box>
      <Grid container>
        <Grid
          item
          sx={{
            '& .technicianLabel': {
              background: '#650606f7',
              color: '#fff !important',
              padding: '.25rem .5rem',
              borderRadius: '20px',
            },
            '& .supplierLabel': {
              background: '#226e26f7',
              color: '#fff !important',
              padding: '.25rem .5rem',
              borderRadius: '20px',
            },
            '& .customerLabel': {
              background: '#222222f7',
              color: '#fff !important',
              padding: '.25rem',
              borderRadius: '20px',
            },
          }}
          xs={partsReadyForPunchout.length > 0 ? 8 : 12}
        >
          <GoogleMap
            zoom={10}
            mapContainerStyle={{
              height: '500px',
              width: '900px',
            }}
            onIdle={handleOnIdle}
            center={leadCoordinates}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <Marker
              position={leadCoordinates}
              label={{
                text: `Customer: ${lead.name} (${lead.repairLocation})`,
                className: 'customerLabel',
              }}
              icon='https://driveway-production.s3.us-east-2.amazonaws.com/img/customer_map_marker.png'
            />

            {suppliers.map(supplier => (
              <Marker
                key={`${supplier.supplierName}`}
                label={{
                  text: `${supplier.totalQuantity} - ${supplier.supplierName} ${supplier.businessName} - ${supplier.addressLineOne}`,
                  className: 'supplierLabel',
                }}
                position={{ lat: Number(supplier.lat), lng: Number(supplier.lng) }}
              />
            ))}
            {techsInBounds.map(technician => (
              <Marker
                key={`${technician.id}`}
                label={{ text: technician.name, className: 'technicianLabel' }}
                position={{
                  lat: Number(technician.lat),
                  lng: Number(technician.lng),
                }}
              />
            ))}
          </GoogleMap>
        </Grid>
        <Grid item xs={partsReadyForPunchout.length > 0 ? 4 : 0}>
          {partsReadyForPunchout.map(part => (
            <TireProcurementSections
              key={part.id}
              assignedTechnician={techsInBounds.find(tech => tech.id === quote.assignedTechnicianId) || null}
              leadCoordinates={leadCoordinates}
              quote={quote}
              suppliers={suppliers}
              part={part}
              setSuppliers={setSuppliers}
            />
          ))}

          {omegaParts.map(part => (
            <AutoglassProcurementSections
              key={part.id}
              assignedTechnician={techsInBounds.find(tech => tech.id === quote.assignedTechnicianId) || null}
              leadCoordinates={leadCoordinates}
              quote={quote}
              suppliers={suppliers}
              part={part}
              setSuppliers={setGlassSuppliers}
              zip={lead.zip}
            />
          ))}
        </Grid>
      </Grid>

      <TechniciansInBounds technicians={techsInBounds} leadCoordinates={leadCoordinates} quote={quote} lead={lead} />

      {techsInBounds.length === 0 && <div>zoom/pan the map to show technicians</div>}
      {map?.zoom <= MIN_ZOOM_LEVEL && <b style={{ color: 'red' }}>Too far, zoom in to see technicians</b>}
    </Box>
  )
}

const TechniciansInBoundsStyles = makeStyles(theme => ({
  techSection: {
    border: '1px solid #c9c9c9',
    marginTop: '1em',
    backgroundColor: '#f9f9f9ba',
  },
}))
const TechniciansInBounds = ({ technicians, leadCoordinates, quote, lead }) => {
  const classes = TechniciansInBoundsStyles()
  const [travelDistances, setTravelDistances] = useState([])

  const receiveTravelDistance = travelDistance => {
    if (travelDistances.find(distance => distance.technicianId === travelDistance.technicianId)) return

    setTravelDistances(prev => [travelDistance, ...prev])
  }

  const prefetchedForTech = technician => travelDistances.find(result => result.technicianId === technician.id)

  const jobRequiresEliteTechnician = eliteVehicleMakes.includes(lead.makeShortName)

  const compareTravelDistances = (a, b) =>
    (travelDistances.find(e => e.technicianId === a.id)
      ? travelDistances.find(e => e.technicianId === a.id).distanceValue
      : 0) -
    (travelDistances.find(e => e.technicianId === b.id)
      ? travelDistances.find(e => e.technicianId === b.id).distanceValue
      : 0)

  return (
    <>
      {technicians
        .sort((a, b) => {
          // bump elite technicians to the top of the list & sort them by shortest driving distance first
          if (jobRequiresEliteTechnician) {
            if (a.isElite && !b.isElite) {
              return -1
            }
            if (!a.isElite && b.isElite) {
              return 1
            }
            return compareTravelDistances(a, b)
          }
          return compareTravelDistances(a, b)
        })
        .map(technician => (
          <div className={classes.techSection} key={technician.id}>
            <TechSchedule
              prefetchedTravelDistance={prefetchedForTech(technician)}
              leadCoordinates={leadCoordinates}
              sendMyTravelDistanceUp={receiveTravelDistance}
              key={technician.id}
              quote={quote}
              lead={lead}
              technician={technician}
            />
          </div>
        ))}
    </>
  )
}

const NewJob = ({ lead, quote }) => {
  const forTechnicianIdOnly = quote?.assignedTechnicianId

  const [global] = useContext(GlobalContext)
  const { loading, error, data } = useQuery(TECHNICIANS, {
    variables: {
      filter: { active: true },
    },
  })
  const classes = RootStyles()
  const [leadCoordinates, setLeadCoordinates] = useState({
    lat: null,
    lng: null,
  })

  useEffect(() => {
    if (lead.addressLineOne) {
      Geocode.fromAddress(`${lead.addressLineOne}, ${lead.city}, ${lead.state}`).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          setLeadCoordinates({ lat, lng })
        },
        error => {
          console.error(error)
        }
      )
    } else {
      Geocode.fromAddress(`${lead.zip}`).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          setLeadCoordinates({ lat, lng })
        },
        error => {
          console.error(error)
        }
      )
    }
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const maybeFilteredTechnicians = forTechnicianIdOnly
    ? data.technicians.filter(tech => tech.id === forTechnicianIdOnly)
    : data.technicians

  const onlyForTech = global.technicians.find(tech => tech.id === forTechnicianIdOnly)

  const addressIsValid = lead.addressLineOne && lead.addressLineOne.length > 2
  const hasMarket = lead.market && lead.market.length > 0

  return (
    <>
      {addressIsValid ? <></> : <div className={classes.scheduler_warning}>Address required before creating a job</div>}
      {hasMarket ? <></> : <div className={classes.scheduler_warning}>The Lead needs to be assigned to a market</div>}
      {forTechnicianIdOnly && (
        <b style={{ color: 'red', margin: '1em' }}>
          Only one technician per Quote, showing ONLY: {onlyForTech && onlyForTech.name}
        </b>
      )}

      {(!leadCoordinates.lat || !leadCoordinates.lng) && <div>no lead coordinates</div>}

      {leadCoordinates.lat && leadCoordinates.lng && (
        <TheMap technicians={maybeFilteredTechnicians} lead={lead} quote={quote} leadCoordinates={leadCoordinates} />
      )}
    </>
  )
}

function CalendarDayTableCell({
  scheduleDay,
  technician,
  ignoreAvailability,
  disablePlusButton,
  onClickPlusButton,
  chunkIndex,
  leadCoordinates,
  showDrivingDistanceDays,
}) {
  const {
    // scheduleDayDateTime,
    scheduleDayOfWeek,
    scheduleDayHeader,
    scheduleDayRequestedOff,
    scheduleDayNormallyOff,
    scheduleDayJobs,
  } = useMemo(() => {
    const scheduleDayDateTime = DateTime.fromISO(scheduleDay.dateString, { zone: 'utc' })
    return {
      scheduleDayDateTime,
      scheduleDayOfWeek: scheduleDayDateTime.toFormat('cccc').toLowerCase(),
      scheduleDayHeader: scheduleDayDateTime.toFormat('ccc, LLL d'),
      scheduleDayRequestedOff: technician.offdays.includes(scheduleDay.dateString),
      scheduleDayNormallyOff: technician.availability[scheduleDayDateTime.toFormat('EEEE').toLowerCase()].length === 0,
      scheduleDayJobs: scheduleDay.jobs.filter(job => !job.heldForReschedulingAt),
    }
  }, [scheduleDay, technician])

  const showPlusButton = useMemo(() => {
    return ignoreAvailability || (!scheduleDayRequestedOff && !scheduleDayNormallyOff)
  }, [scheduleDayRequestedOff, scheduleDayNormallyOff, ignoreAvailability])

  return (
    <TableCell
      cy-data='schedule-calendar-day'
      key={scheduleDay.dateString}
      className={scheduleDayOfWeek}
      sx={{
        padding: '5px',
        verticalAlign: 'top',
        width: '120px',
        minWidth: '120px',
        borderLeft: '1px solid lightgray',
        borderRight: '1px solid lightgray',
        '&.saturday, &.sunday': {
          border: '2px solid gray',
          borderTop: 0,
          borderRight: 0,
        },
      }}
    >
      <Box style={{ borderBottom: '1px solid rgb(203 203 203)' }}>
        {showPlusButton && (
          <button cy-data='create-job-button' disabled={disablePlusButton} onClick={onClickPlusButton}>
            +
          </button>
        )}
        &nbsp;
        {scheduleDayHeader}
      </Box>

      {scheduleDayJobs.map(job => (
        <JobCell
          chunkIndex={chunkIndex}
          leadCoordinates={leadCoordinates}
          showDrivingDistanceDays={showDrivingDistanceDays}
          key={job.id}
          job={job}
          scheduleDay={scheduleDay}
        />
      ))}

      <br />

      {scheduleDayRequestedOff && (
        <span
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            textAlign: 'center',
            border: '2px solid gray',
            color: 'gray',
            fontWeight: 'bold',
          }}
        >
          REQUESTED OFF
        </span>
      )}

      {scheduleDayNormallyOff && (
        <span
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            textAlign: 'center',
            border: '2px solid gray',
            color: 'gray',
            fontWeight: 'bold',
          }}
        >
          OFF DAY
        </span>
      )}

      {scheduleDay.jobs.length === 0 && <b style={{ opacity: 0 }}> No jobs </b>}
    </TableCell>
  )
}

const techScheduleStyles = makeStyles(theme => ({
  scheduleCell: {
    padding: '5px',
    verticalAlign: 'top',
    width: '120px',
    minWidth: '120px',
    borderLeft: '1px solid lightgray',
    borderRight: '1px solid lightgray',
    '&.sunday': {
      border: '2px solid gray',
      borderTop: 0,
      borderRight: 0,
    },
    '&.saturday': {
      border: '2px solid gray',
      borderTop: 0,
      borderLeft: 0,
    },
  },
  job: {
    marginTop: '3px',
    fontSize: 13,
    backgroundColor: 'aliceblue',
    padding: '0px 3px',
    borderRadius: '2px',
    border: '1px solid lavender',
  },
  tech_header_cell: {
    padding: '8px',
    lineHeight: '1em',
  },
  table: {
    margin: '0 2px 3px 2px',
    width: 'calc(100% - 4px)',
  },
  disabled: {
    opacity: 0.65,
  },
  isElite: {
    border: '3px solid royalblue',
  },
}))
const availabilityRegex = /(\d+)-(\d+)/
const formattedAvailability = arr =>
  arr
    .map(rangeString => {
      const match = rangeString.match(availabilityRegex)
      const startDT = DateTime.fromObject({
        zone: 'utc',
        hour: Number(match[1]),
      })
      const endDT = DateTime.fromObject({ zone: 'utc', hour: Number(match[2]) })
      return `${startDT.toFormat('ha')} - ${endDT.toFormat('ha')}`
    })
    .join(', ')

const GET_QUOTE_JOBS = gql`
  query getJobs($quoteId: ID!) {
    jobs(quoteId: $quoteId) {
      technicianId
      canceledAt
    }
  }
`

const TechSchedule = ({
  lead,
  quote,
  technician,
  prefetchedTravelDistance,
  sendMyTravelDistanceUp,
  leadCoordinates,
}) => {
  const classes = techScheduleStyles()

  const [daysToShow, setDaysToShow] = useState(14)

  const [modalScheduleDay, setModalScheduleDay] = useState(null)
  const [ignoreTechnicianAvailability, setIgnoreTechnicianAvailability] = useState(false)

  const [myTravelDistance, setMyTravelDistance] = useState(prefetchedTravelDistance)

  const [showDrivingDistanceDays, setShowDrivingDistanceDays] = useState(0)

  const handleTravelDistanceResult = result => {
    const deepResult = result.rows[0].elements[0]
    setMyTravelDistance({
      technicianId: technician.id,
      distanceValue: deepResult.distance.value,
      distanceString: deepResult.distance.text,
      durationString: deepResult.duration.text,
    })
  }

  useEffect(() => {
    if (prefetchedTravelDistance) return
    if (!myTravelDistance) return
    sendMyTravelDistanceUp(myTravelDistance)
  }, [myTravelDistance])

  const beginningOfUtcDay = DateTime.local().setZone('utc').startOf('day')
  const { loading, error, data, refetch } = useQuery(GET_JOBS, {
    variables: {
      technicianId: technician.id,
      startDatetime: beginningOfUtcDay.toISO(),
      endDatetime: beginningOfUtcDay.plus({ days: daysToShow }).endOf('day').toISO(),
    },
  })

  const {
    loading: loadingAppts,
    error: errorAppts,
    data: apptsData,
    refetch: refetchAppts,
  } = useQuery(GET_APPOINTMENTS, {
    variables: {
      technicianId: technician.id,
      startDatetime: beginningOfUtcDay.toISO(),
      endDatetime: beginningOfUtcDay.plus({ days: daysToShow }).endOf('day').toISO(),
    },
  })

  useEffect(() => {
    data && refetch && refetch()
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const populatedSchedule = [...Array(daysToShow).keys()].map(i => {
    const thisDay = DateTime.local()
      .setZone('utc')
      .startOf('day')
      .plus({ days: i - 1 })
    const dateString = thisDay.toISODate()

    const jobsOnThisDay = data.jobs
      .filter(job => DateTime.fromISO(job.startDatetime, { zone: 'utc' }).hasSame(thisDay, 'day'))
      .map(job => ({ ...job, type: 'job' }))

    const apptsOnThisDay =
      apptsData?.appointments
        .filter(job => DateTime.fromISO(job.startDatetime, { zone: 'utc' }).hasSame(thisDay, 'day'))
        .map(appt => ({ ...appt, type: 'appointment' })) || []

    return {
      dateString: dateString,
      jobs: jobsOnThisDay.concat(apptsOnThisDay).sort((a, b) => (a.startDatetime > b.startDatetime ? 1 : -1)),
    }
  })

  const handleClickPlusButton = scheduleDay => {
    setModalScheduleDay(scheduleDay)
  }

  const formattedStringForAvailRange = string => {
    const match = string.match(/(\d+)-(\d+)/)
    const startHour = DateTime.fromObject({ hour: match[1] }).toFormat('ha')
    const endHour = DateTime.fromObject({ hour: match[2] }).toFormat('ha')
    return `${startHour}- ${endHour}`
  }

  const handleClickShowTwoMoreWeeks = () => {
    setDaysToShow(daysToShow + 14)
  }

  const chunk = (arr, size) =>
    arr.reduce((acc, _, i) => {
      if (i % size === 0) acc.push(arr.slice(i, i + size))
      return acc
    }, [])

  const chunkedPopulatedSchedule = chunk(populatedSchedule, 14)

  const addressIsValid = lead.addressLineOne && lead.addressLineOne.length > 2

  const requestedOff = dateString => technician.offdays.includes(dateString)

  const offDay = dateString =>
    technician.availability[DateTime.fromISO(dateString).toFormat('EEEE').toLowerCase()].length === 0

  const allowPlusButton = dateString =>
    ignoreTechnicianAvailability || (!requestedOff(dateString) && !offDay(dateString))

  const jobRequiresEliteTechnician = eliteVehicleMakes.includes(lead.makeShortName)
  const jobRequiresTiresTechnician = lead.serviceType === 'tires'
  const jobRequiresWindshieldsTechnician = lead.serviceType === 'windshields'

  const technicianCanBeAssignedJob =
    (jobRequiresEliteTechnician && technician.isElite) ||
    (jobRequiresTiresTechnician && technician.skillsets.includes('tire replacements')) ||
    (jobRequiresWindshieldsTechnician && technician.skillsets.includes('autoglass work')) ||
    (!jobRequiresEliteTechnician && !jobRequiresTiresTechnician && !jobRequiresWindshieldsTechnician)
  const addJobButtonDisabled = !addressIsValid || !lead.market || !technicianCanBeAssignedJob

  return (
    <Box
      className={[
        !technicianCanBeAssignedJob && classes.disabled,
        jobRequiresEliteTechnician && technician.isElite && classes.isElite,
      ]}
    >
      {!myTravelDistance && technicianCanBeAssignedJob && (
        <DistanceMatrixService
          options={{
            unitSystem: window.google.maps.UnitSystem.IMPERIAL,
            travelMode: 'DRIVING',
            destinations: [leadCoordinates],
            origins: [{ lat: Number(technician.lat), lng: Number(technician.lng) }],
          }}
          callback={handleTravelDistanceResult}
        />
      )}

      {jobRequiresEliteTechnician && !technician.isElite && (
        <Box sx={{ mt: 1, mx: 2, px: 1, border: '3px dashed red', color: 'red' }}>
          <b>This job cannot be assigned to this technician because it requires an elite technician.</b>
        </Box>
      )}

      {jobRequiresTiresTechnician && !technician.skillsets.includes('tire replacements') && (
        <Box sx={{ mt: 1, mx: 2, px: 1, border: '3px dashed red', color: 'red' }}>
          <b>
            This job cannot be assigned to this technician because it requires a technician that can replace tires. (You
            can update this setting on the technician profile)
          </b>
        </Box>
      )}

      {jobRequiresWindshieldsTechnician && !technician.skillsets.includes('autoglass work') && (
        <Box sx={{ mt: 1, mx: 2, px: 1, border: '3px dashed red', color: 'red' }}>
          <b>
            This job cannot be assigned to this technician because it requires a technician that can replace
            windshields. (You can update this setting on the technician profile)
          </b>
        </Box>
      )}

      <Box sx={{ pl: 1 }}>
        <Grid container sx={{ p: 1 }}>
          <Grid item xs={7}>
            <Link to={`/technicians/${technician.id}`}>
              <b>{technician.name}</b>
            </Link>
            {technicianCanBeAssignedJob && (
              <>
                &nbsp; (Travel{' '}
                {`Distance: ${myTravelDistance?.distanceString}, Time: ${myTravelDistance?.durationString}`}) &nbsp;
                <Button size='small' variant='outlined' onClick={handleClickShowTwoMoreWeeks}>
                  + 2 weeks
                </Button>
                &nbsp;
                <Button
                  size='small'
                  variant='outlined'
                  onClick={() => setShowDrivingDistanceDays(showDrivingDistanceDays + 14)}
                >
                  driving distances ({showDrivingDistanceDays + 14} days)
                </Button>
                <FormControlLabel
                  style={{ marginLeft: '1em' }}
                  control={
                    <Checkbox
                      checked={ignoreTechnicianAvailability}
                      onChange={() => setIgnoreTechnicianAvailability(!ignoreTechnicianAvailability)}
                      color='primary'
                    />
                  }
                  label='Ignore availability'
                />
              </>
            )}
          </Grid>

          <Grid item xs={5} sx={{ pt: 1, fontSize: '13px' }}>
            {Object.entries(technician.availability).map(
              (el, i) =>
                el[1].length > 0 &&
                el[1][0] !== '' && (
                  <>
                    <b>{el[0]}:</b>{' '}
                    {el[1].map((range, i) => (
                      <>{formattedStringForAvailRange(range)},&nbsp;</>
                    ))}
                  </>
                )
            )}
          </Grid>
        </Grid>
      </Box>

      <TableContainer>
        <Table size='small' className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={14}>
                {technician.note && technician.note.length > 0 && (
                  <>
                    Notes: <span style={{ color: '#3f51b5', fontWeight: 'bold' }}>{technician.note}</span>
                  </>
                )}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {chunkedPopulatedSchedule.map((chunk, chunkIndex) => (
              <TableRow key={chunkIndex}>
                {chunk.map((scheduleDay, scheduleDayIndex) => (
                  <CalendarDayTableCell
                    key={scheduleDayIndex}
                    technician={technician}
                    scheduleDay={scheduleDay}
                    ignoreAvailability={ignoreTechnicianAvailability}
                    disablePlusButton={addJobButtonDisabled}
                    onClickPlusButton={() => handleClickPlusButton(scheduleDay)}
                    chunkIndex={chunkIndex}
                    leadCoordinates={leadCoordinates}
                    showDrivingDistanceDays={showDrivingDistanceDays}
                  />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateJobModal
        lead={lead}
        quote={quote}
        technician={technician}
        ignoreAvailability={ignoreTechnicianAvailability}
        scheduleDay={modalScheduleDay}
        setScheduleDay={setModalScheduleDay}
      />
    </Box>
  )
}

function CreateJobModal({ lead, quote, technician, ignoreAvailability, scheduleDay, setScheduleDay }) {
  const history = useHistory()
  const theme = useTheme()
  const [user] = useContext(UserContext)

  const [commissionChargeBackAmount, setCommissionChargeBackAmount] = useState('')
  const [includeTip, setIncludeTip] = useState(false)
  const [addChargeback, setAddChargeback] = useState(true)
  const [startTime, setStartTime] = useState(DateTime.now())
  const [endTime, setEndTime] = useState(DateTime.now())
  const [followUpReason, setFollowUpReason] = useState(null)
  const [loadingCreateJob, setLoadingCreateJob] = useState(false)
  const [chargebackReason, setChargebackReason] = useState('')

  const bearerTokenHeaders = useBearerTokenHeaders()

  const maybeAddChargeback = quote?.payrollStatuses.includes('commission_paid')

  const jobDurationString = useMemo(() => {
    const dur = endTime.diff(startTime, ['hours', 'minutes']).toObject()
    return `${dur.hours}h, ${dur.minutes}m`
  }, [startTime, endTime])

  const { dateLabel, availability, availabilityLabel, quotedHours, quoteJobs, scheduleDayJobs } = useMemo(() => {
    const scheduleDate = DateTime.fromISO(scheduleDay?.dateString, { zone: 'utc' })
    const scheduleDayOfWeek = scheduleDate.toFormat('cccc').toLowerCase()
    const availability = technician.availability[scheduleDayOfWeek] ?? []

    return {
      // scheduleDate,
      // scheduleDayOfWeek,
      dateLabel: scheduleDate.toFormat('ccc, LLL d'),
      availability,
      availabilityLabel: formattedAvailability(availability),
      quotedHours: quote.primaryLaborItems.reduce((acc, val) => (val.void ? acc : acc + val.totalHours), 0),
      quoteJobs: quote.jobs.filter(job => !job.heldForReschedulingAt && !job.canceledAt) ?? [],
      scheduleDayJobs: scheduleDay?.jobs?.filter(job => !job.heldForReschedulingAt && !job.canceledAt) ?? [],
    }
  }, [scheduleDay])

  useEffect(() => {
    if (!scheduleDay) return

    const availabilityStartHour = +availability[0]?.substr(0, availability[0].indexOf('-')) ?? 8
    const lastJob = scheduleDayJobs.length > 0 && scheduleDayJobs[scheduleDayJobs.length - 1]
    const lastJobEndHour = lastJob ? DateTime.fromISO(lastJob.endDatetime, { zone: 'utc' }).c.hour : false
    const startTimeHour = lastJobEndHour ? lastJobEndHour + 1 : availabilityStartHour
    const startTimeString = (startTimeHour < 10 ? '0' : '') + startTimeHour + ':00'

    const endTimeHour = quotedHours ? startTimeHour + Math.floor(quotedHours) : 17
    const endTimeString = (endTimeHour < 10 ? '0' : '') + endTimeHour + ':' + (quotedHours % 1 > 0 ? '30' : '00')

    setStartTime(DateTime.fromISO(scheduleDay.dateString + 'T' + startTimeString, { zone: 'utc' }))
    setEndTime(DateTime.fromISO(scheduleDay.dateString + 'T' + endTimeString, { zone: 'utc' }))
    setFollowUpReason(null)
  }, [scheduleDay])

  function handleChangeStartTimeStart(event) {
    setStartTime(
      DateTime.fromISO(scheduleDay.dateString + 'T' + event.target.value, { zone: 'utc' }).set({ seconds: 0 }),
      { zone: 'utc' }
    )
  }
  function handleChangeEndTime(event) {
    setEndTime(
      DateTime.fromISO(scheduleDay.dateString + 'T' + event.target.value, { zone: 'utc' }).set({ seconds: 0 }),
      { zone: 'utc' }
    )
  }
  function handleClickCreateJob() {
    setLoadingCreateJob(true)
    axios({
      method: 'post',
      url: process.env.REACT_APP_COMMAND_ROOT + '/api/create_job',
      data: {
        lead_id: lead.id,
        user_id: user.id,
        quote_id: quote.id,
        technician_id: technician.id,
        start_datetime: startTime.toISO(),
        end_datetime: endTime.toISO(),
        ignore_availability: ignoreAvailability,
        follow_up_reason: followUpReason,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
      .then(res => {
        setTimeout(() => history.push(`/leads/${lead.id}/quotes/${quote.id}/jobs/${res.data.job.id}`), 1000)
      })
      .catch(() => {
        window.alert('error')
        setLoadingCreateJob(false)
      })
  }

  const followUpReasonRequired = quoteJobs.length > 0
  const disableCreateJob = loadingCreateJob || (followUpReasonRequired && !followUpReason?.trim())

  return (
    <Modal open={!!scheduleDay} onClose={() => setScheduleDay(false)}>
      <div
        style={{
          position: 'absolute',
          width: 'auto',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <h2 id='simple-modal-title'>{technician.name}</h2>
        <p id='simple-modal-description'>date: {dateLabel}</p>
        {availabilityLabel && <p>availability: {availabilityLabel}</p>}
        {quotedHours && <p>quoted hours: {quotedHours}</p>}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label='start time'
              type='time'
              id='new-job-start-time'
              value={startTime.toFormat('HH:mm')}
              onChange={handleChangeStartTimeStart}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='end time'
              type='time'
              id='new-job-end-time'
              onChange={handleChangeEndTime}
              value={endTime.toFormat('HH:mm')}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>
        </Grid>
        {followUpReasonRequired && (
          <>
            <br />
            <Autocomplete
              fullWidth
              clearOnBlur
              options={jobFollowupReasonOptions}
              renderInput={params => <TextField {...params} label='Follow-up Reason' />}
              onChange={(event, value) => setFollowUpReason(value?.value ?? value)}
            />
          </>
        )}

        {maybeAddChargeback && (
          <>
            <FormControlLabel
              sx={{ mt: '1rem', display: 'block' }}
              control={<Switch checked={addChargeback} onChange={e => setAddChargeback(!addChargeback)} />}
              label='Add a chargeback?'
            />
            <Collapse in={addChargeback}>
              <CommissionChargeBacks quote={keysToCamel(quote)} quoteId={quote.id} />
            </Collapse>
          </>
        )}
        <p>duration: {jobDurationString}</p>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 1 }}></Box>
          <Box>
            <Button onClick={() => setScheduleDay(false)}>cancel</Button>
            <Button id='create-new-job-button' disabled={disableCreateJob} onClick={handleClickCreateJob}>
              Create Job
            </Button>
          </Box>
        </Box>
      </div>
    </Modal>
  )
}

export default NewJob
