import { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { UserContext } from 'UserStore'
import { Chip, Skeleton } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { PriceCategoryDialog } from './PriceCategoryDialog'

const DEALERS_QUERY = gql`
  query BToBPriceCategories($dealerId: String) {
    bToBPriceCategories(dealerId: $dealerId) {
      id
      createdAt
      name
      isCustom
      pricePoints {
        id
        price
        commissionAmount
        panelGroup {
          id
          name
        }
        customPricePoint {
          id
          price
          commissionAmount
        }
      }
      customPricePoints {
        id
        price
        commissionAmount
        pricePointId
        panelGroup {
          id
          name
        }
      }
      categoriesWithoutPricePoints {
        id
        name
      }
    }
  }
`

const LINE_ITEM_CATEGORIES = gql`
  query bToBPanelGroups {
    bToBPanelGroups {
      id
      name
      isCustom
    }
  }
`

export const PriceCategoriesTable = ({ dealer }: any) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [open, setOpen] = useState(false)
  const [selectedPriceCategory, setSelectedPriceCategory] = useState<any>({})

  const { loading, error, data, refetch } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      dealerId: dealer.id,
    },
  })

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: dataCategories,
    refetch: refetchCategories,
  } = useQuery(LINE_ITEM_CATEGORIES, {
    notifyOnNetworkStatusChange: true,
  })

  const pricingCategories = data?.bToBPriceCategories || []
  const rows = dataCategories?.bToBPanelGroups || []

  useEffect(() => {
    JSON.stringify(selectedPriceCategory) !== JSON.stringify({}) &&
      setSelectedPriceCategory(pricingCategories.find((item: any) => item.id === selectedPriceCategory.id))
  }, [pricingCategories])

  if (error) return <div>Error!</div>

  const handleRefetch = () => {
    refetch()
    refetchCategories()
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow sx={{ background: 'aliceblue' }}>
              <TableCell>Category Name</TableCell>
              {/* <TableCell>Category Name</TableCell> */}
              {/* <TableCell>Type</TableCell> */}
              {pricingCategories
                .slice()
                .sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1))
                .map((lineItem: any) => (
                  <TableCell>
                    {lineItem.name}{' '}
                    {user.roles.includes('admin') && (
                      <button
                        onClick={() => {
                          setOpen(true)
                          setSelectedPriceCategory(lineItem)
                        }}
                      >
                        edit
                      </button>
                    )}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* @ts-ignore */}
            {loading && <TableLoadingSkeleton />}
            {rows
              .slice()
              .sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1))
              .map((panelGroup: any) => {
                const pricePoints = pricingCategories
                  .slice()
                  .sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1))
                  .map((thing: any) =>
                    thing.pricePoints.find((pricePoint: any) => pricePoint.panelGroup.id === panelGroup.id)
                  )

                return (
                  <TableRow>
                    <TableCell sx={{ borderRight: '1px solid #ddd' }}>{panelGroup.name}</TableCell>
                    {pricePoints.map((pricePoint: any) => (
                      <TableCell
                        key={pricePoint?.id}
                        sx={[
                          {
                            background: !pricePoint ? '#eee' : '#fff',
                            borderRight: '1px solid #ddd',
                          },
                        ]}
                      >
                        {(pricePoint?.price && (
                          <span>
                            $
                            {pricePoint?.customPricePoint !== undefined && pricePoint?.customPricePoint !== null
                              ? pricePoint?.customPricePoint?.price
                              : pricePoint?.price}{' '}
                            {pricePoint?.customPricePoint !== undefined && pricePoint?.customPricePoint !== null && (
                              <>
                                {pricePoint?.customPricePoint?.price !== 0 ? (
                                  <Chip
                                    sx={{ padding: '.125rem', fontWeight: 600, ml: '.5rem', fontSize: '11px' }}
                                    color='success'
                                    size='small'
                                    label='CUSTOM'
                                  />
                                ) : (
                                  <Chip
                                    sx={{ padding: '.125rem', fontWeight: 600, ml: '.5rem', fontSize: '11px' }}
                                    color='error'
                                    size='small'
                                    label='REMOVED'
                                  />
                                )}
                              </>
                            )}
                          </span>
                        )) ||
                          ''}{' '}
                        {pricePoint?.customPricePoint !== undefined && pricePoint?.customPricePoint !== null ? (
                          <span style={{ color: '#777' }}>(${pricePoint?.customPricePoint?.commissionAmount})</span>
                        ) : (
                          <span style={{ color: '#777' }}>(${pricePoint?.commissionAmount})</span>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <PriceCategoryDialog
        open={open}
        setOpen={setOpen}
        refetch={() => handleRefetch()}
        lineItem={selectedPriceCategory}
        dealer={dealer}
      />
    </>
  )
}

const TableLoadingSkeleton = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => (
    <TableRow key={item}>
      {[1, 2, 3, 4, 5].map(inner => (
        <TableCell sx={{ padding: '.25rem' }} key={inner}>
          <Skeleton
            sx={{
              minWidth: '100%',
              minHeight: '28px',
              transform: 'scale(1)',
              background: item % 2 !== 0 ? '#0089CD1a' : '#f1f1f1',
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  ))
}
