import { useQuery, gql } from '@apollo/client'

const QUERY = gql`
  query PaidTimeOff($technicianId: ID, $unclaimed: Boolean) {
    paidTimeOffs(technicianId: $technicianId, unclaimed: $unclaimed) {
      id
      createdAt
      totalHours
      payoutId
      note
      deletedAt
      timeOffDate
      payout {
        id
        createdAt
      }
    }
  }
`

export const useTechnicianPaidTimeOff = ({ technicianId }) => {
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { technicianId: technicianId, unclaimed: true },
  })
  return { loading, error, data, paidTimeOff: data?.paidTimeOffs || [], refetch }
}
