import { gql } from '@apollo/client'
import { Interview } from '../useInterviews/query'

export interface GetInterviewsReq {
  recruiter_id?: string
}

export interface GetInterviewsRes {
  recruiterInterviews?: Interview[]
}

export const GET_ALL_RECRUITER_INTERVIEWS = gql`
  query getInterviewsByRecruiter($recruiter_id: ID!) {
    recruiterInterviews(recruiterId: $recruiter_id) {
      id
      startDatetime
      endDatetime
      createdAt
      canceledAt
      jobApplication {
        id
        jobListing {
          id
          jobTitle
        }
      }
      applicant {
        id
        fullName
      }
      recruiter {
        id
        firstName
        lastName
      }
    }
  }
`
