import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import partStatusClasses from 'Parts/part_status.module.css'

import {
  FormControlLabel, Grid, Popover,
  List, ListItem, ListItemText,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Checkbox, Button, FormControl, InputLabel, Select,
  MenuItem
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { UserContext } from 'UserStore'
import OrderPartDialog from './OrderPartDialog'
import RefundDialog from './RefundDialog'
import CoreRefundDialog from './CoreRefundDialog'
import ReturnDialog from './ReturnDialog'
import PartVendorSelect from 'components/PartVendorSelect'
import ConfirmDialog from 'components/ConfirmDialog'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { PriceInput, Dollars, underscoreToTitleCase, isDefined, diff } from 'tools'

const useStyles = makeStyles(theme => ({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableActions: {
    textAlign: 'right',
    paddingRight: '6px',
    marginBottom: '6px!important',
    marginTop: '14px!important',
    display: 'inline-flex',
    justifyContent: 'flex-end'
  },
  disabled: {
    opacity: 0.65,
    '& + .child-row': {
      opacity: 0.65
    },
    '&:hover': {
      // backgroundColor: 'inherit!important',
      cursor: 'auto!important'
    },
    '&:hover + .child-row': {
      // backgroundColor: 'inherit!important',
      cursor: 'auto!important'
    }
  },
  noBottomBorder: {
    '& .MuiTableCell-root': {
      borderBottomWidth: 0
    },
    '&:hover + .child-row': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  rowBottomBorder: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  }
}))

const partIsEditable = (part) => ['refunded', 'returned', 'canceled', 'removed', 'lost'].indexOf(part.status) === -1
const orderCanBeRequested = (part) => !part.orderPlacedAt && !part.orderRequestedAt && !part.canceledAt && !part.pickupHistory.length && !part.readyForPickupAt
const partCanBeOrdered = (part) => !part.orderPlacedAt && !part.refundInitiatedAt && !part.returnInitiatedAt && !part.canceledAt && !part.pickupHistory.length
const partCanBeMarkedReadyForPickup = (part) => !part.pickupHistory.length && part.orderPlacedAt && !part.readyForPickupAt && !part.canceledAt && !part.removedFromQuoteAt
const canInitiateReturn = (part) => part.orderPlacedAt && part.pickupHistory.length && !part.returnInitiatedAt && !part.canceledAt && !part.coreRefundInitiatedAt && partIsEditable(part)
const techReturnedPart = (part) => part.orderPlacedAt && part.pickupHistory.length && part.returnInitiatedAt && !part.returnedToVendorAt
const returnCanBeCompleted = (part) => part.returnInitiatedAt && part.returnedToVendorAt && !part.returnCompletedAt && part.orderPlacedAt && !part.canceledAt
const partCanBeCanceled = (part) => part.orderPlacedAt && !part.isPaid && !part.pickupHistory.length && !part.canceledAt && part.status !== 'quoted'
const partCanBeRemovedFromQuote = (part) => partIsEditable(part)
const canInitiateCoreRefund = (part) => part.pickupHistory.length && (part.hasCoreRefund || part.coreRefundExpectedAmount) && !part.coreRefundInitiatedAt && !part.refundInitiatedAt && !part.returnInitiatedAt
const techReturnedCore = (part) => part.coreRefundInitiatedAt && !part.coreReturnedToVendorAt
const coreRefundCanBeReceived = (part) => part.coreRefundInitiatedAt && part.coreReturnedToVendorAt && !part.coreRefundCompletedAt
const canInitiateRefund = (part) => part.isPaid && !part.pickupHistory.length && !part.refundInitiatedAt && !part.returnInitiatedAt
const canCompleteRefund = (part) => part.refundInitiatedAt && !part.refundCompletedAt
const partCanBeMarkedLost = (part) => partIsEditable(part) && part.orderPlacedAt

const allKeysValue = (obj, val) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && key !== 'controller') {
      if (obj[key] !== val) {
        return false
      }
    }
  }
  return true
}

const menuOptionEnabled = (selected, fn) => {
  if (!selected.length) {
    return false
  }

  for (let i = 0, l = selected.length; i < l; i++) {
    if (!fn(selected[i])) {
      return false
    }
  }
  return true
}

const PartAndSiblings = ({ part, parts, quote, lead, disableEditing }) => {
  const selectedPart = part || {}
  const allParts = part ? [...parts, part] : parts

  const partsGroupedByVendor = allParts.reduce((acc, part) => ({
    ...acc,
    [part.partVendorId]: [...acc[part.partVendorId] || [], part]
  }), {})

  // move canceled and removed parts to the bottom of the table
  const partSortFn = (a, b) => ((a.canceledAt && !b.canceledAt) || (a.removedFromQuoteAt && !b.removedFromQuoteAt)) ? 1 : -1

  return (
    <>
      <Grid container spacing={0}>
        {Object.keys(partsGroupedByVendor)
          .sort((a, b) => {
            if (a === 'null') {
              return -1
            }

            if (a[0] && b[0]) {
              return a[0].vendor?.name < b[0].vendor?.name
            }

            return 0
          })
          .map(vendor => (
            <MerchantTable
              key={vendor}
              vendorName={partsGroupedByVendor[vendor][0].vendor?.name}
              selectedPart={selectedPart}
              parts={partsGroupedByVendor[vendor].sort(partSortFn)}
              quote={quote}
              lead={lead}
              disableEditing={disableEditing}
            />
          ))}
      </Grid>
    </>
  )
}

const MerchantTable = ({ vendorName, selectedPart, parts, quote, lead, disableEditing }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const classes = useStyles()
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [actionsEl, setActionsEl] = React.useState(null)
  const [orderPartDialogOpen, setOrderPartDialogOpen] = useState(false)
  const [partRefundDialogOpen, setPartRefundDialogOpen] = useState(false)
  const [partCoreRefundDialogOpen, setPartCoreRefundDialogOpen] = useState(false)
  const [partReturnDialogOpen, setPartReturnDialogOpen] = useState(false)
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false)
  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false)
  const [confirmLostDialogOpen, setConfirmLostDialogOpen] = useState(false)
  const firstUpdate = useRef(false)

  const buildInitialValues = (parts) => parts.reduce((acc, part) => ({
    ...acc,
    [part.id]: (part.id === selectedPart.id)
  }), {})

  const [initialValues, setInitialValues] = useState(buildInitialValues(parts))

  const validationSchema = Yup.object().shape({
    selectedIds: Yup.array().of(Yup.string()).min(1, 'At least one part is required.')
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema
  })

  const editablePartValidationSchema = Yup.object().shape({
    number: Yup.string().required('required'),
    cost: Yup.number()
      .nullable()
      .test({
        test: (value, context) => context.parent.type === 'aftermarket' ? value > 0 : true,
        message: 'required'
      }),
    price: Yup.number(),
    etaBusinessDays: Yup.number().nullable(),
    partVendorId: Yup.string().nullable(),
    vendor: Yup.object().nullable(),
    isPaid: Yup.boolean().nullable(),
    coreRefundExpectedAmount: Yup.number().nullable(),
    ccLastFour: Yup.string().nullable()
      .test({
        test: (value, context) => context.parent.orderPlacedAt ? /^(\d|\w){3,4}$/.test(value) : true,
        message: 'required'
      }),
    prePaymentRequired: Yup.boolean(),
    specialOrder: Yup.boolean().nullable()
  })

  const selectedParts = parts.reduce((acc, part) => {
    if (formik.values[part.id]) {
      acc.push(part)
    }
    return acc
  }, [])

  const menuOptions = {
    requestOrder: menuOptionEnabled(selectedParts, orderCanBeRequested),
    orderPlaced: menuOptionEnabled(selectedParts, partCanBeOrdered),
    readyForPickup: menuOptionEnabled(selectedParts, partCanBeMarkedReadyForPickup),
    cancel: menuOptionEnabled(selectedParts, partCanBeCanceled),
    remove: menuOptionEnabled(selectedParts, partCanBeRemovedFromQuote),
    initiateReturn: menuOptionEnabled(selectedParts, canInitiateReturn),
    techReturnedPart: menuOptionEnabled(selectedParts, techReturnedPart),
    completeReturn: menuOptionEnabled(selectedParts, returnCanBeCompleted),
    initiateCoreRefund: menuOptionEnabled(selectedParts, canInitiateCoreRefund),
    techReturnedCore: menuOptionEnabled(selectedParts, techReturnedCore),
    coreRefundReceived: menuOptionEnabled(selectedParts, coreRefundCanBeReceived),
    initiateRefund: menuOptionEnabled(selectedParts, canInitiateRefund),
    completeRefund: menuOptionEnabled(selectedParts, canCompleteRefund),
    lost: menuOptionEnabled(selectedParts, partCanBeMarkedLost)
  }

  const updateAllCheckboxes = (value) => {
    const newState = {}

    allPartIds.forEach(o => {
      newState[o] = value
    })

    formik.setValues(newState)
  }

  const onRowClick = (part) => {
    if (part.canceledAt || (part.removedFromQuoteAt && !part.orderPlacedAt) || isPartBeingEdited(part) || part.lostAt) { return }

    formik.setFieldValue(part.id, !formik.values[part.id])
  }

  const handlePromises = (promises) =>
    Promise.all(promises)
      .finally(() => {
        setWaiting(false)
        updateAllCheckboxes(false)
      })

  const handleRequestOrder = () => {
    setWaiting(true)

    const promises = selectedParts.map(part =>
      window.fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_order_requested`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          partId: part.id,
          orderRequested: true
        })
      }).then(res => res.ok || window.alert('Error')))

    handlePromises(promises)
  }

  const handleReadyForPickup = () => {
    setWaiting(true)

    const promises = selectedParts.map(part =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_ready_for_pickup`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          partId: part.id,
          readyForPickup: true
        })
      }).then(res => res.ok || window.alert('Error')))

    handlePromises(promises)
  }

  const handleInitiateReturn = () => {
    setWaiting(true)

    const promises = selectedParts.map(part =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_return_initiated`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          partId: part.id,
          returnInitiated: true
        })
      }).then(res => res.ok || window.alert('Error')))

    handlePromises(promises)
  }

  const handleTechReturnedPart = () => {
    setWaiting(true)

    const promises = selectedParts.map(part =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_returned_to_vendor`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          partId: part.id,
          returned: true
        })
      }).then(res => res.ok || window.alert('Error')))

    handlePromises(promises)
  }

  const handleInitiateCoreRefund = () => {
    setWaiting(true)

    const promises = selectedParts.map(part =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_core_refund_initiated`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          partId: part.id,
          coreRefundInitiated: true
        })
      }).then(res => res.ok || window.alert('Error')))

    handlePromises(promises)
  }

  const handleTechReturnedCore = () => {
    setWaiting(true)

    const promises = selectedParts.map(part =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_core_returned_to_vendor`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          partId: part.id,
          returned: true
        })
      }).then(res => res.ok || window.alert('Error')))

    handlePromises(promises)
  }

  const handleInitiateRefund = () => {
    setWaiting(true)

    const promises = selectedParts.map(part =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_refund_initiated`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          partId: part.id,
          refundInitiated: true
        })
      }).then(res => res.ok || window.alert('Error')))

    handlePromises(promises)
  }

  const handleCancelPart = () => {
    setWaiting(true)

    const promises = selectedParts.map(part =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_part_canceled`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          partId: part.id,
          canceled: true
        })
      }).then(res => res.ok || window.alert('Error')))

    handlePromises(promises)
  }

  const handleRemovePart = () => {
    setWaiting(true)

    const promises = selectedParts.map(part =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/remove_part_from_quote`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          quoteId: quote.id,
          partId: part.id
        })
      }).then(res => res.ok || window.alert('Error')))

    handlePromises(promises)
  }

  const handlePartLost = () => {
    setWaiting(true)

    const promises = selectedParts.map(part =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/mark_part_as_lost`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          partId: part.id
        })
      }).then(res => res.ok || window.alert('Error')))

    handlePromises(promises)
  }

  const handleClickSave = (part) => {
    const payload = {
      ...diff(editablePartForm.initialValues, part),
      ...diff(part, editablePartForm.initialValues)
    }

    if (Object.keys(payload).length === 0) {
      return
    }

    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_part_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        partId: part.id,
        updatedAttributes: payload
      })
    })
      .then(res => res.ok || window.alert('Error'))
      .finally(() => {
        setWaiting(false)
        editablePartForm.resetForm({ values: initialPartValues })
      })
  }

  const initialPartValues = {
    id: '',
    name: '',
    type: '',
    number: '',
    cost: '',
    price: '',
    etaBusinessDays: '',
    partVendorId: '',
    vendor: null,
    isPaid: '',
    coreRefundExpectedAmount: '',
    ccLastFour: '',
    prePaymentRequired: '',
    specialOrder: false
  }

  const editablePartForm = useFormik({
    initialValues: initialPartValues,
    validationSchema: editablePartValidationSchema,
    onSubmit: handleClickSave
  })

  const isPartBeingEdited = part => editablePartForm.values.id === part.id

  const saveDisabled = (part) => {
    if (!editablePartForm.values.id) {
      return true
    }

    const diffs = {
      ...diff(part, editablePartForm.values),
      ...diff(editablePartForm.values, part)
    }

    return Object.keys(diffs).length === 0
  }

  useEffect(() => {
    // Don't clear the selected checkbox on the first render, only when the parts have changed
    if (firstUpdate.current) {
      updateAllCheckboxes(false)

      // Identify what parts are/are not in this table and register/unregister form fields
      parts.forEach(part => {
        if (!Object.hasOwnProperty.call(initialValues, part.id)) {
          formik.registerField(part.id, {})
        }
      })

      Object.keys(initialValues).forEach(partId => {
        // const found = parts.find(o => o.id === partId)
        if (!parts.find(o => o.id === partId)) {
          formik.unregisterField(partId)
        }
      })

      // Update the form since the parts in this table have changed
      setInitialValues(buildInitialValues(parts))
    } else {
      firstUpdate.current = true
    }
  }, [parts])

  useEffect(() => {
    if (disableEditing) {
      editablePartForm.resetForm({ values: initialPartValues })
    }
  }, [disableEditing])

  useEffect(() => {
    if (waiting) {
      editablePartForm.resetForm({ values: initialPartValues })
    }
  }, [waiting])

  // Make sure these values are calculated after the above useEffect
  // Ignore canceled parts because no actions can be taken on them
  const allPartIds = parts.reduce((acc, part) => (!part.canceledAt && !part.removedFromQuoteAt && !part.lostAt) ? [...acc, part.id] : acc, [])
  const allTrue = allPartIds.length && allKeysValue(formik.values, true)
  const allFalse = allKeysValue(formik.values, false)

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <h3 style={{ marginBottom: 0 }}>{vendorName || 'Not Ordered'}</h3>
        </Grid>
        <Grid item xs={6} className={classes.tableActions}>
          {waiting &&
            <Box className={classes.flexCenter} sx={{ marginRight: '12px' }}>
              <CircularProgress size={20} />
            </Box>}
          <Button
            size='small'
            variant='contained'
            color='primary'
            disabled={waiting || allFalse || allKeysValue(menuOptions, false)}
            onClick={(e) => {
              setActionsEl(e.currentTarget)
              setMenuOpen(true)
            }}
          >Actions
          </Button>
          <Popover
            open={menuOpen}
            anchorEl={actionsEl}
            onClose={() => setMenuOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <List>
              <ListItem
                button
                disabled={!menuOptions.requestOrder}
                onClick={() => {
                  handleRequestOrder()
                  setMenuOpen(false)
                }}
              >
                <ListItemText primary='Request Order' />
              </ListItem>

              <ListItem
                button
                disabled={!menuOptions.orderPlaced}
                onClick={() => {
                  setOrderPartDialogOpen(true)
                  setMenuOpen(false)
                }}
              >
                <ListItemText primary='Order Placed' />
              </ListItem>

              <ListItem
                button
                disabled={!menuOptions.readyForPickup}
                onClick={() => {
                  handleReadyForPickup()
                  setMenuOpen(false)
                }}
              >
                <ListItemText primary='Mark Ready for Pickup' />
              </ListItem>

              {(menuOptions.initiateReturn || menuOptions.techReturnedPart || menuOptions.completeReturn) &&
                <>
                  <hr />

                  <ListItem
                    button
                    disabled={!menuOptions.initiateReturn}
                    onClick={() => {
                      handleInitiateReturn()
                      setMenuOpen(false)
                    }}
                  >
                    <ListItemText primary='Initiate Return' />
                  </ListItem>

                  {(!menuOptions.initiateReturn && (menuOptions.techReturnedPart || menuOptions.completeReturn)) &&
                    <>
                      <ListItem
                        button
                        disabled={!menuOptions.techReturnedPart}
                        onClick={() => {
                          handleTechReturnedPart()
                          setMenuOpen(false)
                        }}
                      >
                        <ListItemText primary='Tech Returned Part' />
                      </ListItem>

                      <ListItem
                        button
                        disabled={!menuOptions.completeReturn}
                        onClick={() => {
                          setPartReturnDialogOpen(true)
                          setMenuOpen(false)
                        }}
                      >
                        <ListItemText primary='Return Completed' />
                      </ListItem>
                    </>}

                </>}

              {(menuOptions.initiateCoreRefund || menuOptions.techReturnedCore || menuOptions.coreRefundReceived) &&
                <>
                  <hr />

                  <ListItem
                    button
                    disabled={!menuOptions.initiateCoreRefund}
                    onClick={() => {
                      handleInitiateCoreRefund()
                      setMenuOpen(false)
                    }}
                  >
                    <ListItemText primary='Initiate Core Refund' />
                  </ListItem>

                  {(!menuOptions.initiateCoreRefund && (menuOptions.techReturnedCore || menuOptions.coreRefundReceived)) &&
                    <>
                      <ListItem
                        button
                        disabled={!menuOptions.techReturnedCore}
                        onClick={() => {
                          handleTechReturnedCore()
                          setMenuOpen(false)
                        }}
                      >
                        <ListItemText primary='Tech Returned Core' />
                      </ListItem>

                      <ListItem
                        button
                        disabled={!menuOptions.coreRefundReceived}
                        onClick={() => {
                          setPartCoreRefundDialogOpen(true)
                          setMenuOpen(false)
                        }}
                      >
                        <ListItemText primary='Core Refund Received' />
                      </ListItem>
                    </>}

                </>}

              {(menuOptions.initiateRefund || menuOptions.completeRefund) &&
                <>
                  <hr />

                  <ListItem
                    button
                    disabled={!menuOptions.initiateRefund}
                    onClick={() => {
                      handleInitiateRefund()
                      setMenuOpen(false)
                    }}
                  >
                    <ListItemText primary='Initiate Refund' />
                  </ListItem>

                  {menuOptions.completeRefund &&
                    <ListItem
                      button
                      disabled={!menuOptions.completeRefund}
                      onClick={() => {
                        setPartRefundDialogOpen(true)
                        setMenuOpen(false)
                      }}
                    >
                      <ListItemText primary='Refund Received' />
                    </ListItem>}
                </>}

              {(menuOptions.cancel || menuOptions.lost) &&
                <>
                  <hr />
                  {menuOptions.cancel &&
                    <ListItem
                      button
                      style={{ color: 'red' }}
                      disabled={!menuOptions.cancel}
                      onClick={() => {
                        setConfirmCancelDialogOpen(true)
                        setMenuOpen(false)
                      }}
                    >
                      <ListItemText primary='Cancel' />
                    </ListItem>}
                  {menuOptions.lost &&
                    <ListItem
                      button
                      style={{ color: 'red' }}
                      disabled={!menuOptions.lost}
                      onClick={() => {
                        setConfirmLostDialogOpen(true)
                        setMenuOpen(false)
                      }}
                    >
                      <ListItemText primary='Lost' />
                    </ListItem>}
                </>}

              {/* {menuOptions.remove &&
                <>
                  <hr />
                  <ListItem
                    button
                    style={{ color: 'red' }}
                    disabled={!menuOptions.remove}
                    onClick={() => {
                      setConfirmRemoveDialogOpen(true)
                      setMenuOpen(false)
                    }}
                  >
                    <ListItemText primary='Remove' />
                  </ListItem>
                </>} */}
            </List>
          </Popover>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center' style={{ maxWidth: '60px' }}>
                <Checkbox
                  indeterminate={!allTrue && !allFalse}
                  checked={allTrue}
                  onChange={() => {
                    const allTrue = allKeysValue(formik.values, true)
                    const allFalse = allKeysValue(formik.values, false)

                    if ((!allTrue && !allFalse) || allFalse) {
                      updateAllCheckboxes(true)
                    } else if (allTrue) {
                      updateAllCheckboxes(false)
                    }
                  }}
                  disabled={waiting || allPartIds.length === 0}
                />
              </TableCell>
              <TableCell>Part Name</TableCell>
              <TableCell>Part Type</TableCell>
              <TableCell>Part Number</TableCell>
              <TableCell align='center'>Qty</TableCell>
              <TableCell align='right'>Cost</TableCell>
              <TableCell align='right'>Quoted Price</TableCell>
              <TableCell align='center'>Special Order</TableCell>
              <TableCell align='center'>Prepay Required</TableCell>
              <TableCell align='center'>ETA <br /> (business days)</TableCell>
              <TableCell align='center'>Status</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parts.map(part =>
              <React.Fragment key={part.id}>
                <TableRow
                  key={part.id}
                  hover
                  style={{ backgroundColor: `${part.id === selectedPart.id ? '#99f8ff' : ''}`, cursor: 'pointer' }}
                  className={[(part.canceledAt || part.lostAt || (part.removedFromQuoteAt && !part.orderPlacedAt) ? classes.disabled : ''), part.orderPlacedAt && classes.noBottomBorder].join(' ')}
                  onClick={() => onRowClick(part)}
                >
                  <TableCell align='center' style={{ maxWidth: '60px' }}>
                    <Checkbox
                      name={part.id}
                      checked={formik.values[part.id] || false}
                      onChange={formik.handleChange}
                      disabled={!!(waiting || part.canceledAt || part.lostAt || (part.removedFromQuoteAt && !part.orderPlacedAt))}
                    />
                  </TableCell>
                  <TableCell>
                    {isPartBeingEdited(part) && !part.orderPlacedAt &&
                      <TextField
                        size='small'
                        name='name'
                        value={editablePartForm.values.name || ''}
                        onClick={e => e.stopPropagation()}
                        onChange={editablePartForm.handleChange}
                        disabled={waiting}
                      />}
                    {(!isPartBeingEdited(part) || (isPartBeingEdited(part) && part.orderPlacedAt)) &&
                      <>
                        {!part.removedFromQuoteAt || (part.removedFromQuoteAt && part.orderPlacedAt)
                          ? <Link
                              to={`/leads/${quote.leadId}/quotes/${quote.id}/parts/${part.id}`}
                              onClick={e => e.stopPropagation()}
                            >{part.name}
                            </Link>
                          : part.name}
                      </>}
                  </TableCell>
                  <TableCell>
                    {isPartBeingEdited(part) && !part.orderPlacedAt
                      ? <FormControl required fullWidth>
                        <InputLabel>Type</InputLabel>
                        <Select
                          fullWidth
                          variant='outlined'
                          size='small'
                          name='type'
                          label='type'
                          InputLabelProps={{ shrink: true }}
                          style={{ minWidth: '135px' }}
                          value={editablePartForm.values.type}
                          error={editablePartForm.touched.type && Boolean(editablePartForm.errors.type)}
                          onChange={editablePartForm.handleChange}
                          disabled={waiting}
                        >
                          <MenuItem value='oem'>OEM</MenuItem>
                          <MenuItem value='aftermarket'>Aftermarket</MenuItem>
                        </Select>
                      </FormControl>
                      : part.type}
                  </TableCell>
                  <TableCell>{isPartBeingEdited(part) && !part.orderPlacedAt
                    ? <TextField
                        fullWidth
                        size='small'
                        name='number'
                        value={editablePartForm.values.number || ''}
                        onClick={e => e.stopPropagation()}
                        onChange={editablePartForm.handleChange}
                        disabled={waiting}
                      />
                    : part.number}
                  </TableCell>
                  <TableCell align='center'>
                    {(part?.tireConnectOrderData) ? part.tireConnectOrderData?.order_info?.quantity : '1'}
                  </TableCell>
                  <TableCell align='right' style={{ lineHeight: '38px', verticalAlign: 'middle' }}>
                    {isPartBeingEdited(part)
                      ? <TextField
                          size='small'
                          name='cost'
                          autoComplete='off'
                          style={{ maxWidth: '100px', textAlign: 'right' }}
                          value={editablePartForm.values.cost}
                          onClick={e => e.stopPropagation()}
                          onChange={event => {
                            const newValue = event.target.value !== '' ? Number(event.target.value) : null
                            editablePartForm.setFieldValue('cost', newValue)
                          }}
                          InputProps={{
                            inputComponent: PriceInput
                          }}
                          disabled={waiting}
                        />
                      : <Dollars value={part.cost} />}
                  </TableCell>
                  <TableCell align='right'>
                    {isPartBeingEdited(part)
                      ? <TextField
                          size='small'
                          name='price'
                          style={{ maxWidth: '100px', textAlign: 'right!important' }}
                          value={editablePartForm.values.price}
                          autoFocus
                          onClick={e => e.stopPropagation()}
                          onChange={event => editablePartForm.setFieldValue('price', Number(event.target.value))}
                          InputProps={{
                            inputComponent: PriceInput
                          }}
                          disabled={waiting}
                        />
                      : <Dollars value={part.price} />}
                  </TableCell>
                  <TableCell align='center'>
                    {isPartBeingEdited(part)
                      ? <Checkbox
                          checked={editablePartForm.values.specialOrder || false}
                          onChange={() =>
                            editablePartForm.setFieldValue('specialOrder', !editablePartForm.values.specialOrder)}
                          disabled={waiting}
                        />
                      : part.specialOrder ? <b style={{ color: 'red' }}>YES</b> : 'no'}
                  </TableCell>
                  <TableCell align='center'>
                    {isPartBeingEdited(part) && !part.orderPlacedAt
                      ? <Checkbox
                          checked={editablePartForm.values.prePaymentRequired || false}
                          onChange={() =>
                            editablePartForm.setFieldValue('prePaymentRequired', !editablePartForm.values.prePaymentRequired)}
                          disabled={waiting}
                        />
                      : part.prePaymentRequired ? <b style={{ color: 'red' }}>YES</b> : 'no'}
                  </TableCell>
                  <TableCell align='center'>
                    {isPartBeingEdited(part)
                      ? <TextField
                          size='small'
                          style={{ maxWidth: '60px', textAlign: 'right!important' }}
                          value={isDefined(editablePartForm.values.etaBusinessDays) ? editablePartForm.values.etaBusinessDays : ''}
                          autoFocus
                          type='number'
                          name='etaBusinessDays'
                          onClick={e => e.stopPropagation()}
                          onChange={event => {
                            const newValue = event.target.value !== '' ? Number(event.target.value) : null
                            editablePartForm.setFieldValue('etaBusinessDays', newValue)
                          }}
                          disabled={waiting}
                        />
                      : part.etaBusinessDays}
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ whiteSpace: 'nowrap' }}
                    className={partStatusClasses['bg_' + part.status]}
                  >{underscoreToTitleCase(part.status)}
                  </TableCell>
                  <TableCell
                    align='center'
                    onClick={e => e.stopPropagation()}
                  >
                    {isPartBeingEdited(part)
                      ? <>
                        <Button
                          size='small'
                          onClick={() => editablePartForm.resetForm({ values: initialPartValues })}
                          disabled={waiting}
                        >cancel
                        </Button>
                        <Button
                          size='small'
                          disableElevation
                          variant='contained'
                          sx={{ ml: 2 }}
                          onClick={editablePartForm.handleSubmit}
                          disabled={waiting || (saveDisabled(part) || !editablePartForm.isValid)}
                        >save
                        </Button>
                      </>
                      : partIsEditable(part) &&
                        <Button
                          size='small'
                          onClick={() => {
                            if (waiting) { return }
                            editablePartForm.resetForm({ values: part })
                          }}
                          disabled={waiting || disableEditing}
                        >edit
                        </Button>}
                  </TableCell>
                </TableRow>
                {part.orderPlacedAt &&
                  <TableRow
                    className='child-row'
                    sx={{ pt: 2 }}
                    style={{ backgroundColor: `${part.id === selectedPart.id ? '#99f8ff' : ''}`, cursor: 'pointer' }}
                    onClick={() => onRowClick(part)}
                  >
                    <TableCell />
                    <TableCell colSpan={3}>
                      <PartVendorSelect
                        name='partVendorId'
                        vendorState={lead.state}
                        hideAddVendor={!!(!part.orderPlacedAt || part.readyForPickupAt || !isPartBeingEdited(part))}
                        value={isPartBeingEdited(part)
                          ? editablePartForm.values.vendor
                          : {
                              id: part.partVendorId,
                              name: part.vendor ? part.vendor.name : ''
                            }}
                        onClick={e => e.stopPropagation()}
                        onChange={vendor => {
                          editablePartForm.setFieldValue('partVendorId', vendor ? vendor.id : null)
                          editablePartForm.setFieldValue('vendor', vendor || {})
                        }}
                        inputProps={{
                          name: 'partVendorId',
                          error: isPartBeingEdited(part) && (!editablePartForm.values.vendor || !editablePartForm.values.vendor.id),
                          required: true,
                          size: 'small'
                        }}
                        disabled={waiting || !part.orderPlacedAt || part.readyForPickupAt || !isPartBeingEdited(part)}
                      />
                    </TableCell>
                    <TableCell align='right'>
                      <TextField
                        size='small'
                        style={{ maxWidth: '100px', textAlign: 'right!important' }}
                        InputLabelProps={{ shrink: true }}
                        label='Core Refund'
                        value={(isPartBeingEdited(part) ? editablePartForm.values.coreRefundExpectedAmount : part.coreRefundExpectedAmount) || ''}
                        onClick={e => e.stopPropagation()}
                        onChange={event => {
                          const newValue = event.target.value !== '' ? Number(event.target.value) : null
                          editablePartForm.setFieldValue('coreRefundExpectedAmount', newValue)
                        }}
                        InputProps={{
                          inputComponent: PriceInput
                        }}
                        disabled={waiting || !isPartBeingEdited(part)}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <TextField
                        fullWidth
                        size='small'
                        name='ccLastFour'
                        style={{ minWidth: '66px', maxWidth: '166px', textAlign: 'right!important' }}
                        InputLabelProps={{ shrink: true }}
                        label='Payment (last 4)'
                        value={isPartBeingEdited(part) ? editablePartForm.values.ccLastFour : part.ccLastFour}
                        onClick={e => e.stopPropagation()}
                        onChange={editablePartForm.handleChange}
                        disabled={waiting || editablePartForm.values.id !== part.id}
                        error={isPartBeingEdited(part) && Boolean(editablePartForm.errors.ccLastFour)}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={(isPartBeingEdited(part) ? editablePartForm.values.isPaid : part.isPaid) || false}
                            onChange={event =>
                              editablePartForm.setFieldValue('isPaid', !editablePartForm.values.isPaid)}
                            disabled={waiting || !part.orderPlacedAt || editablePartForm.values.id !== part.id}
                          />
}
                        label='Paid'
                      />
                    </TableCell>
                    <TableCell colSpan={4} />
                  </TableRow>}
              </React.Fragment>
            )}
            <TotalRow parts={parts} />
          </TableBody>
        </Table>
      </TableContainer>
      <OrderPartDialog
        lead={lead}
        parts={selectedParts}
        isOpen={orderPartDialogOpen}
        onClose={(res) => {
          setOrderPartDialogOpen(false)
          if (res) {
            updateAllCheckboxes(false)
          }
        }}
      />
      <RefundDialog
        parts={selectedParts}
        isOpen={partRefundDialogOpen}
        onClose={() => { setPartRefundDialogOpen(false) }}
      />
      <CoreRefundDialog
        parts={selectedParts}
        isOpen={partCoreRefundDialogOpen}
        onClose={() => { setPartCoreRefundDialogOpen(false) }}
      />
      <ReturnDialog
        parts={selectedParts}
        isOpen={partReturnDialogOpen}
        onClose={() => { setPartReturnDialogOpen(false) }}
      />
      <ConfirmDialog
        open={confirmCancelDialogOpen}
        title='Confirm Action'
        heading='Are you sure you want to cancel this part?'
        text='This action cannot be undone.'
        okText='Yes'
        cancelText='No'
        onClose={(res) => {
          setConfirmCancelDialogOpen(false)
          if (res) {
            handleCancelPart()
          }
        }}
      />
      <ConfirmDialog
        open={confirmRemoveDialogOpen}
        title='Confirm Action'
        heading='Are you sure you want to remove this part from the quote?'
        text='This action cannot be undone.'
        okText='Yes'
        cancelText='No'
        onClose={(res) => {
          setConfirmRemoveDialogOpen(false)
          if (res) {
            handleRemovePart()
          }
        }}
      />
      <ConfirmDialog
        open={confirmLostDialogOpen}
        title='Confirm Action'
        heading='Are you sure you want to mark this part as lost?'
        text='This action cannot be undone.'
        okText='Yes'
        cancelText='No'
        onClose={(res) => {
          setConfirmLostDialogOpen(false)
          if (res) {
            handlePartLost()
          }
        }}
      />
    </>
  )
}

const partIsActive = (part) => (!part.removedFromQuoteAt && !part.canceledAt)
const costReducer = (acc, part) =>
  acc + (partIsActive(part) ? (part.cost || 0) : 0)

const priceReducer = (acc, part) =>
  acc + (partIsActive(part) ? (part.price || 0) : 0)

const TotalRow = ({ parts }) => (
  <TableRow>
    <TableCell />
    <TableCell><b>Totals (active parts)</b></TableCell>
    <TableCell />
    <TableCell />
    <TableCell style={{ paddingRight: '0' }} />
    <TableCell align='right' style={{ lineHeight: '38px', verticalAlign: 'middle' }}>
      <b><Dollars value={parts.reduce(costReducer, 0)} /></b>
    </TableCell>
    <TableCell align='right'>
      <b><Dollars value={parts.reduce(priceReducer, 0)} /></b>
    </TableCell>
  </TableRow>
)

export default PartAndSiblings
