import { useEffect, useState, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import { UserContext } from 'UserStore'
import { GlobalContext } from 'GlobalStore'
import Typography from '@mui/material/Typography'
import { MentionsInput, Mention } from 'react-mentions'
import mentionsClassNames from './mentions.module.css'
import { DateTime } from 'luxon'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const NOTES_QUERY = gql`
  query notes($parentId: ID!, $parentType: String!, $descendentsOnly: Boolean) {
    notes(parentId: $parentId, parentType: $parentType, descendentsOnly: $descendentsOnly) {
      id
      userType
      userId
      parentType
      parentId
      createdAt
      body
      replyToId
      mentionedUserIds
      resourcePath
    }
  }
`

const NotesStyles = makeStyles(theme => ({
  notes: {
    margin: '1em',
  },
  note_user: {
    marginRight: '1em',
  },
  new_note_input: {
    padding: '0.75em',
  },
}))

const mentionsRegex = /@\[[a-z,A-Z,\s\.\@]+\]\([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\)/gm
const mentionIdRegex = /[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/

const mentionedIdsInString = string => {
  const matches = string.match(mentionsRegex)
  if (matches) {
    return matches.map(matchedString => {
      const match = matchedString.match(mentionIdRegex)
      if (match && match.length > 0) {
        return match[0]
      } else {
        return null
      }
    })
  } else {
    return []
  }
}

const mentionableUsers = users =>
  users
    .filter(activeUser => Boolean(activeUser.status !== 'inactive'))
    .map(user => ({ id: user.id, display: `${user.firstName} ${user.lastName.charAt(0)}.` }))
    .sort((a, b) => (a.display > b.display ? 1 : -1))

const Notes = ({ headerText, objectType, objectId, descendentsOnly, noteContainerStyle }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const classes = NotesStyles()
  const [user] = useContext(UserContext)
  const [newNote, setNewNote] = useState('')
  const [mentionedUserIds, setMentionedUserIds] = useState([])
  const [global] = useContext(GlobalContext)

  useEffect(() => {
    setMentionedUserIds(mentionedIdsInString(newNote))
  }, [newNote])

  const { loading, error, data, refetch } = useQuery(NOTES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      parentId: objectId,
      parentType: objectType,
      descendentsOnly: descendentsOnly,
    },
  })

  if (loading) return <div>Loading</div>
  if (error) return <div>Error</div>

  const handleClickSubmitNote = () =>
    newNote.trim().length > 0 &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_note`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        user_type: 'csr',
        parent_type: objectType,
        parent_id: objectId,
        body: newNote,
        mentioned_user_ids: mentionedUserIds,
      }),
    })
      .then(response => {
        if (response.ok) {
          return true
        } else {
          throw Error('bad range?')
        }
      })
      .then(() => {
        refetch()
        setNewNote('')
      })
      .catch(err => {
        window.alert(err.message)
      })

  const handleNewNoteChanged = evt => {
    setNewNote(evt.target.value)
  }

  const notes = data.notes
    .slice()
    .sort((a, b) => (DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt) ? 1 : -1))

  return (
    <>
      <Typography color='primary' variant='h6'>
        {descendentsOnly && 'Descendent'} {headerText || 'Notes'}
      </Typography>

      <div className={classes.notes} style={noteContainerStyle}>
        {notes.map(note => (
          <CurrentNote
            objectId={objectId}
            objectType={objectType}
            key={note.id}
            note={note}
            descendentsOnly={descendentsOnly}
            refetch={refetch}
          />
        ))}
      </div>

      <MentionsInput
        cy-data='note-input'
        className='mentions-new'
        style={{ marginBottom: '10px' }}
        classNames={mentionsClassNames}
        value={newNote}
        onChange={handleNewNoteChanged}
        placeholder='Add new note'
      >
        <Mention
          displayTransform={(id, display) => `@${display}`}
          style={{ backgroundColor: 'rgb(215 255 170)' }}
          trigger='@'
          data={mentionableUsers(global.users)}
          appendSpaceOnAdd
        />
      </MentionsInput>

      <Button cy-data='note-input-submit' onClick={handleClickSubmitNote} variant='contained' color='primary'>
        Submit
      </Button>
      <br />
      <br />
    </>
  )
}

const CurrentNoteStyles = makeStyles(theme => ({
  user_and_note: {
    flexGrow: 1,
    width: '100%',
    marginBottom: '0.5em',
  },
  mentionInput: {
    border: '1px solid red',
    padding: '2em',
  },
  note_user: {
    marginRight: '1em',
    marginBottom: '0',
  },
  reply: {
    paddingLeft: '3em',
  },
  current_note: {
    width: '100%',
    height: 'auto',
  },
}))
const CurrentNote = ({ note, refetch, descendentsOnly, objectId, objectType }) => {
  const classes = CurrentNoteStyles()
  const [clickedReply, setClickedReply] = useState(false)
  const [clickedReplyAll, setClickedReplyAll] = useState(false)
  const [global] = useContext(GlobalContext)
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const handleClickCancel = () => {
    setClickedReply(false)
    setClickedReplyAll(false)
  }

  const handleClickDelete = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_note`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        note_id: note.id,
        parentId: objectId,
        parentType: objectType,
      }),
    }).then(response => {
      if (response.ok) {
        refetch()
      } else {
        throw Error('bad range?')
      }
    })
  }

  const fromUser =
    user.id === note.userId
      ? { firstName: 'me', lastName: '' }
      : global.users.find(user => user.id === note.userId) || { firstName: 'unknown', lastName: '' }

  return (
    <>
      <div className={classes.user_and_note}>
        <div className={classes.note_user}>
          <b>{`${fromUser.firstName} ${fromUser.lastName}`}</b>
          &nbsp;
          <small>{DateTime.fromISO(note.createdAt).toFormat('yyyy-LL-dd h:mm a ZZZZ')}</small>
          {(note.userId === user.id || user.roles.includes('admin')) && (
            <Button
              size='small'
              onClick={() =>
                window.confirm('are you sure you want to delete this? this cannot be undone') && handleClickDelete()
              }
              color='error'
            >
              delete
            </Button>
          )}
        </div>

        <div className={classes.note_and_reply_button}>
          <MentionsInput cy-data='note-input' className='mentions' classNames={mentionsClassNames} value={note.body}>
            <Mention
              displayTransform={(id, display) => `@${display}`}
              trigger='@'
              data={mentionableUsers(global.users)}
              appendSpaceOnAdd
            />
          </MentionsInput>

          {!descendentsOnly && (
            <>
              {clickedReply || clickedReplyAll || (
                <div>
                  {note.mentionedUserIds.length > 0 && (
                    <Button
                      cy-daya='reply-all-note-button'
                      size='small'
                      onClick={() => setClickedReplyAll(true)}
                      color='primary'
                    >
                      reply all
                    </Button>
                  )}

                  <Button
                    cy-daya='reply-note-button'
                    size='small'
                    onClick={() => setClickedReply(true)}
                    color='primary'
                  >
                    reply
                  </Button>
                </div>
              )}
            </>
          )}
        </div>

        {!descendentsOnly && (
          <>
            {clickedReply && <Reply handleClickCancel={handleClickCancel} all={false} note={note} refetch={refetch} />}
            {clickedReplyAll && <Reply handleClickCancel={handleClickCancel} all note={note} refetch={refetch} />}
            {(clickedReply || clickedReplyAll) && (
              <>
                <small onClick={handleClickCancel} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                  cancel
                </small>
                <br />
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

const Reply = ({ note, refetch, all, handleClickCancel }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const classes = CurrentNoteStyles()
  const [mentionedUserIds, setMentionedUserIds] = useState([])
  const [global] = useContext(GlobalContext)
  const mentionData = mentionableUsers(global.users)

  const [newNote, setNewNote] = useState(() =>
    mentionData
      .filter(
        mentionDataUser =>
          mentionDataUser.id === note.userId || (all && note.mentionedUserIds.includes(mentionDataUser.id))
      )
      .map(mentionUser => `@[${mentionUser.display}](${mentionUser.id}) `)
      .join('')
  )

  useEffect(() => {
    setMentionedUserIds(mentionedIdsInString(newNote))
  }, [newNote])

  const handleClickSubmitReply = () =>
    newNote.trim().length > 0 &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_note`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        user_type: 'csr',
        parent_type: note.parentType,
        parent_id: note.parentId,
        body: newNote,
        mentioned_user_ids: mentionedUserIds,
        reply_to_id: note.id,
      }),
    })
      .then(response => {
        if (response.ok) {
          return response.text()
        } else {
          throw Error('bad range?')
        }
      })
      .then(responseText => {
        setNewNote('')
        handleClickCancel()
        refetch()
      })
      .catch(err => {
        window.alert(err.message)
      })

  const handleNewReplyNoteChanged = evt => {
    setNewNote(evt.target.value)
  }

  return (
    <div className={classes.reply}>
      <MentionsInput
        cy-data='reply-note-input'
        className='mentions-reply'
        classNames={mentionsClassNames}
        value={newNote}
        onChange={handleNewReplyNoteChanged}
      >
        <Mention
          displayTransform={(id, display) => `@${display}`}
          style={{ backgroundColor: 'rgb(215 255 170)' }}
          trigger='@'
          data={mentionableUsers(global.users)}
          appendSpaceOnAdd
        />
      </MentionsInput>

      <Button
        cy-data='reply-note-submit'
        size='small'
        style={{ marginTop: '1em' }}
        onClick={handleClickSubmitReply}
        variant='contained'
        color='primary'
      >
        submit reply
      </Button>
    </div>
  )
}

export default Notes
