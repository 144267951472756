import { Box, Button, Grid } from '@mui/material'
import { useParams, Switch, Route, Link, useRouteMatch } from 'react-router-dom'
import { useJobApplication } from 'hooks/recruiting/jobApplications/useJobApplication'
import InterviewRouter from './InterviewView/Router'
import { DateTime } from 'luxon'
import { CreateATask } from 'Tasks'
import Show from './JobApplication/Show'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { useContext, useEffect, useState } from 'react'

const JobApplicationPage = () => {
  const params: any = useParams()
  const { path } = useRouteMatch()

  const [seeFullCoverLetter, setSeeFullCoverLetter] = useState(false)

  const jobApplicationId = params?.applicationId
  const { jobApplication } = useJobApplication(jobApplicationId)
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)

  useEffect(() => {
    const key = registerTaskTarget({ targetType: 'JobApplication', targetId: jobApplicationId })
    return () => unregisterTaskTarget(key)
  }, [])

  if (!jobApplicationId || !jobApplication) return null

  return (
    <Box>
      <Box
        sx={[
          { p: 1, background: '#e3fae3' },
          (jobApplication?.withdrewAt && {
            background: '#FFBEBE',
          }) ||
            {},
        ]}
      >
        {jobApplication?.withdrewAt && (
          <Box sx={{ width: '100%', padding: '0rem 1rem', textAlign: 'center', borderBottom: '1px dashed #222' }}>
            Withdrew at {DateTime.fromISO(jobApplication.withdrewAt).toFormat('DD t')}
          </Box>
        )}
        <span style={{ marginRight: '.5rem', fontSize: '1.2em' }}>
          <Link to={`/applicants/${jobApplication?.applicant?.id}`}> [A] {jobApplication?.applicant?.fullName}</Link>
        </span>
        <span style={{ marginRight: '.5rem' }}>
          {jobApplication?.createdAt && DateTime.fromISO(jobApplication?.createdAt).toFormat('DD')}
        </span>
        <CreateATask targetType='JobApplication' targetId={jobApplication.id} /> <br />
        <span style={{ marginRight: '.5rem' }}>
          <b>phone: </b> {jobApplication?.applicant?.phone}
        </span>
        <span style={{ marginRight: '.5rem' }}>
          <b>email: </b> {jobApplication?.applicant?.email}
        </span>
        <span style={{ marginRight: '.5rem' }}>
          <b>job applied for: </b> {jobApplication?.jobListing?.jobTitle}
        </span>
        <br />
        <span style={{ marginRight: '.5rem' }}>
          <b>resume: </b> {jobApplication?.resumeUrl}
        </span>{' '}
        <br />
        {jobApplication?.coverLetter && (
          <span style={{ marginRight: '.5rem' }}>
            <b>cover letter: </b>{' '}
            {jobApplication?.coverLetter?.length > 400
              ? `${jobApplication?.coverLetter?.slice(
                  0,
                  seeFullCoverLetter ? jobApplication?.coverLetter?.length : 400
                )}${seeFullCoverLetter ? '' : '...'}`
              : jobApplication?.coverLetter}
          </span>
        )}
        {jobApplication?.coverLetter && (
          <>
            {jobApplication?.coverLetter?.length > 400 && (
              <Button size='small' onClick={() => setSeeFullCoverLetter(!seeFullCoverLetter)}>
                {seeFullCoverLetter ? 'Hide' : 'See more'}
              </Button>
            )}
          </>
        )}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Switch>
            <Route path={`${path}/interviews`}>
              <InterviewRouter />
            </Route>
            <Route path={path}>
              <Show jobApplicationId={jobApplicationId} />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </Box>
  )
}

export default JobApplicationPage
