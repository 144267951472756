import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from './GlobalStore'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Dollars } from 'tools'
import TextField from '@mui/material/TextField'

const LEADS_SOLD_TODAY = gql`
  query leads($filter: JSON) {
    leads(filter: $filter) {
      id
      name
      market
      soldAt
      soldByUserId
      soldWithJobId
      soldWithQuoteId
      soldWithJob {
        id
        technicianId
        startDatetime
        endDatetime
      }
      soldWithQuote {
        id
        postTaxGrandTotal
      }
    }
  }
`

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const StyledTableFooterRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#e2ffea',
}))

const Sales = () => {
  const [day, setDay] = useState(DateTime.local().setZone('America/Los_Angeles'))
  const [global] = useContext(GlobalContext)

  const userById = id => global.users.find( user => user.id === id )
  const techById = id => global.technicians.find( tech => tech.id === id )

  const [filter, setFilter] = useState({
    sold_at: {
      gte: day.startOf('day').toISO(),
      lt: day.endOf('day').toISO()
    }
  })

  const { loading, error, data, refetch } = useQuery(LEADS_SOLD_TODAY, {
    variables: {
      filter,
    }
  })

  useEffect(() => {
    setFilter({
      sold_at: {
        gte: day.startOf('day').toISO(),
        lt: day.endOf('day').toISO()
      }
    })
  }, [day])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const leadsBySoldByUserId = data.leads.reduce( (acc,lead) =>
    ({
      ...acc,
      ...{
        [lead.soldByUserId]: [...acc[lead.soldByUserId] || [], lead]
      }
    })
    , {})

  const handleChangedDay = e => setDay(DateTime.fromISO(e.target.value, {zone: 'utc'}))

  return <>
    <br />
    <Typography variant="h6">
      Daily sales&nbsp;
      <TextField
        label="Day"
        size="small"
        type="date"
        value={day.toISODate()}
        onChange={handleChangedDay}
      />
      <br />
      <small>
        new lead conversions only
      </small>
    </Typography>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Customer</StyledTableCell>
            <StyledTableCell align="right">CSR</StyledTableCell>
            <StyledTableCell align="right">Market</StyledTableCell>
            <StyledTableCell align="right">Technician</StyledTableCell>
            <StyledTableCell align="right">Quote</StyledTableCell>
            <StyledTableCell align="right">Job</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(leadsBySoldByUserId).map(userId =>
            <>
              {leadsBySoldByUserId[userId].map(lead =>
                <StyledTableRow key={lead.id}>
                  <StyledTableCell component="th" scope="row">
                    <Link to={`/leads/${lead.id}`}>
                      {lead.name}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    { `${userById(lead.soldByUserId).firstName} ${userById(lead.soldByUserId).lastName[0]}.` }
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {lead.market}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {`${techById(lead.soldWithJob.technicianId).firstName} ${techById(lead.soldWithJob.technicianId).lastName}`}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Link to={`/leads/${lead.id}/quotes/${lead.soldWithQuote.id}`}>
                      <Dollars value={lead.soldWithQuote.postTaxGrandTotal} />
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Link to={`/leads/${lead.id}/quotes/${lead.soldWithQuote.id}/jobs/${lead.soldWithJob.id}`}>
                      {DateTime.fromISO(lead.soldWithJob.startDatetime, {zone: 'utc'}).toISODate()}
                      <br />
                      {DateTime.fromISO(lead.soldWithJob.startDatetime, {zone: 'utc'}).toLocaleString(DateTime.TIME_SIMPLE)}
                      &nbsp; - &nbsp;
                      {DateTime.fromISO(lead.soldWithJob.endDatetime, {zone: 'utc'}).toLocaleString(DateTime.TIME_SIMPLE)}
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              )}

              <StyledTableFooterRow>
                <StyledTableCell colSpan={4}>Summary</StyledTableCell>
                <StyledTableCell align="right">
                  <Dollars
                    value={leadsBySoldByUserId[userId].reduce((acc,lead) => acc + lead.soldWithQuote.postTaxGrandTotal, 0)}
                  />
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableFooterRow>
              <TableRow>
                <TableCell colSpan={6}>
                  &nbsp;
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

export default Sales
