import { Box, Button, Chip, TextField, Typography } from '@mui/material'
import { useState } from 'react'

export const MultipleChoiceCreator = ({ options, setOptions }) => {
  const [optionName, setOptionName] = useState('')

  const handleUpdateOption = () => {
    setOptions(prev => [...prev, optionName])
    setOptionName('')
  }

  return (
    <Box>
      <Box sx={{ padding: '0rem' }}>
        <Typography variant='body1'>Multiple choice options to add </Typography>

        <Box>
          {options?.map((option, index) => (
            <Chip
             sx={{ mb: '1rem', mr: '.5rem' }}
              key={option}
              onDelete={() => setOptions(prev => prev.filter(i => i !== option))}
              label={`#${index + 1}. ${option}`}
            />
          ))}
        </Box>
      </Box>

      <TextField
        label='Multiple choice item label'
        value={optionName}
        onChange={e => setOptionName(e.target.value)}
        placeholder='Option title'
        size='small'
      />

      <Button disabled={optionName?.length < 3 || options?.includes(optionName)} onClick={() => handleUpdateOption()}>
        Add
      </Button>
    </Box>
  )
}
