import { useEffect, useContext, useState } from 'react'
import { Dollars, basicDateTimeFormatter, keysToCamel } from 'tools'
import { Box, Checkbox, Collapse, CircularProgress, Chip, Dialog } from '@mui/material'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { bToBInvoiceStatementsConnection, DEALERS_QUERY, useStyles } from './tools'
import { SelectBusinessHeadCell } from './BToBInvoiceStatement/Components/SelectBusinessHeadCell'
import { DateRangeHeadCell } from './BToBInvoiceStatement/Components/DateRangeHeadCell'
import { SelectLabelHeadCell } from './BToBInvoiceStatement/Components/SelectLabelHeadCell'

export const InProgress = () => {
  const classes = useStyles()
  const [user] = useContext(UserContext)
  const parseState = true

  const bearerTokenHeaders = useBearerTokenHeaders()

  const [isLoading, setIsLoading] = useState(false)

  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([])
  const [search, setSearch] = useLocalStorage('bToBInvoiceStatementsSearchFromInProgressPage', {}, parseState)
  const [order, setOrder] = useLocalStorage('bToBInvoiceStatementsOrderFromInProgressPage', {}, parseState)
  const [filter, setFilter] = useLocalStorage('bToBInvoiceStatementsFilterFromInProgressPage', {}, parseState)
  const [limit, setLimit] = useLocalStorage('bToBInvoiceStatementsLimitFromInProgressPage', 50)

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  const handleClickDeleteInvoice = () => {
    if (window.confirm('Please confirm')) {
      setIsLoading(true)
      selectedInvoiceIds.map(id =>
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_invoice_statement`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: id,
            userId: user.id,
          }),
        })
          .then(res => res.ok || window.alert('Error'))
          .then(res => setTimeout(() => refetch(), 250))
          .then(res => setSelectedInvoiceIds([]))
          .finally(() => setIsLoading(false))
      )
    } else {
    }
  }

  const handleClickMarkInvoiceAsTest = () => {
    if (window.confirm('Please confirm')) {
      setIsLoading(true)
      selectedInvoiceIds.map(id =>
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_statement_attributes`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            bToBInvoiceId: id,
            updatedAttributes: { is_a_test: true },
            actorType: 'csr',
            actorId: user.id,
          }),
        })
          .then(res => res.ok || window.alert('Error'))
          .then(res => setTimeout(() => refetch(), 250))
          .then(res => setSelectedInvoiceIds([]))
          .finally(() => setIsLoading(false))
      )
    }
  }

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: Number(limit),
        after: '',
        isCompleted: false,
      },
    })
  }, [search, order, filter, limit])

  const { loading, error, data, fetchMore, refetch } = useQuery(bToBInvoiceStatementsConnection, {
    variables: {
      search,
      order,
      filter,
      first: Number(limit),
      after: cursorCeiling,
      isCompleted: false,
    },
  })

  const { data: dealersData } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const statements = data.bToBInvoiceStatementsConnection.edges.map(edge => edge.node)
  const totalCount = data.bToBInvoiceStatementsConnection.totalCount
  const endCursor = data.bToBInvoiceStatementsConnection.pageInfo.endCursor

  const dealers = dealersData?.bToBDealers || []

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: limit,
          after: endCursor,
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <>
      <Collapse in={selectedInvoiceIds.length > 0}>
        <Box sx={{ display: 'flex', padding: '1rem', justifyContent: 'flex-end' }}>
          <Button onClick={handleClickMarkInvoiceAsTest} size='small' variant='contained' sx={{ mr: '1rem' }}>
            Mark Selected as Test ({selectedInvoiceIds.length})
          </Button>
          <Button onClick={handleClickDeleteInvoice} size='small' variant='outlined'>
            Delete Selected
          </Button>
        </Box>
      </Collapse>

      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            background: '#11111140',
            bottom: '0px',
            width: '100%',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color='warning' />
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='right'></TableCell>
              <TableCell align='right'>Statement #</TableCell>
              <DateRangeHeadCell
                name='created_at'
                filter={filter}
                setFilter={setFilter}
                order={order}
                setOrder={setOrder}
              />
              <SelectBusinessHeadCell align='right' filter={filter} setFilter={setFilter} dealers={dealers} />
              <TableCell align='right'>Grand Total</TableCell>
              <TableCell align='right'>Paid Sq. Invoice #</TableCell>
              <SelectLabelHeadCell filter={filter} setFilter={setFilter} />
            </TableRow>
          </TableHead>
          <TableBody>
            {statements.slice(page * limit, page * limit + limit).map(statement => (
              <TableRow key={statement.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  <Checkbox
                    onChange={() =>
                      selectedInvoiceIds.includes(statement.id)
                        ? setSelectedInvoiceIds(prev => prev.filter(id => id !== statement.id))
                        : setSelectedInvoiceIds(prev => [...prev, statement.id])
                    }
                    checked={selectedInvoiceIds.includes(statement.id)}
                  />
                </TableCell>

                <TableCell align='right'>{statement.statementNumber}</TableCell>

                <TableCell>
                  <Link target='_blank' to={`/b-to-b/statements/${statement.id}`}>
                    {basicDateTimeFormatter(statement.createdAt)}
                  </Link>
                </TableCell>
                <TableCell align='right'>{statement.businessName}</TableCell>
                <TableCell align='right'>
                  <Dollars value={statement.grandTotal} />
                </TableCell>
                <TableCell align='right'>{statement.paidSquareInvoiceId}</TableCell>
                <TableCell align='right'>{statement.label && <Chip size='small' label={statement.label} />}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}
