import { Grid, Collapse, Select, MenuItem, FormControl, InputLabel, Button, TextField } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { lookupPrice } from '../tools'

export const NewLineItem = ({ lineItems, invoice }) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [waiting, setWaiting] = useState(false)
  const [lineItem, setLineItem] = useState({})
  const resetLineItem = () => setLineItem({})

  const handleSelectedPanel = panel => setLineItem({ panel })
  const handleSelectedSide = side => setLineItem(lineItem => ({ ...lineItem, side }))

  const handleClickAdd = () => {
    setWaiting(true)

    const foundItem = lineItems.find(
      item =>
        lineItem.panel === item.panel &&
        lineItem.type === item.type &&
        (hasAtLeastOneSide ? item.side === lineItem.side : true)
    )

    const { pricingCategory, ...foundItemWithoutPricingCategory } = foundItem

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_invoice_line_item`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        lineItem: {
          ...lineItem,
          ...foundItemWithoutPricingCategory,
        },
        actorType: 'csr',
        actorId: user.id,
      }),
    })
      .catch(() => window.alert('There was an error creating a new invoice.'))
      .finally(() => {
        setWaiting(false)
        setLineItem({})
      })
  }

  const possiblePanels = [...new Set(lineItems.slice().map(item => item.panel))].sort()
  const possibleSides = [
    ...new Set(lineItems.filter(item => item.panel === lineItem.panel).map(item => item.side)),
  ].sort()

  const hasAtLeastOneSide = possibleSides.length > 1

  const possibleTypes = [
    ...new Set(
      lineItems
        .filter(item => item.panel === lineItem.panel && (hasAtLeastOneSide ? item.side === lineItem.side : true))
        .map(item => item.type)
    ),
  ].sort()

  const lineItemIsSavable =
    (hasAtLeastOneSide ? lineItem.side : true) && lineItem.panel && lineItem.type && lineItem.price

  const handleSelectedType = type =>
    setLineItem(prev => ({
      ...prev,
      ...{
        type,
        price: lookupPrice({ lineItems, lineItem: { ...lineItem, type }, hasAtLeastOneSide }),
      },
    }))

  useEffect(() => {
    setLineItem(prev => ({
      ...prev,
      price: lookupPrice({ lineItems, lineItem, hasAtLeastOneSide }),
    }))
  }, [lineItem.panel, lineItem.side, lineItem.type])

  return (
    <Grid container spacing={0} sx={{ mb: '1.25rem' }}>
      <Grid item xs={12} sx={{ mb: '.25rem' }}>
        Add
      </Grid>

      <Grid item xs={12} sx={{ pr: '.25rem' }}>
        <Grid container>
          <Grid item xs={hasAtLeastOneSide ? 5 : 8} sx={{ pr: '.25rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Type</InputLabel>
              <Select
                label='type'
                size='small'
                value={lineItem.panel || ''}
                onChange={e => handleSelectedPanel(e.target.value)}
              >
                {possiblePanels.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {hasAtLeastOneSide && (
            <Grid item xs={3}>
              <FormControl fullWidth size='small'>
                <InputLabel>Side</InputLabel>
                <Select label='side' value={lineItem.side || ''} onChange={e => handleSelectedSide(e.target.value)}>
                  {possibleSides.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={4} sx={{ pr: '.5rem', pl: '.5rem' }}>
            <FormControl fullWidth size='small'>
              <InputLabel>Type</InputLabel>
              <Select label='type' value={lineItem.type || ''} onChange={e => handleSelectedType(e.target.value)}>
                {possibleTypes.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Collapse in={lineItemIsSavable} sx={{ width: '100%' }}>
        <Grid container>
          <Grid item xs={12} sx={{ display: 'flex', mt: '.5rem' }}>
            <Button onClick={resetLineItem} color='error'>
              Cancel
            </Button>
            <Button
              disabled={waiting}
              onClick={handleClickAdd}
              disableElevation
              variant='contained'
              sx={{ ml: 'auto' }}
            >
              Add ({`$${lineItem.price}`})
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  )
}
