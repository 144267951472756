import { Box, Typography, Button, FormControlLabel, Switch } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UserContext } from 'UserStore'
import { DealersTable } from './Components/DealersTable'

const Index = () => {
  const [showInactive, setShowInactive] = useState(false)
  const [user] = useContext(UserContext)
  const history = useHistory()
  const bearerTokenHeaders = useBearerTokenHeaders()

  const handleCreateBToBDealer = () => {
    let businessName = window.prompt('what should the business name be?')
    if (businessName) {
      businessName = businessName.substring(0, 35)
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_dealer`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          businessName: businessName,
        }),
      }).then(res => {
        if (res) {
          res.json().then(dealer => {
            if (dealer.dealer) {
              history.push(`/b-to-b/dealers/${dealer.dealer.id}`)
            }
          })
        } else {
        }
      })
    }
  }

  return (
    <Box>
      <Box sx={{ padding: '0rem 1rem' }}>
        <FormControlLabel
          control={<Switch checked={showInactive} onChange={e => setShowInactive(e.target.checked)} />}
          label='show inactive'
        />
      </Box>
      <Box sx={{ padding: '0rem 1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='h4' sx={{ mt: '1rem', mb: '1rem' }}>
          B2B Dealers
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            disabled={!user.roles.includes('admin')}
            size='small'
            onClick={handleCreateBToBDealer}
            variant='contained'
          >
            Create new dealer
          </Button>
        </Box>
      </Box>
      <DealersTable showInactive={showInactive} />
    </Box>
  )
}

export default Index
