import React, { useEffect, useState } from 'react'
import { Grid, Modal, Box, Button, Tabs, Tab, Typography } from '@mui/material'
import New from './New'
import ReadyForPayroll from './PendingReview'
import Accepted from './ReadyForPayroll'
import All from './All'
import { modalBoxStyle } from './tools'

const Index = () => {
  const [newTimeCardPopup, setNewTimeCardPopup] = useState(false)
  const [currentTab, setCurrentTab] = useState(localStorage.getItem('timeCardsTab') || 'unaccepted')

  useEffect(() => localStorage.setItem('timeCardsTab', currentTab), [currentTab])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ padding: '0rem 1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='h4' sx={{ mt: '1rem', mb: '1rem' }}>
              Employee Time Cards
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Tabs value={currentTab} onChange={(e, v) => setCurrentTab(v)}>
            <Tab value='pending' label='pending review' />
            <Tab value='ready' label='ready for payroll' />

            <Tab value='all' label='all' />
            {/* <Tab value='in-progress' label='in-progress' /> */}
          </Tabs>
        </Grid>

        <Grid item xs={6} sx={{ display: 'flex' }}>
          <Button size='small' onClick={() => setNewTimeCardPopup(true)} sx={{ ml: 'auto' }} variant='contained'>
            Create new
          </Button>
        </Grid>

        {currentTab === 'pending' && <ReadyForPayroll />}

        {currentTab === 'ready' && <Accepted />}

        {currentTab === 'all' && <All />}
      </Grid>
      <Modal open={newTimeCardPopup} onClose={() => setNewTimeCardPopup(false)}>
        <Box sx={modalBoxStyle}>
          <New setCurrentTab={setCurrentTab} />
        </Box>
      </Modal>
    </>
  )
}

export default Index
