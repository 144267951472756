import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import New from './New'
import Job from './Job'

const JobRouter = ({ lead, quote, refetchQuote }) => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <New lead={lead} quote={quote} />
      </Route>
      <Route path={`${path}/:jobId`}>
        <Job refetchQuote={refetchQuote} lead={lead} quote={quote} />
      </Route>
    </Switch>
  )
}

export default JobRouter
