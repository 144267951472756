import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import Part from './Part'

const PartRouter = ({ lead, quote }) => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:partId`}>
        <Part lead={lead} quote={quote} />
      </Route>
    </Switch>
  )
}

export default PartRouter
