import React, { forwardRef } from 'react'
import NumberFormat from 'react-number-format'

const PriceInput = forwardRef((props, ref) => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      prefix='$'
    />
  )
})

export default PriceInput
