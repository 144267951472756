import React, { useState, useContext } from 'react'
import Typography from '@mui/material/Typography'
import { useQuery, gql } from '@apollo/client'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { basicDateTimeFormat, PriceInput, Dollars, getUploadParamsForDropzone } from 'tools'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Dropzone from 'react-dropzone-uploader'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { DateTime } from 'luxon'

const QUERY = gql`
  query reimbursements($technicianId: ID) {
    reimbursements(technicianId: $technicianId) {
      id
      createdByType
      createdById
      createdAt
      amount
      photoUrls
      payoutId
      note
      deletedAt
      payout {
        id
        createdAt
      }
    }
  }
`

const modalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  backgroundColor: 'white',
  padding: '1em',
  boxShadow: 24,
  maxHeight: '500px',
  overflow: 'scroll',
  p: 4
}

const Reimbursements = ({ technician }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const [reimbursementAmount, setReimbursementAmount] = useState(0)
  const [reimbursementNote, setReimbursementNote] = useState('')
  const [reimbursementForPhotoUploadModal, setReimbursementForPhotoUploadModal] = useState(null)

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { technicianId: technician.id }
  })
  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const allowCreate = reimbursementAmount > 0 && reimbursementNote.length > 2

  const handleChangeNote = e => setReimbursementNote(e.target.value)

  const handleClickCreateReimbursement = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_reimbursement`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        createdByType: 'user',
        createdById: user.id,
        technicianId: technician.id,
        amount: reimbursementAmount,
        note: reimbursementNote
      })
    })
      .then(response => {
        if (response.ok) {
          return true
        } else {
          throw Error('error')
        }
      })
      .then(data => {
        setReimbursementAmount(0)
        setReimbursementNote('')
        setTimeout(refetch, 200)
      })
      .catch(err => {
        window.alert(err)
      })

  const deleteReimbursement = id =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_reimbursement`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        deletedByType: 'user',
        deletedById: user.id,
        reimbursementId: id
      })
    })
      .then(res => res.ok || window.alert('error'))

  const PhotoUploader = ({ reimbursement }) => {
    const postPhoto = photo =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_photo_url_to_reimbursement`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          reimbursementId: reimbursement.id,
          addedByType: 'user',
          addedById: user.id,
          photoUrl: photo.meta.fileUrl
        })
      })
        .then(response => {
          if (response.ok) {
            photo.remove()
            return true
          } else {
            throw Error('error')
          }
        })
        .catch(err => {
          window.alert(err)
        })

    const handleSubmit = photos => {
      photos.forEach(photo =>
        postPhoto(photo)
      )
      setReimbursementForPhotoUploadModal(null)
    }

    return (
      <Dropzone
        addClassNames={{ dropzone: 'dzu-dropzone-custom' }}
        getUploadParams={getUploadParamsForDropzone}
        accept='image/*'
        onSubmit={handleSubmit}
        inputContent='Attach photos: '
      />
    )
  }

  return (
    <>
      <Typography variant='h6'>
        Reimbursements
      </Typography>

      <TextField
        size='small' value={reimbursementAmount}
        onChange={event => setReimbursementAmount(Number(event.target.value))}
        InputProps={{
          inputComponent: PriceInput,
          name: 'technician-reimbursement-amount'
        }}
      />
      <TextField
        style={{ minWidth: '250px' }}
        size='small'
        value={reimbursementNote} label='Note'
        variant='outlined' multiline onChange={handleChangeNote}
        InputProps={{
          name: 'technician-reimbursement-note'
        }}
      />

      <Button cy-data="technician-reimbursement-create-button" size='small' disabled={!allowCreate} variant='outlined' onClick={handleClickCreateReimbursement}>Create</Button>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='right'>amount</TableCell>
              <TableCell align='right'>note</TableCell>
              <TableCell align='right'>photos</TableCell>
              <TableCell align='right'>payout?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.reimbursements
              .filter(re => !re.deletedAt)
              .map(reimbursement => (
                <TableRow
                  key={reimbursement.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align='right'><Dollars value={reimbursement.amount} /></TableCell>
                  <TableCell align='right'>{reimbursement.note}</TableCell>
                  <TableCell align='right'>
                    {(reimbursement.photoUrls || []).map(photoUrl =>
                      <div
                        key={photoUrl}
                        onClick={() => window.open(photoUrl)}
                        style={{
                          border: '2px solid rgb(239, 239, 239)',
                          display: 'inline-block',
                          cursor: 'pointer',
                          width: '80px',
                          height: '80x'
                        }}
                      >
                        <img
                          style={{
                            objectFit: 'cover',
                            height: '100%',
                            width: '100%'
                          }}
                          src={photoUrl}
                        />
                      </div>
                    )}
                    <button
                      style={{ display: 'inline-block' }}
                      onClick={() => setReimbursementForPhotoUploadModal(reimbursement)}
                    > +
                    </button>
                  </TableCell>
                  <TableCell align='right'>
                    {reimbursement.payout
                      ? <>
                        {DateTime.fromISO(reimbursement.payout.createdAt).toFormat(basicDateTimeFormat)}
                      </>
                      : <>
                        not linked&nbsp;
                        <Button cy-data='technician-reimbursement-delete-button' size='small' onClick={() => deleteReimbursement(reimbursement.id)}>
                          delete
                        </Button>
                        </>}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {reimbursementForPhotoUploadModal &&
        <Modal
          open={reimbursementForPhotoUploadModal}
          onClose={() => setReimbursementForPhotoUploadModal(null)}
        >
          <Box style={modalBoxStyle}>
            <Typography sx={{ mt: 2 }}>
              <Dollars value={reimbursementForPhotoUploadModal.amount} />
              <pre>
                {reimbursementForPhotoUploadModal.note}
              </pre>
            </Typography>
            <PhotoUploader reimbursement={reimbursementForPhotoUploadModal} />
          </Box>
        </Modal>}
    </>
  )
}

export default Reimbursements
