import React from 'react'
import Dropzone from 'react-dropzone-uploader'
import { Chip, Button, Grid, InputLabel, Select, MenuItem, TextField, FormControl, Autocomplete } from '@mui/material'
import { Save } from '@mui/icons-material'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { API_COMMAND_HEADERS } from 'tools'
import { useHistory, useRouteMatch } from 'react-router-dom'
import STATES from 'states.json'
import { editableUserFields, userRoles } from './tools'
import { getPresignedUploadParams } from 'tools'

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
const phoneNumberRegex = /^(\+503\d{8}|\+54\d{10}|\+8869\d{8}|\+1\d{10})$/
const zipRegex = /^\d{5}$/

const userSchema = Yup.object().shape({
  status: Yup.string(),
  email: Yup.string().matches(emailRegex, 'Must match this form: tech@example.com').required(),
  phone: Yup.string()
    .matches(phoneNumberRegex, 'Phone number must match 1 of: +503(8#), +54(10#), +8869(8#), +1(10#)')
    .nullable(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  zip: Yup.string().matches(zipRegex, 'Must have 5 numbers only.').nullable(),
  roles: Yup.array().of(Yup.string()),
})

const EditUser = ({ editableUser, refetch }) => {
  const history = useHistory()
  const initialValues = {
    status: editableUser.status,
    name: editableUser.name,
    firstName: editableUser.firstName,
    lastName: editableUser.lastName,
    zip: editableUser.zip,
    phone: editableUser.phone,
    email: editableUser.email,
    city: editableUser.city,
    state: editableUser.state,
    addressLineOne: editableUser.addressLineOne,
    addressLineTwo: editableUser.addressLineTwo,
    roles: editableUser.roles,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: userSchema,
    onSubmit: values => {
      // using diff here breaks sub array
      const filteredValues = Object.fromEntries(Object.entries(values).filter(([k, v]) => v !== initialValues[k]))

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/api/update_user_attributes`, {
        method: 'POST',
        headers: API_COMMAND_HEADERS,
        body: JSON.stringify({
          user_id: editableUser.id,
          updated_attributes: filteredValues,
        }),
      }).then(res => {
        if (res.ok) {
          setTimeout(() => refetch(), 250)
          window.open('/users/' + editableUser.id + '/profile', '_self')
        } else {
          window.alert('error')
        }
      })
    },
  })

  const handleReset = () => {
    formik.setValues(initialValues)
  }

  const stateMenuItems = Object.entries(STATES).map(([k, v]) => (
    <MenuItem key={k} value={k}>
      {v}
    </MenuItem>
  ))

  const getUploadParams = async ({ file, meta: { name } }) => {
    const { uploadUrl, fileUrl } = await getPresignedUploadParams(name)
    return { body: file, meta: { fileUrl }, url: uploadUrl, method: 'put' }
  }

  const handleChangeStatus = ({ meta }, status) => {
    if (status === 'done') {
      formik.setFieldValue('avatarUrl', meta.fileUrl)
    }
  }

  const validationErrors = Object.values(
    Object.keys(formik.errors).reduce((acc, k) => {
      acc[k] = formik.errors[k]
      return acc
    }, {})
  )

  return (
    <Grid container sx={{ '& .MuiFormControl-root': { margin: '.5rem' } }}>
      <Grid item xs={6}>
        <div style={{ display: 'none' }}>
          <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            accept='image/*'
            inputContent='Click to add photos'
          />
        </div>

        <div>
          {editableUserFields.map(inputValue => (
            <TextField
              key={inputValue}
              variant='outlined'
              size='small'
              label={inputValue}
              name={inputValue}
              value={formik.values[inputValue]}
              onChange={formik.handleChange}
              error={formik.touched[inputValue] && Boolean(formik.errors[inputValue])}
              helperText={formik.touched[inputValue] && formik.errors[inputValue]}
            />
          ))}
          <FormControl sx={{ minWidth: '100px' }} size='small' variant='outlined'>
            <InputLabel>State</InputLabel>
            <Select label='State' name='state' value={formik.values.state} onChange={formik.handleChange}>
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {stateMenuItems}
            </Select>
          </FormControl>
          <FormControl size='small' variant='outlined'>
            <InputLabel>Status</InputLabel>
            <Select label='Status' name='status' value={formik.values.status} onChange={formik.handleChange}>
              <MenuItem value='active'>
                <em>Active</em>
              </MenuItem>
              <MenuItem value='inactive'>
                <em>Inactive</em>
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <div>
          <Autocomplete
            freeSolo
            multiple
            size='small'
            options={userRoles}
            name='roles'
            value={formik.values.roles}
            onChange={(e, v) => formik.setFieldValue('roles', v)}
            renderInput={markets => <TextField {...markets} label='roles' />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip variant='outlined' label={option} {...getTagProps({ index })} />)
            }
          />
        </div>

        <div>
          {validationErrors.length > 0 && (
            <>
              <br />
              <div style={{ color: 'red' }}>
                {validationErrors.map(error => (
                  <div key={error}>{error}</div>
                ))}
              </div>
              <br />
            </>
          )}
        </div>
        <Button
          variant='contained'
          color='primary'
          disabled={!formik.dirty || !formik.isValid}
          type='submit'
          onClick={formik.handleSubmit}
        >
          <Save />
          Save
        </Button>
        <Button onClick={() => history.goBack()}>Cancel</Button>
        <Button disabled={!formik.dirty} onClick={handleReset}>
          Reset
        </Button>
      </Grid>
    </Grid>
  )
}

export default EditUser
