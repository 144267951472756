import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import TextField from '@mui/material/TextField'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  Button,
  Tabs,
  Tab,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Box,
  Chip,
  SvgIcon,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Popover,
  IconButton,
  InputAdornment,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import CancelIcon from '@mui/icons-material/Cancel'
import { Dollars, underscoreToTitleCase } from 'tools'
import { partStatuses, csrPartStatuses } from 'constants.js'
import Schedule from './Schedule'
import Order from './Order'
import PartVendorSelect from 'components/PartVendorSelect'
import LeadTypeaheadSelect from 'components/LeadTypeaheadSelect'
import partStatusClasses from 'Parts/part_status.module.css'

import STATES from 'statesWeOperateIn.json'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { CatalogItemsTable } from './CatalogItems/CatalogItemsTable'
const stateMenuItems = [
  <MenuItem key='all' id='state' value='nofilter'>
    all
  </MenuItem>,
].concat(
  Object.entries(STATES).map(state => (
    <MenuItem key={state} id='state' value={state[0]}>
      {' '}
      {state[1]}{' '}
    </MenuItem>
  ))
)

const PARTS_CONNECTION = gql`
  query partsConnection($search: JSON, $order: JSON, $filter: JSON, $first: Int, $after: String) {
    partsConnection(search: $search, order: $order, filter: $filter, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          name
          number
          invoiceNumber
          price
          cost
          status
          csrStatus
          createdAt
          pickupStatus
          quotePartsPo
          isPaid
          ccLastFour
          vendor {
            id
            name
            state
          }
          bToBWorkOrder {
            id
            dealer {
              id
              businessName
            }
          }
          quote {
            id
            lead {
              id
              name
              market
              state
            }
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const SmallSelect = styled(Select)(() => ({
  fontSize: '14px',
  '& .MuiSelect-select': {
    fontSize: '14px',
  },
}))

const SelectInputLabel = styled(InputLabel)(({ theme }) => ({
  padding: '1px 4px',
  backgroundColor: 'white',
  top: '-8px',
  '&.MuiInputLabel-sizeSmall': {
    top: '0',
  },
  '&.MuiInputLabel-shrink': {
    top: '0',
  },
}))

const CustomerHeadCell = ({ search, setSearch }) => {
  const [lead, setLead] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClickApply = () => {
    setSearch({
      ...search,
      ...{
        lead_id: lead.id,
        lead_name: lead.name,
      },
    })
    setAnchorEl(null)
  }
  const handleClearLead = () => {
    setLead(null)

    const { lead_id: removed, lead_name: alsoRemoved, ...remainingSearches } = search
    setSearch(remainingSearches)
  }
  const handleClickClose = () => setAnchorEl(null)

  return (
    <TableCell>
      <Box style={{ display: 'inline-flex' }}>
        customer
        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={e => setAnchorEl(e.currentTarget)}>
          <SvgIcon fontSize='small' component={FilterListIcon} />
        </Button>
      </Box>

      {search.lead_name && <Chip variant='outlined' size='small' onDelete={handleClearLead} label={search.lead_name} />}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DialogTitle>Select a Customer</DialogTitle>
        <Grid
          container
          sx={{ py: 2, px: 3 }}
          style={{
            display: 'flex',
            minWidth: '420px',
          }}
          spacing={1}
        >
          <Grid item xs={12}>
            <FormControl error style={{ width: '100%' }}>
              <LeadTypeaheadSelect
                value={lead}
                onChange={lead => {
                  setLead(lead)
                }}
                inputProps={{
                  size: 'small',
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={handleClickClose} color='cancel'>
            Close
          </Button>
          <Button onClick={handleClickApply} color='primary' disabled={!lead}>
            Apply
          </Button>
        </DialogActions>
      </Popover>
    </TableCell>
  )
}

const StateSelect = ({ value, handleChange }) => {
  const onChange = evt => {
    if (evt.target.value === 'nofilter') {
      handleChange(null)
    } else {
      handleChange(evt.target.value)
    }
  }

  return (
    <FormControl style={{ width: '100%' }} size='small'>
      <SelectInputLabel>State</SelectInputLabel>
      <Select
        size='small'
        fullWidth
        variant='outlined'
        style={{ width: '100%' }}
        value={value}
        onChange={onChange}
        label='State'
      >
        {stateMenuItems}
      </Select>
    </FormControl>
  )
}

const MerchantHeadCell = ({ filter, handleUpdateFilter, removeFilter, order, setOrToggleOrder }) => {
  const [stateValue, setStateValue] = useState(null)
  const [vendor, setVendor] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleVendorChanged = vendor => setVendor(vendor)
  const handleClickApply = vendor => {
    if (vendor) {
      handleUpdateFilter('part_vendor_id', vendor?.id)
    } else {
      removeFilter('part_vendor_id')
    }
    setAnchorEl(null)
  }
  const handleClearVendor = () => {
    setStateValue(null)
    handleVendorChanged(null)
    handleClickApply()
  }
  const handleClickClose = () => setAnchorEl(null)

  return (
    <TableCell>
      <Box style={{ display: 'inline-flex' }}>
        merchant
        {/* sort button */}
        {/* <Button
          style={{ minWidth: 0 }}
          color='primary'
          disableElevation
          onClick={() => setOrToggleOrder('part_vendor_id')}
        >
          <SvgIcon
            fontSize='small'
            component={(order.part_vendor_id === 'desc'
              ? ArrowDownwardIcon
              : ArrowUpwardIcon) || ArrowDownwardIcon}
          />
        </Button> */}
        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={e => setAnchorEl(e.currentTarget)}>
          <SvgIcon fontSize='small' component={FilterListIcon} />
        </Button>
      </Box>

      {filter.part_vendor_id && vendor && (
        <Chip variant='outlined' size='small' onDelete={handleClearVendor} label={vendor.name} />
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DialogTitle>Select a Merchant</DialogTitle>
        <Grid
          container
          sx={{ py: 2, px: 3 }}
          style={{
            display: 'flex',
            minWidth: '420px',
          }}
          spacing={1}
        >
          <Grid item xs={4}>
            <StateSelect value={stateValue} handleChange={setStateValue} />
          </Grid>
          <Grid item xs={8}>
            <PartVendorSelect
              hideAddVendor
              style={{ maxWidth: '320px' }}
              name='partVendorId'
              vendorState={stateValue}
              value={vendor || undefined}
              onChange={handleVendorChanged}
              inputProps={{
                name: 'partVendorId',
                size: 'small',
              }}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={handleClickClose} color='cancel'>
            Close
          </Button>
          <Button onClick={() => handleClickApply(vendor)} color='primary' disabled={!vendor}>
            Apply
          </Button>
        </DialogActions>
      </Popover>
    </TableCell>
  )
}

const useDateRangeHeadStyles = makeStyles(theme => ({
  dateDialog: {
    display: 'flex',
    '& div': {
      padding: '5px',
      '& span': {
        background: '#eee',
        display: 'block',
        padding: '3px',
        borderRadius: '3px 3px 0px 0px',
        border: '1px solid #ccc',
        borderBottom: '1px dashed #eee',
      },
      '& input': {
        padding: '3px',
        borderRadius: '0px 0px 3px 3px',
        border: '1px solid #ccc',
        borderTop: '0px',
      },
    },
  },
}))
const DateRangeHeadCell = ({ name, filter, setFilter, order, setOrToggleOrder }) => {
  const classes = useDateRangeHeadStyles()
  const [dateRange, setDateRange] = useState(filter[name] || {})
  const [anchorEl, setAnchorEl] = React.useState(null)
  // ONE of BOTH key of: { ge: iso8601, le: iso8601 } OR null
  // ge -> greater than or equal
  // le -> less than or equal

  const handleDateTimeChanged = evt => setDateRange({ ...dateRange, [evt.target.name]: evt.target.value })
  const handleClickApply = () => {
    setFilter({ ...filter, [name]: dateRange })
    setAnchorEl(null)
  }
  const handleClickClose = () => setAnchorEl(null)

  const handleDeleteRangeKey = key => {
    const { [key]: removed, ...rest } = dateRange
    setDateRange(rest)
    setFilter({ ...filter, [name]: rest })
  }

  return (
    <TableCell>
      <Box>
        {name}

        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={() => setOrToggleOrder(name)}>
          <SvgIcon
            fontSize='small'
            component={(order[name] === 'desc' ? ArrowDownwardIcon : ArrowUpwardIcon) || ArrowDownwardIcon}
          />
        </Button>

        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={e => setAnchorEl(e.currentTarget)}>
          <SvgIcon fontSize='small' component={FilterListIcon} />
        </Button>
      </Box>

      {filter[name] && Object.entries(filter[name]).length > 0 && (
        <>
          <br />
          {Object.entries(filter[name])
            .sort((a, b) => (a[0] > b[0] ? 1 : -1))
            .map(
              filterTuple =>
                (
                  <Chip
                    variant='outlined'
                    size='small'
                    onDelete={() => handleDeleteRangeKey(filterTuple[0])}
                    label={`${filterTuple[0] === 'le' ? 'before' : 'after'} ${DateTime.fromISO(filterTuple[1]).toFormat(
                      'yy-MM-dd'
                    )}`}
                  />
                ) || null
            )}
        </>
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DialogTitle>Select Date Range (One Or Both)</DialogTitle>
        <DialogContent className={classes.dateDialog}>
          <Box>
            <span>
              Start Date<small> (optional)</small>
            </span>
            <input type='datetime-local' name='ge' value={dateRange.ge || ''} onChange={handleDateTimeChanged} />
          </Box>
          <Box>
            <span>
              End Date<small> (optional)</small>
            </span>
            <input type='datetime-local' name='le' value={dateRange.le || ''} onChange={handleDateTimeChanged} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color='cancel'>
            Close
          </Button>
          <Button onClick={handleClickApply} color='primary' autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Popover>
    </TableCell>
  )
}

const OrderableHeadCell = ({ order, setOrToggleOrder, attr }) => {
  return (
    <TableCell>
      {attr}

      <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={() => setOrToggleOrder(attr)}>
        <SvgIcon
          fontSize='small'
          component={(order[attr] === 'desc' ? ArrowDownwardIcon : ArrowUpwardIcon) || ArrowDownwardIcon}
        />
      </Button>
    </TableCell>
  )
}

const PartsIndexWithSearch = () => {
  const [poSearchValue, setPoSearchValue] = useState('')

  const handleChange = event => setPoSearchValue(event.target.value.trim())

  return (
    <>
      <br />
      <TextField
        size='small'
        variant='outlined'
        label='PO Search'
        value={poSearchValue}
        onChange={handleChange}
        InputProps={{
          endAdornment: poSearchValue !== '' && (
            <InputAdornment position='end'>
              <IconButton onClick={() => setPoSearchValue('')}>
                <CancelIcon fontSize='small' />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <PartsIndex poSearchValue={poSearchValue} />
    </>
  )
}

const RemoveFilterButton = ({ onClick }) => (
  <IconButton style={{ display: 'inline-block', padding: 0 }}>
    <CancelIcon style={{ fontSize: '15px' }} onClick={onClick} />
  </IconButton>
)

const SortSearchHeadCell = ({
  search,
  setSearch,
  removeSearch,
  order,
  setOrToggleOrder,
  label,
  field,
  searchOnType,
}) => {
  const [value, setValue] = useState(search[field] ? search[field] : '')
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClickApply = () => {
    if (value === '') {
      removeSearch(field)
    } else {
      setSearch({ ...search, [field]: value })
    }
    setAnchorEl(null)
  }
  const handleClearFilter = () => {
    removeSearch(field)
    setValue('')
    setAnchorEl(null)
  }
  const handleClickClose = () => setAnchorEl(null)
  const handleChange = e => {
    setValue(e.target.value)
    if (searchOnType) {
      setSearch({ ...search, [field]: value })
    }
  }

  return (
    <TableCell>
      <Box>
        {label}
        {/* sort button */}
        {/* <Button
          style={{ minWidth: 0 }}
          color='primary'
          disableElevation
          onClick={() => setOrToggleOrder(field)}
        >
          <SvgIcon
            fontSize='small'
            component={(order.part_vendor_id === 'desc'
              ? ArrowDownwardIcon
              : ArrowUpwardIcon) || ArrowDownwardIcon}
          />
        </Button> */}

        <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={e => setAnchorEl(e.currentTarget)}>
          <SvgIcon fontSize='small' component={FilterListIcon} />
        </Button>
      </Box>

      {search[field] && search[field] !== '' && (
        <Chip variant='outlined' size='small' onDelete={handleClearFilter} label={search[field]} />
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DialogContent>
          <TextField fullWidth autoFocus size='small' variant='outlined' value={value} onChange={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color='cancel'>
            Close
          </Button>
          <Button onClick={handleClickApply} color='primary' autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Popover>
    </TableCell>
  )
}

const SelectFilterHeadCell = ({ filter, removeFilter, handleUpdateFilter, label, field, options }) => {
  const value = filter[field] ? filter[field].eq : 'nofilter'

  const handleChange = evt => {
    const newValue = evt.target.value
    if (newValue === 'nofilter') {
      removeFilter(field)
    } else {
      handleUpdateFilter(field, newValue)
    }
  }

  return (
    <FormControl style={{ minWidth: '6em' }}>
      <Box style={{ display: 'inline-flex' }}>
        <InputLabel shrink style={{ left: '-16px' }}>
          <b>{label}</b>
        </InputLabel>
        <SmallSelect variant='standard' size='small' style={{ marginTop: '6px' }} value={value} onChange={handleChange}>
          <MenuItem value='nofilter'>all</MenuItem>
          {options.map(o => (
            <MenuItem key={o.value} value={o.value}>
              {o.text}
            </MenuItem>
          ))}
        </SmallSelect>

        {value !== 'nofilter' && <RemoveFilterButton onClick={() => removeFilter(field)} />}
      </Box>
    </FormControl>
  )
}

const PartsIndex = ({ poSearchValue }) => {
  const [search, setSearch] = useState(JSON.parse(localStorage.getItem('partsSearch')) || {})
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem('partsOrder')) || {})
  const [filter, setFilter] = useState(JSON.parse(localStorage.getItem('partsFilter')) || {})
  const [limit, setLimit] = useState(JSON.parse(localStorage.getItem('partsLimit')) || 50)
  useEffect(() => {
    // do not persist the customer search
    const { lead_id: removed, lead_name: alsoRemoved, ...remainingSearches } = search
    localStorage.setItem('partsSearch', JSON.stringify(remainingSearches))
  }, [search])
  useEffect(() => localStorage.setItem('partsOrder', JSON.stringify(order)), [order])
  useEffect(() => {
    // do not persist the merchant filter
    const { part_vendor_id: removed, ...remainingFilters } = filter
    return localStorage.setItem('partsFilter', JSON.stringify(remainingFilters))
  }, [filter])
  useEffect(() => localStorage.setItem('partsLimit', JSON.stringify(limit)), [limit])

  const [debouncedSearch] = useDebounce(search, 500, { leading: false })

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

  const removeFilter = filterField => {
    const { [filterField]: removed, ...remainingFilters } = filter
    setFilter(remainingFilters)
  }

  const removeSearch = searchField => {
    const { [searchField]: removed, ...remainingSearches } = search
    setSearch(remainingSearches)
  }

  const setOrToggleOrder = name => {
    setOrder({
      [name]: (order[name] && order[name] === 'desc' ? 'asc' : 'desc') || 'desc',
    })
  }

  const handleUpdateFilter = (field, value) => {
    setPage(0)
    setPageCeiling(0)
    setFilter({ ...filter, [field]: { eq: value } })
  }

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: limit,
        after: '',
      },
    })
  }, [debouncedSearch, order, filter, limit])

  useEffect(() => {
    if (poSearchValue === '') {
      removeSearch('quote_parts_po')
    } else {
      // clear all other filters when we are searching by PO #
      setFilter({})
      setSearch({ ...search, quote_parts_po: poSearchValue })
    }
  }, [poSearchValue])

  const { loading, error, data, fetchMore } = useQuery(PARTS_CONNECTION, {
    variables: {
      search,
      order,
      filter,
      first: limit,
      after: cursorCeiling,
    },
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const parts = data.partsConnection.edges.map(edge => edge.node)
  const totalCount = data.partsConnection.totalCount
  const endCursor = data.partsConnection.pageInfo.endCursor

  const handleChangePage = (event, wantedPage) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: limit,
          after: endCursor,
        },
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <TableContainer component={Paper}>
      <Table size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>name</TableCell>
            <TableCell>
              <CustomerHeadCell search={search} setSearch={setSearch} />
            </TableCell>
            <TableCell>
              <MerchantHeadCell
                filter={filter}
                handleUpdateFilter={handleUpdateFilter}
                removeFilter={removeFilter}
                order={order}
                setOrToggleOrder={setOrToggleOrder}
              />
            </TableCell>
            <TableCell>state</TableCell>
            <TableCell>market</TableCell>
            <TableCell align='right'>
              <SortSearchHeadCell
                search={search}
                setSearch={setSearch}
                removeSearch={removeSearch}
                order={order}
                setOrToggleOrder={setOrToggleOrder}
                label='part no.'
                field='number'
              />
            </TableCell>
            <TableCell align='right'>
              <SortSearchHeadCell
                search={search}
                setSearch={setSearch}
                removeSearch={removeSearch}
                order={order}
                setOrToggleOrder={setOrToggleOrder}
                label='invoice no.'
                field='invoice_number'
              />
            </TableCell>
            <TableCell>P.O. #</TableCell>
            <TableCell align='right'>price</TableCell>
            <TableCell align='right'>cost</TableCell>
            <TableCell>
              <SelectFilterHeadCell
                filter={filter}
                removeFilter={removeFilter}
                handleUpdateFilter={handleUpdateFilter}
                label='Paid'
                field='is_paid'
                options={[
                  { text: 'paid', value: true },
                  { text: 'not paid', value: false },
                ]}
              />
            </TableCell>
            <TableCell>last 4</TableCell>
            <TableCell align='right'>
              <SelectFilterHeadCell
                filter={filter}
                removeFilter={removeFilter}
                handleUpdateFilter={handleUpdateFilter}
                label='Return Status'
                field='csr_status'
                options={csrPartStatuses.map(o => ({ value: o, text: o }))}
              />
            </TableCell>
            <TableCell align='right'>
              <SelectFilterHeadCell
                filter={filter}
                removeFilter={removeFilter}
                handleUpdateFilter={handleUpdateFilter}
                label='Status'
                field='status'
                options={partStatuses.map(o => ({ value: o, text: o }))}
              />
            </TableCell>
            <DateRangeHeadCell
              name='created_at'
              filter={filter}
              setFilter={setFilter}
              order={order}
              setOrToggleOrder={setOrToggleOrder}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {parts.slice(page * limit, page * limit + limit).map(part => (
            <TableRow key={part.id}>
              <TableCell component='th'>
                <Link to={`/parts/${part.id}`}>{part.name}</Link>
              </TableCell>
              <TableCell>{part.quote?.lead.name || part.bToBWorkOrder?.dealer?.businessName}</TableCell>
              <TableCell>{part.vendor?.name}</TableCell>
              <TableCell>{part.vendor?.state}</TableCell>
              <TableCell>{part.quote?.lead.market || part.bToBWorkOrder?.dealer?.state}</TableCell>
              <TableCell align='right'>{part.number}</TableCell>
              <TableCell align='right'>{part.invoiceNumber}</TableCell>
              <TableCell>{part.quotePartsPo}</TableCell>
              <TableCell align='right'>
                <Dollars value={part.price} />
              </TableCell>
              <TableCell align='right'>
                <Dollars value={part.cost} />
              </TableCell>
              <TableCell>{part.isPaid ? <b style={{ color: 'green' }}>PAID</b> : 'not paid'}</TableCell>
              <TableCell>{part.ccLastFour}</TableCell>
              <TableCell align='center' className={partStatusClasses['bg_' + part.csrStatus]}>
                {underscoreToTitleCase(part.csrStatus)}
              </TableCell>
              <TableCell align='right'>{part.status}</TableCell>
              <TableCell align='right'>{DateTime.fromISO(part.createdAt).toFormat('ff')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={totalCount}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

const PartsIndexTabs = () => {
  const [partsTab, setPartsTab] = useLocalStorage('partsTab' || 'list')

  const handleChange = (_, newValue) => {
    setPartsTab(newValue)
  }

  return (
    <div>
      <Tabs value={partsTab} onChange={handleChange}>
        <Tab value='catalog' label='Catalog' />
        <Tab value='list' label='List' />
        <Tab value='schedule' label='Schedule' />
        <Tab value='order' label='Ready to order' />
      </Tabs>

      {partsTab === 'catalog' && <CatalogItemsTable />}
      {partsTab === 'list' && <PartsIndexWithSearch />}
      {partsTab === 'schedule' && <Schedule />}
      {partsTab === 'order' && <Order />}
    </div>
  )
}

export default PartsIndexTabs
