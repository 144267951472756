import { Dollars,  } from 'tools'
import { DateTime } from 'luxon'

export const DayLaborValue = ({ dateIsCurrentDay, jobsForDay }) => {
  const jobsWithVisitNumberOne = jobsForDay.filter(job => {
    const visitNumber =
      job.lead.jobs
        .slice()
        .sort((a, b) => (DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1))
        .findIndex(thisJob => thisJob.id === job.id) + 1
    if (visitNumber !== 1) {
      return null
    } else {
      return job
    }
  })

  const notFullyPaidFollowups = jobsForDay.filter(job => {
    const visitNumber =
      job.lead.jobs
        .slice()
        .sort((a, b) => (DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1))
        .findIndex(thisJob => thisJob.id === job.id) + 1
    if (visitNumber !== 1) {
      if (job.quote.paymentStatus !== 'fully_paid') {
        return job
      }
    } else {
      return null
    }
  })

  const quotesForThoseJobs = jobsWithVisitNumberOne.map(job => job.quote)
  const notFullyPaidFollowupsQuotes = notFullyPaidFollowups.map(job => job.quote)

  const laborTotalForFollowups = notFullyPaidFollowupsQuotes.reduce(
    (acc, item) => acc + item.laborSumAfterAllDiscounts,
    0
  )

  const techChargesTotalForFollowups = notFullyPaidFollowupsQuotes.reduce(
    (acc, item) => acc + item.technicianChargesSum,
    0
  )

  const allLaborTotal = quotesForThoseJobs.reduce((acc, item) => acc + item.laborSumAfterAllDiscounts, 0)
  const techChargesTotal = quotesForThoseJobs.reduce((acc, item) => acc + item.technicianChargesSum, 0)
  const total = allLaborTotal + techChargesTotal + laborTotalForFollowups + techChargesTotalForFollowups

  return (
    <div
      style={{
        opacity: total === 0 ? 0.5 : 1,
        fontSize: '10px',
        fontWeight: 'bold',
        padding: '.125rem',
        background: dateIsCurrentDay ? '#3852B040' : '#f1f1f1',
        marginTop: '.125rem',
        color: dateIsCurrentDay ? '#3852B0' : '#444',
      }}
    >
      LABOR: <Dollars value={total} />
    </div>
  )
}
