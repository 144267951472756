import { useQuery, gql } from '@apollo/client'

const QUERY = gql`
  query SickTimeOff($technicianId: ID, $unclaimed: Boolean) {
    sickTimeOffs(technicianId: $technicianId, unclaimed: $unclaimed) {
      id
      createdAt
      totalHours
      payoutId
      note
      deletedAt
      timeOffDate
      payout {
        id
        createdAt
      }
    }
  }
`

export const useTechnicianSickTimeOff = ({ technicianId }) => {
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { technicianId: technicianId, unclaimed: true },
  })
  return { loading, error, data, sickTimeOff: data?.sickTimeOffs || [], refetch }
}
