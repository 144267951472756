import { useState } from 'react'
import { openai, openai_model } from 'utils/axios'
import { OpenAIRequest, OpenAIResponse } from 'utils/axios'

export const sms_gpt_prompt = `Rewrite the following text message so that it is professional and courteous; suitable for the workplace:\n\n`
export const leading_res = `Rewritten text message:\n\n`

export const useCustomerSMSRephraser = () => {
  const [loading, setLoading] = useState(false)

  const rephrase = (text: string, callback: (newText: string) => void) => {
    setLoading(true)

    openai
      .post<OpenAIRequest, OpenAIResponse>('/completions', {
        model: openai_model,
        max_tokens: 1000,
        prompt: `${sms_gpt_prompt} ${text}\n\n ${leading_res}`,
      })
      .then(res => {
        setLoading(false)
        callback(res?.data?.choices[0]?.text?.trim())
      })
  }

  return { rephrase, rephraseLoading: loading }
}
