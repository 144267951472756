import { useContext, useMemo } from 'react'
import { GlobalContext } from 'GlobalStore'

export default () => {
  const [global] = useContext(GlobalContext)

  const lookup = useMemo(
    () => global.users?.reduce((acc, u) => ({ ...acc, [u.id]: `${u.firstName} ${u.lastName[0]}` }), {}),
    [global.users]
  )

  return user_id => lookup[user_id]
}
