import { CheckCircleRounded } from '@mui/icons-material'
import { Box, Chip, SvgIcon, Typography } from '@mui/material'
import { DateTime } from 'luxon'

export const StatusBanner = ({
  icon = CheckCircleRounded,
  variant = 'success',
  text = 'Applied on',
  timestamp = null,
  style = 'text',
}) => {
  return (
    <Box
      sx={[
        style === 'text' && {
          padding: '1rem 0rem',
          boxSizing: 'border-box',
          color: '#0089CD',
          '& svg': {
            height: '1rem',
            width: '1rem',
            mr: { xs: '1rem', sm: '.5rem' },
            mb: {
              xs: 'auto',
              sm: '0rem',
            },
            mt: {
              xs: 'auto',
              sm: '0rem',
            },
          },
        },
        variant === 'error' && {
          color: '#cd1400',
        },
      ]}
    >
      <Chip
        icon={<SvgIcon component={icon} />}
        label={`${text} ${timestamp && DateTime.fromISO(timestamp).toFormat('cccc, LLL dd - t')}`}
      />
    </Box>
  )
}
