import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { Settings } from './Settings/Settings'
import Payable from './Payments/Payable'
import DealersRouter from './Dealers/Router'
import WorkOrdersRouter from './WorkOrders/Router'
import InvoicesRouter from './Invoices/Router'
import StatementsRouter from './InvoiceStatements/Router'
import Chat from './Chat/Chat'

const Router = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/settings`}>
        <Settings />
      </Route>

      <Route path={`${path}/dealers`}>
        <DealersRouter />
      </Route>

      <Route path={`${path}/workOrders`}>
        <WorkOrdersRouter />
      </Route>

      <Route path={`${path}/invoices`}>
        <InvoicesRouter />
      </Route>

      <Route path={`${path}/statements`}>
        <StatementsRouter />
      </Route>

      <Route path={`${path}/payments`}>
        <Payable />
      </Route>

      <Route path={`${path}/chat`}>
        <Chat />
      </Route>
    </Switch>
  )
}

export default Router
