import { gql, useQuery } from '@apollo/client'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { SkeletonRow } from 'Admin/Payroll/ResidentialContractor/ResidentialContractor'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { Dollars } from 'tools'
import { QuoteType } from 'types/types'

const JOBS_QUERY = gql`
  query jobs($quoteId: ID!, $includeCanceled: Boolean) {
    jobs(quoteId: $quoteId, includeCanceled: $includeCanceled) {
      id
      startDatetime
      quote {
        id
        payrollStatuses
        tipSum
        ccFeeSum
        technicianChargesSum
        laborSumAfterAllDiscounts
        totalTransactionAmount
        forceIncludeInPayroll
        commissionPercentOverride
        payoutBonus
        assignedTechnicianId
        payrollStatuses
        payrollNotesCount
        technician {
          commissionPercent
          quoteCommissionStructure
        }
      }
      lead {
        id
        make
        model
        year
        customer {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const RevenueQuotes = ({ quotes }: any) => {
  const totalPayoutBonus = quotes.reduce((acc: any, quote: any) => acc + quote.payoutData?.payoutBonus || 0, 0)
  const totalLaborTotal = quotes.reduce((acc: any, quote: any) => acc + quote.payoutData?.totalLaborAmount || 0, 0)
  const totalTipAmount = quotes.reduce((acc: any, quote: any) => acc + quote.payoutData?.tipAfterFee || 0, 0)
  const totalPayout = quotes.reduce((acc: any, quote: any) => acc + quote.payoutData?.totalPayout || 0, 0)
  const techChargesTotal = quotes.reduce(
    (acc: any, quote: any) => acc + quote.payoutData?.fullPayoutItemsAfterCcFee || 0,
    0
  )

  return (
    <Table size='small' component={Paper}>
      <TableHead sx={{ fontSize: '12px !important' }}>
        <TableRow>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Last job date
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Customer name
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Vehicle
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Tech Charges
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Tip amount
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Payout bonus
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Labor total
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Commission Percent
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Total payout
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {quotes.map((quote: QuoteType, index: number) => (
          <RevenuePayoutRow quote={quote} key={quote.id} index={index} />
        ))}
        <TableRow sx={{ background: 'aliceblue', borderTop: '2px solid #a1cff7' }}>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={techChargesTotal} />
          </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalTipAmount} />
          </TableCell>

          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalPayoutBonus} />
          </TableCell>

          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalLaborTotal} />
          </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}></TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalPayout} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

const RevenuePayoutRow = ({ quote, index }: { quote: QuoteType; index: number }) => {
  const { loading, error, data } = useQuery(JOBS_QUERY, {
    variables: { quoteId: quote.id, includeCanceled: true },
  })

  if (loading) return <SkeletonRow />
  if (error) return <div>Error!</div>

  const jobs = data.jobs || []

  const latestJob = jobs
    .slice()
    .sort((a: any, b: any) => (DateTime.fromISO(a.startDatetime) < DateTime.fromISO(b.startDatetime) ? 1 : -1))[0]

  return (
    <TableRow sx={{ background: index % 2 === 0 ? '#fff' : '#f9f9f9' }}>
      <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
        <Link key={latestJob.lead.id} target='_blank' to={`/jobs/${latestJob.id}`}>
          {DateTime.fromISO(latestJob.startDatetime).toISODate()}
        </Link>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
        <Link key={latestJob.lead.id} target='_blank' to={`/leads/${latestJob.lead.id}`}>
          {latestJob.lead.customer.firstName} {latestJob.lead.customer.lastName}
        </Link>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
        {latestJob.lead.year} {latestJob.lead.make} {latestJob.lead.model}
      </TableCell>
      <TableCell align='right'>
        <Dollars value={quote.payoutData?.fullPayoutItemsAfterCcFee || 0} />
      </TableCell>
      <TableCell align='right'>
        <Dollars value={quote.payoutData?.tipAfterFee || 0} />
      </TableCell>
      <TableCell align='right'>
        <Dollars value={quote.payoutData?.payoutBonus || 0} />
      </TableCell>
      <TableCell align='right'>
        <Dollars value={quote.payoutData?.totalLaborAmount || 0} />
      </TableCell>
      <TableCell align='right'>{quote.payoutData?.commissionPercentApplied}</TableCell>
      <TableCell align='right'>
        <Dollars value={quote.payoutData?.totalPayout || 0} />
      </TableCell>
    </TableRow>
  )
}

export const HourlyQuotes = ({ quotes }: any) => {
  const totalPayoutBonus = quotes.reduce((acc: any, quote: any) => acc + quote.payoutData?.payoutBonus || 0, 0)
  const totalLaborTotal = quotes.reduce((acc: any, quote: any) => acc + quote.payoutData?.totalLaborAmount || 0, 0)
  const totalTipAmount = quotes.reduce((acc: any, quote: any) => acc + quote.payoutData?.tipAfterFee || 0, 0)
  const totalPayout = quotes.reduce((acc: any, quote: any) => acc + quote.payoutData?.totalPayout || 0, 0)
  const techChargesTotal = quotes.reduce(
    (acc: any, quote: any) => acc + quote.payoutData?.fullPayoutItemsAfterCcFee || 0,
    0
  )

  return (
    <Table size='small' component={Paper}>
      <TableHead sx={{ fontSize: '12px !important' }}>
        <TableRow>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Last job date
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Customer name
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Vehicle
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Tech Charges
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Tip amount
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Payout bonus
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Labor total
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Commission Rate
          </TableCell>
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Total payout
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {quotes.map((quote: QuoteType, index: number) => (
          <HourlyPayoutRow quote={quote} key={quote.id} index={index} />
        ))}
        <TableRow sx={{ background: 'aliceblue', borderTop: '2px solid #a1cff7' }}>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={techChargesTotal} />
          </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalTipAmount} />
          </TableCell>

          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalPayoutBonus} />
          </TableCell>

          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalLaborTotal} />
          </TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}></TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            <Dollars value={totalPayout} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

const HourlyPayoutRow = ({ quote, index }: { quote: QuoteType; index: number }) => {
  const { loading, error, data } = useQuery(JOBS_QUERY, {
    variables: { quoteId: quote.id, includeCanceled: true },
  })

  if (loading) return <SkeletonRow />
  if (error) return <div>Error!</div>

  const jobs = data.jobs || []

  const latestJob = jobs
    .slice()
    .sort((a: any, b: any) => (DateTime.fromISO(a.startDatetime) < DateTime.fromISO(b.startDatetime) ? 1 : -1))[0]

  return (
    <TableRow sx={{ background: index % 2 === 0 ? '#fff' : '#f9f9f9' }}>
      <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
        <Link key={latestJob.lead.id} target='_blank' to={`/jobs/${latestJob.id}`}>
          {DateTime.fromISO(latestJob.startDatetime).toISODate()}
        </Link>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
        <Link key={latestJob.lead.id} target='_blank' to={`/leads/${latestJob.lead.id}`}>
          {latestJob.lead.customer.firstName} {latestJob.lead.customer.lastName}
        </Link>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
        {latestJob.lead.year} {latestJob.lead.make} {latestJob.lead.model}
      </TableCell>
      <TableCell align='right'>
        <Dollars value={quote.payoutData?.fullPayoutItemsAfterCcFee || 0} />
      </TableCell>
      <TableCell align='right'>
        <Dollars value={quote.payoutData?.tipAfterFee || 0} />
      </TableCell>
      <TableCell align='right'>
        <Dollars value={quote.payoutData?.payoutBonus || 0} />
      </TableCell>
      <TableCell align='right'>
        <Dollars value={quote.payoutData?.totalLaborAmount || 0} />
      </TableCell>
      <TableCell align='right'>
        {quote.payoutData?.commissionPercentApplied || <Dollars value={quote?.technician?.commissionAmount} />}
      </TableCell>
      <TableCell align='right'>
        <Dollars value={quote.payoutData?.totalPayout || 0} />
      </TableCell>
    </TableRow>
  )
}
