import { Switch, Route, useRouteMatch } from 'react-router-dom'
import JobApplicationPage from './JobApplicationPage'

const JobApplicationsRouter = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:applicationId`}>
        <JobApplicationPage />
      </Route>
    </Switch>
  )
}

export default JobApplicationsRouter
