import { useQuery, gql } from '@apollo/client'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useWindowSize } from 'tools'
import { CreateNewLineItemRow } from './Components/CreateNewLineItemRow'
import { Skeleton } from '@mui/material'
import { LaborItemRow } from './Components/CarbodylabChargeCatalogItemRow'

const DEALERS_QUERY = gql`
  query CarbodylabChargeCatalogItems {
    carbodylabChargeCatalogItems {
      id
      name
      price
      type
    }
  }
`

export const CarbodylabChargeCatalogItemsTable = () => {
  const { height } = useWindowSize()

  const { loading, error, data, refetch } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  if (error) return <div>Error!</div>

  const laborItems = data?.carbodylabChargeCatalogItems || []

  return (
    <TableContainer component={Paper} sx={{ maxHeight: `${height - 150}px` }}>
      <Table stickyHeader size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>Price</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Item Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <CreateNewLineItemRow refetch={refetch} />
          {/* @ts-ignore */}
          {loading && <TableLoadingSkeleton />}
          {!loading &&
            laborItems.map((laborItem: any) => (
              <LaborItemRow laborItem={laborItem} refetch={refetch} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TableLoadingSkeleton = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map(item => (
    <TableRow key={item}>
      {[1, 2, 3, 4, 5].map(inner => (
        <TableCell sx={{ padding: '.25rem' }} key={inner}>
          <Skeleton
            sx={{
              minWidth: '100%',
              minHeight: '28px',
              transform: 'scale(1)',
              background: item % 2 !== 0 ? '#0089CD1a' : '#f1f1f1',
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  ))
}
