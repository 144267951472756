import { useQuery, gql } from '@apollo/client'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import SuppliesOrderRouter from './SuppliesOrder/Router'

import Index from './Index'
import { FC } from 'react'

const QUERY = gql`
  query suppliesOrderItems {
    suppliesOrderItems {
      id
      type
      description
      size
      quantity
      legality
      deletedAt
    }
  }
`

const Router: FC = () => {
  const { path } = useRouteMatch()
  const { loading, error, data, refetch } = useQuery(QUERY)

  if (loading) return <>Loading...</>
  if (error) return <>{JSON.stringify(error)}</>

  const items = data.suppliesOrderItems

  return (
    <Switch>
      <Route path={`${path}/:suppliesOrderId`}>
        <SuppliesOrderRouter items={items} />
      </Route>
      <Route path={`${path}`}>
        <Index items={items} refetch={refetch} />
      </Route>
    </Switch>
  )
}

export default Router
