import { useState, useContext } from 'react'
import { GlobalContext } from 'GlobalStore'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import { Dollars } from 'tools'
import EventIcon from '@mui/icons-material/Event'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import PartStatusSquare from 'components/PartStatusSquare'
import { CircularProgress, Dialog } from '@mui/material'
import { RescheduleJob } from './RescheduleJob'

const visitCountStyles = makeStyles(theme => ({
  visitCount: {
    fontWeight: 'bold',
    '&.visit2': {
      color: 'orange',
    },
    '&.visit3': {
      color: 'red',
    },
  },
}))
const VisitCount = ({ job }) => {
  const classes = visitCountStyles()

  const visitNumber =
    job.lead.jobs
      .slice()
      .sort((a, b) => (DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1))
      .findIndex(thisJob => thisJob.id === job.id) + 1

  if (visitNumber === 1) {
    return null
  }

  return <span className={classes.visitCount + ` visit${Math.min(visitNumber, 3)}`}>visit #{visitNumber}</span>
}

const jobCellStyles = makeStyles(theme => ({
  reschedule_button: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'white',
    lineHeight: 0,
    borderRadius: '5px',
    '&:hover': {
      boxShadow: '0px 0px 6px -1px red',
    },
  },
  job_cell_clickable: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  job: {
    position: 'relative',
    fontSize: 12,
    padding: '0px 3px',
    borderRadius: '3px',
    border: '1px solid #c8c8c8',
    marginBottom: '4px',
    backgroundColor: 'aliceblue',
    '&.payment-status-missing_parts': {
      backgroundColor: 'red',
    },
    '&.payment-status-fully_paid': {
      backgroundColor: '#70ff748f',
      border: '#70ff748f',
    },
    '&.payment-status-pre_paid': {
      border: '2px solid orange',
    },
    '&.payment-status-waiting_pre_payment': {
      border: '2px solid red',
    },
    '&.payment-status-paid': {
      border: '2px solid green',
    },
    '&.rescheduling': {
      border: '5px dashed red',
    },
  },
}))

const RescheduleHistory = ({ job }) => {
  const [global] = useContext(GlobalContext)

  if (job.rescheduleHistory.length === 0) {
    return null
  }

  const hoverTextObject = job.rescheduleHistory.map(r => {
    const csr = global && global.users && global.users.find(user => user.id === r.userId)
    const timestamp = DateTime.fromISO(r.timestamp).toLocaleString(DateTime.DATETIME_MED)
    const from = {
      start: DateTime.fromISO(r.from.start, { zone: 'utc' }).toLocaleString(DateTime.DATETIME_MED),
      end: DateTime.fromISO(r.from.end, { zone: 'utc' }).toLocaleString(DateTime.DATETIME_MED),
    }
    const to = {
      start: DateTime.fromISO(r.to.start, { zone: 'utc' }).toLocaleString(DateTime.DATETIME_MED),
      end: DateTime.fromISO(r.to.end, { zone: 'utc' }).toLocaleString(DateTime.DATETIME_MED),
    }

    return {
      csr: `${(csr && csr.firstName) || '--'} ${(csr && csr.lastName) || '--'}`,
      timestamp,
      from,
      to,
    }
  })

  return (
    <div style={{ color: 'red' }} title={JSON.stringify(hoverTextObject, null, 4)}>
      Rescheduled [{job.rescheduleHistory.length}] ({job.rescheduledReason ?? <i>none</i>})
    </div>
  )
}

const PartStyles = makeStyles(theme => ({
  parts: {
    backgroundColor: 'white',
    borderRadius: '2px',
    lineHeight: 0,
    border: '1px solid #e6e6e6',
    padding: '2px 2px 0px 2px',
  },
  part: {
    cursor: 'pointer',
    display: 'inline-block',
    margin: '0 0 2px 0',
  },
}))

const PartStatus = ({ quote }) => {
  const history = useHistory()
  const classes = PartStyles()
  const parts = quote.parts

  const handleClickPart = id => history.push(`/parts/${id}`)

  return (
    <div className={classes.parts}>
      {parts.map(part => {
        return (
          <div onClick={() => handleClickPart(part.id)} key={part.id} className={classes.part}>
            <PartStatusSquare part={part} />
          </div>
        )
      })}
    </div>
  )
}

const JobValue = ({ quote }) => {
  const history = useHistory()

  return (
    <div title='open quote' style={{ cursor: 'pointer' }} onClick={() => history.push(`/quotes/${quote.id}`)}>
      quoted: <Dollars value={quote.postTaxGrandTotal} />
      {/* <br /> */}
      {/* paid: <Dollars value={quote.paymentsSum} /> */}
    </div>
  )
}

export const JobCell = ({
  job,
  refetch,
  jobIsInPast,
  focusJob,
  setReschedulingJob,
  reschedulingJob,
  onDragEnd,
}) => {
  const history = useHistory()
  const classes = jobCellStyles()
  const lead = job.lead
  const quote = job.quote
  const [loading, setLoading] = useState(false)
  const [swappedTechId, setSwappedTechId] = useState(job.technicianId)
  const [swappedDate, setSwappedDate] = useState(job.startDatetime)

  const [dialogOpen, setDialogOpen] = useState(false)

  const handleClickReschedule = () => setReschedulingJob(job)

  const isFocusJob = focusJob && focusJob.id === job.id
  const canReschedule =
    DateTime.fromISO(job.startDatetime).setZone('utc') > DateTime.local().setZone('utc').startOf('day')

  const handleDropJobCell = () => {
    const droppedOnElement = [...document.querySelectorAll(':hover')].pop()
    const elemId = droppedOnElement.closest('#job-cell-parent')
    const techId = elemId.getAttribute('tech-id')
    const date = elemId.getAttribute('date')
    setSwappedDate(date)
    setSwappedTechId(techId)
    setDialogOpen(true)
  }

  return (
    <Grid
      draggable
      onDragEnd={e => {
        handleDropJobCell()
        onDragEnd()
      }}
      container
      className={
        classes.job +
        ` payment-status-${quote.paymentStatus}` +
        `${reschedulingJob && reschedulingJob.id === job.id ? ' rescheduling' : ''}`
      }
      sx={{ opacity: jobIsInPast ? '0.5 !important' : 1, cursor: 'pointer', '&:hover': { borderColor: 'blue' } }}
      spacing={0}
    >
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            background: '#11111140',
            bottom: '0px',
            width: '100%',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color='warning' />
        </Box>
      )}
      <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <RescheduleJob
          job={job}
          refetch={refetch}
          technicianId={swappedTechId}
          prefilledDate={swappedDate && swappedDate.slice(0, 10) + job.endDatetime.slice(10)}
        />
      </Dialog>
      {(isFocusJob || (focusJob === undefined && canReschedule)) && (
        <Grid item xs={12}>
          <div onClick={handleClickReschedule} title='reschedule' className={classes.reschedule_button}>
            <EventIcon style={{ fontSize: '1.2em', color: '#6e6e6e' }} />
          </div>
        </Grid>
      )}

      <Grid item xs={12}>
        <VisitCount job={job} />
      </Grid>

      <Grid item xs={12}>
        <div
          title={`${lead.name}, ${lead?.contextNotes ? lead?.contextNotes : ''}`}
          className={classes.job_cell_clickable + ' lead_name'}
          onClick={() => history.push(`/leads/${lead.id}`)}
        >
          {lead?.emojis?.map((emoji, index) => (
            <em-emoji set='apple' key={index} shortcodes={emoji}></em-emoji>
          ))}
          &nbsp;
          <b>{lead.name}</b>
        </div>
      </Grid>

      <Grid item xs={12}>
        <Link
          name='schedule-page-job-link'
          style={{ fontSize: '11px' }}
          to={`/leads/${job.leadId}/quotes/${job.quoteId}/jobs/${job.id}`}
        >
          from: {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)}
          <br />
          to: {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)}
        </Link>
      </Grid>

      <Grid item xs={12}>
        <PartStatus quote={quote} />
      </Grid>

      <Grid item xs={12}>
        <JobValue quote={quote} />
      </Grid>

      <Grid item xs={12}>
        <RescheduleHistory job={job} />
      </Grid>
    </Grid>
  )
}
