import { FormControl, InputLabel, MenuItem, Select, TableCell } from '@mui/material'
import { removeFilter } from 'BToB/Invoices/tools'

export const SelectBusinessHeadCell = ({ filter, dealers, setFilter }) => {
  const handleChange = evt => {
    if (evt.target.value === 'clear') {
      removeFilter({ name: 'dealer_id', filter, setFilter })
    } else {
      setFilter({ ...filter, dealer_id: { eq: evt.target.value } })
    }
  }

  const dealerId = filter?.dealer_id?.eq
  const businesses = dealers
    .slice()
    .sort((a, b) => (a.businessName > b.businessName ? 1 : -1))
    .filter(dealer => dealer.isActive)

  return (
    <TableCell align='right'>
      <FormControl fullWidth size='small'>
        <InputLabel>Business</InputLabel>
        <Select color='primary' size='small' label='Business' value={dealerId} onChange={handleChange}>
          <MenuItem value='clear'>
            <i>All</i>
          </MenuItem>
          {businesses.map(business => (
            <MenuItem key={business.id} value={business.id}>
              <>{business.businessName}</>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TableCell>
  )
}
