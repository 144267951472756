import { useContext, useState } from 'react'
import { DateTime } from 'luxon'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Paper, Button,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
  TextField, FormControl, InputLabel, Select, MenuItem
} from '@mui/material'

import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { basicDateTimeFormat } from 'tools'

const styles = {
  fab: { position: 'sticky', left: '93%', top: '87%' },
  rowCreating: { backgroundColor: '#cfc' },
  rowDeleted: { backgroundColor: '#fcc' },
  rowEditing: { backgroundColor: '#ffa' }
}

const itemLegality = ['', 'California', 'National Rule']
const itemLegalityLabel = {
  '': <i>Everywhere (clear)</i>,
  'California': 'Only in California',
  'National Rule': 'Not in California'
}

const Items = ({ items, refetch }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const [editingProduct, setEditingProduct] = useState(false)

  const sxForRow = (item) => {
    if (editingProduct === item.id) return styles.rowEditing
    else if (item.deletedAt) return styles.rowDeleted
    else return {}
  }

  const schemaCreate = Yup.object().shape({
    supplies_order_item_id: Yup.string()
      .test(
        'idUnique',
        'ItemID must exist, not collide, and not contain spaces',
        val => !itemIds.includes(val) && (val && !val.includes(' '))
      ),
    type: Yup.string().required(),
    description: Yup.string().required(),
    size: Yup.string().required(),
    quantity: Yup.number().required(),
    legality: Yup.string()
      .test(
        'legalityIsValid',
        "Legality must be one of '', 'California', or 'National Rule'",
        val => val === undefined || itemLegality.includes(val)
      )
  })
  const initialValuesCreate = {
    supplies_order_item_id: '',
    type: '',
    description: '',
    size: '',
    quantity: 1,
    legality: ''
  }
  const formikCreate = useFormik({
    initialValues: initialValuesCreate,
    validationSchema: schemaCreate,
    onSubmit: values => {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_supplies_order_item`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          ...values
        })
      })
        .then(res => res.ok ? handleSuccessCreate() : window.alert('Error'))
    }
  })

  const initialValuesItems = items.reduce((acc, item) => {
    return {
      ...acc,
      [item.id]: {
        supplies_order_item_id: item.id,
        type: item.type,
        description: item.description,
        size: item.size,
        quantity: item.quantity,
        legality: item.legality
      }
    }
  }, {})
  const itemIds = Object.keys(initialValuesItems)
  const schemaEdit = Yup.object().shape({
    supplies_order_item_id: Yup.string()
      .test(
        'idExists',
        'ItemID must be known',
        val => itemIds.includes(val)
      ),
    type: Yup.string().required(),
    description: Yup.string().required(),
    size: Yup.string().required(),
    quantity: Yup.number().required(),
    legality: Yup.string()
      .test(
        'legalityIsValid',
        "Legality must be one of '', 'California', or 'National Rule'",
        val => val === undefined || itemLegality.includes(val)
      ),
  })
  const formikEdit = useFormik({
    initialValues: initialValuesItems[editingProduct] || initialValuesCreate,
    enableReinitialize: true,
    validationSchema: schemaEdit,
    onSubmit: values => {
      console.log('submit edit', values)
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_supplies_order_item`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          ...values
        })
      })
        .then(res => res.ok ? handleSuccessEdit() : window.alert('Error'))
    }
  }, [editingProduct])

  const closureHandleClickEdit = (item) => () => { setEditingProduct(item.id) }
  const handleEditCancel = () => setEditingProduct(false)
  const handleEditDelete = () => {
    window.confirm(`Please confirm delete product: ${editingProduct}`) &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_supplies_order_item`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          supplies_order_item_id: editingProduct
        })
      })
        .then(res => res.ok ? handleSuccessDelete() : window.alert('Error'))
  }

  const handleSuccessEdit = () => {
    setEditingProduct(false)
    refetch()
  }
  const handleSuccessCreate = () => {
    formikCreate.setValues(initialValuesCreate)
    refetch()
  }
  const handleSuccessDelete = () => {
    setEditingProduct(false)
    refetch()
  }

  const itemsSorted = [...items].sort((a, b) => a.type > b.type ? 1 : a.type < b.type ? -1 : 0)

  return (
    <TableContainer component={Paper}>
      <Table size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell align='right'>type</TableCell>
            <TableCell align='right'>description</TableCell>
            <TableCell align='right'>size</TableCell>
            <TableCell align='right'>quantity</TableCell>
            <TableCell align='right'>legality</TableCell>
            <TableCell align='right'>actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemsSorted.map(item =>
            <TableRow key={item.id} sx={sxForRow(item)}>
              <TableCell component='th'>
                {item.id}
                {item.id === editingProduct &&
                  <Button size='small' onClick={handleEditDelete}>delete</Button>}
              </TableCell>
              <TableCell align='right'>
                {item.id !== editingProduct && item.type}
                {item.id === editingProduct &&
                  <TextField
                    variant='outlined'
                    size='small'
                    fullWidth
                    label='Type'
                    name='type'
                    value={formikEdit.values.type}
                    onChange={formikEdit.handleChange}
                    error={formikEdit.touched.type && Boolean(formikEdit.errors.type)}
                    helperText={formikEdit.touched.type && formikEdit.errors.type}
                  />}
              </TableCell>
              <TableCell align='right'>
                {item.id !== editingProduct && item.description}
                {item.id === editingProduct &&
                  <TextField
                    variant='outlined'
                    size='small'
                    fullWidth
                    label='Description'
                    name='description'
                    value={formikEdit.values.description}
                    onChange={formikEdit.handleChange}
                    error={formikEdit.touched.type && Boolean(formikEdit.errors.type)}
                    helperText={formikEdit.touched.type && formikEdit.errors.type}
                  />}
              </TableCell>
              <TableCell align='right'>
                {item.id !== editingProduct && item.size}
                {item.id === editingProduct &&
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Size'
                    name='size'
                    value={formikEdit.values.size}
                    onChange={formikEdit.handleChange}
                    error={formikEdit.touched.size && Boolean(formikEdit.errors.size)}
                    helperText={formikEdit.touched.size && formikEdit.errors.size}
                  />}
              </TableCell>
              <TableCell align='right'>
                {item.id !== editingProduct && item.quantity}
                {item.id === editingProduct &&
                  <TextField
                    variant='outlined'
                    size='small'
                    label='Quantity'
                    name='quantity'
                    value={formikEdit.values.quantity}
                    onChange={formikEdit.handleChange}
                    error={formikEdit.touched.quantity && Boolean(formikEdit.errors.quantity)}
                    helperText={formikEdit.touched.quantity && formikEdit.errors.quantity}
                  />}
              </TableCell>
              <TableCell align='right'>
                {item.id !== editingProduct && item.legality}
                {item.id === editingProduct &&
                  <FormControl size='small' fullWidth variant='outlined'>
                    <InputLabel>Legality</InputLabel>
                    <Select
                      name='legality'
                      value={formikEdit.values.legality}
                      onChange={formikEdit.handleChange}
                    >
                      {itemLegality.map(legality =>
                        <MenuItem key={legality} value={legality}>
                          {itemLegalityLabel[legality]}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>}
              </TableCell>
              <TableCell align='right'>
                {item.deletedAt &&
                  DateTime.fromISO(item.deletedAt).toFormat(basicDateTimeFormat)}
                {item.id === editingProduct &&
                  <>
                    <Button
                      size='small'
                      onClick={formikEdit.handleSubmit}
                      disabled={!formikEdit.dirty || !formikEdit.isValid}
                    >save
                    </Button>
                    <Button
                      size='small'
                      onClick={handleEditCancel}
                    >cancel
                    </Button>
                  </>}
                {item.id !== editingProduct && !item.deletedAt &&
                  <Button
                    size='small'
                    onClick={closureHandleClickEdit(item)}
                  >edit
                  </Button>}
              </TableCell>
            </TableRow>)}
          <TableRow sx={styles.rowCreating}>
            <TableCell component='th'>
              <TextField
                variant='outlined'
                size='small'
                fullWidth
                label='Product'
                name='supplies_order_item_id'
                value={formikCreate.values.supplies_order_item_id}
                onChange={formikCreate.handleChange}
                error={!!formikCreate.errors.supplies_order_item_id}
                helperText={formikCreate.errors.supplies_order_item_id}
              />
            </TableCell>
            <TableCell align='right'>
              <TextField
                variant='outlined'
                size='small'
                fullWidth
                label='Type'
                name='type'
                value={formikCreate.values.type}
                onChange={formikCreate.handleChange}
                error={!!formikCreate.errors.type}
                helperText={formikCreate.errors.type}
              />
            </TableCell>
            <TableCell align='right'>
              <TextField
                variant='outlined'
                size='small'
                fullWidth
                label='Description'
                name='description'
                value={formikCreate.values.description}
                onChange={formikCreate.handleChange}
                error={!!formikCreate.errors.description}
                helperText={formikCreate.errors.description}
              />
            </TableCell>
            <TableCell align='right'>
              <TextField
                variant='outlined'
                size='small'
                label='Size'
                name='size'
                value={formikCreate.values.size}
                onChange={formikCreate.handleChange}
                error={!!formikCreate.errors.size}
                helperText={formikCreate.errors.size}
              />
            </TableCell>
            <TableCell align='right'>
              <TextField
                variant='outlined'
                size='small'
                label='Quantity'
                name='quantity'
                value={formikCreate.values.quantity}
                onChange={formikCreate.handleChange}
                error={!!formikCreate.errors.quantity}
                helperText={formikCreate.touched.quantity && formikCreate.errors.quantity}
              />
            </TableCell>
            <TableCell align='right'>
              <FormControl size='small' fullWidth variant='outlined'>
                <InputLabel>Legality</InputLabel>
                <Select
                  name='legality'
                  value={formikCreate.values.legality}
                  onChange={formikCreate.handleChange}
                >
                  {itemLegality.map(legality =>
                    <MenuItem key={legality} value={legality}>
                      {itemLegalityLabel[legality]}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell align='right'>
              <Button
                size='small'
                onClick={formikCreate.handleSubmit}
                disabled={!formikCreate.dirty || !formikCreate.isValid}
              >create
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Items
