import { Box, Typography, Button } from '@mui/material'
import { useParams } from 'react-router-dom'

import { useJobApplication } from 'hooks/recruiting/jobApplications/useJobApplication'

const JobApplicationView = () => {
  const params: any = useParams()

  const jobApplicationId = params?.jobApplicationId
  const { jobApplication, reject, markForInterview, markAsPending } = useJobApplication(jobApplicationId)

  if (!jobApplicationId || !jobApplication) return null

  return (
    <Box sx={{ p: 2 }}>
      <Typography>Application ID: {jobApplication?.id}</Typography>
      <Typography>Applicant ID: {jobApplication?.applicantId}</Typography>
      <Typography>Job Listing ID: {jobApplication?.jobListingId}</Typography>
      <Typography>Applicant Name: {jobApplication?.applicant?.fullName}</Typography>
      <Typography>Job Title: {jobApplication?.jobListing?.jobTitle}</Typography>
      <Typography>Cover Letter: {jobApplication?.coverLetter}</Typography>
      <Typography>Resume URL: {jobApplication?.resumeUrl}</Typography>
      <Typography>Created At: {jobApplication?.createdAt}</Typography>
      <Typography>Rejected At: {jobApplication?.rejectedAt}</Typography>
      <Typography>Marked for interview At: {jobApplication?.markedForInterviewAt}</Typography>
      <Typography>Rejection Reason: {jobApplication?.rejectionReason}</Typography>
      <Typography>Withdrew At: {jobApplication?.withdrewAt}</Typography>

      <Box sx={{ display: 'flex', gap: 1, padding: 2 }}>
        <Button
          onClick={reject}
          disabled={!!jobApplication?.rejectedAt || !!jobApplication?.markedForInterviewAt}
          variant='contained'
        >
          Reject
        </Button>
        <Button
          onClick={markForInterview}
          disabled={!!jobApplication?.rejectedAt || !!jobApplication?.markedForInterviewAt}
          variant='contained'
        >
          Mark for Interview
        </Button>
        <Button
          onClick={markAsPending}
          disabled={!jobApplication?.rejectedAt && !jobApplication?.markedForInterviewAt}
          variant='contained'
        >
          Mark as Pending
        </Button>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant='h6'>Job Listing</Typography>

        <Box sx={{ mt: 2 }}>
          <a href={`/recruiting/job-listings/${jobApplication?.jobListing?.id}`} target='_blank' rel='noreferrer'>
            <Typography>{jobApplication?.jobListing?.jobTitle}</Typography>
          </a>
        </Box>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant='h6'>Interviews</Typography>

        <Box sx={{ mt: 2 }}>
          {jobApplication?.interviews?.map(({ id, recruiter }) => (
            <a key={id} href={`/recruiting/interviews/${id}`} target='_blank' rel='noreferrer'>
              <Typography>
                {recruiter?.firstName} {recruiter?.lastName}
              </Typography>
            </a>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default JobApplicationView
