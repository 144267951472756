export default
{
  "Task::Types::InitialQuoteAndReply": ["sales"],
  "Task::Types::SetMissingCostOfOrderedPart": ["parts"],
  "Task::Types::CarbodylabCustomerCallback": ["sales"],
  "Task::Types::DrivewayCustomerCallback": ["sales"],
  "Task::Types::BToBCallback": ["ops"],
  "Task::Types::CarpartsCallback": ["ops"],
  "Task::Types::TechnicianCallback": ["tech_support"],
  "Task::Types::PartsCallback": ["parts"],
  "Task::Types::ReadReplyTechSms": ["tech_support"]
}
