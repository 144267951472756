export default [
  [
    'Quote',
    [
      [
        'default',
        'select'
      ],
      [
        'ColorCode',
        'Color Code'
      ],
      [
        'Other',
        'Other ...'
      ]
    ]
  ],
  [
    'Lead',
    [
      [
        'default',
        'select'
      ],
      [
        'Other',
        'Other ...'
      ]
    ]
  ]
]
