import React, { useEffect, useState, useContext } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import UserProvider, { UserContext } from './UserStore'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import './index.css'
import App from './App'
import { ApolloProvider, createHttpLink, ApolloClient } from '@apollo/client'
import { InvalidationPolicyCache, RenewalPolicy } from '@nerdwallet/apollo-cache-policies'
import { setContext } from '@apollo/client/link/context'
import { relayStylePagination } from '@apollo/client/utilities'
import { SocketProvider } from '@ericlathrop/phoenix-js-react-hooks'
import Login from './Login'
import RefetchRegistryProvider from './RefetchRegistry'
import UpdateRefetchRegistryProvider from './UpdateRefetchRegistry'
import GlobalStore from './GlobalStore'
import ReadCacheChannelProvider from './ReadCacheChannelStore'
import TaskTargetRegistryProvider from './TaskTargetRegistry'
import CustomerContextProvider from './CustomerStore'
import RecruiterApplicantContextProvider from './RecruitingApplicantStore'

const UserConsumer = () => {
  const [user] = useContext(UserContext)
  const [apolloClient, setApolloClient] = useState<any>()

  const socketUrl = `wss://${process.env.REACT_APP_NEO_HOST}/socket`
  const socketOptions = { params: { token: user.token } }

  useEffect(() => {
    const userIsLoggedIn = user && user.id && user.token

    if (!userIsLoggedIn) return

    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_GQL_URL,
    })

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${user.token}`,
        },
      }
    })

    setApolloClient(
      new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InvalidationPolicyCache({
          invalidationPolicies: {
            timeToLive: 3000,
            renewalPolicy: RenewalPolicy.AccessAndWrite,
            types: {
              Customer: {
                timeToLive: 100,
              },
              Notepad: {
                timeToLive: 100,
              },
            },
          },
          typePolicies: {
            Query: {
              fields: {
                leadsConnection: relayStylePagination(),
                partsConnection: relayStylePagination(),
                partVendorsConnection: relayStylePagination(),
                bToBInvoicesConnection: relayStylePagination(),
                callbackRequestsConnection: relayStylePagination(),
                callRecordsConnection: relayStylePagination(),
                suppliesOrdersConnection: relayStylePagination(),
                jobsConnection: relayStylePagination(),
                timeCardsConnection: relayStylePagination(),
                appointmentsConnection: relayStylePagination(),
                yearMakePremiumsConnection: relayStylePagination(),
                botSessionsConnection: relayStylePagination(),
                jobApplicationsConnection: relayStylePagination(),
                interviewsConnection: relayStylePagination(),
                primaryLaborDamagesConnection: relayStylePagination(),
                jobListingsConnection: relayStylePagination(),
              },
            },
          },
        }),
      })
    )
  }, [user])

  // if (apolloClient) return 'Loading...'

  return (
    <Router>
      <ThemeProvider theme={theme}>
        {(apolloClient && (
          <ApolloProvider client={apolloClient}>
            <GlobalStore>
              <SocketProvider url={socketUrl} options={socketOptions}>
                <ReadCacheChannelProvider>
                  <TaskTargetRegistryProvider>
                    <RefetchRegistryProvider>
                      <UpdateRefetchRegistryProvider>
                        <CustomerContextProvider>
                          <RecruiterApplicantContextProvider>
                            <App />
                          </RecruiterApplicantContextProvider>
                        </CustomerContextProvider>
                      </UpdateRefetchRegistryProvider>
                    </RefetchRegistryProvider>
                  </TaskTargetRegistryProvider>
                </ReadCacheChannelProvider>
              </SocketProvider>
            </GlobalStore>
          </ApolloProvider>
        )) || <Login />}
      </ThemeProvider>
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <UserConsumer />
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
