import { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Box, Button, Typography, FormControl, TextField, Popover, Autocomplete, ListItem } from '@mui/material'
import { v4 as uuid } from 'uuid'
import { hourlyRateFields } from './PrimaryLaborItems'
import { DateTime } from 'luxon'

const PANELS_QUERY = gql`
  query PrimaryLaborCatalogItems {
    primaryLaborCatalogItems {
      id
      name
      side
      panel
      type
    }
  }
`

export const HourlyLaborPopver = ({ hourlyRates, handleLaborSelected }) => {
  const [hourlyLaborItemPopoverElement, setHourlyLaborItemPopoverElement] = useState(null)
  const [selectedPanel, setSelectedPanel] = useState({})

  const [hourlyTotals, setHourlyTotals] = useState({
    totalPrepHours: 0,
    totalPaintHours: 0,
    totalBodyWorkHours: 0,
    totalMajorPartHours: 0,
    totalMinorPartHours: 0,
  })

  const prepCost = hourlyTotals.totalPrepHours * hourlyRates.prep
  const paintCost = hourlyTotals.totalPaintHours * hourlyRates.paint
  const bodyWorkCost = hourlyTotals.totalBodyWorkHours * hourlyRates.bodyWork
  const majorPartCost = hourlyTotals.totalMajorPartHours * hourlyRates.majorPart
  const minorPartCost = hourlyTotals.totalMinorPartHours * hourlyRates.minorPart

  const totalLaborCost = prepCost + paintCost + bodyWorkCost + majorPartCost + minorPartCost

  const totalHours =
    hourlyTotals.totalPrepHours +
    hourlyTotals.totalPaintHours +
    hourlyTotals.totalBodyWorkHours +
    hourlyTotals.totalMajorPartHours +
    hourlyTotals.totalMinorPartHours

  const handleAddLaborItem = () => {
    handleLaborSelected({
      id: uuid(),
      type: selectedPanel.type,
      name: selectedPanel.name,
      side: selectedPanel.side,
      price: Number(totalLaborCost.toFixed(2)),
      totalPrepHours: hourlyTotals.totalPrepHours,
      totalPaintHours: hourlyTotals.totalPaintHours,
      totalBodyWorkHours: hourlyTotals.totalBodyWorkHours,
      totalMajorPartHours: hourlyTotals.totalMajorPartHours,
      totalMinorPartHours: hourlyTotals.totalMinorPartHours,
      isHourly: true,
      totalHours: totalHours,
      panel: selectedPanel.panel,
      createdAt: DateTime.now().toISO(),
    })
    setHourlyLaborItemPopoverElement(null)
    setHourlyTotals({
      totalPrepHours: 0,
      totalPaintHours: 0,
      totalBodyWorkHours: 0,
      totalMajorPartHours: 0,
      totalMinorPartHours: 0,
    })
    setSelectedPanel({})
  }

  const { loading, error, data, refetch } = useQuery(PANELS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  if (error) return <div>Error!</div>

  const primaryLaborCatalogItems = data?.primaryLaborCatalogItems || []

  const isInputFoundInPanelName = (input, panelName) => {
    let subIdx = 0

    return [...panelName].some(char => {
      if (char === input[subIdx]) {
        subIdx++
        return subIdx === input.length
      }
      return false
    })
  }

  const filterOptions = (options, { inputValue }) => {
    if (!inputValue) return options
    return options.filter(item => isInputFoundInPanelName(inputValue.toLowerCase(), item.name.toLowerCase()))
  }

  return (
    <Box>
      <Button
        cy-data='add-hourly-labor-item-button'
        size='small'
        onClick={e => setHourlyLaborItemPopoverElement(e.target)}
        variant='outlined'
      >
        Add Hourly labor item
      </Button>
      <Popover
        open={!!hourlyLaborItemPopoverElement}
        anchorEl={hourlyLaborItemPopoverElement}
        onClose={() => setHourlyLaborItemPopoverElement(null)}
        PaperProps={{ sx: { borderRadius: '20px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ padding: '1rem', overflow: 'hidden' }}>
          <FormControl fullWidth size='small' sx={{ mb: '.5rem' }}>
            <Autocomplete
              fullWidth
              filterOptions={filterOptions}
              options={primaryLaborCatalogItems}
              value={selectedPanel}
              onChange={(event, newValue) => {
                setSelectedPanel(newValue)
              }}
              getOptionLabel={option => option?.name || ''}
              isOptionEqualToValue={(opt, value) => opt.name === value.name}
              renderInput={params => <TextField fullWidth {...params} size='small' label='Select Panel' />}
              renderOption={(props, option, state, ownerState) => <ListItem {...props}>{option.name}</ListItem>}
            />
          </FormControl>
          <Box
            sx={{
              background: '#f1f1f1',
              border: '2px solid #ddd',
              padding: '.25rem .5rem .5rem .5rem',
              borderRadius: '12px',
            }}
          >
            <Typography variant='body1' sx={{ mb: '.5rem', textAlign: 'center' }}>
              Expected Hours
            </Typography>
            <Box sx={{ display: 'flex' }}>
              {hourlyRateFields(hourlyRates).map(item => (
                <Box key={item.label}>
                  <TextField
                    value={hourlyTotals[item.key]}
                    onChange={e =>
                      setHourlyTotals(prev => ({
                        ...prev,
                        [item.key]: Number(e.target.value),
                      }))
                    }
                    inputProps={{ min: 0, 'cy-data': item.key }}
                    type='number'
                    size='small'
                    sx={{ maxWidth: '80px' }}
                    label={item.label}
                  />
                  <Typography variant='body1' sx={{ textAlign: 'center', mt: '.25rem', fontSize: '14px' }}>
                    ${item.hourlyRate.toFixed(2)}/hr
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#f1f1f1',
            alignItems: 'center',
            padding: '.5rem 1rem',
          }}
        >
          <Box>
            Hours: {totalHours} | Total: ${totalLaborCost.toFixed(2)}
          </Box>
          <Button
            cy-data='addLaborButton'
            onClick={handleAddLaborItem}
            size='small'
            disableElevation
            variant='contained'
            disabled={!selectedPanel?.panel}
          >
            Add
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}
