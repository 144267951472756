import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import {
  Paper,
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Line,
  Label,
  LabelList,
  Tooltip,
  ReferenceLine,
} from 'recharts'

import Formatters from 'Charts/Formatters'
import LabelContent from 'Charts/LabelContent'
import TooltipContent from 'Charts/TooltipContent'

const sources = ['All', 'Car Body Lab', 'Driveway']
const services = ['All', 'Auto Body', 'Auto Glass']
const groups = ['Month', 'Week', 'Day']
const colors = ['black', 'red', 'blue', 'cyan', 'green', 'orange', 'silver', 'gray']

const defaultFilter = {
  source: 'All',
  service: 'All',
  state: '',
  market: 'All',
  group: 'Month',
  fromDate: '',
  toDate: '',
}
const defaultView = {
  bar: 'cost',
  line: 'collected',
  filter: true,
  labels: true,
}

const metricLabels = {
  cost: 'Ad Cost',
  clicks: 'Ad Clicks',
  impressions: 'Ad Impressions',
  conversions: 'Ad Conversions',
  leads: 'Lead Count',
  noWay: 'No-Way Lead Count',
  sales: 'Sale Count',
  requested: 'Revenue',
  collected: 'Collected',
  costClicks: 'Cost per Click',
  salesClicks: 'Sales per Click',
  leadsClicks: 'CVR (Online)',
  salesLeads: 'CVR (Offline)',
  costLeads: 'Cost per Lead',
  costSales: 'Cost per Sale',
  requestedSales: 'Average Sale',
  collectedSales: 'Net Sale',
}
const metrics = Object.keys(metricLabels)
const metricScale = {
  cost: 100,
  clicks: 100,
  impressions: 500,
  conversions: 5,
  leads: 25,
  noWay: 10,
  sales: 5,
  requested: 500,
  collected: 500,
  costClicks: 0.5,
  leadsClicks: 0.01,
  salesClicks: 0.01,
  salesLeads: 0.01,
  costLeads: 1,
  costSales: 1,
  requestedSales: 200,
  collectedSales: 200,
}
const metricFormatters = {
  clicks: Formatters.count,
  impressions: Formatters.count,
  conversions: Formatters.count,
  leads: Formatters.count,
  noWay: Formatters.count,
  sales: Formatters.count,
  cost: Formatters.dollars,
  requested: Formatters.dollars,
  collected: Formatters.dollars,
  costClicks: Formatters.cents,
  costLeads: Formatters.cents,
  leadsClicks: Formatters.percent,
  salesLeads: Formatters.percent,
  salesClicks: Formatters.percent,
  costSales: Formatters.dollars,
  requestedSales: Formatters.dollars,
  collectedSales: Formatters.dollars,
}
const defaultMetricColors = {
  cost: 'red',
  clicks: 'blue',
  impressions: 'cyan',
  conversions: 'darkblue',
  leads: 'gold',
  noWay: 'sienna',
  sales: 'green',
  requested: 'silver',
  collected: 'black',
  costClicks: 'purple',
  leadsClicks: 'mediumaquamarine',
  salesClicks: 'darkolivegreen',
  costLeads: 'orange',
  salesLeads: 'lime',
  costSales: 'olive',
  requestedSales: 'indianred',
  collectedSales: 'darkred',
}

const CHARTS = gql`
  query metricCharts($source: String, $service: String, $group: String, $market: String, $fromDate: String, $toDate: String) {
    metricCharts(source: $source, service: $service, group: $group, market: $market, fromDate: $fromDate, toDate: $toDate) {
      x
      cost
      clicks
      impressions
      conversions
      leads
      leadIds
      noWay
      sales
      saleIds
      requested
      collected
      costClicks
      leadsClicks
      salesClicks
      costLeads
      salesLeads
      costSales
      requestedSales
      collectedSales
    }
  }
`

const styles = {
  floatRight: {
    float: 'right',
  },
  sourceField: {
    display: 'inline-flex',
    width: 200,
  },
  serviceField: {
    display: 'inline-flex',
    width: 200,
  },
  marketField: {
    display: 'inline-flex',
    width: 240,
  },
  groupField: {
    display: 'inline-flex',
    width: 140,
  },
  dateField: {
    display: 'inline-flex',
    width: 150,
  },
  metricField: {
    display: 'inline-flex',
    width: 225,
  },
  colorField: {
    display: 'inline-flex',
    width: 120,
  },
  heightField: {
    display: 'inline-flex',
    width: 60,
  },
}

const Metrics = ({ markets }) => {
  const [filter, setFilter] = useState(JSON.parse(localStorage.getItem('chartsMetricsFilter')) || defaultFilter)
  useEffect(() => localStorage.setItem('chartsMetricsFilter', JSON.stringify(filter)), [filter])

  const [nextFilter, setNextFilter] = useState({})
  const handleChangeSource = (event, newval) => setNextFilter({ ...nextFilter, source: newval })
  const handleChangeService = (event, newval) => setNextFilter({ ...nextFilter, service: newval })
  const handleChangeMarket = (event, newval) => {
    if (!newval) {
      const next = { ...nextFilter }
      delete next.market
      setNextFilter(next)
    } else setNextFilter({ ...nextFilter, market: newval.value })
  }
  const handleChangeGroup = (event, newval) => setNextFilter({ ...nextFilter, group: newval })
  const handleChangeFromDate = event => setNextFilter({ ...nextFilter, fromDate: event.target.value })
  const handleChangeToDate = event => setNextFilter({ ...nextFilter, toDate: event.target.value })
  const handleClickClear = () => setNextFilter({})
  const handleClickUpdate = () => {
    const next = nextFilter
    setNextFilter({})
    setFilter({
      ...filter, ...next
    })
  }

  const [view, setView] = useState(JSON.parse(localStorage.getItem('chartsMetricsView')) || defaultView)
  useEffect(() => localStorage.setItem('chartsMetricsView', JSON.stringify(view)), [view])
  const handleChangeFilters = () => setView({ ...view, filter: !view.filter })
  const handleChangeView = () => setView({ ...view, view: !view.view })
  const handleChangeBar = (event, newval) => setView({ ...view, bar: newval || defaultView.bar })
  const handleChangeLine = (event, newval) => setView({ ...view, line: newval || defaultView.line })
  const handleChangeLegend = () => setView({ ...view, legend: !view.legend })
  const handleChangeLabels = () => setView({ ...view, labels: !view.labels })
  const handleChangeTooltip = () => setView({ ...view, tooltip: !view.tooltip })

  const [metricColors, setMetricColors] = useState(
    JSON.parse(localStorage.getItem('chartsMetricColors')) || defaultMetricColors
  )
  useEffect(() => localStorage.setItem('chartsMetricColors', JSON.stringify(metricColors)), [metricColors])
  const setColor = (key, val) => {
    const next = { ...metricColors }
    next[key] = val
    setMetricColors(next)
  }
  const handleChangeBarColor = (event, newval) => setColor(view.bar, newval || defaultMetricColors[view.bar])
  const handleChangeLineColor = (event, newval) => setColor(view.line, newval || defaultMetricColors[view.line])

  const [scale, setScale] = useState({})
  // this won't work nicely because of converting uncontrolled to controlled input
  // i am keeping that because that's how i determine scaling mode nil => 'auto'
  // useEffect(() => setScale({}), [view])
  const setScaleProp = (key, val) => {
    const next = { ...scale }
    next[key] = val
    setScale(next)
  }
  const handleChangeScaleBar = event => setScaleProp(view.bar, event.target.value)
  const handleChangeScaleLine = event => setScaleProp(view.line, event.target.value)

  const CustomTooltip = TooltipContent(metricFormatters, metricLabels, metricColors)
  const CustomBarLabel = LabelContent.bar(metricFormatters[view.bar], metricColors[view.bar])
  const CustomLineLabel = LabelContent.line(metricFormatters[view.line], metricColors[view.line])

  // GraphQL
  const { loading, error, data } = useQuery(CHARTS, {
    variables: {
      source: filter.source,
      service: filter.service,
      group: filter.group,
      market: filter.market,
      fromDate: filter.fromDate || null,
      toDate: filter.toDate || null,
    },
  })

  const dirtyFromDate = Object.hasOwnProperty.call(nextFilter, 'fromDate')
  const dirtyToDate = Object.hasOwnProperty.call(nextFilter, 'toDate')
  const dirty = !!(
    nextFilter.source ||
    nextFilter.service ||
    nextFilter.market ||
    nextFilter.group ||
    dirtyFromDate ||
    dirtyToDate
  )
  const displayMarket = markets.find(market => market.value === (nextFilter.market || filter.market))
  const displayFromDate = dirtyFromDate ? nextFilter.fromDate : filter.fromDate
  const displayToDate = dirtyToDate ? nextFilter.toDate : filter.toDate
  const limitBar = scale[view.bar] ? 'dataMax + ' + scale[view.bar] : 'auto'
  const limitLine = scale[view.line] ? 'dataMax + ' + scale[view.line] : 'auto'

  const [selectedDate, setSelectedDate] = useState({})
  useEffect(() => setSelectedDate({}), [filter])
  const handleClickBar = event => setSelectedDate(event)
  const handleClickCopyLeads = () => navigator.clipboard.writeText(selectedDate.payload.leadIds.join('\n'))
  const handleClickCopySales = () => navigator.clipboard.writeText(selectedDate.payload.saleIds.join('\n'))

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ float: 'right' }}>
        <FormControlLabel
          label='Filter'
          control={<Checkbox checked={view.filter} onChange={handleChangeFilters} name='Filter' />}
        />
        <FormControlLabel
          label='View'
          control={<Checkbox checked={view.view} onChange={handleChangeView} name='View' />}
        />
      </Box>
      <Typography variant='h5'>
        <>
          {filter.source} in {filter.market}
        </>
        <> from {filter.fromDate ? filter.fromDate : '(all time)'}</>
        <> to {filter.toDate ? filter.toDate : '(current)'}</>
        <>
          {' '}
          by {filter.group} : {metricLabels[view.bar]} vs {metricLabels[view.line]}
        </>
      </Typography>

      <Grid container spacing={2}>
        {(view.filter || view.view) && (
          <Grid item xs={12}>
            <Paper>
              <Grid container>
                {view.filter && (
                  <Grid item xs={12}>
                    <Autocomplete
                      sx={styles.sourceField}
                      options={sources}
                      value={nextFilter.source ? nextFilter.source : filter.source}
                      onChange={handleChangeSource}
                      renderInput={props => <TextField {...props} label='Source' />}
                    />
                    <Autocomplete
                      sx={styles.serviceField}
                      options={services}
                      value={nextFilter.service ? nextFilter.service : filter.service ?? 'All'}
                      onChange={handleChangeService}
                      renderInput={props => <TextField {...props} label='Service' />}
                    />
                    <Autocomplete
                      sx={styles.marketField}
                      options={markets}
                      value={displayMarket}
                      onChange={handleChangeMarket}
                      getOptionLabel={market => market.label}
                      getOptionSelected={market => market.value === filter.market}
                      renderInput={params => <TextField {...params} label='Market' />}
                    />
                    <Autocomplete
                      sx={styles.groupField}
                      options={groups}
                      value={nextFilter.group ? nextFilter.group : filter.group}
                      onChange={handleChangeGroup}
                      renderInput={groups => <TextField {...groups} label='Group' />}
                    />
                    <TextField
                      sx={styles.dateField}
                      label='From'
                      type='date'
                      value={displayFromDate}
                      onChange={handleChangeFromDate}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={styles.dateField}
                      label='To'
                      type='date'
                      value={displayToDate}
                      onChange={handleChangeToDate}
                      InputLabelProps={{ shrink: true }}
                    />
                    <Button variant='outlined' color='primary' disabled={!dirty} onClick={handleClickClear}>
                      clear
                    </Button>
                    <Button variant='contained' color='primary' disabled={!dirty} onClick={handleClickUpdate}>
                      update
                    </Button>
                  </Grid>
                )}

                {view.view && (
                  <Grid item xs={12}>
                    <Autocomplete
                      sx={styles.metricField}
                      value={view.bar}
                      options={metrics}
                      onChange={handleChangeBar}
                      getOptionSelected={metric => metric === view.bar}
                      getOptionLabel={metric => metricLabels[metric]}
                      renderInput={params => <TextField {...params} label='Bar' />}
                    />
                    <Autocomplete
                      sx={styles.colorField}
                      value={metricColors[view.bar]}
                      options={colors}
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      onChange={handleChangeBarColor}
                      getOptionSelected={color => color === metricColors[view.bar]}
                      renderInput={params => <TextField {...params} label='Bar Color' />}
                    />
                    <TextField
                      sx={styles.heightField}
                      label='Bar Y'
                      value={scale[view.bar]}
                      size='small'
                      onChange={handleChangeScaleBar}
                      inputProps={{
                        step: metricScale[view.bar],
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                    <Autocomplete
                      sx={styles.metricField}
                      value={view.line}
                      options={metrics}
                      onChange={handleChangeLine}
                      getOptionSelected={metric => metric === view.line}
                      getOptionLabel={metric => metricLabels[metric]}
                      renderInput={params => <TextField {...params} label='Line' />}
                    />
                    <Autocomplete
                      sx={styles.colorField}
                      value={metricColors[view.line]}
                      options={colors}
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      onChange={handleChangeLineColor}
                      getOptionSelected={color => color === metricColors[view.line]}
                      renderInput={params => <TextField {...params} label='Line Color' />}
                    />
                    <TextField
                      sx={styles.heightField}
                      label='Line Y'
                      value={scale[view.line]}
                      size='small'
                      onChange={handleChangeScaleLine}
                      inputProps={{
                        step: metricScale[view.line],
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={view.legend} onChange={handleChangeLegend} name='Legend' />}
                      label='Legend'
                    />
                    <FormControlLabel
                      control={<Checkbox checked={view.labels} onChange={handleChangeLabels} name='Labels' />}
                      label='Labels'
                    />
                    <FormControlLabel
                      control={<Checkbox checked={view.tooltip} onChange={handleChangeTooltip} name='Tooltip' />}
                      label='Tooltip'
                    />
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        )}

        {loading && (
          <Grid item xs={12}>
            <Paper>Loading...</Paper>
          </Grid>
        )}

        {error && (
          <Grid item xs={12}>
            <Paper>Error!</Paper>
          </Grid>
        )}

        {!loading && !error && (
          <Grid item xs={12} style={{ position: 'relative' }}>
            <Paper>
              <ResponsiveContainer height={360} width='98%'>
                <ComposedChart data={data.metricCharts} margin={{ top: 20, left: 20, right: 20 }}>
                  <CartesianGrid stroke='#ccc' />

                  {view.legend && <Legend width={160} />}

                  {view.bar && (
                    <Bar
                      type='monotone'
                      yAxisId='left'
                      barSize={32}
                      dataKey={view.bar}
                      fill={metricColors[view.bar]}
                      onClick={handleClickBar}
                    >
                      {view.labels && <LabelList dataKey={view.bar} position='top' content={CustomBarLabel} />}
                    </Bar>
                  )}

                  {view.line && (
                    <Line
                      type='monotone'
                      yAxisId='right'
                      strokeWidth={7}
                      dataKey={view.line}
                      stroke={metricColors[view.line]}
                    >
                      {view.labels && <LabelList dataKey={view.line} position='center' content={CustomLineLabel} />}
                    </Line>
                  )}

                  <XAxis dataKey='x' />
                  <YAxis yAxisId='left' type='number' domain={[0, limitBar]}>
                    <Label position='left' angle={-90} offset={0} value={metricLabels[view.bar]} />
                  </YAxis>
                  <YAxis yAxisId='right' orientation='right' type='number' domain={[0, limitLine]}>
                    <Label position='right' angle={90} offset={0} value={metricLabels[view.line]} />
                  </YAxis>

                  {view.tooltip && <Tooltip content={<CustomTooltip />} />}

                  {selectedDate.payload && <ReferenceLine x={selectedDate.payload.x} stroke='black' yAxisId='left' />}
                </ComposedChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        )}

        {selectedDate.payload && (
          <Grid item xs={4}>
            <Paper>
              <ul>
                {filter.group} of {selectedDate.payload.x} in {filter.market}
                {['clicks', 'cost', 'leadsClicks', 'salesClicks', 'salesLeads'].map(metric => (
                  <li key={metric}>
                    {metricLabels[metric]}: {selectedDate.payload[metric]}
                  </li>
                ))}
              </ul>
            </Paper>
          </Grid>
        )}

        {selectedDate.payload && (
          <Grid item xs={4}>
            <Paper>
              <Button variant='contained' color='primary' style={{ float: 'right' }} onClick={handleClickCopyLeads}>
                copy
              </Button>
              <ul>
                Leads: {selectedDate.payload.leads}
                {selectedDate.payload.leadIds.map(leadId => (
                  <li key={leadId}>
                    <Link to={'/leads/' + leadId}>{leadId}</Link>
                  </li>
                ))}
              </ul>
            </Paper>
          </Grid>
        )}

        {selectedDate.payload && (
          <Grid item xs={4}>
            <Paper>
              <Button variant='contained' color='primary' style={{ float: 'right' }} onClick={handleClickCopySales}>
                copy
              </Button>
              <ul>
                Sales: {selectedDate.payload.sales}
                {selectedDate.payload.saleIds.map(leadId => (
                  <li key={leadId}>
                    <Link to={'/leads/' + leadId}>{leadId}</Link>
                  </li>
                ))}
              </ul>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default Metrics
