import { useEffect, useState, createRef, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Container, Stack, TextField, Button, Collapse } from '@mui/material'
import { styled } from '@mui/material/styles'
import { API_COMMAND_HEADERS, isEmail } from 'tools'
import { login } from 'auth'

const CODE_LENGTH = 6

const emailSchema = Yup.object().shape({
  email: Yup.string().test({
    name: 'email',
    test: value => !!value && isEmail(value),
    message: 'Please enter a valid email address.',
  }),
})
const emailInitialValues = { email: '' }

const codeSchema = Yup.array()
  .min(CODE_LENGTH, `Must use ${CODE_LENGTH} digits`)
  .max(CODE_LENGTH, `Must use ${CODE_LENGTH} digits`)
  .of(Yup.string().matches(/[0-9]/, 'Must be a number'))
const codeInitialValues = Array.from({ length: CODE_LENGTH }, () => '')

const headers = {
  ...API_COMMAND_HEADERS,
  Accept: 'application/json',
}

const StyledNumberInput = styled(TextField)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  width: 3rem;
`

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const Login = () => {
  const query = useQuery()
  const [loginAttemptId, setLoginAttemptId] = useState(false)
  const [loginAttemptTimer, setLoginAttemptTimer] = useState(0)
  const [devLoginData, setDevLoginData] = useState([])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') return

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/development_login_data`, {
      method: 'GET',
      headers,
    })
      .then(async res => {
        if (res.ok) setDevLoginData(await res.json())
        else throw 'no dev users'
      })
      .catch(e => alert(e))
  }, [])

  useMemo(() => {
    if (devLoginData.length < 1) return
    const user_id = query.get('user_id')
    if (!user_id) return
    const user = devLoginData.users.find(u => u.id === user_id)
    if (!user) return
    login({ ...user, token: devLoginData.token })
  }, [devLoginData])

  useEffect(() => {
    if (loginAttemptTimer < 1) return
    setTimeout(() => {
      setLoginAttemptTimer(seconds => {
        if (seconds === 1) setLoginAttemptId(false)
        return seconds - 1
      })
    }, 1000)
  }, [loginAttemptTimer])

  const formikEmail = useFormik({
    initialValues: emailInitialValues,
    validationSchema: emailSchema,
    onSubmit: values => {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/login_token`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          email: values.email.trim(),
        }),
      })
        .then(res => res.json())
        .then(data => {
          if (data.error) {
            alert('failed to get login token: ' + data.error)
          } else {
            setLoginAttemptId(data.login_attempt_id)
            setLoginAttemptTimer(data.timeout)
            setTimeout(() => focusCodeInputRef(0), 0)
          }
        })
      formikCode.setValues(codeInitialValues)
    },
  })

  const formikCode = useFormik({
    initialValues: codeInitialValues,
    validationSchema: codeSchema,
    onSubmit: values => {
      const value = values.join('')

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/login_attempt`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          login_attempt_id: loginAttemptId,
          value: value,
        }),
      }).then(async res => (res.ok ? login(await res.json()) : alert('code submission failed')))

      setLoginAttemptId(false)
    },
  })

  const [codeInputRefs] = useState(() => Array.from({ length: CODE_LENGTH }, () => createRef()))

  const focusCodeInputRef = i => {
    const input = codeInputRefs[i].current.children[0].children[0]
    input.focus()
    input.select()
  }

  const handleCodeInputKeyUp = (i, e) => {
    const cur = codeInputRefs[i].current.children[0].children[0]
    if (cur.value.length < 1) {
      if (e.key === 'Backspace' && i > 0) focusCodeInputRef(i - 1)
    } else if (i + 1 < CODE_LENGTH) {
      focusCodeInputRef(i + 1)
    } else {
      formikCode.handleSubmit()
    }
  }

  const handleClear = () => {
    if (loginAttemptId) {
      formikCode.setValues(codeInitialValues)
      focusCodeInputRef(0)
    } else {
      formikEmail.setValues(emailInitialValues)
    }
  }

  return (
    <Container>
      <Stack component='form' noValidate autoComplete='off' onSubmit={formikEmail.handleSubmit}>
        <h1>Log in</h1>
        <TextField
          label='Email'
          type='email'
          name='email'
          value={formikEmail.values.email}
          onChange={formikEmail.handleChange}
          error={formikEmail.touched.email && !!formikEmail.errors.email}
          helperText={formikEmail.touched.email && formikEmail.errors.email}
          disabled={!!loginAttemptId}
        />
        <br />
        <Button variant='contained' color='primary' type='submit' disabled={!formikEmail.isValid || !!loginAttemptId}>
          login
        </Button>
        <Collapse in={!!loginAttemptId}>
          <br />
          <h4 style={{ float: 'right', margin: '1rem 0' }}>{loginAttemptTimer}</h4>
          <h3 style={{ margin: '1rem 0' }}>Enter 2FA code</h3>
          <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
            {codeInputRefs.map((ref, i) => (
              <span key={i} style={{ flex: 1 }}>
                <StyledNumberInput
                  ref={ref}
                  key={i}
                  type='number'
                  name={i + ''}
                  value={formikCode.values[i]}
                  onChange={formikCode.handleChange}
                  onKeyUp={e => handleCodeInputKeyUp(i, e)}
                />
              </span>
            ))}
          </div>
        </Collapse>
        <br />
        <Button
          type='submit'
          variant='outlined'
          color='secondary'
          onClick={handleClear}
          disabled={(!formikEmail.dirty && !loginAttemptId) || (!formikCode.dirty && loginAttemptId)}
        >
          clear
        </Button>
        {devLoginData?.users?.length > 0 && (
          <>
            <br />
            <h2 style={{ color: 'red', margin: '1rem 0' }}>Development log in</h2>
            {devLoginData.users.map(user => (
              <button
                key={user.id}
                type='button'
                style={{ margin: '1rem', padding: '1rem' }}
                onClick={() => login({ ...user, token: devLoginData.token })}
              >
                {user.first_name} {user.last_name}, roles: {JSON.stringify(user.roles, null, 2)}
              </button>
            ))}
          </>
        )}
      </Stack>
    </Container>
  )
}

export default Login
