import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { gql, useQuery } from '@apollo/client'
import JSONPretty from 'react-json-pretty'
import { useState } from 'react'

import {
  Button,
  Dialog,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'

import { useToggle } from 'hooks/useToggle'
import { Dollars } from 'tools'
import { CloseRounded, RefreshRounded } from '@mui/icons-material'

const QuoteHistoryQuery = gql`
  query quotesEventsHistory($id: ID!) {
    quotesEventsHistory(id: $id) {
      user {
        id
        firstName
        lastName
      }
      event {
        eventId
        eventType
        timestamp
        data
      }
      quote {
        id
        laborSum
        partsSum
        preTaxGrandTotal
        postTaxGrandTotal
        preJobGrandTotal
        offeredDiscount
        taxableAmount
        taxTotal

        primaryLaborItems {
          id
          name
          price
          void
          totalHours
          totalPrepHours
          totalPaintHours
          totalBodyWorkHours
          totalMinorPartHours
          totalMajorPartHours
        }
        otherLaborItems {
          id
          name
          price
          void
        }
        carbodylabCharges {
          id
          name
          price
          void
        }
        technicianCharges {
          id
          name
          price
          void
        }
        parts {
          id
          name
          number
          cost
          price
        }
      }
    }
  }
`

interface QuotesEventsHistoryResponse {
  quotesEventsHistory?: QuoteEventHistoryItem[]
}

interface QuoteEventHistoryItem {
  user?: {
    id?: string
    firstName?: string
    lastName?: string
  }
  event: Event
  quote: QuoteHistoryItem
}

interface Event {
  eventId: string
  eventType: string
  timestamp: string
  data: {
    user_id: string
    quote_id: string
  }
}

interface QuoteHistoryItem {
  id: string
  laborSum: number
  partsSum: number
  preTaxGrandTotal: number
  postTaxGrandTotal: number
  preJobGrandTotal: number
  taxableAmount: number
  taxTotal: number
  offeredDiscount: number
  technicianCharges: QuoteCharge[]
  carbodylabCharges: QuoteCharge[]
  otherLaborItems: QuoteCharge[]

  parts: (QuoteCharge & { number: string; cost: number })[]

  primaryLaborItems: (QuoteCharge & {
    totalPrepHours: number
    totalPaintHours: number
    totalBodyWorkHours: number
    totalMinorPartHours: number
    totalMajorPartHours: number
    totalHours: number
  })[]
}

interface QuoteCharge {
  id: string
  name: string
  price: number
}

interface MyProps {
  quoteId: string
}

function strikeIfVoidSx(obj: any) {
  if (!obj.void) return {}
  return {
    color: '#555 !important',
    textDecoration: 'line-through',
  }
}

const QuoteHistory = ({ quoteId }: MyProps) => {
  const [showHistory, _toggleHistory, closeHistory, openHistory] = useToggle()
  const [quoteStageIndex, setQuoteStageIndex] = useState<number>(0)
  const [showRawData, setShowRawData] = useState<boolean>(false)

  const { data, refetch } = useQuery<QuotesEventsHistoryResponse>(QuoteHistoryQuery, {
    variables: { id: quoteId },
    skip: !showHistory,
  })

  const eventsHistory = data?.quotesEventsHistory ?? []
  const selectedHistory = eventsHistory[quoteStageIndex]

  const selectedPartsCharges = selectedHistory?.quote.parts?.reduce((a, c) => a + (c.price || 0), 0)
  const selectedTechnicianCharges = selectedHistory?.quote.technicianCharges?.reduce((a, c) => a + (c.price || 0), 0)
  const selectedCarbodylabCharges = selectedHistory?.quote.carbodylabCharges?.reduce((a, c) => a + (c.price || 0), 0)

  return (
    <>
      <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={openHistory}>
        toggle history
      </span>

      <Dialog fullWidth={true} maxWidth='lg' open={showHistory} onClose={closeHistory}>
        <Box
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            padding: '1rem',
            display: 'flex',
            alignItems: 'center',
            '& svg': { cursor: 'pointer' },
          }}
        >
          <>Quote History</>
          <RefreshRounded onClick={() => refetch()} />
          <span style={{ flex: 1 }}></span>
          <CloseRounded onClick={closeHistory} />
        </Box>

        <Box sx={{ display: 'flex' }}>
          <Box sx={{ overflow: 'hidden', width: '250px' }}>
            <Box sx={{ overflowY: 'auto', maxHeight: '700px' }}>
              {eventsHistory.map((hist, index) => {
                const date = new Date(hist.event.timestamp)

                return (
                  <Box
                    id={`EventTab-${hist.event.eventId}`}
                    key={hist.event.eventId}
                    onClick={() => setQuoteStageIndex(index)}
                    sx={[
                      {
                        padding: '.5em',
                        cursor: 'pointer',
                        userSelect: 'none',
                        transition: 'all .25s ease-in-out',
                        background: '#fff',
                        '& b': { fontWeight: 500, fontSize: '12px' },
                        '& span': { fontSize: '12px' },
                      },
                      index === quoteStageIndex && {
                        background: '#2671d9',
                        color: 'white',
                        '& b': { fontSize: '12px', fontWeight: 600 },
                      },
                      index !== quoteStageIndex && { color: '#cdd5df' },
                    ]}
                  >
                    <div>
                      <b>
                        {hist?.user?.firstName} {hist?.user?.lastName} {hist?.user && '-'} {hist?.user && ' '}
                        {hist.event.eventType.replace('Quote::Events::', '')}
                      </b>
                    </div>
                    <div>
                      <span>
                        {date.toDateString()} {date.getHours()}:{date.getMinutes()}.{date.getSeconds()}
                      </span>
                    </div>
                  </Box>
                )
              })}
            </Box>
          </Box>

          <Box
            sx={{
              maxHeight: '700px',
              overflowY: 'auto',
              padding: '0rem',
              display: 'flex',
              justifyContent: 'center',
              flexGrow: 1,
            }}
          >
            {!selectedHistory && 'Missing selectedHistory'}
            {selectedHistory && (
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '95%',
                  '& .MuiAccordion-root': {
                    mb: '1rem',
                  },
                  '& .MuiButtonBase-root': {
                    minHeight: '30px',
                    padding: '0rem .5rem',
                    fontSize: '12px',
                    color: '#777',
                  },
                  '& .MuiAccordionSummary-content': {
                    margin: '0px',
                  },
                  '& .MuiAccordionDetails-root': {
                    padding: '.5rem',
                  },
                  '& .MuiTableCell-root': { padding: '.125rem' },
                }}
              >
                <Accordion
                  elevation={0}
                  disableGutters
                  defaultExpanded
                  sx={{
                    border: '1px solid #ddd',
                    borderRadius: '6px',
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                      <div>Labor:</div>&nbsp;
                      <Dollars value={selectedHistory.quote.laborSum || 0} />
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Table size='small' sx={{ padding: '.5rem 1rem', borderRadius: '6px', background: '#fff', mb: 2 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell colSpan={6}>Hours</TableCell>
                          <TableCell>Price</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell />
                          <TableCell>Prep</TableCell>
                          <TableCell>Paint</TableCell>
                          <TableCell>Body</TableCell>
                          <TableCell>Minor</TableCell>
                          <TableCell>Major</TableCell>
                          <TableCell>Total</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedHistory?.quote?.primaryLaborItems?.map(primaryLaborItem => {
                          return (
                            <TableRow key={primaryLaborItem?.id}>
                              <TableCell sx={strikeIfVoidSx(primaryLaborItem)}>{primaryLaborItem?.name}</TableCell>
                              <TableCell>{primaryLaborItem?.totalPrepHours}</TableCell>
                              <TableCell>{primaryLaborItem?.totalPaintHours}</TableCell>
                              <TableCell>{primaryLaborItem?.totalBodyWorkHours}</TableCell>
                              <TableCell>{primaryLaborItem?.totalMinorPartHours}</TableCell>
                              <TableCell>{primaryLaborItem?.totalMajorPartHours}</TableCell>
                              <TableCell sx={strikeIfVoidSx(primaryLaborItem)}>
                                {primaryLaborItem?.totalHours}
                              </TableCell>
                              <TableCell sx={strikeIfVoidSx(primaryLaborItem)}>
                                <Dollars value={primaryLaborItem?.price} />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>

                    <Table size='small' sx={{ padding: '.5rem 1rem', borderRadius: '6px', background: '#fff' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedHistory?.quote?.otherLaborItems?.map(otherLaborItem => {
                          return (
                            <TableRow key={otherLaborItem?.id}>
                              <TableCell sx={strikeIfVoidSx(otherLaborItem)}>{otherLaborItem?.name}</TableCell>
                              <TableCell sx={strikeIfVoidSx(otherLaborItem)}>
                                <Dollars value={otherLaborItem?.price} />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  elevation={0}
                  disableGutters
                  defaultExpanded
                  sx={{
                    border: '1px solid #ddd',
                    borderRadius: '6px',
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                      <div>Parts:</div>&nbsp;
                      <Dollars value={selectedPartsCharges} />
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Table size='small' sx={{ padding: '.5rem 1rem', borderRadius: '6px', background: '#fff' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>#</TableCell>
                          <TableCell>Cost</TableCell>
                          <TableCell>Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedHistory?.quote?.parts?.map(part => {
                          return (
                            <TableRow key={part?.id}>
                              <TableCell>{part?.name}</TableCell>
                              <TableCell>{part?.number}</TableCell>
                              <TableCell>
                                <Dollars value={part?.cost} />
                              </TableCell>
                              <TableCell>
                                <Dollars value={part?.price} />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  elevation={0}
                  disableGutters
                  defaultExpanded
                  sx={{
                    border: '1px solid #ddd',
                    borderRadius: '6px',
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                      <div>Technician Charges:</div>&nbsp;
                      <Dollars value={selectedTechnicianCharges} />
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Table size='small' sx={{ padding: '.5rem 1rem', borderRadius: '6px', background: '#fff' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedHistory?.quote?.technicianCharges?.map(item => {
                          return (
                            <TableRow key={item?.id}>
                              <TableCell sx={strikeIfVoidSx(item)}>{item?.name}</TableCell>
                              <TableCell sx={strikeIfVoidSx(item)}>
                                <Dollars value={item?.price} />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  elevation={0}
                  disableGutters
                  defaultExpanded
                  sx={{
                    border: '1px solid #ddd',
                    borderRadius: '6px',
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                      <div>Carbodylab Charges:</div>&nbsp;
                      <Dollars value={selectedCarbodylabCharges} />
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Table size='small' sx={{ padding: '.5rem 1rem', borderRadius: '6px', background: '#fff' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedHistory?.quote?.carbodylabCharges?.map(item => {
                          return (
                            <TableRow key={item?.id}>
                              <TableCell sx={strikeIfVoidSx(item)}>{item?.name}</TableCell>
                              <TableCell sx={strikeIfVoidSx(item)}>
                                <Dollars value={item?.price} />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1em' }}>
                  <div>Labor Discount:</div>
                  <Dollars value={selectedHistory.quote.offeredDiscount} />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1em' }}>
                  <div>Grand total before tax:</div>
                  <Dollars value={selectedHistory.quote.preTaxGrandTotal} />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1em' }}>
                  <div>Estimated taxable amount:</div>
                  <Dollars value={selectedHistory.quote.taxableAmount ?? 0} />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1em' }}>
                  <div>Estimated sales tax:</div>
                  <Dollars value={selectedHistory.quote.taxTotal ?? 0} />
                </Box>

                <Box sx={{ paddingY: '1em' }} />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '1em',
                    borderRadius: '4px',
                    background: '#f2f4f8',
                    paddingY: '5px',
                    paddingX: '10px',
                  }}
                >
                  <div>Grand total after tax:</div>
                  <Dollars value={selectedHistory.quote.postTaxGrandTotal} />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '1em',
                    borderRadius: '4px',
                    background: '#fde9e7',
                    color: '#e42614',
                    paddingY: '5px',
                    paddingX: '10px',
                  }}
                >
                  <div>PRE-PAY Amount:</div>
                  <Dollars value={selectedHistory.quote.preJobGrandTotal} />
                </Box>

                <Box sx={{ paddingY: '1em' }} />

                <Button onClick={() => setShowRawData(!showRawData)} fullWidth>
                  {showRawData && 'Hide Raw Data'}
                  {!showRawData && 'Show Raw Data'}
                </Button>

                {showRawData && (
                  <Box sx={{ padding: '1em' }}>
                    <JSONPretty style={{ fontSize: '12px' }} data={selectedHistory} />
                    {/* <JSONPretty style={{ fontSize: '12px' }} data={selectedEvent} /> */}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default QuoteHistory
