import { useQuery, gql } from '@apollo/client'
import { Grid } from '@mui/material'
import buildLaborObject from './buildLaborObject'
import SearchSelector from './SearchSelector'
import { v4 as uuid } from 'uuid'
import { DateTime } from 'luxon'

const DEALERS_QUERY = gql`
  query OtherLaborCatalogItems {
    otherLaborCatalogItems {
      id
      name
      price
      type
    }
  }
`

const AddOtherLabor = ({ quote, disabled, onSave }) => {
  const { loading, error, data, refetch } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  if (error) return <div>Error!</div>

  const laborItems = data?.otherLaborCatalogItems || []

  return (
    <Grid container sx={{ px: 1, pt: 0.3 }}>
      <Grid item xs={12}>
        <SearchSelector
          placeholder='...'
          listOfObjects={laborItems}
          attributeToFilterOn='name'
          renderOption={obj => obj.name + ' - $' + obj.price}
          onSelectionMade={newValue =>
            onSave({
              price: newValue.price,
              name: newValue.name,
              createdAt: DateTime.now().toISO(),
              id: uuid(),
            })
          }
          disabled={disabled}
          name='other-labor-search-selector'
          listName='other-labor-options-for-quote'
        />
      </Grid>
    </Grid>
  )
}

export default AddOtherLabor
