import { useContext } from 'react'
import CustomerSmsChat from './CustomerSmsChat'
import CustomerHistory from './CustomerHistory'
import { CustomerContext } from './CustomerStore'
import { Grid, Typography } from '@mui/material'
import { ApplicantContext } from 'RecruitingApplicantStore'
import RecruitingSmsChat from 'RecruitingSmsChat'

const RightDrawer = () => {
  const { currentCustomer, currentCustomerContext } = useContext(CustomerContext)
  const { currentApplicant, currentApplicantContext } = useContext(ApplicantContext)

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        {currentCustomer && (
          <CustomerSmsChat
            customer={currentCustomer}
            contextType={currentCustomerContext?.type || null}
            contextId={currentCustomerContext?.id || null}
          />
        )}
        {currentApplicant && (
          <RecruitingSmsChat
            applicant={currentApplicant}
            contextType={currentApplicantContext?.type || null}
            contextId={currentApplicantContext?.id || null}
          />
        )}
      </Grid>
      {!currentApplicant && (
        <Grid item xs={12}>
          <Typography sx={{ p: '.25rem', textAlign: 'center', borderBottom: '1px solid #f3f3f3' }}>History</Typography>

          <CustomerHistory />
        </Grid>
      )}
    </Grid>
  )
}

export default RightDrawer
