import { useContext, useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { UserContext } from 'UserStore'
import { Button, Collapse, MenuItem, Select, TextField } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

export const CreateNewLineItemRow = ({ refetch }: { refetch: any }) => {
  const [user] = useContext(UserContext)
  const [type, setType] = useState('')
  const [name, setName] = useState('')
  const [side, setSide] = useState('')
  const [price, setPrice] = useState('')

  const bearerTokenHeaders = useBearerTokenHeaders()

  const showCreateButton = name !== '' && price !== '' && type !== ''

  const handleCreateNewLineItem = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_other_labor_catalog_item`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        created_by_user_id: user.id,
        name: name,
        price: price,
        type: type,
      }),
    }).then(res => {
      if (res) {
        refetch()
        setType('')
        setSide('')
        setPrice('')
        setName('')
      } else {
      }
    })
  }

  return (
    <TableRow sx={{ background: '#f0fff1', position: 'sticky', top: '2.25rem', zIndex: 1 }}>
      <TableCell
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <TextField
          // @ts-ignore
          placeholder='set price'
          fullWidth
          size='small'
          value={price}
          onChange={e => setPrice(e.target.value)}
        />
      </TableCell>

      <TableCell
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <TextField
          // @ts-ignore
          placeholder='enter a new line item type (ex. Extra Large Damage + Paint)'
          fullWidth
          size='small'
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </TableCell>

      <TableCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiSelect-select': {
            padding: '.25rem .25rem .25rem .5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <Select fullWidth placeholder='select a type' size='small' value={type} onChange={e => setType(e.target.value)}>
          {/* <MenuItem value='autobody'>autobody</MenuItem>
          <MenuItem value='autoglass'>autoglass</MenuItem>
          <MenuItem value='tires'>tires</MenuItem>
          <MenuItem value='batteries'>batteries</MenuItem> */}
          <MenuItem value='tires'>upcharge</MenuItem>
        </Select>

        <Collapse sx={{ height: '32px ' }} in={showCreateButton} orientation='horizontal'>
          <Button onClick={handleCreateNewLineItem} sx={{ margin: '0rem .5rem' }} size='small' variant='contained'>
            Create
          </Button>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
