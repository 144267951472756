import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { RefetchRegistry } from 'RefetchRegistry'
import { UpdateRefetchRegistry } from 'UpdateRefetchRegistry'

import ShowEdit from './Part/ShowEdit'

const GET_PART = gql`
  query part($id: ID!) {
    part(id: $id) {
      id
      type
      name
      number
      referenceNumber
      status
      price
      cost
      quoteId
      quotePart
      quotePartId
      quotePartsPo
      quoteNumber
      prePaymentRequired
      pickupStatus
      pickupHistory
      createdAt
      removedFromQuoteAt
      orderPlacedAt
      readyForPickupAt
      etaRequestedAt
      etaReceivedAt
      etaBusinessDays
      orderRequestedAt
      canceledAt
      ccLastFour
      isPaid
      partVendorId
      partVendorContact
      invoiceNumber
      refundInitiatedAt
      refundCompletedAt
      refundAmount
      refundedAt
      returnInitiatedAt
      returnedToVendorAt
      returnCompletedAt
      returnAmount
      hasCoreRefund
      coreRefundExpectedAmount
      coreRefundReceivedAmount
      coreRefundInitiatedAt
      coreReturnedToVendorAt
      coreRefundCompletedAt
      coreRefundAmount
      specialOrder
      lostAt
      disputeInitiatedAt
      disputeResolvedAt
      tireConnectData
      tireConnectOrderData
      nagsGlassId
      vendor {
        id
        name
        addressLineOne
        addressLineTwo
        city
        state
        zip
        phone
        paymentNotes
        website
      }
    }
  }
`

const Part = ({ quote, lead }) => {
  const { partId } = useParams()
  const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  const [registerUpdateRefetch, unregisterUpdateRefetch] = useContext(UpdateRefetchRegistry)

  const { loading, error, data, refetch } = useQuery(GET_PART, {
    variables: { id: partId },
  })

  useEffect(() => {
    const key = registerRefetch({ types: ['Part'], refetch })
    return () => unregisterRefetch(key)
  }, [])

  useEffect(() => {
    const key = registerUpdateRefetch({ types: ['Part'], refetch })
    return () => unregisterUpdateRefetch(key)
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return <ShowEdit part={data.part} lead={lead} quote={quote} />
}

export default Part
