import {
  Box,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,
  Paper,
  Button,
  Popover,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { gql, useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { DateRangeHeadCell } from 'BToB/Invoices/BToBInvoice/Components/DateRangeHeadCell'
import { SearchTextField } from 'Recruiting/components/SearchTextField'
import { MoreVertRounded } from '@mui/icons-material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'

const QUERY = gql`
  query JobListingsConnection($filter: JSON, $first: Int, $after: String, $search: JSON, $order: JSON) {
    jobListingsConnection(filter: $filter, first: $first, after: $after, search: $search, order: $order) {
      edges {
        cursor
        node {
          id
          jobTitle
          city
          state
          description
          salaryStart
          salaryEnd
          salaryPeriod
          education
          jobType
          experience
          company
          country
          zipCode
          streetAddress
          email
          openPositions
          createdAt
          expiresAt
          disabledAt
          status
          jobApplications {
            id
            applicant {
              fullName
            }
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

const JobListingsTable = () => {
  const [cursorCeiling, setCursorCeiling] = useState('')
  const [filter, setFilter] = useState({})
  const [search, setSearch] = useState({})
  const [order, setOrder] = useState({})
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)

  const [user] = useContext(UserContext)
  const [anchorEl, setAnchorEl] = useState(null)

  const bearerTokenHeaders = useBearerTokenHeaders()

  const history = useHistory()

  const { loading, error, data, fetchMore, refetch } = useQuery(QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter,
      first: limit,
      after: cursorCeiling,
    },
  })

  const jobListings = data?.jobListingsConnection?.edges?.map((edge: any) => edge.node) || []
  const totalCount = data?.jobListingsConnection?.totalCount || 0
  const endCursor = data?.jobListingsConnection?.pageInfo?.endCursor || 0

  const handleClickToggleJobListingStatus = (jobListingId: any) =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_job_listing_status`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        jobListingId: jobListingId,
        userId: user.id,
      }),
    })
      .then(response => refetch())
      .then(() => setAnchorEl(null))
      .catch(e => {
        alert(e.message)
      })

  const handleClickDuplicate = (jobListingId: any) =>
    window.confirm('Are you sure?') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/duplicate_job_listing`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        jobListingId: jobListingId,
      }),
    })
      .then(response => refetch())
      .then(() => setAnchorEl(null))
      .catch(e => {
        alert(e.message)
      })

  const handleClickDelete = (jobListingId: any) =>
    window.confirm('Are you sure? This cannot be undone') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_job_listing`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        jobListingId: jobListingId,
      }),
    })
      .then(response => refetch())
      .then(() => setAnchorEl(null))
      .catch(e => {
        alert(e.message)
      })

  const handleChangePage = (event: any, wantedPage: any) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: limit,
          after: endCursor,
        },
      })
    }
  }

  const handleChangeRowsPerPage = (event: any) => {
    setLimit(event.target.value)
    setPage(0)
    setCursorCeiling('')
  }

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: limit,
        after: '',
        cursorCeiling,
      },
    })
  }, [search, order, filter, limit])

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <DateRangeHeadCell
                name='created_at'
                filter={filter}
                setFilter={setFilter}
                order={order}
                setOrder={setOrder}
              />
              <TableCell>
                <SearchTextField model='JobListing::Cache' search={search} field='job_title' setSearch={setSearch} />
              </TableCell>
              <TableCell>Applications</TableCell>
              <TableCell>
                <SearchTextField model='JobListing::Cache' search={search} field='city' setSearch={setSearch} />
              </TableCell>
              <TableCell>
                <SearchTextField model='JobListing::Cache' search={search} field='state' setSearch={setSearch} />
              </TableCell>
              <TableCell>
                <SearchTextField model='JobListing::Cache' search={search} field='status' setSearch={setSearch} />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {jobListings?.map(({ id, createdAt, status, jobTitle, city, state, disabledAt, jobApplications }: any) => {
              return (
                <TableRow
                  sx={[
                    { cursor: 'pointer', '&:hover': { background: '#f9f9f9' } },
                    disabledAt && { background: '#fff9f9', '&:hover': { background: '#fff9f9' } },
                    id === anchorEl && { background: 'aliceblue' },
                  ]}
                  key={id}
                  id={`${id}-row`}
                  onClick={() => history.push(`/job-listings/${id}`)}
                >
                  <TableCell>{DateTime.fromISO(createdAt).toFormat('DD t')}</TableCell>
                  <TableCell>{jobTitle}</TableCell>
                  <TableCell>{jobApplications?.length}</TableCell>
                  <TableCell>{city}</TableCell>
                  <TableCell>{state}</TableCell>
                  <TableCell
                    sx={[
                      { fontWeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' },

                      status === 'Active' && { color: 'green' },
                      status === 'Disabled' && { color: 'red' },
                    ]}
                  >
                    {status}
                    <Button
                      id={`${id}-button`}
                      onClick={e => {
                        e.stopPropagation()
                        // @ts-ignore
                        setAnchorEl(id)
                      }}
                      sx={{ ml: '1rem', padding: '0rem' }}
                    >
                      <MoreVertRounded />
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <Popover
            onClose={() => setAnchorEl(null)}
            open={!!anchorEl}
            anchorEl={document?.getElementById(`${anchorEl}-button`)}
          >
            <MenuItem onClick={() => handleClickToggleJobListingStatus(anchorEl)}>Toggle Status</MenuItem>
            <MenuItem onClick={() => handleClickDuplicate(anchorEl)}>Duplicate</MenuItem>

            <Divider />
            <MenuItem onClick={() => handleClickDelete(anchorEl)}>Delete</MenuItem>
          </Popover>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {loading && (
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

export default JobListingsTable
