import { gql, useQuery } from '@apollo/client'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { bearerTokenHeaders } from 'tools'
import { NewQuestionRow } from './NewQuestionRow'
import LaborItemRow from './QuestionRow'

const QUERY = gql`
  query GetForm($parentId: String!, $parentType: String!) {
    form(parentId: $parentId, parentType: $parentType) {
      id
      createdAt
      title
      parentFormId
      questions {
        id
        title
        description
        type
        options
        relativeOrder
        isOptional
      }
    }
  }
`

export const updateOrdering = ({ questionId, referenceQuestionId, action }: any) =>
  fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_form_question_ordering`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      question_id: questionId,
      reference_question_id: referenceQuestionId,
      todo_action: action,
    }),
  })

export const FormCreator = ({ contextType, contextId }: any) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [questions, setQuestions] = useState<any>([])

  const { data, loading, error, refetch } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      parentType: contextType,
      parentId: contextId,
    },
  })

  const createNewForm = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_form`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        parentId: contextId,
        parentType: contextType,
      }),
    })
      .then(() => refetch())
      .catch(err => alert(err))
  }

  const form = data?.form

  const handleclickDelete = () =>
    window.confirm('Are you sure you want to delete this?') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_form`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        formId: form.id,
      }),
    })
      .then(() => refetch())
      .catch(err => alert(err))

  const handleClickUseExisting = () => {
    const formId = window.prompt('Enter the ID of the form you want to copy')
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_form`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        parentId: contextId,
        parentType: contextType,
        parentFormId: formId,
      }),
    })
      .then(() => refetch())
      .catch(err => alert(err))
  }

  useEffect(() => {
    data && form && setQuestions(form?.questions)
    data && !form && setQuestions([])
  }, [data])

  const moveOrderingOfQuestion = ({ question, action }: { question: any; action: string }) => {
    if (action === 'up') {
      const referenceQuestion = questions[questions.indexOf(question) - 1]
      updateOrdering({ questionId: question.id, action, referenceQuestionId: referenceQuestion.id })
      setTimeout(() => refetch(), 500)
    }
    // if (action === 'down') {
    //   const referenceQuestion = questions[questions.indexOf(question) + 1]
    //   updateOrdering({ questionId: question.id, action, referenceQuestionId: referenceQuestion.id })
    //   setTimeout(() => refetch(), 500)
    // }
  }

  return (
    <Box>
      <Dialog
        PaperProps={{
          sx: {
            padding: '1rem',
          },
        }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        {contextType}-{contextId}
      </Dialog>

      <Box sx={{ padding: '1rem' }}>
        {!form && <Button onClick={() => createNewForm()}>Create new form</Button>}
        {!form && <Button onClick={() => handleClickUseExisting()}>Use Existing form </Button>}

        {form && <Typography variant='body1'>Created: {DateTime.fromISO(form?.createdAt).toFormat('DD t')}</Typography>}
        {form?.id && (
          <Box sx={{ display: 'flex' }}>
            <Typography variant='body1'>
              {form?.parentFormId ? 'PARENT ' : ''}FORM ID: {form?.parentFormId ? form?.parentFormId : form?.id}
            </Typography>{' '}
            <button onClick={handleclickDelete}>{form?.parentFormId ? 'Unlink form' : 'Delete form'}</button>
          </Box>
        )}
        <NewQuestionRow formId={form?.parentFormId ? form?.parentFormId : form?.id} refetch={refetch} />

        {questions?.map((item: any, index: number) => (
          <LaborItemRow
            index={index}
            moveOrderingOfQuestion={moveOrderingOfQuestion}
            refetch={refetch}
            data={item}
            key={item.id}
          />
        ))}
      </Box>

      {/* <Button onClick={() => setDialogOpen(true)}>Create new form</Button> */}
    </Box>
  )
}
