import { Dollars } from 'tools'
import { DateTime } from 'luxon'

export const getWeekLaborValue = ({ populatedSchedule, date }) => {
  const startOfWeek = date.startOf('week').startOf('day').minus({ days: 1 })
  const endOfWeek = date.endOf('week').endOf('day')
  const daysThisWeek = populatedSchedule.filter(sched => sched.dateTime > startOfWeek && sched.dateTime < endOfWeek)

  const totalsForDays = daysThisWeek.map(day => {
    const jobsWithVisitNumberOne = day.jobs.filter(job => {
      const visitNumber =
        job.lead.jobs
          .slice()
          .sort((a, b) => (DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1))
          .findIndex(thisJob => thisJob.id === job.id) + 1
      if (visitNumber !== 1) {
        return null
      } else {
        return job
      }
    })

    const notFullyPaidFollowups = day.jobs.filter(job => {
      const visitNumber =
        job.lead.jobs
          .slice()
          .sort((a, b) => (DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 1 : -1))
          .findIndex(thisJob => thisJob.id === job.id) + 1
      if (visitNumber !== 1) {
        if (job.quote.paymentStatus !== 'fully_paid') {
          return job
        }
      } else {
        return null
      }
    })

    const quotesForThoseJobs = jobsWithVisitNumberOne.map(job => job.quote)
    const notFullyPaidFollowupsQuotes = notFullyPaidFollowups.map(job => job.quote)

    const laborTotalForFollowups = notFullyPaidFollowupsQuotes.reduce(
      (acc, item) => acc + item.laborSumAfterAllDiscounts,
      0
    )

    const techChargesTotalForFollowups = notFullyPaidFollowupsQuotes.reduce(
      (acc, item) => acc + item.technicianChargesSum,
      0
    )

    const allLaborTotal = quotesForThoseJobs.reduce((acc, item) => acc + item.laborSumAfterAllDiscounts, 0)
    const techChargesTotal = quotesForThoseJobs.reduce((acc, item) => acc + item.technicianChargesSum, 0)

    const total = allLaborTotal + techChargesTotal + laborTotalForFollowups + techChargesTotalForFollowups
    return total
  })

  const totalForWeek = totalsForDays.reduce((acc, item) => acc + item, 0)

  return totalForWeek
}

export const WeekLaborValue = ({ populatedSchedule, date }) => {
  const totalForWeek = getWeekLaborValue({ populatedSchedule, date })

  return <Dollars value={totalForWeek} />
}
