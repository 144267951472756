import {
  Grid,
  Grow,
  Collapse,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Paper,
} from '@mui/material'
import { useQuery, gql } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { PriceInput, Dollars } from 'tools'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { RefreshRounded } from '@mui/icons-material'
import { NewLineItem } from './Components/NewLineItem'
import { EditLineItem } from './Components/EditLineItem'
import { ShowLineItem } from './Components/ShowLineItem'
import { PayoutOverrideTotal } from './Components/PayoutOverrideTotal'
import { CommissionPercentOverrideTotal } from './Components/CommissionPercentOverrideTotal'

const PRICE_CATEGORIES = gql`
  query BToBPriceCategories($dealerId: String) {
    bToBPriceCategories(dealerId: $dealerId) {
      id
      createdAt
      name
      isCustom
      pricePoints {
        id
        price
        commissionAmount
        pointAmount
        customPricePoint {
          id
          price
          commissionAmount
        }
        panelGroup {
          id
          name
        }
      }
      categoriesWithoutPricePoints {
        id
        name
      }
    }
  }
`

const PANELS_QUERY = gql`
  query BToBPanels {
    bToBPanels {
      id
      name
      side
      panel
      type
      panelGroupId
      inheritPrice
      isCustom
      panelGroup {
        name
      }
    }
  }
`

const PANEL_GROUPS = gql`
  query bToBPanelGroups {
    bToBPanelGroups {
      id
      name
      isCustom
    }
  }
`

export const LineItemsSection = ({ invoice }) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const initialDiscountPercent = invoice.discountPercent || 0
  const [editingDiscountPercent, setEditingDiscountPercent] = useState(initialDiscountPercent)
  const [editingLineItems, setEditingLineItems] = useState(false)
  const [waiting, setWaiting] = useState(false)

  const {
    loading: loadingPanels,
    error: errorPanels,
    data: panelsData,
    refetch: refetchPanels,
  } = useQuery(PANELS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const {
    loading: loadingPanelGroups,
    error: errorPanelGroups,
    data: panelGroupsData,
    refetch: refetchPanelGroups,
  } = useQuery(PANEL_GROUPS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: categoriesdata,
    refetch: refetchCategories,
  } = useQuery(PRICE_CATEGORIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      dealerId: invoice.dealer.id,
    },
  })

  if (errorCategories || errorPanels || errorPanelGroups) return <div>Error!</div>

  const priceCategories = categoriesdata?.bToBPriceCategories || []
  const panelsList = panelsData?.bToBPanels || []
  const panelGroups = panelGroupsData?.bToBPanelGroups || []

  const lineItems = []

  !loadingCategories &&
    !loadingPanels &&
    !loadingPanelGroups &&
    priceCategories.forEach(pricingCategory => {
      pricingCategory.pricePoints.forEach(pricePoint => {
        const panelGroupForPricePoint = panelGroups.find(panelGroup => panelGroup.id === pricePoint.panelGroup.id)
        const panelsInCategory = panelsList.filter(panel => panel.panelGroupId === panelGroupForPricePoint.id)

        panelsInCategory.forEach(panel => {
          let name = `${panel.panel} - ${pricingCategory.name}`

          if (panel.side) {
            if (panel.side === 'front' || panel.side === 'rear') {
              name = `${panel.panel} - ${panel.side} Side - ${pricingCategory.name}`
            } else if (panel.side !== '') {
              name = `${panel.panel} - ${panel.side} - ${pricingCategory.name}`
            } else {
              name = `${panel.panel} - ${pricingCategory.name}`
            }
          }

          if (pricePoint?.customPricePoint) {
            if (pricePoint?.customPricePoint.price > 0) {
              lineItems.push({
                name: name,
                panelType: panel.panelType,
                panel: panel.panel,
                side: panel.side,
                type: pricingCategory.name,
                panelGroupId: panelGroupForPricePoint.id,
                price: pricePoint.customPricePoint?.price || pricePoint.price,
                panelGroup: {
                  name: panelGroupForPricePoint.name,
                },
                pricePoint: pricePoint,
                customPricePoint: {
                  ...pricePoint.customPricePoint,
                },
                pricingCategory: pricingCategory,
              })
            }
          } else {
            lineItems.push({
              name: name,
              panelType: panel.panelType,
              panel: panel.panel,
              side: panel.side,
              type: pricingCategory.name,
              panelGroupId: panelGroupForPricePoint.id,
              price: pricePoint.price,
              panelGroup: {
                name: panelGroupForPricePoint.name,
              },
              pricePoint: pricePoint,
              pricingCategory: pricingCategory,
            })
          }
        })
      })
    })

  if (lineItems.length === 0) return 'loading...'

  const handleSaveDiscountPercent = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_invoice_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: invoice.id,
        updatedAttributes: {
          discount_percent: editingDiscountPercent,
        },
        actorType: 'csr',
        actorId: user.id,
      }),
    }).then(res => res.ok || window.alert('Error'))

  const handleClickRefreshLineItems = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/refresh_b_to_b_invoice_line_items`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        b_to_b_invoice_id: invoice.id,
      }),
    }).catch(() => window.alert('Error'))
  }

  return (
    <Grid container sx={{ border: '1px solid #ddd', borderRadius: '12px' }}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          fontWeight: 700,
          fontSize: '14px',
          padding: '0.5rem 1rem',
          alignItems: 'center',
        }}
      >
        Line Items
        {!invoice.payoutId && (
          <Button
            startIcon={<RefreshRounded />}
            onClick={handleClickRefreshLineItems}
            size='small'
            variant='outlined'
            sx={{ ml: '.5rem' }}
          >
            Refresh
          </Button>
        )}
        <Grow in={!invoice.payoutData || user.roles.includes('admin')}>
          <Button
            color='primary'
            onClick={() => setEditingLineItems(!editingLineItems)}
            sx={{ ml: 'auto', padding: '0.125rem' }}
          >
            [ Edit{editingLineItems && 'ing'} ]
          </Button>
        </Grow>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          padding: '0.5rem',
        }}
      >
        <Collapse in={(!invoice.payoutData || user.roles.includes('admin')) && editingLineItems}>
          <NewLineItem lineItems={lineItems} invoice={invoice} />
        </Collapse>

        {invoice.lineItems.length > 0 && (
          <Grid
            container
            sx={{
              border: '1px solid #d8dBdd',
              mt: '0.25rem',
              background: '#F8FBFF',
              borderRadius: '8px',
            }}
          >
            <Grid item xs={12} sx={{ padding: '0 0.25rem' }}>
              {invoice.lineItems.map((item, i) =>
                editingLineItems ? (
                  <EditLineItem key={item.id} lineItem={item} lineItems={lineItems} invoice={invoice} />
                ) : (
                  <ShowLineItem key={item.id} lineItem={item} index={i} />
                )
              )}
            </Grid>
          </Grid>
        )}

        <Collapse in={(!invoice.payoutData || user.roles.includes('admin')) && editingLineItems}>
          <Grid container sx={{ padding: '0.5rem 0rem' }}>
            <Grid item xs={10}>
              <TextField
                fullWidth
                variant='outlined'
                size='small'
                label='Discount Percent'
                type='number'
                value={editingDiscountPercent}
                onChange={e => setEditingDiscountPercent(parseFloat(e.target.value))}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={editingDiscountPercent === initialDiscountPercent}
                color='primary'
                onClick={handleSaveDiscountPercent}
              >
                save
              </Button>
            </Grid>
          </Grid>
        </Collapse>

        <Collapse in={!editingLineItems}>
          {!!invoice.discountPercent && (
            <Grid
              item
              xs={12}
              sx={{
                padding: '0.25rem 0.5rem 0rem 0rem',
                textAlign: 'right',
                ml: 'auto',
              }}
            >
              Discount (-{invoice.discountPercent}%): -${invoice.discountPercentTotal.toFixed(2)}
            </Grid>
          )}
        </Collapse>

        <Grid container sx={{ padding: '1rem .5rem' }}>
          <Grid item xs={6}>
            <PayoutOverrideTotal invoice={invoice} />
            <CommissionPercentOverrideTotal invoice={invoice} />
          </Grid>
          <Grid item xs={6} sx={{ '& b': { display: 'block', textAlign: 'right' } }}>
            {!!invoice.ccFee && (
              <b>
                (SQUARE FEE: <Dollars value={invoice.ccFee} />)
              </b>
            )}
            <b>
              Projected Payout: <Dollars value={invoice.projectedPayout} />
            </b>

            <b>Grand total: ${(invoice.grandTotal || invoice.lineItemsSum).toFixed(2)}</b>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
