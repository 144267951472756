// Tooltip is common tooltip displayed by all charts
//
// formatters is a map of metricKey => func(metricVal) string
// labels is a map of metricKey => string
// colors is a map of metricKey => string HTML color code

import { Paper, Grid, Typography } from '@mui/material'

const TooltipContent = (formatters, labels, colors) => ({ active, payload, label }) => {
  if (active && payload && payload.length > 1) {
    return (
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant='h5'>
              {label}
            </Typography>
          </Grid>
          {payload.map(payload =>
            <Grid item key={payload.dataKey} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} style={{ textAlign: 'right', color: colors[payload.dataKey] }}>
                  {labels[payload.dataKey]}
                </Grid>
                <Grid item xs={6}>
                  {formatters[payload.dataKey](payload.value)}
                </Grid>
              </Grid>
            </Grid>)}
        </Grid>
      </Paper>
    )
  }
  return null
}

export default TooltipContent
