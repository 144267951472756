export const markets = [
  'Charlotte',
  'Tampa Bay',
  'Orlando',
  'Raleigh',
  'San Francisco & Bay Area',
  'Bennettsville',
  'Sacramento',
  'San Antonio',
  'Houston',
  'Atlanta',
  'Austin',
  'Las Vegas',
  'Phoenix',
  'Dallas',
  'Los Angeles',
  'Miami',
  'San Diego',
  'Chicago'
]

export const partStatuses = [
  'eta_requested',
  'eta_received',
  'order_requested',
  'order_placed',
  'ready_for_pickup',
  'picked_up',
  'not_picked_up',
  'removed',
  'canceled',
  'return_initiated',
  'part_returned',
  'returned',
  'refund_initiated',
  'refunded',
  'core_refund_initiated',
  'core_returned',
  'core_refunded',
  'lost',
  'pending_dispute',
  'disputed'
]

export const csrPartStatuses = [
  'order_placed',
  'paid',
  'canceled',
  'refunded',
  'pending_refund',
  'pending_return',
  'lost',
  'lost_special_order',
  'pending_dispute',
  'disputed',
  'pending deduction', // todo replace space with _ ?
  'deducted'
]

export const eliteVehicleMakes = [
  'alpha_romeo',
  'aston_martin',
  // 'audi',
  'bentley',
  // 'bmw',
  // 'cadillac',
  'ferrari',
  'jaguar',
  'lamborghini',
  'land_rover',
  'maserati',
  'maybach',
  'mclaren',
  // 'mercedes_benz',
  'porsche',
  'rolls_royce',
  'tesla'
]
