import { Box, Typography, Button, Breadcrumbs, Grid } from '@mui/material'
import { useParams, Link } from 'react-router-dom'
import Notes from 'components/Notes'
import { useInterview } from 'hooks/recruiting/interviews/useInterview'
import StreamDump from 'components/StreamDump'
import { useContext, useEffect } from 'react'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { DateTime } from 'luxon'

const InterviewView = () => {
  const params: any = useParams()
  const interviewId = params?.interviewId
  const { interview, cancel } = useInterview(interviewId)

  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)

  useEffect(() => {
    const key = registerTaskTarget({ targetType: 'Interview', targetId: interviewId })
    return () => unregisterTaskTarget(key)
  }, [])

  if (!interviewId || !interview) return null

  const breadCrumbs = [
    <Link key='listing' to={`/recruiting/job-listings/${interview?.jobApplication?.jobListing?.id || ''}`}>
      {' '}
      Job listing{' '}
    </Link>,
    <Link key='application' to={`/applications/${interview?.jobApplication?.id || 'asdf'}`}>
      {' '}
      Application{' '}
    </Link>,
    <Typography key='interview'> Interview </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs sx={{ display: 'block', padding: '.5rem' }} separator='›'>
        {breadCrumbs}
      </Breadcrumbs>
      <ul>
        <li>Selected language: {interview?.selectedLanguage}</li>
        <li>Start At: {interview?.startDatetime && DateTime.fromISO(interview.startDatetime).toFormat('DD t')}</li>
        <li>Created At: {interview?.createdAt}</li>
        {interview?.canceledAt && <li>Canceled At: {interview?.canceledAt}</li>}
      </ul>

      <Box sx={{ display: 'flex', gap: 1, padding: 2 }}>
        <Button onClick={cancel} disabled={!!interview?.canceledAt} variant='contained'>
          Cancel
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Notes
            headerText='Notes from the application'
            objectType='application'
            objectId={interview?.jobApplication?.id}
            descendentsOnly={false}
            noteContainerStyle={{
              background: '#fff',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Notes
            descendentsOnly={false}
            noteContainerStyle={{
              background: '#fff',
            }}
            headerText='Interview notes'
            objectType='interview'
            objectId={interviewId}
          />
        </Grid>
      </Grid>

      <StreamDump stream={`Interview$${interview?.id}`} />
    </Box>
  )
}

export default InterviewView
