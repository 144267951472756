import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'

const Index = () => {
  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4'>
            Charts
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Link to='/charts/metrics'>
            Metrics
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link to='/charts/quotes'>
            All Quotes by Fully Paid Date
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link to='/charts/technicians'>
            Fully Paid Quotes By Technicians
          </Link>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Index
