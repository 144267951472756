import { useContext, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { Box, Button } from '@mui/material'
import { DateTime } from 'luxon'
import AvailabilityMap from './AvailabilityMap'
import ChangeTechnicianDialog from './components/ChangeTechnicanDialog'
import { UserContext } from 'UserStore'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import Breadcrumbs from 'components/Breadcrumbs'

const APPOINTMENT_QUERY = gql`
  query appointment($id: ID!) {
    appointment(id: $id) {
      id
      createdAt
      approvedAt
      startDatetime
      endDatetime
      canceledAt
      technicianId
      locationVerifiedAt
      quote {
        id
        quoteGrandTotal
        parts {
          id
          name
          tireConnectData
          nagsGlassId
        }
        approvedTechnicians {
          id
          name
          email
          phone
          addressLineOne
          addressLineTwo
          city
          state
          zip
          lat
          lng
          note
          replacements
          availability
          offdays
          active
          note
        }
      }
      approvedBy {
        id
        firstName
        lastName
      }
      locationVerifiedBy {
        id
        firstName
        lastName
      }
      lead {
        id
        name
        addressLineOne
        addressLineTwo
        city
        state
        zip
        repairLocation
        emojis
      }
      technician {
        id
        name
        lat
        lng
      }
    }
  }
`

const optParseUTC = { zone: 'utc' }

const Appointment = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)
  const { appointmentId } = useParams()

  useEffect(() => {
    const key = registerTaskTarget({ targetType: 'Appointment', targetId: appointmentId })
    return () => unregisterTaskTarget(key)
  }, [])

  const { loading, error, data, refetch } = useQuery(APPOINTMENT_QUERY, { variables: { id: appointmentId } })

  const handleClickApprove = () =>
    window.confirm('Approve this appointment?') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/approve_appointment`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        appointment_id: appointmentId,
      }),
    }).then(res => (res.ok ? refetch() : alert('error!')))

  const handleClickCancel = () =>
    window.confirm('Cancel this appointment?') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/cancel_appointment_by_csr`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        appointment_id: appointmentId,
      }),
    }).then(res => (res.ok ? refetch() : alert('error!')))

  const handleClickLocationVerified = () =>
    window.confirm('You have verified the location on a map?') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/verify_appointment_location`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        appointment_id: appointmentId,
      }),
    }).then(res => (res.ok ? refetch() : alert('error!')))

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const appointment = data.appointment

  return (
    <Box>
      <Breadcrumbs lead={appointment.lead} quote={appointment.quote} appointment={appointment} />
      <ul>
        <li>
          <span>technician: </span>
          <Link to={`/technicians/${appointment.technician.id}`}>{appointment.technician.name}</Link>
        </li>
        <li>
          <span> appointment date: </span>
          {DateTime.fromISO(appointment.startDatetime, optParseUTC).toLocaleString('yyyy-LL-dd')}
        </li>
        <li>
          <span>start time: </span>
          {DateTime.fromISO(appointment.startDatetime, optParseUTC).toLocaleString(DateTime.TIME_SIMPLE)}
        </li>
        <li>
          <span>end time: </span>
          {DateTime.fromISO(appointment.endDatetime, optParseUTC).toLocaleString(DateTime.TIME_SIMPLE)}
        </li>

        {appointment.approvedAt && (
          <li>
            <span>approved at: </span>
            <>{DateTime.fromISO(appointment.approvedAt).toLocaleString(DateTime.DATETIME_MED)}</>
            <span>
              {' '}
              by: {appointment.approvedBy?.firstName} {appointment.approvedBy?.lastName}
            </span>
          </li>
        )}
        {appointment.locationVerifiedAt && (
          <li>
            <span>location was verified at: </span>
            <>{DateTime.fromISO(appointment.locationVerifiedAt).toLocaleString(DateTime.DATETIME_MED)}</>
            <span>
              {' '}
              by: {appointment.locationVerifiedBy?.firstName} {appointment.locationVerifiedBy?.lastName}
            </span>
          </li>
        )}
      </ul>

      <ChangeTechnicianDialog quote={appointment.quote} appointment={appointment} refetch={refetch} />
      <Button
        sx={{ ml: '.5rem' }}
        size='small'
        color='error'
        variant='outlined'
        disabled={!!appointment.approvedAt || appointment.canceledAt}
        onClick={handleClickCancel}
      >
        reject
      </Button>
      <Button
        sx={{ ml: '.5rem' }}
        variant='contained'
        size='small'
        disabled={!!appointment.approvedAt || appointment.canceledAt || !appointment.locationVerifiedAt}
        onClick={handleClickApprove}
      >
        approve
      </Button>
      <br />
      <Button
        sx={{ mt: '.5rem' }}
        variant='contained'
        disabled={!!appointment.locationVerifiedAt || !!appointment.approvedAt}
        onClick={handleClickLocationVerified}
      >
        location has been verified
      </Button>
      {appointment.canceledAt && <b style={{ color: 'red', display: 'block' }}>this appointment has been cancelled </b>}
      {appointment.approvedAt && (
        <b style={{ color: 'red', display: 'block' }}>this appointment had already been approved </b>
      )}
      <hr />
      <Box>
        <AvailabilityMap
          assignedTechnician={appointment.technician}
          quote={appointment.quote}
          technicians={appointment.quote.approvedTechnicians}
          lead={appointment.lead}
        />
      </Box>
    </Box>
  )
}

export default Appointment
