import React, { useState, useContext, useEffect } from 'react'
import { UserContext } from 'UserStore'
import { styled } from '@mui/material/styles'
import { Dialog, DialogContent, DialogActions, Button, Box, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { Dollars, PriceInput } from 'tools'

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2)
}))

const AddWriteoffAmountDialog = ({ quote, isOpen, onClose }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required('Amount is required.')
      .test({
        name: 'min',
        message: 'Amount must be greater than zero.',
        test: value => value > 0
      })
      .test({
        name: 'max',
        message: 'Writeoff amount cannot cause payments to exceed quote total.',
        test: value => value <= Number(quote.balanceAmountDue)
      })
  })

  const onSubmit = data => {
    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_writeoff_amount`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        quote_id: quote.id,
        amount: data.amount
      })
    })
      .then(res => res.ok ? onClose() : alert('error'))
      .finally(() => setWaiting(false))
  }

  const initialValues = {
    amount: 0
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit
  })

  useEffect(() => {
    if (!isOpen) {
      setWaiting(false)
    } else {
      formik.setValues(initialValues)
    }
  }, [isOpen])

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      onClose={onClose}
      aria-labelledby='add-writeoff-amount'
      open={isOpen}
    >
      <DialogContent sx={{ p: 3 }} dividers>
        <Typography variant='h6'>
          Add Writeoff Amount
        </Typography>

        <Box>Enter only and exactly the writeoff amount, something like "19.99"'</Box>
        <Box style={{ display: 'flex', flexDirection: 'column' }} sx={{ mt: 2 }}>
          <div>
            <b>Quote Total:</b> <Dollars value={quote.postTaxGrandTotal} />
          </div>
          <div>
            <b>Amount Paid:</b> <Dollars value={quote.groupedPayments.reduce((sum, payment) => sum + payment.amountBeforeTip, 0)} />
          </div>
          <div>
            <b>Balance Due:</b> <Dollars value={quote.balanceAmountDue} />
          </div>
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            required
            size='small'
            name='amount'
            label='Writeoff Amount'
            error={!!(formik.dirty && formik.errors.amount)}
            helperText={formik.dirty && formik.errors.amount}
            value={formik.values.amount}
            onChange={evt => formik.setFieldValue('amount', Number(evt.target.value))}
            InputProps={{
              inputComponent: PriceInput
            }}
          />
        </Box>
      </DialogContent>
      <CustomDialogActions>
        <Button
          variant='outlined'
          color='primary'
          disabled={waiting || !formik.isValid}
          onClick={formik.handleSubmit}
        >
          {!waiting ? 'Add' : 'Saving'}
        </Button>
      </CustomDialogActions>
    </Dialog>
  )
}

export default AddWriteoffAmountDialog
