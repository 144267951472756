import { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { GlobalContext } from 'GlobalStore'
import { Dollars, keysToCamel } from 'tools'
import { makeStyles } from '@mui/styles'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { LaunchRounded } from '@mui/icons-material'
import CompactReadOnlyNotes from 'components/CompactReadOnlyNotes'
import { paymentStatusLabels } from '../data/styles'

const JOBS_QUERY = gql`
  query jobs($date: ISO8601Date!) {
    jobsForDailyDashboard(date: $date) {
      id
      leadId
      quoteId
      startDatetime
      endDatetime
      canceledAt
      rescheduledAt
      technicianId
      rescheduleHistory
      canceledAt
      rescheduleHistory
      rescheduledAt
      isActive
      createdAt
      heldForReschedulingAt
      cancellationReason
      lead {
        id
        name
        market
        state
        emojis
      }
      quote {
        id
        leadId
        assignedTechnicianId
        payments
        groupedPayments {
          id
          createdAt
          totalAmount
          tipAmount
          paymentType
          refundedAmount
          receiptUrl
          amountBeforeTip
          processingFeeAmount
          updatedAt
          origin
          paymentType
          sourceType
          squarePaymentId
          photoUrls
        }
        balanceAmountDue
        postTaxGrandTotal
        paymentStatus
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: '1em',
    marginRight: '1em',
    width: 200,
  },
}))

const groupJobsByQuoteId = jobs =>
  jobs.reduce(
    (acc, job) => ({
      ...acc,
      ...{
        [job.quoteId]: [...(acc[job.quoteId] || []), job],
      },
    }),
    {}
  )

const Today = () => {
  const [pacificDay] = useState(
    DateTime.fromISO(DateTime.local().toISODate(), {
      zone: 'America/Los_Angeles',
    })
  )
  const [utcDay, setUtcDay] = useState(pacificDay.setZone('utc'))
  const [expandAll, setExpandAll] = useState(false)
  const [expandAllActive, setExpandAllActive] = useState(false)
  const history = useHistory()

  const paymentsFromToday = quote =>
    quote.groupedPayments.filter(
      payment =>
        DateTime.fromISO(payment.createdAt, {
          zone: 'America/Los_Angeles',
        }) >= utcDay.setZone('America/Los_Angeles').startOf('day')
    )

  const paymentsBeforeToday = quote => {
    const value = quote.groupedPayments.filter(
      payment =>
        DateTime.fromISO(payment.createdAt, {
          zone: 'America/Los_Angeles',
        }) < utcDay.setZone('America/Los_Angeles').startOf('day')
    )
    return value
  }

  const paymentsSumFromToday = quote =>
    paymentsFromToday(quote).reduce((acc, payment) => acc + Number(payment.amountBeforeTip), 0)

  const paymentsSumBeforeToday = quote =>
    paymentsBeforeToday(quote).reduce((acc, payment) => acc + Number(payment.amountBeforeTip), 0)

  const dueAtDayStart = quote => Number(quote.postTaxGrandTotal) - paymentsSumBeforeToday(quote, utcDay)

  const leftUncollectedToday = quote => dueAtDayStart(quote) - paymentsSumFromToday(quote)
  const collectedToday = quote => paymentsSumFromToday(quote)

  const { loading, error, data } = useQuery(JOBS_QUERY, {
    variables: { date: utcDay.toISODate() },
  })
  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const jobsForDailyDashboard = keysToCamel(data.jobsForDailyDashboard)

  const jobsForToday = jobsForDailyDashboard
    .filter(job => {
      const startDatetime = DateTime.fromISO(job.startDatetime)

      return startDatetime >= utcDay.startOf('day') && startDatetime <= utcDay.endOf('day')
    })
    .sort((a, b) => (DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 0 : -1))

  const jobsRescheduledAwayFromTodayToday = jobsForDailyDashboard
    .filter(job => {
      const startDatetime = DateTime.fromISO(job.startDatetime)

      const isOnScheduleToday =
        startDatetime && startDatetime >= utcDay.startOf('day') && startDatetime < utcDay.endOf('day')

      const heldDateTime = job.heldForReschedulingAt && DateTime.fromISO(job.heldForReschedulingAt)

      // disclude it if it's on the calendar today
      // unless heldForReschedulingAt
      // THIS will not be comprehended at first glance
      if (
        isOnScheduleToday &&
        !(heldDateTime && heldDateTime >= utcDay.startOf('day') && heldDateTime < utcDay.endOf('day'))
      ) {
        return false
      }

      // include it if its "heldForReschedulingAt" == today
      const wasHeldForReschedulingForFutureToday =
        heldDateTime && heldDateTime >= utcDay.startOf('day') && heldDateTime < utcDay.endOf('day')

      if (wasHeldForReschedulingForFutureToday) {
        return true
      }

      // include it if its "rescheduledAt" == today
      const rescheduledAtDateTime = job.rescheduledAt && DateTime.fromISO(job.rescheduledAt)
      const wasRescheduledToday =
        rescheduledAtDateTime &&
        rescheduledAtDateTime >= utcDay.startOf('day') &&
        rescheduledAtDateTime < utcDay.endOf('day')

      if (wasRescheduledToday) {
        return true
      }

      return false
    })
    .sort((a, b) => (DateTime.fromISO(a.startDatetime) > DateTime.fromISO(b.startDatetime) ? 0 : -1))

  const active = groupJobsByQuoteId(jobsForToday.filter(job => job.isActive))

  const rescheduled = groupJobsByQuoteId(jobsRescheduledAwayFromTodayToday)

  const rescheduledAndNotOnActiveList = Object.keys(rescheduled).reduce(
    (acc, quoteId) =>
      (active[quoteId] && acc) || {
        ...acc,
        [quoteId]: rescheduled[quoteId],
      },
    []
  )

  const canceledToday = jobsForDailyDashboard.filter(job => {
    const canceledDateTime = job.canceledAt && DateTime.fromISO(job.canceledAt)

    const wasCanceledToday =
      canceledDateTime && canceledDateTime >= utcDay.startOf('day') && canceledDateTime < utcDay.endOf('day')

    if (wasCanceledToday) {
      return true
    }

    return false
  })

  const canceled = groupJobsByQuoteId(canceledToday)

  const canceledAndNotOnActiveList = Object.keys(canceled).reduce(
    (acc, quoteId) =>
      (active[quoteId] && acc) || {
        ...acc,
        [quoteId]: canceled[quoteId],
      },
    []
  )

  const handleChangedDay = e => setUtcDay(DateTime.fromISO(e.target.value, { zone: 'utc' }))

  const QuoteRow = ({ quote, jobs, preExpanded, includeCancellationReason }) => {
    const classes = quoteRowStyles()
    const [expanded, setExpanded] = useState(preExpanded)
    const [global] = useContext(GlobalContext)

    const lead = jobs[0].lead
    const technician = global.technicians.find(tech => tech.id === quote.assignedTechnicianId)

    const cancellationReason = includeCancellationReason && jobs.find(job => job.cancellationReason)?.cancellationReason

    const cancelledJob = jobs.find(job => job.canceledAt)
    const technicianForCancelledJob = cancelledJob?.technicianId
    const cancelledTechnician = global.technicians.find(tech => tech.id === technicianForCancelledJob)

    return (
      <>
        <TableRow style={{ cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
          <TableCell align='right'>
            {includeCancellationReason ? (
              <>
                {cancelledTechnician && (
                  <Link to={`/technicians/${cancelledTechnician.id}`}>
                    {cancelledTechnician.firstName} {cancelledTechnician.lastName}
                  </Link>
                )}
              </>
            ) : (
              <>
                {technician && (
                  <Link to={`/technicians/${technician.id}`}>
                    {technician.firstName} {technician.lastName}
                  </Link>
                )}
              </>
            )}
          </TableCell>
          <TableCell align='right'>
            <Link to={`/leads/${lead.id}/quotes/${quote.id}`}>
              {lead?.emojis?.map((emoji, index) => (
                <em-emoji set='apple' key={index} shortcodes={emoji}></em-emoji>
              ))}&nbsp;
              {lead.name}
            </Link>
          </TableCell>
          <TableCell align='right'>
            <Dollars value={quote.postTaxGrandTotal} />
          </TableCell>
          <TableCell align='right'>{lead.state}</TableCell>
          <TableCell align='right'>{lead.market}</TableCell>
          <TableCell align='right'>
            <Dollars value={dueAtDayStart(quote)} />
          </TableCell>
          <TableCell align='right'>
            <Dollars value={leftUncollectedToday(quote)} />
          </TableCell>
          <TableCell align='right'>
            <Dollars value={collectedToday(quote)} />
          </TableCell>
          <TableCell align='right'>
            <span className={classes.paymentStatus + ` ${quote.paymentStatus}`}>{quote.paymentStatus}</span>
          </TableCell>

          {includeCancellationReason && <TableCell align='right'>{cancellationReason}</TableCell>}
        </TableRow>

        {expanded && (
          <TableRow>
            <TableCell colSpan={9}>
              <CompactReadOnlyNotes parentType='lead' parentId={quote.leadId} />
            </TableCell>
          </TableRow>
        )}
      </>
    )
  }

  const SumRow = ({ data, includeCancellationReason }) => {
    const dueAtDayStartSum = Object.keys(data)
      .map(quoteId => data[quoteId][0])
      .reduce((acc, job) => acc + dueAtDayStart(job.quote), 0)

    const paymentsSumFromTodaySum = Object.keys(data)
      .map(quoteId => data[quoteId][0])
      .reduce((acc, job) => acc + paymentsSumFromToday(job.quote), 0)

    const leftUncollectedTodaySum = Object.keys(data)
      .map(quoteId => data[quoteId][0])
      .reduce((acc, job) => acc + leftUncollectedToday(job.quote), 0)

    return (
      <TableRow style={{ backgroundColor: 'darkgray' }}>
        <TableCell colSpan={5}>Sums</TableCell>
        <TableCell align='right'>
          <Dollars value={dueAtDayStartSum} />
        </TableCell>
        <TableCell align='right'>
          <Dollars value={leftUncollectedTodaySum} />
        </TableCell>
        <TableCell align='right'>
          <Dollars value={paymentsSumFromTodaySum} />
        </TableCell>
        <TableCell />
        {includeCancellationReason && <TableCell />}
      </TableRow>
    )
  }

  return (
    <>
      <TextField label='Day' size='small' type='date' value={utcDay.toISODate()} onChange={handleChangedDay} />

      {Object.keys(active).length > 0 && (
        <>
          <Typography style={{ margin: '1em' }}>
            Active &nbsp;&nbsp;
            <Button variant='outlined' onClick={() => setExpandAllActive(!expandAllActive)}>
              toggle expand all
            </Button>
            <Button
              sx={{
                ml: '1rem',
                '& svg': {
                  ml: '.5rem',
                  fontSize: '18px',
                },
              }}
              variant='outlined'
              onClick={() => history.push('/jobs')}
            >
              See all jobs <LaunchRounded />
            </Button>
          </Typography>

          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align='right'>Technician</TableCell>
                  <TableCell align='right'>Quote</TableCell>
                  <TableCell align='right'>Total Quoted amount</TableCell>
                  <TableCell align='right'>State</TableCell>
                  <TableCell align='right'>Market</TableCell>
                  <TableCell align='right'>Due at day start</TableCell>
                  <TableCell align='right'>Not collected today</TableCell>
                  <TableCell align='right'>Collected today</TableCell>
                  <TableCell align='right'>Payment Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(active).map(quoteId => (
                  <QuoteRow quote={active[quoteId][0].quote} jobs={active[quoteId]} preExpanded={expandAllActive} />
                ))}
                <SumRow data={active} />
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <br />
      <Button variant='outlined' onClick={() => setExpandAll(!expandAll)}>
        toggle expand below
      </Button>

      {Object.keys(rescheduledAndNotOnActiveList).length > 0 && (
        <>
          <Typography style={{ margin: '1em' }}>Rescheduled</Typography>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align='right'>Technician</TableCell>
                  <TableCell align='right'>Quote</TableCell>
                  <TableCell align='right'>Total Quoted amount</TableCell>
                  <TableCell align='right'>State</TableCell>
                  <TableCell align='right'>Market</TableCell>
                  <TableCell align='right'>Due at day start</TableCell>
                  <TableCell align='right'>Not collected today</TableCell>
                  <TableCell align='right'>Collected today</TableCell>
                  <TableCell align='right'>Payment Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(rescheduledAndNotOnActiveList).map(quoteId => (
                  <QuoteRow
                    quote={rescheduledAndNotOnActiveList[quoteId][0].quote}
                    jobs={rescheduledAndNotOnActiveList[quoteId]}
                    preExpanded={expandAll}
                  />
                ))}
                <SumRow data={rescheduledAndNotOnActiveList} />
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {Object.keys(canceledAndNotOnActiveList).length > 0 && (
        <>
          <Typography style={{ margin: '1em' }}>Canceled</Typography>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align='right'>Technician</TableCell>
                  <TableCell align='right'>Quote</TableCell>
                  <TableCell align='right'>Total Quoted amount</TableCell>
                  <TableCell align='right'>State</TableCell>
                  <TableCell align='right'>Market</TableCell>
                  <TableCell align='right'>Due at day start</TableCell>
                  <TableCell align='right'>Not collected today</TableCell>
                  <TableCell align='right'>Collected today</TableCell>
                  <TableCell align='right'>Payment Status</TableCell>
                  <TableCell align='right'>Cancellation reason</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(canceledAndNotOnActiveList).map(quoteId => (
                  <QuoteRow
                    includeCancellationReason
                    preExpanded={expandAll}
                    quote={canceledAndNotOnActiveList[quoteId][0].quote}
                    jobs={canceledAndNotOnActiveList[quoteId]}
                  />
                ))}
                <SumRow includeCancellationReason data={canceledAndNotOnActiveList} />
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}

const quoteRowStyles = makeStyles(theme => ({
  row: {
    '& td': {
      fontWeight: 'bold',
      backgroundColor: '#e1e1e1',
    },
  },
  paymentStatus: {
    ...paymentStatusLabels,
  },
}))

export default Today
