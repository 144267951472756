import { useQuery } from '@apollo/client'

import { api } from 'utils/axios'
import { useCurrentUser } from 'hooks/user/useUser/useUser'

import { GET_JOB_LISTING, getJobListingReq, getJobListingRes } from './query'

export const useJobListing = (id?: string) => {
  const { user } = useCurrentUser()
  const { data, refetch } = useQuery<getJobListingRes, getJobListingReq>(GET_JOB_LISTING, {
    variables: { id },
    skip: !id,
  })

  const enable_job_listing = () => {
    api
      .post('/enable_job_listing', {
        job_listing_id: id,
        user_id: user?.id,
      })
      .then(res => {
        if (res.status === 200) alert('Enabled Job Listing Successfully')
        else alert('Something went wrong')
        refetch()
      })
  }

  const disable_job_listing = () => {
    api
      .post('/disable_job_listing', {
        job_listing_id: id,
        user_id: user?.id,
      })
      .then(res => {
        if (res.status === 200) alert('Disabled Job Listing Successfully')
        else alert('Something went wrong')
        refetch()
      })
  }

  return { refetch, jobListing: data?.jobListing, enable_job_listing, disable_job_listing }
}
