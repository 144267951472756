export const userRoles = [
  'csr',
  'admin',
  'sr_sales',
  'sr_parts',
  'ops',
  'sales',
  'reports',
  'training',
  'beta_tester',
  'recruiter',
  'recruiter_en',
  'recruiter_es',
  'can_claim_customer'
]

export const editableUserFields = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'addressLineOne',
  'addressLineTwo',
  'zip',
  'city'
]
