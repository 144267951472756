import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'

const PART_QUERY = gql`
  query appointment($id: ID!) {
    appointment(id: $id) {
      quote {
        id
        leadId
      }
    }
  }
`

const Router = () => {
  const { appointmentId } = useParams()

  const { loading, error, data } = useQuery(PART_QUERY, { variables: { id: appointmentId } })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  return <Redirect to={`/leads/${data.appointment.quote.leadId}/quotes/${data.appointment.quote.id}/appointments/${appointmentId}`} />
}

export default Router
