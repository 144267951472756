import { useEffect, useContext } from 'react'
import { useQuery, gql, useLazyQuery } from '@apollo/client'
import { GlobalContext } from 'GlobalStore'
import { RefetchRegistry } from 'RefetchRegistry'
import { Chip } from '@mui/material'

const QUERY = gql`
  query commissionChargeBacks($technicianId: ID!, $unclaimed: Boolean) {
    commissionChargeBacks(technicianId: $technicianId, unclaimed: $unclaimed) {
      id
      userId
      createdAt
      amount
      payoutId
      note
      deletedAt
      technicianId
      quote {
        id
        assignedTechnicianId
      }
    }
  }
`

const CommissionChargeBacks = ({ technician, prefetched }) => {
  const [global] = useContext(GlobalContext)
  const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  const [fetchData, { loading, error, data, refetch }] = useLazyQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: { technicianId: technician.id, unclaimed: true },
  })

  useEffect(() => {
    !prefetched && fetchData()
  }, [prefetched])

  useEffect(() => {
    const key = registerRefetch({ types: ['CommissionChargeBack'], refetch })
    return () => unregisterRefetch(key)
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const technicianById = id => global.technicians.find(tech => tech.id === id)
  const activeCommissionChargeBacks = prefetched || data.commissionChargeBacks.filter(comp => !comp.deletedAt) || []

  return (
    <>
      <span style={{ fontSize: '12px', color: '#111', fontWeight: 700 }}>Commission Charge Backs:&nbsp;</span>
      {activeCommissionChargeBacks.map(comp => (
        <Chip
          key={comp.id}
          size='small'
          label={
            <>
              <a href={`/quotes/${comp.quote.id}`}>{comp.quote.lead.name}</a> - ${comp.amount} - {comp.note}-
            </>
          }
        />
      ))}
      {activeCommissionChargeBacks.length === 0 && <span style={{ fontSize: '12px', color: '#777 ' }}>None</span>}
    </>
  )
}

export default CommissionChargeBacks
