import { useState, useContext, useRef, useEffect } from 'react'
import { CustomerContext } from './CustomerStore'
import { UserContext } from 'UserStore'
import { useQuery, gql } from '@apollo/client'
import { RefetchRegistry } from 'RefetchRegistry'
import { UpdateRefetchRegistry } from 'UpdateRefetchRegistry'
import {
  Typography,
  Box,
  Button,
  Modal,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import useUsersNames from 'hooks/useUsersNames'
import { keysToCamel, basicDateTimeFormat } from 'tools'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'

const modalBoxSx = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxWidth: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3.5,
  borderRadius: '3px',
}

const outerBoxSx = {
  display: 'flex',
  alignItems: 'center',
  p: 1,
  backgroundColor: 'ghostwhite',
  border: '2px solid #eae6ff',
  borderLeft: 0,
  borderRight: 0,
}

const QUERY = gql`
  query helpRequests($customerId: String!) {
    helpRequests(customerId: $customerId) {
      id
      userId
      contextType
      contextId
      description
      type
      urgency
      resolvedAt
      resolvedByUserId
      resolutionNotes
      urlPath
      createdAt
    }
  }
`

const HelpRequests = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [showModal, setShowModal] = useState(false)
  const [user] = useContext(UserContext)
  const { currentCustomer, currentCustomerContext } = useContext(CustomerContext)
  const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  const [registerUpdateRefetch, unregisterUpdateRefetch] = useContext(UpdateRefetchRegistry)

  const [description, setDescription] = useState('')
  const [type, setType] = useState('')
  const [urgency, setUrgency] = useState('')

  const handleClickGetHelp = () => {
    setShowModal(true)
  }

  const resetFields = () => {
    setDescription('')
    setType('')
    setUrgency('')
  }

  useEffect(resetFields, [currentCustomer])

  const { data, refetch } = useQuery(QUERY, {
    variables: {
      customerId: currentCustomer?.id,
    },
  })

  useEffect(() => {
    const key = registerRefetch({ types: ['HelpRequest'], refetch })
    return () => unregisterRefetch(key)
  }, [])

  useEffect(() => {
    const key = registerUpdateRefetch({ types: ['HelpRequest'], refetch })
    return () => unregisterUpdateRefetch(key)
  }, [])

  const helpRequests = (data && keysToCamel(data.helpRequests)) || []

  const handleCreateHelpRequest = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_help_request`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        customerId: currentCustomer.id,
        contextType: currentCustomerContext?.type || null,
        contextId: currentCustomerContext?.id || null,
        urlPath: window.location.pathname,
        description: description.trim(),
        type: type,
        urgency: Number(urgency),
      }),
    }).then(res => {
      if (res.ok) {
        resetFields()
        setShowModal(false)
      } else {
        alert('error')
      }
    })

  const handleChangeType = e => {
    setType(e.target.value)
  }

  const handleChangeUrgency = e => {
    setUrgency(e.target.value)
  }

  const allowSave = description.length > 4 && urgency.length > 0 && type.length > 0

  const resolvedHelpRequests = helpRequests.filter(helpRequest => helpRequest.resolvedAt)
  const pendingHelpRequests = helpRequests.filter(helpRequest => !helpRequest.resolvedAt)

  return (
    <Box sx={outerBoxSx}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='subtitle2'>Help Requests</Typography>
          </Box>

          <Box>
            <Button onClick={handleClickGetHelp} variant='outlined' color='secondary' size='small'>
              Get Help
            </Button>
          </Box>
        </Grid>

        {pendingHelpRequests.length > 0 && (
          <Grid item xs={12}>
            <Typography>Pending Help Requests</Typography>

            {pendingHelpRequests.map(helpRequest => (
              <HelpRequest key={helpRequest.id} helpRequest={helpRequest} />
            ))}
          </Grid>
        )}

        {resolvedHelpRequests.length > 0 && (
          <Grid item xs={12}>
            <Typography>Resolved Help Requests</Typography>

            {resolvedHelpRequests.map(helpRequest => (
              <HelpRequest key={helpRequest.id} helpRequest={helpRequest} />
            ))}
          </Grid>
        )}
      </Grid>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box sx={modalBoxSx}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ lineHeight: 1 }}>
                Ask for help
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl sx={{ minWidth: 200 }} size='small'>
                <InputLabel>What kind of help?</InputLabel>
                <Select value={type} label='What kind of help?' onChange={handleChangeType}>
                  <MenuItem value='quoting'>Quoting</MenuItem>
                  <MenuItem value='sales'>Sales</MenuItem>
                  <MenuItem value='parts'>Parts</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                sx={{ width: '100%' }}
                value={description}
                onChange={e => setDescription(e.target.value)}
                multiline
                rows={3}
                label='Type out all the details of the request'
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <FormLabel>Urgency</FormLabel>
                <RadioGroup name='urgency' value={urgency} onChange={handleChangeUrgency}>
                  <FormControlLabel
                    value='1'
                    control={<Radio />}
                    label='1: no rush or purely internal issue'
                    labelPlacement='right'
                  />
                  <FormControlLabel
                    value='2'
                    control={<Radio />}
                    label='2: blocking something time-sensitive or customer is waiting'
                    labelPlacement='right'
                  />
                  <FormControlLabel
                    value='3'
                    control={<Radio />}
                    label='3: angry customer is involved'
                    labelPlacement='right'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={() => setShowModal(false)} color='primary' size='small'>
                  cancel
                </Button>
                <Button
                  disabled={!allowSave}
                  onClick={handleCreateHelpRequest}
                  variant='contained'
                  color='primary'
                  size='small'
                >
                  create
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  )
}

const helpRequestStyle = {
  marginTop: '4px',
  backgroundColor: 'white',
  borderRadius: '2px',
  padding: '4px',
  border: '1px solid lightgray',
}

const HelpRequest = ({ helpRequest }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)
  const [showModal, setShowModal] = useState(false)
  const [resolutionNotes, setResolutionNotes] = useState('')
  const [user] = useContext(UserContext)
  const userName = useUsersNames()

  useEffect(() => {
    const key = registerTaskTarget({ targetType: 'HelpRequest', targetId: helpRequest.id })
    return () => unregisterTaskTarget(key)
  }, [])

  const allowResolution = !helpRequest.resolvedAt && resolutionNotes.length > 2

  const handleClickMarkAsResolved = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/resolve_help_request`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        helpRequestId: helpRequest.id,
        resolutionNotes: resolutionNotes.trim(),
      }),
    }).then(res => {
      if (res.ok) {
        setShowModal(false)
      } else {
        alert('error')
      }
    })

  return (
    <>
      <Grid container sx={{ fontSize: '0.92em' }}>
        <Grid item xs={12} />

        <Grid item xs={12}>
          <Box
            sx={{
              ...helpRequestStyle,
              backgroundColor: helpRequest.resolvedAt ? '#d0ffc136' : 'white',
              display: 'flex',
            }}
          >
            <Box sx={{ cursor: 'pointer', flexGrow: 1, overflow: 'hidden' }} onClick={() => setShowModal(true)}>
              <span>
                {userName(helpRequest.userId)}
                <small>{DateTime.fromISO(helpRequest.createdAt).toFormat(basicDateTimeFormat)}</small>
              </span>
              <br />
              {helpRequest.description}
            </Box>

            <Box style={{ fontSize: '0.8em' }}>
              <Link to={`${helpRequest.urlPath}`}>source</Link>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box sx={modalBoxSx}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ lineHeight: 1 }}>
                Help request from {userName(helpRequest.userId)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField disabled sx={{ width: '100%' }} value={helpRequest.description} multiline rows={3} />
            </Grid>

            <Grid item xs={12}>
              <hr />
            </Grid>

            <Grid item xs={12}>
              {helpRequest.resolvedAt && (
                <>
                  {userName(helpRequest.resolvedByUserId)}
                  <small>{DateTime.fromISO(helpRequest.resolvedAt).toFormat(basicDateTimeFormat)}</small>
                  <br />
                </>
              )}

              <TextField
                sx={{ width: '100%' }}
                value={helpRequest.resolutionNotes}
                disabled={!!helpRequest.resolvedAt}
                onChange={e => setResolutionNotes(e.target.value)}
                multiline
                rows={3}
                label='Describe how you resolved this'
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Button onClick={() => setShowModal(false)} color='primary' size='small'>
                    cancel
                  </Button>
                </Box>

                <Button
                  disabled={!allowResolution}
                  onClick={handleClickMarkAsResolved}
                  variant='contained'
                  color='primary'
                  size='small'
                >
                  mark{helpRequest.resolvedAt && 'ed'} as resolved
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}

export default HelpRequests
