import { ArrowDownward, ArrowUpward, FilterList } from '@mui/icons-material'
import { Chip, DialogActions, Box, Button, Dialog, DialogContent, DialogTitle, SvgIcon, TableCell } from '@mui/material'
import { setOrToggleOrder, useStyles } from 'BToB/Invoices/tools'
import { DateTime } from 'luxon'
import { useState } from 'react'

export const DateRangeHeadCell = ({ name, filter, setFilter, order, setOrder }) => {
  const classes = useStyles()
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [dateRange, setDateRange] = useState(filter[name] || {})
  // ONE of BOTH key of: { ge: iso8601, le: iso8601 } OR null
  // ge -> greater than or equal
  // le -> less than or equal

  const handleDateTimeChanged = evt => {
    setDateRange({ ...dateRange, [evt.target.name]: evt.target.value })
  }

  const handleClickApply = () => {
    setFilter({ ...filter, [name]: dateRange })
  }

  const handleClickClose = () => {
    setDialogIsOpen(false)
  }

  const handleDeleteRangeKey = key => {
    const { [key]: removed, ...rest } = dateRange
    setDateRange(rest)
    setFilter({ ...filter, [name]: rest })
  }

  return (
    <TableCell>
      {name}

      <Button
        sx={{
          minWidth: '30px',
          padding: '5px 0px',
          margin: '0px 5px',
        }}
        variant={order[name] ? 'contained' : 'text'}
        color='primary'
        disableElevation
        onClick={() => setOrToggleOrder({ name, order, setOrder })}
      >
        <SvgIcon fontSize='small' component={(order[name] === 'desc' ? ArrowDownward : ArrowUpward) || ArrowDownward} />
      </Button>

      <Button style={{ minWidth: 0 }} color='primary' disableElevation onClick={() => setDialogIsOpen(true)}>
        <SvgIcon fontSize='small' component={FilterList} />
      </Button>

      {filter[name] && Object.entries(filter[name]).length > 0 && (
        <>
          <br />
          {Object.entries(filter[name])
            .sort((a, b) => (a[0] > b[0] ? 1 : -1))
            .map(filterTuple => (
              <Chip
                key={filterTuple[0]}
                variant='outlined'
                size='small'
                onDelete={() => handleDeleteRangeKey(filterTuple[0])}
                label={`
                    ${filterTuple[0] === 'le' ? 'before' : 'after'}
                    ${DateTime.fromISO(filterTuple[1]).toFormat('yy-MM-dd')}
                  `}
              />
            ))}
        </>
      )}

      <Dialog open={dialogIsOpen} onClose={setDialogIsOpen}>
        <DialogTitle>Select Date Range (One Or Both)</DialogTitle>
        <DialogContent className={classes.dateDialog}>
          <Box>
            <span>
              Start Date<small> (optional)</small>
            </span>
            <input type='date' name='ge' value={dateRange.ge || ''} onChange={handleDateTimeChanged} />
          </Box>
          <Box>
            <span>
              End Date<small> (optional)</small>
            </span>
            <input type='date' name='le' value={dateRange.le || ''} onChange={handleDateTimeChanged} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color='primary'>
            Close
          </Button>
          <Button onClick={handleClickApply} color='primary' autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </TableCell>
  )
}
