import React, { useContext, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useHistory, Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import { Typography, Button, Tabs, Tab } from '@mui/material'
import Profile from './Profile'
import Edit from './Edit'
import Chat from './Chat'
import Schedule from './Schedule'
import Payroll from './Payroll'

import { TaskTargetRegistry } from 'TaskTargetRegistry'
import { CreateATask } from 'Tasks'
import { TLD, keysToCamel } from 'tools'

const QUERY = gql`
  query technician($id: ID!) {
    technician(id: $id) {
      id
      name
      email
      firstName
      lastName
      businessName
      contractorIsBusiness
      ssn
      ein
      phone
      addressLineOne
      addressLineTwo
      city
      state
      zip
      lat
      lng
      drivewayJobs
      active
      commissionPercent
      market
      displayMarket
      availability
      offdays
      loanBalance
      loanWeeklyDeduction
      loanActivity
      isEmployee
      isElite
      otherLoanBalance
      otherLoanWeeklyDeduction
      otherLoanActivity
      payrollIds
      isTrusted
      note
      isBToB
      enableB2bSquareFee
      bToBBusinessName
      emergencyContact
      notifications
      hourlyRate
      commissionAmount
      vehicleYear
      vehicleMakeShortName
      vehicleModelShortName
      vehicleColor
      skillsets
      maxDrivingDistance
      quoteCommissionStructure
      bToBInvoiceCommissionStructure
      techChargesCommissionPercent
      bToBDealerIds
      netLaborRevenuePercentage
      suppliesCostPercentage
      bToBDealers {
        id
        businessName
      }
    }
  }
`

const Router = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { technicianId } = useParams()

  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)

  useEffect(() => {
    const key = registerTaskTarget({
      targetType: 'Technician',
      targetId: technicianId,
    })
    return () => unregisterTaskTarget(key)
  }, [])

  const handleClickViewTechAppAsTech = () => {
    window.open(`${process.env.REACT_APP_TECH_APP_URL}/technician/${technician.id}`)
  }

  const handleChangeTab = (_, newValue) => {
    history.push(newValue)
  }

  const { loading, error, data } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: { id: technicianId },
  })

  if (loading || !data) return <div>Loading...</div>
  if (error) return <div>Error!</div>

  const technician = keysToCamel(data.technician)

  return (
    <>
      <Typography variant='h5'>
        {technician.firstName} {technician.lastName}
        &nbsp;&nbsp;
        <CreateATask targetType='Technician' targetId={technician.id} />
        &nbsp;&nbsp;
        <Button variant='outlined' size='small' color='primary' onClick={handleClickViewTechAppAsTech}>
          view tech app as {technician.name}
        </Button>
      </Typography>

      <Tabs value={window.location.pathname} onChange={handleChangeTab}>
        <Tab cy-data="technician-tab-chat" value={`/technicians/${technicianId}`} label='Chat' />
        <Tab cy-data="technician-tab-profile" value={`/technicians/${technicianId}/profile`} label='Profile' />
        <Tab cy-data="technician-tab-schedule" value={`/technicians/${technicianId}/schedule`} label='Schedule' />
        <Tab cy-data="technician-tab-edit" value={`/technicians/${technicianId}/edit`} label='Edit' />
        <Tab cy-data="technician-tab-payroll" value={`/technicians/${technicianId}/payroll`} label='Payroll' />
      </Tabs>

      <Switch>
        <Route path={`${path}/profile`}>
          <Profile technician={technician} />
        </Route>
        <Route path={`${path}/edit`}>
          <Edit technician={technician} />
        </Route>
        <Route path={`${path}/schedule`}>
          <Schedule technician={technician} />
        </Route>
        <Route path={`${path}/payroll`}>
          <Payroll technician={technician} />
        </Route>
        <Route path={`${path}`}>
          <Chat technician={technician} />
        </Route>
      </Switch>
    </>
  )
}

export default Router
