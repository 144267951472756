import { Box, Button, Dialog } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { useContext, useState } from 'react'
import Dropzone from 'react-dropzone-uploader'
import { UserContext } from 'UserStore'
import StreamDump from './CsvListStreamDump'
import { downloadStringAsFile } from 'tools'

const getPresignedUploadParams = async filename =>
  fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_presigned_file_upload_s3_url`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({ filename }),
  })
    .then(res => res.ok && res.json())
    .then(data => data)

const getUploadParamsForDropzone = async ({ file, meta: { name } }) => {
  const { uploadUrl, fileUrl } = await getPresignedUploadParams(name)
  return { body: file, meta: { fileUrl }, url: uploadUrl, method: 'put' }
}

export const CsvConfigurableListOptions = ({ parentType, parentTitle }) => {
  const [user] = useContext(UserContext)
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const handleDownloadCurrentList = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/download_csv_configurable_list`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        parent_type: parentType,
        user_id: user.id,
      }),
    })
      .then(res => res.json())
      .then(data => {
        downloadStringAsFile({ string: data.csvString, fileName: data.csvFilename })
      })
      .catch(err => window.alert(err))
  }

  const handleSubmit = file => {
    console.log({ file })
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/upload_csv_configurable_list`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        migration_type: 'upload',
        parent_type: parentType,
        url_string: file[0]?.meta?.fileUrl,
      }),
    })
      .then(response => {
        if (response.ok) {
          alert('File uploaded successfully')
          window.location.reload(false)
          return true
        } else {
          throw Error('error')
        }
      })
      .catch(e => {
        alert(e.message)
      })
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          padding: '.5rem',
          alignItems: 'center',
          '& .dzu-inputLabel': { fontSize: '14px' },
          '& .dzu-dropzone-custom': {
            display: 'block',
            width: '500px',
            marginRight: 'auto',
          },
        }}
      >
        <Box>
          <Dropzone
            addClassNames={{ dropzone: 'dzu-dropzone-custom' }}
            getUploadParams={getUploadParamsForDropzone}
            onSubmit={handleSubmit}
            submitButton
            inputContent={`Upload new ${parentTitle} list`}
            styles={{
              fontSize: '12px',
            }}
          />
        </Box>
        <Button onClick={handleDownloadCurrentList} sx={{ mr: '.5rem' }} variant='contained'>
          Download current {parentTitle} list
        </Button>
        <Button onClick={() => setHistoryDialogOpen(true)}>View {parentTitle} list history</Button>
      </Box>
      <Dialog fullWidth={true} maxWidth='lg' open={historyDialogOpen} onClose={() => setHistoryDialogOpen(false)}>
        <StreamDump stream={`${parentType}:Configuration`} open={historyDialogOpen} />
      </Dialog>
    </Box>
  )
}
