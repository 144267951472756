import { useQuery, gql } from '@apollo/client'
import { Grid } from '@mui/material'
import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'
import SearchSelector from './SearchSelector'

const DEALERS_QUERY = gql`
  query TechChargeCatalogItems {
    techChargeCatalogItems {
      id
      name
      price
      type
    }
  }
`

const AddTechnicianCharge = ({ quote, disabled, onSave }) => {
  const { loading, error, data, refetch } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  if (error) return <div>Error!</div>

  const techChargesItems = data?.techChargeCatalogItems || []

  return (
    <Grid container sx={{ px: 1, pt: 0.3 }}>
      <Grid item xs={12}>
        <SearchSelector
          placeholder='...'
          listOfObjects={techChargesItems}
          renderOption={obj => obj.name + ' - $' + obj.price}
          attributeToFilterOn='name'
          onSelectionMade={newValue => {
            onSave({
              price: newValue.price,
              createdAt: DateTime.now().toISO(),
              name: newValue.name,
              id: uuid(),
            })
          }}
          disabled={disabled}
          name='tech-charges-search-selector'
          listName='tech-charges-options-for-quote'
        />
      </Grid>
    </Grid>
  )
}

export default AddTechnicianCharge
