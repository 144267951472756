import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import LeadRouter from './Lead/Router'

const Router = ({ customer }) => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:leadId`}>
        <LeadRouter customer={customer} />
      </Route>
    </Switch>
  )
}

export default Router
