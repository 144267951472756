import { Box, Grid } from '@mui/material'
import AvailabilityHours from './AvailabilityHours'
import DaysOff from './DaysOff'
import { useContext } from 'react'
import { UserContext } from 'UserStore'
import { useUser } from 'hooks/user/useUser/useUser'

const RecruiterAvailability = () => {
  const [user] = useContext(UserContext)
  const { user: userObj } = useUser(user.id)

  if (!user) return null

  return (
    <Box>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={6}>
          <AvailabilityHours user={userObj} />
        </Grid>
        <Grid item xs={6}>
          <DaysOff user={userObj} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default RecruiterAvailability
