import { useQuery } from '@apollo/client'

import { GetInterviewsRes, GET_ALL_INTERVIEWS } from './query'
import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'

export const useInterviews = () => {
  const [filter, setFilter] = useState({})
  const [order, setOrder] = useState({})
  const [search, setSearch] = useState({})
  const [limit, setLimit] = useState(25)
  const [cursorCeiling, setCursorCeiling] = useState('')

  const { data, loading, fetchMore } = useQuery<any>(GET_ALL_INTERVIEWS, {
    variables: {
      search,
      order,
      filter,
      first: limit,
      after: cursorCeiling,
    },
  })
  const interviewsRaw = data?.interviewsConnection?.edges?.map((edge: any) => edge.node) ?? []
  const totalCount = data?.interviewsConnection?.totalCount || 0
  const endCursor = data?.interviewsConnection?.pageInfo?.endCursor || 0

  const interviews = interviewsRaw?.map((interview: any) => {
    let status = 'pending'
    if (interview?.canceledAt) status = 'canceled'
    if (interview?.startDatetime && DateTime.fromISO(interview?.startDatetime) < DateTime.now().endOf('day'))
      status = 'completed'

    return {
      ...interview,
      status,
    }
  })

  useEffect(() => {
    fetchMore({
      variables: {
        search,
        order,
        filter,
        first: limit,
        after: '',
        cursorCeiling,
      },
    })
  }, [search, order, filter, limit])

  return {
    interviews,
    loading,
    filter,
    setFilter,
    order,
    setOrder,
    limit,
    setLimit,
    setCursorCeiling,
    totalCount,
    fetchMore,
    endCursor,
    search
  }
}
