import { Switch, Route, useRouteMatch } from 'react-router-dom'
import MarketRouter from './Markets/Market/Router'
import YearMakePremiumRouter from './YearMakePremiums/YearMakePremium/Router'
import Index from './Index'

const Router = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/markets/:marketID`}>
        <MarketRouter />
      </Route>
      <Route path={`${path}/year-make-premiums/:yearMakePremiumID`}>
        <YearMakePremiumRouter />
      </Route>
      <Route path={`${path}`}>
        <Index />
      </Route>
    </Switch>
  )
}

export default Router