import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { Grid, FormControl, Button } from '@mui/material'

const useStyles = makeStyles(theme => ({
  editableGrid: {
    '& .MuiInput-root': {
      paddingBottom: '0',
    },
    '& .MuiInput-root::before': {
      borderWidth: '0',
    },
    '& .MuiInput-root input': {
      borderWidth: '0',
      paddingBottom: '0',
    },
    '& .MuiInput-root textarea': {
      borderWidth: '0',
      paddingBottom: '0',
      marginTop: '5px',
    },
    '& .MuiSelect-select': {
      paddingBottom: 0,
    },
    '& .MuiSelect-icon': {
      visibility: 'hidden',
    },
    '& .MuiInput-root:hover .MuiSelect-icon': {
      visibility: 'visible',
    },
    '& input[name="cost"], & input[name="price"]': {
      textAlign: 'right',
    },
    '& .MuiInput-root::before:hover': {
      borderWidth: '1px',
      borderColor: '#eaeae8',
    },
  },
  fontRed: {
    color: 'red',
  },
}))

const CenterAlignedGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const InlineTireconnectPartSearchGrid = ({ quote, part }) => {
  const classes = useStyles()

  return (
    <Grid container gap={2} m='.5rem' className={classes.editableGrid}>
      <CenterAlignedGridItem item xs={8}>
        {`[HELP NEEDED] ${part.name}`}
      </CenterAlignedGridItem>
      <CenterAlignedGridItem item xs={3}>
        <FormControl fullWidth>
          <Button size='small' disableElevation variant='contained'>
            Search tire
          </Button>
        </FormControl>
      </CenterAlignedGridItem>
    </Grid>
  )
}

export default InlineTireconnectPartSearchGrid
