import appleData from '@emoji-mart/data/sets/14/apple.json'

import { init } from 'emoji-mart'

init({ appleData, set: 'apple' })

export default function EmojiString({ inputString }) {
  // Use a regular expression to split the string by emoji shortcodes
  const parts = inputString.split(/(:[\w-]+:)/g)

  return (
    <>
      {parts.map((part, index) => {
        // If the part matches the emoji shortcode format
        if (/^:[\w-]+:$/.test(part)) {
          return <em-emoji set={'apple'} size='1em' key={index} shortcodes={part}></em-emoji>
        }
        // Otherwise, just return the string part
        return part
      })}
    </>
  )
}
