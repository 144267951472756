import {
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Grid,
  Popover,
  Typography
} from '@mui/material'
import { DateTime, Duration } from 'luxon'
import { FROM_NUMBERS, filterBarButtonPrettyNames } from './constants'
import DateTimePicker from '@mui/lab/DateTimePicker'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useState } from 'react'
import { style } from './style'

export const userByTwilioId = (clientId, users) =>
  users.find(user => user.twilioIdentity === clientId)

const userById = (userId, users) => users.find(user => user.id === userId)

export const userId = (userId, users) => {
  const user = userByTwilioId(userId, users)
  if (user) {
    return user.id
  }
}
export const userName = (userId, users) => {
  const user = userByTwilioId(userId, users)
  if (user) {
    return `${user.firstName} ${user.lastName}`
  }
}

const userNameById = (userId, users) => {
  const user = userById(userId, users)
  if (user) {
    return `${user.firstName} ${user.lastName}`
  }
}

export const avgFromList = arr => arr.reduce((a, b) => a + b, 0) / arr.length
export const onlyNumbersList = arr =>
  arr.filter(item => typeof item === 'number')

const avgDurationToLuxon = millis =>
  (millis &&
    Duration.fromMillis(millis)
      .shiftTo('hours', 'minutes', 'seconds')
      .toFormat("mm'm' ss's'")) ||
  'Not available'

const avgDurationsForUser = (clientId, callRecords) =>
  callRecords
    .filter(record => record.clientId === clientId)
    .map(item => {
      return (
        DateTime.fromISO(item.endDatetime) -
        DateTime.fromISO(item.startDatetime)
      )
    }, []) || []

export const avgCallDurationForList = callRecords =>
  avgDurationAmount(
    (callRecords.length > 0 &&
      callRecords.map(item => {
        return (
          DateTime.fromISO(item.endDatetime) -
          DateTime.fromISO(item.startDatetime)
        )
      }, [])) ||
      []
  )

export const avgCallDurationForUserInList = (clientId, callRecords) =>
  avgDurationToLuxon(avgFromList(avgDurationsForUser(clientId, callRecords)))

export const avgDurationAmount = durationsList =>
  avgDurationToLuxon(avgFromList(durationsList))

export const numberOfCsrs = callRecords =>
  Object.entries(occurrencesOfEachObjectInList(clientsList(callRecords))).length

export const callRecordTotalsGroupedByCsr = callRecords =>
  Object.entries(occurrencesOfEachObjectInList(clientsList(callRecords)))

export const clientsList = callRecords =>
  callRecords.map(record => record.clientId)

const callsStartsAndEndTimesForUser = (clientId, callRecords) =>
  callRecords
    .filter(record => record.clientId === clientId)
    .map((record, index) =>
      index % 2 === 0 ? record.endDatetime : record.startDatetime
    )

const callsStartsAndEndTimes = callRecords =>
  callRecords.map((record, index) =>
    index % 2 === 0 ? record.endDatetime : record.startDatetime
  )

export const occurrencesOfEachObjectInList = list =>
  list.reduce((acc, curr) => {
    return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc
  }, [])

const listOfDurationBetweenCalls = listOfStartAndEndTimes =>
  listOfStartAndEndTimes
    .reduce(
      (acc, item, index) =>
        index % 2 === 0
          ? [
              ...acc,
              DateTime.fromISO(item) -
                DateTime.fromISO(listOfStartAndEndTimes[index + 1])
            ]
          : acc.length > 0
            ? acc
            : [],
      []
    )
    .filter(item => item < 720000)

const callsSegmentedIntoDayWithoutSunday = callRecords =>
  callRecords
    .map(record => DateTime.fromISO(record.endDatetime).toFormat('ccc DD'))
    .filter(record => !record.includes('Sun'))

export const callsSegmentedIntoDayWithoutSundayForList = (
  clientId,
  callRecords
) =>
  callRecords
    .filter(record => record.clientId === clientId)
    .map(record => DateTime.fromISO(record.endDatetime).toFormat('ccc DD'))
    .filter(record => !record.includes('Sun'))

export const averageDurationBetweenCalls = callRecords =>
  avgDurationToLuxon(
    avgFromList(listOfDurationBetweenCalls(callsStartsAndEndTimes(callRecords)))
  )

export const averageDurationBetweenCallsForUserInList = (
  clientId,
  callRecords
) =>
  avgDurationToLuxon(
    avgFromList(
      listOfDurationBetweenCalls(
        callsStartsAndEndTimesForUser(clientId, callRecords)
      )
    )
  )

export const totalTimeOnCalls = callRecords =>
  Duration.fromMillis(
    callRecords.reduce(
      (acc, item) =>
        acc +
        (DateTime.fromISO(item.endDatetime) -
          DateTime.fromISO(item.startDatetime)),
      0
    )
  )
    .shiftTo('hours', 'minutes', 'seconds')
    .toFormat("mm'm' ss's'")

export const totalTimeOnCallsForUserInList = (clientId, callRecords) =>
  Duration.fromMillis(
    callRecords
      .filter(rec => rec.clientId === clientId)
      .reduce(
        (acc, item) =>
          acc +
          (DateTime.fromISO(item.endDatetime) -
            DateTime.fromISO(item.startDatetime)),
        0
      )
  )
    .shiftTo('hours', 'minutes', 'seconds')
    .toFormat("mm'm' ss's'")

export const avgNumberOfDailyCallsForUserInList = (
  twilioIdentity,
  callRecords
) =>
  avgFromList(
    Object.values(
      occurrencesOfEachObjectInList(
        callsSegmentedIntoDayWithoutSundayForList(twilioIdentity, callRecords)
      )
    )
  ).toFixed(2)

export const avgNumberOfDailyCalls = callRecords =>
  avgFromList(
    Object.values(
      occurrencesOfEachObjectInList(
        callsSegmentedIntoDayWithoutSunday(callRecords)
      )
    )
  )

export const avgTimeToStartCall = callbackRequests =>
  avgDurationToLuxon(
    avgFromList(
      callbackRequests
        .filter(record => record.attempts.length > 0)
        .filter(
          record =>
            !DateTime.fromISO(record.createdAt)
              .toFormat('ccc DD')
              .includes('Sun')
        )
        .filter(
          record =>
            DateTime.fromISO(record.createdAt).toFormat('DD') ===
            DateTime.fromISO(
              record.attempts
                .slice()
                .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))[0]
                .timestamp
            ).toFormat('DD')
        )
        .map(record => {
          const sortedAttempts = record.attempts
            .slice()
            .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))

          if (sortedAttempts.length === 1) {
            return {
              firstAttempt: sortedAttempts[0].timestamp,
              createdAt: record.createdAt
            }
          }
          if (sortedAttempts.length > 1) {
            return {
              firstAttempt: sortedAttempts[0].timestamp,
              secondAttempt: sortedAttempts[1].timestamp,
              createdAt: record.createdAt
            }
          }
          if (sortedAttempts.length > 1) {
            return {
              firstAttempt: sortedAttempts[0].timestamp,
              secondAttempt: sortedAttempts[1].timestamp,
              thirdAttempt: sortedAttempts[2].timestamp,
              createdAt: record.createdAt
            }
          }
        })
        .map(item =>
          avgFromList(
            onlyNumbersList([
              DateTime.fromISO(item.firstAttempt) -
                DateTime.fromISO(item.createdAt),
              item.secondAttempt &&
                DateTime.fromISO(item.secondAttempt) -
                  DateTime.fromISO(item.firstAttempt) +
                  600000,
              item.thirdAttempt &&
                DateTime.fromISO(item.thirdAttempt) -
                  DateTime.fromISO(item.secondAttempt) +
                  600000
            ])
          )
        )
        .filter(item => item < 86400000)
    )
  )
export const avgTimeToStartCallForUserInList = (userId, callbackRequests) =>
  avgDurationToLuxon(
    avgFromList(
      onlyNumbersList(
        callbackRequests
          .filter(record => record.attempts.length > 0)
          .filter(
            record =>
              record.attempts.find(attempt => attempt.user_id === userId) &&
              record
          )
          // REMOVE ALL REQUESTS THAT CAME IN ON SUNDAY FROM OUR AVERAGE
          .filter(
            record =>
              !DateTime.fromISO(record.createdAt)
                .toFormat('ccc DD')
                .includes('Sun')
          )

          // REMOVE ALL REQUESTS THAT HAVE MISMATCHED DAYS BETWEEN FIRST RESPONSE
          // AND REQUEST DATE
          .filter(
            record =>
              DateTime.fromISO(record.createdAt).toFormat('DD') ===
              DateTime.fromISO(
                record.attempts
                  .slice()
                  .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))[0]
                  .timestamp
              ).toFormat('DD')
          )
          .map(record => {
            const sortedAttempts = record.attempts
              .slice()
              .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))

            if (sortedAttempts.length === 1) {
              return {
                firstAttempt: {
                  user_id: sortedAttempts[0].user_id,
                  timestamp: sortedAttempts[0].timestamp
                },
                createdAt: record.createdAt
              }
            }
            if (sortedAttempts.length === 2) {
              return {
                firstAttempt: {
                  user_id: sortedAttempts[0].user_id,
                  timestamp: sortedAttempts[0].timestamp
                },
                secondAttempt: {
                  user_id: sortedAttempts[1].user_id,
                  timestamp: sortedAttempts[1].timestamp
                },
                createdAt: record.createdAt
              }
            }
            if (sortedAttempts.length === 3) {
              return {
                firstAttempt: {
                  user_id: sortedAttempts[0].user_id,
                  timestamp: sortedAttempts[0].timestamp
                },
                secondAttempt: {
                  user_id: sortedAttempts[1].user_id,
                  timestamp: sortedAttempts[1].timestamp
                },
                thirdAttempt: {
                  user_id: sortedAttempts[2].user_id,
                  timestamp: sortedAttempts[2].timestamp
                },
                createdAt: record.createdAt
              }
            }
            if (sortedAttempts.length === 4) {
              return {
                firstAttempt: {
                  user_id: sortedAttempts[0].user_id,
                  timestamp: sortedAttempts[0].timestamp
                },
                secondAttempt: {
                  user_id: sortedAttempts[1].user_id,
                  timestamp: sortedAttempts[1].timestamp
                },
                thirdAttempt: {
                  user_id: sortedAttempts[2].user_id,
                  timestamp: sortedAttempts[2].timestamp
                },
                fourthAttempt: {
                  user_id: sortedAttempts[3].user_id,
                  timestamp: sortedAttempts[3].timestamp
                },
                createdAt: record.createdAt
              }
            }
            if (sortedAttempts.length === 5) {
              return {
                firstAttempt: {
                  user_id: sortedAttempts[0].user_id,
                  timestamp: sortedAttempts[0].timestamp
                },
                secondAttempt: {
                  user_id: sortedAttempts[1].user_id,
                  timestamp: sortedAttempts[1].timestamp
                },
                thirdAttempt: {
                  user_id: sortedAttempts[2].user_id,
                  timestamp: sortedAttempts[2].timestamp
                },
                fourthAttempt: {
                  user_id: sortedAttempts[3].user_id,
                  timestamp: sortedAttempts[3].timestamp
                },
                fifthAttempt: {
                  user_id: sortedAttempts[4].user_id,
                  timestamp: sortedAttempts[4].timestamp
                },
                createdAt: record.createdAt
              }
            }
            if (sortedAttempts.length === 6) {
              return {
                firstAttempt: {
                  user_id: sortedAttempts[0].user_id,
                  timestamp: sortedAttempts[0].timestamp
                },
                secondAttempt: {
                  user_id: sortedAttempts[1].user_id,
                  timestamp: sortedAttempts[1].timestamp
                },
                thirdAttempt: {
                  user_id: sortedAttempts[2].user_id,
                  timestamp: sortedAttempts[2].timestamp
                },
                fourthAttempt: {
                  user_id: sortedAttempts[3].user_id,
                  timestamp: sortedAttempts[3].timestamp
                },
                fifthAttempt: {
                  user_id: sortedAttempts[4].user_id,
                  timestamp: sortedAttempts[4].timestamp
                },
                sixthAttempt: {
                  user_id: sortedAttempts[5].user_id,
                  timestamp: sortedAttempts[5].timestamp
                },
                createdAt: record.createdAt
              }
            }
          })
          .map(item =>
            avgFromList(
              onlyNumbersList([
                (item.firstAttempt.user_id === userId &&
                  DateTime.fromISO(item.firstAttempt.timestamp) -
                    DateTime.fromISO(item.createdAt)) ||
                  undefined,
                (item.secondAttempt &&
                  item.secondAttempt.user_id === userId &&
                  DateTime.fromISO(item.secondAttempt.timestamp) -
                    DateTime.fromISO(item.firstAttempt.timestamp) +
                    600000) ||
                  undefined,
                (item.thirdAttempt &&
                  item.thirdAttempt.user_id === userId &&
                  DateTime.fromISO(item.thirdAttempt.timestamp) -
                    DateTime.fromISO(item.secondAttempt.timestamp) +
                    600000) ||
                  undefined,
                (item.fourthAttempt &&
                  item.fourthAttempt.user_id === userId &&
                  DateTime.fromISO(item.fourthAttempt.timestamp) -
                    DateTime.fromISO(item.thirdAttempt.timestamp) +
                    600000) ||
                  undefined,
                (item.fifthAttempt &&
                  item.fifthAttempt.user_id === userId &&
                  DateTime.fromISO(item.fifthAttempt.timestamp) -
                    DateTime.fromISO(item.fourthAttempt.timestamp) +
                    600000) ||
                  undefined,
                (item.sixthAttempt &&
                  item.sixthAttempt.user_id === userId &&
                  DateTime.fromISO(item.sixthAttempt.timestamp) -
                    DateTime.fromISO(item.fifthAttempt.timestamp) +
                    600000) ||
                  undefined
              ])
            )
          )
      )
    )
  )

export const groupedActivityForUser = (
  clientId,
  users,
  filter,
  callbackRequests,
  callRecords,
  callbackRequestsData,
  activityLimit
) =>
  callRecords
    .map(req => ({
      timestamp: req.startDatetime,
      hoursTimestamp: DateTime.fromISO(req.startDatetime).toLocaleString(
        DateTime.TIME_SIMPLE
      ),
      prettyDate: DateTime.fromISO(req.startDatetime).toLocaleString({
        weekday: 'short',
        month: '2-digit',
        day: '2-digit'
      }),
      type: 'call',
      duration: Duration.fromMillis(
        DateTime.fromISO(req.endDatetime) - DateTime.fromISO(req.startDatetime)
      )
        .shiftTo('hours', 'minutes', 'seconds')
        .toFormat("mm'm' ss's'")
    }))
    .concat(attemptsMadeByThisUser(clientId, users, filter, callbackRequests))
    .concat(allRequestsWithoutThisUser(callbackRequestsData))
    .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
    .slice(0, activityLimit)

export const allRequestsWithoutThisUser = callbackRequestsData =>
  (callbackRequestsData &&
    callbackRequestsData.callbackRequestsConnection.edges
      .map(edge => edge.node)
      .reduce(
        (acc, item) => [
          ...acc,
          {
            timestamp: item.createdAt,
            hoursTimestamp: DateTime.fromISO(item.createdAt).toLocaleString(
              DateTime.TIME_SIMPLE
            ),
            prettyDate: DateTime.fromISO(item.createdAt).toLocaleString({
              weekday: 'short',
              month: '2-digit',
              day: '2-digit'
            }),
            id: item.id,
            type: 'request'
          }
        ],
        []
      )) ||
  []

export const attemptsMadeByThisUser = (
  clientId,
  users,
  filter,
  callbackRequests
) =>
  callbackRequests
    .filter(rec => rec.attempts.length > 0)
    .filter(rec =>
      rec.attempts.find(attempt => attempt.user_id === userId(clientId, users))
    )
    .filter(rec => {
      if (filter.end_datetime && filter.end_datetime.ge) {
        return (
          DateTime.fromISO(
            rec.attempts.find(
              attempt => attempt.user_id === userId(clientId, users)
            ).timestamp
          ) < DateTime.fromISO(filter.end_datetime.le) &&
          DateTime.fromISO(
            rec.attempts.find(
              attempt => attempt.user_id === userId(clientId, users)
            ).timestamp
          ) > DateTime.fromISO(filter.end_datetime.ge)
        )
      } else {
        return rec.attempts.find(
          attempt => attempt.user_id === userId(clientId, users)
        )
      }
    })
    .reduce(
      (acc, item) => [
        ...acc,
        ...item.attempts.map(att => ({
          timestamp: att.timestamp,
          hoursTimestamp: DateTime.fromISO(att.timestamp).toLocaleString(
            DateTime.TIME_SIMPLE
          ),
          prettyDate: DateTime.fromISO(att.timestamp).toLocaleString({
            weekday: 'short',
            month: '2-digit',
            day: '2-digit'
          }),
          id: item.id,
          type: 'attempt'
        }))
      ],
      []
    )

export const handleClickDateRangeButtonCreatedAt = (
  ranges,
  setEditableFilter
) => {
  switch (ranges) {
    case 'today':
      return setEditableFilter({
        created_at: {
          le: DateTime.fromJSDate(new Date())
            .endOf('day')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .startOf('day')
            .toISO()
        }
      })
      break
    case 'yesterday':
      return setEditableFilter({
        created_at: {
          le: DateTime.fromJSDate(new Date())
            .minus({ days: 1 })
            .endOf('day')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .minus({ days: 1 })
            .startOf('day')
            .toISO()
        }
      })
      break
    case 'this week':
      return setEditableFilter({
        created_at: {
          le: DateTime.fromJSDate(new Date())
            .endOf('week')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .startOf('week')
            .toISO()
        }
      })
      break
    case 'last week':
      return setEditableFilter({
        created_at: {
          le: DateTime.fromJSDate(new Date())
            .minus({ week: 1 })
            .endOf('week')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .minus({ week: 1 })
            .startOf('week')
            .toISO()
        }
      })
      break
    case 'two weeks ago':
      return setEditableFilter({
        created_at: {
          le: DateTime.fromJSDate(new Date())
            .minus({ week: 2 })
            .endOf('week')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .minus({ week: 2 })
            .startOf('week')
            .toISO()
        }
      })
      break
    case 'this month':
      return setEditableFilter({
        created_at: {
          le: DateTime.fromJSDate(new Date())
            .endOf('month')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .startOf('month')
            .toISO()
        }
      })
      break
    case 'last month':
      return setEditableFilter({
        created_at: {
          le: DateTime.fromJSDate(new Date())
            .minus({ month: 1 })
            .endOf('month')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .minus({ month: 1 })
            .startOf('month')
            .toISO()
        }
      })
      break
    case 'all time':
      return setEditableFilter({
      })
      break
  }
}

export const handleClickDateRangeButton = (ranges, setEditableFilter) => {
  switch (ranges) {
    case 'today':
      return setEditableFilter({
        end_datetime: {
          le: DateTime.fromJSDate(new Date())
            .endOf('day')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .startOf('day')
            .toISO()
        }
      })
      break
    case 'yesterday':
      return setEditableFilter({
        end_datetime: {
          le: DateTime.fromJSDate(new Date())
            .minus({ days: 1 })
            .endOf('day')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .minus({ days: 1 })
            .startOf('day')
            .toISO()
        }
      })
      break
    case 'this week':
      return setEditableFilter({
        end_datetime: {
          le: DateTime.fromJSDate(new Date())
            .endOf('week')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .startOf('week')
            .toISO()
        }
      })
      break
    case 'last week':
      return setEditableFilter({
        end_datetime: {
          le: DateTime.fromJSDate(new Date())
            .minus({ week: 1 })
            .endOf('week')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .minus({ week: 1 })
            .startOf('week')
            .toISO()
        }
      })
      break
    case 'two weeks ago':
      return setEditableFilter({
        end_datetime: {
          le: DateTime.fromJSDate(new Date())
            .minus({ week: 2 })
            .endOf('week')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .minus({ week: 2 })
            .startOf('week')
            .toISO()
        }
      })
      break
    case 'this month':
      return setEditableFilter({
        end_datetime: {
          le: DateTime.fromJSDate(new Date())
            .endOf('month')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .startOf('month')
            .toISO()
        }
      })
      break
    case 'last month':
      return setEditableFilter({
        end_datetime: {
          le: DateTime.fromJSDate(new Date())
            .minus({ month: 1 })
            .endOf('month')
            .toISO(),
          ge: DateTime.fromJSDate(new Date())
            .minus({ month: 1 })
            .startOf('month')
            .toISO()
        }
      })
      break
    case 'all time':
      return setEditableFilter({
        end_datetime: {
          le: null,
          ge: null
        }
      })
      break
  }
}

export const callLogsForTable = (callRecords, page, callRecordsLimit) =>
  callRecords
    .slice(page * callRecordsLimit, page * callRecordsLimit + callRecordsLimit)
    .map((req, index) => ({
      ...req,
      timeGap:
        callRecords[index + 1] &&
        Duration.fromMillis(
          DateTime.fromISO(req.startDatetime) -
            DateTime.fromISO(
              callRecords[index + page * callRecordsLimit + 1].endDatetime
            )
        )
          .shiftTo('hours', 'minutes', 'seconds')
          .toFormat("mm'm' ss's'"),
      duration: Duration.fromMillis(
        DateTime.fromISO(req.endDatetime) - DateTime.fromISO(req.startDatetime)
      )
        .shiftTo('hours', 'minutes', 'seconds')
        .toFormat("mm'm' ss's'"),
      prettyEndTime: DateTime.fromISO(req.endDatetime).toLocaleString(
        DateTime.DATETIME_SHORT_WITH_SECONDS
      ),
      prettyStartTime: DateTime.fromISO(req.startDatetime).toLocaleString(
        DateTime.DATETIME_SHORT_WITH_SECONDS
      ),
      prettyToNumber: FROM_NUMBERS.find(num =>
        num.number.includes(req.toNumber)
      )
        ? (
          <Typography sx={{ fontSize: '14px', color: '#1565c0' }}>
            {FROM_NUMBERS.find(num => num.number.includes(req.toNumber)).name}
          </Typography>
          )
        : (
            req.toNumber
          ),
      prettyFromNumber: FROM_NUMBERS.find(num =>
        num.number.includes(req.fromNumber)
      )
        ? (
          <Typography sx={{ fontSize: '14px', color: '#1565c0' }}>
            {FROM_NUMBERS.find(num => num.number.includes(req.fromNumber)).name}
          </Typography>
          )
        : (
            req.fromNumber
          )
    }))

export const callbackRequestsForTable = (
  callbackRequestsForUser,
  callbackRequestsPage,
  callbacksRequestLimit,
  users
) =>
  callbackRequestsForUser
    .slice(
      callbackRequestsPage * callbacksRequestLimit,
      callbackRequestsPage * callbacksRequestLimit + callbacksRequestLimit
    )
    .map(req => ({
      ...req,
      timeToFirstResponse:
        req.attempts.length > 0
          ? Duration.fromMillis(
              DateTime.fromISO(
                req.attempts
                  .slice()
                  .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))[0]
                  .timestamp
              ) - DateTime.fromISO(req.createdAt)
            )
              .shiftTo('hours', 'minutes', 'seconds')
              .toFormat("mm'm' ss's'")
          : 'None',
      attempts:
        req.attempts.length > 0
          ? req.attempts.map(attempt => (
            <>
              {userNameById(attempt.user_id, users)}&nbsp;
              {DateTime.fromISO(attempt.timestamp).toLocaleString(
                DateTime.DATETIME_FULL
              )}
              <br />
            </>
            ))
          : [],
      requestedAt: DateTime.fromISO(req.createdAt).toLocaleString({
        weekday: 'short',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }),
      isSunday: DateTime.fromISO(req.createdAt)
        .toLocaleString({
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        })
        .includes('Sun'),
      completedAt: req.completedAt
        ? DateTime.fromISO(req.completedAt).toLocaleString(
            DateTime.DATETIME_FULL
          )
        : 'No answer'
    }))

export const FilterBar = ({
  totalCount,
  setFilter,
  setOrder,
  filter,
  setLimit,
  limit,
  setPage,
  name
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [editableFilter, setEditableFilter] = useState(filter)

  const handleClickUpdate = () => {
    setFilter(editableFilter)
  }

  return (
    <Grid container sx={{ padding: '.5rem' }}>
      <Grid item xs={7} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ color: '#777' }}>{name} &#8212;&nbsp;</Typography>
        <span style={{ fontSize: '16px' }}>
          Results based on {limit > totalCount ? totalCount : limit} records
          {filter.created_at && (
            <span style={style.filterChip}>
              {filter.created_at.ge &&
                DateTime.fromISO(filter.created_at.ge).toFormat('ccc DD tt')}
              &#8212;
              {filter.created_at.le &&
                DateTime.fromISO(filter.created_at.le).toFormat('ccc DD tt')}
            </span>
          )}
        </span>
      </Grid>
      <Grid
        item
        xs={5}
        sx={{ ml: 'auto', display: 'flex', justifyContent: 'flex-end' }}
      >
        <FormControl
          size='small'
          fullWidth
          sx={{ maxWidth: '140px', mr: '.5rem' }}
        >
          <InputLabel>Max results</InputLabel>
          <Select
            size='small'
            value={limit}
            onChange={e => e.target.value && setLimit(e.target.value)}
            label='max results'
          >
            <MenuItem
              onClick={e => {
                e.stopPropagation()
                setLimit(totalCount)
              }}
            >
              See all
            </MenuItem>
            {[50, 100, 200, 500, 750, 1000, 2500, 3000, 5000, 10000, 15000].map(
              item => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>

        <Button
          sx={{ maxWidth: '140px' }}
          onClick={e => setAnchorEl(e.target)}
          disableElevation
          variant='contained'
          placeholder='from'
        >
          Filter Dates
        </Button>

        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Popover
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            open={!!anchorEl}
          >
            <Grid container sx={{ padding: '1rem', minWidth: '450px' }}>
              <Grid item xs={6} sx={style.filterButtonsGroup}>
                {filterBarButtonPrettyNames.map(item => (
                  <Button
                    key={item}
                    disableElevation
                    sx={{ mb: '.5rem' }}
                    variant='outlined'
                    onClick={() =>
                      handleClickDateRangeButtonCreatedAt(
                        item,
                        setEditableFilter
                      )}
                  >
                    {item}
                  </Button>
                ))}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pl: '.5rem'
                }}
              >
                <DateTimePicker
                  inputFormat='MMM dd, yyyy hh:mm'
                  label='starting from'
                  maxDate={DateTime.fromJSDate(new Date())}
                  value={
                    (editableFilter.created_at &&
                      editableFilter.created_at.ge) ||
                    null
                  }
                  onChange={newValue =>
                    setEditableFilter({
                      ...editableFilter,
                      created_at: {
                        ...editableFilter.created_at,
                        ge: newValue ? newValue.toISO() : null
                      }
                    })}
                  renderInput={startProps => <TextField {...startProps} />}
                />

                <DateTimePicker
                  inputFormat='MMM dd, yyyy hh:mm'
                  label='ending on'
                  maxDate={DateTime.fromJSDate(new Date())}
                  value={
                    (editableFilter.created_at &&
                      editableFilter.created_at.le) ||
                    null
                  }
                  onChange={newValue =>
                    setEditableFilter({
                      ...editableFilter,
                      created_at: {
                        ...editableFilter.created_at,
                        le: newValue ? newValue.toISO() : null
                      }
                    })}
                  renderInput={startProps => (
                    <TextField sx={{ mt: '1rem' }} {...startProps} />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: '1rem'
                }}
              >
                <Button
                  disableElevation
                  sx={{ minWidth: '200px' }}
                  variant='contained'
                  onClick={handleClickUpdate}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Popover>
        </LocalizationProvider>
        <Button
          onClick={() => {
            setFilter({})
            setOrder({ created_at: 'desc' })
            setPage && setPage(0)
          }}
          fullWidth
          sx={{ maxWidth: '100px' }}
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  )
}
