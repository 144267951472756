import { useContext, useEffect, useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { UserContext } from 'UserStore'
import { Box, Button, Collapse, Dialog, Table, TableBody, TableHead, TextField, Tooltip } from '@mui/material'
import { DeleteRounded, EditRounded } from '@mui/icons-material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

export const PriceCategoryDialog = ({ lineItem, open, setOpen, refetch }: any) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const handleClickDeletePriceCategory = () => {
    window.confirm(`are you sure you want to update delete this line item?`) &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_price_category`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          category_id: lineItem.id,
        }),
      }).then(res => {
        if (res) {
          setTimeout(() => refetch(), 250)
          setOpen(false)
        } else {
          console.log('ERROR!')
        }
      })
  }

  const handleClickEditPriceCategory = () => {
    const newName = prompt("Enter a new name for the pricing category", lineItem.name)
    if (!!newName?.toString().trim()) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_price_category_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          category_id: lineItem.id,
          updated_attributes: {
            name: newName.trim(),
            updated_by_user_id: user.id,
          },
        }),
      }).then(res => {
        if (res) {
          refetch()
        } else {
          console.log('ERROR!')
        }
      })
    }
  }

  return (
    <>
      {open && (
        <Dialog
          onClose={() => setOpen(false)}
          open={open}
          PaperProps={{
            sx: { minWidth: '800px ' },
          }}
        >
          <Box
            sx={{
              padding: '.5rem 1rem',
              background: 'aliceblue',
              borderBottom: '2px solid #a1cff7',
              fontWeight: 500,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '& span': {
                fontSize: '11px',
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '18px' }}>{lineItem.name}</span>{' '}
              <Button
                onClick={ handleClickEditPriceCategory }
                color='warning'
                size='small'
                sx={{ minWidth: '30px', minHeight: '30px', marginLeft: '.5rem' }}
              >
                <EditRounded fontSize='small' />
              </Button>
              <Button
                onClick={handleClickDeletePriceCategory}
                color='warning'
                size='small'
                sx={{ minWidth: '30px', minHeight: '30px', marginLeft: '.5rem' }}
              >
                <DeleteRounded fontSize='small' />
              </Button>
            </Box>
            <span style={{ color: '#777' }}>PRICE CATEGORY</span>
          </Box>
          <Box>
            {lineItem.pricePoints.length > 0 && (
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Panel Category</TableCell>
                    <TableCell>Dealer Price</TableCell>
                    <TableCell>Point Amount</TableCell>
                    <TableCell>Commission Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lineItem.pricePoints.map((pricePoint: any) => (
                    <PricePointRow key={pricePoint.id} pricePoint={pricePoint} refetch={refetch} />
                  ))}
                </TableBody>
              </Table>
            )}
            {lineItem.categoriesWithoutPricePoints.length > 0 && (
              <>
                <Box
                  sx={{
                    padding: '.5rem 1rem',
                    borderBottom: '2px solid aliceblue',
                    fontWeight: 500,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '& span': {
                      fontSize: '11px',
                    },
                  }}
                >
                  <span style={{ color: '#777' }}>CATEGORIES WITHOUT PRICING</span>
                </Box>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Panel Category</TableCell>
                      <TableCell>Dealer Price</TableCell>
                      <TableCell>Point Amount</TableCell>
                      <TableCell>Commission Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lineItem.categoriesWithoutPricePoints.map((category: any) => (
                      <CategoryRow key={category.id} pricingCatogory={lineItem} category={category} refetch={refetch} />
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </Box>
        </Dialog>
      )}
    </>
  )
}

const CategoryRow = ({ pricingCatogory, category, refetch }: any) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const categoryName = category.name
  const [price, setPrice] = useState<string | number>('')
  const [commissionAmount, setCommissionAmount] = useState<string | number>('')
  const [pointAmount, setPointAmount] = useState<string | number>('')

  const handleCreatePricePoint = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_b_to_b_price_point`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        b_to_b_panel_group_id: category.id,
        pricing_category_id: pricingCatogory.id,
        price: price,
        commission_amount: commissionAmount || 0,
        user_id: user.id,
        point_amount: pointAmount,
      }),
    }).then(res => {
      if (res) {
        refetch()
      } else {
        console.log('ERROR!')
      }
    })
  }

  return (
    <TableRow key={category.id}>
      <TableCell>{categoryName}</TableCell>
      <TableCell
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <TextField
          fullWidth
          size='small'
          value={price}
          type='number'
          // @ts-ignore
          onChange={e =>
            Number(e.target.value) > 999 || Number(e.target.value) < 0 || Number(e.target.value) === 0
              ? setPrice('')
              : setPrice(Number(e.target.value))
          }
        />
      </TableCell>
      <TableCell
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
            width: '80px',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <TextField
          fullWidth
          size='small'
          value={pointAmount}
          type='number'
          // @ts-ignore
          onChange={e =>
            Number(e.target.value) > 999 || Number(e.target.value) < 0 || Number(e.target.value) === 0
              ? setPointAmount('')
              : setPointAmount(Number(e.target.value))
          }
        />
      </TableCell>
      <TableCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <TextField
          fullWidth
          size='small'
          value={commissionAmount}
          type='number'
          // @ts-ignore
          onChange={e =>
            Number(e.target.value) > 999 || Number(e.target.value) < 0
              ? setCommissionAmount('')
              : setCommissionAmount(Number(e.target.value))
          }
        />
        <Collapse orientation='horizontal' in={price != ''} sx={{ padding: '0rem .25rem' }}>
          {price !== '' && (
            <Button onClick={handleCreatePricePoint} variant='contained' size='small'>
              Create
            </Button>
          )}
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

const PricePointRow = ({ pricePoint, refetch }: any) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const categoryName = pricePoint.panelGroup.name
  const [price, setPrice] = useState(pricePoint.price)
  const [commissionAmount, setCommissionAmount] = useState(pricePoint.commissionAmount)
  const [pointAmount, setPointAmount] = useState(pricePoint.pointAmount)

  const priceNeedsSaving = price !== pricePoint.price
  const comissionNeedsSaving = commissionAmount !== pricePoint.commissionAmount
  const pointAmountNeedsSaving = pointAmount !== pricePoint.pointAmount

  const handleSaveAttribute = (name: string, attribute: string) => {
    window.confirm(`are you sure you want to update the ${name} to ${attribute}?`) &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_price_point_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          price_point_id: pricePoint.id,
          updated_attributes: {
            [name]: attribute,
            updated_by_user_id: user.id,
          },
        }),
      }).then(res => {
        if (res) {
          refetch()
        } else {
          console.log('ERROR!')
        }
      })
  }

  const handleClickDelete = () => {
    window.confirm(`are you sure you want to update delete this line item?`) &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_price_point`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          price_point_id: pricePoint.id,
        }),
      }).then(res => {
        if (res) {
          setTimeout(() => refetch(), 250)
        } else {
          console.log('ERROR!')
        }
      })
  }

  useEffect(() => {
    setPrice(pricePoint.price)
    setCommissionAmount(pricePoint.commissionAmount)
    setPointAmount(pricePoint.pointAmount)
  }, [pricePoint])

  return (
    <TableRow key={pricePoint.id}>
      <TableCell>{categoryName}</TableCell>
      <TableCell
        sx={{
          '& fieldset': {
            borderColor: priceNeedsSaving ? 'orange !important' : '',
            borderWidth: priceNeedsSaving ? '2px !important' : '',
          },
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <Tooltip arrow open={priceNeedsSaving} title='Press enter to save changes'>
          <TextField
            fullWidth
            size='small'
            value={price}
            type='number'
            // @ts-ignore
            onKeyDown={e => e.code === 'Enter' && Number(e.target.value) > 0 && handleSaveAttribute('price', e.target.value)}
            onChange={e =>
              Number(e.target.value) > 999 || Number(e.target.value) < 0 || Number(e.target.value) === 0
                ? setPrice('')
                : setPrice(Number(e.target.value))
            }
          />
        </Tooltip>
      </TableCell>
      <TableCell
        sx={{
          '& fieldset': {
            borderColor: pointAmountNeedsSaving ? 'orange !important' : '',
            borderWidth: pointAmountNeedsSaving ? '2px !important' : '',
          },
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
            width: '100px',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <Tooltip arrow open={pointAmountNeedsSaving} title='Press enter to save changes'>
          <TextField
            fullWidth
            size='small'
            value={pointAmount}
            type='number'
            // @ts-ignore
            onKeyDown={e => e.code === 'Enter' && handleSaveAttribute('pointAmount', e.target.value)}
            onChange={e =>
              Number(e.target.value) > 999 || Number(e.target.value) < 0 || Number(e.target.value) === 0
                ? setPointAmount('')
                : setPointAmount(Number(e.target.value))
            }
          />
        </Tooltip>
      </TableCell>
      <TableCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& fieldset': {
            borderColor: comissionNeedsSaving ? 'orange !important' : '',
            borderWidth: comissionNeedsSaving ? '2px !important' : '',
          },
          '& .MuiInputBase-root': {
            paddingLeft: '.5rem',
          },
          '& input': {
            padding: '.25rem',
          },
        }}
      >
        <Tooltip arrow open={comissionNeedsSaving} title='Press enter to save changes'>
          <TextField
            fullWidth
            type='number'
            size='small'
            value={commissionAmount}
            // @ts-ignore
            onKeyDown={e => e.code === 'Enter' && handleSaveAttribute('commissionAmount', e.target.value)}
            onChange={e =>
              Number(e.target.value) > 999 || Number(e.target.value) < 0 || Number(e.target.value) === 0
                ? setCommissionAmount('')
                : setCommissionAmount(Number(e.target.value))
            }
          />
        </Tooltip>

        <Button onClick={() => handleClickDelete()} color='error' size='small' sx={{ ml: 'auto', minWidth: '40px' }}>
          <DeleteRounded fontSize='small' />
        </Button>
      </TableCell>
    </TableRow>
  )
}
