import React, { useEffect, useState, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Link } from 'react-router-dom'
import { UserContext } from 'UserStore'
import { GlobalContext } from 'GlobalStore'
import { DateTime } from 'luxon'
import { MentionsInput, Mention } from 'react-mentions'
import mentionsClassNames from './compactMentions.module.css'
import Linkify from 'linkifyjs/react'

const NOTES_QUERY = gql`
  query notes($parentId: ID!, $parentType: String!) {
    notes(parentId: $parentId, parentType: $parentType, includeDescendents: true) {
      id
      userType
      userId
      parentType
      parentId
      createdAt
      body
      replyToId
      resourcePath
    }
  }
`

const CompactReadOnlyNotes = ({parentType, parentId}) => {
  const [global] = useContext(GlobalContext)
  const { loading, error, data } = useQuery(NOTES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      parentId,
      parentType
    }
  })

  const userById = userId => global.users.find(user => user.id === userId)

  if (loading) return <div>Loading</div>
  if (error) return <div>Error</div>

  const sortByCreatedAt = (a,b) =>
    DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt) ? 1 : -1

  const nestedNotes =
    [...data.notes]
    .filter(note => !note.replyToId)
    .sort(sortByCreatedAt)
    .map(note =>
      [
        note,
        data.notes.filter(_note => _note.replyToId === note.id)
        .sort(sortByCreatedAt)
      ]
    )

  const TheNote = ({note, replies}) => {
    let user = userById(note.userId)

    return <div
      style={{
        margin: '0 0 5px 1em',
        borderLeft: '1px solid gray',
        paddingLeft: '1em'
      }}
    >
      {user.firstName} {user.lastName}
      &nbsp;
      <Link to={note.resourcePath} target="_blank">
        {note.parentType}
      </Link>
      &nbsp;
      <small>
        {DateTime.fromISO(note.createdAt).toFormat('yyyy-LL-dd')}
      </small>

      <MentionsInput
        className='mentions'
        classNames={mentionsClassNames}
        value={note.body}
        disabled
      >
        <Mention
          displayTransform={(id, display) => `@${display}`}
          style={{backgroundColor: 'rgb(215 255 170)'}}
          appendSpaceOnAdd={true}
        />
      </MentionsInput>
      {replies && replies.map(_note => <TheNote note={_note} />)}
    </div>
  }

  return <>
    {nestedNotes.length === 0 && 'no notes'}
    {nestedNotes.map(([note, replies]) => <TheNote note={note} replies={replies} />)}
  </>
}

export default CompactReadOnlyNotes
