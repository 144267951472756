import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from 'UserStore'
import { useFormik } from 'formik'
import ValidationSchema from '../ValidationScheme'
import { Grid, TextField, Box, Typography, Button, InputAdornment, CircularProgress } from '@mui/material'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'

import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { PhoneInput, ZipInput, fieldHasError, fieldHelperText, diff } from 'tools'
import StateSelect from 'components/StateSelect'

const ShowEdit = ({ partVendor }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)

  const onSubmit = data => {
    const diffs = diff(data, partVendor, ['userId'])

    if (Object.keys(diffs).length === 0) {
      return
    }

    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_part_vendor`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        partVendorId: partVendor.id,
        updatedAttributes: diffs
      })
    })
      .then(res => res.ok || window.alert('Error'))
  }

  const getInitialValues = (data) => ({
    userId: user.id,
    name: data.name || '',
    googleMapUrl: data.googleMapUrl || '',
    addressLineOne: data.addressLineOne || '',
    addressLineTwo: data.addressLineTwo || '',
    city: data.city || '',
    state: data.state || '',
    zip: data.zip || '',
    phone: data.phone || '',
    email: data.email || '',
    website: data.website || '',
    paymentNotes: data.paymentNotes || ''
  })

  const formik = useFormik({
    initialValues: getInitialValues(partVendor),
    validationSchema: ValidationSchema,
    onSubmit: onSubmit
  })

  const handleAddressPasted = (e) => {
    const str = e.clipboardData.getData('Text')
    if (str) {
      const split = str.split(', ')
      if (split.length === 3) {
        const stateZip = split[2].split(' ')

        formik.setValues({
          ...formik.values,
          addressLineOne: split[0],
          city: split[1],
          state: stateZip[0],
          zip: stateZip[1]
        })
      }
    }
  }

  const removeQueryString = (e) => {
    if (e.target.value) {
      formik.setFieldValue('website', e.target.value.split('?')[0], { shouldValidate: true })
    }
  }

  useEffect(() => {
    formik.resetForm({
      values: getInitialValues(partVendor)
    })
    setWaiting(false)
  }, [partVendor])

  const saveDisabled = Object.keys({
    ...diff(formik.values, partVendor, ['userId'])
  }).length === 0

  return (
    <>
      <Typography variant='h5' sx={{ m: 1 }}>{partVendor.name}</Typography>

      <Grid container spacing={2} sx={{ mt: 1, pr: 2 }}>
        <Grid item xs={12} lg={8}>
          <Box
            sx={{
              '& .MuiTextField-root': {
                m: 1,
                width: '100%'
              },
              mb: 1,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  name='name'
                  label='Merchant Name'
                  autoFocus
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={fieldHasError(formik, 'name')}
                  helperText={fieldHelperText(formik, 'name')}
                  disabled={waiting}
                />
                <TextField
                  required
                  inputProps={{
                    placeholder: 'https://google.com/maps/place/...'
                  }}
                  name='googleMapUrl'
                  label='Google Maps URL'
                  value={formik.values.googleMapUrl}
                  onChange={formik.handleChange}
                  error={fieldHasError(formik, 'googleMapUrl')}
                  helperText={fieldHelperText(formik, 'googleMapUrl')}
                  disabled={waiting}
                />
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <ContentPasteIcon color='action' />
                      </InputAdornment>
                    )
                  }}
                  label='Paste Address From Google Maps Here To Autofill Address'
                  name='paste-address'
                  value=''
                  onPaste={handleAddressPasted}
                  disabled={waiting}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Typography variant='h6' sx={{ ml: 1 }}>Address</Typography>
                <TextField
                  required
                  fullWidth
                  name='addressLineOne'
                  label='Address Line 1'
                  variant='outlined'
                  value={formik.values.addressLineOne}
                  onChange={formik.handleChange}
                  error={fieldHasError(formik, 'addressLineOne')}
                  helperText={fieldHelperText(formik, 'addressLineOne')}
                  disabled={waiting}
                />
                <TextField
                  fullWidth
                  label='Address Line 2'
                  name='addressLineTwo'
                  variant='outlined'
                  value={formik.values.addressLineTwo}
                  onChange={formik.handleChange}
                  disabled={waiting}
                />
                <TextField
                  required
                  fullWidth
                  name='city'
                  label='City'
                  variant='outlined'
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={fieldHasError(formik, 'city')}
                  helperText={fieldHelperText(formik, 'city')}
                  disabled={waiting}
                />
                <StateSelect
                  required
                  variant='outlined'
                  name='state'
                  style={{ margin: '8px', width: '100%' }}
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  error={fieldHasError(formik, 'state')}
                  helperText={fieldHelperText(formik, 'state')}
                  disabled={waiting}
                />
                <TextField
                  required
                  fullWidth
                  label='Zip'
                  name='zip'
                  variant='outlined'
                  value={formik.values.zip}
                  disabled={waiting}
                  onChange={formik.handleChange}
                  error={fieldHasError(formik, 'zip')}
                  helperText={fieldHelperText(formik, 'zip')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputComponent: ZipInput }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant='h6' sx={{ ml: 1 }}>Contact</Typography>
                <TextField
                  required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  name='phone'
                  label='Phone'
                  variant='outlined'
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  InputProps={{ inputComponent: PhoneInput }}
                  error={fieldHasError(formik, 'phone')}
                  helperText={fieldHelperText(formik, 'phone')}
                  disabled={waiting}
                />
                <TextField
                  fullWidth
                  inputProps={{
                    type: 'email'
                  }}
                  name='email'
                  label='Email'
                  variant='outlined'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={fieldHasError(formik, 'email')}
                  helperText={fieldHelperText(formik, 'email')}
                  disabled={waiting}
                />
                <TextField
                  fullWidth
                  inputProps={{
                    type: 'url',
                    placeholder: 'http://www.example.com'
                  }}
                  name='website'
                  label='Merchant Website'
                  onPaste={removeQueryString}
                  variant='outlined'
                  value={formik.values.website}
                  onChange={removeQueryString}
                  error={fieldHasError(formik, 'website')}
                  helperText={fieldHelperText(formik, 'website')}
                  disabled={waiting}
                />
                <TextField
                  fullWidth
                  name='paymentNotes'
                  label='Payment Notes'
                  variant='outlined'
                  value={formik.values.paymentNotes}
                  onChange={formik.handleChange}
                  disabled={waiting}
                />
              </Grid>
            </Grid>
          </Box>
          <Button
            variant='outlined'
            sx={{ m: 1 }}
            style={{ minWidth: '157px' }}
            disabled={waiting || saveDisabled}
            onClick={() => formik.resetForm()}
          >Reset
          </Button>
          <Button
            variant='contained'
            color='primary'
            sx={{ m: 1 }}
            style={{ minWidth: '157px' }}
            disabled={waiting || saveDisabled}
            onClick={formik.handleSubmit}
          >
            {waiting
              ? <CircularProgress size={18} />
              : <>Save</>}
          </Button>
        </Grid>

        <Grid item xs={12} lg={6} />
      </Grid>
    </>
  )
}

export default ShowEdit
