import { useState, useContext } from 'react'
import { Dialog, Select, Box, Button, Typography, MenuItem } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UserContext } from 'UserStore'
import { CloseRounded } from '@mui/icons-material'

const ChangeTechnicianDialog = ({ quote, appointment, refetch }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const { approvedTechnicians } = quote
  const [user] = useContext(UserContext)
  const [open, setOpen] = useState(false)
  const [selectedTechnician, setSelectedTechnician] = useState(appointment.technicianId)
  const saveButtonIsDisabled = selectedTechnician === appointment.technicianId

  const handleUpdateAppointmentTechnician = e =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/change_appointment_technician`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        appointment_id: appointment.id,
        technician_id: selectedTechnician,
      }),
    })
      .then(() => setOpen(false))
      .then(() => refetch())
      .catch(() => alert('An error occurred.'))

  return (
    <>
      <Dialog open={open}>
        <Box sx={{ minWidth: '600px' }}>
          <Box
            sx={{
              padding: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#3f51b5',
              color: '#fff',
            }}
          >
            <Typography variant='h6'>Change Technician</Typography>
            <Button onClick={() => setOpen(false)} disableRipple sx={{ color: '#fff', minWidth: '30px' }}>
              <CloseRounded />
            </Button>
          </Box>
          <Box sx={{ background: '#f1f1f1', padding: '1rem', borderBottom: '1px solid #ddd' }}>
            <span cy-data='approved-technicians-num'>{approvedTechnicians.length}</span> Approved Technician
            {approvedTechnicians.length !== 1 && 's'} for this quote
          </Box>
          <Box sx={{ padding: '1rem' }}>
            <Select
              name='approvedTechnicians'
              value={selectedTechnician}
              onChange={e => setSelectedTechnician(e.target.value)}
            >
              {approvedTechnicians.map(tech => (
                <MenuItem
                  cy-data={selectedTechnician === tech.id ? 'selected-technician' : 'technician-option'}
                  key={tech.id}
                  value={tech.id}
                >
                  {tech.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              background: '#f1f1f1',
              borderTop: '1px solid #ddd',
              padding: '.5rem 1rem',
            }}
          >
            <Button
              cy-data='save-changed-technician-button'
              disabled={saveButtonIsDisabled}
              onClick={() => handleUpdateAppointmentTechnician(selectedTechnician)}
              variant='contained'
            >
              Save
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Button
        disabled={appointment.canceledAt || appointment.approvedAt || !appointment.locationVerifiedAt}
        size='small'
        variant='outlined'
        onClick={() => setOpen(true)}
      >
        Change technician
      </Button>
    </>
  )
}

export default ChangeTechnicianDialog
