import { createContext } from 'react'
import useLocalForage from 'hooks/useLocalForage'

export const UserContext = createContext()

const UserStore = ({ children }) => {
  const [user, setUser, ready] = useLocalForage('user', {})

  if (!ready) return <></>
  return <UserContext.Provider value={[user, ready]}>{children}</UserContext.Provider>
}

export default UserStore
