import React from 'react'
import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'

import ShowID from './ShowID'

const Router = () => {
  const { path } = useRouteMatch()
  const { technicianId } = useParams()

  return (
    <Switch>
      <Route path={path}>
        <ShowID technicianId={technicianId} />
      </Route>
    </Switch>
  )
}

export default Router
