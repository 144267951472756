import { Grid } from '@mui/material'
import { Dollars } from 'tools'

const getCommissionPointsAndPrice = ({ lineItem }) => {
  if (lineItem.customPricePoint) {
    return {
      commissionAmount: lineItem.customPricePoint.commissionAmount,
      points: lineItem.pricePoint.pointAmount,
    }
  } else if (lineItem.pricePoint) {
    return {
      commissionAmount: lineItem.pricePoint.commissionAmount,
      points: lineItem.pricePoint.pointAmount,
    }
  } else {
    return {
      commissionAmount: lineItem.commissionAmount,
      points: 0,
    }
  }
}

export const ShowLineItem = ({ lineItem }) => {
  const hasSide = !!lineItem.side
  const { points, commissionAmount } = getCommissionPointsAndPrice({ lineItem })

  return (
    <Grid
      container
      style={{
        padding: '4px',
        borderBottom: '1px dashed lightsteelblue',
      }}
    >
      <Grid item xs={10} sx={{ p: '4px', textTransform: 'capitalize' }}>
        {lineItem.panel} {hasSide && ` - ${lineItem.side} Side`} - {lineItem.type}
      </Grid>

      <Grid item xs={2} sx={{ textAlign: 'right', p: '4px' }}>
        {`$${lineItem.price}`}
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'right', fontSize: '12px', padding: '.25rem', fontWeight: 700 }}>
        {points || 0} POINT{points !== 1 && 'S'}, <Dollars value={commissionAmount || 0} /> COMMISSION
        {lineItem.customPricePoint && (
          <>
            <br />
            <b style={{ fontSize: '11px' }}>*Points and commission inherited from dealer</b>
          </>
        )}
      </Grid>
    </Grid>
  )
}
