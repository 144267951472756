import axios from 'axios'

const bearerTokenHeaders = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage?.getItem('token')}`,
}

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_COMMAND_ROOT}`,
  headers: bearerTokenHeaders,
})

// text-davinci-003
// text-curie-001
// text-babbage-001
// text-ada-001

export interface OpenAIResponse {
  data: {
    id?: string
    object: string
    created: number
    model: string
    choices: {
      text: string
      index: number
      logprobs: unknown
      finish_reason: string
    }[]
    usage: {
      prompt_tokens: number
      completion_tokens: number
      total_tokens: number
    }
  }
}

export interface OpenAIRequest {
  model?: string
  prompt?: string
  max_tokens?: number
  temperature?: number
  top_p?: number
  n?: number
  stream?: boolean
  logprobs?: unknown
  stop?: string
}

const beareTokenOpenAI = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
}

// text-davinci-003
// text-curie-001
// text-babbage-001
// text-ada-001

export interface OpenAIResponse {
  data: {
    id?: string
    object: string
    created: number
    model: string
    choices: {
      text: string
      index: number
      logprobs: unknown
      finish_reason: string
    }[]
    usage: {
      prompt_tokens: number
      completion_tokens: number
      total_tokens: number
    }
  }
}

export interface OpenAIRequest {
  model?: string
  prompt?: string
  max_tokens?: number
  temperature?: number
  top_p?: number
  n?: number
  stream?: boolean
  logprobs?: unknown
  stop?: string
}

export const openai_model = 'gpt-3.5-turbo-instruct'

export const openai = axios.create({
  baseURL: `${process.env.REACT_APP_OPENAI_URL}`,
  headers: beareTokenOpenAI,
})
