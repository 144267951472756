import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  MenuItem,
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  Chip,
  Autocomplete,
  Grid,
} from '@mui/material'
import ContactsRounded from '@mui/icons-material/ContactsRounded'
import { Save } from '@mui/icons-material'
import STATES from 'states.json'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { editableUserFields, userRoles } from './tools'
import { API_COMMAND_HEADERS } from 'tools'

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
const phoneNumberRegex = /^\+1\d{10}$/
const zipRegex = /^\d{5}$/

const userSchema = Yup.object().shape({
  status: Yup.string(),
  email: Yup.string()
    .matches(emailRegex, 'Must match this form: tech@example.com')
    .required(),
  phone: Yup.string()
    .matches(
      phoneNumberRegex,
      'Invalid phone number. Must match this form: +1XXXxxxXXXX'
    )
    .nullable(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  zip: Yup.string().matches(zipRegex, 'Must have 5 numbers only.').nullable(),
  roles: Yup.array().of(Yup.string()),
})

const NewUser = () => {
  const history = useHistory()
  const initialValues = {
    status: 'active',
    firstName: '',
    lastName: '',
    zip: '',
    phone: '',
    email: '',
    city: '',
    state: '',
    addressLineOne: '',
    addressLineTwo: '',
    roles: [],
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: userSchema,
    onSubmit: values => {
      // using diff here breaks sub array
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([k, v]) => v !== initialValues[k])
      )

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/api/create_user`, {
        method: 'POST',
        headers: API_COMMAND_HEADERS,
        body: JSON.stringify({
          ...values,
        }),
      }).then(() => history.push('/users'))
    },
  })

  const handleReset = () => {
    formik.setValues(initialValues)
  }

  const stateMenuItems = Object.entries(STATES).map(([k, v]) => (
    <MenuItem key={k} value={k}>
      {v}
    </MenuItem>
  ))

  const validationErrors = Object.values(
    Object.keys(formik.errors).reduce((acc, k) => {
      acc[k] = formik.errors[k]
      return acc
    }, {})
  )

  return (
    <Grid container sx={{ '& .MuiFormControl-root': { margin: '.5rem' } }}>
      <Grid item xs={6}>
        <div>
          New CRM User
          <ContactsRounded style={{ color: '#fff' }} />
        </div>

        <div>
          {editableUserFields.map(inputValue => (
            <TextField
              key={inputValue}
              variant='outlined'
              size='small'
              label={inputValue}
              name={inputValue}
              value={formik.values[inputValue]}
              onChange={formik.handleChange}
              error={
                formik.touched[inputValue] && Boolean(formik.errors[inputValue])
              }
              helperText={
                formik.touched[inputValue] && formik.errors[inputValue]
              }
            />
          ))}
          <FormControl
            sx={{ minWidth: '100px' }}
            size='small'
            variant='outlined'
          >
            <InputLabel>State</InputLabel>
            <Select
              label='State'
              name='state'
              value={formik.values.state}
              onChange={formik.handleChange}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {stateMenuItems}
            </Select>
          </FormControl>
          <FormControl size='small' variant='outlined'>
            <InputLabel>Status</InputLabel>
            <Select
              label='Status'
              name='status'
              value={formik.values.status}
              onChange={formik.handleChange}
            >
              <MenuItem value='active'>
                <em>Active</em>
              </MenuItem>
              <MenuItem value='inactive'>
                <em>Inactive</em>
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <div>
          <Autocomplete
            freeSolo
            multiple
            size='small'
            options={userRoles}
            name='roles'
            value={formik.roles}
            onChange={(e, v) => formik.setFieldValue('roles', v)}
            renderInput={markets => <TextField {...markets} label='roles' />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant='outlined'
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </div>

        <div style={{ padding: '1rem' }}>
          *<b>only</b> first name, last name and email are required.
        </div>

        <div>
          {validationErrors.length > 0 && (
            <>
              <br />
              <div style={{ color: 'red' }}>
                {validationErrors.map(error => (
                  <div key={error}>{error}</div>
                ))}
              </div>
              <br />
            </>
          )}
        </div>
        <Button
          variant='contained'
          color='primary'
          disabled={!formik.dirty || !formik.isValid}
          type='submit'
          onClick={formik.handleSubmit}
        >
          <Save />
          Save
        </Button>
        <Button onClick={() => history.goBack()}>Cancel</Button>
        <Button disabled={!formik.dirty} onClick={handleReset}>
          Reset
        </Button>
      </Grid>
    </Grid>
  )
}

export default NewUser
