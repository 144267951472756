import { useEffect, useState, useContext, useRef } from 'react'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import { DateTime } from 'luxon'
import Button from '@mui/material/Button'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { useChannel, useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import { prettyPhoneNumber } from 'tools'
import 'react-dropzone-uploader/dist/styles.css'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useCustomerSMSRephraser } from 'hooks/customer/sms-chat/useCustomerSMSRephraser'

import {
  Typography,
  TextField,
  Modal,
  Grid,
  LinearProgress,
  Box,
  Stack,
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  Collapse,
} from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton'

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
// import VisibilitySensor from 'react-visibility-sensor-v2'
// a reminder for myself to implement this,
// only load the applicant pics that are visible
import HelpRequests from './HelpRequests'
import { ChevronRight, CheckCircle, CloseRounded } from '@mui/icons-material'

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 320,
      padding: '0rem',
      borderRadius: '6px',
      boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)',
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #ddd',
    },
  })
)

const RecruitingSmsChat = ({ applicant, contextType, contextId, contextObject }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [messages, setMessages] = useState([])
  const [global] = useContext(GlobalContext)
  const [user] = useContext(UserContext)
  const [body, setBody] = useState('')
  const [photoModalUrl, setPhotoModalUrl] = useState(null)
  const [waiting, setWaiting] = useState(false)
  const [selectedPrefab, setSelectedPrefab] = useState(null)
  const { rephrase, rephraseLoading } = useCustomerSMSRephraser()
  const inputRef = useRef(null)

  const handleGetPrefab = e => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_prefab_text`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        contextType,
        contextId,
        type: e,
      }),
    })
      .then(res => res.json())
      .then(data => {
        setBody(data.text.trim())
      })
      .catch(() => alert('Error'))
  }

  const prefabTooltipOptionsByContextType = (() => {
    switch (contextType) {
      case 'JobApplication':
        return [
          ['offerInterview', 'Offer Interview Time'],
        ].map(([value, title]) => (
          <ListItem
            onClick={() => {
              setSelectedPrefab(title)
              handleGetPrefab(value)
              setTimeout(() => inputRef.current?.focus(), 0)
            }}
            key={value}
            disablePadding
            sx={{
              '& .MuiListItemButton-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
            }}
          >
            <ListItemButton>
              {title} <ChevronRight />
            </ListItemButton>
          </ListItem>
        ))
      default:
        return ''
    }
  })()
  const scrollToBottom = () => {
    const element = document.getElementById('smsList')
    if (element) {
      element.scrollTop = element.scrollHeight - element.clientHeight
    }
  }

  const channel = useChannel(`applicant_smss:${applicant.id}`, undefined, (channel, { applicantSmss }) => {
    setMessages(
      applicantSmss.map((message, index) => ({
        ...message,
        renderPhotos: index < 5,
      }))
    )
    setTimeout(scrollToBottom, 300)
  })

  useEventHandler(channel, 'applicant_sms_created', ({ applicantSms: message }) => {
    setMessages(messages => [...messages, { ...message, renderPhotos: true }])
    setTimeout(scrollToBottom, 250)
  })

  useEventHandler(channel, 'applicant_sms_updated', ({ applicantSms: message }) => {
    setMessages(prev => prev.map(thisMsg => (message.id === thisMsg.id ? { ...message, renderPhotos: true } : thisMsg)))
  })

  const createOutgoingSms = () => {
    setWaiting(true)
    body.length > 0 &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_outbound_applicant_sms`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          applicantId: applicant.id,
          body: body.trim(),
          contextType,
          contextId,
        }),
      })
        .then(response => {
          if (response.ok) {
            setBody('')
            scrollToBottom()
            return true
          } else {
            alert('Error')
          }
        })
        .finally(() => setTimeout(() => setWaiting(false), 500))
  }

  const createOutgoingSmsAndLeaveTimestamp = () => {
    setWaiting(true)
    const endpointToUse = () => {
      switch (selectedPrefab) {
        case 'offerInterview':
          return 'mark_interview_offered'
        default:
          return ''
      }
    }
    body.length > 0 &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_outbound_applicant_sms`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          applicantId: applicant.id,
          body: body.trim(),
          contextType,
          contextId,
        }),
      })
        .then(response => {
          if (response.ok) {
            setBody('')
            scrollToBottom()
            // UPDATE THE SPECIFIED TIMESTAMP ON THE LEAD
            fetch(`${process.env.REACT_APP_COMMAND_ROOT}/${endpointToUse()}`, {
              method: 'POST',
              headers: bearerTokenHeaders,
              body: JSON.stringify({
                userId: user.id,
                lead_id: contextId,
                quote_id: contextId,
              }),
            })

            setSelectedPrefab(null)
            return true
          } else {
            alert('Error')
          }
        })
        .finally(() => setTimeout(() => setWaiting(false), 500))
  }

  const userById = userId => global.users.find(user => user.id === userId)
  const userName = userId => {
    const user = userById(userId)
    return `${user.firstName} ${user.lastName[0]}`
  }

  const photos = message => {
    if (!message.mediaUrls) return null

    const photoWidthNumber = Math.floor(Math.max(300 / message.mediaUrls.length, 120))
    const photoWidthPx = `${photoWidthNumber}px`
    const photoHeightNumber = Math.floor(Math.max(120 / message.mediaUrls.length, 45))
    const photoHeightPx = `${photoHeightNumber}px`

    return (
      <>
        {message.mediaUrls.map(mediaUrl => (
          <div
            key={mediaUrl}
            style={{
              border: '2px solid #efefef',
              display: 'inline-block',
              width: photoWidthPx,
              height: photoHeightPx,
            }}
          >
            <img
              src={mediaUrl}
              style={{
                objectFit: 'cover',
                height: '100%',
                width: '100%',
                cursor: 'pointer',
              }}
              onClick={() => setPhotoModalUrl(mediaUrl)}
            />
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ padding: '.25rem', textAlign: 'center', borderBottom: '1px solid #f1f1f1' }}>
          <Typography variant='body1'>
            SMS with <b>{applicant.fullName}</b>
            &nbsp;
            <a href={`carbodylab-phone://dial?toNumber=${applicant.phone}&fromNumber=${applicant.outboundNumber}`}>
              {prettyPhoneNumber(applicant.phone)}
            </a>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box id='smsList' style={{ overflow: 'auto', maxHeight: window.innerHeight * 0.5 }}>
            {messages.map(message => (
              <div key={message.id}>
                {message.userId ? (
                  <OutboundMessage message={message} applicant={applicant} photos={photos} setMessages={setMessages} />
                ) : (
                  <InboundMessage applicant={applicant} message={message} photos={photos} />
                )}
              </div>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ background: !!selectedPrefab && selectedPrefab !== 'other' ? '#f0fff1' : '' }}>
          <Collapse in={!!selectedPrefab && selectedPrefab !== 'other'}>
            <Box
              sx={{
                padding: '0rem .5rem',
                background: '#f0fff1',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderTop: '1px solid #3f51b540',
              }}
            >
              <Typography variant='body1' sx={{ fontSize: '12px', color: '#222' }}>
                <b> SYSTEM ACTION:</b> {selectedPrefab}
              </Typography>
              <Button
                onClick={() => {
                  setSelectedPrefab(null)
                  setBody('')
                }}
                disableRipple
                sx={{ color: '#222', minWidth: '30px' }}
              >
                <CloseRounded />
              </Button>
            </Box>
          </Collapse>
          <HtmlTooltip
            arrow
            sx={{ display: !!selectedPrefab ? 'none' : '' }}
            placement='top'
            title={
              <Box>
                <Typography
                  sx={{
                    borderBottom: '1px solid #ddd',
                    color: '#777',
                    fontSize: '16px',
                    fontWeight: 500,
                    padding: '.5rem',
                  }}
                  variant='h1'
                >
                  About to do any of the following?
                </Typography>
                <List
                  sx={{
                    '& .MuiListItem-root': {
                      background: '#fff',
                      '&:hover': { cursor: 'pointer', background: '#f1f1f1' },
                    },
                  }}
                >
                  {prefabTooltipOptionsByContextType}
                  <ListItem
                    disablePadding
                    sx={{
                      '& .MuiListItemButton-root': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      },
                    }}
                    onClick={() => {
                      setTimeout(() => inputRef.current?.focus(), 0)
                      setSelectedPrefab('other')
                    }}
                  >
                    <ListItemButton>
                      Other <ChevronRight />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            }
          >
            <TextField
              style={{ width: 'calc(100% - 1rem)', margin: '0px .5rem', background: '#fff' }}
              label='...'
              multiline
              onBlur={() => body === '' && setSelectedPrefab(null)}
              inputRef={inputRef}
              disabled={!selectedPrefab}
              value={body || ''}
              loading={rephraseLoading}
              onChange={e => setBody(e.target.value)}
            />
          </HtmlTooltip>
          <Collapse in={!!selectedPrefab && selectedPrefab !== 'other'}>
            <Box
              loading={rephraseLoading}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                background: '#f0fff1',
                padding: '.5rem 1rem',
                flexDirection: 'row',
              }}
            >
              <LoadingButton
                size='small'
                loading={rephraseLoading}
                variant='outlined'
                onClick={() =>
                  rephrase(body, newText => {
                    setBody(newText)
                  })
                }
              >
                Rephrase
              </LoadingButton>

              <Button variant='contained' onClick={createOutgoingSmsAndLeaveTimestamp}>
                Send
              </Button>
            </Box>
          </Collapse>
          <Collapse in={!selectedPrefab || selectedPrefab === 'other'}>
            <Box sx={{ display: 'flex', p: 1 }}>
              <Box sx={{ flexGrow: 1 }}>
                <FormControl
                  size='small'
                  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <LoadingButton
                    size='small'
                    loading={rephraseLoading}
                    variant='outlined'
                    onClick={() =>
                      rephrase(body, newText => {
                        setBody(newText)
                      })
                    }
                  >
                    Rephrase
                  </LoadingButton>

                  <Button
                    disabled={waiting || body?.length < 2}
                    style={{ marginTop: '5px' }}
                    variant='outlined'
                    color='primary'
                    onClick={createOutgoingSms}
                  >
                    send
                  </Button>
                </FormControl>
              </Box>
            </Box>
          </Collapse>
        </Grid>
      </Grid>

      <Modal open={!!photoModalUrl} onClose={() => setPhotoModalUrl(null)}>
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            paddingTop: '64px',
          }}
          onClick={() => setPhotoModalUrl(null)}
        >
          <PinchZoomPan position='center' maxScale={2}>
            <img src={photoModalUrl} />
          </PinchZoomPan>

          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              marginBottom: '1em',
              padding: '0.5em 0',
              backgroundColor: 'white',
              textAlign: 'center',
            }}
          >
            <CopyToClipboard style={{ cursor: 'pointer' }} text={photoModalUrl}>
              <span>
                Photo url: (click to copy)
                <br />
                {photoModalUrl}
              </span>
            </CopyToClipboard>

            <div
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '8px',
                right: '8px',
              }}
            >
              [click anywhere to close]
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

const InboundMessage = ({ applicant, message, photos }) => (
  <div
    style={{
      padding: '0.5em',
      margin: '0.25rem',
      borderRadius: '12px 12px 12px 0px',
      border: '1px solid rgb(165 255 242 / 64%)',
      backgroundColor: 'rgb(165 255 242 / 32%)',
    }}
  >
    <Typography>
      <b>{applicant.firstName}</b>&nbsp;
      <small style={{ color: 'gray' }}>
        {DateTime.fromISO(message.createdAt).toLocaleString(DateTime.DATETIME_FULL)}
      </small>
    </Typography>

    <div style={{ padding: '0.5em' }}>{message.body}</div>
    {photos(message)}
  </div>
)

const OutboundMessage = ({ message, photos, setMessages }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [editing, setEditing] = useState(false)
  const [editableBody, setEditableBody] = useState(message.body)
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const userById = userId => global.users.find(user => user.id === userId)
  const userName = userId => {
    const user = userById(userId)
    return `${user.firstName} ${user.lastName[0]}`
  }

  const handleDeleteStoppedSms = id =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_stopped_applicant_sms`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        applicantSmsId: id,
      }),
    }).then(res => (res.ok ? setMessages(_messages => _messages.filter(message => message.id !== id)) : alert('Error')))

  const handleSaveNewBody = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_body_of_stopped_applicant_sms`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        applicantSmsId: message.id,
        body: editableBody.trim(),
      }),
    }).then(res => res.ok || alert('Error'))

  const handleQueueDelivery = () =>
    !editing &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/queue_delivery_of_stopped_applicant_sms`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        applicantSmsId: message.id,
      }),
    }).then(res => res.ok || alert('Error'))

  return (
    <div
      style={{
        padding: '0.5em',
        margin: '0.25rem',
        background: '#f9f9f9',
        borderRadius: '12px 12px 0px 12px',
        border: '1px solid #f1f1f1',
      }}
    >
      <b>{userName(message.userId)}</b>&nbsp;
      <small style={{ color: '#747474' }}>
        {message.deliveredAt &&
          ` (delivered ${DateTime.fromISO(message.deliveredAt).toLocaleString(DateTime.DATETIME_SHORT)})`}

        {!message.deliveredAt && message.attemptDeliveryAt && <PendingDelivery message={message} />}

        {!message.deliveredAt && !message.attemptDeliveryAt && (
          <Box sx={{ width: '100%' }}>
            <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={1}>
              <span
                style={{
                  color: editing ? 'gray' : 'black',
                  cursor: editing ? 'not-allowed' : 'pointer',
                }}
                onClick={handleQueueDelivery}
              >
                [ deliver ]
              </span>

              <span>delivery stopped</span>

              {message.userId === user.id && (
                <span style={{ cursor: 'pointer', color: 'gray' }} onClick={() => setEditing(true)}>
                  [ edit{`${editing ? 'ing' : ''}`} ]
                </span>
              )}

              {message.userId === user.id && (
                <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteStoppedSms(message.id)}>
                  [ delete ]
                </span>
              )}
            </Stack>
          </Box>
        )}
      </small>
      <div style={{ whiteSpace: 'pre-wrap', padding: '0.2em 0.8em' }}>
        {(editing && (
          <>
            <TextField
              style={{ width: '100%' }}
              size='small'
              multiline
              value={editableBody}
              onChange={e => setEditableBody(e.target.value)}
            />
            <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={1}>
              <span style={{ cursor: 'pointer', color: 'gray' }} onClick={() => setEditing(false)}>
                [cancel]
              </span>
              <span style={{ cursor: 'pointer', color: 'black' }} onClick={handleSaveNewBody}>
                [save]
              </span>
            </Stack>
          </>
        )) ||
          message.body}
      </div>
      {photos(message)}
    </div>
  )
}
const PendingDelivery = ({ message }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [nowDateTime, setNowDateTime] = useState(DateTime.local())
  const [user] = useContext(UserContext)
  const tickTheClock = () => setNowDateTime(DateTime.local())

  const handleStopDelivery = id => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/stop_applicant_sms_delivery`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        applicantSmsId: id,
      }),
    }).then(res => res.ok || alert('Error'))
  }

  useEffect(() => {
    const interval = setInterval(tickTheClock, 1000)
    return () => clearInterval(interval)
  }, [])

  const startedCountdownAt = DateTime.fromISO(message.createdAt).toSeconds()

  const floor = DateTime.fromISO(message.createdAt).toSeconds()
  const ceiling = DateTime.fromISO(message.attemptDeliveryAt).toSeconds()

  const distanceToTravel = ceiling - floor
  const distanceTraveled = nowDateTime.toSeconds() - startedCountdownAt

  const secondsRemaining = Math.floor((distanceToTravel - distanceTraveled).toFixed(0), 0)
  const percentTraveled = (distanceTraveled / distanceToTravel) * 100

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant='determinate' value={percentTraveled} />

      {message.userId === user.id && (
        <>
          sending in {secondsRemaining} sec. &nbsp;
          {secondsRemaining > 1 && (
            <span style={{ cursor: 'pointer' }} onClick={() => handleStopDelivery(message.id)}>
              [stop delivery]
            </span>
          )}
        </>
      )}
    </Box>
  )
}

export default RecruitingSmsChat
