export default [
  'complaint',
  'complaint_resolved',
  'hot',
  'insurance',
  'interested',
  'lost',
  'lost_no_photos',
  'lost_no_vin',
  'maybe',
  'no_way_post_booking',
  'property_manager',
  'sold',
  'specify',
]
