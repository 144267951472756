import React, { useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Autocomplete,
  Box,
  Fab,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import HistoryIcon from '@mui/icons-material/History'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

import { UserContext } from 'UserStore'
import { diff, API_COMMAND_HEADERS } from 'tools'
import STATES from 'states.json'

const MARKETS_QUERY = gql`
  query markets {
    markets {
      state
      stateCode
      market
    }
  }
`

const VEHICLE_YEARS_QUERY = gql`
  query fgVehicleYears {
    fgVehicleYears
  }
`

const VEHICLE_MAKE_QUERY = gql`
  query fgVehicleMakes($year: String) {
    fgVehicleMakes(year: $year) {
      makeId
      makeName
      makeShortName
    }
  }
`

const VEHICLE_MODEL_QUERY = gql`
  query fgVehicleModels($year: String, $makeShortName: String) {
    fgVehicleModels(year: $year, makeShortName: $makeShortName) {
      modelId
      modelName
      modelShortName
    }
  }
`

// use states.json
const stateMenuItems = Object.entries(STATES).map(([k, v]) => (
  <MenuItem key={v} value={k}>
    {v}
  </MenuItem>
))

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
const phoneNumberRegex = /^\d{10}$/
const fourDigitRegex = /^[0-9]{4}$/
const zipRegex = /^\d{5}$/
const vinRegex = /[A-HJ-NPR-Z0-9]{17}$/

const leadSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().matches(emailRegex, 'Email must match this form: tech@example.com').required(),
  phone: Yup.string().matches(phoneNumberRegex, 'Invalid phone number. Must match this form: XXXxxxXXXX').required(),
  city: Yup.string().min(1, 'Must have city').required(),
  state: Yup.string().required(),
  market: Yup.string().required(),
  zip: Yup.string().matches(zipRegex, 'Zip must have 5 numbers only.').required(),
  year: Yup.string().matches(fourDigitRegex, 'Year must have 4 numbers only.').required(),
  repair_location: Yup.string().required(),
  vin: Yup.string().matches(vinRegex, 'VIN must have 17 numbers or letters without I or O.').nullable(),
})

const Edit = ({ lead }) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const initialValues = {
    name: lead.name,
    email: lead.email,
    phone: lead.phone,
    description: lead.description,
    address_line_one: lead.addressLineOne,
    address_line_two: lead.addressLineTwo,
    city: lead.city,
    state: lead.state,
    zip: lead.zip,
    year: lead.year,
    make_short_name: lead.makeShortName,
    model_short_name: lead.modelShortName,
    repair_location: lead.repairLocation,
    market: lead.market,
    vin: lead.vin,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: leadSchema,
    onSubmit: values => {
      const filteredValues = diff(values, initialValues)
      if (filteredValues.make_short_name) {
        const prettyMake = makesData.fgVehicleMakes.find(
          m => m.makeShortName === filteredValues.make_short_name
        )?.makeName
        if (prettyMake) filteredValues.make = prettyMake
      }
      if (filteredValues.model_short_name) {
        const prettyModel = modelsData.fgVehicleModels.find(
          m => m.modelShortName === filteredValues.model_short_name
        )?.modelName
        if (prettyModel) filteredValues.model = prettyModel
      }

      fetch(process.env.REACT_APP_COMMAND_ROOT + '/update_lead_attributes', {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          lead_id: lead.id,
          updated_attributes: filteredValues,
        }),
      })
    },
  })

  const handleReset = () => formik.setValues(initialValues)

  const { data: marketsData, loading: marketsLoading, error: marketsError } = useQuery(MARKETS_QUERY)

  const { data: yearsData, loading: yearsLoading, error: yearsError } = useQuery(VEHICLE_YEARS_QUERY)

  const {
    data: makesData,
    loading: makesLoading,
    error: makesError,
  } = useQuery(VEHICLE_MAKE_QUERY, {
    variables: {
      year: formik.values.year + '',
    },
  })

  const {
    data: modelsData,
    loading: modelsLoading,
    error: modelsError,
  } = useQuery(VEHICLE_MODEL_QUERY, {
    variables: {
      year: formik.values.year + '',
      makeShortName: formik.values.make_short_name,
    },
  })

  const getMakeOptionLabel = make => {
    const dataMake = makesData.fgVehicleMakes.find(m => m.makeShortName === make)
    return dataMake ? dataMake.makeName : make
  }

  const getModelOptionLabel = model => {
    const dataModel = modelsData.fgVehicleModels.find(m => m.modelShortName === model)
    return dataModel ? dataModel.modelName : model
  }

  if (marketsLoading || yearsLoading || makesLoading || modelsLoading) return <div>Loading...</div>
  if (marketsError) return JSON.stringify(marketsError)
  if (yearsError) return JSON.stringify(yearsError)
  if (makesError) return JSON.stringify(makesError)
  if (modelsError) return JSON.stringify(modelsError)

  const states = {}
  const marketMenuItemsByState = {}
  marketsData.markets.forEach(market => {
    states[market.stateCode] = market.state
    marketMenuItemsByState[market.stateCode] = marketMenuItemsByState[market.stateCode] || []
    marketMenuItemsByState[market.stateCode].push(
      <MenuItem key={market.market} value={market.market}>
        {market.market}
      </MenuItem>
    )
  })
  // prefer states.json, this is the code for actual market states
  // const stateMenuItems = Object.entries(states).map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)
  const validationErrors = Object.values(formik.errors)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box
            component='form'
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
              '& .MuiFormControl-root': { m: 1 },
            }}
            noValidate
            autoComplete='off'
            onSubmit={formik.handleSubmit}
          >
            <Typography variant='h6'>Edit lead</Typography>

            <div>
              <TextField
                variant='outlined'
                size='small'
                label='Name'
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                variant='outlined'
                size='small'
                label='Email'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                disabled
                variant='outlined'
                size='small'
                label='Phone (xxxXXXxxxx)'
                name='phone'
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
              <FormControl size='small' variant='outlined'>
                <InputLabel>Year</InputLabel>
                <Select
                  label='Year'
                  name='year'
                  value={formik.values.year + ''}
                  onChange={(e, v) => formik.handleChange(e, +v)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {yearsData.fgVehicleYears.map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
                sx={{ display: 'inline-block' }}
                size='small'
                options={makesData.fgVehicleMakes.map(make => make.makeShortName).sort()}
                value={formik.values.make_short_name}
                freeSolo
                selectOnFocus
                clearOnBlur
                onChange={(e, v) => formik.setFieldValue('make_short_name', v ? v : initialValues.make_short_name)}
                getOptionLabel={getMakeOptionLabel}
                getOptionSelected={make => make === formik.values.make_short_name}
                renderInput={params => <TextField {...params} label='Make' />}
              />
              <Autocomplete
                sx={{ display: 'inline-block' }}
                size='small'
                options={modelsData.fgVehicleModels.map(model => model.modelShortName).sort()}
                value={formik.values.model_short_name}
                freeSolo
                selectOnFocus
                clearOnBlur
                onChange={(e, v) => formik.setFieldValue('model_short_name', v ? v : initialValues.model_short_name)}
                getOptionLabel={getModelOptionLabel}
                getOptionSelected={model => model === formik.values.model_short_name}
                renderInput={params => <TextField {...params} label='Model' />}
              />
              <TextField
                variant='outlined'
                size='small'
                fullWidth
                style={{ width: '100%' }}
                label='VIN'
                name='vin'
                value={formik.values.vin}
                onChange={formik.handleChange}
                error={formik.touched.vin && Boolean(formik.errors.vin)}
                helperText={formik.touched.vin && formik.errors.vin}
                inputProps={{ maxLength: 17 }}
              />
              <TextField
                variant='outlined'
                size='small'
                fullWidth
                style={{ width: '100%' }}
                label='Address Line 1'
                name='address_line_one'
                value={formik.values.address_line_one}
                onChange={formik.handleChange}
                error={formik.touched.address_line_one && Boolean(formik.errors.address_line_one)}
                helperText={formik.touched.address_line_one && formik.errors.address_line_one}
              />
              <br />
              <TextField
                variant='outlined'
                size='small'
                fullWidth
                style={{ width: '100%' }}
                label='Address Line 2'
                name='address_line_two'
                value={formik.values.address_line_two}
                onChange={formik.handleChange}
              />
            </div>
            <div>
              <TextField
                variant='outlined'
                size='small'
                label='City'
                value={formik.values.city}
                name='city'
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
              <FormControl size='small' variant='outlined'>
                <InputLabel>State</InputLabel>
                <Select label='State' name='state' value={formik.values.state} onChange={formik.handleChange}>
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {stateMenuItems}
                </Select>
              </FormControl>
              <TextField
                variant='outlined'
                size='small'
                label='Zip'
                name='zip'
                value={formik.values.zip}
                onChange={formik.handleChange}
                error={formik.touched.zip && Boolean(formik.errors.zip)}
                helperText={formik.touched.zip && formik.errors.zip}
              />
            </div>

            <div>
              <FormControl>
                <InputLabel>Market</InputLabel>
                <Select
                  size='small'
                  required
                  label='Market'
                  name='market'
                  value={formik.values.market || ''}
                  onChange={formik.handleChange}
                >
                  <MenuItem value=''>
                    <em>none: (must select market to book a job)</em>
                  </MenuItem>
                  {marketMenuItemsByState[formik.values.state]}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: '200px' }}>
                <InputLabel>Repair Location</InputLabel>
                <Select
                  size='small'
                  required
                  label='Repair Location'
                  name='repair_location'
                  value={formik.values.repair_location || ''}
                  onChange={formik.handleChange}
                >
                  <MenuItem value=''>
                    <em>none: (must select repair location to book a job)</em>
                  </MenuItem>
                  {['apartment', 'office', 'house', 'help'].map(item => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <br />

            <div style={{ color: 'red' }}>
              {validationErrors.map(error => (
                <div key={error}>{error}</div>
              ))}
            </div>

            <div>
              <Fab
                cy-data='save-button'
                variant='extended'
                color='primary'
                style={{ zIndex: '999' }}
                disabled={!formik.dirty || !formik.isValid}
                onClick={formik.handleSubmit}
                aria-label='save'
              >
                <SaveIcon />
                Save
              </Fab>
              &nbsp; &nbsp;
              <Fab
                variant='extended'
                color='primary'
                style={{ zIndex: '999' }}
                disabled={!formik.dirty}
                onClick={handleReset}
                aria-label='reset'
              >
                <HistoryIcon style={{ color: 'orange' }} />
                Reset
              </Fab>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Edit
