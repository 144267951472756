import { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import {
  Box,
  Button,
  Typography,
  FormControl,
  TextField,
  Popover,
  Autocomplete,
  Badge,
  Collapse,
  InputAdornment,
} from '@mui/material'
import { v4 as uuid } from 'uuid'
import { LaborDamagesContext, hourlyRateFields } from './PrimaryLaborItems'
import { DateTime } from 'luxon'
import { InfoRounded } from '@mui/icons-material'
import { useLocalStorage } from 'hooks/useLocalStorage'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const PANELS_QUERY = gql`
  query PrimaryLaborDamages {
    primaryLaborDamages {
      id
      sides
      panel
      type
      category
      locations
      totalHours
      prepHours
      bodyWorkHours
      paintHours
      minorPartHours
      majorPartHours
    }
  }
`

const checkIfFoundPanel = (selectedAttribute, damageList) => {
  if (selectedAttribute === 'damage') {
    const sideOptions =
      (damageList &&
        [...new Set(damageList?.map(item => item.sides))]?.filter(i => i !== 'N/A').flatMap(item => item.split('/'))) ||
      []

    const locationOptions =
      (damageList &&
        [...new Set(damageList?.map(item => item.locations))]
          ?.filter(i => i !== 'N/A')
          .flatMap(item => item.split('/'))) ||
      []

    return locationOptions.length === 0 && sideOptions.length === 0
  }

  if (selectedAttribute === 'location') {
    const sideOptions =
      (damageList &&
        [...new Set(damageList?.map(item => item.sides))]?.filter(i => i !== 'N/A').flatMap(item => item.split('/'))) ||
      []

    return sideOptions.length === 0
  }

  if (selectedAttribute === 'side') {
    const locationOptions =
      (damageList &&
        [...new Set(damageList?.map(item => item.locations))]
          ?.filter(i => i !== 'N/A')
          .flatMap(item => item.split('/'))) ||
      []

    return locationOptions.length === 0
  }
}

export const PreFilledHourlyLaborPopover = ({ hourlyRates, handleLaborSelected, primaryLaborItems }) => {
  const [hourlyLaborItemPopoverElement, setHourlyLaborItemPopoverElement] = useState(null)
  const [selectedPanel, setSelectedPanel] = useState('')
  const [selectedDamage, setSelectedDamage] = useState('')
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedSide, setSelectedSide] = useState('')
  const isFirstPanel = primaryLaborItems?.length === 0
  const [panelMarkdown, setPanelMarkdown] = useLocalStorage('panelMarkdownPrice', 20, false)
  const [panelMarkdownInMongo, setPanelMarkdownInMongo] = useState(panelMarkdown)
  const [selectedOption, setSelectedOption] = useState()
  const { setLaborDamageItems } = useContext(LaborDamagesContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const actualPanelMarkdownAmount = panelMarkdown / 100 + 1

  const getMarkedDownNumber = amount =>
    isFirstPanel ? amount : amount - Number((amount * actualPanelMarkdownAmount - amount).toFixed(2))

  const [hourlyTotals, setHourlyTotals] = useState({
    totalPrepHours: 0,
    totalPaintHours: 0,
    totalBodyWorkHours: 0,
    totalMajorPartHours: 0,
    totalMinorPartHours: 0,
  })

  const prepCost = hourlyTotals.totalPrepHours * hourlyRates.prep
  const paintCost = hourlyTotals.totalPaintHours * hourlyRates.paint
  const bodyWorkCost = hourlyTotals.totalBodyWorkHours * hourlyRates.bodyWork
  const majorPartCost = hourlyTotals.totalMajorPartHours * hourlyRates.majorPart
  const minorPartCost = hourlyTotals.totalMinorPartHours * hourlyRates.minorPart

  const totalLaborCost = prepCost + paintCost + bodyWorkCost + majorPartCost + minorPartCost

  const totalHours = Number(
    (
      hourlyTotals.totalPrepHours +
      hourlyTotals.totalPaintHours +
      hourlyTotals.totalBodyWorkHours +
      hourlyTotals.totalMajorPartHours +
      hourlyTotals.totalMinorPartHours
    ).toFixed(2)
  )

  const handleAddLaborItem = () => {
    handleLaborSelected({
      damageId: selectedOption.id,
      id: uuid(),
      type: selectedDamage,
      name: `${selectedPanel}${selectedSide && ` (${selectedSide})`}${selectedLocation && ` (${selectedLocation})`}${
        selectedOption.category !== 'N/A' ? ` - ${selectedOption.category}` : ''
      }`,
      side: selectedSide || 'None',
      price: Number(totalLaborCost.toFixed(2)),
      totalPrepHours: hourlyTotals.totalPrepHours,
      totalPaintHours: hourlyTotals.totalPaintHours,
      totalBodyWorkHours: hourlyTotals.totalBodyWorkHours,
      totalMajorPartHours: hourlyTotals.totalMajorPartHours,
      totalMinorPartHours: hourlyTotals.totalMinorPartHours,
      isHourly: true,
      totalHours: totalHours,
      panel: selectedPanel,
      createdAt: DateTime.now().toISO(),
    })
    setHourlyLaborItemPopoverElement(null)
    setSelectedPanel('')
    setSelectedDamage('')
    setSelectedSide('')
    setSelectedLocation('')
    setHourlyTotals({
      totalPrepHours: 0,
      totalPaintHours: 0,
      totalBodyWorkHours: 0,
      totalMajorPartHours: 0,
      totalMinorPartHours: 0,
    })
  }

  const { loading, error, data, refetch } = useQuery(PANELS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  const getMarkdownPercent = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/get_second_panel_markdown_percent`, {
      method: 'GET',
    }).then(res =>
      res.json().then(res => {
        setPanelMarkdown(res.percentage)
        setPanelMarkdownInMongo(res.percentage)
      })
    )

  const saveMarkdownPercent = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_second_panel_markdown_percent`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        percentage: Number(panelMarkdown),
      }),
    }).then(res =>
      res.json().then(res => {
        setPanelMarkdown(res.percentage)
        setPanelMarkdownInMongo(res.percentage)
      })
    )

  useEffect(() => {
    getMarkdownPercent()
  }, [])

  useEffect(() => {
    data && setLaborDamageItems(data?.primaryLaborDamages)
  }, [data])

  const availableOptions =
    (data?.primaryLaborDamages && [
      ...new Set(
        data?.primaryLaborDamages?.filter(
          d =>
            d.panel === selectedPanel &&
            d.type === selectedDamage &&
            ((selectedLocation !== '' && d.locations?.includes(selectedLocation)) || true) &&
            ((selectedSide !== '' && d.sides?.includes(selectedSide)) || true)
        )
      ),
    ]) ||
    []

  const sideOptions =
    (data?.primaryLaborDamages &&
      [
        ...new Set(
          data?.primaryLaborDamages
            ?.filter(d => d.panel === selectedPanel && d.type === selectedDamage)
            ?.map(item => item.sides)
        ),
      ]
        ?.filter(i => i !== 'N/A')
        .flatMap(item => item.split('/'))) ||
    []

  const locationOptions =
    (data?.primaryLaborDamages &&
      [
        ...new Set(
          data?.primaryLaborDamages
            ?.filter(d => d.panel === selectedPanel && d.type === selectedDamage)
            ?.map(item => item.locations)
        ),
      ]
        ?.filter(i => i !== 'N/A')
        .flatMap(item => item.split('/'))) ||
    []

  const setHourTotalsForItem = selectedOption => {
    setSelectedOption(selectedOption)
    setHourlyTotals({
      totalPrepHours: getMarkedDownNumber(selectedOption?.prepHours),
      totalPaintHours: getMarkedDownNumber(selectedOption?.paintHours),
      totalBodyWorkHours: selectedOption?.bodyWorkHours,
      totalMajorPartHours: selectedOption?.majorPartHours,
      totalMinorPartHours: selectedOption?.minorPartHours,
    })
  }

  useEffect(() => {
    selectedOption && setHourTotalsForItem(selectedOption)
  }, [panelMarkdown])

  if (error) return <div>Error!</div>

  const primaryLaborDamages = data?.primaryLaborDamages || []
  const panelOptions = [...new Set(primaryLaborDamages?.map(item => item.panel))]

  const damageOptions =
    (data?.primaryLaborDamages && [
      ...new Set(data?.primaryLaborDamages?.filter(d => d.panel === selectedPanel)?.map(item => item.type)),
    ]) ||
    []

  const shouldDisableAddButton = Number(totalHours) === 0 || selectedPanel === '' || selectedDamage === ''

  const markdownPercentNeedsSaving = panelMarkdownInMongo !== panelMarkdown

  return (
    <Box>
      <Button
        cy-data='add-hourly-labor-item-button'
        size='small'
        onClick={e => setHourlyLaborItemPopoverElement(e.target)}
        variant='outlined'
      >
        Add Labor Item
      </Button>
      <Popover
        open={!!hourlyLaborItemPopoverElement}
        anchorEl={hourlyLaborItemPopoverElement}
        onClose={() => setHourlyLaborItemPopoverElement(null)}
        PaperProps={{ sx: { borderRadius: '20px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ padding: '1rem', overflow: 'hidden' }}>
          <FormControl fullWidth size='small' sx={{ mb: '.5rem' }}>
            <Autocomplete
              fullWidth
              options={panelOptions}
              value={selectedPanel}
              onChange={(event, newValue) => {
                setSelectedPanel(newValue)
                setSelectedDamage('')
                setSelectedSide('')
                setSelectedLocation('')
                setHourlyTotals({
                  totalPrepHours: 0,
                  totalPaintHours: 0,
                  totalBodyWorkHours: 0,
                  totalMajorPartHours: 0,
                  totalMinorPartHours: 0,
                })
              }}
              renderInput={params => <TextField fullWidth {...params} size='small' label='Select Panel' />}
            />
          </FormControl>

          <FormControl fullWidth size='small' sx={{ mb: '.5rem' }}>
            <Autocomplete
              fullWidth
              options={damageOptions?.sort()}
              value={selectedDamage}
              onChange={(event, newValue) => {
                setSelectedDamage(newValue)
                setSelectedSide('')
                setSelectedLocation('')

                if (
                  checkIfFoundPanel(
                    'damage',
                    data?.primaryLaborDamages?.filter(d => d.panel === selectedPanel && d.type === newValue)
                  )
                ) {
                  const options = data?.primaryLaborDamages?.filter(
                    d => d.panel === selectedPanel && d.type === newValue
                  )

                  setHourTotalsForItem(options[0])
                } else {
                  setHourlyTotals({
                    totalPrepHours: 0,
                    totalPaintHours: 0,
                    totalBodyWorkHours: 0,
                    totalMajorPartHours: 0,
                    totalMinorPartHours: 0,
                  })
                }
              }}
              renderInput={params => <TextField fullWidth {...params} size='small' label='Select Damage' />}
            />
          </FormControl>

          <Collapse in={sideOptions?.length > 0}>
            <FormControl fullWidth size='small' sx={{ mb: '.5rem' }}>
              <Autocomplete
                fullWidth
                options={sideOptions?.sort()}
                value={selectedSide}
                onChange={(event, newValue) => {
                  setSelectedSide(newValue)
                  setSelectedLocation('')

                  if (
                    checkIfFoundPanel(
                      'side',
                      data?.primaryLaborDamages?.filter(
                        d =>
                          d.panel === selectedPanel &&
                          d.type === selectedDamage &&
                          ((selectedLocation !== '' && d.locations.includes(selectedLocation)) || true) &&
                          d.sides.includes(newValue)
                      ) ||
                        // if this is the last item selected of the 4 options
                        (selectedPanel !== '' && selectedDamage !== '' && selectedLocation !== '')
                    )
                  ) {
                    setHourTotalsForItem(availableOptions[0])
                  } else {
                    setHourlyTotals({
                      totalPrepHours: 0,
                      totalPaintHours: 0,
                      totalBodyWorkHours: 0,
                      totalMajorPartHours: 0,
                      totalMinorPartHours: 0,
                    })
                  }
                }}
                renderInput={params => <TextField fullWidth {...params} size='small' label='Select Side' />}
              />
            </FormControl>
          </Collapse>

          <Collapse in={locationOptions?.length > 0}>
            <FormControl fullWidth size='small' sx={{ mb: '.5rem' }}>
              <Autocomplete
                fullWidth
                options={locationOptions?.sort()}
                value={selectedLocation}
                onChange={(event, newValue) => {
                  setSelectedLocation(newValue)
                  if (
                    checkIfFoundPanel(
                      'location',
                      data?.primaryLaborDamages?.filter(
                        d =>
                          d.panel === selectedPanel &&
                          d.type === selectedDamage &&
                          ((selectedSide !== '' && d.sides.includes(selectedSide)) || true) &&
                          d.locations.includes(newValue)
                      )
                    ) ||
                    // if this is the last item selected of the 4 options
                    (selectedPanel !== '' && selectedDamage !== '' && selectedSide !== '')
                  ) {
                    setHourTotalsForItem(availableOptions[0])
                  } else {
                    setHourlyTotals({
                      totalPrepHours: 0,
                      totalPaintHours: 0,
                      totalBodyWorkHours: 0,
                      totalMajorPartHours: 0,
                      totalMinorPartHours: 0,
                    })
                  }
                }}
                renderInput={params => <TextField fullWidth {...params} size='small' label='Select Location' />}
              />
            </FormControl>
          </Collapse>

          <Box
            sx={{
              background: '#f1f1f1',
              border: '2px solid #ddd',
              padding: '.25rem .5rem .5rem .5rem',
              borderRadius: '12px',
            }}
          >
            <Typography variant='body1' sx={{ mb: '.5rem', textAlign: 'center' }}>
              Expected Hours
            </Typography>
            <Box sx={{ display: 'flex' }}>
              {hourlyRateFields(hourlyRates).map(item => (
                <Box key={item.label}>
                  <TextField
                    value={hourlyTotals[item.key]}
                    onChange={e =>
                      setHourlyTotals(prev => ({
                        ...prev,
                        [item.key]: Number(e.target.value),
                      }))
                    }
                    inputProps={{ min: 0 }}
                    type='number'
                    size='small'
                    sx={{ maxWidth: '80px' }}
                    label={item.label}
                  />
                  <Typography variant='body1' sx={{ textAlign: 'center', mt: '.25rem', fontSize: '14px' }}>
                    ${item.hourlyRate.toFixed(2)}/hr
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>

          {!isFirstPanel && (
            <>
              <Box
                sx={{
                  padding: '1rem .5rem 0rem .5rem',
                  maxWidth: '420px',
                  textAlign: 'left',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '12px',
                  '& svg': { mr: '1rem' },
                  whiteSpace: 'nowrap',
                }}
              >
                <InfoRounded /> 2nd panel mark-down percent
                <TextField
                  sx={{ ml: 'auto', width: '100px' }}
                  type='number'
                  size='small'
                  value={panelMarkdown}
                  onChange={e => setPanelMarkdown(e.target.value)}
                  inputProps={{ min: 0 }}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>%</InputAdornment>,
                  }}
                />
              </Box>

              <Collapse in={markdownPercentNeedsSaving}>
                <Box
                  sx={{
                    padding: '1rem .5rem 0rem .5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button variant='contained' disableElevation onClick={() => saveMarkdownPercent()}>
                    Save markdown percent
                  </Button>
                </Box>
              </Collapse>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#f1f1f1',
            alignItems: 'center',
            padding: '.5rem 1rem',
          }}
        >
          <Box>
            Hours: {totalHours} | Total: ${totalLaborCost.toFixed(2)}
          </Box>
          <Button
            cy-data='addLaborButton'
            onClick={handleAddLaborItem}
            size='small'
            disableElevation
            variant='contained'
            disabled={shouldDisableAddButton}
          >
            Add
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}
