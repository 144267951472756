import { ChevronLeftRounded } from '@mui/icons-material'
import { Box, Breadcrumbs, Button, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { Link, useHistory } from 'react-router-dom'
import { CreateATask } from 'Tasks'

export const BToBWorkOrderCard = ({ part }) => {
  const bToBWorkOrder = part.bToBWorkOrder
  const dealer = part.bToBWorkOrder?.dealer

  const breadCrumbs = [
    <Link to={`/b-to-b/dealers/${dealer.id}`}> Dealer </Link>,
    <Link to={`/b-to-b/work-orders/${bToBWorkOrder.id}`}> Work Order </Link>,
    <Typography>Part</Typography>,
  ]

  return (
    <Box sx={{ mb: '1rem' }}>
      <Box sx={{ background: '#f1faf1', padding: '.5rem' }}>
        <Box>
          <span style={{ marginRight: '.5rem', fontSize: '1.2em' }}>
            <Link to={`/b-to-b/dealers/${dealer.id}`}> {dealer.businessName}</Link>
          </span>
          <span style={{ marginRight: '.5rem' }}>
            {' '}
            {DateTime.fromISO(bToBWorkOrder.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}
          </span>
          <CreateATask targetType='BToBWorkOrder' targetId={bToBWorkOrder.id} />
          <br />
          <span>
            {bToBWorkOrder.year && bToBWorkOrder.make && bToBWorkOrder.model && (
              <strong>
                {bToBWorkOrder.year} - {bToBWorkOrder.make} - {bToBWorkOrder.model}
              </strong>
            )}
          </span>
          {bToBWorkOrder.vin && (
            <span style={{ marginRight: '.5rem' }}>
              <b>VIN: </b>
              {bToBWorkOrder.vin}
            </span>
          )}
        </Box>
      </Box>
      <Breadcrumbs sx={{ display: 'block', padding: '.5rem' }} separator='›'>
        {breadCrumbs}
      </Breadcrumbs>
    </Box>
  )
}
