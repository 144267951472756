import { useEffect, useContext, useState } from 'react'
import { Dollars, basicDateTimeFormatter, keysToCamel } from 'tools'
import { Box, Checkbox, Collapse, CircularProgress, Chip, Dialog } from '@mui/material'
import { Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { DEALERS_QUERY } from '../../../Invoices/tools'
import { SelectBusinessHeadCell } from './SelectBusinessHeadCell'
import { SelectTechnicianHeadCell } from './SelectTechnicianHeadCell'
import { DateRangeHeadCell } from './DateRangeHeadCell'
import { SelectLabelHeadCell } from './SelectLabelHeadCell'
import { VinSearchTextField } from './VinSearchTextField'

export const BToBInvoicesConnection = gql`
  query BToBInvoicesConnection(
    $search: JSON
    $order: JSON
    $filter: JSON
    $first: Int
    $after: String
    $isCompleted: Boolean
    $isAccepted: Boolean
    $hasPayoutData: Boolean
    $excludeFullyPaid: Boolean
    $hasPayoutId: Boolean
    $hasStatement: Boolean
  ) {
    bToBInvoicesConnection(
      search: $search
      order: $order
      filter: $filter
      first: $first
      after: $after
      isCompleted: $isCompleted
      isAccepted: $isAccepted
      hasPayoutData: $hasPayoutData
      excludeFullyPaid: $excludeFullyPaid
      hasPayoutId: $hasPayoutId
      hasStatement: $hasStatement
    ) {
      edges {
        cursor
        node {
          id
          createdAt
          acceptedAt
          businessName
          lineItems
          lineItemsSum
          grandTotal
          discountPercent
          discountPercentTotal
          preJobPhotos
          postJobPhotos
          year
          make
          makeShortName
          model
          modelShortName
          payoutData
          vin
          vinPhoto
          completedAt
          repairOrder
          stock
          deletedAt
          payoutId
          squareInvoiceId
          paidSquareInvoiceId
          projectedPayout
          fullyPaidAt
          invoiceNumber
          ccFee
          invoiceVersion
          dealerId
          emailSentAt
          commissionPercent
          payoutOverrideTotal
          label
          markedAsPromotionalRepairAt
          statementId
          dealer {
            id
            businessName
            addressLineOne
            addressLineTwo
            city
            state
            zip
            primaryContact {
              firstName
              lastName
              email
            }
          }
          technician {
            id
            firstName
            lastName
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const InvoicesTable = ({
  statement = false,
  selectInvoices = (invoiceIds: string[]) => {},
  isAddingToStatement = false,
}) => {
  const [user] = useContext(UserContext)
  const [modalItemId, setModalItemId] = useState(null)

  const [isLoading, setIsLoading] = useState(false)

  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<any>([])
  const [search, setSearch] = useState({})
  const [order, setOrder] = useState({})
  const [filter, setFilter] = useState({})
  const [limit, setLimit] = useState(50)

  const [page, setPage] = useState(0)
  const [pageCeiling, setPageCeiling] = useState(page)
  const [cursorCeiling, setCurrentCeiling] = useState('')

    useEffect(() => {
      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: Number(limit),
          after: '',
          isCompleted: true,
          hasStatement: false,
        },
      })
    }, [search, order, filter, limit])

  const { loading, error, data, fetchMore, refetch } = useQuery(BToBInvoicesConnection, {
    variables: {
      search,
      order,
      filter,
      first: Number(limit),
      after: cursorCeiling,
      isCompleted: true,
      hasStatement: false,
    },
  })

  const { data: dealersData } = useQuery(DEALERS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)

  const invoices = data.bToBInvoicesConnection.edges.map((edge: any) => edge.node)
  const totalCount = data.bToBInvoicesConnection.totalCount
  const endCursor = data.bToBInvoicesConnection.pageInfo.endCursor

  const dealers = dealersData?.bToBDealers || []

  const handleChangePage = (event: any, wantedPage: any) => {
    setPage(wantedPage)

    if (wantedPage > pageCeiling) {
      setPageCeiling(wantedPage)

      fetchMore({
        variables: {
          search,
          order,
          filter,
          first: limit,
          after: endCursor,
        },
      })
    }
  }

  const handleChangeRowsPerPage = (event: any) => {
    setLimit(event.target.value)
    setPage(0)
    setCurrentCeiling('')
  }

  return (
    <>
      <Collapse in={selectedInvoiceIds.length > 0}>
        <Box sx={{ display: 'flex', padding: '1rem', justifyContent: 'flex-end' }}>
          <Button
            disabled={isAddingToStatement}
            onClick={() => selectInvoices(selectedInvoiceIds)}
            size='small'
            variant='contained'
            sx={{ mr: '1rem' }}
          >
            {isAddingToStatement ? 'Adding Selected Invoices' : 'Add Selected Invoices'} ({selectedInvoiceIds.length})
          </Button>
        </Box>
      </Collapse>

      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            background: '#11111140',
            bottom: '0px',
            width: '100%',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color='warning' />
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='right'>
                <Checkbox
                  onClick={() =>
                    setSelectedInvoiceIds(invoices.slice(page * limit, page * limit + limit)?.map((i: any) => i.id))
                  }
                  checked={selectedInvoiceIds?.length === invoices?.length}
                />
              </TableCell>
              <DateRangeHeadCell
                name='created_at'
                filter={filter}
                setFilter={setFilter}
                order={order}
                setOrder={setOrder}
              />
              <DateRangeHeadCell
                name='completed_at'
                filter={filter}
                setFilter={setFilter}
                order={order}
                setOrder={setOrder}
              />
              <SelectTechnicianHeadCell filter={filter} setFilter={setFilter} />
              <SelectBusinessHeadCell filter={filter} setFilter={setFilter} dealers={dealers} />
              <TableCell align='right'>VIN</TableCell>
              <TableCell align='right'>RO #</TableCell>
              <TableCell align='right'>Invoice Total</TableCell>
              <TableCell align='right'>Invoice #</TableCell>
              <SelectLabelHeadCell filter={filter} setFilter={setFilter} />
              <TableCell align='right'>Info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.slice(page * limit, page * limit + limit).map((invoice: any) => (
              <TableRow key={invoice.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  <Checkbox
                    onChange={() =>
                      selectedInvoiceIds.includes(invoice.id)
                        ? setSelectedInvoiceIds((prev: any) => prev.filter((id: any) => id !== invoice.id))
                        : setSelectedInvoiceIds((prev: any) => [...prev, invoice.id])
                    }
                    checked={selectedInvoiceIds.includes(invoice.id)}
                  />
                </TableCell>
                <TableCell>
                  <Link target='_blank' to={`/b-to-b-invoices/${invoice.id}`}>
                    {basicDateTimeFormatter(invoice.createdAt)}
                  </Link>
                </TableCell>
                <TableCell>{basicDateTimeFormatter(invoice.completedAt)}</TableCell>
                <TableCell align='right'>
                  <Link target='_blank' to={`/technicians/${invoice.technician.id}`}>
                    {invoice.technician.firstName}
                    &nbsp;
                    {invoice.technician.lastName}
                  </Link>
                </TableCell>
                <TableCell align='right'>{invoice.businessName}</TableCell>
                <TableCell align='right'>{invoice.vin}</TableCell>
                <TableCell align='right'>{invoice.repairOrder}</TableCell>
                <TableCell align='right'>
                  <b>
                    <Dollars value={invoice.grandTotal || invoice.lineItemsSum} />
                  </b>
                  &nbsp;
                </TableCell>
                <TableCell align='right'>{invoice.invoiceNumber}</TableCell>
                <TableCell align='right'>{invoice.label && <Chip size='small' label={invoice.label} />}</TableCell>
                <TableCell align='right'>
                  {invoice.year}, {invoice.make} {invoice.model}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}
