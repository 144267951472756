import { useEffect, useState, useContext } from 'react'
import {
  Paper, Grid
} from '@mui/material'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import Modal from '@mui/material/Modal'
import Notes from 'components/Notes'
import DescendentNotes from 'components/DescendentNotes'
import TechNotes from 'components/TechNotes'
// import Quotes from './components/Quotes'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { cdnUrl, TLD, thumbnailUrlForPhoto, fullSizeUrlForPhoto, formatPhotoTimestamp } from 'tools'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import StreamDump from 'components/StreamDump'

const Show = ({ customer, lead }) => {
  const [modalPhoto, setModalPhoto] = useState(null)
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)

  useEffect(() => {
    const key = registerTaskTarget({ targetType: 'Lead', targetId: lead.id })
    return () => unregisterTaskTarget(key)
  }, [])

  const modalPhotoIndex = currentPhoto => lead.photos.findIndex(photo => photo.id === currentPhoto.id)

  const stepPhotoBack = () =>
    setModalPhoto(prev =>
      lead.photos[Math.max(modalPhotoIndex(prev) - 1, 0)]
    )

  const stepPhotoForward = () =>
    setModalPhoto(prev =>
      lead.photos[Math.min(modalPhotoIndex(prev) + 1, lead.photos.length - 1)]
    )

  const handleKeyDown = event => {
    switch (event.keyCode) {
      case 37:
      // left
        stepPhotoBack()
        break
      case 38:
      // up
        break
      case 39:
      // right
        stepPhotoForward()
        break
      case 40:
      // down
        break
    }
  }

  useEffect(() => {
    const listener = window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', listener)
  }, [])

  const photoUploaderUrl =
    `https://www.carbodylab.${TLD}/leads/${lead.id}/photo-uploader`

  return (
    <>
      :: SOME SORT OF CUSTOMER-AND-DOWNWARD TREE/BREADCRUMB UI ::
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* <Breadcrumbs lead={lead} activeObject={lead} /> */}
              {/* <Quotes lead={lead} /> */}
            </Grid>

            <Grid item xs={12}>
              {lead.photos.map(photo =>
                <Paper key={photo.id}>
                  <div>
                    <img
                      style={{ width: '100px', height: '100px' }}
                      onClick={() => setModalPhoto(photo)}
                      src={thumbnailUrlForPhoto(photo)}
                    />
                    <div style={{ marginTop: '4px' }}>{formatPhotoTimestamp(photo.timestamp)}</div>
                  </div>
                </Paper>
              )}
            </Grid>

            <Grid item xs={12}>
              <CopyToClipboard
                style={{ cursor: 'pointer' }}
                text={photoUploaderUrl}
              >
                <span>
                  Photo uploader url: (click to copy)
                  <br />
                  {photoUploaderUrl}
                </span>
              </CopyToClipboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Notes objectType='lead' objectId={lead.id} />
              <DescendentNotes objectType='lead' objectId={lead.id} />
            </Grid>
            <Grid item xs={6}>
              <TechNotes parentType='lead' parentId={lead.id} leadId={lead.id} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <StreamDump stream={`Lead$${lead.id}`} />
        </Grid>
      </Grid>

      {modalPhoto &&
        <Modal open={!!modalPhoto} onClose={() => setModalPhoto(null)}>
          <div>
            <div onClick={() => setModalPhoto(null)}>
              X
            </div>

            <PinchZoomPan position='center' maxScale={2}>
              <img src={cdnUrl(modalPhoto ? fullSizeUrlForPhoto(modalPhoto) : '')} />
            </PinchZoomPan>

            {modalPhoto.id !== lead.photos[0].id &&
              <div
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  zIndex: 100,
                  position: 'absolute',
                  left: 0,
                  top: '50%',
                  padding: '0.5em',
                  fontSize: '1.5em',
                  cursor: 'pointer'
                }}
                onClick={stepPhotoBack}
              >
                ◄
              </div>}
            {modalPhoto.id !== lead.photos[lead.photos.length - 1].id &&
              <div
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  zIndex: 100,
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  padding: '0.5em',
                  fontSize: '1.5em',
                  cursor: 'pointer'
                }}
                onClick={stepPhotoForward}
              >
                ►
              </div>}
          </div>
        </Modal>}
    </>
  )
}

export default Show
