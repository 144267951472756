import React, { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { GlobalContext } from 'GlobalStore'
import Typography from '@mui/material/Typography'
import Chat from 'Chat'
import { TaskTargetRegistry } from 'TaskTargetRegistry'

const Technician = () => {
  const { technicianId } = useParams()
  const [global] = useContext(GlobalContext)
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)

  useEffect(() => {
    const key = registerTaskTarget({ targetType: 'TechnicianBToB', targetId: technicianId })
    return () => unregisterTaskTarget(key)
  }, [])

  const techById = techId => global.technicians.find(tech => tech.id === techId)
  const techName = techId => {
    const tech = techById(techId)
    return `${tech.firstName} ${tech.lastName[0]}.`
  }

  return (
    <>
      <Typography variant='h6'>B2B Chat with {techName(technicianId)}</Typography>
      <Chat enableReadToggle contextType='TechnicianBToB' contextId={technicianId} />
    </>
  )
}

export default Technician
