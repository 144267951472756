import { gql } from '@apollo/client'
import { JobListing } from '../useJobListings/query'

export interface getJobListingRes {
  jobListing?: JobListing
}

export interface getJobListingReq {
  id?: string
}

export const GET_JOB_LISTING = gql`
  query getJobListing($id: ID!) {
    jobListing(id: $id) {
      id
      jobTitle
      city
      state
      description
      salaryStart
      salaryEnd
      salaryPeriod
      education
      jobType
      experience
      company
      country
      zipCode
      streetAddress
      email
      openPositions
      createdAt
      expiresAt
      disabledAt
      jobApplications {
        id
        applicantId
        coverLetter
        resumeUrl
        createdAt
        rejectedAt
        markedForInterviewAt
        rejectionReason
        withdrewAt
        applicant {
          id
          fullName
          phone
          email
        }
      }
    }
  }
`
