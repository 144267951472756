import { useContext, useMemo } from 'react'
import { UserContext } from 'UserStore'

export default () => {
  const [user] = useContext(UserContext)

  const token = useMemo(() => user?.token, [user])

  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
}
