import { Switch, Route, useRouteMatch } from 'react-router-dom'
import BToBWorkOrderRouter from './WorkOrder/Router'
import Index from './Index'
import PartRouter from './WorkOrder/Parts/Router'

const Router = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:workOrderId/parts`}>
        <PartRouter />
      </Route>
      <Route path={`${path}/:workOrderId`}>
        <BToBWorkOrderRouter />
      </Route>
      <Route path={`${path}`}>
        <Index />
      </Route>
    </Switch>
  )
}

export default Router
