import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import { DateTime } from 'luxon'
import { TravelDistanceFromLeadToDealer, getCoordinates } from 'Leads/AvailabilityMap'

const jobCellStyles = makeStyles(theme => ({
  reschedule_button: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'white',
    lineHeight: 0,
    borderRadius: '5px',
    '&:hover': {
      boxShadow: '0px 0px 6px -1px red',
    },
  },
  job_cell_clickable: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  job: {
    position: 'relative',
    fontSize: 12,
    padding: '0px 3px',
    borderRadius: '3px',
    border: '1px solid #c8c8c8',
    marginBottom: '4px',
    backgroundColor: 'aliceblue',
    '&.payment-status-missing_parts': {
      backgroundColor: 'red',
    },
    '&.payment-status-fully_paid': {
      backgroundColor: '#70ff748f',
      border: '#70ff748f',
    },
    '&.payment-status-pre_paid': {
      border: '2px solid orange',
    },
    '&.payment-status-waiting_pre_payment': {
      border: '2px solid red',
    },
    '&.payment-status-paid': {
      border: '2px solid green',
    },
    '&.rescheduling': {
      border: '5px dashed red',
    },
  },
}))

export const BToBJobCell = ({ job, jobIsInPast }) => {
  const history = useHistory()
  const classes = jobCellStyles()
  const dealer = job.dealer

  return (
    <Grid
      draggable
      container
      spacing={0}
      className={classes.job}
      sx={{ opacity: jobIsInPast ? '0.5 !important' : 1, cursor: 'pointer', '&:hover': { borderColor: 'blue' } }}
    >
      <Grid item xs={12}>
        <div
          title={`${dealer.businessName}, (click to open lead)`}
          className={classes.job_cell_clickable + ' lead_name'}
          onClick={() => history.push(`/b-to-b/dealers/${dealer.id}`)}
        >
          <b>{dealer.businessName}</b>
        </div>
      </Grid>

      <Grid item xs={12}>
        <Link
          name='schedule-page-job-link'
          style={{ fontSize: '11px' }}
          to={`/b-to-b/dealers/${job.dealer.id}`}
        >
          from: {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)}
          <br />
          to: {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)}
        </Link>
      </Grid>
    </Grid>
  )
}

export const BToBJobCellWithDrivingDistance = ({ job, jobIsInPast, chunkIndex, showDrivingDistanceDays, leadCoordinates }) => {
  const history = useHistory()
  const classes = jobCellStyles()
  const { dealer } = job
  const [dealerCoordinates, setDealerCoordinates] = useState(null)


  const getDealerCoords = async() => {
    const { addressLineOne, city, state, zip } = dealer
    const coordinates = await getCoordinates(addressLineOne, city, state, zip)
    setDealerCoordinates(coordinates)
  }

  useEffect(() => {
    if (!showDrivingDistanceDays) {
      return
    }

    if( dealer.zip ) {
      getDealerCoords()
    }
  }, [showDrivingDistanceDays])

  return (
    <Grid
      draggable
      container
      spacing={0}
      className={classes.job}
      sx={{ opacity: jobIsInPast ? '0.5 !important' : 1, cursor: 'pointer', '&:hover': { borderColor: 'blue' } }}
    >
      <Grid item xs={12}>
        <div
          title={`${dealer.businessName}, (click to open lead)`}
          className={classes.job_cell_clickable + ' lead_name'}
          onClick={() => history.push(`/b-to-b/dealers/${dealer.id}`)}
        >
          <b>{dealer.businessName}</b>
        </div>
      </Grid>

      <Grid item xs={12}>
        <Link
          name='schedule-page-job-link'
          style={{ fontSize: '11px' }}
          to={`/b-to-b/dealers/${job.dealer.id}`}
        >
          from: {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)}
          <br />
          to: {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toLocaleString(DateTime.TIME_SIMPLE)}
        </Link>
      </Grid>

      <Grid item xs={12}>
        {showDrivingDistanceDays > 0 &&
          job?.technician &&
          job?.dealer &&
          showDrivingDistanceDays / 14 >= chunkIndex + 1 && (
            <TravelDistanceFromLeadToDealer
              leadCoordinates={leadCoordinates}
              dealerCoordinates={dealerCoordinates}
            />
          )}
      </Grid>
    </Grid>
  )
}
