import { useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import { IconButton, FormControl, TextField, InputAdornment } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { ClickAwayListener } from '@mui/base'

const SearchSelectorLi = styled('li')(({ theme }) => ({
  padding: '2px 8px',
  margin: '0',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#eaeae8',
  },
}))

const SearchSelector = ({
  value,
  placeholder = '',
  listOfObjects = [],
  onSelectionMade = () => {},
  renderOption = () => {},
  disabled,
  name,
  listName,
}) => {
  const [listIsVisible, setListIsVisible] = useState(false)
  const [searchInputText, setSearchInputText] = useState('')
  const formControlRef = useRef(null)

  const handleClickAway = () => setListIsVisible(false)
  const handleFocusFilter = () => setListIsVisible(true)
  const handleUserClickedItem = item => {
    item && onSelectionMade(item)
    setSearchInputText('')
    handleClickAway()
  }

  const searchedParts = () => {
    const filteredItems = listOfObjects.filter(item => {
      return item.name && item.name.toLowerCase().includes(searchInputText.toLowerCase())
    })
    return searchInputText === '' ? listOfObjects : filteredItems
  }

  return (
    <ClickAwayListener mouseEvent='onMouseDown' touchEvent='onTouchStart' onClickAway={handleClickAway}>
      <div style={{ width: '100%' }}>
        <FormControl ref={formControlRef} style={{ width: '100%', position: 'relative' }}>
          <TextField
            fullWidth
            size='small'
            style={{ minWidth: '360px' }}
            onChange={e => setSearchInputText(e.target.value)}
            type='search'
            value={searchInputText.trim() !== '' ? searchInputText : value || ''}
            onFocus={handleFocusFilter}
            placeholder={placeholder}
            variant='standard'
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {(searchInputText || value) && (
                    <IconButton onClick={() => handleUserClickedItem()}>
                      <CancelIcon fontSize='small' />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              name: name,
            }}
          />
        </FormControl>
        <div
          style={{
            border: '2px solid rgba(0, 0, 0, 0.12)',
            maxHeight: '250px',
            overflow: 'auto',
            display: `${listIsVisible ? 'block' : 'none'}`,
            position: 'absolute',
            background: 'white',
            zIndex: 2,
            width: formControlRef.current ? formControlRef.current.clientWidth : '450px',
          }}
        >
          <ul id={listName} style={{ listStyle: 'none', paddingLeft: 0, margin: 0 }}>
            {searchedParts().map(object => (
              <SearchSelectorLi
                key={object.id}
                onClick={() => {
                  handleUserClickedItem(object)
                }}
              >
                {renderOption(object)}
              </SearchSelectorLi>
            ))}
          </ul>
        </div>
      </div>
    </ClickAwayListener>
  )
}

export default SearchSelector
