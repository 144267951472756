import { gql } from '@apollo/client'
import { Interview } from '../useInterviews/query'

export interface GetInterviewReq {
  id?: string
}

export interface GetInterviewRes {
  interview?: Interview
}

export const GET_INTERVIEW = gql`
  query getInterview($id: ID!) {
    interview(id: $id) {
      id
      startDatetime
      endDatetime
      createdAt
      canceledAt
      selectedLanguage
      jobApplication {
        id
        jobListing {
          id
          jobTitle
        }
      }
      applicant {
        id
        fullName
      }
      recruiter {
        id
        firstName
        lastName
      }
    }
  }
`
