import { Box, Typography } from '@mui/material'
import { Tabs, Tab } from '@mui/material'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { LineItemPanelsTable } from './Components/PanelsTable'
import { LineItemsTable } from './Components/LineItemsTable'
import { PriceCategoriesTable } from './Components/PriceCategoriesTable'

export const Settings = () => {
  const [value, setValue] = useLocalStorage('bToBSettingsTab', 'line-items')
  const handleChangeTab = (_: any, newValue: any) => {
    setValue(newValue)
  }

  return (
    <Box>
      <Box sx={{ padding: '0rem 1rem' }}>
        <Typography variant='h4' sx={{ mt: '1rem', mb: '1rem' }}>
          B2B Settings
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Tabs value={value} onChange={handleChangeTab}>
          <Tab value='price-categories' label='Pricing Categories' />
          <Tab value='line-item-panels' label='Panels List' />
          <Tab value='line-items' label='Line Items' />
        </Tabs>
      </Box>

      {value === 'line-items' && <LineItemsTable />}
      {value === 'line-item-panels' && <LineItemPanelsTable />}
      {value === 'price-categories' && <PriceCategoriesTable />}
    </Box>
  )
}
