import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from 'GlobalStore'
import { Switch, Route, useParams, useRouteMatch, useHistory } from 'react-router-dom'

import New from './New'
import TechnicianRouter from './Technician/Router'
import Index from './Index'

const Router = () => {
  const [global] = useContext(GlobalContext)
  const history = useHistory()
  const { path } = useRouteMatch()
  // const [technician, setTechnician] = useState(global.technicians[4])
  const [technician, setTechnician] = useState(null)

  useEffect(() => {
    technician && history.push(`${path}/${technician.id}`)
  }, [technician])

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <New />
      </Route>
      <Route path={`${path}/:technicianId`}>
        <TechnicianRouter technician={technician} />
      </Route>
      <Route path={`${path}`}>
        <Index />
      </Route>
    </Switch>
  )
}

export default Router
