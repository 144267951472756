import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import BreadCrumbs from '@mui/material/Breadcrumbs'
import { Typography } from '@mui/material'

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    padding: '0.3em'
  }
}))

const Breadcrumbs = ({ lead, quote, job, part, appointment, activeObject }) => {
  const classes = useStyles()

  const breadcrumbs = []
  if (lead) {
    if (activeObject !== lead) {
      breadcrumbs.push(<Link key='lead' to={`/leads/${lead.id}`}>Lead</Link>)
    } else {
      breadcrumbs.push(<Typography key='lead'>Lead</Typography>)
    }
  }
  if (quote) {
    if (activeObject !== quote) {
      breadcrumbs.push(<Link key='quote' to={`/leads/${lead.id}/quotes/${quote.id}`}>Quote</Link>)
    } else {
      breadcrumbs.push(<Typography key='quote'>Quote</Typography>)
    }
  }
  if (job) {
    breadcrumbs.push(<Typography key='job'>Job</Typography>)
  }
  if (appointment) {
    breadcrumbs.push(<Typography key='appointment'>Appointment</Typography>)
  }
  if (part) {
    if (activeObject !== part) {
      breadcrumbs.push(<Link key='part' to={`/leads/${lead.id}/quotes/${quote.id}`}>Part</Link>)
    } else {
      breadcrumbs.push(<Typography key='part'>Part</Typography>)
    }
  }

  return (
    <BreadCrumbs sx={{ display: 'inline-block' }} className={classes.breadcrumbs} separator='›'>
      {breadcrumbs}
    </BreadCrumbs>
  )
}

export default Breadcrumbs
