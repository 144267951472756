import { gql, useQuery } from '@apollo/client'
import { Box } from '@mui/material'

const TIRECONNECT_SUPPLIERS = gql`
  query TireConnectSuppliers {
    tireConnectSuppliers {
      id
      zip
      state
      city
      phone
      addressLineOne
      tireConnectLocationAddress
      tireConnectLocationName
      businessName
      supplierName
    }
  }
`

export const TireConnectSupplier = ({ supplierId }: { supplierId: string }) => {
  const { data, loading, error } = useQuery(TIRECONNECT_SUPPLIERS)

  const suppliers = data?.tireConnectSuppliers || []

  const thisSupplier = suppliers?.find((sup: any) => sup.id === supplierId)

  return (
    <Box>
      <ul>
        <li>{thisSupplier?.supplierName}</li>
        <li>{thisSupplier?.addressLineOne}</li>
        <li>{thisSupplier?.city}</li>
        <li>{thisSupplier?.state}</li>
        <li>{thisSupplier?.zip}</li>
        <li>{thisSupplier?.phone}</li>
      </ul>
    </Box>
  )
}
