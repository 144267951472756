import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import Edit from './Edit'

const QUERY = gql`
  query market($id: String!) {
    market(id: $id) {
      id
      market
      state
      stateCode
      hourlyRatePrep
      hourlyRatePaint
      hourlyRateBodyWork
      hourlyRateMajorPart
      hourlyRateMinorPart
      quoteApprovalRequired
    }
  }
`

const Router = () => {
  const { path } = useRouteMatch()
  const { marketID } = useParams()

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { id: marketID },
  })

  if (loading) return 'Loading...'
  if (error) return JSON.stringify(error)
  const market = data.market

  return (
    <Switch>
      <Route path={path}>
        <Edit market={market} refetch={refetch} />
      </Route>
    </Switch>
  )
}

export default Router
