import { gql } from '@apollo/client'

export interface Interview {
  id?: string
  startDatetime?: string
  endDatetime?: string
  createdAt?: string
  canceledAt?: string
  selectedLanguage?: string
  status?: string

  jobApplication?: {
    id?: string
    jobListing?: {
      id?: string
      jobTitle?: string
    }
  }

  applicant?: {
    id?: string
    fullName?: string
  }

  recruiter?: {
    id?: string
    firstName?: string
    lastName?: string
  }

  jobListing?: {
    id?: string
  }
}

export interface GetInterviewsRes {
  interviews?: Interview[]
}

export const GET_ALL_INTERVIEWS = gql`
  query InterviewsConnection($order: JSON, $filter: JSON, $first: Int, $after: String) {
    interviewsConnection(order: $order, filter: $filter, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          startDatetime
          endDatetime
          createdAt
          canceledAt

          jobApplication {
            id
            jobListing {
              id
              jobTitle
            }
          }

          applicant {
            id
            fullName
          }

          recruiter {
            id
            firstName
            lastName
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
