import { Box, CircularProgress, Typography } from '@mui/material'
import { CheckCircle, Error } from '@mui/icons-material'
import { styles } from './style'

export const ErrorOverlay = () => {
  return (
    <Box sx={styles.submittingOverlay}>
      <Box sx={styles.submittingOverlay.container}>
        <Error sx={{ color: '#fff', mb: '1rem' }} />
        <Typography variant='body1' sx={{ textAlign: 'center', color: '#fff' }}>
          An error occured while processing your payment. <br /> We are sorry for the inconvenience. <br /> <br />
          Today's Payment: $0.00 <br /> <br /> Please reload to try again.
        </Typography>
      </Box>
    </Box>
  )
}

export const ProcessingOverlay = () => {
  return (
    <Box sx={styles.submittingOverlay}>
      <Box sx={styles.submittingOverlay.container}>
        <CircularProgress sx={{ height: '40px', width: '40px', color: '#fff', mb: '1rem' }} />
        Processing Payment...
      </Box>
    </Box>
  )
}

export const SuccessfullyProcessedOverlay = () => {
  return (
    <Box sx={styles.submittingOverlay}>
      <Box sx={styles.submittingOverlay.container}>
        <CheckCircle sx={{ color: '#fff', mb: '1rem' }} />
        Payment Processed
      </Box>
    </Box>
  )
}

export const VerifyingOverlay = () => {
  return (
    <Box sx={styles.submittingOverlay}>
      <Box sx={styles.submittingOverlay.container}>
        <CircularProgress sx={{ height: '40px', width: '40px', color: '#fff', mb: '1rem' }} />
        Verifying Card Information...
      </Box>
    </Box>
  )
}

export const SuccessfullyVerifiedOverlay = () => {
  return (
    <Box sx={styles.submittingOverlay}>
      <Box sx={styles.submittingOverlay.container}>
        <CheckCircle sx={{ color: '#fff', mb: '1rem' }} />
        Verification Successful!
      </Box>
    </Box>
  )
}
