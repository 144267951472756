import { useEffect, useState } from 'react'
import JSONPretty from 'react-json-pretty'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import Button from '@mui/material/Button'

const StreamDump = ({ stream }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [dump, setDump] = useState(null)
  const [show, setShow] = useState(false)

  const fetchStream = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/events_dump`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({ stream })
    })
      .then(res => res.json())
      .then(data => setDump(data))

  useEffect(() => {
    show && fetchStream()
  }, [show])

  if (!show) return <Button onClick={() => setShow(true)} size='small'>Pull back the curtain</Button>
  if (!dump) return <>loading </>
  return (
    <>
      <JSONPretty style={{ fontSize: '12px' }} data={dump} />
      <Button size='small' onClick={fetchStream}>reload</Button>
    </>
  )
}

export default StreamDump
