import { Box, Typography, Button } from '@mui/material'
import { useParams } from 'react-router-dom'

import { useInterview } from 'hooks/recruiting/interviews/useInterview'

const InterviewView = () => {
  const params: any = useParams()

  const interviewId = params?.interviewId
  const { interview, cancel } = useInterview(interviewId)

  if (!interviewId || !interview) return null

  return (
    <Box sx={{ p: 2 }}>
      <Typography>Interview ID: {interview?.id}</Typography>
      <Typography>Application ID: {interview?.jobApplication?.id}</Typography>
      <Typography>Applicant ID: {interview?.applicant?.id}</Typography>
      <Typography>Job Listing ID: {interview?.jobApplication?.jobListing?.id}</Typography>
      <Typography>Applicant Name: {interview?.applicant?.fullName}</Typography>
      <Typography>Job Title: {interview?.jobApplication?.jobListing?.jobTitle}</Typography>
      <Typography>Created At: {interview?.createdAt}</Typography>
      <Typography>Canceled At: {interview?.canceledAt}</Typography>

      <Box sx={{ display: 'flex', gap: 1, padding: 2 }}>
        <Button onClick={cancel} disabled={!!interview?.canceledAt} variant='contained'>
          Reject
        </Button>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant='h6'>Job Application</Typography>

        <Box sx={{ mt: 2 }}>
          <a href={`/recruiting/applications/${interview?.jobApplication?.id}`} target='_blank' rel='noreferrer'>
            <Typography>{interview?.applicant?.fullName}</Typography>
          </a>
        </Box>
      </Box>
    </Box>
  )
}

export default InterviewView
