import { useQuery } from '@apollo/client'
import { GET_JOB_APPLICATION, GetJobApplicationReq, GetJobApplicationRes } from './query'
import { api } from 'utils/axios'

export const useJobApplication = (id?: string) => {
  const { data, refetch } = useQuery<GetJobApplicationRes, GetJobApplicationReq>(GET_JOB_APPLICATION, {
    variables: { id },
    skip: !id,
  })

  const reject = () => {
    api
      .post('/reject_job_application', {
        job_application_id: id,
        rejection_reason: '', // TODO
      })
      .then(res => {
        if (res.status === 200) alert('Rejected job application successfully')
        else alert('Failed to reject job application')
        refetch()
      })
  }

  const markForInterview = () => {
    api
      .post('/mark_for_interview_job_application', {
        job_application_id: id,
      })
      .then(res => {
        if (res.status === 200) alert('Marked job application for interview')
        else alert('Failed to mark job application for interview')
        refetch()
      })
  }

  const markAsPending = () => {
    api
      .post('/mark_as_pending_job_application', {
        job_application_id: id,
      })
      .then(res => {
        if (res.status === 200) alert('Marked job application as pending')
        else alert('Failed to mark job application as pending')
        refetch()
      })
  }

  return { jobApplication: data?.jobApplication, reject, markForInterview, markAsPending }
}
