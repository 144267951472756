export const styles = {
  title: {
    textAlign: 'center',
    color: '#444',
    fontWeight: 500,
    maxWidth: '90%',
    fontSize: '28px',
    margin: '1.5rem auto 1rem auto',
  },
  subtitle: {
    textAlign: 'center',
    fontWeight: 400,
    lineHeight: 1.35,
    maxWidth: '90%',
    fontSize: '18px',
    margin: '1rem auto 2rem auto',
  },
  grandTotalTitle: {
    textAlign: 'center',
    color: '#444',
    fontWeight: 600,
    maxWidth: '90%',
    fontSize: '16px',
    margin: '1.5rem auto 1rem auto',
  },

  popupContainer: {
    padding: '0rem 1.25rem 1rem 1.25rem',
    width: '500px',
    boxSizing: 'border-box',
    margin: '0px auto',
  },
  closeButton: {
    position: 'fixed',
    color: '#fff',
    '&:hover': {
      background: 'transparent',
    },
    ml: '440px',
    mr: '-3rem',
    mt: '-40px',
  },
  filledButton: {
    ml: 'auto important',
    fontWeight: 500,
    fontSize: '14px',
    padding: '.35rem',
    textTransform: 'none',
    fontFamily: 'Gotham SSm',
    borderRadius: '8px',
    maxWidth: '200px',
  },
  textButton: {
    mr: 'auto',
    fontWeight: 500,
    fontSize: '14px',
    textTransform: 'none',
    fontFamily: 'Gotham SSm',
    borderRadius: '8px',
  },
  label: {
    fontWeight: 700,
    margin: '0rem 0rem .5rem 0rem',
    fontSize: '14px',
  },
  submittingOverlay: {
    position: 'fixed',
    background: 'rgba(0, 0, 0, 0.6)',
    backdropFilter: 'blur(6px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: '0px',
    right: '0px',
    top: '0px',
    bottom: '0px',
    height: '100%',
    width: '100%',
    zIndex: 1801,
    borderRadius: '12px',
    color: '#fff',
    userSelect: 'none',
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem',
      borderRadius: '12px',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      color: '#fff',
      '& svg': {
        height: '40px',
        width: '40px',
      },
    },
  },
}
