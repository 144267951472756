import {
  Box,
  Button,
  Typography,
  FormControl,
  TextField,
  Grid,
  Dialog,
  FormControlLabel,
  Switch,
  List,
  ListItemText,
} from '@mui/material'
import { Save, StarRounded } from '@mui/icons-material'
import { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
const phoneNumberRegex = /^\d{10}$/

const contactSchema = Yup.object().shape({
  first_name: Yup.string().nullable(),
  last_name: Yup.string().nullable(),
  email: Yup.string().matches(emailRegex, 'Must match this form: tech@example.com').nullable(),
  phone: Yup.string().matches(phoneNumberRegex, 'Invalid phone number. Must match this form: XXXxxxXXXX').nullable(),
})

export const ContactAdder = ({ refetch, dealer }) => {
  const [user] = useContext(UserContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedContact, setSelectedContact] = useState({})

  useEffect(() => {
    !dialogOpen && setSelectedContact({})
  }, [dialogOpen])

  return (
    <Box sx={{ mt: '1rem' }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            padding: '.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            background: 'aliceblue',
            borderTop: '2px solid #a1cff7',
            borderBottom: '2px solid #a1cff7',
          }}
        >
          <Typography>Contacts</Typography>
          <Button disabled={!user.roles.includes('admin')} size='small' variant='outlined' onClick={() => setDialogOpen(true)}>
            Add new contact
          </Button>
        </Grid>
      </Grid>

      <ContactShowEdit
        contact={selectedContact}
        open={dialogOpen}
        setOpen={setDialogOpen}
        dealer={dealer}
        refetch={refetch}
      />

      <List>
        {dealer.contacts
          .slice()
          .sort((a, b) => (b.isPrimary ? 1 : -1))
          .map(contact => (
            <ListItemText
              primary={
                <>
                  {contact.isPrimary && <StarRounded sx={{ fontSize: '.8rem' }} />} {contact.firstName}{' '}
                  {contact.lastName}{' '}
                  {user.roles.includes('admin') && (
                    <button
                      onClick={() => {
                        setSelectedContact(contact)
                        setDialogOpen(true)
                      }}
                    >
                      edit
                    </button>
                  )}
                </>
              }
              secondary={
                <>
                  {contact.email} - {contact.phone} <br /> {contact.description}
                </>
              }
            />
          ))}
      </List>
    </Box>
  )
}

const ContactShowEdit = ({ dealer, refetch, contact = {}, open, setOpen }) => {
  const [user] = useContext(UserContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const dealerId = dealer.id

  const initialValues = {
    first_name: contact.firstName,
    last_name: contact.lastName,
    email: contact.email,
    phone: contact.phone,
    description: contact.description,
    is_primary: contact.isPrimary,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: contactSchema,
    onSubmit: values => {
      const filteredValues = Object.fromEntries(Object.entries(values).filter(([k, v]) => v !== initialValues[k]))
      const urlToUse = contact.id ? 'update_b_to_b_dealer_contact_attributes' : 'create_b_to_b_dealer_contact'

      const bodyToUse = contact.id
        ? {
            contact_id: contact.id,
            user_id: user.id,
            updated_attributes: filteredValues,
          }
        : {
            contact_id: contact.id,
            user_id: user.id,
            dealerId: dealerId,
            ...filteredValues,
          }

      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/${urlToUse}`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify(bodyToUse),
      })
        .then(() => refetch())
        .then(() => setOpen(false))
    },
  })

  const handleClickDelete = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_dealer_contact`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        contact_id: contact.id,
      }),
    })
      .then(() => refetch())
      .then(() => setOpen(false))
  }

  const handleReset = () => {
    formik.setValues(initialValues)
  }

  const validationErrors = Object.values(
    Object.keys(formik.errors).reduce((acc, k) => {
      return acc
    }, {})
  )

  useEffect(() => {
    formik.setValues({
      first_name: contact.firstName,
      last_name: contact.lastName,
      email: contact.email,
      phone: contact.phone,
      description: contact.description,
      is_primary: contact.isPrimary,
    })
  }, [contact])

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Typography sx={{ background: 'aliceblue', padding: '.5rem', textAlign: 'center', fontWeight: 600 }}>
        Add new contact
      </Typography>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                color='primary'
                name='is_primary'
                checked={formik.values.is_primary}
                onChange={() => formik.setFieldValue('is_primary', formik.values.is_primary ? false : true)}
              />
            }
            label='Primary contact'
          />
        </Grid>
        {Object.keys(initialValues)
          .filter(k => k !== 'description')
          .filter(k => k !== 'is_primary')
          .map(val => (
            <Grid item xs={6}>
              <FormControl fullWidth size='small'>
                <TextField
                  size='small'
                  value={formik.values[val]}
                  name={val}
                  onChange={formik.handleChange}
                  placeholder={val}
                  error={formik.touched[val] && Boolean(formik.errors[val])}
                  helperText={formik.touched[val] && formik.errors[val]}
                />
              </FormControl>
            </Grid>
          ))}

        <Grid item xs={12}>
          <TextField
            fullWidth
            size='small'
            multiline
            maxRows={4}
            minRows={3}
            value={formik.values.description}
            name='description'
            onChange={formik.handleChange}
            placeholder='description'
            error={formik.touched['description'] && Boolean(formik.errors['description'])}
            helperText={formik.touched['description'] && formik.errors['description']}
          />
        </Grid>

        {validationErrors.length > 0 && (
          <>
            <br />
            <div style={{ color: 'red' }}>
              {validationErrors.map(error => (
                <div key={error}>{error}</div>
              ))}
            </div>
            <br />
          </>
        )}
      </Grid>

      {validationErrors.length > 0 && (
        <>
          <br />
          <div style={{ color: 'red' }}>
            {validationErrors.map(error => (
              <div key={error}>{error}</div>
            ))}
          </div>
          <br />
        </>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem' }}>
        <Button disabled={!contact.id} onClick={handleClickDelete}>
          Delete
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={!formik.dirty || !formik.isValid}
          type='submit'
          onClick={formik.handleSubmit}
        >
          <Save />
          Save
        </Button>
      </Box>
    </Dialog>
  )
}
