import { memo, useEffect, useContext, useState } from 'react'
import { useChannel, useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import taskRoles from 'data/taskRoles'
import { makeStyles } from '@mui/styles'
import { DateTime } from 'luxon'
import { UserContext } from 'UserStore'
import { GlobalContext } from 'GlobalStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import {
  Grid, Checkbox, FormControlLabel, ToggleButtonGroup, ToggleButton,
  Typography, Autocomplete, TextField, Box, Switch, Collapse
} from '@mui/material'
import { useQuery, gql } from '@apollo/client'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ManualTasks from './ManualTasks'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import TaskLink from 'components/TaskLink'

const tasksStyles = makeStyles(theme => ({
  task: {
    display: 'block',
    padding: '0.3em',
    backgroundColor: 'white',
    '&.green': {
      borderLeft: '5px solid lightgreen'
    },
    '&.yellow': {
      borderLeft: '5px solid yellow'
    },
    '&.orange': {
      borderLeft: '5px solid orange'
    },
    '&.red': {
      borderLeft: '5px solid lightcoral'
    }
  }
}))

const MARKETS_QUERY = gql`
  query markets {
    markets {
      state
      stateCode
      market
    }
  }
`
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const formattedUsername = user => `${user?.firstName} ${user?.lastName && user?.lastName?.length > 0 && user?.lastName[0]}`

const Tasks = () => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const classes = tasksStyles()
  const [global, _, users] = useContext(GlobalContext)
  const [nowDateTime, setNowDateTime] = useState(DateTime.local())
  const [user] = useContext(UserContext)
  const [regionToggle, setRegionToggle] = useState(localStorage.getItem('tasksRegion') || 'all')
  const [marketToggle, setMarketToggle] = useState(JSON.parse(localStorage.getItem('tasksMarket')) || [])
  const [roles, setRoles] = useState(JSON.parse(localStorage.getItem('tasksRoles')) || [])
  const [manualTypes, setManualTypes] = useState(JSON.parse(localStorage.getItem('tasksManualTypes')) || [])
  const [onlyShowMyClaimed, setOnlyShowMyClaimed] = useState(false)
  const [tasks, setTasks] = useState([])
  const [showFutureManual, setShowFutureManual] = useState(false)
  const [showMarkets, setShowMarkets] = useState(localStorage.getItem('marketSwitch') === 'true' || false)
  const [showManualTasks, setShowManualTasks] = useLocalStorage('showManualTasks', 'false')
  const [taskOwnerIds, setTaskOwnerIds] = useState(JSON.parse(localStorage.getItem('taskOwnerIds')) || [user?.id])
  const [allPossibleOwners, setAllPossibleOwners] = useState([{ userId: 'all', label: 'All' }, { userId: user?.id, label: 'me' }])

  useEffect(() => {
    if (user && users) {
      const canClaimCustomerUsers = users.filter(u => u.roles.includes('can_claim_customer'))

      if (canClaimCustomerUsers?.length > 0) {
        setAllPossibleOwners(
          [
            {
              userId: 'all',
              label: 'All'
            },
            { userId: user.id, label: 'me' },
            ...canClaimCustomerUsers.map(user => ({
              userId: user.id,
              label: formattedUsername(user)
            }))
          ]
        )
      }
    }
  }, [users, user])

  useEffect(() => localStorage.setItem('tasksRegion', regionToggle), [regionToggle])
  useEffect(() => localStorage.setItem('tasksMarket', JSON.stringify(marketToggle)), [marketToggle])
  useEffect(() => localStorage.setItem('tasksRoles', JSON.stringify(roles)), [roles])
  useEffect(() => localStorage.setItem('tasksManualTypes', JSON.stringify(manualTypes)), [manualTypes])
  useEffect(() => localStorage.setItem('taskOwnersIds', JSON.stringify(taskOwnerIds)), [taskOwnerIds])

  const channel = useChannel('tasks:page', null, (channel, { tasks }) => {
    setTasks(tasks)
  })

  useEventHandler(channel, 'task_created', ({ task }) => {
    setTasks(prev => [task, ...prev])
  })

  useEventHandler(channel, 'task_updated', ({ task }) => {
    setTasks(prev => prev.map(thisTask => (thisTask.id === task.id && task) || thisTask))
  })

  const technicianById = id => global.technicians.find(tech => tech.id === id)
  const technicianName = id => {
    const user = technicianById(id)
    return `${user.firstName} ${user.lastName}.`
  }

  const userById = id => global.users.find(user => user.id === id)
  const userName = id => {
    const user = userById(id)

    return (user && `${user.firstName} ${user.lastName[0]}`) || 'UNKNOWN'
  }

  const taskTechnicianName = task => task.targetType === 'Technician' && ` - (${technicianName(task.targetId)})`

  const tickTheClock = () => setNowDateTime(DateTime.local())

  useEffect(() => {
    const interval = setInterval(tickTheClock, 10000)
    return () => clearInterval(interval)
  }, [])

  const toggleRole = role => {
    setRoles((roles.includes(role) && roles.filter(thisRole => role !== thisRole)) || roles.concat(role))
  }

  const urgencyLevel = task => {
    if (nowDateTime > DateTime.fromISO(task.redAt)) {
      return 'red'
    } else if (nowDateTime > DateTime.fromISO(task.orangeAt)) {
      return 'orange'
    } else if (nowDateTime > DateTime.fromISO(task.yellowAt)) {
      return 'yellow'
    }
    return 'green'
  }

  // const taskAge = task => {
  //   const diff = DateTime.local().diff(DateTime.fromISO(task.createdAt), ['hours', 'minutes']).toObject()
  //   return `${diff.hours}h:${diff.minutes.toFixed()}m`
  // }

  // const taskAgeOrViewedAge = task => {
  //   const diff = DateTime.local()
  //     .diff(DateTime.fromISO(task.lastViewedAt || task.createdAt), ['hours', 'minutes'])
  //     .toObject()
  //   return `${diff.hours}h:${diff.minutes.toFixed()}m`
  // }

  const ownedTasks = tasks.filter(task => task.ownerUserId && task.type !== 'Task::Types::Manual')
  const nonOwnedTasks = tasks.filter(task => !task.ownerUserId)

  const unclaimedOwnedTasks =
    ownedTasks.filter(task => !task.claimedAt)

  const ownedTasksToShow =
    unclaimedOwnedTasks.filter(task => taskOwnerIds.includes('all') || taskOwnerIds.includes(task.ownerUserId))

  const exclusive = nonOwnedTasks.filter(task => task.exclusiveUserId === user.id)
  const nonExclusive = nonOwnedTasks.filter(task => !task.exclusiveUserId)

  const incompleteExclusive = exclusive.filter(task => !task.completedAt)

  const unclaimedNonExclusive = nonExclusive.filter(task => !task.claimedAt)

  const claimedTasks = tasks.filter(task => task.claimedAt)
  const claimedButNotCompleteTasks = claimedTasks.filter(task => !task.completedAt)

  const completedTasks = tasks.filter(task => task.completedAt)

  const maybeRegionFilteredTasks =
    (regionToggle !== 'all' &&
      unclaimedNonExclusive.filter(task => task.region === regionToggle || task.region === null)) ||
    unclaimedNonExclusive

  const maybeRegionAndMarketFilteredTasks =
    (marketToggle.length > 0 &&
      maybeRegionFilteredTasks.filter(task => {
        if (marketToggle.includes(task.market) || task.market === null || !task.market) {
          if (!marketToggle.includes('Unknown market')) {
            if (task.market !== null && task.market) {
              return task
            }
          } else {
            return task
          }
        }
      })) ||
    maybeRegionFilteredTasks

  const finallyFilteredTasks = maybeRegionAndMarketFilteredTasks.filter(task =>
    (task.roles || taskRoles[task.type]).reduce((acc, role) => acc || roles.includes(role), false)
  )

  const myPrioritizedUnclaimed = finallyFilteredTasks.filter(
    task => task.prioritizedUserIds && task.prioritizedUserIds.includes(user.id)
  )

  const finallyFilteredTasksMinusPrioritized = finallyFilteredTasks.filter(
    task => !(task.prioritizedUserIds && task.prioritizedUserIds.includes(user.id))
  )

  const completedTasksToShow = (
    (onlyShowMyClaimed && completedTasks.filter(task => task.claimedByUserId === user.id)) ||
    completedTasks
  ).sort((a, b) => (DateTime.fromISO(a.claimedAt) > DateTime.fromISO(b.claimedAt) ? -1 : 1))

  const handleClickViewTask = taskId => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/view_task`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        taskId
      })
    }).then(res => res.ok || alert('error'))
  }

  const tasksForExclusiveList = [...incompleteExclusive].sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
  const tasksForPrioritizedList = [...myPrioritizedUnclaimed].sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
  const tasksForMainList = [...finallyFilteredTasksMinusPrioritized].sort((a, b) =>
    a.createdAt > b.createdAt ? 1 : -1
  )

  const { error, loading, data } = useQuery(MARKETS_QUERY, {
    fetchPolicy: 'network-only'
  })

  const stateMenuItems = data?.markets
    ?.slice()
    .sort((a, b) => (a.market > b.market ? 1 : -1))
    .map(k => k.market)

  const taskOwners =
    taskOwnerIds.map(userId => allPossibleOwners.find(possibleUserId => possibleUserId.userId === userId))

  if (loading) return 'loading...'
  if (error) return 'error...'

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <div
            style={{
              marginTop: '1em',
              backgroundColor: 'white',
              border: '2px solid #dcefff',
              padding: '0.25em 0.5em'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ToggleButtonGroup
                color='primary'
                value={regionToggle}
                exclusive
                size='small'
                onChange={e => {
                  setRegionToggle(e.target.value)
                  setMarketToggle([])
                }}
              >
                <ToggleButton value='east'>East</ToggleButton>
                <ToggleButton value='all'>all</ToggleButton>
                <ToggleButton value='west'>West</ToggleButton>
              </ToggleButtonGroup>

              &nbsp;&nbsp;

              <Autocomplete
                sx={{ display: 'inline-block' }}
                multiple
                size='small'
                options={['Unknown market', ...stateMenuItems]}
                disableCloseOnSelect
                value={marketToggle}
                onChange={(e, v) => {
                  setMarketToggle(v)
                  setRegionToggle('all')
                }}
                getOptionLabel={option => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option}
                  </li>
                )}
                style={{ width: '100%' }}
                renderInput={params => (
                  <TextField size='small' {...params} label='Markets' placeholder='Add a market' />
                )}
              />

              <FormControlLabel
                checked={showMarkets}
                control={<Switch color='primary' />}
                label='Show Markets'
                labelPlacement='start'
                sx={{ minWidth: '165px', '& span': { fontWeight: 'bold' } }}
                onChange={() => {
                  localStorage.setItem('marketSwitch', !showMarkets)
                  setShowMarkets(!showMarkets)
                }}
              />
            </Box>

            &nbsp;&nbsp;

            <FormControlLabel
              label='Quoting'
              control={<Checkbox onClick={() => toggleRole('quoting')} checked={roles.includes('quoting')} />}
            />
            <FormControlLabel
              label='Sales'
              control={<Checkbox onClick={() => toggleRole('sales')} checked={roles.includes('sales')} />}
            />
            <FormControlLabel
              label='Gravy'
              control={<Checkbox onClick={() => toggleRole('gravy')} checked={roles.includes('gravy')} />}
            />
            <FormControlLabel
              label='Tech. Support'
              control={<Checkbox onClick={() => toggleRole('tech_support')} checked={roles.includes('tech_support')} />}
            />
            <FormControlLabel
              label='Parts'
              control={<Checkbox onClick={() => toggleRole('parts')} checked={roles.includes('parts')} />}
            />
            <FormControlLabel
              label='Audatex'
              control={<Checkbox onClick={() => toggleRole('audatex')} checked={roles.includes('audatex')} />}
            />
            <FormControlLabel
              label='Calls'
              control={<Checkbox onClick={() => toggleRole('calls')} checked={roles.includes('calls')} />}
            />
            <FormControlLabel
              label='Windshields'
              control={<Checkbox onClick={() => toggleRole('windshields')} checked={roles.includes('windshields')} />}
            />
            <FormControlLabel
              label='Recruiting'
              control={<Checkbox onClick={() => toggleRole('recruiter')} checked={roles.includes('recruiter')} />}
            />
            <br />
            {user.roles.includes('sr_quoter') && (
              <FormControlLabel
                label='Senior Quoter'
                control={<Checkbox onClick={() => toggleRole('sr_quoter')} checked={roles.includes('sr_quoter')} />}
              />
            )}
            {user.roles.includes('sr_sales') && (
              <FormControlLabel
                label='Senior Sales'
                control={<Checkbox onClick={() => toggleRole('sr_sales')} checked={roles.includes('sr_sales')} />}
              />
            )}
            {user.roles.includes('sr_parts') && (
              <FormControlLabel
                label='Senior Parts'
                control={<Checkbox onClick={() => toggleRole('sr_parts')} checked={roles.includes('sr_parts')} />}
              />
            )}
            {user.roles.includes('ops') && (
              <FormControlLabel
                label='Ops'
                control={<Checkbox onClick={() => toggleRole('ops')} checked={roles.includes('ops')} />}
              />
            )}
            {user.roles.includes('beta_tester') && (
              <FormControlLabel
                label='AI Estimates'
                control={
                  <Checkbox onClick={() => toggleRole('ai_estimates')} checked={roles.includes('ai_estimates')} />
                }
              />
            )}
            {user.roles.includes('beta_tester') && (
              <FormControlLabel
                label='Tires'
                control={<Checkbox onClick={() => toggleRole('tires')} checked={roles.includes('tires')} />}
              />
            )}
            <FormControlLabel
              label='Hot leads'
              control={<Checkbox onClick={() => toggleRole('hot')} checked={roles.includes('hot')} />}
            />
            <br />
            <FormControlLabel
              label='Insurance'
              control={<Checkbox onClick={() => toggleRole('insurance')} checked={roles.includes('insurance')} />}
            />
            <FormControlLabel
              label='Parts Sales'
              control={<Checkbox onClick={() => toggleRole('parts-sales')} checked={roles.includes('parts-sales')} />}
            />
          </div>

          <div
            style={{
              marginTop: '1em',
              backgroundColor: 'white',
              border: '2px solid #dcefff',
              padding: '0.25em 0.5em'
            }}
          >
            <Typography>Owned</Typography>

            <Autocomplete
              multiple
              size='small'
              options={allPossibleOwners}
              disableCloseOnSelect
              value={taskOwners}
              getOptionLabel={option => option.label}
              onChange={(e, v) => {
                setTaskOwnerIds([user.id, ...v.map(({ userId }) => userId).filter(id => id !== user.id)])
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.label}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField size='small' {...params} placeholder='...' />
              )}
            />

            {ownedTasksToShow.map(task => (
              <div key={task.id} className={classes.task + ` ${urgencyLevel(task)}`}>
                <TaskLink task={task} ownedName={userName(task.ownerUserId)} showAge />
              </div>
            ))}
          </div>

          <div
            style={{
              marginTop: '1em',
              backgroundColor: 'white',
              border: '2px solid #dcefff',
              padding: '0.25em 0.5em'
            }}
          >
            <Typography>Exclusive</Typography>
            {tasksForExclusiveList.map(task => (
              <div key={task.id} className={classes.task + ` ${urgencyLevel(task)}`}>
                <TaskLink task={task} showAge />
              </div>
            ))}
          </div>

          <div
            style={{
              marginTop: '1em',
              backgroundColor: 'white',
              border: '2px solid #dcefff',
              padding: '0.25em 0.5em'
            }}
          >
            <Typography>Prioritized</Typography>
            {tasksForPrioritizedList.map(task => (
              <div key={task.id} className={classes.task + ` ${urgencyLevel(task)}`}>
                <TaskLink task={task} showAge>
                  {taskTechnicianName(task)}
                </TaskLink>
              </div>
            ))}
          </div>

          <div
            style={{
              marginTop: '1em',
              backgroundColor: 'white',
              border: '2px solid #dcefff',
              padding: '0.25em 0.5em'
            }}
          >
            <Typography>Available</Typography>
            {tasksForMainList.map(task => (
              <div key={task.id} className={classes.task + ` ${urgencyLevel(task)}`}>
                <TaskLink task={task} showAge showMarket={showMarkets} />
              </div>
            ))}
          </div>
        </Grid>

        <Grid item xs={5}>
          <div
            style={{
              marginTop: '1em',
              backgroundColor: 'white',
              border: '2px solid #dcefff',
              padding: '0.25em 0.5em'
            }}
          >
            <Box
              sx={{
                zIndex: 1,
                display: 'flex',
                cursor: 'pointer',
                justifyContent: 'space-between',
                width: '100%',
                '& svg': {
                  transform: showManualTasks === 'true' ? 'rotate(180deg)' : 'rotate(0deg)'
                }
              }}
              onClick={() => setShowManualTasks(showManualTasks === 'true' ? 'false' : 'true')}
            >
              <Typography>Manual Tasks</Typography>

              <KeyboardArrowDownRounded />
            </Box>

            <Collapse in={showManualTasks === 'true'}>
              <ManualTasks tasks={tasks} />
            </Collapse>
          </div>

          {claimedButNotCompleteTasks.length > 0 && (
            <div
              style={{
                marginTop: '1em',
                backgroundColor: 'white',
                border: '2px solid #dcefff',
                padding: '0.25em 0.5em'
              }}
            >
              <Typography>Claimed but Incomplete</Typography>
              {claimedButNotCompleteTasks.map(task => (
                <div style={{ padding: '3px 1em' }} key={task.id}>
                  <div>
                    {userName(task.claimedByUserId)}
                    &nbsp;-&nbsp;
                    {DateTime.fromISO(task.claimedAt).toLocaleString(DateTime.TIME_SIMPLE)}
                    &nbsp;-&nbsp;
                    {task.completedAt
                      ? (
                        <b style={{ color: 'green' }}>complete</b>
                        )
                      : (
                        <b style={{ color: 'red' }}>NOT complete</b>
                        )}
                  </div>
                  <TaskLink task={task} target='_blank' />
                </div>
              ))}
            </div>
          )}
          <div
            style={{
              marginTop: '1em',
              backgroundColor: 'white',
              border: '2px solid #dcefff',
              padding: '0.25em 0.5em'
            }}
          >
            <Typography>
              Completed recently:&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Checkbox onClick={() => setOnlyShowMyClaimed(!onlyShowMyClaimed)} checked={onlyShowMyClaimed} />
                }
                label='Only Mine'
              />
            </Typography>

            {completedTasksToShow.map(task => (
              <div style={{ padding: '3px 1em' }} key={task.id}>
                <div>
                  {userName(task.claimedByUserId)}
                  &nbsp;-&nbsp;
                  {DateTime.fromISO(task.claimedAt).toLocaleString(DateTime.TIME_SIMPLE)}
                  &nbsp;-&nbsp;
                  {task.completedAt
                    ? (
                      <b style={{ color: 'green' }}>complete</b>
                      )
                    : (
                      <b style={{ color: 'red' }}>NOT complete</b>
                      )}
                </div>
                <TaskLink task={task} />
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default Tasks
