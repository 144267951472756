import {  Box, Button, } from '@mui/material'

const Overview = ({ smartFollowup }) => {
  return (
    <Box sx={{ padding: '1rem' }}>
      <Button
        sx={{ ml: '.5rem' }}
        variant='contained'
        disabled={!!smartFollowup.approvedAt || smartFollowup.canceledAt || !smartFollowup.locationVerifiedAt}
      >
        approve
      </Button>
    </Box>
  )
}

export default Overview
