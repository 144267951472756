import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { DeleteRounded } from '@mui/icons-material'
import { Button, Collapse, MenuItem, Select } from '@mui/material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { useState } from 'react'

export const PanelRow = ({ panel, panelGroups, refetch }: any) => {
  const [side, setSide] = useState(panel.side)
  const [selectedCategoryId, setSelectedCategoryId] = useState(panel.panelGroupId)
  const bearerTokenHeaders = useBearerTokenHeaders()

  const deletePanel = (panelId: string) => {
    window.confirm(`are you sure you want to update delete this line item?`) &&
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_b_to_b_panel`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          line_item_panel_id: panelId,
        }),
      }).then(res => {
        if (res) {
          setTimeout(() => refetch(), 250)
        } else {
          console.log('ERROR!')
        }
      })
  }

  const handleUpdatePanel = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_b_to_b_panel_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        line_item_panel_id: panel.id,
        updated_attributes: {
          side: side,
          panel_group_id: selectedCategoryId,
        },
      }),
    }).then(res => {
      if (res) {
        setTimeout(() => refetch(), 250)
      } else {
        console.log('ERROR!')
      }
    })
  }

  const itemNeedsUpdating = selectedCategoryId !== panel.panelGroupId || side !== panel.side

  return (
    <TableRow
      key={panel.id}
      sx={{
        background: itemNeedsUpdating ? '#fff5f0' : '#fff',
      }}
    >
      <TableCell>{panel.panel}</TableCell>
      <TableCell
        sx={{
          '& .MuiSelect-select': {
            padding: '.25rem .25rem .25rem .5rem',
          },
        }}
      >
        <Select
          disabled
          fullWidth
          placeholder='select a category'
          size='small'
          value={side}
          onChange={e => setSide(e.target.value)}
        >
          <MenuItem value=''>
            <i>None</i>
          </MenuItem>
          <MenuItem value='passenger'>Passenger</MenuItem>
          <MenuItem value='driver'>Driver</MenuItem>
        </Select>
      </TableCell>
      <TableCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiSelect-select': {
            padding: '.25rem .25rem .25rem .5rem',
          },
        }}
      >
        <Select
          fullWidth
          placeholder='select a category'
          size='small'
          value={selectedCategoryId}
          onChange={e => setSelectedCategoryId(e.target.value)}
        >
          {panelGroups.map((item: any) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </Select>

        <Collapse orientation='horizontal' in={panel.isCustom}>
          <Button
            onClick={() => deletePanel(panel.id)}
            color='error'
            size='small'
            sx={{ ml: 'auto', minWidth: '40px' }}
          >
            <DeleteRounded fontSize='small' />
          </Button>
        </Collapse>

        <Collapse orientation='horizontal' in={!!itemNeedsUpdating} sx={{ height: '30px' }}>
          <Button
            onClick={handleUpdatePanel}
            color='warning'
            size='small'
            sx={{ ml: '.5rem', minWidth: '40px', fontSize: '14px', padding: '.25rem' }}
          >
            Save
          </Button>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
