import { Box } from '@mui/material'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'

import CalendarInterviews from './CalendarInterviews'

import { useRecruiterInterviews } from 'hooks/recruiting/interviews/useRecruiterInterviews'
import { useContext } from 'react'
import { UserContext } from 'UserStore'

const RecruitingCalendar = () => {
  const [user] = useContext(UserContext)
  const { weeks } = useRecruiterInterviews(user.id)

  return (
    <Box sx={{ p: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Week</TableCell>
            <TableCell>Monday</TableCell>
            <TableCell>Tuesday</TableCell>
            <TableCell>Wednesday</TableCell>
            <TableCell>Thursday</TableCell>
            <TableCell>Friday</TableCell>
            <TableCell>Saturday</TableCell>
            <TableCell>Sunday</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.entries(weeks).map(([weekKey, week]) => {
            return (
              <TableRow key={weekKey}>
                <TableCell>{weekKey}</TableCell>
                <TableCell>
                  <CalendarInterviews interviews={week?.monday} />
                </TableCell>
                <TableCell>
                  <CalendarInterviews interviews={week?.tuesday} />
                </TableCell>
                <TableCell>
                  <CalendarInterviews interviews={week?.wednesday} />
                </TableCell>
                <TableCell>
                  <CalendarInterviews interviews={week?.thursday} />
                </TableCell>
                <TableCell>
                  <CalendarInterviews interviews={week?.friday} />
                </TableCell>
                <TableCell>
                  <CalendarInterviews interviews={week?.saturday} />
                </TableCell>
                <TableCell>
                  <CalendarInterviews interviews={week?.sunday} />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}

export default RecruitingCalendar
