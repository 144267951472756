import React, { useState, useEffect, useContext } from 'react'
import { useLazyQuery, useQuery, gql } from '@apollo/client'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { Link } from 'react-router-dom'
import { RefetchRegistry } from 'RefetchRegistry'
import { UpdateRefetchRegistry } from 'UpdateRefetchRegistry'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Dollars } from 'tools'
import { Button, Typography } from '@mui/material'

const QUERY = gql`
  query commissionChargeBacks($unclaimed: Boolean) {
    commissionChargeBacks(unclaimed: $unclaimed) {
      id
      quoteId
      userId
      createdAt
      amount
      payoutId
      note
      technicianId
      quote {
        id
        assignedTechnicianId
        lead {
          name
        }
      }
    }
  }
`

const CommissionChargeBacks = ({ refetchAll }) => {
  const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  const [registerUpdateRefetch, unregisterUpdateRefetch] = useContext(UpdateRefetchRegistry)

  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)

  const technicianById = id => global.technicians.find(tech => tech.id === id)

  const bearerTokenHeaders = useBearerTokenHeaders()

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { unclaimed: true },
  })

  useEffect(() => {
    data && refetch && refetch()
  }, [])

  useEffect(() => {
    const key = registerRefetch({ types: ['CommissionChargeBack'], refetch })
    return () => unregisterRefetch(key)
  }, [])

  useEffect(() => {
    const key = registerUpdateRefetch({ types: ['CommissionChargeBack'], refetch })
    return () => unregisterUpdateRefetch(key)
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const chargeBacks = data?.commissionChargeBacks || []

  const moveAll = () =>
    chargeBacks.forEach(comp =>
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/link_commission_charge_back_to_a_payout`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          commission_charge_back_id: comp.id,
        }),
      })
        .then(response => {
          if (response.ok) {
            console.log('ok')
          } else {
            throw Error('error')
          }
        })
        .catch(err => {
          window.alert(err)
        })
    )

  return (
    <>
      <Typography
        variant='body1'
        sx={{
          padding: '.5rem',
          background: 'aliceblue',
          borderBottom: '2px solid #a1cff7',
          borderTop: '2px solid #a1cff7',
          opacity: chargeBacks.length === 0 ? 0.5 : 1,
        }}
      >
        Charge Backs:
        <Button
          variant='outlined'
          sx={{
            fontSize: '12px !important',
            minWidth: '30px',
            padding: '.125rem .5rem',
            minHeight: '15px !important',
            ml: '.5rem',
            '&  .MuiButtonBase-root': {
              padding: '.125rem .5rem',
              minHeight: '15px !important',
            },
          }}
          onClick={e => {
            e.stopPropagation()
            moveAll()
          }}
        >
          Move all
        </Button>
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          opacity: chargeBacks.length === 0 ? 0.35 : 1,
          overflowX: 'visible !important',
        }}
      >
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='right'>Technician</TableCell>
              <TableCell align='right'>Quote</TableCell>
              <TableCell align='right'>Amount</TableCell>
              <TableCell align='right'>Note</TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chargeBacks.map(comp => (
              <CommissionChargeBack refetch={refetch} refetchAll={refetchAll} key={comp.id} manualCompensation={comp} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const CommissionChargeBack = ({ manualCompensation: comp, refetch, refetchAll }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const [waiting, setWaiting] = useState(false)
  const technicianById = id => global.technicians.find(tech => tech.id === id)

  const linkToAPayout = () => {
    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/link_commission_charge_back_to_a_payout`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        commission_charge_back_id: comp.id,
      }),
    })
      .then(response => {
        if (response.ok) {
          refetch()
          refetchAll()
        } else {
          throw Error('error')
        }
      })
      .then(() => setWaiting(false))
      .catch(err => {
        setWaiting(false)
        window.alert(err)
      })
  }

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell align='right'>
        {technicianById(comp.technicianId).firstName} {technicianById(comp.technicianId).lastName}
      </TableCell>
      <TableCell align='right'>
        <Link to={`/quotes/${comp.quote.id}`}>{comp.quote.lead.name}</Link>
      </TableCell>
      <TableCell align='right'>
        <b>
          <Dollars value={comp.amount} />
        </b>
      </TableCell>

      <TableCell align='right'>{comp.note}</TableCell>
      <TableCell align='right'>
        <button onClick={() => linkToAPayout(comp.id)} disabled={waiting}>
          link to payout
        </button>
      </TableCell>
    </TableRow>
  )
}

export default CommissionChargeBacks
