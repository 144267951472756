import { Box, Dialog } from '@mui/material'
import Notes from 'components/Notes'

export const NotesDialog = ({ noteDialogOpen, setNoteDialogOpen, bToBInvoice }) => {
  return (
    <Dialog
      PaperProps={{ sx: { minWidth: '600px', maxHeight: '500px', overflowY: 'auto' } }}
      open={noteDialogOpen}
      onClose={() => setNoteDialogOpen(false)}
    >
      <Box sx={{ padding: '1rem' }}>
        <Notes headerText='PAYROLL NOTES' objectType='BToBInvoiceForPayroll' objectId={bToBInvoice.id} />
      </Box>
    </Dialog>
  )
}
